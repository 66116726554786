import PropTypes from 'prop-types';

export const ImpactTotalPropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    weight: PropTypes.string,
    color: PropTypes.string,
});

export const ProfileActivityTotalsPropType = PropTypes.shape({
    points: PropTypes.number.isRequired,
    logs: PropTypes.number.isRequired,
    actions: PropTypes.number.isRequired,
    badges: PropTypes.number.isRequired,
});

export const ProfilePropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    headline: PropTypes.string,
    followers: PropTypes.number.isRequired,
    following: PropTypes.number.isRequired,
    impactTotals: PropTypes.arrayOf(ImpactTotalPropType).isRequired,
    activityTotals: ProfileActivityTotalsPropType.isRequired,
});

export const ActionTrophyPropType = PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
});

export const BadgeTrophyPropType = PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
});

export const MedalTrophyPropType = PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
});

export const ProfileTrophyPropType = PropTypes.shape({
    actions: PropTypes.arrayOf(ActionTrophyPropType).isRequired,
    badges: PropTypes.arrayOf(BadgeTrophyPropType).isRequired,
    medals: PropTypes.arrayOf(MedalTrophyPropType).isRequired,
});
