import {
    RETRIEVE_STRIPE_PLAN_ID,
    SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_FAILED,
    SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_SUCCEEDED,
    SUBSCRIPTION_MANAGEMENT_LINK_REQUESTED,
    SUBSCRIPTION_RECORDS_REQUEST_FAILED,
    SUBSCRIPTION_RECORDS_REQUEST_SUCCEEDED,
    SUBSCRIPTION_RECORDS_REQUESTED,
    SUBSCRIPTION_REQUEST_FAILED,
    SUBSCRIPTION_REQUEST_SUCCEEDED,
    SUBSCRIPTION_REQUESTED,
    SUBSCRIPTION_SESSION_REQUEST_FAILED,
    SUBSCRIPTION_SESSION_REQUEST_SUCCEEDED,
    SUBSCRIPTION_SESSION_REQUESTED,
    SUBSCRIPTION_TIERS_REQUEST_FAILED,
    SUBSCRIPTION_TIERS_REQUEST_SUCCEEDED,
    SUBSCRIPTION_TIERS_REQUESTED,
    SUBSCRIPTION_VERIFICATION_REQUEST_FAILED,
    SUBSCRIPTION_VERIFICATION_REQUEST_SUCCEEDED,
    SUBSCRIPTION_VERIFICATION_REQUESTED,
    SUBSCRIPTION_SESSION_CLEAR_ERRORS,
} from './actions';

const initialState = {
    accountPlan: {
        accountType: '',
        currentUsers: 0,
        maxUsers: 0,
        currentSubscription: null,
        errors: [],
        loading: false,
    },
    paymentMethod: null,
    stripePlanId: '',
    billingSession: {
        url: '',
        errors: [],
        loading: false,
    },
    subscriptionRecords: {
        loading: false,
        errors: [],
        records: [],
    },
    subscriptionTiers: {
        loading: false,
        errors: [],
        tiers: [],
    },
    management: {
        loading: false,
        errors: [],
        url: '',
    },
};

const subscriptionReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case SUBSCRIPTION_REQUESTED:
            return {
                ...state,
                accountPlan: {
                    ...state.accountPlan,
                    loading: true,
                },
            };
        case RETRIEVE_STRIPE_PLAN_ID:
            return {
                ...state,
                stripePlanId: payload.planId,
            };
        case SUBSCRIPTION_SESSION_REQUESTED:
            return {
                ...state,
                billingSession: {
                    ...initialState.billingSession,
                    loading: true,
                },
            };
        case SUBSCRIPTION_SESSION_REQUEST_SUCCEEDED:
            return {
                ...state,
                billingSession: {
                    ...state.billingSession,
                    url: payload.url,
                    errors: [],
                    loading: false,
                },
            };
        case SUBSCRIPTION_SESSION_REQUEST_FAILED:
            return {
                ...state,
                billingSession: {
                    ...state.billingSession,
                    errors: payload.errors,
                    loading: false,
                },
            };
        case SUBSCRIPTION_SESSION_CLEAR_ERRORS:
            return {
                ...state,
                billingSession: {
                    ...state.billingSession,
                    errors: [],
                },
            };
        case SUBSCRIPTION_VERIFICATION_REQUESTED:
            return {
                ...state,
                billingSession: {
                    ...state.billingSession,
                    loading: true,
                },
            };
        case SUBSCRIPTION_VERIFICATION_REQUEST_SUCCEEDED:
            return {
                ...state,
                accountPlan: {
                    ...state.accountPlan,
                    currentSubscription: payload.subscription,
                },
                billingSession: {
                    ...state.billingSession,
                    errors: [],
                    loading: false,
                },
            };
        case SUBSCRIPTION_VERIFICATION_REQUEST_FAILED:
            return {
                ...state,
                billingSession: {
                    ...state.billingSession,
                    errors: payload.errors,
                    loading: false,
                },
            };
        case SUBSCRIPTION_REQUEST_SUCCEEDED:
            return {
                ...state,
                accountPlan: {
                    ...state.accountPlan,
                    currentSubscription: payload.currentSubscription,
                    errors: [],
                    loading: false,
                },
            };
        case SUBSCRIPTION_REQUEST_FAILED:
            return {
                ...state,
                accountPlan: {
                    ...state.accountPlan,
                    errors: payload.errors,
                    loading: false,
                },
            };
        case SUBSCRIPTION_RECORDS_REQUESTED:
            return {
                ...state,
                subscriptionRecords: {
                    ...state.subscriptionRecords,
                    loading: true,
                },
            };
        case SUBSCRIPTION_RECORDS_REQUEST_SUCCEEDED:
            return {
                ...state,
                subscriptionRecords: {
                    ...state.subscriptionRecords,
                    loading: false,
                    records: payload.records,
                },
            };
        case SUBSCRIPTION_RECORDS_REQUEST_FAILED:
            return {
                ...state,
                subscriptionRecords: {
                    ...state.subscriptionRecords,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case SUBSCRIPTION_TIERS_REQUESTED:
            return {
                ...state,
                subscriptionTiers: {
                    ...state.subscriptionTiers,
                    loading: true,
                    errors: [],
                    tiers: [],
                },
            };
        case SUBSCRIPTION_TIERS_REQUEST_SUCCEEDED:
            return {
                ...state,
                subscriptionTiers: {
                    ...state.subscriptionTiers,
                    loading: false,
                    tiers: payload.tiers,
                },
            };
        case SUBSCRIPTION_TIERS_REQUEST_FAILED:
            return {
                ...state,
                subscriptionTiers: {
                    ...state.subscriptionTiers,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case SUBSCRIPTION_MANAGEMENT_LINK_REQUESTED:
            return {
                ...state,
                management: {
                    ...initialState.management,
                    loading: true,
                },
            };
        case SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_SUCCEEDED:
            return {
                ...state,
                management: {
                    ...state.management,
                    loading: false,
                    errors: [],
                    url: payload.url,
                },
            };
        case SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_FAILED:
            return {
                ...state,
                management: {
                    ...state.management,
                    loading: false,
                    errors: payload.errors,
                },
            };

        default:
            return state;
    }
};

export default subscriptionReducer;
