const styles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `solid 3px ${theme.palette.primary.main}`,
        borderRadius: '4px',
        padding: '10px 50px 10px 50px',
        [theme.breakpoints.down('md')]: {
            padding: '20px',
        },
    },
    logoSection: {
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginRight: 'auto',
            marginLeft: 'inherit',
        },
    },
    logo: {
        width: '40px',
        height: 'auto',
    },
    tipTitleSection: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        minWidth: '59px',
    },
    tipTitle: {
        fontWeight: '700',
    },
    tipContent: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
});

export default styles;
