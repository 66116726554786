export const AUDIENCE_VALUE_USERS = 'users';
export const AUDIENCE_VALUE_COMMUNITIES = 'communities';
export const AUDIENCE_VALUE_COMMUNITY_MEMBERS = 'communityMembers';
export const AUDIENCE_VALUE_COMMUNITY_MEMBERS_NOT_JOINED =
    'communityMembersNotJoined';
export const AUDIENCE_VALUE_CHALLENGE_TEAMS = 'challengeTeams';
export const AUDIENCE_VALUE_CHALLENGE_MEMBERS = 'challengeMembers';

export const GENERAL_SUBJECT_TYPE = 'general';
export const CHALLENGE_SUBJECT_TYPE = 'Challenge';
export const ACTION_SUBJECT_TYPE = 'Pin';

export const VALUE_GENERAL_SUBJECT_TYPE = 'general';
export const VALUE_CHALLENGE_SUBJECT_TYPE = 'challenge';
export const VALUE_ACTION_SUBJECT_TYPE = 'pin';

export const SUBJECT_TYPES_MAP = {
    [VALUE_GENERAL_SUBJECT_TYPE]: GENERAL_SUBJECT_TYPE,
    [VALUE_CHALLENGE_SUBJECT_TYPE]: CHALLENGE_SUBJECT_TYPE,
    [VALUE_ACTION_SUBJECT_TYPE]: ACTION_SUBJECT_TYPE,
};

export const SETTING_TYPE_PIN = 'pin';
export const SETTING_TYPE_CHALLENGE = 'challenge';

export const SAFE_COMMUNITY_CHALLENGE_AUDIENCE_TYPES = [
    AUDIENCE_VALUE_USERS,
    AUDIENCE_VALUE_CHALLENGE_TEAMS,
    AUDIENCE_VALUE_CHALLENGE_MEMBERS,
];
