import React, { Fragment } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import { ArrowBack, Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

import Button from '../Button';
import { Can } from '../../casl/ability-context';
import { noop } from '../../utils';
import {
    ACTIONS,
    EDIT_ACTION,
} from '../../screens/ActionsScreen/AdminActionsScreen/constants';

const BackgroundHeader = ({
    photoUrl,
    iconUrl,
    iconStyle,
    name,
    status,
    action,
    subject,
    allowReset,
    onChangePhoto,
    onReset,
    onChangeIcon,
    onChangeName,
    children,
    intercomTargetPrefix,
}) => {
    const classes = useStyles({ photoUrl, iconUrl });
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Card className={classes.root} data-intercom-target="Challenge Header">
            <CardHeader
                classes={{
                    root: classes.headerRoot,
                    content: classes.headerContent,
                    action: classes.headerAction,
                }}
                action={
                    <Fragment>
                        <IconButton
                            className={classes.backIcon}
                            onClick={history.goBack}
                            data-intercom-target={`${intercomTargetPrefix} Header Cover Back Button`}
                        >
                            <ArrowBack aria-label={t('alt.backButton')} />
                        </IconButton>
                        <Grid container direction="row" justify="flex-end">
                            <Can I={action} a={subject}>
                                {() => (
                                    <Button
                                        onClick={onChangePhoto}
                                        className={classes.editCover}
                                        variant="outlined"
                                        color="primary"
                                        data-intercom-target={`${intercomTargetPrefix} Header Cover Photo Edit`}
                                    >
                                        <Edit fontSize="small" />
                                        {t('coverPhoto.edit')}
                                    </Button>
                                )}
                            </Can>
                            {allowReset && (
                                <Can I={action} a={subject}>
                                    {() => (
                                        <Button
                                            variant="outlined"
                                            color="white"
                                            onClick={onReset}
                                            className={classes.btnReset}
                                        >
                                            {t('Reset')}
                                        </Button>
                                    )}
                                </Can>
                            )}
                        </Grid>
                    </Fragment>
                }
            />
            <CardContent className={classes.content}>
                <Can passThrough I={action} a={`${subject}Icon`}>
                    {(can) => (
                        <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            justify="flex-start"
                            className={classes.cardContent}
                        >
                            <Grid
                                item
                                className={classes.icon}
                                style={iconStyle}
                                data-intercom-target={`${intercomTargetPrefix} Header Icon Edit`}
                            >
                                {!!can && (
                                    <Button
                                        className={classes.btnEditIcon}
                                        onClick={onChangeIcon}
                                    >
                                        <Edit fontSize="small" />
                                    </Button>
                                )}
                            </Grid>
                            <Grid item className={classes.rightBody}>
                                <Can passThrough I={action} a={subject}>
                                    {(can) => (
                                        <Typography
                                            variant="h1"
                                            className={clsx(
                                                classes.name,
                                                !!can && classes.editName,
                                            )}
                                            onClick={onChangeName}
                                            data-intercom-target={`${intercomTargetPrefix} Header Name Edit`}
                                        >
                                            {name}
                                            {!!can &&
                                                !(
                                                    action === EDIT_ACTION &&
                                                    subject === ACTIONS
                                                ) && <Edit />}
                                        </Typography>
                                    )}
                                </Can>
                                {!!status && (
                                    <Typography
                                        variant="body2"
                                        className={classes.status}
                                    >
                                        {status}
                                    </Typography>
                                )}
                                {children}
                            </Grid>
                        </Grid>
                    )}
                </Can>
            </CardContent>
        </Card>
    );
};

BackgroundHeader.propTypes = {
    photoUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    iconStyle: PropTypes.object,
    name: PropTypes.string.isRequired,
    status: PropTypes.string,
    action: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    allowReset: PropTypes.bool,
    onChangePhoto: PropTypes.func,
    onReset: PropTypes.func,
    onChangeIcon: PropTypes.func,
    onChangeName: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    // Used to identify this common background header on intercom targets
    intercomTargetPrefix: PropTypes.string,
};

BackgroundHeader.defaultProps = {
    photoUrl: null,
    iconUrl: null,
    iconStyle: {},
    status: null,
    allowReset: false,
    onChangePhoto: noop,
    onReset: noop,
    onChangeIcon: noop,
    onChangeName: noop,
    children: null,
    intercomTargetPrefix: '',
};

export default BackgroundHeader;
