import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

import { ChallengeType } from '../propTypes';
import styles from './styles';
import Button from '../../../common/Button';
import ContentToggleButton from '../../../common/ContentToggleButton';
import { noop } from '../../../common/utils';
import { trackEvent } from '../../../utils/analytics';
import {
    EVENT_ONBOARDING_CHALLENGE_TYPE_CHOSEN,
    EVENT_ONBOARDING_SKIPPED,
} from '../../../constants/analyticsEvents';

export const CHALLENGE_STEP_ONE = 1;
export const CHALLENGE_STEP_TWO = 2;

const ChallengeTypeSelection = ({
    classes,
    onChallengeSelected,
    onConfirm,
    challengeTypes,
    onSkip,
}) => {
    const { t } = useTranslation();
    const [selectedChallengeType, setSelectedChallengeType] = useState(null);

    useEffect(() => {
        setSelectedChallengeType(challengeTypes[0]);
    }, [challengeTypes]);

    const onSelectChallengeType = (item) => {
        setSelectedChallengeType(item);
        onChallengeSelected(item);
    };

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            alignContent="center"
            className={classes.scrollOption}
        >
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.challengeTypeSection}
            >
                <Typography variant="h3" className={classes.challengeTypeTitle}>
                    {t('onBoarding.createChallenge.selectChallengeHeader')}
                </Typography>
                <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.typesSection}
                    xs={12}
                    spacing={0}
                >
                    {challengeTypes.map((item, index) => (
                        <Grid
                            item
                            md={4}
                            xs={6}
                            key={index}
                            className={classes.toggleButtonWrapper}
                        >
                            <ContentToggleButton
                                className={classes.toggleButton}
                                iconUrl={item.whiteIcon}
                                description={item.explainerText}
                                photoUrl={item.photoUrl}
                                name={item.name}
                                data-test={`challengeButton${index}`}
                                selectable={true}
                                selected={item.id === selectedChallengeType?.id}
                                onClick={() => {
                                    onSelectChallengeType(item);
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid container item>
                    <Grid container item>
                        <Typography className={classes.themeDescriptionText}>
                            {selectedChallengeType?.themeDescription}
                        </Typography>
                    </Grid>
                    {selectedChallengeType?.sampleActions?.length > 0 && (
                        <Grid container item alignItems="center">
                            <Grid item xs={12} sm={5}>
                                <Typography className={classes.actionsText}>
                                    {t(
                                        'onBoarding.createChallenge.availableActions',
                                    )}
                                </Typography>
                            </Grid>
                            {selectedChallengeType?.sampleActions?.map(
                                (action) => (
                                    <Grid xs={4} sm={2} key={action.id} item>
                                        <Grid
                                            className={classes.actionContainer}
                                            container
                                            item
                                            alignItems="center"
                                            wrap="nowrap"
                                        >
                                            <img
                                                className={classes.actionIcon}
                                                src={action.iconUrl}
                                                alt={action.name}
                                            />
                                            <Typography
                                                className={classes.actionName}
                                            >
                                                {action.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ),
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    className={classes.buttonWrapper}
                >
                    <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="outlined"
                        size="medium"
                        style={{ marginRight: `0`, marginBottom: '48px' }}
                        onClick={() => {
                            trackEvent(EVENT_ONBOARDING_SKIPPED);
                            onSkip();
                        }}
                        data-test="nextButton"
                    >
                        {t('onBoarding.common.skipToDashboard')}
                    </Button>
                    <Button
                        className={classes.actionButton}
                        color="primary"
                        size="medium"
                        style={{ marginRight: `0`, marginBottom: '48px' }}
                        onClick={() => {
                            trackEvent(EVENT_ONBOARDING_CHALLENGE_TYPE_CHOSEN, {
                                themeId: selectedChallengeType.id,
                            });
                            onConfirm(selectedChallengeType);
                        }}
                        data-test="nextButton"
                    >
                        {t('onBoarding.common.next')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

ChallengeTypeSelection.propTypes = {
    classes: PropTypes.object.isRequired,
    onChallengeSelected: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    challengeTypes: PropTypes.arrayOf(PropTypes.shape(ChallengeType))
        .isRequired,
};

ChallengeTypeSelection.defaultProps = {
    onChallengeSelected: noop,
    onSkip: noop,
};

export default withStyles(styles)(ChallengeTypeSelection);
