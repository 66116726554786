const styles = (theme) => ({
    modal: {
        backgroundColor: theme.custom.bgLightAccentColor,
        padding: '5px',
        minHeight: '300px',
        width: '600px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: theme.custom.mainContentHorizontalPadding,
            marginRight: theme.custom.mainContentHorizontalPadding,
        },
    },
    scrollPaper: {
        alignItems: 'flex-start',
    },
    titleBar: {
        padding: '15px',
        fontSize: '18px',
        fontWeight: 100,
    },
    title: {},
    closeButton: {},
    closeIcon: {
        opacity: 0.2,

        '&:hover': {
            opacity: 0.5,
            cursor: 'pointer',
        },
    },
    summary: {},
    summaryItem: {
        marginLeft: '20px',
    },
    summaryImage: {
        margin: '10px',
        width: '64px',
        height: '64px',
    },
    summaryPoints: {
        fontSize: '40px',
        paddingRight: '5px',
    },
    ok: {
        marginTop: '40px',
        marginBottom: '15px',
    },
    okButton: {
        width: '60%',
    },
});

export default styles;
