import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Link } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import useStyles from './styles';

import { Can } from '../../casl/ability-context';
import Button from '../Button';
import { noop } from '../../utils';

const defaultImageUrl = require('../../assets/images/defaultImage.png');

const ImageCaptionCard = ({
    name,
    description,
    imageUrl,
    linkUrl,
    withControls,
    onEdit,
    onRemove,
    onClick,
    editPermissionAction,
    topRightImageUrl,
}) => {
    const { t } = useTranslation();

    const classes = useStyles(!!linkUrl);

    const linkWrapper = (children) =>
        linkUrl ? (
            <Link
                href={linkUrl}
                color="inherit"
                underline="none"
                target="_blank"
            >
                {children}
            </Link>
        ) : (
            children
        );

    const buttons = () => (
        <Grid
            container
            item
            direction="row"
            justify="flex-end"
            spacing={1}
            className={classes.linkControls}
        >
            <Grid item>
                <Button variant="outlined" color="gray" onClick={onEdit}>
                    <Edit fontSize="small" className={classes.buttonIcon} />
                    {t('edit')}
                </Button>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="gray" onClick={onRemove}>
                    {t('remove')}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <Grid container item direction="column" onClick={onClick}>
            {linkWrapper(
                <Grid
                    container
                    item
                    direction="row"
                    wrap="nowrap"
                    className={classes.linkItem}
                >
                    <Grid item className={classes.imageContainer}>
                        <img
                            src={imageUrl || defaultImageUrl}
                            alt="link"
                            width="100"
                            height="100"
                            className={classes.linkImage}
                        />
                        {topRightImageUrl && (
                            <img
                                src={topRightImageUrl}
                                width={30}
                                height={30}
                                className={classes.topRightImage}
                                alt="topRightImage"
                            />
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        className={classes.linkInfo}
                    >
                        <Grid item className={classes.linkName}>
                            {name}
                        </Grid>
                        <Grid item className={classes.linkDescription}>
                            {description}
                        </Grid>
                    </Grid>
                </Grid>,
            )}
            {withControls &&
                (editPermissionAction ? (
                    <Can I="edit" a={editPermissionAction}>
                        {() => buttons()}
                    </Can>
                ) : (
                    buttons()
                ))}
        </Grid>
    );
};

ImageCaptionCard.propTypes = {
    // The card's title
    name: PropTypes.string.isRequired,

    // The card's description
    description: PropTypes.string.isRequired,

    // The URL of the image for the card
    imageUrl: PropTypes.string.isRequired,

    // The permission necessary for editing this card
    editPermissionAction: PropTypes,

    // The url of this card's link
    linkUrl: PropTypes.string,

    // Should we show edit/remove buttons below the card if the user has permissions
    withControls: PropTypes.bool,

    // Function called when pressing the edit button
    onEdit: PropTypes.func,

    // Function called when clicking on the card
    onClick: PropTypes.func,

    // Function called when pressing the remove button
    onRemove: PropTypes.func,

    // Image that will be be shown in the top right corner on top of the main image
    topRightImageUrl: PropTypes.string,
};

ImageCaptionCard.defaultProps = {
    onEdit: noop,
    onRemove: noop,
    onClick: noop,
    linkUrl: '',
    withControls: false,
    editPermissionAction: '',
    topRightImageUrl: '',
};

export default ImageCaptionCard;
