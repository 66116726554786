const styles = (theme) => ({
    header: {
        margin: '20px 0 10px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '24px',
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    helpIcon: {
        marginLeft: '5px',
        color: theme.custom.iconColor,
        fontSize: '18px',
        '&:hover': {
            color: theme.custom.iconColorHover,
            cursor: 'pointer',
        },
    },
    button: {
        paddingRight: 0,
    },
    emptyText: {
        margin: '20px 0',
        fontSize: '14px',
        lineHeight: 1.1,
        fontWeight: '600',
        textAlign: 'center',
        opacity: 0.5,
    },
});

export default styles;
