import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    CssBaseline,
    Grid,
    isWidthDown,
    isWidthUp,
    withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import clsx from 'clsx';
import styles from './styles';
import { AbilityContext, Can } from '../../casl/ability-context';
import PageHeader from '../../common/PageHeader';
import LeftColumn from '../../common/LeftColumn';
import Footer from '../../common/Footer';
import MainHeader from '../../common/MainHeader';
import { accountPlanDataRequested } from '../../community/actions';
import { getAccountPlan } from '../../community/selectors';
import { isAuthenticated } from '../../auth/selectors';
import { setActiveCommunity } from '../../user/actions';
import { getActiveCommunityId, getUser } from '../../user/selectors';
import { useTranslation } from 'react-i18next';
import { handleCommunityIdQueryParam } from '../../utils';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import RequestLoader from '../../common/RequestLoader';

const MainScreen = ({
    classes,
    isAuth,
    accountPlanData,
    children,
    loading,
    width,
    user,
    communityId,
    changeCommunity,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [snackbarError, setSnackbarError] = useState('');
    const ability = useContext(AbilityContext);
    const history = useHistory();

    const [showFooter, setShowFooter] = useState(true);
    const [open, setOpen] = useState(isWidthUp('md', width));

    const toggleHandler = () => {
        setOpen(!open);
    };

    useEffect(() => {
        ability.can('view', 'AdminScreens') &&
            handleCommunityIdQueryParam(
                user.communityPermissions,
                communityId,
                changeCommunity,
                setSnackbarError,
                t,
                history,
            );
    }, [
        ability,
        user,
        communityId,
        changeCommunity,
        setSnackbarError,
        t,
        history,
        location.pathname,
    ]);

    useEffect(() => {
        switch (location.pathname) {
            case '/settings':
                setShowFooter(false);
                break;
            default:
                setShowFooter(true);
        }
    }, [location]);

    return (
        <Fragment>
            <RequestLoader isLoading={loading} />
            <CssBaseline />
            <PageHeader onClick={toggleHandler} />
            <LeftColumn isOpen={open} setIsOpen={setOpen} width={width} />
            {isWidthDown('xs', width) && open && (
                <div
                    className={classes.navOverlay}
                    onClick={() => setOpen(false)}
                />
            )}
            <Grid
                className={clsx({
                    [classes.openDrawer]: open,
                    [classes.hideDrawer]: !open,
                })}
            >
                <Grid item>
                    <Can I="view" a="BillingInfo">
                        <div className={classes.mainHeader}>
                            <MainHeader
                                isAuth={isAuth}
                                userCounterData={{
                                    accountType: accountPlanData?.accountType,
                                    currentUsers: accountPlanData?.currentUsers,
                                    maxUsers: accountPlanData?.maxUsers,
                                }}
                            />
                        </div>
                    </Can>
                    <main className={classes.content}>{children}</main>
                </Grid>
                {showFooter && (
                    <Grid item>
                        <Footer className={classes.footer} />
                    </Grid>
                )}
            </Grid>
            <SnackBarAPIResponse
                errors={snackbarError}
                clearResponse={() => setSnackbarError('')}
            />
        </Fragment>
    );
};

MainScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    isAuth: PropTypes.bool.isRequired,
    width: PropTypes.string.isRequired,
    accountPlanData: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    loading: PropTypes.bool,
    communityId: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    changeCommunity: PropTypes.func.isRequired,
};

MainScreen.defaultProps = {
    children: null,
    loading: false,
};

export const StyledMainScreen = withWidth()(withStyles(styles)(MainScreen));

const mapStateToProps = (state) => ({
    isAuth: isAuthenticated(state),
    accountPlanData: getAccountPlan(state),
    communityId: getActiveCommunityId(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchAccountPlan: (request) => dispatch(accountPlanDataRequested(request)),
    changeCommunity: (communityId) => dispatch(setActiveCommunity(communityId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StyledMainScreen);
