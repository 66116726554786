import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DATE_FORMAT } from '../constants';
import Tooltip from '../../../common/Tooltip';
import styles from './styles';

const ThrottlesCell = ({ classes, data }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Tooltip title={t('buzzThrottle.total')}>
                <Typography component="span" className={classes.text}>
                    {data.throttled.count}
                </Typography>
            </Tooltip>
            <br />
            <Grid container wrap="nowrap">
                <Tooltip title={t('buzzThrottle.perPin')}>
                    <Typography component="span" className={classes.text}>
                        {data.throttleActionHour.count}
                    </Typography>
                </Tooltip>
                <Tooltip title={t('buzzThrottle.35-15')}>
                    <Typography component="span" className={classes.text}>
                        {data.throttleActionTimesWithinMinutes.count}
                    </Typography>
                </Tooltip>
                <Tooltip title={t('buzzThrottle.100-8')}>
                    <Typography component="span" className={classes.text}>
                        {data.throttleActionDay.count}
                    </Typography>
                </Tooltip>
                <br />
                <Tooltip title={t('buzzThrottle.unique')}>
                    <Typography component="span" className={classes.text}>
                        {data.throttled.contentCount}
                    </Typography>
                </Tooltip>
            </Grid>

            <Tooltip title={t('buzzThrottle.last')}>
                <Typography component="span" noWrap className={classes.small}>
                    {moment(data.throttled.created).format(DATE_FORMAT)}
                </Typography>
            </Tooltip>
        </Fragment>
    );
};

ThrottlesCell.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.shape({
        throttled: PropTypes.object,
        throttleActionHour: PropTypes.object,
        throttleActionTimesWithinMinutes: PropTypes.object,
        throttleActionDay: PropTypes.object,
    }).isRequired,
};

export default withStyles(styles)(ThrottlesCell);
