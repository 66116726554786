export const DATE_FORMAT = 'MM/DD/YY';

export const CHARTS = [
    {
        name: 'Score Tracker',
        options: {
            isScoreTracker: true,
        },
        showAnimateButton: true,
    },
    {
        name: 'Total Logs',
        showAnimateButton: true,
    },
    {
        name: 'Users Activity',
        showAnimateButton: true,
    },
];
