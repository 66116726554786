import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { analyticsGraphDataRequested } from '../../../../analytics/actions';
import {
    getAnalyticsGraphData,
    isGraphDataLoading,
} from '../../../../analytics/selectors';
import BuzzIcon from '../../../../assets/images/buzz.png';
import CommunityIcon from '../../../../assets/images/community.png';
import PhotoIcon from '../../../../assets/images/photo.png';
import CarbonIcon from '../../../../assets/images/carbon.png';
import WaterIcon from '../../../../assets/images/water.png';
import WasteIcon from '../../../../assets/images/waste.png';
import theme from '../../../../theme';
import styles from './../styles';
import VisualItem from '../../../../common/DatePickerRange/VisualItem';
import { impactMetrics, metrics } from '../../constants';
import { prettyFormatNumber } from '../../../../common/transforms';
import { getResolution } from '../../../../analytics/utils';
import {
    getFeatureFlagValue,
    REMOTE_CONFIG_SHOW_HABITAT_SAVED_GRAPH,
    REMOTE_CONFIG_SHOW_IMPACT_GRAPHS,
} from '../../../../utils/remoteConfig';
import { Landscape } from '@material-ui/icons';
import {
    GRAPH_METRIC_ACTIVE_USERS,
    GRAPH_METRIC_IMPACT_CARBON,
    GRAPH_METRIC_IMPACT_HABITAT_SAVED,
    GRAPH_METRIC_IMPACT_WASTE,
    GRAPH_METRIC_IMPACT_WATER,
    GRAPH_METRIC_PHOTO_ADDED,
    GRAPH_METRIC_TOTAL_LOGS,
} from '../../../../common/Graph/ChartItem/constants';
import { getActiveCommunityId } from '../../../../user/selectors';

export const Graphs = ({
    classes,
    communityId,
    fetchGraphData,
    analyticsGraphData,
    isGraphLoading,
    startDate,
    endDate,
}) => {
    const { t } = useTranslation();

    const communityImpactMetrics = useMemo(() => {
        let metrics = [];
        if (getFeatureFlagValue(REMOTE_CONFIG_SHOW_IMPACT_GRAPHS)) {
            metrics = impactMetrics;
        }
        if (getFeatureFlagValue(REMOTE_CONFIG_SHOW_HABITAT_SAVED_GRAPH)) {
            metrics = [
                GRAPH_METRIC_IMPACT_CARBON,
                GRAPH_METRIC_IMPACT_HABITAT_SAVED,
            ];
        }
        return metrics;
    }, []);

    // Fetch Data
    useEffect(() => {
        const options = {
            startDateIso: startDate.toISOString(),
            endDateIso: endDate.toISOString(),
            scope: 'organization',
            resolution: getResolution(startDate, endDate),
        };
        fetchGraphData(options, communityId, metrics);
        if (communityImpactMetrics?.length > 0) {
            fetchGraphData(
                { ...options, singleRequest: true },
                communityId,
                communityImpactMetrics,
            );
        }
    }, [
        startDate,
        endDate,
        communityId,
        fetchGraphData,
        communityImpactMetrics,
    ]);

    const graphType = useCallback(
        (metric) => {
            const dataMap = {
                [GRAPH_METRIC_TOTAL_LOGS]: 'Actions',
                [GRAPH_METRIC_ACTIVE_USERS]: 'Engagement',
                [GRAPH_METRIC_PHOTO_ADDED]: 'Engagement',
                [GRAPH_METRIC_IMPACT_CARBON]: 'Sustainability',
                [GRAPH_METRIC_IMPACT_WASTE]: 'Sustainability',
                [GRAPH_METRIC_IMPACT_WATER]: 'Sustainability',
                [GRAPH_METRIC_IMPACT_HABITAT_SAVED]: 'Sustainability',
            };

            return t(dataMap[metric]);
        },
        [t],
    );

    const graphIcon = useCallback(
        (metric) => {
            if (metric === GRAPH_METRIC_IMPACT_HABITAT_SAVED) {
                return <Landscape />;
            }
            const icons = {
                [GRAPH_METRIC_TOTAL_LOGS]: BuzzIcon,
                [GRAPH_METRIC_ACTIVE_USERS]: CommunityIcon,
                [GRAPH_METRIC_PHOTO_ADDED]: PhotoIcon,
                [GRAPH_METRIC_IMPACT_CARBON]: CarbonIcon,
                [GRAPH_METRIC_IMPACT_WASTE]: WasteIcon,
                [GRAPH_METRIC_IMPACT_WATER]: WaterIcon,
            };
            return (
                <img
                    className={classes.visualItemIcon}
                    src={icons[metric]}
                    alt={metric}
                />
            );
        },
        [classes],
    );

    const graphColors = useCallback((type) => {
        const colors = {
            Actions: {
                flagColor: theme.custom.bgWellness,
                flagRGBColor: theme.custom.bgChartWellness,
            },
            Engagement: {
                flagColor: theme.custom.bgEngagement,
                flagRGBColor: theme.custom.bgChartEngagement,
            },
            Sustainability: {
                flagColor: theme.custom.bgSustainability,
                flagRGBColor: theme.custom.bgSustainability,
            },
        };

        return colors[type];
    }, []);

    const graphData = useMemo(() => {
        // Start with template data so we can show a pretty loading screen
        let data = [...metrics, ...communityImpactMetrics].map((metric) => {
            const newGraphType = graphType(metric);
            return {
                metric,
                ...graphColors(newGraphType),
                type: newGraphType,
                Icon: graphIcon(metric),
                loading: true,
            };
        });

        data = data.map((data) => {
            if (Object.keys(analyticsGraphData).includes(data.metric)) {
                const dataset = analyticsGraphData[data.metric];
                const total =
                    dataset.roundedTotal ||
                    dataset.data?.[0]?.reduce((value, sum) => sum + value) ||
                    0;
                const totalWithSuffix = prettyFormatNumber(total, 0);
                const formattedTotal = `${totalWithSuffix.value}${totalWithSuffix.weight} ${dataset.series?.[0]?.name}`;
                const newLabels = dataset.labels?.map((label) => {
                    return moment(label).format('MM.DD.YYYY');
                });
                return {
                    ...data,
                    dataset,
                    ...analyticsGraphData[data.metric],
                    total: formattedTotal,
                    series: [
                        {
                            name: dataset.series?.[0]?.name,
                            style: 'solid',
                            data: dataset.data?.[0],
                            labels: newLabels,
                        },
                    ],
                    loading: isGraphLoading[data.metric],
                };
            }
            return null;
        });
        // Remove the graph if it fails to load
        data = data?.filter(
            (data) => data?.loading || data?.series?.length > 0,
        );
        return data;
    }, [
        analyticsGraphData,
        isGraphLoading,
        graphColors,
        graphIcon,
        graphType,
        communityImpactMetrics,
    ]);

    const isGraphDataEmpty = useMemo(
        () =>
            Object.values(graphData).filter(
                (item) => item.data.length > 0 || item.loading,
            ).length === 0,
        [graphData],
    );

    return (
        <Grid container direction="row" justify="flex-start" spacing={3}>
            {graphData.map((graphObject) => {
                const {
                    key,
                    title,
                    type,
                    flagColor,
                    flagRGBColor,
                    Icon,
                    total,
                    xAxisLabel,
                    yAxisLabel,
                    series,
                    loading,
                } = graphObject;
                return (
                    <Grid
                        key={key}
                        className={classes.graphContainer}
                        item
                        lg={4}
                        md={6}
                        sm={6}
                        xs={12}
                    >
                        <VisualItem
                            type={type}
                            title={title}
                            description=""
                            flagColor={flagColor}
                            flagRGBColor={flagRGBColor}
                            Icon={Icon}
                            total={total}
                            goal=""
                            xAxisLabel={xAxisLabel}
                            yAxisLabel={yAxisLabel}
                            series={series}
                            loading={loading}
                        />
                    </Grid>
                );
            })}
            {isGraphDataEmpty && (
                <Grid item xs={12}>
                    <div className={classes.placeholderTextContainer}>
                        <Typography className={classes.placeholderText}>
                            {t('data.empty')}
                        </Typography>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    analyticsGraphData: getAnalyticsGraphData(state),
    isGraphLoading: isGraphDataLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchGraphData: (options, communityId, metrics) =>
        dispatch(analyticsGraphDataRequested(options, communityId, metrics)),
});

Graphs.propTypes = {
    communityId: PropTypes.string.isRequired,
    fetchGraphData: PropTypes.func.isRequired,
    analyticsGraphData: PropTypes.object.isRequired,
    isGraphLoading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

const StyledGraphs = withStyles(styles)(Graphs);

export default connect(mapStateToProps, mapDispatchToProps)(StyledGraphs);
