import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Grid, Typography } from '@material-ui/core';
import ConfirmModal from '../ConfirmModal';
import styles from './styles';
import { getAppImages } from '../../utils';

const Delete = ({
    classes,
    isOpen,
    deleteWhat,
    item,
    description,
    onClose,
    onCancel,
    onConfirm,
    confirmMessage,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <ConfirmModal
            title={`${t('common:delete')} ${deleteWhat}`}
            isOpen={isOpen}
            confirmBtnText={t('prompt.delete.descriptor')}
            confirmBtnType="danger"
            cancelBtnText="Cancel"
            cancelBtnType="default"
            onClose={onClose}
            onCancel={onCancel}
            onConfirm={onConfirm}
            {...props}
        >
            <Grid container direction="column">
                <Grid item className={classes.confirmMessage}>
                    {confirmMessage || (
                        <Typography className={classes.confirmMessage}>
                            {t('prompt.delete.firstWarn')}{' '}
                            <strong>{deleteWhat}</strong>?{' '}
                            {t('prompt.delete.lastWarn')}
                        </Typography>
                    )}
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    wrap="nowrap"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <img
                                alt=""
                                src={
                                    item?.iconUrl ||
                                    getAppImages().defaultProfileIcon
                                }
                                width={40}
                                height={40}
                                className={
                                    item?.isChallenge && classes.borderIcon
                                }
                            />
                        </Grid>
                        <Grid item>{item?.name}</Grid>
                    </Grid>
                    <Grid item className={classes.identifier}>
                        {item?.identifier}
                    </Grid>
                </Grid>
                <span className={classes.description}>{description}</span>
            </Grid>
        </ConfirmModal>
    );
};

Delete.propTypes = {
    confirmMessage: PropTypes.string,
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    deleteWhat: PropTypes.string.isRequired,
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        iconUrl: PropTypes.string.isRequired,
        identifier: PropTypes.string.isRequired,
        isChallenge: PropTypes.bool,
    }).isRequired,
    description: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

Delete.defaultProps = {
    confirmMessage: null,
};
export default withStyles(styles)(Delete);
