import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getActiveCommunityId } from '../../../user/selectors';
import {
    arePhotosLoading,
    getTopPhotos,
} from '../../../analytics/photos/selectors';
import { communityPhotosRequested } from '../../../analytics/photos/actions';
import styles from './styles';
import ProTips from '../../../common/ProTips';
import TopPhotos from '../../../common/TopPhotos';
import DatePickerRange from '../../../common/DatePickerRange';
import { PHOTOS_BATCH_SIZE } from '../../../common/TopPhotos/constants';
import ActiveChallenges from '../../../common/ActiveChallenges';
import CreateChallengeBanner from '../../../common/CreateChallengeBanner';
import ActivateModal from '../../../common/ActivateModal';
import CommonPropTypes from '../../../common/propTypes';
import {
    getChallengesScreenActionErrors,
    getChallengesScreenActionResponse,
    getGroupChallenges,
    isChallengesScreenActionLoading,
    isGroupLoading,
} from '../../ChallengesScreen/selectors';
import {
    getHeadlinePhotoUrl,
    isChallengeLoading,
} from '../../../challenges/selectors';
import {
    challengeActivationRequested,
    challengeDeactivationRequested,
    challengeReportRequested,
    challengesRequested,
    clearChallengesScreenActionErrors,
    viewChallengeRequested,
} from '../../../challenges/actions';
import {
    BUTTONS_CELL,
    DEACTIVATE_ACTION,
    GENERATE_REPORT_ACTION,
    GROUP_ACTIVE,
    GROUP_UPCOMING,
} from '../../ChallengesScreen/constants';
import { handleButtonsCell } from '../../../utils/challengeButtons';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';
import { noop } from '../../../common/utils';
import { trackEvent } from '../../../utils/analytics';
import ContentLoader from 'react-content-loader';
import { HOME_SCREEN } from '../../../common/PhotoCard/constants';
import { getAppName } from '../../../utils';
import Graphs from './Graphs';

import AccountTierNotificationCard from '../../../common/AccountTierNotificationCard';
import { getCurrentSubscription } from '../../../subscriptions/selectors';
import { shouldShowSubscription } from '../../../subscriptions/utils';
import { Can } from '../../../casl/ability-context';
import { ANALYTICS_FILTER_CHANGED } from '../../../constants/analyticsEvents';

export const AdminHome = ({
    topPhotos,
    fetchTopPhotos,
    classes,
    communityId,
    isLoading,
    isLoadingActive,
    isLoadingUpcoming,
    actionResponse,
    loadFunc,
    activeChallenge,
    upcomingChallenge,
    requestDeactivation,
    requestChallengeReport,
    isActionLoading,
    actionErrors,
    clearErrors,
    requestChallenge,
    challengeCoverPhotoUrl,
    isPhotosLoading,
    accountPlanData,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [modalDetails, setModalDetails] = useState(null);
    const [isActionSent, setActionSent] = useState(false);
    const [tip, setTip] = useState('');
    const [haveRequestedChallenges, setHaveRequestedChallenges] = useState(
        false,
    );

    const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
    const [endDate, setEndDate] = useState(moment());

    const fetchPhotoPayload = useCallback(() => {
        return {
            afterTimestamp: startDate?.unix(),
            beforeTimestamp: endDate?.unix(),
            limit: PHOTOS_BATCH_SIZE,
            skip: 0,
            communityId,
        };
    }, [communityId, startDate, endDate]);

    const handleDateChange = (values) => {
        trackEvent(ANALYTICS_FILTER_CHANGED);
        setStartDate(values.startDate);
        setEndDate(values.endDate);
    };

    useEffect(() => {
        fetchTopPhotos(fetchPhotoPayload());

        const proTips = t('proTips.tips', { returnObjects: true });
        const randomProTip =
            proTips[Math.floor(Math.random() * proTips.length)];
        setTip(randomProTip.tip);
    }, [t, fetchTopPhotos, fetchPhotoPayload]);

    const loadActiveChallenges = useCallback(() => {
        loadFunc({
            communityId,
            group: GROUP_ACTIVE,
        });
    }, [loadFunc, communityId]);

    const loadUpcomingChallenges = useCallback(() => {
        loadFunc({
            communityId,
            group: GROUP_UPCOMING,
        });
    }, [loadFunc, communityId]);

    useEffect(() => {
        loadActiveChallenges();
        loadUpcomingChallenges();
    }, [loadActiveChallenges, loadUpcomingChallenges]);

    useEffect(() => {
        if (activeChallenge?.[0]) {
            const challenge = activeChallenge[0];
            requestChallenge({ challengeId: challenge.id, communityId });
        }
        if (upcomingChallenge?.[upcomingChallenge.length - 1]) {
            const challenge = upcomingChallenge[upcomingChallenge.length - 1];
            requestChallenge({ challengeId: challenge.id, communityId });
        }
        setHaveRequestedChallenges(true);
    }, [activeChallenge, upcomingChallenge, requestChallenge, communityId]);

    useEffect(() => {
        if (
            isActionSent &&
            !isActionLoading &&
            actionErrors == null &&
            actionResponse != null
        ) {
            loadActiveChallenges();
            setActionSent(false);
        }
    }, [
        isActionSent,
        isActionLoading,
        actionErrors,
        actionResponse,
        loadActiveChallenges,
    ]);

    const handleCloseModal = () => {
        modalDetails && setModalDetails({ ...modalDetails, isOpen: false });
    };

    const handleConfirm = () => {
        handleCloseModal();
        const challengeId = modalDetails.items[0].identifier;
        const requestBody = {
            communityId,
            challengeId,
        };
        switch (modalDetails.action) {
            case DEACTIVATE_ACTION:
                requestDeactivation(requestBody);
                setActionSent(true);
                break;
            case GENERATE_REPORT_ACTION:
                requestChallengeReport(challengeId);
                break;
            default:
                break;
        }
    };

    const onButtonsCell = (id, key) => {
        const challenge = activeChallenge.find((item) => item.id === id);
        handleButtonsCell(
            id,
            key,
            challenge?.name,
            challenge?.icon?.[0]?.url,
            history,
            setModalDetails,
            t,
        );
    };

    return (
        <Grid container className={classes.container}>
            {modalDetails && (
                <ActivateModal
                    {...modalDetails}
                    onClose={handleCloseModal}
                    onCancel={handleCloseModal}
                    onConfirm={handleConfirm}
                />
            )}
            <Can I="view" a="BillingInfo">
                {shouldShowSubscription() && (
                    <AccountTierNotificationCard
                        accountPlanData={accountPlanData}
                    />
                )}
            </Can>
            <Grid
                item
                container
                direction="column"
                justify="center"
                className={classes.top}
            >
                <Typography variant="h6" className={classes.title}>
                    {`${getAppName()} ${t('adminDash')}`}
                </Typography>
                <Typography className={classes.text}>
                    {t('welcomeManage')}
                </Typography>
            </Grid>
            {isLoading ||
            isLoadingActive ||
            isLoadingUpcoming ||
            !haveRequestedChallenges ? (
                <Grid
                    container
                    direction="column"
                    className={classes.activeChallengeWrapper}
                >
                    <ContentLoader
                        speed={2}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        className={classes.contentLoaderContainer}
                    >
                        <rect
                            x="0"
                            y="0"
                            rx="2"
                            ry="2"
                            width="22%"
                            height="28"
                        />
                        <rect
                            x="0"
                            y="60"
                            rx="2"
                            ry="2"
                            width="100%"
                            height="360"
                        />
                    </ContentLoader>
                </Grid>
            ) : challengeCoverPhotoUrl !== null && activeChallenge?.[0] ? (
                <div className={classes.activeChallengeWrapper}>
                    <ActiveChallenges
                        activityTitle={t('activeChallenges.activeChallenge')}
                        defaultCoverImageUrl={challengeCoverPhotoUrl}
                        buttonsCell={BUTTONS_CELL[GROUP_ACTIVE]}
                        onButtonsCell={onButtonsCell}
                        activityItem={activeChallenge?.[0] || {}}
                        isLoading={isLoadingActive}
                    />
                </div>
            ) : challengeCoverPhotoUrl !== null && upcomingChallenge?.[0] ? (
                <div className={classes.activeChallengeWrapper}>
                    <ActiveChallenges
                        activityTitle={t(
                            'upcomingChallenges.upcomingChallenge',
                        )}
                        defaultCoverImageUrl={challengeCoverPhotoUrl}
                        buttonsCell={BUTTONS_CELL[GROUP_UPCOMING]}
                        onButtonsCell={onButtonsCell}
                        activityItem={
                            upcomingChallenge?.[upcomingChallenge.length - 1] ||
                            {}
                        }
                        isLoading={isLoadingUpcoming}
                    />
                </div>
            ) : (
                <div className={classes.activeChallengeWrapper}>
                    <CreateChallengeBanner />
                </div>
            )}

            <Grid item container className={classes.proTipsSection}>
                <ProTips tip={tip} />
            </Grid>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    direction="row"
                    className={classes.analyticsDatePickerContainer}
                    container
                >
                    <Grid container alignItems="center" xs={12} sm={6}>
                        <Typography className={classes.header} variant="h6">
                            {t('impact.homeHeader')}
                        </Typography>
                    </Grid>
                    <Grid container xs={12} sm={6} justify="flex-end">
                        <DatePickerRange
                            startDate={startDate}
                            endDate={endDate}
                            handleDateChange={handleDateChange}
                        />
                    </Grid>
                </Grid>
                <Graphs startDate={startDate} endDate={endDate} />
            </Grid>

            <Grid item container className={classes.photoSection}>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        className={classes.photoSectionTitle}
                    >
                        {t('Engagement')}
                    </Typography>
                </Grid>
                {!isPhotosLoading ? (
                    <Grid item xs={12}>
                        {topPhotos?.length !== 0 ? (
                            <TopPhotos
                                noHeader
                                photos={topPhotos}
                                screen={HOME_SCREEN}
                            />
                        ) : (
                            <div className={classes.emptyTextContainer}>
                                <Typography className={classes.emptyText}>
                                    {t('engagement.emptyPlaceholder')}
                                </Typography>
                            </div>
                        )}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <div className={classes.emptyTextContainer}>
                            <Typography className={classes.emptyText}>
                                {t('engagement.loading')}
                            </Typography>
                        </div>
                    </Grid>
                )}
            </Grid>
            <SnackBarAPIResponse
                messages={actionResponse}
                errors={actionErrors}
                clearResponse={clearErrors}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isLoading: isChallengeLoading(state),
    isLoadingActive: isGroupLoading(state, GROUP_ACTIVE),
    isLoadingUpcoming: isGroupLoading(state, GROUP_UPCOMING),
    isActionLoading: isChallengesScreenActionLoading(state),
    actionErrors: getChallengesScreenActionErrors(state),
    actionResponse: getChallengesScreenActionResponse(state),
    activeChallenge: getGroupChallenges(state, GROUP_ACTIVE),
    upcomingChallenge: getGroupChallenges(state, GROUP_UPCOMING),
    challengeCoverPhotoUrl: getHeadlinePhotoUrl(state),
    topPhotos: getTopPhotos(state),
    isPhotosLoading: arePhotosLoading(state),
    accountPlanData: getCurrentSubscription(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadFunc: (request) => dispatch(challengesRequested(request)),
    requestActivation: (request) =>
        dispatch(challengeActivationRequested(request)),
    requestDeactivation: (request) =>
        dispatch(challengeDeactivationRequested(request)),
    requestChallengeReport: (challengeId) =>
        dispatch(challengeReportRequested(challengeId)),
    clearErrors: () => dispatch(clearChallengesScreenActionErrors()),
    requestChallenge: ({ challengeId, communityId }) =>
        dispatch(viewChallengeRequested({ challengeId, communityId })),
    fetchTopPhotos: (request) => dispatch(communityPhotosRequested(request)),
});

AdminHome.propTypes = {
    communityId: PropTypes.string.isRequired,
    topPhotos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            actionUrl: PropTypes.string,
            likes: PropTypes.array,
            comments: PropTypes.array,
        }),
    ),
    fetchTopPhotos: PropTypes.func.isRequired,
    isPhotosLoading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingActive: PropTypes.bool.isRequired,
    isLoadingUpcoming: PropTypes.bool.isRequired,
    actionResponse: PropTypes.object,
    loadFunc: PropTypes.func.isRequired,
    activeChallenge: PropTypes.array,
    upcomingChallenge: PropTypes.array,
    requestDeactivation: PropTypes.func.isRequired,
    requestChallengeReport: PropTypes.func.isRequired,
    isActionLoading: PropTypes.bool.isRequired,
    actionErrors: CommonPropTypes.errors,
    clearErrors: PropTypes.func.isRequired,
    challengeCoverPhotoUrl: PropTypes.string,
    requestChallenge: PropTypes.func.isRequired,
    accountPlanData: PropTypes.array,
};

AdminHome.defaultProps = {
    actionResponse: null,
    loadFunc: noop,
    tableData: null,
    actionErrors: null,
    activeChallenge: null,
    upcomingChallenge: null,
    challengeCoverPhotoUrl: null,
    topPhotos: [],
    fetchSubscriptionDetails: noop,
    groupId: '',
    accountPlanData: [],
};

const StyledAdminHome = withStyles(styles)(AdminHome);

export default connect(mapStateToProps, mapDispatchToProps)(StyledAdminHome);
