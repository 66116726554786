export const GROUP_KEY_INDIVIDUAL = 'individual';
export const GROUP_KEY_TEAM = 'team';

export const DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';

export const GROUP_INDIVIDUAL = 'individual';
export const GROUP_TEAM = 'team';
export const GROUP_KEYS = {
    [GROUP_INDIVIDUAL]: GROUP_KEY_INDIVIDUAL,
    [GROUP_TEAM]: GROUP_KEY_TEAM,
};

export const TEAMS_TYPE_MANUAL = 'manual';
export const TEAMS_TYPE_USER_CREATED = 'user_created';

export const COMPETITION_COMPETITIVE = 'Competitive';
export const COMPETITION_GOALS = 'Goals';

export const GOALS_TYPE_USER = 'user';
export const GOALS_TYPE_TEAM = 'team';
export const GOALS_TYPE_CHALLENGE = 'challenge';

export const MIN_NAME_LENGTH = 3;
export const MAX_NAME_LENGTH = 60;

export const MAX_TEAM_COUNT = 999;
export const MIN_TEAM_COUNT = 2;
