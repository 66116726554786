import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, Typography, withStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles';

const ModalFooter = ({ classes, linkText, onLinkClick, accessCode }) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            {accessCode && (
                <>
                    <Typography variant="body2">
                        {t('usersPage.inviteModal.footerAccessCode')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.accessCode}
                        data-intercom-target="Invite Modal Footer Access Code"
                    >
                        {accessCode}
                    </Typography>
                </>
            )}
            <Typography
                variant="body2"
                data-intercom-target="Invite Modal Footer Manual Invite Container"
            >
                <Trans t={t} i18nKey={linkText}>
                    <Link
                        onClick={() => onLinkClick()}
                        className={classes.link}
                    />
                </Trans>
            </Typography>
        </Grid>
    );
};

ModalFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    linkText: PropTypes.string.isRequired,
    onLinkClick: PropTypes.func.isRequired,
    accessCode: PropTypes.string.isRequired,
};

export default withStyles(styles)(ModalFooter);
