import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#f7f7f7',
        padding: 15,
    },
    innerContainer: {
        color: theme.palette.primary.main,
    },
    participantsRow: {
        marginBottom: 20,
    },
    participantsFieldContainer: {
        flex: 1,
        marginRight: 10,
    },
    participantsField: {
        textAlign: 'center',
    },
    inputLabel: {
        color: theme.palette.primary.main,
        lineHeight: 1,
        fontSize: 'clamp(12px, 1vw, 16px)',
        marginBottom: 5,
        fontWeight: '500',
    },
    calculateButton: {
        flex: 1,
        height: 40,
        marginLeft: 10,
        marginBottom: 5,
    },
    costDollars: ({ digits }) => ({
        fontSize: digits > 3 ? 30 : 36,
        lineHeight: 1,
        fontWeight: '700',
    }),
    costSuffix: ({ digits }) => ({
        fontSize: digits > 3 ? 12 : 16,
        lineHeight: 1.2,
        fontWeight: '700',
    }),
}));

export default useStyles;
