import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import {
    challengeSearchRequested,
    setChallenge,
    userChallengesRequested,
} from '../../../challenges/actions';
import { getActiveCommunityId } from '../../../user/selectors';
import SearchBar from '../../../common/Search';
import { getAppImages, noop } from '../../../utils';
import { isGroupLoading } from '../selectors';
import ActionCard from '../../../common/ActionCard';
import { dateDifferenceInLargestUnits } from '../../../utils/dates';
import { useTrackScreenView } from '../../../utils/analytics';
import InfoCardHeader from '../../../common/InfoCardHeader';
import { INFO_CARD_USER_CHALLENGE } from '../../../common/InfoCard/constants';
import {
    getAvailableChallenges,
    getSearchedChallenges,
    getUserPreviousChallenges,
    getUserSubscribedChallenges,
    isChallengesLoading,
} from '../../../challenges/selectors';
import { RequestLoader } from '../../../common/RequestLoader';

const CHALLENGE_DEFAULT_SECTION = 'default';
const CHALLENGE_AVAILABLE_SECTION = 'available';
const CHALLENGE_PREVIOUS_SECTION = 'previous';

const ChallengesScreen = ({
    classes,
    loadAllChallengesFunc,
    communityId,
    isAllChallengesLoading,
    availableChallenges,
    subscribedChallenges,
    previousChallenges,
    setChallenge,
    searchChallenges,
    searchedChallenges,
    searchLoading,
}) => {
    const { t } = useTranslation();

    const [challenges, setChallenges] = useState(() => ({
        [CHALLENGE_DEFAULT_SECTION]: [],
        [CHALLENGE_AVAILABLE_SECTION]: [],
        [CHALLENGE_PREVIOUS_SECTION]: [],
    }));
    const [searchText, setSearchText] = useState('');

    useTrackScreenView('challenge_view_overview');

    const loadChallenges = useCallback(() => {
        loadAllChallengesFunc({
            communityId,
        });
    }, [loadAllChallengesFunc, communityId]);

    const formatChallengeEndDateDetails = useCallback(
        (endDate) => {
            const currentDate = moment();
            const challengeEnded = currentDate.unix() > moment(endDate).unix();
            return challengeEnded
                ? `${t('challengeEndedOn')} ${moment(endDate).format(
                      'MM/DD/YY',
                  )}`
                : `${t(
                      'challenge.challengeEndsIn',
                  )} ${dateDifferenceInLargestUnits(
                      currentDate.toDate(),
                      moment(endDate).toDate(),
                  )}`;
        },
        [t],
    );

    const initFilteredItems = useCallback(() => {
        setChallenges((prevState) => {
            return {
                ...prevState,
                [CHALLENGE_DEFAULT_SECTION]: subscribedChallenges,
            };
        });
        setChallenges((prevState) => {
            return {
                ...prevState,
                [CHALLENGE_AVAILABLE_SECTION]: availableChallenges,
            };
        });
        setChallenges((prevState) => {
            return {
                ...prevState,
                [CHALLENGE_PREVIOUS_SECTION]: previousChallenges,
            };
        });
    }, [availableChallenges, subscribedChallenges, previousChallenges]);

    useEffect(() => {
        loadChallenges();
    }, [loadChallenges]);

    useEffect(() => {
        initFilteredItems();
    }, [initFilteredItems]);

    useEffect(() => {
        if (searchedChallenges) {
            const subscribed = [];
            const available = [];
            const previous = [];
            searchedChallenges.forEach((challenge) => {
                if (challenge.participationStatus === 'joined')
                    subscribed.push(challenge);
                else if (challenge.endDate < Date.now())
                    previous.push(challenge);
                else available.push(challenge);
            });
            setChallenges((prevState) => {
                return {
                    ...prevState,
                    [CHALLENGE_DEFAULT_SECTION]: subscribed,
                };
            });
            setChallenges((prevState) => {
                return {
                    ...prevState,
                    [CHALLENGE_AVAILABLE_SECTION]: available,
                };
            });
            setChallenges((prevState) => {
                return {
                    ...prevState,
                    [CHALLENGE_PREVIOUS_SECTION]: previous,
                };
            });
        }
    }, [searchedChallenges]);

    // ensures we don't recreate the debounce object on rerender
    const debouncedResults = useMemo(() => {
        const onSearch = (text) => {
            setSearchText(text);
            if (text) {
                searchChallenges(text);
            } else {
                initFilteredItems();
            }
        };
        return debounce(onSearch, 300);
    }, [initFilteredItems, searchChallenges]);

    // cleanup any pending debounce calls if the component unmounts
    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <Grid container className={classes.container}>
            <InfoCardHeader
                infoCardId={INFO_CARD_USER_CHALLENGE}
                image={getAppImages().welcomeChallenges}
                pageTitle={t('challenge_plural')}
            >
                <Typography variant="h2" className={classes.bannerTitle}>
                    {t('challenge.infoCard.title')}
                </Typography>
                <Typography className={classes.bannerBody}>
                    {t('challenge.infoCard.description')}
                </Typography>
            </InfoCardHeader>
            <Grid item xs={12}>
                <Grid item xs={12} className={classes.searchContainer}>
                    <SearchBar
                        placeholder={t('challenge.search')}
                        handleTextChange={debouncedResults}
                        runSearch={debouncedResults}
                    />
                    <RequestLoader
                        isLoading={isAllChallengesLoading || searchLoading}
                        title={t('challenge.loading')}
                    />
                </Grid>
                {!isAllChallengesLoading &&
                    !searchLoading &&
                    challenges.default?.length === 0 &&
                    challenges.available.length === 0 &&
                    challenges.previous.length === 0 && (
                        <Typography className={classes.noChallengesText}>
                            {searchText
                                ? t('challenge.noChallengesSearch')
                                : t('challenge.noChallenges')}
                        </Typography>
                    )}
                {!searchLoading && challenges.default?.length > 0 && (
                    <Grid container className={classes.challengeSection}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                component="h5"
                                className={classes.challengeHeading}
                            >
                                {t('challenge.yourChallenges')}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            {challenges.default?.map((challenge) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={challenge.id}
                                    onClick={() => setChallenge(challenge)}
                                >
                                    <ActionCard
                                        name={challenge.name}
                                        description={formatChallengeEndDateDetails(
                                            challenge.endDate,
                                        )}
                                        iconImageURL={challenge.iconUrl}
                                        backgroundImageURL={
                                            challenge.backgroundImageUrl
                                        }
                                        actionLink={`/challenges/${challenge.id}`}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}

                {!searchLoading && challenges.available?.length > 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                component="h5"
                                className={classes.challengeHeading}
                            >
                                {t('challenge.availableChallenges')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            {challenges.available?.map((challenge) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={challenge.id}
                                    onClick={() => setChallenge(challenge)}
                                >
                                    <ActionCard
                                        name={challenge.name}
                                        description={formatChallengeEndDateDetails(
                                            challenge.endDate,
                                        )}
                                        iconImageURL={challenge.iconUrl}
                                        backgroundImageURL={
                                            challenge.backgroundImageUrl
                                        }
                                        actionLink={`/challenges/${challenge.id}`}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}

                {!searchLoading && challenges.previous?.length > 0 && (
                    <Grid container className={classes.challengeSection}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                component="h5"
                                className={classes.challengeHeading}
                            >
                                {t('challenge.previousChallenges')}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            {challenges.previous?.map((challenge) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={challenge.id}
                                    onClick={() => setChallenge(challenge)}
                                >
                                    <ActionCard
                                        name={challenge.name}
                                        description={formatChallengeEndDateDetails(
                                            challenge.endDate,
                                        )}
                                        iconImageURL={challenge.iconUrl}
                                        backgroundImageURL={
                                            challenge.backgroundImageUrl
                                        }
                                        actionLink={`/challenges/${challenge.id}`}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

ChallengesScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    availableChallenges: PropTypes.object.isRequired,
    subscribedChallenges: PropTypes.object.isRequired,
    previousChallenges: PropTypes.object.isRequired,
    loadAllChallengesFunc: PropTypes.func.isRequired,
    communityId: PropTypes.string.isRequired,
    isAllChallengesLoading: PropTypes.bool.isRequired,
    setChallenge: PropTypes.func.isRequired,
    searchChallenges: PropTypes.func.isRequired,
    searchedChallenges: PropTypes.array,
    searchLoading: PropTypes.bool.isRequired,
};

ChallengesScreen.defaultProps = {
    loadAllChallengesFunc: noop,
    isAllChallengesLoading: false,
    isAvailableChallengesLoading: false,
    communityId: '',
    searchedChallenges: [],
};

const StyledChallengesScreen = withStyles(styles)(ChallengesScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    availableChallenges: getAvailableChallenges(state),
    subscribedChallenges: getUserSubscribedChallenges(state),
    previousChallenges: getUserPreviousChallenges(state),
    isAllChallengesLoading: isGroupLoading(state, 'all'),
    isAvailableChallengesLoading: isGroupLoading(state, 'available'),
    searchedChallenges: getSearchedChallenges(state),
    searchLoading: isChallengesLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    setChallenge: (challenge) => dispatch(setChallenge(challenge?.id)),
    loadAllChallengesFunc: (request) =>
        dispatch(userChallengesRequested(request)),
    searchChallenges: (searchString) =>
        dispatch(challengeSearchRequested(searchString)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledChallengesScreen);
