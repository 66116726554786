const styles = (theme) => ({
    container: {
        paddingBottom: '32px',
    },
    editingBanner: {},
    main: {
        maxWidth: theme.custom.mainContentWidth,
        width: '100%',
        paddingLeft: theme.custom.mainContentHorizontalPadding,
        paddingRight: theme.custom.mainContentHorizontalPadding,
    },
    controls: {
        marginTop: '26px',
    },
    infoButtonRoot: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '12px',
        paddingLeft: '12px',
        borderRadius: '8px',
    },
    infoIcon: {
        color: theme.palette.primary.main,

        '&:hover': {
            cursor: 'pointer',
        },
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
    },
    button: {
        flexGrow: 1,
        fontSize: '18px',
        borderRadius: '8px',
    },
    buttonIcon: {
        marginRight: '3px',
    },
    banner: {
        marginTop: 26,
    },
    buttonDiscard: {
        flexGrow: 1,
        fontSize: '18px',
        border: '2px',
        borderStyle: 'solid',
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        borderRadius: '8px',
        '&:disabled': {
            color: theme.palette.error.main,
        },
    },
    buttonOutlined: {
        flexGrow: 1,
        fontSize: '18px',
        border: '2px',
        borderStyle: 'solid',
        borderRadius: '8px',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:disabled': {
            border: '2px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },
    confirmationText: {
        marginBottom: '30px',
        fontSize: '14px',
    },
    infoText: {
        color: theme.custom.textDarkSecondaryMutedColor,
        fontSize: '14px',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export default styles;
