const styles = (theme) => ({
    container: {
        padding: 0,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '3px',
    },
    header: {
        padding: '10px 30px',
        background: theme.palette.primary.main,
        color: theme.palette.white.main,
    },
    clickable: {
        cursor: 'pointer',
        textDecoration: 'underline',
        width: 'fit-content',
    },
    content: {
        padding: '20px 30px',
    },
    billingSection: {
        paddingTop: '50px',
    },
    download: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

export default styles;
