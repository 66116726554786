import makeStyles from '@material-ui/styles/makeStyles';

const invertFilter = {
    red:
        'invert(22%) sepia(66%) saturate(2254%) hue-rotate(355deg) brightness(100%) contrast(126%);',
};

export default makeStyles((theme) => ({
    container: ({ borderColor }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        padding: '30px',
        background: theme.custom.grey93,
        [theme.breakpoints.down('md')]: {
            padding: '20px',
        },
        border: `solid 2px ${borderColor || theme.custom.red}`,
    }),
    closeButtonWrapper: {
        width: '100%',
        height: '15px',
        display: 'flex',
        justifyContent: 'flex-end',
        zIndex: '2',
        top: '25px',
        right: '25px',
        position: 'relative',
    },

    closeButton: {
        cursor: 'pointer',
    },
    logoSection: {
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginRight: 'auto',
            marginLeft: 'inherit',
        },
    },

    logo: ({ logoColor }) => ({
        flex: 1,
        height: 'auto',
        filter: `${logoColor ? invertFilter[logoColor] : 'none'}`,
    }),

    tipTitleSection: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        minWidth: '59px',
    },
    tipTitle: {
        display: 'flex',
        fontWeight: '700',
        '& span': {
            color: theme.palette.primary.main,
            marginLeft: '5px',
        },
        marginBottom: '5px',
    },
    tipContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },

    actionWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
}));
