import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import ContentLoader from 'react-content-loader';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import LineChart from '../../Charts/LineChart';
import { LineChartSeries } from '../propTypes';
import theme from '../../../theme';

const LINE_STYLE_DASHED = 'Dashed';

const VisualItem = ({
    classes,
    type,
    title,
    description,
    Icon,
    flagColor,
    flagRGBColor,
    total,
    goal,
    series,
    loading,
}) => {
    const [lineChartData, setLineChartData] = useState({
        labels: [],
        datasets: [],
    });

    useEffect(() => {
        const solidObjectProperties = {
            fill: true,
            backgroundColor: flagRGBColor,
            borderColor: flagRGBColor,
            pointRadius: 3,
            pointHoverRadius: 5,
            pointBackgroundColor: function(context) {
                return theme.palette.white.main;
            },
        };

        const dashedObjectProperties = {
            fill: false,
            backgroundColor: theme.custom.bgChartDashedColor,
            borderColor: theme.custom.bgChartDashedColor,
            tension: 0.4,
            borderDash: [1, 3],
            pointRadius: 3,
            pointHoverRadius: 5,
            pointBackgroundColor: function() {
                return theme.custom.bgChartDashedColor;
            },
        };

        const chartDecorator = {};
        const labels = new Set(); // make it as Set to avoid duplicate labels
        const dataSet = [];
        if (!loading) {
            series.forEach((element) => {
                const additionalProperties =
                    element.style === LINE_STYLE_DASHED
                        ? { ...dashedObjectProperties }
                        : { ...solidObjectProperties };

                dataSet.push({
                    label: element.name,
                    data: element.data,
                    ...additionalProperties,
                });
                element.labels.forEach((label) => {
                    labels.add(label);
                });
            });
        }

        chartDecorator.labels = [...labels];
        chartDecorator.datasets = [...dataSet];
        // eslint-disable-next-line no-console
        setLineChartData(chartDecorator);
    }, [series, flagRGBColor, loading]);

    const lineChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
                grid: {
                    display: true,
                    drawBorder: false,
                    drawOnChartArea: true,
                    drawTicks: false,
                },
                title: {
                    display: false,
                    text: 'Value',
                },
                beginAtZero: true,
            },
        },
    };

    const Loader = ({ type }) => {
        const titleLoader = (
            <ContentLoader
                speed={2}
                width={120}
                height={10}
                viewBox="0 0 120 10"
                backgroundColor={theme.custom.loaderForeground}
                foregroundColor={theme.custom.loaderBackground}
            >
                <rect x="0" y="0" rx="3" ry="3" width="120" height="10" />
            </ContentLoader>
        );

        const chartLoader = (
            <ContentLoader viewBox="0 0 180 160" width={300} height={240}>
                <rect x="75" y="70" rx="0" ry="0" width="20" height="70" />
                <rect x="100" y="100" rx="0" ry="0" width="20" height="40" />
                <rect x="125" y="80" rx="0" ry="0" width="20" height="60" />
                <rect x="150" y="40" rx="0" ry="0" width="20" height="100" />
            </ContentLoader>
        );

        const loaders = {
            title: titleLoader,
            chart: chartLoader,
        };

        return loaders[type];
    };

    return (
        <Grid className={classes.visualItemWrapper} alignItems="stretch">
            <Grid className={classes.visualFlagTitleCover}>
                <div
                    className={classes.visualFlagTitle}
                    style={{ backgroundColor: flagColor }}
                >
                    <div className={classes.visualFlagTitleCard}>
                        <p className={classes.visualFlagTitleText}>{type}</p>
                    </div>
                </div>
                <div
                    className={classes.visualFlag}
                    style={{ backgroundColor: flagColor }}
                />
            </Grid>

            <Grid className={classes.visualItem}>
                <div className={classes.visualItemHeader}>
                    {loading ? (
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Loader type="title" />
                        </Grid>
                    ) : (
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            {Icon}
                            <div className={classes.title}>{title}</div>
                        </Grid>
                    )}
                </div>
                <div className={classes.visualItemBody}>
                    {loading ? (
                        <div>
                            <Loader type="chart" />
                        </div>
                    ) : (
                        <div>
                            <Grid className={classes.metaHeading}>
                                {total && (
                                    <Typography
                                        className={classes.totalHeading}
                                        style={{ color: flagColor }}
                                    >
                                        {total}
                                    </Typography>
                                )}
                                {goal && (
                                    <Typography className={classes.goalHeading}>
                                        {goal}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end"
                                className={classes.chartWrapper}
                            >
                                <div className={classes.chartInner}>
                                    <LineChart
                                        options={lineChartOptions}
                                        data={lineChartData}
                                    />
                                </div>
                            </Grid>
                        </div>
                    )}
                </div>

                <div className={classes.visualItemFooter}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Typography className={classes.footerLeft}>
                            {description}
                        </Typography>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

VisualItem.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    Icon: PropTypes.node.isRequired,
    flagColor: PropTypes.string.isRequired,
    flagRGBColor: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    goal: PropTypes.string.isRequired,
    series: PropTypes.arrayOf(LineChartSeries).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(VisualItem);
