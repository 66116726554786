import React from 'react';

import { Favorite, Settings } from '@material-ui/icons';
import { FollowIcon } from '../../../assets/images/svg';
import IcomoonIcon from '../../../common/IcomoonIcon';

export const getNotificationIcon = (iconName, props) => {
    let newName = '';
    switch (iconName) {
        case 'icon_quote':
            newName = 'jb-quote';
            break;
        case 'icon_earth':
            newName = 'jb-earth';
            break;
        case 'icon_buzzes':
            newName = 'jb-buzz';
            break;
        case 'icon_challenge':
            newName = 'jb-challenge';
            break;
        case 'icon_badge':
            newName = 'jb-badge';
            break;
        case 'icon_medal':
            newName = 'jb-medal';
            break;
        case 'icon_alert':
            newName = 'jb-restricted';
            break;
        case 'icon_goal':
            newName = 'jb-goal';
            break;
        case 'icon_lb':
            newName = 'jb-trophy';
            break;
        case 'icon_prize':
            newName = 'jb-prizes';
            break;
        default:
    }
    if (!newName) {
        switch (iconName) {
            case 'icon_settings':
                return <Settings {...props} />;
            case 'icon_heart':
                return <Favorite {...props} />;
            case 'icon_friend':
                return <FollowIcon {...props} />;
            default:
                newName = 'jb-notifications';
        }
    }
    return <IcomoonIcon icon={newName} {...props} />;
};
