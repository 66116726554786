import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    container: {
        background: theme.palette.white.main,
        padding: '8px',
        borderRadius: '5px',
        height: '100%',
    },
    bottomRow: {
        paddingTop: '5px',
    },
    measurement: ({ color }) => ({
        fontWeight: 700,
        fontSize: '12.5px',
        color: color,
    }),
    subtitle: ({ color }) => ({
        fontWeight: '700',
        fontSize: '10px',
        color: color,
    }),
}));
