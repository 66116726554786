import React, { useMemo, useState } from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';

import NotificationCard from './NotificationCard';

import styles from './styles';
import Button from '../Button';
import theme from '../../theme';
import IMAGES from '../../constants/images';
import {
    FREE_TIER_ID,
    CUSTOM_TIER_ID,
    TRIAL_MAX_USER_COUNT,
} from './constants';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../utils/analytics';
import {
    EVENT_SUBSCRIPTION_PRESS_CONTACT,
    EVENT_SUBSCRIPTION_PRESS_UPGRADE,
} from '../../constants/analyticsEvents';
import {
    PARAM_ORIGIN,
    PARAM_SUBSCRIPTION_ID,
} from '../../constants/analyticsParams';
import { showIntercomChat } from '../../config/intercom';

const AccountTierNotifications = ({ classes, accountPlanData }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(true);
    const maxUserCount =
        accountPlanData?.subscriptionTier?.quotas?.maxUserCount ||
        TRIAL_MAX_USER_COUNT;

    const limitExceed = () => (
        <NotificationCard
            tipTitle={t('account.accountNotifications.userAmountExceed')}
            tipDescription={t(
                'account.accountNotifications.userAmountExceedDescription',
            )}
            isOpen={isOpen}
            image={IMAGES.warningIcon}
            logoColor="red"
            borderColor={theme.custom.red}
            onClose={() => setIsOpen(false)}
            isDismissible={false}
        >
            <Link
                target="_self"
                rel="noopener"
                href="payment"
                data-test="helpLink"
            >
                <Button
                    textColor={theme.palette.primary.main}
                    border="red"
                    className={classes.buttonWhite}
                    data-test="doneButton"
                    onClick={() => {
                        trackEvent(EVENT_SUBSCRIPTION_PRESS_UPGRADE, {
                            [PARAM_ORIGIN]: 'banner',
                        });
                        history.push('/payment');
                    }}
                >
                    {t('account.accountNotifications.upgradeButtonLabel')}
                </Button>
            </Link>
            <Button
                textColor={theme.palette.primary.contrastText}
                borderColor="borderRed"
                className={classes.buttonGreen}
                data-test="doneButton"
                onClick={() => {
                    trackEvent(EVENT_SUBSCRIPTION_PRESS_CONTACT, {
                        [PARAM_SUBSCRIPTION_ID]:
                            accountPlanData?.subscriptionTier?.id,
                    });
                    showIntercomChat();
                }}
            >
                {t('account.accountNotifications.contactUsButtonLabel')}
            </Button>
        </NotificationCard>
    );

    const welcomeFreeTier = () => (
        <NotificationCard
            tipTitle={
                <>
                    {t(`account.accountNotifications.defaultFreeTrial.title`)}
                    <span>
                        {t(
                            `account.accountNotifications.defaultFreeTrial.greenTitleSection`,
                        )}
                    </span>
                    <span className={classes.underlined}>
                        {t(
                            `account.accountNotifications.defaultFreeTrial.greenTileUnderlinedSection`,
                            { maxUserCount },
                        )}
                    </span>
                </>
            }
            tipDescription={
                <>
                    {t(
                        `account.accountNotifications.defaultFreeTrial.description`,
                        { maxUserCount },
                    )}
                    <div className={classes.tipSelectPlan}>
                        <Typography
                            variant="body1"
                            className={classes.tipSelectPlan}
                        >
                            {t(
                                `account.accountNotifications.defaultFreeTrial.selectPlan`,
                            )}
                            <span>
                                <Link
                                    target="_self"
                                    rel="noopener"
                                    href="payment"
                                    data-test="helpLink"
                                >
                                    {t(
                                        `account.accountNotifications.defaultFreeTrial.selectPlanLink`,
                                    )}
                                </Link>
                            </span>
                        </Typography>
                    </div>
                </>
            }
            image={IMAGES.greenLogo}
            borderColor={theme.palette.primary.main}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <Link
                target="_self"
                rel="noopener"
                href="payment"
                data-test="upgradeLink"
            >
                <Button
                    textColor={theme.palette.primary.contrastText}
                    className={classes.buttonGreen}
                    data-test="doneButton"
                >
                    {t('account.accountNotifications.upgradeButtonLabel')}
                </Button>
            </Link>
        </NotificationCard>
    );

    const isUserCountNotExceeded = useMemo(() => {
        if (accountPlanData) {
            const userCount = accountPlanData.currentUserCount || 0;
            return maxUserCount > userCount;
        }
        return false;
    }, [accountPlanData, maxUserCount]);

    if (accountPlanData?.loading) {
        return null;
    }

    return (
        <Grid className={classes.container}>
            {isUserCountNotExceeded
                ? FREE_TIER_ID === accountPlanData?.subscriptionTier?.id &&
                  welcomeFreeTier()
                : CUSTOM_TIER_ID !== accountPlanData?.subscriptionTier?.id &&
                  limitExceed()}
        </Grid>
    );
};

AccountTierNotifications.propTypes = {
    accountPlanData: PropTypes.array,
    classes: PropTypes.object.isRequired,
};

AccountTierNotifications.defaultProps = {
    accountPlanData: [],
};

export default withStyles(styles)(AccountTierNotifications);
