const styles = (theme) => ({
    previewColumn: {
        width: '50%',
        marginRight: '10px',
    },
    previewVideo: {
        backgroundColor: theme.custom.inputBackground,
    },
    formColumn: {
        width: '100%',
    },
    textarea: theme.shineAdmin.textarea,
    icon: {
        color: theme.custom.tooltipIconColor,
        marginTop: '3px',
        marginLeft: '3px',

        '&:hover': {
            cursor: 'pointer',
            color: '#562732',
        },
    },
});

export default styles;
