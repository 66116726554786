import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';
import ErrorList from '../../../common/errors/ErrorList';
import TemplateGroup from './TemplateGroup';
import { noop } from '../../../common/utils';
import Button from '../../../common/Button';
import { baseColumns } from '../../ActionsScreen/AdminActionsScreen/constants';
import TableManager from '../../ActionsScreen/AdminActionsScreen/TableManager';
import HeaderPage from '../../../common/HeaderPage';
import { actionTablePropType } from '../../../actions/propTypes';
import { PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME } from '../../../actions/constants';
import { INTERCOM_TARGET_PREFIX } from '../constants';

const SelectChallengeTheme = ({
    selectedTemplate,
    themes,
    setSelectedTemplate,
    actions,
    onCustomizeClick,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <Grid container className={classes.container}>
            <HeaderPage title={t('challenge.create.title')} />
            <Grid container item className={classes.templates}>
                <TemplateGroup
                    templates={themes}
                    selectedTemplate={selectedTemplate}
                    onTemplateClicked={setSelectedTemplate}
                />
            </Grid>
            <Grid
                container
                direction="column"
                className={classes.descriptionContainer}
            >
                <Typography align="center">
                    {selectedTemplate?.description}
                </Typography>
            </Grid>
            <Grid container className={classes.confirmButton} justify="center">
                <Grid item xs={6}>
                    <Button
                        data-intercom-target={`${INTERCOM_TARGET_PREFIX}CustomizeChallengeButton`}
                        color="accent"
                        fullWidth
                        size="large"
                        disabled={selectedTemplate === null}
                        onClick={onCustomizeClick}
                    >
                        {t('challenge.create.customize')}
                    </Button>
                </Grid>
            </Grid>
            <ErrorList errors={actions?.errors} />
            {selectedTemplate && actions?.data && (
                <TableManager
                    type={PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME}
                    columns={baseColumns}
                    data={actions}
                    initialSortBy={[{ id: 'name', desc: false }]}
                    shouldShowMultiSelect={false}
                    clientPagination
                />
            )}
        </Grid>
    );
};

SelectChallengeTheme.propTypes = {
    selectedTemplate: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        iconUrl: PropTypes.string,
        description: PropTypes.string,
    }),
    themes: PropTypes.array,
    setSelectedTemplate: PropTypes.func,
    onCustomizeClick: PropTypes.func,
    actions: actionTablePropType,
};

SelectChallengeTheme.defaultProps = {
    loadingTemplates: false,
    templatesErrors: null,
    themes: [],
    selectedTemplate: null,
    setSelectedTemplate: noop,
    onCustomizeClick: noop,
    actions: {},
    clearTemplateErrors: noop,
};

export default SelectChallengeTheme;
