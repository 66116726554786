import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: '112px',
        padding: 0,

        '&:hover': {
            transform: 'translateY(-2px)',
            transition: 'all .2s ease-in',
        },
    },
    outlinedPrimary: ({ selected }) => {
        const base = {
            color: selected
                ? theme.palette.primary.main
                : theme.custom.textDarkSecondaryColor,
            borderRadius: '10px',
            opacity: 1,
        };

        const selectedOption = selected
            ? {
                  borderWidth: '5px',
                  boxShadow: '0px 5px 10px rgba(0,0,0,.1)',
                  borderColor: theme.palette.white.main,
                  '&:hover': {
                      borderWidth: '5px',
                      borderColor: theme.palette.white.main,
                  },
              }
            : {
                  borderWidth: '2px',
                  padding: '3px',
                  opacity: 1,
                  borderColor: theme.palette.primary.main,
                  '&:hover': {
                      borderWidth: '2px',
                      borderColor: theme.palette.primary.main,
                  },
              };

        return {
            ...base,
            '&:hover': {
                boxShadow: selected
                    ? 'none'
                    : '0 0 0 1px rgba(0,0,0,.03),0 2px 8px 0 rgba(0,0,0,.09)',
            },
            ...selectedOption,
        };
    },
    container: {
        flex: 1,
        zIndex: 2,
        borderRadius: '6px',

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:hover $header': {
            opacity: 'none',
        },
        backgroundColor: theme.palette.white.main,
    },
    header: ({ selected }) => ({
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: selected ? 1 : 0,
        transition: 'opacity .2s ease-in',
        backgroundColor: theme.palette.primary.main,
        maxHeight: '100%',
        borderRadius: '4px',
    }),
    wrapper: ({ selected }) => ({
        color: selected ? theme.palette.white.main : theme.palette.primary.main,
        zIndex: 5,
        margin: 5,
    }),
    icon: {
        position: 'relative',
        top: '40%',
        transition: 'opacity .2s ease-in',
        width: '40px',
        height: '40px',
        fill: theme.palette.white.main,
        opacity: 1,
    },
    iconContainer: {
        marginBottom: '5px',
    },
    nameContainer: {
        fontWeight: 600,
    },
    nameText: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: 1.2,
    },
    descriptionText: {
        fontSize: '16px',
        lineHeight: 1.1,
    },
}));

export default useStyles;
