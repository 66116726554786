import ContentLoader from 'react-content-loader';
import React from 'react';
import useStyles from './styles';
import { Grid } from '@material-ui/core';

const ActivityCardLoader = () => {
    const classes = useStyles();
    return (
        <Grid className={classes.cardLoaderContainer}>
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox="0 0 350 105"
            >
                <rect x="40" y="6" rx="3" ry="3" width="177" height="7" />
                <rect x="40" y="22" rx="3" ry="3" width="135" height="5" />
                <rect x="0" y="55" rx="3" ry="3" width="350" height="6" />
                <rect x="0" y="40" rx="3" ry="3" width="129" height="6" />
                <rect x="41" y="77" rx="3" ry="3" width="137" height="5" />
                <rect x="0" y="1" rx="5" ry="5" width="30" height="30" />
                <circle cx="317" cy="20" r="17" />
                <rect x="40" y="97" rx="3" ry="3" width="279" height="5" />
                <rect x="11" y="75" rx="5" ry="5" width="15" height="10" />
            </ContentLoader>
        </Grid>
    );
};

export default ActivityCardLoader;
