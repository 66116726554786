import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: '36px',

        '&:hover': {
            transform: 'translateY(-2px)',
            transition: 'all .2s ease-in',
        },
    },
    outlinedPrimary: ({ selected }) => ({
        color: selected
            ? theme.palette.primary.main
            : theme.custom.textDarkSecondaryColor,
        backgroundColor: selected
            ? theme.palette.white.main
            : 'rgba(0,0,0,.05)',
        borderColor: selected
            ? theme.palette.primary.main
            : theme.custom.bgColor,
        borderWidth: '2px',
        borderRadius: '6px',

        '&:hover': {
            color: selected
                ? theme.palette.primary.main
                : theme.custom.textDarkColor,
            backgroundColor: theme.custom.bgLightColor,
            borderColor: selected
                ? theme.palette.primary.main
                : theme.custom.bgColor,
            borderWidth: '2px',
            boxShadow: selected
                ? 'none'
                : '0 0 0 1px rgba(0,0,0,.03),0 2px 8px 0 rgba(0,0,0,.09)',
        },
    }),
    container: {
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    label: {
        fontWeight: 600,
        marginTop: '16px',
    },
}));

export default useStyles;
