import { all, put, select, takeLatest } from 'redux-saga/effects';
import { clearQueuedDeepLink, OPEN_QUEUED_DEEP_LINK } from './actions';
import { getQueuedDeepLink } from './selectors';
import { push } from 'connected-react-router';

export function* openDeepLink() {
    const deepLinkPath = yield select(getQueuedDeepLink);
    if (deepLinkPath) {
        yield put(push(deepLinkPath));
        yield put(clearQueuedDeepLink());
    }
}

export default function* deepLinksSaga() {
    yield all([takeLatest(OPEN_QUEUED_DEEP_LINK, openDeepLink)]);
}
