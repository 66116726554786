import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import {
    AddCircleRounded,
    PlusOneRounded,
    StarsRounded,
    VibrationRounded,
} from '@material-ui/icons';
import useStyles from './styles';
import StatCard from '../../../common/StatCard';
import ActivityTotals from '../../../common/ActivityTotals';
import { ProfilePropType } from '../propTypes';
import ImpactToolTip from '../../../common/ImpactTooltip';

const ProfileStats = ({ profile }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const activityItems = useMemo(
        () =>
            profile.activityTotals && [
                {
                    label: t('Points'),
                    value: profile.activityTotals.points,
                    icon: <PlusOneRounded />,
                },
                {
                    label: t('profile.logs'),
                    value: profile.activityTotals.logs,
                    icon: <VibrationRounded />,
                },
                {
                    label: t('profile.actions'),
                    value: profile.activityTotals.actions,
                    icon: <AddCircleRounded />,
                },
                {
                    label: t('profile.badges'),
                    value: profile.activityTotals.badges,
                    icon: <StarsRounded />,
                },
            ],
        [profile, t],
    );

    return (
        <Grid container direction="column" className={classes.container}>
            <>
                <Grid container direction="row" wrap="nowrap" spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <StatCard
                            title={t('Followers')}
                            value={profile.followers}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <StatCard
                            title={t('Following')}
                            value={profile.following}
                        />
                    </Grid>
                </Grid>
                <Typography className={classes.impactTitle} variant="h3">
                    {t('impact.totals')}
                    <ImpactToolTip />
                </Typography>
                <Grid
                    container
                    justify="space-evenly"
                    direction="row"
                    spacing={2}
                >
                    {profile.impactTotals.map((impact, index) => (
                        <Grid container key={index} item xs>
                            <StatCard capitalize {...impact} />
                        </Grid>
                    ))}
                </Grid>
                <Grid container className={classes.activityTotals}>
                    <ActivityTotals
                        activityTitle={t('activity.totals')}
                        activityItems={activityItems}
                    />
                </Grid>
            </>
        </Grid>
    );
};

ProfileStats.propTypes = {
    profile: ProfilePropType,
};

ProfileStats.defaultProps = {
    profile: null,
};

export default ProfileStats;
