import { createSelector } from 'reselect';
import { getCurrencyString } from './utils';
import { sortSubscriptionTiers } from './transforms';
import { coerceIntoErrors } from '../common/errors';

export const getSubscriptionState = (state) => state.subscription;

export const getCurrentSubscription = createSelector(
    getSubscriptionState,
    (subscriptionState) => ({
        ...subscriptionState.accountPlan?.currentSubscription,
        formattedAmount: getCurrencyString(
            subscriptionState.accountPlan?.currentSubscription?.upcomingAmount,
            subscriptionState.accountPlan?.currentSubscription?.currency,
        ),
        loading: subscriptionState.accountPlan?.loading,
    }),
);

export const getSubscriptionName = createSelector(
    getCurrentSubscription,
    (currentSubscription) => currentSubscription?.subscriptionTier?.name,
);

export const getSubscriptionId = createSelector(
    getCurrentSubscription,
    (currentSubscription) => currentSubscription?.subscriptionTier?.id,
);

export const getSubscriptionAmount = createSelector(
    getCurrentSubscription,
    (currentSubscription) => currentSubscription?.amount,
);

export const isSubscriptionCanceled = createSelector(
    getCurrentSubscription,
    (currentSubscription) => currentSubscription?.cancelAtPeriodEnd,
);

export const getLatestInvoice = createSelector(
    getSubscriptionState,
    (subscriptionState) => ({
        ...subscriptionState.accountPlan?.currentSubscription?.invoice,
        amountPaid: getCurrencyString(
            subscriptionState.accountPlan?.currentSubscription?.invoice
                ?.amountPaid,
            subscriptionState.accountPlan?.currentSubscription?.currency,
        ),
        subTotal: getCurrencyString(
            subscriptionState.accountPlan?.currentSubscription?.invoice
                ?.subTotal,
            subscriptionState.accountPlan?.currentSubscription?.currency,
        ),
        salesTax: getCurrencyString(
            subscriptionState.accountPlan?.currentSubscription?.invoice
                ?.salesTax,
            subscriptionState.accountPlan?.currentSubscription?.currency,
        ),
        amountDue: getCurrencyString(
            subscriptionState.accountPlan?.currentSubscription?.invoice
                ?.amountDue,
            subscriptionState.accountPlan?.currentSubscription?.currency,
        ),
    }),
);

export const getPaymentMethod = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState.paymentMethod,
);

export const getStripePlanId = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState.stripePlanId,
);

export const getBillingSessionUrl = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState.billingSession.url,
);

export const getBillingSessionErrors = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState.billingSession.errors,
);
export const getBillingSessionLoading = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState.billingSession.loading,
);

export const getSubscriptionRecords = createSelector(
    getSubscriptionState,
    (subscriptionState) =>
        subscriptionState?.subscriptionRecords?.records?.map((record) => ({
            ...record,
            formattedAmount: getCurrencyString(
                record.invoice.amountPaid,
                record.currency,
            ),
        })),
);

export const getSubscriptionTiers = createSelector(
    getSubscriptionState,
    (subscriptionState) =>
        sortSubscriptionTiers(subscriptionState.subscriptionTiers?.tiers),
);

export const areSubscriptionTiersLoading = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState.subscriptionTiers?.loading,
);

export const getSubscriptionTierErrors = createSelector(
    getSubscriptionState,
    (subscriptionState) =>
        coerceIntoErrors(subscriptionState.subscriptionTiers?.errors),
);

export const getSubscriptionSessionLoadingState = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState?.billingSession?.loading,
);

export const getSubscriptionManagementUrl = createSelector(
    getSubscriptionState,
    (subscriptionState) => subscriptionState.management.url,
);
