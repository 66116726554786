const styles = (theme) => ({
    container: {
        padding: '0',
    },
    helpWrapper: {
        margin: '10px 0',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    helpText: {
        marginLeft: '5px',
        fontSize: '75%',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    emptyText: {
        fontSize: '14px',
        lineHeight: 1.1,
        fontWeight: 600,
        textAlign: 'center',
        color: theme.custom.textDarkColor,
        opacity: 0.5,
    },
    contentLoaderContainer: {
        [theme.breakpoints.up('sm')]: {
            minHeight: 140,
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 260,
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: 390,
        },
        width: '100%',
        height: '100%',
    },
    hideCanvas: {
        display: 'none',
    },
    canvasContainer: {
        width: '100%',
        height: '100%',
    },
    canvasStyles: {
        minHeight: '100%',
    },
});

export default styles;
