import {
    CLEAR_CREATE_NOTIFICATION,
    CLEAR_NOTIFICATION_ERRORS,
    NOTIFICATION_REQUESTED,
    NOTIFICATION_SUCCEEDED,
    NOTIFICATIONS_FAILED,
    NOTIFICATIONS_REQUESTED,
    NOTIFICATIONS_SUCCEEDED,
    OPEN_CREATE_NOTIFICATION,
    SET_NOTIFICATION_COUNTS,
    UPDATE_NOTIFICATION_FAILED,
    UPDATE_NOTIFICATION_REQUESTED,
    UPDATE_NOTIFICATION_SUCCEEDED,
    USER_NOTIFICATIONS_REQUEST_FAILED,
    USER_NOTIFICATIONS_REQUEST_SUCCEEDED,
    USER_NOTIFICATIONS_REQUESTED,
} from './actions';
import { GROUPS } from '../screens/NotificationsScreen/AdminNotificationsScreen/constants';
import { mergePaginatedResponse } from '../common/utils';

const initialState = [...GROUPS, 'notification'].reduce(
    (state, key) => ({
        ...state,
        [key]: {
            data: [],
            totalCount: 0,
            isLoading: false,
            errors: [],
            message: null,
        },
    }),
    {
        create: {
            subjectContentType: null,
            subjectContentID: null,
            subject: '',
            message: '',
            audienceType: null,
            audiences: [],
            iconUrl: null,
        },
        userNotifications: {
            loading: false,
            errors: [],
            notifications: [],
            counts: {
                NotificationCenter: 0,
                ActivityFeed: 0,
                Buzz: 0,
                Leaderboard: 0,
                Profile: 0,
                total: 0,
            },
        },
    },
);

export default (state = initialState, action) => {
    const { type, payload = {} } = action;

    switch (type) {
        case NOTIFICATIONS_REQUESTED:
            return {
                ...state,
                [payload.group]: {
                    ...state[payload.group],
                    errors: [],
                    isLoading: true,
                },
            };
        case NOTIFICATIONS_SUCCEEDED:
            return {
                ...state,
                [payload.group]: {
                    ...state[payload.group],
                    data: payload.notifications,
                    totalCount: payload.totalCount,
                    isLoading: false,
                },
                errors: payload.errors,
            };
        case NOTIFICATIONS_FAILED:
            return {
                ...state,
                [payload.group]: {
                    ...state[payload.group],
                    errors: payload.errors,
                    isLoading: false,
                },
                errors: payload.errors,
            };

        case NOTIFICATION_REQUESTED:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    errors: [],
                    isLoading: true,
                },
            };
        case NOTIFICATION_SUCCEEDED:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    data: payload.notification,
                    isLoading: false,
                },
            };
        case UPDATE_NOTIFICATION_REQUESTED:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    errors: [],
                    errorStatus: null,
                    isLoading: true,
                },
            };
        case UPDATE_NOTIFICATION_FAILED:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    errors: payload.errors,
                    errorStatus: payload.errorStatus,
                    isLoading: false,
                },
            };
        case UPDATE_NOTIFICATION_SUCCEEDED:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    errors: [],
                    errorStatus: null,
                    isLoading: false,
                    message: payload,
                },
            };

        case CLEAR_NOTIFICATION_ERRORS:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    errors: [],
                    message: null,
                },
            };

        case OPEN_CREATE_NOTIFICATION: {
            return {
                ...state,
                create: {
                    ...payload,
                },
            };
        }
        case CLEAR_CREATE_NOTIFICATION: {
            return {
                ...state,
                create: {
                    subjectContentType: null,
                    subjectContentID: null,
                    subject: '',
                    message: '',
                    audienceType: null,
                    audiences: [],
                    iconUrl: null,
                },
            };
        }
        case USER_NOTIFICATIONS_REQUESTED:
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    errors: [],
                    loading: true,
                },
            };
        case USER_NOTIFICATIONS_REQUEST_SUCCEEDED:
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    notifications: mergePaginatedResponse(
                        state.userNotifications.notifications,
                        payload.notifications,
                        payload.skip,
                    ),
                    loading: false,
                },
                errors: payload.errors,
            };
        case USER_NOTIFICATIONS_REQUEST_FAILED:
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    errors: payload.errors,
                    loading: false,
                },
            };
        case SET_NOTIFICATION_COUNTS:
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    counts: payload.counts,
                },
            };
        default:
            return state;
    }
};
