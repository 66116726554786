import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    INTEGRATIONS_REQUESTED,
    integrationsRequestFailed,
    integrationsRequestSucceeded,
    OIDC_INTEGRATION_REMOVE_REQUESTED,
    OIDC_INTEGRATION_REQUESTED,
    SLACK_INTEGRATION_REMOVE_REQUESTED,
    SLACK_INTEGRATION_REQUESTED,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../../utils/api';
import i18n from 'i18next';
import { trackEvent } from '../../utils/analytics';
import { getActiveCommunityId } from '../../user/selectors';
import { getProperties } from '../../utils';
import { push } from 'connected-react-router';
import { getSSOConfig } from '../../auth/selectors';
import {
    EVENT_ORGANIZATION_DOMAIN_RESTRICTED,
    EVENT_SLACK_INTEGRATED,
} from '../../constants/analyticsEvents';

export function* handleSlackIntegrationRequest(action) {
    const { code } = action.payload;
    trackEvent(EVENT_SLACK_INTEGRATED);
    const communityId = yield select(getActiveCommunityId);
    try {
        const response = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/integrations/slack/add`,
            {
                method: 'POST',
                body: {
                    code,
                    redirect_uri: getProperties()?.integrationCredentials?.slack
                        ?.redirectURI,
                },
            },
        );
        const slackIntegration = response.response.integrations[0].slack;
        yield put(
            integrationsRequestSucceeded(
                slackIntegration,
                i18n.t('settings.integrations.slack.integrateSuccess'),
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('settings.integrations.slack.integrateFailure'),
        );
        yield put(integrationsRequestFailed(errors));
    }
}

export function* handleSlackIntegrationRemoveRequest() {
    const communityId = yield select(getActiveCommunityId);
    try {
        const response = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/integrations/slack/remove`,
            {
                method: 'POST',
            },
        );
        const integrations = response.response.integrations[0];
        yield put(integrationsRequestSucceeded(integrations));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('settings.integrations.slack.removeFailure'),
        );
        yield put(integrationsRequestFailed(errors));
    }
}

export function* handleOIDCIntegrationRequest({ payload: body }) {
    const communityId = yield select(getActiveCommunityId);
    yield put(push('/settings'));
    const config = yield select(getSSOConfig);
    try {
        const response = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/integrations/oidc/add`,
            {
                method: 'POST',
                body: {
                    ...body,
                    clientId: config?.config?.clientId,
                },
            },
        );
        const integrations = response.response.integrations[0];
        trackEvent(EVENT_ORGANIZATION_DOMAIN_RESTRICTED, { type: 'google' });
        yield put(
            integrationsRequestSucceeded(
                integrations,
                i18n.t('settings.integrations.oidc.integrateSuccess'),
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('settings.integrations.oidc.integrateFailure'),
        );
        yield put(integrationsRequestFailed(errors));
    }
}

export function* handleOIDCIntegrationRemoveRequest({ payload: { authType } }) {
    const communityId = yield select(getActiveCommunityId);
    try {
        const response = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/integrations/oidc/remove`,
            {
                method: 'POST',
                body: {
                    authType,
                },
            },
        );
        const integrations = response.response.integrations[0];
        yield put(integrationsRequestSucceeded(integrations));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('settings.integrations.oidc.removeFailure'),
        );
        yield put(integrationsRequestFailed(errors));
    }
}

export function* getIntegrations() {
    const communityId = yield select(getActiveCommunityId);
    try {
        const response = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/integrations`,
        );
        const integrations = response.response.integrations[0];
        yield put(integrationsRequestSucceeded(integrations));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('settings.integrations.fetchFailure'),
        );
        yield put(integrationsRequestFailed(errors));
    }
}

export default function* settingsSaga() {
    yield all([
        takeLatest(SLACK_INTEGRATION_REQUESTED, handleSlackIntegrationRequest),
        takeLatest(
            SLACK_INTEGRATION_REMOVE_REQUESTED,
            handleSlackIntegrationRemoveRequest,
        ),
        takeLatest(INTEGRATIONS_REQUESTED, getIntegrations),
        takeLatest(OIDC_INTEGRATION_REQUESTED, handleOIDCIntegrationRequest),
        takeLatest(
            OIDC_INTEGRATION_REMOVE_REQUESTED,
            handleOIDCIntegrationRemoveRequest,
        ),
    ]);
}
