import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import {
    subscriptionSessionClearErrors,
    subscriptionSessionRequested,
    subscriptionTiersRequested,
} from '../../subscriptions/actions';
import {
    getSubscriptionTiers,
    areSubscriptionTiersLoading,
    getSubscriptionTierErrors,
    getBillingSessionUrl,
    getSubscriptionSessionLoadingState,
    getBillingSessionErrors,
} from '../../subscriptions/selectors';
import { getActiveCommunityId } from '../../user/selectors';
import ErrorList from '../../common/errors/ErrorList';
import { getCurrencyString } from '../../subscriptions/utils';
import { trackEvent } from '../../utils/analytics';
import { EVENT_SUBSCRIPTION_STRIPE_UPGRADE_BEGIN } from '../../constants/analyticsEvents';
import { PARAM_PLAN_ID } from '../../constants/analyticsParams';
import OnboardingFooter from '../../common/OnboardingFooter';
import OnboardingHeader from '../../common/OnboardingHeader';
import PaymentPlans from '../../common/PaymentPlans';
import Loader from '../../common/OnBoarding/Loader';
import {
    FREE_PLAN_INDEX,
    ONBOARDING_CREATE_CANCEL_URL,
    CREATE_SUCCESS_URL,
} from '../../subscriptions/constants';

const OnBoardingSubscriptionScreen = ({
    classes,
    fetchSubscriptionTiers,
    fetchSubscriptionSession,
    subscriptionTiers,
    subscriptionTiersLoading,
    subscriptionTierErrors,
    groupId,
    paymentUrl,
    isSubscriptionUrlFetching,
    errors,
    clearErrors,
}) => {
    const { t } = useTranslation();

    const [billingSessionRequested, setBillingSessionRequested] = useState(
        false,
    );

    useEffect(() => {
        fetchSubscriptionTiers();
    }, [fetchSubscriptionTiers]);

    const handleUpgradeClick = useCallback(
        (id, plan, quantity) => {
            trackEvent(EVENT_SUBSCRIPTION_STRIPE_UPGRADE_BEGIN, {
                [PARAM_PLAN_ID]: plan?.id,
            });
            fetchSubscriptionSession({
                groupId,
                successUrl: CREATE_SUCCESS_URL(plan?.id),
                cancelUrl: ONBOARDING_CREATE_CANCEL_URL,
                planId: plan?.id,
                quantity,
            });
            setBillingSessionRequested(true);
        },
        [fetchSubscriptionSession, groupId],
    );

    const isPresentable = useMemo(
        () => !subscriptionTiersLoading && subscriptionTierErrors == null,
        [subscriptionTiersLoading, subscriptionTierErrors],
    );

    const viewableSubscriptionTiers = useMemo(
        () =>
            subscriptionTiers.filter(
                (tier, index) =>
                    index !== subscriptionTiers.length - 1 ||
                    tier.stripeProductId,
            ),
        [subscriptionTiers],
    );

    const upgradeButtonProps = useMemo(
        () =>
            viewableSubscriptionTiers.map((tier, index) => {
                if (index === FREE_PLAN_INDEX)
                    return {
                        buttonText: t('account.paymentTiers.freeTrial'),
                        action: 'free',
                        handler: (id, plan) => null,
                    };
                else
                    return {
                        buttonText: t('account.paymentTiers.freeTrial'),
                        action: 'checkout',
                        handler: (id, plan, quantity) => {
                            handleUpgradeClick(id, plan, quantity);
                        },
                    };
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [viewableSubscriptionTiers, t],
    );

    const tierInfo = useMemo(
        () =>
            viewableSubscriptionTiers.map((tier, index) => {
                switch (index) {
                    case FREE_PLAN_INDEX:
                        return {
                            rateText: getCurrencyString(0, null, false),
                            userCountText: t(
                                'account.paymentTiers.freeTier.userRange',
                                {
                                    maxUserCount:
                                        tier.quotas?.maxUserCount || 0,
                                },
                            ),
                        };
                    default:
                        return {
                            userCountText: t(
                                'account.paymentTiers.premiumTier.userRange',
                                {
                                    min: tier.quotas?.minUserCount || 0,
                                    max: tier.quotas?.maxUserCount || 0,
                                },
                            ),
                        };
                }
            }),
        [viewableSubscriptionTiers, t],
    );

    useEffect(() => {
        if (paymentUrl && billingSessionRequested) {
            window.location.assign(paymentUrl);
        }
    }, [billingSessionRequested, paymentUrl]);

    return (
        <>
            {(isSubscriptionUrlFetching || subscriptionTiersLoading) && (
                <Loader />
            )}
            <Grid
                container
                direction="column"
                justifyContent="center"
                className={classes.page}
            >
                <Grid container item sm={12}>
                    <OnboardingHeader />
                </Grid>
                <Grid
                    container
                    item
                    justifyContent="center"
                    spacing={3}
                    className={classes.mainContent}
                    sm={12}
                >
                    <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h3" className={classes.title}>
                            {t('onBoarding.paymentTitle')}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        className={classes.container}
                        xs={12}
                    >
                        <ErrorList errors={subscriptionTierErrors} />

                        {isPresentable &&
                            viewableSubscriptionTiers?.length > 0 && (
                                <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    spacing={3}
                                >
                                    <PaymentPlans
                                        subscriptionTiers={
                                            viewableSubscriptionTiers
                                        }
                                        upgradeButtonProps={upgradeButtonProps}
                                        tierInfo={tierInfo}
                                        errors={errors}
                                        clearSnackBar={clearErrors}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </Grid>
                <Grid container item sm={12}>
                    <OnboardingFooter />
                </Grid>
            </Grid>
        </>
    );
};

OnBoardingSubscriptionScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    fetchSubscriptionTiers: PropTypes.func.isRequired,
    fetchSubscriptionSession: PropTypes.func.isRequired,
    subscriptionTiers: PropTypes.array.isRequired,
    subscriptionTiersLoading: PropTypes.bool.isRequired,
    subscriptionTierErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    groupId: PropTypes.string.isRequired,
    paymentUrl: PropTypes.string.isRequired,
    isSubscriptionUrlFetching: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearErrors: PropTypes.func.isRequired,
};

OnBoardingSubscriptionScreen.defaultProps = {
    currentSubscriptionAmount: 0,
};

export const StyledOnBoardingSubscriptionScreen = withStyles(styles)(
    OnBoardingSubscriptionScreen,
);

const mapStateToProps = (state) => ({
    subscriptionTiers: getSubscriptionTiers(state),
    subscriptionTiersLoading: areSubscriptionTiersLoading(state),
    subscriptionTierErrors: getSubscriptionTierErrors(state),
    groupId: getActiveCommunityId(state),
    paymentUrl: getBillingSessionUrl(state),
    isSubscriptionUrlFetching: getSubscriptionSessionLoadingState(state),
    errors: getBillingSessionErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchSubscriptionTiers: () => dispatch(subscriptionTiersRequested()),
    fetchSubscriptionSession: ({ groupId, successUrl, cancelUrl, planId }) =>
        dispatch(
            subscriptionSessionRequested(
                groupId,
                successUrl,
                cancelUrl,
                planId,
            ),
        ),
    clearErrors: () => dispatch(subscriptionSessionClearErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledOnBoardingSubscriptionScreen);
