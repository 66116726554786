import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';

import Copyright from '../Copyright';
import IMAGES from '../../constants/images';

const Footer = ({ classes, ...props }) => {
    return (
        <Grid
            container
            className={classes.footerContainer}
            direction="row"
            justify="center"
            alignItems="center"
            {...props}
        >
            <Grid item container direction="column" alignItems="center">
                <img
                    className={classes.logo}
                    src={IMAGES.logoJBGray}
                    alt="JouleBug"
                />
                <Typography className={classes.footerText} variant="body2">
                    <Copyright name="JouleBug" />
                    {` | v${process.env.REACT_APP_VERSION}`}
                </Typography>
            </Grid>
        </Grid>
    );
};
Footer.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
