const styles = (theme) => ({
    container: {
        padding: '0 10px',
    },
    participationIcon: {
        marginRight: '5px',
    },
    buttonsContainer: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    joinButton: {
        fontSize: 'min(18px, 5vw)',
        padding: '8px 8px',
    },
    shareButton: {
        fontSize: 'min(18px, 5vw)',
        marginLeft: 20,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginTop: '10px',
            padding: '8px 8px',
        },
    },
});

export default styles;
