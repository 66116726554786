const styles = (theme) => ({
    buttonText: {
        justifyContent: 'center',
        marginTop: '5px',
    },
    inputRow: {
        margin: '10px 0px 0px 0px',
    },
    toggleText: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 14,
    },
    toggle: {
        zIndex: 2,
    },
    asteriskRequired: {
        fontSize: '12px',
        color: theme.palette.disabled.main,
        flexWrap: 'wrap',
    },
    dropDown: {
        zIndex: 1,
        top: '-54px',
        padding: '0px 15px',
    },
    dropDownContainer: {
        marginBottom: '-54px',
    },
});

export default styles;
