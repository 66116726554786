const styles = (theme) => ({
    copyToEmailWrapper: {
        flex: 1,
    },
    headerText: {
        textAlign: 'center',
        flex: 1,
        fontWeight: 'bold',
    },
    modalBodyText: {
        marginTop: 20,
        textAlign: 'center',
    },
    modalPreviewHeader: {
        marginTop: 40,
        textAlign: 'center',
        width: '100%',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    messageTextContainer: {
        alignItems: 'center',
        height: 300,
        textAlign: 'center',
        backgroundColor: theme.custom.grey93,
        borderRadius: 6,
        marginTop: 20,
        marginBottom: 20,
        padding: '25px',
    },
    pdfContainer: {
        maxHeight: 200,
        maxWidth: 155,
    },
    linkText: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
    linkPreText: {
        fontWeight: 'bold',
    },
    bottomButton: {
        marginLeft: 20,
        marginRight: 20,
    },
});

export default styles;
