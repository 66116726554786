export const ACTIVITIES_REQUESTED = 'ACTIVITIES_REQUESTED';
export const ACTIVITIES_REQUEST_SUCCEEDED = 'ACTIVITIES_REQUEST_SUCCEEDED';
export const ACTIVITIES_REQUEST_FAILED = 'ACTIVITIES_REQUEST_FAILED';
export const USERS_ANALYTIC_REQUESTED = 'USERS_ANALYTIC_REQUESTED';
export const USERS_ANALYTIC_REQUEST_SUCCEEDED =
    'USERS_ANALYTIC_REQUEST_SUCCEEDED';
export const USERS_ANALYTIC_REQUEST_FAILED = 'USERS_ANALYTIC_REQUEST_FAILED';
export const IMPACT_STATS_REQUESTED = 'IMPACT_STATS_REQUESTED';
export const IMPACT_STATS_REQUEST_SUCCEEDED = 'IMPACT_STATS_REQUEST_SUCCEEDED';
export const IMPACT_STATS_REQUEST_FAILED = 'IMPACT_STATS_REQUEST_FAILED';
export const GRAPH_DATA_REQUESTED = 'GRAPH_DATA_REQUESTED';
export const GRAPH_DATA_REQUEST_SUCCEEDED = 'GRAPH_DATA_REQUEST_SUCCEEDED';
export const GRAPH_DATA_REQUEST_FAILED = 'GRAPH_DATA_REQUEST_FAILED';
export const SET_ANALYTICS_END_DATE_FILTER = 'SET_ANALYTICS_END_DATE_FILTER';
export const ANALYTICS_GRAPH_DATA_REQUESTED = 'ANALYTICS_GRAPH_DATA_REQUESTED';
export const ANALYTICS_GRAPH_DATA_REQUEST_SUCCEEDED =
    'ANALYTICS_GRAPH_DATA_SUCCEEDED';
export const ANALYTICS_GRAPH_DATA_REQUEST_FAILED =
    'ANALYTICS_GRAPH_DATA_FAILED';

export const activitiesRequested = (options, communityId) => ({
    type: ACTIVITIES_REQUESTED,
    payload: {
        options,
        communityId,
    },
});

export const activitiesRequestFailed = () => ({
    type: ACTIVITIES_REQUEST_FAILED,
});

export const activitiesRequestSucceeded = (activities) => ({
    type: ACTIVITIES_REQUEST_SUCCEEDED,
    payload: {
        activities,
    },
});

export const usersAnalyticRequested = (options, communityId) => ({
    type: USERS_ANALYTIC_REQUESTED,
    payload: {
        options,
        communityId,
    },
});

export const usersAnalyticRequestFailed = () => ({
    type: USERS_ANALYTIC_REQUEST_FAILED,
});

export const usersAnalyticRequestSucceeded = (usersAnalytic) => ({
    type: USERS_ANALYTIC_REQUEST_SUCCEEDED,
    payload: {
        usersAnalytic,
    },
});

export const impactStatsRequested = (options, communityId) => ({
    type: IMPACT_STATS_REQUESTED,
    payload: {
        options,
        communityId,
    },
});

export const impactStatsRequestFailed = () => ({
    type: IMPACT_STATS_REQUEST_FAILED,
});

export const impactStatsRequestSucceeded = (impactStats) => ({
    type: IMPACT_STATS_REQUEST_SUCCEEDED,
    payload: {
        impactStats,
    },
});

export const graphDataRequested = (options, communityId) => ({
    type: GRAPH_DATA_REQUESTED,
    payload: {
        options,
        communityId,
    },
});

export const graphDataRequestFailed = () => ({
    type: GRAPH_DATA_REQUEST_FAILED,
});

export const graphDataRequestSucceeded = (newGraphData, metric) => ({
    type: GRAPH_DATA_REQUEST_SUCCEEDED,
    payload: {
        newGraphData,
        metric,
    },
});

export const analyticsGraphDataRequested = (options, communityId, metrics) => ({
    type: ANALYTICS_GRAPH_DATA_REQUESTED,
    payload: {
        options,
        communityId,
        metrics,
    },
});

export const analyticsGraphDataRequestFailed = (errors, metrics) => ({
    type: ANALYTICS_GRAPH_DATA_REQUEST_FAILED,
    payload: {
        errors,
        metrics,
    },
});

export const analyticsGraphDataRequestSucceeded = (data) => ({
    type: ANALYTICS_GRAPH_DATA_REQUEST_SUCCEEDED,
    payload: {
        newGraphData: data.newGraphData,
        metrics: data.metrics,
    },
});
