import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    linkItem: ({ shouldHighlight }) => {
        const base = {
            backgroundColor: theme.custom.bgLightColor,
            borderRadius: '6px',
            marginBottom: '6px',
        };
        if (shouldHighlight) {
            base['&:hover'] = theme.custom.bgLightAccentColor;
        }
        return base;
    },
    imageContainer: {
        position: 'relative',
    },
    linkImage: {
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
    },
    topRightImage: {
        position: 'absolute',
        top: 5,
        right: 5,
    },
    linkInfo: {
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '8px',
        flex: 1,
    },
    linkName: {
        marginBottom: '5px',
        fontSize: '14px',
        fontWeight: '600',
    },
    linkDescription: {
        color: theme.custom.textDarkSecondaryColor,
    },
    linkControls: {
        marginTop: '6px',
        marginBottom: '20px',
    },
    buttonIcon: {
        marginRight: '3px',
    },
}));

export default useStyles;
