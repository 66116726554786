import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
    clearEmailVerification,
    CREATE_USER_REQUESTED,
    createUserFailed,
    createUserSucceeded,
    EMAIL_VERIFICATION_VERIFY,
    emailVerificationVerifyFailed,
    emailVerificationVerifySucceeded,
    INITIAL_FORM_SUBMIT,
    initialFormSubmitFailed,
    initialFormSubmitSucceeded,
    LOGIN_BY_MICROSOFT_REQUEST,
    LOGIN_BY_OKTA_REQUEST,
    LOGIN_REQUESTED,
    loginRequestFailed,
    loginRequestSucceeded,
    LOGOUT_REQUESTED,
    logoutSucceeded,
    redirectionAutofill,
    RESET_PASSWORD_EMAIL_REQUESTED,
    RESET_PASSWORD_REQUESTED,
    resetPasswordEmailSent,
    resetPasswordFailedToSend,
    resetPasswordRequestFailed,
    resetPasswordRequestSucceeded,
    SIGN_UP_REQUESTED,
    signUpRequestFailed,
    signUpRequestSucceeded,
    SSO_AUTH_REQUESTED,
    SSO_CODE_EXCHANGE_REQUESTED,
    SSO_LOGIN_REQUEST_FAILED,
    SSO_LOGIN_REQUESTED,
    SSOLoginRequested,
    SSOLoginRequestFailed,
    SSOLoginRequestSucceeded,
    SSOStoreConfig,
} from './actions';
import { login, microsoftLogin, onGetOAuthToken } from './api';
import { trackEvent } from '../utils/analytics';
import { USER_REQUEST_SUCCEEDED, userRequested } from '../user/actions';
import {
    fetchWithAuth,
    fetchWithoutAuth,
    getErrorsFromPossibleAPIErrorResponse,
    setToken,
} from '../utils/api';
import { getProperties } from '../utils';
import {
    CLIENT_SIDE_PKCE,
    LOGIN_METHOD_AZURE_AD,
    LOGIN_METHOD_GOOGLE,
    LOGIN_METHOD_MICROSOFT,
    LOGIN_METHOD_OKTA,
    SERVER_SIDE_NON_PKCE,
} from '../properties/constants';
import { push, replace } from 'connected-react-router';
import { shutDownIntercom } from '../config/intercom';
import i18n from 'i18next';
import APIErrorResponse from '../utils/api/APIErrorResponse';
import { getQueuedDeepLink } from '../deepLinks/selectors';
import { clearQueuedDeepLink } from '../deepLinks/actions';
import {
    AuthorizationNotifier,
    AuthorizationRequest,
    AuthorizationServiceConfiguration,
    BaseTokenRequestHandler,
    DefaultCrypto,
    FetchRequestor,
    GRANT_TYPE_AUTHORIZATION_CODE,
    LocalStorageBackend,
    RedirectRequestHandler,
    TokenRequest,
} from '@openid/appauth';
import {
    RETURN_LOCATION_ADMIN_SIGNUP,
    RETURN_LOCATION_SETTINGS,
} from './constants';
import { getSSOConfig } from './selectors';
import { requestOIDCIntegration } from '../screens/SettingsScreen/actions';
import { store } from '../App';
import { QueryStringUtils } from './utils';
import {
    getFeatureFlagValue,
    REMOTE_CONFIG_USER_VIEW_ENABLED,
} from '../utils/remoteConfig';
import { SNACKBAR_DURATION } from '../common/SnackbarAlert/constants';
import {
    EVENT_ACCOUNT_LOGIN,
    EVENT_ONBOARDING_GOOGLE_ORGANIZATION_CREATED,
    EVENT_ONBOARDING_EMAIL_VERIFIED,
    EVENT_ONBOARDING_ORGANIZATION_CREATE_BEGAN,
    EVENT_ONBOARDING_ORGANIZATION_DETAILS_SUBMITTED,
    EVENT_ACCOUNT_SSO_LOGGED_IN,
} from '../constants/analyticsEvents';

export function* handleLoginRequest(action) {
    const { data } = action.payload;

    trackEvent(EVENT_ACCOUNT_LOGIN);

    try {
        const token = yield call(login, data);
        yield put(loginRequestSucceeded(token));
        yield put(userRequested(true));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Incorrect username or password was supplied. Please try again.',
        );
        yield put(loginRequestFailed(errors));
    }
}

export function* authedRedirect(action) {
    if (action.payload.isAuthenticating) {
        const deepLinkPath = yield select(getQueuedDeepLink);
        if (deepLinkPath) {
            yield put(push(deepLinkPath));
            yield put(clearQueuedDeepLink());
        } else {
            yield put(push('/'));
        }
    }
}

function* handleOAuthLoginRequest(loginFunction) {
    try {
        const token = yield call(loginFunction);
        if (!token) {
            yield put(loginRequestFailed(['Unable to fetch auth token']));
        } else {
            yield put(loginRequestSucceeded(token));
            yield put(userRequested(true));
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Incorrect username or password was supplied. Please try again.',
        );
        yield put(loginRequestFailed(errors));
    }
}

export function* handleLoginByMicrosoftRequest() {
    yield handleOAuthLoginRequest(microsoftLogin);
}

export function* handleLoginByOktaRequest({ payload: { code } }) {
    yield handleOAuthLoginRequest(() =>
        onGetOAuthToken(code, null, LOGIN_METHOD_OKTA),
    );
}

export function* handleSignUpRequest(action) {
    const { form } = action.payload;

    let params = null;
    if (form.authType) {
        params = { authType: form.authType };
    }
    trackEvent(EVENT_ONBOARDING_ORGANIZATION_DETAILS_SUBMITTED, params);

    try {
        const data = yield call(
            fetchWithoutAuth,
            '/signup/organization/email',
            {
                method: 'POST',
                body: {
                    ...form,
                    client_secret: getProperties()?.clientSecret,
                    client_id: getProperties()?.clientId,
                },
            },
        );
        setToken(data.access_token);
        yield put(
            signUpRequestSucceeded(
                data.access_token,
                data.organization_passphrase,
            ),
        );

        yield put(userRequested(false));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(signUpRequestFailed(errors));
    }
}

export function* requestPasswordReset({ payload }) {
    try {
        const data = yield call(fetchWithoutAuth, '/changepassword', {
            method: 'POST',
            body: {
                newPassword1: payload.password,
                newPassword2: payload.passwordConfirm,
                userId: payload.userId,
                resetToken: payload.token,
                client_secret: getProperties()?.clientSecret,
                client_id: getProperties()?.clientId,
            },
        });
        const returnURL = data.response.returnURL;
        if (!returnURL) {
            yield put(push('/login'));
        }
        yield put(resetPasswordRequestSucceeded(returnURL));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('passwordReset.resetFailed'),
        );
        yield put(resetPasswordRequestFailed(errors));
    }
}

export function* requestPasswordResetLink(action) {
    try {
        const email = action.payload && action.payload.email;
        yield call(fetchWithoutAuth, '/forgotpassword', {
            method: 'POST',
            body: {
                email,
                client_secret: getProperties()?.clientSecret,
                client_id: getProperties()?.clientId,
            },
        });
        yield put(resetPasswordEmailSent());
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('passwordReset.resetSendFailed'),
        );
        yield put(resetPasswordFailedToSend(errors));
    }
}

export function* requestLogout() {
    try {
        setToken(null);
        shutDownIntercom();
        // Wipe states
        yield put(logoutSucceeded());
        yield call(fetchWithAuth, '/logout', {
            method: 'POST',
        });
        yield put(push('/login'));
    } catch (error) {
        // Do nothing
    }
}

export function* submitForm({ payload: { email, ...values } }) {
    trackEvent(EVENT_ONBOARDING_ORGANIZATION_CREATE_BEGAN);
    try {
        const data = yield call(fetchWithoutAuth, '/user/lookup', {
            method: 'POST',
            body: {
                email,
                ...values,
                client_secret: getProperties()?.clientSecret,
                client_id: getProperties()?.clientId,
            },
        });
        const successCode = data.meta.successCode;
        switch (successCode) {
            case 107:
                yield put(
                    initialFormSubmitSucceeded(
                        email,
                        values.resendVerification,
                    ),
                );
                break;
            case 106:
                yield put(redirectionAutofill(email));
                yield put(push('/login'));
                yield put(
                    initialFormSubmitFailed(
                        email,
                        i18n.t('onBoarding.alreadyExists'),
                    ),
                );
                break;
            case 103:
            case 104:
                yield put(redirectionAutofill(email));
                if (getFeatureFlagValue(REMOTE_CONFIG_USER_VIEW_ENABLED)) {
                    yield put(push('/signup/create-account'));
                    yield put(
                        initialFormSubmitFailed(
                            email,
                            i18n.t('onBoarding.domainExists.webSignup'),
                        ),
                    );
                } else {
                    yield put(
                        initialFormSubmitFailed(
                            email,
                            i18n.t('onBoarding.domainExists.appSignup'),
                        ),
                    );
                    yield delay(SNACKBAR_DURATION);
                    yield put(push('/download'));
                }
                break;
            default:
                yield put(
                    initialFormSubmitFailed(email, i18n.t('error.unknown')),
                );
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to send email for verification.',
        );
        yield put(initialFormSubmitFailed(email, errors));
    }
}

export function* verifyEmailAddress({ payload: { email, verificationCode } }) {
    trackEvent(EVENT_ONBOARDING_EMAIL_VERIFIED);

    try {
        yield call(fetchWithoutAuth, '/signup/verification/verify', {
            method: 'POST',
            body: {
                email,
                client_secret: getProperties()?.clientSecret,
                client_id: getProperties()?.clientId,
                verification_code: verificationCode,
            },
        });
        yield put(emailVerificationVerifySucceeded(email));
        yield put(clearEmailVerification());
        yield put(push('/get-started/profile'));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to verify the email address.',
        );
        yield put(emailVerificationVerifyFailed(errors));
    }
}

export function* createUser({
    payload: {
        username,
        password,
        organizationPassphrase,
        marketingEmailOptIn,
    },
}) {
    try {
        const data = yield call(fetchWithoutAuth, '/signup', {
            method: 'POST',
            body: {
                username,
                password,
                organizationPassphrase,
                client_secret: getProperties()?.clientSecret,
                client_id: getProperties()?.clientId,
                marketingEmailOptIn,
            },
        });
        try {
            setToken(data.access_token);
            try {
                yield put(loginRequestSucceeded(data.access_token));
                yield put(createUserSucceeded(data.access_token));
                yield put(push('/signup/create-profile'));
            } catch {
                yield put(
                    createUserFailed([i18n.t('userCreate.stateUpdateFailed')]),
                );
                yield put(push('/login'));
            }
        } catch {
            yield put(createUserFailed([i18n.t('userCreate.setTokenFailed')]));
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to create user',
        );
        yield put(createUserFailed(errors));
        if (
            error instanceof APIErrorResponse &&
            error.getError()?.errorCode === 1
        ) {
            yield put(redirectionAutofill(username));
            yield put(push('/login'));
        }
    }
}

export function* handleSSOLoginFailure({ payload: { error } }) {
    if (error?.errorDescription) {
        yield put(
            loginRequestFailed(error.errorDescription.replaceAll('+', ' ')),
        );
    }

    if (Array.isArray(error)) {
        yield put(loginRequestFailed(error));
    }
    const config = yield select(getSSOConfig);
    switch (config?.returnLocation) {
        case RETURN_LOCATION_SETTINGS:
            yield put(replace('/settings'));
            break;
        case RETURN_LOCATION_ADMIN_SIGNUP:
            yield put(replace('/get-started'));
            break;
        default:
            yield put(replace('/login'));
    }
}

export function* handleSSOAuthRedirect({
    payload: { config, returnLocation },
}) {
    try {
        const res = yield call(
            AuthorizationServiceConfiguration.fetchFromIssuer,
            config.issuer,
            new FetchRequestor(),
        );
        const authorizationHandler = new RedirectRequestHandler();
        const extras = { access_type: 'offline' };

        switch (config.authType) {
            // Google requires a prompt for consent, or it will fail to fetch refresh tokens
            case LOGIN_METHOD_GOOGLE:
                extras.prompt = 'consent';
                break;
            // Okta requires a prompt for login, or it will fail to authenticate if the uses is not already logged in
            case LOGIN_METHOD_OKTA:
                extras.prompt = 'login';
                break;
            // Microsoft and Azure AD require a prompt to select an account, or it will fail to authenticate if the user is not already logged in
            case LOGIN_METHOD_MICROSOFT:
            case LOGIN_METHOD_AZURE_AD:
                extras.prompt = 'select_account';
                break;
            default:
                break;
        }

        const request = new AuthorizationRequest(
            {
                client_id: config.clientId,
                redirect_uri: config.redirectURI,
                scope: config.scopes?.join(' '),
                response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
                extras,
            },
            new DefaultCrypto(),
            config.codeExchangeMethod !== SERVER_SIDE_NON_PKCE,
        );
        authorizationHandler.performAuthorizationRequest(res, request);
        yield put(SSOStoreConfig(config, res, returnLocation));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(loginRequestFailed(errors));
    }
}

function onAuthorizationComplete(config) {
    return (request, response, error) => {
        if (error) {
            store.dispatch(SSOLoginRequestFailed(error));
            return;
        }
        if (config?.config?.codeExchangeMethod === CLIENT_SIDE_PKCE) {
            // For client side PKCE, we do the code exchange on the client, then send the token to the server
            const tokenHandler = new BaseTokenRequestHandler(
                new FetchRequestor(),
            );
            const r = new TokenRequest({
                client_id: config?.config?.clientId,
                redirect_uri: config?.config?.redirectURI,
                grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                code: response.code,
                extras: { code_verifier: request.internal.code_verifier },
            });
            tokenHandler
                .performTokenRequest(config?.serviceConfig, r)
                .catch((e) => {
                    store.dispatch(
                        SSOLoginRequestFailed([
                            'Unable to perform token request with SSO provider',
                        ]),
                    );
                })
                .then((response) => {
                    if (response && response.accessToken) {
                        store.dispatch(
                            SSOLoginRequested(
                                response.accessToken,
                                response.refreshToken,
                            ),
                        );
                    } else {
                        store.dispatch(
                            SSOLoginRequestFailed([
                                'Did not receive a token from the SSO provider',
                            ]),
                        );
                    }
                });
        } else {
            // Otherwise we need to send the code to the server to complete the code exchange
            if (config?.returnLocation === RETURN_LOCATION_SETTINGS) {
                // If we're in the settings, we need to see if we can turn on google auth for the organization
                store.dispatch(
                    requestOIDCIntegration(
                        null,
                        null,
                        response.code,
                        request.internal?.code_verifier,
                    ),
                );
            } else {
                // Otherwise, we're just logging in
                store.dispatch(
                    SSOLoginRequested(
                        null,
                        null,
                        response.code,
                        request.internal?.code_verifier,
                    ),
                );
            }
        }
    };
}

export function* attemptSSOCodeExchange() {
    const config = yield select(getSSOConfig);
    const handler = new RedirectRequestHandler(
        new LocalStorageBackend(),
        new QueryStringUtils(),
        window.location,
    );
    const notifier = new AuthorizationNotifier();
    handler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener(onAuthorizationComplete(config));
    handler.completeAuthorizationRequestIfPossible().catch((e) => {
        store.dispatch(
            SSOLoginRequestFailed([
                'Unable to complete authorization request with SSO provider',
            ]),
        );
    });
}

export function* handleSSOLoginRequest({
    payload: { accessToken, refreshToken, authorizationCode, codeVerifier },
}) {
    const config = yield select(getSSOConfig);
    try {
        const response = yield call(fetchWithoutAuth, `/login/oidc`, {
            method: 'POST',
            body: {
                accessToken,
                refreshToken,
                authorizationCode,
                codeVerifier,
                clientId: config?.config?.clientId,
                client_secret: getProperties()?.clientSecret,
                client_id: getProperties()?.clientId,
                userType:
                    config?.returnLocation === RETURN_LOCATION_ADMIN_SIGNUP
                        ? 'admin'
                        : 'user',
            },
        });
        if (response.access_token) {
            trackEvent(EVENT_ACCOUNT_SSO_LOGGED_IN, {
                auth_type: config?.authType,
            });
            setToken(response.access_token);
            yield put(loginRequestSucceeded(response.access_token));
            yield put(userRequested(true));
        } else if (
            response.meta?.code === 201 &&
            config?.returnLocation === RETURN_LOCATION_ADMIN_SIGNUP
        ) {
            trackEvent(EVENT_ONBOARDING_GOOGLE_ORGANIZATION_CREATED);
            yield put(SSOLoginRequestSucceeded(response.response?.email));
            yield put(replace('/get-started/oauth'));
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(loginRequestFailed(errors));
        if (config?.returnLocation === RETURN_LOCATION_ADMIN_SIGNUP) {
            yield put(replace('/get-started'));
        } else {
            yield put(replace('/login'));
        }
    }
}

export default function* loginSaga() {
    yield all([
        takeLatest(LOGIN_REQUESTED, handleLoginRequest),
        takeLatest(USER_REQUEST_SUCCEEDED, authedRedirect),
        takeLatest(LOGIN_BY_MICROSOFT_REQUEST, handleLoginByMicrosoftRequest),
        takeLatest(LOGIN_BY_OKTA_REQUEST, handleLoginByOktaRequest),
        takeLatest(SIGN_UP_REQUESTED, handleSignUpRequest),
        takeLatest(RESET_PASSWORD_EMAIL_REQUESTED, requestPasswordResetLink),
        takeLatest(RESET_PASSWORD_REQUESTED, requestPasswordReset),
        takeLatest(LOGOUT_REQUESTED, requestLogout),
        takeLatest(INITIAL_FORM_SUBMIT, submitForm),
        takeLatest(EMAIL_VERIFICATION_VERIFY, verifyEmailAddress),
        takeLatest(CREATE_USER_REQUESTED, createUser),
        takeLatest(SSO_AUTH_REQUESTED, handleSSOAuthRedirect),
        takeLatest(SSO_LOGIN_REQUESTED, handleSSOLoginRequest),
        takeLatest(SSO_LOGIN_REQUEST_FAILED, handleSSOLoginFailure),
        takeLatest(SSO_CODE_EXCHANGE_REQUESTED, attemptSSOCodeExchange),
    ]);
}
