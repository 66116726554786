import React from 'react';

import { Can } from '../../casl/ability-context';
import AdminHome from './AdminHome';
import UserActionsScreen from '../ActionsScreen/UserActionsScreen';

export const HomeScreen = () => {
    return (
        <>
            <Can I="view" a="AdminScreens">
                <AdminHome />
            </Can>
            <Can not I="view" a="AdminScreens">
                <UserActionsScreen />
            </Can>
        </>
    );
};

export default HomeScreen;
