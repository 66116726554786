import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

import styles from './styles';
import {
    ActionTrophyPropType,
    BadgeTrophyPropType,
    MedalTrophyPropType,
} from '../../screens/ProfileScreen/propTypes';
import EmptyTrophySection from '../EmptyTrophySection';

const TrophySection = ({
    classes,
    title,
    trophies,
    description,
    hasMultiplier,
    hasBorder,
}) => {
    return trophies?.length > 0 ? (
        <Grid container item direction="column" alignItems="left">
            <Typography className={classes.title} variant="h6">
                {title}
            </Typography>
            <Grid
                className={classes.trophies}
                container
                item
                direction="row"
                spacing={2}
            >
                {trophies.map((trophy, i) => {
                    return (
                        <Grid
                            key={i}
                            item
                            direction="column"
                            justify="center"
                            className={classes.trophyContainer}
                        >
                            <img
                                src={trophy.imageUrl}
                                width="70"
                                alt="trophy"
                                title={trophy.name}
                                className={hasBorder && classes.border}
                            />
                            {hasMultiplier && trophy.multiplier > 1 && (
                                <div className={classes.medalMultiplier}>
                                    <Typography
                                        className={classes.multiplierText}
                                    >{`${trophy.multiplier}x`}</Typography>
                                </div>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    ) : (
        <EmptyTrophySection description={description} title={title} />
    );
};

TrophySection.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    trophies: PropTypes.arrayOf(
        PropTypes.oneOfType([
            ActionTrophyPropType,
            BadgeTrophyPropType,
            MedalTrophyPropType,
        ]),
    ).isRequired,
    hasMultiplier: PropTypes.bool,
    hasBorder: PropTypes.bool,
};

TrophySection.defaultProps = {
    hasMultiplier: false,
    hasBorder: PropTypes.bool,
};

export default withStyles(styles)(TrophySection);
