import React from 'react';
import {
    Grid,
    MenuItem,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FormInput from '../../../Form/FormInput';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { Field } from 'formik';

const SignUpFormFields = ({ showEmailField, showNameFields, showSSOField }) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const EmployeeCountSelector = ({ onChange, ...props }) => {
        /*
         * Modified props object to avoid Material error
         * Material-UI: the key `input` provided to the classes prop is not implemented in ForwardRef(TextField).
         * You can only override one of the following: root.
         * eg: {"name":"empCount","value":"1 - 10","classes":{"root":"makeStyles-root-142 makeStyles-root-183","input":"makeStyles-input-143"}}
         */

        const modifiedProps = {
            name: props.name,
            value: props.value,
            classes: {
                root: props.classes.root,
            },
        };

        const options = ['1-100', '101-1000', '1001-5000', '5000+'];

        return (
            <TextField
                onChange={onChange}
                InputProps={{ disableUnderline: true }}
                className={classes.selectBox}
                fullWidth
                select
                {...modifiedProps}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    const employeeCountField = (
        <Grid item xs={12} md={5} lg={4}>
            <Typography className={classes.inputLabel}>
                {t('onBoarding.community.countLabel')}
            </Typography>
            <FormInput
                noLeftMargin
                noBottomMargin
                as={EmployeeCountSelector}
                name="employeeCount"
            />
        </Grid>
    );

    return (
        <>
            {showNameFields && (
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    spacing={1}
                >
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.inputLabel}>
                            {t('onBoarding.welcome.nameLabels.first')}*
                        </Typography>
                        <FormInput
                            name="firstName"
                            autoComplete="off"
                            placeholder={t(
                                'onBoarding.welcome.nameLabels.first',
                            )}
                            required
                            noLeftMargin
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.inputLabel}>
                            {t('onBoarding.welcome.nameLabels.last')}*
                        </Typography>
                        <FormInput
                            name="lastName"
                            autoComplete="off"
                            placeholder={t(
                                'onBoarding.welcome.nameLabels.last',
                            )}
                            required
                            noLeftMargin
                        />
                    </Grid>
                </Grid>
            )}
            {showEmailField && (
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    spacing={1}
                    className={classes.formRow}
                >
                    <Grid item xs={12} md={7} lg={8}>
                        <Typography className={classes.inputLabel}>
                            {t('email')}*
                        </Typography>
                        <FormInput
                            name="email"
                            placeholder={t('onBoarding.emailPlaceholder')}
                            data-test="emailInput"
                            required
                            noLeftMargin
                        />
                    </Grid>
                    {employeeCountField}
                </Grid>
            )}
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={1}
                className={classes.formRow}
            >
                <Grid item xs={12} md={6} direction="column">
                    <Typography className={classes.inputLabel}>
                        {t('onBoarding.community.nameLabel')}*
                    </Typography>
                    <FormInput
                        name="orgName"
                        placeholder={t('onBoarding.community.namePlaceholder')}
                        required
                        noLeftMargin
                        noBottomMargin
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className={classes.inputLabel}>
                        {t('onBoarding.community.websiteLabel')}
                    </Typography>
                    <FormInput
                        noLeftMargin
                        noBottomMargin
                        name="website"
                        placeholder={t(
                            'onBoarding.community.websitePlaceholder',
                        )}
                    />
                </Grid>
            </Grid>
            {!showEmailField && (
                <Grid
                    container
                    justifyContent="center"
                    direction="row"
                    className={classes.formRow}
                >
                    {employeeCountField}
                    {showSSOField && (
                        <Grid
                            container
                            direction="column"
                            item
                            style={{ marginLeft: 10 }}
                            xs={12}
                            md={5}
                            lg={4}
                        >
                            <Typography className={classes.inputLabel}>
                                {t('onBoarding.enableSSO')}
                            </Typography>
                            <Grid
                                container
                                style={{ flex: 1 }}
                                item
                                alignItems="center"
                            >
                                <Field
                                    type="checkbox"
                                    as={Switch}
                                    name="ssoEnabled"
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

SignUpFormFields.propTypes = {
    showEmailField: PropTypes.bool,
    showNameFields: PropTypes.bool,
    showSSOField: PropTypes.bool,
};

SignUpFormFields.defaultProps = {
    showEmailField: true,
    showNameFields: true,
    showSSOField: false,
};

export default SignUpFormFields;
