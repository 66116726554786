const styles = (theme) => ({
    title: {
        margin: '20px 0 10px',
        fontSize: 24,
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    tabBox: {
        padding: '10px 0 0',
    },
});

export default styles;
