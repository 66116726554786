import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, useTheme } from '@material-ui/core';

import useStyles from './styles';
import { noop } from '../utils';
import ColorImage from '../ColorImage';

const ContentToggleButton = ({
    selected,
    onClick,
    name,
    iconUrl,
    description,
    icon: Icon,
    ...props
}) => {
    const classes = useStyles({ selected, iconUrl });
    const theme = useTheme();

    const [isHovered, setHovered] = useState(false);
    return (
        <Button
            color="primary"
            variant="outlined"
            classes={{
                root: classes.root,
                outlinedPrimary: classes.outlinedPrimary,
            }}
            onClick={onClick}
            {...props}
            className={classes.container}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Grid container>
                <div className={classes.header} />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    {isHovered ? (
                        <Grid item className={classes.descriptionContainer}>
                            <Typography className={classes.descriptionText}>
                                {description}
                            </Typography>
                        </Grid>
                    ) : (
                        <>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                className={classes.iconContainer}
                            >
                                {iconUrl && (
                                    <ColorImage
                                        imageUrl={iconUrl}
                                        imageColor={
                                            selected
                                                ? '#fff'
                                                : theme.palette.primary.main
                                        }
                                        height={45}
                                        width={45}
                                    />
                                )}
                                {Icon && <Icon className={classes.icon} />}
                            </Grid>
                            <Grid item className={classes.nameContainer}>
                                <Typography className={classes.nameText}>
                                    {name}
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Button>
    );
};

ContentToggleButton.propTypes = {
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    name: PropTypes.string,
    description: PropTypes.string,
    iconUrl: PropTypes.string,
    icon: PropTypes.elementType,
};

ContentToggleButton.defaultProps = {
    name: '',
    description: '',
    iconUrl: null,
    onClick: noop,
    icon: null,
};

export default ContentToggleButton;
