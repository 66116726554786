import {
    ACTIVATE_USERS_REQUEST_FAILED,
    ACTIVATE_USERS_REQUEST_SUCCEEDED,
    ACTIVATE_USERS_REQUESTED,
    CLEAR_USER_ERRORS,
    CURRENT_USER_REQUESTED,
    DEACTIVATE_USERS_REQUEST_FAILED,
    DEACTIVATE_USERS_REQUEST_SUCCEEDED,
    DEACTIVATE_USERS_REQUESTED,
    DELETE_USER_ACCOUNT_REQUEST_FAILED,
    DELETE_USER_ACCOUNT_REQUEST_SUCCEEDED,
    DELETE_USER_ACCOUNT_REQUESTED,
    DELETE_USER_REQUEST_FAILED,
    DELETE_USER_REQUEST_SUCCEEDED,
    DELETE_USER_REQUESTED,
    DISMISS_INFO_CARD,
    MEMBERS_REQUEST_FAILED,
    MEMBERS_REQUEST_SUCCEEDED,
    MEMBERS_REQUESTED,
    MEMBERS_SEARCH_REQUEST_FAILED,
    MEMBERS_SEARCH_REQUEST_SUCCEEDED,
    MEMBERS_SEARCH_REQUESTED,
    MOVE_USER_ORGANIZATION_FAILED,
    MOVE_USER_ORGANIZATION_REQUESTED,
    MOVE_USER_ORGANIZATION_SUCCEEDED,
    OTHER_COMMUNITIES_REQUEST_FAILED,
    OTHER_COMMUNITIES_REQUEST_SUCCEEDED,
    OTHER_COMMUNITIES_REQUESTED,
    PERMISSIONS_AND_ROLES_REQUEST_FAILED,
    PERMISSIONS_AND_ROLES_REQUEST_SUCCEEDED,
    PERMISSIONS_AND_ROLES_REQUESTED,
    SET_ACTIVE_COMMUNITY,
    SET_ACTIVE_COMMUNITY_FAILED,
    SET_ACTIVE_COMMUNITY_SUCCEEDED,
    SHOW_INFO_CARD,
    TOGGLE_USER_VIEW,
    UPDATE_USER_COMMUNITIES,
    UPDATE_USER_PERMISSIONS_FAILED,
    UPDATE_USER_PERMISSIONS_REQUESTED,
    UPDATE_USER_PERMISSIONS_SUCCEEDED,
    USER_DETAILS_REQUEST_SUCCEEDED,
    USER_DETAILS_REQUESTED,
    USER_DETAILS_REQUESTED_FAILED,
    USER_EMAIL_UPDATE_FAILED,
    USER_EMAIL_UPDATE_REQUESTED,
    USER_EMAIL_UPDATE_SUCCEEDED,
    USER_REQUEST_FAILED,
    USER_REQUEST_SUCCEEDED,
    USER_UPDATE_FAILED,
    USER_UPDATE_REQUESTED,
    USER_UPDATE_SUCCEEDED,
} from './actions';
import {
    LOGOUT_REQUEST_FAILED,
    LOGOUT_REQUEST_SUCCEEDED,
} from '../auth/actions';
import { ACTIVE_STATUS_ACTIVE, ACTIVE_STATUS_DEACTIVATED } from './constants';
import { setAnalyticsCommunity } from '../utils/analytics';
import { transformCommunityPermission } from './transforms';

const initialState = {
    user: null,
    loading: {
        user: false,
    },
    [ACTIVE_STATUS_ACTIVE]: {
        data: [],
        loading: false,
        totalCount: 0,
    },
    [ACTIVE_STATUS_DEACTIVATED]: {
        data: [],
        loading: false,
        totalCount: 0,
    },
    success: false,
    messages: [],
    errors: [],
    update: {
        loading: false,
        errors: [],
        result: null,
    },
    activeCommunity: {
        loading: false,
        errors: [],
    },
    communities: {
        response: null,
        loading: false,
        errors: [],
    },
    selectedUser: null,
    selectedUserLoading: false,
    dismissedInfoCardIds: [],
    userViewActive: false,
};

const userReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;

    if (type === CURRENT_USER_REQUESTED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                user: true,
            },
        };
    } else if (type === USER_REQUEST_SUCCEEDED) {
        const { user } = payload;
        return {
            ...state,
            user,
            errors: [],
            loading: {
                ...state.loading,
                user: false,
            },
        };
    } else if (type === USER_REQUEST_FAILED) {
        return {
            ...state,
            errors: payload.errors,
            loading: {
                ...state.loading,
                user: false,
            },
        };
    } else if (type === USER_DETAILS_REQUESTED) {
        return {
            ...state,
            selectedUserLoading: true,
        };
    } else if (type === USER_DETAILS_REQUEST_SUCCEEDED) {
        const { user } = payload;
        return {
            ...state,
            selectedUser: user,
            selectedUserErrors: [],
            selectedUserLoading: false,
            selectedUserActivities: [],
        };
    } else if (type === USER_DETAILS_REQUESTED_FAILED) {
        const { errors } = payload;
        return {
            ...state,
            selectedUser: null,
            selectedUserErrors: errors,
            selectedUserLoading: false,
            selectedUserActivities: [],
        };
    } else if (type === SET_ACTIVE_COMMUNITY) {
        const { communityId } = payload;
        return {
            ...state,
            activeCommunity: {
                ...state.activeCommunity,
                ...transformCommunityPermission(
                    state.user.communityPermissions[communityId],
                ),
                loading: true,
            },
        };
    } else if (type === SET_ACTIVE_COMMUNITY_FAILED) {
        return {
            ...state,
            activeCommunity: {
                ...state.activeCommunity,
                loading: false,
            },
        };
    } else if (type === SET_ACTIVE_COMMUNITY_SUCCEEDED) {
        return {
            ...state,
            activeCommunity: {
                ...state.activeCommunity,
                loading: false,
            },
        };
    } else if (
        type === USER_UPDATE_REQUESTED ||
        type === USER_EMAIL_UPDATE_REQUESTED
    ) {
        return {
            ...state,
            update: {
                ...state.update,
                loading: true,
            },
        };
    } else if (
        type === USER_UPDATE_SUCCEEDED ||
        type === USER_EMAIL_UPDATE_SUCCEEDED
    ) {
        return {
            ...state,
            update: {
                ...state.update,
                loading: false,
                errors: [],
                result: payload.response,
            },
        };
    } else if (
        type === USER_UPDATE_FAILED ||
        type === USER_EMAIL_UPDATE_FAILED
    ) {
        return {
            ...state,
            update: {
                ...state.update,
                loading: false,
                errors: payload.errors,
            },
        };
    } else if (
        type === MEMBERS_REQUESTED ||
        type === MEMBERS_SEARCH_REQUESTED
    ) {
        return {
            ...state,
            [payload.activeStatus]: {
                ...state[payload.activeStatus],
                loading: true,
            },
            errors: [],
        };
    } else if (
        type === MEMBERS_REQUEST_SUCCEEDED ||
        type === MEMBERS_SEARCH_REQUEST_SUCCEEDED
    ) {
        const { users: data, totalCount } = payload;
        return {
            ...state,
            [payload.activeStatus]: {
                ...state[payload.activeStatus],
                loading: false,
                data,
                totalCount,
            },
        };
    } else if (
        type === MEMBERS_REQUEST_FAILED ||
        type === MEMBERS_SEARCH_REQUEST_FAILED
    ) {
        return {
            ...state,
            [payload.activeStatus]: {
                ...state[payload.activeStatus],
                loading: false,
                data: [],
                totalCount: 0,
            },
            errors: payload.errors,
        };
    } else if (
        type === DELETE_USER_REQUESTED ||
        type === DELETE_USER_ACCOUNT_REQUESTED ||
        type === UPDATE_USER_PERMISSIONS_REQUESTED ||
        type === MOVE_USER_ORGANIZATION_REQUESTED
    ) {
        return {
            ...state,
            [ACTIVE_STATUS_ACTIVE]: {
                ...state[ACTIVE_STATUS_ACTIVE],
                loading: true,
            },
        };
    } else if (
        type === DELETE_USER_REQUEST_SUCCEEDED ||
        type === DELETE_USER_ACCOUNT_REQUEST_SUCCEEDED ||
        type === UPDATE_USER_PERMISSIONS_SUCCEEDED ||
        type === MOVE_USER_ORGANIZATION_SUCCEEDED
    ) {
        return {
            ...state,
            [ACTIVE_STATUS_ACTIVE]: {
                ...state[ACTIVE_STATUS_ACTIVE],
                loading: false,
            },
        };
    } else if (
        type === DELETE_USER_REQUEST_FAILED ||
        type === DELETE_USER_ACCOUNT_REQUEST_FAILED ||
        type === UPDATE_USER_PERMISSIONS_FAILED ||
        type === MOVE_USER_ORGANIZATION_FAILED
    ) {
        return {
            ...state,
            [ACTIVE_STATUS_ACTIVE]: {
                ...state[ACTIVE_STATUS_ACTIVE],
                loading: false,
            },
            errors: payload.errors,
        };
    } else if (type === PERMISSIONS_AND_ROLES_REQUESTED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                permissions: true,
            },
        };
    } else if (type === PERMISSIONS_AND_ROLES_REQUEST_SUCCEEDED) {
        const { permissions, roles } = payload;
        return {
            ...state,
            permissions,
            roles,
            loading: {
                ...state.loading,
                permissions: false,
            },
        };
    } else if (type === PERMISSIONS_AND_ROLES_REQUEST_FAILED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                permissions: false,
            },
        };
    } else if (
        type === ACTIVATE_USERS_REQUESTED ||
        type === DEACTIVATE_USERS_REQUESTED
    ) {
        return {
            ...state,
            loading: {
                ...state.loading,
                user: true,
            },
        };
    } else if (
        type === ACTIVATE_USERS_REQUEST_SUCCEEDED ||
        type === DEACTIVATE_USERS_REQUEST_SUCCEEDED
    ) {
        return {
            ...state,
            loading: {
                ...state.loading,
                user: false,
            },
        };
    } else if (
        type === ACTIVATE_USERS_REQUEST_FAILED ||
        type === DEACTIVATE_USERS_REQUEST_FAILED
    ) {
        return {
            ...state,
            loading: {
                ...state.loading,
                user: false,
            },
        };
    } else if (type === OTHER_COMMUNITIES_REQUESTED) {
        return {
            ...state,
            communities: {
                loading: true,
                errors: [],
            },
        };
    } else if (type === OTHER_COMMUNITIES_REQUEST_SUCCEEDED) {
        const { response } = payload;
        return {
            ...state,
            communities: {
                loading: false,
                response: response,
            },
        };
    } else if (type === OTHER_COMMUNITIES_REQUEST_FAILED) {
        const { errors } = payload;
        return {
            ...state,
            communities: {
                loading: false,
                errors: errors,
            },
        };
    } else if (type === UPDATE_USER_COMMUNITIES) {
        const { communities } = payload;
        return {
            ...state,
            user: {
                ...state.user,
                communityMemberships: communities,
            },
        };
    } else if (
        type === LOGOUT_REQUEST_SUCCEEDED ||
        type === LOGOUT_REQUEST_FAILED
    ) {
        return initialState;
    } else if (type === CLEAR_USER_ERRORS) {
        return {
            ...state,
            errors: [],
            update: {
                ...state.update,
                errors: [],
            },
        };
    } else if (type === DISMISS_INFO_CARD) {
        if (state.dismissedInfoCardIds.includes(payload.infoCardId)) {
            return state;
        }
        return {
            ...state,
            dismissedInfoCardIds: [
                ...state.dismissedInfoCardIds,
                payload.infoCardId,
            ],
        };
    } else if (type === SHOW_INFO_CARD) {
        const cardIds = state.dismissedInfoCardIds.filter(
            (id) => id !== payload.infoCardId,
        );
        return {
            ...state,
            dismissedInfoCardIds: cardIds,
        };
    } else if (type === TOGGLE_USER_VIEW) {
        const activeCommunity =
            state.user.communityPermissions[state.user.organization.id];

        return {
            ...state,
            userViewActive: !state.userViewActive,
            activeCommunity: activeCommunity,
        };
    } else if (type === 'persist/REHYDRATE') {
        setAnalyticsCommunity(state.activeCommunity);
        return state;
    }
    return state;
};

export default userReducer;
