import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip as TooltipUI } from '@material-ui/core';
import useStyles from './styles';
import { tooltipPlacementPropType } from './propTypes';
import { noop } from '../../utils';

const Tooltip = ({ title, placement, children, onClick, ...props }) => {
    const classes = useStyles();

    if (title === null) {
        props.disableHoverListener = true;
        props.disableFocusListener = true;
    }

    return (
        <TooltipUI
            arrow
            classes={classes}
            title={title}
            placement={placement}
            enterTouchDelay={0}
            {...props}
        >
            <Button onClick={onClick} className={classes.button}>
                {children}
            </Button>
        </TooltipUI>
    );
};

Tooltip.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    placement: tooltipPlacementPropType,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    onClick: PropTypes.func,
};

Tooltip.defaultProps = {
    title: '',
    placement: 'top',
    children: null,
    onClick: noop,
};

export default Tooltip;
