import { getProperties } from '../utils';
import posthog from 'posthog-js';

let firebaseConfig = getProperties()?.firebaseConfig;
let posthogToken = getProperties()?.posthogConfig.token;
// If we're not in production, always use the staging firebase project
if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    firebaseConfig = {
        apiKey: 'AIzaSyCKUPZycanSmMzeuMteBacF14vjh2nM6KM',
        authDomain: 'joulebug-dashboard-staging.firebaseapp.com',
        projectId: 'joulebug-dashboard-staging',
        storageBucket: 'joulebug-dashboard-staging.appspot.com',
        messagingSenderId: '643018100191',
        appId: '1:643018100191:web:8ea835bba96a003231c86a',
        measurementId: 'G-SC249NQ4X3',
    };
    posthogToken = 'phc_WCWf8krBQIcjYVwHiPE16LBqtjH263n8f0KzEQIZFPZ';
}

// Initialize posthog
posthog.init(posthogToken, {
    api_host: 'https://app.posthog.com',
});
// Opt out until we have consent from the user
posthog.opt_out_capturing();
export { firebaseConfig };
