const styles = (theme) => ({
    container: {
        backgroundColor: theme.custom.bgLightAccentColor,
        color: theme.custom.bgDarkColor,
    },
    icon: {
        height: '54px',
        padding: '16px 0',
        margin: '0 30px 0 40px',
    },
    text: {
        padding: '16px 0',
        fontSize: '18px',
        lineHeight: '24px',
    },
});

export default styles;
