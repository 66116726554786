const styles = (theme) => ({
    confirmMessage: {
        paddingBottom: '10px',
    },
    identifier: {
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    description: {
        marginTop: '10px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
});

export default styles;
