const styles = (theme) => ({
    title: {
        fontSize: '18px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 700,
    },
    tooltipIcon: {
        marginLeft: '3px',
        color: 'rgba(51,51,51,.25)',
        cursor: 'pointer',
    },
    charsLeft: {
        marginTop: '5px',
        marginBottom: '10px',
        fontSize: '85%',
        color: '#737373',
    },
    errorBelow: {
        marginTop: '10px',
        marginBottom: '20px',
        color: theme.palette.error.main,
    },
});

export default styles;
