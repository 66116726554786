import { createTheme } from '@material-ui/core/styles';
import { generateTableColumnStyles } from '../utils/styles';
import { getColors, getFontFamily } from '../utils';
import transitions from '@material-ui/core/styles/transitions';

const white = '#ffffff';
const black = 'rgba(0, 0, 0, 0.9)';

export const getPalette = (variantColors) => ({
    white,
    black,
    background: white,
    foreground: black,

    primary: variantColors.primary,
    primaryDark: variantColors.primaryDark,
    primaryForeground: variantColors.primaryForeground,
    secondary: variantColors.secondary,
    secondaryDark: variantColors.secondaryDark,
    accent: variantColors.accent,
    accentDark: variantColors.accentDark,
    warn: variantColors.warn,
    warnDark: variantColors.warnDark,
    loginFooter: variantColors.loginFooter,
    impactTileColor: variantColors.impactTileColor,

    danger: '#EC4C37',
    dangerDark: '#d2322d',
    dangerForeground: white,
    success: '#5cb85c',
    successDark: '#47a447',
    successForeground: black,
    warnForeground: black,
    info: '#5bc0de',
    infoDark: '#31b0d5',
    infoForeground: white,
    textDarkColor: '#333',
    textDarkSecondaryColor: '#2F2F2F',
    textDarkSecondaryMutedColor: '#2F2F2F',
    inputBackground: 'rgba(245, 246, 247, 0.8)',
    lightGray: '#EFEFEF',
    disabledGray: '#959393',
    bgAccentColor: '#d9d9d9',
    bgLightColor: '#f5f6f7',
    bgLightAccentColor: '#F8F8F8',
    disabled: 'rgba(0, 0, 0, 0.38)',
    shadow: 'rgba(0,0,0,0.75)',
});

export const getTheme = (palette) => {
    const custom = {
        headerPadding: 6,
        headerHeight: 50,
        containerPadding: 30,
        exteriorContainerPadding: 80,
        containerMaxWidth: 1460,
        mainContentWidth: '1200px',
        mainContentHorizontalPadding: '16px',
        buttonHoverOpacity: 0.75,
        paginationFontSize: 12,
        actionsAndFiltersWidth: 300,
        actionsAndFiltersWidthForMediumAndDown: 230,
        aqua: '#05BCF4',
        darkBlue: '#022B6E',
        successBlue: '#5C8CD9',
        red: '#8b3f50',
        redDark: '#d9534f',
        red219: '#db655D',
        yammer: '#0072c6',
        okta: '#009CDD',
        editGreen: '#00C14A',
        darkCyan: '#00b89f',
        darkGrey: '#999999', // todo: remove in favor of grey60
        grey: '#EEEEEE',
        grey93: '#edeff0',
        grey88: '#DDDDDD',
        grey83: '#838383',
        grey70: '#707070',
        grey60: '#999999',
        grey20: '#333',
        waste: '#16A779',
        inputBackground: palette.inputBackground,
        inputSeparator: 'rgba(0, 0, 0, 0.05)',
        linkColor: '#1D8DC8',
        linkHoverColor: '#2196F3',
        textLightColor: white,
        textLightSecondaryColor: '#b3b3b3',
        textLightDisabledColor: '#808080',
        textDarkColor: palette.textDarkColor,
        textDarkSecondaryColor: palette.textDarkSecondaryColor,
        textDarkSecondaryMutedColor: palette.textDarkSecondaryMutedColor,
        textDarkDisabledColor: '#bdbdbd',
        bgColor: '#ffffff',
        bgAccentColor: palette.bgAccentColor,
        bgLightColor: palette.bgLightColor,
        bgLightAccentColor: palette.bgLightAccentColor,
        bgDarkColor: '#666666',
        bgDarkAccentColor: '#333333',
        bgSeparator: 'rgba(0, 0, 0, 0.05)',
        bgBlackTransparent: 'rgba(0, 0, 0, 0.5)',
        bgDarkHover: '#d3d3d3',
        tooltipIconColor: 'rgba(51,51,51,.25)',
        tooltipIconHoverColor: '#999',
        iconColor: 'rgba(51, 51, 51, 0.25)',
        iconColorHover: 'rgba(51, 51, 51, 0.5)',
        linkPrimaryHover: '#562732',
        EditingBannerBackground: palette.primary,
        assetIconColor: '#A9A9A9',
        disabledOpacity: 0.5,
        dividerColor: '#E6E6E6',
        dividerTextColor: '#1C1C1C',

        // Colors used in charts
        bgSustainability: 'rgba(19, 167, 121, 1)',
        bgWellness: 'rgba(38, 161, 183, 1)',
        bgCivic: 'rgba(219, 101, 93, 1)',
        bgEngagement: 'rgba(58, 88, 144, 1)',
        bgChartSustainability: 'rgba(19, 167, 121, 0.9)',
        bgChartWellness: 'rgba(38, 161, 183, 0.9)',
        bgChartCivic: 'rgba(219, 101, 93, 0.9)',
        bgChartEngagement: 'rgba(58, 88, 144, 0.9)',
        bgChartDashedColor: 'rgba(153, 153, 153, 0.9)',
        loaderForeground: '#fcfeff',
        loaderBackground: '#dddddd',

        // Colors used in onboarding component
        bgOnboardingFooter: palette.loginFooter,
        bgChallengePreviewOnMobile: '#F4F4F4',
        grayBorder: '#B1B1B1',
        bgMobilePreviewIcon: '#797979',

        impactTileColor: palette.impactTileColor,
    };
    const theme = createTheme({
        palette: {
            primary: {
                main: palette.primary,
                dark: palette.primaryDark,
                contrastText: white,
            },
            secondary: {
                main: palette.secondary,
                dark: palette.secondaryDark,
                contrastText: white,
            },
            default: {
                main: palette.bgLightAccentColor,
                dark: white,
                contrastText: black,
            },
            accent: {
                main: palette.accent,
                dark: palette.accentDark,
                contrastText: white,
            },
            alert: {
                main: palette.primary,
                dark: palette.primaryDark,
                contrastText: white,
            },
            error: {
                main: palette.danger,
                dark: palette.dangerDark,
                contrastText: white,
            },
            warning: {
                main: palette.warn,
                dark: palette.warnDark,
                contrastText: white,
            },
            success: {
                main: palette.success,
                dark: palette.successDark,
                contrastText: white,
            },
            info: {
                main: palette.info,
                dark: palette.infoDark,
                contrastText: white,
            },
            white: {
                main: white,
                dark: '#e6e6e6',
                contrastText: black,
            },
            gray: {
                main: palette.textDarkSecondaryColor,
                contrastText: white,
            },
            disabled: {
                main: palette.disabledGray,
                contrastText: white,
            },
            light: {
                main: palette.bgLightAccentColor,
                dark: palette.bgLightColor,
                contrastText: black,
            },
        },
        typography: {
            fontFamily: `"${getFontFamily() || 'effra'}", sans-serif`,
            useNextVariants: true,
            subtitle1: {
                ':not(.MuiPickersToolbarText-toolbarTxt)': {
                    fontWeight: 100,
                    textTransform: 'uppercase',
                    letterSpacing: 4,
                    fontSize: 28,
                },
            },
            h1: {
                fontWeight: 600,
            },
            h4: {
                fontWeight: 600,
            },
        },
        // Custom variables
        custom: {
            ...custom,
        },
        // Custom component styling
        shineAdmin: {
            select: {
                largeHeight: 35,
                largeMinWidth: 125,
                compactHeight: 25,
                compactMinWidth: 50,
            },
            dashboardPanel: {
                minWidth: 550,
            },
            // Common head cell styling for columns that show up in multiple tables
            tableHeadCells: {
                checkbox: generateTableColumnStyles(70),
                id: generateTableColumnStyles(50),
                name: generateTableColumnStyles(240),
                photo: generateTableColumnStyles(70),
                status: generateTableColumnStyles(120),
                // Note that "auto" essentially dictates this cell is the cell that will stretch to fill remaining space
                date: generateTableColumnStyles('auto'),
            },
            textarea: {
                backgroundColor: palette.inputBackground,
                width: '100%',
                border: '1px solid',
                borderRadius: '8px',
                padding: '6px 10px',
                fontSize: '14px',
                borderColor: palette.primary,
            },
        },
        // Global props for app-wide changes.
        props: {
            MuiButtonBase: {
                disableRipple: true, // We want to disable the button ripple, for the whole app.
            },
        },
        // Overrides for Material UI components
        overrides: {
            MuiSwitch: {
                root: {
                    width: 33,
                    height: 18,
                    padding: 0,
                    display: 'flex',
                    '&:active': {
                        '& .MuiSwitch-thumb': {
                            width: 17,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            transform: 'translateX(14px)',
                        },
                    },
                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        color: custom.grey60,
                        '&.Mui-checked': {
                            transform: 'translateX(16px)',
                            color: palette.primary,
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: custom.grey88,
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        width: 17,
                        height: 17,
                        borderRadius: 9,
                        transition: transitions.create(['width'], {
                            duration: 200,
                        }),
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 18 / 2,
                        opacity: 1,
                        backgroundColor: custom.grey88,
                        boxSizing: 'border-box',
                    },
                },
            },
            MuiPrivateTabScrollButton: {
                root: {
                    width: 6,
                    color: '#003283',
                    marginLeft: 3,
                    marginRight: 3,
                },
            },
            MuiInputBase: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
            MuiFilledInput: {
                input: {
                    marginBottom: 12,
                },
            },
            MuiDialogContentText: {
                root: {
                    textAlign: 'center',
                },
            },
            MuiDialogTitle: {
                root: {
                    textAlign: 'center',
                },
            },
            MuiDialogActions: {
                root: {
                    paddingTop: 20,
                },
            },
            MuiSvgIcon: {
                root: {
                    fontSize: '20px',
                },
                fontSizeSmall: {
                    fontSize: '16px',
                },
                fontSizeLarge: {
                    fontSize: '24px',
                },
            },
            MuiTableCell: {
                root: {
                    paddingLeft: 10,
                    paddingRight: 10,
                },
            },
            MuiButton: {
                root: {
                    minWidth: 'auto',
                    padding: '8px 12px',
                    border: '1px solid transparent',
                    fontSize: '14px',
                    lineHeight: '20px',
                    textTransform: 'capitalize',
                },
                sizeSmall: {
                    height: '30px',
                    padding: '5px 10px',
                    borderRadius: '3px',
                    fontSize: '12px',
                    lineHeight: '18px',
                },
                sizeLarge: {
                    padding: '10px 16px',
                    borderRadius: '6px',
                    fontSize: '18px',
                    lineHeight: '24px',
                },
                outlined: {
                    padding: '8px 12px',
                },
                contained: {
                    boxShadow: 'none',

                    '&:hover, &:active': {
                        boxShadow: 'none',
                    },
                },
            },
            DataTable: {
                root: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                },
                meta: {
                    padding: '20px 0',
                    margin: '0 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    width: 320,
                },
                filters: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                },
                searchContainer: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                },
                search: {
                    flex: 1,
                },
                searchInput: {
                    'label + &': {
                        marginTop: 0,
                    },
                },
                searchControl: {
                    width: '100%',
                },
                tableBodyWithDataRow: {
                    '&:nth-child(2n)': {
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    },
                },
            },
            DataTableColumn: {
                sortArrow: {
                    width: 'auto',
                    height: '1em',
                    marginTop: '-0.5em',
                    marginBottom: '-0.5em',
                },
            },
            MuiTabs: {
                indicator: {
                    display: 'none',
                },
            },
            MuiTab: {
                root: {
                    padding: '10px 16px',
                    fontSize: '14px',
                    fontWeight: 700,
                    color: palette.textDarkSecondaryMutedColor,
                    backgroundColor: 'transparent',
                    borderBottom: `3px solid ${palette.bgAccentColor}`,

                    '&:hover': {
                        color: palette.darkGrey,
                        borderBottomColor: palette.textDarkSecondaryColor,
                    },

                    '&$selected': {
                        backgroundColor: palette.bgAccentColor,
                        borderBottomColor: palette.primary,
                    },
                },
                textColorPrimary: {
                    color: palette.grey,
                },
                wrapper: {
                    textTransform: 'none',
                },
            },
            MuiStepLabel: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                },
                iconContainer: {
                    paddingRight: 0,
                },
                label: {
                    marginTop: '5px',
                    textAlign: 'center',
                    color: '#bdbdbd',

                    '&$active': {
                        fontWeight: 700,
                        color: palette.textDarkColor,
                    },

                    '&$completed': {
                        color: '#bdbdbd',
                    },
                },
            },
            MuiStepper: {
                root: {
                    justifyContent: 'space-between',
                    maxWidth: '400px',
                    width: '100%',
                    margin: '15px auto 60px',
                    padding: 0,
                    backgroundColor: 'transparent',
                },
            },
            MuiStepIcon: {
                root: {
                    width: '40px',
                    height: '40px',
                    margin: '0 auto',
                    padding: 0,
                    border: '1px solid #bdbdbd',
                    borderRadius: '50%',
                    color: 'transparent',

                    '&$active': {
                        borderColor: palette.textDarkColor,
                        color: palette.bgLightColor,
                    },

                    '&$completed': {
                        fill: 'transparent',
                        stroke: '#bdbdbd',
                        border: 0,
                    },
                },
                text: {
                    fill: '#bdbdbd',
                },
                active: {
                    '& .MuiStepIcon-text': {
                        fill: palette.textDarkColor,
                    },
                },
            },
        },
    });
    theme.overrides.DataTable = {
        ...theme.overrides.DataTable,
        checkboxHeadCell: theme.shineAdmin.tableHeadCells.checkbox,
    };

    theme.overrides.DataTableColumn = {
        ...theme.overrides.DataTableColumn,
        headCell: {
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
        },
        bodyCell: {
            color: theme.palette.primary.main,
        },
    };

    theme.overrides.MuiCardActionArea = {
        focusHighlight: {
            backgroundColor: 'transparent',
        },
    };
    return theme;
};
const theme = getTheme(getPalette(getColors()));

export default theme;
