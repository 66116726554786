const styles = (theme) => ({
    container: {
        padding: '0 16px',
        maxWidth: 1280,
        margin: 'auto',
    },
    selectedThemes: {
        marginTop: '30px',
        position: 'relative',
        marginBottom: '30px',
    },
    descriptionsContainer: {
        marginBottom: '30px',
    },
    actionsTable: {
        marginTop: '20px',
    },
});

export default styles;
