import React from 'react';
import { Input as InputUI } from '@material-ui/core';

import useStyles from './styles';

const Input = (props) => {
    const classes = useStyles();
    return (
        <InputUI classes={classes} {...props}>
            {props.children}
        </InputUI>
    );
};

export default Input;
