import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_AZURE_AD } from './constants';

const PUREINDUSTRIAL_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    loginMethods: [LOGIN_METHOD_AZURE_AD],
    appName: 'Joulebug: Pure Industrial',
    clientId: '660c723db906c8000d4aa489',
    clientSecret:
        '02NJRT1wP_ULmX1BOf-B29OBSlfSZDACXu1A_-WHSyNN2oIRKJDcohVeEACzTy1S5N',
    microsoftClientId: '345b9429-dd08-4e8f-ace0-10facbf28f71',
    microsoftClientAuthority:
        'https://login.microsoftonline.com/cf1a8e4b-971c-4a33-83a7-db26afe7a6dc',
    wordpressDomain: 'https://joulebug.com',
    // Flag for enabling the org creation flow
    shouldShowSignUp: false,
    appDescription:
        'With JouleBug, compete in challenges with fellow co-workers to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we work towards common goals.',
    appStoreLink:
        'https://apps.apple.com/us/app/joulebug-enterprise/id1105090085',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.carebug',
};
export default PUREINDUSTRIAL_PROPERTIES;
