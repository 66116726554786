import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import ConfirmModal from '../ConfirmModal';
import PermissionsDropdown from '../PermissionsDropdown';
import CommunityDropdown from '../CommunityDropdown';
import { PERMISSIONS_LIST } from './constants';
import styles from './styles';
import { activeCommunityPropType } from '../../user/propTypes';
import InfoTooltip from '../InfoTooltip';

const PermissionsEditor = ({
    classes,
    isOpen,
    permissions,
    roles,
    userPermissions,
    activeCommunity,
    member,
    onSave,
    onCancel,
    onClose,
}) => {
    const { t } = useTranslation();
    const [checkedPermissions, setCheckedPermissions] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState({
        ...activeCommunity,
        title: activeCommunity?.name || '',
    });
    const [selectedRole, setSelectedRole] = useState(
        member?.communityPermissions[selectedOrganization?.id]?.role,
    );

    const checkRoles = (checkedPermissions) => {
        const numChecked = Object.values(checkedPermissions).reduce(
            (sum, permission) => sum + permission,
            0,
        );
        const possibleRoles = roles.filter((role) => {
            const filterRole = permissions.filter((permission) => {
                return permission.roles.includes(role.internalName);
            });

            if (filterRole.length > 0) {
                return (
                    filterRole.every(
                        (p) => checkedPermissions[p.internalName],
                    ) && filterRole.length === numChecked
                );
            }

            if (role.internalName === 'user') {
                return permissions.every(
                    (p) => !checkedPermissions[p.internalName],
                );
            }
            return [];
        });
        if (!possibleRoles.length) {
            const filterRoles = roles.filter(
                (r) => r.internalName === 'custom',
            );
            if (filterRoles.length !== 0) {
                setSelectedRole(filterRoles[0].internalName);
            }
        } else {
            setSelectedRole(possibleRoles[0].internalName);
        }
    };

    const permissionCheckHandler = (e, parentPermission) => {
        const name = e.target.name;
        const isChecked = e.target.checked;
        let permissions = checkedPermissions;

        if (isChecked) {
            permissions = {
                ...permissions,
                [parentPermission.internalName]: true,
            };
        }
        permissions = {
            ...permissions,
            [name]: isChecked,
        };
        setCheckedPermissions(permissions);
        checkRoles(permissions);
    };

    const parentPermissionCheckHandler = (e, perms) => {
        const name = e.target.name;
        const isChecked = e.target.checked;
        let newPermissions = checkedPermissions;

        if (!isChecked) {
            const permissions = perms.reduce((newPermissions, permission) => {
                newPermissions[permission.internalName] = false;
                return newPermissions;
            }, {});
            newPermissions = {
                ...newPermissions,
                ...permissions,
            };
        }
        newPermissions = {
            ...newPermissions,
            [name]: isChecked,
        };
        setCheckedPermissions(newPermissions);
        checkRoles(newPermissions);
    };

    const confirmHandler = () => {
        const permissions = Object.entries(checkedPermissions).reduce(
            (permissions, [name, checked]) => {
                if (checked) {
                    permissions.push(name);
                }
                return permissions;
            },
            [],
        );
        const body = {
            permissions: permissions.join(','),
            role: selectedRole,
            userIds: member.id,
        };
        onSave(selectedOrganization.id, body);
    };

    const changeRoleHandler = (e) => {
        const { value } = e.target;
        const filteredPermissions = permissions.reduce(
            (newPermissions, permission) => {
                if (permission.roles.includes(value)) {
                    newPermissions[permission.internalName] = true;
                }
                return newPermissions;
            },
            {},
        );
        setCheckedPermissions(filteredPermissions);
        setSelectedRole(value);
    };

    const setUserPermissionsForOrg = useCallback(
        (org) => {
            const selectedUserPermissions =
                member.communityPermissions[org?.id]?.actions;
            const checkedPermissions = selectedUserPermissions?.reduce(
                (permissions, permission) => {
                    permissions[permission] = true;
                    return permissions;
                },
                {},
            );
            setCheckedPermissions(checkedPermissions || {});
        },
        [member.communityPermissions],
    );

    const handleSelectedOrganization = useCallback(
        (org) => {
            setSelectedOrganization(org);
            setUserPermissionsForOrg(org);
            setSelectedRole(member?.communityPermissions[org?.id]?.role || '');
        },
        [member, setUserPermissionsForOrg],
    );

    useEffect(() => {
        const organizations = Object.values(userPermissions).reduce(
            (organizations, value) => {
                if (value?.actions?.includes('setUserRole')) {
                    organizations.push({
                        ...value?.content,
                        title: value?.content?.name,
                        url: value?.content?.imageSizes[0]?.url,
                    });
                }
                return organizations;
            },
            [],
        );
        setOrganizations(organizations);
    }, [userPermissions]);

    useEffect(() => {
        setUserPermissionsForOrg(selectedOrganization);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderTitle = () => (
        <Grid container item>
            <Typography variant="h4" className={classes.title}>
                {t('permissions.edit')}
            </Typography>
            <InfoTooltip placement="right" text={t('viewDocs')} />
        </Grid>
    );

    const getSortedCommunities = useCallback(() => {
        return organizations.sort((org1, org2) =>
            member.communityPermissions[org1.id]?.role?.localeCompare(
                member.communityPermissions[org2.id]?.role,
            ),
        );
    }, [member.communityPermissions, organizations]);

    const renderOrganizations = () => (
        <>
            <Typography variant="h3" className={classes.label}>
                {t('permissions.setForOrg')}
            </Typography>
            <CommunityDropdown
                communities={getSortedCommunities()}
                value={selectedOrganization}
                groupBy={(option) =>
                    member?.communityPermissions[option.id]?.role || t('none')
                }
                onChange={(e, val) => {
                    handleSelectedOrganization(val);
                }}
            />
        </>
    );

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={renderTitle()}
            confirmBtnType="accent"
            confirmBtnText="Save"
            onConfirm={confirmHandler}
            onCancel={onCancel}
            onClose={onClose}
            confirmBtnDisabled={!selectedOrganization}
        >
            <Grid container direction="column">
                <Grid container className={classes.header} direction="column">
                    {organizations.length > 1 && renderOrganizations()}
                    <Typography variant="h3" className={classes.label}>
                        {t('changeRole')}
                    </Typography>
                    <select
                        className={classes.select}
                        onChange={changeRoleHandler}
                        value={selectedRole}
                    >
                        {roles.map((role) => (
                            <option
                                key={role.internalName}
                                value={role.internalName}
                                defaultValue={selectedRole}
                            >
                                {role.name}
                            </option>
                        ))}
                    </select>
                </Grid>
                {PERMISSIONS_LIST.map((permission) => (
                    <PermissionsDropdown
                        key={permission.parentInternalName}
                        permissions={permissions}
                        checkedPermissions={checkedPermissions}
                        parent={permission.parent}
                        parentInternalName={permission.parentInternalName}
                        onPermissionCheck={permissionCheckHandler}
                        onPermissionParentCheck={parentPermissionCheckHandler}
                    />
                ))}
            </Grid>
        </ConfirmModal>
    );
};

PermissionsEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    permissions: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    userPermissions: PropTypes.object.isRequired,
    activeCommunity: activeCommunityPropType.isRequired,
    member: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(PermissionsEditor);
