import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import useStyles from './styles';
import PaymentPlanFooter from '../PaymentPlanFooter';
import Button from '../Button';
import { getCurrencyString } from '../../subscriptions/utils';
import { noop } from '../../utils';
import { trackEvent } from '../../utils/analytics';
import { EVENT_SUBSCRIPTION_PRESS_CONTACT } from '../../constants/analyticsEvents';
import { PARAM_SUBSCRIPTION_ID } from '../../constants/analyticsParams';
import { useHistory } from 'react-router-dom';
import { prettyFormatNumber } from '../transforms';
import CostCalculator from './CostCalculator';
import { SUBSCRIPTION_BUCKET_QUANTITY } from '../../subscriptions/constants';
import { showIntercomChat } from '../../config/intercom';

const SubscriptionTierCard = ({
    tier,
    tierInfo,
    upgradeButtonProps,
    errors,
}) => {
    const isPerUserPricing = !!tier.minUsers;
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles({ isPerUserPricing });
    const [tierActionLoading, setTierActionLoading] = useState(false);

    const [participants, setParticipants] = useState(tier.minUsers);
    const [billYearly, setBillYearly] = useState(true);

    // To be removed after successful merge of JB-1140
    const isImpactHidden = true;

    const getPlan = (interval) => {
        let plan = tier.plans?.find((plan) => plan.interval === interval);
        if (!plan) {
            plan = tier.plans?.[0];
        }
        return plan;
    };

    const handleContactUs = useCallback(() => {
        trackEvent(EVENT_SUBSCRIPTION_PRESS_CONTACT, {
            [PARAM_SUBSCRIPTION_ID]: tier.id,
        });
        // pass query params to the current url so that they can be used as target by intercom bots
        const url = new URL(window.location);
        url.searchParams.set('sales-bot', tier.name);
        window.history.pushState(null, '', url.toString());
        showIntercomChat();
    }, [tier]);

    const handleUpgradeButtonClick = () => {
        setTierActionLoading(true);
        upgradeButtonProps.handler(
            tier.id,
            getPlan(billYearly ? 'year' : 'month'),
            participants,
        );
    };

    const displayImpact = useCallback((amount) => {
        const prettyFormat = prettyFormatNumber(amount, 0, true);
        return prettyFormat.value + prettyFormat.weight + ' ';
    }, []);

    const handleFreePlanClick = () => {
        setTierActionLoading(true);
        history.push('/get-started/challenge');
    };

    const renderPriceOrRelevantDetail = (billYearly) => {
        if (isPerUserPricing) {
            const plan = getPlan(billYearly ? 'year' : 'month');
            let price = plan.price / SUBSCRIPTION_BUCKET_QUANTITY;
            if (billYearly) {
                price = price / 12;
            }
            return getCurrencyString(
                price,
                plan.currency,
                !Number.isInteger(price / 100),
            );
        }
        const plan = getPlan('year');
        if (plan) {
            return (
                getCurrencyString(plan.price / 12, plan.currency) +
                t('account.plan.billingRate')
            );
        } else {
            return tierInfo.rateText;
        }
    };

    const UpgradeButton = ({ actionHandler }) => {
        return (
            <Button
                type="submit"
                className={classNames([
                    classes.upgradeButton,
                    tier.isPopular && classes.popularUpgradeButton,
                ])}
                onClick={actionHandler}
                loading={tierActionLoading}
            >
                {upgradeButtonProps.buttonText}
            </Button>
        );
    };

    const TierAction = () => {
        if (upgradeButtonProps.action === 'contact') {
            return <UpgradeButton actionHandler={handleContactUs} />;
        } else if (upgradeButtonProps.action === 'none') {
            return (
                <UpgradeButton actionHandler={upgradeButtonProps.handler()} />
            );
        } else if (upgradeButtonProps.action === 'free') {
            return <UpgradeButton actionHandler={handleFreePlanClick} />;
        } else {
            return <UpgradeButton actionHandler={handleUpgradeButtonClick} />;
        }
    };

    useEffect(() => {
        if (errors) setTierActionLoading(false);
    }, [errors]);

    return (
        <Grid
            className={classes.container}
            container
            alignItems="center"
            direction="column"
        >
            <Grid
                item
                container
                className={
                    tier.isPopular ? classes.greenHeader : classes.headerGap
                }
                justify="center"
                alignItems="center"
            >
                <Typography className={classes.popularText} align="center">
                    {tier.isPopular
                        ? t('account.paymentTiers.popularTierLabel')
                        : null}
                </Typography>
            </Grid>
            <Grid
                item
                container
                className={classNames([
                    classes.category,
                    tier.isPopular && classes.isPopular,
                ])}
            >
                <Grid item container direction="column">
                    <Typography className={classes.packageType}>
                        {tier.name}
                    </Typography>
                    <Typography className={classes.packageDescription}>
                        {tier.description}
                    </Typography>
                    <Grid
                        container
                        className={classes.packageFee}
                        item
                        wrap="nowrap"
                        alignItems="flex-end"
                    >
                        <span>{renderPriceOrRelevantDetail(billYearly)}</span>
                        <span className={classes.period}>
                            {isPerUserPricing
                                ? t('account.paymentTiers.perUserTier.perUser')
                                : t('account.paymentTiers.billedAnnually')}
                        </span>
                    </Grid>
                    <Typography className={classes.packageUserCount}>
                        {isPerUserPricing
                            ? t('account.paymentTiers.perUserTier.userRange', {
                                  min: tier.minUsers,
                                  increment: SUBSCRIPTION_BUCKET_QUANTITY,
                              })
                            : tierInfo.userCountText}
                    </Typography>
                    {isPerUserPricing && (
                        <Grid className={classes.calculatorContainer}>
                            <CostCalculator
                                billYearly={billYearly}
                                setBillYearly={setBillYearly}
                                participants={participants}
                                setParticipants={setParticipants}
                                minParticipants={tier.minUsers}
                                maxParticipants={100000}
                                monthlyRate={
                                    getPlan('month').price /
                                    100 /
                                    SUBSCRIPTION_BUCKET_QUANTITY
                                }
                                yearlyRate={
                                    getPlan('year').price /
                                    100 /
                                    SUBSCRIPTION_BUCKET_QUANTITY /
                                    12
                                }
                            />
                        </Grid>
                    )}
                    <TierAction />
                    <Typography className={classes.featureTitle}>
                        {tier.featuresHeader}
                    </Typography>
                    <Grid
                        className={classes.features}
                        container
                        item
                        direction="column"
                    >
                        {tier.features?.map((feature) => (
                            <Grid
                                className={classes.featureRow}
                                item
                                container
                                key={feature}
                                alignItems="center"
                            >
                                <DoneIcon
                                    color="primary"
                                    className={classes.doneIcon}
                                />
                                <Typography className={classes.box}>
                                    {feature}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {!isImpactHidden && tier.estimatedImpact?.length > 0 && (
                    <Grid
                        justifyContent="center"
                        className={classes.footerContainer}
                    >
                        <PaymentPlanFooter
                            units={tier.estimatedImpact.map((impact) => ({
                                unit: impact.unit,
                                value: displayImpact(impact.amount),
                                subtitle: impact.name,
                            }))}
                            userCount={tier.quotas?.maxUserCount}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

SubscriptionTierCard.propTypes = {
    tier: PropTypes.object.isRequired,
    tierInfo: PropTypes.shape({
        rateText: PropTypes.string,
        userCountText: PropTypes.string,
    }),
    upgradeButtonProps: PropTypes.shape({
        buttonText: PropTypes.string,
        action: PropTypes.string,
        url: PropTypes.string,
        handler: PropTypes.func,
    }),
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SubscriptionTierCard.defaultProps = {
    tierInfo: { rateText: '', userCountText: '' },
    upgradeButtonProps: { buttonText: '', action: '', url: '', handler: noop },
};

export default SubscriptionTierCard;
