import {
    IMAGE_UPLOAD_REQUEST_FAILED,
    IMAGE_UPLOAD_REQUEST_SUCCEEDED,
    IMAGE_UPLOAD_REQUESTED,
    CLEAR_UPLOADED_IMAGE,
} from './actions';

const initialState = {
    imageUploadResponse: null,
    isImageUploadRequestPending: false,
    imageUploadErrors: [],
    uploadId: '',
};

const imageReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case IMAGE_UPLOAD_REQUESTED:
            return {
                ...state,
                isImageUploadRequestPending: true,
                uploadId: payload.id,
            };
        case IMAGE_UPLOAD_REQUEST_SUCCEEDED:
            return {
                ...state,
                imageUploadResponse: payload.response,
                isImageUploadRequestPending: false,
                imageUploadErrors: [],
            };
        case IMAGE_UPLOAD_REQUEST_FAILED:
            return {
                ...state,
                isImageUploadRequestPending: false,
                imageUploadErrors: payload.errors,
            };
        case CLEAR_UPLOADED_IMAGE:
            return { ...state, imageUploadResponse: null, uploadId: '' };
        default:
            return state;
    }
};

export default imageReducer;
