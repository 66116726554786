import React from 'react';
import useStyles from './styles';
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { noop } from '../utils';
import { Clear } from '@material-ui/icons';

const FormDropdown = ({
    value,
    onChange,
    placeholder,
    items,
    renderItems,
    SelectProps,
    ...props
}) => {
    const classes = useStyles();
    return (
        <TextField
            onChange={onChange}
            InputProps={{
                disableUnderline: true,
                endAdornment: value && (
                    <div
                        className={classes.clear}
                        onClick={() => onChange({ target: { value: '' } })}
                    >
                        <Clear />
                    </div>
                ),
            }}
            SelectProps={{
                classes: {
                    select: classes.select,
                },
                displayEmpty: true,
                renderValue: (value) => {
                    const label = items?.find((item) => item.value === value)
                        ?.label;
                    if (!label) {
                        return <Typography>{placeholder}</Typography>;
                    } else {
                        return <Typography>{label}</Typography>;
                    }
                },
                ...SelectProps,
            }}
            classes={{
                root: classes.inputRoot,
            }}
            value={value}
            fullWidth
            select
            {...props}
        >
            {renderItems
                ? renderItems()
                : items?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                          <Grid item container className={classes.selectItem}>
                              <Typography>{item.label}</Typography>
                          </Grid>
                      </MenuItem>
                  ))}
        </TextField>
    );
};

FormDropdown.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    renderItems: PropTypes.func,
    SelectProps: PropTypes.object,
};

FormDropdown.defaultProps = {
    value: '',
    onChange: noop,
    placeholder: '',
    items: [],
    renderItems: null,
    SelectProps: {},
};

export default FormDropdown;
