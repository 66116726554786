import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import useStyles from './styles';
import IconFormInput from './IconFormInput';
import LargeIconButton from '../../../common/LargeIconButton';
import {
    COMPETITION_COMPETITIVE,
    COMPETITION_GOALS,
    DATETIME_FORMAT,
    GOALS_TYPE_CHALLENGE,
    GOALS_TYPE_TEAM,
    GOALS_TYPE_USER,
    GROUP_INDIVIDUAL,
    GROUP_KEY_TEAM,
    GROUP_TEAM,
    MAX_NAME_LENGTH,
    MAX_TEAM_COUNT,
    MIN_NAME_LENGTH,
    MIN_TEAM_COUNT,
    TEAMS_TYPE_MANUAL,
    TEAMS_TYPE_USER_CREATED,
} from './constants';
import DatePicker from '../../../common/DatePicker';
import { getTimezoneAbbrev } from '../../../utils/dates';
import FormInput from '../../../common/Form/FormInput';
import { noop } from '../../../utils';
import Button from '../../../common/Button';
import Textarea from '../../../common/Textarea';
import ModalTitle from '../../../common/ModalTitle';
import FieldLabel from '../../../common/FieldLabel';
import { INTERCOM_TARGET_PREFIX } from '../constants';
import PhotoAlternatePlaceholder from '../../../common/Photo/PhotoAlternatePlaceholder';
import { CHALLENGE_UNIT_TEAM } from '../../../challenges/constants';
import PhotoUpload from '../../../common/PhotoUpload';

const CustomizeChallenge = ({
    onConfirm,
    onCancel,
    selectedTheme,
    challengeToDuplicate,
}) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    const startDateLabel = useMemo(() => t('challenge.create.startDate'), [t]);
    const endDateLabel = useMemo(() => t('challenge.create.endDate'), [t]);

    const tzCode = getTimezoneAbbrev(i18n.language);

    const [startDate, setStartDate] = useState(null);
    const [startDateValid, setStartDateValid] = useState(true);
    const [endDate, setEndDate] = useState(null);
    const [endDateValid, setEndDateValid] = useState(true);

    const maxStartDate = useMemo(() => moment().add(1, 'years'), []);
    const startDateOrNow = useMemo(
        () => (startDate == null ? moment() : startDate),
        [startDate],
    );
    const maxEndDate = useMemo(() => moment(startDateOrNow).add(1, 'years'), [
        startDateOrNow,
    ]);

    const [selectedGroup, setSelectedGroup] = useState(
        challengeToDuplicate?.challengeUnit === CHALLENGE_UNIT_TEAM
            ? GROUP_TEAM
            : GROUP_INDIVIDUAL,
    );
    const [selectedCompetition, setSelectedCompetition] = useState(
        challengeToDuplicate?.rulesType === COMPETITION_GOALS
            ? COMPETITION_GOALS
            : COMPETITION_COMPETITIVE,
    );

    const [challengeImage, setChallengeImage] = useState(null);
    const initialValues = useMemo(() => {
        if (challengeToDuplicate) {
            const goalsType = challengeToDuplicate?.goalSummary?.team
                ? GOALS_TYPE_TEAM
                : challengeToDuplicate?.goalSummary?.challenge
                ? GOALS_TYPE_CHALLENGE
                : GOALS_TYPE_USER;
            const goalPointValue =
                challengeToDuplicate?.goalSummary?.[goalsType]?.levels?.[0]
                    ?.value;
            return {
                challengeName: challengeToDuplicate?.name,
                challengeDescription:
                    challengeToDuplicate?.basicsInfo?.description,
                teams: challengeToDuplicate?.teams?.length,
                teamsType:
                    challengeToDuplicate?.teamCreation === 'disabled'
                        ? TEAMS_TYPE_MANUAL
                        : TEAMS_TYPE_USER_CREATED,
                goalsType,
                goalPointValue,
            };
        }
        return {
            challengeName: selectedTheme?.challengeName,
            challengeDescription: selectedTheme?.challengeDescription,
            teams: '',
            goalPointValue: null,
            teamsType: TEAMS_TYPE_MANUAL,
            goalsType: GOALS_TYPE_USER,
        };
    }, [challengeToDuplicate, selectedTheme]);

    const errorTeams = useMemo(() => t('challenge.create.teamsLimit'), [t]);
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                teams: Yup.number().test(
                    'teams',
                    () => errorTeams,
                    (value) => {
                        if (selectedGroup === GROUP_TEAM) {
                            return (
                                value >= MIN_TEAM_COUNT &&
                                value <= MAX_TEAM_COUNT
                            );
                        }
                        return true;
                    },
                ),
                challengeName: Yup.string()
                    .required(t('challenge.create.nameRequired'))
                    .min(
                        MIN_NAME_LENGTH,
                        t('challenge.name.length.min', {
                            count: MIN_NAME_LENGTH,
                        }),
                    )
                    .max(MAX_NAME_LENGTH),
                challengeDescription: Yup.string().required(
                    t('challenge.create.descriptionRequired'),
                ),
            }),
        [errorTeams, t, selectedGroup],
    );

    const confirmDisabled = useCallback(
        (formikProps) => {
            return (
                !formikProps.isValid ||
                startDate === null ||
                !startDateValid ||
                endDate === null ||
                !endDateValid ||
                (selectedGroup === GROUP_KEY_TEAM &&
                    !formikProps.values.teams) ||
                (selectedCompetition === COMPETITION_GOALS &&
                    !formikProps.values.goalPointValue)
            );
        },
        [
            startDate,
            startDateValid,
            endDate,
            endDateValid,
            selectedGroup,
            selectedCompetition,
        ],
    );

    const handleConfirm = useCallback(
        (formik) => {
            const formValues = formik.values;
            const values = {
                ...formValues,
                startDate,
                endDate,
                themeId: selectedTheme?.id,
                icon: challengeImage,
                selectedGroup,
                selectedCompetition,
                challengeToDuplicateId: challengeToDuplicate?.id,
            };

            onConfirm(values);
        },
        [
            onConfirm,
            startDate,
            endDate,
            selectedGroup,
            selectedTheme,
            selectedCompetition,
            challengeImage,
            challengeToDuplicate,
        ],
    );

    return (
        <Grid container className={classes.container} justify="flex-end">
            <Button
                color="danger"
                variant="outlined"
                onClick={onCancel}
                className={classes.cancelButton}
            >
                <Clear fontSize="medium" className={classes.clearIcon} />
                <Typography>Cancel</Typography>
            </Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={noop}
            >
                {(formikProps) => (
                    <Form>
                        <Grid container direction="column">
                            <Grid
                                container
                                item
                                justify="space-between"
                                className={classes.header}
                            >
                                <ModalTitle
                                    tooltipText={t('viewDocumentation')}
                                    text={t('challenge.create.customizeTitle')}
                                />
                                {selectedTheme && (
                                    <Typography
                                        variant="h3"
                                        className={classes.headerText}
                                    >
                                        {`${t('theme')}: `}{' '}
                                        {selectedTheme?.name}
                                        {selectedTheme?.iconUrl && (
                                            <img
                                                style={{
                                                    marginLeft: 5,
                                                    height: 30,
                                                }}
                                                src={selectedTheme.iconUrl}
                                                alt={t('alt.themeIcon')}
                                            />
                                        )}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid container item className={classes.body}>
                                <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    className={classes.challengeNameContainer}
                                >
                                    <Grid item className={classes.photo}>
                                        <PhotoUpload
                                            photo={
                                                challengeImage?.file?.url ||
                                                challengeToDuplicate
                                                    ?.imageSizes?.[0]?.url ||
                                                selectedTheme?.challengeIconUrl ||
                                                selectedTheme?.iconUrl
                                            }
                                            onUpload={setChallengeImage}
                                            placeholder={
                                                <PhotoAlternatePlaceholder
                                                    containerStyle={{
                                                        padding: '33px 0px',
                                                    }}
                                                />
                                            }
                                            hideTip
                                            intercomTarget={`${INTERCOM_TARGET_PREFIX}Icon`}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={9}
                                        direction="column"
                                        className={
                                            classes.challengeNameFormContainer
                                        }
                                        justify="space-between"
                                        alignItems="space-between"
                                    >
                                        <Grid
                                            item
                                            className={
                                                classes.challengeNameFormInput
                                            }
                                        >
                                            <FormInput
                                                as={FormInput}
                                                noErrorMessage
                                                borderColor={
                                                    formikProps.errors.name ==
                                                    null
                                                        ? null
                                                        : 'red'
                                                }
                                                name="challengeName"
                                            />
                                        </Grid>
                                        <FormInput
                                            as={Textarea}
                                            name="challengeDescription"
                                            rowsMin={2}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    wrap="nowrap"
                                    justify="space-between"
                                    className={classes.dates}
                                >
                                    <Grid
                                        xs={5}
                                        container
                                        item
                                        direction="column"
                                    >
                                        <FieldLabel
                                            text={`${startDateLabel} (${tzCode})`}
                                            tooltipText={t(
                                                'challenge.duration.start.tooltip',
                                            )}
                                            intercomTarget={`${INTERCOM_TARGET_PREFIX}StartDateLabel`}
                                        />
                                        <DatePicker
                                            as={DateTimePicker}
                                            disablePast
                                            emptyLabel={startDateLabel}
                                            maxDate={maxStartDate}
                                            minDate={moment().add(
                                                '10',
                                                'minutes',
                                            )}
                                            format={DATETIME_FORMAT}
                                            value={startDate}
                                            onChange={setStartDate}
                                            minDateMessage={t(
                                                'datePickers.startDateInFuture',
                                            )}
                                            onError={(errorMsg) =>
                                                errorMsg &&
                                                setStartDateValid(false)
                                            }
                                            onAccept={() =>
                                                setStartDateValid(true)
                                            }
                                            strictCompareDates
                                            data-intercom-target={`${INTERCOM_TARGET_PREFIX}StartDatePicker`}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={5}
                                        container
                                        item
                                        direction="column"
                                    >
                                        <FieldLabel
                                            text={`${endDateLabel} (${tzCode})`}
                                            tooltipText={t(
                                                'challenge.duration.end.tooltip',
                                            )}
                                            intercomTarget={`${INTERCOM_TARGET_PREFIX}EndDateLabel`}
                                        />
                                        <DatePicker
                                            as={DateTimePicker}
                                            emptyLabel={endDateLabel}
                                            minDate={startDateOrNow}
                                            maxDate={maxEndDate}
                                            format={DATETIME_FORMAT}
                                            value={endDate}
                                            onChange={setEndDate}
                                            strictCompareDates
                                            minDateMessage={t(
                                                'datePickers.endAfterStart',
                                            )}
                                            onError={(errorMsg) =>
                                                errorMsg &&
                                                setEndDateValid(false)
                                            }
                                            onAccept={() =>
                                                setEndDateValid(true)
                                            }
                                            data-intercom-target={`${INTERCOM_TARGET_PREFIX}EndDatePicker`}
                                        />
                                    </Grid>
                                </Grid>
                                <FieldLabel
                                    text={t('challenge.create.participantType')}
                                    tooltipText={t(
                                        'challenge.create.participantTypeInfo',
                                    )}
                                    intercomTarget={`${INTERCOM_TARGET_PREFIX}ParticipantTypeLabel`}
                                />
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    wrap="nowrap"
                                    className={classes.groups}
                                    data-intercom-target={`${INTERCOM_TARGET_PREFIX}ParticipantTypeButtonGroup`}
                                >
                                    <Grid
                                        container
                                        item
                                        className={classes.leftGroup}
                                    >
                                        <LargeIconButton
                                            icon="jb-profile"
                                            label={t(
                                                'challenge.create.individuals',
                                            )}
                                            selected={
                                                selectedGroup ===
                                                GROUP_INDIVIDUAL
                                            }
                                            onClick={() =>
                                                setSelectedGroup(
                                                    GROUP_INDIVIDUAL,
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        className={classes.rightGroup}
                                    >
                                        <LargeIconButton
                                            icon="jb-community"
                                            label={t('challenge.teams')}
                                            selected={
                                                selectedGroup === GROUP_TEAM
                                            }
                                            onClick={() =>
                                                setSelectedGroup(GROUP_TEAM)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {selectedGroup === GROUP_TEAM ? (
                                    <Grid container>
                                        <FieldLabel
                                            text={t(
                                                'challenge.create.teamsType',
                                            )}
                                            tooltipText={t(
                                                'challenge.create.teamsTypeInfo',
                                            )}
                                            intercomTarget={`${INTERCOM_TARGET_PREFIX}TeamsTypeLabel`}
                                        />
                                        <Grid
                                            container
                                            justify="space-between"
                                            alignItems="center"
                                            className={
                                                classes.teamsTypeContainer
                                            }
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={6}
                                                direction="column"
                                            >
                                                <Field
                                                    as={RadioGroup}
                                                    name="teamsType"
                                                    className={
                                                        classes.leftGroup
                                                    }
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Radio
                                                                color="primary"
                                                                name="teamsType"
                                                                value={
                                                                    TEAMS_TYPE_MANUAL
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                className={
                                                                    classes.radioButtonLabel
                                                                }
                                                            >
                                                                {t(
                                                                    'challenge.create.manualSetTeams',
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Radio
                                                                color="primary"
                                                                name="teamsType"
                                                                value={
                                                                    TEAMS_TYPE_USER_CREATED
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                className={
                                                                    classes.radioButtonLabel
                                                                }
                                                            >
                                                                {t(
                                                                    'challenge.create.userCreatedTeams',
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    className={
                                                        classes.rightGroup
                                                    }
                                                    noLeftMargin
                                                    name="teams"
                                                    as={IconFormInput}
                                                    fullWidth
                                                    type="number"
                                                    placeholder={
                                                        formikProps.values
                                                            .teamsType ===
                                                        TEAMS_TYPE_MANUAL
                                                            ? t(
                                                                  'challenge.create.howManyTeams',
                                                              )
                                                            : t(
                                                                  'challenge.create.howManyPlayers',
                                                              )
                                                    }
                                                    icon="jb-community"
                                                />
                                                <ErrorMessage
                                                    className={
                                                        classes.errorBelow
                                                    }
                                                    name="teams"
                                                    component="span"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                                <FieldLabel
                                    text={t('challenge.create.competitionType')}
                                    tooltipText={t(
                                        'challenge.create.competitionTypeInfo',
                                    )}
                                    intercomTarget={`${INTERCOM_TARGET_PREFIX}CompetitionTypeLabel`}
                                />
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    wrap="nowrap"
                                    spacing={2}
                                    className={classes.groups}
                                    data-intercom-target={`${INTERCOM_TARGET_PREFIX}CompetitionTypeButtonGroup`}
                                >
                                    <Grid
                                        container
                                        item
                                        className={classes.leftGroup}
                                    >
                                        <LargeIconButton
                                            icon="jb-challenge"
                                            label={t(
                                                'challenge.create.competitive',
                                            )}
                                            selected={
                                                selectedCompetition ===
                                                COMPETITION_COMPETITIVE
                                            }
                                            onClick={() =>
                                                setSelectedCompetition(
                                                    COMPETITION_COMPETITIVE,
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        className={classes.rightGroup}
                                    >
                                        <LargeIconButton
                                            icon="jb-goals"
                                            label={t('challenge.create.goals')}
                                            selected={
                                                selectedCompetition ===
                                                COMPETITION_GOALS
                                            }
                                            onClick={() =>
                                                setSelectedCompetition(
                                                    COMPETITION_GOALS,
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {selectedCompetition === COMPETITION_GOALS ? (
                                    <Grid container align="center">
                                        <FieldLabel
                                            text={t(
                                                'challenge.create.goalsType',
                                            )}
                                            tooltipText={t(
                                                'challenge.create.goalsTypeInfo',
                                            )}
                                            intercomTarget={`${INTERCOM_TARGET_PREFIX}GoalsTypeLabel`}
                                        />
                                        <Grid
                                            container
                                            justify="space-between"
                                            alignItems="center"
                                            className={
                                                classes.teamsTypeContainer
                                            }
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={6}
                                                direction="column"
                                            >
                                                <Field
                                                    as={RadioGroup}
                                                    name="goalsType"
                                                    className={
                                                        classes.leftGroup
                                                    }
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Radio
                                                                color="primary"
                                                                value={
                                                                    selectedGroup ===
                                                                    GROUP_TEAM
                                                                        ? GOALS_TYPE_TEAM
                                                                        : GOALS_TYPE_USER
                                                                }
                                                                name="goalsType"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                className={
                                                                    classes.radioButtonLabel
                                                                }
                                                            >
                                                                {t(
                                                                    selectedGroup ===
                                                                        GROUP_TEAM
                                                                        ? 'challenge.create.teamGoal'
                                                                        : 'challenge.create.userGoal',
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Radio
                                                                color="primary"
                                                                value={
                                                                    GOALS_TYPE_CHALLENGE
                                                                }
                                                                name="goalsType"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                className={
                                                                    classes.radioButtonLabel
                                                                }
                                                            >
                                                                {t(
                                                                    'challenge.create.challengeGoal',
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    className={
                                                        classes.rightGroup
                                                    }
                                                    noLeftMargin
                                                    name="goalPointValue"
                                                    as={IconFormInput}
                                                    fullWidth
                                                    type="number"
                                                    placeholder={t(
                                                        'challenge.create.goalPointValue',
                                                    )}
                                                    icon="jb-points"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid
                                container
                                className={classes.confirmButton}
                                justify="center"
                            >
                                <Grid item xs={6}>
                                    <Button
                                        color="accent"
                                        fullWidth
                                        size="large"
                                        disabled={confirmDisabled(formikProps)}
                                        onClick={() =>
                                            handleConfirm(formikProps)
                                        }
                                        data-intercom-target={`${INTERCOM_TARGET_PREFIX}SubmitButton`}
                                    >
                                        {t('challenge.create.submit')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};

CustomizeChallenge.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    selectedTheme: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        iconUrl: PropTypes.string,
        description: PropTypes.string,
        themeIcon: PropTypes.string,
        contentFilterIcon: PropTypes.string,
        challengeName: PropTypes.string,
        challengeDescription: PropTypes.string,
        challengeIconUrl: PropTypes.string,
    }),
    challengeToDuplicate: PropTypes.object,
};

CustomizeChallenge.defaultProps = {
    onConfirm: noop,
    onCancel: noop,
    requestImageUpload: noop,
    templatesErrors: null,
    selectedTheme: null,
    challengeToDuplicate: null,
};

export default CustomizeChallenge;
