import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../../common/errors';
import { getViewChallengeState } from '../../challenges/selectors';
import { GROUPS } from './constants';

export const getChallengesState = (state) => state.challenges;

export const getGroupState = createSelector(
    getChallengesState,
    (state, group) => group,
    (challengesState, group) => challengesState[group],
);

export const isGroupLoading = createSelector(
    getGroupState,
    (groupState) => groupState?.isLoading,
);

export const getGroupLoadingStates = createSelector(
    getChallengesState,
    (challengesState) => {
        const groups = {};
        GROUPS.forEach((group) => {
            groups[group] = challengesState[group]?.isLoading;
        });
        return groups;
    },
);

export const getGroupErrors = createSelector(getGroupState, (groupState) =>
    coerceIntoErrors(groupState.errors),
);

export const getChallengesScreenActions = createSelector(
    getViewChallengeState,
    (viewChallengeState = {}) => viewChallengeState.challengesScreenActions,
);

export const isChallengesScreenActionLoading = createSelector(
    getChallengesScreenActions,
    (activation = {}) => activation.loading,
);

export const getChallengesScreenActionErrors = createSelector(
    getChallengesScreenActions,
    (challengesScreenActions = {}) =>
        coerceIntoErrors(challengesScreenActions.errors),
);

export const getChallengesScreenActionResponse = createSelector(
    getChallengesScreenActions,
    (challengesScreenActions = {}) => challengesScreenActions.response,
);

export const getGroupChallenges = createSelector(
    getGroupState,
    (groupState) => groupState.challenges,
);

export const getGroupTableData = createSelector(
    getGroupChallenges,
    (challenges) => {
        return (challenges || []).map((challenge) => {
            return {
                ...challenge,
                icon: {
                    url: challenge.icon[0]?.url,
                    communityId: challenge.sponsoringCommunities[0]?.id,
                    communityName: challenge.sponsoringCommunities[0]?.name,
                },
                title: challenge.name,
            };
        });
    },
);
