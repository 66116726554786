import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    header: {
        marginTop: '60px',
        marginBottom: '10px',
    },
    title: {
        fontSize: '36px',
        fontWeight: 600,
    },
    helpIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '5px',

        '&:hover': {
            cursor: 'pointer',
            color: theme.custom.tooltipIconHoverColor,
        },
    },
    duration: {
        fontSize: '23px',
        fontWeight: 400,
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    divider: {
        width: '100%',
        height: '1px',
        border: 'none',
        backgroundColor: theme.custom.bgDarkHover,
        marginTop: '10px',
        marginBottom: '30px',
    },
    loaderWrapper: {
        position: 'relative',
    },
    bottom: {
        height: 0,
    },
}));

export default useStyles;
