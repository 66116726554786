import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import CommunityAvatar from '../CommunityAvatar';
const CommunityCard = ({ title, url }) => {
    return (
        <>
            <CommunityAvatar url={url} title={title} />
            <Typography noWrap>{title}</Typography>
        </>
    );
};

CommunityCard.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default CommunityCard;
