import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import styles from './styles';
import { showIntercomChat } from '../../config/intercom';

const HeaderPage = ({
    classes,
    title,
    subtitle,
    helpLink,
    rightContent,
    underlined,
}) => {
    const { t } = useTranslation();
    const containerStyle = [classes.root];
    if (underlined) {
        containerStyle.push(classes.underlined);
    }
    return (
        <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            className={containerStyle}
        >
            <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                className={classes.titleContainer}
            >
                <Typography variant="h1" className={classes.title}>
                    {title}
                </Typography>
                {typeof helpLink === 'string' ? (
                    <Tooltip title={t('viewDocs')} placement="right">
                        <HelpIcon
                            onClick={() => showIntercomChat()}
                            data-intercom-target="pageHeader"
                            className={classes.link}
                        />
                    </Tooltip>
                ) : (
                    helpLink
                )}
            </Grid>
            <Grid item>{subtitle}</Grid>
            {rightContent ? <Grid item>{rightContent}</Grid> : null}
        </Grid>
    );
};

HeaderPage.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    helpLink: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    rightContent: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    underlined: PropTypes.bool,
};

HeaderPage.defaultProps = {
    subtitle: null,
    helpLink: '',
    rightContent: null,
    underlined: true,
};

export default withStyles(styles)(HeaderPage);
