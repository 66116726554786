import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';

import useStyles from './styles';

import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import Tooltip from '../../common/Tooltip';
import Photo from '../../common/Photo';
import CommonPropTypes from '../../common/propTypes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getAppImages, noop } from '../../utils';
import LimitedLengthInput from '../../common/LimitedLengthInput';
import FormInput from '../../common/Form/FormInput';
import Textarea from '../../common/Textarea';
import Button from '../../common/Button';
import theme from '../../theme';
import { BUTTON_ACCENT_COLOR } from '../../common/Button/constants';
import { CommunityProfilePropType } from '../CommunityProfileScreen';
import {
    communityRequested,
    communityUpdateRequested,
} from '../../community/actions';
import {
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../image/selectors';
import { imageUploadRequested } from '../../image/actions';
import {
    getCommunityErrors,
    getCommunityProfile,
    getCommunityUpdateErrors,
    getCommunityUpdateResult,
    isCommunityLoading,
    isCommunityUpdateLoading,
} from '../../community/selectors';

const MAX_NAME_LENGTH = 150;

const UpdateCommunityProfileScreen = ({
    profileLoading,
    profileErrors,
    profile,
    requestCommunity,
    imageUploading,
    imageUploadErrors,
    imageUploadResponse,
    requestImageUpload,
    updateLoading,
    updateErrors,
    updateResult,
    requestUpdate,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { communityId } = useParams();

    const isPresentable = useMemo(
        () => !profileLoading && profileErrors == null && profile != null,
        [profileLoading, profileErrors, profile],
    );

    useEffect(() => {
        requestCommunity(communityId);
    }, [requestCommunity, communityId]);

    const [profileImage, setProfileImage] = useState(null);
    const [imageSizes, setImageSizes] = useState(null);

    useEffect(() => {
        if (profileImage != null) {
            requestImageUpload({
                communityId,
                file: profileImage,
            });
        }
    }, [profileImage, requestImageUpload, communityId]);

    useEffect(() => {
        if (
            !imageUploading &&
            imageUploadErrors == null &&
            imageUploadResponse != null
        ) {
            setImageSizes(imageUploadResponse);
        }
    }, [imageUploading, imageUploadErrors, imageUploadResponse, setImageSizes]);

    const initialValues = useMemo(
        () => ({
            name: profile?.name,
            description: profile?.description,
        }),
        [profile],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .required()
                    .max(MAX_NAME_LENGTH),
                description: Yup.string(),
            }),
        [],
    );

    const handleNameChange = useCallback((event, formikHandleChange) => {
        if (event.target.value.length <= MAX_NAME_LENGTH) {
            formikHandleChange(event);
        }
    }, []);

    const handleCancel = useCallback(() => {
        history.push(`/communities/${communityId}`);
    }, [communityId, history]);

    const [isUpdateRequestSent, setUpdateRequestSent] = useState(false);

    const handleSave = useCallback(
        ({ name, description }) => {
            requestUpdate({
                communityId,
                name,
                description,
                imageSizes,
            });
            setUpdateRequestSent(true);
        },
        [requestUpdate, communityId, imageSizes, setUpdateRequestSent],
    );

    useEffect(() => {
        if (
            isUpdateRequestSent &&
            !updateLoading &&
            updateErrors == null &&
            updateResult != null
        ) {
            history.push(`/communities/${communityId}`);
        }
    }, [
        isUpdateRequestSent,
        updateLoading,
        updateErrors,
        updateResult,
        history,
        communityId,
    ]);

    return (
        <Grid container direction="column" className={classes.container}>
            <RequestLoader
                isLoading={profileLoading}
                title={`${t('loading.community')}...`}
            />
            <RequestLoader
                isLoading={updateLoading}
                title={`${t('SavingChanges')}...`}
            />
            <ErrorList errors={profileErrors} />
            <ErrorList errors={updateErrors} />
            {isPresentable ? (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={noop}
                >
                    {(formikProps) => (
                        <>
                            <Typography variant="h3" className={classes.title}>
                                {t('Edit Community')}
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                className={classes.body}
                            >
                                <Grid item xs={3} className={classes.photo}>
                                    <Photo
                                        title={t('community.profilePic')}
                                        photo={
                                            profile.image ||
                                            getAppImages().defaultProfileIcon
                                        }
                                        setCroppedImageData={setProfileImage}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={9}
                                    direction="column"
                                    className={classes.formRight}
                                >
                                    <Typography className={classes.label}>
                                        {t('community.name')}
                                        <Tooltip
                                            placement="right"
                                            title={t('community.nameInfo')}
                                        >
                                            <InfoOutlined fontSize="small" />
                                        </Tooltip>
                                    </Typography>
                                    <FormInput
                                        as={LimitedLengthInput}
                                        noBottomMargin
                                        noErrorMessage
                                        borderColor={
                                            formikProps.errors.name == null
                                                ? null
                                                : 'red'
                                        }
                                        name="name"
                                        charactersLeft={
                                            MAX_NAME_LENGTH -
                                            formikProps.values.name.length
                                        }
                                        onChange={(event) =>
                                            handleNameChange(
                                                event,
                                                formikProps.handleChange,
                                            )
                                        }
                                    />
                                    <Typography className={classes.label}>
                                        {t('community.descriptionTitle')}
                                        <Tooltip
                                            placement="right"
                                            title={t('community.description')}
                                        >
                                            <InfoOutlined fontSize="small" />
                                        </Tooltip>
                                    </Typography>
                                    <FormInput
                                        as={Textarea}
                                        name="description"
                                        rowsMin={3}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                justify="flex-end"
                                className={classes.buttons}
                            >
                                <Button
                                    btnColor={theme.custom.bgLightColor}
                                    textColor={theme.custom.textDarkColor}
                                    onClick={handleCancel}
                                >
                                    {t('cancel')}
                                </Button>
                                &nbsp;
                                <Button
                                    color={BUTTON_ACCENT_COLOR}
                                    onClick={() =>
                                        handleSave(formikProps.values)
                                    }
                                    disabled={!formikProps.isValid}
                                >
                                    {t('Save')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Formik>
            ) : null}
        </Grid>
    );
};

UpdateCommunityProfileScreen.propTypes = {
    profileLoading: PropTypes.bool.isRequired,
    profileErrors: CommonPropTypes.errors,
    profile: CommunityProfilePropType,
    requestCommunity: PropTypes.func.isRequired,
    imageUploading: PropTypes.bool.isRequired,
    imageUploadErrors: CommonPropTypes.errors,
    imageUploadResponse: PropTypes.object,
    requestImageUpload: PropTypes.func.isRequired,
    updateLoading: PropTypes.bool.isRequired,
    updateErrors: CommonPropTypes.errors,
    updateResult: PropTypes.object,
    requestUpdate: PropTypes.func.isRequired,
};

UpdateCommunityProfileScreen.defaultProps = {
    profileErrors: null,
    profile: null,
    imageUploadErrors: null,
    imageUploadResponse: null,
    updateErrors: null,
    updateResult: null,
};

const mapStateToProps = (state) => ({
    profileLoading: isCommunityLoading(state),
    profileErrors: getCommunityErrors(state),
    profile: getCommunityProfile(state),
    imageUploading: isImageUploadRequestPending(state),
    imageUploadErrors: getImageUploadErrors(state),
    imageUploadResponse: getImageUploadResponse(state),
    updateLoading: isCommunityUpdateLoading(state),
    updateErrors: getCommunityUpdateErrors(state),
    updateResult: getCommunityUpdateResult(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestCommunity: (request) => dispatch(communityRequested(request)),
    requestImageUpload: (request) => dispatch(imageUploadRequested(request)),
    requestUpdate: (request) => dispatch(communityUpdateRequested(request)),
});

const ConnectedUpdateCommunityProfileScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateCommunityProfileScreen);
export default ConnectedUpdateCommunityProfileScreen;
