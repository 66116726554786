import { AssessmentRounded, Folder, Help } from '@material-ui/icons';
import {
    GRAPH_METRIC_ACTIVE_USERS,
    GRAPH_METRIC_IMPACT_CARBON,
    GRAPH_METRIC_IMPACT_WASTE,
    GRAPH_METRIC_IMPACT_WATER,
    GRAPH_METRIC_PHOTO_ADDED,
    GRAPH_METRIC_TOTAL_LOGS,
} from '../../common/Graph/ChartItem/constants';

export const sections = [
    {
        route: '/analytics',
        label: 'View Analytics',
        icon: AssessmentRounded,
        subject: 'CommunityAnalytics',
    },
    {
        route: '/actions',
        label: 'Modify Actions',
        iconName: 'jb-buzz',
        subject: 'ActionsAdmin',
    },
    {
        route: '/challenges',
        label: 'Manage Challenges',
        iconName: 'jb-challenge',
        subject: 'ChallengesAdmin',
    },
    {
        route: '/notifications',
        label: 'Manage Notifications',
        iconName: 'jb-notifications',
        subject: 'MasterNotifications',
    },
    {
        route: '/members',
        label: 'Manage Users',
        iconName: 'jb-profile',
        subject: 'CommunityMembers',
    },
    {
        route: '/flags',
        label: 'Monitor Flagged Content',
        iconName: 'jb-flag',
        subject: 'FlaggedContent',
    },
    {
        route: '/assets',
        label: 'Download Assets',
        icon: Folder,
        subject: 'Assets',
    },
    {
        route: '/support',
        label: 'View Support & Promotion',
        icon: Help,
        subject: 'Support',
    },
];

export const metrics = [
    GRAPH_METRIC_TOTAL_LOGS,
    GRAPH_METRIC_PHOTO_ADDED,
    GRAPH_METRIC_ACTIVE_USERS,
];

export const impactMetrics = [
    GRAPH_METRIC_IMPACT_WATER,
    GRAPH_METRIC_IMPACT_CARBON,
    GRAPH_METRIC_IMPACT_WASTE,
];

export const globalDateRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/g;
export const dateRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/;
