export default (theme) => ({
    image: {
        width: '40px',
        height: '40px',
        margin: '0 5px 0 0',
        borderRadius: '5px',
    },
    borderIcon: {
        border: `2px solid ${theme.palette.common.white}`,
        borderRadius: '6px 6px 50% 50%',
    },
    loader: {
        margin: '0 5px 0 0',
    },
    hidden: {
        display: 'none',
    },
});
