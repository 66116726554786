export default (theme) => ({
    visualItemWrapper: {
        display: 'flex',
        width: '100%',
        position: 'relative',
    },
    visualFlag: {
        width: '10px',
        borderRadius: '4px 0 0px 4px',
        position: 'absolute',
        right: 0,
        height: '100%',
    },
    visualItem: {
        width: '90%',
        backgroundColor: theme.custom.grey93,
        padding: '15px',
        borderRadius: '0px 4px 4px 0px',
    },
    visualFlagTitleCover: {
        position: 'relative',
        width: '10%',
        background: 'none',
    },
    visualFlagTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,

        position: 'absolute',
        top: '0',
        right: '0',
        width: '100%',
        height: '50%',
        lineHeight: '30px',
        borderRadius: '4px 4px 0 4px',
        fontWeight: 600,
        textAlign: 'center',
        padding: '0 10px',
        fontSize: '13px',
    },
    visualFlagTitleCard: {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1',
    },
    visualFlagTitleText: {
        transform: 'rotate(270deg)',
        position: 'absolute',
        top: '50%',
        width: '100%',
    },
    visualItemHeader: {
        paddingBottom: '10px',
        '& .MuiGrid-root': {},
    },
    icon: {
        width: '20px',
        height: '20px',
    },
    title: {
        fontSize: '16px',
        fontWeight: '600',
        padding: '0 10px',
    },
    visualItemBody: {
        backgroundColor: theme.palette.white.main,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
    },
    visualItemFooter: {
        width: '100%',
        paddingTop: '10px',
    },
    detailButton: {
        width: '25%',
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        border: '2px',
        fontWeight: 600,
        backgroundColor: theme.palette.white.main,
    },
    footerLeft: {
        width: '75%',
        paddingRight: '10px',
        fontSize: '13px',
    },
    metaHeading: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        zIndex: 100,
    },
    totalHeading: {
        fontSize: '24px',
        margin: 0,
        fontWeight: 600,
    },
    goalHeading: {
        color: '#959393',
        fontSize: '11px',
        margin: 0,
    },
    chartWrapper: {
        height: '100%',
        width: '100%',
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        paddingTop: '64px',
    },
    chartInner: {
        height: '82%',
        width: '100%',
    },
});
