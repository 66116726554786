/**
 * Sorts available subscription tiers in the order of free -> premium -> custom
 * @param tiers
 */
export const sortSubscriptionTiers = (tiers) => {
    if (!tiers) {
        return [];
    }
    const sortedOrder = tiers.sort((a, b) => {
        if (!a.quotas.maxUserCount) return 1;
        if (!b.quotas.maxUserCount) return -1;
        if (a.quotas.maxUserCount < b.quotas.maxUserCount) return -1;
        if (a.quotas.maxUserCount > b.quotas.maxUserCount) return 1;
        return 0;
    });

    return (
        (sortedOrder &&
            sortedOrder.map((tier, index) => {
                if (index > 0 && tier.quotas) {
                    const previousMaxUserCount =
                        sortedOrder[index - 1].quotas?.maxUserCount;

                    if (index === sortedOrder.length - 1) {
                        return {
                            ...tier,
                            quotas: {
                                ...tier.quotas,
                                maxUserCount: previousMaxUserCount,
                            },
                        };
                    }

                    return {
                        ...tier,
                        quotas: {
                            ...tier.quotas,
                            minUserCount: previousMaxUserCount + 1,
                        },
                    };
                } else return tier;
            })) ||
        []
    );
};
