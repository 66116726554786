const styles = (theme) => ({
    onBoardingContainer: {
        fontFamily: 'effra,"Helvetica Neue",Helvetica,Arial,sans-serif',
        overflowY: 'auto',
        height: 'calc(100vh - 72px)',
        paddingTop: 95,
        paddingBottom: 55,
        [theme.breakpoints.down('sm')]: {
            padding: '6.5% 0px',
            height: 'auto',
            marginBottom: '26px',
            '@media (orientation: landscape)': {
                padding: '2% 0px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: '5% 0px',
            '@media (orientation: landscape)': {
                padding: '5% 0px',
            },
        },
    },
    socialButtonsSection: {
        [theme.breakpoints.down('md')]: {
            marginTop: '2px',
        },
    },
    middleSection: {
        marginTop: '30px',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
        },
    },
    resendAlertSection: {
        marginTop: '10px',
    },
    addressText: {
        fontWeight: '700',
    },
    alertContainer: {
        [theme.breakpoints.down('md')]: {
            padding: '0px 13px',
            marginBottom: '10px',
        },
    },
    onBoardingSubtitle: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            padding: '0px 13px',
        },
    },
    resendText: {
        color: '#1D8DC7',
        fontWeight: '700',
        textDecoration: 'underline',
        cursor: 'pointer',
        logInTitle: {
            marginBottom: '20px',
        },
    },
    divider: {
        marginTop: 35,
        marginBottom: 35,
    },
});

export default styles;
