export const EMPLOYEE_COUNT_DROPDOWN_VALUES = [
    '1-100',
    '101-1000',
    '1001-5000',
    '5000+',
];

export const SAMPLE_UNITS = [
    {
        value: 250,
        unit: 'kg',
        subtitle: 'CO<sub>2</sub> Saved',
    },
    {
        value: 300,
        unit: 'L',
        subtitle: 'Water Saved',
    },
    {
        value: 320,
        unit: 'kg',
        subtitle: 'Waste Diverted',
    },
];
