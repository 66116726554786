import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import IMAGES from '../../../constants/images';
import useStyles from './styles';
import { getAppImages } from '../../../utils';
import { getRelativeTime } from '../../utils';
import { useHistory } from 'react-router-dom';
import {
    CONTENT_TYPE_PIN,
    CONTENT_TYPES,
} from '../../../constants/contentType';

const ActivityCardHeader = ({
    userName,
    userImage,
    userId,
    activityName,
    activityImage,
    activityCreated,
    contentType,
    contentId,
}) => {
    const classes = useStyles({ userId, contentId, contentType });
    const history = useHistory();
    const onContentClick = (e) => {
        if (contentId && contentType === CONTENT_TYPE_PIN) {
            history.push(`/actions/${contentId}`);
            e.stopPropagation();
        }
    };

    const onProfileClick = (e) => {
        if (userId) {
            history.push(`/profile/${userId}`);
            e.stopPropagation();
        }
    };
    return (
        <Grid
            container
            item
            className={classes.container}
            direction="row"
            wrap="nowrap"
            justify="space-between"
        >
            <Grid
                container
                item
                direction="row"
                wrap="nowrap"
                justify="space-between"
            >
                <Grid
                    item
                    className={classes.userImage}
                    onClick={onProfileClick}
                >
                    <img src={userImage} alt="user" />
                </Grid>
                <Grid
                    container
                    justify="center"
                    direction="column"
                    item
                    alignItems="baseline"
                    className={classes.textBlock}
                >
                    <Grid container item>
                        <Typography
                            onClick={onProfileClick}
                            className={classes.userName}
                        >
                            {userName}
                        </Typography>
                        <Typography className={classes.activityCreated}>
                            {getRelativeTime(activityCreated)}
                        </Typography>
                    </Grid>
                    <Typography>{activityName}</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="column"
                justify="center"
                onClick={onContentClick}
                className={classes.activityImage}
            >
                <img
                    src={activityImage}
                    alt="activity"
                    width="80"
                    height="80"
                />
            </Grid>
        </Grid>
    );
};

ActivityCardHeader.propTypes = {
    userName: PropTypes.string.isRequired,
    userImage: PropTypes.string,
    userId: PropTypes.string.isRequired,
    activityName: PropTypes.string.isRequired,
    activityImage: PropTypes.string,
    activityCreated: PropTypes.string.isRequired,
    contentType: PropTypes.oneOf([CONTENT_TYPES]),
    contentId: PropTypes.string,
};

ActivityCardHeader.defaultProps = {
    userImage: getAppImages().defaultProfileIcon,
    activityImage: IMAGES.defaultPinIcon,
    contentType: null,
    contentId: null,
};

export default ActivityCardHeader;
