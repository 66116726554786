import React, { useState } from 'react';
import Button from '../../Button';
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';
import { withStyles, Grid, Typography, Link } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

const ShareChallenge = ({
    classes,
    url,
    sharingOptions,
    onSocialLinkClicked,
}) => {
    const [toggle, setToggle] = useState(false);
    const { t } = useTranslation();

    const onMouseOver = () => {
        setToggle(true);
    };

    const onCopy = () => {
        copy(url);
        setToggle(false);
    };

    return (
        <div className={classes.mainWrapper}>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-end"
                className={classes.shareActionContainer}
            >
                <Button
                    className={classes.shareButton}
                    variant="outlined"
                    color="primary"
                    onMouseOver={onMouseOver}
                    data-test="shareButton"
                >
                    <ShareIcon className={classes.shareButtonIcon} />
                    {t('activeChallenges.share')}
                </Button>
            </Grid>
            {toggle && (
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.shareChallengeWrapper}
                    data-test="shareOptions"
                >
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.shareChallengeInner}
                    >
                        <Typography className={classes.title} variant="h3">
                            {t('activeChallenges.shareAndInviteOther')}
                        </Typography>

                        {url && (
                            <div
                                data-test="shareUrl"
                                className={classes.textMutedLink}
                            >
                                {url}
                            </div>
                        )}

                        {url && (
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                className={classes.shareOption}
                            >
                                <LinkIcon className={classes.shareOptionIcon} />
                                <Link
                                    data-test="copyLinkButton"
                                    component="button"
                                    onClick={onCopy}
                                >
                                    {t('activeChallenges.copyLinkToClipboard')}
                                </Link>
                            </Grid>
                        )}

                        {sharingOptions &&
                            sharingOptions.map((option) => (
                                <Grid
                                    key={option.type}
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    className={classes.shareOption}
                                >
                                    <div className={classes.shareOptionIcon}>
                                        <img
                                            alt={option.type}
                                            src={option.iconUrl}
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                    <Link
                                        component="button"
                                        data-test={`${option.type}SocialButton`}
                                        onClick={() =>
                                            onSocialLinkClicked(option)
                                        }
                                    >
                                        {option.url}
                                    </Link>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export const SharingOptions = PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
});

ShareChallenge.propTypes = {
    classes: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    sharingOptions: PropTypes.arrayOf(SharingOptions).isRequired,
    onSocialLinkClicked: PropTypes.func.isRequired,
};

ShareChallenge.defaultProps = {
    url: '',
};

export default withStyles(styles)(ShareChallenge);
