import { getFullURI, setToken } from '../utils/api';
import { getProperties } from '../utils';
import {
    LOGIN_METHOD_MICROSOFT,
    LOGIN_METHOD_OKTA,
} from '../properties/constants';
import APIErrorResponse from '../utils/api/APIErrorResponse';

const getUserAgentApplicationInstance = () => {
    return new window.Msal.UserAgentApplication(
        getProperties()?.microsoftClientId,
        getProperties()?.microsoftClientAuthority,
        null,
        {
            navigateToLoginRequestUrl: false,
            redirectUri: `${window.location.protocol}//${window.location.host}/shine/login/`,
        },
    );
};

export const onGetOAuthToken = async (token, accessToken, loginMethod) => {
    const body = {
        client_secret: getProperties()?.clientSecret,
        client_id: getProperties()?.clientId,
    };
    if (loginMethod === LOGIN_METHOD_MICROSOFT) {
        body.microsoftIdToken = token;
        body.microsoftAccessToken = accessToken;
    } else if (loginMethod === LOGIN_METHOD_OKTA) {
        body.authorizationCode = token;
    }
    const res = await fetch(`${getProperties()?.apiUri}/login/${loginMethod}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
    });

    const response = await res.json();

    if (response.meta?.code >= 400 && response.meta?.message) {
        throw Error(response.meta?.message);
    }

    if (response.access_token) {
        setToken(response.access_token);
        return response.access_token;
    }
};

/**
 * Trigger MSAL login flow and retrieve auth token from the server
 * Note that this function will throw if any step of the flow fails.
 * @throws
 * @returns {Promise<*>}
 */
export const microsoftLogin = async () => {
    const graphScopes = ['user.read'];
    const userAgentApplicationInstance = getUserAgentApplicationInstance();
    const idToken = await userAgentApplicationInstance.loginPopup(graphScopes);
    const msalAccessToken = await userAgentApplicationInstance.acquireTokenSilent(
        graphScopes,
    );
    const token = await onGetOAuthToken(
        idToken,
        msalAccessToken,
        LOGIN_METHOD_MICROSOFT,
    );
    return token;
};

export const login = async (data) => {
    const { username, password } = data;

    const uri = getFullURI('/login');
    const res = await fetch(uri, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
            client_secret: getProperties()?.clientSecret,
            client_id: getProperties()?.clientId,
            username,
            password,
        }),
    });

    const body = await res.json();

    if (body.meta && body.meta.code >= 400) {
        const errorResponse = new APIErrorResponse();
        errorResponse.json = body;
        errorResponse.response = res;
        throw errorResponse;
    }

    if (body.access_token) {
        setToken(body.access_token);
        return body.access_token;
    }
};
