const styles = (theme) => ({
    logActionIcon: {
        marginRight: 4,
    },
    form: {
        width: '100%',
    },
    lable: {
        fontWeight: 700,
        fontSize: 14,
        marginBottom: 5,
    },
    closeIcon: {
        opacity: 0.2,

        '&:hover': {
            opacity: 0.5,
            cursor: 'pointer',
        },
    },
    titleBar: {
        padding: '15px',
        fontSize: '18px',
        fontWeight: 100,
    },
    content: {
        padding: 15,
    },
    button: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    buttonCancel: {
        background: 'none',
        marginRight: 10,
    },
    modalFooter: {
        padding: 15,
        marginTop: 10,
    },
    icon: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
});

export default styles;
