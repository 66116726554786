import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table from '../Table';
import styles from './styles';
import { subscriptionRecordsRequested } from '../../subscriptions/actions';
import { getSubscriptionRecords } from '../../subscriptions/selectors';
import { getActiveCommunityId } from '../../user/selectors';
import { noop } from '../../utils';
import billingHistoryColumns, {
    DETAIL_VIEW_COLUMN,
    DOWNLOAD_COLUMN,
} from './constants';

const makeData = (data, columns) =>
    data.map((item) =>
        [...columns, { accessor: 'id' }].reduce((result, call) => {
            if (
                call.accessor === DETAIL_VIEW_COLUMN ||
                call.accessor === DOWNLOAD_COLUMN
            ) {
                return {
                    ...result,
                    [call.accessor]: item[call.accessor],
                };
            }
            return { ...result, [call.accessor]: item[call.accessor] };
        }, []),
    );

const BillingHistory = ({
    fetchBillingRecords,
    classes,
    billingData,
    groupId,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        fetchBillingRecords({ groupId });
    }, [fetchBillingRecords, groupId]);

    const billingHistoryColumn = useMemo(
        () =>
            billingHistoryColumns({ t }, classes).map(({ header, ...cell }) => {
                const headerObj = header
                    ? {
                          Header: () => (
                              <Typography className={classes.green}>
                                  {header}
                              </Typography>
                          ),
                      }
                    : {};
                return {
                    ...headerObj,
                    ...cell,
                };
            }),
        [classes, t],
    );

    const responseFormatter = useCallback(
        (records, billingHistoryColumn) => {
            if (!Array.isArray(records)) {
                return [];
            }
            const preData = records.map(
                ({ formattedAmount, invoiceId, invoice, created }) => {
                    return {
                        date: created,
                        description: invoice.description,
                        amount: `${formattedAmount || '$00.00'} `,
                        detailView: {
                            link: invoice.receiptUrl,
                            text: t('account.paymentRecords.viewLinkLabel'),
                        },
                    };
                },
            );
            return makeData(preData, billingHistoryColumn);
        },
        [t],
    );

    const tableData = useMemo(
        () => responseFormatter(billingData, billingHistoryColumn),
        [billingData, billingHistoryColumn, responseFormatter],
    );

    const [pageCount, setPageCount] = useState(0);

    return (
        <Grid container>
            <Table
                title={t('account.billingHistory.title')}
                noItemFoundText={t('account.billingHistory.noItemFound')}
                columns={billingHistoryColumn}
                data={tableData}
                setPageCount={setPageCount}
                pageCount={pageCount}
            />
        </Grid>
    );
};

BillingHistory.propTypes = {
    billingData: PropTypes.array,
    fetchBillingRecords: PropTypes.func,
    classes: PropTypes.any.isRequired,
    groupId: PropTypes.string,
};

BillingHistory.defaultProps = {
    billingData: [],
    fetchBillingRecords: noop,
    groupId: '',
};

const mapStateToProps = (state) => ({
    billingData: getSubscriptionRecords(state),
    groupId: getActiveCommunityId(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchBillingRecords: ({ groupId }) =>
        dispatch(subscriptionRecordsRequested({ groupId })),
});

export const BillingHistoryStory = withStyles(styles)(BillingHistory);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(BillingHistory));
