import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../common/errors';
import { flaggedContentToTableData } from './transforms';

export const getFlaggedState = (state) => state.flags;

export const isFlaggedContentLoading = createSelector(
    getFlaggedState,
    (flagged) => flagged.loading,
);

export const getFlaggedContentErrors = createSelector(
    getFlaggedState,
    (flagged) => coerceIntoErrors(flagged.errors),
);

export const getFlaggedContent = createSelector(
    getFlaggedState,
    (flagged) => flagged.content,
);

export const getPresentableFlaggedContent = createSelector(
    getFlaggedContent,
    (content) => flaggedContentToTableData(content),
);

export const getFlaggedContentTotalCount = createSelector(
    getFlaggedState,
    (flagged) => flagged.total,
);

export const getFlaggedContentTotal = createSelector(
    getFlaggedState,
    (flagged) => flagged.total,
);

export const getReportPostContentId = createSelector(
    getFlaggedState,
    (state) => state.reportPost.contentId,
);

export const getErrors = createSelector(getFlaggedState, (state) =>
    coerceIntoErrors(state.errors),
);

export const getMessages = createSelector(
    getFlaggedState,
    (state) => state.messages,
);
