const styles = (theme) => ({
    root: {
        paddingBottom: '32px',
    },
    container: {
        width: theme.custom.mainContentWidth,
    },
    button: {
        flexGrow: 1,
        fontSize: '18px',
    },
    buttonIcon: {
        marginRight: '3px',
    },
    noVideos: {
        opacity: 0.5,
        paddingTop: '10px',
        paddingBottom: '10px',
        fontWeight: 600,
    },
});

export default styles;
