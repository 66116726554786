import {
    getString,
    fetchAndActivate,
    getRemoteConfig,
    getBoolean,
} from 'firebase/remote-config';

import posthog from 'posthog-js';

const remoteConfig = getRemoteConfig();
// one hour
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export const REMOTE_CONFIG_SHOW_IMPACT_GRAPHS = 'SHOW_IMPACT_GRAPHS';
export const REMOTE_CONFIG_USER_VIEW_ENABLED =
    'REMOTE_CONFIG_USER_VIEW_ENABLED';
export const REMOTE_CONFIG_SHOW_HABITAT_SAVED_GRAPH =
    'SHOW_HABITAT_SAVED_GRAPH';
export const REMOTE_CONFIG_SHOW_SUBSCRIPTION = 'SHOW_SUBSCRIPTION';
export const REMOTE_CONFIG_SHOW_CHALLENGE_INVITE_MODAL_BUTTON =
    'SHOW_CHALLENGE_INVITE_MODAL_BUTTON';

const defaultConfig = {
    [REMOTE_CONFIG_SHOW_IMPACT_GRAPHS]: true,
    [REMOTE_CONFIG_USER_VIEW_ENABLED]: true,
    [REMOTE_CONFIG_SHOW_HABITAT_SAVED_GRAPH]: false,
    [REMOTE_CONFIG_SHOW_SUBSCRIPTION]: true,
    [REMOTE_CONFIG_SHOW_CHALLENGE_INVITE_MODAL_BUTTON]: false,
};

remoteConfig.defaultConfig = defaultConfig;

fetchAndActivate(remoteConfig);

export const getFeatureFlagValue = (key) => {
    if (process.env.REACT_APP_FEATURE_FLAG_PROVIDER === 'posthog') {
        try {
            return posthog.isFeatureEnabled(key);
        } catch (e) {
            // Posthog issue here when posthog isn't initialized https://github.com/PostHog/posthog-js/issues/283
        }
    } else {
        return getBoolean(remoteConfig, key);
    }
};

export const getStringRemoteConfigValue = (key) => {
    return getString(remoteConfig, key);
};
