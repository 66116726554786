import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import {
    loginByMicrosoftRequested,
    loginRequested,
    SSOAuthRequested,
} from '../../../auth/actions';
import { getAutofillEmail, isAuthLoading } from '../../../auth/selectors';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import IMAGES from '../../../constants/images';
import Button from '../../../common/Button';
import Form from '../../../common/Form';
import FormInput from '../../../common/Form/FormInput';
import FormInputGroup from '../../../common/Form/FormInputGroup';
import styles from './styles';
import { getProperties, noop } from '../../../utils';
import {
    LOGIN_METHOD_ADFS,
    LOGIN_METHOD_AZURE_AD,
    LOGIN_METHOD_EMAIL,
    LOGIN_METHOD_GOOGLE,
    LOGIN_METHOD_MICROSOFT,
    LOGIN_METHOD_OKTA,
} from '../../../properties/constants';
import { useTranslation } from 'react-i18next';
import SignInScreenTemplate from '../index';
import {
    getFeatureFlagValue,
    REMOTE_CONFIG_USER_VIEW_ENABLED,
} from '../../../utils/remoteConfig';
import GoogleButton from '../OAuthButtons/GoogleButton';
import Divider from '../../../common/Divider';

// TODO: add Form component, validation, errors handling
const SignInScreen = ({
    classes,
    onLogin,
    onSSOClick,
    loginLoading,
    email,
}) => {
    const { t } = useTranslation();

    const userForm = { username: email, password: '' };

    const ValidationSchema = Yup.object().shape({
        username: Yup.string()
            .trim()
            .email(t('userCreate.emailInvalid'))
            .required(t('userCreate.inputRequired'))
            .typeError(t('userCreate.emailInvalid')),
        password: Yup.string().required(t('userCreate.inputRequired')),
    });

    const submitHandler = (values) => {
        onLogin({
            username: values.username.trim(),
            password: values.password,
        });
    };

    const authCredentials = getProperties().authCredentials;

    return (
        <SignInScreenTemplate>
            <Typography variant="h3" className={classes.title}>
                {t('signIn.dashboardLogin')}
            </Typography>
            <Typography className={classes.text}>
                {t('signIn.loginDescription')}
            </Typography>
            <Grid container item direction="column" alignItems="center">
                {getProperties().shouldShowSignUp && (
                    <Typography className={classes.createAccountText}>
                        {t('signIn.needToSetUp') + ' '}
                        <Link className={classes.link} to="/get-started">
                            <strong>{t('signIn.getStartedFree')}</strong>
                        </Link>
                    </Typography>
                )}
                {getFeatureFlagValue(REMOTE_CONFIG_USER_VIEW_ENABLED) && (
                    <Typography className={classes.createAccountText}>
                        {t('signIn.newUser') + ' '}
                        <Link
                            className={classes.link}
                            to="/signup/create-account"
                        >
                            <strong>{t('signIn.createAccount')}</strong>
                        </Link>
                    </Typography>
                )}

                {authCredentials?.find(
                    (config) => config.authType === LOGIN_METHOD_EMAIL,
                ) && (
                    <>
                        <Form
                            validationSchema={ValidationSchema}
                            initialValues={userForm}
                            onSubmit={submitHandler}
                            disabled={loginLoading}
                            submitBtnText={t('Submit')}
                        >
                            <FormInputGroup>
                                <Typography className={classes.inputLabel}>
                                    {t('userCreate.emailLabel')}
                                </Typography>
                                <FormInput
                                    name="username"
                                    placeholder={t('userCreate.emailLabel')}
                                />
                                <Typography className={classes.inputLabel}>
                                    {t('userCreate.passwordLabel')}
                                </Typography>
                                <FormInput
                                    name="password"
                                    placeholder={t('userCreate.passwordLabel')}
                                    type="password"
                                />
                            </FormInputGroup>
                        </Form>
                        <Link className={classes.link} to="/forgot-password">
                            {t('signIn.forgotPassword')}
                        </Link>

                        {authCredentials?.length > 1 && (
                            <Divider text={t('or')} />
                        )}
                    </>
                )}
                {authCredentials?.map((config) => {
                    switch (config.authType) {
                        case LOGIN_METHOD_MICROSOFT:
                        case LOGIN_METHOD_AZURE_AD:
                        case LOGIN_METHOD_ADFS:
                            return (
                                <Button
                                    key="microsoft"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => onSSOClick(config)}
                                    className={classes.ssoButton}
                                    data-test="buttonViaMicrosoft"
                                >
                                    <img
                                        src={IMAGES.yammerBtnIcon}
                                        className={classes.buttonIcon}
                                        alt="yammer button icon"
                                    />
                                    <span>
                                        {t('signUp.signInWith', {
                                            provider: 'Microsoft',
                                        })}
                                    </span>
                                </Button>
                            );
                        case LOGIN_METHOD_OKTA:
                            return (
                                <Button
                                    key="okta"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.ssoButton}
                                    onClick={() => onSSOClick(config)}
                                    size="large"
                                >
                                    <img
                                        src={IMAGES.oktaBtnIcon}
                                        className={classes.buttonIcon}
                                        alt="okta button icon"
                                    />
                                    <span>
                                        {t('signUp.signInWith', {
                                            provider: 'Okta',
                                        })}
                                    </span>
                                </Button>
                            );
                        case LOGIN_METHOD_GOOGLE:
                            return (
                                <GoogleButton
                                    key="google"
                                    clientId={config.clientId}
                                    onClick={() => onSSOClick(config)}
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </Grid>
        </SignInScreenTemplate>
    );
};

SignInScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
    onSSOClick: PropTypes.func,
    loginLoading: PropTypes.bool,
    email: PropTypes.string.isRequired,
};

SignInScreen.defaultProps = {
    token: null,
    loginLoading: false,
    email: '',
    onSSOClick: noop,
};

export const StyledSignInScreen = withStyles(styles)(SignInScreen);

const mapStateToProps = (state) => ({
    loginLoading: isAuthLoading(state),
    email: getAutofillEmail(state),
});

const mapDispatchToProps = (dispatch) => ({
    onLogin: (form) => dispatch(loginRequested(form)),
    onLoginByMicrosoft: () => dispatch(loginByMicrosoftRequested()),
    onSSOClick: (config) => dispatch(SSOAuthRequested(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledSignInScreen);
