import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, CircularProgress, Fade } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import Modal from '../Modal';
import Button from '../Button';
import styles from './styles';
import clsx from 'clsx';

const ConfirmModal = ({
    title,
    titleStyle,
    modalStyle,
    children,
    isOpen,
    classes,
    confirmBtnLoading,
    confirmBtnText,
    confirmBtnType,
    confirmBtnColor,
    confirmBtnTextColor,
    confirmBtnDisabled,
    confirmBtnHidden,
    cancelBtnText,
    cancelBtnType,
    cancelBtnColor,
    cancelBtnTextColor,
    cancelBtnDisabled,
    cancelBtnHidden,
    centeredButtons,
    onClose,
    onCancel,
    onConfirm,
    ...modalProps
}) => {
    return (
        <Modal
            classes={{
                scrollPaper: classes.scrollPaper,
                paper: clsx([classes.modal, modalStyle]),
            }}
            isOpen={isOpen}
            onClose={onClose}
            {...modalProps}
        >
            <Grid container>
                <Grid
                    container
                    item
                    justify="space-between"
                    alignItems="center"
                    className={clsx([classes.title, titleStyle])}
                >
                    <Grid item style={{ flex: 1 }}>
                        {title}
                    </Grid>
                    <Grid item>
                        <Close
                            onClick={onClose}
                            fontSize="small"
                            className={classes.closeIcon}
                        />
                    </Grid>
                </Grid>
                <Grid container item className={classes.container}>
                    {children}
                </Grid>
                {(!cancelBtnHidden || !confirmBtnHidden) && (
                    <Grid
                        container
                        item
                        justify={centeredButtons ? 'center' : 'flex-end'}
                        className={classes.footer}
                    >
                        <Fade
                            in={confirmBtnLoading}
                            style={{
                                transitionDelay: confirmBtnLoading
                                    ? '800ms'
                                    : '0ms',
                            }}
                            unmountOnExit
                        >
                            <CircularProgress size={22} />
                        </Fade>
                        {!cancelBtnHidden && (
                            <Button
                                color={cancelBtnType}
                                btnColor={cancelBtnColor}
                                textColor={cancelBtnTextColor}
                                onClick={onCancel}
                                disabled={cancelBtnDisabled}
                                fullWidth={centeredButtons}
                            >
                                {cancelBtnText}
                            </Button>
                        )}
                        {!confirmBtnHidden && (
                            <Button
                                color={confirmBtnType}
                                btnColor={confirmBtnColor}
                                loading={confirmBtnLoading}
                                textColor={confirmBtnTextColor}
                                onClick={onConfirm}
                                className={classes.button}
                                disabled={confirmBtnDisabled}
                                fullWidth={centeredButtons}
                            >
                                {confirmBtnText}
                            </Button>
                        )}
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    titleStyle: PropTypes.object,
    modalStyle: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    confirmBtnLoading: PropTypes.bool,
    confirmBtnText: PropTypes.string,
    confirmBtnType: PropTypes.string,
    confirmBtnColor: PropTypes.string,
    confirmBtnTextColor: PropTypes.string,
    confirmBtnDisabled: PropTypes.bool,
    confirmBtnHidden: PropTypes.bool,
    cancelBtnText: PropTypes.string,
    cancelBtnType: PropTypes.string,
    cancelBtnColor: PropTypes.string,
    cancelBtnTextColor: PropTypes.string,
    cancelBtnDisabled: PropTypes.bool,
    cancelBtnHidden: PropTypes.bool,
    centeredButtons: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
    children: null,
    confirmBtnLoading: false,
    confirmBtnText: 'confirm',
    confirmBtnType: 'default',
    confirmBtnColor: null,
    confirmBtnTextColor: null,
    confirmBtnDisabled: false,
    confirmBtnHidden: false,
    cancelBtnText: 'cancel',
    cancelBtnType: 'default',
    cancelBtnColor: null,
    cancelBtnTextColor: null,
    cancelBtnDisabled: false,
    cancelBtnHidden: false,
    centeredButtons: false,
    titleStyle: {},
    modalStyle: {},
};

export default withStyles(styles)(ConfirmModal);
