import templateStyles from '../styles';

const styles = (theme) => {
    return {
        ...templateStyles(theme),
        photoContainer: {
            position: 'relative',
            height: '200px',
            maxWidth: '160px',
            margin: '20px 0px',
        },
        photoPlaceholder: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    };
};

export default styles;
