import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../common/errors';
import IMAGES from '../constants/images';
import { transformAdminCommunity, transformCommunity } from './transforms';

export const getCommunityState = (state) => state.community;

export const isCommunityLoading = createSelector(
    getCommunityState,
    (communityState) => communityState.loading,
);

export const getCommunityErrors = createSelector(
    getCommunityState,
    (communityState) => coerceIntoErrors(communityState.errors),
);

export const getCommunity = createSelector(
    getCommunityState,
    (communityState) => communityState.community,
);

export const getCommunityProfile = createSelector(getCommunity, (community) =>
    transformCommunity(community),
);

export const getCommunityAccessCode = createSelector(
    getCommunity,
    (community) => community?.orgAccessCode,
);

export const getCommunityUpdateState = createSelector(
    getCommunityState,
    (communityState) => communityState.update,
);

export const isCommunityUpdateLoading = createSelector(
    getCommunityUpdateState,
    (update) => update.loading,
);

export const getCommunityUpdateErrors = createSelector(
    getCommunityUpdateState,
    (update) => coerceIntoErrors(update.errors),
);

export const getCommunityUpdateResult = createSelector(
    getCommunityUpdateState,
    (update) => update.result,
);

export const getCommunityAssets = createSelector(
    getCommunity,
    (community) => community?.communityAssets,
);

export const getPresentableCommunityAssets = createSelector(
    getCommunityAssets,
    (assets) =>
        assets?.reduce((result, asset) => {
            if (asset.assetFileType === 'image') {
                return [
                    ...result,
                    {
                        title: asset.name,
                        imageUrl: asset.image?.sizes?.[0]?.file?.url,
                        assetUrl: asset.image?.sizes?.[0]?.file?.url,
                        assetType: asset.assetFileType,
                    },
                ];
            }

            const fileUrl = asset.file?.url;
            const fileType = (/[.]/.exec(fileUrl)
                ? /[^.]+$/.exec(fileUrl)[0]
                : 'other'
            ).toUpperCase();

            return [
                ...result,
                {
                    title: asset.name,
                    imageUrl: IMAGES[fileType] || IMAGES.OTHER,
                    assetUrl: fileUrl,
                    assetType: asset.assetFileType,
                    description: asset.description,
                },
            ];
        }, []),
);
export const getAccountPlan = createSelector(
    getCommunityState,
    (communityState) => communityState.accountPlan,
);

export const isJoinRequestLoading = createSelector(
    getCommunityState,
    (communityState) => communityState.join?.loading,
);

export const getJoinRequestErrors = createSelector(
    getCommunityState,
    (communityState) => coerceIntoErrors(communityState.join?.errors),
);

export const isUnjoinRequestLoading = createSelector(
    getCommunityState,
    (communityState) => communityState.unjoin?.loading,
);

export const getUnjoinRequestErrors = createSelector(
    getCommunityState,
    (communityState) => coerceIntoErrors(communityState.unjoin?.errors),
);

export const getCommunityInviteLink = createSelector(
    getCommunityState,
    (communityState) => communityState.url,
);

export const isCommunityInviteLinkLoading = createSelector(
    getCommunityState,
    (communityState) => communityState.inviteLink?.loading,
);

export const getCommunityInviteLinkErrors = createSelector(
    getCommunityState,
    (communityState) => communityState.inviteLink?.errors,
);

export const communitiesLoading = createSelector(
    getCommunityState,
    (state) => state.communities?.loading,
);

export const getCommunitiesErrors = createSelector(getCommunityState, (state) =>
    coerceIntoErrors(state.communities?.errors),
);

export const getCommunities = createSelector(
    getCommunityState,
    (state) =>
        state.communities.communities.map((community) =>
            transformCommunity(community),
        ) || [],
);

export const getCommunitiesAdmin = createSelector(getCommunityState, (state) =>
    state.communities.communities.map((community) =>
        transformAdminCommunity(community),
    ),
);

export const createCommunityLoading = createSelector(
    getCommunityState,
    (state) => state.create?.loading,
);

export const createCommunityErrors = createSelector(
    getCommunityState,
    (state) => coerceIntoErrors(state.create?.errors),
);

export const getSearchedCommunities = createSelector(
    getCommunityState,
    (communityState) => communityState.search?.response,
);

export const isCommunitySearchLoading = createSelector(
    getCommunityState,
    (communityState) => communityState.search?.isLoading,
);

export const getCommunityShareImage = createSelector(
    getCommunityState,
    (communityState) => communityState.shareImage?.url,
);

export const isCommunityShareImageLoading = createSelector(
    getCommunityState,
    (communityState) => communityState.shareImage?.loading,
);

export const getCommunityShareImageErrors = createSelector(
    getCommunityState,
    (communityState) => communityState.shareImage?.errors,
);

export const getCommunityBulkJoinResponse = createSelector(
    getCommunityState,
    (communityState) => communityState.bulkJoin?.response,
);

export const isCommunityBulkJoinLoading = createSelector(
    getCommunityState,
    (communityState) => communityState.bulkJoin?.loading,
);

export const getCommunityBulkJoinErrors = createSelector(
    getCommunityState,
    (communityState) => communityState.bulkJoin?.errors,
);
