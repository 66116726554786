import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { noop } from '../../utils';
import UpdateTextModal from '../UpdateTextModal';

const ChallengeRules = ({
    isOpen,
    setOpen,
    text,
    onClose,
    onCancel,
    onConfirm,
}) => {
    const { t } = useTranslation();

    return (
        <UpdateTextModal
            isOpen={isOpen}
            setOpen={setOpen}
            title={t('challenge.rulesFieldTitle')}
            label={t('challenge.rulesFieldLabel')}
            text={text}
            onClose={onClose}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
};

ChallengeRules.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
};

ChallengeRules.defaultProps = {
    onClose: noop,
    onCancel: noop,
};

export default ChallengeRules;
