import {
    CLEAR_SETTINGS_RESPONSE,
    INTEGRATIONS_REQUEST_FAILED,
    INTEGRATIONS_REQUEST_SUCCEEDED,
    INTEGRATIONS_REQUESTED,
    OIDC_INTEGRATION_REMOVE_REQUESTED,
    SET_ACTION_DUPLICATION_COMMUNITIES,
} from './actions';
import { LOGOUT_REQUESTED } from '../../auth/actions';
import { SET_ACTIVE_COMMUNITY } from '../../user/actions';

const initialState = {
    actionDuplicationCommunities: [],
    loading: false,
    errors: [],
    response: [],
    integrations: {
        slack: null,
        oidc: [],
    },
    isAccountUpgradeNotificationVisible: true,
};

const settingsReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case SET_ACTION_DUPLICATION_COMMUNITIES:
            return {
                ...state,
                actionDuplicationCommunities: payload.communities,
            };
        case LOGOUT_REQUESTED:
        case SET_ACTIVE_COMMUNITY:
            return initialState;
        case INTEGRATIONS_REQUESTED:
        case OIDC_INTEGRATION_REMOVE_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case INTEGRATIONS_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                errors: payload.errors,
            };
        case INTEGRATIONS_REQUEST_SUCCEEDED:
            return {
                ...state,
                loading: false,
                response: payload.responseMessage,
                integrations: payload.integrations,
            };
        case CLEAR_SETTINGS_RESPONSE:
            return {
                ...state,
                errors: [],
                response: [],
            };
        default:
            return state;
    }
};

export default settingsReducer;
