const styles = (theme) => ({
    bold: {
        fontWeight: 'bold',
    },
    small: {
        fontSize: '12px !important',
    },
});

export default styles;
