import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import useStyles from './styles';

const BonusPoints = ({ count, title, imageUrl }) => {
    const classes = useStyles({ imageUrl });

    return (
        <Grid container item direction="column" className={classes.container}>
            <Grid item className={classes.count}>
                {count}
            </Grid>
            <Grid item className={classes.title}>
                {title}
            </Grid>
        </Grid>
    );
};

BonusPoints.propTypes = {
    count: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
};

export default BonusPoints;
