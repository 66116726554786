import React, { useEffect, useState } from 'react';
import PhotoPropTypes from './propTypes';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import ConfirmModal from '../ConfirmModal';
import { dataURLtoFile } from '../../utils';
import useStyles from './styles';

import 'react-image-crop/dist/ReactCrop.css';
import Upload from '../Upload';

const Photo = ({
    photo,
    setCroppedImageData,
    initCrop,
    CropProps,
    intercomTarget,
    ...props
}) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(photo);
    const [imageRef, setImageRef] = useState(null);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [crop, setCrop] = useState(
        initCrop || {
            aspect: 1,
            unit: 'px',
            width: 80,
        },
    );

    const classes = useStyles({
        hasImage: !!image,
    });

    const onCancel = () => {
        setImage(photo);
        setOpen(false);
    };

    const onConfirm = () => {
        getCroppedImg(imageRef, crop);
        setOpen(false);
    };

    const onDropAccepted = (acceptedFiles) => {
        if (acceptedFiles?.[0]) {
            setImageToCrop(URL.createObjectURL(acceptedFiles[0]));
            setOpen(true);
        }
    };

    const onImageLoaded = (image) => {
        setImageRef(image);
    };

    const getCroppedImg = async (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const ctx = canvas.getContext('2d');

        if (!crop.height || !crop.width) {
            canvas.width = image.width;
            canvas.height = image.height;
        } else {
            canvas.width = Math.ceil(crop.width * scaleX);
            canvas.height = Math.ceil(crop.height * scaleY);
        }

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        const base64Image = canvas.toDataURL('image/jpeg', 1.0);
        const file = dataURLtoFile(base64Image, 'cropped.jpg');

        setImage(base64Image);
        setCroppedImageData(file);
    };

    useEffect(() => {
        setImage(photo);
    }, [photo]);

    return (
        <Grid
            container
            item
            className={classes.photoContainer}
            data-intercom-target={intercomTarget}
        >
            <Upload
                value={image}
                onDropAccepted={onDropAccepted}
                CropProps={CropProps}
                {...props}
            />
            <ConfirmModal
                title={t('photo.crop')}
                isOpen={open}
                onClose={onCancel}
                onCancel={onCancel}
                onConfirm={onConfirm}
                confirmBtnType="accent"
                className={classes.modal}
            >
                <Grid container justify="center" alignItems="center">
                    <Grid
                        container
                        className={classes.container}
                        justify="center"
                        alignItems="center"
                    >
                        <ReactCrop
                            minHeight={80}
                            minWidth={80}
                            src={imageToCrop}
                            crop={crop}
                            onChange={(newCrop) => {
                                if (newCrop.width > 0 && newCrop.height > 0) {
                                    setCrop(newCrop);
                                }
                            }}
                            onImageLoaded={onImageLoaded}
                            {...CropProps}
                        />
                    </Grid>
                </Grid>
            </ConfirmModal>
        </Grid>
    );
};

Photo.propTypes = PhotoPropTypes.photo;

Photo.defaultProps = {
    photoTooltip: null,
    placeholder: null,
    maxHeight: null,
    photoTooltipKey: 'photo.tooltip',
    DropzoneProps: {
        maxSize: 10000000,
    },
    CropProps: {},
    initCrop: null,
    hideTip: false,
    border: true,
    borderRadius: '0px',
    disabled: false,
    photo: null,
    intercomTarget: null,
};

export default Photo;
