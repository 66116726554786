import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import CommunityCard from './CommunityCard';
import { noop } from '../../../utils';

const CommunityList = ({
    classes,
    joinedCommunities,
    otherCommunities,
    onView,
    onJoin,
    onLeave,
    isSearch,
}) => {
    const { t } = useTranslation();

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography className={classes.title}>
                    {t('settings.user.communities.joinedTitle')}
                </Typography>
            </Grid>
            {joinedCommunities?.length < 1 && (
                <Typography className={classes.noChallengesText}>
                    {isSearch
                        ? t('settings.user.communities.noCommunitiesSearch')
                        : t('settings.user.communities.noCommunities')}
                </Typography>
            )}
            {joinedCommunities?.map((community, i) => (
                <Grid
                    className={classes.cardContainer}
                    item
                    key={community.id + i}
                >
                    <CommunityCard
                        name={community.name}
                        memberCount={community.memberCount}
                        image={community.imageSizes?.[0]?.url}
                        url={community.url}
                        membershipStatus={community.membershipStatus}
                        joinEligibility={community.joinEligibility}
                        unjoinEligibility={community.unjoinEligibility}
                        onView={onView}
                        onJoin={onJoin}
                        onLeave={onLeave}
                    />
                </Grid>
            ))}
            <Grid item>
                <Typography className={classes.title}>
                    {t('settings.user.communities.availableTitle')}
                </Typography>
            </Grid>
            {otherCommunities &&
                otherCommunities.map(
                    (community, i) =>
                        !joinedCommunities
                            ?.map((com) => com.id)
                            .includes(community.id) && (
                            <Grid
                                className={classes.cardContainer}
                                item
                                key={i}
                            >
                                <CommunityCard
                                    id={community.id}
                                    name={community.name}
                                    memberCount={community.memberCount}
                                    image={community.imageSizes?.[0]?.url}
                                    url={community.url}
                                    membershipStatus={
                                        community.membershipStatus
                                    }
                                    joinEligibility={community.joinEligibility}
                                    unjoinEligibility={
                                        community.unjoinEligibility
                                    }
                                    onView={onView}
                                    onJoin={onJoin}
                                    onLeave={onLeave}
                                />
                            </Grid>
                        ),
                )}
            {otherCommunities?.length < 1 && (
                <Typography className={classes.noChallengesText}>
                    {isSearch
                        ? t('settings.user.communities.noCommunitiesSearch')
                        : t('settings.user.communities.noCommunities')}
                </Typography>
            )}
        </Grid>
    );
};

export const CommunityProps = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    memberCount: PropTypes.number,
    url: PropTypes.string,
    unjoinEligibility: PropTypes.objectOf({ eligible: PropTypes.bool }),
});

CommunityList.propTypes = {
    classes: PropTypes.object.isRequired,
    joinedCommunities: PropTypes.arrayOf(CommunityProps).isRequired,
    otherCommunities: PropTypes.arrayOf(CommunityProps).isRequired,
    onView: PropTypes.func,
    onJoin: PropTypes.func,
    onLeave: PropTypes.func,
    isSearch: PropTypes.bool,
};

CommunityList.defaultProps = {
    onView: noop,
    onJoin: noop,
    onLeave: noop,
    isSearch: false,
};

export default withStyles(styles)(CommunityList);
