const styles = (theme) => ({
    container: {
        paddingLeft: theme.custom.mainContentHorizontalPadding,
        paddingRight: theme.custom.mainContentHorizontalPadding,
        maxWidth: theme.custom.mainContentWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    header: {
        marginTop: '40px',
        marginBottom: '20px',
    },
    headerName: {
        fontSize: '36px',
        fontWeight: 600,
    },
    helpIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '5px',
        fontSize: '65%',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    divider: {
        width: '100%',
        height: '1px',
        border: 'none',
        backgroundColor: theme.custom.bgDarkHover,
    },
    challengeIcon: {
        borderColor: '#fff',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '6px 6px 50% 50%',
    },
    challengeTitle: {
        fontWeight: 700,
    },
    table: {
        marginTop: '40px',
    },
    newDateCell: {
        backgroundColor: 'red',
    },
    centerColumn: {
        width: '100%',
        textAlign: 'center',
    },
});

export default styles;
