import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    cardLoaderContainer: {
        padding: '20px 10px 20px 20px',
        width: '100%',
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '650px',
        },
    },
}));
