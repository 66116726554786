import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import {
    subscriptionManagementLinkRequested,
    subscriptionSessionClearErrors,
    subscriptionSessionRequested,
    subscriptionTiersRequested,
} from '../../subscriptions/actions';
import {
    areSubscriptionTiersLoading,
    getBillingSessionErrors,
    getBillingSessionUrl,
    getSubscriptionManagementUrl,
    getSubscriptionName,
    getSubscriptionSessionLoadingState,
    getSubscriptionTierErrors,
    getSubscriptionTiers,
} from '../../subscriptions/selectors';
import { getActiveCommunityId } from '../../user/selectors';
import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import { getCurrencyString } from '../../subscriptions/utils';
import { trackEvent } from '../../utils/analytics';
import { EVENT_SUBSCRIPTION_STRIPE_UPGRADE_BEGIN } from '../../constants/analyticsEvents';
import { PARAM_PLAN_ID } from '../../constants/analyticsParams';
import PaymentPlans from '../../common/PaymentPlans';
import {
    CREATE_CANCEL_URL,
    FREE_PLAN_INDEX,
    MANAGE_RETURN_URL,
    CREATE_SUCCESS_URL,
} from '../../subscriptions/constants';

const PaymentPlanScreen = ({
    fetchSubscriptionTiers,
    fetchSubscriptionSession,
    subscriptionTiers,
    subscriptionTiersLoading,
    subscriptionTierErrors,
    groupId,
    paymentUrl,
    isSubscriptionUrlFetching,
    currentSubscriptionName,
    currentSubscriptionAmount,
    fetchManagementUrl,
    managementUrl,
    errors,
    clearErrors,
}) => {
    const { t } = useTranslation();

    const [billingSessionRequested, setBillingSessionRequested] = useState(
        false,
    );
    const [
        managementSessionRequested,
        setManagementSessionRequested,
    ] = useState(false);

    useEffect(() => {
        fetchSubscriptionTiers();
    }, [fetchSubscriptionTiers]);

    const handleUpgradeClick = useCallback(
        (id, plan, quantity) => {
            trackEvent(EVENT_SUBSCRIPTION_STRIPE_UPGRADE_BEGIN, {
                [PARAM_PLAN_ID]: plan?.id,
            });
            fetchSubscriptionSession({
                groupId,
                successUrl: CREATE_SUCCESS_URL(plan?.id),
                cancelUrl: CREATE_CANCEL_URL,
                planId: plan?.id,
                quantity,
            });
            setBillingSessionRequested(true);
        },
        [fetchSubscriptionSession, groupId],
    );

    const getCurrentPlanPosition = useCallback(
        (tiers) => {
            return tiers
                .map((tier) => tier.name)
                .indexOf(currentSubscriptionName);
        },
        [currentSubscriptionName],
    );

    const isPresentable = useMemo(
        () => !subscriptionTiersLoading && subscriptionTierErrors == null,
        [subscriptionTiersLoading, subscriptionTierErrors],
    );

    const initialPlanPosition = useMemo(
        () => getCurrentPlanPosition(subscriptionTiers),
        [subscriptionTiers, getCurrentPlanPosition],
    );

    const isPaidTier =
        initialPlanPosition !== FREE_PLAN_INDEX ||
        currentSubscriptionAmount > 0;

    const viewableSubscriptionTiers = useMemo(() => {
        let viewableSubscriptionTiers = subscriptionTiers;
        if (isPaidTier) {
            viewableSubscriptionTiers = subscriptionTiers.filter(
                (tier, index) => index !== FREE_PLAN_INDEX,
            );
        }
        return viewableSubscriptionTiers;
    }, [subscriptionTiers, isPaidTier]);

    const currentPlanPosition = useMemo(
        () => getCurrentPlanPosition(viewableSubscriptionTiers),
        [viewableSubscriptionTiers, getCurrentPlanPosition],
    );

    const fetchManagementSession = useCallback(() => {
        fetchManagementUrl({
            groupId,
            returnUrl: MANAGE_RETURN_URL,
        });
        setManagementSessionRequested(true);
    }, [fetchManagementUrl, groupId]);

    const upgradeButtonProps = useMemo(
        () =>
            viewableSubscriptionTiers.map((tier, index) => {
                if (index === currentPlanPosition) {
                    if (
                        !isPaidTier ||
                        index === viewableSubscriptionTiers.length - 1
                    )
                        return {
                            buttonText: t('account.paymentTiers.currentPlan', {
                                planName: viewableSubscriptionTiers[index].name,
                            }),
                            action: 'none',
                            handler: () => {
                                return null;
                            },
                        };
                    else
                        return {
                            buttonText: t(
                                'account.paymentTiers.manageSubscription',
                                {
                                    planName:
                                        viewableSubscriptionTiers[index].name,
                                },
                            ),
                            action: 'checkout',
                            handler: (id, plan) => fetchManagementSession(),
                        };
                } else if (
                    currentPlanPosition ===
                    viewableSubscriptionTiers.length - 1
                )
                    return {
                        buttonText: t(
                            'account.accountNotifications.contactUsButtonLabel',
                        ),
                        action: 'contact',
                    };
                else if (index < currentPlanPosition)
                    return {
                        buttonText: t('account.paymentTiers.downgrade', {
                            planName: viewableSubscriptionTiers[index].name,
                        }),
                        action: 'checkout',
                        handler: (id, plan) => fetchManagementSession(),
                    };
                else if (index === viewableSubscriptionTiers.length - 1)
                    return {
                        buttonText: t(
                            'account.accountNotifications.contactUsButtonLabel',
                        ),
                        action: 'contact',
                    };
                else if (
                    viewableSubscriptionTiers.length < subscriptionTiers.length
                )
                    return {
                        buttonText: t('account.paymentTiers.upgrade', {
                            planName: viewableSubscriptionTiers[index].name,
                        }),
                        action: 'checkout',
                        handler: (id, plan) => fetchManagementSession(),
                    };
                else
                    return {
                        buttonText: t('account.paymentTiers.upgrade'),
                        action: 'checkout',
                        handler: (id, plan, quantity) => {
                            handleUpgradeClick(id, plan, quantity);
                        },
                    };
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [viewableSubscriptionTiers, currentPlanPosition, t],
    );

    const tierInfo = useMemo(
        () =>
            viewableSubscriptionTiers.map((tier, index) => {
                if (!isPaidTier && index === FREE_PLAN_INDEX) {
                    return {
                        rateText: getCurrencyString(0, null, false),
                        userCountText: t(
                            'account.paymentTiers.freeTier.userRange',
                            {
                                maxUserCount: tier.quotas?.maxUserCount || 0,
                            },
                        ),
                    };
                }
                switch (index) {
                    case viewableSubscriptionTiers.length - 1:
                        return {
                            rateText: t('account.paymentTiers.customTier.fee'),
                            userCountText: t(
                                'account.paymentTiers.customTier.userRange',
                                {
                                    maxUserCount:
                                        tier.quotas?.maxUserCount || 0,
                                },
                            ),
                        };
                    default:
                        return {
                            userCountText: t(
                                'account.paymentTiers.premiumTier.userRange',
                                {
                                    min: tier.quotas?.minUserCount || 0,
                                    max: tier.quotas?.maxUserCount || 0,
                                },
                            ),
                        };
                }
            }),
        [viewableSubscriptionTiers, t, isPaidTier],
    );

    useEffect(() => {
        if (paymentUrl && billingSessionRequested) {
            window.location.assign(paymentUrl);
        }
    }, [paymentUrl, billingSessionRequested]);

    useEffect(() => {
        if (managementUrl && managementSessionRequested) {
            window.location.assign(managementUrl);
        }
    }, [managementUrl, managementSessionRequested]);

    return (
        <Grid container item spacing={2} direction="row" justify="center">
            <RequestLoader
                isLoading={isSubscriptionUrlFetching}
                title={t('account.paymentTiers.paymentUILoadingLabel')}
            />
            <RequestLoader
                isLoading={subscriptionTiersLoading}
                title={t('Loading Plans...')}
            />
            <ErrorList errors={subscriptionTierErrors} />

            {isPresentable && viewableSubscriptionTiers && (
                <Grid item container justifyContent="space-evenly">
                    <PaymentPlans
                        subscriptionTiers={viewableSubscriptionTiers}
                        upgradeButtonProps={upgradeButtonProps}
                        tierInfo={tierInfo}
                        errors={errors}
                        clearSnackBar={clearErrors}
                    />
                </Grid>
            )}
        </Grid>
    );
};

PaymentPlanScreen.propTypes = {
    fetchSubscriptionTiers: PropTypes.func.isRequired,
    fetchSubscriptionSession: PropTypes.func.isRequired,
    subscriptionTiers: PropTypes.array.isRequired,
    subscriptionTiersLoading: PropTypes.bool.isRequired,
    subscriptionTierErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    groupId: PropTypes.string.isRequired,
    paymentUrl: PropTypes.string.isRequired,
    isSubscriptionUrlFetching: PropTypes.bool.isRequired,
    currentSubscriptionName: PropTypes.string.isRequired,
    currentSubscriptionAmount: PropTypes.number,
    fetchManagementUrl: PropTypes.func.isRequired,
    managementUrl: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearErrors: PropTypes.func.isRequired,
};

PaymentPlanScreen.defaultProps = {
    currentSubscriptionAmount: 0,
};

export const StyledPaymentPlanScreen = withStyles(styles)(PaymentPlanScreen);

const mapStateToProps = (state) => ({
    subscriptionTiers: getSubscriptionTiers(state),
    subscriptionTiersLoading: areSubscriptionTiersLoading(state),
    subscriptionTierErrors: getSubscriptionTierErrors(state),
    groupId: getActiveCommunityId(state),
    paymentUrl: getBillingSessionUrl(state),
    isSubscriptionUrlFetching: getSubscriptionSessionLoadingState(state),
    currentSubscriptionName: getSubscriptionName(state),
    currentSubscriptionAmount: getSubscriptionName(state),
    managementUrl: getSubscriptionManagementUrl(state),
    errors: getBillingSessionErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchSubscriptionTiers: () => dispatch(subscriptionTiersRequested()),
    fetchSubscriptionSession: ({
        groupId,
        successUrl,
        cancelUrl,
        planId,
        quantity,
    }) =>
        dispatch(
            subscriptionSessionRequested(
                groupId,
                successUrl,
                cancelUrl,
                planId,
                quantity,
            ),
        ),
    fetchManagementUrl: ({ groupId, returnUrl }) =>
        dispatch(subscriptionManagementLinkRequested(groupId, returnUrl)),
    clearErrors: () => dispatch(subscriptionSessionClearErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledPaymentPlanScreen);
