import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import useStyles from './styles';

export const VARIANT_VERTICAL = 'vertical';
export const VARIANT_HORIZONTAL = 'horizontal';
export const GROUP_VARIANTS = [VARIANT_VERTICAL, VARIANT_HORIZONTAL];

const FormInputGroup = ({ children, variant }) => {
    const classes = useStyles({ variant });
    const direction = variant === VARIANT_VERTICAL ? 'column' : 'row';

    return (
        <Grid
            container
            item
            wrap="nowrap"
            direction={direction}
            className={classes.container}
        >
            {children}
        </Grid>
    );
};

FormInputGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    variant: PropTypes.oneOf(GROUP_VARIANTS),
};

FormInputGroup.defaultProps = {
    children: null,
    variant: VARIANT_VERTICAL,
};

export default FormInputGroup;
