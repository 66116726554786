const styles = (theme) => ({
    container: {
        width: '100%',
        maxWidth: theme.custom.containerMaxWidth,
        position: 'fixed',
        [theme.breakpoints.down('md')]: {
            position: 'relative',
        },
    },
    innerContainer: {
        width: '100%',
        margin: '0 auto',
        padding: `0 ${theme.custom.containerPadding}px`,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '13px',
        },
    },
    menuAndLogoutGridItem: {
        flexGrow: 1,
    },
    lightMoodContainer: {
        backgroundColor: 'red',
        '$ img': {
            width: 500,
            filter: 'brightness(0) invert(1',
        },
    },
});

export default styles;
