const styles = (theme) => ({
    container: {
        padding: '0 6px 14px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 4px 8px',
        },
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        paddingBottom: '100%',
        borderRadius: '0px',
        overflow: 'hidden',
    },
    photo: {
        position: 'absolute',
        height: '100%',
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0,
        transitionDuration: '150ms',
        background:
            'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.80) 100%)',

        '&:hover': {
            backgroundColor: theme.custom.bgBlackTransparent,
            cursor: 'pointer',
            opacity: 1,

            '& $icon': {
                [theme.breakpoints.up('xs')]: {
                    fontSize: 18,
                },
                [theme.breakpoints.up('sm')]: {
                    fontSize: 20,
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: 28,
                },
            },
            '& $chatIcon': {
                [theme.breakpoints.up('xs')]: {
                    height: 18,
                    width: 18,
                },
                [theme.breakpoints.up('sm')]: {
                    height: 20,
                    width: 20,
                },
                [theme.breakpoints.up('md')]: {
                    height: 28,
                    width: 28,
                },
            },
            '& $overlayBottomSpacing': {
                [theme.breakpoints.up('xs')]: {
                    bottom: 2,
                    fontSize: 16,
                },
                [theme.breakpoints.up('sm')]: {
                    bottom: 2,
                    fontSize: 20,
                },
                [theme.breakpoints.up('md')]: {
                    bottom: 2,
                    fontSize: 28,
                },
            },
            '& $overlayTop': {
                opacity: 1,
            },
        },
        fontSize: 'large',
    },
    overlayTop: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: '33%',
        left: 0,
        width: '100%',
        height: '66%',
        padding: '8px',
    },
    actionImage: {
        display: 'block',
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.up('xs')]: {
            marginTop: '15%',
            maxWidth: '80%',
            maxHeight: '80%',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '13%',
            maxWidth: '90%',
            maxHeight: '90%',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '10%',
            maxWidth: '80%',
            maxHeight: '80%',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '13%',
            maxWidth: '80%',
            maxHeight: '80%',
        },
    },
    overlayBottom: {
        position: 'absolute',
        top: '66%',
        right: 0,
        left: 0,
        width: '100%',
        height: '33%',
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
        fontWeight: 'bold',
        transitionDuration: '100ms',
    },
    overlayBottomLeft: {
        position: 'absolute',
        right: '45%',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    overlayBottomRight: {
        position: 'absolute',
        left: '45%',
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    overlayBottomSpacing: {
        [theme.breakpoints.up('xs')]: {
            bottom: 0,
            fontSize: 12,
        },
        [theme.breakpoints.up('sm')]: {
            bottom: 0,
            fontSize: 18,
        },
        [theme.breakpoints.up('md')]: {
            bottom: 0,
            fontSize: 26,
        },
    },
    icon: {
        marginRight: '3px',
        [theme.breakpoints.up('xs')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 26,
        },
    },
    chatIcon: {
        marginRight: '5.5px',
        [theme.breakpoints.up('xs')]: {
            height: 14,
            width: 14,
        },
        [theme.breakpoints.up('sm')]: {
            height: 18,
            width: 18,
        },
        [theme.breakpoints.up('md')]: {
            height: 26,
            width: 26,
        },
    },
    overlayHomeScreen: {
        opacity: 1,
    },
    overlayTopHomeScreen: {
        opacity: 0,
    },
});

export default styles;
