const styles = (theme) => ({
    scrollPaper: {
        alignItems: 'flex-start',
    },
    modal: {
        border: '1px solid rgba(0,0,0,.2)',
        borderRadius: '8px',
        backgroundColor: theme.custom.bgLightAccentColor,
        boxShadow: '0 5px 15px rgba(0,0,0,.5)',

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    title: {
        padding: '15px',
        fontSize: '18px',
        backgroundColor: theme.custom.bgLightColor,
    },
    container: {
        width: '100%',
        padding: '20px',
    },
    footer: {
        marginTop: '15px',
        padding: '19px 20px 20px',
    },
    button: {
        marginLeft: '5px',
    },
    closeIcon: {
        opacity: 0.2,

        '&:hover': {
            opacity: 0.5,
            cursor: 'pointer',
        },
    },
});

export default styles;
