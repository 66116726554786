import { getProperties } from './index';

/**
 * Returns a cookie value if a name is specified. Otherwise returns the entire cookies as an object
 * @param [name] - The name of the cookie to fetch the value for. Returns the entire map of cookies if not specified
 * @returns {string|Object} - The value of the cookie specified by `name` if specified. Otherwise returns a name value map of the available cookies
 */
export function getCookie(name) {
    const cookies = document.cookie.split(';').reduce((acc, cookieString) => {
        const [key, value] = cookieString.split('=').map((s) => s.trim());
        if (key && value) {
            acc[key] = decodeURIComponent(value);
        }
        return acc;
    }, {});
    return name ? cookies[name] || '' : cookies;
}

export function hasGDPRConsent() {
    return getCookie('cky-consent') === 'yes';
}

export function hasAnalyticsConsent() {
    return getCookie('cookieyes-analytics') === 'yes';
}

export function hasAdStorageConsent() {
    // Map ad_storage to ad_user_data
    return getCookie('cookieyes-ad_storage') === 'yes';
}

export function hasAdUserDataConsent() {
    return getCookie('cookieyes-ad_user_data') === 'yes';
}

export function hasAdPersonalizationConsent() {
    return getCookie('cookieyes-ad_personalization') === 'yes';
}

export function setupCookieYes() {
    const scriptSrc = getProperties()?.cookieYesConfig?.scriptSrc || '';
    const script = document.createElement('script');
    script.id = 'cookieyes';
    script.type = 'text/javascript';
    script.src = scriptSrc;
    document.body.appendChild(script);
    return script;
}
