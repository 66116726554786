const styles = (theme) => ({
    switchText: {
        marginLeft: 10,
    },
    switchContainer: {
        marginTop: 20,
        marginLeft: 50,
        marginRight: 50,
    },
});

export default styles;
