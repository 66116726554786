const styles = (theme) => ({
    image: {
        alignSelf: 'center',
    },
    title: {
        color: theme.custom.textDarkSecondaryMutedColor,
        fontWeight: '700',
        marginBottom: '12px',
    },
    description: {
        alignSelf: 'center',
        margin: '15px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
});

export default styles;
