import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './styles';
import { Can } from '../../casl/ability-context';
import AdminSettingsScreen from './AdminSettingsScreen';
import UserSettingsScreen from './UserSettingsScreen';

const SettingsScreen = ({ classes }) => {
    return (
        <Grid container>
            <Can I="view" a="Support">
                <AdminSettingsScreen />
            </Can>
            <Can not I="view" a="Support">
                <UserSettingsScreen />
            </Can>
        </Grid>
    );
};

SettingsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SettingsScreen);
