import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import IMAGES from '../../constants/images';

const ActionCard = ({
    backgroundImageURL,
    iconImageURL,
    name,
    description,
    actionLink,
}) => {
    const classes = useStyles({ backgroundImageURL });
    const { t } = useTranslation();

    return (
        <NavLink
            to={`${actionLink}`}
            className={classes.navContainer}
            data-test="link"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.cardContainer}
                data-test="cardContainer"
            >
                <Grid item className={classes.iconBox}>
                    <img
                        src={iconImageURL || IMAGES.greenLogo}
                        className={
                            actionLink.includes('challenges')
                                ? classes.iconChallenge
                                : classes.icon
                        }
                        alt={t('alt.actionImage')}
                    />
                </Grid>
                <Grid item className={classes.infoContainer}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            className={classes.name}
                            data-test="name"
                        >
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.description}>
                        <Typography variant="body2" data-test="description">
                            {description && parse(description)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </NavLink>
    );
};

ActionCard.propTypes = {
    backgroundImageURL: PropTypes.string.isRequired,
    iconImageURL: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    actionLink: PropTypes.string.isRequired,
};

ActionCard.defaultProps = {
    backgroundImageURL: '',
    iconImageURL: '',
    name: '',
    description: '',
    actionLink: '',
};

export default ActionCard;
