import theme from '../../../theme';
import templateStyles from '../styles';

const styles = () => ({
    ...templateStyles(theme),
    title: {
        margin: '20px 0 10px',
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    text: {
        marginBottom: '10px',
        textAlign: 'center',
    },
});

export default styles;
