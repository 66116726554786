import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    scoringExplanationText: {
        color: theme.custom.textDarkColor,
        paddingBottom: 5,
        fontSize: 16,
    },
}));

export default useStyles;
