import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { withStyles, Grid, Typography } from '@material-ui/core';

import Button from '../../Button';
import IMAGES from '../../../constants/images';
import styles from './styles';

const CreateSection = ({ classes }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleRedirect = () => {
        history.push('/challenges/create');
    };

    return (
        <div className={classes.container}>
            <Grid container direction="row" className={classes.header}>
                <Grid className={classes.logoSection}>
                    <div className={classes.logoContainer}>
                        <img
                            className={classes.logo}
                            src={IMAGES.greenLogo}
                            alt={t('alt.logo')}
                        />
                    </div>
                </Grid>
                <Grid className={classes.textSection}>
                    <Typography variant="h6" className={classes.title}>
                        {t('challenge.new.title')}
                    </Typography>
                    <Typography variant="body2" className={classes.body}>
                        {t('challenge.new.body')}
                    </Typography>
                    <Button
                        variant="outlined"
                        className={classes.createBtn}
                        onClick={handleRedirect}
                    >
                        <Typography>{t('challenge.new.create')}</Typography>
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

CreateSection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateSection);
