import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: ({ noBottomMargin, borderColor }) => ({
        width: '100%',
        marginBottom: noBottomMargin ? 0 : '7px',
        padding: '10px 16px',
        border: `1px solid ${
            borderColor == null ? theme.palette.primary.main : borderColor
        } !important`,
        borderRadius: '8px',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '18px',

        '&:before, &:after': {
            display: 'none',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:active:not(.Mui-disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&.Mui-focused': {
            borderColor:
                borderColor == null ? theme.palette.primary.dark : borderColor,
        },
    }),
    input: {
        height: '24px',
        padding: 0,
    },
    container: (props) => ({
        position: 'relative',
        width: '100%',
        height: props.autoHeight ? 'auto' : '78px',
    }),
    inputLabel: {
        color: theme.palette.primary.main,
    },
    icon: {
        position: 'absolute',
        right: '0',
        width: '44px',
        height: '44px',
        padding: '12px',
        color: theme.custom.grey20,

        '&:hover': {
            color: theme.palette.secondary.dark,
            cursor: 'pointer',
        },
    },
    errorBelow: (props) => ({
        margin: `0 0 10px ${props.noLeftMargin ? 0 : '20px'}`,
        color: props.errorTextColor,
    }),
}));

export default useStyles;
