import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.custom.bgLightColor,
        padding: '5px 20px',
    },
    andBlock: {
        width: '60px',
        height: '100%',
        fontWeight: '700',
        fontSize: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.custom.textLightDisabledColor,
    },
    badgeName: {
        marginLeft: '8px',
    },
    badgeImage: {
        marginLeft: '8px',
        maxWidth: '80px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useStyles;
