import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const ActivityCardPhoto = ({ classes, photoUrl }) => {
    const { t } = useTranslation();
    if (!photoUrl) {
        return null;
    }

    return (
        <Grid container item>
            <img
                src={photoUrl}
                className={classes.photo}
                alt={t('alt.activityPhoto')}
            />
        </Grid>
    );
};

ActivityCardPhoto.propTypes = {
    classes: PropTypes.object.isRequired,
    photoUrl: PropTypes.string,
};

ActivityCardPhoto.defaultProps = {
    photoUrl: null,
};

export default withStyles(styles)(ActivityCardPhoto);
