import IMAGES from '../../../constants/images';

const styles = (theme) => ({
    scrollOption: {
        marginTop: '65px',
        paddingBottom: '0px',
        paddingTop: '52px',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            paddingBottom: 'auto',
            paddingTop: '20px',
            marginTop: '0px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '0px',
        },
    },
    challengeTypeTitle: {
        fontWeight: '700',
        marginBottom: 20,
    },
    imageSection: {
        height: '100%',
        width: '100%',
        backgroundImage: `url(${IMAGES.bgWelcome})`,
        backgroundSize: 'cover',
    },
    actionButton: {
        marginRight: '10px',
    },
    toggleButton: {
        padding: '35px',
    },
    toggleButtonWrapper: {
        minWidth: '200px',
        padding: '10px 5px 10px 0',
    },
});

export default styles;
