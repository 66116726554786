import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    image: {
        border: '1px solid #fff',
        borderRadius: '6px',
    },
    description: {
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    button: {
        padding: '20px 10px',
    },
    container: {
        marginBottom: '10px',
    },
}));

export default useStyles;
