const styles = (theme) => ({
    container: {
        margin: '0 0 25px',
    },
    buttonWhite: {
        border: `1px solid ${theme.palette.primary.main}`,
        margin: '5px',
    },
    buttonGreen: {
        border: `1px solid ${theme.palette.primary.main}`,
        margin: '5px',
        background: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
            testDecoration: 'none',
        },
    },

    underlined: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.dark,
        },
    },

    tipSelectPlan: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: '10px',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
        '& span': {
            textDecoration: 'underline',
            marginLeft: '5px',

            '& a': {
                color: theme.custom.linkColor,
                '&:hover': {
                    cursor: 'pointer',
                    color: theme.custom.linkHoverColor,
                },
            },
        },
    },
    closeButton: {
        margin: '6px 12px',
        backgroundColor: theme.palette.accent.main,

        '&:hover': {
            backgroundColor: theme.palette.accent.dark,
        },
    },
});

export default styles;
