import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, ListItemIcon, Typography } from '@material-ui/core';
import { Clear, Help, InfoOutlined } from '@material-ui/icons';
import useStyles from './styles';
import clsx from 'clsx';
import Tooltip from '../../Tooltip';
import {
    leaderboardCardDefaultPropTypes,
    leaderboardCardPropTypes,
    leaderboardItemPropType,
} from '../propTypes';
import {
    LEADERBOARD_TYPE_DEFAULT,
    LEADERBOARD_TYPE_TOP_USERS,
    LEADERBOARD_TYPES,
} from '../constants';
import { useTranslation } from 'react-i18next';

const LeaderboardCard = ({
    item,
    rank,
    defaultImageSrc,
    imageIsRounded,
    hideRanks,
    Icon,
    ViewIcon,
    onIconClick,
    onItemClick,
    iconTooltip,
    viewIconTooltip,
    iconTooltipPlacement,
    onInfoClick,
    showId,
    leaderboardType,
    isPenalty,
    onRemove,
    ...props
}) => {
    const classes = useStyles({
        imageIsRounded,
        hideRanks,
        hasOnClick: !!onItemClick,
        isPenalty,
    });

    const { t } = useTranslation();

    return (
        <Grid
            container
            item
            disabled={isPenalty}
            direction="row"
            alignItems="center"
            wrap="nowrap"
            key={item.id}
            className={classes.listItem}
            onClick={() => onItemClick && onItemClick(item.id)}
            {...props}
        >
            <Grid
                container
                item
                direction="row"
                wrap="nowrap"
                alignItems="center"
                className={classes.leftBlock}
            >
                <Grid item>
                    {hideRanks || (
                        <ListItemIcon className={classes.place}>
                            {item.disabled ? null : (
                                <span>{isPenalty || rank}</span>
                            )}
                        </ListItemIcon>
                    )}
                </Grid>
                <Grid item>
                    <img
                        alt=""
                        src={item.image || defaultImageSrc}
                        className={clsx([
                            classes.image,
                            item.disabled && classes.disabled,
                        ])}
                    />
                </Grid>
                <Typography
                    className={clsx([
                        classes.label,
                        item.disabled && classes.disabled,
                    ])}
                >
                    {leaderboardType === LEADERBOARD_TYPE_TOP_USERS
                        ? item.label || t('leaderboard.deletedUser')
                        : item.label}
                </Typography>
                {showId ? (
                    <Grid item>
                        <Typography>{`(${item.id})`}</Typography>
                    </Grid>
                ) : null}
            </Grid>
            <Grid
                container
                item
                direction="row"
                justify="flex-end"
                alignItems="center"
                wrap="nowrap"
                className={classes.rightBlock}
            >
                {item.disabled ? (
                    <Typography className={classes.infoIcon}>
                        <IconButton
                            classes={{
                                root: classes.infoIconRoot,
                            }}
                            onClick={onInfoClick}
                        >
                            <InfoOutlined />
                        </IconButton>
                    </Typography>
                ) : isPenalty ? (
                    <Tooltip
                        title={t('challenge.penalties.leaderboard')}
                        className={classes.value}
                    >
                        <Help />
                    </Tooltip>
                ) : (
                    <Typography className={classes.value}>
                        {item.value?.toLocaleString()}
                    </Typography>
                )}
                {Icon && (
                    <Tooltip
                        title={iconTooltip}
                        placement={iconTooltipPlacement}
                    >
                        <Icon
                            className={classes.icon}
                            onClick={(e) => {
                                onIconClick(e, item.id);
                                e.stopPropagation();
                            }}
                        />
                    </Tooltip>
                )}
                {onRemove && (
                    <Tooltip title={t('remove')}>
                        <Clear
                            className={classes.icon}
                            onClick={(e) => {
                                onRemove(item.id);
                                e.stopPropagation();
                            }}
                        />
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    );
};

LeaderboardCard.propTypes = {
    // The leaderboard item to be rendered
    item: leaderboardItemPropType.isRequired,

    // The item's rank in the leaderboard
    rank: PropTypes.number,
    leaderboardType: PropTypes.oneOf(LEADERBOARD_TYPES),
    isPenalty: PropTypes.bool,
    ...leaderboardCardPropTypes,
};

LeaderboardCard.defaultProps = {
    item: {},
    rank: 0,
    leaderboardType: LEADERBOARD_TYPE_DEFAULT,
    // purposely used null instead of noop so that we can add the cursor: pointer style if this exists
    onItemClick: null,
    isPenalty: false,
    ...leaderboardCardDefaultPropTypes,
};

export default LeaderboardCard;
