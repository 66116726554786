import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import TemplatePropTypes from '../../CustomizeChallenge/propTypes';
import Button from '../../../../common/Button';
import theme from '../../../../theme';
import useStyles from './styles';
import ContentToggleButton from '../../../../common/ContentToggleButton';
import {
    BLANK_CHALLENGE_THEME_NAME,
    INTERCOM_TARGET_PREFIX,
} from '../../constants';
import { getAppImages } from '../../../../utils';

const TEMPLATES_PER_ROW = 4;

const TemplateGroup = ({
    templates,
    selectedTemplate,
    uncutRows,
    onTemplateClicked,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = useCallback(() => setShowAll(!showAll), [
        setShowAll,
        showAll,
    ]);
    const shownTemplates = useMemo(
        () =>
            showAll
                ? templates
                : templates?.slice(0, TEMPLATES_PER_ROW * uncutRows),
        [showAll, templates, uncutRows],
    );

    return (
        <Grid container>
            <Grid
                container
                direction="row"
                spacing={1}
                justify="center"
                alignItems="center"
            >
                {shownTemplates?.map((template, index) => (
                    <Grid
                        item
                        key={index}
                        lg={3}
                        xs={6}
                        justify="center"
                        className={classes.flex}
                    >
                        <ContentToggleButton
                            data-intercom-target={`${INTERCOM_TARGET_PREFIX}TemplateButton#${template?.id}`}
                            selected={template?.id === selectedTemplate?.id}
                            name={template?.name}
                            iconUrl={template?.whiteIcon}
                            description={template?.explainerText}
                            onClick={() => onTemplateClicked(template)}
                        />
                    </Grid>
                ))}
                <Grid
                    item
                    lg={3}
                    xs={6}
                    justify="center"
                    className={classes.flex}
                >
                    {shownTemplates?.length > 0 && (
                        <ContentToggleButton
                            selected={
                                selectedTemplate?.challengeType ===
                                BLANK_CHALLENGE_THEME_NAME
                            }
                            data-intercom-target={`${INTERCOM_TARGET_PREFIX}TemplateButtonEmpty`}
                            name={t('challenge.create.blankTheme.title')}
                            iconUrl={getAppImages()?.blankChallengeIcon}
                            description={t(
                                'challenge.create.blankTheme.explainerText',
                            )}
                            onClick={() =>
                                onTemplateClicked({
                                    challengeType: BLANK_CHALLENGE_THEME_NAME,
                                    name: t(
                                        'challenge.create.blankTheme.title',
                                    ),
                                    description: t(
                                        'challenge.create.blankTheme.description',
                                    ),
                                })
                            }
                        />
                    )}
                </Grid>
            </Grid>
            {!showAll && shownTemplates?.length === templates?.length ? null : (
                <Grid
                    container
                    item
                    justify="center"
                    className={classes.moreOptionsButton}
                >
                    <Button
                        variant="text"
                        btnColor="rgba(0,0,0,.1)"
                        textColor={theme.palette.secondary.main}
                        size="large"
                        onClick={toggleShowAll}
                    >
                        {t(showAll ? 'Fewer Options' : 'More Options')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

TemplateGroup.propTypes = {
    templates: PropTypes.arrayOf(TemplatePropTypes.template).isRequired,
    selectedTemplate: PropTypes.object,
    uncutRows: PropTypes.number,
    onTemplateClicked: PropTypes.func.isRequired,
};

TemplateGroup.defaultProps = {
    selectedTemplate: null,
    uncutRows: 2,
};

export default TemplateGroup;
