export const VIEW_CHALLENGE_REQUESTED = 'VIEW_CHALLENGE_REQUESTED';
export const VIEW_CHALLENGE_SUCCEEDED = 'VIEW_CHALLENGE_SUCCEEDED';
export const VIEW_CHALLENGE_FAILED = 'VIEW_CHALLENGE_FAILED';

export const JOIN_CHALLENGE_REQUESTED = 'JOIN_CHALLENGE_REQUESTED';
export const JOIN_CHALLENGE_SUCCEEDED = 'JOIN_CHALLENGE_SUCCEEDED';
export const JOIN_CHALLENGE_FAILED = 'JOIN_CHALLENGE_FAILED';

export const UNJOIN_CHALLENGE_REQUESTED = 'UNJOIN_CHALLENGE_REQUESTED';
export const UNJOIN_CHALLENGE_SUCCEEDED = 'UNJOIN_CHALLENGE_SUCCEEDED';
export const UNJOIN_CHALLENGE_FAILED = 'UNJOIN_CHALLENGE_FAILED';

export const CHALLENGE_SHARE_URL_REQUESTED = 'CHALLENGE_SHARE_URL_REQUESTED';
export const CHALLENGE_SHARE_URL_REQUEST_SUCCEEDED =
    'CHALLENGE_SHARE_URL_REQUEST_SUCCEEDED';
export const CHALLENGE_SHARE_URL_REQUEST_FAILED =
    'CHALLENGE_SHARE_URL_REQUEST_FAILED';

export const CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUESTED =
    'CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUESTED';
export const CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_SUCCEEDED =
    'CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_SUCCEEDED';
export const CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_FAILED =
    'CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_FAILED';

export const CHALLENGE_CLEAR_ERRORS = 'CHALLENGE_CLEAR_ERRORS';

export const CHALLENGE_LEADERBOARD_REQUESTED =
    'CHALLENGE_LEADERBOARD_REQUESTED';
export const CHALLENGE_LEADERBOARD_LOADED = 'CHALLENGE_LEADERBOARD_LOADED';
export const CHALLENGE_LEADERBOARD_FAILED = 'CHALLENGE_LEADERBOARD_FAILED';

export const CHALLENGE_GRAPH_DATA_REQUESTED = 'CHALLENGE_GRAPH_DATA_REQUESTED';
export const CHALLENGE_GRAPH_DATA_SUCCEEDED = 'CHALLENGE_GRAPH_DATA_SUCCEEDED';
export const CHALLENGE_GRAPH_DATA_FAILED = 'CHALLENGE_GRAPH_DATA_FAILED';

export const CHALLENGE_ANALYTICS_ACTIVITY_REQUESTED =
    'CHALLENGE_ANALYTICS_ACTIVITY_REQUESTED';
export const CHALLENGE_ANALYTICS_ACTIVITY_SUCCEEDED =
    'CHALLENGE_ANALYTICS_ACTIVITY_SUCCEEDED';
export const CHALLENGE_ANALYTICS_ACTIVITY_FAILED =
    'CHALLENGE_ANALYTICS_ACTIVITY_FAILED';

export const CHALLENGE_ANALYTICS_USERS_REQUESTED =
    'CHALLENGE_ANALYTICS_USERS_REQUESTED';
export const CHALLENGE_ANALYTICS_USERS_SUCCEEDED =
    'CHALLENGE_ANALYTICS_USERS_SUCCEEDED';
export const CHALLENGE_ANALYTICS_USERS_FAILED =
    'CHALLENGE_ANALYTICS_USERS_FAILED';

export const CHALLENGE_ANALYTICS_IMPACT_REQUESTED =
    'CHALLENGE_ANALYTICS_IMPACT_REQUESTED';
export const CHALLENGE_ANALYTICS_IMPACT_SUCCEEDED =
    'CHALLENGE_ANALYTICS_IMPACT_SUCCEEDED';
export const CHALLENGE_ANALYTICS_IMPACT_FAILED =
    'CHALLENGE_ANALYTICS_IMPACT_FAILED';

export const CHALLENGE_ANALYTICS_PHOTOS_REQUESTED =
    'CHALLENGE_ANALYTICS_PHOTOS_REQUESTED';
export const CHALLENGE_ANALYTICS_PHOTOS_SUCCEEDED =
    'CHALLENGE_ANALYTICS_PHOTOS_SUCCEEDED';
export const CHALLENGE_ANALYTICS_PHOTOS_FAILED =
    'CHALLENGE_ANALYTICS_PHOTOS_FAILED';
export const CHALLENGE_PENALTIES_REQUESTED = 'CHALLENGE_PENALTIES_REQUESTED';
export const CHALLENGE_PENALTIES_SUCCEEDED = 'CHALLENGE_PENALTIES_SUCCEEDED';
export const CHALLENGE_PENALTIES_FAILED = 'CHALLENGE_PENALTIES_FAILED';

export const CHALLENGE_WARN_USER_REQUESTED = 'CHALLENGE_WARN_USER_REQUESTED';
export const CHALLENGE_WARN_USER_SUCCEEDED = 'CHALLENGE_WARN_USER_SUCCEEDED';
export const CHALLENGE_WARN_USER_FAILED = 'CHALLENGE_WARN_USER_FAILED';

export const CHALLENGE_REMOVE_USER_REQUESTED =
    'CHALLENGE_REMOVE_USER_REQUESTED';
export const CHALLENGE_REMOVE_USER_SUCCEEDED =
    'CHALLENGE_REMOVE_USER_SUCCEEDED';
export const CHALLENGE_REMOVE_USER_FAILED = 'CHALLENGE_REMOVE_USER_FAILED';

export const CHALLENGE_PENALTIES_CLEAR_ACTIONS_RESPONSE =
    'CHALLENGE_PENALTIES_CLEAR_ACTIONS_RESPONSE ';

export const CHALLENGES_REQUESTED = 'CHALLENGES_REQUESTED';
export const CHALLENGES_REQUEST_SUCCEEDED = 'CHALLENGES_REQUEST_SUCCEEDED';
export const CHALLENGES_REQUEST_FAILED = 'CHALLENGES_REQUEST_FAILED';

export const CHALLENGE_SEARCH_REQUESTED = 'CHALLENGE_SEARCH_REQUESTED';
export const CHALLENGE_SEARCH_REQUEST_SUCCEEDED =
    'CHALLENGE_SEARCH_REQUEST_SUCCEEDED';
export const CHALLENGE_SEARCH_REQUEST_FAILED =
    'CHALLENGE_SEARCH_REQUEST_FAILED';

export const CHALLENGE_CREATION_REQUESTED = 'CHALLENGE_CREATION_REQUESTED';
export const CHALLENGE_CREATION_REQUEST_SUCCEEDED =
    'CHALLENGE_CREATION_REQUEST_SUCCEEDED';
export const CHALLENGE_CREATION_REQUEST_FAILED =
    'CHALLENGE_CREATION_REQUEST_FAILED';
export const CLEAR_CHALLENGE_CREATION = 'CLEAR_CHALLENGE_CREATION';
export const CHALLENGE_ACTIVATION_REQUESTED = 'CHALLENGE_ACTIVATION_REQUESTED';
export const CHALLENGE_ACTIVATION_REQUEST_SUCCEEDED =
    'CHALLENGE_ACTIVATION_REQUEST_SUCCEEDED';
export const CHALLENGE_ACTIVATION_REQUEST_FAILED =
    'CHALLENGE_ACTIVATION_REQUEST_FAILED';

export const CHALLENGE_DELETION_REQUESTED = 'CHALLENGE_DELETION_REQUESTED';
export const CHALLENGE_DELETION_REQUEST_SUCCEEDED =
    'CHALLENGE_DELETION_REQUEST_SUCCEEDED';
export const CHALLENGE_DELETION_REQUEST_FAILED =
    'CHALLENGE_DELETION_REQUEST_FAILED';

export const CHALLENGE_REPORT_REQUESTED = 'CHALLENGE_REPORT_REQUESTED';
export const CHALLENGE_REPORT_REQUEST_SUCCEEDED =
    'CHALLENGE_REPORT_REQUEST_SUCCEEDED';
export const CHALLENGE_REPORT_REQUEST_FAILED =
    'CHALLENGE_REPORT_REQUEST_FAILED';

export const CLEAR_CHALLENGES_SCREEN_ACTION_ERRORS =
    'CLEAR_CHALLENGES_SCREEN_ACTION_ERRORS';

export const CHALLENGE_DEACTIVATION_REQUESTED =
    'CHALLENGE_DEACTIVATION_REQUESTED';
export const CHALLENGE_DEACTIVATION_REQUEST_SUCCEEDED =
    'CHALLENGE_DEACTIVATION_REQUEST_SUCCEEDED';
export const CHALLENGE_DEACTIVATION_REQUEST_FAILED =
    'CHALLENGE_DEACTIVATION_REQUEST_FAILED';

export const TEAM_DETAILS_REQUESTED = 'TEAM_DETAILS_REQUESTED';
export const TEAM_DETAILS_REQUEST_SUCCEEDED = 'TEAM_DETAILS_REQUEST_SUCCEEDED';
export const TEAM_DETAILS_REQUEST_FAILED = 'TEAM_DETAILS_REQUEST_FAILED';

export const TEAM_LEADERBOARD_REQUESTED = 'TEAM_LEADERBOARD_REQUESTED';
export const TEAM_LEADERBOARD_REQUEST_SUCCEEDED =
    'TEAM_LEADERBOARD_REQUEST_SUCCEEDED';
export const TEAM_LEADERBOARD_REQUEST_FAILED =
    'TEAM_LEADERBOARD_REQUEST_FAILED';

export const USER_CHALLENGES_REQUESTED = 'USER_CHALLENGES_REQUESTED';
export const USER_CHALLENGES_REQUEST_SUCCEEDED =
    'USER_CHALLENGES_REQUEST_SUCCEEDED';
export const USER_CHALLENGES_REQUEST_FAILED = 'USER_CHALLENGES_REQUEST_FAILED';

export const SET_CHALLENGE = 'SET_CHALLENGE';

export const viewChallengeRequested = ({ challengeId, communityId }) => ({
    type: VIEW_CHALLENGE_REQUESTED,
    payload: {
        challengeId,
        communityId,
    },
});

export const viewChallengeSucceeded = (viewChallengeData) => ({
    type: VIEW_CHALLENGE_SUCCEEDED,
    payload: {
        ...viewChallengeData,
    },
});

export const viewChallengeFailed = (errors) => ({
    type: VIEW_CHALLENGE_FAILED,
    payload: {
        errors,
    },
});

export const joinChallengeRequested = ({
    challengeId,
    communityId,
    teamId,
}) => ({
    type: JOIN_CHALLENGE_REQUESTED,
    payload: {
        challengeId,
        communityId,
        teamId,
    },
});

export const joinChallengeSucceeded = ({ teamId }) => ({
    type: JOIN_CHALLENGE_SUCCEEDED,
    payload: {
        teamId,
    },
});

export const joinChallengeFailed = (errors) => ({
    type: JOIN_CHALLENGE_FAILED,
    payload: {
        errors,
    },
});

export const unjoinChallengeRequested = ({ challengeId, communityId }) => ({
    type: UNJOIN_CHALLENGE_REQUESTED,
    payload: {
        challengeId,
        communityId,
    },
});

export const unjoinChallengeSucceeded = () => ({
    type: UNJOIN_CHALLENGE_SUCCEEDED,
});

export const unjoinChallengeFailed = (errors) => ({
    type: UNJOIN_CHALLENGE_FAILED,
    payload: {
        errors,
    },
});

export const challengeLeaderboardRequested = (request) => ({
    type: CHALLENGE_LEADERBOARD_REQUESTED,
    payload: request,
});

export const challengeLeaderboardLoaded = (leaderboardData) => ({
    type: CHALLENGE_LEADERBOARD_LOADED,
    payload: {
        ...leaderboardData,
    },
});

export const challengeLeaderboardFailed = (errors) => ({
    type: CHALLENGE_LEADERBOARD_FAILED,
    payload: {
        errors,
    },
});

export const getGraphDataRequested = (args) => ({
    type: CHALLENGE_GRAPH_DATA_REQUESTED,
    payload: args,
});

export const graphDataSucceeded = (graphData) => ({
    type: CHALLENGE_GRAPH_DATA_SUCCEEDED,
    payload: {
        graphData,
    },
});

export const graphDataFailed = (errors) => ({
    type: CHALLENGE_GRAPH_DATA_FAILED,
    payload: {
        errors,
    },
});

export const penaltiesRequested = (args) => ({
    type: CHALLENGE_PENALTIES_REQUESTED,
    payload: args,
});

export const penaltiesSuccessed = (penalties) => ({
    type: CHALLENGE_PENALTIES_SUCCEEDED,
    payload: {
        penalties,
    },
});

export const penaltiesFailed = (errors) => ({
    type: CHALLENGE_PENALTIES_FAILED,
    payload: {
        errors,
    },
});

export const warnUserRequested = (args) => ({
    type: CHALLENGE_WARN_USER_REQUESTED,
    payload: args,
});

export const warnUserRequestSucceeded = (response) => ({
    type: CHALLENGE_WARN_USER_SUCCEEDED,
    payload: {
        response,
    },
});

export const warnUserRequestFailed = (errors) => ({
    type: CHALLENGE_WARN_USER_FAILED,
    payload: {
        errors,
    },
});

export const removeUserRequested = (args) => ({
    type: CHALLENGE_REMOVE_USER_REQUESTED,
    payload: args,
});

export const removeUserRequestSucceeded = (response) => ({
    type: CHALLENGE_REMOVE_USER_SUCCEEDED,
    payload: {
        response,
    },
});

export const removeUserRequestFailed = (errors) => ({
    type: CHALLENGE_REMOVE_USER_FAILED,
    payload: {
        errors,
    },
});

export const clearChallengePenaltiesActionsResponse = () => ({
    type: CHALLENGE_PENALTIES_CLEAR_ACTIONS_RESPONSE,
});

export const analyticsActivityRequested = (args) => ({
    type: CHALLENGE_ANALYTICS_ACTIVITY_REQUESTED,
    payload: args,
});

export const analyticsActivitySucceeded = (analyticsActivity) => ({
    type: CHALLENGE_ANALYTICS_ACTIVITY_SUCCEEDED,
    payload: {
        analyticsActivity,
    },
});

export const analyticsActivityFailed = (errors) => ({
    type: CHALLENGE_ANALYTICS_ACTIVITY_FAILED,
    payload: {
        errors,
    },
});

export const challengeCreationRequested = (request) => ({
    type: CHALLENGE_CREATION_REQUESTED,
    payload: request,
});

export const challengeCreationRequestSucceeded = (response) => ({
    type: CHALLENGE_CREATION_REQUEST_SUCCEEDED,
    payload: response,
});

export const challengeCreationRequestFailed = (errors) => ({
    type: CHALLENGE_CREATION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearChallengeCreation = () => ({
    type: CLEAR_CHALLENGE_CREATION,
});

export const challengesRequested = (request) => ({
    type: CHALLENGES_REQUESTED,
    payload: request,
});

export const challengesRequestSucceeded = (response) => ({
    type: CHALLENGES_REQUEST_SUCCEEDED,
    payload: response,
});

export const challengesRequestFailed = (errors, group) => ({
    type: CHALLENGES_REQUEST_FAILED,
    payload: {
        errors,
        group,
    },
});

export const challengeSearchRequested = (searchString) => ({
    type: CHALLENGE_SEARCH_REQUESTED,
    payload: { searchString },
});

export const challengeSearchRequestSucceeded = (challenges) => ({
    type: CHALLENGE_SEARCH_REQUEST_SUCCEEDED,
    payload: { challenges },
});

export const challengeSearchRequestFailed = (errors) => ({
    type: CHALLENGE_SEARCH_REQUEST_FAILED,
    payload: { errors },
});

export const analyticsUsersRequested = (args) => ({
    type: CHALLENGE_ANALYTICS_USERS_REQUESTED,
    payload: args,
});

export const analyticsUsersSucceeded = (analyticsUsers) => ({
    type: CHALLENGE_ANALYTICS_USERS_SUCCEEDED,
    payload: { analyticsUsers },
});

export const analyticsUsersFailed = (errors) => ({
    type: CHALLENGE_ANALYTICS_USERS_FAILED,
    payload: {
        errors,
    },
});

export const challengeActivationRequested = (request) => ({
    type: CHALLENGE_ACTIVATION_REQUESTED,
    payload: {
        request,
    },
});

export const challengeActivationRequestSucceeded = (response) => ({
    type: CHALLENGE_ACTIVATION_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const challengeActivationRequestFailed = (errors) => ({
    type: CHALLENGE_ACTIVATION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const challengeDeletionRequested = (request) => ({
    type: CHALLENGE_DELETION_REQUESTED,
    payload: {
        request,
    },
});

export const challengeDeletionRequestSucceeded = (response) => ({
    type: CHALLENGE_DELETION_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const challengeDeletionRequestFailed = (errors) => ({
    type: CHALLENGE_DELETION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const challengeReportRequested = (challengeId) => ({
    type: CHALLENGE_REPORT_REQUESTED,
    payload: {
        challengeId,
    },
});

export const challengeReportRequestSucceeded = (response) => ({
    type: CHALLENGE_REPORT_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const challengeReportRequestFailed = (errors) => ({
    type: CHALLENGE_REPORT_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearChallengesScreenActionErrors = () => ({
    type: CLEAR_CHALLENGES_SCREEN_ACTION_ERRORS,
});

export const analyticsImpactRequested = (args) => ({
    type: CHALLENGE_ANALYTICS_IMPACT_REQUESTED,
    payload: args,
});

export const analyticsImpactSucceeded = (analyticsImpact) => ({
    type: CHALLENGE_ANALYTICS_IMPACT_SUCCEEDED,
    payload: { analyticsImpact },
});

export const analyticsImpactFailed = (errors) => ({
    type: CHALLENGE_ANALYTICS_IMPACT_FAILED,
    payload: { errors },
});

export const analyticsPhotosRequested = (args) => ({
    type: CHALLENGE_ANALYTICS_PHOTOS_REQUESTED,
    payload: args,
});

export const analyticsPhotosSucceeded = (analyticsPhotos) => {
    return {
        type: CHALLENGE_ANALYTICS_PHOTOS_SUCCEEDED,
        payload: { analyticsPhotos },
    };
};

export const analyticsPhotosFailed = (errors) => ({
    type: CHALLENGE_ANALYTICS_PHOTOS_FAILED,
    payload: { errors },
});

export const challengeDeactivationRequested = (request) => ({
    type: CHALLENGE_DEACTIVATION_REQUESTED,
    payload: {
        request,
    },
});

export const challengeDeactivationRequestSucceeded = (response) => ({
    type: CHALLENGE_DEACTIVATION_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const challengeDeactivationRequestFailed = (errors) => ({
    type: CHALLENGE_DEACTIVATION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const teamDetailsRequested = (request) => ({
    type: TEAM_DETAILS_REQUESTED,
    payload: {
        request,
    },
});

export const teamDetailsRequestSucceeded = (response) => ({
    type: TEAM_DETAILS_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const teamDetailsRequestFailed = (errors) => ({
    type: TEAM_DETAILS_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const teamLeaderboardRequested = (request) => ({
    type: TEAM_LEADERBOARD_REQUESTED,
    payload: {
        request,
    },
});

export const teamLeaderboardRequestSucceeded = (response) => ({
    type: TEAM_LEADERBOARD_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const teamLeaderboardRequestFailed = (errors) => ({
    type: TEAM_LEADERBOARD_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const userChallengesRequested = ({ challengeId, communityId }) => ({
    type: USER_CHALLENGES_REQUESTED,
    payload: {
        challengeId,
        communityId,
    },
});

export const userChallengesSucceeded = (data) => ({
    type: USER_CHALLENGES_REQUEST_SUCCEEDED,
    payload: data,
});

export const userChallengesFailed = (errors) => ({
    type: USER_CHALLENGES_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const setChallenge = (challengeId) => ({
    type: SET_CHALLENGE,
    payload: {
        challengeId,
    },
});

export const challengeShareURLRequested = (challengeId, includeOrgInvite) => ({
    type: CHALLENGE_SHARE_URL_REQUESTED,
    payload: {
        challengeId,
        includeOrgInvite,
    },
});

export const challengeShareURLRequestSucceeded = (url) => ({
    type: CHALLENGE_SHARE_URL_REQUEST_SUCCEEDED,
    payload: {
        url,
    },
});

export const challengeShareURLRequestFailed = (errors) => ({
    type: CHALLENGE_SHARE_URL_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const challengeAdminShareImageURLRequested = (
    communityId,
    challengeId,
    includeOrgInvite,
) => ({
    type: CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUESTED,
    payload: {
        communityId,
        challengeId,
        includeOrgInvite,
    },
});

export const challengeAdminShareImageURLRequestSucceeded = (url) => ({
    type: CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_SUCCEEDED,
    payload: {
        url,
    },
});

export const challengeAdminShareImageURLRequestFailed = (errors) => ({
    type: CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const challengeClearErrors = () => ({
    type: CHALLENGE_CLEAR_ERRORS,
});
