import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import images from '../../constants/images';
import OnboardingHeader from '../../common/OnboardingHeader';
import OnboardingFooter from '../../common/OnboardingFooter';
import Loader from '../../common/OnBoarding/Loader';
import ChallengeTypeSelection, {
    CHALLENGE_STEP_ONE,
    CHALLENGE_STEP_TWO,
} from './ChallengeTypeSelection';
import CustomizeChallenge from './CustomizeChallenge';
import { ChallengeType } from './propTypes';
import { trackEvent } from '../../utils/analytics';
import { noop } from 'rxjs';
import moment from 'moment';
import {
    challengeClearErrors,
    challengeCreationRequested,
} from '../../challenges/actions';
import { getToken } from '../../auth/selectors';
import { getActiveCommunityId, getUserId } from '../../user/selectors';
import {
    getChallengeCreationErrors,
    getCreatedChallenge,
    isChallengeCreationLoading,
} from '../../challenges/selectors';
import { themesRequested } from '../../communityThemes/actions';
import {
    areThemesLoading,
    getThemes,
    getThemesErrors,
} from '../../communityThemes/selectors';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import {
    EVENT_ONBOARDING_CHALLENGE_DETAILS_SUBMITTED,
    EVENT_ONBOARDING_SKIPPED,
} from '../../constants/analyticsEvents';

const OnboardingChallengeScreen = ({
    classes,
    themes,
    onChallengeTypeSubmit,
    isAuthenticated,
    fetchThemes,
    communityId,
    areThemesLoading,
    themesErrors,
    creationErrors,
    userId,
    isCreationLoading,
    requestChallengeCreation,
    createdChallenge,
    clearErrors,
}) => {
    const history = useHistory();
    const { i18n } = useTranslation();
    const language = useMemo(() => i18n.language, [i18n.language]);

    const [challengeStep, setChallengeStep] = useState(1);
    const [selectedTheme, setSelectedTheme] = useState({});

    useEffect(() => {
        fetchThemes(communityId);
    }, [communityId, fetchThemes]);

    useEffect(() => {
        if (!userId) {
            history.push('/');
        }
    }, [isAuthenticated, userId, history]);

    useEffect(() => {
        setSelectedTheme({
            ...themes[0],
            startDate: moment().add(2, 'hours'),
            endDate: moment()
                .add(2, 'hours')
                .add(10, 'days'),
        });
    }, [themes]);

    useEffect(() => {
        if (createdChallenge?.id) {
            history.push(`/challenges/${createdChallenge.id}/edit`);
        }
    }, [createdChallenge, history]);

    const [backgroundImage, setBackgroundImage] = useState(
        images.bgSustainability,
    );

    const onChallengeSelected = (data) => {
        setSelectedTheme({
            ...data,
            startDate: moment().add(2, 'hours'),
            endDate: moment()
                .add(2, 'hours')
                .add(10, 'days'),
        });
        setBackgroundImage(data.largePhotoUrl);
    };

    const onChallengeTypeConfirm = (data) => {
        setChallengeStep(CHALLENGE_STEP_TWO);
        setBackgroundImage(data.largePhotoUrl);
        onChallengeTypeSubmit(data);
    };

    const onCustomizeChallengeBackButtonClicked = () => {
        setChallengeStep(1);
    };

    const onCustomizeChallengeSubmit = useCallback(
        (creationData) => {
            trackEvent(EVENT_ONBOARDING_CHALLENGE_DETAILS_SUBMITTED, {
                themeId: selectedTheme.id,
            });
            const {
                challengeName,
                challengeDescription,
                startDate,
                endDate,
                icon,
                coverPhoto,
            } = creationData;
            requestChallengeCreation({
                communityId,
                language,
                challengeDescription,
                challengeName,
                startDate: moment(startDate),
                endDate: moment(endDate),
                competitionType: 'Competitive',
                themeId: selectedTheme.id,
                setTheme: true,
                ...(coverPhoto
                    ? {
                          headlinePhoto: {
                              photo: {
                                  sizes: coverPhoto,
                              },
                              photoAuthorName: '',
                          },
                      }
                    : {}),
                ...(icon
                    ? {
                          icon: {
                              sizes: icon,
                          },
                      }
                    : {}),
            });
        },
        [communityId, language, requestChallengeCreation, selectedTheme],
    );

    const onCustomizeChallengeDataChange = (data) => {
        setSelectedTheme(() => {
            return { ...data };
        });
    };

    const onSkip = () => {
        trackEvent(EVENT_ONBOARDING_SKIPPED);
        history.push('/');
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            {(isCreationLoading || areThemesLoading) && <Loader />}
            <SnackBarAPIResponse
                errors={[...(themesErrors || []), ...(creationErrors || [])]}
                clearResponse={clearErrors}
            />
            <Grid
                container
                direction="row"
                item
                md={8}
                sm={9}
                xs={12}
                className={classes.splitItem}
            >
                <OnboardingHeader />
                <Grid container item xs={12} justifyContent="center">
                    <Grid item sm={10} xs={11}>
                        {!areThemesLoading &&
                            themesErrors?.length < 1 &&
                            challengeStep === CHALLENGE_STEP_ONE && (
                                <ChallengeTypeSelection
                                    onChallengeSelected={onChallengeSelected}
                                    onConfirm={onChallengeTypeConfirm}
                                    challengeTypes={themes}
                                    onSkip={onSkip}
                                />
                            )}
                        {challengeStep === CHALLENGE_STEP_TWO && (
                            <CustomizeChallenge
                                formData={selectedTheme}
                                onFormDataChanged={
                                    onCustomizeChallengeDataChange
                                }
                                onBack={onCustomizeChallengeBackButtonClicked}
                                onSubmit={onCustomizeChallengeSubmit}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                item
                md={4}
                sm={3}
                xs={12}
                className={classes.splitItem}
                style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 75%, rgba(0,0,0,0.75)), url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: `none`,
                    backgroundPosition: `center`,
                    position: `relative`,
                    height: 'calc(100vh - 72px)',
                }}
            ></Grid>
            <OnboardingFooter />
        </Grid>
    );
};

OnboardingChallengeScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    themes: PropTypes.arrayOf(PropTypes.shape(ChallengeType)).isRequired,
    onChallengeTypeSubmit: PropTypes.func.isRequired,
    fetchThemes: PropTypes.func.isRequired,
    clearErrors: PropTypes.func,
    requestChallengeCreation: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.string.isRequired,
    communityId: PropTypes.string.isRequired,
    areThemesLoading: PropTypes.bool,
    isCreationLoading: PropTypes.bool,
    themesErrors: PropTypes.array,
    creationErrors: PropTypes.array,
    userId: PropTypes.string.isRequired,
    createdChallenge: PropTypes.object,
    // Called to indicate the on boarding flow has finalized
};

OnboardingChallengeScreen.defaultProps = {
    onChallengeTypeSubmit: noop,
    fetchThemes: noop,
    themes: [],
    createdChallenge: null,
    themesErrors: [],
    creationErrors: [],
    isCreationLoading: false,
    areThemesLoading: false,
    clearErrors: noop,
};

export const StyledOnboardingChallengeScreen = withStyles(styles)(
    OnboardingChallengeScreen,
);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    userId: getUserId(state),
    isAuthenticated: getToken(state),
    areThemesLoading: areThemesLoading(state),
    themesErrors: getThemesErrors(state),
    creationErrors: getChallengeCreationErrors(state),
    themes: getThemes(state),
    createdChallenge: getCreatedChallenge(state),
    isCreationLoading: isChallengeCreationLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchThemes: (request) => dispatch(themesRequested(request)),
    requestChallengeCreation: (request) =>
        dispatch(challengeCreationRequested(request)),
    clearErrors: () => dispatch(challengeClearErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledOnboardingChallengeScreen);
