const styles = (theme) => ({
    container: {
        marginBottom: '15px',
    },
    column: {
        width: '50%',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    helpLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    helpIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '3px',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    label: {
        marginBottom: '5px',
        fontWeight: 700,
    },
    infoIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '3px',

        '&:hover': {
            cursor: 'pointer',
        },
    },
});

export default styles;
