export default (theme) => ({
    duplicationContainer: {
        marginTop: 20,
    },
    communityCheckContainer: {
        marginTop: 10,
        display: 'flex',
    },
    footer: {
        marginTop: 0,
        paddingTop: 0,
    },
    container: {
        paddingBottom: 0,
    },
    communityName: {
        alignItems: 'center',
        flex: 1,
    },
});
