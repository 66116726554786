import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import {
    analyticsActivityFailed,
    analyticsActivitySucceeded,
    analyticsImpactFailed,
    analyticsImpactSucceeded,
    analyticsPhotosFailed,
    analyticsPhotosSucceeded,
    analyticsUsersFailed,
    analyticsUsersSucceeded,
    CHALLENGE_ACTIVATION_REQUESTED,
    CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUESTED,
    CHALLENGE_ANALYTICS_ACTIVITY_REQUESTED,
    CHALLENGE_ANALYTICS_IMPACT_REQUESTED,
    CHALLENGE_ANALYTICS_PHOTOS_REQUESTED,
    CHALLENGE_ANALYTICS_USERS_REQUESTED,
    CHALLENGE_CREATION_REQUESTED,
    CHALLENGE_DEACTIVATION_REQUESTED,
    CHALLENGE_GRAPH_DATA_REQUESTED,
    CHALLENGE_LEADERBOARD_REQUESTED,
    CHALLENGE_PENALTIES_REQUESTED,
    CHALLENGE_REMOVE_USER_REQUESTED,
    CHALLENGE_REPORT_REQUESTED,
    CHALLENGE_SHARE_URL_REQUESTED,
    CHALLENGE_WARN_USER_REQUESTED,
    challengeActivationRequestFailed,
    challengeActivationRequestSucceeded,
    challengeAdminShareImageURLRequestFailed,
    challengeAdminShareImageURLRequestSucceeded,
    challengeCreationRequestFailed,
    challengeCreationRequestSucceeded,
    challengeDeactivationRequestFailed,
    challengeDeactivationRequestSucceeded,
    challengeLeaderboardFailed,
    challengeLeaderboardLoaded,
    challengeLeaderboardRequested,
    challengeReportRequestFailed,
    challengeReportRequestSucceeded,
    CHALLENGES_REQUESTED,
    challengeShareURLRequestFailed,
    challengeShareURLRequestSucceeded,
    challengesRequestFailed,
    challengesRequestSucceeded,
    graphDataFailed,
    graphDataSucceeded,
    JOIN_CHALLENGE_REQUESTED,
    joinChallengeFailed,
    joinChallengeSucceeded,
    penaltiesFailed,
    penaltiesSuccessed,
    removeUserRequestFailed,
    removeUserRequestSucceeded,
    TEAM_DETAILS_REQUESTED,
    TEAM_LEADERBOARD_REQUESTED,
    teamDetailsRequestFailed,
    teamDetailsRequestSucceeded,
    teamLeaderboardRequestFailed,
    teamLeaderboardRequestSucceeded,
    UNJOIN_CHALLENGE_REQUESTED,
    unjoinChallengeFailed,
    unjoinChallengeSucceeded,
    USER_CHALLENGES_REQUESTED,
    userChallengesFailed,
    userChallengesSucceeded,
    VIEW_CHALLENGE_REQUESTED,
    viewChallengeFailed,
    viewChallengeSucceeded,
    warnUserRequestFailed,
    warnUserRequestSucceeded,
    CHALLENGE_SEARCH_REQUESTED,
    challengeSearchRequestSucceeded,
    challengeSearchRequestFailed,
    CHALLENGE_DELETION_REQUESTED,
    challengeDeletionRequestSucceeded,
    challengeDeletionRequestFailed,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
    postWithAuth,
} from '../utils/api';
import { transformChallengeCreateQuery } from './transforms';
import i18n from 'i18next';
import { trackEvent } from '../utils/analytics';
import {
    EVENT_CHALLENGE_CREATED,
    EVENT_CHALLENGE_JOINED,
} from '../constants/analyticsEvents';

export function* getViewChallengeData(action) {
    const { challengeId, communityId } = action.payload;
    try {
        const challengeData = yield call(
            fetchWithAuth,
            `/challenges/${challengeId}`,
            { query: { communityId } },
        );
        const challenge = challengeData.response.challenges[0];

        yield put(
            viewChallengeSucceeded({
                challenge,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get View Challenge data',
        );
        yield put(viewChallengeFailed(errors));
    }
}

export function* joinChallenge(action) {
    const { challengeId, communityId, teamId } = action.payload;
    trackEvent(EVENT_CHALLENGE_JOINED, {
        challenge_id: challengeId,
    });
    try {
        yield call(fetchWithAuth, `challenges/${challengeId}/join`, {
            method: 'POST',
            body: { communityId, teamId },
        });
        yield all([
            put(joinChallengeSucceeded({ teamId })),
            put(challengeLeaderboardRequested({ challengeId, communityId })),
        ]);
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Join Challenge data',
        );
        yield put(joinChallengeFailed(errors));
    }
}

export function* unjoinChallenge(action) {
    try {
        const { challengeId, communityId } = action.payload;
        yield call(fetchWithAuth, `challenges/${challengeId}/unjoin`, {
            method: 'POST',
            body: { communityId },
        });
        yield all([
            put(unjoinChallengeSucceeded()),
            put(challengeLeaderboardRequested({ challengeId, communityId })),
        ]);
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Unjoin Challenge data',
        );
        yield put(unjoinChallengeFailed(errors));
    }
}

export function* getChallengeLeaderboard(action) {
    try {
        const {
            challengeId,
            communityId,
            limit = 25,
            skip = 0,
            challengeUnit = null,
        } = action.payload;
        const query = {
            communityId,
            includeGoals: false,
            limit,
            skip,
        };
        if (challengeUnit) {
            query.challengeUnit = challengeUnit;
        }
        const leaderboardData = yield call(
            fetchWithAuth,
            `/challenges/${challengeId}/leaderboard`,
            { query },
        );
        const leaderboard = leaderboardData?.response?.leaderboard || [];
        yield put(
            challengeLeaderboardLoaded({
                leaderboard,
                challengeUnit,
                skip,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Challenge Leaderboard data',
        );
        yield put(challengeLeaderboardFailed(errors));
    }
}

export function* analyticsGraphDataRequested({ payload }) {
    try {
        const { communityId, challengeId, type, ...paramsQuery } = payload;

        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/challenge/${challengeId}/${type}`,
            {
                query: paramsQuery,
            },
        );
        const graphData = get(result, 'response.analytics[0]');

        yield put(graphDataSucceeded(graphData));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get analytics graph data, please try again later.',
        );
        yield put(graphDataFailed(errors));
    }
}

export function* penaltiesRequested({ payload }) {
    try {
        const { communityId, challengeId, sortBy } = payload;
        let options = {};
        if (sortBy) {
            const { id, desc } = sortBy;
            options = {
                sort: id === 'points' ? 'score' : id,
                order: desc ? -1 : 1,
            };
        }
        const queryParams = Object.assign({ skip: 0, limit: 30 }, options);
        const penaltiesData = yield call(
            fetchWithAuth,
            `kiosk/group/${communityId}/challenges/${challengeId}/penalties`,
            { query: queryParams },
        );
        const penalties = penaltiesData.response.challenges;

        yield put(penaltiesSuccessed(penalties));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Penalties data',
        );
        yield put(penaltiesFailed(errors));
    }
}

export function* analyticsActivityRequested({ payload }) {
    try {
        const {
            communityId,
            challengeId,
            startDate,
            endDate,
            summaryType,
        } = payload;
        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/challenge/${challengeId}/timeseries/summary`,
            {
                query: {
                    summaryType,
                    startDateIso: startDate,
                    endDateIso: endDate,
                },
            },
        );

        yield put(
            analyticsActivitySucceeded(
                get(result, 'response.analytics[0].activities') || [],
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get analytics Activity data, please try again later.',
        );

        yield put(analyticsActivityFailed(errors));
    }
}

export function* analyticsUsersRequested({ payload }) {
    try {
        const {
            challengeId,
            communityId,
            startDate,
            endDate,
            summaryType,
        } = payload;
        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/challenge/${challengeId}/timeseries/summary`,
            {
                query: {
                    summaryType,
                    startDateIso: startDate,
                    endDateIso: endDate,
                },
            },
        );
        const analyticsUsers = get(result, 'response.analytics[0]');

        yield put(analyticsUsersSucceeded(analyticsUsers));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get analytics Users data',
        );
        yield put(analyticsUsersFailed(errors));
    }
}

export function* analyticsImpactRequested({ payload }) {
    try {
        const { communityId, challengeId, startDate, endDate } = payload;
        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/challenge/${challengeId}/impact/summary`,
            {
                query: {
                    metric: 'buzzBaseAction',
                    startDateIso: startDate,
                    endDateIso: endDate,
                },
            },
        );
        yield put(analyticsImpactSucceeded(result.response['impact-stats']));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get analytics Impact data, please try again later.',
        );
        yield put(analyticsImpactFailed(errors));
    }
}

export function* analyticsPhotosRequested({ payload }) {
    try {
        const { communityId, challengeId, skip, limit } = payload;
        const result = yield call(
            fetchWithAuth,
            `/challenges/${challengeId}/buzz`,
            {
                query: {
                    communityId,
                    limit,
                    skip,
                },
            },
        );
        const analyticsPhotos = get(result, 'response') || [];

        yield put(analyticsPhotosSucceeded(analyticsPhotos));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get analytics photos data',
        );
        yield put(analyticsPhotosFailed(errors));
    }
}

export function* createChallenge(action) {
    try {
        const body = transformChallengeCreateQuery(action.payload);
        trackEvent(EVENT_CHALLENGE_CREATED, {
            theme_id: action.payload?.themeId,
            community_id: action.payload.communityId,
        });
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${action.payload.communityId}/challenges/create`,
            {
                method: 'POST',
                body,
            },
        );
        let challenge = data.response.challenges[0];
        challenge = {
            ...challenge,
            description: action.payload.challengeDescription,
        };
        yield put(
            challengeCreationRequestSucceeded({
                challenge,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,

            'Failed to create challenge',
        );
        yield put(challengeCreationRequestFailed(errors));
    }
}

export function* getGroupedChallenges(action) {
    try {
        const { communityId, group } = action.payload;
        let limit = 1000;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/challenges`,
            {
                query: {
                    communityId,
                    groupBy: group,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    sortBy: 'startDate',
                    order: -1,
                    limit,
                    context: 'group',
                },
            },
        );
        let challenges;
        if (!data.response || !data.response.totalCount) {
            challenges = [];
        } else {
            challenges = data.response.challenges;
            if (data.response.totalCount > limit) {
                limit = data.response.totalCount;
                const allData = yield call(
                    fetchWithAuth,
                    `/kiosk/group/${communityId}/challenges`,
                    {
                        query: {
                            communityId,
                            groupBy: group,
                            timezone: Intl.DateTimeFormat().resolvedOptions()
                                .timeZone,
                            sortBy: 'startDate',
                            order: -1,
                            limit,
                            context: 'group',
                        },
                    },
                );
                challenges = allData.response?.challenges || [];
            }
        }
        yield put(
            challengesRequestSucceeded({
                challenges,
                group,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Challenges',
        );
        yield put(challengesRequestFailed(errors, action.payload.group));
    }
}

export function* activateChallenge(action) {
    try {
        const {
            payload: { request },
        } = action;
        const responseData = yield call(
            fetchWithAuth,
            `/kiosk/group/${request.communityId}/challenges/activate`,
            {
                method: 'POST',
                body: request,
            },
        );
        const response = responseData.response;
        yield put(challengeActivationRequestSucceeded(response));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to activate challenge',
        );
        yield put(challengeActivationRequestFailed(errors));
    }
}

export function* deactivateChallenge(action) {
    try {
        const {
            payload: { request },
        } = action;
        const responseData = yield call(
            fetchWithAuth,
            `/kiosk/group/${request.communityId}/challenges/optout`,
            {
                method: 'POST',
                body: request,
            },
        );
        const response = responseData.response;
        yield put(challengeDeactivationRequestSucceeded(response));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to deactivate challenge',
        );
        yield put(challengeDeactivationRequestFailed(errors));
    }
}

export function* getTeamDetails(action) {
    try {
        const {
            payload: {
                request: { communityId, teamId },
            },
        } = action;
        const responseData = yield call(fetchWithAuth, `/teams/${teamId}`, {
            query: {
                communityId,
            },
        });
        const team = responseData.response.teams[0];
        yield put(teamDetailsRequestSucceeded(team));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get team details',
        );
        yield put(teamDetailsRequestFailed(errors));
    }
}

export function* getTeamLeaderboard(action) {
    try {
        const {
            payload: {
                request: { communityId, teamId },
            },
        } = action;
        const responseData = yield call(
            fetchWithAuth,
            `/teams/${teamId}/leaderboard`,
            {
                query: {
                    communityId,
                },
            },
        );
        const leaderboard = responseData.response?.leaderboard || [];
        yield put(teamLeaderboardRequestSucceeded(leaderboard));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get team leaderboard',
        );
        yield put(teamLeaderboardRequestFailed(errors));
    }
}

export function* requestChallengeReport({ payload: { challengeId } }) {
    try {
        yield call(fetchWithAuth, `/challenges/${challengeId}/report`, {
            method: 'GET',
            json: false,
        });
        yield put(challengeReportRequestSucceeded(i18n.t('report.queued')));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('report.failed'),
        );
        yield put(challengeReportRequestFailed(errors));
    }
}

export function* postWarnUser({
    payload: { challengeId, userId, communityId },
}) {
    try {
        yield call(
            postWithAuth,
            `kiosk/organization/${communityId}/challenges/${challengeId}/penalties/${userId}/warn`,
        );
        yield put(
            warnUserRequestSucceeded(
                i18n.t('challenge.penalties.warn.success'),
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('challenge.penalties.warn.failed'),
        );
        yield put(warnUserRequestFailed(errors));
    }
}

export function* postRemoveUser({
    payload: { challengeId, userId, communityId },
}) {
    try {
        yield call(
            postWithAuth,
            `kiosk/organization/${communityId}/challenges/${challengeId}/penalties/${userId}/remove`,
        );
        yield put(
            removeUserRequestSucceeded(
                i18n.t('challenge.penalties.remove.success'),
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('challenge.penalties.remove.failed'),
        );
        yield put(removeUserRequestFailed(errors));
    }
}

export function* getUserChallenges({ payload: { options } }) {
    try {
        const response = yield call(fetchWithAuth, '/challenges', {
            query: { ...options },
        });
        yield put(userChallengesSucceeded(response.response?.challenges || []));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get user challenges',
        );
        yield put(userChallengesFailed(errors));
    }
}

export function* getChallengeShareURL({
    payload: { challengeId, includeOrgInvite },
}) {
    try {
        const response = yield call(
            fetchWithAuth,
            `/challenges/${challengeId}/share`,
            {
                query: {
                    includeOrgInvite: !!includeOrgInvite,
                },
            },
        );
        const url = response.response['dynamic-links'][0].url;
        yield put(challengeShareURLRequestSucceeded(url));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('challenge.saga.errors.shareURL'),
        );
        yield put(challengeShareURLRequestFailed(errors));
    }
}

export function* getChallengeAdminShareImageURL({
    payload: { communityId, challengeId, includeOrgInvite },
}) {
    try {
        const response = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/challenges/${challengeId}/shareImage`,
            {
                query: {
                    includeOrgInvite: !!includeOrgInvite,
                },
            },
        );
        const url = response.response.urls[0].url;
        yield put(challengeAdminShareImageURLRequestSucceeded(url));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('challenge.saga.errors.shareURL'),
        );
        yield put(challengeAdminShareImageURLRequestFailed(errors));
    }
}

export function* searchChallenges({ payload: { searchString } }) {
    try {
        const data = yield call(fetchWithAuth, `/challenges/search`, {
            query: {
                searchString,
            },
        });
        const challenges = data.response ? data.response.challenges : [];
        yield put(challengeSearchRequestSucceeded(challenges));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('challenge.saga.errors.search'),
        );
        yield put(challengeSearchRequestFailed(errors));
    }
}

export function* deleteChallenge({ payload: { request } }) {
    try {
        yield call(
            fetchWithAuth,
            `/kiosk/group/${request.communityId}/challenges/${request.challengeId}/delete`,
            {
                method: 'DELETE',
            },
        );
        yield put(
            challengeDeletionRequestSucceeded(
                i18n.t('challenge.delete.success'),
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(challengeDeletionRequestFailed(errors));
    }
}

export default function* challengeSaga() {
    yield all([
        takeLatest(VIEW_CHALLENGE_REQUESTED, getViewChallengeData),
        takeLatest(CHALLENGE_LEADERBOARD_REQUESTED, getChallengeLeaderboard),
        takeLatest(JOIN_CHALLENGE_REQUESTED, joinChallenge),
        takeLatest(UNJOIN_CHALLENGE_REQUESTED, unjoinChallenge),
        takeLatest(CHALLENGE_GRAPH_DATA_REQUESTED, analyticsGraphDataRequested),
        takeLatest(
            CHALLENGE_ANALYTICS_ACTIVITY_REQUESTED,
            analyticsActivityRequested,
        ),
        takeLatest(
            CHALLENGE_ANALYTICS_USERS_REQUESTED,
            analyticsUsersRequested,
        ),
        takeLatest(
            CHALLENGE_ANALYTICS_IMPACT_REQUESTED,
            analyticsImpactRequested,
        ),
        takeLatest(
            CHALLENGE_ANALYTICS_PHOTOS_REQUESTED,
            analyticsPhotosRequested,
        ),
        takeLatest(CHALLENGE_PENALTIES_REQUESTED, penaltiesRequested),
        takeLatest(CHALLENGE_CREATION_REQUESTED, createChallenge),
        takeEvery(CHALLENGES_REQUESTED, getGroupedChallenges),
        takeLatest(CHALLENGE_ACTIVATION_REQUESTED, activateChallenge),
        takeLatest(CHALLENGE_DEACTIVATION_REQUESTED, deactivateChallenge),
        takeLatest(CHALLENGE_REPORT_REQUESTED, requestChallengeReport),
        takeLatest(TEAM_DETAILS_REQUESTED, getTeamDetails),
        takeLatest(TEAM_LEADERBOARD_REQUESTED, getTeamLeaderboard),
        takeLatest(CHALLENGE_WARN_USER_REQUESTED, postWarnUser),
        takeLatest(CHALLENGE_REMOVE_USER_REQUESTED, postRemoveUser),
        takeLatest(USER_CHALLENGES_REQUESTED, getUserChallenges),
        takeLatest(CHALLENGE_SHARE_URL_REQUESTED, getChallengeShareURL),
        takeLatest(
            CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUESTED,
            getChallengeAdminShareImageURL,
        ),
        takeLatest(CHALLENGE_SEARCH_REQUESTED, searchChallenges),
        takeLatest(CHALLENGE_DELETION_REQUESTED, deleteChallenge),
    ]);
}
