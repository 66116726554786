import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    checkbox: {
        backgroundColor: 'white',
        padding: 2,
        height: 16,
        width: 16,
        borderRadius: 2,
        border: '1px solid',
    },
}));

export default useStyles;
