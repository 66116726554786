import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { getAppImages } from '../../utils';

const styles = (theme) => ({
    logo: {
        marginTop: 15,
        height: 'auto',
        width: '175px',
        [theme.breakpoints.down('md')]: {
            width: '150px',
        },
    },
});

const Logo = ({ classes, isLogoWhite }) => {
    const logoURL = () => {
        if (isLogoWhite) {
            return getAppImages().onBoardingLogoWhite;
        }
        return getAppImages().onBoardingLogo;
    };

    return <img className={classes.logo} src={logoURL()} alt="JouleBug" />;
};
Logo.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
    isLogoWhite: PropTypes.bool,
};
Logo.defaultProps = {
    isLogoWhite: false,
};
export default withStyles(styles)(Logo);
