import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    activateUsersRequested,
    deactivateUsersRequested,
    deleteUserRequested,
    membersRequested,
    membersSearchRequested,
    moveUserOrganizationRequested,
    permissionsAndRolesRequested,
    updateUserPermissionsRequested,
} from '../../user/actions';
import {
    getActiveCommunity,
    getActiveMembersLoading,
    getActiveMembersTable,
    getActiveMembersTotalCount,
    getCurrentUserPermissions,
    getDeactivatedMembersLoading,
    getDeactivatedMembersTable,
    getDeactivatedMembersTotalCount,
    getErrors,
    getPermissions,
    getRoles,
    isOrganization,
} from '../../user/selectors';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import TableManager from './TableManager';
import Button from '../../common/Button';
import HeaderPage from '../../common/HeaderPage';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { activeCommunityPropType } from '../../user/propTypes';
import { trackEvent, useTrackScreenView } from '../../utils/analytics';
import {
    ACTIVE_STATUS_ACTIVE,
    ACTIVE_STATUS_DEACTIVATED,
} from '../../user/constants';
import SearchBar from '../../common/Search';
import InviteModal from '../../common/InviteModal';
import {
    clearCommunityErrors,
    communityInviteLinkRequested,
    communityRequested,
    communityShareImageRequested,
    uploadCommunityFileRequested,
} from '../../community/actions';
import {
    getCommunityAccessCode,
    getCommunityBulkJoinErrors,
    getCommunityBulkJoinResponse,
    getCommunityInviteLink,
    getCommunityInviteLinkErrors,
    getCommunityShareImage,
    getCommunityShareImageErrors,
    isCommunityBulkJoinLoading,
    isCommunityInviteLinkLoading,
    isCommunityShareImageLoading,
} from '../../community/selectors';
import { downloadShareImage, getAppImages, noop } from '../../utils';
import {
    getInviteUsersErrors,
    getInviteUsersLoading,
    getInviteUsersMessages,
} from '../../store/inviteUsers/selectors';
import {
    inviteUsersRequested,
    inviteUsersReset,
} from '../../store/inviteUsers/actions';
import ManualInviteModal from './ManualInviteModal';
import ModalFooter from './ModalFooter';
import ConfirmModal from '../../common/ConfirmModal';
import PhotoAlternatePlaceholder from '../../common/Photo/PhotoAlternatePlaceholder';
import Upload from '../../common/Upload';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import {
    EVENT_COMMUNITY_SHARE_IMAGE_DOWNLOADED,
    EVENT_COMMUNITY_USERS_MANUALLY_INVITED,
} from '../../constants/analyticsEvents';

const UsersScreen = ({
    classes,
    activeCommunity,
    fetchMembers,
    activeMembersTable,
    deactivatedMembersTable,
    activeMembersTotalCount,
    deactivatedMembersTotalCount,
    searchUsers,
    permissions,
    roles,
    isActiveMembersLoading,
    isDeactivatedMembersLoading,
    requestActivateUsers,
    requestDeactivateUsers,
    onDelete,
    errors,
    fetchPermissions,
    updateUserPermissions,
    currentUserPermissions,
    moveUserOrganization,
    generateInviteLink,
    orgInviteLink,
    orgShareImageUrl,
    fetchOrgShareImageUrl,
    isInviteLinkLoading,
    isShareImageLoading,
    inviteLinkErrors,
    shareImageErrors,
    clearErrors,
    fetchCommunity,
    accessCode,
    inviteUsersLoading,
    inviteUsersMessages,
    inviteUsersErrors,
    requestInvites,
    resetInvites,
    isOrganization,
    onConfirmUpload,
    bulkJoinResponse,
    bulkJoinErrors,
    isBulkJoinLoading,
}) => {
    const { path } = useRouteMatch();
    const { t } = useTranslation();

    useTrackScreenView('profile_view_overview');

    const [searchString, setSearchString] = useState('');
    const [isInviteOpen, setInviteOpen] = useState(false);
    const [isManualOpen, setManualOpen] = useState(false);
    const [isUploadOpen, setUploadOpen] = useState(false);
    const [
        shareImageDownloadRequested,
        setShareImageDownloadRequested,
    ] = useState(false);
    const [imageDownloadError, setImageDownloadError] = useState('');

    const [resetFuncHolder, setResetFuncHolder] = useState({ resetForm: noop });

    const [uploadedFile, setUploadedFile] = useState(null);

    const inviteModalText = {
        header: t('usersPage.inviteModal.header'),
        body: t('usersPage.inviteModal.body'),
        previewHeader: t('usersPage.inviteModal.previewHeader'),
        copied: t('usersPage.inviteModal.copied'),
        pdfHeader: t('usersPage.inviteModal.pdfHeader'),
    };

    const genericCommunityInvite = getAppImages()?.genericCommunityInvite;

    useEffect(() => {
        fetchPermissions(activeCommunity.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCommunity.id]);

    useEffect(() => {
        accessCode || fetchCommunity(activeCommunity.id);
    }, [fetchCommunity, activeCommunity.id, accessCode]);

    useEffect(() => {
        if (inviteUsersMessages.length > 0) {
            resetFuncHolder.resetForm({});
        }
    }, [inviteUsersMessages, resetFuncHolder]);

    const renderHeaderButton = () => (
        <Grid>
            {!isOrganization && (
                <Button
                    data-intercom-target="Member Header Upload Button"
                    onClick={() => setUploadOpen(true)}
                    color="accent"
                    className={classes.headerButtonLeft}
                >
                    <Add /> {t('users.bulkAdd')}
                </Button>
            )}

            <Button
                data-intercom-target="Member Header Invite Button"
                onClick={() => {
                    setInviteOpen(true);
                    generateInviteLink(activeCommunity.id);
                }}
                color="accent"
            >
                <Add /> {t('users.invite')}
            </Button>
        </Grid>
    );

    const activateUsers = (userIds) => {
        requestActivateUsers(activeCommunity.id, userIds);
    };

    const deactivateUsers = (userIds) => {
        requestDeactivateUsers(activeCommunity.id, userIds);
    };

    const fetchData = useCallback(
        (options, status) => {
            fetchMembers(activeCommunity.id, status, options);
        },
        [fetchMembers, activeCommunity.id],
    );

    const fetchActive = useCallback(
        (options) => fetchData(options, ACTIVE_STATUS_ACTIVE),
        [fetchData],
    );

    const fetchDeactivated = useCallback(
        (options) => fetchData(options, ACTIVE_STATUS_DEACTIVATED),
        [fetchData],
    );

    const searchData = useCallback(
        (searchString, options, status) => {
            searchUsers(activeCommunity.id, searchString, status, options);
        },
        [searchUsers, activeCommunity.id],
    );

    const searchActive = useCallback(
        (searchString, options) =>
            searchData(searchString, options, ACTIVE_STATUS_ACTIVE),
        [searchData],
    );

    const searchDeactivated = useCallback(
        (searchString, options) =>
            searchData(searchString, options, ACTIVE_STATUS_DEACTIVATED),
        [searchData],
    );

    const handleManualSubmit = useCallback(
        (emails, setSubmitting, resetForm) => {
            requestInvites(activeCommunity.id, emails.join(','));
            trackEvent(EVENT_COMMUNITY_USERS_MANUALLY_INVITED, {
                community_id: activeCommunity.id,
            });
            setSubmitting(false);
            setResetFuncHolder({ resetForm: resetForm });
        },
        [requestInvites, activeCommunity],
    );

    useEffect(() => {
        if (shareImageDownloadRequested && orgShareImageUrl)
            downloadShareImage(
                orgShareImageUrl,
                activeCommunity.name,
                setShareImageDownloadRequested,
                setImageDownloadError,
                t('usersPage.inviteModal.downloadFailed'),
            );
    }, [
        orgShareImageUrl,
        activeCommunity.name,
        shareImageDownloadRequested,
        t,
    ]);

    useEffect(() => {
        if (bulkJoinResponse) {
            setUploadOpen(false);
        }
    }, [bulkJoinResponse]);

    return (
        <Grid container className={classes.container}>
            <HeaderPage
                title={t('users.descriptor')}
                rightContent={renderHeaderButton()}
            />
            <SearchBar
                placeholder={t('users.search')}
                handleTextChange={setSearchString}
                runSearch={setSearchString}
                data-intercom-target="Member Header Search"
            />

            {errors && errors.length ? (
                <Typography align="center" color="error" gutterBottom>
                    {t('error.plural')}: {errors}
                </Typography>
            ) : null}

            <ConfirmModal
                isOpen={isUploadOpen}
                onClose={() => setUploadOpen(false)}
                onCancel={() => setUploadOpen(false)}
                confirmBtnLoading={isBulkJoinLoading}
                confirmBtnDisabled={!uploadedFile}
                onConfirm={() => {
                    onConfirmUpload(activeCommunity?.id, uploadedFile);
                }}
                title={t('users.bulkAdd')}
                confirmBtnType="accent"
            >
                <Grid
                    item
                    container
                    justifyContent="center"
                    data-intercom-target="Member Bulk Upload Container"
                >
                    <Upload
                        onDropAccepted={(files) => {
                            setUploadedFile(files[0]);
                        }}
                        placeholder={<PhotoAlternatePlaceholder />}
                    />
                </Grid>
                {uploadedFile && (
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        className={classes.uploadedFile}
                    >
                        <Typography>{uploadedFile.name}</Typography>
                        <Close
                            onClick={() => setUploadedFile(null)}
                            fontSize="small"
                            className={classes.closeIcon}
                        />
                    </Grid>
                )}
            </ConfirmModal>
            <InviteModal
                messageText={t('usersPage.inviteModal.invite', {
                    org_name: activeCommunity.name,
                })}
                inviteModalText={inviteModalText}
                communityId={activeCommunity.id}
                isOpen={isInviteOpen}
                Footer={
                    isOrganization && (
                        <ModalFooter
                            linkText="usersPage.inviteModal.footerManualInvite"
                            onLinkClick={() => {
                                setInviteOpen(false);
                                setManualOpen(true);
                            }}
                            accessCode={accessCode}
                        />
                    )
                }
                shareLink={orgInviteLink}
                shareImageURL={genericCommunityInvite}
                inviteLoading={isInviteLinkLoading}
                imageLoading={isShareImageLoading}
                onDownload={() => {
                    trackEvent(EVENT_COMMUNITY_SHARE_IMAGE_DOWNLOADED, {
                        community_id: activeCommunity.id,
                    });
                    setShareImageDownloadRequested(true);
                    if (!orgShareImageUrl)
                        fetchOrgShareImageUrl(activeCommunity.id);
                }}
                onClose={() => setInviteOpen(false)}
                errors={[
                    ...inviteLinkErrors,
                    ...shareImageErrors,
                    ...(imageDownloadError || []),
                ]}
                clearErrors={clearErrors}
            />
            <ManualInviteModal
                onClose={() => setManualOpen(false)}
                isOpen={isManualOpen}
                isLoading={inviteUsersLoading}
                onSubmit={handleManualSubmit}
                messages={inviteUsersMessages}
                errors={inviteUsersErrors}
                clearSnackBar={() => resetInvites()}
                loadUrlFunc={() => generateInviteLink(activeCommunity.id)}
                url={orgInviteLink}
                linkText="usersPage.inviteModal.footerQuickInvite"
                onLinkClick={() => {
                    setInviteOpen(true);
                    setManualOpen(false);
                }}
                accessCode={accessCode}
            />
            {path !== '/members/deactivated' && (
                <TableManager
                    intercomTargetPrefix="Active Member"
                    title={t('active')}
                    data={activeMembersTable}
                    type={ACTIVE_STATUS_ACTIVE}
                    onChangeActive={deactivateUsers}
                    isLoading={isActiveMembersLoading}
                    activeCommunity={activeCommunity}
                    onDelete={onDelete}
                    permissions={permissions}
                    roles={roles}
                    updateUserPermissions={updateUserPermissions}
                    currentUserPermissions={currentUserPermissions}
                    moveUserOrganization={moveUserOrganization}
                    fetchDataFunc={fetchActive}
                    searchFunc={searchActive}
                    searchString={searchString}
                    totalCount={activeMembersTotalCount}
                    searchPlaceholder={t('users.search')}
                />
            )}
            {path !== '/members/active' && (
                <TableManager
                    intercomTargetPrefix="Deactivated Member"
                    title={t('deactivated')}
                    data={deactivatedMembersTable}
                    onChangeActive={activateUsers}
                    type={ACTIVE_STATUS_DEACTIVATED}
                    isLoading={isDeactivatedMembersLoading}
                    activeCommunity={activeCommunity}
                    fetchDataFunc={fetchDeactivated}
                    searchFunc={searchDeactivated}
                    searchString={searchString}
                    totalCount={deactivatedMembersTotalCount}
                    searchPlaceholder={t('users.search')}
                />
            )}
            <SnackBarAPIResponse
                messages={bulkJoinResponse}
                errors={bulkJoinErrors}
                clearResponse={clearErrors}
            />
        </Grid>
    );
};

UsersScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    activeCommunity: activeCommunityPropType.isRequired,
    fetchMembers: PropTypes.func.isRequired,
    searchUsers: PropTypes.func.isRequired,
    activeMembersTable: PropTypes.array.isRequired,
    deactivatedMembersTable: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    isActiveMembersLoading: PropTypes.bool.isRequired,
    isDeactivatedMembersLoading: PropTypes.bool.isRequired,
    requestActivateUsers: PropTypes.func.isRequired,
    requestDeactivateUsers: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchPermissions: PropTypes.func.isRequired,
    updateUserPermissions: PropTypes.func.isRequired,
    currentUserPermissions: PropTypes.object.isRequired,
    moveUserOrganization: PropTypes.func.isRequired,
    activeMembersTotalCount: PropTypes.number.isRequired,
    deactivatedMembersTotalCount: PropTypes.number.isRequired,
    orgInviteLink: PropTypes.string.isRequired,
    generateInviteLink: PropTypes.func.isRequired,
    isInviteLinkLoading: PropTypes.bool.isRequired,
    isShareImageLoading: PropTypes.bool.isRequired,
    inviteLinkErrors: PropTypes.array.isRequired,
    shareImageErrors: PropTypes.array.isRequired,
    accessCode: PropTypes.string.isRequired,
    fetchCommunity: PropTypes.func.isRequired,
    orgShareImageUrl: PropTypes.string,
    fetchOrgShareImageUrl: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    inviteUsersLoading: PropTypes.bool.isRequired,
    inviteUsersMessages: PropTypes.string.isRequired,
    inviteUsersErrors: PropTypes.array.isRequired,
    requestInvites: PropTypes.func.isRequired,
    resetInvites: PropTypes.func.isRequired,
    onConfirmUpload: PropTypes.func.isRequired,
    bulkJoinResponse: PropTypes.string.isRequired,
    bulkJoinErrors: PropTypes.array.isRequired,
    isBulkJoinLoading: PropTypes.bool.isRequired,
    isOrganization: PropTypes.bool,
};

UsersScreen.defaultProps = {
    inviteLinkErrors: [],
    shareImageErrors: [],
    inviteUsersErrors: [],
    orgShareImageUrl: '',
    isOrganization: false,
};

const StyledUsersScreen = withStyles(styles)(UsersScreen);

const mapStateToProps = (state) => ({
    activeCommunity: getActiveCommunity(state),
    activeMembersTable: getActiveMembersTable(state),
    deactivatedMembersTable: getDeactivatedMembersTable(state),
    isActiveMembersLoading: getActiveMembersLoading(state),
    isDeactivatedMembersLoading: getDeactivatedMembersLoading(state),
    activeMembersTotalCount: getActiveMembersTotalCount(state),
    deactivatedMembersTotalCount: getDeactivatedMembersTotalCount(state),
    permissions: getPermissions(state),
    roles: getRoles(state),
    errors: getErrors(state),
    currentUserPermissions: getCurrentUserPermissions(state),
    orgInviteLink: getCommunityInviteLink(state),
    orgShareImageUrl: getCommunityShareImage(state),
    isInviteLinkLoading: isCommunityInviteLinkLoading(state),
    isShareImageLoading: isCommunityShareImageLoading(state),
    inviteLinkErrors: getCommunityInviteLinkErrors(state),
    shareImageErrors: getCommunityShareImageErrors(state),
    accessCode: getCommunityAccessCode(state),
    inviteUsersLoading: getInviteUsersLoading(state),
    inviteUsersMessages: getInviteUsersMessages(state),
    inviteUsersErrors: getInviteUsersErrors(state),
    isOrganization: isOrganization(state),
    bulkJoinResponse: getCommunityBulkJoinResponse(state),
    isBulkJoinLoading: isCommunityBulkJoinLoading(state),
    bulkJoinErrors: getCommunityBulkJoinErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchMembers: (communityId, activeStatus, options) =>
        dispatch(membersRequested(communityId, activeStatus, options)),
    requestActivateUsers: (communityId, userIds) =>
        dispatch(activateUsersRequested(communityId, userIds)),
    requestDeactivateUsers: (communityId, userIds) =>
        dispatch(deactivateUsersRequested(communityId, userIds)),
    onDelete: (communityId, userIds) =>
        dispatch(deleteUserRequested(communityId, userIds)),
    fetchPermissions: (communityId) =>
        dispatch(permissionsAndRolesRequested(communityId)),
    searchUsers: (communityId, searchTerm, activeStatus, options) => {
        dispatch(
            membersSearchRequested(
                communityId,
                searchTerm,
                activeStatus,
                options,
            ),
        );
    },
    updateUserPermissions: (communityId, userIds, permissions, role) =>
        dispatch(
            updateUserPermissionsRequested(
                communityId,
                userIds,
                permissions,
                role,
            ),
        ),
    moveUserOrganization: (communityId, userIds, newOrganizationId) =>
        dispatch(
            moveUserOrganizationRequested(
                communityId,
                userIds,
                newOrganizationId,
            ),
        ),
    generateInviteLink: (communityId) =>
        dispatch(communityInviteLinkRequested(communityId)),
    fetchOrgShareImageUrl: (communityId) =>
        dispatch(communityShareImageRequested(communityId)),
    clearErrors: () => dispatch(clearCommunityErrors()),
    fetchCommunity: (communityId) => dispatch(communityRequested(communityId)),
    requestInvites: (communityId, emails) =>
        dispatch(inviteUsersRequested(communityId, emails)),
    resetInvites: () => dispatch(inviteUsersReset()),
    onConfirmUpload: (communityId, file) =>
        dispatch(uploadCommunityFileRequested(communityId, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledUsersScreen);
