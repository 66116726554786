import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, withStyles } from '@material-ui/core';
import styles from './styles.js';
import { getAppImages } from '../../../utils';

const CommunityAvatar = ({ url, title, classes }) => {
    const [successLoad, setSuccessLoad] = useState(false);
    const [failLoad, setFailLoad] = useState(false);

    const onError = () => {
        setFailLoad(true);
    };

    const onLoad = () => {
        setSuccessLoad(true);
    };

    if (!url || failLoad)
        return (
            <img
                src={getAppImages().defaultCommunityIcon}
                alt="default community icon"
                className={classes.image}
            />
        );

    return (
        <React.Fragment>
            <img
                src={url}
                alt={title}
                className={successLoad ? classes.image : classes.hidden}
                onLoad={onLoad}
                onError={onError}
            />
            {!successLoad && (
                <CircularProgress size={20} className={classes.loader} />
            )}
        </React.Fragment>
    );
};

CommunityAvatar.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

CommunityAvatar.defaultProps = {
    url: null,
    title: null,
};

export default withStyles(styles)(CommunityAvatar);
