import {
    ACTION_REQUESTED,
    ACTION_REQUEST_SUCCEEDED,
    ACTION_REQUEST_FAILED,
    LOG_ACTION_REQUESTED,
    LOG_ACTION_SUCCEEDED,
    LOG_ACTION_FAILED,
    URL_METADATA_REQUESTED,
    URL_METADATA_REQUEST_SUCCEEDED,
    URL_METADATA_REQUEST_FAILED,
    ACTION_CUSTOMIZATION_REQUESTED,
    ACTION_CUSTOMIZATION_REQUEST_SUCCEEDED,
    ACTION_CUSTOMIZATION_REQUEST_FAILED,
    CLEAR_ACTION_LINK_METADATA,
    SET_URL_METADATA,
    ACTION_CATEGORIES_REQUEST_SUCCEEDED,
    ACTION_CATEGORIES_REQUEST_FAILED,
    ACTION_CATEGORIES_REQUESTED,
    SET_VISITED_ACTION_CATEGORY,
    SET_ACTION,
    ACTION_SHARE_URL_REQUESTED,
    ACTION_SHARE_URL_REQUEST_FAILED,
    ACTION_SHARE_URL_REQUEST_SUCCEEDED,
    CLEAR_ACTION_CUSTOMIZATION_ERRORS,
    ACTIONS_REQUEST_SUCCEEDED,
    ACTIONS_REQUESTED,
    ACTIONS_REQUEST_FAILED,
    CLEAR_ACTION_ERRORS,
    SDGS_REQUESTED,
    SDGS_REQUEST_SUCCEEDED,
    SDGS_REQUEST_FAILED,
} from './actions';
import {
    ACTIVITY_SHARE_URL_REQUEST_FAILED,
    ACTIVITY_SHARE_URL_REQUEST_SUCCEEDED,
    ACTIVITY_SHARE_URL_REQUESTED,
} from '../activities/actions';
import { PIN_ADMIN_VISIBILITIES } from './constants';

const initialState = {
    action: null,
    isLoading: false,
    actionShareURL: {
        loading: false,
        errors: [],
    },
    logAction: {
        shareURL: { loading: false, url: null },
    },
    isLogActionLoading: false,
    logErrors: [],
    logStatus: null,

    actions: {
        ...PIN_ADMIN_VISIBILITIES.map((v) => ({
            [v]: {
                actions: {
                    pagination: {},
                    data: [],
                },
                loading: false,
                errors: [],
            },
        })),
    },
    errors: [],
    link: {
        metadata: null,
        isMetadataLoading: false,
        metadataErrors: [],
        confirmed: false,
    },
    customize: {
        customizationMessage: null,
        isCustomizationRequested: false,
        customizationErrors: [],
    },
    actionCategories: {
        categories: null,
        isLoading: false,
        actionCategoriesErrors: [],
        lastActiveTabIndex: 1,
    },
    sdgs: {
        data: [],
        loading: false,
        errors: [],
    },
};

const viewActionReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case ACTION_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case ACTION_REQUEST_SUCCEEDED:
            return {
                ...state,
                action: payload.action,
                isLoading: false,
                errors: [],
            };
        case ACTION_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload.errors,
            };
        case LOG_ACTION_REQUESTED:
            return {
                ...state,
                logAction: {},
                isLogActionLoading: true,
            };
        case ACTIVITY_SHARE_URL_REQUESTED:
            return {
                ...state,
                logAction: {
                    ...state.logAction,
                    shareURL: { loading: true, url: null },
                },
            };
        case ACTIVITY_SHARE_URL_REQUEST_SUCCEEDED:
            return {
                ...state,
                logAction: {
                    ...state.logAction,
                    shareURL: { loading: false, url: payload.url },
                },
            };
        case ACTIVITY_SHARE_URL_REQUEST_FAILED:
            return {
                ...state,
                logAction: {
                    ...state.logAction,
                    shareURL: {
                        loading: false,
                        url: null,
                        errors: payload.errors,
                    },
                },
            };
        case LOG_ACTION_SUCCEEDED:
            return {
                ...state,
                logAction: { ...state.logAction, ...payload.logAction },
                logErrors: [],
                isLogActionLoading: false,
            };
        case LOG_ACTION_FAILED:
            return {
                ...state,
                logErrors: payload.errors,
                logStatus: payload.status,
                isLogActionLoading: false,
            };
        case ACTIONS_REQUESTED:
            if (!payload.filters.challengeIds) {
                return {
                    ...state,
                    actions: {
                        ...state.actions,
                        [payload.filters.visibility]: {
                            ...state.actions[payload.filters.visibility],
                            loading: true,
                            errors: [],
                        },
                    },
                };
            } else {
                return state;
            }
        case ACTIONS_REQUEST_SUCCEEDED:
            if (payload && !payload.filters?.challengeIds) {
                return {
                    ...state,
                    actions: {
                        ...state.actions,
                        [payload.filters?.visibility]: {
                            ...state.actions[payload.filters?.visibility],
                            data: payload.actions,
                            pagination: payload.filters?.pagination || {},
                            loading: false,
                            errors: [],
                        },
                    },
                };
            } else {
                return state;
            }
        case ACTIONS_REQUEST_FAILED:
            if (!payload.filters.challengeIds) {
                return {
                    ...state,
                    actions: {
                        ...state.actions,
                        [payload.filters.visibility]: {
                            ...state.actions[payload.filters.visibility],
                            loading: false,
                            errors: payload.errors,
                        },
                    },
                };
            } else {
                return state;
            }
        case URL_METADATA_REQUESTED:
            return {
                ...state,
                link: {
                    ...state.link,
                    isMetadataLoading: true,
                },
            };
        case URL_METADATA_REQUEST_SUCCEEDED:
            return {
                ...state,
                link: {
                    ...state.link,
                    metadata: payload.metadata,
                    isMetadataLoading: false,
                    metadataErrors: [],
                    confirmed: payload.confirm,
                },
            };
        case URL_METADATA_REQUEST_FAILED:
            return {
                ...state,
                link: {
                    ...state.link,
                    isMetadataLoading: false,
                    metadataErrors: payload.errors,
                },
            };
        case SET_URL_METADATA:
            return {
                ...state,
                link: {
                    ...state.link,
                    metadata: payload.metadata,
                },
            };
        case ACTION_CUSTOMIZATION_REQUESTED:
            return {
                ...state,
                customize: {
                    ...state.customize,
                    isCustomizationRequested: true,
                },
            };
        case ACTION_CUSTOMIZATION_REQUEST_SUCCEEDED:
            return {
                ...state,
                customize: {
                    ...state.customize,
                    isCustomizationRequested: false,
                    customizationErrors: [],
                    customizationMessage: payload.response,
                },
            };
        case ACTION_CUSTOMIZATION_REQUEST_FAILED:
            return {
                ...state,
                customize: {
                    ...state.customize,
                    isCustomizationRequested: false,
                    customizationErrors: payload.errors,
                },
            };
        case CLEAR_ACTION_CUSTOMIZATION_ERRORS:
            return {
                ...state,
                customize: {
                    ...state.customize,
                    customizationErrors: [],
                    customizationMessage: null,
                },
            };
        case CLEAR_ACTION_LINK_METADATA:
            return {
                ...state,
                link: {
                    ...state.link,
                    metadata: initialState.link.metadata,
                    confirmed: false,
                },
            };
        case ACTION_CATEGORIES_REQUESTED:
            return {
                ...state,
                actionCategories: {
                    ...state.actionCategories,
                    isLoading: true,
                    categories: null,
                    actionCategoriesErrors: [],
                },
            };
        case ACTION_CATEGORIES_REQUEST_FAILED:
            return {
                ...state,
                actionCategories: {
                    ...state.actionCategories,
                    isLoading: false,
                    actionCategoriesErrors: payload.errors,
                },
            };
        case ACTION_CATEGORIES_REQUEST_SUCCEEDED:
            return {
                ...state,
                actionCategories: {
                    ...state.actionCategories,
                    categories: payload.categories,
                    isLoading: false,
                    actionCategoriesErrors: [],
                },
            };
        case SET_VISITED_ACTION_CATEGORY: {
            return {
                ...state,
                actionCategories: {
                    ...state.actionCategories,
                    lastActiveTabIndex: payload.tabIndex,
                },
            };
        }
        case SET_ACTION: {
            const action = state.actionCategories?.categories
                ?.map((category) => category.pins)
                ?.flat()
                ?.find((action) => action?.id === payload.actionId);
            return {
                ...state,
                action,
            };
        }
        case ACTION_SHARE_URL_REQUESTED:
            return {
                ...state,
                actionShareURL: {
                    ...state.actionShareURL,
                    loading: true,
                },
            };
        case ACTION_SHARE_URL_REQUEST_FAILED:
            return {
                ...state,
                actionShareURL: {
                    ...state.actionShareURL,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case ACTION_SHARE_URL_REQUEST_SUCCEEDED:
            return {
                ...state,
                action: {
                    ...state.action,
                    shareURL:
                        state.action.id === payload.actionId
                            ? payload.shareURL
                            : action.shareURL,
                },
                actionShareURL: {
                    ...state.actionShareURL,
                    loading: false,
                },
            };
        case CLEAR_ACTION_ERRORS:
            return {
                ...state,
                ...PIN_ADMIN_VISIBILITIES.map((v) => ({
                    [v]: {
                        ...state[v],
                        errors: [],
                    },
                })),
            };
        case SDGS_REQUESTED:
            return {
                ...state,
                sdgs: {
                    ...state.sdgs,
                    loading: true,
                },
            };
        case SDGS_REQUEST_FAILED:
            return {
                ...state,
                sdgs: {
                    ...state.sdgs,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case SDGS_REQUEST_SUCCEEDED:
            return {
                ...state,
                sdgs: {
                    ...state.sdgs,
                    loading: false,
                    data: payload.sdgs,
                },
            };
        default:
            return state;
    }
};

export default viewActionReducer;
