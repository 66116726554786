import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import useStyles from './styles';

import ConfirmModal from '../ConfirmModal';
import { noop } from '../../utils';
import {
    BUTTON_DANGER_COLOR,
    BUTTON_DEFAULT_COLOR,
    BUTTON_SUCCESS_COLOR,
} from '../Button/constants';
import FlagContentPropType from './propTypes';

export const CONTENT_ACTION_MODAL_TYPE_DESTRUCTIVE = 'destructive';
export const CONTENT_ACTION_MODAL_TYPE_POSITIVE = 'positive';
const ContentActionConfirmationModal = ({
    isOpen,
    setOpen,
    items,
    onClose,
    onCancel,
    onConfirm,
    title,
    description,
    header,
    type,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
    }, [onClose, setOpen]);

    const handleCancel = useCallback(() => {
        onCancel();
        setOpen(false);
    }, [onCancel, setOpen]);

    const handleApprove = useCallback(() => {
        onConfirm && onConfirm(items.map(({ id }) => id));
        setOpen(false);
    }, [setOpen, onConfirm, items]);

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={title}
            confirmBtnText={
                type === CONTENT_ACTION_MODAL_TYPE_DESTRUCTIVE
                    ? t('flags.exclude')
                    : t('flags.approve')
            }
            confirmBtnType={
                type === CONTENT_ACTION_MODAL_TYPE_DESTRUCTIVE
                    ? BUTTON_DANGER_COLOR
                    : BUTTON_SUCCESS_COLOR
            }
            cancelBtnText={t('cancel')}
            cancelBtnType={BUTTON_DEFAULT_COLOR}
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={handleApprove}
        >
            <Grid container direction="column">
                <Grid item className={classes.confirmMessage}>
                    {header}
                </Grid>
                {items.map(({ id, icon, identifier } = {}, index) => (
                    <Grid
                        key={index}
                        container
                        item
                        direction="row"
                        wrap="nowrap"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            justify="space-between"
                        >
                            <Grid item>
                                <img src={icon} width={40} height={40} alt="" />
                            </Grid>
                            <Grid item>{id}</Grid>
                        </Grid>
                        <Grid item className={classes.identifier}>
                            {identifier}
                        </Grid>
                    </Grid>
                ))}
                <span className={classes.description}>{description}</span>
            </Grid>
        </ConfirmModal>
    );
};

ContentActionConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(FlagContentPropType).isRequired,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
        CONTENT_ACTION_MODAL_TYPE_POSITIVE,
        CONTENT_ACTION_MODAL_TYPE_DESTRUCTIVE,
    ]),
    title: PropTypes.string,
    description: PropTypes.string,
    header: PropTypes.string,
};

ContentActionConfirmationModal.defaultProps = {
    onClose: noop,
    onCancel: noop,
    type: CONTENT_ACTION_MODAL_TYPE_POSITIVE,
    description: '',
    title: '',
    header: '',
};

export default ContentActionConfirmationModal;
