import {
    CHALLENGE_ACTIVATION_REQUEST_FAILED,
    CHALLENGE_ACTIVATION_REQUEST_SUCCEEDED,
    CHALLENGE_ACTIVATION_REQUESTED,
    CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_FAILED,
    CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_SUCCEEDED,
    CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUESTED,
    CHALLENGE_ANALYTICS_ACTIVITY_FAILED,
    CHALLENGE_ANALYTICS_ACTIVITY_REQUESTED,
    CHALLENGE_ANALYTICS_ACTIVITY_SUCCEEDED,
    CHALLENGE_ANALYTICS_IMPACT_FAILED,
    CHALLENGE_ANALYTICS_IMPACT_REQUESTED,
    CHALLENGE_ANALYTICS_IMPACT_SUCCEEDED,
    CHALLENGE_ANALYTICS_PHOTOS_FAILED,
    CHALLENGE_ANALYTICS_PHOTOS_REQUESTED,
    CHALLENGE_ANALYTICS_PHOTOS_SUCCEEDED,
    CHALLENGE_ANALYTICS_USERS_FAILED,
    CHALLENGE_ANALYTICS_USERS_REQUESTED,
    CHALLENGE_ANALYTICS_USERS_SUCCEEDED,
    CHALLENGE_CLEAR_ERRORS,
    CHALLENGE_CREATION_REQUEST_FAILED,
    CHALLENGE_CREATION_REQUEST_SUCCEEDED,
    CHALLENGE_CREATION_REQUESTED,
    CHALLENGE_DEACTIVATION_REQUEST_FAILED,
    CHALLENGE_DEACTIVATION_REQUEST_SUCCEEDED,
    CHALLENGE_DEACTIVATION_REQUESTED,
    CHALLENGE_DELETION_REQUEST_FAILED,
    CHALLENGE_DELETION_REQUEST_SUCCEEDED,
    CHALLENGE_DELETION_REQUESTED,
    CHALLENGE_GRAPH_DATA_FAILED,
    CHALLENGE_GRAPH_DATA_REQUESTED,
    CHALLENGE_GRAPH_DATA_SUCCEEDED,
    CHALLENGE_LEADERBOARD_FAILED,
    CHALLENGE_LEADERBOARD_LOADED,
    CHALLENGE_LEADERBOARD_REQUESTED,
    CHALLENGE_PENALTIES_CLEAR_ACTIONS_RESPONSE,
    CHALLENGE_PENALTIES_FAILED,
    CHALLENGE_PENALTIES_REQUESTED,
    CHALLENGE_PENALTIES_SUCCEEDED,
    CHALLENGE_REMOVE_USER_FAILED,
    CHALLENGE_REMOVE_USER_REQUESTED,
    CHALLENGE_REMOVE_USER_SUCCEEDED,
    CHALLENGE_REPORT_REQUEST_FAILED,
    CHALLENGE_REPORT_REQUEST_SUCCEEDED,
    CHALLENGE_REPORT_REQUESTED,
    CHALLENGE_SEARCH_REQUEST_FAILED,
    CHALLENGE_SEARCH_REQUEST_SUCCEEDED,
    CHALLENGE_SEARCH_REQUESTED,
    CHALLENGE_SHARE_URL_REQUEST_FAILED,
    CHALLENGE_SHARE_URL_REQUEST_SUCCEEDED,
    CHALLENGE_SHARE_URL_REQUESTED,
    CHALLENGE_WARN_USER_FAILED,
    CHALLENGE_WARN_USER_REQUESTED,
    CHALLENGE_WARN_USER_SUCCEEDED,
    CLEAR_CHALLENGE_CREATION,
    CLEAR_CHALLENGES_SCREEN_ACTION_ERRORS,
    JOIN_CHALLENGE_FAILED,
    JOIN_CHALLENGE_REQUESTED,
    JOIN_CHALLENGE_SUCCEEDED,
    SET_CHALLENGE,
    TEAM_DETAILS_REQUEST_FAILED,
    TEAM_DETAILS_REQUEST_SUCCEEDED,
    TEAM_DETAILS_REQUESTED,
    TEAM_LEADERBOARD_REQUEST_FAILED,
    TEAM_LEADERBOARD_REQUEST_SUCCEEDED,
    TEAM_LEADERBOARD_REQUESTED,
    UNJOIN_CHALLENGE_FAILED,
    UNJOIN_CHALLENGE_REQUESTED,
    UNJOIN_CHALLENGE_SUCCEEDED,
    USER_CHALLENGES_REQUEST_FAILED,
    USER_CHALLENGES_REQUEST_SUCCEEDED,
    USER_CHALLENGES_REQUESTED,
    VIEW_CHALLENGE_FAILED,
    VIEW_CHALLENGE_REQUESTED,
    VIEW_CHALLENGE_SUCCEEDED,
} from './actions';
import { mergePaginatedResponse } from '../common/utils';
import { LOGOUT_REQUESTED } from '../auth/actions';
import { ACTIONS_REQUEST_SUCCEEDED } from '../actions/actions';

const initialState = {
    challenge: null,
    challenges: [],
    isChallengeLoading: false,
    challengeErrors: [],
    leaderboard: null,
    isLeaderboardLoading: false,
    leaderboardErrors: [],
    joinErrors: [],
    unjoinErrors: [],
    graphData: null,
    graphDataErrors: [],
    isGraphDataLoading: false,
    analyticsActivity: [],
    isAnalyticsActivityLoading: false,
    analyticsActivityErrors: [],
    analyticsUsers: null,
    isAnalyticsUsersLoading: false,
    analyticsUsersErrors: [],
    analyticsImpact: [],
    isAnalyticsImpactLoading: false,
    analyticsImpactErrors: [],
    analyticsPhotos: [],
    isAnalyticsPhotosLoading: false,
    analyticsPhotosErrors: [],
    penalties: [],
    penaltiesLoading: false,
    penaltiesActions: {
        loading: false,
        response: null,
        errors: [],
    },
    penaltiesErrors: [],
    templates: null,
    areTemplatesLoading: false,
    templatesErrors: [],
    creation: {
        createdChallenge: null,
        isChallengeCreationRequested: false,
        challengeCreationErrors: [],
    },
    challengesScreenActions: {
        loading: false,
        errors: [],
        response: null,
    },
    teamDetails: {
        loading: false,
        errors: [],
        team: null,
    },
    teamLeaderboard: {
        loading: false,
        errors: [],
        leaderboard: null,
    },
    shareURL: {
        challengeId: '',
        loading: false,
        errors: [],
        url: '',
    },
    adminShareImageURL: {
        challengeId: '',
        loading: false,
        errors: [],
        url: '',
    },
    challengeToDuplicate: null,
};

const viewChallengeReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case VIEW_CHALLENGE_REQUESTED:
            return {
                ...state,
                isChallengeLoading: true,
            };
        case VIEW_CHALLENGE_SUCCEEDED:
            return {
                ...state,
                challenge: payload.challenge,
                isChallengeLoading: false,
                challengeErrors: [],
            };
        case VIEW_CHALLENGE_FAILED:
            return {
                ...state,
                isChallengeLoading: false,
                challengeErrors: payload.errors,
            };
        case JOIN_CHALLENGE_REQUESTED:
            return state;
        case JOIN_CHALLENGE_SUCCEEDED:
            return {
                ...state,
                challenge: {
                    ...state.challenge,
                    participationStatus: 'joined',
                    teams: payload.teamId
                        ? state.challenge.teams?.map((team) => ({
                              ...team,
                              participationStatus:
                                  team.id === payload.teamId
                                      ? 'joined'
                                      : 'unjoined',
                          }))
                        : state.challenge.teams,
                },
                joinErrors: [],
            };
        case JOIN_CHALLENGE_FAILED:
            return {
                ...state,
                joinErrors: payload.errors,
            };
        case UNJOIN_CHALLENGE_REQUESTED:
            return state;
        case UNJOIN_CHALLENGE_SUCCEEDED:
            return {
                ...state,
                challenge: {
                    ...state.challenge,
                    participationStatus: 'unjoined',
                    teams: state.challenge.teams?.map((team) => ({
                        ...team,
                        participationStatus: 'unjoined',
                    })),
                },
                unjoinErrors: [],
            };
        case UNJOIN_CHALLENGE_FAILED:
            return {
                ...state,
                unjoinErrors: payload.errors,
            };
        case CHALLENGE_LEADERBOARD_REQUESTED:
            return {
                ...state,
                isLeaderboardLoading: true,
                leaderboardErrors: [],
            };
        case CHALLENGE_LEADERBOARD_LOADED: {
            const { challengeUnit, leaderboard, skip } = payload;
            const newState = {
                ...state,
                isLeaderboardLoading: false,
                leaderboardErrors: [],
            };
            if (challengeUnit) {
                const challengeUnitLeaderboard = `${challengeUnit}Leaderboard`;
                newState[challengeUnitLeaderboard] = mergePaginatedResponse(
                    state[challengeUnitLeaderboard],
                    leaderboard,
                    skip,
                );
            } else {
                newState.leaderboard = mergePaginatedResponse(
                    state.leaderboard,
                    leaderboard,
                    skip,
                );
            }
            return newState;
        }
        case ACTIONS_REQUEST_SUCCEEDED:
            if (payload.filters.challengeIds?.includes(state.challenge.id)) {
                return {
                    ...state,
                    challenge: {
                        ...state.challenge,
                        actions: {
                            data: payload.actions,
                            pagination: payload.filters?.pagination || {},
                            loading: false,
                            errors: [],
                        },
                    },
                };
            } else {
                return state;
            }
        case CHALLENGE_LEADERBOARD_FAILED:
            return {
                ...state,
                isLeaderboardLoading: false,
                leaderboardErrors: payload.errors,
            };
        case CHALLENGE_GRAPH_DATA_REQUESTED:
            return {
                ...state,
                isGraphDataLoading: true,
            };
        case CHALLENGE_GRAPH_DATA_SUCCEEDED:
            return {
                ...state,
                graphDataErrors: [],
                isGraphDataLoading: false,
                graphData: payload.graphData,
            };
        case CHALLENGE_GRAPH_DATA_FAILED:
            return {
                ...state,
                graphData: null,
                isGraphDataLoading: false,
                graphDataErrors: payload.errors,
            };
        case CHALLENGE_ANALYTICS_ACTIVITY_REQUESTED:
            return {
                ...state,
                analyticsActivityErrors: [],
                isAnalyticsActivityLoading: true,
            };
        case CHALLENGE_ANALYTICS_ACTIVITY_SUCCEEDED:
            return {
                ...state,
                isAnalyticsActivityLoading: false,
                analyticsActivity: payload.analyticsActivity,
            };
        case CHALLENGE_ANALYTICS_ACTIVITY_FAILED:
            return {
                ...state,
                analyticsActivity: null,
                isAnalyticsActivityLoading: false,
                analyticsActivityErrors: payload.errors,
            };
        case CHALLENGE_ANALYTICS_USERS_REQUESTED:
            return {
                ...state,
                analyticsUsersErrors: [],
                isAnalyticsUsersLoading: true,
            };
        case CHALLENGE_ANALYTICS_USERS_SUCCEEDED:
            return {
                ...state,
                isAnalyticsUsersLoading: false,
                analyticsUsers: payload.analyticsUsers,
            };
        case CHALLENGE_ANALYTICS_USERS_FAILED:
            return {
                ...state,
                analyticsUsers: null,
                isAnalyticsUsersLoading: false,
                analyticsUsersErrors: payload.errors,
            };
        case CHALLENGE_ANALYTICS_IMPACT_REQUESTED:
            return {
                ...state,
                analyticsImpactErrors: [],
                isAnalyticsImpactLoading: true,
            };
        case CHALLENGE_ANALYTICS_IMPACT_SUCCEEDED:
            return {
                ...state,
                isAnalyticsImpactLoading: false,
                analyticsImpact: payload.analyticsImpact,
            };
        case CHALLENGE_ANALYTICS_IMPACT_FAILED:
            return {
                ...state,
                isAnalyticsImpactLoading: false,
                analyticsImpactErrors: payload.errors,
            };
        case CHALLENGE_ANALYTICS_PHOTOS_REQUESTED:
            return {
                ...state,
                isAnalyticsPhotosLoading: true,
            };
        case CHALLENGE_ANALYTICS_PHOTOS_SUCCEEDED:
            return {
                ...state,
                isAnalyticsPhotosLoading: false,
                analyticsPhotos: payload.analyticsPhotos,
            };
        case CHALLENGE_ANALYTICS_PHOTOS_FAILED:
            return {
                ...state,
                isAnalyticsPhotosLoading: false,
                analyticsPhotosErrors: payload.errors,
            };

        case CHALLENGE_PENALTIES_REQUESTED:
            return {
                ...state,
                penaltiesLoading: true,
            };
        case CHALLENGE_PENALTIES_SUCCEEDED:
            return {
                ...state,
                penaltiesLoading: false,
                penalties: payload.penalties,
            };
        case CHALLENGE_PENALTIES_FAILED:
            return {
                ...state,
                penaltiesLoading: false,
                penaltiesErrors: payload.errors,
            };
        case CHALLENGE_WARN_USER_REQUESTED:
            return {
                ...state,
                penaltiesActions: {
                    ...state.penaltiesActions,
                    loading: true,
                },
            };
        case CHALLENGE_WARN_USER_SUCCEEDED:
            return {
                ...state,
                penaltiesActions: {
                    ...state.penaltiesActions,
                    loading: false,
                    response: payload.response,
                },
            };
        case CHALLENGE_WARN_USER_FAILED:
            return {
                ...state,
                penaltiesActions: {
                    ...state.penaltiesActions,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case CHALLENGE_REMOVE_USER_REQUESTED:
            return {
                ...state,
                penaltiesActions: {
                    ...state.penaltiesActions,
                    loading: true,
                    succeeded: false,
                },
            };
        case CHALLENGE_REMOVE_USER_SUCCEEDED:
            return {
                ...state,
                penaltiesActions: {
                    ...state.penaltiesActions,
                    loading: false,
                    response: payload.response,
                },
            };
        case CHALLENGE_REMOVE_USER_FAILED:
            return {
                ...state,
                penaltiesActions: {
                    ...state.penaltiesActions,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case CHALLENGE_PENALTIES_CLEAR_ACTIONS_RESPONSE:
            return {
                ...state,
                penaltiesActions: {
                    ...state.penaltiesActions,
                    loading: false,
                    response: null,
                    errors: [],
                },
            };
        case CHALLENGE_CREATION_REQUESTED:
            return {
                ...state,
                creation: {
                    ...state.creation,
                    loading: true,
                    isChallengeCreationRequested: true,
                },
            };
        case CHALLENGE_CREATION_REQUEST_SUCCEEDED:
            return {
                ...state,
                creation: {
                    ...state.creation,
                    loading: false,
                    createdChallenge: payload.challenge,
                    isChallengeCreationRequested: false,
                    challengeCreationErrors: [],
                },
            };
        case CHALLENGE_CREATION_REQUEST_FAILED:
            return {
                ...state,
                creation: {
                    ...state.creation,
                    createdChallenge: null,
                    loading: false,
                    isChallengeCreationRequested: false,
                    challengeCreationErrors: payload.errors,
                },
            };
        case CLEAR_CHALLENGE_CREATION:
            return {
                ...state,
                creation: {
                    ...state.creation,
                    createdChallenge: null,
                    isChallengeCreationRequested: false,
                    challengeCreationErrors: [],
                },
            };
        case CHALLENGE_ACTIVATION_REQUESTED:
        case CHALLENGE_DEACTIVATION_REQUESTED:
        case CHALLENGE_REPORT_REQUESTED:
        case CHALLENGE_DELETION_REQUESTED:
            return {
                ...state,
                challengesScreenActions: {
                    ...state.challengesScreenActions,
                    loading: true,
                },
            };
        case CHALLENGE_ACTIVATION_REQUEST_SUCCEEDED:
        case CHALLENGE_DEACTIVATION_REQUEST_SUCCEEDED:
        case CHALLENGE_REPORT_REQUEST_SUCCEEDED:
        case CHALLENGE_DELETION_REQUEST_SUCCEEDED:
            return {
                ...state,
                challengesScreenActions: {
                    ...state.challengesScreenActions,
                    loading: false,
                    errors: [],
                    response: payload.response,
                },
            };
        case CHALLENGE_DEACTIVATION_REQUEST_FAILED:
        case CHALLENGE_ACTIVATION_REQUEST_FAILED:
        case CHALLENGE_REPORT_REQUEST_FAILED:
        case CHALLENGE_DELETION_REQUEST_FAILED:
            return {
                ...state,
                challengesScreenActions: {
                    ...state.challengesScreenActions,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case CLEAR_CHALLENGES_SCREEN_ACTION_ERRORS:
            return {
                ...state,
                challengesScreenActions: {
                    ...state.challengesScreenActions,
                    errors: [],
                    response: [],
                },
            };
        case TEAM_DETAILS_REQUESTED:
            return {
                ...state,
                teamDetails: {
                    ...state.teamDetails,
                    loading: true,
                },
            };
        case TEAM_DETAILS_REQUEST_SUCCEEDED:
            return {
                ...state,
                teamDetails: {
                    ...state.teamDetails,
                    loading: false,
                    errors: [],
                    team: payload.response,
                },
            };
        case TEAM_DETAILS_REQUEST_FAILED:
            return {
                ...state,
                teamDetails: {
                    ...state.teamDetails,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case TEAM_LEADERBOARD_REQUESTED:
            return {
                ...state,
                teamLeaderboard: {
                    ...state.teamLeaderboard,
                    loading: true,
                },
            };
        case TEAM_LEADERBOARD_REQUEST_SUCCEEDED:
            return {
                ...state,
                teamLeaderboard: {
                    ...state.teamLeaderboard,
                    loading: false,
                    errors: [],
                    leaderboard: payload.response,
                },
            };
        case TEAM_LEADERBOARD_REQUEST_FAILED:
            return {
                ...state,
                teamLeaderboard: {
                    ...state.teamLeaderboard,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case SET_CHALLENGE: {
            const challenge = state.challenges?.challenges?.find(
                (challenge) => challenge.id === payload.challengeId,
            );
            return {
                ...state,
                challenge,
            };
        }
        case USER_CHALLENGES_REQUESTED:
            return {
                ...state,
                challenges: {
                    isLoading: true,
                },
            };
        case USER_CHALLENGES_REQUEST_FAILED:
            return {
                ...state,
                challenges: {
                    isLoading: false,
                    errors: payload.errors,
                },
            };
        case USER_CHALLENGES_REQUEST_SUCCEEDED:
            return {
                ...state,
                challenges: {
                    challenges: payload,
                    isLoading: false,
                    errors: [],
                },
            };
        case CHALLENGE_SEARCH_REQUESTED:
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    isLoading: true,
                },
            };
        case CHALLENGE_SEARCH_REQUEST_SUCCEEDED:
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    searchResult: payload.challenges,
                    isLoading: false,
                },
            };
        case CHALLENGE_SEARCH_REQUEST_FAILED:
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    errors: payload.errors,
                    isLoading: false,
                },
            };
        case LOGOUT_REQUESTED: {
            return initialState;
        }
        case CHALLENGE_SHARE_URL_REQUESTED:
            return {
                ...state,
                shareURL: {
                    ...initialState.shareURL,
                    challengeId: payload.challengeId,
                    loading: true,
                },
            };
        case CHALLENGE_SHARE_URL_REQUEST_SUCCEEDED:
            return {
                ...state,
                shareURL: {
                    ...state.shareURL,
                    loading: false,
                    url: payload.url,
                },
            };
        case CHALLENGE_SHARE_URL_REQUEST_FAILED:
            return {
                ...state,
                shareURL: {
                    ...state.shareURL,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUESTED:
            return {
                ...state,
                adminShareImageURL: {
                    ...initialState.adminShareImageURL,
                    challengeId: payload.challengeId,
                    loading: true,
                },
            };
        case CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_SUCCEEDED:
            return {
                ...state,
                adminShareImageURL: {
                    ...state.adminShareImageURL,
                    loading: false,
                    url: payload.url,
                },
            };
        case CHALLENGE_ADMIN_SHARE_IMAGE_URL_REQUEST_FAILED:
            return {
                ...state,
                adminShareImageURL: {
                    ...state.adminShareImageURL,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case CHALLENGE_CLEAR_ERRORS:
            return {
                ...state,
                challengeErrors: [],
                leaderboardErrors: [],
                joinErrors: [],
                unjoinErrors: [],
                graphDataErrors: [],
                analyticsActivityErrors: [],
                analyticsUsersErrors: [],
                analyticsImpactErrors: [],
                analyticsPhotosErrors: [],
                penaltiesActions: {
                    ...state.penaltiesActions,
                    errors: [],
                },
                penaltiesErrors: [],
                templatesErrors: [],
                creation: {
                    ...state.creation,
                    challengeCreationErrors: [],
                },
                challengesScreenActions: {
                    ...state.challengesScreenActions,
                    errors: [],
                },
                teamDetails: {
                    ...state.teamDetails,
                    errors: [],
                },
                teamLeaderboard: {
                    ...state.teamLeaderboard,
                    errors: [],
                },
                shareURL: {
                    ...state.shareURL,
                    errors: [],
                },
                adminShareImageURL: {
                    ...state.adminShareImageURL,
                    errors: [],
                },
            };
        default:
            return state;
    }
};

export default viewChallengeReducer;
