import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';
import TeamDetailsLeaderboard from './TeamDetailsLeaderboard';
import TeamDetailsImpact, {
    TeamDetailsActivityTotalsPropType,
} from './TeamDetailsImpact';

import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import HeaderPage from '../../common/HeaderPage';
import TabbedSection from '../../common/TabbedSection';
import CommonPropTypes from '../../common/propTypes';
import { getActiveCommunityId } from '../../user/selectors';
import { teamDetailsRequested } from '../../challenges/actions';
import {
    areTeamDetailsLoading,
    getChallengeName,
    getTeamDetails,
    getTeamDetailsErrors,
} from '../../challenges/selectors';
import { ImpactTotalPropType } from '../ProfileScreen/propTypes';
import { Link, useRouteMatch } from 'react-router-dom';
import { getAppImages } from '../../utils';
import { useTrackScreenView } from '../../utils/analytics';
import {
    activityDataRequested,
    clearActivities,
} from '../../activities/actions';
import { ACTIVITIES_FEED_TYPE_TEAM } from '../../activities/constants';
import { activitiesLoading, getActivities } from '../../activities/selectors';
import ActivityCardScroller from '../../common/ActivityCard/ActivityCardScroller';

const TeamDetailsScreen = ({
    communityId,
    requestTeamDetails,
    teamDetailsLoading,
    teamDetailsErrors,
    teamDetails,
    challengeName,
    clearTeamActivities,
    requestActivities,
    activities,
    activitiesLoading,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        params: { challengeId, teamId },
    } = useRouteMatch();

    useTrackScreenView('challenge_view_team');

    useEffect(() => {
        clearTeamActivities();
        requestActivities(teamId, {});
        requestTeamDetails({
            communityId,
            teamId,
        });
    }, [
        clearTeamActivities,
        requestTeamDetails,
        communityId,
        teamId,
        requestActivities,
        challengeId,
    ]);

    const isPresentable = useMemo(
        () =>
            !teamDetailsLoading &&
            teamDetailsErrors == null &&
            teamDetails != null,
        [teamDetailsLoading, teamDetailsErrors, teamDetails],
    );

    const breadcrumb = useMemo(() => {
        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Link
                        to={`/challenges/${challengeId}`}
                        className={classes.breadcrumbLink}
                    >{`${t('challenge.descriptor')}`}</Link>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body2"
                        className={classes.breadcrumbText}
                    >
                        {`/ ${t('challenge.team')}`}
                    </Typography>
                </Grid>
            </Grid>
        );
    }, [classes, t, challengeId]);

    return (
        <Grid container direction="column" className={classes.container}>
            <RequestLoader
                isLoading={teamDetailsLoading}
                title={`${t('challengeTeam.loading')}...`}
            />
            <ErrorList errors={teamDetailsErrors} />
            {isPresentable ? (
                <>
                    <HeaderPage
                        title={t('challengeTeam.details')}
                        rightContent={breadcrumb}
                    />
                    <Grid item xs={3} className={classes.imageContainer}>
                        <img
                            src={
                                teamDetails.image ||
                                getAppImages().defaultProfileIcon
                            }
                            alt={t('challenge.alt.teamIcon', {
                                teamName: teamDetails.name,
                            })}
                            className={classes.image}
                        />
                    </Grid>
                    <Typography variant="h2" className={classes.name}>
                        {teamDetails.name}
                    </Typography>
                    {teamDetails.headline ? (
                        <Typography className={classes.headline}>
                            {teamDetails.headline}
                        </Typography>
                    ) : null}
                    <TabbedSection
                        tabs={{
                            [t('Leaderboard')]: (
                                <TeamDetailsLeaderboard
                                    communityId={communityId}
                                    teamId={teamId}
                                    challengeId={challengeId}
                                    challengeName={challengeName}
                                />
                            ),
                            [t('Activity')]: (
                                <ActivityCardScroller
                                    loading={activitiesLoading}
                                    loadFunc={(options) =>
                                        requestActivities(teamId, ...options)
                                    }
                                    items={activities}
                                />
                            ),
                            [t('Impact')]: (
                                <TeamDetailsImpact
                                    impactTotals={teamDetails.impactTotals}
                                    activityTotals={teamDetails.activityTotals}
                                />
                            ),
                        }}
                    />
                </>
            ) : null}
        </Grid>
    );
};

TeamDetailsScreen.propTypes = {
    communityId: PropTypes.string.isRequired,
    requestTeamDetails: PropTypes.func.isRequired,
    teamDetailsLoading: PropTypes.bool.isRequired,
    teamDetailsErrors: CommonPropTypes.errors,
    teamDetails: PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.string,
        impactTotals: PropTypes.arrayOf(ImpactTotalPropType).isRequired,
        activityTotals: TeamDetailsActivityTotalsPropType.isRequired,
        headline: PropTypes.string,
    }),
    challengeName: PropTypes.string,
    clearTeamActivities: PropTypes.func.isRequired,
    requestActivities: PropTypes.func.isRequired,
    activities: PropTypes.object.isRequired,
    activitiesLoading: PropTypes.bool.isRequired,
};

TeamDetailsScreen.defaultProps = {
    teamDetailsErrors: null,
    teamDetails: null,
    challengeName: '',
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    teamDetailsLoading: areTeamDetailsLoading(state),
    teamDetailsErrors: getTeamDetailsErrors(state),
    teamDetails: getTeamDetails(state),
    challengeName: getChallengeName(state),
    activities: getActivities(state, ACTIVITIES_FEED_TYPE_TEAM),
    activitiesLoading: activitiesLoading(state, ACTIVITIES_FEED_TYPE_TEAM),
});

const mapDispatchToProps = (dispatch) => ({
    requestActivities: (teamId, options) =>
        dispatch(
            activityDataRequested(ACTIVITIES_FEED_TYPE_TEAM, {
                teamId,
                ...options,
            }),
        ),
    requestTeamDetails: (request) => dispatch(teamDetailsRequested(request)),
    clearTeamActivities: () =>
        dispatch(clearActivities(ACTIVITIES_FEED_TYPE_TEAM)),
});

const ConnectedTeamDetailsScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TeamDetailsScreen);
export default ConnectedTeamDetailsScreen;
