import {
    AddBox,
    AddToPhotos,
    Assessment,
    Delete,
    Edit,
    Folder,
    RemoveCircle,
    Telegram,
    Visibility,
    Whatshot,
} from '@material-ui/icons';

export const GROUP_ACTIVE = 'active';
export const GROUP_DRAFT = 'draft';
export const GROUP_UPCOMING = 'upcoming';
export const GROUP_PAST = 'past';
export const GROUP_DEACTIVATED = 'optout';

export const GROUPS = [
    GROUP_ACTIVE,
    GROUP_DRAFT,
    GROUP_UPCOMING,
    GROUP_PAST,
    GROUP_DEACTIVATED,
];

export const GROUP_NAMES = {
    [GROUP_ACTIVE]: 'active',
    [GROUP_DRAFT]: 'challenge.status.draft',
    [GROUP_UPCOMING]: 'challenge.status.upcoming',
    [GROUP_PAST]: 'challenge.status.past',
    [GROUP_DEACTIVATED]: 'deactivated',
};

export const BTN_EDIT_CHALLENGE = {
    key: 'editChallenge',
    actions: 'edit',
    subject: 'Challenge',
    tooltip: 'edit',
    icon: Edit,
};

export const BTN_VIEW_CHALLENGE = {
    key: 'viewChallengesAdmin',
    actions: 'view',
    subject: 'ChallengesAdmin',
    tooltip: 'view',
    icon: Visibility,
};

export const BTN_PUBLISH_CHALLENGE = {
    key: 'publishChallenge',
    actions: 'view',
    subject: 'ChallengesAdmin',
    tooltip: 'challenge.publish.descriptor',
    icon: Telegram,
};

export const BTN_DELETE_CHALLENGE = {
    key: 'deleteChallenge',
    actions: 'edit',
    subject: 'Challenge',
    tooltip: 'delete',
    icon: Delete,
};

export const BTN_DUPLICATE_CHALLENGE = {
    key: 'createChallenge',
    actions: 'create',
    subject: 'Challenge',
    tooltip: 'duplicate',
    icon: AddToPhotos,
};

export const BTN_VIEW_ANALYTICS = {
    key: 'viewChallengeAnalytics',
    actions: 'view',
    subject: 'ChallengeAnalytics',
    tooltip: 'analytics',
    icon: Assessment,
};

export const BTN_DEACTIVATE_CHALLENGE = {
    key: 'optOutChallenge',
    actions: 'optOut',
    subject: 'Challenge',
    tooltip: 'deactivate',
    icon: RemoveCircle,
};

export const BTN_VIEW_PENALTIES = {
    key: 'viewChallengePenalties',
    actions: 'view',
    subject: 'ChallengePenalties',
    tooltip: 'challenge.penalties.descriptor',
    icon: Whatshot,
};

export const BTN_ACTIVATE_CHALLENGE = {
    key: 'activateChallenge',
    actions: 'activate',
    subject: 'Challenge',
    tooltip: 'activate',
    icon: AddBox,
};

export const BTN_DOWNLOAD_CHALLENGE_REPORT = {
    key: 'viewChallengeReports',
    actions: 'view',
    subject: 'ChallengeReports',
    tooltip: 'report.descriptor',
    icon: Folder,
};

export const BUTTONS_CELL = {
    [GROUP_ACTIVE]: [
        BTN_EDIT_CHALLENGE,
        BTN_VIEW_CHALLENGE,
        BTN_VIEW_ANALYTICS,
        BTN_DEACTIVATE_CHALLENGE,
        BTN_VIEW_PENALTIES,
        BTN_DOWNLOAD_CHALLENGE_REPORT,
    ],
    [GROUP_DRAFT]: [
        BTN_EDIT_CHALLENGE,
        BTN_VIEW_CHALLENGE,
        BTN_PUBLISH_CHALLENGE,
        BTN_DELETE_CHALLENGE,
    ],
    [GROUP_UPCOMING]: [
        BTN_VIEW_CHALLENGE,
        BTN_EDIT_CHALLENGE,
        BTN_DEACTIVATE_CHALLENGE,
        BTN_VIEW_PENALTIES,
    ],
    [GROUP_PAST]: [
        BTN_VIEW_CHALLENGE,
        BTN_VIEW_ANALYTICS,
        BTN_VIEW_PENALTIES,
        BTN_DOWNLOAD_CHALLENGE_REPORT,
        BTN_DUPLICATE_CHALLENGE,
    ],
    [GROUP_DEACTIVATED]: [BTN_VIEW_CHALLENGE, BTN_ACTIVATE_CHALLENGE],
};

export const ACTIVATE_ACTION = 'activate';
export const DEACTIVATE_ACTION = 'deactivate';
export const GENERATE_REPORT_ACTION = 'report.generate';

export const DELETE_CHALLENGE_ACTION = 'challenge.delete';

export const INTERCOM_TARGET_PREFIX = 'challenges';
