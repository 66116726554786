import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, withStyles } from '@material-ui/core';
import styles from './styles.js';
import clsx from 'clsx';
import { getAppImages } from '../../../utils';

const UrlIcon = ({ classes, url, title, isChallenge }) => {
    const [successLoad, setSuccessLoad] = useState(false);
    const [failLoad, setFailLoad] = useState(false);

    const onError = () => {
        setFailLoad(true);
    };

    const onLoad = () => {
        setSuccessLoad(true);
    };

    if (!url || failLoad)
        return (
            <img
                src={getAppImages().defaultCommunityIcon}
                alt="default community icon"
                className={classes.image}
            />
        );

    return (
        <Fragment>
            <img
                src={url}
                alt={title}
                className={
                    successLoad
                        ? clsx([
                              classes.image,
                              isChallenge && classes.borderIcon,
                          ])
                        : classes.hidden
                }
                onLoad={onLoad}
                onError={onError}
            />
            {!successLoad && (
                <CircularProgress size={20} className={classes.loader} />
            )}
        </Fragment>
    );
};

UrlIcon.propTypes = {
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    isChallenge: PropTypes.bool,
};

UrlIcon.defaultProps = {
    url: null,
    title: null,
    isChallenge: false,
};

export default withStyles(styles)(UrlIcon);
