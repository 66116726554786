const styles = (theme) => ({
    onBoardingSubtitle: {
        textAlign: 'left',
        marginLeft: '100px',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            padding: '0px 13px',
        },
    },
    asteriskRequired: {
        fontSize: '12px',
        color: theme.palette.disabled.main,
        flexWrap: 'wrap',
    },
    checkbox: {
        color: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
            fontSize: 30,
        },
    },
    linkText: {
        color: '#1D8DC7',
        fontWeight: '700',
    },
    onboardingSubmit: {
        marginTop: '20px',
        width: '60%',
        position: 'relative',
        margin: 'auto',
        height: '60px',
    },
});

export default styles;
