import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import InfoTooltip from '../../../../common/InfoTooltip';

const NotificationItemTitle = ({
    classes,
    title,
    tooltip,
    tooltipIntercomTarget,
    children,
    ...props
}) => {
    return (
        <Grid container className={classes.container} {...props}>
            <Grid item container alignItems="center">
                <Typography variant="h5" className={classes.title}>
                    {title}
                </Typography>
                {tooltip && (
                    <InfoTooltip
                        text={tooltip}
                        intercomTarget={tooltipIntercomTarget}
                    />
                )}
            </Grid>
            {children}
        </Grid>
    );
};

NotificationItemTitle.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    tooltipIntercomTarget: PropTypes.string,
};

NotificationItemTitle.defaultProps = {
    tooltip: null,
    children: null,
    tooltipIntercomTarget: '',
};

export default withStyles(styles)(NotificationItemTitle);
