const styles = (theme) => ({
    header: {
        fontSize: '36px',
        fontWeight: 600,
        marginTop: '40px',
        marginBottom: '40px',
    },
    helpIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '5px',
        fontSize: '65%',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    timezone: {
        fontSize: '14px',
        fontWeight: 400,
        marginBottom: '10px',
        color: '#999',
    },
    backIcon: {
        padding: 0,
    },
});

export default styles;
