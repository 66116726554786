import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        border: '1px solid transparent',
        borderRadius: '4px',
        borderWidth: 0,
        marginBottom: '20px',
        maxWidth: '800px',
    },
    header: {
        padding: '10px 15px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomWidth: 0,
        borderColor: '#ddd',
        backgroundColor: theme.custom.bgLightColor,
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    headerText: {
        fontSize: '16px',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    helpIcon: {
        marginLeft: '3px',
        color: theme.custom.tooltipIconColor,

        '&:hover': {
            color: theme.custom.tooltipIconHoverColor,
        },
    },
    body: {
        padding: '15px 40px',
        backgroundColor: theme.custom.bgLightAccentColor,
        paddingBottom: '100px',
    },
    dates: {
        marginBottom: '50px',
    },
    dateLabel: {
        fontWeight: 700,
        marginBottom: '5px',
    },
    infoIcon: {
        marginLeft: '3px',
        color: theme.custom.tooltipIconColor,
        cursor: 'pointer',

        '&:hover': {
            color: theme.custom.tooltipIconHoverColor,
        },
    },
    subHeaderContainer: {
        marginBottom: '5px',
    },
    subHeader: {
        fontWeight: 700,
    },
    groups: {
        marginBottom: '16px',
    },
    leftGroup: {
        marginRight: '20px',
    },
    rightGroup: {
        marginLeft: '20px',
    },
    templatesLabel: {
        fontWeight: 700,
        marginTop: '20px',
        marginBottom: '5px',
    },
    confirmButton: {
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: theme.custom.bgLightColor,
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
    },
    photo: {
        height: '130px',
        width: '130px',
    },
    challengeNameContainer: {
        marginTop: '20px',
        marginBottom: '40px',
    },
    challengeNameFormContainer: {
        marginLeft: '40px',
    },
    challengeNameFormInput: {
        width: '70%',
    },
    textArea: {
        resize: 'none',
    },
    cancelButton: {
        marginBottom: '20px',
        borderWidth: '2px',
    },
    clearIcon: {
        marginRight: '10px',
    },
    teamsTypeContainer: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    radioButtonLabel: {
        display: 'flex',
    },
    errorBelow: (props) => ({
        margin: `0 0 0 20px`,
        color: theme.palette.error.main,
    }),
}));

export default useStyles;
