import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import Button from '../../../Button';
import { getAppImages, noop } from '../../../../utils';
import { MANUALLY_JOINED, NOT_JOINED } from '../../../../user/constants';
import {
    isJoinRequestLoading,
    isUnjoinRequestLoading,
} from '../../../../community/selectors';
import { connect } from 'react-redux';

const CommunityCard = ({
    classes,
    id,
    image,
    name,
    memberCount,
    url,
    membershipStatus,
    unjoinEligibility,
    joinEligibility,
    onView,
    onJoin,
    onLeave,
    joinRequestLoading,
    leaveRequestLoading,
}) => {
    const { t } = useTranslation();

    const handleOnView = () => {
        onView(id);
    };

    const handleOnLeave = () => {
        onLeave(id);
    };

    const handleOnJoin = () => {
        onJoin(id);
    };

    return (
        <Grid
            className={classes.container}
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
        >
            <Grid direction="row" wrap="nowrap" item container>
                <Grid item>
                    <img
                        alt="Community"
                        className={classes.image}
                        src={image || getAppImages().defaultProfileIcon}
                    />
                </Grid>
                <Grid
                    className={classes.detailsContainer}
                    item
                    container
                    justifyContent="center"
                    direction="column"
                >
                    <Grid item container>
                        <Typography variant="body1">{name}</Typography>
                    </Grid>
                    <Grid item container className={classes.subTextContainer}>
                        {memberCount !== null && (
                            <Typography
                                className={classes.subText}
                                variant="body2"
                            >
                                {t('settings.user.communities.memberCount', {
                                    count: memberCount,
                                })}
                            </Typography>
                        )}
                        <Typography className={classes.subText} variant="body2">
                            {url}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.buttonContainer} item>
                <Button
                    className={classes.viewButton}
                    color="primary"
                    onClick={handleOnView}
                >
                    {t('settings.user.communities.buttons.view')}
                </Button>
            </Grid>
            {unjoinEligibility.eligible &&
                membershipStatus === MANUALLY_JOINED && (
                    <Grid className={classes.buttonContainer} item>
                        <Button
                            className={classes.leaveButton}
                            color="primary"
                            onClick={handleOnLeave}
                            loading={leaveRequestLoading}
                        >
                            {t('settings.user.communities.buttons.leave')}
                        </Button>
                    </Grid>
                )}
            {joinEligibility.eligible && membershipStatus === NOT_JOINED && (
                <Grid className={classes.buttonContainer} item>
                    <Button
                        className={classes.leaveButton}
                        color="primary"
                        onClick={handleOnJoin}
                        loading={joinRequestLoading}
                    >
                        {t('settings.user.communities.buttons.join')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

CommunityCard.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    name: PropTypes.string,
    memberCount: PropTypes.number,
    url: PropTypes.string,
    membershipStatus: PropTypes.string,
    joinEligibility: PropTypes.objectOf({ eligible: PropTypes.bool }),
    unjoinEligibility: PropTypes.objectOf({ eligible: PropTypes.bool }),
    onView: PropTypes.func,
    onJoin: PropTypes.func,
    onLeave: PropTypes.func,
    joinRequestLoading: PropTypes.bool.isRequired,
    leaveRequestLoading: PropTypes.bool.isRequired,
};

CommunityCard.defaultProps = {
    image: '',
    name: '',
    memberCount: null,
    url: '',
    membershipStatus: '',
    joinEligibility: { eligible: false },
    unjoinEligibility: { eligible: false },
    onView: noop,
    onJoin: noop,
    onLeave: noop,
};

const StyledCommunityCard = withStyles(styles)(CommunityCard);

const mapStateToProps = (state) => ({
    joinRequestLoading: isJoinRequestLoading(state),
    leaveRequestLoading: isUnjoinRequestLoading(state),
});

export default connect(mapStateToProps)(StyledCommunityCard);
