import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import {
    ACTIVATE_USERS_REQUESTED,
    activateUsersRequestFailed,
    activateUsersRequestSucceeded,
    CURRENT_USER_REQUESTED,
    DEACTIVATE_USERS_REQUESTED,
    deactivateUsersRequestFailed,
    deactivateUsersRequestSucceeded,
    DELETE_USER_ACCOUNT_REQUESTED,
    DELETE_USER_REQUESTED,
    deleteUserAccountRequestFailed,
    deleteUserAccountRequestSucceeded,
    deleteUserRequestFailed,
    deleteUserRequestSucceeded,
    MEMBERS_REQUESTED,
    MEMBERS_SEARCH_REQUESTED,
    membersRequested,
    membersRequestFailed,
    membersRequestSucceeded,
    membersSearchRequestFailed,
    membersSearchRequestSucceeded,
    MOVE_USER_ORGANIZATION_REQUESTED,
    moveUserOrganizationFailed,
    moveUserOrganizationSucceeded,
    OTHER_COMMUNITIES_REQUESTED,
    otherCommunitiesRequestFailed,
    otherCommunitiesRequestSucceeded,
    PERMISSIONS_AND_ROLES_REQUESTED,
    permissionsAndRolesRequestFailed,
    permissionsAndRolesRequestSucceeded,
    SET_ACTIVE_COMMUNITY,
    setActiveCommunityFailed,
    setActiveCommunitySucceeded,
    TOGGLE_USER_VIEW,
    UPDATE_USER_PERMISSIONS_REQUESTED,
    updateUserPermissionsFailed,
    updateUserPermissionsSucceeded,
    USER_DETAILS_REQUESTED,
    USER_EMAIL_UPDATE_REQUESTED,
    USER_REQUEST_SUCCEEDED,
    USER_UPDATE_REQUESTED,
    userDetailsRequestFailed,
    userDetailsRequestSucceeded,
    userEmailUpdateFailed,
    userEmailUpdateSucceeded,
    userRequested,
    userRequestFailed,
    userRequestSucceeded,
    userUpdateFailed,
    userUpdateSucceeded,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
    getToken,
    uploadWithAuth,
} from '../utils/api';
import {
    setAnalyticsCommunity,
    setUserID,
    trackEvent,
} from '../utils/analytics';
import {
    getActiveCommunity,
    getUser,
    isOrganization,
    isUserViewActive,
} from './selectors';
import ability, { getUserPermissions } from '../casl/ability';
import { ACTIVE_STATUS_ACTIVE, ACTIVE_STATUS_DEACTIVATED } from './constants';
import { INITIALIZATION_SUCCEEDED } from '../init/actions';
import i18n from 'i18next';
import { replace } from 'connected-react-router';
import {
    EVENT_PROFILE_ACTIVATED,
    EVENT_PROFILE_DEACTIVATED,
} from '../constants/analyticsEvents';

export function* getCurrentUser(action) {
    const token = getToken();
    if (!token || token === 'null') {
        return;
    }
    try {
        const data = yield call(fetchWithAuth, '/user/self');
        const user = data.response.users[0];
        const isUserView = yield select(isUserViewActive);
        if (!isUserView) {
            const activeCommunity = yield select(getActiveCommunity);
            const rules = getUserPermissions(user, activeCommunity?.id);
            rules.push({ actions: 'read', subject: 'all' });
            localStorage.setItem('rules', JSON.stringify(rules));
            ability.update(rules);
        }
        yield put(
            userRequestSucceeded(user, action?.payload?.isAuthenticating),
        );
        setUserID(user?.id);
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get authenticated user details.',
        );
        yield put(userRequestFailed(errors));
    }
}

export function* updateUser(action) {
    try {
        const {
            payload: {
                request: { communityId, userId, name, headline, file },
                isCreating,
            },
        } = action;
        const body = new FormData();
        body.append('id', userId);
        body.append('name', name);
        body.append('headline', headline);
        if (file != null) {
            body.append('file', file);
        }
        const updateData = yield call(
            uploadWithAuth,
            `/user/${userId}/update`,
            {
                method: 'POST',
                query: {
                    communityId,
                },
                body,
            },
        );
        const response = updateData.response.users[0];
        yield put(userUpdateSucceeded(response));
        if (isCreating) {
            yield put(userRequested(true));
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update user',
        );
        yield put(userUpdateFailed(errors));
    }
}

export function* updateUserEmail(action) {
    try {
        const {
            request: { userId, email },
        } = action.payload;

        const body = new FormData();
        body.append('id', userId);
        body.append('email', email);

        const updateResponse = yield call(
            uploadWithAuth,
            `/user/${userId}/update`,
            {
                method: 'POST',
                body,
            },
        );
        const response = updateResponse.response.users[0];

        yield put(userEmailUpdateSucceeded(response));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update user email',
        );
        yield put(userEmailUpdateFailed(errors));
    }
}

export function* getMembers(action) {
    const { communityId, activeStatus, options } = action.payload;
    try {
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/members`,
            {
                query: {
                    activeStatus,
                    context: 'group',
                    ...options,
                },
            },
        );
        yield put(
            membersRequestSucceeded({
                users: data.response.users || [],
                totalCount: data.response.totalCount,
                activeStatus,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('usersPage.getMembersFailed'),
        );
        yield put(membersRequestFailed(activeStatus, errors));
    }
}

export function* searchMembers(action) {
    const { communityId, searchString, activeStatus, options } = action.payload;
    try {
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/members/search`,
            {
                query: {
                    context: 'group',
                    searchString,
                    activeStatus,
                    ...options,
                },
            },
        );
        yield put(
            membersSearchRequestSucceeded({
                users: data.response.users || [],
                totalCount: data.response.totalCount,
                activeStatus,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('usersPage.getMembersFailed'),
        );
        yield put(membersSearchRequestFailed(activeStatus, errors));
    }
}

export function* getPermissions(action) {
    try {
        const { communityId } = action.payload;
        const data = yield call(
            fetchWithAuth,
            `kiosk/group/${communityId}/permissions`,
        );

        const permissions = data.response.permissions;
        const roles = data.response.roles;

        yield put(permissionsAndRolesRequestSucceeded(permissions, roles));
    } catch (error) {
        yield put(permissionsAndRolesRequestFailed());
    }
}

export function* activateUsers(action) {
    trackEvent(EVENT_PROFILE_ACTIVATED);

    try {
        const { communityId, userIds } = action.payload;
        yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/user/reactivate`,
            {
                method: 'POST',
                body: {
                    userIds: userIds.join(','),
                },
            },
        );

        yield put(activateUsersRequestSucceeded());
        yield put(
            membersRequested(communityId, ACTIVE_STATUS_ACTIVE, { limit: 10 }),
        );
        yield put(
            membersRequested(communityId, ACTIVE_STATUS_DEACTIVATED, {
                limit: 10,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to activate users.',
        );
        yield put(activateUsersRequestFailed(errors));
    }
}

export function* deactivateUsers(action) {
    trackEvent(EVENT_PROFILE_DEACTIVATED);

    try {
        const { communityId, userIds } = action.payload;
        yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/user/deactivate`,
            {
                method: 'POST',
                body: {
                    userIds: userIds.join(','),
                },
            },
        );

        yield put(deactivateUsersRequestSucceeded());
        yield put(
            membersRequested(communityId, ACTIVE_STATUS_ACTIVE, { limit: 10 }),
        );
        yield put(
            membersRequested(communityId, ACTIVE_STATUS_DEACTIVATED, {
                limit: 10,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to deactivate users.',
        );
        yield put(deactivateUsersRequestFailed(errors));
    }
}

export function* handleUpdatedCommunity() {
    try {
        const user = yield select(getUser);
        const activeCommunity = yield select(getActiveCommunity);
        const isUserView = yield select(isUserViewActive);
        let activeCommunityId = activeCommunity?.id;
        let rules = getUserPermissions(user, activeCommunityId);
        if (isUserView) {
            activeCommunityId = user.organization.id;
            rules = [];
        }
        rules.push({ actions: 'read', subject: 'all' });
        localStorage.setItem('rules', JSON.stringify(rules));
        ability.update(rules);
        const params = new URLSearchParams(window.location.search);
        if (params.get('communityId') !== activeCommunityId) {
            params.delete('communityId');
            params.append('communityId', activeCommunityId);
            yield put(replace({ search: params.toString() }));
            setAnalyticsCommunity(activeCommunity);
        }
        yield put(setActiveCommunitySucceeded(activeCommunityId || ''));
    } catch (error) {
        yield put(
            setActiveCommunityFailed([
                'Failed to set permissions for the selected community. Please contact your administrator.',
            ]),
        );
    }
}

export function* removeUser(action) {
    const { communityId, userIds } = action.payload;
    const isOrg = yield select(isOrganization);
    if (isOrg) {
        /*
         * Can't remove a user from their org
         * TODO: Update error string
         */
        yield put(deleteUserRequestFailed(['Can not remove user from org']));
        return;
    }

    try {
        yield call(fetchWithAuth, `/kiosk/group/${communityId}/users/remove`, {
            method: 'DELETE',
            body: {
                userIds: userIds,
            },
        });

        yield put(deleteUserRequestSucceeded());
        yield put(
            membersRequested(communityId, ACTIVE_STATUS_ACTIVE, { limit: 10 }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to delete user.',
        );
        yield put(deleteUserRequestFailed(errors));
    }
}
export function* deleteUser(action) {
    try {
        const { communityId, userIds } = action.payload;
        const isOrg = yield select(isOrganization);
        if (!isOrg) {
            // The behavior to 'remove' a user from a community differs from a organization.
            return yield call(removeUser, action);
        }
        yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/user/${userIds[0]}/delete`,
            { method: 'DELETE' },
        );
        yield put(deleteUserRequestSucceeded());
        yield put(
            membersRequested(communityId, ACTIVE_STATUS_ACTIVE, { limit: 10 }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to delete user.',
        );
        yield put(deleteUserRequestFailed(errors));
    }
}

export function* deleteAccount(action) {
    try {
        const {
            payload: { userId },
        } = action;
        yield call(fetchWithAuth, `/kiosk/user/${userId}/deleteuser`, {
            method: 'DELETE',
        });
        yield put(deleteUserAccountRequestSucceeded());
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to delete account.',
        );
        yield put(deleteUserAccountRequestFailed(errors));
    }
}

export function* updatePermissions(action) {
    const {
        payload: { communityId, ...body },
    } = action;
    try {
        yield call(fetchWithAuth, `/kiosk/group/${communityId}/setRole`, {
            method: 'POST',
            body,
        });
        yield put(updateUserPermissionsSucceeded());
        const activeCommunity = yield select(getActiveCommunity);
        yield put(
            membersRequested(activeCommunity.id, ACTIVE_STATUS_ACTIVE, {
                limit: 10,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update user role and/or permissions.',
        );
        yield put(updateUserPermissionsFailed(errors));
    }
}

export function* moveOrgs(action) {
    const {
        payload: { communityId, userId, newOrganizationId },
    } = action;
    try {
        yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/user/${userId}/moveorg`,
            {
                method: 'POST',
                query: { newOrganizationId },
            },
        );
        yield put(moveUserOrganizationSucceeded());
        yield put(
            membersRequested(communityId, ACTIVE_STATUS_ACTIVE, { limit: 10 }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to switch organizations.',
        );
        yield put(moveUserOrganizationFailed(errors));
    }
}

export function* fetchUserDetails(action) {
    try {
        const {
            payload: { userId },
        } = action;
        const data = yield call(fetchWithAuth, `/user/${userId}`);
        const user = data.response.users[0];
        yield put(userDetailsRequestSucceeded(user));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get user information.',
        );
        yield put(userDetailsRequestFailed(errors));
    }
}

export function* fetchOtherCommunities(action) {
    try {
        const data = yield call(fetchWithAuth, `/community/available`, {
            query: {
                skip: 0,
                limit: 5,
            },
        });

        const communities = data.response.communities;
        yield put(otherCommunitiesRequestSucceeded(communities));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get other communities.',
        );
        yield put(otherCommunitiesRequestFailed(errors));
    }
}

export default function*() {
    yield all([
        takeLatest(USER_DETAILS_REQUESTED, fetchUserDetails),
        takeLatest(CURRENT_USER_REQUESTED, getCurrentUser),
        takeLatest(INITIALIZATION_SUCCEEDED, getCurrentUser),
        takeLatest(USER_UPDATE_REQUESTED, updateUser),
        takeLatest(USER_EMAIL_UPDATE_REQUESTED, updateUserEmail),
        takeEvery(MEMBERS_REQUESTED, getMembers),
        takeEvery(MEMBERS_SEARCH_REQUESTED, searchMembers),
        takeLatest(PERMISSIONS_AND_ROLES_REQUESTED, getPermissions),
        takeLatest(ACTIVATE_USERS_REQUESTED, activateUsers),
        takeLatest(DEACTIVATE_USERS_REQUESTED, deactivateUsers),
        takeLatest(SET_ACTIVE_COMMUNITY, handleUpdatedCommunity),
        takeLatest(USER_REQUEST_SUCCEEDED, handleUpdatedCommunity),
        takeLatest(TOGGLE_USER_VIEW, handleUpdatedCommunity),
        takeLatest(DELETE_USER_REQUESTED, deleteUser),
        takeLatest(DELETE_USER_ACCOUNT_REQUESTED, deleteAccount),
        takeLatest(UPDATE_USER_PERMISSIONS_REQUESTED, updatePermissions),
        takeLatest(MOVE_USER_ORGANIZATION_REQUESTED, moveOrgs),
        takeLatest(OTHER_COMMUNITIES_REQUESTED, fetchOtherCommunities),
    ]);
}
