const styles = (theme) => ({
    splitItem: {
        [theme.breakpoints.down('sm')]: {
            height: 'auto !important',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundImage: 'none !important',
        },
    },
});

export default styles;
