import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Input, IconButton, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import styles from './styles';
import { noop } from '../../utils';
import { useTranslation } from 'react-i18next';

const SearchBar = ({
    classes,
    placeholder = 'Search',
    handleTextChange,
    runSearch,
}) => {
    const [value, setValue] = useState('');
    const { t } = useTranslation();

    const onSearchButtonClicked = () => runSearch(value);
    const onSearchTextChanged = (event) => {
        setValue(event.target.value);
        handleTextChange(event.target.value);
    };
    const clear = () => {
        setValue('');
        handleTextChange('');
    };

    return (
        <Grid container direction="row" wrap="nowrap">
            <IconButton
                className={classes.searchButton}
                onClick={onSearchButtonClicked}
                aria-label={t('actions.search')}
            >
                <SearchIcon />
            </IconButton>
            <Input
                name="search"
                placeholder={placeholder}
                autoComplete="off"
                classes={{
                    root:
                        value === ''
                            ? classes.searchFieldRootEmpty
                            : classes.searchFieldRootWithText,
                    input: classes.searchFieldInput,
                }}
                value={value}
                onChange={onSearchTextChanged}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        setTimeout(onSearchButtonClicked, 200);
                    }
                }}
            />
            {value === '' ? null : (
                <IconButton className={classes.clearButton} onClick={clear}>
                    <ClearIcon />
                </IconButton>
            )}
        </Grid>
    );
};

SearchBar.propTypes = {
    classes: PropTypes.object.isRequired,
    handleTextChange: PropTypes.func,
    runSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
    placeholder: 'Search',
    handleTextChange: noop,
};

export default withStyles(styles)(SearchBar);
