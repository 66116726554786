import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL } from './constants';

const REXEL_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'Rexel Earth',
    shouldShowSignUp: false,
    clientId: '62eae37f05a31e4aabeccb23',
    clientSecret:
        '03xq0CsXzta6mNvRepm8gqPmUV32SetGjVaXRyNgc40GDXRnlKLZnPrw1fsSHHOrGV',
    loginMethods: [LOGIN_METHOD_EMAIL],
    apiUri: process.env.REACT_APP_JBE_API_URI,
    supportedLanguageCodes: ['en', 'es'],
    appDescription:
        'With Rexel USA, compete in challenges with fellow co-workers to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we work towards common goals.',
};
export default REXEL_PROPERTIES;
