import {
    ACTIVITY_ADD_COMMENT_REQUEST_FAILED,
    ACTIVITY_ADD_COMMENT_REQUEST_SUCCEEDED,
    ACTIVITY_ADD_COMMENT_REQUESTED,
    ACTIVITY_CLEAR_ERRORS,
    ACTIVITY_DATA_REQUEST_FAILED,
    ACTIVITY_DATA_REQUEST_SUCCEEDED,
    ACTIVITY_DATA_REQUESTED,
    ACTIVITY_DETAIL_REQUEST_FAILED,
    ACTIVITY_DETAIL_REQUEST_SUCCEEDED,
    ACTIVITY_DETAIL_REQUESTED,
    ACTIVITY_LIKE_REQUEST_FAILED,
    ACTIVITY_LIKE_REQUEST_SUCCEEDED,
    ACTIVITY_LIKE_REQUESTED,
    ACTIVITY_SHARE_URL_REQUEST_FAILED,
    ACTIVITY_SHARE_URL_REQUEST_SUCCEEDED,
    ACTIVITY_SHARE_URL_REQUESTED,
    CLEAR_ACTIVITIES,
} from './actions';
import { mergeItemById, mergePaginatedResponse } from '../common/utils';
import {
    ACTIVITIES_FEED_TYPE_CHALLENGE,
    ACTIVITIES_FEED_TYPE_FOLLOWING,
    ACTIVITIES_FEED_TYPE_TEAM,
    ACTIVITIES_FEED_TYPE_TRENDING,
    ACTIVITIES_FEED_TYPE_USER,
} from './constants';

export const initialState = {
    activities: {
        [ACTIVITIES_FEED_TYPE_TRENDING]: {
            activities: [],
            loading: false,
        },
        [ACTIVITIES_FEED_TYPE_FOLLOWING]: {
            activities: [],
            loading: false,
        },
        [ACTIVITIES_FEED_TYPE_CHALLENGE]: {
            activities: [],
            loading: false,
        },
        [ACTIVITIES_FEED_TYPE_TEAM]: {
            activities: [],
            loading: false,
        },
        [ACTIVITIES_FEED_TYPE_USER]: {
            activities: [],
            loading: false,
        },
    },
    errors: [],
    messages: [],
    activityDetail: {
        loading: false,
        activity: null,
        errors: [],
    },
    likePost: {
        activityId: null,
    },
    addComment: {
        activityId: null,
    },
    shareURL: {
        activityId: null,
    },
};

/**
 * Merge the updated activity into all of the activity lists
 * that we currently have in the state
 * @param activities current state.activities
 * @param activity The activity to merge
 * @returns {string} The new state.activities
 */
const mergeActivityState = (activities, activity) => {
    return Object.keys(activities)?.reduce((a, key) => {
        a[key].activities = mergeItemById(a[key].activities, activity);
        return a;
    }, activities);
};

export default function activitiesReducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case ACTIVITY_DATA_REQUESTED:
            return {
                ...state,
                activities: {
                    ...state.activities,
                    [payload.activitiesType]: {
                        ...state.activities[payload.activitiesType],
                        loading: true,
                    },
                },
            };
        case ACTIVITY_DATA_REQUEST_SUCCEEDED:
            return {
                ...state,
                loading: false,
                activities: {
                    ...state.activities,
                    [payload.activitiesType]: {
                        loading: false,
                        activities: mergePaginatedResponse(
                            state.activities[payload.activitiesType].activities,
                            payload.newActivityData,
                            payload.skip,
                        ),
                    },
                },
            };
        case ACTIVITY_DATA_REQUEST_FAILED:
            return {
                ...state,
                activities: {
                    ...state.activities,
                    [payload.activitiesType]: {
                        ...state.activities[payload.activitiesType],
                        loading: false,
                    },
                },
                errors: payload.errors,
            };
        case ACTIVITY_DETAIL_REQUESTED:
            return {
                ...state,
                activityDetail: {
                    ...state.activityDetail,
                    errors: [],
                    loading: true,
                    activity: null,
                },
            };
        case ACTIVITY_DETAIL_REQUEST_SUCCEEDED:
            return {
                ...state,
                activityDetail: {
                    ...state.activityDetail,
                    activity: payload.activity,
                    errors: [],
                    loading: false,
                },
            };
        case ACTIVITY_DETAIL_REQUEST_FAILED:
            return {
                ...state,
                activityDetail: {
                    ...state.activityDetail,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case ACTIVITY_SHARE_URL_REQUESTED:
            return {
                ...state,
                shareURL: {
                    ...state.shareURL,
                    activityId: payload.activityId,
                },
            };
        case ACTIVITY_SHARE_URL_REQUEST_SUCCEEDED: {
            const activity = Object.values(state.activities)
                .flatMap((activities) => activities.activities)
                .find((activity) => activity?.id === payload.activityId);
            if (activity) {
                activity.shareURL = payload.url;
                return {
                    ...state,
                    activities: mergeActivityState(state.activities, activity),
                    shareURL: {
                        ...state.shareURL,
                        activityId: null,
                    },
                };
            }
            return {
                ...state,
                shareURL: {
                    ...state.shareURL,
                    activityId: null,
                },
            };
        }
        case ACTIVITY_SHARE_URL_REQUEST_FAILED:
            return {
                ...state,
                errors: payload.errors,
                shareURL: {
                    ...state.shareURL,
                    activityId: null,
                },
            };
        case ACTIVITY_LIKE_REQUESTED:
            return {
                ...state,
                likePost: {
                    ...state.likePost,
                    activityId: payload.activityId,
                },
            };
        case ACTIVITY_LIKE_REQUEST_SUCCEEDED:
            return {
                ...state,
                activities: mergeActivityState(
                    state.activities,
                    payload.activity,
                ),
                likePost: {
                    ...state.likePost,
                    activityId: null,
                },
                activityDetail: {
                    ...state.activityDetail,
                    activity: payload.activity,
                },
            };
        case ACTIVITY_LIKE_REQUEST_FAILED:
            return {
                ...state,
                errors: payload.errors,
                likePost: {
                    ...state.likePost,
                    activityId: null,
                },
            };
        case ACTIVITY_ADD_COMMENT_REQUESTED:
            return {
                ...state,
                addComment: {
                    ...state.addComment,
                    activityId: payload.activityId,
                },
            };
        case ACTIVITY_ADD_COMMENT_REQUEST_SUCCEEDED:
            return {
                ...state,
                activities: mergeActivityState(
                    state.activities,
                    payload.activity,
                ),
                addComment: {
                    ...state.addComment,
                    activityId: null,
                },
                activityDetail: {
                    ...state.activityDetail,
                    activity: payload.activity,
                },
            };
        case ACTIVITY_ADD_COMMENT_REQUEST_FAILED:
            return {
                ...state,
                errors: payload.errors,
                addComment: {
                    ...state.addComment,
                    activityId: null,
                },
            };
        case ACTIVITY_CLEAR_ERRORS:
            return {
                ...state,
                errors: [],
                messages: [],
            };
        case CLEAR_ACTIVITIES: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    [payload.activitiesType]: {
                        activities: [],
                        loading: false,
                    },
                },
            };
        }
        default:
            return state;
    }
}
