import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Modal from '../Modal';
import SummaryModal from './SummaryModal';
import CommentModal from './CommentModal';

import styles from './styles';
import { MODAL_STATE_COMMENT, MODAL_STATE_SUMMARY } from './constants';
import RequestLoader from '../RequestLoader';

const LogAction = ({
    classes,
    isOpen,
    onClose,
    onCancel,
    summaryImageLink,
    bonuses,
    encouragements,
    challengeUpdates,
    okayFn,
    logActionFunc,
    onShare,
    shareURLLoading,
    loading,
}) => {
    const [modalState, setModalState] = useState(MODAL_STATE_COMMENT);

    const handleOnClose = useCallback(() => {
        setModalState(MODAL_STATE_COMMENT);
        onClose();
    }, [setModalState, onClose]);

    const handleSubmit = useCallback(
        (message, imageFile, imageMD5) => {
            logActionFunc(message, imageFile, imageMD5);
            setModalState(MODAL_STATE_SUMMARY);
        },
        [logActionFunc],
    );

    const handleOnOkay = useCallback(() => {
        setModalState(MODAL_STATE_COMMENT);
        okayFn();
    }, [okayFn]);

    const handleOnShare = useCallback(() => {
        onShare();
    }, [onShare]);

    const modalContents = useCallback(() => {
        if (loading) {
            return <RequestLoader isLoading={loading} />;
        } else if (modalState === MODAL_STATE_COMMENT) {
            return (
                <CommentModal
                    onClose={handleOnClose}
                    onCancel={onCancel}
                    setModalState={setModalState}
                    onSubmit={handleSubmit}
                    PhotoProps={{
                        CropProps: {
                            minWidth: 100,
                            unit: '%',
                        },
                    }}
                />
            );
        } else if (modalState === MODAL_STATE_SUMMARY) {
            return (
                <SummaryModal
                    onClose={handleOnClose}
                    summaryImageLink={summaryImageLink}
                    bonuses={bonuses}
                    encouragements={encouragements}
                    challengeUpdates={challengeUpdates}
                    okayFn={handleOnOkay}
                    onShare={handleOnShare}
                    shareURLLoading={shareURLLoading}
                />
            );
        }
    }, [
        loading,
        onCancel,
        summaryImageLink,
        bonuses,
        encouragements,
        challengeUpdates,
        modalState,
        handleOnOkay,
        handleSubmit,
        handleOnClose,
        handleOnShare,
        shareURLLoading,
    ]);

    return (
        <Modal
            classes={{ paper: classes.modal, scrollPaper: classes.scrollPaper }}
            isOpen={isOpen}
            onClose={handleOnClose}
        >
            {modalContents()}
        </Modal>
    );
};

LogAction.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    summaryImageLink: PropTypes.string.isRequired,
    encouragements: PropTypes.arrayOf(PropTypes.string),
    challengeUpdates: PropTypes.arrayOf(PropTypes.string),
    bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            points: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ),
    okayFn: PropTypes.func.isRequired,
    logActionFunc: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    shareURLLoading: PropTypes.bool,
    loading: PropTypes.bool,
};

LogAction.defaultProps = {
    challengeUpdates: [],
    encouragements: [],
    bonuses: null,
    shareURLLoading: false,
    loading: false,
};

export default withStyles(styles)(LogAction);
