import React, { memo, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward, ImportExport } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { columnsPropType } from '../propTypes';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const _getSortByToggleProps = (cell) => {
    // Intentionally removing "title" prop because that ends up being the tooltip because that's what browsers do.
    const { title, ...rest } = cell.getSortByToggleProps();
    return rest;
};

const TableHeader = memo(({ classes, columns, cellActions, sortBy }) => {
    useMemo(() => ({ sortBy }), [sortBy]);

    const { t } = useTranslation();

    const renderCell = (cell) => {
        if (typeof cell.Header === 'string' || cell.Header instanceof String) {
            cell.Header = t(cell.Header);
        }
        if (
            cell.id === 'selection' ||
            cell.id === 'icon' ||
            cell.sortable === false
        ) {
            return cell.render('Header');
        } else if (cell.id === 'actions') {
            return cellActions;
        }

        return (
            <TableSortLabel
                active
                classes={{ active: classes.sortLabel }}
                IconComponent={
                    !cell.isSorted
                        ? ImportExport
                        : cell.isSortedDesc
                        ? ArrowDownward
                        : ArrowUpward
                }
            >
                {cell.render('Header')}
            </TableSortLabel>
        );
    };

    return (
        <TableHead className={classes.root}>
            {columns.map((headerGroup, rowIdx) => (
                <TableRow
                    key={`headRow-${rowIdx}`}
                    {...headerGroup.getHeaderGroupProps()}
                >
                    {headerGroup.headers.map((cell, cellIdx) => (
                        <TableCell
                            key={`headCell-${cellIdx}`}
                            padding={
                                cell.id === 'selection' ? 'checkbox' : 'default'
                            }
                            className={
                                cell.id !== 'actions'
                                    ? classes.defaultCell
                                    : classes.checkboxCell
                            }
                            {...(cell.id === 'selection' ||
                            cell.id === 'icon' ||
                            cell.sortable === false ||
                            cell.id === 'actions'
                                ? cell.getHeaderProps()
                                : cell.getHeaderProps(
                                      _getSortByToggleProps(cell),
                                  ))}
                        >
                            <div
                                className={clsx([
                                    cell.align &&
                                        cell.align === 'center' &&
                                        classes.centerAlignHeader,
                                    cell.align &&
                                        cell.align === 'left' &&
                                        classes.leftAlignHeader,
                                    cell.align &&
                                        cell.align === 'right' &&
                                        classes.rightAlignHeader,
                                ])}
                            >
                                {renderCell(cell)}
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableHead>
    );
});

TableHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    cellActions: PropTypes.node,
    columns: columnsPropType.isRequired,
    sortBy: PropTypes.array,
};

TableHeader.defaultProps = {
    cellActions: null,
    sortBy: [],
};

export default withStyles(styles)(TableHeader);
