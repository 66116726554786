import { CLEAR_QUEUED_DEEP_LINK, QUEUE_DEEP_LINK } from './actions';

export const deepLinksInitialState = {
    queuedDeepLinkPath: null,
};

export default function deepLinksReducer(
    state = deepLinksInitialState,
    action,
) {
    const { type, payload = {} } = action;
    switch (type) {
        case QUEUE_DEEP_LINK:
            return {
                ...state,
                queuedDeepLinkPath: payload.deepLinkPath,
            };
        case CLEAR_QUEUED_DEEP_LINK:
            return {
                ...state,
                queuedDeepLinkPath: null,
            };
        default:
            return state;
    }
}
