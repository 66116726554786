import templateStyles from '../styles';

const styles = (theme) => {
    return {
        ...templateStyles(theme),
        button: {
            margin: '10px 0 15px',
            textDecoration: 'none',
        },
    };
};

export default styles;
