import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';

const ColorImage = ({ imageUrl, imageColor, height, width }) => {
    const [id] = useState(_uniqueId());
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={height}
            height={width}
            style={{ float: 'left' }}
        >
            <defs>
                <filter id={`colorMask${id}`}>
                    <feFlood floodColor={imageColor} result="flood" />
                    <feComposite
                        in="SourceGraphic"
                        in2="flood"
                        operator="arithmetic"
                        k1="1"
                        k2="0"
                        k3="0"
                        k4="0"
                    />
                </filter>
            </defs>
            <image
                width="100%"
                height="100%"
                href={imageUrl}
                filter={`url(#colorMask${id})`}
            />
        </svg>
    );
};

ColorImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    imageColor: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
};

ColorImage.defaultProps = {
    height: 50,
    width: 50,
};

export default ColorImage;
