import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { DATE_FORMAT } from '../constants';
import Tooltip from '../../../common/Tooltip';
import styles from './styles';
import PenaltyCell from '../PenaltyCell';

const PenaltyLevelCell = ({ classes, data }) => {
    const { t } = useTranslation();
    const level = (data?.name.match(/\d+$/) || [''])[0];

    return (
        <>
            <PenaltyCell
                totalTooltip={t('challenge.penalties.totalLevelPenalties', {
                    level,
                })}
                lastTooltip={t('challenge.penalties.lastPenalized')}
                created={data?.created}
                count={data?.count}
            />
            {data?.active ? (
                level <= 2 ? (
                    <Typography component="span">
                        {t('challenge.penalties.activeUntil')}
                        <br />
                        {moment(data?.expiration)}
                    </Typography>
                ) : (
                    <Tooltip title={t('challenge.penalties.penalizedUntil')}>
                        <Typography component="span">
                            <Typography
                                component="span"
                                className={clsx([classes.small, classes.until])}
                            >
                                {moment(data?.expiration).format(DATE_FORMAT)}
                            </Typography>
                        </Typography>
                    </Tooltip>
                )
            ) : null}
        </>
    );
};

PenaltyLevelCell.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.shape({
        name: PropTypes.string,
        active: PropTypes.bool,
        count: PropTypes.number,
        created: PropTypes.string,
        expiration: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(PenaltyLevelCell);
