import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { noop } from '../utils';
import styles from './styles';
import { getAppImages } from '../../utils';

const CommunityCard = ({ classes, name, url, active, ...props }) => {
    return (
        <ListItem
            button
            classes={{
                root: classes.listItemRoot,
                button: classes.listItemButton,
            }}
            {...props}
        >
            <ListItemAvatar>
                <Avatar
                    alt={name}
                    src={url || getAppImages().defaultCommunityIcon}
                    className={classes.img}
                />
            </ListItemAvatar>
            <ListItemText
                primary={name}
                primaryTypographyProps={{
                    variant: 'h4',
                    className: clsx([classes.title, active && classes.active]),
                }}
            />
        </ListItem>
    );
};

CommunityCard.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    active: PropTypes.bool,
    onClick: PropTypes.func,
};

CommunityCard.defaultProps = {
    url: null,
    active: false,
    onClick: noop,
};

export default withStyles(styles)(CommunityCard);
