const styles = (theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',

        '&.Mui-expanded': {
            margin: 0,
        },

        '&:before': {
            display: 'none',
        },
    },
    summary: {
        padding: 0,

        '&.Mui-expanded': {
            minHeight: '48px',
        },

        '&:hover': {
            backgroundColor: '#f5f6f7',
        },
    },
    title: {
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    summaryContent: {
        '&.Mui-expanded': {
            margin: '12px 0',
        },
    },
    details: {
        padding: 0,
    },
    detailsContent: {
        borderTop: '1px solid #b3b3b3',
        padding: '3px 30px 0',
    },
    detailsItem: {
        marginBottom: '15px',
    },
    helpIcon: {
        marginLeft: '5px',
        lineHeight: 1,
        color: theme.custom.iconColor,

        '&:hover': {
            color: theme.custom.iconColorHover,
            cursor: 'pointer',
        },
    },
    arrowIcon: {
        transition: 'transform 0.3s linear',
    },
    rotate: {
        transform: 'rotate(90deg)',
    },
    disabled: {
        color: '#b3b3b3',
    },
    small: {
        fontSize: '13.5px',
    },
    countText: {
        paddingRight: '20px',
    },
});

export default styles;
