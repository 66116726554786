const styles = (theme) => ({
    header: {
        marginBottom: '10px',
        paddingBottom: '20px',
        borderBottom: `1px solid ${theme.custom.textLightSecondaryColor}`,
    },
    title: {
        fontSize: '18px',
        fontWeight: 600,
    },
    titleIcon: {
        marginLeft: '5px',
        color: theme.custom.iconColor,

        '&:hover': {
            color: theme.custom.iconColorHover,
            cursor: 'pointer',
        },
    },
    label: {
        margin: '20px 0 10px',
        fontSize: '24px',
        fontWeight: 600,
    },
    select: {
        margin: '10px 0',
        fontSize: '24px',
    },
});

export default styles;
