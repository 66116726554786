import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import queryString from 'query-string';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getActiveUsersGraphData,
    getActivityStats,
    getImpactStats,
    getNewUsersGraphData,
    getTopFiveActions,
    getTopFiveUsers,
    getTotalLogsGraphData,
    getUserStats,
    isActivityStatsLoading,
    isGraphDataLoading,
    isImpactStatsLoading,
    isUserStatsLoading,
} from '../../analytics/selectors';
import {
    arePhotosLoading,
    getTopPhotos,
} from '../../analytics/photos/selectors';
import { communityPhotosRequested } from '../../analytics/photos/actions';
import {
    activitiesRequested,
    graphDataRequested,
    impactStatsRequested,
    usersAnalyticRequested,
} from '../../analytics/actions';
import Graph from '../../common/Graph';
import {
    CHART_INTERVAL_DAY,
    CHART_INTERVALS,
    GRAPH_METRIC_ACTIVE_USERS,
    GRAPH_METRIC_NEW_USERS,
    GRAPH_METRIC_TOTAL_LOGS,
} from '../../common/Graph/ChartItem/constants';
import TabbedSection from '../../common/TabbedSection';
import StatCard from '../../common/StatCard';
import Leaderboard from '../../common/Leaderboard';
import TopPhotos from '../../common/TopPhotos';
import RequestLoader from '../../common/RequestLoader';
import IMAGES from '../../constants/images';
import styles from './styles';
import {
    PHOTOS_BATCH_SIZE,
    TOP_PHOTOS_SIZE,
} from '../../common/TopPhotos/constants';
import { getAppImages } from '../../utils';
import { trackEvent } from '../../utils/analytics';
import { ANALYTICS_SCREEN } from '../../common/PhotoCard/constants';
import { getResolution } from '../../analytics/utils';
import DatePickerRange from '../../common/DatePickerRange';
import { LEADERBOARD_TYPE_TOP_USERS } from '../../common/Leaderboard/constants';
import HeaderPage from '../../common/HeaderPage';
import { ANALYTICS_FILTER_CHANGED } from '../../constants/analyticsEvents';

export const DATE_FORMAT = 'MM/DD/YYYY';

const INTERCOM_TARGET_PREFIX = 'analytics';

const AnalyticsScreen = ({
    classes,
    communityId,
    activityStats,
    userStats,
    impactStats,
    totalLogsGraphData,
    newUsersGraphData,
    activeUsersGraphData,
    topUsers,
    topActions,
    fetchActivityStats,
    isActivityLoading,
    fetchUserStats,
    isUserLoading,
    fetchImpactStats,
    isImpactLoading,
    fetchGraphData,
    isGraphLoading,
    fetchTopPhotos,
    topPhotos,
    isPhotosLoading,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [intervals, setIntervals] = useState(CHART_INTERVALS);
    const [interval, setInterval] = useState(CHART_INTERVAL_DAY);
    const [metric, setMetric] = useState(GRAPH_METRIC_TOTAL_LOGS);
    const [tabIndex, setTabIndex] = useState(0);

    const queryVals = queryString.parse(location.search);
    const [startDate, setStartDate] = useState(
        queryVals.startDate
            ? moment(queryVals.startDate)
            : moment().subtract(1, 'months'),
    );
    const [endDate, setEndDate] = useState(
        queryVals.endDate ? moment(queryVals.endDate) : moment(),
    );
    const [dateChange, setDateChange] = useState(false);

    const charts = [
        { name: t('totalLogs.title') },
        { name: t('activeUsers.title') },
        { name: t('newUsers.title') },
    ];
    const activityStatsTab = [
        {
            id: 0,
            title: t('totalLogs.title'),
            info: t('totalLogs.description'),
        },
        {
            id: 1,
            title: t('Points'),
            info: t('points.description'),
        },
        {
            id: 2,
            title: t('actionsMastered.title'),
            info: t('actionsMastered.description'),
        },
        {
            id: 3,
            title: t('uniqueActions.title'),
            info: t('uniqueActions.description'),
        },
    ];
    const userStatsTab = [
        {
            id: 0,
            title: t('activeUsers.title'),
            info: t('activeUsers.description'),
        },
        {
            id: 1,
            title: t('newUsers.title'),
            info: t('newUsers.description'),
        },
        {
            id: 2,
            title: t('logsPerUser.title'),
            info: t('logsPerUser.description'),
        },
        {
            id: 3,
            title: t('logsPerUserPerDay.title'),
            info: t('logsPerUserPerDay.description'),
        },
    ];

    const renderStatsTab = (stats, values, statCardGridItemProps = {}) => (
        <Grid container spacing={2} justify="space-evenly">
            {stats.map((statDetails, index) => (
                <Grid
                    key={statDetails.id}
                    item
                    xs={12}
                    sm={12}
                    md
                    {...statCardGridItemProps}
                >
                    <StatCard
                        {...values[index]}
                        weight={values[index].weight}
                        {...statDetails}
                    />
                </Grid>
            ))}
        </Grid>
    );

    const statsTabs = {
        [t('activity.descriptor')]: renderStatsTab(
            activityStatsTab,
            activityStats,
        ),
        [t('users.descriptor')]: renderStatsTab(userStatsTab, userStats),
        [t('impact.descriptor')]: renderStatsTab(impactStats, impactStats, {
            className: classes.impactStatCard,
            md: true,
        }),
    };

    const onViewAllPhotosClick = () => {
        history.push(
            `/analytics/photos?${getDateQueryString(startDate, endDate)}`,
        );
    };

    const onViewAllTopActionsClick = () => {
        history.push(
            `/analytics/top-actions?${getDateQueryString(startDate, endDate)}`,
        );
    };

    const options = {
        startDateIso: startDate.toISOString(),
        endDateIso: endDate.toISOString(),
        scope: 'organization',
    };

    const checkIncludeInterval = (intervals) => {
        if (!intervals.includes(interval)) {
            setInterval(intervals[0]);
            return intervals[0];
        }

        return interval;
    };

    const getIntervalByDate = () => {
        const diffWeeks = moment(endDate).diff(moment(startDate), 'weeks');
        const diffMonth = moment(endDate).diff(moment(startDate), 'months');

        if (diffMonth > 12) {
            return ['month', 'year'];
        } else if (diffWeeks > 9) {
            return ['week', 'month'];
        } else {
            return ['day', 'week', 'month'];
        }
    };

    const fetchData = () => {
        const newIntervals = getIntervalByDate();
        setIntervals(newIntervals);
        const newInterval = checkIncludeInterval(newIntervals);

        fetchGraphData(
            { ...options, metric, resolution: newInterval },
            communityId,
        );
        fetchActivityStats(options, communityId);
        fetchUserStats(options, communityId);
        fetchImpactStats(
            { ...options, resolution: getResolution(startDate, endDate) },
            communityId,
        );
        fetchTopPhotos({
            afterTimestamp: startDate.unix(),
            beforeTimestamp: endDate.unix(),
            limit: PHOTOS_BATCH_SIZE,
            skip: 0,
            communityId,
        });
    };

    useEffect(() => {
        if (dateChange) fetchData();
        setDateChange(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateChange]);

    const intervalChangeHandler = (interval) => {
        setInterval(interval);
        trackEvent(ANALYTICS_FILTER_CHANGED);

        fetchGraphData(
            { ...options, metric, resolution: interval },
            communityId,
        );
    };

    const getGraphData = useCallback(
        (newMetric) => {
            switch (newMetric || metric) {
                case GRAPH_METRIC_ACTIVE_USERS:
                    return activeUsersGraphData;
                case GRAPH_METRIC_NEW_USERS:
                    return newUsersGraphData;
                default:
                    return totalLogsGraphData;
            }
        },
        [activeUsersGraphData, metric, newUsersGraphData, totalLogsGraphData],
    );

    const getDateQueryString = (startDate, endDate) => {
        const dates = {
            endDate: moment(endDate).format('YYYY-MM-DD'),
            startDate: moment(startDate).format('YYYY-MM-DD'),
        };
        return `${queryString.stringify(dates)}`;
    };

    const tabChangeHandler = (tab) => {
        let newMetric = GRAPH_METRIC_TOTAL_LOGS;
        setTabIndex(tab);
        if (tab === 1) {
            newMetric = GRAPH_METRIC_ACTIVE_USERS;
        } else if (tab === 2) {
            newMetric = GRAPH_METRIC_NEW_USERS;
        }
        setMetric(newMetric);
        if (getGraphData(newMetric)?.data?.length < 1) {
            fetchGraphData(
                { ...options, metric: newMetric, resolution: interval },
                communityId,
            );
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchData(), [communityId]);

    return (
        <Grid container className={classes.container} direction="column">
            <HeaderPage
                title={t('analytics')}
                rightContent={
                    <DatePickerRange
                        startDate={startDate}
                        endDate={endDate}
                        handleDateChange={({ startDate, endDate }) => {
                            history.push({
                                pathname: `/analytics`,
                                search: getDateQueryString(startDate, endDate),
                            });
                            setStartDate(startDate);
                            setEndDate(endDate);
                            setDateChange(true);
                        }}
                    />
                }
            />
            <Grid container item className={classes.relative}>
                <Graph
                    loading={isGraphLoading[metric]}
                    charts={charts}
                    data={getGraphData()}
                    intervals={intervals}
                    interval={interval}
                    onIntervalChange={intervalChangeHandler}
                    onTabChange={tabChangeHandler}
                    selectedIndex={tabIndex}
                />
            </Grid>
            <Grid container item className={classes.relative}>
                <Typography className={classes.label}>{t('stats')}</Typography>
                <TabbedSection tabs={statsTabs} />
                <RequestLoader
                    isLoading={
                        isActivityLoading || isUserLoading || isImpactLoading
                    }
                    title={`${t('loading.stats')}...`}
                />
            </Grid>
            <Grid container direction="row">
                <Grid
                    container
                    item
                    className={classes.topUsers}
                    xs={12}
                    md={6}
                >
                    <Leaderboard
                        title={t('topUsers.title')}
                        tooltip={t('topUsers.description')}
                        intercomTargetPrefix={`${INTERCOM_TARGET_PREFIX}Users`}
                        leaderboardCardProps={{
                            defaultImageSrc: getAppImages().defaultProfileIcon,
                        }}
                        leaderboardType={LEADERBOARD_TYPE_TOP_USERS}
                        items={topUsers}
                    />
                </Grid>
                <Grid
                    container
                    item
                    className={classes.topActions}
                    xs={12}
                    md={6}
                >
                    <Leaderboard
                        title={t('topActions.title')}
                        tooltip={t('topActions.description')}
                        defaultImage={IMAGES.defaultPinIcon}
                        intercomTargetPrefix={`${INTERCOM_TARGET_PREFIX}Actions`}
                        items={topActions}
                        viewAllBtn
                        onViewAllClick={onViewAllTopActionsClick}
                    />
                </Grid>
            </Grid>
            <Grid container item className={classes.relative}>
                <TopPhotos
                    photos={
                        topPhotos?.length > TOP_PHOTOS_SIZE
                            ? topPhotos.slice(0, TOP_PHOTOS_SIZE)
                            : topPhotos
                    }
                    intercomTargetPrefix={`${INTERCOM_TARGET_PREFIX}`}
                    onViewAllClick={onViewAllPhotosClick}
                    screen={ANALYTICS_SCREEN}
                />
                <RequestLoader
                    isLoading={isPhotosLoading}
                    title={`${t('loading.photos')}...`}
                />
            </Grid>
        </Grid>
    );
};

AnalyticsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    activityStats: PropTypes.array.isRequired,
    userStats: PropTypes.array.isRequired,
    impactStats: PropTypes.array.isRequired,
    totalLogsGraphData: PropTypes.object.isRequired,
    newUsersGraphData: PropTypes.object.isRequired,
    activeUsersGraphData: PropTypes.object.isRequired,
    topActions: PropTypes.array.isRequired,
    topUsers: PropTypes.array.isRequired,
    fetchActivityStats: PropTypes.func.isRequired,
    isActivityLoading: PropTypes.bool.isRequired,
    fetchUserStats: PropTypes.func.isRequired,
    isUserLoading: PropTypes.bool.isRequired,
    fetchImpactStats: PropTypes.func.isRequired,
    isImpactLoading: PropTypes.bool.isRequired,
    fetchGraphData: PropTypes.func.isRequired,
    isGraphLoading: PropTypes.object.isRequired,
    fetchTopPhotos: PropTypes.func.isRequired,
    topPhotos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            actionUrl: PropTypes.string,
            likes: PropTypes.array,
            comments: PropTypes.array,
        }),
    ),
    isPhotosLoading: PropTypes.bool.isRequired,
};

AnalyticsScreen.defaultProps = {
    topPhotos: [],
};

export const StyledAnalyticsScreen = withStyles(styles)(AnalyticsScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    activityStats: getActivityStats(state),
    userStats: getUserStats(state),
    impactStats: getImpactStats(state),
    totalLogsGraphData: getTotalLogsGraphData(state),
    newUsersGraphData: getNewUsersGraphData(state),
    activeUsersGraphData: getActiveUsersGraphData(state),
    topActions: getTopFiveActions(state),
    topUsers: getTopFiveUsers(state),
    isActivityLoading: isActivityStatsLoading(state),
    isUserLoading: isUserStatsLoading(state),
    isImpactLoading: isImpactStatsLoading(state),
    isGraphLoading: isGraphDataLoading(state),
    topPhotos: getTopPhotos(state),
    isPhotosLoading: arePhotosLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchActivityStats: (options, communityId) =>
        dispatch(activitiesRequested(options, communityId)),
    fetchUserStats: (options, communityId) =>
        dispatch(usersAnalyticRequested(options, communityId)),
    fetchImpactStats: (options, communityId) =>
        dispatch(impactStatsRequested(options, communityId)),
    fetchGraphData: (options, communityId) =>
        dispatch(graphDataRequested(options, communityId)),
    fetchTopPhotos: (request) => dispatch(communityPhotosRequested(request)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledAnalyticsScreen);
