import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Select, withStyles } from '@material-ui/core';
import TabbedSection from '../TabbedSection';
import ChartItem from '../Graph/ChartItem';
import {
    CHART_TYPES,
    CHART_INTERVALS,
    CHART_TYPE_LINE,
    CHART_INTERVAL_DAY,
} from '../Graph/ChartItem/constants';
import styles from './styles';

const Graph = ({
    classes,
    charts,
    intervals,
    interval,
    data,
    onTypeChange,
    onIntervalChange,
    onTabChange,
    loading,
    ...tabbedSectionProps
}) => {
    const { t } = useTranslation();
    const [type, setType] = useState(CHART_TYPE_LINE);

    const changeTypeHandler = (e) => {
        const { value } = e.target;
        setType(value);
        onTypeChange && onTypeChange(value);
    };

    const changeInterval = (e) => {
        const { value } = e.target;
        onIntervalChange(value);
    };

    const renderFooter = () => (
        <Grid container item justify="flex-end">
            <Select
                value={type}
                onChange={changeTypeHandler}
                variant="outlined"
                className={classes.selectWrapper}
                classes={{ root: classes.select }}
            >
                {CHART_TYPES.map((type) => (
                    <option className={classes.option} value={type} key={type}>
                        {type}
                    </option>
                ))}
            </Select>
            <Select
                value={interval}
                onChange={changeInterval}
                variant="outlined"
                className={classes.selectWrapper}
                classes={{ root: classes.select }}
            >
                {intervals.map((interval) => (
                    <option
                        className={classes.option}
                        value={interval}
                        key={interval}
                    >
                        {interval}
                    </option>
                ))}
            </Select>
        </Grid>
    );

    const renderCharts = charts.reduce(
        (prevChart, currChart) => ({
            ...prevChart,
            [currChart.name]: (
                <ChartItem
                    loading={loading}
                    type={type}
                    interval={interval}
                    showLegend={
                        currChart.options && currChart.options.isScoreTracker
                    }
                    rawData={data}
                    showAnimateButton={currChart.showAnimateButton}
                />
            ),
        }),
        {},
    );

    return (
        <Grid container item>
            <Typography className={classes.title}>
                {t('graph.descriptor')}
            </Typography>
            <Grid container item className={classes.tabs}>
                <TabbedSection
                    tabs={renderCharts}
                    withFooter={renderFooter()}
                    onTabChange={onTabChange}
                    {...tabbedSectionProps}
                />
            </Grid>
        </Grid>
    );
};

Graph.propTypes = {
    classes: PropTypes.object.isRequired,
    charts: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            options: PropTypes.shape({
                // Used to indicate whether the legend should be shown
                isScoreTracker: PropTypes.bool,
            }),
        }),
    ).isRequired,
    intervals: PropTypes.arrayOf(CHART_INTERVALS),
    interval: PropTypes.oneOf(CHART_INTERVALS),
    data: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string),
        data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        series: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            }),
        ),
    }).isRequired,
    onTypeChange: PropTypes.func,
    onIntervalChange: PropTypes.func.isRequired,
    onTabChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

Graph.defaultProps = {
    loading: false,
    intervals: CHART_INTERVALS,
    interval: CHART_INTERVAL_DAY,
    onTypeChange: null,
};

export default withStyles(styles)(Graph);
