import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        ...Object.keys(theme.shineAdmin.textarea)
            .filter((key) => key !== 'padding')
            .reduce((obj, key) => {
                obj[key] = theme.shineAdmin.textarea[key];
                return obj;
            }, {}),
        marginBottom: '15px',
        padding: '10px 16px',

        '&:before, &:after': {
            display: 'none',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:active:not(.Mui-disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&.Mui-focused': {
            borderColor: theme.palette.primary.dark,
        },
    },
    input: {
        height: '24px',
        padding: 0,
    },
}));

export default useStyles;
