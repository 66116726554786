import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import useStyles from './styles';

import Button from '../Button';

const defaultIconStyle = {
    border: '2px solid #fff',
    borderRadius: '6px 6px 50% 50%',
};

const ChallengeIcon = ({ iconUrl, iconStyle, canEdit, onChangeIcon }) => {
    const classes = useStyles({ iconUrl });

    return (
        <Grid item className={classes.icon} style={iconStyle}>
            {!!canEdit && (
                <Button className={classes.btnEditIcon} onClick={onChangeIcon}>
                    <Edit fontSize="small" />
                </Button>
            )}
        </Grid>
    );
};

ChallengeIcon.propTypes = {
    iconUrl: PropTypes.string.isRequired,
    iconStyle: PropTypes.object,
    canEdit: PropTypes.bool.isRequired,
    onChangeIcon: PropTypes.func.isRequired,
};

ChallengeIcon.defaultProps = {
    iconStyle: defaultIconStyle,
};

export default ChallengeIcon;
