const styles = (theme) => ({
    challengeTitle: {
        fontSize: '24px',
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.palette.white.main,
    },

    peopleIcon: {
        width: '45px',
        height: '45px',
        '& svg': {
            fill: theme.palette.white.main,
            fontSize: '30px',
        },
    },
    challengeText: {
        color: theme.palette.white.main,
    },
});

export default styles;
