import { Link } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../../constants/dates';

export const DATE_COLUMN = 'date';
export const DESCRIPTION_COLUMN = 'description';
export const AMOUNT_COLUMN = 'amount';
export const DETAIL_VIEW_COLUMN = 'detailView';
export const DOWNLOAD_COLUMN = 'download';

const billingHistoryColumns = ({ t }, classes) => [
    {
        header: t('account.paymentRecords.dateColumnTitle'),
        accessor: DATE_COLUMN,
        Cell: (props) =>
            moment(props.value).format(DATETIME_FORMAT_FOR_DATETIME_PICKERS),
        sortable: false,
    },
    {
        header: t('account.paymentRecords.descriptionColumnTitle'),
        accessor: DESCRIPTION_COLUMN,
        sortable: false,
    },
    {
        header: t('account.paymentRecords.AmountColumnTitle'),
        accessor: AMOUNT_COLUMN,
        sortType: false,
        hideSortIcon: true,
        sortable: false,
    },
    {
        accessor: DETAIL_VIEW_COLUMN,
        sortable: false,
        Cell: ({ value }) => (
            <Link
                href={value?.link}
                color="inherit"
                underline="none"
                target="_blank"
                className={classes.link}
            >
                {value?.text}
            </Link>
        ),
    },
    {
        accessor: DOWNLOAD_COLUMN,
        sortable: false,
        Cell: ({ value }) => (
            <Link
                href={value?.link}
                color="inherit"
                underline="none"
                className={classes.link}
            >
                {value?.text}
            </Link>
        ),
    },
];

export default billingHistoryColumns;
