import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';

import styles from './styles';
import IMAGES from '../../../constants/images';
import { noop } from '../../../utils';

const NotificationCard = ({
    tipTitle,
    tipDescription,
    image,
    borderColor,
    logoColor,
    isOpen,
    onClose,
    children,
    isDismissible,
    ...props
}) => {
    const classes = styles({ borderColor, logoColor, isDismissible });

    if (!isOpen) {
        return null;
    }
    return (
        <Grid container>
            {isDismissible && (
                <div className={classes.closeButtonWrapper}>
                    <img
                        className={classes.closeButton}
                        src={IMAGES.closeIcon}
                        alt=""
                        onClick={onClose}
                    />
                </div>
            )}
            <Grid
                container
                direction="row"
                className={classNames(classes.container)}
                data-test="NotificationCard"
            >
                <Grid container item xs={1}>
                    <div className={classes.logoSection}>
                        <img src={image} className={classes.logo} alt="" />
                    </div>
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    md={8}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <div className={classes.tipContent}>
                        <div className={classes.tipTitleSection}>
                            <Typography
                                variant="h6"
                                className={classes.tipTitle}
                            >
                                {tipTitle}
                            </Typography>
                        </div>
                        <div className={classes.tipContent}>
                            <Typography
                                variant="body1"
                                className={classes.tipContent}
                            >
                                {tipDescription}
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid container item xs={4} md={3}>
                    <div className={classes.actionWrapper}>{children}</div>
                </Grid>
            </Grid>
        </Grid>
    );
};

NotificationCard.propTypes = {
    tipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    tipDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    children: PropTypes.node,
    image: PropTypes.string.isRequired,
    logoColor: PropTypes.oneOf(['red', '']),
    borderColor: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    isDismissible: PropTypes.bool,
};

NotificationCard.defaultProps = {
    tipTitle: '',
    tipDescription: '',
    children: [],
    image: '',
    logoColor: '',
    borderColor: null,
    isOpen: true,
    onClose: noop,
    isDismissible: true,
};

export default NotificationCard;
