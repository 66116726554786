import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, Collapse, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import useStyles from './styles';
import ModalTitle from '../../../common/ModalTitle';
import CheckboxIcon from './CheckboxIcon';

import ConfirmModal from '../../../common/ConfirmModal';
import { noop } from '../../../common/utils';
import {
    BUTTON_ACCENT_COLOR,
    BUTTON_DEFAULT_COLOR,
} from '../../../common/Button/constants';
import FormInput from '../../../common/Form/FormInput';
import IMAGES from '../../../constants/images';
import { StyledRequestLoader as RequestLoader } from '../../../common/RequestLoader';
import CommonPropTypes from '../../../common/propTypes';
import ErrorList from '../../../common/errors/ErrorList';
import {
    clearActionLinkMetadata,
    urlMetadataRequested,
} from '../../../actions/actions';
import {
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../../image/selectors';
import {
    getConfirmLinkStatus,
    getMetadata,
    getMetadataErrors,
    isMetadataLoading,
} from '../../../actions/selectors';
import ConditionalWrapper, { ARef } from './ConditionalWrapper';
import ImageCaptionForm from '../../../common/ImageCaptionForm';
import { isEmpty } from 'ramda';
import { clearUploadedImage } from '../../../image/actions';
import { INTERCOM_TARGET_PREFIX } from '../constants';

const AddLink = ({
    isOpen,
    requestMetadataForUrl,
    isMetadataLoading,
    metadataErrors,
    metadata,
    isImageUploading,
    imageUploadErrors,
    onClose,
    onCancel,
    onConfirm,
    clearMetadata,
    editMode,
    sponsor,
    confirmed,
    clearCustomImage,
}) => {
    const classes = useStyles();
    const checkboxClasses = useMemo(
        () => ({
            root: classes.checkboxRoot,
            colorSecondary: classes.checkboxSecondaryColor,
        }),
        [classes],
    );

    const { t } = useTranslation();

    const [customizationOpen, setCustomizationOpen] = useState(false);
    const [sponsorLogoAdded, setSponsorLogoAdded] = useState(
        !!metadata?.sponsorId,
    );
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [metaImageUrl, setMetaImageUrl] = useState(null);
    const [metaImageSizes, setMetaImageSizes] = useState(null);
    const [customImageSizes, setCustomImageSizes] = useState(null);

    const [key, setKey] = useState(0);

    const clearState = useCallback(() => {
        setTitle('');
        setDescription('');
        setMetaImageUrl(null);
        setMetaImageSizes(null);
        setCustomImageSizes(null);
        setInitialValues({
            url: metadata?.url || '',
            title,
        });
        setCustomizationOpen(false);
        setKey(key + 1);
        setConfirmedUrl('');
        setCustomized(false);
        clearCustomImage();
    }, [key, metadata, title, clearCustomImage]);

    const sizes = useMemo(
        () => (customizationOpen ? customImageSizes : metaImageSizes),
        [customizationOpen, customImageSizes, metaImageSizes],
    );

    const previewImageUrl = useMemo(() => {
        let imageUrl = metaImageUrl;
        if (customizationOpen) {
            imageUrl =
                customImageSizes?.[0]?.url ||
                customImageSizes?.[0]?.file?.url ||
                metaImageUrl;
        }
        return imageUrl;
    }, [customizationOpen, metaImageUrl, customImageSizes]);

    const [urlPristine, setUrlPristine] = useState(true);
    const handleUrlChange = useCallback(
        (event, formik) => {
            formik.handleChange(event);
            setUrlPristine(false);
        },
        [setUrlPristine],
    );

    const [customized, setCustomized] = useState(false);
    const handleUrlBlur = useCallback(
        (event, formik) => {
            formik.handleBlur(event);
            if (!urlPristine && formik.isValid) {
                setUrlPristine(true);
                requestMetadataForUrl({
                    url: formik.values.url,
                    saveImage: true,
                });
            }
        },
        [urlPristine, setUrlPristine, requestMetadataForUrl],
    );

    const handleCustomizationChange = useCallback(
        (event) => setCustomizationOpen(event.target.checked),
        [setCustomizationOpen],
    );

    const handleTitleChange = useCallback(
        (event, setFieldValue) => {
            setTitle(event.target.value);
            setFieldValue(event.target.value);
        },
        [setTitle],
    );

    const handleDescriptionChange = useCallback(
        (event, setFieldValue) => {
            setDescription(event.target.value);
            setFieldValue(event.target.value);
        },
        [setDescription],
    );

    const handleClose = useCallback(
        (formik) => {
            const { resetForm } = formik;
            onClose();
            clearMetadata && clearMetadata();
            clearState();
            resetForm();
        },
        [onClose, clearState, clearMetadata],
    );

    const handleCancel = useCallback(
        (formik) => {
            const { resetForm } = formik;
            onCancel();
            clearMetadata && clearMetadata();
            clearState();
            resetForm();
        },
        [onCancel, clearMetadata, clearState],
    );

    const [confirmedUrl, setConfirmedUrl] = useState('');

    const handleConfirm = useCallback(
        (formik) => {
            const { values: { url = '' } = {}, resetForm } = formik;
            formik
                .validateForm({
                    ...formik.values,
                    url,
                })
                .then((errors) => {
                    if (isEmpty(errors)) {
                        setConfirmedUrl(url);
                        customizationOpen
                            ? setCustomized(true)
                            : requestMetadataForUrl({
                                  url,
                                  saveImage: true,
                                  confirm: true,
                              });
                        resetForm();
                    }
                });
        },
        [requestMetadataForUrl, customizationOpen],
    );

    useEffect(() => {
        if (confirmed || customized) {
            onConfirm({
                ...metadata,
                url: confirmedUrl,
                sponsorId: sponsorLogoAdded && sponsor?.id,
                sizes,
                title: title || metadata.title,
                description: description || metadata.description,
                imageUrl:
                    previewImageUrl || metadata.imageUrl || IMAGES.defaultImage,
                custom: customizationOpen,
            });
            clearMetadata && clearMetadata();
            clearState();
        }
    }, [
        onConfirm,
        sponsor,
        sponsorLogoAdded,
        clearState,
        clearMetadata,
        metadata,
        confirmed,
        confirmedUrl,
        sizes,
        title,
        description,
        previewImageUrl,
        customized,
        customizationOpen,
    ]);

    useEffect(() => {
        !editMode && clearMetadata && clearMetadata();
    }, [clearMetadata, editMode]);

    useEffect(() => {
        if (!isMetadataLoading && metadataErrors == null && metadata != null) {
            setTitle(metadata.title);
            setDescription(metadata.description);
            // TODO imageUrl may be relative
            setMetaImageUrl(metadata.imageUrl);
            setMetaImageSizes(metadata.image?.sizes);
            setCustomizationOpen(!metadata.updateMetadataAutomatically);
            if (metadata.key) {
                setKey(metadata.key);
            }
        }
    }, [
        isMetadataLoading,
        metadataErrors,
        metadata,
        setTitle,
        setDescription,
        setMetaImageUrl,
        setMetaImageSizes,
    ]);

    const [initialValues, setInitialValues] = useState({
        url: metadata?.url || '',
        title,
    });

    const errorUrlRequired = useMemo(() => t('validation.urlRequired'), [t]);
    const errorNotAUrl = useMemo(
        () => `${t('validation.urlInvalid')}https://somesite.com.`,
        [t],
    );
    const errorUrlHttps = useMemo(() => t('validation.urlHttps'), [t]);

    const initialErrors = useMemo(
        () => (metadata?.url ? {} : { url: errorUrlRequired }),
        [errorUrlRequired, metadata],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                url: Yup.string()
                    .required(errorUrlRequired)
                    .url(errorNotAUrl)
                    .matches(/^https:\/\//, errorUrlHttps),
            }),
        [errorUrlRequired, errorNotAUrl, errorUrlHttps],
    );

    return (
        <Formik
            initialValues={initialValues}
            initialErrors={initialErrors}
            validationSchema={validationSchema}
            onSubmit={noop}
            key={key}
        >
            {(formikProps) => {
                return (
                    <ConfirmModal
                        isOpen={isOpen}
                        title={
                            <ModalTitle
                                text={t('addActionLink')}
                                tooltipText={t('viewDocs')}
                                tooltipPlacement="right"
                            />
                        }
                        confirmBtnText={t('OK')}
                        confirmBtnType={BUTTON_ACCENT_COLOR}
                        confirmBtnDisabled={!formikProps.isValid}
                        cancelBtnText={t('cancel')}
                        cancelBtnType={BUTTON_DEFAULT_COLOR}
                        onClose={() => handleClose(formikProps)}
                        onCancel={() => handleCancel(formikProps)}
                        onConfirm={() => handleConfirm(formikProps)}
                    >
                        <Form className={classes.form}>
                            <Grid container direction="column">
                                <Grid item>
                                    <FormInput
                                        label={t('url')}
                                        tooltipText={t('linkURL')}
                                        tooltipIntercomTarget={`${INTERCOM_TARGET_PREFIX}EditLinkURLLabel`}
                                        noLeftMargin
                                        name="url"
                                        onChange={(event) =>
                                            handleUrlChange(event, formikProps)
                                        }
                                        onBlur={(event) =>
                                            handleUrlBlur(event, formikProps)
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        className={classes.customize}
                                    >
                                        <span
                                            className={classes.customizeLabel}
                                        >
                                            {t('links.customizeURLPreview')}
                                        </span>
                                        <Checkbox
                                            checked={customizationOpen}
                                            icon={<CheckboxIcon />}
                                            classes={checkboxClasses}
                                            onChange={handleCustomizationChange}
                                        />
                                    </Typography>
                                </Grid>
                                {sponsor?.imageUrl && (
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            className={classes.customize}
                                        >
                                            <span
                                                className={classes.sponsorLabel}
                                            >
                                                {t('links.addLogo', {
                                                    sponsorName: sponsor?.name,
                                                })}
                                            </span>
                                            <Checkbox
                                                checked={sponsorLogoAdded}
                                                icon={<CheckboxIcon />}
                                                classes={checkboxClasses}
                                                onChange={() =>
                                                    setSponsorLogoAdded(
                                                        !sponsorLogoAdded,
                                                    )
                                                }
                                            />
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Collapse in={customizationOpen}>
                                        <ImageCaptionForm
                                            imageLabel={t('image')}
                                            imageSizes={customImageSizes}
                                            imageTooltipText={t(
                                                'links.imageTooltip',
                                            )}
                                            titleLabel={t('links.title')}
                                            title={title}
                                            titleTooltipText={t(
                                                'links.titleTooltip',
                                            )}
                                            descriptionLabel={t(
                                                'links.description',
                                            )}
                                            descriptionTooltipText={t(
                                                'links.descriptionTooltip',
                                            )}
                                            description={description}
                                            handleTitleChange={(e) =>
                                                handleTitleChange(
                                                    e,
                                                    formikProps.setFieldValue,
                                                )
                                            }
                                            handleDescriptionChange={(e) =>
                                                handleDescriptionChange(
                                                    e,
                                                    formikProps.setFieldValue,
                                                )
                                            }
                                            handleImageChange={(sizes) =>
                                                setCustomImageSizes(sizes)
                                            }
                                        />
                                    </Collapse>
                                </Grid>

                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        className={classes.previewLabel}
                                    >
                                        {t('preview')}
                                    </Typography>
                                </Grid>
                                <ConditionalWrapper
                                    wrap={formikProps.isValid}
                                    Parent={ARef}
                                    ParentProps={{
                                        href: formikProps.values.url,
                                        className: classes.link,
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        wrap="nowrap"
                                        className={classes.preview}
                                    >
                                        <RequestLoader
                                            isLoading={
                                                isMetadataLoading ||
                                                isImageUploading
                                            }
                                            title={t('loading.descriptor')}
                                        />
                                        <Grid
                                            container
                                            item
                                            className={
                                                classes.previewImageWrapper
                                            }
                                        >
                                            <img
                                                src={
                                                    previewImageUrl ||
                                                    IMAGES.defaultImage
                                                }
                                                width={100}
                                                height={100}
                                                className={classes.previewImage}
                                                alt="link"
                                            />
                                            {sponsorLogoAdded && (
                                                <img
                                                    src={sponsor?.imageUrl}
                                                    width={30}
                                                    height={30}
                                                    className={
                                                        classes.sponsorLogo
                                                    }
                                                    alt="logo"
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            container
                                            direction="column"
                                            className={classes.previewSummary}
                                        >
                                            <Grid item>
                                                <Typography
                                                    variant="h5"
                                                    className={
                                                        classes.previewSummaryTitle
                                                    }
                                                >
                                                    {title}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                className={
                                                    classes.previewSummaryDescription
                                                }
                                            >
                                                {description}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ConditionalWrapper>
                                {metadataErrors != null ? (
                                    <ErrorList errors={metadataErrors} />
                                ) : null}
                                {imageUploadErrors != null ? (
                                    <ErrorList errors={imageUploadErrors} />
                                ) : null}
                            </Grid>
                        </Form>
                    </ConfirmModal>
                );
            }}
        </Formik>
    );
};

AddLink.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    requestMetadataForUrl: PropTypes.func.isRequired,
    isMetadataLoading: PropTypes.bool.isRequired,
    metadataErrors: CommonPropTypes.errors,
    metadata: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        image: PropTypes.shape({
            sizes: PropTypes.array,
        }),
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sponsorId: PropTypes.string,
        updateMetadataAutomatically: PropTypes.bool,
    }),
    isImageUploading: PropTypes.bool.isRequired,
    imageUploadErrors: CommonPropTypes.errors,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    clearMetadata: PropTypes.func,
    editMode: PropTypes.bool,
    sponsor: PropTypes.shape({
        name: PropTypes.string,
        imageUrl: PropTypes.string,
        id: PropTypes.string,
    }),
    confirmed: PropTypes.bool,
    clearCustomImage: PropTypes.func.isRequired,
};

AddLink.defaultProps = {
    onClose: noop,
    onCancel: noop,
    metadataErrors: null,
    metadata: null,
    imageUploadErrors: null,
    imageUploadResponse: null,
    clearMetadata: noop,
    editMode: false,
    sponsor: null,
    confirmed: false,
};

export { AddLink as DumbAddLink };

const mapStateToProps = (state) => ({
    isMetadataLoading: isMetadataLoading(state),
    metadataErrors: getMetadataErrors(state),
    metadata: getMetadata(state),
    isImageUploading: isImageUploadRequestPending(state),
    imageUploadErrors: getImageUploadErrors(state),
    imageUploadResponse: getImageUploadResponse(state),
    confirmed: getConfirmLinkStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestMetadataForUrl: (request) => dispatch(urlMetadataRequested(request)),
    clearMetadata: () => dispatch(clearActionLinkMetadata()),
    clearCustomImage: () => dispatch(clearUploadedImage()),
});

const ConnectedAddLink = connect(mapStateToProps, mapDispatchToProps)(AddLink);

export default ConnectedAddLink;
