import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles } from '@material-ui/core';
import { Add, Send } from '@material-ui/icons';
import Form from '../Form';
import Button from '../Button';
import styles from './styles';
import * as Yup from 'yup';
import { FieldArray } from 'formik';
import EmailField from './EmailField';
import SnackBarAPIResponse from '../SnackbarAlert/SnackBarAPIResponse';
import SnackbarAlert from '../SnackbarAlert';

const INITIAL_EXTRA_EMAIL_FIELDS_NUMBER = 2;

const InviteUsers = ({
    classes,
    onSubmit,
    errors,
    clearSnackBar,
    loading,
    messages,
    ...props
}) => {
    const { t } = useTranslation();

    const [snackBarMessage, setSnackBarMessage] = useState();

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                requiredEmail: Yup.string()
                    .email(t('validation.emailInvalid'))
                    .required(t('validation.emailRequired')),
                extraEmails: Yup.array().of(
                    Yup.string().email(t('validation.emailInvalid')),
                ),
            }),
        [t],
    );

    const initialValues = useMemo(
        () => ({
            requiredEmail: '',
            extraEmails: Array(INITIAL_EXTRA_EMAIL_FIELDS_NUMBER).fill(''),
        }),
        [],
    );

    const submitHandler = useCallback(
        ({ requiredEmail, extraEmails }, { setSubmitting, resetForm }) => {
            const nonEmptyEmails = extraEmails.filter((email) => email !== '');
            requiredEmail !== '' && nonEmptyEmails.push(requiredEmail);
            onSubmit(nonEmptyEmails, setSubmitting, resetForm);
        },
        [onSubmit],
    );

    const buttonText = (
        <>
            <Send style={{ paddingRight: '3px' }} />
            {t('users.sendInvitations')}
        </>
    );

    return (
        <Grid className={classes.container} {...props}>
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                submitBtnText={buttonText}
                loading={loading}
                onSubmit={submitHandler}
            >
                {() => (
                    <Grid>
                        <Grid
                            className={classes.emailFields}
                            container
                            item
                            direction="column"
                        >
                            <EmailField name="requiredEmail" />
                        </Grid>
                        <FieldArray name="extraEmails">
                            {({ push, remove, form }) => (
                                <Grid container item direction="column">
                                    {form.values.extraEmails.map(
                                        (email, index) => (
                                            <Grid
                                                key={index}
                                                container
                                                item
                                                className={classes.emailFields}
                                            >
                                                <EmailField
                                                    name={`extraEmails.${index}`}
                                                    removable={index !== -1}
                                                    onRemove={() =>
                                                        remove(index)
                                                    }
                                                />
                                            </Grid>
                                        ),
                                    )}
                                    <Grid
                                        container
                                        item
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid
                                            item
                                            xs={4}
                                            justifyContent="center"
                                            className={
                                                classes.addButtonContainer
                                            }
                                        >
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.addButton}
                                                onClick={() => push('')}
                                            >
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    direction="row"
                                                    className={
                                                        classes.buttonText
                                                    }
                                                >
                                                    <Add />
                                                    {t('users.addMoreButton')}
                                                </Grid>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </FieldArray>
                    </Grid>
                )}
            </Form>
            <SnackBarAPIResponse
                errors={errors}
                messages={messages}
                clearResponse={clearSnackBar}
            />
            <Grid className={classes.snackBarWrapper}>
                {snackBarMessage && (
                    <SnackbarAlert
                        severity="success"
                        messages={snackBarMessage}
                        clearMessages={() => {
                            setSnackBarMessage(null);
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

InviteUsers.propTypes = {
    classes: PropTypes.object.isRequired,

    // Must accept two arguments, the list of emails submitted and the Formik function to set isSubmitting
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    loading: PropTypes.bool.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearSnackBar: PropTypes.func.isRequired,
};

export default withStyles(styles)(InviteUsers);
