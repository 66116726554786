import {
    SAVE_DRAFT_REQUESTED,
    SAVE_DRAFT_FAILED,
    SAVE_DRAFT_SUCCEEDED,
    CLEAR_SAVE_ERRORS,
} from './actions';

export const initialState = {
    saveInProgress: false,
    saveSuccessful: false,
    publish: false,
    errors: [],
};

export default (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case SAVE_DRAFT_REQUESTED: {
            return {
                ...state,
                saveInProgress: true,
                saveSuccessful: false,
                errors: [],
            };
        }
        case SAVE_DRAFT_SUCCEEDED: {
            return {
                ...state,
                publish: payload.publish,
                saveInProgress: false,
                saveSuccessful: true,
            };
        }
        case SAVE_DRAFT_FAILED: {
            return {
                ...state,
                ...payload,
                saveInProgress: false,
                saveSuccessful: false,
            };
        }
        case CLEAR_SAVE_ERRORS: {
            return {
                ...state,
                errors: [],
                saveSuccessful: false,
            };
        }
        default: {
            return state;
        }
    }
};
