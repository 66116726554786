import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from './styles';
import IMAGES from '../../constants/images';

const ProTips = ({ tip, classes }) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            direction="row"
            className={classes.container}
            data-test="proTipsComponent"
        >
            <Grid container item xs={2}>
                <div className={classes.logoSection}>
                    <img
                        src={IMAGES.greenLogo}
                        className={classes.logo}
                        alt=""
                    />
                </div>
            </Grid>

            <Grid
                container
                item
                xs={12}
                md={10}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <div className={classes.tipContent}>
                    <div className={classes.tipTitleSection}>
                        <Typography
                            variant="body1"
                            className={classes.tipTitle}
                        >
                            {t('proTips.title')}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body1" data-test="tipText">
                            <i>{tip}</i>
                        </Typography>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

ProTips.propTypes = {
    tip: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};

PropTypes.defaultProps = {
    tip: '',
};

export default withStyles(styles)(ProTips);
