import IMAGES from '../../../constants/images';

const styles = (theme) => ({
    container: {
        minHeight: '605px',
        width: '320px',
        margin: '0 auto',
        background: `url(${IMAGES.notificationWrapper}) no-repeat center center`,
        backgroundSize: 'cover',
    },
    screen: {
        width: '100%',
        margin: '80px 35px',
        background: `url(${IMAGES.iosNotifications}) no-repeat center center`,
        backgroundSize: 'cover',
    },
    notification: {
        margin: '140px 10px 0',
        borderRadius: '7px',
        backgroundColor: '#c7d6ed',
        overflow: 'hidden',
    },
    notificationTitle: {
        display: 'flex',
        alignItems: 'center',
        padding: '1% 2% 1% 2%',
        fontSize: '9px',
        lineHeight: 2,
        backgroundColor: '#d4deef',
    },
    notificationIcon: {
        maxWidth: '20px',
        maxHeight: '20px',
        width: '6%',
        marginRight: '5px',
    },
    notificationText: {
        wordWrap: 'break-word',
        padding: '3% 4% 3% 4%',
        fontSize: '10px',
    },
});

export default styles;
