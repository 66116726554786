import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    warningBannerTitle: {
        color: theme.palette.error.main,
        fontSize: 24,
        fontWeight: '700',
        textAlign: 'center',
    },
    warningBannerIcon: {
        color: theme.palette.error.main,
        fontSize: 30,
        paddingRight: 5,
    },
    warningBannerBody: {
        paddingTop: 10,
        flex: 1,
        textAlign: 'center',
    },
    communityLink: {
        color: theme.palette.error.main,
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: '700',
        display: 'inline',
    },
}));
