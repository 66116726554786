import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: ({ photoUrl }) => ({
        width: '100%',
        minHeight: 300,
        borderRadius: 0,
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${photoUrl})`,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '20px',
    }),
    headerRoot: {
        padding: '5px',
        width: '100%',
    },
    headerContent: {
        flex: 0,
        whiteSpace: 'nowrap',
    },
    headerAction: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        margin: 0,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        color: theme.palette.common.white,
        '&:last-child': {
            padding: '0 16px 8px',
        },
    },
    backIcon: {
        padding: 0,
        color: theme.palette.common.white,
    },
    icon: ({ iconUrl }) => ({
        minWidth: '110px',
        width: '110px',
        height: '110px',
        backgroundImage: `url(${iconUrl})`,
        backgroundSize: 'cover',
        position: 'relative',
        marginRight: '20px',
        [theme.breakpoints.down('xs')]: {
            marginRight: '5px',
            width: '60px',
            height: '60px',
            minWidth: '60px',
        },
    }),
    btnEditIcon: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        padding: '4px',
        borderRadius: '50%',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    btnReset: {
        marginLeft: 3,
    },
    rightBody: {
        paddingLeft: '5px',
    },
    name: {
        marginTop: '25px',
        maxHeight: '214px',
        fontSize: 'min(10vw, 38px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&& svg': {
            fontSize: '65%',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            padding: '5px',
            verticalAlign: 'middle',
            marginLeft: '10px',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
        },
    },
    editName: {
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.25)',
            borderRadius: '6px',
            cursor: 'pointer',
        },
    },
    status: {
        marginBottom: '10px',
        fontSize: '18px',
    },
    editCover: {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        border: '2px',
        backgroundColor: theme.palette.white.main,
        borderRadius: '10px',
        '&:hover': {
            color: theme.palette.white.main,
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.dark,
        },
    },
    cardContent: {
        maxWidth: theme.custom.mainContentWidth,
    },
}));
