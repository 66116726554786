import { transformAction } from '../actions/transforms';

export const transformTheme = (theme) => ({
    id: theme?.id,
    name: theme?.name,
    iconUrl: theme?.imageSizes[0]?.url,
    status: theme?.status,
    description: theme?.description,
    challengeName: theme?.challengeName,
    challengeDescription: theme?.challengeDescription,
    challengeIconUrl: theme?.challengeIcon?.[0]?.url,
    whiteIcon: theme?.whiteIcon?.[0]?.url,
    explainerText: theme?.explainerText,
    sampleActions: theme?.samplePins?.map((pin) => transformAction(pin)),
    photoUrl: theme?.headlinePhoto?.photoSizes?.[0]?.url,
    categories: theme?.categories?.map((c) => transformCategory(c)),
    largePhotoUrl:
        theme?.headlinePhoto?.photoSizes?.[
            theme.headlinePhoto.photoSizes.length - 1
        ]?.url,
});

const transformCategory = (category) => ({
    id: category?.id,
    name: category?.name,
    iconUrl: category?.imageSizes?.[0]?.url,
});
