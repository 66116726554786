import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../common/errors';
import {
    GROUP_TYPE_DELIVERED,
    GROUP_TYPE_QUEUED,
} from '../screens/NotificationsScreen/AdminNotificationsScreen/constants';
import {
    notificationResponseToTableCellData,
    transformActions,
    transformAdmins,
    transformChallenges,
    transformCreateNotification,
    transformUserNotifications,
    transformUsers,
} from './transforms';
import {
    GROUP_ACTIVE,
    GROUP_PAST,
    GROUP_UPCOMING,
} from '../screens/ChallengesScreen/constants';
import { getChallengesState } from '../screens/ChallengesScreen/selectors';
import { getActiveMembers } from '../user/selectors';
import { getActions } from '../actions/selectors';

export const getNotificationsState = (state) => state.notifications;

export const getGroupTableData = createSelector(
    getNotificationsState,
    (notifications) => ({
        [GROUP_TYPE_QUEUED]: notificationResponseToTableCellData(
            notifications[GROUP_TYPE_QUEUED].data,
        ),
        [GROUP_TYPE_DELIVERED]: notificationResponseToTableCellData(
            notifications[GROUP_TYPE_DELIVERED].data,
        ),
    }),
);

export const getGroupLoader = createSelector(
    getNotificationsState,
    (notifications) => ({
        [GROUP_TYPE_QUEUED]: notifications[GROUP_TYPE_QUEUED].isLoading,
        [GROUP_TYPE_DELIVERED]: notifications[GROUP_TYPE_DELIVERED].isLoading,
    }),
);

export const getGroupTotalCount = createSelector(
    getNotificationsState,
    (notifications) => ({
        [GROUP_TYPE_QUEUED]: notifications[GROUP_TYPE_QUEUED].totalCount,
        [GROUP_TYPE_DELIVERED]: notifications[GROUP_TYPE_DELIVERED].totalCount,
    }),
);

export const getQueuedErrors = createSelector(
    getNotificationsState,
    (notifications) =>
        coerceIntoErrors(notifications[GROUP_TYPE_QUEUED].errors),
);

export const getDeliveredErrors = createSelector(
    getNotificationsState,
    (notifications) =>
        coerceIntoErrors(notifications[GROUP_TYPE_DELIVERED].errors),
);

export const getNotificationData = createSelector(
    getNotificationsState,
    (notifications) => notifications?.notification?.data || [],
);

export const getNotificationLoader = createSelector(
    getNotificationsState,
    (notifications) => notifications.notification?.isLoading,
);

export const getNotificationErrors = createSelector(
    getNotificationsState,
    (notifications) => coerceIntoErrors(notifications.notification?.errors),
);

export const getNotificationUpdateMessage = createSelector(
    getNotificationsState,
    (notifications) => notifications.notification?.message,
);

export const getNotificationActions = createSelector(getActions, (actions) =>
    transformActions(actions?.data),
);

export const getCommunityUsers = createSelector(
    getActiveMembers,
    (activeMembers) => transformUsers(activeMembers),
);

export const getCommunityAdmins = createSelector(
    getActiveMembers,
    (activeMembers) => transformAdmins(activeMembers),
);

export const getChallenges = createSelector(getChallengesState, (groupState) =>
    [GROUP_ACTIVE, GROUP_UPCOMING, GROUP_PAST].reduce((res, group) => {
        if (groupState[group].challenges) {
            return [
                ...res,
                ...transformChallenges(
                    group,
                    groupState[group]?.challenges || [],
                ),
            ];
        }
        return res;
    }, []),
);

export const getUpdateErrors = createSelector(
    getNotificationsState,
    (notifications) => coerceIntoErrors(notifications.notification?.errors),
);

export const getUpdateErrorStatus = createSelector(
    getNotificationsState,
    (notifications) => notifications.notification?.errorStatus,
);

export const getCreate = createSelector(
    getNotificationsState,
    (notifications) =>
        transformCreateNotification(notifications.create.notification),
);

export const areUserNotificationsLoading = createSelector(
    getNotificationsState,
    (notifications) => notifications.userNotifications.loading,
);

export const getUserNotifications = createSelector(
    getNotificationsState,
    (notifications) =>
        transformUserNotifications(
            notifications.userNotifications?.notifications || [],
        ),
);

export const getUserNotificationCounts = createSelector(
    getNotificationsState,
    (notifications) => notifications.userNotifications?.counts,
);
