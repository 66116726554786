import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import clsx from 'clsx';
import { Grid, withStyles } from '@material-ui/core';
import IMAGES from '../../../constants/images';
import styles from './styles';
import { getAppImages } from '../../../utils';

const InAppNotificationPreview = ({
    classes,
    iconUrl,
    message,
    subjectContentType,
    subject,
    sendDate,
    isChallenge,
}) => {
    const { t } = useTranslation();

    const showTitle = () => {
        if (subjectContentType === 'General') {
            return subject || t('appName');
        } else if (subjectContentType === 'Challenge') {
            return subject || t('notifications.subjectChallengeTitle');
        }
    };

    return (
        <Grid container item className={classes.container}>
            <div className={classes.screen}>
                <div className={classes.notification}>
                    <img
                        alt={t('alt.communityPhoto')}
                        src={iconUrl || getAppImages().defaultCommunityIcon}
                        className={clsx([
                            classes.notificationIcon,
                            iconUrl && isChallenge && classes.iconBorder,
                        ])}
                    />
                    <div className={classes.notificationContent}>
                        <div>
                            <div className={classes.notificationTitle}>
                                {showTitle()}
                            </div>
                            <div className={classes.notificationText}>
                                {message ||
                                    t('notifications.messageFieldToolTip')}
                            </div>
                        </div>
                        <div className={classes.notificationTime}>
                            <img
                                alt=""
                                src={IMAGES.notificationBuzzIcon}
                                className={classes.notificationTimeIcon}
                            />
                            {sendDate
                                ? moment(sendDate).format('h:mm A')
                                : 'now'}
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
};

InAppNotificationPreview.propTypes = {
    classes: PropTypes.object.isRequired,
    iconUrl: PropTypes.string,
    message: PropTypes.string,
    subjectContentType: PropTypes.string,
    subject: PropTypes.string,
    sendDate: PropTypes.string,
    isChallenge: PropTypes.bool,
};

InAppNotificationPreview.defaultProps = {
    iconUrl: null,
    message: null,
    subjectContentType: 'General',
    subject: null,
    sendDate: null,
    isChallenge: false,
};

export default withStyles(styles)(InAppNotificationPreview);
