import IMAGES from '../../constants/images';
import { makeStyles } from '@material-ui/core';
const styles = (theme) => ({
    container: {
        overflowY: 'auto',
        height: 'calc(100% - 72px)',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    overlay: {
        opacity: 0.2,
    },
    loginScreen: ({ leftBackgroundImage, rightBackgroundImage }) => ({
        height: '100vh',
        width: '100%',
        position: 'relative',
        zIndex: '2',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '23.5%',
            height: '100%',
            bottom: '69px',
            left: '0',
            backgroundImage: `url(${rightBackgroundImage || IMAGES.loginBg1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'left,bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '19.5%',
            height: '100%',
            bottom: '39px',
            right: '0',
            backgroundImage: `url(${leftBackgroundImage || IMAGES.loginBg2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'right, bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 160px)',
            background: 'none',
            '@media (orientation: landscape)': {
                height: 'auto',
            },
        },
    }),
    wrapper: {
        padding: '0 5px',
        marginTop: '50px',
        marginBottom: '30px',
    },
    createAccountText: {
        marginBottom: '10px',
        marginRight: '5px',
    },
    link: {
        color: '#1D8DC7',
        marginTop: 20,
        marginBottom: 20,
        textDecoration: 'underline',

        '&:hover': {
            textDecoration: 'underline',
            color: theme.custom.bgBlackTransparent,
            backgroundColor: 'transparent',
        },
    },
    loginOptionsText: {
        marginBottom: '20px',
    },
    title: {
        fontWeight: '700',
        margin: '20px 0 10px',
        textAlign: 'center',
    },
    loader: {
        maxWidth: '80px',
        height: 'auto',
        padding: '0px',
        backgroundColor: theme.custom.bgBlackTransparent,
        border: '2px',
        borderRadius: '5px',
    },
    inputLabel: {
        color: theme.palette.primary.main,
    },
    footer: {
        backgroundColor: 'transparent',
    },
    text: {
        marginBottom: '10px',
        textAlign: 'center',
    },
});

export default styles;

export const useStyles = makeStyles(styles);
