import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Checkbox, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { createUserRequested } from '../../../auth/actions';
import Form from '../../../common/Form';
import FormInput from '../../../common/Form/FormInput';
import FormInputGroup from '../../../common/Form/FormInputGroup';

import styles from './styles';
import SignInScreenTemplate from '../index';
import {
    getAppName,
    getPrivacyLink,
    getProperties,
    getSecondPrivacyLink,
    hasMarketingEmailOptIn,
} from '../../../utils';
import { isAuthLoading } from '../../../auth/selectors';

const UserCreateScreen = ({ classes, createUser, loading }) => {
    const { t } = useTranslation();

    const [optIn, setOptIn] = useState(hasMarketingEmailOptIn() ? false : null);

    const userForm = {
        username: '',
        password: '',
        confirmPassword: '',
        organizationPassphrase: '',
    };

    const schema = {
        username: Yup.string()
            .trim()
            .email(t('userCreate.emailInvalid'))
            .required(t('userCreate.inputRequired')),
        password: Yup.string()
            .min(8, t('userCreate.passwordLength', { length: 8 }))
            .required(t('userCreate.inputRequired')),
        confirmPassword: Yup.string()
            .required(t('userCreate.inputRequired'))
            .oneOf(
                [Yup.ref('password'), null],
                t('userCreate.confirmPasswordMatch'),
            ),
    };
    const passphraseLessLogin = getProperties()?.allowPassphraseLessLogin;
    if (!passphraseLessLogin) {
        schema.organizationPassphrase = Yup.string().required(
            t('userCreate.inputRequired'),
        );
    }

    const ValidationSchema = Yup.object().shape(schema);

    const submitHandler = (values) => {
        createUser({
            username: values.username.trim(),
            password: values.password,
            organizationPassphrase: values.organizationPassphrase,
            marketingEmailOptIn: optIn,
        });
    };

    return (
        <SignInScreenTemplate>
            <Typography variant="h3" className={classes.title}>
                {t('userCreate.createYourAccountText')}
            </Typography>
            <Typography className={classes.text}>
                {t('userCreate.createYourAccountDescription')}
            </Typography>
            <Typography className={classes.text}>
                {t('userCreate.ExistingUserText') + ' '}
                <Link className={classes.link} to="/login">
                    <strong>{t('userCreate.loginText')}</strong>
                </Link>
            </Typography>
            {getProperties().shouldShowSignUp && (
                <Typography className={classes.createAccountText}>
                    {t('signIn.needToSetUp') + ' '}
                    <Link className={classes.link} to="/get-started">
                        <strong>{t('signIn.getStartedFree')}</strong>
                    </Link>
                </Typography>
            )}
            <Form
                validationSchema={ValidationSchema}
                initialValues={userForm}
                onSubmit={submitHandler}
                submitBtnText={t('Submit')}
                disabled={loading}
            >
                <FormInputGroup>
                    <Typography className={classes.inputLabel}>
                        {t('userCreate.emailLabel')}
                    </Typography>
                    <FormInput
                        name="username"
                        placeholder={t('userCreate.emailLabel')}
                    />
                    <Typography className={classes.inputLabel}>
                        {t('userCreate.passwordLabel')}
                    </Typography>
                    <FormInput
                        name="password"
                        placeholder={t('userCreate.passwordLabel')}
                        type="password"
                    />
                    <Typography className={classes.inputLabel}>
                        {t('userCreate.confirmPasswordLabel')}
                    </Typography>
                    <FormInput
                        name="confirmPassword"
                        placeholder={t('userCreate.confirmPasswordLabel')}
                        type="password"
                    />
                    {!passphraseLessLogin && (
                        <>
                            <Typography className={classes.inputLabel}>
                                {t('userCreate.accessCodeLabel')}
                            </Typography>
                            <FormInput
                                name="organizationPassphrase"
                                placeholder={t('userCreate.accessCodeLabel')}
                            />
                        </>
                    )}
                </FormInputGroup>
                {hasMarketingEmailOptIn() && (
                    <Grid
                        wrap="nowrap"
                        container
                        item
                        style={{ marginBottom: '20px' }}
                    >
                        <Checkbox
                            style={{ padding: '0px 5px' }}
                            checked={optIn}
                            onChange={(e) => setOptIn(e.target.checked)}
                        />
                        <Typography style={{ fontSize: '12px' }}>
                            {t('signUp.marketingEmailOptIn', {
                                appName: getAppName(),
                            })}
                        </Typography>
                    </Grid>
                )}
            </Form>
            <Grid wrap="nowrap" container item style={{ marginTop: '20px' }}>
                <Typography style={{ fontSize: '12px' }}>
                    <Trans i18nKey="signUp.acceptTerms">
                        By creating an account, you acknowledge you have read
                        and understand the
                        <a
                            target="_blank"
                            href={getPrivacyLink()}
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        and
                        <a
                            target="_blank"
                            href={getSecondPrivacyLink()}
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                    </Trans>
                </Typography>
            </Grid>
        </SignInScreenTemplate>
    );
};
UserCreateScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    createUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export const StyledUserCreateScreen = withStyles(styles)(UserCreateScreen);

const mapStateToProps = (state) => ({
    loading: isAuthLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    createUser: (request) => dispatch(createUserRequested(request)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledUserCreateScreen);
