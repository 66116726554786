import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from '../../../common/ConfirmModal';
import { Grid, Typography, withStyles } from '@material-ui/core';
import InviteUsers from '../../../common/InviteUsers';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import ModalFooter from '../ModalFooter';

const ManualInviteModal = ({
    classes,
    onClose,
    isOpen,
    isLoading,
    onSubmit,
    messages,
    errors,
    clearSnackBar,
    loadUrlFunc,
    url,
    linkText,
    onLinkClick,
    accessCode,
}) => {
    const { t } = useTranslation();

    return (
        <ConfirmModal
            isOpen={isOpen}
            centeredButtons
            cancelBtnHidden
            confirmBtnHidden
            onClose={onClose}
        >
            <Grid container item className={classes.modalBody}>
                <Grid
                    container
                    item
                    xs={8}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="h4" className={classes.modalHeader}>
                        {t('usersPage.inviteModal.manualInvite')}
                    </Typography>
                    <InviteUsers
                        data-intercom-target="Manual Invite Modal Email Container"
                        onSubmit={onSubmit}
                        messages={messages}
                        errors={errors}
                        loading={isLoading}
                        clearSnackBar={clearSnackBar}
                        loadUrlFunc={loadUrlFunc}
                        url={url}
                    />
                </Grid>
            </Grid>
            <ModalFooter
                linkText={linkText}
                onLinkClick={onLinkClick}
                accessCode={accessCode}
            />
        </ConfirmModal>
    );
};

ManualInviteModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    messages: PropTypes.string.isRequired,
    errors: PropTypes.array.isRequired,
    clearSnackBar: PropTypes.func.isRequired,
    loadUrlFunc: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    onLinkClick: PropTypes.func.isRequired,
    accessCode: PropTypes.string.isRequired,
};

export default withStyles(styles)(ManualInviteModal);
