import IMAGES from '../../../constants/images';
import { getAppImages } from '../../../utils';

const styles = (theme) => ({
    container: {
        minHeight: '605px',
        width: '320px',
        margin: '0 auto',
        background: `url(${IMAGES.notificationWrapper}) no-repeat center center`,
        backgroundSize: 'cover',
    },
    screen: {
        width: '100%',
        margin: '80px 35px',
        background: `url(${
            getAppImages().inAppNotifications
        }) no-repeat center center`,
        backgroundSize: 'cover',
    },
    notification: {
        display: 'flex',
        marginTop: '50px',
        overflow: 'hidden',
    },
    notificationTitle: {
        display: 'flex',
        marginBottom: '5px',
        alignItems: 'center',
        fontSize: '10.5px',
        fontWeight: 700,
        lineHeight: 1,
    },
    notificationIcon: {
        width: '11%',
        height: '11%',
        marginLeft: '4%',
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: '6px',
        boxSizing: 'border-box',
    },
    notificationText: {
        maxWidth: '156px',
        margin: '5px 0',
        fontSize: '10px',
        lineHeight: 1.1,
        wordWrap: 'break-word',
    },
    notificationContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '2%',
        width: '83%',
        borderBottom: '1px solid #b7b7b7',
    },
    notificationTime: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '0 8px 5px 0',
        fontSize: '9px',
        color: 'gray',
    },
    notificationTimeIcon: {
        width: '16px',
        height: '16px',
    },
    iconBorder: {
        border: `2px solid ${theme.palette.common.white}`,
        borderRadius: '6px 6px 50% 50%',
    },
});

export default styles;
