import { get } from 'lodash';
import moment from 'moment';
import IMAGES from '../constants/images';
import i18next from 'i18next';

import {
    AUDIENCE_VALUE_CHALLENGE_TEAMS,
    AUDIENCE_VALUE_USERS,
    GENERAL_SUBJECT_TYPE,
    SETTING_TYPE_CHALLENGE,
    SETTING_TYPE_PIN,
    SUBJECT_TYPES_MAP,
} from './constants';
import { ALL_AUDIENCE_TYPES } from '../screens/UpdateNotificationScreen/NotificationForm/constants';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../constants/dates';
import { getAppImages } from '../utils';
import { CONTENT_TYPE_USER } from '../constants/contentType';

const getAudienceMembers = (audiences) => {
    let audienceString = audiences
        .map((audienceMember) => audienceMember.name)
        .slice(0, 10)
        .join(', ');
    if (audiences.length > 10) {
        audienceString += '...';
    }
    return audienceString;
};

const getDefaultIcon = (settingsType) => {
    switch (settingsType) {
        case SETTING_TYPE_CHALLENGE:
            return getAppImages().defaultChallengeIcon;
        case SETTING_TYPE_PIN:
            return IMAGES.defaultPinIcon;
        default:
            return null;
    }
};

export const notificationToTableCell = (notification) => {
    const {
        subjectReference: {
            name: subject,
            imageSizes,
            type: contentType,
            id: subjectId,
        } = {},
        userCount,
        audiences,
        content,
        audienceType,
        sendDate,
        settingsType,
        id,
    } = notification || {};

    const language = i18next.language;
    const message = get(
        content,
        `0.message.${language}`,
        content[0].message.en,
    );
    const audience = getAudienceMembers(audiences);
    const contentTitle = get(
        content,
        `0.title.${language}`,
        content[0].title.en,
    );

    return {
        id,
        // is this notification subject a challenge?
        isChallenge: settingsType === SETTING_TYPE_CHALLENGE,
        // Main subject of the notification. Could be a title for the notification or the name of the subject in context
        subject: subject || contentTitle,
        subjectId,

        // Icon to show in the data table for this row
        icon: get(imageSizes, '0.url', getDefaultIcon(settingsType)),

        // The type of subject.
        contentType,

        // Actual localized message
        message,

        // Number of users who will or did receive the notification
        userCount,

        // The members who received this notification ie multiple communities or multiple users
        audience,

        // See audienceTypes
        audienceType: i18next.t(`notifications.audienceTypes.${audienceType}`),

        // When this message was set to be delivered
        sendDate: sendDate,
    };
};

export const notificationResponseToTableCellData = (
    notificationResponseData,
) => {
    return notificationResponseData.map((notificationData) =>
        notificationToTableCell(notificationData),
    );
};

export const transformNotification = (data, language) => {
    const audienceType = get(data, 'audienceType');
    let audiences = get(data, 'audiences');
    switch (audienceType) {
        case AUDIENCE_VALUE_CHALLENGE_TEAMS:
            audiences = transformTeams(audiences);
            break;
        case AUDIENCE_VALUE_USERS:
        default:
            audiences = transformUsers(audiences);
    }
    return {
        subjectContentType: get(data, 'settingsType'),
        subjectContentID: get(data, 'subjectReference.id'),
        subject:
            get(data, `content[0].title[${language}]`) ||
            get(data, 'content[1].title.en'),
        message:
            get(data, `content[0].message[${language}]`) ||
            get(data, 'content[0].message.en'),
        audienceType: audienceType,
        audiences: audiences,
        sendDate: get(data, 'sendDate'),
        iconUrl: get(data, 'subjectReference.imageSizes[0].url'),
    };
};

export const transformActions = (data) =>
    data?.map(({ id, name: value, iconUrl }) => ({
        id,
        value,
        iconUrl,
    })) || [];

export const transformAdmins = (data) =>
    data
        ? data.map(({ id, username, imageSizes = [] }) => ({
              id,
              title: username,
              value: id,
              iconUrl: imageSizes[0]?.url || getAppImages().defaultProfileIcon,
          }))
        : null;

export const transformUsers = (data) =>
    data
        ? data.map(({ id, name, label, imageSizes = [] }) => ({
              id,
              title: `${name || label} (${id})`,
              value: id,
              iconUrl: imageSizes[0]?.url || getAppImages().defaultProfileIcon,
          }))
        : null;

const transformTeams = (team) =>
    team
        ? team.map(({ id, name, icon, score }) => ({
              id,
              title: `${name} (${id}) score: ${score}`,
              value: id,
              iconUrl: icon[0]?.url || getAppImages().defaultCommunityIcon,
              score,
          }))
        : null;

export const transformChallenges = (role, data) =>
    data.map(
        ({
            id,
            name: value,
            icon,
            startDate,
            endDate,
            teamsInfo: teams,
            sponsoringCommunities,
        }) => ({
            id,
            value,
            subTitle: `${moment(startDate).format(
                DATETIME_FORMAT_FOR_DATETIME_PICKERS,
            )} - ${moment(endDate).format(
                DATETIME_FORMAT_FOR_DATETIME_PICKERS,
            )}`,
            iconUrl: icon[0]?.url || getAppImages().defaultChallengeIcon,
            role,
            isChallenge: true,
            teams: transformTeams(teams),
            sponsoringCommunities,
        }),
    );

export const transformOptionsQuery = (options) => {
    const {
        subjectContentType,
        subject,
        message,
        sendDate,
        language,
    } = options;
    const audienceType = ALL_AUDIENCE_TYPES.find(
        (item) => item.value === options.audienceType,
    )?.title;
    const audiences = options.audiences.map(({ id }) => id);
    const body = {
        subject,
        localSubject: { [language]: subject },
        message,
        localMessage: { [language]: message },
        audienceType,
        sendDate: Math.floor(new Date(sendDate).getTime() / 1000),
        language,
        audiences,
    };

    if (subjectContentType !== GENERAL_SUBJECT_TYPE) {
        body.subjectContentType = SUBJECT_TYPES_MAP[subjectContentType];
        body.subjectContentID = options.subjectContentID;
        body.image = options.image;
    }

    if (options.notificationId) {
        body.id = options.notificationId;
    }

    return body;
};

export const transformCreateNotification = (notification = {}) => {
    const { audiences = [] } = notification;
    return {
        ...notification,
        audiences: transformUsers(audiences),
    };
};

export const transformUserNotifications = (notifications) =>
    notifications?.map((notification) => {
        const pictureContentType =
            notification.content?.subjectPictureData?.contentType;
        let defaultLeftIcon = getAppImages().defaultAppIcon;
        if (pictureContentType === CONTENT_TYPE_USER) {
            defaultLeftIcon = getAppImages().defaultProfileIcon;
        }
        return {
            created: moment(notification.created),
            title: notification.content?.title,
            message: notification.content?.message,
            targetView: notification.content?.targetView,
            leftIcon:
                notification.content?.subjectPictureData?.imageSizes?.[0]
                    ?.url || defaultLeftIcon,
            leftIconType: notification.content?.subjectPictureData?.contentType,
            rightIconName: notification.content?.iconName,
            status: notification.status,
        };
    });
