import {
    CLEAR_AUTH_ERRORS,
    CLEAR_AUTH_MESSAGES,
    CLEAR_EMAIL_VERIFICATION,
    CONFIRMATION_REQUEST_FAILED,
    CONFIRMATION_REQUEST_SUCCEEDED,
    CONFIRMATION_REQUESTED,
    CREATE_USER_FAILED,
    CREATE_USER_REQUESTED,
    CREATE_USER_SUCCEEDED,
    EMAIL_VERIFICATION_VERIFY,
    EMAIL_VERIFICATION_VERIFY_FAILED,
    EMAIL_VERIFICATION_VERIFY_SUCCEEDED,
    INITIAL_FORM_POPULATE,
    INITIAL_FORM_SUBMIT,
    INITIAL_FORM_SUBMIT_FAILED,
    INITIAL_FORM_SUBMIT_SUCCEEDED,
    LOGIN_REQUEST_FAILED,
    LOGIN_REQUEST_SUCCEEDED,
    LOGIN_REQUESTED,
    LOGOUT_REQUEST_SUCCEEDED,
    LOGOUT_REQUESTED,
    REDIRECTION_AUTOFILL,
    RESET_PASSWORD_EMAIL_REQUESTED,
    RESET_PASSWORD_REQUEST_FAILED,
    RESET_PASSWORD_REQUEST_SUCCEEDED,
    RESET_PASSWORD_REQUESTED,
    RESET_PASSWORD_SEND_FAILED,
    RESET_PASSWORD_SENT,
    SIGN_UP_REQUEST_FAILED,
    SIGN_UP_REQUEST_SUCCEEDED,
    SIGN_UP_REQUESTED,
    CLEAR_VERIFICATION_MESSAGES,
    SSO_LOGIN_REQUESTED,
    SSO_LOGIN_REQUEST_SUCCEEDED,
    SSO_STORE_CONFIG,
} from './actions';
import { getToken } from '../utils/api/index';
import i18n from '../localization/i18n';
import { QUEUE_DEEP_LINK } from '../deepLinks/actions';

const initialState = {
    token: getToken(),
    organizationPassphrase: null,
    loading: false,
    redirect: null,
    success: false,
    passwordResetReturnURL: '',
    messages: [],
    onBoarding: {
        verified: false,
        verificationMessages: [],
        verificationCode: '',
        accountDetails: {
            username: '',
            email: null,
            employeeCount: null,
            organization: null,
            website: null,
        },
        orgValid: false,
    },
    errors: {
        login: [],
        signup: [],
        confirm: [],
        pwReset: [],
        invite: [],
        emailVerificationErrors: [],
        userCreateErrors: [],
    },
    sso: {
        config: {},
        serviceConfig: {},
        returnLocation: '',
    },
    invite: {
        success: false,
    },
};

export default function authReducer(state = initialState, action) {
    const { type, payload = {}, payload: { errors = [] } = {} } = action;

    if (
        type === LOGIN_REQUESTED ||
        type === SSO_LOGIN_REQUESTED ||
        type === RESET_PASSWORD_EMAIL_REQUESTED ||
        type === RESET_PASSWORD_REQUESTED
    ) {
        return {
            ...state,
            loading: true,
            errors: {
                ...state.errors,
                login: [],
            },
        };
    } else if (type === CONFIRMATION_REQUESTED) {
        return {
            ...state,
            loading: true,
            errors: {
                ...state.errors,
                setup: [],
            },
            invite: {
                success: false,
            },
        };
    } else if (type === SIGN_UP_REQUESTED) {
        return {
            ...state,
            loading: true,
            errors: {
                ...state.errors,
                signup: [],
            },
        };
    } else if (
        type === LOGIN_REQUEST_SUCCEEDED ||
        type === SIGN_UP_REQUEST_SUCCEEDED
    ) {
        const { token, organizationPassphrase = null } = payload;
        return {
            ...state,
            token,
            organizationPassphrase,
            loading: false,
        };
    } else if (type === LOGIN_REQUEST_FAILED) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                login: errors,
            },
        };
    } else if (type === SIGN_UP_REQUEST_FAILED) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                signup: errors,
            },
        };
    } else if (type === CONFIRMATION_REQUEST_FAILED) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                confirm: errors,
            },
        };
    } else if (
        type === RESET_PASSWORD_SEND_FAILED ||
        type === RESET_PASSWORD_REQUEST_FAILED
    ) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                pwReset: errors,
            },
        };
    } else if (type === CONFIRMATION_REQUEST_SUCCEEDED) {
        return {
            ...state,
            loading: false,
        };
    } else if (type === RESET_PASSWORD_SENT) {
        return {
            ...state,
            success: true,
            messages: [i18n.t('passwordReset.resetSendSuccessful')],
            loading: false,
        };
    } else if (type === RESET_PASSWORD_REQUEST_SUCCEEDED) {
        return {
            ...state,
            success: true,
            passwordResetReturnURL: payload.returnURL,
            messages: [i18n.t('passwordReset.resetSuccessful')],
            loading: false,
        };
    } else if (type === LOGOUT_REQUESTED) {
        return {
            ...initialState,
            token: null,
            redirect: '/login',
            errors: {
                ...state.errors,
                login: payload.errors,
            },
        };
    } else if (type === CLEAR_AUTH_MESSAGES) {
        return {
            ...state,
            messages: initialState.messages,
            invite: {
                success: false,
            },
        };
    } else if (type === CLEAR_AUTH_ERRORS) {
        return {
            ...state,
            messages: initialState.messages,
            errors: {
                ...initialState.errors,
            },
            invite: {
                success: false,
            },
        };
    } else if (type === CLEAR_EMAIL_VERIFICATION) {
        return {
            ...state,
            onBoarding: {
                ...state.onBoarding,
                verified: false,
            },
        };
    } else if (type === EMAIL_VERIFICATION_VERIFY) {
        return {
            ...state,
            loading: true,
            errors: {
                ...state.errors,
                emailVerificationErrors: [],
            },
            onBoarding: {
                ...state.onBoarding,
                verificationCode: payload.verificationCode,
            },
        };
    } else if (type === EMAIL_VERIFICATION_VERIFY_SUCCEEDED) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                emailVerificationErrors: [],
            },
            onBoarding: {
                ...state.onBoarding,
                verified: true,
                verificationCode: '',
            },
        };
    } else if (type === EMAIL_VERIFICATION_VERIFY_FAILED) {
        const { errors } = payload;
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                emailVerificationErrors: errors,
            },
            onBoarding: {
                ...state.onBoarding,
                verified: false,
            },
        };
    } else if (type === CREATE_USER_REQUESTED) {
        return {
            ...state,
            loading: true,
            errors: {
                ...state.errors,
                userCreateErrors: [],
            },
        };
    } else if (type === CREATE_USER_SUCCEEDED) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                userCreateErrors: [],
            },
        };
    } else if (type === CREATE_USER_FAILED) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                userCreateErrors: errors,
            },
        };
    } else if (type === REDIRECTION_AUTOFILL) {
        const { email } = payload;
        return {
            ...state,
            onBoarding: {
                ...state.onBoarding,
                accountDetails: {
                    ...state.accountDetails,
                    email,
                },
            },
        };
    } else if (type === QUEUE_DEEP_LINK) {
        return {
            ...state,
            errors: {
                ...state.errors,
                login: [i18n.t('signIn.linkQueued')],
            },
        };
    } else if (type === LOGOUT_REQUEST_SUCCEEDED) {
        return {
            ...initialState,
            errors: {
                ...state.errors,
            },
        };
    } else if (
        type === INITIAL_FORM_POPULATE ||
        type === SSO_LOGIN_REQUEST_SUCCEEDED
    ) {
        return {
            ...state,
            loading: false,
            onBoarding: {
                ...state.onBoarding,
                accountDetails: {
                    username: payload.username,
                    email: payload.email,
                    employeeCount: payload.employeeCount,
                    organization: payload.organization,
                    website: payload.website,
                },
            },
        };
    } else if (type === INITIAL_FORM_SUBMIT) {
        return {
            ...state,
            loading: true,
            errors: {
                ...state.errors,
                emailVerificationErrors: [],
            },
            onBoarding: {
                ...state.onBoarding,
                accountDetails: {
                    username: payload.username,
                    email: payload.email,
                    employeeCount: payload.employeeCount,
                    organization: payload.organization,
                    website: payload.website,
                },
            },
        };
    } else if (type === INITIAL_FORM_SUBMIT_SUCCEEDED) {
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                emailVerificationErrors: [],
            },
            onBoarding: {
                ...state.onBoarding,
                orgValid: true,
                verificationMessages: payload.resendVerification
                    ? [i18n.t('onBoarding.resendSuccess')]
                    : [],
            },
        };
    } else if (type === INITIAL_FORM_SUBMIT_FAILED) {
        const { errors } = payload;
        return {
            ...state,
            loading: false,
            errors: {
                ...state.errors,
                emailVerificationErrors: errors,
            },
            onBoarding: {
                ...state.onBoarding,
                orgValid: false,
            },
        };
    } else if (type === CLEAR_VERIFICATION_MESSAGES) {
        return {
            ...state,
            onBoarding: {
                ...state.onBoarding,
                verificationMessages: [],
            },
        };
    } else if (type === SSO_STORE_CONFIG) {
        return {
            ...state,
            sso: {
                ...state.sso,
                config: payload.config,
                serviceConfig: payload.serviceConfig,
                returnLocation: payload.returnLocation,
            },
        };
    }
    return state;
}
