export default (theme) => ({
    container: {
        boxShadow: 'none',
    },
    root: {
        backgroundColor: theme.custom.bgLightAccentColor,
    },
    cell: {
        padding: 8,
        width: 'inherit',
        height: 'inherit',
        borderBottom: 'solid 1px',
        borderBottomColor: theme.custom.grey88,
        '& img': {
            maxWidth: '42px',
            borderRadius: '50%',
            borderStyle: 'none',
            boxShadow: '0px 3px 10px #00000026',
        },
    },
    borderIcon: {
        '& img': {
            borderRadius: '50%',
            borderStyle: 'none',
            boxShadow: '0px 3px 10px #00000026',
        },
    },
    noItemsText: {
        padding: '16px 0',
        opacity: 0.5,
        fontSize: 14,
        textAlign: 'center',
    },
    cellTitle: {
        fontWeight: 700,
    },
    contentLoaderContainer: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        height: 70,
    },
    pagination: {
        marginTop: '10px',
    },
});
