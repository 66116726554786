import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useStyles from './styles';
import CommunityCard from './CommunityCard';

const CommunityDropdown = ({
    paperRef,
    value,
    communities,
    placeholder,
    onChange,
    bgColor,
    groupBy,
    inputValue,
    ...props
}) => {
    const { t } = useTranslation();
    const classes = useStyles({ bgColor });

    const [currentValue, setCurrentValue] = useState(inputValue);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        if (inputValue !== currentValue) {
            setCurrentValue(inputValue);
            setGroups([]);
        }
    }, [inputValue, currentValue]);

    const renderOption = useCallback(
        (option) => {
            if (
                option.isOrganization &&
                groups.some((group) => group === option.organizationId)
            )
                return;
            return (
                <Grid container alignItems="center" wrap="nowrap">
                    {!option.isOrganization && (
                        <>
                            <div className={classes.communityIndent} />
                        </>
                    )}
                    <CommunityCard title={option.title} url={option.url} />
                </Grid>
            );
        },
        [classes, groups],
    );

    const renderGroup = useCallback(
        (params) => {
            const isGroup = !!params.group;
            if (isGroup && !groups.some((group) => group === params.group))
                setGroups([...groups, params.group]);
            const community = communities.filter(
                (community) =>
                    community.isOrganization &&
                    community.organizationId === params.group,
            )?.[0];

            return (
                <>
                    {isGroup && community && (
                        <Grid
                            container
                            item
                            alignItems="center"
                            wrap="nowrap"
                            onClick={() =>
                                onChange(null, {
                                    content: { id: params.group },
                                    organizationId: params.group,
                                })
                            }
                            className={classes.groupCard}
                        >
                            <CommunityCard
                                title={community?.title}
                                url={community?.url}
                            />
                        </Grid>
                    )}
                    {params.children}
                </>
            );
        },
        [classes, communities, groups, onChange],
    );

    const renderPaper = ({ children }) => (
        <Paper ref={paperRef}>{children}</Paper>
    );

    const renderInput = (params) => (
        <TextField
            {...params}
            placeholder={
                placeholder || `${t('communityDropdown.switchCommunity')}...`
            }
            disableListWrap
            fullWidth
            variant="outlined"
            rowsMax="8"
        />
    );
    return (
        <Autocomplete
            id="community-dropdown"
            getOptionLabel={(option) => {
                return option?.title || '';
            }}
            classes={classes}
            value={value}
            groupBy={
                groupBy ||
                ((option) =>
                    option.isOrganization ? null : option.organizationId)
            }
            options={communities}
            onChange={onChange}
            renderInput={renderInput}
            renderOption={renderOption}
            renderGroup={groupBy ? null : renderGroup}
            PaperComponent={renderPaper}
            {...props}
        />
    );
};

CommunityDropdown.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    communities: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    bgColor: PropTypes.string,
    groupBy: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    paperRef: PropTypes.shape({ current: PropTypes.any }),
    inputValue: PropTypes.string,
};

CommunityDropdown.defaultProps = {
    collapsed: false,
    value: null,
    bgColor: 'transparent',
    placeholder: null,
    paperRef: null,
    inputValue: '',
    groupBy: null,
};

export default CommunityDropdown;
