import {
    FLAG_EXCLUDE_REQUEST_FAILED,
    FLAG_EXCLUDE_REQUEST_SUCCEEDED,
    FLAG_EXCLUDE_REQUESTED,
    FLAG_IGNORE_REQUEST_FAILED,
    FLAG_IGNORE_REQUEST_SUCCEEDED,
    FLAG_IGNORE_REQUESTED,
    FLAGGED_CONTENT_REQUEST_FAILED,
    FLAGGED_CONTENT_REQUEST_SUCCEEDED,
    FLAGGED_CONTENT_REQUESTED,
    FLAG_REPORT_REQUESTED,
    FLAG_REPORT_REQUEST_SUCCEEDED,
    FLAG_REPORT_REQUEST_FAILED,
    FLAG_REPORT_CLEAR_MESSAGES,
} from './actions';
import i18n from 'i18next';

const initialState = {
    loading: false,
    errors: [],
    messages: [],
    content: null,
    total: null,
    reportPost: {
        contentId: null,
    },
};

const flagsReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case FLAG_EXCLUDE_REQUESTED:
        case FLAG_IGNORE_REQUESTED:
        case FLAGGED_CONTENT_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case FLAGGED_CONTENT_REQUEST_SUCCEEDED:
            return {
                ...state,
                loading: false,
                errors: [],
                content: payload.response.content,
                total: payload.response.total,
            };
        case FLAG_IGNORE_REQUEST_SUCCEEDED:
        case FLAG_EXCLUDE_REQUEST_SUCCEEDED:
            return {
                ...state,
                loading: false,
                errors: [],
            };
        case FLAGGED_CONTENT_REQUEST_FAILED:
        case FLAG_EXCLUDE_REQUEST_FAILED:
        case FLAG_IGNORE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                errors: payload.errors,
            };
        case FLAG_REPORT_REQUESTED:
            return {
                ...state,
                reportPost: {
                    contentId: payload.contentId,
                },
            };
        case FLAG_REPORT_REQUEST_FAILED:
            return {
                ...state,
                errors: payload.errors,
                reportPost: {
                    contentId: null,
                },
            };
        case FLAG_REPORT_REQUEST_SUCCEEDED:
            return {
                ...state,
                reportPost: {
                    contentId: null,
                },
                messages: [i18n.t('activitiesPage.addFlagSuccess')],
            };
        case FLAG_REPORT_CLEAR_MESSAGES:
            return {
                ...state,
                messages: [],
                errors: [],
            };
        default:
            return state;
    }
};

export default flagsReducer;
