const styles = (theme) => ({
    container: {
        paddingTop: '9px',
        paddingRight: '30px',
        paddingLeft: '30px',
        maxWidth: 1280,
        margin: 'auto',
    },
    counterContainer: { paddingBottom: '30px' },
    loader: {
        maxWidth: '80px',
        height: 'auto',
        padding: '0px',
        backgroundColor: theme.custom.bgBlackTransparent,
        border: '2px',
        borderRadius: '5px',
    },
    themesTitle: {
        margin: '5px 0 5px',
        fontSize: '24px',
        color: theme.custom.textDarkSecondaryColor,
        fontWeight: 600,
        lineHeight: 1.1,
    },
    themesSubtitle: {
        fontSize: '14px',
        color: theme.custom.textDarkSecondaryColor,
        fontWeight: 400,
    },
    infoIcon: {
        marginLeft: '10px',
        color: theme.custom.tooltipIconColor,
    },
    editThemesButton: {
        margin: '20px 0 20px',
    },
    editThemesPlus: {
        marginRight: '5px',
    },
    themesHeader: {
        marginBottom: '20px',
    },
    selectedThemes: {
        position: 'relative',
        marginBottom: '50px',
    },
    modalDescription: {
        paddingBottom: 20,
    },
    filter: {
        paddingLeft: 20,
    },
    pointsInput: {
        width: 100,
        paddingLeft: 5,
        paddingRight: 5,
    },
});

export default styles;
