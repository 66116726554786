const styles = (theme) => ({
    accessCode: {
        margin: '15px',
        fontWeight: 'bold',
    },
    link: {
        color: '#1D8DC7',
        textDecoration: 'underline',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
});

export default styles;
