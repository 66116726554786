import PropTypes from 'prop-types';
import { tooltipPlacementPropType } from '../Tooltip/propTypes';
import { noop } from '../utils';

export const leaderboardItemPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
});

export const leaderboardCardPropTypes = {
    // The default image if there isn't one in the item
    defaultImageSrc: PropTypes.string.isRequired,

    // Whether or not the image has rounded corners
    imageIsRounded: PropTypes.bool,

    // whether or not we should hide the item's rank
    hideRanks: PropTypes.bool,

    // The left icon on the right side of the card
    Icon: PropTypes.node,

    // The right icon on the right side of the card
    ViewIcon: PropTypes.node,

    // onClick for the left icon on the right side of the card
    onIconClick: PropTypes.func,

    // onClick for the entire card
    onItemClick: PropTypes.func,

    // onClick for the info icon
    onInfoClick: PropTypes.func,

    // tooltip for the left icon on the right side of the card
    iconTooltip: PropTypes.string,

    // tooltip for the right icon on the right side of the card
    viewIconTooltip: PropTypes.string,

    // tooltip placement for all tooltips
    iconTooltipPlacement: tooltipPlacementPropType,

    // Whether or not we should show the items ID in the card
    showId: PropTypes.bool,
};

export const leaderboardCardDefaultPropTypes = {
    defaultImageSrc: '',
    imageIsRounded: false,
    hideRanks: false,
    Icon: null,
    ViewIcon: null,
    onIconClick: noop,
    onItemClick: noop,
    onInfoClick: noop,
    iconTooltip: null,
    viewIconTooltip: null,
    iconTooltipPlacement: 'bottom',
    showId: false,
};
