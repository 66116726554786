import { DateTime, Duration, Info, Interval } from 'luxon';
import { has, pick } from 'ramda';

export const NONE = 'none';

/**
 * Given a Date or DateTime object, returns the day of the year.
 *
 * @param {Date|DateTime} date
 * @returns {number} the day of year the given date is on, parsed to an integer.
 */
export const getDayOfYear = (date) => {
    if (date instanceof Date) {
        date = DateTime.fromJSDate(date);
    } else if (!(date instanceof DateTime)) {
        throw TypeError(
            'getDayOfYear only supports input parameter as a Date or DateTime.',
        );
    }
    return parseInt(date.toFormat('o'));
};

/**
 * Given a start date, returns an Interval that covers the entire month from that date to the
 * last millisecond of the last day of the month.
 *
 * @param {Date|DateTime} startDate
 * @returns {Interval}
 */
export const getMonthInterval = (startDate) => {
    if (startDate instanceof Date) {
        startDate = DateTime.fromJSDate(startDate);
    } else if (!(startDate instanceof DateTime)) {
        throw TypeError(
            'getMonthInterval only supports input parameter as a Date or DateTime.',
        );
    }
    return Interval.after(
        startDate,
        Duration.fromObject({
            days: startDate.daysInMonth - startDate.day,
            hours: 23,
            minutes: 59,
            seconds: 59,
        }),
    );
};

/* Common form of weekdays that will be used in weekday pickers */
export const weekdays = Info.weekdays('short');

/**
 * Options for weekday pickers.
 * @type {{label: string, value: number}[]}
 */
export const weekdayOptions = weekdays.map((weekday, index) => {
    return { value: index + 1, label: weekday };
});

/**
 * Month display values to be used in the month picker.
 * @type {string[]}
 */
export const months = Info.months('short');

/**
 * Options for month pickers.
 * @type {{label: string, value: string}[]}
 */
export const monthOptions = [{ value: NONE, label: 'Month' }].concat(
    months.map((month, index) => {
        return { value: index + 1, label: month };
    }),
);

/**
 * This is the current year as an integer. It is the maximum value selectable in the year picker.
 * @type {number}
 */
export const currentYear = Number.parseInt(DateTime.local().toFormat('yyyy'));

/**
 * This is the minimum year which is displayed in the year picker.
 * @type {number}
 */
export const MIN_YEAR = 2019;

/**
 * The array of years to use as options in the year picker.
 * @type {Array}
 */
export const years = [];
for (let year = MIN_YEAR; year <= currentYear; year++) {
    years.push(year);
}

/**
 * Options for year pickers.
 * @type {{label: string, value: string}[]}
 */
export const yearOptions = [{ value: NONE, label: 'Year' }].concat(
    years.map((year) => {
        return { value: year, label: year };
    }),
);

/**
 * Format used by daysInMonth function to create a date time.
 * @type {string}
 */
const MONTH_FORMAT = 'M-yyyy';

/**
 * Given a month in its number format and a year integer,
 * return the number of days in the given month.
 *
 * @param {number} month
 * @param {number} year, defaults to current year if not specified
 * @returns {number} the number of days in the given month
 */
export const daysInMonth = (month, year = currentYear) => {
    return DateTime.fromFormat(`${month}-${year}`, MONTH_FORMAT).daysInMonth;
};

/**
 * Gets options for the days in the given month and year.
 *
 * @param {number|String} month number or NONE
 * @param {number|String} year will default to the current year if not specified or if its NONE.
 * @returns {{label: string, value: string}[]}
 */
export const daysInMonthOptions = (month, year = currentYear) => {
    const dayOptions = [{ value: NONE, label: 'Day' }];
    if (month !== NONE) {
        const numberOfDays =
            year && year !== NONE
                ? daysInMonth(month, year)
                : daysInMonth(month);
        for (let day = 1; day <= numberOfDays; day++) {
            dayOptions.push({ value: day, label: day });
        }
    }
    return dayOptions;
};

/**
 * Format the dateString into the given format.
 * @param {string} dateString the date you want to format.
 * @param {string} format the format in which you want the date to be returned, defauls to MM.dd.yyyy
 * @returns {string}
 */
export function formatDate(dateString, format = 'MM.dd.yyyy') {
    return DateTime.fromISO(dateString.split(' ').join('T'))
        .toLocal()
        .toFormat(format);
}

/**
 * Gets the start of today as an epoch timestamp.
 * @returns {number} the timestamp of the start of today
 */
export function getStartOfTodayTimestamp() {
    return Math.round(
        DateTime.local()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toMillis() / 1000,
    );
}

/**
 * Gets the end of today as an epoch timestamp
 * @returns {number} the timestamp of the end of today
 */
export function getEndOfTodayTimestamp() {
    return Math.round(
        DateTime.local()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .toMillis() / 1000,
    );
}

/**
 * Gets the start of specified DateTime as an epoch timestamp.
 * @returns {number} the timestamp of the start of today
 */
export function getStartOfDateTimestamp(dateTime) {
    return Math.round(
        dateTime
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toMillis() / 1000,
    );
}

/**
 * Gets the end of the specified DateTime as an epoch timestamp
 * @returns {number} the timestamp of the end of today
 */
export function getEndOfDateTimestamp(dateTime) {
    return Math.round(
        dateTime
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .toMillis() / 1000,
    );
}

export const hasMonth = has('month');
export const hasDay = has('day');
export const hasYear = has('year');

export const getMonthDayAndYearFromObject = (object) => {
    return pick(['month', 'day', 'year'], object);
};

export const dateDifferenceInDays = (date1, date2) =>
    Math.round((date2.valueOf() - date1.valueOf()) / 1000 / 60 / 60 / 24);

export const dateDifferenceInLargestUnits = (date1, date2) => {
    const diff = date2.valueOf() - date1.valueOf();
    const diffInDays = diff / 1000 / 60 / 60 / 24;
    if (diffInDays > 1) {
        return `${Math.round(diffInDays)} days`;
    }
    const diffInHours = diff / 1000 / 60 / 60;
    if (diffInHours > 1) {
        return `${Math.round(diffInHours)} hours`;
    }
    const diffInMinutes = diff / 1000 / 60;
    if (diffInMinutes > 1) {
        return `${Math.round(diffInMinutes)} minutes`;
    }
    const diffInSeconds = Math.round(diff / 1000);
    return `${diffInSeconds} seconds`;
};

export const getTimezoneOffsetInHours = () => {
    const now = new Date();
    const offset = Math.round(-now.getTimezoneOffset() / 60);
    const absOffset = Math.abs(offset);
    const sign = offset >= 0 ? '+' : '-';
    const twoDigitOffset = absOffset < 10 ? `0${absOffset}` : absOffset;
    return `${sign}${twoDigitOffset}`;
};

/**
 * Get the timezone abbreviation (e.g. EDT, PDT, ...)
 * @param {string} locale The client locale / language
 * @param {[string|null]} tzName A string timezone (e.g. America/New_York)
 * @returns {string}
 */
export const getTimezoneAbbrev = (locale = 'en', tzName = null) => {
    const localeOptions = { timeZoneName: 'short' };
    if (tzName !== null) {
        localeOptions.timeZone = tzName;
    }
    return new Date()
        .toLocaleString(locale, localeOptions)
        .split(' ')
        .pop();
};

/* Get the local timezone name */
export const getTimezoneName = DateTime.local().zoneName;

const stdTimezoneOffset = () => {
    const jan = new Date(new Date().getFullYear(), 0, 1);
    const jul = new Date(new Date().getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDstObserved = (date) => {
    return date.getTimezoneOffset() < stdTimezoneOffset();
};
