import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

import IMAGES from '../../constants/images';
import styles from './styles';

const EmptyTrophySection = ({ classes, title, description }) => {
    return (
        <Grid container item direction="column">
            <Typography className={classes.title} variant="h6">
                {title}
            </Typography>
            <img
                src={IMAGES.noContent}
                className={classes.image}
                width="150"
                height="150"
                alt="no trophies"
            />
            <Typography className={classes.description} variant="h6">
                {description}
            </Typography>
        </Grid>
    );
};

EmptyTrophySection.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default withStyles(styles)(EmptyTrophySection);
