import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../common/errors';
import { transformAction } from './transforms';
import { uniqBy } from 'lodash';
import { flatten } from 'ramda';
import {
    CHEATING_ACTIONS,
    PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
} from './constants';

export const getViewActionState = (state) => state.viewAction;

export const isActionLoading = createSelector(
    getViewActionState,
    (viewAction) => viewAction.isLoading,
);

export const isLogActionLoading = createSelector(
    getViewActionState,
    (viewAction) => viewAction.isLogActionLoading,
);

export const isActionShareURLLoading = createSelector(
    getViewActionState,
    (viewAction) => viewAction.actionShareURL.loading,
);

export const getActionShareURLErrors = createSelector(
    getViewActionState,
    (viewAction) => viewAction.actionShareURL.errors,
);

export const getActionErrors = createSelector(
    getViewActionState,
    (viewAction) => coerceIntoErrors(viewAction.errors),
);

export const getAction = createSelector(getViewActionState, (viewAction) =>
    transformAction(viewAction.action),
);

export const getLogAction = createSelector(
    getViewActionState,
    (viewAction = {}) => viewAction.logAction,
);

export const getLoggedActivityId = createSelector(
    getLogAction,
    (logAction) => logAction?.activities?.[0].id || '',
);

export const getShareURL = createSelector(
    getLogAction,
    (logAction) => logAction?.shareURL?.url,
);

export const isShareURLLoading = createSelector(
    getLogAction,
    (logAction) => logAction?.shareURL?.loading,
);

export const getLogErrors = createSelector(
    getViewActionState,
    (viewAction = {}) => coerceIntoErrors(viewAction.logErrors),
);

export const getLogStatus = createSelector(
    getViewActionState,
    (viewAction = {}) => viewAction.logStatus,
);

export const didLogAction = createSelector(getLogAction, (logAction) =>
    Boolean(logAction),
);

export const hasLogActionOverloaded = createSelector(
    getLogAction,
    (logAction = {}) =>
        logAction?.activities?.[0]?.actions?.some((action) =>
            CHEATING_ACTIONS.includes(action.actionName),
        ),
);

export const getLogActionEncouragements = createSelector(
    getLogAction,
    (logAction) => logAction?.encouragements || [],
);

export const getLogActionChallengeUpdates = createSelector(
    getLogAction,
    (logAction) => logAction?.['challenge-updates'] || [],
);
export const getLogActionBonuses = createSelector(getLogAction, (logAction) =>
    logAction?.activities?.[0]?.actions?.map((action) => ({
        name: action.title,
        actionName: action.actionName,
        description: action.subtitle,
        points: action.actionMultiplier,
    })),
);

export const getActions = createSelector(
    [getViewActionState, (state, visibility) => visibility],
    (actions = {}, visibility = PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME) => ({
        ...actions.actions?.[visibility],
        data: actions.actions?.[visibility]?.data?.map((action) =>
            transformAction(action),
        ),
    }),
);

export const getLinkState = createSelector(
    getViewActionState,
    (viewActionState = {}) => viewActionState.link,
);

export const isMetadataLoading = createSelector(
    getLinkState,
    (linkState = {}) => linkState.isMetadataLoading,
);

export const getMetadataErrors = createSelector(getLinkState, (linkState) =>
    coerceIntoErrors(linkState.errors),
);

export const getMetadata = createSelector(
    getLinkState,
    (linkState = {}) => linkState.metadata,
);

export const getCustomizationState = createSelector(
    getViewActionState,
    (viewAction = {}) => viewAction.customize,
);

export const isCustomizationRequested = createSelector(
    getCustomizationState,
    (customizationState = {}) => customizationState.isCustomizationRequested,
);

export const getCustomizationMessage = createSelector(
    getCustomizationState,
    (customizationState = {}) => customizationState.customizationMessage,
);

export const getCustomizationErrors = createSelector(
    getCustomizationState,
    (customizationState = {}) => customizationState.customizationErrors,
);

export const getActionCategoriesState = createSelector(
    getViewActionState,
    (viewAction) => viewAction.actionCategories,
);

export const isActionCategoriesLoading = createSelector(
    getActionCategoriesState,
    (actionCategories) => actionCategories?.isLoading,
);

export const getActionTabNames = createSelector(
    getActionCategoriesState,
    (actionCategories) =>
        actionCategories?.categories?.map((category) => category.name),
);

export const getActionPins = createSelector(
    getActionCategoriesState,
    (actionCategories) =>
        actionCategories?.categories?.map((category) =>
            category.pins.map((pin) => transformAction(pin)),
        ) || [],
);

export const getLastVisitedActionCategoryIndex = createSelector(
    getActionCategoriesState,
    (actionCategories) => actionCategories.lastActiveTabIndex,
);

export const getSearchablePins = createSelector(getActionPins, (actionPins) =>
    uniqBy(flatten(actionPins), 'id'),
);

export const getConfirmLinkStatus = createSelector(
    getLinkState,
    (linkState = {}) => linkState.confirmed,
);

export const getSDGs = createSelector(
    getViewActionState,
    (viewActionState = {}) => viewActionState.sdgs.data,
);
