import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Help, PlayArrow, Stop } from '@material-ui/icons';
import { Chart } from 'chart.js';
import { CHART_INTERVALS, CHART_TYPE_LINE, CHART_TYPES } from './constants';
import { parseRawData } from './utils';
import styles from './styles';
import Button from '../../Button';
import ContentLoader from 'react-content-loader';

const ChartItem = ({
    classes,
    rawData,
    interval,
    type,
    showLegend,
    showAnimateButton,
    loading,
}) => {
    const { t } = useTranslation();
    const [chart, setChart] = useState(null);
    const parsedData = useMemo(() => parseRawData(rawData, showLegend, type), [
        rawData,
        showLegend,
        type,
    ]);
    const [data, setData] = useState(parsedData);
    const [playing, setPlaying] = useState(false);
    const [playInterval, setPlayInterval] = useState(0);
    const chartRef = useRef();

    const play = useCallback(() => {
        setPlaying(true);
        setData({
            ...data,
            datasets: data.datasets.map((dataset, i) => ({
                ...dataset,
                data: [parsedData.datasets[i].data[0]],
            })),
        });
        const temp = [...data.datasets];
        const nodes = temp[0].data.length;
        let i = 1;
        const playInterval = setInterval(
            () => {
                const data = chart.data;
                data.datasets.forEach((dataset, index) => {
                    dataset.data.push(parsedData.datasets[index].data[i]);
                });
                if (type === CHART_TYPE_LINE) {
                    chart.update('none');
                } else {
                    chart.update();
                }

                i++;
                if (i === nodes) {
                    clearInterval(playInterval);
                    setPlaying(false);
                }
            },
            700,
            nodes,
        );

        setPlayInterval(playInterval);
    }, [chart, data, parsedData, type]);

    const stop = () => {
        clearInterval(playInterval);
        setPlaying(false);
        setData(parsedData);
    };

    useEffect(() => {
        setData(parseRawData(rawData, showLegend, type));
    }, [rawData, showLegend, type]);

    useEffect(() => {
        if (!chartRef.current) return;
        chart && chart.destroy();

        const context = chartRef.current.getContext('2d');
        const chartObject = new Chart(context, {
            type,
            data,
            options: {
                hover: {
                    mode: 'single',
                },
                aspectRatio: 3,
                legend: {
                    display: showLegend,
                    position: 'right',
                    fullWidth: true,
                    labels: {
                        fontFamily: `'effra',sans-serif`,
                        fontSize: 10,
                        boxWidth: 10,
                    },
                },
                elements: {
                    line: {
                        tension: 0,
                        borderWidth: 4,
                        borderJoinStyle: 'round',
                        borderCapStyle: 'round',
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        setChart(chartObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, interval, parsedData, showLegend]);

    useEffect(() => {
        if (!chart) return;

        chart.data = data;
        try {
            chart.update();
        } catch (e) {
            // Chart updating can cause an exception for some reason so lets just wrap in a try catch
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const hasData = data?.datasets?.length > 0;

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.container}
        >
            <div
                className={
                    !loading && hasData
                        ? classes.canvasContainer
                        : classes.hideCanvas
                }
            >
                <canvas className={classes.canvasStyles} ref={chartRef} />
            </div>
            {loading ? (
                <ContentLoader className={classes.contentLoaderContainer}>
                    <rect x="10%" y="50%" width="9%" height="40%" />
                    <rect x="20%" y="70%" width="9%" height="20%" />
                    <rect x="30%" y="40%" width="9%" height="50%" />
                    <rect x="40%" y="60%" width="9%" height="30%" />
                    <rect x="50%" y="50%" width="9%" height="40%" />
                    <rect x="60%" y="30%" width="9%" height="60%" />
                    <rect x="70%" y="70%" width="9%" height="20%" />
                    <rect x="80%" y="40%" width="9%" height="50%" />
                </ContentLoader>
            ) : !hasData ? (
                <Typography className={classes.emptyText}>
                    {t('data.empty')}. <br /> {t('data.range')}.
                </Typography>
            ) : (
                <>
                    <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        className={classes.helpWrapper}
                    >
                        {showAnimateButton ? (
                            <Button onClick={playing ? stop : play}>
                                {playing ? <Stop /> : <PlayArrow />} Animate
                                Graph
                            </Button>
                        ) : null}
                    </Grid>
                    <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        className={classes.helpWrapper}
                    >
                        <Help fontSize="small" />
                        <Typography className={classes.helpText}>
                            {t('graph.info')}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

ChartItem.propTypes = {
    classes: PropTypes.object.isRequired,
    rawData: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string),
        data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        series: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            }),
        ),
    }).isRequired,
    type: PropTypes.oneOf(CHART_TYPES).isRequired,
    interval: PropTypes.oneOf(CHART_INTERVALS).isRequired,
    showLegend: PropTypes.bool,
    showAnimateButton: PropTypes.bool,
    loading: PropTypes.bool,
};

ChartItem.defaultProps = {
    loading: false,
    isScoreTracker: false,
    showLegend: false,
    showAnimateButton: false,
};

export default withStyles(styles)(ChartItem);
