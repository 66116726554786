import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import CommonPropTypes from '../propTypes';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import RequestLoader from '../RequestLoader';
import { noop } from '../utils';
import { SCROLLER_BATCH_SIZE } from './constants';
import IMAGES from '../../constants/images';

const InfiniteScroller = ({
    isLoading,
    errors,
    items,
    loadFunc,
    renderFunc,
    listEmptyText,
    fetchLimit,
    Loader,
}) => {
    const bottomRef = useRef(null);
    const { t } = useTranslation();

    const [prevItemsCount, setPrevItemsCount] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        const itemsCount = (items && items.length) || 0;
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (entries.some((entry) => entry.intersectionRatio === 1)) {
                    if (prevItemsCount + fetchLimit <= itemsCount) {
                        setPrevItemsCount(itemsCount);
                        loadFunc({
                            limit: fetchLimit,
                            skip: itemsCount,
                        });
                    }
                }
            },
            { threshold: 1 },
        );
        const targetElement = bottomRef.current;
        if (targetElement) {
            intersectionObserver.observe(targetElement);
            return () => intersectionObserver.unobserve(targetElement);
        }
    }, [
        loadFunc,
        items,
        prevItemsCount,
        setPrevItemsCount,
        bottomRef,
        fetchLimit,
    ]);

    const renderItems = () => {
        if (items?.length > 0) {
            return items.map((item, index) => renderFunc(item, index));
        } else if (!isLoading) {
            return (
                <Grid container direction="column">
                    <Grid item className={classes.notFoundImage}>
                        <img src={IMAGES.notFound} alt="not found" />
                    </Grid>
                    <Grid item className={classes.noDataLabel}>
                        {listEmptyText}
                    </Grid>
                </Grid>
            );
        }
    };
    return (
        <>
            {errors?.length > 0 ? (
                errors.map((err, i) => <Typography key={i}>{err}</Typography>)
            ) : (
                <>
                    <Grid container direction="column" alignItems="center">
                        {renderItems()}
                    </Grid>
                    {Loader ? (
                        isLoading && Loader
                    ) : (
                        <RequestLoader
                            isLoading={isLoading}
                            title={t('loading.descriptor')}
                        />
                    )}
                    <div ref={bottomRef} style={{ width: '100%', height: 1 }} />
                </>
            )}
        </>
    );
};

InfiniteScroller.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    renderFunc: PropTypes.func.isRequired,
    errors: CommonPropTypes.errors,
    items: PropTypes.array,
    loadFunc: PropTypes.func,
    listEmptyText: PropTypes.string,
    fetchLimit: PropTypes.number,
    Loader: PropTypes.node,
};

InfiniteScroller.defaultProps = {
    errors: null,
    items: [],
    loadFunc: noop,
    listEmptyText: '',
    fetchLimit: SCROLLER_BATCH_SIZE,
    Loader: null,
};

export default InfiniteScroller;
