import React from 'react';

import { Can } from '../../casl/ability-context';
import UserNotificationsScreen from './UserNotificationsScreen';
import AdminNotificationsScreen from './AdminNotificationsScreen';

export const NotificationsScreen = (props) => {
    return (
        <>
            <Can I="view" a="AdminScreens">
                <AdminNotificationsScreen {...props} />
            </Can>
            <Can not I="view" a="AdminScreens">
                <UserNotificationsScreen {...props} />
            </Can>
        </>
    );
};

export default NotificationsScreen;
