import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
    container: {
        flex: 1,
        margin: '0 0 16px',
    },
    title: {
        margin: '10px 0',
        fontSize: 14,
        fontWeight: 600,
    },
    infoIcon: {
        paddingLeft: 4,
        color: fade(theme.custom.bgDarkAccentColor, 0.25),
    },
});
