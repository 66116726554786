import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import {
    CREATE_NOTIFICATION_REQUESTED,
    DELETE_NOTIFICATION_REQUESTED,
    MARK_USER_NOTIFICATIONS_REQUESTED,
    markUserNotificationsRequestFailed,
    markUserNotificationsRequestSucceeded,
    NOTIFICATION_REQUESTED,
    notificationRequestSucceeded,
    NOTIFICATIONS_REQUESTED,
    notificationsRequest,
    notificationsRequestFailed,
    notificationsRequestSucceeded,
    OPEN_CREATE_NOTIFICATION,
    UPDATE_NOTIFICATION_REQUESTED,
    updateNotificationFailed,
    updateNotificationSucceeded,
    USER_NOTIFICATIONS_REQUESTED,
    userNotificationsRequestFailed,
    userNotificationsRequestSucceeded,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../utils/api';
import { trackEvent } from '../utils/analytics';
import {
    GROUP_NAMES,
    GROUP_TYPE_QUEUED,
} from '../screens/NotificationsScreen/AdminNotificationsScreen/constants';
import { transformOptionsQuery } from './transforms';
import { push } from 'connected-react-router';
import i18n from 'i18next';
import {
    EVENT_NOTIFICATION_CREATE_FINISHED,
    EVENT_NOTIFICATION_DELETED,
    EVENT_NOTIFICATION_EDIT_FINISHED,
} from '../constants/analyticsEvents';

export function* getNotificationsData({
    payload: { communityId, group, options },
}) {
    try {
        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/master-notifications`,
            {
                query: {
                    limit: 10,
                    ...options,
                    group,
                },
            },
        );

        const notifications = result?.response?.['master-notifications'] || [];
        const totalCount = result?.response?.totalCount || 0;
        yield put(
            notificationsRequestSucceeded({ group, notifications, totalCount }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            `Failed to get ${GROUP_NAMES[group]} Data`,
        );

        yield put(notificationsRequestFailed({ group, errors }));
    }
}

export function* deleteNotificationRequest({
    payload: { communityId, notificationId, challengeId, options },
}) {
    trackEvent(EVENT_NOTIFICATION_DELETED);

    try {
        const query = challengeId ? { challengeId } : {};

        yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/master-notifications/${notificationId}`,
            {
                method: 'DELETE',
                query,
            },
        );

        yield put(
            notificationsRequest(communityId, GROUP_TYPE_QUEUED, options),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            `Failed to remove Notification`,
        );

        yield put(
            notificationsRequestFailed({ group: GROUP_TYPE_QUEUED, errors }),
        );
    }
}

export function* getNotificationData({
    payload: { communityId, notificationId },
}) {
    try {
        const result = yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/master-notifications/${notificationId}`,
        );
        const notification =
            get(result, 'response.master-notifications[0]') || null;

        yield put(notificationRequestSucceeded({ notification }));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            `Failed to get nofification Data`,
        );

        yield put(
            notificationsRequestFailed({ group: 'notification', errors }),
        );
    }
}

export function* createNotificationRequest({
    payload: { communityId, options },
}) {
    trackEvent(EVENT_NOTIFICATION_CREATE_FINISHED);

    try {
        const body = transformOptionsQuery(options);

        yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/master-notifications`,
            {
                method: 'POST',
                mode: 'no-cors',
                body,
            },
        );
        yield put(updateNotificationSucceeded(i18n.t('notifications.publish')));
        yield put(push('/notifications'));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to create notification.',
        );
        yield put(
            updateNotificationFailed(errors, get(error, 'response.status')),
        );
    }
}

export function* updateNotificationRequest({
    payload: { communityId, notificationId, options },
}) {
    trackEvent(EVENT_NOTIFICATION_EDIT_FINISHED);

    try {
        const body = transformOptionsQuery({ ...options, notificationId });
        yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/master-notifications/${notificationId}`,
            {
                method: 'PUT',
                body,
            },
        );
        yield put(updateNotificationSucceeded(i18n.t('notifications.update')));
        yield put(push('/notifications'));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update notification.',
        );
        yield put(
            updateNotificationFailed(errors, get(error, 'response.status')),
        );
    }
}

export function* openCreateNotificationScreen() {
    yield put(push('/notifications/create'));
}

export function* getUserNotifications({ payload: options }) {
    try {
        const data = yield call(fetchWithAuth, '/notification', {
            query: {
                transmissionType: 'notificationCenter',
                ...options,
            },
        });
        yield put(
            userNotificationsRequestSucceeded(
                data.response.notifications,
                options?.skip,
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get notifications.',
        );
        yield put(userNotificationsRequestFailed(errors));
    }
}

export function* markUserNotifications() {
    try {
        yield call(fetchWithAuth, '/notification/mark', {
            method: 'POST',
            query: {
                markBeforeTimestamp: Math.round(new Date() / 1000),
                status: 'read',
            },
        });
        yield put(markUserNotificationsRequestSucceeded());
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to mark notifications.',
        );
        yield put(markUserNotificationsRequestFailed(errors));
    }
}

export default function* notificationsSaga() {
    yield all([
        takeEvery(NOTIFICATIONS_REQUESTED, getNotificationsData),
        takeLatest(DELETE_NOTIFICATION_REQUESTED, deleteNotificationRequest),
        takeLatest(NOTIFICATION_REQUESTED, getNotificationData),
        takeLatest(CREATE_NOTIFICATION_REQUESTED, createNotificationRequest),
        takeLatest(UPDATE_NOTIFICATION_REQUESTED, updateNotificationRequest),
        takeLatest(OPEN_CREATE_NOTIFICATION, openCreateNotificationScreen),
        takeLatest(MARK_USER_NOTIFICATIONS_REQUESTED, markUserNotifications),
        takeLatest(USER_NOTIFICATIONS_REQUESTED, getUserNotifications),
    ]);
}
