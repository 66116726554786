import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../utils';
import { SSOCodeExchangeRequested } from '../../auth/actions';
import { connect } from 'react-redux';
import SignInScreen from '../../screens/SignInScreens/SignInScreen';
import { getSSOReturnLocation } from '../../auth/selectors';
import { RETURN_LOCATION_ADMIN_SIGNUP } from '../../auth/constants';
import { useHistory } from 'react-router-dom';
import MainScreen from '../MainScreen';

export const OAuthScreen = ({ requestCodeExchange, returnLocation }) => {
    const history = useHistory();
    useEffect(() => {
        requestCodeExchange();
    }, [returnLocation, history, requestCodeExchange]);
    if (!returnLocation || returnLocation === RETURN_LOCATION_ADMIN_SIGNUP) {
        return <SignInScreen />;
    }
    return <MainScreen loading />;
};

OAuthScreen.propTypes = {
    requestCodeExchange: PropTypes.func,
    returnLocation: PropTypes.string,
};

OAuthScreen.defaultProps = {
    requestCodeExchange: noop,
    returnLocation: '',
};

const mapStateToProps = (state) => ({
    returnLocation: getSSOReturnLocation(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestCodeExchange: () => dispatch(SSOCodeExchangeRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OAuthScreen);
