import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import emoji from 'i18next-emoji-postprocessor';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SUPPORTED_LANGUAGE_CODES } from './constants';
import { getSupportedLanguageCodes, getStringsNamespace } from '../utils';
/*
 * See Quick-start guide if want to use predefined list of languages instead of language detection in the browser.
 * learn more: https://react.i18next.com/getting-started
 */

const supportedLanguages =
    getSupportedLanguageCodes() || SUPPORTED_LANGUAGE_CODES;

const appNamespace = getStringsNamespace();
let allNamespaces = ['common'];
if (appNamespace) {
    allNamespaces = [appNamespace, 'common'];
}

i18n.use(emoji)
    /*
     * load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
     * learn more: https://github.com/i18next/i18next-xhr-backend
     */
    .use(Backend)
    /*
     * detect user language
     * learn more: https://github.com/i18next/i18next-browser-languageDetector
     */
    .use(LanguageDetector)
    /*
     * pass the i18n instance to react-i18next.
     */
    .use(initReactI18next)
    /*
     * init i18next
     * for all options read: https://www.i18next.com/overview/configuration-options
     */
    .init({
        fallbackLng: supportedLanguages,
        debug: process.env.NODE_ENV === 'development',
        load: 'currentOnly',
        preload: supportedLanguages, // array of languages to preload, important on serverside to assert translations loaded before rendering views
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        detection: {
            /**
             * initially tries to use the language that the user has previously selected
             * If there is none, it tries to use the browser language
             */
            order: [
                'localStorage',
                'navigator',
                'htmlTag',
                'querystring',
                'cookie',
            ],
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function(value, format, _) {
                // Acceptable formats: 'uppercase', any valid date string format
                if (format === 'uppercase') return value.toUpperCase();
                if (value instanceof Date) return moment(value).format(format);
                return value;
            },
        },
        ns: allNamespaces,
        whitelist: supportedLanguages,
        defaultNS: appNamespace || 'common',
        fallbackNS: appNamespace ? 'common' : false,
        // options
        saveMissing: false, // calls save missing key function on backend if not found
        missingKeyHandler: false, // requires saveMissing set to true
    });

export default i18n;
