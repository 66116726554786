import { get } from 'lodash';

export const getVideoId = (video) => {
    const matchResult = video.match(
        /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/watch\?(?:.*&)?v=)([^&?]+)/,
    );
    if (matchResult) {
        return matchResult[1];
    }
    // assume it's a video ID rather than a URL
    return video;
};

export const toVideoURL = (video) => {
    return video ? `https://youtube.com/embed/${getVideoId(video)}` : null;
};

export const transformAction = (action) => ({
    ...action,
    headlinePhotoUrl:
        action?.headlinePhoto?.photoSizes?.[
            action.headlinePhoto.photoSizes.length - 1
        ]?.url,
    headlinePhotoAuthor: action?.headlinePhoto?.photoAuthorName,
    headlinePhotoId: action?.headlinePhoto?.id,
    iconUrl: action?.imageSizes?.[action.imageSizes.length - 1]?.url,
    contentBonuses: action?.contentBonuses?.map((bonus) => ({
        name: bonus?.name,
        value: bonus?.bonusValue,
        imageUrl: bonus?.backgroundImage?.[0]?.url,
    })),
    videos: action?.pinYouTubes?.map((video) => ({
        name: video?.name,
        description: video?.description,
        videoId: video?.videoID,
        isCommunity: !!video?.community,
    })),
    sponsor: {
        id: action?.sponsors[Object.keys(action.sponsors)[0]]?.id,
        name: action?.sponsors[Object.keys(action.sponsors)[0]]?.shortName,
        imageUrl:
            action?.sponsors[Object.keys(action.sponsors)[0]]?.imageSizes?.[0]
                ?.url,
    },
    rawLinks: action?.pinURLs,
    links: action?.pinURLs?.map((link) => ({
        name: link?.name,
        description: link?.description,
        imageUrl: get(
            link,
            'imageSizes.0.url',
            require('../assets/images/defaultImage.png'),
        ),
        linkUrl: link?.url,
        isCommunity: !!link?.community,
        sizes: link?.imageSizes,
        sponsorId: link?.sponsorId,
        key: link?.id,
    })),
    impactConversions: action?.impactConversions?.map((conversion) => ({
        leadingPhrase: conversion.leadingPhrase,
        trailingPhrase: conversion.trailingPhrase,
        value: conversion.value,
        backgroundImage: conversion.photo?.photoSizes?.[0]?.url,
        photoAuthor: conversion.photo?.photoAuthorName,
    })),
    sustainableDevelopmentGoals: action?.sustainableDevelopmentGoals?.map(
        (sdg) => ({
            imageUrl: sdg.imageSizes?.[0]?.url,
            linkUrl: sdg.url,
            ...sdg,
        }),
    ),
});
