import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import useStyles from './styles';
import Tooltip from '../Tooltip';
import { showIntercomChat } from '../../config/intercom';

const ModalTitle = ({ text, Icon, tooltipText, tooltipPlacement }) => {
    const classes = useStyles();

    return (
        <Typography variant="h4" className={classes.title}>
            {text}
            <Tooltip title={tooltipText} placement={tooltipPlacement}>
                <Icon
                    data-intercom-target="modalTitle"
                    fontSize="small"
                    className={classes.icon}
                    onClick={showIntercomChat}
                />
            </Tooltip>
        </Typography>
    );
};

ModalTitle.propTypes = {
    text: PropTypes.string.isRequired,
    Icon: PropTypes.elementType,
    tooltipText: PropTypes.string.isRequired,
    tooltipPlacement: PropTypes.string,
};

ModalTitle.defaultProps = {
    Icon: Help,
    tooltipPlacement: 'right',
};

export default ModalTitle;
