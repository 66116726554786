import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        maxWidth: theme.custom.mainContentWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imageContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    image: {
        display: 'block',
        width: '188px',
        height: '188px',
        borderRadius: '6px',
        border: '1px solid rgba(255,255,255)',
    },
    name: {
        marginTop: '20px',
        marginBottom: '10px',
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: 1.1,
        color: theme.custom.textDarkColor,
    },
    headline: {
        textAlign: 'center',
        fontSize: 14,
        marginVertical: 10,
        marginBottom: 20,
        fontWeight: 500,
        lineHeight: 1.1,
        color: theme.custom.textDarkColor,
    },
    breadcrumbLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    breadcrumbText: {
        color: theme.palette.gray.main,
    },
}));

export default useStyles;
