import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        color: theme.palette.primary.main,
        fontWeight: '700',
    },
    formRow: {
        marginTop: '5px',
    },
    checkbox: {
        color: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
            fontSize: 30,
        },
    },
    selectBox: { padding: '6px 16px' },
}));

export default useStyles;
