import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { noop } from '../../utils';
import PhotoPropTypes from '../../Photo/propTypes';
import Photo from '../../Photo';
import FormInput from '../../Form/FormInput';
import Button from '../../Button';
import IcomoonIcon from '../../IcomoonIcon';
import styles from './styles';
import md5 from 'md5';

const CommentModal = ({
    classes,
    onClose,
    onCancel,
    onSubmit,
    DropzoneProps,
    PhotoProps,
    initCrop,
}) => {
    const { t } = useTranslation();

    const [croppedData, setCroppedData] = useState(null);
    const [croppedDataLoading, setCroppedDataLoading] = useState(false);

    const MultilineTextField = ({ placeholder, onChange, value }) => (
        <TextField
            placeholder={placeholder}
            variant="outlined"
            multiline
            rows={8}
            fullWidth
            onChange={onChange}
            defaultValue={value}
        />
    );

    useEffect(() => {
        setCroppedData(null);
    }, []);

    const handleAddImage = (file) => {
        setCroppedDataLoading(true);
        setCroppedData({ file, md5: md5(file) });
        setCroppedDataLoading(false);
    };

    const handleSubmit = useCallback(
        (values) => {
            onSubmit(values.caption, croppedData?.file, croppedData?.md5);
        },
        [onSubmit, croppedData],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                caption: Yup.string(),
            }),
        [],
    );

    const initialValues = useMemo(() => ({ caption: '' }), []);

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
        >
            {(formikProps) => {
                return (
                    <Form className={classes.form}>
                        <Grid
                            container
                            item
                            wrap="nowrap"
                            direction="row"
                            justify="space-between"
                            className={classes.titleBar}
                        >
                            <Grid container item className={classes.title}>
                                <Typography>
                                    {t('action.log.details')}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.closeButton}>
                                <CloseIcon
                                    fontSize="small"
                                    onClick={onClose}
                                    className={classes.closeIcon}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            wrap="nowrap"
                            spacing={1}
                            className={classes.content}
                        >
                            <Grid container item xs={3} direction="column">
                                <Photo
                                    title={t('action.log.image')}
                                    photoTooltip={t('action.log.imageToolTip')}
                                    setCroppedImageData={handleAddImage}
                                    DropzoneProps={DropzoneProps}
                                    fileTooLargeErrorMessage={t(
                                        'photo.tooLarge',
                                    )}
                                    classes={{ icon: classes.icon }}
                                    initCrop={initCrop}
                                    {...PhotoProps}
                                />
                            </Grid>
                            <Grid container item xs={9} direction="column">
                                <Grid item>
                                    <Typography className={classes.lable}>
                                        {t('caption')}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ height: '175px' }}>
                                    <FormInput
                                        as={MultilineTextField}
                                        noLeftMargin
                                        name="caption"
                                        placeholder={t('addACaption')}
                                        onChange={(event) => {
                                            formikProps.setFieldValue(
                                                'caption',
                                                event.target.value,
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            spacing={1}
                            justifyContent="flex-end"
                            className={classes.modalFooter}
                        >
                            <Grid>
                                <Button
                                    color="white"
                                    size="medium"
                                    onClick={onCancel}
                                    className={classes.buttonCancel}
                                >
                                    {t('cancel')}
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    color="accent"
                                    type="submit"
                                    size="medium"
                                    disabled={
                                        !formikProps.isValid ||
                                        croppedDataLoading
                                    }
                                    className={classes.button}
                                >
                                    <IcomoonIcon
                                        icon="jb-buzz"
                                        size={18}
                                        color="white"
                                        className={classes.logActionIcon}
                                    />
                                    <Typography>{t('logAction')}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

CommentModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,

    // See https://react-dropzone.js.org/
    DropzoneProps: PropTypes.object,

    PhotoProps: PhotoPropTypes.photo,

    initCrop: PropTypes.object,
};

CommentModal.defaultProps = {
    onClose: noop,
    onCancel: noop,
    DropzoneProps: {
        maxSize: 10000000,
    },
    PhotoProps: {},
    initCrop: {
        aspect: 1,
        width: 500,
        unit: 'px',
    },
};

export const StyledCommentModal = withStyles(styles)(CommentModal);

export default StyledCommentModal;
