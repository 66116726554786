import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { FormatQuoteRounded } from '@material-ui/icons';
import styles from './styles';

const ActivityCardComment = ({
    classes,
    commentUser,
    commentText,
    children,
}) => {
    return (
        <Grid
            container
            item
            className={classes.container}
            direction="row"
            wrap="nowrap"
        >
            <FormatQuoteRounded className={classes.icon} fontSize="large" />
            <Grid
                container
                item
                className={classes.textBlock}
                direction="column"
            >
                <Typography className={classes.commentUser}>
                    {commentUser}
                </Typography>
                <Typography className={classes.commentText}>
                    {commentText}
                </Typography>
            </Grid>
            {children}
        </Grid>
    );
};

ActivityCardComment.propTypes = {
    classes: PropTypes.object.isRequired,
    commentUser: PropTypes.string.isRequired,
    commentText: PropTypes.string.isRequired,
    children: PropTypes.node,
};

ActivityCardComment.defaultProps = {
    children: null,
};
export default withStyles(styles)(ActivityCardComment);
