import React, { Fragment } from 'react';
import { withStyles, TextareaAutosize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './styles';

const TextAreaField = ({ classes, charactersLeft, ...props }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <TextareaAutosize {...props} />
            {charactersLeft ? (
                <div className={classes.charsLeft}>
                    {`${charactersLeft} ${t('charactersRemaining')}`}
                </div>
            ) : null}
        </Fragment>
    );
};

TextAreaField.propTypes = {
    classes: PropTypes.object.isRequired,
    charactersLeft: PropTypes.number,
};

TextAreaField.defaultProps = {
    charactersLeft: NaN,
};

export default withStyles(styles)(TextAreaField);
