import templateStyles from '../styles.js';
const styles = (theme) => {
    return {
        ...templateStyles(theme),
        loginOptionsText: {
            marginBottom: '20px',
        },
        ssoButton: {
            height: 41,
            marginTop: 10,
            marginBottom: 10,
            fontWeight: '600',
            borderRadius: 0,
            textTransform: 'none',
            border: `1px solid #8C8C8C`,
            color: '#8C8C8C',
            fontSize: '15px',
            '&:hover': {
                backgroundColor: '#fff',
                borderColor: '#8C8C8C',
            },
        },
        buttonIcon: {
            maxHeight: '20.5px',
            marginRight: '12px',
        },
        divider: {
            marginBottom: '10px',
        },
    };
};

export default styles;
