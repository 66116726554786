import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    container: {
        background: theme.custom.bgLightAccentColor,
        padding: '15px 0',
        borderRadius: '5px',
    },
    title: {
        fontSize: '14px',
        paddingBottom: '5px',
    },
    endText: {
        paddingTop: '5px',
        fontSize: '12px',
    },
}));
