import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';

import useStyles from './styles';

import theme from '../../theme';
import IcomoonIcon from '../IcomoonIcon';

const ParticipantsButton = ({ selected, icon, label, onClick }) => {
    const classes = useStyles({ selected });
    /*
     * TODO if an unselected button is hovered, its icon color should be theme.custom.textDarkColor
     * The problem is that IcomoonReact does not respect color specified in CSS class if it has already been
     * specified in the color prop.
     */
    const iconColor = selected
        ? theme.palette.primary.main
        : theme.custom.textDarkSecondaryColor;

    return (
        <Button
            color="primary"
            variant="outlined"
            classes={{
                root: classes.root,
                outlinedPrimary: classes.outlinedPrimary,
            }}
            onClick={onClick}
        >
            <Grid
                container
                item
                direction="column"
                alignItems="center"
                className={classes.container}
            >
                <Grid item>
                    <IcomoonIcon icon={icon} color={iconColor} size={32} />
                </Grid>
                <Grid item className={classes.label}>
                    {label}
                </Grid>
            </Grid>
        </Button>
    );
};

ParticipantsButton.propTypes = {
    selected: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ParticipantsButton;
