import React from 'react';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import { Grid, Input } from '@material-ui/core';
import classNames from 'classnames';

import useStyles from './styles';
import theme from '../../../theme';
import FieldLabel from '../../FieldLabel';

const FormInput = ({
    errorTextColor,
    noLeftMargin,
    noBottomMargin,
    autoHeight,
    noErrorMessage,
    borderColor,
    Icon,
    onIconClick,
    label,
    as,
    useFormik,
    additionalClasses,
    Component,
    tooltipText,
    tooltipIntercomTarget,
    intercomTargetPrefix,
    name,
    ...props
}) => {
    const classes = useStyles({
        errorTextColor,
        noLeftMargin,
        noBottomMargin,
        autoHeight,
        borderColor,
    });
    const inputClasses = {
        root: classes.root,
        input: classes.input,
    };

    return (
        <Grid
            item
            container
            className={classNames([
                classes.wrapper,
                additionalClasses?.wrapper,
            ])}
        >
            {label && (
                <FieldLabel
                    tooltipText={tooltipText}
                    intercomTarget={tooltipIntercomTarget}
                    text={label}
                />
            )}
            <Grid
                item
                container
                className={classNames([
                    classes.container,
                    additionalClasses?.container,
                ])}
                data-intercom-target={`${intercomTargetPrefix}${name}Input`}
            >
                {useFormik ? (
                    <Field
                        as={as}
                        classes={inputClasses}
                        name={name}
                        {...props}
                    />
                ) : (
                    <Component classes={inputClasses} name={name} {...props} />
                )}
                {Icon && (
                    <Icon className={classes.icon} onClick={onIconClick} />
                )}
                {noErrorMessage ? null : (
                    <ErrorMessage
                        name={name}
                        className={classes.errorBelow}
                        component="p"
                    />
                )}
            </Grid>
        </Grid>
    );
};

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    errorTextColor: PropTypes.string,
    // whether the error message should have left margin
    noLeftMargin: PropTypes.bool,
    // whether the field should have bottom margin
    noBottomMargin: PropTypes.bool,
    // whether the error message should be displayed
    noErrorMessage: PropTypes.bool,
    // whether the input container has smaller container height
    autoHeight: PropTypes.bool,
    borderColor: PropTypes.string,

    label: PropTypes.string,

    // whether to use Formik's Field component
    useFormik: PropTypes.bool,
    // Can be used to deep merge classes
    additionalClasses: PropTypes.object,

    // an instance of a material UI icon, which is just an object
    Icon: PropTypes.object,
    onIconClick: PropTypes.func,
    as: PropTypes.elementType,
    Component: PropTypes.elementType,
    tooltipText: PropTypes.string,
    tooltipIntercomTarget: PropTypes.string,
    intercomTargetPrefix: PropTypes.string,
};

FormInput.defaultProps = {
    errorTextColor: theme.palette.error.main,
    noLeftMargin: false,
    noBottomMargin: false,
    noErrorMessage: false,
    autoHeight: true,
    borderColor: null,
    useFormik: true,
    Icon: null,
    onIconClick: null,
    as: Input,
    label: '',
    additionalClasses: {},
    Component: Input,
    tooltipText: null,
    tooltipIntercomTarget: null,
    intercomTargetPrefix: '',
};

export default FormInput;
