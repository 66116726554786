export const QUEUE_DEEP_LINK = 'QUEUE_DEEP_LINK';
export const OPEN_QUEUED_DEEP_LINK = 'OPEN_QUEUED_DEEP_LINK';
export const CLEAR_QUEUED_DEEP_LINK = 'CLEAR_QUEUED_DEEP_LINK';

export const queueDeepLink = (deepLinkPath) => ({
    type: QUEUE_DEEP_LINK,
    payload: {
        deepLinkPath,
    },
});

export const openQueuedDeepLink = () => ({
    type: OPEN_QUEUED_DEEP_LINK,
});

export const clearQueuedDeepLink = () => ({
    type: CLEAR_QUEUED_DEEP_LINK,
});
