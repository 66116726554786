import React, { useState } from 'react';
import UploadPropTypes from './propTypes';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import useStyles from './styles';

import 'react-image-crop/dist/ReactCrop.css';
import { AddPhotoIcon } from '../../assets/images/svg';
import InfoTooltip from '../InfoTooltip';

const FILE_TOO_LARGE_CODE = 'file-too-large';

const Upload = ({
    title,
    DropzoneProps,
    value,
    photoTooltip,
    photoTooltipKey,
    fileTooLargeErrorMessage,
    hideTip,
    border,
    borderRadius,
    disabled,
    maxHeight,
    onDropAccepted,
    placeholder,
    acceptTypes,
}) => {
    const { t } = useTranslation();

    const [errorMessages, setErrorMessages] = useState([]);

    const classes = useStyles({
        border,
        borderRadius,
        maxHeight,
    });

    const _onDropAccepted = (acceptedFiles) => {
        if (acceptedFiles?.[0]) {
            onDropAccepted(acceptedFiles);
            setErrorMessages([]);
        }
    };

    const onDropRejected = (rejectedFiles = []) => {
        if (rejectedFiles.length) {
            setErrorMessages(
                rejectedFiles.map(({ errors = [] }) => {
                    if (errors.length) {
                        return errors.map(({ message, code }) => {
                            if (
                                code === FILE_TOO_LARGE_CODE &&
                                fileTooLargeErrorMessage
                            ) {
                                return fileTooLargeErrorMessage;
                            } else {
                                return message;
                            }
                        });
                    }
                    return [];
                }),
            );
        }
    };

    return (
        <Grid container item classNAme={classes.photoContainer}>
            {title && (
                <Typography className={classes.title}>
                    {title}
                    <InfoTooltip
                        intercomTarget="uploadInfo"
                        text={photoTooltip || t(photoTooltipKey)}
                    />
                </Typography>
            )}
            <Dropzone
                onDropAccepted={_onDropAccepted}
                onDropRejected={onDropRejected}
                disabled={disabled}
                accept={acceptTypes}
                {...DropzoneProps}
            >
                {({ getRootProps, getInputProps }) => (
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.photo}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {value ? (
                            <img src={value} className={classes.image} alt="" />
                        ) : (
                            placeholder || (
                                <AddPhotoIcon
                                    className={classes.defaultPhoto}
                                />
                            )
                        )}
                    </Grid>
                )}
            </Dropzone>
            {!hideTip && (
                <Typography className={classes.smallText}>
                    {t('photo.drop.tip')}
                </Typography>
            )}
            {errorMessages.map((errorMessage) => {
                return (
                    <Grid item key={errorMessage}>
                        <Typography color="error" className={classes.smallText}>
                            {errorMessage}
                        </Typography>
                    </Grid>
                );
            })}
        </Grid>
    );
};

Upload.propTypes = UploadPropTypes.upload;

Upload.defaultProps = {
    photoTooltip: null,
    placeholder: null,
    maxHeight: null,
    photoTooltipKey: 'photo.tooltip',
    DropzoneProps: {
        maxSize: 10000000,
    },
    hideTip: false,
    border: true,
    borderRadius: '0px',
    disabled: false,
    acceptType: ['image/jpg', 'image/jpeg', 'image/png'],
};

export default Upload;
