import PropTypes from 'prop-types';
import CommonPropTypes from '../common/propTypes';

export const LinkPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    isCommunity: PropTypes.bool.isRequired,
    sizes: PropTypes.array.isRequired,
});

export const BonusPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
});

export const VideoPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    isCommunity: PropTypes.bool.isRequired,
});

export const actionPropType = PropTypes.shape({
    headlinePhotoUrl: PropTypes.string,
    headlinePhotoAuthor: PropTypes.string,
    headlinePhotoId: PropTypes.string,
    iconUrl: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    originalDescription: PropTypes.string,
    contentBonuses: PropTypes.arrayOf(BonusPropType),
    videos: PropTypes.arrayOf(VideoPropType),
    rawLinks: PropTypes.array,
    links: PropTypes.arrayOf(LinkPropType),
});

export const actionTablePropType = PropTypes.shape({
    data: PropTypes.arrayOf(actionPropType),
    pagination: PropTypes.shape({
        totalCount: PropTypes.number,
    }),
    loading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
});
