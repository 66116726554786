import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    charsLeft: {
        marginTop: '5px',
        marginBottom: '10px',
        fontSize: '85%',
        color: '#737373',
    },
}));

export default useStyles;
