const styles = (theme) => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: '600',
        paddingBottom: '5px',
    },
    cardContainer: { marginBottom: '10px' },
});

export default styles;
