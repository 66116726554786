import React, { useEffect } from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import styles from './styles';
import CommonPropTypes from '../../../common/propTypes';
import RequestLoader from '../../../common/RequestLoader';
import TabbedSection from '../../../common/TabbedSection';
import StatCard from '../../../common/StatCard';
import {
    analyticsActivityRequested,
    analyticsUsersRequested,
    analyticsImpactRequested,
} from '../../../challenges/actions';
import {
    getAnalyticsActivityLoading,
    getTopActions,
    getAnalyticsActivityErrors,
    getTopUsers,
    getAnalyticsUsersErrors,
    getAnalyticsUsersLoading,
    getAnalyticsImpactLoading,
    getAnalyticsImpactErrors,
    getActivityTab,
    getUsersTab,
    getChallengeImpactStats,
} from '../../../challenges/selectors';
import { challengePhotosRequested } from '../../../analytics/photos/actions';
import {
    arePhotosLoading,
    getTopPhotos,
} from '../../../analytics/photos/selectors';
import TopPhotos from '../../../common/TopPhotos';
import IMAGES from '../../../constants/images';
import Leaderboard from '../../../common/Leaderboard';
import { getAppImages } from '../../../utils';
import { CHALLENGE_ANALYTICS_TOP_PHOTOS } from '../../../common/PhotoCard/constants';
import { LEADERBOARD_TYPE_TOP_USERS } from '../../../common/Leaderboard/constants';

const ChallengeStats = ({
    classes,
    communityId,
    challengeId,
    startDate,
    endDate,

    usersTab,
    activityTab,

    impactStats,
    impactTabErrors,
    impactTabRequest,
    isImpactTabLoading,

    topActions,
    topActionsRequest,
    topActionsErrors,
    isTopActionsLoading,

    topUsersRequest,
    topUsers,
    topUsersErrors,
    isTopUsersLoading,

    topPhotoRequest,
    topPhotos,
    isTopPhotosLoading,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        if (!startDate || !endDate) return;

        topActionsRequest({
            challengeId,
            communityId,
            startDate,
            endDate,
            summaryType: 'activity',
        });
        topUsersRequest({
            challengeId,
            communityId,
            startDate,
            endDate,
            summaryType: 'users',
        });
        impactTabRequest({
            communityId,
            challengeId,
            startDate,
            endDate,
        });
        topPhotoRequest({ communityId, challengeId });
    }, [
        communityId,
        challengeId,
        startDate,
        endDate,
        topActionsRequest,
        topUsersRequest,
        impactTabRequest,
        topPhotoRequest,
    ]);

    const renderStatCard = (tabArray) => (
        <Grid container spacing={2} justify="space-evenly">
            {tabArray.map(({ id, ...props }, i) => (
                <Grid key={id} item xs={12} sm={12} md>
                    <StatCard {...props} />
                </Grid>
            ))}
        </Grid>
    );

    const getDateQueryString = (startDate, endDate) => {
        const dates = {
            endDate: moment(endDate).format('YYYY-MM-DD'),
            startDate: moment(startDate).format('YYYY-MM-DD'),
            challengeId,
        };
        return `${queryString.stringify(dates)}`;
    };

    const onViewAllTopActionsClick = () => {
        history.push(
            `/analytics/top-actions?${getDateQueryString(startDate, endDate)}`,
        );
    };

    return (
        <Grid item container style={{ position: 'relative' }}>
            <Typography variant="h3" className={classes.title}>
                Stats
            </Typography>
            <TabbedSection
                tabs={{
                    [t('activity.descriptor')]: renderStatCard(activityTab),
                    [t('users.descriptor')]: renderStatCard(usersTab),
                    [t('impact.descriptor')]: renderStatCard(impactStats),
                }}
            />
            <Grid item container spacing={2}>
                <Leaderboard
                    items={topUsers.slice(0, 5)}
                    title={t('topUsers.title')}
                    tooltip={t('topUsers.description')}
                    leaderboardCardProps={{
                        defaultImageSrc: getAppImages().defaultProfileIcon,
                    }}
                    xs={12}
                    sm={12}
                    md={6}
                    leaderboardType={LEADERBOARD_TYPE_TOP_USERS}
                />
                <Leaderboard
                    items={topActions.slice(0, 5)}
                    title={t('topActions.title')}
                    tooltip={t('topActions.description')}
                    leaderboardCardProps={{
                        defaultImageSrc: IMAGES.defaultPinIcon,
                    }}
                    xs={12}
                    sm={12}
                    md={6}
                    viewAllBtn
                    onViewAllClick={onViewAllTopActionsClick}
                />
            </Grid>
            <TopPhotos
                photos={topPhotos}
                challengeId={challengeId}
                screen={CHALLENGE_ANALYTICS_TOP_PHOTOS}
            />
            <RequestLoader
                title={t('loading.stats')}
                isLoading={
                    isImpactTabLoading ||
                    isTopUsersLoading ||
                    isTopActionsLoading ||
                    isTopPhotosLoading
                }
            />
        </Grid>
    );
};

ChallengeStats.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    challengeId: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    usersTab: PropTypes.array.isRequired,
    activityTab: PropTypes.array.isRequired,
    impactStats: PropTypes.array.isRequired,
    impactTabRequest: PropTypes.func.isRequired,
    impactTabErrors: CommonPropTypes.errors.isRequired,
    isImpactTabLoading: PropTypes.bool.isRequired,
    topUsers: PropTypes.array.isRequired,
    topUsersRequest: PropTypes.func.isRequired,
    topUsersErrors: CommonPropTypes.errors.isRequired,
    isTopUsersLoading: PropTypes.bool.isRequired,
    topActions: PropTypes.array.isRequired,
    topActionsRequest: PropTypes.func.isRequired,
    topActionsErrors: CommonPropTypes.errors.isRequired,
    isTopActionsLoading: PropTypes.bool.isRequired,
    topPhotoRequest: PropTypes.func.isRequired,
    topPhotos: PropTypes.array.isRequired,
    isTopPhotosLoading: PropTypes.bool.isRequired,
};

ChallengeStats.defaultProps = {
    startDate: null,
    endDate: null,
};

const StyledLeftColumn = withStyles(styles)(ChallengeStats);

const mapStateToProps = (state) => ({
    impactStats: getChallengeImpactStats(state),
    impactTabErrors: getAnalyticsImpactErrors(state),
    isImpactTabLoading: getAnalyticsImpactLoading(state),
    activityTab: getActivityTab(state),
    topActions: getTopActions(state),
    topActionsErrors: getAnalyticsActivityErrors(state),
    isTopActionsLoading: getAnalyticsActivityLoading(state),
    usersTab: getUsersTab(state),
    topUsers: getTopUsers(state),
    topUsersErrors: getAnalyticsUsersErrors(state),
    isTopUsersLoading: getAnalyticsUsersLoading(state),
    topPhotos: getTopPhotos(state),
    isTopPhotosLoading: arePhotosLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    impactTabRequest: (args) => dispatch(analyticsImpactRequested(args)),
    topUsersRequest: (args) => dispatch(analyticsUsersRequested(args)),
    topActionsRequest: (args) => dispatch(analyticsActivityRequested(args)),
    topPhotoRequest: (args) => dispatch(challengePhotosRequested(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledLeftColumn);
