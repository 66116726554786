import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL } from './constants';

const CEMEX_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'Cemex Always In Action',
    shouldShowSignUp: false,
    clientId: '63867ee99d656099fd5d42ce',
    clientSecret:
        '067wdBM2MEhzne_xsJvXsHIAwydCrTkWBSrsBkZBKo9SMCrHAvDS-W3hUnVR7JCcjD',
    loginMethods: [LOGIN_METHOD_EMAIL],
    apiUri: process.env.REACT_APP_JBE_EU_API_URI,
    colors: {
        primary: '#0000B3',
        primaryDark: '#000087',
        primaryForeground: '#000087',
        secondary: '#53CC80',
        secondaryDark: '#38995C',
        accent: '#53CC80',
        accentDark: '#38995C',
        warn: '#0000B3',
        warnDark: '#0000B3',
        EditingBannerBackground: '#f26549',
        loginFooter: '#0000B3',
        impactTileColor: '#53CC80',
    },
    supportedLanguageCodes: ['en', 'es', 'de', 'fr', 'pl', 'cs', 'hr'],
    appStoreLink:
        'https://apps.apple.com/gb/app/cemex-always-in-action/id6444710005',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.cemex',
    appDescription:
        'Have fun and make a positive impact with CEMEX Always in Action. You can compete in challenges with CEMEX co-workers to build a better future and improve our planet. Earn points and build camaraderie by completing simple real-life actions as we work towards common goals.',
};
export default CEMEX_PROPERTIES;
