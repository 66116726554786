const styles = (theme) => ({
    mainWrapper: {
        position: 'relative',
        backgroundColor: 'red',
    },
    shareButton: {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        border: '2px',
        fontWeight: 600,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            color: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.white.main,
        },
        zIndex: 200,
        position: 'absolute',
        right: '15px',
        top: '15px',
    },
    shareButtonIcon: {
        marginRight: '5px',
    },
    shareChallengeWrapper: {
        borderRadius: '3px',
        fontFamily: `'effra',sans-serif`,
        zIndex: 100,
        minHeight: '200px',
        background: `${theme.palette.white.main} 0% 0% no-repeat padding-box`,
        boxShadow: '3px 5px 10px #000000',
        border: '1px solid #DBDBDB',
        position: 'absolute',
        top: '0px',
        right: 0,
        width: '300px',
        transition: '0.5s',
    },
    shareChallengeInner: {
        minHeight: '200px',
        padding: '70px 15px 15px 15px',
        fontFamily: 'inherit',
        zIndex: 99,
    },
    hide: {
        visibility: 'hidden',
    },
    textMutedLink: {
        textDecoration: 'underline',
        color: theme.custom.textDarkDisabledColor,
        lineHeight: '40px',
    },
    shareActionContainer: {
        position: 'relative',
    },
    title: {
        fontSize: '24px',
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    shareOptionIcon: {
        height: '24px',
        width: '24px',
        lineHeight: '24px',
        margin: '5px 10px 5px 0',
        color: theme.palette.primary.main,
    },
    shareOption: {
        padding: '5px 0',
        borderTop: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.custom.bgAccentColor,
    },
});

export default styles;
