import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Grid,
    MenuItem,
    Select,
    Typography,
    withStyles,
} from '@material-ui/core';
import {
    getActiveCommunityId,
    getActiveCommunityName,
} from '../../user/selectors';
import styles from './styles';
import { SCROLLER_BATCH_SIZE } from '../../common/InfiniteScroller/constants';
import { getAppImages } from '../../utils';
import { trackEvent } from '../../utils/analytics';
import { activitiesLoading, getActivities } from '../../activities/selectors';
import { activityDataRequested } from '../../activities/actions';
import InfoCardHeader from '../../common/InfoCardHeader';
import { INFO_CARD_USER_ACTIVITY } from '../../common/InfoCard/constants';
import {
    ACTIVITIES_FEED_TYPE_FOLLOWING,
    ACTIVITIES_FEED_TYPE_TRENDING,
    ACTIVITY_FEED_SUPPORTED_TYPES,
    DEFAULT_ACTIVITY_FEED_TYPE,
} from '../../activities/constants';
import ActivityCardScroller from '../../common/ActivityCard/ActivityCardScroller';

const ActivitiesScreen = ({
    classes,
    communityId,
    communityName,
    areActivitiesLoading,
    fetchActivityData,
    getActivitiesForType,
}) => {
    const { t } = useTranslation();
    const [activityType, setActivityType] = useState('');
    const [activityTypes, setActivityTypes] = useState([]);

    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
        trackEvent(`activity_view_${activityType}`);
    };

    useEffect(() => {
        setActivityTypes(
            ACTIVITY_FEED_SUPPORTED_TYPES.map((type) => {
                const label = t(`activitiesPage.activityTypes.${type}`, {
                    communityName,
                });
                return {
                    label,
                    value: type,
                };
            }),
        );
        if (!activityType) {
            // User hasn't yet selected a type filter, use the default instead
            setActivityType(DEFAULT_ACTIVITY_FEED_TYPE);
        }

        const options = {
            communityId: communityId,
            limit: SCROLLER_BATCH_SIZE,
            skip: 0,
        };
        fetchActivityData(activityType, options);
    }, [t, communityId, communityName, fetchActivityData, activityType]);

    const items = useMemo(() => getActivitiesForType(activityType), [
        activityType,
        getActivitiesForType,
    ]);

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <InfoCardHeader
                    infoCardId={INFO_CARD_USER_ACTIVITY}
                    pageTitle={t('activitiesPage.title')}
                    image={getAppImages().welcomeActivity}
                >
                    <Typography variant="h2" className={classes.bannerTitle}>
                        {t('activitiesPage.bannerTitle')}
                    </Typography>
                    <Typography className={classes.bannerBody}>
                        {t('activitiesPage.bannerBody')}
                        <br />
                    </Typography>
                </InfoCardHeader>
            </Grid>
            <Grid
                item
                container
                xs={12}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid item xs={6}></Grid>
                <Grid item xs={4}>
                    {activityTypes.length > 1 ? (
                        <Select
                            labelId="demo-simple-select-disabled-label"
                            id="demo-simple-select-disabled"
                            value={activityType}
                            onChange={handleTypeChange}
                            fullWidth
                            className={classes.typeSelector}
                        >
                            {activityTypes.map((type, index) => (
                                <MenuItem
                                    key={index}
                                    value={type.value}
                                    className={classes.menuItem}
                                >
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : null}
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="row"
                justifyContent="center"
                className={classes.cardContainer}
            >
                <Grid item style={{ maxWidth: '100%' }}>
                    <ActivityCardScroller
                        loading={areActivitiesLoading}
                        loadFunc={(options) => {
                            fetchActivityData(activityType, {
                                communityId: communityId,
                                ...options,
                            });
                        }}
                        items={items}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    communityName: getActiveCommunityName(state),
    areActivitiesLoading:
        activitiesLoading(state, ACTIVITIES_FEED_TYPE_FOLLOWING) ||
        activitiesLoading(state, ACTIVITIES_FEED_TYPE_TRENDING),
    getActivitiesForType: (type) => getActivities(state, type),
});

const mapDispatchToProps = (dispatch) => ({
    fetchActivityData: (activitiesType, options) =>
        dispatch(activityDataRequested(activitiesType, options)),
});

ActivitiesScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    communityName: PropTypes.string.isRequired,
    areActivitiesLoading: PropTypes.bool.isRequired,
    fetchActivityData: PropTypes.func.isRequired,
    // Given a type, provide an array of the activities for the specified type of feed
    getActivitiesForType: PropTypes.func.isRequired,
};

ActivitiesScreen.defaultProps = {};

const StyledActivitiesScreen = withStyles(styles)(ActivitiesScreen);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledActivitiesScreen);
