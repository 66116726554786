import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import reduxPersistStorage from 'redux-persist/lib/storage';
import initReducer from '../init/reducer';
import authReducer from '../auth/reducer';
import userReducer from '../user/reducer';
import viewActionReducer from '../actions/reducer';
import analyticsPhotoReducer from '../analytics/photos/reducer';
import analyticsReducer from '../analytics/reducer';
import viewChallengeReducer from '../challenges/reducer';
import challengesReducer from '../screens/ChallengesScreen/reducer';
import notificationsReducer from '../notifications/reducer';
import communityReducer from '../community/reducer';
import imageReducer from '../image/reducer';
import flagsReducer from '../flags/reducer';
import updateChalllengeReducer from '../screens/UpdateChallengeScreen/reducer';
import themesReducer from '../communityThemes/reducer';
import settingsReducer from '../screens/SettingsScreen/reducer';
import deepLinksReducer from '../deepLinks/reducer';
import inviteUsersReducer from '../store/inviteUsers/reducer';
import activitiesReducer from '../activities/reducer';
import subscriptionReducer from '../subscriptions/reducer';

const authPersistConfig = {
    key: 'auth',
    storage: reduxPersistStorage,
    whitelist: ['token', 'onBoarding', 'sso'],
};

const subscriptionPersistConfig = {
    key: 'subscription',
    storage: reduxPersistStorage,
    whitelist: ['stripePlanId'],
};

// Persist challenge creation so that refreshing the /get-started/invite page doesn't break it
const viewChallengePersistConfig = {
    key: 'viewChallenge',
    storage: reduxPersistStorage,
    whitelist: ['creation'],
};

const userPersistConfig = {
    key: 'user',
    storage: reduxPersistStorage,
    whitelist: [
        'user',
        'dismissedInfoCardIds',
        'userViewActive',
        'activeCommunity',
    ],
};

export default function createRootReducer(history) {
    return combineReducers({
        router: connectRouter(history),
        init: initReducer,
        deepLinks: deepLinksReducer,
        auth: persistReducer(authPersistConfig, authReducer),
        user: persistReducer(userPersistConfig, userReducer),
        viewChallenge: persistReducer(
            viewChallengePersistConfig,
            viewChallengeReducer,
        ),
        viewAction: viewActionReducer,
        analyticsPhoto: analyticsPhotoReducer,
        analytics: analyticsReducer,
        activities: activitiesReducer,
        challenges: challengesReducer,
        flags: flagsReducer,
        notifications: notificationsReducer,
        community: communityReducer,
        themes: themesReducer,
        image: imageReducer,
        updateChallenge: updateChalllengeReducer,
        settings: settingsReducer,
        inviteUsers: inviteUsersReducer,
        subscription: persistReducer(
            subscriptionPersistConfig,
            subscriptionReducer,
        ),
    });
}
