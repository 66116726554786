export const NOTIFICATIONS_REQUESTED = 'NOTIFICATIONS_REQUESTED';
export const NOTIFICATIONS_SUCCEEDED = 'NOTIFICATIONS_SUCCEEDED';
export const NOTIFICATIONS_FAILED = 'NOTIFICATIONS_FAILED';

export const NOTIFICATION_REQUESTED = 'NOTIFICATION_REQUESTED';
export const NOTIFICATION_SUCCEEDED = 'NOTIFICATION_REQUEST_SUCCEEDED';

export const CREATE_NOTIFICATION_REQUESTED = 'CREATE_NOTIFICATION_REQUESTED';
export const DELETE_NOTIFICATION_REQUESTED = 'DELETE_NOTIFICATION_REQUESTED';
export const UPDATE_NOTIFICATION_REQUESTED = 'UPDATE_NOTIFICATION_REQUESTED';
export const UPDATE_NOTIFICATION_SUCCEEDED = 'UPDATE_NOTIFICATION_SUCCEEDED';
export const UPDATE_NOTIFICATION_FAILED = 'UPDATE_NOTIFICATION_FAILED';

export const CLEAR_NOTIFICATION_ERRORS = 'CLEAR_NOTIFICATION_ERRORS';

export const OPEN_CREATE_NOTIFICATION = 'OPEN_CREATE_NOTIFICATION';
export const CLEAR_CREATE_NOTIFICATION = 'CLEAR_CREATE_NOTIFICATION';

export const USER_NOTIFICATIONS_REQUESTED = 'USER_NOTIFICATIONS_REQUESTED';
export const USER_NOTIFICATIONS_REQUEST_SUCCEEDED =
    'USER_NOTIFICATIONS_REQUEST_SUCCEEDED';
export const USER_NOTIFICATIONS_REQUEST_FAILED =
    'USER_NOTIFICATIONS_REQUEST_FAILED';

export const MARK_USER_NOTIFICATIONS_REQUESTED =
    'MARK_USER_NOTIFICATIONS_REQUESTED';
export const MARK_USER_NOTIFICATIONS_REQUEST_SUCCEEDED =
    'MARK_USER_NOTIFICATIONS_REQUEST_SUCCEEDED';
export const MARK_USER_NOTIFICATIONS_REQUEST_FAILED =
    'MARK_USER_NOTIFICATIONS_REQUEST_FAILED';
export const SET_NOTIFICATION_COUNTS = 'SET_NOTIFICATION_COUNTS';

export const markUserNotificationsRequested = () => ({
    type: MARK_USER_NOTIFICATIONS_REQUESTED,
});

export const markUserNotificationsRequestSucceeded = () => ({
    type: MARK_USER_NOTIFICATIONS_REQUEST_SUCCEEDED,
});

export const markUserNotificationsRequestFailed = (response) => ({
    type: MARK_USER_NOTIFICATIONS_REQUEST_FAILED,
    payload: response,
});

export const userNotificationsRequested = (options) => ({
    type: USER_NOTIFICATIONS_REQUESTED,
    payload: options,
});

export const userNotificationsRequestSucceeded = (notifications, skip) => ({
    type: USER_NOTIFICATIONS_REQUEST_SUCCEEDED,
    payload: {
        notifications,
        skip,
    },
});

export const userNotificationsRequestFailed = (response) => ({
    type: USER_NOTIFICATIONS_REQUEST_FAILED,
    payload: response,
});

export const notificationsRequest = (communityId, group, options) => ({
    type: NOTIFICATIONS_REQUESTED,
    payload: {
        communityId,
        group,
        options,
    },
});

export const notificationsRequestSucceeded = (response) => ({
    type: NOTIFICATIONS_SUCCEEDED,
    payload: response,
});

export const notificationsRequestFailed = (response) => ({
    type: NOTIFICATIONS_FAILED,
    payload: response,
});

export const deleteNotificationRequest = (
    communityId,
    notificationId,
    challengeId,
    options,
) => ({
    type: DELETE_NOTIFICATION_REQUESTED,
    payload: {
        communityId,
        notificationId,
        challengeId,
        options,
    },
});

export const notificationRequest = (response) => ({
    type: NOTIFICATION_REQUESTED,
    payload: response,
});

export const notificationRequestSucceeded = (response) => ({
    type: NOTIFICATION_SUCCEEDED,
    payload: response,
});

export const createNotificationRequest = (response) => ({
    type: CREATE_NOTIFICATION_REQUESTED,
    payload: response,
});

export const updateNotificationRequest = (response) => ({
    type: UPDATE_NOTIFICATION_REQUESTED,
    payload: response,
});

export const updateNotificationSucceeded = (response) => ({
    type: UPDATE_NOTIFICATION_SUCCEEDED,
    payload: response,
});

export const updateNotificationFailed = (errors, errorStatus) => ({
    type: UPDATE_NOTIFICATION_FAILED,
    payload: {
        errors,
        errorStatus,
    },
});

export const clearAllErrors = () => ({
    type: CLEAR_NOTIFICATION_ERRORS,
});

export const openCreateNotification = (notification) => ({
    type: OPEN_CREATE_NOTIFICATION,
    payload: {
        notification,
    },
});

export const clearCreateNotification = () => ({
    type: CLEAR_CREATE_NOTIFICATION,
});

export const setNotificationCounts = (counts) => ({
    type: SET_NOTIFICATION_COUNTS,
    payload: {
        counts,
    },
});
