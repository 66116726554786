import moment from 'moment';
import 'moment/min/locales.min';
import i18n from 'i18next';
export const noop = () => {};

/**
 * Сalculate table pagination range.
 *
 * @param {number} total number of pages
 * @param {number} current page selelected
 * @param {number} display, number of pages you want to show in the panel
 * @return {start, end}, page range
 */
export const calculateRangePagination = ({ total, current, display }) => {
    let end = total;
    let start = 1;
    if (display < end) {
        let beforeNumber = Math.round(display / 2 - 0.5);
        const afterNumber = beforeNumber;
        if (display % 2 === 0) {
            beforeNumber -= 1;
        }

        if (current <= beforeNumber + 1) {
            end = display;
        } else if (current >= total - afterNumber) {
            start = total - display + 1;
        } else {
            start = current - beforeNumber;
            end = current + afterNumber;
        }
    }

    return { start, end };
};

/**
 * Determining the initial state of the pagination panel for a table.
 *
 * @param {number} total number of pages
 * @param {number} current page selelected
 * @param {number} display, number of pages you want to show in the panel
 * @return {total, current, display}
 */
export const initStatePagination = (props) => {
    let { total, current, display } = props;
    total = total > 0 ? total : 1;
    current = current > 0 ? current : 1;
    display = display > 0 ? display : 1;
    current = current < total ? current : total;
    display = display < total ? display : total;
    return { total, current, display };
};

/**
 *
 * @param {array} permissions array of permissions
 * @param {string} parent filter key permissions group
 * @param {string} parentInternalName name of permission on title
 * @return {[perm, parentPermission]}
 */
export const getPermissionsBlock = (
    permissions,
    parent,
    parentInternalName,
) => {
    let parentPermission;
    const perms = permissions.filter((permission) => {
        if (permission.internalName === parentInternalName) {
            parentPermission = permission;
            return false;
        }

        return permission.parent === parent;
    });

    return [perms, parentPermission];
};

/**
 * Append a new set of data to the set that we currently have
 * @param currentData The data we currently have for the list
 * @param newData the new data returned by the API
 * @param skip the skip value we used for pagination
 * @returns {*[]} The concatenated data
 */
export const mergePaginatedResponse = (
    currentData = [],
    newData = [],
    skip = 0,
) => {
    if (!currentData) {
        return newData;
    }
    const slicedCurrentData = currentData.slice(0, skip || 0);
    if (!newData) {
        return slicedCurrentData;
    }
    return slicedCurrentData.concat(newData);
};

export const getRelativeTime = (start) => {
    return moment(start)
        .locale(i18n.language)
        .fromNow(true);
};

export const mergeItemById = (oldArray, newItem) => {
    return oldArray?.reduce((newArray, item) => {
        if (item.id === newItem.id) {
            newArray.push(newItem);
        } else {
            newArray.push(item);
        }
        return newArray;
    }, []);
};
