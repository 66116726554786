import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';

import ActivityCardHeader from './ActivityCardHeader';
import ActivityCardBadge from './ActivityCardBadge';
import ActivityCardPhoto from './ActivityCardPhoto';
import ActivityCardLikes from './ActivityCardLikes';
import ActivityCardComment from './ActivityCardComment';
import ActivityOptionsPopper from './ActivityOptionsPopper';
import useStyles from './styles';
import { AddComment, Favorite, FavoriteBorder } from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';
import {
    activityLikeRequested,
    activityShareURLRequested,
    addCommentRequested,
} from '../../activities/actions';
import { noop } from '../../utils';
import { activityReportRequested } from '../../flags/actions';

import {
    getAddCommentLoadingActivityId,
    getLikeLoadingActivityId,
    getShareURLLoadingActivityId,
} from '../../activities/selectors';
import { getUserId } from '../../user/selectors';
import SnackbarAlert from '../SnackbarAlert';
import { getReportPostContentId } from '../../flags/selectors';
import {
    REPORT_TYPE_CAPTION,
    REPORT_TYPE_PHOTO,
    REPORT_TYPE_POST,
    REPORT_TYPE_COMMENT,
} from './ActivityOptionsPopper/constants';

const ActivityCard = ({
    activity,
    addComment,
    getShareURL,
    likePost,
    likeLoadingActivityId,
    addCommentLoadingActivityId,
    shareURLLoadingActivityId,
    userId,
    submitReport,
    activityReportLoadingId,
    ...props
}) => {
    const { t } = useTranslation();

    const [comment, setComment] = useState('');
    const [showComment, setShowComment] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [shareRequested, setShareRequested] = useState(false);

    const comments = activity.comments || [];

    const isLiked = useMemo(
        () => activity.likes?.some((like) => like.user?.id === userId),
        [activity.likes, userId],
    );

    const likeLoading = useMemo(() => likeLoadingActivityId === activity.id, [
        likeLoadingActivityId,
        activity.id,
    ]);

    const shareURLLoading = useMemo(
        () => shareURLLoadingActivityId === activity.id,
        [shareURLLoadingActivityId, activity.id],
    );

    const addCommentLoading = useMemo(
        () => addCommentLoadingActivityId === activity.id,
        [addCommentLoadingActivityId, activity.id],
    );

    const addReportLoading = useMemo(
        () => activityReportLoadingId === activity.id,
        [activityReportLoadingId, activity.id],
    );

    const classes = useStyles();

    const submitComment = () => {
        if (comment?.length > 0) {
            addComment(activity.id, comment);
        }
    };

    useEffect(() => {
        setShowComment(false);
    }, [comments.length]);

    useEffect(() => {
        if (activity.shareURL && shareRequested) {
            copy(activity.shareURL);
            setSnackbarMessage(t('sharing.copied'));
            setShareRequested(false);
        }
    }, [activity.shareURL, shareRequested, t]);

    const openComments = () => {
        setShowComment(true);
    };

    const onShare = () => {
        if (!activity.shareURL) {
            getShareURL(activity.id);
            setShareRequested(true);
        } else {
            copy(activity.shareURL);
            setSnackbarMessage(t('sharing.copied'));
        }
    };

    const onLike = () => {
        likePost(activity.id, !isLiked);
    };

    const getReportingOptionsForActivity = () => {
        const reportTypes = [REPORT_TYPE_POST];
        // if the current user is the creator, they shouldn't be able to report. deleting and modifying needed instead (FEAT JB-1145)

        // If an image is contained, in can be reported
        if (activity.photoUrl) {
            reportTypes.push(REPORT_TYPE_PHOTO);
        }
        // Otherwise, only the post (and it's caption) can be reported
        return reportTypes.map((reportType) => {
            return {
                id: reportType,
                label: t(`flag.${reportType}`),
            };
        });
    };

    const featuredActivity =
        activity?.badges?.[0] || activity?.masteries?.[0] || activity;
    const otherActivities = activity?.badges
        ?.filter((badge) => badge.id !== featuredActivity.id)
        .concat(
            activity?.masteries?.filter(
                (mastery) => mastery.id !== featuredActivity.id,
            ) || Array(activity),
        );

    return (
        <Grid container item className={classes.container} {...props}>
            <ActivityCardHeader
                userImage={activity.userImage}
                userName={activity.userName}
                userId={activity.userId}
                activityCreated={activity.activityCreated}
                activityImage={featuredActivity.activityImage}
                activityName={featuredActivity.activityName}
                contentType={activity.contentType}
                contentId={activity.contentId}
            />
            {otherActivities.length > 0 && (
                <ActivityCardBadge activities={otherActivities} />
            )}
            <ActivityCardPhoto photoUrl={activity.photoUrl} />
            <ActivityCardLikes likes={activity.numLikes} />
            <Grid container className={classes.actionsContainer}>
                <Grid
                    container
                    item
                    className={classes.actionContainer}
                    alignItems="center"
                    justify="center"
                    onClick={onLike}
                >
                    {likeLoading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <>
                            {isLiked ? (
                                <Favorite className={classes.icon} />
                            ) : (
                                <FavoriteBorder className={classes.icon} />
                            )}
                            <Typography className={classes.actionText}>
                                {!isLiked
                                    ? t('activitiesPage.like')
                                    : t('activitiesPage.unlike')}
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid
                    container
                    item
                    justify="center"
                    alignItems="center"
                    className={classes.actionContainer}
                    onClick={openComments}
                >
                    <AddComment className={classes.icon} />
                    <Typography className={classes.actionText}>
                        {t('activitiesPage.comment')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    className={classes.actionContainer}
                    alignItems="center"
                    justify="center"
                    onClick={onShare}
                >
                    {shareURLLoading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <>
                            <ShareIcon className={classes.icon} />
                            <Typography className={classes.actionText}>
                                {t('share')}
                            </Typography>
                        </>
                    )}
                </Grid>
                <ActivityOptionsPopper
                    onSelect={(content, _) => {
                        submitReport(activity.id);
                    }}
                    isLoading={addReportLoading}
                    content={activity}
                    options={getReportingOptionsForActivity()}
                />
            </Grid>
            {showComment && (
                <Grid
                    container
                    item
                    className={classes.commentContainer}
                    direction="row"
                    alignItems="center"
                    justify="center"
                >
                    <TextField
                        autoFocus
                        placeholder={t('activitiesPage.addComment')}
                        minRows={1}
                        multiline
                        className={classes.commentInput}
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                        variant="outlined"
                        InputProps={{
                            style: { paddingTop: '5px', paddingBottom: '5px' },
                        }}
                        margin="none"
                    />
                    <Grid container justify="center" style={{ flex: 1 }}>
                        {addCommentLoading ? (
                            <CircularProgress size={22} />
                        ) : (
                            <Button
                                className={classes.commentButton}
                                disabled={!comment}
                                onClick={submitComment}
                            >
                                {t('post')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            )}
            {activity.comments &&
                activity.comments.map((comment, index) => (
                    <ActivityCardComment
                        key={index}
                        commentUser={comment.commentUser}
                        commentText={comment.commentText}
                    >
                        <Grid item className={classes.commentPopper}>
                            <ActivityOptionsPopper
                                onSelect={() => {
                                    submitReport(activity.id, comment.id);
                                }}
                                isLoading={addReportLoading}
                                content={activity}
                                options={
                                    comment.isCaption
                                        ? [
                                              {
                                                  id: REPORT_TYPE_CAPTION,
                                                  label: t(
                                                      `flag.${REPORT_TYPE_CAPTION}`,
                                                  ),
                                              },
                                          ]
                                        : [
                                              {
                                                  id: REPORT_TYPE_COMMENT,
                                                  label: t(
                                                      `flag.${REPORT_TYPE_COMMENT}`,
                                                  ),
                                              },
                                          ]
                                }
                            />
                        </Grid>
                    </ActivityCardComment>
                ))}
            <SnackbarAlert
                messages={snackbarMessage}
                severity="success"
                clearMessages={() => setSnackbarMessage('')}
            />
        </Grid>
    );
};

ActivityCard.propTypes = {
    activity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        contentId: PropTypes.string,
        contentType: PropTypes.string,
        shareURL: PropTypes.string,
        userName: PropTypes.string.isRequired,
        userImage: PropTypes.string,
        activityName: PropTypes.string.isRequired,
        activityImage: PropTypes.string,
        activityCreated: PropTypes.string.isRequired,
        masteries: PropTypes.arrayOf(
            PropTypes.shape({
                activityName: PropTypes.string,
                acxtivityImage: PropTypes.string,
                id: PropTypes.string,
            }),
        ).isRequired,
        badges: PropTypes.arrayOf(
            PropTypes.shape({
                activityName: PropTypes.string,
                acxtivityImage: PropTypes.string,
                id: PropTypes.string,
            }),
        ).isRequired,
        photoUrl: PropTypes.string,
        likes: PropTypes.object.isRequired,
        numLikes: PropTypes.number,
        comments: PropTypes.arrayOf(
            PropTypes.shape({
                commentUser: PropTypes.string,
                commentText: PropTypes.string,
            }),
        ),
    }).isRequired,
    likeLoadingActivityId: PropTypes.string,
    shareURLLoadingActivityId: PropTypes.string,
    addCommentLoadingActivityId: PropTypes.string,
    activityReportLoadingId: PropTypes.string,
    addComment: PropTypes.func,
    likePost: PropTypes.func,
    getShareURL: PropTypes.func,
    userId: PropTypes.string,
    submitReport: PropTypes.func,
};

ActivityCard.defaultProps = {
    likeLoadingActivityId: '',
    shareURLLoadingActivityId: '',
    addCommentLoadingActivityId: '',
    activityReportLoadingId: '',
    addComment: noop,
    likePost: noop,
    getShareURL: noop,
    userId: '',
    submitReport: noop,
};

const mapStateToProps = (state) => ({
    likeLoadingActivityId: getLikeLoadingActivityId(state),
    shareURLLoadingActivityId: getShareURLLoadingActivityId(state),
    addCommentLoadingActivityId: getAddCommentLoadingActivityId(state),
    activityReportLoadingId: getReportPostContentId(state),
    userId: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
    getShareURL: (activityId) =>
        dispatch(activityShareURLRequested(activityId)),
    submitReport: (activityId, commentId) =>
        dispatch(activityReportRequested(activityId, commentId)),
    likePost: (activityId, like) =>
        dispatch(activityLikeRequested(activityId, like)),
    addComment: (activityId, comment) =>
        dispatch(addCommentRequested(activityId, comment)),
});

export const UnconnectedActivityCard = ActivityCard;
export default connect(mapStateToProps, mapDispatchToProps)(ActivityCard);
