import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    label: {
        marginTop: '26px',
        marginBottom: '10px',
        fontSize: '20px',
        fontWeight: '700',
        color: theme.palette.primary.main,
    },

    title: {
        textAlign: 'center',
        fontSize: 26,
        fontWeight: 'bold',
        backgroundColor: 'white',
    },
    modal: {
        backgroundColor: 'white',
    },
    description: {
        marginBottom: 30,
    },
    searchContainer: {
        marginBottom: 15,
    },
    addButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
    },
    inputRoot: {
        width: '100%',
        borderRadius: '8px',
        border: '1px solid transparent',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '18px',
        '&:before, &:after': {
            display: 'none',
        },
        height: 46,
        justifyContent: 'center',
        paddingLeft: 10,
    },
    select: {
        '&:focus': {
            backgroundColor: theme.custom.inputBackground,
        },
    },
    selectItem: {
        paddingLeft: 10,
    },
    categoryIcon: {
        width: '24px',
        marginRight: 5,
        height: 'auto',
        objectFit: 'contain',
    },
}));

export default useStyles;
