const styles = (theme) => ({
    container: {
        backgroundColor: theme.custom.bgLightAccentColor,
        color: theme.custom.bgDarkColor,
        borderTop: `1px solid ${theme.custom.bgSeparator}`,
    },
    icon: {
        height: '54px',
        padding: '16px 0',
        margin: '0 28px 0 38px',
    },
    textBlock: {
        padding: '16px 0',
        border: '0 !important',
    },
    commentUser: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 600,
    },
    commentText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
});

export default styles;
