import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    container: {
        paddingTop: '50px',
        height: '100vh',
        backgroundImage: `linear-gradient(${theme.palette.accent.main}, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        color: theme.palette.common.white,
    },
    wrapper: {
        padding: '0 5px',
    },
    logo: {
        margin: '0 auto 28px',
        maxWidth: '50%',
    },
    link: {
        marginBottom: '10px',
        color: theme.palette.common.white,
        textDecoration: 'none',

        '&:hover': {
            color: 'rgba(0, 0, 0, 0.5)',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },
    title: {
        margin: '20px 0 10px',
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    text: {
        marginBottom: '10px',
        textAlign: 'center',
    },
    button: {
        margin: '10px 0 15px',
        textDecoration: 'none',
    },
    loader: {
        maxWidth: '80px',
        height: 'auto',
        padding: '0px',
        backgroundColor: 'rgba(0,0,0,0.5)',
        border: '2px',
        borderRadius: '5px',
    },
    form: {
        width: '100%',
    },
    footer: {
        marginTop: '100px',
        backgroundColor: 'transparent',
    },
    returnToLoginButton: {
        marginTop: theme.spacing(4),
    },
}));
