import React, { useMemo } from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './styles';

import IcomoonIcon from '../../../../common/IcomoonIcon';
import theme from '../../../../theme';

const IconFormInput = ({ icon, ...props }) => {
    const classes = useStyles();
    const inputProps = useMemo(
        () => ({
            classes: {
                root: classes.inputRoot,
                input: classes.input,
            },
        }),
        [classes],
    );

    return (
        <Grid
            container
            item
            direction="row"
            wrap="nowrap"
            className={classes.root}
        >
            <Grid container item>
                <TextField InputProps={inputProps} {...props} />
            </Grid>
            <Grid item className={classes.icon}>
                <IcomoonIcon
                    icon={icon}
                    color={theme.palette.common.white}
                    size={24}
                />
            </Grid>
        </Grid>
    );
};

IconFormInput.propTypes = {
    icon: PropTypes.string,
};

IconFormInput.defaultProps = {
    icon: null,
};

export default IconFormInput;
