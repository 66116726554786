export const IMAGE_UPLOAD_REQUESTED = 'IMAGE_UPLOAD_REQUESTED';
export const IMAGE_UPLOAD_REQUEST_SUCCEEDED = 'IMAGE_UPLOAD_REQUEST_SUCCEEDED';
export const IMAGE_UPLOAD_REQUEST_FAILED = 'IMAGE_UPLOAD_REQUEST_FAILED';
export const CLEAR_UPLOADED_IMAGE = 'CLEAR_UPLOADED_IMAGE';
export const IMAGE_UPLOAD_CLEAR_ERRORS = 'IMAGE_UPLOAD_CLEAR_ERRORS';

export const imageUploadRequested = (request) => ({
    type: IMAGE_UPLOAD_REQUESTED,
    payload: request,
});

export const imageUploadRequestSucceeded = (response) => ({
    type: IMAGE_UPLOAD_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const imageUploadRequestFailed = (errors) => ({
    type: IMAGE_UPLOAD_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearUploadedImage = () => ({
    type: CLEAR_UPLOADED_IMAGE,
});

export const imageUploadClearErrors = () => ({
    type: IMAGE_UPLOAD_CLEAR_ERRORS,
});
