import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { noop } from '../../../utils';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import useStyles from './styles';

import ConfirmModal from '../../../common/ConfirmModal';
import {
    BUTTON_ACCENT_COLOR,
    BUTTON_DEFAULT_COLOR,
} from '../../../common/Button/constants';
import ModalTitle from '../../../common/ModalTitle';
import { Grid } from '@material-ui/core';
import Photo from '../../../common/Photo';
import FieldLabel from '../../../common/FieldLabel';
import FormInput from '../../../common/Form/FormInput';
import Textarea from '../../../common/Textarea';
import { getActiveCommunityId } from '../../../user/selectors';
import {
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../../image/selectors';
import { imageUploadRequested } from '../../../image/actions';
import CommonPropTypes from '../../../common/propTypes';

const UpdateTeam = ({
    communityId,
    isOpen,
    setOpen,
    name: initialName,
    headline: initialHeadline,
    photo: initialPhoto,
    requestImageUpload,
    isImageUploading,
    imageUploadErrors,
    imageUploadResponse,
    onClose,
    onCancel,
    onConfirm,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [imageData, setImageData] = useState(null);
    const [imageSizes, setImageSizes] = useState(null);

    useEffect(() => {
        if (imageData != null) {
            requestImageUpload({ communityId, file: imageData });
        }
    }, [imageData, requestImageUpload, communityId]);

    useEffect(() => {
        if (
            !isImageUploading &&
            imageUploadErrors == null &&
            imageUploadResponse != null
        ) {
            setImageSizes(imageUploadResponse);
        }
    }, [
        isImageUploading,
        imageUploadErrors,
        imageUploadResponse,
        setImageSizes,
    ]);

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
    }, [onClose, setOpen]);

    const handleCancel = useCallback(() => {
        onCancel();
        setOpen(false);
    }, [onCancel, setOpen]);

    const handleConfirm = useCallback(
        (formikProps) => {
            const team = {
                name: formikProps.values.name,
                headline: formikProps.values.headline,
                image: imageSizes?.[0]?.url || initialPhoto,
            };
            if (imageSizes !== null) {
                team.updateImage = { sizes: imageSizes };
            }
            onConfirm(team);
        },
        [onConfirm, imageSizes, initialPhoto],
    );

    const initialValues = useMemo(
        () => ({
            name: initialName,
            headline: initialHeadline,
        }),
        [initialName, initialHeadline],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string().required(
                    t('challengeTeam.validationRequiredName'),
                ),
            }),
        [t],
    );

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={noop}
        >
            {(formikProps) => (
                <ConfirmModal
                    isOpen={isOpen}
                    title={
                        <ModalTitle
                            text={t('challengeTeam.edit')}
                            tooltipText={t('viewDocs')}
                            tooltipPlacement="right"
                        />
                    }
                    confirmBtnText={t('OK')}
                    confirmBtnType={BUTTON_ACCENT_COLOR}
                    confirmBtnDisabled={!formikProps.isValid}
                    cancelBtnText={t('cancel')}
                    cancelBtnType={BUTTON_DEFAULT_COLOR}
                    onClose={handleClose}
                    onCancel={handleCancel}
                    onConfirm={() => handleConfirm(formikProps)}
                >
                    <Form className={classes.form}>
                        <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            spacing={1}
                        >
                            <Grid container item xs={3} direction="column">
                                <Photo
                                    title={t('challengeTeam.photoFieldTitle')}
                                    photo={initialPhoto}
                                    setCroppedImageData={setImageData}
                                />
                            </Grid>
                            <Grid container item xs={9} direction="column">
                                <Grid item>
                                    <FieldLabel
                                        text={t('challengeTeam.nameFieldTitle')}
                                        tooltipText={t(
                                            'challengeTeam.nameFieldToolTip',
                                        )}
                                        tooltipPlacement="right"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormInput noLeftMargin name="name" />
                                </Grid>
                                <Grid item>
                                    <FieldLabel
                                        text={t('profileCreate.headline')}
                                        tooltipText={t(
                                            'challengeTeam.headlineFieldToolTip',
                                        )}
                                        tooltipPlacement="right"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormInput
                                        as={Textarea}
                                        name="headline"
                                        rowsMin={3}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </ConfirmModal>
            )}
        </Formik>
    );
};

UpdateTeam.propTypes = {
    communityId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    requestImageUpload: PropTypes.func.isRequired,
    isImageUploading: PropTypes.bool.isRequired,
    imageUploadErrors: CommonPropTypes.errors,
    imageUploadResponse: PropTypes.object,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
};

UpdateTeam.defaultProps = {
    imageUploadErrors: null,
    imageUploadResponse: null,
    onClose: noop,
    onCancel: noop,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    imageUploading: isImageUploadRequestPending(state),
    imageUploadErrors: getImageUploadErrors(state),
    imageUploadResponse: getImageUploadResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestImageUpload: (request) => dispatch(imageUploadRequested(request)),
});

const ConnectedUpdateTeam = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateTeam);

export default ConnectedUpdateTeam;
