import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from './styles';
import { noop } from '../../utils';
import InformationSection from './InformationSection';
import BillingSection from './BillingSection';

const PaymentReceipt = ({
    classes,
    receiptNumber,
    customerName,
    customerAddress,
    card,
    nextBillingDate,
    subscriptionName,
    handlePrint,
    handleDownload,
    billingProps,
}) => {
    const { t } = useTranslation();

    return (
        <Grid className={classes.container} container direction="row">
            <Grid
                className={classes.header}
                item
                container
                direction="row"
                xs={12}
            >
                <Grid item xs={8}>
                    <Typography variant="body1">
                        {t('account.receipt.receiptNumber', { receiptNumber })}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    {handlePrint !== noop && (
                        <Typography
                            className={classes.clickable}
                            onClick={handlePrint}
                            variant="body1"
                        >
                            {t('print')}
                        </Typography>
                    )}
                </Grid>
                <Grid className={classes.download} item xs={2}>
                    <Typography
                        className={classes.clickable}
                        onClick={handleDownload}
                        variant="body1"
                    >
                        {t('download')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                className={classes.content}
                item
                container
                direction="row"
                xs={12}
            >
                <Grid item xs={12}>
                    <InformationSection
                        customerName={customerName}
                        customerAddress={customerAddress}
                        card={card}
                        nextBillingDate={nextBillingDate}
                    />
                </Grid>
                <Grid className={classes.billingSection} item xs={12}>
                    <BillingSection
                        subscriptionName={subscriptionName}
                        {...billingProps}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

PaymentReceipt.propTypes = {
    classes: PropTypes.object.isRequired,
    billingProps: PropTypes.object.isRequired,
    receiptNumber: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    customerAddress: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    nextBillingDate: PropTypes.string.isRequired,
    subscriptionName: PropTypes.string.isRequired,
    handlePrint: PropTypes.func,
    handleDownload: PropTypes.func,
};

PaymentReceipt.defaultProps = {
    handlePrint: noop,
    handleDownload: noop,
};
export default withStyles(styles)(PaymentReceipt);
