import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Tooltip as TooltipUI, withStyles } from '@material-ui/core';
import { LibraryAdd, LibraryAddCheck, Share } from '@material-ui/icons';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import styles from './styles';

import BackgroundHeader from '../../../common/BackgroundHeader';
import Button from '../../../common/Button';

import { dateDifferenceInLargestUnits } from '../../../utils/dates';
import {
    challengeAdminShareImageURLRequested,
    challengeClearErrors,
    challengeShareURLRequested,
    joinChallengeRequested,
    unjoinChallengeRequested,
} from '../../../challenges/actions';
import {
    getActiveCommunityId,
    getActiveCommunityName,
} from '../../../user/selectors';
import { downloadShareImage, getAppImages, noop } from '../../../utils';
import JoinTeam from '../JoinTeam';
import SelectTeam from '../SelectTeam';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../utils/analytics';
import { EVENT_SHARE_BEGIN } from '../../../constants/analyticsEvents';
import {
    CONTENT_TYPES,
    PARAM_CONTENT_ID,
    PARAM_CONTENT_TYPE,
} from '../../../constants/analyticsParams';
import { Can } from '../../../casl/ability-context';
import InviteUsersModal from './InviteUsersModal';
import {
    CHALLENGE_TEMPLATE_STATUS_DRAFT,
    CHALLENGE_TEMPLATE_STATUSES,
} from '../../../constants/challenges';
import {
    getChallengeAdminShareImageURL,
    getChallengeAdminShareImageURLErrors,
    getChallengeShareURL,
    getChallengeShareURLErrors,
    getChallengeThemeName,
    isChallengeAdminShareImageURLLoading,
    isChallengeShareURLLoading,
} from '../../../challenges/selectors';
import {
    getFeatureFlagValue,
    REMOTE_CONFIG_SHOW_CHALLENGE_INVITE_MODAL_BUTTON,
} from '../../../utils/remoteConfig';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';

const ChallengeHeader = ({
    classes,
    challengeId,
    communityId,
    headlinePhotoUrl,
    iconUrl,
    name,
    action,
    participationStatus,
    unit,
    teams,
    description,
    endDate,
    joinFunc,
    unjoinFunc,
    onChangeName,
    onChangePhoto,
    onChangeIcon,
    challengeStatus,
    getShareURL,
    shareURLLoading,
    shareURL,
    shareURLErrors,
    orgName,
    themeName,
    clearErrors,
    getAdminShareImageURL,
    adminShareImageURL,
    adminShareImageURLErrors,
    adminShareImageURLLoading,
}) => {
    const { t } = useTranslation();

    const currentDate = new Date(Date.now());
    const challengeEnded = currentDate.valueOf() > endDate.valueOf();
    const status = challengeEnded
        ? `${t('challengeEndedOn')} ${moment(endDate).format('MM/DD/YY')}`
        : `${t('challenge.challengeEndsIn')} ${dateDifferenceInLargestUnits(
              currentDate,
              endDate,
          )}`;

    const [joinTeamShown, setJoinTeamShown] = useState(false);
    const [selectTeamShown, setSelectTeamShown] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState();
    const [inviteModalRequested, setInviteModalRequested] = useState(false);
    const [isInviteModalOpen, setInviteModalOpen] = useState(false);
    const [shareButtonPressed, setShareButtonPressed] = useState(false);
    const [
        shareImageDownloadRequested,
        setShareImageDownloadRequested,
    ] = useState(false);

    const [imageDownloadError, setImageDownloadError] = useState(null);

    useEffect(() => {
        if (inviteModalRequested && shareURLErrors?.length > 0) {
            setInviteModalRequested(false);
        }
        if (inviteModalRequested && shareURL) {
            setInviteModalOpen(true);
            setInviteModalRequested(false);
        }
    }, [inviteModalRequested, shareURL, shareURLErrors.length]);

    useEffect(() => {
        if (shareButtonPressed && shareURL) {
            copy(shareURL);
            setSnackBarMessage(t('onBoarding.invite.linkCopied'));
            setShareButtonPressed(false);
        }
    }, [shareURL, shareButtonPressed, t]);

    const toggleParticipationStatus = useCallback(
        () =>
            participationStatus === 'joined'
                ? unjoinFunc({ challengeId, communityId })
                : joinFunc({ challengeId, communityId }),
        [participationStatus, unjoinFunc, joinFunc, challengeId, communityId],
    );

    const handleTeamParticipationStatusChange = useCallback(
        () =>
            participationStatus === 'joined'
                ? setSelectTeamShown(true)
                : setJoinTeamShown(true),
        [participationStatus, setSelectTeamShown, setJoinTeamShown],
    );

    const handleParticipationButtonClick = useCallback(
        () =>
            unit === 'User'
                ? toggleParticipationStatus()
                : handleTeamParticipationStatusChange(),
        [unit, toggleParticipationStatus, handleTeamParticipationStatusChange],
    );

    const handleJoinTeam = useCallback(
        (id) => {
            joinFunc({ challengeId, communityId, teamId: id });
            setJoinTeamShown(false);
        },
        [joinFunc, challengeId, communityId, setJoinTeamShown],
    );

    const handleSwitchTeam = useCallback(
        (id) => {
            joinFunc({ challengeId, communityId, teamId: id });
            setSelectTeamShown(false);
        },
        [joinFunc, challengeId, communityId, setSelectTeamShown],
    );

    const handleLeaveChallenge = useCallback(() => {
        unjoinFunc({ challengeId, communityId });
        setSelectTeamShown(false);
    }, [unjoinFunc, challengeId, communityId, setSelectTeamShown]);

    const handleCopyDeepLink = useCallback(() => {
        if (shareURL) {
            copy(shareURL);
            setSnackBarMessage(t('onBoarding.invite.linkCopied'));
        } else {
            getShareURL(challengeId, action === 'view');
            setShareButtonPressed(true);
        }
        trackEvent(EVENT_SHARE_BEGIN, {
            [PARAM_CONTENT_TYPE]: CONTENT_TYPES.CHALLENGES,
            [PARAM_CONTENT_ID]: challengeId,
        });
    }, [shareURL, challengeId, t, getShareURL, action]);

    const genericAdminChallengeInvite = getAppImages()
        ?.genericAdminChallengeInvite;

    const showInviteModalButton =
        !!genericAdminChallengeInvite &&
        getFeatureFlagValue(REMOTE_CONFIG_SHOW_CHALLENGE_INVITE_MODAL_BUTTON);

    useEffect(() => {
        if (shareImageDownloadRequested && adminShareImageURL)
            downloadShareImage(
                adminShareImageURL,
                name,
                setShareImageDownloadRequested,
                setImageDownloadError,
                t('challenge.inviteModal.downloadFailed'),
            );
    }, [adminShareImageURL, name, shareImageDownloadRequested, t]);

    return (
        <>
            <JoinTeam
                isOpen={joinTeamShown}
                setOpen={setJoinTeamShown}
                teams={teams}
                onJoinTeam={handleJoinTeam}
            />
            <SelectTeam
                isOpen={selectTeamShown}
                setOpen={setSelectTeamShown}
                teams={teams}
                onSwitchTeam={handleSwitchTeam}
                onLeaveChallenge={handleLeaveChallenge}
            />
            <BackgroundHeader
                photoUrl={headlinePhotoUrl}
                iconUrl={iconUrl}
                name={name}
                iconStyle={{
                    border: '2px solid #fff',
                    borderRadius: '50%',
                }}
                status={status}
                action={action}
                subject="Challenge"
                onChangeName={onChangeName}
                onChangePhoto={onChangePhoto}
                onChangeIcon={onChangeIcon}
                className={classes.container}
                intercomTargetPrefix="Challenge"
            >
                <Grid container className={classes.buttonsContainer}>
                    {action === 'view' && (
                        <Button
                            color="accent"
                            size="large"
                            disabled={challengeEnded}
                            onClick={handleParticipationButtonClick}
                            className={classes.joinButton}
                        >
                            {participationStatus === 'joined' ? (
                                <>
                                    <LibraryAddCheck
                                        className={classes.participationIcon}
                                    />
                                    {t('Member')}
                                </>
                            ) : (
                                <>
                                    <LibraryAdd
                                        className={classes.participationIcon}
                                    />
                                    {t('challenge.joinChallengeCTA')}
                                </>
                            )}
                        </Button>
                    )}
                    {!challengeEnded && (
                        <Can I="view" a="AdminScreens" passThrough>
                            {(allowed) => {
                                if (allowed && showInviteModalButton) {
                                    const inviteUsersDisabled =
                                        challengeEnded ||
                                        challengeStatus ===
                                            CHALLENGE_TEMPLATE_STATUS_DRAFT;
                                    return (
                                        <>
                                            <TooltipUI
                                                arrow
                                                classes={classes}
                                                title={t(
                                                    'challenge.inviteChallengeUsersCTA.disabledToolTip',
                                                )}
                                                placement="bottom"
                                                enterTouchDelay={0}
                                                disableHoverListener={
                                                    !inviteUsersDisabled
                                                }
                                                disableTouchListener={
                                                    !inviteUsersDisabled
                                                }
                                                disableFocusListener={
                                                    !inviteUsersDisabled
                                                }
                                            >
                                                <span data-intercom-target="challengeInviteUsersButton">
                                                    <Button
                                                        color="accent"
                                                        size="large"
                                                        className={
                                                            action === 'view'
                                                                ? classes.shareButton
                                                                : classes.joinButton
                                                        }
                                                        style={{
                                                            paddingTop: 10,
                                                            paddingBottom: 10,
                                                        }}
                                                        disabled={
                                                            inviteUsersDisabled
                                                        }
                                                        loading={
                                                            inviteModalRequested &&
                                                            shareURLLoading
                                                        }
                                                        onClick={() => {
                                                            getShareURL(
                                                                challengeId,
                                                                true,
                                                            );
                                                            setInviteModalRequested(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        <LibraryAddCheck
                                                            className={
                                                                classes.participationIcon
                                                            }
                                                        />
                                                        {t(
                                                            'challenge.inviteChallengeUsersCTA.buttonText',
                                                        )}
                                                    </Button>
                                                </span>
                                            </TooltipUI>

                                            <InviteUsersModal
                                                isOpen={isInviteModalOpen}
                                                onClose={() =>
                                                    setInviteModalOpen(false)
                                                }
                                                orgName={orgName}
                                                challengeId={challengeId}
                                                challengeName={name}
                                                themeName={themeName}
                                                imageLoading={
                                                    adminShareImageURLLoading ||
                                                    shareImageDownloadRequested
                                                }
                                                inviteLoading={shareURLLoading}
                                                challengeDescription={
                                                    description
                                                }
                                                challengeShareLink={shareURL}
                                                shareImageURL={
                                                    genericAdminChallengeInvite
                                                }
                                                onDownload={() => {
                                                    setShareImageDownloadRequested(
                                                        true,
                                                    );
                                                    if (!adminShareImageURL) {
                                                        getAdminShareImageURL(
                                                            communityId,
                                                            challengeId,
                                                            true,
                                                        );
                                                    }
                                                }}
                                                onConfirm={() => {
                                                    setInviteModalOpen(false);
                                                    setSnackBarMessage(
                                                        t(
                                                            'onBoarding.invite.linkCopied',
                                                        ),
                                                    );
                                                }}
                                            />
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <Button
                                                color="accent"
                                                size="large"
                                                onClick={handleCopyDeepLink}
                                                loading={
                                                    !inviteModalRequested &&
                                                    shareURLLoading
                                                }
                                                className={
                                                    action === 'view'
                                                        ? classes.shareButton
                                                        : classes.joinButton
                                                }
                                            >
                                                <>
                                                    <Share
                                                        className={
                                                            classes.participationIcon
                                                        }
                                                    />
                                                    {t('copyShareLink')}
                                                </>
                                            </Button>
                                        </>
                                    );
                                }
                            }}
                        </Can>
                    )}
                </Grid>
            </BackgroundHeader>
            <SnackBarAPIResponse
                messages={snackBarMessage}
                errors={[
                    ...shareURLErrors,
                    ...adminShareImageURLErrors,
                    ...(imageDownloadError || []),
                ]}
                clearResponse={() => {
                    setImageDownloadError(null);
                    setSnackBarMessage(null);
                    clearErrors();
                }}
            />
        </>
    );
};

ChallengeHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    challengeId: PropTypes.string.isRequired,
    communityId: PropTypes.string.isRequired,
    headlinePhotoUrl: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    action: PropTypes.string,
    participationStatus: PropTypes.string,
    unit: PropTypes.string,
    teams: PropTypes.array,
    endDate: PropTypes.instanceOf(Date).isRequired,
    joinFunc: PropTypes.func,
    unjoinFunc: PropTypes.func,
    onChangeName: PropTypes.func.isRequired,
    onChangePhoto: PropTypes.func.isRequired,
    onChangeIcon: PropTypes.func.isRequired,
    description: PropTypes.string,
    challengeStatus: PropTypes.oneOf(CHALLENGE_TEMPLATE_STATUSES).isRequired,
    getShareURL: PropTypes.func,
    shareURLLoading: PropTypes.bool,
    shareURL: PropTypes.string,
    orgName: PropTypes.string,
    themeName: PropTypes.string,
    shareURLErrors: PropTypes.array,
    clearErrors: PropTypes.func,
    adminShareImageURL: PropTypes.string,
    getAdminShareImageURL: PropTypes.func,
    adminShareImageURLLoading: PropTypes.bool,
    adminShareImageURLErrors: PropTypes.array,
};

ChallengeHeader.defaultProps = {
    action: 'view',
    participationStatus: null,
    unit: 'User',
    teams: null,
    joinFunc: noop,
    unjoinFunc: noop,
    description: '',
    getShareURL: noop,
    shareURLLoading: false,
    shareURL: '',
    orgName: '',
    themeName: '',
    shareURLErrors: [],
    clearErrors: noop,
    adminShareImageURL: '',
    getAdminShareImageURL: noop,
    adminShareImageURLLoading: false,
    adminShareImageURLErrors: [],
};

export const StyledChallengeHeader = withStyles(styles)(ChallengeHeader);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    shareURLLoading: isChallengeShareURLLoading(state),
    shareURL: getChallengeShareURL(state),
    orgName: getActiveCommunityName(state),
    themeName: getChallengeThemeName(state),
    shareURLErrors: getChallengeShareURLErrors(state),
    adminShareImageURL: getChallengeAdminShareImageURL(state),
    adminShareImageURLLoading: isChallengeAdminShareImageURLLoading(state),
    adminShareImageURLErrors: getChallengeAdminShareImageURLErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    joinFunc: ({ challengeId, communityId, teamId }) =>
        dispatch(joinChallengeRequested({ challengeId, communityId, teamId })),
    unjoinFunc: ({ challengeId, communityId }) =>
        dispatch(unjoinChallengeRequested({ challengeId, communityId })),
    getShareURL: (challengeId, includeOrgInvite) =>
        dispatch(challengeShareURLRequested(challengeId, includeOrgInvite)),
    getAdminShareImageURL: (communityId, challengeId, includeOrgInvite) =>
        dispatch(
            challengeAdminShareImageURLRequested(
                communityId,
                challengeId,
                includeOrgInvite,
            ),
        ),
    clearErrors: () => dispatch(challengeClearErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledChallengeHeader);
