const styles = (theme) => {
    const green = theme.palette.primary.main;
    return {
        container: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        green: {
            color: green,
        },
        link: {
            color: green,
            fontWeight: 600,
        },
    };
};

export default styles;
