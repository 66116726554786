import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { connect, useDispatch } from 'react-redux';
import { dismissInfoCard, showInfoCard } from '../../user/actions';
import { getDismissedInfoCardIds } from '../../user/selectors';
import InfoCard from '../InfoCard';
import HeaderPage from '../HeaderPage';
import { InfoOutlined } from '@material-ui/icons';

export const InfoCardHeader = ({
    infoCardId,
    image,
    pageTitle,
    hideCard,
    helpLink,
    hasHelpLink,
    rightContent,
    dismissedInfoCardIds,
    onClose,
    children,
}) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const isDismissed = dismissedInfoCardIds.includes(infoCardId) || hideCard;

    return (
        <>
            <InfoCard
                image={image}
                helpLink={helpLink}
                onClose={() => onClose(infoCardId)}
                infoCardId={infoCardId}
                hideCard={hideCard}
                isFullWidth
            >
                {children}
            </InfoCard>
            <Grid item xs={12}>
                <HeaderPage
                    title={pageTitle}
                    helpLink={
                        hasHelpLink &&
                        isDismissed && (
                            <InfoOutlined
                                className={classes.infoIcon}
                                onClick={() => {
                                    dispatch(showInfoCard(infoCardId));
                                }}
                            />
                        )
                    }
                    rightContent={rightContent}
                />
            </Grid>
        </>
    );
};

InfoCardHeader.propTypes = {
    infoCardId: PropTypes.number.isRequired,
    image: PropTypes.string,
    pageTitle: PropTypes.string,
    hideCard: PropTypes.bool,
    rightContent: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    dismissedInfoCardIds: PropTypes.array,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    // If true, we show the info icon for the user to get to the help links
    hasHelpLink: PropTypes.bool,
    helpLink: PropTypes.string,
};

InfoCardHeader.defaultProps = {
    image: null,
    hideCard: false,
    rightContent: null,
    dismissedInfoCardIds: [],
    pageTitle: '',
    hasHelpLink: true,
    helpLink: '',
};

const mapStateToProps = (state) => ({
    dismissedInfoCardIds: getDismissedInfoCardIds(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: (infoCardId) => dispatch(dismissInfoCard(infoCardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoCardHeader);
