import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { Grid, Typography, withStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DateTimePicker as MaterialDatePicker } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { noop } from '../../../../utils';
import FormInput from '../../../../common/Form/FormInput';
import Button from '../../../../common/Button';
import DatePicker from '../../../../common/DatePicker';
import LimitedLengthInput from '../../../../common/LimitedLengthInput';
import Tooltip from '../../../../common/Tooltip';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../../../../constants/dates';
import {
    MAX_NAME_LENGTH,
    MIN_NAME_LENGTH,
} from '../../../CreateChallengeScreen/CustomizeChallenge/constants';
import PhotoUpload from '../../../../common/PhotoUpload';
import PhotoAlternatePlaceholder from '../../../../common/Photo/PhotoAlternatePlaceholder';

const MAX_DESCRIPTION_LENGTH = 280;

const StartDateField = ({ value, placeholder, onChange }) => (
    <DatePicker
        as={MaterialDatePicker}
        value={value}
        placeholder={placeholder}
        minDate={moment()
            .add(10, 'minutes')
            .toDate()}
        maxDate={moment()
            .add(5, 'years')
            .toDate()}
        onChange={(value) => onChange(value.toString())}
        format={DATETIME_FORMAT_FOR_DATETIME_PICKERS}
        isBottomMargin
    />
);

const EndDateField = ({ value, placeholder, startDate, onChange }) => (
    <DatePicker
        as={MaterialDatePicker}
        value={value}
        placeholder={placeholder}
        minDate={moment(startDate).toDate()}
        maxDate={moment(startDate)
            .add(30, 'days')
            .toDate()}
        onChange={(value) => {
            onChange(value.toString());
        }}
        format={DATETIME_FORMAT_FOR_DATETIME_PICKERS}
        isBottomMargin
    />
);

const ChallengeForm = ({
    onSubmit,
    onBack,
    classes,
    formData,
    onFormDataChanged,
}) => {
    const { t } = useTranslation();

    const [datePickerStart, setDatePickerStart] = useState(moment().toDate());
    const [icon, setIcon] = useState(null);
    const [coverPhoto, setCoverPhoto] = useState(null);

    const errorTeams = useMemo(() => t('challenge.create.teamsLimit'), [t]);
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                teams: Yup.number()
                    .min(2, errorTeams)
                    .max(25, errorTeams),
                challengeName: Yup.string()
                    .required(t('challenge.create.nameRequired'))
                    .min(
                        MIN_NAME_LENGTH,
                        t('challenge.name.length.min', {
                            count: MIN_NAME_LENGTH,
                        }),
                    )
                    .max(MAX_NAME_LENGTH),
                challengeDescription: Yup.string().required(
                    t('challenge.create.descriptionRequired'),
                ),
                startDate: Yup.string(),
                endDate: Yup.string()
                    .test(
                        'dateValueTest',
                        t('challenge.errors.invalidDateValue'),
                        (endDate) => {
                            if (endDate === null) return false;
                            return true;
                        },
                    )
                    .test(
                        'dateRangeTest',
                        t('challenge.errors.invalidDateRange'),
                        function(endDate) {
                            const { parent } = this;
                            const startTimeStamp = moment(
                                parent.startDate,
                            ).unix();
                            const endTimeStamp = moment(endDate).unix();

                            if (startTimeStamp > endTimeStamp) return false;
                            return true;
                        },
                    )
                    .nullable(),
            }),
        [errorTeams, t],
    );

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={formData}
            onSubmit={(values) => {
                onSubmit({
                    ...values,
                    icon,
                    coverPhoto,
                });
            }}
        >
            {(formikProps) => {
                const handleNameChange = (event) => {
                    if (event.target.value.length <= MAX_NAME_LENGTH) {
                        formikProps.handleChange(event);
                    }
                };

                const handleDescriptionChange = (event) => {
                    if (event.target.value?.length <= MAX_DESCRIPTION_LENGTH) {
                        formikProps.handleChange(event);
                    }
                };

                return (
                    <Form>
                        <Grid container className={classes.form}>
                            <Grid
                                item
                                container
                                sm={12}
                                md={6}
                                style={{ paddingRight: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-between"
                                    wrap="nowrap"
                                    style={{ marginBottom: 20 }}
                                >
                                    <Typography className={classes.inputLabel}>
                                        {t(
                                            'onBoarding.createChallenge.challengeName',
                                        ) + '*'}
                                    </Typography>
                                    <FormInput
                                        noLeftMargin={true}
                                        name="challengeName"
                                        as={LimitedLengthInput}
                                        charactersLeft={
                                            MAX_NAME_LENGTH -
                                            formikProps.values.challengeName
                                                .length
                                        }
                                        maxCharacters={MAX_NAME_LENGTH}
                                        customStyle={{
                                            textAlign: 'right',
                                            marginBottom: 0,
                                            marginTop: 0,
                                            flex: 1,
                                        }}
                                        onChange={handleNameChange}
                                        onBlur={(e) => {
                                            formikProps.handleBlur(e);
                                            onFormDataChanged(
                                                formikProps.values,
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-between"
                                    className={classes.formItemDescription}
                                    wrap="nowrap"
                                >
                                    <Typography className={classes.inputLabel}>
                                        {t(
                                            'onBoarding.createChallenge.description',
                                        ) + '*'}
                                    </Typography>
                                    <FormInput
                                        noLeftMargin={true}
                                        name="challengeDescription"
                                        as={LimitedLengthInput}
                                        multiline={true}
                                        minRows={3}
                                        charactersLeft={
                                            MAX_DESCRIPTION_LENGTH -
                                            formikProps.values
                                                .challengeDescription.length
                                        }
                                        maxCharacters={MAX_DESCRIPTION_LENGTH}
                                        customStyle={{
                                            textAlign: 'right',
                                            marginBottom: 0,
                                            marginTop: 0,
                                            flex: 1,
                                        }}
                                        onChange={handleDescriptionChange}
                                        onBlur={(e) => {
                                            formikProps.handleBlur(e);
                                            onFormDataChanged(
                                                formikProps.values,
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    className={classes.formButtonRow}
                                    wrap="nowrap"
                                >
                                    <Grid
                                        container
                                        item
                                        justifyContent="space-between"
                                    >
                                        <Grid
                                            item
                                            className={
                                                classes.formChallengeType
                                            }
                                        >
                                            <Typography
                                                className={classes.inputLabel}
                                            >
                                                {t(
                                                    'challenge.create.startDate',
                                                )}
                                            </Typography>
                                            <FormInput
                                                as={StartDateField}
                                                name="startDate"
                                                placeholder={t(
                                                    `challenge.startDateSelect`,
                                                )}
                                                noLeftMargin
                                                onChange={(value) => {
                                                    formikProps.setFieldValue(
                                                        'startDate',
                                                        value,
                                                    );
                                                    formikProps.setFieldValue(
                                                        'endDate',
                                                        null,
                                                    );
                                                    setDatePickerStart(value);
                                                    onFormDataChanged({
                                                        ...formikProps.values,
                                                        startDate: value,
                                                        endDate: null,
                                                    });
                                                    formikProps.handleChange(
                                                        value,
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={
                                                classes.formChallengeType
                                            }
                                        >
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    className={
                                                        classes.inputLabel
                                                    }
                                                    style={{ display: 'flex' }}
                                                >
                                                    {t(
                                                        'challenge.create.endDate',
                                                    )}
                                                </Typography>
                                                <Tooltip
                                                    title={t(
                                                        'onBoarding.createChallenge.durationTooltip',
                                                    )}
                                                    placement="right"
                                                >
                                                    <InfoOutlinedIcon
                                                        className={
                                                            classes.formButtonRowInfoIcon
                                                        }
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <FormInput
                                                as={EndDateField}
                                                name="endDate"
                                                placeholder={t(
                                                    `challenge.endDateSelect`,
                                                )}
                                                startDate={datePickerStart}
                                                noLeftMargin
                                                onChange={(value) => {
                                                    formikProps.setFieldValue(
                                                        'endDate',
                                                        value,
                                                    );
                                                    formikProps.handleBlur(
                                                        value,
                                                    );

                                                    onFormDataChanged({
                                                        ...formikProps.values,
                                                        endDate: value,
                                                    });
                                                    formikProps.handleChange(
                                                        value,
                                                    );
                                                }}
                                            />
                                            {formikProps.errors.endDate && (
                                                <Typography
                                                    variant="body1"
                                                    className={
                                                        classes.dateError
                                                    }
                                                >
                                                    {formikProps.errors.endDate}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                sm={12}
                                md={6}
                                direction="row"
                                style={{ paddingLeft: 5 }}
                            >
                                <Grid
                                    container
                                    item
                                    className={classes.photoContainer}
                                    style={{ marginBottom: 32 }}
                                >
                                    <Typography
                                        className={classes.inputLabel}
                                        style={{ display: 'flex' }}
                                    >
                                        {t('challenge.create.challengeIcon')}
                                    </Typography>
                                    <PhotoUpload
                                        borderRadius={8}
                                        hideTip
                                        onUpload={setIcon}
                                        maxHeight={135}
                                        placeholder={
                                            <PhotoAlternatePlaceholder />
                                        }
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    className={classes.photoContainer}
                                    style={{ marginBottom: 32 }}
                                >
                                    <Typography
                                        className={classes.inputLabel}
                                        style={{ display: 'flex' }}
                                    >
                                        {t(
                                            'challenge.create.challengeCoverPhoto',
                                        )}
                                    </Typography>
                                    <PhotoUpload
                                        borderRadius={5}
                                        onUpload={setCoverPhoto}
                                        hideTip
                                        CropProps={{
                                            minWidth: 800,
                                            minHeight: 500,
                                            maxWidth: 1080,
                                            maxHeight: 1920,
                                        }}
                                        initCrop={{
                                            aspect: 16 / 9,
                                            width: 500,
                                            unit: 'px',
                                        }}
                                        maxHeight={135}
                                        placeholder={
                                            <PhotoAlternatePlaceholder />
                                        }
                                    ></PhotoUpload>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                className={classes.buttonWrapper}
                            >
                                <Button
                                    className={classes.actionButton}
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    onClick={onBack}
                                >
                                    {t('onBoarding.common.back')}
                                </Button>
                                <Button
                                    className={classes.actionButton}
                                    color="primary"
                                    type="submit"
                                    size="medium"
                                    style={{ marginRight: `0` }}
                                    disabled={!formikProps.isValid}
                                >
                                    {t('onBoarding.common.actionize')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

ChallengeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    onFormDataChanged: PropTypes.func.isRequired,
};

ChallengeForm.defaultProps = {
    onSubmit: noop,
    onBack: noop,
    onFormDataChanged: noop,
    formData: {
        name: '',
        description: '',
        startDate: moment().add(2, 'hours'),
        endDate: moment()
            .add(2, 'hours')
            .add(10, 'days'),
    },
};

export default withStyles(styles)(ChallengeForm);
