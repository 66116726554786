import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    icon: {
        marginLeft: '5px',
        color: theme.custom.iconColor,
        fontSize: '18px',
        '&:hover': {
            color: theme.custom.iconColorHover,
            cursor: 'pointer',
        },
    },
}));

export default useStyles;
