import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    icon: ({ iconUrl }) => ({
        minWidth: '128px',
        width: '128px',
        height: '128px',
        backgroundImage: `url(${iconUrl})`,
        backgroundSize: 'cover',
        position: 'relative',
    }),
    btnEditIcon: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        padding: '8px',
    },
}));
