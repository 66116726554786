import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { resetPasswordRequested } from '../../../auth/actions';
import { useTranslation } from 'react-i18next';
import Form from '../../../common/Form';
import FormInput from '../../../common/Form/FormInput';
import useStyles from './styles';
import SignInScreenTemplate from '../index';
import { getPasswordResetReturnURL } from '../../../auth/selectors';
import AdminButton from '../../../common/Button';

const ResetPasswordScreen = ({ returnURL, onSubmit }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const ValidationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, t('userCreate.passwordLength', { length: 8 }))
            .required(t('userCreate.inputRequired')),
        passwordConfirm: Yup.string()
            .required(t('userCreate.inputRequired'))
            .oneOf(
                [Yup.ref('password'), null],
                t('userCreate.confirmPasswordMatch'),
            ),
    });

    const history = useHistory();

    const query = new URLSearchParams(window.location.search);
    const userId = query.get('user');
    const token = query.get('reset_token');
    if (!userId || !token) {
        history.push('/login');
    }

    const submitHandler = (value) => {
        onSubmit(value.password, value.passwordConfirm, userId, token);
    };

    return (
        <SignInScreenTemplate>
            <Grid container item justify="center">
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="h2">
                        {t('passwordReset.title')}
                    </Typography>
                </Grid>
                {!returnURL ? (
                    <>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                {t('passwordReset.resetDescription')}
                            </Typography>
                        </Grid>
                        <Form
                            validationSchema={ValidationSchema}
                            onSubmit={submitHandler}
                            initialValues={{
                                password: '',
                                passwordConfirm: '',
                            }}
                        >
                            <FormInput
                                name="password"
                                label={t('passwordReset.newPassword')}
                                type="password"
                            />
                            <FormInput
                                name="passwordConfirm"
                                label={t('passwordReset.confirmNewPassword')}
                                type="password"
                            />
                        </Form>
                    </>
                ) : (
                    <Grid>
                        <AdminButton
                            onClick={() => (window.location.href = returnURL)}
                            color="accent"
                            size="large"
                            style={{ width: '100%' }}
                        >
                            {t('passwordReset.returnToLogin')}
                        </AdminButton>
                        <Button />
                    </Grid>
                )}
            </Grid>
        </SignInScreenTemplate>
    );
};

ResetPasswordScreen.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    returnURL: PropTypes.string,
};

ResetPasswordScreen.defaultProps = {
    returnURL: '',
};

const mapStateToProps = (state) => ({
    returnURL: getPasswordResetReturnURL(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (password, passwordConfirm, userId, token) =>
        dispatch(
            resetPasswordRequested(password, passwordConfirm, userId, token),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResetPasswordScreen);
