import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL } from './constants';

const BLACKSTONE_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'Blackstone',
    shouldShowSignUp: false,
    clientId: '60f0967be0b52680fad768c3',
    clientSecret:
        '02-CIRcJVwXsWiVYqfSVy5JGOhL6rR-FG_OCREzgLEyR1IEkLmEXXbg-2g0qITnt8S',
    loginMethods: [LOGIN_METHOD_EMAIL],
    wordpressDomain: 'https://blackstone.joulebug.com',
    appStoreLink:
        'https://apps.apple.com/us/app/blackstone-energy/id1583055398',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.blackstone',
    appDescription:
        'With Blackstone Energy ecoBoss™, compete in challenges to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we strive towards common goals. Download today!',
};

export default BLACKSTONE_PROPERTIES;
