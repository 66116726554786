export const HOME_SCREEN = 'HOME_SCREEN';
export const ANALYTICS_SCREEN = 'ANALYTICS_SCREEN';
export const ANALYTICS_PHOTOS_SCREEN = 'ANALYTICS_PHOTOS_SCREEN';
export const CHALLENGE_TOP_PHOTOS = 'CHALLENGE_TOP_PHOTOS';
export const CHALLENGE_ANALYTICS_TOP_PHOTOS = 'CHALLENGE_ANALYTICS_TOP_PHOTOS';
export const CARD_SCREENS = [
    HOME_SCREEN,
    ANALYTICS_SCREEN,
    ANALYTICS_PHOTOS_SCREEN,
    CHALLENGE_TOP_PHOTOS,
    CHALLENGE_ANALYTICS_TOP_PHOTOS,
];
