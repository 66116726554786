import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import ConfirmModal from '../ConfirmModal';
import CommunityDropdown from '../CommunityDropdown';
import theme from '../../theme';
import styles from './styles';

const SwitchOrganization = ({
    classes,
    userName,
    organizationName,
    availableOrganizations,
    selectedOrganization,
    onChange,
    isOpen,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();

    return (
        <ConfirmModal
            isOpen={isOpen}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={onConfirm}
            title={t('switchOrg')}
            confirmBtnText="Switch"
            confirmBtnType="accent"
            confirmBtnDisabled={!selectedOrganization}
        >
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="h2" className={classes.textLabel}>
                        {t('Move')}:
                        <Typography className={classes.textValue}>
                            {userName}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h2" className={classes.textLabel}>
                        {t('From')}:
                        <Typography className={classes.textValue}>
                            {organizationName}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h2" className={classes.textLabel}>
                        {t('To')}:
                    </Typography>
                    <CommunityDropdown
                        communities={availableOrganizations}
                        value={selectedOrganization}
                        onChange={onChange}
                        placeholder={`${t('communitySelect')}...`}
                        bgColor={theme.custom.bgSeparator}
                    />
                </Grid>
            </Grid>
        </ConfirmModal>
    );
};

SwitchOrganization.propTypes = {
    classes: PropTypes.object.isRequired,
    userName: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired,
    availableOrganizations: PropTypes.object.isRequired,
    selectedOrganization: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default withStyles(styles)(SwitchOrganization);
