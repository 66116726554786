import {
    ACTIVITIES_REQUEST_FAILED,
    ACTIVITIES_REQUEST_SUCCEEDED,
    ACTIVITIES_REQUESTED,
    ANALYTICS_GRAPH_DATA_REQUEST_FAILED,
    ANALYTICS_GRAPH_DATA_REQUEST_SUCCEEDED,
    ANALYTICS_GRAPH_DATA_REQUESTED,
    GRAPH_DATA_REQUEST_FAILED,
    GRAPH_DATA_REQUEST_SUCCEEDED,
    GRAPH_DATA_REQUESTED,
    IMPACT_STATS_REQUEST_FAILED,
    IMPACT_STATS_REQUEST_SUCCEEDED,
    IMPACT_STATS_REQUESTED,
    USERS_ANALYTIC_REQUEST_FAILED,
    USERS_ANALYTIC_REQUEST_SUCCEEDED,
    USERS_ANALYTIC_REQUESTED,
} from './actions';
import {
    GRAPH_METRIC_ACTIVE_USERS,
    GRAPH_METRIC_IMPACT_CARBON,
    GRAPH_METRIC_IMPACT_WASTE,
    GRAPH_METRIC_IMPACT_WATER,
    GRAPH_METRIC_NEW_USERS,
    GRAPH_METRIC_PHOTO_ADDED,
    GRAPH_METRIC_TOTAL_LOGS,
} from '../common/Graph/ChartItem/constants';

export const initialState = {
    activities: [],
    usersAnalytic: [],
    impactStats: [],
    graphData: {
        [GRAPH_METRIC_TOTAL_LOGS]: { labels: [], data: [], series: [] },
        [GRAPH_METRIC_ACTIVE_USERS]: { labels: [], data: [], series: [] },
        [GRAPH_METRIC_NEW_USERS]: { labels: [], data: [], series: [] },
        [GRAPH_METRIC_PHOTO_ADDED]: { labels: [], data: [], series: [] },
        [GRAPH_METRIC_IMPACT_CARBON]: { labels: [], data: [], series: [] },
        [GRAPH_METRIC_IMPACT_WATER]: { labels: [], data: [], series: [] },
        [GRAPH_METRIC_IMPACT_WASTE]: { labels: [], data: [], series: [] },
    },
    loading: {
        activity: false,
        user: false,
        impact: false,
        graph: {
            [GRAPH_METRIC_TOTAL_LOGS]: false,
            [GRAPH_METRIC_ACTIVE_USERS]: false,
            [GRAPH_METRIC_NEW_USERS]: false,
            [GRAPH_METRIC_PHOTO_ADDED]: false,
            [GRAPH_METRIC_IMPACT_CARBON]: false,
            [GRAPH_METRIC_IMPACT_WATER]: false,
            [GRAPH_METRIC_IMPACT_WASTE]: false,
        },
    },
};

const updateGraphLoadingState = (state, metrics, newLoadingStatus) => {
    const newLoadingState = {};
    if (Array.isArray(metrics)) {
        metrics.forEach((metric) => {
            newLoadingState[metric] = newLoadingStatus;
        });
    } else {
        newLoadingState[metrics] = newLoadingStatus;
    }

    return {
        ...state,
        loading: {
            ...state.loading,
            graph: {
                ...state.loading.graph,
                ...newLoadingState,
            },
        },
    };
};

export default function analyticsReducer(state = initialState, action) {
    const { type, payload = {} } = action;

    if (type === ACTIVITIES_REQUESTED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                activity: true,
            },
        };
    } else if (type === ACTIVITIES_REQUEST_SUCCEEDED) {
        const { activities } = payload;
        return {
            ...state,
            activities,
            loading: {
                ...state.loading,
                activity: false,
            },
        };
    } else if (type === ACTIVITIES_REQUEST_FAILED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                activity: false,
            },
        };
    } else if (type === USERS_ANALYTIC_REQUESTED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                user: true,
            },
        };
    } else if (type === USERS_ANALYTIC_REQUEST_SUCCEEDED) {
        const { usersAnalytic } = payload;
        return {
            ...state,
            usersAnalytic,
            loading: {
                ...state.loading,
                user: false,
            },
        };
    } else if (type === USERS_ANALYTIC_REQUEST_FAILED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                user: false,
            },
        };
    } else if (type === IMPACT_STATS_REQUESTED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                impact: true,
            },
        };
    } else if (type === IMPACT_STATS_REQUEST_SUCCEEDED) {
        const { impactStats } = payload;
        return {
            ...state,
            impactStats,
            loading: {
                ...state.loading,
                impact: false,
            },
        };
    } else if (type === IMPACT_STATS_REQUEST_FAILED) {
        return {
            ...state,
            loading: {
                ...state.loading,
                impact: false,
            },
        };
    } else if (type === GRAPH_DATA_REQUESTED) {
        const { options } = payload;
        return updateGraphLoadingState(state, options.metric, true);
    } else if (type === GRAPH_DATA_REQUEST_SUCCEEDED) {
        const { newGraphData, metric } = payload;
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [metric]: newGraphData,
            },
            loading: updateGraphLoadingState(state, metric, false).loading,
        };
    } else if (type === GRAPH_DATA_REQUEST_FAILED) {
        const { metrics } = payload;
        return updateGraphLoadingState(state, metrics, false);
    } else if (type === ANALYTICS_GRAPH_DATA_REQUESTED) {
        const { metrics } = payload;
        return updateGraphLoadingState(state, metrics, true);
    } else if (type === ANALYTICS_GRAPH_DATA_REQUEST_SUCCEEDED) {
        const { newGraphData, metrics } = payload;
        let graphDataUpdate = state.graphData;
        metrics.forEach((metric, index) => {
            graphDataUpdate = {
                ...graphDataUpdate,
                [metric]: newGraphData[index],
            };
        });

        return {
            ...state,
            graphData: {
                ...state.graphData,
                ...graphDataUpdate,
            },
            loading: updateGraphLoadingState(state, metrics, false).loading,
        };
    } else if (type === ANALYTICS_GRAPH_DATA_REQUEST_FAILED) {
        const { errors, metrics } = payload;
        return {
            ...state,
            loading: {
                ...updateGraphLoadingState(state, metrics, false).loading,
                errors: errors,
            },
        };
    }
    return state;
}
