const styles = (theme) => ({
    root: {
        flex: 1,
        padding: '20px 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0px 0 20px',
    },
    underlined: {
        borderBottom: `1px solid ${theme.custom.bgDarkHover}`,
    },
    titleContainer: {
        flex: 1,
        alignItems: 'center',
        margin: '10px 0 10px',
    },
    title: {
        fontSize: '36px',
    },
    link: {
        marginLeft: '6px',
        fontSize: '18px',
        color: 'rgba(51,51,51,.25)',
    },
});

export default styles;
