import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormLabel, Grid, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import useStyles from './styles';

import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import Tooltip from '../../common/Tooltip';
import Photo from '../../common/Photo';
import CommonPropTypes from '../../common/propTypes';
import { ProfilePropType } from '../ProfileScreen/propTypes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getAppImages, noop } from '../../utils';
import LimitedLengthInput from '../../common/LimitedLengthInput';
import FormInput from '../../common/Form/FormInput';
import Textarea from '../../common/Textarea';
import {
    getActiveCommunityId,
    getUserErrors,
    getUserProfile,
    getUserUpdateErrors,
    getUserUpdateResult,
    isUserLoading,
    isUserUpdateLoading,
} from '../../user/selectors';
import { userRequested, userUpdateRequested } from '../../user/actions';
import Button from '../../common/Button';
import theme from '../../theme';
import { BUTTON_ACCENT_COLOR } from '../../common/Button/constants';

const MAX_NAME_LENGTH = 50;

const UpdateProfileScreen = ({
    communityId,
    profileLoading,
    profileErrors,
    profile,
    requestUser,
    updateLoading,
    updateErrors,
    updateResult,
    requestUpdate,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const isPresentable = useMemo(
        () => !profileLoading && profileErrors == null && profile != null,
        [profileLoading, profileErrors, profile],
    );

    useEffect(() => {
        requestUser();
    }, [requestUser]);

    const [profileImage, setProfileImage] = useState(null);

    const initialValues = useMemo(
        () => ({
            name: profile?.name,
            headline: profile?.headline,
        }),
        [profile],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .required()
                    .max(MAX_NAME_LENGTH),
                headline: Yup.string(),
            }),
        [],
    );

    const handleNameChange = useCallback((event, formikHandleChange) => {
        if (event.target.value.length <= MAX_NAME_LENGTH) {
            formikHandleChange(event);
        }
    }, []);

    const handleCancel = useCallback(() => {
        // Can only edit own profile, so we go back to 'self'
        history.push('/profile/self');
    }, [history]);

    const [isUpdateRequestSent, setUpdateRequestSent] = useState(false);

    const handleSave = useCallback(
        ({ name = '', headline = '' }) => {
            requestUpdate({
                communityId,
                userId: profile.id,
                name,
                headline,
                file: profileImage,
            });
            history.push('/profile/self');
            setUpdateRequestSent(true);
        },
        [
            history,
            requestUpdate,
            communityId,
            profile,
            profileImage,
            setUpdateRequestSent,
        ],
    );

    useEffect(() => {
        if (
            isUpdateRequestSent &&
            !updateLoading &&
            updateErrors == null &&
            updateResult != null
        ) {
            history.push('/profile');
        }
    }, [
        isUpdateRequestSent,
        updateLoading,
        updateErrors,
        updateResult,
        history,
    ]);

    return (
        <Grid container direction="column" className={classes.container}>
            <RequestLoader
                isLoading={profileLoading}
                title={`${t('profile.loading')}...`}
            />
            <RequestLoader
                isLoading={updateLoading}
                title={`${t('savingChanges')}...`}
            />
            <ErrorList errors={profileErrors} />
            <ErrorList errors={updateErrors} />
            {isPresentable ? (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={noop}
                >
                    {(formikProps) => (
                        <>
                            <Typography variant="h3" className={classes.title}>
                                {t('profile.edit')}
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                className={classes.body}
                            >
                                <Grid item xs={3} className={classes.photo}>
                                    <Photo
                                        title={t('profile.pictureTitle')}
                                        photo={
                                            profile.image ||
                                            getAppImages().defaultProfileIcon
                                        }
                                        setCroppedImageData={setProfileImage}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={9}
                                    direction="column"
                                    className={classes.formRight}
                                >
                                    <FormLabel>
                                        <Typography className={classes.label}>
                                            {t('name')}
                                            <Tooltip
                                                placement="right"
                                                title={t(
                                                    'profile.toolTipUserName',
                                                )}
                                            >
                                                <InfoOutlined fontSize="small" />
                                            </Tooltip>
                                        </Typography>
                                        <FormInput
                                            as={LimitedLengthInput}
                                            noBottomMargin
                                            noErrorMessage
                                            borderColor={
                                                formikProps.errors.name == null
                                                    ? null
                                                    : 'red'
                                            }
                                            name="name"
                                            charactersLeft={
                                                MAX_NAME_LENGTH -
                                                formikProps.values.name.length
                                            }
                                            onChange={(event) =>
                                                handleNameChange(
                                                    event,
                                                    formikProps.handleChange,
                                                )
                                            }
                                        />
                                    </FormLabel>
                                    <FormLabel>
                                        <Typography className={classes.label}>
                                            {t('profileCreate.headline')}
                                            <Tooltip
                                                placement="right"
                                                title={t(
                                                    'challengeTeam.headlineFieldToolTip',
                                                )}
                                            >
                                                <InfoOutlined fontSize="small" />
                                            </Tooltip>
                                        </Typography>
                                        <FormInput
                                            as={Textarea}
                                            name="headline"
                                            rowsMin={3}
                                        />
                                    </FormLabel>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                justify="flex-end"
                                className={classes.buttons}
                            >
                                <Button
                                    btnColor={theme.custom.bgLightColor}
                                    textColor={theme.custom.textDarkColor}
                                    onClick={handleCancel}
                                >
                                    {t('cancel')}
                                </Button>
                                &nbsp;
                                <Button
                                    color={BUTTON_ACCENT_COLOR}
                                    onClick={() =>
                                        handleSave(formikProps.values)
                                    }
                                    disabled={!formikProps.isValid}
                                >
                                    {t('Save')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Formik>
            ) : null}
        </Grid>
    );
};

UpdateProfileScreen.propTypes = {
    communityId: PropTypes.string.isRequired,
    profileLoading: PropTypes.bool.isRequired,
    profileErrors: CommonPropTypes.errors,
    profile: ProfilePropType,
    requestUser: PropTypes.func.isRequired,
    updateLoading: PropTypes.bool.isRequired,
    updateErrors: CommonPropTypes.errors,
    updateResult: PropTypes.object,
    requestUpdate: PropTypes.func.isRequired,
};

UpdateProfileScreen.defaultProps = {
    profileErrors: null,
    profile: null,
    updateErrors: null,
    updateResult: null,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    profileLoading: isUserLoading(state),
    profileErrors: getUserErrors(state),
    profile: getUserProfile(state),
    updateLoading: isUserUpdateLoading(state),
    updateErrors: getUserUpdateErrors(state),
    updateResult: getUserUpdateResult(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestUser: () => dispatch(userRequested()),
    requestUpdate: (request) => dispatch(userUpdateRequested(request)),
});

const ConnectedUpdateProfileScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateProfileScreen);
export default ConnectedUpdateProfileScreen;
