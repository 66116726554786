import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import useStyles from './styles';

import { Can } from '../../../casl/ability-context';
import Button from '../../../common/Button';

const Description = ({ description, onEdit, onReset }) => {
    const { t } = useTranslation();

    const classes = useStyles();

    return (
        <Grid container item direction="column">
            <Grid item className={classes.description}>
                {description && parse(description)}
            </Grid>
            <Can I="edit" a="Actions">
                {() => (
                    <Grid
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                        className={classes.descriptionControls}
                    >
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onEdit}
                                data-intercom-target="Action Edit Description Button"
                            >
                                <Edit
                                    fontSize="small"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.buttonIcon}
                                />
                                {t('action.edit.editDescription')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onReset}
                            >
                                {t('Reset')}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Can>
        </Grid>
    );
};

Description.propTypes = {
    description: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
};

export default Description;
