import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import BackgroundHeader from '../../../common/BackgroundHeader';
import Button from '../../../common/Button';
import { useTranslation } from 'react-i18next';
import IcomoonIcon from '../../../common/IcomoonIcon';
import styles from './styles';
import {
    ACTIONS,
    VIEW_ACTION,
} from '../../ActionsScreen/AdminActionsScreen/constants';
import { Share } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';
import {
    actionShareURLRequested,
    clearActionShareURLErrors,
} from '../../../actions/actions';
import {
    getActionShareURLErrors,
    isActionShareURLLoading,
} from '../../../actions/selectors';
import { Grid } from '@material-ui/core';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';

const ActionHeader = ({
    classes,
    logActionFunc,
    headlineUrl,
    iconUrl,
    name,
    shareURL,
    actionId,
    fetchShareURL,
    shareURLLoading,
    errors,
    clearErrors,
}) => {
    const { t } = useTranslation();

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [shareURLRequested, setShareURLRequested] = useState(false);

    const copyLink = useCallback(() => {
        copy(shareURL);
        setSnackbarMessage(t('sharing.copied'));
        setShareURLRequested(false);
    }, [shareURL, t]);

    useEffect(() => {
        if (shareURL && shareURLRequested) {
            copyLink();
        }
    }, [shareURL, shareURLRequested, copyLink]);

    const handleCopyDeepLink = useCallback(() => {
        if (shareURL) {
            copyLink();
        } else {
            fetchShareURL(actionId);
            setShareURLRequested(true);
        }
    }, [shareURL, copyLink, fetchShareURL, actionId]);

    return (
        <>
            <BackgroundHeader
                photoUrl={headlineUrl}
                iconUrl={iconUrl}
                name={name}
                action={VIEW_ACTION}
                subject={ACTIONS}
            >
                <Grid container className={classes.buttonsContainer}>
                    <Button
                        className={classes.logActionButton}
                        color="accent"
                        size="large"
                        onClick={() => {
                            logActionFunc();
                        }}
                        aria-label={t('logAction')}
                    >
                        <Grid container alignItems="center" direction="row">
                            <IcomoonIcon
                                icon="jb-buzz"
                                size={18}
                                color="white"
                                className={classes.logActionIcon}
                            />
                            {t('logAction')}
                        </Grid>
                    </Button>
                    <Button
                        color="accent"
                        size="large"
                        onClick={handleCopyDeepLink}
                        className={classes.shareButton}
                        loading={shareURLLoading}
                    >
                        <Grid container alignItems="center" direction="row">
                            <Share className={classes.logActionIcon} />
                            {t('copyShareLink')}
                        </Grid>
                    </Button>
                </Grid>
            </BackgroundHeader>
            <SnackBarAPIResponse
                messages={snackbarMessage}
                errors={errors}
                clearResponse={() => {
                    clearErrors();
                    setSnackbarMessage(null);
                }}
            />
        </>
    );
};

ActionHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    logActionFunc: PropTypes.func.isRequired,
    headlineUrl: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    actionId: PropTypes.string.isRequired,
    fetchShareURL: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    shareURL: PropTypes.string,
    shareURLLoading: PropTypes.bool,
    errors: PropTypes.array,
};

const mapStateToProps = (state) => ({
    shareURLLoading: isActionShareURLLoading(state),
    errors: getActionShareURLErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchShareURL: (actionId) => dispatch(actionShareURLRequested(actionId)),
    clearErrors: () => dispatch(clearActionShareURLErrors()),
});

ActionHeader.defaultProps = {
    shareURL: '',
    shareURLLoading: false,
    errors: [],
};

export const StyledActionHeader = withStyles(styles)(ActionHeader);

export default connect(mapStateToProps, mapDispatchToProps)(StyledActionHeader);
