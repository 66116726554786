import { getAppImages } from '../utils';

export const flaggedContentToTableRow = (flaggedContent) => {
    const {
        id,
        photoSizes,
        message,
        flags,
        created,
        updated,
        user,
        isComment,
    } = flaggedContent || {};
    return {
        id,
        icon: user?.imageSizes?.[0]?.url || getAppImages().defaultProfileIcon,
        user: user?.name || '',
        comment: message,
        photo: photoSizes?.[0]?.url,
        count: isComment
            ? flags.length
            : flags.filter((flag) => !flag.flaggedCommentId).length,
        created: created,
        flaggedOn: updated,
    };
};

export const getCommentRows = (data) => {
    const commentIds = [];
    return data?.flatMap((item) =>
        item.flags
            .filter(
                (flag) =>
                    flag.flaggedCommentId &&
                    item.comments.some((c) => c.id === flag.flaggedCommentId) &&
                    !commentIds.includes(flag.flaggedCommentId) &&
                    commentIds.push(flag.flaggedCommentId),
            )
            .map((flag) => {
                const comment = item.comments.find(
                    (comment) => comment?.id === flag.flaggedCommentId,
                );
                return flaggedContentToTableRow({
                    ...item,
                    photoSizes: [],
                    flags: item.flags.filter(
                        (f) => f.flaggedCommentId === flag.flaggedCommentId,
                    ),
                    message: comment?.message,
                    user: comment?.user,
                    created: comment?.postedOn,
                    id: item.id,
                    commentId: comment?.id,
                    isComment: true,
                });
            }),
    );
};

export const flaggedContentToTableData = (data) => {
    const dat = data;
    return data
        ?.filter((item) => item.flags.some((flag) => !flag.flaggedCommentId))
        .map((item) => flaggedContentToTableRow(item))
        .concat(getCommentRows(dat));
};
