import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import styles from './styles';
import FormInput from '../../Form/FormInput';
import Button from '../../Button';
import SnackBarAPIResponse from '../../SnackbarAlert/SnackBarAPIResponse';
import { noop } from '../../utils';

const EditEmailForm = ({
    classes,
    initialFormValues,
    onSubmit,
    errors,
    clearErrors,
}) => {
    const { t } = useTranslation();

    const [formDisabled, setFormDisabled] = useState(true);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('userCreate.emailInvalid'))
            .required(t('userCreate.inputRequired')),
    });

    return (
        <Grid container>
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                submitBtnText={t('onBoarding.getStarted')}
                onSubmit={(values) => {
                    onSubmit(values);
                    setFormDisabled(true);
                }}
                enableReinitialize
            >
                {(formikProps) => {
                    const handleEditClick = () => {
                        setFormDisabled(false);
                    };

                    const handleCancelClick = () => {
                        setFormDisabled(true);
                        formikProps.resetForm();
                    };

                    /* Disabled/hidden until JB-1194 is resolved */
                    const editButton = formDisabled ? null : (
                        <Button
                            className={classes.fieldBtn}
                            onClick={handleEditClick}
                            color="primary"
                            disabled={true}
                        >
                            <Edit fontSize="small" />
                            <Typography
                                className={classes.fieldBtnText}
                                variant="body1"
                            >
                                {t('edit')}
                            </Typography>
                        </Button>
                    );

                    return (
                        <Form
                            className={classes.form}
                            data-test="editEmailForm"
                        >
                            <Grid
                                container
                                justifyContent="space-between"
                                style={{ paddingBottom: '5px' }}
                                wrap="nowrap"
                            >
                                <Grid item container alignItems="center" xs={5}>
                                    <Typography className={classes.inputLabel}>
                                        {t('userCreate.emailLabel')}
                                    </Typography>
                                </Grid>
                                {formDisabled ? (
                                    editButton
                                ) : (
                                    <div className={classes.fieldBtnContainer}>
                                        <Button
                                            className={classes.fieldBtn}
                                            onClick={handleCancelClick}
                                        >
                                            <Typography
                                                variant="body1"
                                                className={classes.fieldBtnText}
                                            >
                                                {t('cancel')}
                                            </Typography>
                                        </Button>
                                        <Button
                                            className={classes.fieldBtn}
                                            type="submit"
                                            color="primary"
                                            disabled={
                                                !(
                                                    formikProps.isValid &&
                                                    formikProps.dirty
                                                )
                                            }
                                        >
                                            <Typography
                                                variant="body1"
                                                className={classes.fieldBtnText}
                                            >
                                                {t('Submit')}
                                            </Typography>
                                        </Button>
                                    </div>
                                )}
                            </Grid>
                            <div className={classes.fieldContainer}>
                                <FormInput
                                    name="email"
                                    placeholder={t(
                                        'onBoarding.emailPlaceholder',
                                    )}
                                    data-test="emailInput"
                                    disabled={formDisabled}
                                />
                            </div>
                            <SnackBarAPIResponse
                                errors={errors}
                                clearResponse={clearErrors}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </Grid>
    );
};

EditEmailForm.propTypes = {
    classes: PropTypes.object.isRequired,
    initialFormValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    errors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    clearErrors: PropTypes.func.isRequired,
};

EditEmailForm.defaultProps = {
    onSubmit: noop,
    errors: '',
};

export default withStyles(styles)(EditEmailForm);
