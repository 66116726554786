import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    ListItemIcon,
    Grid,
    Typography,
    withStyles,
    ListItem,
} from '@material-ui/core';
import styles from './styles';

const ActivityTotals = ({ activityItems, activityTitle, classes }) => {
    return (
        <Grid container direction="column">
            <Typography className={classes.title} variant="h3">
                {activityTitle}
            </Typography>
            <List className={classes.container}>
                {activityItems.map((activityItem) => (
                    <ListItem
                        container
                        item
                        direction="row"
                        wrap="nowrap"
                        key={activityItem.label}
                        className={classes.listItem}
                    >
                        <Grid container item direction="row">
                            <ListItemIcon className={classes.icon}>
                                {activityItem.icon}
                            </ListItemIcon>
                            <Typography className={classes.label}>
                                {activityItem.label}
                            </Typography>
                        </Grid>
                        <Typography className={classes.value}>
                            {activityItem.value}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
};

export const ActivityTotalPropType = PropTypes.shape({
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
});

ActivityTotals.propTypes = {
    activityItems: PropTypes.arrayOf(ActivityTotalPropType),
    activityTitle: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

ActivityTotals.defaultProps = {
    activityItems: [],
    activityTitle: 'Activity Totals',
};

export default withStyles(styles)(ActivityTotals);
