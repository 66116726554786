import PropTypes from 'prop-types';

export const ChallengeType = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
    largePhotoUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
