import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import ActivityCard from '../../common/ActivityCard';
import {
    activityDetail,
    activityErrors,
    activityLoading,
    getErrors,
} from '../../activities/selectors';
import {
    activityDetailRequested,
    clearActivityErrors,
} from '../../activities/actions';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import ActivityCardLoader from '../../common/ActivityCard/ActivityCardLoader';
import ErrorList from '../../common/errors/ErrorList';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { useRouteMatch } from 'react-router-dom';

const ViewActivityScreen = ({
    fetchActivity,
    activity,
    loading,
    fetchErrors,
    actionErrors,
    clearErrors,
}) => {
    const {
        params: { activityId },
    } = useRouteMatch();

    useEffect(() => {
        fetchActivity(activityId);
    }, [fetchActivity, activityId]);

    const { t } = useTranslation();

    const renderPage = () => {
        if (loading) {
            return <ActivityCardLoader />;
        } else if (activity?.id) {
            return <ActivityCard activity={activity} />;
        } else if (fetchErrors?.length > 0) {
            return <ErrorList errors={fetchErrors} />;
        } else {
            return <ErrorList errors={t('error.unknown')} />;
        }
    };

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            style={{
                padding: theme.custom.mainContentHorizontalPadding,
            }}
            direction="column"
        >
            {renderPage()}
            <SnackBarAPIResponse
                errors={actionErrors}
                clearResponse={clearErrors}
            />
        </Grid>
    );
};

ViewActivityScreen.propTypes = {
    activity: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchActivity: PropTypes.func.isRequired,
    fetchErrors: PropTypes.array.isRequired,
    actionErrors: PropTypes.array.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    activity: activityDetail(state),
    fetchErrors: activityErrors(state),
    actionErrors: getErrors(state),
    loading: activityLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchActivity: (id) => dispatch(activityDetailRequested(id)),
    clearErrors: () => dispatch(clearActivityErrors()),
});

ViewActivityScreen.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewActivityScreen);
