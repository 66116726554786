import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import UserCounter from '../UserCounter/index';
import { shouldShowSubscription } from '../../subscriptions/utils';
import { getActiveCommunityId, isOrganization } from '../../user/selectors';
import { isAuthenticated } from '../../auth/selectors';
import {
    getCurrentSubscription,
    getSubscriptionName,
} from '../../subscriptions/selectors';
import { subscriptionRequested } from '../../subscriptions/actions';
import { connect } from 'react-redux';

const MainHeader = ({
    classes,
    isAuth,
    subscription,
    subscriptionName,
    communityId,
    fetchCurrentSubscription,
    isOrganization,
}) => {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (isOrganization && shouldShowSubscription()) {
            fetchCurrentSubscription({ groupId: communityId });
        }
    }, [communityId, fetchCurrentSubscription, isOrganization]);

    const getPageTitle = useCallback(() => {
        if (isAuth) {
            if (location.pathname === '/' || location.pathname === '/login') {
                return t('home.pageName');
            }
        }
    }, [t, isAuth, location]);

    const maxUsers = useMemo(() => subscription?.quotas?.maxUserCount, [
        subscription,
    ]);

    const shouldShowUserCounter = useCallback(() => {
        return (
            isOrganization &&
            location.pathname !== '/account' &&
            !location.pathname.toString().includes('/payment') &&
            maxUsers &&
            shouldShowSubscription()
        );
    }, [isOrganization, location.pathname, maxUsers]);

    return (
        <Grid container direction="row">
            {shouldShowUserCounter() && (
                <Grid item container xs={12}>
                    <Grid className={classes.titleContainer} item xs={7}>
                        <Typography className={classes.pageTitle} variant="h4">
                            {getPageTitle()}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <UserCounter
                            accountType={subscription.subscriptionTier?.name}
                            accountTypeId={subscription.subscriptionTier?.id}
                            currentUsers={subscription.currentUserCount}
                            maxUsers={maxUsers}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

MainHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    isAuth: PropTypes.bool.isRequired,
    subscription: PropTypes.object.isRequired,
    subscriptionName: PropTypes.string.isRequired,
    communityId: PropTypes.string.isRequired,
    fetchCurrentSubscription: PropTypes.func.isRequired,
    isOrganization: PropTypes.bool,
};

MainHeader.defaultProps = {
    isOrganization: true,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isOrganization: isOrganization(state),
    isAuth: isAuthenticated(state),
    subscription: getCurrentSubscription(state),
    subscriptionName: getSubscriptionName(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCurrentSubscription: (request) =>
        dispatch(subscriptionRequested(request)),
});

export const StyledMainHeader = withStyles(styles)(MainHeader);

export default connect(mapStateToProps, mapDispatchToProps)(StyledMainHeader);
