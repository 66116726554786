import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    inputRoot: (props) => ({
        color: '#999',
        padding: '0 !important',
        backgroundColor: props.bgColor,

        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primary.dark}`,
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primary.dark}`,
        },

        '& input:focus': {
            backgroundColor: 'white',
        },
    }),
    input: {
        padding: '6px 65px 6px 12px !important',
        height: '24px',
    },
    option: {
        padding: '3px 20px',
    },
    popper: {
        minWidth: '200px',
    },
    listbox: {
        '&>li:last-child .MuiAutocomplete-groupUl': {
            border: 'none',
            padding: 0,
        },
    },
    groupUl: {
        paddingBottom: 9,
        borderWidth: '0 0 1px',
        borderStyle: 'solid',
        borderColor: fade(theme.palette.common.black, 0.15),

        '& .MuiAutocomplete-option': {
            padding: '0 16px',
        },
    },
    inputField: (props) => ({
        fontSize: props.fontSize,
    }),
    titleBlock: (props) => ({
        marginLeft: props.marginLeft,
        '& p': {
            fontSize: props.fontSize,
        },
    }),
}));
