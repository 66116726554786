import {
    INVITE_USERS_REQUEST_FAILED,
    INVITE_USERS_REQUEST_SUCCEEDED,
    INVITE_USERS_REQUESTED,
    INVITE_USERS_RESET,
} from './actions';
import i18n from '../../localization/i18n';

const initialState = {
    loading: false,
    success: false,
    failure: false,
    errors: [],
    messages: [],
};

export default function authReducer(state = initialState, action) {
    const { type, payload: { errors = [] } = {} } = action;

    switch (type) {
        case INVITE_USERS_REQUESTED: {
            return {
                ...state,
                loading: true,
                errors: [],
                messages: [],
                failure: false,
                success: false,
            };
        }
        case INVITE_USERS_REQUEST_SUCCEEDED: {
            return {
                ...state,
                success: true,
                messages: [i18n.t('signUp.inviteSucceeded')],
                errors: [],
                loading: false,
            };
        }
        case INVITE_USERS_REQUEST_FAILED: {
            return {
                ...state,
                loading: false,
                errors: errors,
                success: false,
            };
        }
        case INVITE_USERS_RESET: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
}
