const DEFAULT_IMAGES = {
    favicon: require(`../assets/images/favicon.ico`),

    challengeSampleBreakfast: require('../assets/images/challengeSamples/breakfast.jpeg'),
    challengeSampleCamera: require('../assets/images/challengeSamples/camera.jpeg'),
    challengeSampleBurger: require('../assets/images/challengeSamples/burgers.jpeg'),
    challengeSampleGreen: require('../assets/images/bg_default-active-challange.jpg'),

    mobileScreen: require('../assets/images/mobile_screen.png'),
    noContent: require('../assets/images/no-content.png'),
    notFound: require('../assets/images/not_found.png'),
    defaultPinIcon: require('../assets/images/defaultPinIcon.png'),
    defaultImage: require('../assets/images/defaultImage.png'),
    logoJBGray: require('../assets/images/logo-jb-gray.png'),
    defaultActivityIcon: require('../assets/images/defaultActivityIcon.png'),
    defaultActionsIcon: require('../assets/images/defaultActionsIcon.png'),
    yammerBtnIcon: require('../assets/images/yammer_btn_icon.png'),
    oktaBtnIcon: require('../assets/images/okta_btn_icon.png'),
    loader: require('../assets/images/loader.gif'),
    iosNotifications: require('../assets/images/ios_notifications.png'),
    notificationWrapper: require('../assets/images/notification_wrapper.png'),
    notificationBuzzIcon: require('../assets/images/notification_buzz_icon.png'),
    inviteSignUpBg: require('../assets/images/bg_signup_invite.jpg'),
    challengeCompetitive: require('../assets/images/challenge_competitive.png'),
    challengeIndividuals: require('../assets/images/challenge_individuals.png'),
    challengeDuration: require('../assets/images/challenge_duration.png'),
    plusOneBonusPoints: require('../assets/images/plus_one_bonus_points.png'),
    videosIcon: require('../assets/images/videos_icon.png'),
    linksIcon: require('../assets/images/links_icon.png'),
    verifyEmailIcon: require('../assets/images/verify_email_icon.png'),
    googleIcon: require('../assets/images/social/google.png'),
    onBoardingLogo: require('../assets/images/onboarding_logo.png'),
    onBoardingLogoWhite: require('../assets/images/onboarding_logo_white.png'),
    appleStoreBadge: require('../assets/images/apple_store_badge.png'),
    googleStoreBadge: require('../assets/images/google_store_badge.png'),
    bgEmailVerification1: require('../assets/images/bg_email_verification1.png'),
    bgEmailVerification2: require('../assets/images/bg_email_verification2.png'),
    bgOnBoarding1: require('../assets/images/bg_on_boarding1.png'),
    bgOnBoarding2: require('../assets/images/bg_on_boarding2.png'),
    loginBg1: require('../assets/images/login-bkgd-1.png'),
    loginBg2: require('../assets/images/login-bkgd-2.png'),
    bgWelcome: require('../assets/images/bg_welcome.png'),
    bgCommunity: require('../assets/images/bg_community.png'),
    bgSustainability: require('../assets/images/sustainability-2.png'),
    bgFitness: require('../assets/images/fitness-2.png'),
    bgNutrition: require('../assets/images/nutrition-2.png'),
    bgDiversity: require('../assets/images/diversity_inclusion.jpg'),
    bgEngagement: require('../assets/images/remote_engagement.jpg'),
    bgCivic: require('../assets/images/civic.jpg'),
    bgSmallSustainability: require('../assets/images/sustainability.png'),
    bgSmallFitness: require('../assets/images/fitness.png'),
    bgSmallNutrition: require('../assets/images/nutrition.png'),
    sustainabilityIcon: require('../assets/images/base_impact_eco.png'),
    sustainabilityWhiteIcon: require('../assets/images/base_impact_eco.png'),
    fitnessIcon: require('../assets/images/base_impact_fitness.png'),
    fitnessWhiteIcon: require('../assets/images/base_impact_fitness.png'),
    nutritionIcon: require('../assets/images/base_impact_nutrition.png'),
    nutritionWhiteIcon: require('../assets/images/base_impact_nutrition.png'),
    civicIcon: require('../assets/images/base_impact_civic.png'),
    civicWhiteIcon: require('../assets/images/base_impact_civic.png'),
    bgInviteScreen: require('../assets/images/bg_invite_screen.png'),
    bgChallengeCustomize: require('../assets/images/challenge_customize.png'),
    recycleIcon: require('../assets/images/base_impact_waste.png'),
    carbonIcon: require('../assets/images/carbon.png'),
    waterDropIcon: require('../assets/images/base_impact_water.png'),
    blankChallengeIcon: require('../assets/images/blank_challenge_icon.png'),

    sustainabilitySvgIcon: require('../assets/images/svg/sustainability-icon.svg'),
    civicSvgIcon: require('../assets/images/svg/civic-icon.svg'),
    competitiveSvgIcon: require('../assets/images/svg/competitive-icon.svg'),
    daysSvgIcon: require('../assets/images/svg/days-icon.svg'),
    diversitySvgIcon: require('../assets/images/svg/diversity-icon.svg'),
    fitnessSvgIcon: require('../assets/images/svg/fitness-icon.svg'),
    habitSvgIcon: require('../assets/images/svg/habits-icon.svg'),
    habitsSvgIcon: require('../assets/images/svg/habits-icon.svg'),
    individualSvgIcon: require('../assets/images/svg/individual-icon.svg'),
    nutritionSvgIcon: require('../assets/images/svg/nutrition-icons.svg'),
    remoteSvgIcon: require('../assets/images/svg/remote-icon.svg'),
    greenLogo: require('../assets/images/logo_green.png'),
    defaultActiveChallengeBg: require('../assets/images/bg_default-active-challange.jpg'),
    slackIcon: require('../assets/images/social/slack.png'),
    facebookIcon: require('../assets/images/social/facebook.png'),
    impactEcoIcon: require('../assets/images/base_impact_eco.png'),
    warningIcon: require('../assets/images/svg/warning-icon.svg'),
    closeIcon: require('../assets/images/svg/close-icon.svg'),

    '7Z': require('../assets/images/filetypes/7z.png'),
    AE: require('../assets/images/filetypes/ae.png'),
    AI: require('../assets/images/filetypes/ai.png'),
    AVI: require('../assets/images/filetypes/avi.png'),
    CSS: require('../assets/images/filetypes/css.png'),
    CSV: require('../assets/images/filetypes/csv.png'),
    DOC: require('../assets/images/filetypes/doc.png'),
    HTML: require('../assets/images/filetypes/html.png'),
    ICO: require('../assets/images/filetypes/ico.png'),
    JPG: require('../assets/images/filetypes/jpg.png'),
    JS: require('../assets/images/filetypes/js.png'),
    MOV: require('../assets/images/filetypes/mov.png'),
    MP3: require('../assets/images/filetypes/mp3.png'),
    MP4: require('../assets/images/filetypes/mp4.png'),
    MPEG: require('../assets/images/filetypes/mpeg.png'),
    PDF: require('../assets/images/filetypes/pdf.png'),
    PNG: require('../assets/images/filetypes/png.png'),
    PPT: require('../assets/images/filetypes/ppt.png'),
    PSD: require('../assets/images/filetypes/psd.png'),
    PY: require('../assets/images/filetypes/py.png'),
    RAR: require('../assets/images/filetypes/rar.png'),
    SVG: require('../assets/images/filetypes/svg.png'),
    SWF: require('../assets/images/filetypes/swf.png'),
    TIFF: require('../assets/images/filetypes/tiff.png'),
    TXT: require('../assets/images/filetypes/txt.png'),
    WAV: require('../assets/images/filetypes/wav.png'),
    WMV: require('../assets/images/filetypes/wmv.png'),
    XLS: require('../assets/images/filetypes/xls.png'),
    ZIP: require('../assets/images/filetypes/zip.png'),
    OTHER: require('../assets/images/filetypes/other.png'),

    defaultProfileIcon: require(`../assets/images/public/defaultProfileIcon.png`),
};
const DEFAULT_VARIANT_IMAGES = {
    logo: require(`../assets/images/joulebug/logo.png`),
    defaultChallengeIcon: require(`../assets/images/joulebug/defaultChallengeIcon.png`),
    defaultCommunityIcon: require(`../assets/images/joulebug/defaultCommunityIcon.png`),
    defaultProfileIcon: require(`../assets/images/joulebug/defaultProfileIcon.png`),
    defaultAppIcon: require(`../assets/images/joulebug/defaultAppIcon.png`),
    inAppNotifications: require(`../assets/images/joulebug/in_app_notifications.png`),
    welcomeProfile: require(`../assets/images/joulebug/welcome-profile.png`),
    welcomeEdit: require(`../assets/images/joulebug/welcome-edit.png`),
    welcomeActions: require(`../assets/images/joulebug/welcome-actions.png`),
    welcomeChallenges: require(`../assets/images/joulebug/welcome-challenges.png`),
    welcomeActivity: require(`../assets/images/joulebug/welcome-activity.png`),
    landingMockup: require(`../assets/images/joulebug/landing-mockup.png`),
    appMockup: require(`../assets/images/joulebug/app-mockup.png`),
    ...DEFAULT_IMAGES,
};
const IMAGES = {
    joulebug: {
        ...DEFAULT_VARIANT_IMAGES,
        genericAdminChallengeInvite: require(`../assets/images/joulebug/challenge-share-image.jpg`),
        genericCommunityInvite: require(`../assets/images/joulebug/community-share-image.jpg`),
    },
    public: {
        ...DEFAULT_IMAGES,
        logo: require(`../assets/images/joulebug/logo.png`),
        defaultChallengeIcon: require(`../assets/images/public/defaultChallengeIcon.png`),
        defaultCommunityIcon: require(`../assets/images/public/defaultCommunityIcon.png`),
        defaultProfileIcon: require(`../assets/images/public/defaultProfileIcon.png`),
        defaultAppIcon: require(`../assets/images/public/defaultAppIcon.png`),
        inAppNotifications: require(`../assets/images/public/in_app_notifications.png`),
        welcomeProfile: require(`../assets/images/public/welcome-profile.png`),
        welcomeEdit: require(`../assets/images/public/welcome-edit.png`),
        welcomeChallenges: require(`../assets/images/public/welcome-challenges.png`),
    },
    betterliving: {
        ...DEFAULT_IMAGES,
        logo: require(`../assets/images/betterLiving/logo.png`),
        defaultChallengeIcon: require(`../assets/images/betterLiving/defaultChallengeIcon.png`),
        defaultCommunityIcon: require(`../assets/images/betterLiving/defaultCommunityIcon.png`),
        defaultProfileIcon: require(`../assets/images/betterLiving/defaultProfileIcon.png`),
        defaultAppIcon: require(`../assets/images/betterLiving/defaultAppIcon.png`),
        inAppNotifications: require(`../assets/images/betterLiving/in_app_notifications.png`),
        welcomeProfile: require(`../assets/images/betterLiving/welcome-profile.png`),
        welcomeEdit: require(`../assets/images/betterLiving/welcome-edit.png`),
    },
    app: {
        ...DEFAULT_IMAGES,
        favicon: require(`../assets/images/wildForAll/logo.png`),
        faviconDarkMode: require(`../assets/images/wildForAll/logo-white.png`),
        onBoardingLogo: require(`../assets/images/wildForAll/logo.png`),
        defaultChallengeIcon: require(`../assets/images/wildForAll/defaultChallengeIcon.png`),
        defaultCommunityIcon: require(`../assets/images/wildForAll/defaultCommunityIcon.png`),
        defaultProfileIcon: require(`../assets/images/wildForAll/defaultProfileIcon.png`),
        welcomeProfile: require(`../assets/images/wildForAll/welcome-profile.png`),
        welcomeEdit: require(`../assets/images/wildForAll/welcome-profile.png`),
        welcomeChallenges: require(`../assets/images/wildForAll/welcome-challenges.png`),
        welcomeActions: require(`../assets/images/wildForAll/welcome-actions.png`),
        welcomeActivity: require(`../assets/images/wildForAll/welcome-activity.png`),
        metaImage: require('../assets/images/wildForAll/meta-image.jpg'),
        metaImageHorizontal: require('../assets/images/wildForAll/meta-image-horizontal.png'),
    },
    bu: {
        landingMockup: require(`../assets/images/bostonu/landing-mockup.png`),
        appMockup: require(`../assets/images/bostonu/app-mockup.png`),
        ...DEFAULT_IMAGES,
    },
    earthshare: {
        landingMockup: require(`../assets/images/earthshare/landing-mockup.png`),
        appMockup: require(`../assets/images/earthshare/app-mockup.png`),
        ...DEFAULT_IMAGES,
    },
    blackstoneenergy: {
        landingMockup: require(`../assets/images/blackstone/landing-mockup.png`),
        appMockup: require(`../assets/images/blackstone/app-mockup.png`),
        ...DEFAULT_IMAGES,
    },
    cemex: {
        landingMockup: require(`../assets/images/cemex/landing-mockup.png`),
        appMockup: require(`../assets/images/cemex/app-mockup.png`),
        ...DEFAULT_IMAGES,
    },
    ...DEFAULT_VARIANT_IMAGES,
};

export default IMAGES;
