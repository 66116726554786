export const COMMUNITY_PHOTOS_REQUESTED = 'COMMUNITY_PHOTOS_REQUESTED';
export const COMMUNITY_PHOTOS_REQUEST_SUCCEEDED =
    'COMMUNITY_PHOTOS_REQUEST_SUCCEEDED';
export const COMMUNITY_PHOTOS_REQUEST_FAILED =
    'COMMUNITY_PHOTOS_REQUEST_FAILED';
export const CHALLENGE_PHOTOS_REQUESTED = 'CHALLENGE_PHOTOS_REQUESTED';
export const CHALLENGE_PHOTOS_REQUEST_SUCCEEDED =
    'CHALLENGE_PHOTOS_REQUEST_SUCCEEDED';
export const CHALLENGE_PHOTOS_REQUEST_FAILED =
    'CHALLENGE_PHOTOS_REQUEST_FAILED';
export const CLEAR_COMMUNITY_PHOTOS = 'CLEAR_COMMUNITY_PHOTOS';

export const communityPhotosRequested = (request) => ({
    type: COMMUNITY_PHOTOS_REQUESTED,
    payload: request,
});

export const communityPhotosRequestSucceeded = ({ photos, skip }) => ({
    type: COMMUNITY_PHOTOS_REQUEST_SUCCEEDED,
    payload: {
        photos,
        skip,
    },
});

export const communityPhotosRequestFailed = (errors) => ({
    type: COMMUNITY_PHOTOS_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const challengePhotosRequested = (request) => ({
    type: CHALLENGE_PHOTOS_REQUESTED,
    payload: request,
});

export const challengePhotosRequestSucceeded = (response) => ({
    type: CHALLENGE_PHOTOS_REQUEST_SUCCEEDED,
    payload: response,
});

export const challengePhotosRequestFailed = (errors) => ({
    type: CHALLENGE_PHOTOS_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearCommunityPhotos = () => ({
    type: CLEAR_COMMUNITY_PHOTOS,
});
