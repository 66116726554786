import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: ({ removable }) => ({
        width: '100%',
        padding: '10px 16px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderTopRightRadius: removable ? 0 : '8px',
        borderBottomRightRadius: removable ? 0 : '8px',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '18px',

        '&:before, &:after': {
            display: 'none',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:active:not(.Mui-disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
        },
    }),
    input: {
        height: '24px',
        padding: 0,
    },
    removeButton: {
        height: '100%',
        backgroundColor: theme.custom.inputBackground,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderLeft: 'none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',

        '& svg': {
            color: theme.custom.textDarkColor,
        },

        '&:hover': {
            backgroundColor: theme.custom.inputBackground,

            '& svg': {
                color: '#562732',
            },
        },
    },
}));

export default useStyles;
