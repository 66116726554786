import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ConnectedNotificationForm } from './NotificationForm';
import NotificationsPreview from '../../common/NotificationsPreview';
import { CHALLENGE_SUBJECT_TYPE } from '../../notifications/constants';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getCreate,
    getNotificationData,
    getNotificationLoader,
    getUpdateErrors,
    getUpdateErrorStatus,
} from '../../notifications/selectors';
import {
    clearAllErrors,
    createNotificationRequest,
    notificationRequest,
    updateNotificationRequest,
} from '../../notifications/actions';
import { transformNotification } from '../../notifications/transforms';
import { GROUPS } from '../ChallengesScreen/constants';
import styles from './styles';
import ErrorModal from '../../common/ErrorModal';
import CommonPropTypes from '../../common/propTypes';
import { isGroupLoading } from '../ChallengesScreen/selectors';
import ConfirmModal from '../../common/ConfirmModal';
import RequestLoader from '../../common/RequestLoader';

const UpdateNotificationScreen = ({
    classes,
    communityId,
    notification,
    fetchNotification,
    createNotification,
    updateNotification,
    updateErrors,
    updateErrorStatus,
    clearErrors,
    loading,
    create,
}) => {
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const { params } = useRouteMatch();

    const language = useMemo(() => i18n.language, [i18n.language]);
    const notificationId = useMemo(() => params.notificationId, [
        params.notificationId,
    ]);
    const initData = useMemo(
        () =>
            notificationId
                ? transformNotification(notification, language)
                : create,
        [notificationId, notification, language, create],
    );
    const initCommunityId = useRef(communityId);

    const notificationPreviewData = (data) => ({
        message: data.message,
        sendDate: data.sendDate,
        iconUrl: data.iconUrl,
        isChallenge: data.subjectContentType === CHALLENGE_SUBJECT_TYPE,
    });

    const [isErrorShown, setErrorShown] = useState(false);
    const [formData, setFormData] = useState(null);
    const [pristine, setPristine] = useState(true);
    const [leaveModalShown, setLeaveModalShown] = useState(false);
    const [leaveLocation, setLeaveLocation] = useState(null);
    const [leaveConfirmed, setLeaveConfirmed] = useState(false);

    useEffect(() => {
        if (initCommunityId.current !== communityId) {
            history.push(`/notifications`);
            return;
        }
        if (!notificationId) return;
        fetchNotification({ communityId, notificationId });
    }, [communityId, notificationId, fetchNotification, history]);

    useEffect(() => setFormData(notificationPreviewData(initData)), [initData]);

    useEffect(() => {
        if (updateErrors != null) {
            setErrorShown(true);
        }
    }, [updateErrors]);

    const handleChangeValue = useCallback(
        (newData) => {
            setFormData({ ...formData, ...newData });
            setPristine(false);
        },
        [formData, setPristine],
    );

    const handleSubmit = (options) => {
        if (notificationId) {
            updateNotification({
                communityId,
                notificationId,
                options: { ...options, image: formData.image, language },
            });
        } else {
            createNotification({
                communityId,
                options: { ...options, image: formData.image, language },
            });
        }
        setPristine(true);
    };

    const handleModal = useCallback(() => {
        clearErrors();
        setErrorShown(false);
    }, [clearErrors]);

    useEffect(() => {
        if (leaveConfirmed) {
            history.push(leaveLocation);
        }
    }, [history, leaveConfirmed, leaveLocation]);

    return (
        <Grid container className={classes.container}>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                className={classes.wrapper}
            >
                <ConnectedNotificationForm
                    title={
                        params.notificationId
                            ? t('notifications.table.headers.edit')
                            : t('notifications.table.headers.create')
                    }
                    onChangeValue={handleChangeValue}
                    onSubmit={handleSubmit}
                    {...initData}
                />
            </Grid>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                className={classes.wrapper}
            >
                {formData && <NotificationsPreview notification={formData} />}
            </Grid>
            {isErrorShown && (
                <ErrorModal
                    error={{
                        message: updateErrors.join(';'),
                        status: updateErrorStatus,
                    }}
                    isOpen={isErrorShown}
                    onClose={handleModal}
                    onCancel={handleModal}
                />
            )}
            <Prompt
                when={!pristine}
                message={(nextLocation) => {
                    if (!leaveConfirmed) {
                        setLeaveLocation(nextLocation);
                        setLeaveModalShown(true);
                        return false;
                    }
                    return true;
                }}
            />
            <ConfirmModal
                title={t('unsavedTitle')}
                confirmBtnText={t('leave')}
                isOpen={leaveModalShown}
                setOpen={setLeaveModalShown}
                onConfirm={() => {
                    setLeaveConfirmed(true);
                    setLeaveModalShown(false);
                }}
                onClose={() => {
                    setLeaveModalShown(false);
                }}
                onCancel={() => {
                    setLeaveModalShown(false);
                }}
            >
                {t('unsavedWarning')}
            </ConfirmModal>
            <RequestLoader isLoading={loading} />
        </Grid>
    );
};

UpdateNotificationScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    notification: PropTypes.object,
    fetchNotification: PropTypes.func.isRequired,
    createNotification: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired,
    updateErrors: CommonPropTypes.errors,
    updateErrorStatus: PropTypes.number,
    clearErrors: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    create: PropTypes.object,
};

UpdateNotificationScreen.defaultProps = {
    notification: null,
    updateErrors: null,
    updateErrorStatus: null,
    loading: false,
    create: {},
};

const StyledUpdateChallengeScreen = withStyles(styles)(
    UpdateNotificationScreen,
);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    notification: getNotificationData(state),
    updateErrors: getUpdateErrors(state),
    updateErrorStatus: getUpdateErrorStatus(state),
    loading:
        getNotificationLoader(state) ||
        GROUPS.some((group) => isGroupLoading(state, group)),
    create: getCreate(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchNotification: (params) => dispatch(notificationRequest(params)),
    createNotification: (params) => dispatch(createNotificationRequest(params)),
    updateNotification: (params) => dispatch(updateNotificationRequest(params)),
    clearErrors: () => dispatch(clearAllErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledUpdateChallengeScreen);
