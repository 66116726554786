import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import { getAppImages } from '../../../utils';

const IosNotificationPreview = ({ classes, message }) => {
    const { t } = useTranslation();

    return (
        <Grid container item className={classes.container}>
            <div className={classes.screen}>
                <div className={classes.notification}>
                    <div className={classes.notificationTitle}>
                        <img
                            src={getAppImages().defaultAppIcon}
                            className={classes.notificationIcon}
                            alt={t('alt.notificationIcon')}
                        />
                        {t('appName')}
                    </div>
                    <div className={classes.notificationText}>
                        {message || t('notifications.yourCustomMessage')}
                    </div>
                </div>
            </div>
        </Grid>
    );
};

IosNotificationPreview.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.string,
};

IosNotificationPreview.defaultProps = {
    message: null,
};

export default withStyles(styles)(IosNotificationPreview);
