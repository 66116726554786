import React from 'react';
import { TextareaAutosize } from '@material-ui/core';

import useStyles from './styles';

const Textarea = (props) => {
    const classes = useStyles();
    return <TextareaAutosize className={classes.textarea} {...props} />;
};

export default Textarea;
