import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { ButtonsCellPropType } from '../propTypes';

const QuickActions = ({ classes, id, buttonsCell, onButtonsCell }) => {
    const { t } = useTranslation();
    return (
        <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
            {buttonsCell.map(({ key, tooltip, icon: Icon }) => (
                <div key={`${key}-${id}`}>
                    <Tooltip
                        data-test={`${key}Tooltip`}
                        title={t(tooltip)}
                        placement="top"
                    >
                        <IconButton
                            data-test={key}
                            className={classes.iconLink}
                            onClick={() => onButtonsCell(id, key)}
                        >
                            <Icon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                </div>
            ))}
        </Grid>
    );
};

QuickActions.propTypes = {
    id: PropTypes.string.isRequired,
    buttonsCell: ButtonsCellPropType.isRequired,
    onButtonsCell: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuickActions);
