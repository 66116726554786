import PropTypes from 'prop-types';

const TemplatePropTypes = {
    template: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        iconUrl: PropTypes.string.isRequired,
        photoUrl: PropTypes.string,
    }),
};

export default TemplatePropTypes;
