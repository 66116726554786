import React from 'react';
import {
    withStyles,
    BottomNavigationAction,
    BottomNavigation,
} from '@material-ui/core';
import { SupervisorAccount } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IcomoonIcon from '../../../../common/IcomoonIcon';
import styles from './styles';
import {
    VALUE_GENERAL_SUBJECT_TYPE,
    VALUE_CHALLENGE_SUBJECT_TYPE,
    VALUE_ACTION_SUBJECT_TYPE,
} from '../../../../notifications/constants';

const SubjectContentType = ({
    classes,
    value,
    generalTypeDisabled,
    challengeTypeDisabled,
    actionTypeDisabled,
    onChange,
}) => {
    const { t } = useTranslation();

    const renderNavAction = (props) => (
        <BottomNavigationAction
            classes={{
                root: classes.root,
                selected: classes.selected,
                wrapper: classes.wrapper,
                label: classes.label,
            }}
            {...props}
        />
    );

    return (
        <BottomNavigation
            classes={{
                root: classes.nav,
            }}
            showLabels
            value={value}
            onChange={onChange}
        >
            {renderNavAction({
                icon: <SupervisorAccount />,
                label: t('notifications.subjectGeneralTitle'),
                value: VALUE_GENERAL_SUBJECT_TYPE,
                disabled: value === VALUE_GENERAL_SUBJECT_TYPE,
                'data-intercom-target': 'subjectGeneralButton',
            })}
            {renderNavAction({
                icon: <IcomoonIcon icon="jb-challenge" />,
                label: t('challenge.descriptor'),
                value: VALUE_CHALLENGE_SUBJECT_TYPE,
                disabled:
                    challengeTypeDisabled ||
                    value === VALUE_CHALLENGE_SUBJECT_TYPE,
                'data-intercom-target': 'subjectChallengeButton',
            })}
            {renderNavAction({
                icon: <IcomoonIcon icon="jb-buzz" />,
                label: t('Action'),
                value: VALUE_ACTION_SUBJECT_TYPE,
                disabled:
                    actionTypeDisabled || value === VALUE_ACTION_SUBJECT_TYPE,
                'data-intercom-target': 'subjectActionButton',
            })}
        </BottomNavigation>
    );
};

SubjectContentType.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.string || PropTypes.number])
        .isRequired,
    generalTypeDisabled: PropTypes.bool,
    challengeTypeDisabled: PropTypes.bool,
    actionTypeDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

SubjectContentType.defaultProps = {
    generalTypeDisabled: false,
    challengeTypeDisabled: false,
    actionTypeDisabled: false,
};

export default withStyles(styles)(SubjectContentType);
