import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    onBoardingTitle: {
        fontWeight: '700',
        marginBottom: '10px',
        textAlign: 'center',
        margin: '0px 15px 0px 15px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 13px',
            '@media (orientation: landscape)': {
                textAlign: 'left',
            },
        },
    },

    verifyTitle: {
        fontWeight: '700',
        color: theme.palette.primary.main,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            padding: '0px 13px',
        },
    },
}));
