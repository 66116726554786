import moment from 'moment';
import { TIME_FORMAT, DATE_FORMAT } from './constants';

export const createChallengeStartString = (startDate, t) => {
    return `${t('activeChallenges.challengeStartAt')} ${moment(
        startDate,
    ).format(TIME_FORMAT)} ${t('activeChallenges.on')} ${moment(
        startDate,
    ).format(DATE_FORMAT)}`;
};
export const createChallengeEndString = (endDate, t) => {
    return `${t('activeChallenges.challengeEndsAt')} ${moment(endDate).format(
        TIME_FORMAT,
    )} ${t('activeChallenges.on')} ${moment(endDate).format(DATE_FORMAT)}`;
};
