import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import styles from './styles';

import ConfirmModal from '../ConfirmModal';
import FormInput from '../Form/FormInput';
import { noop } from '../../utils';
import { getVideoId, toVideoURL } from '../../actions/transforms';
import { VideoPropType } from '../../actions/propTypes';
import Textarea from '../Textarea';
import ModalTitle from '../ModalTitle';
import { INTERCOM_TARGET_PREFIX } from '../../screens/UpdateActionScreen/constants';

const initialValues = (video) => ({
    video: video?.videoId || '',
    name: video?.name || '',
    description: video?.description || '',
});

const validationSchema = Yup.object().shape({
    video: Yup.string().required('VideoID or URL is required.'),
    name: Yup.string(),
    description: Yup.string(),
});

const ActionVideo = ({
    classes,
    isOpen,
    setOpen,
    onClose,
    onCancel,
    onConfirm,
    initialVideo,
}) => {
    const { t } = useTranslation();
    return (
        <Formik
            initialValues={initialValues(initialVideo)}
            validationSchema={validationSchema}
            onSubmit={onConfirm}
            enableReinitialize
        >
            {({ isValid, ...formikProps }) => {
                const handleClose = () => {
                    onClose();
                    setOpen(false);
                };
                const handleCancel = () => {
                    onCancel();
                    setOpen(false);
                };
                const handleConfirm = () => {
                    onConfirm({
                        ...formikProps.values,
                        video: getVideoId(formikProps.values.video),
                    });
                };
                return (
                    <ConfirmModal
                        title={
                            <ModalTitle
                                text={t('actions.editVideos')}
                                tooltipText={t('viewDocs')}
                            />
                        }
                        isOpen={isOpen}
                        confirmBtnText={t('OK')}
                        confirmBtnType="accent"
                        cancelBtnText={t('cancel')}
                        cancelBtnType="default"
                        onClose={handleClose}
                        onCancel={handleCancel}
                        onConfirm={handleConfirm}
                        confirmBtnDisabled={
                            formikProps.values.video === '' || !isValid
                        }
                    >
                        <Grid container direction="row" wrap="nowrap">
                            <Grid
                                container
                                item
                                direction="column"
                                className={classes.previewColumn}
                            >
                                <Grid item>
                                    <strong>{t('preview')}</strong>
                                </Grid>
                                <Grid item>
                                    <iframe
                                        title="video preview"
                                        src={toVideoURL(
                                            formikProps.values.video,
                                        )}
                                        width="100%"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className={classes.previewVideo}
                                    />
                                </Grid>
                            </Grid>
                            <Form>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    className={classes.formColumn}
                                >
                                    <FormInput
                                        label={t('actions.editVideoUrlTitle')}
                                        tooltipText={t(
                                            'actions.editVideoUrlToolTip',
                                        )}
                                        tooltipIntercomTarget={`${INTERCOM_TARGET_PREFIX}EditVideoURLLabel`}
                                        name="video"
                                    />
                                    <FormInput
                                        label={t('actions.editVideoNameTitle')}
                                        tooltipText={t('internalOnly')}
                                        tooltipIntercomTarget={`${INTERCOM_TARGET_PREFIX}EditVideoNameLabel`}
                                        name="name"
                                    />
                                    <FormInput
                                        label={t('description')}
                                        tooltipText={t('internalOnly')}
                                        tooltipIntercomTarget={`${INTERCOM_TARGET_PREFIX}EditVideoDescriptionLabel`}
                                        as={Textarea}
                                        name="description"
                                        className={classes.textarea}
                                        rowsMin={5}
                                    />
                                </Grid>
                            </Form>
                        </Grid>
                    </ConfirmModal>
                );
            }}
        </Formik>
    );
};

ActionVideo.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    initialVideo: VideoPropType,
};

ActionVideo.defaultProps = {
    onClose: noop,
    onCancel: noop,
    initialVideo: null,
};

export default withStyles(styles)(ActionVideo);
