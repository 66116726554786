export default (theme) => ({
    container: {
        flexDirection: 'column',
        padding: '0 96px',
    },
    relative: {
        position: 'relative',
    },
    banner: {
        marginTop: '26px',
    },
});
