import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    photoContainer: {
        cursor: 'pointer',
    },
    container: {
        maxWidth: '500px',
        backgroundColor: '#e4e4e4',
    },
    photo: ({ border, maxHeight, hasImage }) => ({
        width: hasImage && maxHeight ? 'auto' : '100%',
        border: border ? `1px dashed ${theme.custom.bgDarkColor}` : 'none',
        backgroundColor: theme.palette.common.white,
        borderRadius: '6px',
    }),
    modal: {
        display: 'flex',
        justifyContent: 'center',
    },
    image: ({ borderRadius, maxHeight }) => ({
        display: 'block',
        height: maxHeight,
        width: '100%',
        borderRadius: borderRadius,
    }),
    title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
        fontSize: 'min(14px, 4vw)',
        fontWeight: 700,
    },
    icon: {
        marginLeft: '5px',
        color: theme.custom.iconColor,

        '&:hover': {
            color: theme.custom.iconColorHover,
        },
    },
    smallText: {
        fontSize: '85%',
    },
    defaultPhoto: {
        height: '70%',
        width: '70%',
        fill: theme.palette.primary.main,
    },
}));
