import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    linksHeader: {
        marginTop: '26px',
        marginBottom: '10px',
    },
    linksIcon: {
        marginRight: '8px',
    },
    linksLabel: {
        color: theme.custom.textDarkSecondaryColor,
        fontSize: '24px',
        fontWeight: '600',
    },
    buttonIcon: {
        marginRight: '3px',
    },
}));

export default useStyles;
