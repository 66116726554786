import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../ConfirmModal';
import {
    BUTTON_DANGER_COLOR,
    BUTTON_DEFAULT_COLOR,
} from '../../Button/constants';
import { noop } from '../../utils';

const ActivityOptionsPopper = ({
    content,
    options,
    onSelect,
    isLoading,
    ...props
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isConfirmationShown, setConfirmationShown] = useState(false);
    const [optionId, setSelectedOptionId] = useState(null);

    useEffect(() => {
        if (!isLoading) {
            handleClose();
        }
    }, [isLoading]);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setConfirmationShown(false);
    };

    const id = 'demo-positioned-button';

    return (
        <>
            <ConfirmModal
                isOpen={isConfirmationShown}
                title={t('flag.descriptor')}
                confirmBtnText={t('flag.descriptor')}
                confirmBtnLoading={isLoading}
                confirmBtnDisabled={isLoading}
                cancelBtnDisabled={isLoading}
                confirmBtnType={BUTTON_DANGER_COLOR}
                cancelBtnText={t('cancel')}
                cancelBtnType={BUTTON_DEFAULT_COLOR}
                onClose={handleClose}
                onCancel={handleClose}
                onConfirm={() => {
                    onSelect(content, optionId);
                }}
            >
                <Grid container direction="column">
                    <Grid item>{t('flag.reportConfirmationDescription')}</Grid>
                </Grid>
            </ConfirmModal>
            <Grid alignItems="center" justify="center">
                <Button
                    id={id}
                    aria-controls={open ? id : ''}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : ''}
                    onClick={handleClick}
                >
                    . . .
                </Button>
                <Menu
                    id="activity-options-menu"
                    aria-labelledby={id}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {options.map((option, key) => {
                        return (
                            <MenuItem
                                key={key}
                                onClick={() => {
                                    setSelectedOptionId(option.id);
                                    setConfirmationShown(true);
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Grid>
        </>
    );
};

ActivityOptionsPopper.propTypes = {
    // pass back with the onSelect callback for context.
    content: PropTypes.object,
    // An object containing all the different options that will be available in the popper
    options: PropTypes.arrayOf(
        PropTypes.shape({
            // What is displayed to the user
            label: PropTypes.string,
            // a unique id for this option
            id: PropTypes.string,
        }),
    ),
    /*
     * Called whe one of the options in the popper is selected
     * passes the content and the option that was selcted as it's args
     */
    onSelect: PropTypes.func,
    isLoading: PropTypes.bool,
};

ActivityOptionsPopper.defaultProps = {
    content: {},
    options: [],
    onSelect: noop,
    isLoading: false,
};

export default ActivityOptionsPopper;
