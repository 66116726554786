import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserName, isUserViewActive } from '../../user/selectors';
import { AppBar, Grid, Toolbar, withStyles } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import UserDropdown from '../UserDropdown';
import styles from './styles';
import { getAppName } from '../../utils';
import { logoutRequested } from '../../auth/actions';
import { toggleUserView } from '../../user/actions';
import { Can } from '../../casl/ability-context';

const PageHeader = ({
    classes,
    userName,
    onClick,
    showHamburger,
    onLogout,
    toggleUserView,
    isUserViewActive,
}) => {
    const { t } = useTranslation();

    const handleUserViewToggle = () => {
        toggleUserView();
    };

    const menuItems = [
        { label: t('profile.descriptor'), url: '/profile/self' },
        { label: t('Logout'), url: '/login', onClick: () => onLogout() },
    ];
    const prevAsAdminMenuItems = [
        {
            label: t('switchDesktopUser'),
            url: '/',
            onClick: () => handleUserViewToggle(),
        },
        ...menuItems,
    ];
    const prevAsUserMenuItems = [
        {
            label: t('switchAdmin'),
            url: '/',
            onClick: () => handleUserViewToggle(),
        },
        ...menuItems,
    ];

    return (
        <AppBar className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <Grid
                    container
                    item
                    alignItems="center"
                    justify="space-between"
                    direction="row"
                >
                    <Grid
                        container
                        item
                        alignItems="center"
                        className={classes.menuIconWrapper}
                        sm={9}
                        xs={7}
                        wrap="nowrap"
                    >
                        {showHamburger && (
                            <Menu
                                className={classes.menuIcon}
                                fontSize="large"
                                onClick={onClick}
                            />
                        )}
                        <Link to="/" className={classes.title}>
                            {getAppName()}
                        </Link>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.userDropdown}
                        justify="flex-end"
                        direction="row"
                        sm={3}
                        xs={5}
                    >
                        <Can not I="view" a="AdminScreens">
                            {isUserViewActive ? (
                                <UserDropdown
                                    title={userName}
                                    menuItems={prevAsUserMenuItems}
                                />
                            ) : (
                                <UserDropdown
                                    title={userName}
                                    menuItems={menuItems}
                                />
                            )}
                        </Can>

                        <Can I="view" a="AdminScreens">
                            <UserDropdown
                                title={userName}
                                menuItems={prevAsAdminMenuItems}
                            />
                        </Can>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

PageHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    userName: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    showHamburger: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
    toggleUserView: PropTypes.func.isRequired,
    isUserViewActive: PropTypes.bool,
};

PageHeader.defaultProps = {
    onClick: null,
    showHamburger: true,
    isUserViewActive: false,
};

export const StyledPageHeader = withStyles(styles)(PageHeader);

const mapStateToProps = (state) => ({
    userName: getUserName(state),
    isUserViewActive: isUserViewActive(state),
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch(logoutRequested()),
    toggleUserView: () => dispatch(toggleUserView()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledPageHeader);
