export const PREFIX = 'init/';
export const INITIALIZATION_SUCCEEDED = `${PREFIX}INITIALIZATION_SUCCEEDED`;
export const INITIALIZATION_FAILED = `${PREFIX}INITIALIZATION_FAILED`;
export const APP_STRINGS_REQUEST_SUCCEEDED = 'APP_STRINGS_REQUEST_SUCCEEDED';
export const APP_STRINGS_REQUEST_FAILED = 'APP_STRINGS_REQUEST_FAILED';

export function initializationSucceeded() {
    return {
        type: INITIALIZATION_SUCCEEDED,
    };
}

export function initializationFailed(errors = null) {
    return {
        type: INITIALIZATION_FAILED,
        payload: {
            errors,
        },
    };
}

export const appStringsRequestSucceeded = (data) => ({
    type: APP_STRINGS_REQUEST_SUCCEEDED,
    payload: data,
});

export const appStringsRequestFailed = (errors) => ({
    type: APP_STRINGS_REQUEST_FAILED,
    payload: {
        errors,
    },
});
