import IMAGES from '../../constants/images';

const styles = (theme) => ({
    container: {},
    pictureColumn: {
        height: 'calc(100vh - 65px)',
        width: '100%',
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 75%, rgba(0,0,0,0.75)), url(${IMAGES.bgWelcome})`,
        backgroundSize: 'cover',
        position: 'relative',
        backgroundPosition: 'bottom',
    },
    mainContent: {
        padding: '0px 0px 0px 100px',
        [theme.breakpoints.down('md')]: {
            padding: '10% 32px 10% 32px',
            '@media (orientation: landscape)': {
                padding: '25% 32px 25% 32px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '34% 32px 34% 32px',
            '@media (orientation: landscape)': {
                padding: '5% 32px 5% 32px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: '13px',
            paddingTop: '12%',
            paddingBottom: '12%',
            '@media (orientation: landscape)': {
                paddingTop: '5%',
                paddingBottom: '5%',
            },
        },
    },
    title: {
        fontWeight: '700',
        [theme.breakpoints.down('md')]: {
            paddingTop: '5%',
        },
    },
    formContainer: {
        padding: '20px 0px 0px 0px',
        marginLeft: '0px',
        width: '60%',
    },
    subtitle: {
        marginTop: '15px',
        marginBottom: '15px',
    },
    skipLink: {
        color: theme.palette.primary.main,
        fontFamily: 'effra,"Helvetica Neue",Helvetica,Arial,sans-serif',
    },
});

export default styles;
