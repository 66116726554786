const styles = (theme) => ({
    container: {
        zIndex: 1000,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    wrapper: {
        zIndex: 1002,
        position: 'relative',
        width: 'auto',
        padding: '10px 15px',
        margin: '15px',
        borderRadius: '4px',
        backgroundColor: '#f5f6f7',
    },
    loader: {
        maxWidth: '100px',
        height: '40px',
        borderRadius: '6px',
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    overlay: {
        zIndex: 1001,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#e0e0e0',
        opacity: 0.8,
    },
});

export default styles;
