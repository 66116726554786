import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    place: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 0,
        width: '35px',
        margin: '0 8px',
        padding: '8px 4px 8px',
        fontSize: '24px',
        color: theme.custom.textDarkColor,
    },
    disabled: {
        opacity: theme.custom.disabledOpacity,
    },
    image: (props) => ({
        width: '42px',
        height: '42px',
        margin: `8px 6px 8px ${props.hideRanks ? '24px' : '4px'}`,
        borderRadius: props.imageIsRounded && '6px',
        maxWidth: 'none',
    }),
    label: {
        padding: '8px',
        fontSize: '18px',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    infoIcon: {
        padding: '8px',
        paddingRight: '16px',
        color: theme.custom.textDarkSecondaryColor,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    infoIconRoot: {
        padding: 0,
    },
    value: {
        padding: '8px',
        paddingRight: '16px',
        fontSize: '34px',
        textAlign: 'left',
    },
    listItem: ({ hasOnClick, isPenalty }) => ({
        display: 'flex',
        overflow: 'hidden',
        border: '1px solid transparent',
        borderBottomColor: theme.custom.bgSeparator,
        color: theme.custom.textDarkColor,
        cursor: hasOnClick ? 'pointer' : 'auto',
        '&:last-child': {
            borderBottomColor: 'transparent',
        },
        opacity: isPenalty ? 0.25 : 1,
    }),
    icon: {
        margin: '8px',
        padding: '7px',
        borderRadius: '6px',
        fontSize: '38px',

        '&:hover': {
            backgroundColor: theme.custom.bgDarkHover,
            cursor: 'pointer',
        },
    },
    rightBlock: {
        flex: 1,
        minWidth: '20px',
    },
    leftBlock: {
        flex: 3,
        minWidth: '20px',
    },
    emptyText: {
        padding: '20px 0',
        fontSize: '14px',
        lineHeight: 1.1,
        fontWeight: 600,
        textAlign: 'center',
        color: theme.custom.textDarkColor,
        opacity: 0.5,
    },
}));

export default useStyles;
