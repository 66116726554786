import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns'; // needed to parse & format timeseries data in x/y axis

const LineChart = ({ classes, options, data }) => {
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const chartDefaultOptions = {
            responsive: true,
            legend: {
                display: false,
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    display: true,
                    grid: {
                        display: true,
                    },
                },
                y: {
                    display: true,
                    grid: {
                        display: true,
                    },
                    title: {
                        display: true,
                    },
                },
            },
            interaction: {
                mode: 'index',
                intersect: true,
            },
            ...options,
        };

        setChartOptions(chartDefaultOptions);
    }, [options]);
    return (
        <div className={classes.chartWrapper}>
            <Line data={data} options={chartOptions} />
        </div>
    );
};

LineChart.propTypes = {
    classes: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(LineChart);
