import { Grid, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import React from 'react';
import useStyles from './styles';

const PhotoAlternatePlaceholder = ({ containerStyle }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            className={classes.dragAndDropContainer}
            style={containerStyle}
        >
            <Trans i18nKey="photo.drop.chooseFile">
                <Typography className={classes.dragAndDropText} />
                <Typography className={classes.dragAndDropText} />
                <Typography className={classes.dragAndDropChooseText} />
            </Trans>
        </Grid>
    );
};

export default PhotoAlternatePlaceholder;
