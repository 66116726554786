import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import FormInput from '../../Form/FormInput';
import Button from '../../Button';
import { noop } from '../../utils';
import { getCurrencyString } from '../../../subscriptions/utils';
import { SUBSCRIPTION_BUCKET_QUANTITY } from '../../../subscriptions/constants';

export const CostCalculator = ({
    monthlyRate,
    yearlyRate,
    minParticipants,
    maxParticipants,
    billYearly,
    setBillYearly,
    participants,
    setParticipants,
}) => {
    const { t } = useTranslation();

    const [cost, setCost] = useState(minParticipants * yearlyRate);

    const updateBillingCycle = () => {
        setBillYearly(!billYearly);
        updateCost(!billYearly);
    };

    const updateCost = (billYearly) => {
        let currentParticipants = participants;
        if (participants < minParticipants) {
            currentParticipants = minParticipants;
        } else if (participants > maxParticipants) {
            currentParticipants = maxParticipants;
            setParticipants(currentParticipants);
        } else if (participants % SUBSCRIPTION_BUCKET_QUANTITY !== 0) {
            currentParticipants =
                participants +
                (SUBSCRIPTION_BUCKET_QUANTITY -
                    (participants % SUBSCRIPTION_BUCKET_QUANTITY));
        }
        const cost =
            currentParticipants * (billYearly ? yearlyRate : monthlyRate);
        setCost(cost);
    };
    const currencyString = getCurrencyString(cost * 100, null);
    const split = currencyString.split('.');

    const classes = useStyles({ digits: split[0]?.length });

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.container}
        >
            <Grid container item className={classes.innerContainer}>
                <Grid
                    container
                    item
                    alignItems="flex-end"
                    className={classes.participantsRow}
                >
                    <Grid
                        container
                        item
                        direction="column"
                        className={classes.participantsFieldContainer}
                    >
                        <Typography className={classes.inputLabel}>
                            {t('onBoarding.community.countLabel')}
                        </Typography>
                        <FormInput
                            name="participants"
                            noLeftMargin
                            inputProps={{
                                className: classes.participantsField,
                            }}
                            fullWidth
                            noErrorMessage
                            useFormik={false}
                            noBottomMargin
                            max={maxParticipants}
                            defaultValue={minParticipants}
                            min={minParticipants}
                            value={participants}
                            onChange={(e) => {
                                setParticipants(parseInt(e.target.value) || 0);
                            }}
                        />
                    </Grid>
                    <Button
                        onClick={() => updateCost(billYearly)}
                        className={classes.calculateButton}
                        color="primary"
                    >
                        {t('account.plan.calculate')}
                    </Button>
                </Grid>
                <Grid container item>
                    <Grid container item style={{ flex: 1 }}>
                        <Grid alignItems="flex-end">
                            <span className={classes.costDollars}>
                                {split[0]}
                            </span>
                            <span className={classes.costSuffix}>
                                {'.' + split[1] + t('account.plan.billingRate')}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        style={{ flex: 1 }}
                    >
                        <Typography className={classes.inputLabel}>
                            {t('account.plan.billYearly')}
                        </Typography>
                        <Switch
                            onChange={updateBillingCycle}
                            checked={billYearly}
                        ></Switch>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

CostCalculator.propTypes = {
    monthlyRate: PropTypes.number,
    yearlyRate: PropTypes.number,
    minParticipants: PropTypes.number,
    maxParticipants: PropTypes.number,
    billYearly: PropTypes.bool,
    setBillYearly: PropTypes.func,
    participants: PropTypes.number,
    setParticipants: PropTypes.func,
};

CostCalculator.defaultProps = {
    monthlyRate: 1,
    yearlyRate: 0.8,
    minParticipants: 30,
    maxParticipants: 1000,
    billYearly: false,
    setBillYearly: noop,
    participants: 30,
    setParticipants: noop,
};

export default CostCalculator;
