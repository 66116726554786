const styles = (theme) => ({
    root: {
        width: '100%',
        borderRadius: '8px',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.custom.inputBackground,
        '&:before, &:after': {
            display: 'none',
        },

        '&:active:not(.Mui-disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&.Mui-focused': {
            borderColor: theme.palette.primary.dark,

            '& .MuiOutlinedInput-notchedOutline': {
                // border: `1px solid ${theme.palette.primary.main}`,
                borderRight: 'none',
                borderRadius: '6px 0px 0px 6px',
            },
        },

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            top: '0px',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,

            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
            },
        },
    },
    input: {
        width: '100%',
        padding: '14px 12px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '6px 0px 0px 6px',
        borderRight: 'none',
    },
    clearButton: {
        borderRadius: 0,
        maxHeight: 49,
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.custom.inputBackground,
        color: theme.palette.primary.dark,
        borderLeft: 'none',
        '&:hover': {
            backgroundColor: theme.custom.grey88,
        },
    },
    clearButtonWithError: {
        maxHeight: 49,
    },
    bottomMargin: {
        marginBottom: 16,
    },
});

export default styles;
