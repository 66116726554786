import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '10px',
    },
    icon: {
        padding: '10px 16px',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        backgroundColor: theme.palette.accent.main,
        borderColor: theme.palette.accent.main,
        cursor: 'pointer',
    },
    inputRoot: {
        width: '100%',
        padding: '10px 16px',
        border: '1px solid transparent',
        borderBottomLeftRadius: '8px',
        borderTopLeftRadius: '8px',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '18px',

        '&:before, &:after': {
            display: 'none',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:active:not(.Mui-disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&.Mui-focused': {
            borderColor: theme.palette.primary.dark,
        },
    },
    input: {
        height: '24px',
        padding: 0,
    },
}));

export default useStyles;
