import PropTypes from 'prop-types';

export const challengeInfoPropType = PropTypes.shape({
    description: PropTypes.string.isRequired,
    rulesType: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    contentFilterUrl: PropTypes.string.isRequired,
    contentFilterText: PropTypes.string.isRequired,
    howToWin: PropTypes.string.isRequired,
    rules: PropTypes.string.isRequired,
    prizes: PropTypes.array,
    autoJoin: PropTypes.bool.isRequired,
    makeChallengeCategory: PropTypes.bool.isRequired,
});
