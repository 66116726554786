import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { Help, InfoOutlined } from '@material-ui/icons';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import styles from './styles';

import Tooltip from '../Tooltip';
import ConfirmModal from '../ConfirmModal';
import FormInput from '../Form/FormInput';
import { noop } from '../../utils';
import LimitedLengthInput from '../LimitedLengthInput';
import {
    MAX_NAME_LENGTH,
    MIN_NAME_LENGTH,
} from '../../screens/CreateChallengeScreen/CustomizeChallenge/constants';

const ChallengeName = ({
    classes,
    isOpen,
    setOpen,
    name,
    onClose,
    onCancel,
    changeNameFunc,
}) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(
                MIN_NAME_LENGTH,
                t('challenge.name.length.min', { count: MIN_NAME_LENGTH }),
            )
            .max(MAX_NAME_LENGTH),
    });

    const handleClose = (formik) => {
        const { resetForm } = formik;
        setOpen(false);
        onClose();
        resetForm();
    };
    const handleCancel = (formik) => {
        const { resetForm } = formik;
        setOpen(false);
        onCancel();
        resetForm();
    };

    const handleSubmit = (values) => changeNameFunc(values.name);

    return (
        <Formik
            initialValues={{ name }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formikProps) => {
                const handleConfirm = () => {
                    if (!formikProps.errors.name) {
                        changeNameFunc(formikProps.values.name);
                        formikProps.resetForm();
                    }
                };

                const handleChange = (event) => {
                    if (event.target.value.length <= MAX_NAME_LENGTH) {
                        formikProps.handleChange(event);
                    }
                };
                return (
                    <ConfirmModal
                        isOpen={isOpen}
                        title={
                            <Typography variant="h4" className={classes.title}>
                                {t('challenge.name.edit')}
                                <Tooltip
                                    title={
                                        <span className={classes.tooltipText}>
                                            {t('viewDocs')}
                                        </span>
                                    }
                                    placement="right"
                                >
                                    <Help
                                        className={classes.tooltipIcon}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            </Typography>
                        }
                        confirmBtnText={t('OK')}
                        confirmBtnType="accent"
                        confirmBtnDisabled={!formikProps.isValid}
                        cancelBtnText={t('cancel')}
                        cancelBtnType="default"
                        onClose={() => handleClose(formikProps)}
                        onCancel={() => handleCancel(formikProps)}
                        onConfirm={handleConfirm}
                    >
                        <Grid container direction="column" wrap="nowrap">
                            <Grid item>
                                <Typography className={classes.label}>
                                    {t('challenge.name.descriptor')}
                                    <Tooltip
                                        title={
                                            <span
                                                className={classes.tooltipText}
                                            >
                                                {t('challenge.name.tooltip')}
                                            </span>
                                        }
                                        placement="right"
                                    >
                                        <InfoOutlined
                                            className={classes.tooltipIcon}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Form>
                                    <FormInput
                                        name="name"
                                        as={LimitedLengthInput}
                                        charactersLeft={
                                            MAX_NAME_LENGTH -
                                            formikProps.values.name.length
                                        }
                                        onChange={handleChange}
                                    />
                                </Form>
                            </Grid>
                        </Grid>
                    </ConfirmModal>
                );
            }}
        </Formik>
    );
};

ChallengeName.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    changeNameFunc: PropTypes.func.isRequired,
};

ChallengeName.defaultProps = {
    onClose: noop,
    onCancel: noop,
};

export default withStyles(styles)(ChallengeName);
