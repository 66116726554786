import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearAuthErrors } from '../../auth/actions';
import {
    getAuthMessages,
    getEmailVerificationErrors,
    getLoginErrors,
    getOrganizationCreationErrors,
    getPwResetErrors,
    getUserCreateErrors,
    isAuthLoading,
} from '../../auth/selectors';
import { isUserLoading } from '../../user/selectors';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import OnboardingHeader from '../../common/OnboardingHeader';
import OnboardingFooter from '../../common/OnboardingFooter';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import RequestLoader from '../../common/RequestLoader';
import { noop } from '../../utils';
import IMAGES from '../../constants/images';

export const SignInScreenTemplate = ({
    loginLoading,
    userLoading,
    loginErrors,
    userCreateErrors,
    passwordResetErrors,
    emailVerificationErrors,
    messages,
    orgCreateErrors,
    leftBackgroundImage,
    rightBackgroundImage,
    clearErrors,
    children,
}) => {
    const classes = useStyles({ leftBackgroundImage, rightBackgroundImage });
    const isLoading = loginLoading || userLoading;
    return (
        <Grid container className={classes.loginScreen} justify="center">
            <Grid container className={classes.container} justify="center">
                <Grid container item sm={12}>
                    <OnboardingHeader />
                </Grid>
                <Grid
                    container
                    item
                    className={classes.wrapper}
                    direction="column"
                    alignItems="center"
                    sm={8}
                    md={5}
                >
                    <RequestLoader
                        isLoading={isLoading}
                        classes={{ overlay: classes.overlay }}
                    />
                    {children}
                </Grid>
                <Grid container item sm={12}>
                    <OnboardingFooter />
                </Grid>
                <SnackBarAPIResponse
                    errors={[
                        ...loginErrors,
                        ...userCreateErrors,
                        ...passwordResetErrors,
                        ...emailVerificationErrors,
                        ...orgCreateErrors,
                    ]}
                    messages={messages}
                    clearResponse={clearErrors}
                />
            </Grid>
        </Grid>
    );
};

SignInScreenTemplate.propTypes = {
    loginLoading: PropTypes.bool,
    userLoading: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    clearErrors: PropTypes.func,
    emailVerificationErrors: PropTypes.arrayOf(PropTypes.string),
    loginErrors: PropTypes.arrayOf(PropTypes.string),
    userCreateErrors: PropTypes.arrayOf(PropTypes.string),
    passwordResetErrors: PropTypes.arrayOf(PropTypes.string),
    orgCreateErrors: PropTypes.arrayOf(PropTypes.string),
    leftBackgroundImage: PropTypes.string,
    rightBackgroundImage: PropTypes.string,
};

SignInScreenTemplate.defaultProps = {
    loginLoading: false,
    userLoading: false,
    messages: [],
    children: null,
    cancelOnboarding: noop,
    emailVerificationErrors: [],
    loginErrors: [],
    userCreateErrors: [],
    passwordResetErrors: [],
    orgCreateErrors: [],
    clearErrors: noop,
    leftBackgroundImage: IMAGES.loginBg2,
    rightBackgroundImage: IMAGES.loginBg1,
};

const mapStateToProps = (state) => ({
    loginErrors: getLoginErrors(state),
    userCreateErrors: getUserCreateErrors(state),
    passwordResetErrors: getPwResetErrors(state),
    emailVerificationErrors: getEmailVerificationErrors(state),
    orgCreateErrors: getOrganizationCreationErrors(state),
    messages: getAuthMessages(state),
    loginLoading: isAuthLoading(state),
    userLoading: isUserLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearErrors: () => dispatch(clearAuthErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignInScreenTemplate);
