import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import { Grid } from '@material-ui/core';

import useStyles from './styles';

import EmailInput, { emailInputProps } from '../EmailInput';

const EmailField = (props) => {
    const classes = useStyles();

    return (
        <>
            <Field as={EmailInput} {...props} />
            <ErrorMessage name={props.name}>
                {(message) => (
                    <Grid
                        container
                        item
                        justify="center"
                        className={classes.error}
                    >
                        <strong>{message}</strong>
                    </Grid>
                )}
            </ErrorMessage>
        </>
    );
};

EmailField.propTypes = {
    name: PropTypes.string.isRequired,
    ...emailInputProps,
};

export default EmailField;
