const baseUrl = window.location.host;
const protocol = baseUrl.includes('localhost') ? 'http' : 'https';

export const CREATE_SUCCESS_URL = (planId) =>
    `${protocol}://${baseUrl}/payment/success?planId=${planId}`;
export const ONBOARDING_CREATE_CANCEL_URL = `${protocol}://${baseUrl}/get-started/payment?cancel=true`;

export const CREATE_CANCEL_URL = `${protocol}://${baseUrl}/payment`;
export const MANAGE_RETURN_URL = `${protocol}://${baseUrl}/account?stripeCancellation=true`;

export const FREE_PLAN_INDEX = 0;

// The number of users allowed per bucket in our pricing tiers.
export const SUBSCRIPTION_BUCKET_QUANTITY = 5;
