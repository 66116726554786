import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography, Grid } from '@material-ui/core';
import TabbedSection from '../TabbedSection';
import IosNotificationPreview from './IosNotificationPreview';
import InAppNotificationPreview from './InAppNotificationPreview';
import styles from './styles';

const NotificationsPreview = ({ classes, notification }) => {
    const { t } = useTranslation();
    const iOSPreview = t('notifications.iosPreview');
    const inAppPreview = t('notifications.appPreview');

    const tabs = {
        [iOSPreview]: <IosNotificationPreview message={notification.message} />,
        [inAppPreview]: <InAppNotificationPreview {...notification} />,
    };

    const renderFooter = () => {
        return (
            <Typography className={classes.footer}>
                {t('notifications.previewFooter')}
            </Typography>
        );
    };

    return (
        <Grid item container className={classes.tabs}>
            <TabbedSection tabs={tabs} withFooter={renderFooter()} />
        </Grid>
    );
};

NotificationsPreview.propTypes = {
    classes: PropTypes.object.isRequired,
    notification: PropTypes.shape({
        message: PropTypes.string,
        subjectContentType: PropTypes.string,
        subject: PropTypes.string,
        iconUrl: PropTypes.string,
        sendDate: PropTypes.string,
    }),
};

NotificationsPreview.defaultProps = {
    notification: {},
};

export default withStyles(styles)(NotificationsPreview);
