import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles } from '@material-ui/core';
import moment from 'moment';

import styles from './styles';

import ConfirmModal from '../ConfirmModal';
import DatePicker from '../DatePicker';
import { getTimezoneAbbrev } from '../../utils/dates';
import { DateTimePicker } from '@material-ui/pickers';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../../constants/dates';
import {
    CHALLENGE_TEMPLATE_STATUS_DRAFT,
    CHALLENGE_TEMPLATE_STATUSES,
} from '../../constants/challenges';
import FieldLabel from '../FieldLabel';
import ModalTitle from '../ModalTitle';

const ChallengeDuration = ({
    classes,
    challengeStatus,
    isOpen,
    setOpen,
    startDate: initialStartDate,
    endDate: initialEndDate,
    onClose,
    onCancel,
    onConfirm,
}) => {
    const { t, i18n } = useTranslation();

    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    useEffect(() => {
        setStartDate(moment(initialStartDate));
        setEndDate(moment(initialEndDate));
    }, [initialStartDate, initialEndDate, setStartDate, setEndDate]);

    const [startDateValid, setStartDateValid] = useState(true);
    const [endDateValid, setEndDateValid] = useState(true);

    const handleClose = () => {
        onClose();
        setOpen(false);
    };
    const handleCancel = () => {
        onCancel();
        setOpen(false);
    };
    const handleConfirm = () => onConfirm({ startDate, endDate });

    const tzCode = getTimezoneAbbrev(i18n.language);

    /*
     * If the challenge start date is in the past and it's not a draft
     * We allow editing of start and end date
     */
    const alreadyStarted =
        moment().isAfter(startDate) &&
        challengeStatus !== CHALLENGE_TEMPLATE_STATUS_DRAFT;

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={
                <ModalTitle
                    text={t('challenge.durationFieldTitle')}
                    tooltipText={t('viewDocs')}
                />
            }
            confirmBtnText={t('OK')}
            confirmBtnType="accent"
            confirmBtnDisabled={!startDateValid || !endDateValid}
            cancelBtnText={t('cancel')}
            cancelBtnType="default"
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        >
            <Grid
                container
                direction="row"
                wrap="nowrap"
                className={classes.container}
            >
                <Grid
                    container
                    item
                    direction="column"
                    className={classes.column}
                >
                    <FieldLabel
                        text={`${t('challenge.startDate')} (${tzCode})`}
                        tooltipText={t('challenge.durationInfoTitle')}
                        intercomTarget="challengeStartDateLabel"
                    />
                    <Grid item>
                        <DatePicker
                            as={DateTimePicker}
                            disabled={alreadyStarted}
                            minDate={moment().add(1, 'hours')}
                            maxDate={moment(startDate).add(1, 'years')}
                            format={DATETIME_FORMAT_FOR_DATETIME_PICKERS}
                            value={startDate}
                            onChange={setStartDate}
                            minDateMessage={
                                alreadyStarted
                                    ? ''
                                    : t('datePickers.startDateInFuture')
                            }
                            onError={(errorMsg) =>
                                errorMsg &&
                                (alreadyStarted || setStartDateValid(false))
                            }
                            onAccept={() => setStartDateValid(true)}
                            strictCompareDates
                        />
                    </Grid>
                    {alreadyStarted && (
                        <Grid item>{t('challenge.durationFieldLocked')}</Grid>
                    )}
                </Grid>
                <Grid
                    container
                    item
                    direction="column"
                    className={classes.column}
                >
                    <FieldLabel
                        text={`${t('challenge.endDate')} (${tzCode})`}
                        tooltipText={t('challenge.durationEndFieldTooltip')}
                        intercomTarget="challengeEndDateLabel"
                    />
                    <Grid item>
                        <DatePicker
                            as={DateTimePicker}
                            minDate={startDate}
                            maxDate={moment(startDate).add(1, 'years')}
                            format={DATETIME_FORMAT_FOR_DATETIME_PICKERS}
                            value={endDate}
                            onChange={setEndDate}
                            strictCompareDates
                            minDateMessage={t('datePickers.endAfterStart')}
                            onError={(errorMsg) =>
                                errorMsg && setEndDateValid(false)
                            }
                            onAccept={() => setEndDateValid(true)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ConfirmModal>
    );
};

ChallengeDuration.propTypes = {
    classes: PropTypes.object.isRequired,
    challengeStatus: PropTypes.oneOf(CHALLENGE_TEMPLATE_STATUSES).isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChallengeDuration);
