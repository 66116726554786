import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography } from '@material-ui/core';
import styles from './styles';
import SnackBarAPIResponse from '../../SnackbarAlert/SnackBarAPIResponse';
import FormInput from '../../Form/FormInput';
import Form from '../../Form';
import * as Yup from 'yup';
import { noop } from '../../utils';

const Verification = ({
    classes,
    onSubmit,
    initialFormValues,
    messages,
    errors,
    clearSnackBar,
}) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .min(6, t('onBoarding.verify.codeLengthError.min'))
            .max(6, t('onBoarding.verify.codeLengthError.max'))
            .required(t('onBoarding.verify.codeRequired')),
    });

    return (
        <Form
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            submitBtnText={t('onBoarding.verifyAccount')}
            onSubmit={onSubmit}
            data-test="verifyForm"
        >
            <Typography className={classes.inputLabel}>
                {t('onBoarding.verify.codeLabel')}
            </Typography>
            <FormInput
                name="code"
                placeholder={t('onBoarding.verify.codePlaceholder')}
                data-test="codeInput"
                autoComplete="off"
            />
            <SnackBarAPIResponse
                messages={messages}
                errors={errors}
                clearResponse={clearSnackBar}
            />
        </Form>
    );
};

Verification.propTypes = {
    initialFormValues: shape({ code: PropTypes.string }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearSnackBar: PropTypes.func,
    classes: PropTypes.object.isRequired,
    messages: PropTypes.string.isRequired,
};

Verification.defaultProps = {
    initialFormValues: { code: '' },
    onSubmit: noop,
    clearSnackBar: noop,
};

export default withStyles(styles)(Verification);
