import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
    infoIcon: {
        marginLeft: '6px',
        fontSize: '28px',
        color: 'rgba(51,51,51,.25)',
        cursor: 'pointer',

        '&:hover': {
            color: 'rgba(51,51,51,.5)',
        },
    },
}));
