import { getAppImages } from '../../utils';
import React from 'react';
import { Edit, Visibility } from '@material-ui/icons';
import moment from 'moment/moment';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../../constants/dates';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

export const tableColumns = [
    {
        accessor: 'image',
        Cell: ({ value }) => (
            <img
                src={value || getAppImages().defaultCommunityIcon}
                width={42}
                height={42}
                style={{ borderRadius: '6px' }}
                alt="alt.profileIcon"
            />
        ),
        sortable: false,
    },
    {
        Header: 'name',
        accessor: 'name',
        Cell: ({ value }) => <span>{value}</span>,
    },
    {
        Header: 'visibility',
        accessor: 'visibility',
        Cell: ({ value }) => <span>{value}</span>,
    },
    {
        Header: 'Members',
        accessor: 'members',
        Cell: ({ value }) => <span>{value}</span>,
    },
    {
        Header: 'community.hasPassword',
        accessor: 'hasPassword',
        Cell: ({ value }) => (value ? <CheckIcon /> : <ClearIcon />),
    },
    {
        Header: 'created',
        accessor: 'created',
        Cell: (props) =>
            moment(props.value).format(DATETIME_FORMAT_FOR_DATETIME_PICKERS),
    },
    { accessor: 'actions' },
];

export const buttonsCell = [
    {
        key: 'editCommunityProfile',
        actions: 'edit',
        subject: 'CommunityProfile',
        tooltip: 'edit',
        icon: Edit,
    },
    {
        key: 'viewCommunityProfile',
        actions: 'view',
        subject: 'CommunityProfile',
        tooltip: 'view',
        icon: Visibility,
    },
];

export const DUMMY_PASSWORD = 'fh$@e()#ej';
