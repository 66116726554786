import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';

const ChallengeStats = ({
    classes,
    icon: Icon,
    placeholder,
    stat,
    color,
    flexJustify,
}) => {
    return (
        <Grid
            container
            direction="row"
            justify={flexJustify}
            alignItems="center"
        >
            <div className={classes.peopleIcon} data-test="iconContainer">
                <Icon style={{ fill: color, fontSize: 35 }} />
            </div>
            <div>
                <Typography
                    className={classes.challengeTitle}
                    style={{ color: color }}
                    variant="h3"
                    data-test="challengeStatValue"
                >
                    {stat}
                </Typography>
                <Typography
                    style={{ color: color }}
                    className={classes.challengeText}
                    data-test="challengeStatProperty"
                >
                    {placeholder}
                </Typography>
            </div>
        </Grid>
    );
};

ChallengeStats.propTypes = {
    icon: PropTypes.elementType.isRequired,
    classes: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    stat: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    flexJustify: PropTypes.string,
};

ChallengeStats.defaultProps = {
    flexJustify: 'flex-start',
};
export default withStyles(styles)(ChallengeStats);
