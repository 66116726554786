import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { withStyles, Grid } from '@material-ui/core';

import styles from './styles';

const Activate = ({ classes, description, isActivate, items, headerKey }) => {
    const { t } = useTranslation();
    const verb = isActivate ? t('activate') : t('deactivate');

    return (
        <Grid container direction="column">
            <Grid item className={classes.confirmMessage}>
                <Trans i18nKey={headerKey} count={items.length}>
                    Are you sure you want to
                    <strong> {{ verb }} </strong>
                    this item?
                </Trans>
            </Grid>
            {items.map(({ name, iconUrl, identifier }, index) => (
                <Grid
                    key={index}
                    container
                    item
                    direction="row"
                    wrap="nowrap"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <img src={iconUrl} width={40} height={40} alt="" />
                        </Grid>
                        <Grid item>{name}</Grid>
                    </Grid>
                    <Grid item className={classes.identifier}>
                        {identifier}
                    </Grid>
                </Grid>
            ))}
            <span className={classes.description}>{description}</span>
        </Grid>
    );
};

Activate.propTypes = {
    classes: PropTypes.object.isRequired,
    isActivate: PropTypes.bool.isRequired,

    // Should be a translation key
    headerKey: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            iconUrl: PropTypes.string.isRequired,
            identifier: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    description: PropTypes.string.isRequired,
};

export default withStyles(styles)(Activate);
