import { Grid } from '@material-ui/core';
import SearchBar from '../../../common/Search';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHALLENGE_ACTIONS_TABLE_COLUMNS } from './constants';
import TableManager from '../../ActionsScreen/AdminActionsScreen/TableManager';
import useStyles from './styles';
import PropTypes from 'prop-types';
import { noop } from '../../../utils';
import CategoryDropdown from '../../../common/CategoryDropdown';

export const ChallengeActionsTable = ({
    showThemesFilter,
    themes,
    fetchDataFunc,
    ...props
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [searchFilter, setSearchFilter] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('1');
    const [refetchData, setRefetchData] = useState(0);

    const columns = CHALLENGE_ACTIONS_TABLE_COLUMNS;

    const fetchFilteredData = useCallback(
        (args) => {
            args.categoryIds = [selectedCategoryId];
            fetchDataFunc(args);
        },
        [fetchDataFunc, selectedCategoryId],
    );

    return (
        <>
            <Grid
                item
                container
                direction="row"
                className={classes.searchContainer}
                alignItems="center"
                data-intercom-target="challengeActionsAddButton"
            >
                <Grid item container style={{ flex: 2 }}>
                    <SearchBar
                        placeholder={t('actions.search')}
                        handleTextChange={setSearchFilter}
                        runSearch={setSearchFilter}
                        data-intercom-target="Action Header Search"
                    />
                </Grid>
                {showThemesFilter && (
                    <Grid item container style={{ flex: 1, marginLeft: 15 }}>
                        <CategoryDropdown
                            onChange={(e) => {
                                setSelectedCategoryId(e.target.value);
                                setRefetchData(refetchData + 1);
                            }}
                            value={selectedCategoryId}
                            themes={themes}
                            placeholder={t('filterBy')}
                        />
                    </Grid>
                )}
            </Grid>
            <TableManager
                filter={searchFilter}
                columns={columns}
                shouldShowMultiSelect={false}
                intercomTargetPrefix="Challenge Actions"
                fetchDataFunc={fetchFilteredData}
                refetchData={refetchData}
                {...props}
            />
        </>
    );
};

ChallengeActionsTable.propTypes = {
    showThemesFilter: PropTypes.bool,
    themes: PropTypes.array,
    fetchDataFunc: PropTypes.func,
};

ChallengeActionsTable.defaultProps = {
    showThemesFilter: false,
    themes: [],
    fetchDataFunc: noop,
};

export default ChallengeActionsTable;
