import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    withStyles,
    Typography,
    IconButton,
    InputAdornment,
    Grid,
    useMediaQuery,
} from '@material-ui/core';
import styles from './styles';

import FormInput from '../Form/FormInput';
import * as Yup from 'yup';
import { noop } from '../utils';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import Button from '../Button';
import SnackBarAPIResponse from '../SnackbarAlert/SnackBarAPIResponse';
import theme from '../../theme';

const WelcomeForm = ({
    initialFormValues,
    onSubmit,
    errors,
    clearSnackBar,
    classes,
}) => {
    const { t } = useTranslation();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, t('onBoarding.welcome.passwordLength'))
            .required(t('onBoarding.welcome.passwordRequired')),
    });

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const iconProps = {
        color: 'primary',
        fontSize: 'large',
    };

    return (
        <div>
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ isValid, ...formikProps }) => (
                    <Form className={classes.form}>
                        <Grid
                            container
                            item
                            direction="column"
                            xs={10}
                            md={12}
                            spacing={isSmall ? 0 : 10}
                        >
                            <Grid
                                container
                                item
                                direction="row"
                                sm={8}
                                xs={11}
                                justifyContent="flex-start"
                            >
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        className={classes.title}
                                    >
                                        {t('onBoarding.welcome.title')}
                                    </Typography>
                                    <Typography className={classes.inputLabel}>
                                        <strong>
                                            {t(
                                                'onBoarding.welcome.passwordLabel',
                                            )}
                                        </strong>
                                    </Typography>
                                    <FormInput
                                        name="password"
                                        placeholder={t(
                                            'onBoarding.welcome.passwordPlaceholder',
                                        )}
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff
                                                            {...iconProps}
                                                        />
                                                    ) : (
                                                        <Visibility
                                                            {...iconProps}
                                                        />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                direction="row"
                                xs={11}
                                sm={8}
                                md={11}
                                className={classes.buttonContainer}
                            >
                                <Grid item xs={12} md={4}>
                                    <Button
                                        color="accent"
                                        size="large"
                                        type="submit"
                                        disabled={!isValid}
                                        className={classes.onBoardingPassword}
                                    >
                                        {t('onBoarding.common.next')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <SnackBarAPIResponse
                errors={errors}
                clearResponse={clearSnackBar}
            />
        </div>
    );
};

WelcomeForm.propTypes = {
    initialFormValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearSnackBar: PropTypes.func.isRequired,
};

WelcomeForm.defaultProps = {
    onSubmit: noop,
};

export default withStyles(styles)(WelcomeForm);
