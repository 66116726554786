import React from 'react';
import {
    Edit,
    Visibility,
    IndeterminateCheckBox,
    AddBox,
    Add,
} from '@material-ui/icons';
import { Typography } from '@material-ui/core';

export const baseColumns = [
    {
        accessor: 'iconUrl',
        Cell: ({ row }) => (
            <img
                src={row.original.iconUrl}
                style={{ marginRight: 15 }}
                alt=""
            />
        ),
        sortable: false,
    },
    { Header: 'name', accessor: 'name' },
    { Header: 'description', accessor: 'description' },
];
export const tableColumn = [
    ...baseColumns,
    {
        Header: 'action.sdgs',
        accessor: 'sustainableDevelopmentGoals',
        sortable: false,
        Cell: ({ row }) => (
            <div
                style={{
                    display: 'flex',
                    flexDirection: row,
                }}
            >
                {row.original.sustainableDevelopmentGoals
                    ?.filter((sdg) => sdg.color && sdg.number)
                    ?.slice(0, 3)
                    ?.map((sdg) => (
                        <div
                            key={sdg.number}
                            style={{
                                backgroundColor: `#${sdg.color}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 40,
                                width: 40,
                                marginLeft: 5,
                                marginRight: 5,
                                position: 'relative',
                            }}
                        >
                            <Typography style={{ color: 'white' }}>
                                {sdg.number}
                            </Typography>
                        </div>
                    ))}
                {row.original.sustainableDevelopmentGoals?.length > 3 && (
                    <div
                        style={{
                            flex: 1,
                        }}
                    >
                        <Add style={{ height: 40, width: 40 }} />
                    </div>
                )}
            </div>
        ),
    },
    { accessor: 'actions' },
];

export const activeButtonsCell = [
    {
        key: 'viewActionsAdmin',
        actions: 'view',
        subject: 'ActionsAdmin',
        tooltip: 'view',
        icon: Visibility,
    },
    {
        key: 'editActions',
        actions: 'edit',
        subject: 'Actions',
        tooltip: 'edit',
        icon: Edit,
    },
    {
        key: 'deactivateActionExclude',
        actions: 'edit',
        subject: 'CommunityActionExclude',
        tooltip: 'deactivate',
        icon: IndeterminateCheckBox,
    },
];

export const deactivatedButtonsCell = [
    {
        key: 'viewActionsAdmin',
        actions: 'view',
        subject: 'ActionsAdmin',
        tooltip: 'View',
        icon: Visibility,
    },
    {
        key: 'activateActionExclude',
        actions: 'edit',
        subject: 'CommunityActionExclude',
        tooltip: 'Activate',
        icon: AddBox,
    },
];

export const challengeAddButtonsCell = [
    {
        key: 'editChallenge',
        actions: 'edit',
        subject: 'Challenge',
        tooltip: 'add',
        icon: AddBox,
    },
];

export const challengeEditButtonsCell = [
    {
        key: 'viewActionsAdmin',
        actions: 'view',
        subject: 'ActionsAdmin',
        tooltip: 'view',
        icon: Visibility,
    },
    {
        key: 'editActions',
        actions: 'edit',
        subject: 'Actions',
        tooltip: 'edit',
        icon: Edit,
    },
    {
        key: 'editChallenge',
        actions: 'edit',
        subject: 'Challenge',
        tooltip: 'remove',
        icon: IndeterminateCheckBox,
    },
];

export const challengeViewButtonsCell = [
    {
        key: 'viewActionsAdmin',
        tooltip: 'view',
        icon: Visibility,
    },
];

export const VIEW_ACTION = 'view';
export const EDIT_ACTION = 'edit';
export const ACTIONS = 'Actions';

export const INTERCOM_TARGET_PREFIX = 'actions';
