import { createSelector } from 'reselect';
import { transformActivities, transformActivity } from './transforms';
import { coerceIntoErrors } from '../common/errors';

export const getActivitiesState = (state) => state.activities;

export const activitiesLoading = createSelector(
    [getActivitiesState, (state, activitiesType) => activitiesType],
    (state, activitiesType) => state.activities[activitiesType].loading,
);
export const getErrors = createSelector(getActivitiesState, (state) =>
    coerceIntoErrors(state.errors),
);

export const getMessages = createSelector(
    getActivitiesState,
    (state) => state.messages,
);

export const activityLoading = createSelector(
    getActivitiesState,
    (state) => state.activityDetail.loading,
);

export const activityErrors = createSelector(
    getActivitiesState,
    (state) => state.activityDetail.errors,
);
export const activityDetail = createSelector(getActivitiesState, (state) =>
    transformActivity(state.activityDetail.activity),
);

export const getLikeLoadingActivityId = createSelector(
    getActivitiesState,
    (state) => state.likePost.activityId,
);
export const getShareURLLoadingActivityId = createSelector(
    getActivitiesState,
    (state) => state.shareURL.activityId,
);
export const getAddCommentLoadingActivityId = createSelector(
    getActivitiesState,
    (state) => state.addComment.activityId,
);

export const getActivities = createSelector(
    [getActivitiesState, (state, activitiesType) => activitiesType],
    (state, activitiesType) => {
        const activities = state.activities[activitiesType]?.activities;
        return activities && activities.length > 0
            ? transformActivities(activities)
            : [];
    },
);
