import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: `29px 20px`,
        maxWidth: theme.custom.mainContentWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    introText: {
        paddingBottom: '10px',
    },
    communityCreateButton: { fontWeight: '700', fontSize: 24 },
}));

export default useStyles;
