import React, { Suspense, useEffect, useState } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { AbilityContext } from './casl/ability-context';
import ability from './casl/ability';
import createStore from './store';
import globalTheme from './theme';
import GlobalStyles from './theme/GlobalStyles';
import Router from './Router';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedAppGate } from './init/AppGate';
import Screen from './common/Screen';
import ErrorBoundary from './common/errors/ErrorBoundary';
import LoadingScreen from './common/LoadingScreen';
import { initAnalytics, trackEvent } from './utils/analytics';
import browserHistory from './history';
import { useInterval } from './utils';
import { hasGDPRConsent, setupCookieYes } from './utils/cookies';
export const { store, persistor } = createStore();

function App() {
    const inProd = process.env.NODE_ENV === 'production';
    const [interval, setInterval] = useState(inProd ? 1000 : 0);

    browserHistory.listen((location, _) => {
        window.scrollTo(0, 0);
        trackEvent('screen_view', {
            firebase_screen: location.pathname,
        });
    });
    useEffect(() => {
        if (inProd) {
            // Enable GDPR compliance tracking on production
            const script = setupCookieYes();
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [inProd]);

    useInterval(() => {
        if (hasGDPRConsent() || !inProd) {
            // Received consent, stop polling
            setInterval(null);
            initAnalytics();
        }
    }, interval);

    return (
        <StoreProvider store={store}>
            <MuiThemeProvider theme={globalTheme}>
                <Suspense fallback={<LoadingScreen />}>
                    <GlobalStyles />
                    <ErrorBoundary component={Screen}>
                        <AbilityContext.Provider value={ability}>
                            <PersistGate
                                loading={<LoadingScreen />}
                                persistor={persistor}
                            >
                                <ConnectedAppGate>
                                    {() => <Router history={browserHistory} />}
                                </ConnectedAppGate>
                            </PersistGate>
                        </AbilityContext.Provider>
                    </ErrorBoundary>
                </Suspense>
            </MuiThemeProvider>
        </StoreProvider>
    );
}

export default App;
