const styles = (theme) => ({
    text: {
        padding: '0 2px',
        fontWeight: 'bold',
    },
    small: {
        fontSize: '12px !important',
    },
});

export default styles;
