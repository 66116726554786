export const ACTIVE_STATUS_ACTIVE = 'Active';
export const ACTIVE_STATUS_DEACTIVATED = 'Deactivated';

export const GROUPS = [ACTIVE_STATUS_ACTIVE, ACTIVE_STATUS_DEACTIVATED];

// Used in place of an id in some places when referring to the current user
export const USER_SELF = 'self';

export const MANUALLY_JOINED = 'ManuallyJoined';
export const NOT_JOINED = 'NotJoined';

export const USER_ROLE_ORGANIZATION_PARTICIPANT = 'organizationParticipant';
export const USER_ROLE_ORGANIZATION_ADMIN = 'organizationAdmin';
export const USER_ROLE_ORGANIZATION_OWNER = 'organizationOwner';
