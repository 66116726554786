import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import PaymentPlanFooter from '../PaymentPlanFooter';
import UserCounter from '../UserCounter';

const DATE_FORMAT = 'MM/DD/YYYY';

const AccountPlan = ({ classes, accountPlanProps }) => {
    const { t } = useTranslation();

    // To be removed after successful merge of JB-1140
    const isImpactHidden = true;

    const isFreeTier = useMemo(() => accountPlanProps.rawAmount <= 0, [
        accountPlanProps,
    ]);

    return (
        <Grid className={classes.container} container direction="row">
            <Grid className={classes.content} item container xs={12}>
                <Grid item container xs={12}>
                    <Grid item>
                        <Typography
                            className={classes.accountType}
                            variant="h1"
                            data-test="account-type"
                        >
                            {accountPlanProps.accountType}
                        </Typography>
                    </Grid>
                    {isFreeTier && (
                        <Grid className={classes.trialTextContainer} item>
                            <Typography
                                className={classes.trialText}
                                variant="h6"
                                data-test="free-trial-subtext"
                            >
                                {t('account.plan.freeTrial.subtext', {
                                    userCount: accountPlanProps.maxUsers,
                                })}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={isFreeTier ? 12 : 6}>
                        <Typography
                            className={classes.description}
                            variant="body1"
                            data-test="description"
                        >
                            {accountPlanProps.description}
                        </Typography>
                    </Grid>
                </Grid>
                {accountPlanProps.rate && accountPlanProps.interval && (
                    <Grid item container xs={12}>
                        <Grid item container xs={12}>
                            <Grid item>
                                <Typography
                                    className={classes.rate}
                                    variant="h1"
                                >
                                    {accountPlanProps.rate +
                                        t('account.plan.billingRate')}
                                </Typography>
                            </Grid>
                            <Grid className={classes.subBillTextContainer} item>
                                <Typography
                                    className={classes.subBillText}
                                    variant="body1"
                                >
                                    {accountPlanProps.interval === 'month'
                                        ? t('account.plan.billed.monthly')
                                        : t('account.plan.billed.annually')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.countText}
                                variant="body1"
                            >
                                {t('account.plan.userCount', {
                                    max: accountPlanProps.maxUsers,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {accountPlanProps.maxUsers && (
                    <Grid
                        className={classes.counterContainer}
                        item
                        container
                        xs={12}
                        data-test="user-counter"
                    >
                        <UserCounter
                            accountType={accountPlanProps.accountType}
                            maxUsers={accountPlanProps.maxUsers}
                            currentUsers={accountPlanProps.currentUsers}
                            variant="account"
                        />
                    </Grid>
                )}
                {accountPlanProps.nextAmount && accountPlanProps.nextDate && (
                    <Grid
                        className={classes.nextPaymentContainer}
                        item
                        container
                        xs={12}
                    >
                        <Grid item xs={12}>
                            <Typography
                                className={classes.nextPayText}
                                variant="body1"
                                data-test="next-date"
                            >
                                {}
                                <Trans
                                    i18nKey={
                                        accountPlanProps.cancelAtPeriodEnd
                                            ? 'account.plan.cancelled'
                                            : 'account.plan.nextDate'
                                    }
                                    values={{
                                        nextBillDate: moment(
                                            accountPlanProps.nextDate,
                                        ).format(DATE_FORMAT),
                                    }}
                                >
                                    <b />
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.nextPayText}
                                variant="body1"
                                data-test="next-amount"
                            >
                                <b>{t('account.plan.nextAmount')}</b>
                                {accountPlanProps.nextAmount}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid className={classes.footerContainer} item container xs={12}>
                {!isImpactHidden && accountPlanProps.units?.length > 0 && (
                    <PaymentPlanFooter
                        sidePadding={false}
                        units={accountPlanProps.units}
                        userCount={accountPlanProps.estimateUserCount}
                        data-test="footer"
                    />
                )}
            </Grid>
        </Grid>
    );
};

const UnitsObject = {
    value: PropTypes.number,
    unit: PropTypes.string,
};

AccountPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    accountPlanProps: PropTypes.shape({
        accountType: PropTypes.string,
        description: PropTypes.string,
        rate: PropTypes.string,
        maxUsers: PropTypes.number,
        currentUsers: PropTypes.number,
        nextDate: PropTypes.string,
        nextAmount: PropTypes.string,
        rawAmount: PropTypes.number,
        interval: PropTypes.string,
        units: PropTypes.arrayOf(PropTypes.shape(UnitsObject)),
        estimateUserCount: PropTypes.number,
        cancelAtPeriodEnd: PropTypes.bool,
    }).isRequired,
};

export default withStyles(styles)(AccountPlan);
