import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import BUTTON_COLORS from './constants';
import RawButton from './RawButton';
import { CircularProgress, Grid, useTheme } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import theme from '../../theme';

const AdminButton = ({ children, color, loading, ...props }) => {
    const currentTheme = useTheme();
    const themes = {
        default: currentTheme.palette.default,
        primary: currentTheme.palette.primary,
        accent: currentTheme.palette.accent,
        danger: currentTheme.palette.error,
        warning: currentTheme.palette.warning,
        success: currentTheme.palette.success,
        info: currentTheme.palette.info,
        white: currentTheme.palette.white,
        gray: currentTheme.palette.gray,
        light: currentTheme.palette.light,
    };
    const selectedTheme = createTheme({
        ...currentTheme,
        palette: {
            primary: themes[color],
        },
    });
    return (
        <ThemeProvider theme={selectedTheme || theme}>
            <RawButton color={color} {...props}>
                <div
                    style={{
                        visibility: loading ? 'hidden' : 'visible',
                        width: '100%',
                        display: 'inherit',
                        alignItems: 'inherit',
                        justifyContent: 'inherit',
                    }}
                >
                    {children}
                </div>
                {loading && (
                    <Grid
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                        }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress
                            style={{
                                color: 'white',
                            }}
                            size={20}
                        />
                    </Grid>
                )}
            </RawButton>
        </ThemeProvider>
    );
};

AdminButton.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(BUTTON_COLORS),
    loading: PropTypes.bool,
};

AdminButton.defaultProps = {
    children: PropTypes.node,
    color: 'default',
    loading: false,
};

export default AdminButton;
