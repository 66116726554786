import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Check, Delete } from '@material-ui/icons';

import useStyles from './styles';
import FlaggedContentPropType from './propTypes';
import {
    getFlaggedContentErrors,
    getFlaggedContentTotalCount,
    getPresentableFlaggedContent,
    isFlaggedContentLoading,
} from '../../flags/selectors';
import {
    flagExcludeRequested,
    flaggedContentRequested,
    flagIgnoreRequested,
} from '../../flags/actions';

import HeaderPage from '../../common/HeaderPage';
import Table from '../../common/Table';
import { getTimezoneAbbrev } from '../../utils/dates';
import ContentActionConfirmationModal, {
    CONTENT_ACTION_MODAL_TYPE_DESTRUCTIVE,
} from '../../common/ContentActionConfirmationModal';
import ImagePreviewFlaggedContent from '../../common/ImagePreviewFlaggedContent';
import CommonPropTypes from '../../common/propTypes';
import { getActiveCommunityId } from '../../user/selectors';
import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import moment from 'moment';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../../constants/dates';

const API_SORT_FIELDS = {
    flags: 'count',
    createdDate: 'created',
    flaggedDate: 'flaggedOn',
};

const DEFAULT_SORT_BY = [
    {
        id: API_SORT_FIELDS.flaggedDate,
        desc: true,
    },
];

const FlaggedContentScreen = ({
    communityId,
    loading,
    errors,
    items,
    requestContent,
    requestActivityFlagExclude,
    requestActivityFlagIgnore,
}) => {
    const { t, i18n } = useTranslation();
    const tzCode = getTimezoneAbbrev(i18n.language);
    const classes = useStyles();

    const itemsById = useMemo(
        () =>
            items?.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: item,
                }),
                {},
            ),
        [items],
    );

    const [isPreviewShown, setPreviewShown] = useState(false);
    const [previewedImage, setPreviewedImage] = useState('');

    const handleIconClick = useCallback(
        (icon) => {
            setPreviewedImage(icon);
            setPreviewShown(true);
        },
        [setPreviewedImage, setPreviewShown],
    );

    const columns = useMemo(
        () => [
            {
                accessor: 'icon',
                Cell: ({ value }) => (
                    <img
                        src={value}
                        width={42}
                        height={42}
                        onClick={() => handleIconClick(value)}
                        alt=""
                    />
                ),
            },
            {
                Header: t('flagged.user'),
                accessor: 'user',
            },
            {
                Header: t('Comment'),
                accessor: 'comment',
            },
            {
                Header: t('Photo'),
                accessor: 'photo',
                Cell: ({ value }) =>
                    value ? (
                        <img
                            src={value}
                            width={42}
                            height={42}
                            onClick={() => handleIconClick(value)}
                            alt=""
                        />
                    ) : (
                        <></>
                    ),
            },
            {
                Header: `# ${t('Flags')}`,
                accessor: 'count',
            },
            {
                Header: `${t('created')} (${tzCode})`,
                accessor: 'created',
                Cell: (props) =>
                    // eslint-disable-next-line react/prop-types
                    moment(props.value).format(
                        DATETIME_FORMAT_FOR_DATETIME_PICKERS,
                    ),
            },
            {
                Header: `${t('flagged.descriptor')} (${tzCode})`,
                accessor: 'flaggedOn',
                Cell: (props) =>
                    // eslint-disable-next-line react/prop-types
                    moment(props.value).format(
                        DATETIME_FORMAT_FOR_DATETIME_PICKERS,
                    ),
            },
            {
                accessor: 'actions',
            },
        ],
        [tzCode, t, handleIconClick],
    );

    const approveButton = useMemo(
        () => ({
            key: 'approveFlaggedContent',
            actions: 'manage',
            subject: 'FlaggedUserActivity',
            tooltip: t('flags.table.actions.ignore.title'),
            icon: Check,
        }),
        [t],
    );

    const deleteButton = useMemo(
        () => ({
            key: 'deleteFlaggedContent',
            actions: 'manage',
            subject: 'FlaggedUserActivity',
            tooltip: t('flags.table.actions.flag.title'),
            icon: Delete,
        }),
        [t],
    );

    const buttonsCell = useMemo(() => [approveButton, deleteButton], [
        approveButton,
        deleteButton,
    ]);

    const [isApproveShown, setApproveShown] = useState(false);
    const [itemsToApprove, setItemsToApprove] = useState([]);

    const handleApprove = useCallback(
        (approvedIds) => {
            requestActivityFlagIgnore({
                communityId,
                activityId: approvedIds,
                commentId: itemsToApprove?.[0]?.commentId,
            });
            setApproveShown(false);
        },
        [requestActivityFlagIgnore, communityId, itemsToApprove],
    );

    const handleApproveClicked = useCallback(
        (id) => {
            const approvedItem = itemsById[id];
            setItemsToApprove([approvedItem]);
            setApproveShown(true);
        },
        [itemsById, setItemsToApprove, setApproveShown],
    );

    const [isDeleteShown, setDeleteShown] = useState(false);
    const [itemsToDelete, setItemsToDelete] = useState([]);

    const handleDelete = useCallback(
        (approvedIds) => {
            requestActivityFlagExclude({
                communityId,
                activityId: approvedIds,
                commentId: itemsToDelete?.[0]?.commentId,
            });
            setDeleteShown(false);
        },
        [requestActivityFlagExclude, communityId, itemsToDelete],
    );

    const handleDeleteClicked = useCallback(
        (id) => {
            const approvedItem = itemsById[id];
            setItemsToDelete([approvedItem]);
            setDeleteShown(true);
        },
        [itemsById, setItemsToDelete],
    );

    const handleButtonsCell = useCallback(
        (id, key) => {
            switch (key) {
                case approveButton.key:
                    handleApproveClicked(id);
                    break;
                case deleteButton.key:
                    handleDeleteClicked(id);
                    break;
                default:
                    break;
            }
        },
        [
            approveButton,
            deleteButton,
            handleApproveClicked,
            handleDeleteClicked,
        ],
    );

    useEffect(() => {
        requestContent({
            communityId,
            context: 'group',
            skip: 0,
            limit: 50,
        });
    }, [requestContent, communityId]);

    return (
        <Grid container direction="column" className={classes.container}>
            <HeaderPage title={t('flags.descriptor')} />
            <Grid container className={classes.wrapper}>
                <RequestLoader
                    isLoading={loading}
                    title={`${t('loading.flags')}...`}
                />
                <ErrorList errors={errors} />
                {!loading && errors == null && items != null ? (
                    <>
                        <ImagePreviewFlaggedContent
                            isOpen={isPreviewShown}
                            setOpen={setPreviewShown}
                            imageUrl={previewedImage}
                        />
                        <ContentActionConfirmationModal
                            isOpen={isApproveShown}
                            setOpen={setApproveShown}
                            items={itemsToApprove}
                            onConfirm={handleApprove}
                            title={t('flags.table.actions.ignore.title')}
                            description={t(
                                'flags.table.actions.ignore.description',
                            )}
                            header={t('flags.table.actions.ignore.header')}
                        />

                        <ContentActionConfirmationModal
                            isOpen={isDeleteShown}
                            setOpen={setDeleteShown}
                            items={itemsToDelete}
                            onConfirm={handleDelete}
                            title={t('flags.table.actions.flag.title')}
                            description={t(
                                'flags.table.actions.flag.description',
                            )}
                            header={t('flags.table.actions.flag.header')}
                            type={CONTENT_ACTION_MODAL_TYPE_DESTRUCTIVE}
                        />
                        <Table
                            title={t('flags.descriptor')}
                            columns={columns}
                            data={items}
                            buttonsCell={buttonsCell}
                            onButtonsCell={handleButtonsCell}
                            initialSortBy={DEFAULT_SORT_BY}
                            noItemFoundText={t('flagged.nonFound')}
                            clientPagination={true}
                        />
                    </>
                ) : null}
            </Grid>
        </Grid>
    );
};

FlaggedContentScreen.propTypes = {
    communityId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    items: PropTypes.arrayOf(FlaggedContentPropType),
    requestContent: PropTypes.func.isRequired,
    requestActivityFlagExclude: PropTypes.func.isRequired,
    requestActivityFlagIgnore: PropTypes.func.isRequired,
};

FlaggedContentScreen.defaultProps = {
    errors: null,
    items: null,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    loading: isFlaggedContentLoading(state),
    errors: getFlaggedContentErrors(state),
    items: getPresentableFlaggedContent(state),
    totalCount: getFlaggedContentTotalCount(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestContent: (request) => dispatch(flaggedContentRequested(request)),
    requestActivityFlagExclude: (request) =>
        dispatch(flagExcludeRequested(request)),
    requestActivityFlagIgnore: (request) =>
        dispatch(flagIgnoreRequested(request)),
});

const ConnectedFlaggedContentScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FlaggedContentScreen);
export default ConnectedFlaggedContentScreen;
