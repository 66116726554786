import PropTypes from 'prop-types';
import CommonPropTypes from '../propTypes';

export const columnsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        Header: PropTypes.string,
        accessor: PropTypes.string,
    }),
);

export const buttonsCellPropType = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string.isRequired,
        actions: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
        tooltip: PropTypes.string.isRequired,
        icon: CommonPropTypes.component,
        intercomTarget: PropTypes.string,
    }),
);

// Describes the initialSort prop
export const sortPropType = PropTypes.arrayOf(
    PropTypes.shape({
        // This is the id of the column to sort by.
        id: PropTypes.string.isRequired,

        // Indicate direction to sort the column by.
        desc: PropTypes.bool.isRequired,
    }),
);

export const dataPropType = PropTypes.array;
