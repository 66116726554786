import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    showMoreBtnItem: {
        marginTop: '6px',
    },
    noLinks: {
        opacity: 0.5,
        paddingTop: '10px',
        paddingBottom: '10px',
        fontWeight: 600,
    },
}));

export default useStyles;
