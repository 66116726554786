import PropTypes from 'prop-types';

export const activeCommunityPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
});

export const availableCommunitiesPropType = PropTypes.arrayOf(
    PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string,
        role: PropTypes.string,
        content: {
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        },
    }),
);
