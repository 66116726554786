import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { ONBOARDING_STATUS, VERIFY_STATUS } from './constants';
import SignUpForm from './SignUpForm';
import TopSection from './TopSection';
import Verification from './Verification';
import { noop } from '../utils';
import { getApplicationConfig } from '../../utils';
import GoogleButton from '../../screens/SignInScreens/OAuthButtons/GoogleButton';
import { LOGIN_METHOD_GOOGLE } from '../../properties/constants';

const OnBoarding = ({
    initialEmailFormValues,
    initialVerificationFormValues,
    status,
    onFormSubmit,
    onVerificationSubmit,
    onVerificationResend,
    errors,
    messages,
    clearSnackBar,
    emailAddress,
    stripePlanId,
    classes,
    toggleChecked,
    checked,
    toggleSubscribed,
    toggleSSOEnabled,
    onSSOClick,
}) => {
    const { t } = useTranslation();

    const handleFormSubmit = (values) => {
        onFormSubmit(values);
    };

    const handleVerificationSubmit = (values) => {
        onVerificationSubmit(values);
    };

    const handleResendVerification = () => {
        onVerificationResend();
    };

    const resendLinkText = (value) => {
        return (
            <span
                className={classes.resendText}
                data-test="resendEmailAction"
                onClick={() => {
                    handleResendVerification();
                }}
            >
                {t(value)}
            </span>
        );
    };

    const SSOButtons = getApplicationConfig()?.authCredentials?.reduce(
        (buttons, config) => {
            switch (config.authType) {
                case LOGIN_METHOD_GOOGLE:
                    buttons.push(
                        <Grid
                            key="google"
                            justifyContent="center"
                            container
                            style={{
                                marginBottom: 10,
                            }}
                        >
                            <GoogleButton
                                clientId={config.clientId}
                                onClick={() => onSSOClick(config)}
                            />
                        </Grid>,
                    );
                    break;
                default:
                    break;
            }
            return buttons;
        },
        [],
    );

    return (
        <Grid
            className={classes.onBoardingContainer}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid container direction="row">
                <Grid container item xs={12} justify="center">
                    <TopSection
                        status={status}
                        email={emailAddress}
                        stripePlanId={stripePlanId}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    className={classes.middleSection}
                >
                    {status === ONBOARDING_STATUS && (
                        <Grid item xs={11} md={5}>
                            {SSOButtons}
                            {SSOButtons?.length > 0 && (
                                <Grid
                                    container
                                    style={{ marginBottom: 10 }}
                                    justifyContent="center"
                                >
                                    <h2
                                        style={{
                                            borderBottom: '1px solid black',
                                            width: '100%',
                                            lineHeight: 0.1,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            style={{
                                                lineHeight: 0.1,
                                                backgroundColor: '#fff',
                                                padding: '0 5px',
                                            }}
                                        >
                                            {t('or')}
                                        </Typography>
                                    </h2>
                                </Grid>
                            )}
                            <SignUpForm
                                initialFormValues={initialEmailFormValues}
                                onSubmit={handleFormSubmit}
                                errors={errors}
                                clearSnackBar={clearSnackBar}
                                checked={checked}
                                toggleChecked={toggleChecked}
                                toggleSubscribed={toggleSubscribed}
                                toggleSSOEnabled={toggleSSOEnabled}
                            />
                        </Grid>
                    )}
                </Grid>
                {status === VERIFY_STATUS && (
                    <Grid
                        container
                        item
                        justifyContent="center"
                        direction="row"
                    >
                        <Grid
                            container
                            item
                            className={classes.alertContainer}
                            justify="center"
                            data-test="emailSubmitSuccess"
                        >
                            <Grid item xs={12} md={5}>
                                <Verification
                                    initialFormValues={
                                        initialVerificationFormValues
                                    }
                                    onSubmit={handleVerificationSubmit}
                                    messages={messages}
                                    errors={errors}
                                    clearSnackBar={clearSnackBar}
                                />
                                <Typography
                                    variant="subtitle2"
                                    className={classes.onBoardingSubtitle}
                                >
                                    {t('onBoarding.verify.subtitle2')}
                                    {resendLinkText('onBoarding.resend')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ marginTop: 10 }}>
                            <Typography>{t('or')}</Typography>
                        </Grid>
                        {SSOButtons}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

OnBoarding.propTypes = {
    initialEmailFormValues: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        employeeCount: PropTypes.object,
        orgName: PropTypes.string,
        website: PropTypes.string,
    }).isRequired,
    initialVerificationFormValues: PropTypes.shape({ code: PropTypes.string })
        .isRequired,
    status: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    onFormSubmit: PropTypes.func,
    onVerificationSubmit: PropTypes.func,
    onVerificationResend: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearSnackBar: PropTypes.func,
    stripePlanId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    toggleChecked: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    toggleSubscribed: PropTypes.func.isRequired,
    messages: PropTypes.string.isRequired,
    onSSOClick: PropTypes.func,
    toggleSSOEnabled: PropTypes.func,
};

OnBoarding.defaultProps = {
    initialEmailFormValues: { email: '' },
    initialVerificationFormValues: { code: '' },
    status: ONBOARDING_STATUS,
    emailAddress: '',
    onEmailLogin: noop,
    onMicrosoftLogin: noop,
    onGoogleLogin: noop,
    onFormSubmit: noop,
    onVerificationSubmit: noop,
    onVerificationResend: noop,
    clearSnackBar: noop,
    onSSOClick: noop,
    toggleSSOEnabled: noop,
};

export default withStyles(styles)(OnBoarding);
