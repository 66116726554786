const styles = (theme) => ({
    form: {
        width: '100%',
    },
    inputLabel: {
        color: theme.palette.primary.main,
        fontWeight: '600',
    },
    emailField: { fontSize: '18px' },
    fieldContainer: {
        position: 'relative',
    },
    fieldBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldBtnText: {
        paddingLeft: '10px',
        fontSize: '12px',
    },
    fieldBtn: {
        padding: '3px 10px',
    },
});

export default styles;
