import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

import useStyles from './styles';
import { Can } from '../../../casl/ability-context';
import Button from '../../../common/Button';

const PinSubheader = ({
    title,
    addText,
    imageSrc,
    imageAlt,
    editAction,
    onAdd,
}) => {
    const classes = useStyles();

    return (
        <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
            className={classes.linksHeader}
        >
            <Grid
                container
                item
                direction="row"
                alignItems="center"
                wrap="nowrap"
            >
                {imageSrc && (
                    <Grid item className={classes.linksIcon}>
                        <img
                            src={imageSrc}
                            alt={imageAlt}
                            width="24"
                            height="24"
                        />
                    </Grid>
                )}
                <Grid item className={classes.linksLabel}>
                    {title}
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="row"
                justify="flex-end"
                wrap="nowrap"
                className={classes.linksLabel}
            >
                <Can I="edit" a={editAction}>
                    {() => (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onAdd}
                            data-intercom-target="Add Link Button"
                        >
                            <AddCircle
                                fontSize="small"
                                className={classes.buttonIcon}
                            />
                            {addText}
                        </Button>
                    )}
                </Can>
            </Grid>
        </Grid>
    );
};

PinSubheader.propTypes = {
    title: PropTypes.string,
    addText: PropTypes.string,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    editAction: PropTypes.string,
    onAdd: PropTypes.func,
};

PinSubheader.defaultProps = {
    title: '',
    addText: '',
    imageSrc: '',
    imageAlt: '',
    editAction: '',
    onAdd: () => {},
};

export default PinSubheader;
