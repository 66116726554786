import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import UrlIcon from './UrlIcon';
import IcomoonIcon from '../IcomoonIcon';
import theme from '../../theme';
import { noop } from '../../utils';
import useStyles from './styles';

const CustomDropdown = ({
    id,
    data,
    value,
    placeholder,
    onChange,
    bgColor,
    fontSize,
    marginLeft,
    ...props
}) => {
    const { t } = useTranslation();
    const classes = useStyles({ bgColor, fontSize, marginLeft });

    const renderInput = (params) => (
        <TextField
            {...params}
            placeholder={placeholder || `${t('dropdownSwitch')}...`}
            fullWidth
            variant="outlined"
            rowsMax={8}
            inputProps={{
                ...params.inputProps,
                className: classes.inputField,
            }}
        />
    );

    const renderOption = ({
        iconUrl,
        iconName,
        title,
        subTitle,
        value,
        isChallenge,
    }) => (
        <Grid container alignItems="center" wrap="nowrap">
            {iconUrl ? (
                <UrlIcon
                    url={iconUrl}
                    title={title || value}
                    isChallenge={isChallenge}
                />
            ) : (
                iconName && (
                    <IcomoonIcon
                        size={24}
                        icon={iconName}
                        color={theme.palette.primary.main}
                    />
                )
            )}
            <Grid item container className={classes.titleBlock}>
                <Typography noWrap>{title || value}</Typography>
                {subTitle && <Typography>{subTitle}</Typography>}
            </Grid>
        </Grid>
    );

    return (
        <Autocomplete
            id={id}
            classes={classes}
            value={value}
            options={data?.filter((item) => item?.title || item?.value)}
            getOptionLabel={(option) => option.title || option.value}
            onChange={onChange}
            renderInput={renderInput}
            renderOption={renderOption}
            {...props}
        />
    );
};

CustomDropdown.propTypes = {
    id: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            title: PropTypes.string,
            subTitle: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            iconUrl: PropTypes.string,
            iconName: PropTypes.string,
            role: PropTypes.string,
        }),
    ),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    bgColor: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    groupBy: PropTypes.func,
    multiple: PropTypes.bool,
    fontSize: PropTypes.number,
    marginLeft: PropTypes.number,
};

CustomDropdown.defaultProps = {
    id: 'customDropdown',
    data: [],
    value: null,
    placeholder: null,
    bgColor: 'transparent',
    groupBy: noop,
    multiple: false,
    fontSize: 14,
    marginLeft: 0,
};

export default CustomDropdown;
