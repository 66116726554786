export default (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
        background: '#00000080',
        zIndex: 100,
        '& img': {
            width: '150px',
            height: '150px',
        },
    },
});
