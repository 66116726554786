import React, {
    Fragment,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { withStyles } from '@material-ui/styles';
import parse from 'html-react-parser';
import {
    Grid,
    Paper,
    Table as TableUI,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableCellCheckbox from './TableCellCheckbox';
import TableCellActions from './TableCellActions';
import TableToolbar from './TableToolbar';
import {
    buttonsCellPropType,
    columnsPropType,
    dataPropType,
    sortPropType,
} from './propTypes';
import { noop } from '../../utils';
import styles from './styles';
import ContentLoader from 'react-content-loader';
import { Pagination } from '@material-ui/lab';
import InfoTooltip from '../InfoTooltip';

const Table = memo(
    ({
        classes,
        title,
        noItemFoundText,
        columns,
        data,
        fetchDataFunc,
        searchFunc,
        searchString,
        multiActionButton,
        onRowSelect,
        buttonsCell,
        onButtonsCell,
        pageOptions,
        totalCount,
        initialPageSize,
        hideRowsPerPage,
        initialPageIndex,
        initialSortBy,
        clientPagination,
        loading,
        loaderRows,
        isBorderImg,
        intercomTargetPrefix,
        refetchData,
        activeCommunityId,
    }) => {
        const { t } = useTranslation();

        const [pageIndex, setPageIndex] = useState(initialPageIndex);

        const renderContentLoader = useCallback(
            () =>
                [...Array(loaderRows).keys()].map((key) => (
                    <ContentLoader
                        key={`${key}-loader`}
                        className={classes.contentLoaderContainer}
                    >
                        <rect
                            x="50"
                            y="26"
                            rx="3"
                            ry="3"
                            width="95%"
                            height="32"
                        />
                        <circle cx="22" cy="42" r="22" />
                    </ContentLoader>
                )),
            [classes.contentLoaderContainer, loaderRows],
        );

        const instance = useTable(
            {
                columns,
                data,
                initialState: {
                    pageSize: initialPageSize,
                    pageIndex: initialPageIndex,
                    sortBy: initialSortBy,
                },
                autoResetPage: !clientPagination,
                autoResetFilters: !clientPagination,
                manualSortBy: !clientPagination,
                manualPagination: !clientPagination,
            },
            useSortBy,
            usePagination,
            useRowSelect,
            (hooks) => {
                if (!multiActionButton) return;
                hooks.allColumns.push((columns) => [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <TableCellCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        ),
                        Cell: ({ row }) => (
                            <TableCellCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        ),
                    },
                    ...columns,
                ]);
            },
        );

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            gotoPage,
            setPageSize,
            state: { selectedRowIds, sortBy, pageSize },
        } = instance;

        const pc = useMemo(() => {
            let totalItems = totalCount;
            if (clientPagination) {
                totalItems = data.length;
            }
            return Math.ceil(totalItems / pageSize);
        }, [totalCount, clientPagination, pageSize, data.length]);

        useEffect(() => {
            const listRowId = Object.keys(selectedRowIds).map(
                (rowIndex) => data[rowIndex]?.id,
            );
            onRowSelect(listRowId);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedRowIds]);

        const fetchData = useCallback(
            (pageIndex) => {
                let skip = 0;
                if (pageIndex > 0) {
                    /**
                     * Weird hack to account for items being removed from the table
                     * so that we don't skip the same number of items we removed.
                     * Used for the ChallengeActionsModal table
                     */
                    skip = (pageIndex - 1) * pageSize + data?.length;
                }
                const options = {
                    skip,
                    limit: pageSize,
                    sortBy: sortBy?.[0]?.id,
                    order: sortBy?.[0]?.desc === false ? 1 : -1,
                };
                if (searchString) {
                    options.search = searchString;
                }
                if (searchFunc && searchString) {
                    searchFunc(searchString, options);
                } else {
                    fetchDataFunc(options);
                }
            },
            [
                fetchDataFunc,
                searchString,
                searchFunc,
                pageSize,
                sortBy,
                data.length,
            ],
        );

        useEffect(() => {
            setPageIndex(0);
            gotoPage(0);
            fetchData(0);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            sortBy,
            pageSize,
            searchString,
            gotoPage,
            refetchData,
            activeCommunityId,
        ]);

        const renderCellActions = () => {
            if (!multiActionButton) return;
            return (
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <InfoTooltip
                        text={t('table.selectMultiple')}
                        placement="left"
                        intercomTarget={`${intercomTargetPrefix}TableMultiSelect`}
                    />
                    {multiActionButton}
                </Grid>
            );
        };

        return (
            <Fragment>
                <TableToolbar
                    title={title}
                    rowsPerPage={pageSize}
                    options={pageOptions}
                    setPageSize={setPageSize}
                    hideRowsPerPage={hideRowsPerPage}
                    intercomTargetPrefix={intercomTargetPrefix}
                />
                <TableContainer
                    component={Paper}
                    classes={{ root: classes.container }}
                    data-intercom-target={`${intercomTargetPrefix ||
                        title} Table`}
                >
                    {loading && renderContentLoader()}
                    {!loading && !data.length && noItemFoundText ? (
                        <Typography className={classes.noItemsText}>
                            {noItemFoundText}
                        </Typography>
                    ) : (
                        !loading && (
                            <Fragment>
                                <TableUI
                                    {...getTableProps()}
                                    className={classes.root}
                                >
                                    <TableHeader
                                        columns={headerGroups}
                                        cellActions={renderCellActions()}
                                        sortBy={sortBy}
                                    />
                                    <TableBody {...getTableBodyProps()}>
                                        {page.map((row, rowIdx) => {
                                            prepareRow(row);
                                            return (
                                                <TableRow
                                                    key={`bodyRow-${rowIdx}`}
                                                    data-intercom-target={`${intercomTargetPrefix ||
                                                        title} Table Row ${rowIdx}`}
                                                    {...row.getRowProps()}
                                                >
                                                    {row.cells.map(
                                                        (cell, cellIdx) => (
                                                            <TableCell
                                                                key={`bodyCell-${cellIdx}`}
                                                                className={clsx(
                                                                    [
                                                                        classes.cell,
                                                                        isBorderImg &&
                                                                            classes.borderIcon,
                                                                    ],
                                                                )}
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.column
                                                                    .id ===
                                                                'actions' ? (
                                                                    <TableCellActions
                                                                        id={
                                                                            cell
                                                                                .row
                                                                                .original
                                                                                .id
                                                                        }
                                                                        rowIdx={
                                                                            rowIdx
                                                                        }
                                                                        buttonsCell={
                                                                            buttonsCell
                                                                        }
                                                                        onButtonsCell={
                                                                            onButtonsCell
                                                                        }
                                                                    />
                                                                ) : cell.column
                                                                      .id ===
                                                                      'description' &&
                                                                  cell.value ? (
                                                                    parse(
                                                                        cell.value,
                                                                    )
                                                                ) : (
                                                                    cell.render(
                                                                        'Cell',
                                                                    )
                                                                )}
                                                            </TableCell>
                                                        ),
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </TableUI>
                            </Fragment>
                        )
                    )}
                </TableContainer>
                <Grid
                    container
                    justify="flex-end"
                    className={classes.pagination}
                >
                    <Pagination
                        count={pc}
                        page={pageIndex + 1}
                        onChange={(e, v) => {
                            const index = v - 1;
                            gotoPage(index);
                            setPageIndex(index);
                            fetchData(index);
                        }}
                        color="primary"
                    />
                </Grid>
            </Fragment>
        );
    },
);

Table.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    noItemFoundText: PropTypes.string,
    columns: columnsPropType.isRequired,
    data: dataPropType.isRequired,
    fetchDataFunc: PropTypes.func,
    searchFunc: PropTypes.func,
    searchString: PropTypes.string,
    multiActionButton: PropTypes.node,
    onRowSelect: PropTypes.func,
    buttonsCell: buttonsCellPropType,
    onButtonsCell: PropTypes.func,
    pageOptions: PropTypes.arrayOf(PropTypes.number),
    totalCount: PropTypes.number,
    initialPageSize: PropTypes.number,
    hideRowsPerPage: PropTypes.bool,
    initialPageIndex: PropTypes.number,
    initialSortBy: sortPropType,
    clientPagination: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    isBorderImg: PropTypes.bool,
    loaderRows: PropTypes.number,
    refetchData: PropTypes.number,
    activeCommunityId: PropTypes.string,
    // Used solely to identify this table's rows and container on intercom tags
    intercomTargetPrefix: PropTypes.string,
};

Table.defaultProps = {
    title: null,
    noItemFoundText: null,
    multiActionButton: null,
    onRowSelect: noop,
    buttonsCell: null,
    onButtonsCell: noop,
    initialPageIndex: 0,
    pageOptions: [10, 20, 30, 40, 50],
    initialPageSize: 10,
    loaderRows: 10,
    totalCount: 0,
    hideRowsPerPage: false,
    initialSortBy: [],
    fetchDataFunc: noop,
    searchFunc: null,
    searchString: '',
    clientPagination: false,
    isBorderImg: false,
    intercomTargetPrefix: '',
    refetchData: 0,
    activeCommunityId: '',
};

export default withStyles(styles)(Table);
