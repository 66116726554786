const styles = (theme) => ({
    footerContainer: {
        backgroundColor: theme.palette.secondary.main,
        height: 50,
        [theme.breakpoints.down('md')]: {
            height: 35,
        },
    },
    logo: {
        maxWidth: '20px',
    },
    footerText: {
        color: theme.custom.textDarkColor,
        letterSpacing: 3,
        opacity: 0.75,
    },
});

export default styles;
