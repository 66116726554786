import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import theme from '../../theme';
import useStyles from './styles';
import { useTheme } from '@material-ui/core';

const StatCard = ({
    title,
    value,
    weight,
    unit,
    info,
    colorTitle,
    colorContent,
    capitalize,
}) => {
    const theme = useTheme();

    /*
     * the themeColor on the impactStatType DB doc isn't customizable by application,
     * so this is a hack to change the color for WFA
     */
    const transformColor = (color) => {
        switch (color.toLowerCase()) {
            case '#00b89f':
                return theme.custom.impactTileColor || color;
            default:
                return color;
        }
    };
    const classes = useStyles({
        colorTitle: transformColor(colorTitle),
        colorContent: transformColor(colorContent),
        capitalize,
    });
    const [isDescription, setIsDescription] = useState(false);

    return (
        <Card className={classes.cardRoot}>
            <CardHeader
                classes={{
                    root: classes.headerRoot,
                    content: classes.headerContent,
                    title: classes.headerTitle,
                    action: classes.headerAction,
                }}
                title={title}
                action={
                    info && (
                        <IconButton
                            onClick={() => setIsDescription(!isDescription)}
                            style={{ padding: 0 }}
                        >
                            <InfoOutlinedIcon className={classes.iconButton} />
                        </IconButton>
                    )
                }
            />
            <CardContent classes={{ root: classes.contentRoot }}>
                <Collapse in={isDescription} timeout={240}>
                    <p style={{ margin: 0 }}>{info}</p>
                </Collapse>
                <Collapse in={!isDescription} timeout={240}>
                    <Typography className={classes.value}>
                        {value}
                        <span className={classes.weight}>
                            {weight} {unit}
                        </span>
                    </Typography>
                </Collapse>
            </CardContent>
        </Card>
    );
};

StatCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    weight: PropTypes.string,
    info: PropTypes.string,
    colorTitle: PropTypes.string,
    colorContent: PropTypes.string,
    // whether the title should be capitalized
    capitalize: PropTypes.bool,
};

StatCard.defaultProps = {
    impactValue: null,
    colorTitle: theme.custom.grey60,
    colorContent: theme.custom.grey20,
    value: '',
    unit: '',
    weight: '',
    info: null,
    capitalize: false,
};

export default StatCard;
