import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Grid, Typography } from '@material-ui/core';

import CreateSection from './CreateSection';

import styles from './styles';

const CreateChallengeBanner = ({ classes }) => {
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.header}
            >
                <Typography className={classes.title} variant="h6">
                    {t('challenge.new.getStarted')}
                </Typography>
            </Grid>
            <Grid>
                <CreateSection />
            </Grid>
        </div>
    );
};

CreateChallengeBanner.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateChallengeBanner);
