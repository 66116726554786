const styles = (theme) => ({
    image: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
        borderRadius: '5px',
    },
    loader: {
        marginRight: '5px',
    },
    hidden: {
        display: 'none',
    },
});

export default styles;
