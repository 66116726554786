const styles = (theme) => ({
    actionButton: {
        marginRight: '10px',
        paddingLeft: '40px',
        paddingRight: '40px',
        [theme.breakpoints.down('xs')]: {
            width: '48.3%',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },
});

export default styles;
