export const CHEATING_ACTIONS = [
    'throttleBaseAction',
    'pinThrottleHour',
    'pinThrottleDay',
    'pinThrottleTimesInMinutes',
    'penaltyLevel1Reached',
    'penaltyLevel2Reached',
    'penaltyLevel3Reached',
    'penaltyLevel4Reached',
    'penaltyLevel5Reached',
    'penaltyLevel1Active',
    'penaltyLevel2Active',
    'penaltyLevel3Active',
    'penaltyLevel4Active',
    'penaltyLevel5Active',
    'penaltyLevelWarning',
    'penaltyLevel1Warning',
    'penaltyLevel2Warning',
    'penaltyLevel3Warning',
    'penaltyLevel4Warning',
    'penaltyLevel5Warning',
    'inappropriateContentDuplicatePhoto',
    'inappropriateContentPostMessage',
];

export const PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME = 'includedForTheme';
export const PIN_ADMIN_VISIBILITY_EXCLUDED = 'excluded';

export const PIN_ADMIN_VISIBILITY_ALL_INCLUDED = 'allIncluded';

export const PIN_ADMIN_VISIBILITIES = [
    PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
    PIN_ADMIN_VISIBILITY_EXCLUDED,
    PIN_ADMIN_VISIBILITY_ALL_INCLUDED,
];

export const CLIENT_PAGINATION_FETCH_LIMIT = 5000;
