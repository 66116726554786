import React, { useCallback, useEffect, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { AddTwoTone } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { trackEvent, useTrackScreenView } from '../../../utils/analytics';

import HeaderPage from '../../../common/HeaderPage';
import Button from '../../../common/Button';
import TableManager from './TableManager';
import { GROUP_TYPE_DELIVERED, GROUP_TYPE_QUEUED } from './constants';
import {
    getGroupLoader,
    getGroupTableData,
    getGroupTotalCount,
    getNotificationErrors,
    getNotificationUpdateMessage,
} from '../../../notifications/selectors';
import {
    clearAllErrors,
    deleteNotificationRequest,
    notificationsRequest,
} from '../../../notifications/actions';
import { getActiveCommunityId } from '../../../user/selectors';
import { Can } from '../../../casl/ability-context';
import styles from './styles';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';
import { EVENT_NOTIFICATION_CREATE_BEGAN } from '../../../constants/analyticsEvents';

const AdminNotificationsScreen = ({
    classes,
    communityId,
    loading,
    tableData,
    totalCount,
    getNotifications,
    deleteNotification,
    publishErrors,
    message,
    clearSnackBar,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const [snackBarMessage, setSnackBarMessage] = useState();

    useTrackScreenView('notifications_view_overview');

    const handleCreate = () => {
        trackEvent(EVENT_NOTIFICATION_CREATE_BEGAN);
        history.push(`/notifications/create`);
    };

    const renderRightButton = () => (
        <Can I="manage" a="MasterNotifications">
            <Grid item data-intercom-target="createNotificationButton">
                <Button color="accent" onClick={handleCreate}>
                    <AddTwoTone />
                    {t('notifications.table.headers.create')}
                </Button>
            </Grid>
        </Can>
    );

    const fetchQueued = useCallback(
        (args) => getNotifications(communityId, GROUP_TYPE_QUEUED, args),
        [communityId, getNotifications],
    );

    const fetchDelivered = useCallback(
        (args) => getNotifications(communityId, GROUP_TYPE_DELIVERED, args),
        [communityId, getNotifications],
    );

    useEffect(() => {
        if (message) setSnackBarMessage(message);
    }, [message, t]);

    return (
        <Grid container className={classes.container}>
            <HeaderPage
                title={t('Notifications')}
                rightContent={renderRightButton()}
            />
            {path !== '/notifications/sent' && (
                <TableManager
                    classes={classes}
                    group={GROUP_TYPE_QUEUED}
                    fetchDataFunc={fetchQueued}
                    tableData={tableData[GROUP_TYPE_QUEUED]}
                    totalCount={totalCount[GROUP_TYPE_QUEUED]}
                    loading={loading[GROUP_TYPE_QUEUED]}
                    deleteNotification={(...args) =>
                        deleteNotification(communityId, ...args)
                    }
                    communityId={communityId}
                />
            )}
            {path !== '/notifications/queued' && (
                <TableManager
                    classes={classes}
                    group={GROUP_TYPE_DELIVERED}
                    fetchDataFunc={fetchDelivered}
                    tableData={tableData[GROUP_TYPE_DELIVERED]}
                    totalCount={totalCount[GROUP_TYPE_DELIVERED]}
                    loading={loading[GROUP_TYPE_DELIVERED]}
                    deleteNotification={(...args) =>
                        deleteNotification(communityId, ...args)
                    }
                    communityId={communityId}
                />
            )}
            <SnackBarAPIResponse
                messages={snackBarMessage}
                errors={publishErrors}
                clearResponse={() => {
                    setSnackBarMessage(null);
                    clearSnackBar();
                }}
            />
        </Grid>
    );
};

AdminNotificationsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    tableData: PropTypes.object.isRequired,
    totalCount: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    getNotifications: PropTypes.func.isRequired,
    deleteNotification: PropTypes.func.isRequired,
    publishErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    message: PropTypes.string.isRequired,
    clearSnackBar: PropTypes.func.isRequired,
};

const StyledAdminNotificationsScreen = withStyles(styles)(
    AdminNotificationsScreen,
);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    tableData: getGroupTableData(state),
    totalCount: getGroupTotalCount(state),
    loading: getGroupLoader(state),
    publishErrors: getNotificationErrors(state),
    message: getNotificationUpdateMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
    getNotifications: (...args) => dispatch(notificationsRequest(...args)),
    deleteNotification: (...args) =>
        dispatch(deleteNotificationRequest(...args)),
    clearSnackBar: () => dispatch(clearAllErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledAdminNotificationsScreen);
