import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    getErrorsFromPossibleAPIErrorResponse,
    uploadWithAuth,
} from '../utils/api';
import {
    IMAGE_UPLOAD_REQUESTED,
    imageUploadRequestFailed,
    imageUploadRequestSucceeded,
} from './actions';

export function* uploadImage(action) {
    try {
        const {
            payload: { communityId, file },
        } = action;
        const formData = new FormData();
        formData.append('file', file);
        const response = yield call(uploadWithAuth, '/image', {
            method: 'POST',
            query: { communityId },
            body: formData,
        });
        yield put(imageUploadRequestSucceeded(response));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to upload image',
        );
        yield put(imageUploadRequestFailed(errors));
    }
}

export default function* imageSaga() {
    yield all([takeLatest(IMAGE_UPLOAD_REQUESTED, uploadImage)]);
}
