import {
    CLEAR_THEMES_ERRORS,
    THEMES_REQUEST_FAILED,
    THEMES_REQUEST_SUCCEEDED,
    THEMES_REQUESTED,
    THEMES_UPDATE_REQUEST_FAILED,
    THEMES_UPDATE_REQUEST_SUCCEEDED,
    THEMES_UPDATE_REQUESTED,
} from './actions';

const initialState = {
    themes: {
        loading: false,
        errors: [],
        themes: [],
    },
    update: {
        loading: false,
        errors: [],
    },
};

const themesReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case THEMES_REQUESTED:
            return {
                ...state,
                themes: {
                    ...state.themes,
                    loading: true,
                },
            };
        case THEMES_REQUEST_SUCCEEDED:
            return {
                ...state,
                themes: {
                    ...state.themes,
                    loading: false,
                    errors: [],
                    themes: payload.themes,
                },
            };
        case THEMES_REQUEST_FAILED:
            return {
                ...state,
                themes: {
                    ...state.themes,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case THEMES_UPDATE_REQUESTED:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: true,
                },
            };
        case THEMES_UPDATE_REQUEST_SUCCEEDED:
            return {
                ...state,
                themes: {
                    ...state.themes,
                    themes: payload.themes,
                },
                update: {
                    ...state.update,
                    loading: false,
                    errors: [],
                },
            };
        case THEMES_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case CLEAR_THEMES_ERRORS:
            return {
                ...state,
                update: {
                    ...state.update,
                    errors: [],
                },
                themes: {
                    ...state.themes,
                    errors: [],
                },
            };
        default:
            return state;
    }
};

export default themesReducer;
