import { all, call, takeLatest, put } from 'redux-saga/effects';
import {
    FLAG_EXCLUDE_REQUESTED,
    FLAG_IGNORE_REQUESTED,
    flagExcludeRequestFailed,
    flagExcludeRequestSucceeded,
    FLAGGED_CONTENT_REQUESTED,
    flaggedContentRequestFailed,
    flaggedContentRequestSucceeded,
    flagIgnoreRequestFailed,
    flagIgnoreRequestSucceeded,
    FLAG_REPORT_REQUESTED,
    activityReportRequestSucceeded,
    activityReportRequestFailed,
    flaggedContentRequested,
} from './actions';

import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../utils/api';
import i18n from 'i18next';

function* getFlaggedContent(action) {
    try {
        const {
            payload: { request },
        } = action;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${request.communityId}/activity/flags`,
            {
                query: {
                    ...request,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            },
        );
        const content = data?.response?.activities || [];
        const total = data?.response?.totalCount;
        yield put(
            flaggedContentRequestSucceeded({
                content,
                total,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get flagged content',
        );
        yield put(flaggedContentRequestFailed(errors));
    }
}

function* ignoreFlaggedContent(action) {
    try {
        const {
            payload: { request },
        } = action;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${request.communityId}/activity/${request.activityId}/unflag`,
            {
                method: 'POST',
                query: {
                    ...request,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            },
        );
        const content = data?.response?.activities || [];
        const total = data?.response?.totalCount;
        yield put(
            flagIgnoreRequestSucceeded({
                content,
                total,
            }),
        );
        yield put(
            flaggedContentRequested({
                communityId: request.communityId,
                context: 'group',
                skip: 0,
                limit: 50,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to unflag content',
        );
        yield put(flagIgnoreRequestFailed(errors));
    }
}

function* excludeFlaggedContent(action) {
    try {
        const {
            payload: { request },
        } = action;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${request.communityId}/activity/${request.activityId}/remove`,
            {
                method: 'POST',
                query: {
                    ...request,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            },
        );
        const content = data?.response?.activities || [];
        const total = data?.response?.totalCount;
        yield put(
            flagExcludeRequestSucceeded({
                content,
                total,
            }),
        );
        yield put(
            flaggedContentRequested({
                communityId: request.communityId,
                context: 'group',
                skip: 0,
                limit: 50,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to remove activity',
        );
        yield put(flagExcludeRequestFailed(errors));
    }
}

export function* addFlagReport({
    payload: { contentId, commentId, type = 'buzz' },
}) {
    try {
        const activityData = yield call(
            fetchWithAuth,
            `/${type}/${contentId}/flag`,
            {
                method: 'POST',
                body: {
                    commentId,
                },
            },
        );

        let responseId = null;
        switch (type) {
            case 'buzz':
                responseId = activityData.response.activities[0].id;
                break;
            case 'user':
                responseId = activityData.response.users[0].id;
                break;
            default:
                break;
        }

        yield put(activityReportRequestSucceeded(responseId));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('activitiesPage.addFlagError'),
        );
        yield put(activityReportRequestFailed(errors));
    }
}

export default function* flaggedContentSaga() {
    yield all([
        takeLatest(FLAGGED_CONTENT_REQUESTED, getFlaggedContent),
        takeLatest(FLAG_IGNORE_REQUESTED, ignoreFlaggedContent),
        takeLatest(FLAG_EXCLUDE_REQUESTED, excludeFlaggedContent),
        takeLatest(FLAG_REPORT_REQUESTED, addFlagReport),
    ]);
}
