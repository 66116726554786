import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import Logo from '../Logo';

const OnboardingHeader = ({ classes, isLightMode }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={
                isLightMode ? classes.lightModeContainer : classes.container
            }
        >
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                wrap="nowrap"
                className={classes.innerContainer}
            >
                <Grid item>
                    <Logo isLogoWhite={isLightMode} />
                </Grid>
            </Grid>
        </Grid>
    );
};

OnboardingHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    isLightMode: PropTypes.bool,
};
OnboardingHeader.defaultProps = {
    isLightMode: false,
};
export default withStyles(styles)(OnboardingHeader);
