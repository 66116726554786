import { call, put } from 'redux-saga/effects';
import {
    appStringsRequestFailed,
    appStringsRequestSucceeded,
    initializationSucceeded,
} from './actions';
import {
    fetchWithoutAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../utils/api';
import { getProperties, setApplicationConfig } from '../utils';
import { setTrackingProperties } from '../utils/analytics';

export default function* initSaga() {
    setTrackingProperties({ platform: 'dashboard' });
    try {
        const configResponse = yield call(
            fetchWithoutAuth,
            '/application/config',
            {
                query: {
                    client_secret: getProperties()?.clientSecret,
                    client_id: getProperties()?.clientId,
                },
            },
        );
        const stringResponse = yield call(
            fetchWithoutAuth,
            '/application/strings',
            {
                query: {
                    client_secret: getProperties()?.clientSecret,
                    client_id: getProperties()?.clientId,
                },
            },
        );
        const config = configResponse?.response?.applications?.[0];
        yield put(appStringsRequestSucceeded(stringResponse));
        if (config) {
            setApplicationConfig(config);
        }
    } catch (error) {
        // Fallback to defaults if this fails
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get application strings',
        );
        yield put(appStringsRequestFailed(errors));
    }
    yield put(initializationSucceeded());
}
