// The feeds that a user can select to filter
export const ACTIVITIES_FEED_TYPE_TRENDING = 'trending';
export const ACTIVITIES_FEED_TYPE_FOLLOWING = 'following';
export const ACTIVITIES_FEED_TYPE_RECENT = 'recent';

// The feeds that are filter automatically by the platform (ie. viewing a challenge page)
export const ACTIVITIES_FEED_TYPE_CHALLENGE = 'challenge';
export const ACTIVITIES_FEED_TYPE_TEAM = 'team';
export const ACTIVITIES_FEED_TYPE_USER = 'user';

// This is the type of feed we will default to for filtering purposes
export const DEFAULT_ACTIVITY_FEED_TYPE = ACTIVITIES_FEED_TYPE_TRENDING;

// All the types of feed the user is allowed to filter through
export const ACTIVITY_FEED_SUPPORTED_TYPES = [ACTIVITIES_FEED_TYPE_TRENDING];
