import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HeaderPage from '../../common/HeaderPage';
import Table from '../../common/Table';
import styles from './styles';
import CommonPropTypes from '../../common/propTypes';
import {
    clearChallengePenaltiesActionsResponse,
    penaltiesRequested,
    removeUserRequested,
    warnUserRequested,
} from '../../challenges/actions';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getPenalties,
    getPenaltiesActionsErrors,
    getPenaltiesActionsResponse,
    isPenaltiesActionLoading,
    isPenaltiesLoading,
} from '../../challenges/selectors';
import {
    BTN_REMOVE_USER,
    BTN_WARN_USER,
    BUTTONS_CELL,
    COLUMNS,
} from './constants';
import Tooltip from '../../common/Tooltip';
import TableToolbar from '../../common/Table/TableToolbar';
import RequestLoader from '../../common/RequestLoader';
import ThrottlesCell from './ThrottlesCell';
import PenaltyLevelCell from './PenaltyLevelCell';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import ConfirmModal from '../../common/ConfirmModal';
import PenaltyCell from './PenaltyCell';
import { useRouteMatch } from 'react-router-dom';

const ChallengePenaltiesScreen = ({
    classes,
    errors,
    communityId,
    penalties,
    isPenaltiesLoading,
    isActionLoading,
    penaltiesRequest,
    warnUserRequest,
    removeUserRequest,
    actionResponse,
    actionErrors,
    clearActionResponse,
}) => {
    const { t } = useTranslation();
    const {
        params: { challengeId },
    } = useRouteMatch();

    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        penaltiesRequest({ communityId, challengeId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (actionResponse) {
            penaltiesRequest({ communityId, challengeId });
        }
    }, [actionResponse, penaltiesRequest, communityId, challengeId]);

    const handleButtonsCell = (id, key) => {
        const user = penalties.find((item) => item.id === id);
        if (user) {
            setSelectedUser(user);
            switch (key) {
                case BTN_WARN_USER.key:
                    setWarningModalOpen(true);
                    break;
                case BTN_REMOVE_USER.key:
                    setRemoveModalOpen(true);
                    break;
                default:
                    break;
            }
        }
    };

    const onClose = () => {
        setWarningModalOpen(false);
        setRemoveModalOpen(false);
    };

    const handleConfirm = (func) => {
        func({
            challengeId,
            userId: selectedUser?.userId,
            communityId,
        });
        onClose();
    };

    const makeTableData = useCallback(
        (column, data = []) =>
            data.map((item) =>
                [...column, { accessor: 'id' }].reduce((result, cell) => {
                    if (cell.accessor === 'user') {
                        return {
                            ...result,
                            [cell.accessor]: {
                                username: item.username,
                                teamName: item.teamName,
                            },
                        };
                    }

                    if (cell.accessor === 'throttles') {
                        return {
                            ...result,
                            [cell.accessor]: {
                                throttled: item.throttled,
                                throttleActionHour: item.throttleActionHour,
                                throttleActionTimesWithinMinutes:
                                    item.throttleActionTimesWithinMinutes,
                                throttleActionDay: item.throttleActionDay,
                            },
                        };
                    }
                    return { ...result, [cell.accessor]: item[cell.accessor] };
                }, {}),
            ),
        [],
    );

    const makeTableColumns = useCallback(() => {
        return COLUMNS.reduce((result, column) => {
            if (column.accessor === 'user') {
                return [
                    ...result,
                    {
                        ...column,
                        Cell: ({ cell }) => {
                            const {
                                username,
                                teamName,
                            } = cell.row.original.user;
                            return (
                                <Fragment>
                                    <Typography
                                        component="span"
                                        className={classes.bold}
                                    >
                                        {username}
                                    </Typography>
                                    <br />
                                    <Tooltip title={t('challenges.teamName')}>
                                        <Typography component="span">
                                            {teamName}
                                        </Typography>
                                    </Tooltip>
                                </Fragment>
                            );
                        },
                    },
                ];
            }

            if (column.accessor === 'throttles') {
                return [
                    ...result,
                    {
                        ...column,
                        Cell: ({ cell }) => (
                            <ThrottlesCell data={cell.row.original.throttles} />
                        ),
                    },
                ];
            }

            if (column.accessor.includes('penalty')) {
                return [
                    ...result,
                    {
                        ...column,
                        Cell: ({ cell }) => (
                            <PenaltyLevelCell
                                data={cell.row.original[column.accessor]}
                            />
                        ),
                    },
                ];
            } else if (column.accessor.includes('warnings')) {
                return [
                    ...result,
                    {
                        ...column,
                        Cell: ({ cell }) => {
                            const data = cell.row.original[column.accessor];
                            return (
                                <PenaltyCell
                                    totalTooltip={t(
                                        'challenge.penalties.warn.total',
                                    )}
                                    lastTooltip={t(
                                        'challenge.penalties.warn.last',
                                    )}
                                    created={data?.created}
                                    count={data?.count}
                                />
                            );
                        },
                    },
                ];
            }

            return [...result, column];
        }, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const tableColumns = useMemo(() => {
        return makeTableColumns();
    }, [makeTableColumns]);

    const tableData = useMemo(() => {
        return makeTableData(tableColumns, penalties);
    }, [penalties, tableColumns, makeTableData]);

    return (
        <Grid
            container
            direction="column"
            justify="center"
            className={classes.root}
        >
            {errors ? (
                errors.map((err, i) => <Typography key={i}>{err}</Typography>)
            ) : (
                <Fragment>
                    <HeaderPage
                        title={t('challenge.penalties.descriptor')}
                        rightContent={<TableToolbar />}
                    />
                    <Box position="relative">
                        <Table
                            columns={tableColumns}
                            data={tableData}
                            buttonsCell={BUTTONS_CELL}
                            onButtonsCell={handleButtonsCell}
                            isPerPageOptions={false}
                            hideRowsPerPage
                            clientPagination={true}
                        />
                        <RequestLoader
                            isLoading={isPenaltiesLoading}
                            title={t('loading.penalties')}
                        />
                        <RequestLoader
                            isLoading={isActionLoading}
                            title={t('loading.descriptor')}
                        />
                    </Box>
                </Fragment>
            )}
            <ConfirmModal
                title={t('challenge.penalties.warn.send')}
                isOpen={warningModalOpen}
                confirmBtnText={t('challenge.penalties.warn.send')}
                confirmBtnType="danger"
                cancelBtnText={t('cancel')}
                onClose={onClose}
                onCancel={onClose}
                onConfirm={() => handleConfirm(warnUserRequest)}
            >
                <Grid item>
                    {t('challenge.penalties.warn.modalText', {
                        user: selectedUser?.username,
                    })}
                </Grid>
            </ConfirmModal>
            <ConfirmModal
                title={t('challenge.penalties.remove.removeFromChallenge')}
                isOpen={removeModalOpen}
                confirmBtnText={t(
                    'challenge.penalties.remove.removeFromChallenge',
                )}
                confirmBtnType="danger"
                cancelBtnText={t('cancel')}
                onClose={onClose}
                onCancel={onClose}
                onConfirm={() => handleConfirm(removeUserRequest)}
            >
                <Grid item>
                    {t('challenge.penalties.remove.modalText', {
                        user: selectedUser?.username,
                    })}
                </Grid>
            </ConfirmModal>
            <SnackBarAPIResponse
                messages={actionResponse}
                errors={actionErrors}
                clearResponse={clearActionResponse}
            />
        </Grid>
    );
};

ChallengePenaltiesScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: CommonPropTypes.errors,
    communityId: PropTypes.string.isRequired,
    penaltiesRequest: PropTypes.func.isRequired,
    isPenaltiesLoading: PropTypes.bool.isRequired,
    isActionLoading: PropTypes.bool.isRequired,
    penalties: PropTypes.arrayOf(PropTypes.object).isRequired,
    actionResponse: PropTypes.string,
    actionErrors: CommonPropTypes.errors,
    warnUserRequest: PropTypes.func.isRequired,
    removeUserRequest: PropTypes.func.isRequired,
    clearActionResponse: PropTypes.func.isRequired,
};

ChallengePenaltiesScreen.defaultProps = {
    errors: null,
    actionResponse: '',
    actionErrors: null,
};

const StyledLeftColumn = withStyles(styles)(ChallengePenaltiesScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    penalties: getPenalties(state),
    isPenaltiesLoading: isPenaltiesLoading(state),
    actionResponse: getPenaltiesActionsResponse(state),
    actionErrors: getPenaltiesActionsErrors(state),
    isActionLoading: isPenaltiesActionLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    penaltiesRequest: (args) => dispatch(penaltiesRequested(args)),
    warnUserRequest: (args) => dispatch(warnUserRequested(args)),
    removeUserRequest: (args) => dispatch(removeUserRequested(args)),
    clearActionResponse: () =>
        dispatch(clearChallengePenaltiesActionsResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledLeftColumn);
