import { getAppImages } from '../utils';

export const transformActivities = (activities) => {
    return activities?.map((activity) => transformActivity(activity));
};

export const transformActivity = (activity) => {
    const { name: userName, id: userId, imageSizes } = activity?.user || {};
    const userImage =
        imageSizes?.[imageSizes.length - 1]?.url ||
        getAppImages().defaultProfileIcon;

    const {
        actions,
        created: activityCreated,
        photoSizes,
        message,
        liked,
        comments: otherComments,
    } = activity || {};

    const action =
        actions.filter((action) => action.actionName === 'buzzBaseAction') ||
        [];

    const masteries = actions
        ?.filter((action) => action.actionName === 'pinEarn')
        .map(
            ({
                actionDefaultText,
                content: { imageSizes: activityImageSizes },
                actionId,
            }) => {
                return {
                    activityName: actionDefaultText,
                    activityImage:
                        activityImageSizes[activityImageSizes.length - 1]?.url,
                    id: actionId,
                };
            },
        );

    const badges = actions
        ?.filter((action) => action.actionName === 'badgeEarn')
        .map(
            ({
                actionDefaultText,
                content: { imageSizes: activityImageSizes },
                actionId,
            }) => {
                return {
                    activityName: actionDefaultText,
                    activityImage:
                        activityImageSizes[activityImageSizes.length - 1]?.url,
                    id: actionId,
                };
            },
        );

    const activityName = action?.[0].actionDefaultText;
    const {
        content: { contentType, id: contentId, imageSizes: activityImageSizes },
    } = action?.[0] || { content: {} };
    const activityImage =
        activityImageSizes?.[activityImageSizes.length - 1]?.url;
    const photoUrl = photoSizes?.[photoSizes.length - 1]?.url;
    const numLikes = liked?.length;
    const selfComment = message && {
        commentUser: userName,
        commentText: message,
        isCaption: true,
    };
    const otherCommentsOnCard =
        otherComments?.map(
            ({ message: commentText, user: { name: commentUser }, id }) => ({
                commentUser,
                commentText,
                id,
            }),
        ) || [];
    const comments = selfComment
        ? [selfComment, ...otherCommentsOnCard]
        : otherCommentsOnCard;
    return {
        id: activity?.id,
        shareURL: activity?.shareURL,
        userId,
        userName,
        userImage,
        activityCreated,
        activityName,
        activityImage,
        badges,
        masteries,
        photoUrl,
        likes: liked,
        numLikes,
        comments,
        contentType,
        contentId,
    };
};
