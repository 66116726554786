export const ACTION_REQUESTED = 'ACTION_REQUESTED';
export const ACTION_REQUEST_SUCCEEDED = 'ACTION_REQUEST_SUCCEEDED';
export const ACTION_REQUEST_FAILED = 'ACTION_REQUEST_FAILED';

export const LOG_ACTION_REQUESTED = 'LOG_ACTION_REQUESTED';
export const LOG_ACTION_SUCCEEDED = 'LOG_ACTION_SUCCEEDED';
export const LOG_ACTION_FAILED = 'LOG_ACTION_FAILED';

export const ACTIONS_REQUESTED = 'ACTIONS_REQUESTED';
export const ACTIONS_REQUEST_SUCCEEDED = 'ACTIONS_REQUEST_SUCCEEDED';
export const ACTIONS_REQUEST_FAILED = 'ACTIONS_REQUEST_FAILED';

export const ADD_INCLUDE_ACTION_REQUESTED = 'ADD_INCLUDE_ACTION_REQUESTED';
export const ADD_EXCLUDE_ACTION_REQUESTED = 'ADD_EXCLUDE_ACTION_REQUESTED';

export const URL_METADATA_REQUESTED = 'URL_METADATA_REQUESTED';
export const URL_METADATA_REQUEST_SUCCEEDED = 'URL_METADATA_REQUEST_SUCCEEDED';
export const URL_METADATA_REQUEST_FAILED = 'URL_METADATA_REQUEST_FAILED';

export const ACTION_CUSTOMIZATION_REQUESTED = 'ACTION_CUSTOMIZATION_REQUESTED';
export const ACTION_CUSTOMIZATION_REQUEST_SUCCEEDED =
    'ACTION_CUSTOMIZATION_REQUEST_SUCCEEDED';
export const ACTION_CUSTOMIZATION_REQUEST_FAILED =
    'ACTION_CUSTOMIZATION_REQUEST_FAILED';
export const CLEAR_ACTION_CUSTOMIZATION_ERRORS =
    'CLEAR_ACTION_CUSTOMIZATION_ERRORS';
export const SET_URL_METADATA = 'SET_URL_METADATA';

export const CLEAR_ACTION_LINK_METADATA = 'CLEAR_ACTION_LINK_METADATA';

export const ACTION_CATEGORIES_REQUESTED = 'ACTION_CATEGORIES_REQUESTED';
export const ACTION_CATEGORIES_REQUEST_SUCCEEDED =
    'ACTION_CATEGORIES_REQUEST_SUCCEEDED';
export const ACTION_CATEGORIES_REQUEST_FAILED =
    'ACTION_CATEGORIES_REQUEST_FAILED';
export const SET_VISITED_ACTION_CATEGORY = 'SET_VISITED_ACTION_CATEGORY';
export const SET_ACTION = 'SET_ACTION';

export const ACTION_SHARE_URL_REQUESTED = 'ACTION_SHARE_URL_REQUESTED';
export const ACTION_SHARE_URL_REQUEST_SUCCEEDED =
    'ACTION_SHARE_URL_REQUEST_SUCCEEDED';
export const ACTION_SHARE_URL_REQUEST_FAILED =
    'ACTION_SHARE_URL_REQUEST_FAILED';

export const CLEAR_ACTION_SHARE_URL_ERRORS = 'CLEAR_ACTION_SHARE_URL_ERRORS';

export const CLEAR_ACTION_ERRORS = 'CLEAR_ACTION_ERRORS';

export const SDGS_REQUESTED = 'SDGS_REQUESTED';
export const SDGS_REQUEST_SUCCEEDED = 'SDGS_REQUEST_SUCCEEDED';
export const SDGS_REQUEST_FAILED = 'SDGS_REQUEST_FAILED';

export const addExcludeActionRequest = (
    actionIds,
    actionDuplicationCommunityIds,
    filters,
) => ({
    type: ADD_EXCLUDE_ACTION_REQUESTED,
    payload: {
        actionIds,
        actionDuplicationCommunityIds,
        filters,
    },
});

export const addIncludeActionRequest = (
    actionIds,
    actionDuplicationCommunityIds,
    filters,
) => {
    return {
        type: ADD_INCLUDE_ACTION_REQUESTED,
        payload: {
            actionIds,
            actionDuplicationCommunityIds,
            filters,
        },
    };
};

export const actionRequested = ({ actionId, communityId }) => ({
    type: ACTION_REQUESTED,
    payload: {
        actionId,
        communityId,
    },
});

export const actionRequestSucceeded = ({ action }) => ({
    type: ACTION_REQUEST_SUCCEEDED,
    payload: {
        action,
    },
});

export const actionRequestFailed = (errors) => ({
    type: ACTION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const logActionRequested = ({ actionId, communityId, options }) => ({
    type: LOG_ACTION_REQUESTED,
    payload: {
        actionId,
        communityId,
        options,
    },
});

export const logActionSucceeded = (logActionData) => ({
    type: LOG_ACTION_SUCCEEDED,
    payload: {
        logAction: logActionData,
    },
});

export const logActionFailed = (errors, status) => ({
    type: LOG_ACTION_FAILED,
    payload: {
        errors,
        status,
    },
});

const actionsFilters = { challengeId: null, themeId: null, excluded: false };
export const actionsRequested = (communityId, filters = actionsFilters) => ({
    type: ACTIONS_REQUESTED,
    payload: {
        communityId,
        filters,
    },
});
export const actionsRequestSucceeded = (actions, filters) => ({
    type: ACTIONS_REQUEST_SUCCEEDED,
    payload: {
        actions,
        filters,
    },
});

export const actionsRequestFailed = (errors, filters) => ({
    type: ACTIONS_REQUEST_FAILED,
    payload: {
        errors,
        filters,
    },
});

export const urlMetadataRequested = (request) => ({
    type: URL_METADATA_REQUESTED,
    payload: request,
});

export const urlMetadataRequestSucceeded = (metadata, confirm) => ({
    type: URL_METADATA_REQUEST_SUCCEEDED,
    payload: {
        metadata,
        confirm,
    },
});

export const urlMetadataRequestFailed = (errors) => ({
    type: URL_METADATA_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const setUrlMetadata = (metadata) => ({
    type: SET_URL_METADATA,
    payload: {
        metadata,
    },
});

export const actionCustomizationRequested = (request) => ({
    type: ACTION_CUSTOMIZATION_REQUESTED,
    payload: {
        request,
    },
});

export const actionCustomizationRequestSucceeded = (response) => ({
    type: ACTION_CUSTOMIZATION_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const actionCustomizationRequestFailed = (errors) => ({
    type: ACTION_CUSTOMIZATION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearActionCustomizationErrors = () => ({
    type: CLEAR_ACTION_CUSTOMIZATION_ERRORS,
});

export const clearActionLinkMetadata = () => ({
    type: CLEAR_ACTION_LINK_METADATA,
});

export const actionCategoriesRequested = (request) => ({
    type: ACTION_CATEGORIES_REQUESTED,
    payload: request,
});

export const actionCategoriesRequestSucceeded = (categories) => ({
    type: ACTION_CATEGORIES_REQUEST_SUCCEEDED,
    payload: {
        categories,
    },
});

export const actionCategoriesRequestFailed = (errors) => ({
    type: ACTION_CATEGORIES_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const setLastVisitedActionCategoryIndex = (tabIndex) => ({
    type: SET_VISITED_ACTION_CATEGORY,
    payload: {
        tabIndex,
    },
});

export const setAction = (actionId) => ({
    type: SET_ACTION,
    payload: {
        actionId,
    },
});

export const actionShareURLRequested = (actionId) => ({
    type: ACTION_SHARE_URL_REQUESTED,
    payload: {
        actionId,
    },
});

export const actionShareURLRequestSucceeded = (shareURL, actionId) => ({
    type: ACTION_SHARE_URL_REQUEST_SUCCEEDED,
    payload: {
        shareURL,
        actionId,
    },
});

export const actionShareURLRequestFailed = (errors) => ({
    type: ACTION_SHARE_URL_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearActionShareURLErrors = () => ({
    type: CLEAR_ACTION_SHARE_URL_ERRORS,
});

export const clearActionErrors = () => ({
    type: CLEAR_ACTION_ERRORS,
});

export const sdgsRequested = () => ({
    type: SDGS_REQUESTED,
});

export const sdgsRequestSucceeded = (sdgs) => ({
    type: SDGS_REQUEST_SUCCEEDED,
    payload: {
        sdgs,
    },
});

export const sdgsRequestFailed = (errors) => ({
    type: SDGS_REQUEST_FAILED,
    payload: {
        errors,
    },
});
