import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from '../../../../common/DatePicker';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../../../../constants/dates';

const DateField = ({ value, placeholder, onChange, format }) => (
    <DatePicker
        as={DateTimePicker}
        value={value}
        placeholder={placeholder}
        minDate={moment()
            .subtract(1, 'days')
            .set({ hour: 0, second: 0, millisecond: 0 })
            .toDate()}
        maxDate={moment()
            .add(5, 'years')
            .toDate()}
        onChange={(value) => onChange(value.toString())}
        format={format}
        isBottomMargin
    />
);

DateField.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    format: PropTypes.string,
};

DateField.defaultProps = {
    value: null,
    format: DATETIME_FORMAT_FOR_DATETIME_PICKERS,
};

export default DateField;
