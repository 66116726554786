import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
    nav: {
        flex: 1,
        height: 'auto',
        margin: '0 -8px',
        backgroundColor: 'initial',
    },
    title: {
        margin: '10px 0',
        fontSize: 14,
    },
    infoIcon: {
        paddingLeft: 4,
        color: fade(theme.custom.bgDarkAccentColor, 0.25),
    },
    root: {
        maxWidth: 'none',
        padding: 6,
        margin: '0 8px',
        border: `2px solid ${theme.custom.bgColor}`,
        borderRadius: 6,
        backgroundColor: theme.custom.bgColor,
        color: theme.custom.textDarkSecondaryColor,
        fill: theme.custom.textDarkSecondaryColor,
    },
    selected: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
    },
    wrapper: {
        '& svg': {
            width: 32,
            height: 48,
            fontSize: 32,
        },
    },
    label: {
        fontSize: 14,
        fontWeight: 400,
    },
});
