import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { shape } from 'prop-types';

import {
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';

import * as Yup from 'yup';
import Form from '../../../common/Form';
import FormInput from '../../../common/Form/FormInput';
import { connect } from 'react-redux';
import { noop } from '../../../utils';
import PhotoUpload from '../../../common/PhotoUpload';
import PhotoAlternatePlaceholder from '../../../common/Photo/PhotoAlternatePlaceholder';
import useStyles from './styles';
import CustomDropdown from '../../../common/CustomDropdown';
import {
    membersRequested,
    membersSearchRequested,
} from '../../../user/actions';
import { ACTIVE_STATUS_ACTIVE } from '../../../user/constants';
import {
    getActiveCommunityId,
    getActiveMembersLoading,
    getUserId,
} from '../../../user/selectors';
import { getCommunityAdmins } from '../../../notifications/selectors';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { DUMMY_PASSWORD } from '../constants';
import FieldLabel from '../../../common/FieldLabel';

const CreateCommunityForm = ({
    communityId,
    orgId,
    initialFormValues,
    onSubmit,
    admins,
    adminsLoading,
    fetchMembers,
    searchMembers,
    loading,
    currentUserId,
    submitBtnText,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [icon, setIcon] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const [memberSearchString, setMemberSearchString] = useState('');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('userCreate.inputRequired')),
        visibility: Yup.string().required(t('userCreate.inputRequired')),
        passwordChecked: Yup.boolean(),
        password: Yup.string().when('passwordChecked', {
            is: true,
            then: Yup.string()
                .required(t('userCreate.inputRequired'))
                .min(6, t('userCreate.passwordLength', { length: 6 })),
        }),
        description: Yup.string(),
        additionalAdmins: Yup.string(),
    });

    useEffect(() => {
        const timeout = setTimeout(
            () =>
                memberSearchString
                    ? searchMembers(orgId, memberSearchString)
                    : fetchMembers(orgId),
            250,
        );
        return () => clearTimeout(timeout);
    }, [orgId, fetchMembers, searchMembers, memberSearchString]);

    const VisibilitySelector = ({ onChange, ...props }) => {
        const modifiedProps = {
            name: props.name,
            value: props.value,
            classes: {
                root: props.classes.root,
            },
        };

        const options = ['Public', 'Limited', 'Hidden'];

        return (
            <TextField
                onChange={onChange}
                InputProps={{ disableUnderline: true }}
                className={classes.selectBox}
                fullWidth
                select
                {...modifiedProps}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    const iconProps = {
        color: 'primary',
        fontSize: 'large',
    };

    const isEditing = !!communityId;
    if (isEditing && initialFormValues.passwordChecked) {
        initialFormValues.password = DUMMY_PASSWORD;
    }

    return (
        <>
            <Grid item container alignItems="center" justifyContent="center">
                <Grid item style={{ width: 150, height: 150, marginBottom: 5 }}>
                    <PhotoUpload
                        photo={initialFormValues.image}
                        borderRadius={8}
                        hideTip
                        onUpload={setIcon}
                        maxHeight={135}
                        placeholder={
                            <PhotoAlternatePlaceholder
                                containerStyle={{ padding: '42px 0px' }}
                            />
                        }
                    />
                </Grid>
            </Grid>
            <Form
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                submitBtnText={submitBtnText}
                onSubmit={(values) => {
                    const body = {
                        ...values,
                        image: icon,
                        communityId: communityId || orgId,
                    };
                    if (
                        values.password === DUMMY_PASSWORD &&
                        values.passwordChecked
                    ) {
                        delete body.passwordChecked;
                        delete body.password;
                    }
                    onSubmit(body);
                }}
                data-test="emailForm"
                submitBtnClass={classes.onboardingSubmit}
                loading={loading}
            >
                {({ values, setFieldValue }) => {
                    return (
                        <>
                            <Grid item container>
                                <FormInput
                                    label={t('community.name') + '*'}
                                    name="name"
                                    placeholder={t('community.community')}
                                    required
                                    fullWidth
                                    noLeftMargin
                                />
                            </Grid>
                            <Grid item container>
                                <Grid item xs={12} sm={4}>
                                    <FormInput
                                        label={t('visibility') + '*'}
                                        noLeftMargin
                                        fullWidth
                                        noBottomMargin
                                        as={VisibilitySelector}
                                        name="visibility"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    sm={2}
                                    justifyContent="center"
                                    style={{ padding: '0 10px' }}
                                >
                                    <Typography className={classes.inputLabel}>
                                        {t('community.createPassword')}
                                    </Typography>
                                    <Switch
                                        onChange={(e) =>
                                            setFieldValue(
                                                'passwordChecked',
                                                e.target.checked,
                                            )
                                        }
                                        checked={values.passwordChecked}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    style={{
                                        opacity: values.passwordChecked
                                            ? 1
                                            : 0.5,
                                    }}
                                >
                                    <FieldLabel
                                        text={
                                            t('userCreate.passwordLabel') + '*'
                                        }
                                        intercomTarget="createCommunityPassword"
                                    />
                                    <FormInput
                                        name="password"
                                        disabled={!values.passwordChecked}
                                        placeholder={t(
                                            'onBoarding.welcome.passwordPlaceholder',
                                        )}
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        endAdornment={
                                            !isEditing && (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword,
                                                            )
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff
                                                                {...iconProps}
                                                            />
                                                        ) : (
                                                            <Visibility
                                                                {...iconProps}
                                                            />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <FormInput
                                    name="description"
                                    label={t('community.headline')}
                                    fullWidth
                                    noLeftMargin
                                />
                            </Grid>
                            <Grid item container>
                                <FormInput
                                    as={CustomDropdown}
                                    name="additionalAdmins"
                                    label={t('community.additionalAdmins')}
                                    placeholder={t(
                                        'onBoarding.emailPlaceholder',
                                    )}
                                    className={classes.dropdown}
                                    noLeftMargin
                                    data={admins?.filter(
                                        (value) =>
                                            value?.id !== currentUserId &&
                                            !values.additionalAdmins.some(
                                                (audience) =>
                                                    audience.id === value.id,
                                            ),
                                    )}
                                    value={values.additionalAdmins}
                                    onInputChange={(e, data) =>
                                        setMemberSearchString(data)
                                    }
                                    onChange={(e, data) =>
                                        setFieldValue(
                                            'additionalAdmins',
                                            data || [],
                                        )
                                    }
                                    filterSelectedOptions
                                    renderTags={(val, getTagProps) =>
                                        val.map(({ title }, index) => (
                                            <Chip
                                                key={`chip-${index}`}
                                                variant="outlined"
                                                label={title}
                                                {...getTagProps({
                                                    index,
                                                })}
                                            />
                                        ))
                                    }
                                    multiple
                                    loading={adminsLoading}
                                />
                            </Grid>
                            <Typography
                                variant="body2"
                                className={classes.asteriskRequired}
                            >
                                {t('onBoarding.asteriskRequired')}
                            </Typography>
                        </>
                    );
                }}
            </Form>
        </>
    );
};

CreateCommunityForm.propTypes = {
    initialFormValues: shape({
        image: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        visibility: PropTypes.string,
        additionalAdmins: PropTypes.array,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    orgId: PropTypes.string.isRequired,
    submitBtnText: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    fetchMembers: PropTypes.func,
    searchMembers: PropTypes.func,
    admins: PropTypes.array,
    adminsLoading: PropTypes.bool,
    communityId: PropTypes.string,
    currentUserId: PropTypes.string,
};

CreateCommunityForm.defaultProps = {
    initialFormValues: {
        name: '',
        passwordChecked: false,
        description: '',
        visibility: 'Public',
        additionalAdmins: [],
    },
    onSubmit: noop,
    fetchMembers: noop,
    searchMembers: noop,
    loading: false,
    clearSnackBar: noop,
    admins: [],
    adminsLoading: false,
    communityId: '',
    currentUserId: '',
};

const mapStateToProps = (state) => ({
    adminsLoading: getActiveMembersLoading(state),
    admins: getCommunityAdmins(state),
    orgId: getActiveCommunityId(state),
    currentUserId: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchMembers: (communityId) =>
        dispatch(
            membersRequested(communityId, ACTIVE_STATUS_ACTIVE, { limit: 25 }),
        ),
    searchMembers: (communityId, string) =>
        dispatch(
            membersSearchRequested(communityId, string, ACTIVE_STATUS_ACTIVE, {
                limit: 25,
            }),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateCommunityForm);
