const styles = (theme) => ({
    description: {
        marginBottom: '50px',
    },
    icon: {
        height: '60px',
        width: 'auto',
        fill: theme.palette.primary.main,
    },
    iconLabel: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginTop: '10px',
    },
    datesRow: {
        width: '100%',
        marginTop: '50px',
        marginBottom: '50px',
    },
    startEndLabel: {
        fontSize: '18px',
        color: theme.palette.primary.main,
        marginBottom: '10px',
        fontWeight: '700',
        marginTop: '10px',
    },
    date: {
        fontSize: '30px',
        marginBottom: '10px',
    },
    time: {
        fontSize: '18px',
        marginBottom: '10px',
        color: theme.custom.textDarkSecondaryColor,
    },
    teamsLabel: {
        marginTop: '26px',
        marginBottom: '10px',
        fontSize: '24px',
        color: theme.custom.textDarkSecondaryColor,
    },
    howToWinLabel: {
        marginTop: '26px',
        marginBottom: '10px',
        fontSize: '20px',
        fontWeight: '700',
        color: theme.palette.primary.main,
    },
    rulesLabel: {
        marginTop: '26px',
        marginBottom: '10px',
        fontSize: '20px',
        fontWeight: '700',
        color: theme.palette.primary.main,
    },
    rules: {
        marginBottom: '3px',
    },
    prizeIcon: {
        borderColor: '#fff',
        borderStyle: 'solid',
        borderWidth: '2px',
        position: 'relative',
        zIndex: '0',
    },
    prizeName: {
        fontWeight: 700,
    },
    editable: {
        padding: '8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.25)',
            borderRadius: '6px',

            '&& svg': {
                opacity: 1,
            },
        },
    },
    editIcon: {
        color: theme.palette.white.main,
        opacity: 0.5,
        marginLeft: '3px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        padding: '5px',
        verticalAlign: 'middle',
    },
    dateContainer: {
        backgroundColor: theme.custom.bgLightAccentColor,
        borderRadius: '5px',
        margin: '10px',
        '& .MuiGrid-item': {
            width: '100%',
            position: 'relative',
            textAlign: 'center',
            '& svg.MuiSvgIcon-root': {
                position: 'absolute',
                top: '-2px',
                right: '12px',
                fontSize: '24px',
            },
        },
        '&:hover': {
            '& svg.MuiSvgIcon-root': {
                opacity: 1,
                cursor: 'pointer',
            },
        },
    },
    addButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
    },
    buttonText: {
        justifyContent: 'center',
        textColor: '#ffffff',
    },
    toggleContainer: {
        padding: '8px',
    },
    toggleText: {
        margin: '0px 5px 0px 10px',
    },
});

export default styles;
