import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import {
    AddCircleRounded,
    Edit,
    PlusOneRounded,
    StarsRounded,
    SupervisorAccountRounded,
    VibrationRounded,
} from '@material-ui/icons';

import useStyles from './styles';

import HeaderPage from '../../common/HeaderPage';
import Button from '../../common/Button';
import StatCard from '../../common/StatCard';
import ActivityTotals from '../../common/ActivityTotals';
import { BUTTON_ACCENT_COLOR } from '../../common/Button/constants';
import CommonPropTypes from '../../common/propTypes';
import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import { communityRequested } from '../../community/actions';
import {
    getCommunityErrors,
    getCommunityProfile,
    isCommunityLoading,
} from '../../community/selectors';
import { Can } from '../../casl/ability-context';
import { ImpactTotalPropType } from '../ProfileScreen/propTypes';
import { getAppImages } from '../../utils';
import ImpactToolTip from '../../common/ImpactTooltip';
import { getActiveCommunityOrgId } from '../../user/selectors';

const CommunityProfileScreen = ({
    loading,
    errors,
    profile,
    requestCommunity,
    orgId,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { communityId } = useParams();

    const isPresentable = useMemo(
        () => !loading && errors == null && profile != null,
        [loading, errors, profile],
    );

    const handleEditClick = useCallback(() => {
        history.push(`/communities/${communityId}/edit`);
    }, [communityId, history]);

    useEffect(() => {
        requestCommunity(communityId);
    }, [requestCommunity, communityId]);

    const activityItems = useMemo(
        () =>
            profile?.activityTotals == null
                ? null
                : [
                      {
                          label: t('Points'),
                          value: profile.activityTotals.points,
                          icon: <PlusOneRounded />,
                      },
                      {
                          label: t('profile.logs'),
                          value: profile.activityTotals.logs,
                          icon: <VibrationRounded />,
                      },
                      {
                          label: t('action.descriptor_plural'),
                          value: profile.activityTotals.actions,
                          icon: <AddCircleRounded />,
                      },
                      {
                          label: t('profile.badges'),
                          value: profile.activityTotals.badges,
                          icon: <StarsRounded />,
                      },
                      {
                          label: t('Members'),
                          value: profile.activityTotals.members,
                          icon: <SupervisorAccountRounded />,
                      },
                  ],
        [profile, t],
    );

    return (
        <Grid container direction="column" className={classes.container}>
            <RequestLoader
                isLoading={loading}
                title={`${t('loading.community')}...`}
            />
            <ErrorList errors={errors} />
            {isPresentable ? (
                <>
                    <HeaderPage
                        title={t('community.profile')}
                        rightContent={
                            <Can I="view" a="AdminScreens">
                                <Button
                                    color={BUTTON_ACCENT_COLOR}
                                    className={classes.editButton}
                                    onClick={handleEditClick}
                                >
                                    <Edit />
                                    {t('profile.edit')}
                                </Button>
                            </Can>
                        }
                    />
                    <Grid item className={classes.profileImageContainer}>
                        <img
                            alt={t('alt.profileImage')}
                            src={
                                profile.image ||
                                getAppImages().defaultProfileIcon
                            }
                            className={classes.profileImage}
                        />
                    </Grid>
                    <Typography variant="h2" className={classes.profileName}>
                        {profile.name}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.profileDescription}
                    >
                        {profile.description}
                    </Typography>
                    <Typography
                        variant="h3"
                        className={classes.impactTotalsLabel}
                    >
                        {t('impact.totals')}
                        <ImpactToolTip />
                    </Typography>
                    <Grid container direction="row" spacing={2}>
                        {profile.impactTotals?.map((impact, index) => (
                            <Grid container key={index} item xs>
                                <StatCard capitalize {...impact} />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container className={classes.activityTotals}>
                        <ActivityTotals
                            activityTitle={t('activity.totals')}
                            activityItems={activityItems}
                        />
                    </Grid>
                </>
            ) : null}
            <Typography className={classes.orgId}>
                {communityId !== orgId
                    ? t('community.communityId', { id: communityId })
                    : t('community.orgId', { id: orgId })}
            </Typography>
        </Grid>
    );
};

export const CommunityProfileActivityTotalsPropType = PropTypes.shape({
    points: PropTypes.number.isRequired,
    logs: PropTypes.number.isRequired,
    actions: PropTypes.number.isRequired,
    badges: PropTypes.number.isRequired,
    members: PropTypes.number.isRequired,
});

export const CommunityProfilePropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    impactTotals: PropTypes.arrayOf(ImpactTotalPropType).isRequired,
    activityTotals: CommunityProfileActivityTotalsPropType.isRequired,
});

CommunityProfileScreen.propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    profile: CommunityProfilePropType,
    requestCommunity: PropTypes.func.isRequired,
    orgId: PropTypes.string.isRequired,
};

CommunityProfileScreen.defaultProps = {
    errors: null,
    profile: null,
};

const mapStateToProps = (state) => ({
    loading: isCommunityLoading(state),
    errors: getCommunityErrors(state),
    profile: getCommunityProfile(state),
    orgId: getActiveCommunityOrgId(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestCommunity: (request) => dispatch(communityRequested(request)),
});

const ConnectedCommunityProfileScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommunityProfileScreen);
export default ConnectedCommunityProfileScreen;
