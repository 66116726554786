import {
    DEFAULT_LANGUAGE,
    SUPPORTED_LANGUAGE_CODES,
    SUPPORTED_LANGUAGES,
} from './constants';

export const getLanguageDisplay = (languageCode) => {
    return languageCode && SUPPORTED_LANGUAGE_CODES.includes(languageCode)
        ? SUPPORTED_LANGUAGES[languageCode]
        : SUPPORTED_LANGUAGES[DEFAULT_LANGUAGE];
};
