import { LOGIN_METHOD_EMAIL } from './constants';

const DEFAULT_PROPERTIES = {
    loginMethods: [LOGIN_METHOD_EMAIL],
    apiUri: process.env.REACT_APP_JBE_API_URI,
    posthogConfig: {
        token: 'phc_i5W3kWO7f0Yx72z0lQobcWWzo5cDGbd66bISGHN3ewG',
    },
    firebaseConfig: {
        apiKey: 'AIzaSyAss9cc6FVNkKVNZrqhaO86joG08Ju03wM',
        authDomain: 'shine-app-c250d.firebaseapp.com',
        databaseURL: 'https://shine-app-c250d.firebaseio.com',
        projectId: 'shine-app-c250d',
        storageBucket: 'shine-app-c250d.appspot.com',
        messagingSenderId: '709167458225',
        appId: '1:709167458225:web:e495b574ad23a06f597f90',
        measurementId: 'G-H2QZCL66VK',
    },
    cookieYesConfig: {
        scriptSrc:
            process.env.REACT_APP_COOKIE_YES_SCRIPT_SRC ||
            'https://cdn-cookieyes.com/client_data/0f3cb690981f2aba35a81a77/script.js',
    },
    intercomConfig: {
        enabled: true,
        appId: process.env.REACT_APP_JBE_INTERCOM_APP_ID,
        apiBase: 'https://api-iam.intercom.io',
        productTours: {
            challengeEdit:
                process.env
                    .REACT_APP_JBE_INTERCOM_PRODUCT_TOUR_CHALLENGE_EDIT_ID ||
                '365420',
        },
    },
    colors: {
        primary: '#00B89F',
        primaryDark: '#007262',
        primaryForeground: '#00B89F',
        secondary: '#208DC7',
        secondaryDark: '#1479AE',
        accent: '#208DC7',
        accentDark: '#1479AE',
        warn: '#00B89F',
        warnDark: '#007262',
        EditingBannerBackground: '#f26549',
        loginFooter: '#0E133D',
    },
    fontFamily: 'effra',
    wordpressDomain: 'https://joulebug.com',
};

export default DEFAULT_PROPERTIES;
