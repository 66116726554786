import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Input } from '@material-ui/core';

import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const LimitedLengthInput = ({
    charactersLeft,
    maxCharacters,
    customStyle,
    ...restProps
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <Input {...restProps} />
            <Grid className={classes.charsLeft} style={customStyle}>
                {maxCharacters === null
                    ? `${charactersLeft} ${t('charactersRemaining')}`
                    : `${charactersLeft}/${maxCharacters}`}
            </Grid>
        </>
    );
};

LimitedLengthInput.propTypes = {
    charactersLeft: PropTypes.number.isRequired,
    maxCharacters: PropTypes.number,
    customStyle: PropTypes.object,
};

LimitedLengthInput.defaultProps = {
    maxCharacters: null,
    customStyle: {},
};

export default LimitedLengthInput;
