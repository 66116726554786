import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { LOGIN_METHOD_GOOGLE } from '../../../../properties/constants';
import useStyles from '../styles';
import { noop } from 'rxjs';
import GoogleButton from '../../../SignInScreens/OAuthButtons/GoogleButton';
import Button from '../../../../common/Button';

export const GoogleIntegration = ({
    integrations,
    requestOIDCIntegration,
    requestOIDCIntegrationRemove,
    googleAuth,
    loading,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [googleIntegrated, setGoogleIntegrated] = useState(false);

    useEffect(() => {
        setGoogleIntegrated(
            !!integrations?.oidc?.some(
                (config) => config.authType === LOGIN_METHOD_GOOGLE,
            ),
        );
    }, [integrations.oidc]);

    return (
        <>
            <Grid item container>
                <Typography variant="h2" className={classes.duplication}>
                    {t('settings.sso.descriptor')}
                </Typography>
                <Grid container alignItems="center">
                    <Grid container item xs={6} style={{ paddingRight: 10 }}>
                        <Grid item>
                            <Typography style={{ fontWeight: '700' }}>
                                {t('settings.sso.google')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Trans
                                i18nKey={
                                    googleIntegrated
                                        ? 'settings.sso.googleEnabledExplanation'
                                        : 'settings.sso.googleExplanation'
                                }
                                values={{
                                    domains: integrations.domains?.join(', '),
                                }}
                            >
                                <strong></strong>
                            </Trans>

                            <Typography></Typography>
                        </Grid>
                    </Grid>
                    {!googleIntegrated ? (
                        <GoogleButton
                            clientId={googleAuth.clientId}
                            onClick={() => requestOIDCIntegration(googleAuth)}
                        />
                    ) : (
                        <Button
                            color="danger"
                            loading={loading}
                            onClick={() =>
                                requestOIDCIntegrationRemove(
                                    googleAuth.authType,
                                )
                            }
                        >
                            {t('remove')}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

GoogleIntegration.propTypes = {
    integrations: PropTypes.shape({
        slack: PropTypes.shape({
            postChannel: PropTypes.string,
            teamName: PropTypes.string,
        }),
        oidc: PropTypes.arrayOf(PropTypes.object),
        domains: PropTypes.arrayOf(PropTypes.string),
    }),
    requestOIDCIntegration: PropTypes.func,
    requestOIDCIntegrationRemove: PropTypes.func,
    googleAuth: PropTypes.shape({
        clientId: PropTypes.string,
        clientSecret: PropTypes.string,
        redirectURI: PropTypes.string,
        authType: PropTypes.string,
    }),
    loading: PropTypes.bool,
};

GoogleIntegration.defaultProps = {
    integrations: {},
    requestOIDCIntegration: noop,
    requestOIDCIntegrationRemove: noop,
    googleAuth: {},
    loading: false,
};

export default GoogleIntegration;
