import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ReactComponent as ChatBubbleIcon } from '../../assets/images/svg/chat-bubble.svg';
import { CARD_SCREENS, HOME_SCREEN } from './constants';

const PhotoCard = ({ classes, photo, index, onClick, screen }) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            item
            className={classes.container}
            onClick={(e) => onClick(e, index)}
            xs={3}
            sm={3}
            md={3}
            lg={2}
        >
            <div className={classes.wrapper}>
                <img
                    src={photo.photoUrl}
                    className={classes.photo}
                    alt={t('alt.actionPhoto')}
                />
                <div
                    className={clsx(
                        classes.overlay,
                        screen === HOME_SCREEN && classes.overlayHomeScreen,
                    )}
                >
                    <div
                        className={clsx(
                            classes.overlayTop,
                            screen === HOME_SCREEN &&
                                classes.overlayTopHomeScreen,
                        )}
                    >
                        <img
                            src={photo.actionUrl}
                            className={classes.actionImage}
                            alt={t('alt.actionImage')}
                        />
                    </div>
                    <div
                        className={clsx(
                            classes.overlayBottom,
                            screen === HOME_SCREEN && classes.overlayHomeScreen,
                        )}
                    >
                        <div
                            className={clsx(
                                classes.overlayBottomLeft,
                                classes.overlayBottomSpacing,
                            )}
                        >
                            <Favorite className={classes.icon} />
                            {photo.likes.length || 0}
                        </div>
                        <div
                            className={clsx(
                                classes.overlayBottomRight,
                                classes.overlayBottomSpacing,
                            )}
                        >
                            <ChatBubbleIcon className={classes.chatIcon} />
                            {photo.comments.length || 0}
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
};

PhotoCard.propTypes = {
    classes: PropTypes.object.isRequired,
    photo: PropTypes.shape({
        photoUrl: PropTypes.string,
        actionUrl: PropTypes.string,
        likes: PropTypes.array,
        comments: PropTypes.array,
    }).isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    screen: PropTypes.oneOf(CARD_SCREENS).isRequired,
};

PhotoCard.defaultProps = {
    onClick: null,
};

export default withStyles(styles)(PhotoCard);
