import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import App from './App';
import './localization/i18n';

WebFont.load({
    typekit: {
        id: 'gzv0tyn',
        families: ['effra', 'sans-serif'],
    },
});

const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS;

if (analyticsId) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', analyticsId);
    };
}

ReactDOM.render(<App />, document.getElementById('root'));
