const styles = (theme) => ({
    onBoardingFooterContainer: {
        backgroundColor: theme.custom.bgOnboardingFooter,
        padding: '5px 27px 5px 27px',
        zIndex: '4',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '13px',
        },
    },
    onBoardingFooterContainerFixed: {
        backgroundColor: theme.custom.bgOnboardingFooter,
        padding: '5px 27px 5px 27px',
        zIndex: '4',
        position: 'fixed',
        bottom: '0px',
        height: '72px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '13px',
        },
    },
    gridLeft: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'revert',
        },
    },
    listItem: {
        display: 'flex',
        padding: '10px 10px 10px 10px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px',
            marginRight: '13px',
            marginBottom: '10px',
            width: '50%',
        },
    },
    inlineListSection: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'revert',
            width: '100%',
        },
    },
    onBoardingFooterText: {
        color: theme.palette.white.main,
        opacity: 0.75,
    },
    storeBadge: {
        maxWidth: '125px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '129px',
        },
    },
    lightModeContainer: {
        backgroundColor: 'white',
        padding: '5px 27px 5px 27px',
        zIndex: '0',
        position: 'fixed',
        bottom: '0px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            backgroundColor: theme.custom.white,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '13px',
        },
        '& a': {
            color: theme.custom.textDarkSecondaryColor,
        },
    },
});

export default styles;
