import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import useStyles from './styles';

import { Can } from '../../../casl/ability-context';
import Button from '../../../common/Button';
import { noop } from '../../../utils';
import { VideoPropType } from '../../../actions/propTypes';
import { toVideoURL } from '../../../actions/transforms';

const VideoComponent = ({ video, onEdit, onRemove }) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const { videoId, isCommunity: withControls } = video;

    return (
        <Grid container item direction="column">
            <Grid item>
                <iframe
                    title="video preview"
                    src={toVideoURL(videoId)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </Grid>
            {withControls ? (
                <Can I="edit" a="CommunityActionYoutubes">
                    {() => (
                        <Grid
                            container
                            item
                            direction="row"
                            justify="flex-end"
                            spacing={1}
                            className={classes.videoControls}
                        >
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="gray"
                                    onClick={onEdit}
                                >
                                    <Edit
                                        fontSize="small"
                                        className={classes.buttonIcon}
                                    />
                                    {t('edit')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="gray"
                                    onClick={onRemove}
                                >
                                    {t('Remove')}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Can>
            ) : null}
        </Grid>
    );
};

VideoComponent.propTypes = {
    video: VideoPropType.isRequired,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
};

VideoComponent.defaultProps = {
    onEdit: noop,
    onRemove: noop,
};

export default VideoComponent;
