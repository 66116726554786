export const PERMISSIONS = [
    {
        action: 'activate',
        subject: 'Challenge',
        identifier: 'activateChallenge',
    },
    { action: 'create', subject: 'Challenge', identifier: 'createChallenge' },
    {
        action: 'change',
        subject: 'AppContext',
        identifier: 'changeAppContext',
    },
    { action: 'deactivate', subject: 'User', identifier: 'deactivateUser' },
    { action: 'delete', subject: 'User', identifier: 'deleteUser' },
    {
        action: 'remove',
        subject: 'UserFromCommunity',
        identifier: 'removeUserFromCommunity',
    },
    { action: 'view', subject: 'UserProfile', identifier: 'viewUserProfile' },
    { action: 'edit', subject: 'Actions', identifier: 'editActions' },
    {
        action: 'edit',
        subject: 'CommunityActionAppendDescription',
        identifier: 'editCommunityActionAppendDescription',
    },
    {
        action: 'edit',
        subject: 'CommunityActionExclude',
        identifier: 'editCommunityActionExclude',
    },
    {
        action: 'edit',
        subject: 'CommunityActionOverwriteDescription',
        identifier: 'editCommunityActionOverwriteDescription',
    },
    {
        action: 'edit',
        subject: 'CommunityActionPhoto',
        identifier: 'editCommunityActionPhoto',
    },
    {
        action: 'edit',
        subject: 'CommunityActionUrls',
        identifier: 'editCommunityActionUrls',
    },
    {
        action: 'edit',
        subject: 'CommunityActionYoutubes',
        identifier: 'editCommunityActionYoutubes',
    },
    {
        action: 'edit',
        subject: 'CommunityProfile',
        identifier: 'editCommunityProfile',
    },
    { action: 'edit', subject: 'Challenge', identifier: 'editChallenge' },
    {
        action: 'edit',
        subject: 'ChallengePhoto',
        identifier: 'editChallengePhoto',
    },
    {
        action: 'edit',
        subject: 'ChallengeIcon',
        identifier: 'editChallengeIcon',
    },
    {
        action: 'edit',
        subject: 'ChallengeDescription',
        identifier: 'editChallengeDescription',
    },
    {
        action: 'edit',
        subject: 'ChallengeDuration',
        identifier: 'editChallengeDuration',
    },
    {
        action: 'edit',
        subject: 'ChallengeTeams',
        identifier: 'editChallengeTeams',
    },
    {
        action: 'edit',
        subject: 'ChallengeHowToWin',
        identifier: 'editChallengeHowToWin',
    },
    {
        action: 'edit',
        subject: 'ChallengeRules',
        identifier: 'editChallengeRules',
    },
    {
        action: 'edit',
        subject: 'ChallengePrizes',
        identifier: 'editChallengePrizes',
    },
    {
        action: 'edit',
        subject: 'Themes',
        identifier: 'editThemes',
    },
    {
        action: 'edit',
        subject: 'BillingInfo',
        identifier: 'editBillingInfo',
    },
    {
        action: 'manage',
        subject: 'MasterNotifications',
        identifier: 'manageMasterNotifications',
    },
    { action: 'optOut', subject: 'Challenge', identifier: 'optOutChallenge' },
    { action: 'reactivate', subject: 'User', identifier: 'reactivateUser' },
    { action: 'send', subject: 'OrgInvite', identifier: 'sendOrgInvite' },
    { action: 'set', subject: 'UserRole', identifier: 'setUserRole' },
    { action: 'suspend', subject: 'User', identifier: 'suspendUser' },
    {
        action: 'manage',
        subject: 'FlaggedUserActivity',
        identifier: 'manageFlaggedUserActivity',
    },
    { action: 'move', subject: 'UserOrg', identifier: 'moveUserOrg' },
    {
        action: 'view',
        subject: 'ActionsAdmin',
        identifier: 'viewActionsAdmin',
    },
    { action: 'view', subject: 'Assets', identifier: 'viewAssets' },
    {
        action: 'view',
        subject: 'ChallengesAdmin',
        identifier: 'viewChallengesAdmin',
    },
    {
        action: 'view',
        subject: 'ChallengeAnalytics',
        identifier: 'viewChallengeAnalytics',
    },
    {
        action: 'view',
        subject: 'ChallengePenalties',
        identifier: 'viewChallengePenalties',
    },
    { action: 'view', subject: 'Communities', identifier: 'viewCommunities' },
    {
        action: 'view',
        subject: 'CommunityAnalytics',
        identifier: 'viewCommunityAnalytics',
    },
    {
        action: 'view',
        subject: 'CommunityMembers',
        identifier: 'viewCommunityMembers',
    },
    {
        action: 'view',
        subject: 'CommunityProfile',
        identifier: 'viewCommunityProfile',
    },
    {
        action: 'view',
        subject: 'FlaggedContent',
        identifier: 'viewFlaggedContent',
    },
    {
        action: 'view',
        subject: 'MasterNotifications',
        identifier: 'viewMasterNotifications',
    },
    { action: 'view', subject: 'Support', identifier: 'viewSupport' },
    {
        action: 'view',
        subject: 'TeamAnalytics',
        identifier: 'viewTeamAnalytics',
    },
    {
        action: 'view',
        subject: 'UserPrivateData',
        identifier: 'viewUserPrivateData',
    },
    {
        action: 'view',
        subject: 'Themes',
        identifier: 'viewThemes',
    },
    {
        action: 'view',
        subject: 'ChallengeReports',
        identifier: 'viewChallengeReports',
    },
    {
        action: 'view',
        subject: 'BillingInfo',
        identifier: 'viewBillingInfo',
    },
    {
        action: 'warn',
        subject: 'ChallengeUser',
        identifier: 'warnChallengeUser',
    },
    {
        action: 'remove',
        subject: 'ChallengeUser',
        identifier: 'removeChallengeUser',
    },
    {
        action: 'add',
        subject: 'SlackWebhook',
        identifier: 'addSlackWebhook',
    },
    {
        action: 'create',
        subject: 'Community',
        identifier: 'createCommunity',
    },
];
