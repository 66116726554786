import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
    getActiveCommunityId,
    getActiveCommunityImage,
    getActiveCommunityName,
    getActiveCommunityOrgId,
    getAvailableCommunities,
    isActiveCommunityLoading,
    isUserViewActive,
} from '../../user/selectors';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import {
    Badge,
    Drawer,
    Grid,
    isWidthDown,
    isWidthUp,
    List,
    ListItem,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import {
    AssessmentOutlined,
    Folder,
    Group,
    Home,
    Settings,
} from '@material-ui/icons';
import CommunityCard from '../CommunityCard';
import MenuDropdown from '../MenuDropdown';
import { Can } from '../../casl/ability-context';
import IcomoonIcon from '../IcomoonIcon';
import {
    AccountMenuIcon,
    CommunitiesIcon,
} from '../../assets/images/svg/index';
import CommunityDropdown from '../CommunityDropdown';
import {
    actionItems,
    challengeItems,
    notificationsItems,
    TAB_ACTIONS,
    TAB_CHALLENGES,
    TAB_NOTIFICATIONS,
    TAB_USERS,
    usersItems,
} from './constants';
import { localizeFieldsInObject } from '../../localization/transforms';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { setActiveCommunity } from '../../user/actions';
import { availableCommunitiesPropType } from '../../user/propTypes';
import { shouldShowSubscription } from '../../subscriptions/utils';
import { getUserNotificationCounts } from '../../notifications/selectors';

export const LeftColumn = ({
    classes,
    activeCommunityName,
    activeCommunityId,
    activeCommunityOrgId,
    activeCommunityImage,
    communities,
    width,
    isOpen,
    setIsOpen,
    changeCommunity,
    activeCommunityLoading,
    userViewActive,
    notificationCounts,
}) => {
    const { t } = useTranslation();
    const [communitySelectOpen, setCommunitySelectOpen] = useState(false);
    const history = useHistory();
    const isCommunitySelected = useRouteMatch(
        `/communities/${activeCommunityId}`,
    );

    // expandable panel states
    const [adminActionTabOpen, setAdminActionTabOpen] = useState(false);
    const [adminChallengeTabOpen, setAdminChallengeTabOpen] = useState(false);
    const [adminNotificationsTabOpen, setAdminNotificationsTabOpen] = useState(
        false,
    );
    const [adminUsersTabOpen, setAdminUsersTabOpen] = useState(false);

    // localize menu items
    const tUsersItems = useMemo(
        () => localizeFieldsInObject(t, 'label', usersItems),
        [t],
    );
    const tActionItems = useMemo(
        () => localizeFieldsInObject(t, 'label', actionItems),
        [t],
    );
    const tChallengeItems = useMemo(
        () => localizeFieldsInObject(t, 'label', challengeItems),
        [t],
    );
    const tNotificationsItems = useMemo(
        () => localizeFieldsInObject(t, 'label', notificationsItems),
        [t],
    );

    const handleTabClick = () => {
        if (isWidthDown('xs', width)) {
            setIsOpen(false);
        }
    };

    const handleCloseExpandedTabs = () => {
        setAdminActionTabOpen(false);
        setAdminChallengeTabOpen(false);
        setAdminNotificationsTabOpen(false);
        setAdminUsersTabOpen(false);
        handleTabClick();
    };

    const handleSelfExpand = (id) => {
        if (id === TAB_ACTIONS) setAdminActionTabOpen(!adminActionTabOpen);
        else setAdminActionTabOpen(false);

        if (id === TAB_CHALLENGES)
            setAdminChallengeTabOpen(!adminChallengeTabOpen);
        else setAdminChallengeTabOpen(false);

        if (id === TAB_NOTIFICATIONS)
            setAdminNotificationsTabOpen(!adminNotificationsTabOpen);
        else setAdminNotificationsTabOpen(false);

        if (id === TAB_USERS) setAdminUsersTabOpen(!adminUsersTabOpen);
        else setAdminUsersTabOpen(false);
    };

    const setStateByResize = useCallback(() => {
        if (isWidthUp('md', width)) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [setIsOpen, width]);

    const changeCommunityHandler = (value) => {
        const params = new URLSearchParams(window.location.search);
        params.delete('communityId');
        params.append('communityId', value.content?.id || value.organizationId);

        const searchParams = params.toString();
        const communityId = value.organizationId;
        let newUrl;
        if (window.location.pathname.startsWith('/communities')) {
            newUrl = `/communities/${communityId}?communityId=${communityId}`;
        } else {
            newUrl = `${window.location.pathname}?${searchParams}&communityId=${communityId}`;
        }
        history.push(newUrl);
        changeCommunity(value.organizationId);
        changeCommunity(value.content?.id || value.organizationId);
    };

    const communityClickHandler = () => {
        handleTabClick();
        history.push(`/communities/${activeCommunityId}`);
    };

    useEffect(() => {
        setStateByResize();
    }, [setStateByResize]);

    const [communitySearchValue, setCommunitySearchValue] = useState('');

    const communityDropdownPaperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                !communityDropdownPaperRef.current?.contains(event.target) &&
                setCommunitySelectOpen &&
                !isOpen
            ) {
                setCommunitySelectOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        communitySelectOpen,
        setCommunitySelectOpen,
        communityDropdownPaperRef,
        isOpen,
    ]);

    const isOrganization = activeCommunityOrgId === activeCommunityId;

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.openDrawer]: isOpen,
                [classes.hideDrawer]: !isOpen,
            })}
            classes={{
                paper: clsx(
                    {
                        [classes.openDrawer]: isOpen,
                        [classes.hideDrawer]: !isOpen,
                    },
                    classes.paper,
                ),
            }}
            ModalProps={{
                keepMounted: true,
            }}
        >
            {!isOrganization &&
                communities.some(
                    (comm) => comm.content.id === activeCommunityOrgId,
                ) && (
                    <Grid
                        container
                        item
                        className={classes.backToOrgButtonContainer}
                    >
                        <Typography
                            className={classes.backToOrgButton}
                            onClick={() =>
                                changeCommunity(activeCommunityOrgId)
                            }
                        >
                            {t('community.backToOrg')}
                        </Typography>
                    </Grid>
                )}
            <CommunityCard
                name={activeCommunityName}
                url={activeCommunityImage}
                onClick={communityClickHandler}
                active={isCommunitySelected}
            />
            <List>
                {!userViewActive && communities.length > 1 && (
                    <ListItem
                        className={clsx({
                            [classes.navLink]: !isOpen,
                            [classes.dropdown]: isOpen,
                        })}
                    >
                        {!isOpen && (
                            <Tooltip
                                title={t('communityDropdown.switchCommunity')}
                                placement="left"
                            >
                                <Group
                                    className={classes.icon}
                                    onClick={() => {
                                        setCommunitySelectOpen(
                                            !communitySelectOpen,
                                        );
                                    }}
                                />
                            </Tooltip>
                        )}
                        <CommunityDropdown
                            paperRef={communityDropdownPaperRef}
                            communities={communities}
                            disableClearable
                            inputValue={communitySearchValue}
                            onInputChange={(e, newInputValue) => {
                                setCommunitySearchValue(newInputValue);
                                if (
                                    communities?.length > 100 &&
                                    newInputValue?.length < 2
                                ) {
                                    setCommunitySelectOpen(false);
                                }
                            }}
                            onChange={(e, val) => {
                                changeCommunityHandler(val);
                                setCommunitySearchValue('');
                                setCommunitySelectOpen(false);
                            }}
                            loading={activeCommunityLoading}
                            disabled={activeCommunityLoading}
                            open={communitySelectOpen}
                            onOpen={() => {
                                if (
                                    communities?.length < 100 ||
                                    communitySearchValue?.length > 0
                                ) {
                                    setCommunitySelectOpen(true);
                                }
                            }}
                            onClose={() => setCommunitySelectOpen(false)}
                            clearOnEscape
                        />
                    </ListItem>
                )}
                <Can I="view" a="AdminScreens">
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            onClick={handleCloseExpandedTabs}
                            to="/"
                            exact
                        >
                            <Home className={classes.icon} />
                            {t('home.descriptor')}
                        </NavLink>
                    </ListItem>
                </Can>
                <Can not I="view" a="AdminScreens">
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            to="/actions"
                            onClick={handleTabClick}
                            exact
                        >
                            <IcomoonIcon
                                className={classes.iconMoon}
                                size={20}
                                icon="jb-buzz"
                            />
                            {t('action.descriptor_plural')}
                            <Badge
                                badgeContent={notificationCounts.ActivityFeed}
                                color="primary"
                                className={classes.Buzz}
                            />
                        </NavLink>
                    </ListItem>
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            to="/challenges"
                            onClick={handleTabClick}
                            exact
                        >
                            <IcomoonIcon
                                className={classes.iconMoon}
                                size={20}
                                icon="jb-challenge"
                            />
                            {t('challenge_plural')}
                            <Badge
                                badgeContent={notificationCounts.Leaderboard}
                                color="primary"
                                className={classes.notificationBadge}
                            />
                        </NavLink>
                    </ListItem>
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            to="/activities"
                            onClick={handleTabClick}
                            exact
                        >
                            <IcomoonIcon
                                className={classes.iconMoon}
                                size={20}
                                icon="jb-comment"
                            />
                            {t('activities')}
                            <Badge
                                badgeContent={notificationCounts.ActivityFeed}
                                color="primary"
                                className={classes.notificationBadge}
                            />
                        </NavLink>
                    </ListItem>
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            to="/notifications"
                            onClick={handleTabClick}
                            exact
                        >
                            <IcomoonIcon
                                className={classes.iconMoon}
                                size={20}
                                icon="jb-notifications"
                            />
                            {t('notifications.descriptor')}
                            <Badge
                                badgeContent={
                                    notificationCounts.NotificationCenter
                                }
                                color="primary"
                                className={classes.notificationBadge}
                            />
                        </NavLink>
                    </ListItem>
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            to="/profile/self"
                            onClick={handleTabClick}
                            exact
                        >
                            <IcomoonIcon
                                className={classes.iconMoon}
                                size={20}
                                icon="jb-profile"
                            />
                            {t('profile.descriptor')}
                            <Badge
                                badgeContent={notificationCounts.Profile}
                                color="primary"
                                className={classes.notificationBadge}
                            />
                        </NavLink>
                    </ListItem>
                </Can>

                <Can I="view" a="CommunityAnalytics">
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            onClick={handleCloseExpandedTabs}
                            to="/analytics"
                        >
                            <AssessmentOutlined className={classes.icon} />
                            {t('analytics')}
                        </NavLink>
                    </ListItem>
                </Can>
                <Can I="view" a="ActionsAdmin">
                    <ListItem className={classes.menuItem}>
                        <MenuDropdown
                            menuItems={tActionItems}
                            TitleIcon={IcomoonIcon}
                            iconName="jb-buzz"
                            title={t('action.descriptor_plural')}
                            id={TAB_ACTIONS}
                            titleUrl="/actions"
                            isExpandBlocked={!isOpen}
                            isExpanded={adminActionTabOpen}
                            handleSelfExpand={handleSelfExpand}
                        />
                    </ListItem>
                </Can>
                <Can I="view" a="ChallengesAdmin">
                    <ListItem className={classes.menuItem}>
                        <MenuDropdown
                            menuItems={tChallengeItems}
                            TitleIcon={IcomoonIcon}
                            iconName="jb-challenge"
                            title={t('challenge_plural')}
                            id={TAB_CHALLENGES}
                            titleUrl="/challenges"
                            isExpandBlocked={!isOpen}
                            isExpanded={adminChallengeTabOpen}
                            handleSelfExpand={handleSelfExpand}
                        />
                    </ListItem>
                </Can>
                <Can I="view" a="MasterNotifications">
                    <ListItem className={classes.menuItem}>
                        <MenuDropdown
                            menuItems={tNotificationsItems}
                            TitleIcon={IcomoonIcon}
                            iconName="jb-notifications"
                            title={t('notifications.descriptor')}
                            id={TAB_NOTIFICATIONS}
                            titleUrl="/notifications"
                            isExpandBlocked={!isOpen}
                            isExpanded={adminNotificationsTabOpen}
                            handleSelfExpand={handleSelfExpand}
                        />
                    </ListItem>
                </Can>
                <Can I="view" a="CommunityMembers">
                    <ListItem className={classes.menuItem}>
                        <MenuDropdown
                            menuItems={tUsersItems}
                            TitleIcon={IcomoonIcon}
                            iconName="jb-profile"
                            title={t('member_plural')}
                            titleUrl="/members"
                            id="users"
                            isExpandBlocked={!isOpen}
                            isExpanded={adminUsersTabOpen}
                            handleSelfExpand={handleSelfExpand}
                        />
                    </ListItem>
                </Can>
                {isOrganization && (
                    <Can I="view" a="Communities">
                        <ListItem className={classes.menuItem}>
                            <NavLink
                                className={classes.navLink}
                                activeClassName={classes.navLinkActive}
                                onClick={handleCloseExpandedTabs}
                                to="/communities"
                            >
                                <CommunitiesIcon
                                    className={classes.importedIcon}
                                />
                                {t('community.community_plural')}
                            </NavLink>
                        </ListItem>
                    </Can>
                )}
                {isOrganization && shouldShowSubscription() && (
                    <Can I="view" a="BillingInfo">
                        <ListItem className={classes.menuItem}>
                            <NavLink
                                className={classes.navLink}
                                activeClassName={classes.navLinkActive}
                                onClick={handleCloseExpandedTabs}
                                to="/account"
                            >
                                <AccountMenuIcon
                                    className={classes.importedIcon}
                                />
                                {t('Account')}
                            </NavLink>
                        </ListItem>
                    </Can>
                )}
                <Can I="view" a="FlaggedContent">
                    <ListItem className={classes.menuItem}>
                        <NavLink
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            onClick={handleCloseExpandedTabs}
                            to="/flags"
                        >
                            <IcomoonIcon
                                className={classes.iconMoon}
                                size={20}
                                icon="jb-flag"
                            />
                            {t('Flags')}
                        </NavLink>
                    </ListItem>
                </Can>
                <Can I="view" a="Assets">
                    {/* Disabled for everyone for now -- route was also removed JB-2120 */}
                    {false && (
                        <ListItem className={classes.menuItem} hidden={true}>
                            <NavLink
                                className={classes.navLink}
                                activeClassName={classes.navLinkActive}
                                onClick={handleCloseExpandedTabs}
                                to="/assets"
                            >
                                <Folder className={classes.icon} />
                                {t('Downloads')}
                            </NavLink>
                        </ListItem>
                    )}
                </Can>
                <ListItem className={classes.menuItem}>
                    <NavLink
                        className={classes.navLink}
                        activeClassName={classes.navLinkActive}
                        onClick={handleCloseExpandedTabs}
                        to="/settings"
                    >
                        <Settings className={classes.icon} />
                        {t('Settings')}
                    </NavLink>
                </ListItem>
            </List>
        </Drawer>
    );
};

LeftColumn.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
    activeCommunityName: PropTypes.string.isRequired,
    activeCommunityId: PropTypes.string.isRequired,
    activeCommunityOrgId: PropTypes.string,
    activeCommunityImage: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]).isRequired,
    communities: availableCommunitiesPropType.isRequired,
    changeCommunity: PropTypes.func.isRequired,
    activeCommunityLoading: PropTypes.bool,
    userViewActive: PropTypes.bool.isRequired,
    notificationCounts: PropTypes.object.isRequired,
};

LeftColumn.defaultProps = {
    activeCommunityLoading: false,
    activeCommunityOrgId: '',
};

export const StyledLeftColumn = withStyles(styles)(LeftColumn);

const mapStateToProps = (state) => ({
    activeCommunityName: getActiveCommunityName(state),
    activeCommunityId: getActiveCommunityId(state),
    activeCommunityOrgId: getActiveCommunityOrgId(state),
    activeCommunityImage: getActiveCommunityImage(state),
    communities: getAvailableCommunities(state),
    activeCommunityLoading: isActiveCommunityLoading(state),
    userViewActive: isUserViewActive(state),
    notificationCounts: getUserNotificationCounts(state),
});

const mapDispatchToProps = (dispatch) => ({
    changeCommunity: (communityId) => dispatch(setActiveCommunity(communityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledLeftColumn);
