import get from 'lodash/get';
import { CHALLENGE_UNIT_TEAM, CHALLENGE_UNIT_USER } from './constants';
import {
    COMPETITION_GOALS,
    GROUP_INDIVIDUAL,
    GROUP_TEAM,
    TEAMS_TYPE_USER_CREATED,
} from '../screens/CreateChallengeScreen/CustomizeChallenge/constants';
import i18n from 'i18next';
import { mapSortAddRank } from '../utils';
import { transformAction } from '../actions/transforms';

export const transformUsers = (data) =>
    mapSortAddRank(
        (get(data, 'activities') || []).map((item, index) => ({
            id: `top-user-${index}`,
            label: item.contentName || '',
            value: item.points,
            image: get(item, 'images[0].url') || '',
        })),
    );

export const transformActivity = (data) =>
    mapSortAddRank(
        (data || []).reduce(
            (result, item, index) =>
                item.contentName
                    ? [
                          ...result,
                          {
                              id: `top-actions-${index}`,
                              label: item.contentName,
                              value: item.total,
                              image: get(item, 'images[0].url') || '',
                          },
                      ]
                    : result,
            [],
        ),
    );

export const transformLeaderboardItem = (
    { status, rank, item: { score = 0, id, name, imageSizes } },
    includeScore = false,
) => {
    const transformedTeam = {
        id,
        label: name,
        image: imageSizes?.[imageSizes.length - 1]?.url,
        disabled: status === 'unjoined',
        status,
        rank,
    };
    if (includeScore) {
        transformedTeam.value = score;
    }
    return transformedTeam;
};

const reorderLeaderboardItems = (items) =>
    Array.prototype.concat.apply(
        ...items.reduce(
            ([enabled, disabled], item) => [
                item.disabled ? [...enabled] : [...enabled, item],
                item.disabled ? [...disabled, item] : [...disabled],
            ],
            [[], []],
        ),
    );

export const transformLeaderboardItems = (
    leaderboard,
    includeScore = false,
) => {
    return reorderLeaderboardItems(
        leaderboard.map((team) => transformLeaderboardItem(team, includeScore)),
    );
};

export const transformChallengeCreateQuery = (params) => {
    const {
        teams,
        challengeName,
        challengeDescription,
        startDate,
        endDate,
        language,
        selectedGroup,
        teamsType,
        selectedCompetition,
        goalPointValue,
        goalsType,
        themeId,
        challengeToDuplicateId,
        icon,
        ...restParams
    } = params;
    const body = {
        challengeName: { [language]: challengeName },
        challengeDescription: { [language]: challengeDescription },
        challengeUnit:
            selectedGroup === GROUP_INDIVIDUAL
                ? CHALLENGE_UNIT_USER
                : CHALLENGE_UNIT_TEAM,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        competitionType: selectedCompetition,
        language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        duplicateChallengeId: challengeToDuplicateId,
        ...restParams,
    };
    if (icon) {
        body.icon = {
            sizes: icon,
        };
    }
    if (!challengeToDuplicateId) {
        body.themeId = themeId;
    }
    if (selectedGroup === GROUP_TEAM) {
        body.teamCreationEnabled = teamsType === TEAMS_TYPE_USER_CREATED;
        if (teamsType === TEAMS_TYPE_USER_CREATED) {
            body.playersPerTeam = teams;
        } else {
            body.teams = teams;
        }
    }
    if (selectedCompetition === COMPETITION_GOALS) {
        body.goalPointValue = goalPointValue;
        body.goalsType = goalsType;
    }
    return body;
};

export const transformChallenge = (challenge, actions) =>
    challenge && {
        rulesType: challenge.rulesType,
        unit: challenge.challengeUnit,
        contentFilterUrl: challenge.contentFilterIcon?.[0]?.url,
        contentFilterText: challenge.contentFilterText,
        description: challenge.basicsInfo.description,
        howToWin: challenge.howToWinInfo.description,
        rules: challenge.rulesInfo.description,
        prizes: challenge.prizes,
        autoJoin: challenge.joinCommunityMembersAutomatically,
        makeChallengeCategory: challenge.makeChallengeCategory,
        includeActionIds: challenge.includeActionIds || [],
        excludeActionIds: challenge.excludeActionIds || [],
        actions: {
            ...actions,
            data: actions?.data?.map((action) => transformAction(action)) || [],
        },
    };

export const transformUpdateChallengeRequest = (
    image,
    author,
    iconData,
    info,
    startDate,
    endDate,
    name,
    teams,
) => ({
    ...(image != null
        ? {
              headlinePhoto: {
                  photo: {
                      sizes: image,
                  },
                  photoAuthorName: author,
              },
          }
        : {}),
    ...(iconData != null
        ? {
              icon: {
                  sizes: iconData,
              },
          }
        : {}),
    basicDescription: {
        [i18n.language]: info.description,
    },
    startDate: startDate.valueOf(),
    endDate: endDate.valueOf(),
    howToWin: {
        [i18n.language]: info.howToWin,
    },
    rulesInfo: {
        [i18n.language]: info.rules,
    },
    challengeName: {
        [i18n.language]: name,
    },
    prizes: info.prizes.map((prize) => {
        const transformedPrize = {
            ...prize,
            id: prize.id,
            name: {
                [i18n.language]: prize.name,
            },
            description: {
                [i18n.language]: prize.description,
            },
        };
        /*
         * The prize imageSizes will only have a file field if a new image was uploaded
         * due to a quirk in the API
         */
        if (prize.imageSizes?.[0]?.file) {
            transformedPrize.updateImage = {
                sizes: prize.imageSizes,
            };
        }
        return transformedPrize;
    }),
    teams,
    joinCommunityMembersAutomatically: info.autoJoin,
    makeChallengeCategory: info.makeChallengeCategory,
    includeActionIds: info.includeActionIds,
    excludeActionIds: info.excludeActionIds,
});
