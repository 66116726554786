const styles = (theme) => ({
    container: {
        padding: `29px 0px`,
        maxWidth: theme.custom.mainContentWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    header: {
        paddingLeft: theme.custom.mainContentHorizontalPadding,
        paddingRight: theme.custom.mainContentHorizontalPadding,
    },
    bannerTitle: {
        marginBottom: 10,
        marginTop: 10,
        fontSize: 30,
        fontWeight: 'bold',
    },
    bannerBody: {
        margin: '0 0 10px',
        fontSize: 24,
    },
    sectionHeading: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    title: {
        fontSize: '36px',
    },
    infoIcon: {
        marginLeft: '6px',
        fontSize: '28px',
        color: 'rgba(51,51,51,.25)',
        cursor: 'pointer',

        '&:hover': {
            color: 'rgba(51,51,51,.5)',
        },
    },
    searchContainer: {
        position: 'relative',
        paddingBottom: '20px',
    },
    emptyTextContainer: {
        textAlign: 'center',
    },
    emptyText: {
        fontWeight: 600,
        color: theme.custom.textLightSecondaryColor,
    },
    searchList: {
        border: 'solid 1px',
        borderColor: theme.custom.grey,
        display: 'inline-block',
        borderRadius: '5px',
        maxHeight: '300px',
        height: 'auto',
        overflowY: 'auto',
        position: 'absolute',
        left: '50px',
        zIndex: '9999',
        backgroundColor: 'white',
        boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
        '&::-webkit-scrollbar': {
            width: '0.7em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '20px',
        },
        '& li': {
            padding: '5px 25px',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                '& a': {
                    color: 'white',
                },
            },
            '& a': {
                display: 'flex',
                textDecoration: 'none',
                color: theme.custom.textDarkColor,
                '&:focus-visible': {
                    outline: 'none',
                },
                '& img': {
                    width: '25px',
                    marginRight: '10px',
                },
            },
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '20px',
            },
        },
    },
});

export default styles;
