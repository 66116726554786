import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../ConfirmModal';
import Activate from './Activate';

const ActivateModal = ({
    isOpen,
    isActivate,
    items,
    headerKey,
    description,
    onClose,
    onCancel,
    onConfirm,
    ...props
}) => {
    const { t } = useTranslation();
    const action = isActivate ? t('activate') : t('deactivate');

    return (
        <ConfirmModal
            title={t('confirmUpdate')}
            isOpen={isOpen}
            confirmBtnText={action}
            confirmBtnType={isActivate ? 'success' : 'danger'}
            cancelBtnText="Cancel"
            cancelBtnType="default"
            onClose={onClose}
            onCancel={onCancel}
            onConfirm={onConfirm}
            {...props}
        >
            <Activate
                isActivate={isActivate}
                items={items}
                headerKey={headerKey}
                description={description}
            />
        </ConfirmModal>
    );
};

ActivateModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isActivate: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            iconUrl: PropTypes.string.isRequired,
            identifier: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    description: PropTypes.string.isRequired,
    // Should be a translation key
    headerKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

ActivateModal.defaultProps = {
    isActivate: false,
};

export default ActivateModal;
