import React from 'react';
import { Can } from '../../casl/ability-context';
import { Link } from 'react-router-dom';
import { getLanguageDisplay } from '../../localization/utils';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import MessagePaper from '../MessagePaper';
import useStyles from './styles';
import { availableCommunitiesPropType } from '../../user/propTypes';

const EditingBanner = ({ actionDuplicationCommunities, ...props }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    return (
        <Can I="view" a="Support">
            <MessagePaper
                type="info"
                classes={{ info: classes.banner }}
                {...props}
                data-intercom-target="Editing Banner Container"
            >
                <Grid item container direction="row" alignItems="center">
                    <Grid item className={classes.messageGridItem}>
                        <Typography className={classes.messageText}>
                            <Trans i18nKey="editingBanner.languageMessage">
                                You are editing in the
                                <strong>
                                    {{
                                        displayLanguage: getLanguageDisplay(
                                            i18n.language,
                                        ),
                                    }}
                                </strong>
                                Language.
                            </Trans>
                        </Typography>
                        {actionDuplicationCommunities.length > 0 && (
                            <Typography className={classes.messageText}>
                                <Trans i18nKey="editingBanner.communityMessage">
                                    Your edits are being duplicated in
                                    <strong>
                                        {{
                                            communities: actionDuplicationCommunities
                                                .map(
                                                    (community) =>
                                                        community.content.name,
                                                )
                                                .join(', '),
                                        }}
                                    </strong>
                                </Trans>
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        data-intercom-target="editingBannerChangeSettingsButton"
                    >
                        <Link to="/settings" className={classes.link}>
                            {t('editingBanner.settings')}
                        </Link>
                    </Grid>
                </Grid>
            </MessagePaper>
        </Can>
    );
};

EditingBanner.propTypes = {
    actionDuplicationCommunities: availableCommunitiesPropType,
};

EditingBanner.defaultProps = {
    actionDuplicationCommunities: [],
};

export default EditingBanner;
