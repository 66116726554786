import { createSelector } from 'reselect';
import {
    transformActivityStats,
    transformTopActions,
    transformTopUsers,
    transformUserStats,
} from './transforms';
import {
    GRAPH_METRIC_ACTIVE_USERS,
    GRAPH_METRIC_NEW_USERS,
    GRAPH_METRIC_TOTAL_LOGS,
} from '../common/Graph/ChartItem/constants';
import { transformImpactStatsToTotals } from '../common/transforms';

export const getAnalyticsState = (state) => state.analytics;

export const getActivities = createSelector(
    getAnalyticsState,
    (analytics) => analytics.activities,
);

export const getActivityStats = createSelector(getActivities, (activities) =>
    transformActivityStats(activities),
);

export const getUsersAnalytic = createSelector(
    getAnalyticsState,
    (analytics) => analytics.usersAnalytic,
);

export const getUserStats = createSelector(getUsersAnalytic, (usersAnalytic) =>
    transformUserStats(usersAnalytic),
);

export const getImpactStats = createSelector(getAnalyticsState, (analytics) =>
    transformImpactStatsToTotals(analytics.impactStats),
);

export const getAllGraphData = createSelector(
    getAnalyticsState,
    (analytics) => analytics.graphData,
);

const getGraphData = (metric) => {
    return createSelector(
        getAnalyticsState,
        (analytics) => analytics.graphData[metric],
    );
};

export const getTotalLogsGraphData = getGraphData(GRAPH_METRIC_TOTAL_LOGS);
export const getNewUsersGraphData = getGraphData(GRAPH_METRIC_NEW_USERS);
export const getActiveUsersGraphData = getGraphData(GRAPH_METRIC_ACTIVE_USERS);

export const getAnalyticsGraphData = createSelector(
    getAnalyticsState,
    (analytics) => analytics.graphData,
);

export const getTopActions = createSelector(getActivities, (activities) =>
    transformTopActions(activities),
);

export const getTopFiveActions = createSelector(getTopActions, (actions) =>
    actions.slice(0, 5),
);

export const getTopUsers = createSelector(getUsersAnalytic, (usersAnalytic) =>
    transformTopUsers(usersAnalytic?.activities),
);

export const getTopFiveUsers = createSelector(getTopUsers, (users) =>
    users.slice(0, 5),
);

export const getAnalyticsLoaders = createSelector(
    getAnalyticsState,
    (analytics) => analytics.loading,
);

export const isActivityStatsLoading = createSelector(
    getAnalyticsLoaders,
    (loading) => loading.activity,
);

export const isUserStatsLoading = createSelector(
    getAnalyticsLoaders,
    (loading) => loading.user,
);

export const isImpactStatsLoading = createSelector(
    getAnalyticsLoaders,
    (loading) => loading.impact,
);

export const isGraphDataLoading = createSelector(
    getAnalyticsLoaders,
    (loading) => loading.graph,
);
