const styles = (theme) => ({
    titleContainer: {
        paddingBottom: '10px',
    },
    formTitle: {
        fontSize: '24px',
        fontWeight: '700',
    },
    addButton: {
        borderRadius: '10px',
        border: '2px solid',
    },
    methodContainer: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '10px',
        padding: '10px 16px',
    },
    editBtnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    editBtn: {
        height: 'fit-content',
        borderRadius: '5px',
        padding: '0px 10px',
    },
    editBtnIcon: {
        padding: '0 10px 0 0',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default styles;
