import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';

import useStyles from './styles';

import ConfirmModal from '../../../common/ConfirmModal';
import Button from '../../../common/Button';
import { noop } from '../../../utils';
import {
    BUTTON_ACCENT_COLOR,
    BUTTON_DANGER_COLOR,
    BUTTON_DEFAULT_COLOR,
} from '../../../common/Button/constants';

const SelectTeam = ({
    isOpen,
    setOpen,
    teams,
    onClose,
    onCancel,
    onSwitchTeam,
    onLeaveChallenge,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
    }, [onClose, setOpen]);

    const handleCancel = useCallback(() => {
        onCancel();
        setOpen(false);
    }, [onCancel, setOpen]);

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={t('challenge.teamSelectConfirmModalTitle')}
            confirmBtnHidden
            cancelBtnType={BUTTON_DEFAULT_COLOR}
            cancelBtnText={t('cancel')}
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={noop}
        >
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography className={classes.header}>
                        {t('challenge.alreadyMember')}
                    </Typography>
                </Grid>
                <Grid container item>
                    {teams?.map(({ id, image, name, joined }, index) => (
                        <>
                            <Grid container item>
                                <hr className={classes.divider} />
                            </Grid>
                            <Grid
                                key={index}
                                container
                                item
                                direction="row"
                                wrap="nowrap"
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <img
                                            alt={t('alt.teamPhoto')}
                                            src={image}
                                            width={40}
                                            height={40}
                                            className={classes.image}
                                        />
                                    </Grid>
                                    <Grid item>{name}</Grid>
                                </Grid>
                                <Grid item>
                                    {joined ? (
                                        <Button
                                            disabled
                                            color={BUTTON_ACCENT_COLOR}
                                            size="small"
                                        >
                                            <Check /> {t('Member')}
                                        </Button>
                                    ) : (
                                        <Button
                                            color={BUTTON_DEFAULT_COLOR}
                                            size="small"
                                            onClick={() => onSwitchTeam(id)}
                                        >
                                            <Add /> {t('Switch')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Grid>
                <Grid container item>
                    <Button
                        fullWidth
                        color={BUTTON_DANGER_COLOR}
                        size="large"
                        onClick={onLeaveChallenge}
                    >
                        {t('challenge.leave')}
                    </Button>
                </Grid>
            </Grid>
        </ConfirmModal>
    );
};

SelectTeam.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            image: PropTypes.string,
            name: PropTypes.string,
            joined: PropTypes.bool.isRequired,
        }),
    ),
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSwitchTeam: PropTypes.func.isRequired,
    onLeaveChallenge: PropTypes.func.isRequired,
};

SelectTeam.defaultProps = {
    teams: null,
    onClose: noop,
    onCancel: noop,
};

export default SelectTeam;
