export const SAVE_DRAFT_REQUESTED = 'SAVE_DRAFT_REQUESTED';
export const SAVE_DRAFT_SUCCEEDED = 'SAVE_DRAFT_SUCCEEDED';
export const SAVE_DRAFT_FAILED = 'SAVE_DRAFT_FAILED';
export const CLEAR_SAVE_ERRORS = 'CLEAR_SAVE_ERRORS';

export const saveDraftRequested = (request) => ({
    type: SAVE_DRAFT_REQUESTED,
    payload: request,
});

export const saveDraftSucceeded = (publish) => ({
    type: SAVE_DRAFT_SUCCEEDED,
    payload: {
        publish: publish,
    },
});

export const saveDraftFailed = (errors) => ({
    type: SAVE_DRAFT_FAILED,
    payload: {
        errors,
    },
});

export const clearSaveErrors = () => ({
    type: CLEAR_SAVE_ERRORS,
});
