import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    THEMES_REQUESTED,
    THEMES_UPDATE_REQUESTED,
    themesRequestFailed,
    themesRequestSucceeded,
    themesUpdateRequestFailed,
    themesUpdateRequestSucceeded,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../utils/api';
import { push } from 'connected-react-router';

export function* getThemes({ payload: { communityId, includeCategories } }) {
    try {
        const data = yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/themes`,
            {
                query: {
                    includeCategories,
                },
            },
        );
        yield put(themesRequestSucceeded(data.response['community-themes']));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get themes',
        );
        yield put(themesRequestFailed(errors));
    }
}

export function* updateCommunityThemes(action) {
    try {
        const {
            payload: { orgId, themeIds, nextLocation },
        } = action;
        const updateData = yield call(
            fetchWithAuth,
            `/kiosk/organization/${orgId}/themes/edit`,
            {
                method: 'POST',
                body: {
                    themeIds: themeIds?.join(','),
                },
            },
        );
        const themes = updateData.response.themes;
        yield put(themesUpdateRequestSucceeded(themes));
        if (nextLocation) {
            yield put(push(nextLocation));
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update community',
        );
        yield put(themesUpdateRequestFailed(errors));
    }
}

export default function* themesSaga() {
    yield all([
        takeLatest(THEMES_REQUESTED, getThemes),
        takeLatest(THEMES_UPDATE_REQUESTED, updateCommunityThemes),
    ]);
}
