import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    categoryIcon: {
        width: '24px',
        marginRight: 5,
        height: 'auto',
        objectFit: 'contain',
    },
    selectItem: {
        paddingLeft: 10,
    },
}));

export default useStyles;
