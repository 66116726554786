const styles = (theme) => ({
    container: {
        paddingTop: '5px',
        paddingRight: '32px',
        paddingLeft: '32px',
    },
    counterContainer: { paddingBottom: '30px' },
    header: {
        margin: '5px 0 20px',
        borderBottom: `1px solid ${theme.custom.bgDarkHover}`,
    },
    timezone: {
        fontSize: '13.5px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    title: {
        margin: '20px 0 10px',
        fontSize: '36px',
        fontWeight: 600,
    },
    titleIcon: {
        marginLeft: '5px',
        color: theme.custom.iconColor,

        '&:hover': {
            color: theme.custom.iconColorHover,
        },
    },
    label: {
        margin: '20px 0 10px',
        fontSize: '24px',
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    topUsers: {
        paddingRight: '8px',

        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    topActions: {
        paddingLeft: '8px',

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    relative: {
        position: 'relative',
    },
    impactStatCard: {
        flexGrow: 1,
    },
    filterLabel: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        padding: '0 10px',
        fontSize: '13px',
    },
});

export default styles;
