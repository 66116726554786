import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Chip, Grid, Typography, withStyles } from '@material-ui/core';
import { debounce } from 'lodash';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import clsx from 'clsx';
import Form from '../../../common/Form';
import Section from './Section';
import SubjectContentType from './SubjectContentType';
import FormInput from '../../../common/Form/FormInput';
import TextareaField from './TextAreaField';
import CustomDropdown from '../../../common/CustomDropdown';
import DateField from './DateField';
import theme from '../../../theme';
import styles from './styles';
import {
    ALL_AUDIENCE_TYPES,
    AUDIENCE_TYPE_CHALLENGE_TEAMS,
    AUDIENCE_TYPES_WITH_AUDIENCE,
    DEFAULT_AUDIENCE_TYPES,
    FORM_FIELD_AUDIENCE_TYPE,
    FORM_FIELD_AUDIENCES,
    FORM_FIELD_MESSAGE,
    FORM_FIELD_SEND_DATE,
    FORM_FIELD_SUBJECT,
    FORM_FIELD_SUBJECT_CONTENT_ID,
    FORM_FIELD_SUBJECT_CONTENT_TYPE,
    MAX_MSG_LENGTH,
} from './constants';
import {
    AUDIENCE_VALUE_CHALLENGE_TEAMS,
    AUDIENCE_VALUE_COMMUNITIES,
    AUDIENCE_VALUE_USERS,
    SAFE_COMMUNITY_CHALLENGE_AUDIENCE_TYPES,
    VALUE_ACTION_SUBJECT_TYPE,
    VALUE_CHALLENGE_SUBJECT_TYPE,
    VALUE_GENERAL_SUBJECT_TYPE,
} from '../../../notifications/constants';
import { getTimezoneName } from '../../../utils/dates';
import { noop } from '../../../utils';
import EditingBanner from '../../../common/EditingBanner';
import {
    getActiveCommunityId,
    getActiveMembersLoading,
} from '../../../user/selectors';
import {
    getChallenges,
    getCommunityUsers,
    getNotificationActions,
} from '../../../notifications/selectors';
import {
    GROUP_ACTIVE,
    GROUP_PAST,
    GROUP_UPCOMING,
} from '../../ChallengesScreen/constants';
import { challengesRequested } from '../../../challenges/actions';
import { actionsRequested } from '../../../actions/actions';
import { connect } from 'react-redux';
import {
    membersRequested,
    membersSearchRequested,
    setActiveCommunity,
} from '../../../user/actions';
import { ACTIVE_STATUS_ACTIVE } from '../../../user/constants';
import {
    CLIENT_PAGINATION_FETCH_LIMIT,
    PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
} from '../../../actions/constants';
import { CommunityConfirmInfo } from './CommunityConfirmInfo';
import ConfirmModal from '../../../common/ConfirmModal';

const NotificationForm = ({
    classes,
    title,
    subjectContentType,
    subjectContentID,
    subject,
    message,
    audienceType,
    audiences,
    sendDate,
    audienceCommunities,
    onChangeValue,
    onSubmit,

    challenges,
    fetchChallenges,
    users,
    fetchUsers,
    actions,
    fetchActions,
    communityId,
    searchUsers,
    usersLoading,
    changeCommunity,
}) => {
    const { t } = useTranslation();

    const initialValues = useMemo(
        () => ({
            subjectContentType,
            subjectContentID,
            subject,
            message,
            audienceType,
            audiences,
            sendDate,
        }),
        [
            subjectContentType,
            subjectContentID,
            subject,
            message,
            audienceType,
            audiences,
            sendDate,
        ],
    );

    const [selectedChallenge, setSelectedChallenge] = useState(
        subjectContentType === VALUE_CHALLENGE_SUBJECT_TYPE &&
            challenges.find((challenge) => challenge.id === subjectContentID),
    );

    const [communityConfirmModalOpen, setCommunityConfirmModalOpen] = useState(
        false,
    );

    useEffect(() => {
        [GROUP_ACTIVE, GROUP_UPCOMING, GROUP_PAST].forEach((group) => {
            fetchChallenges({ communityId, group });
        });
        fetchActions(communityId);
    }, [communityId, fetchActions, fetchChallenges]);

    const getAudienceValues = useCallback(
        (audienceType) => {
            if (audienceType === AUDIENCE_VALUE_CHALLENGE_TEAMS) {
                const sortedTeams = selectedChallenge?.teams?.sort(
                    (a, b) => b.score - a.score,
                );
                return sortedTeams;
            }
            if (audienceType === AUDIENCE_VALUE_COMMUNITIES) {
                return audienceCommunities;
            }
            if (audienceType === AUDIENCE_VALUE_USERS) {
                return users;
            }
        },
        [users, audienceCommunities, selectedChallenge],
    );

    const [selectedAudienceType, setSelectedAudienceType] = useState(
        audienceType,
    );

    const [audienceValues, setAudienceValues] = useState(
        getAudienceValues(audienceType),
    );

    useEffect(() => {
        setAudienceValues(getAudienceValues(selectedAudienceType));
    }, [setAudienceValues, selectedAudienceType, users, getAudienceValues]);

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                subject: Yup.string()
                    .required(t('notifications.validationRequiredSubject'))
                    .nullable(),
                message: Yup.string()
                    .required(t('notifications.validationRequiredMessage'))

                    .nullable(),
                audienceType: Yup.string()
                    .required(t('notifications.validationRequiredType'))

                    .nullable(),
                sendDate: Yup.string()
                    .required(t('notifications.validationRequiredSendDate'))
                    .nullable(),
            }),
        [t],
    );

    const validateAudiences = useCallback(
        (value, audienceType) => {
            if (audienceType === 'users' && !value.length)
                return t('notifications.validationRequiredAudience');
        },
        [t],
    );

    const generateFormState = useCallback(
        (value) => {
            let newInterface = {
                audienceTypes: DEFAULT_AUDIENCE_TYPES,
                audienceUsers: users,
                audienceCommunities,
            };

            if (value === VALUE_GENERAL_SUBJECT_TYPE) {
                newInterface = {
                    ...newInterface,
                    subjectTitle: t('notifications.subjectGeneralTitle'),
                    subjectPlaceholder: t(
                        'notifications.subjectGeneralPlaceholder',
                    ),
                    subjectData: [],
                };
            } else if (value === VALUE_CHALLENGE_SUBJECT_TYPE) {
                newInterface = {
                    ...newInterface,
                    subjectTitle: t('notifications.subjectChallengeTitle'),
                    subjectPlaceholder: t(
                        'notifications.subjectChallengePlaceholder',
                    ),
                    subjectData: challenges,
                    audienceTypes: ALL_AUDIENCE_TYPES,
                };
            } else if (value === VALUE_ACTION_SUBJECT_TYPE) {
                newInterface = {
                    ...newInterface,
                    subjectTitle: t('notifications.subjectActionTitle'),
                    subjectPlaceholder: t(
                        'notifications.subjectActionPlaceholder',
                    ),
                    subjectData: actions,
                };
            }
            if (audienceCommunities.length === 0) {
                newInterface.audienceTypes = newInterface.audienceTypes.filter(
                    ({ value }) => !['communities'].includes(value),
                );
            }

            return newInterface;
        },
        [users, audienceCommunities, t, challenges, actions],
    );

    const [formInterface, setFormInterface] = useState(() => {
        return generateFormState(subjectContentType);
    });

    const setSubjectContentType = useCallback(
        (value, setFieldValue, resetForm) => {
            const newInterface = generateFormState(value);
            setFormInterface(newInterface);

            resetForm();
            setFieldValue(FORM_FIELD_SUBJECT, '');
            setFieldValue(FORM_FIELD_SUBJECT_CONTENT_ID, null);
            setFieldValue(FORM_FIELD_SUBJECT_CONTENT_TYPE, value);

            onChangeValue({
                subjectContentType: value,
                subject: null,
                iconUrl: null,
                isChallenge: value === VALUE_CHALLENGE_SUBJECT_TYPE,
            });
        },
        [generateFormState, onChangeValue],
    );

    const handleChangeSubject = (item, setFieldValue, values) => {
        setFieldValue(FORM_FIELD_SUBJECT_CONTENT_ID, item && item.id);
        setFieldValue(FORM_FIELD_SUBJECT, item && item.value);
        onChangeValue({
            subject: item && item.value,
            iconUrl: item && item.isChallenge && item.iconUrl,
            image: item && item.iconUrl,
        });
        if (item?.isChallenge) {
            setSelectedChallenge(item);
            const audienceTypes = formInterface.audienceTypes;
            const challengeTeamsIndex = audienceTypes.findIndex(
                (type) => type.id === AUDIENCE_TYPE_CHALLENGE_TEAMS.id,
            );
            if (challengeTeamsIndex > -1 && !item.teams?.length) {
                audienceTypes.splice(challengeTeamsIndex, 1);
                setFormInterface({
                    ...formInterface,
                    audienceTypes,
                });
            } else if (challengeTeamsIndex === -1 && item.teams?.length) {
                audienceTypes.push(AUDIENCE_TYPE_CHALLENGE_TEAMS);
                setFormInterface({
                    ...formInterface,
                    audienceTypes,
                });
            }
            if (values?.audienceType === AUDIENCE_VALUE_CHALLENGE_TEAMS) {
                setFieldValue(FORM_FIELD_AUDIENCES, []);
                setAudienceValues(item?.teams);
            }
        }
    };

    const handleChangeValue = (field, value, setFieldValue) => {
        setFieldValue(field, value);
        onChangeValue({ [field]: value });
    };

    const handleChangeAudienceType = (audienceType, setFieldValue) => {
        setFieldValue(FORM_FIELD_AUDIENCE_TYPE, audienceType);
        setFieldValue(FORM_FIELD_AUDIENCES, []);
        setSelectedAudienceType(audienceType);
        if (audienceType === AUDIENCE_VALUE_USERS) {
            fetchUsers(communityId);
        }
    };

    const handleChangeAudienceInput = debounce((data, values) => {
        if (values.audienceType === AUDIENCE_VALUE_USERS) {
            data ? searchUsers(communityId, data) : fetchUsers(communityId);
        }
    }, 300);

    /**
     * Check if a challenge is selected that belongs to a sub community
     * and the audience type would result in the notification being sent to users outside the sub community
     */
    const isOutOfCommunityScope = (values) =>
        selectedChallenge?.sponsoringCommunities?.length > 0 &&
        selectedChallenge?.sponsoringCommunities?.every(
            (c) => c.id !== communityId,
        ) &&
        values.audienceType &&
        !SAFE_COMMUNITY_CHALLENGE_AUDIENCE_TYPES.includes(values.audienceType);

    return formInterface ? (
        <Grid className={classes.container}>
            <Form
                submitBtnText={t('notifications.save')}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) =>
                    isOutOfCommunityScope(values)
                        ? setCommunityConfirmModalOpen(true)
                        : onSubmit(values)
                }
                enableReinitialize
            >
                {({ values, setFieldValue, resetForm }) => {
                    return (
                        <Fragment>
                            <ConfirmModal
                                open={communityConfirmModalOpen}
                                onClose={() =>
                                    setCommunityConfirmModalOpen(false)
                                }
                                onCancel={() =>
                                    setCommunityConfirmModalOpen(false)
                                }
                                confirmBtnType="accent"
                                confirmBtnText={t('notifications.queue')}
                                onConfirm={() => onSubmit(values)}
                                titleStyle={classes.modalTitle}
                            >
                                <CommunityConfirmInfo
                                    newCommunity={
                                        selectedChallenge
                                            ?.sponsoringCommunities?.[0]
                                    }
                                    title={t(
                                        'notifications.organizationWarningBanner.modalTitle',
                                    )}
                                />
                            </ConfirmModal>
                            <Grid container className={classes.top}>
                                <Edit />
                                <Typography
                                    variant="h3"
                                    className={classes.title}
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid container className={classes.body}>
                                {isOutOfCommunityScope(values) && (
                                    <Grid
                                        container
                                        item
                                        className={classes.warningBanner}
                                    >
                                        <CommunityConfirmInfo
                                            newCommunity={
                                                selectedChallenge
                                                    ?.sponsoringCommunities?.[0]
                                            }
                                            title={t('warning')}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <EditingBanner />
                                </Grid>
                                <Section
                                    title={t('notifications.typeFieldTitle')}
                                    tooltip={t(
                                        'notifications.typeFieldToolTip',
                                    )}
                                    tooltipIntercomTarget="notificationType"
                                    data-intercom-target="notificationTypeSection"
                                >
                                    <SubjectContentType
                                        value={values.subjectContentType}
                                        challengeTypeDisabled={
                                            !challenges.length
                                        }
                                        actionTypeDisabled={!actions?.length}
                                        onChange={(e, value) =>
                                            setSubjectContentType(
                                                value,
                                                setFieldValue,
                                                resetForm,
                                            )
                                        }
                                    />
                                </Section>
                                <Grid item xs={12}>
                                    <Section
                                        title={t(formInterface.subjectTitle)}
                                    >
                                        <Grid item xs={12}>
                                            {[
                                                VALUE_CHALLENGE_SUBJECT_TYPE,
                                                VALUE_ACTION_SUBJECT_TYPE,
                                            ].includes(
                                                values.subjectContentType,
                                            ) ? (
                                                <FormInput
                                                    as={CustomDropdown}
                                                    name={FORM_FIELD_SUBJECT}
                                                    intercomTargetPrefix={
                                                        values.subjectContentType
                                                    }
                                                    placeholder={`${t(
                                                        formInterface.subjectPlaceholder,
                                                    )}...`}
                                                    className={classes.dropdown}
                                                    noLeftMargin
                                                    data={
                                                        formInterface.subjectData
                                                    }
                                                    value={formInterface.subjectData.find(
                                                        (item) =>
                                                            item.value ===
                                                            values.subject,
                                                    )}
                                                    onChange={(e, data) =>
                                                        handleChangeSubject(
                                                            data,
                                                            setFieldValue,
                                                            values,
                                                        )
                                                    }
                                                    bgColor={
                                                        theme.custom
                                                            .inputBackground
                                                    }
                                                    groupBy={(option) =>
                                                        option.role
                                                    }
                                                />
                                            ) : (
                                                <FormInput
                                                    name={FORM_FIELD_SUBJECT}
                                                    intercomTargetPrefix={
                                                        values.subjectContentType
                                                    }
                                                    className={
                                                        classes.inputField
                                                    }
                                                    placeholder={t(
                                                        'notifications.enterSubject',
                                                    )}
                                                    noLeftMargin
                                                    onChange={(e) =>
                                                        handleChangeSubject(
                                                            {
                                                                value:
                                                                    e.target
                                                                        .value,
                                                            },
                                                            setFieldValue,
                                                        )
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Section>
                                    <Section
                                        title={t('Message')}
                                        tooltip={t(
                                            'notifications.messageFieldToolTip',
                                        )}
                                        tooltipIntercomTarget="notificationMessage"
                                    >
                                        <Grid item xs={12}>
                                            <FormInput
                                                as={TextareaField}
                                                name={FORM_FIELD_MESSAGE}
                                                placeholder={t(
                                                    'notifications.messageFieldPlaceholder',
                                                )}
                                                className={clsx([
                                                    classes.inputField,
                                                    classes.textarea,
                                                ])}
                                                noLeftMargin
                                                rowsMin={3}
                                                maxLength={MAX_MSG_LENGTH}
                                                charactersLeft={
                                                    MAX_MSG_LENGTH -
                                                    values.message.length
                                                }
                                                onChange={(e) =>
                                                    handleChangeValue(
                                                        FORM_FIELD_MESSAGE,
                                                        e.target.value,
                                                        setFieldValue,
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Section>
                                </Grid>
                                <Section
                                    title={t('notifications.audienceType')}
                                >
                                    <Grid item xs={12}>
                                        <FormInput
                                            as={CustomDropdown}
                                            id="audienceDropdown"
                                            name={FORM_FIELD_AUDIENCE_TYPE}
                                            placeholder={`${t(
                                                'notifications.audienceSelectPlaceholder',
                                            )}...`}
                                            className={classes.dropdown}
                                            noLeftMargin
                                            data={formInterface.audienceTypes}
                                            value={formInterface.audienceTypes.find(
                                                (item) =>
                                                    item.value ===
                                                    values.audienceType,
                                            )}
                                            onChange={(e, data) =>
                                                handleChangeAudienceType(
                                                    data.value,
                                                    setFieldValue,
                                                )
                                            }
                                            bgColor={
                                                theme.custom.inputBackground
                                            }
                                            marginLeft={8}
                                        />
                                    </Grid>
                                </Section>
                                {AUDIENCE_TYPES_WITH_AUDIENCE.includes(
                                    values.audienceType,
                                ) && (
                                    <Section title={t('Audience')}>
                                        <Grid item xs={12}>
                                            <FormInput
                                                as={CustomDropdown}
                                                id="audienceUsers"
                                                name={FORM_FIELD_AUDIENCES}
                                                placeholder={`${t(
                                                    'notifications.audienceSelectPlaceholder',
                                                )}...`}
                                                className={classes.dropdown}
                                                validate={(value) =>
                                                    validateAudiences(
                                                        value,
                                                        values.audienceType,
                                                    )
                                                }
                                                noLeftMargin
                                                data={audienceValues?.filter(
                                                    (value) =>
                                                        !values.audiences.some(
                                                            (audience) =>
                                                                audience.id ===
                                                                value.id,
                                                        ),
                                                )}
                                                value={values.audiences}
                                                onChange={(e, data) =>
                                                    setFieldValue(
                                                        FORM_FIELD_AUDIENCES,
                                                        data || [],
                                                    )
                                                }
                                                onInputChange={(e, data) =>
                                                    handleChangeAudienceInput(
                                                        data,
                                                        values,
                                                    )
                                                }
                                                bgColor={
                                                    theme.custom.inputBackground
                                                }
                                                filterSelectedOptions
                                                renderTags={(
                                                    val,
                                                    getTagProps,
                                                ) =>
                                                    val.map(
                                                        ({ title }, index) => (
                                                            <Chip
                                                                key={`chip-${index}`}
                                                                variant="outlined"
                                                                label={title}
                                                                {...getTagProps(
                                                                    {
                                                                        index,
                                                                    },
                                                                )}
                                                            />
                                                        ),
                                                    )
                                                }
                                                multiple
                                                loading={usersLoading}
                                            />
                                        </Grid>
                                    </Section>
                                )}
                                <Section
                                    title={`${t(
                                        'notifications.sendFieldTitle',
                                    )} (${getTimezoneName})`}
                                    tooltip={t('notifications.sendFieldTooTip')}
                                    tooltipIntercomTarget="notificationSendDate"
                                >
                                    <Grid item className={classes.bottomFields}>
                                        <FormInput
                                            as={DateField}
                                            name={FORM_FIELD_SEND_DATE}
                                            placeholder={t(
                                                'notifications.selectSendDate',
                                            )}
                                            noLeftMargin
                                            value={values.sendDate}
                                            onChange={(value) =>
                                                handleChangeValue(
                                                    FORM_FIELD_SEND_DATE,
                                                    value,
                                                    setFieldValue,
                                                )
                                            }
                                        />
                                    </Grid>
                                </Section>
                            </Grid>
                        </Fragment>
                    );
                }}
            </Form>
        </Grid>
    ) : null;
};

NotificationForm.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subjectContentType: PropTypes.string,
    subjectContentID: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
    audienceType: PropTypes.string,
    audiences: PropTypes.array,
    sendDate: PropTypes.string,
    audienceCommunities: PropTypes.array,
    onChangeValue: PropTypes.func,
    onSubmit: PropTypes.func,

    communityId: PropTypes.string,
    challenges: PropTypes.array,
    fetchChallenges: PropTypes.func,
    users: PropTypes.array,
    fetchUsers: PropTypes.func,
    actions: PropTypes.array,
    fetchActions: PropTypes.func,
    searchUsers: PropTypes.func,
    usersLoading: PropTypes.bool,
    changeCommunity: PropTypes.func.isRequired,
};

NotificationForm.defaultProps = {
    subjectContentType: VALUE_GENERAL_SUBJECT_TYPE,
    subjectContentID: '',
    subject: '',
    message: '',
    audienceType: null,
    audiences: [],
    audienceCommunities: [],
    sendDate: null,
    onChangeValue: noop,
    onSubmit: noop,

    users: [],
    fetchUsers: noop,
    challenges: [],
    fetchChallenges: noop,
    actions: [],
    fetchActions: noop,
    searchUsers: noop,
    usersLoading: false,
    communityId: '',
};

const StyledNotificationForm = withStyles(styles)(NotificationForm);
export default StyledNotificationForm;

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    challenges: getChallenges(state),
    actions: getNotificationActions(
        state,
        PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
    ),
    users: getCommunityUsers(state),
    usersLoading: getActiveMembersLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchChallenges: (params) => dispatch(challengesRequested(params)),
    changeCommunity: (communityId) => dispatch(setActiveCommunity(communityId)),
    fetchActions: (communityId) =>
        dispatch(
            actionsRequested(communityId, {
                visibility: PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
                limit: CLIENT_PAGINATION_FETCH_LIMIT,
                sortBy: 'name',
                order: 1,
            }),
        ),
    fetchUsers: (communityId) =>
        dispatch(
            membersRequested(communityId, ACTIVE_STATUS_ACTIVE, {
                skip: 0,
                limit: 25,
            }),
        ),
    searchUsers: (communityId, searchString) =>
        dispatch(
            membersSearchRequested(
                communityId,
                searchString,
                ACTIVE_STATUS_ACTIVE,
                { skip: 0, limit: 25 },
            ),
        ),
});

export const ConnectedNotificationForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledNotificationForm);
