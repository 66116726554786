import {
    MANUALLY_JOINED,
    USER_ROLE_ORGANIZATION_ADMIN,
    USER_ROLE_ORGANIZATION_OWNER,
    USER_ROLE_ORGANIZATION_PARTICIPANT,
} from './constants';
import { getAppImages } from '../utils';

/**
 * Checks Shine user for imageSizes array and gets the highest resolution image.
 * If none, returns the default profile icon.
 * @param user
 * @return {string}
 */
export const getHighResProfilePicture = (user) => {
    const { imageSizes } = user || {};
    return (
        (imageSizes && imageSizes.length > 0 && imageSizes.pop().url) ||
        getAppImages().defaultProfileIcon
    );
};

/**
 * Maps Shine user fields for display in Users screen.
 * @param users
 * @param communityId The ID of the community for which to get each user's permissions
 * @returns {Object[]}
 */
export const transformUsers = (users, communityId) =>
    users?.map((member) => ({
        id: member.id,
        icon: getHighResProfilePicture(member),
        name: member.name,
        email: member.emails[0],
        role: member.communityPermissions?.[communityId]?.role || '',
        dateJoined: member.dateJoined,
        lastSeen: member.lastSeen,
        communityPermissions: member.communityPermissions || {},
    })) || [];

/**
 * Get a user's associated communities
 * @param user
 * @returns {Object[]}
 */
export const transformAvailableCommunities = (user) => {
    const rawCommunities = Object.values(user.communityPermissions);
    return rawCommunities
        .map((community) => {
            if (!community || !community.content) return {};

            return {
                ...community,
                title: community.content.name,
                url:
                    community.content.imageSizes[0] &&
                    community.content.imageSizes[0].url,
                isOrganization:
                    community.content.id === community.organizationId,
            };
        })
        .filter((community) => community.title)
        .sort((a, b) =>
            a.title.toUpperCase().localeCompare(b.title.toUpperCase()),
        )
        .sort((a, b) => {
            const c = a.organizationId.localeCompare(b.organizationId);
            if (c === 0) {
                return b.isOrganization - a.isOrganization;
            } else {
                return 0;
            }
        });
};

export const transformCommunityPermission = (permissionInfo) => {
    return {
        ...permissionInfo.content,
        organizationId: permissionInfo.organizationId,
    };
};

const extractTrophyInfo = (trophies) => {
    return Object.values(trophies)
        .filter((trophy) => trophy.completed)
        .map((trophy) => {
            return {
                imageUrl:
                    trophy?.content?.imageSizes[
                        trophy.content.imageSizes.length - 1
                    ]?.url,
                name: trophy?.content?.name,
            };
        });
};

const extractMedalInfo = (medals) => {
    return Object.values(medals)
        .filter((medal) => medal.completed)
        .map((medal) => {
            let medalInfo = {
                name: medal?.content?.name,
                multiplier: medal?.num_checkins,
            };
            const images = medal?.content?.imageSizes || [];
            if (images.length > 0) {
                medalInfo = {
                    ...medalInfo,
                    imageUrl: images[images.length - 1]?.url,
                };
            }
            return medalInfo;
        });
};

export const transformUserWithIntercomRole = (user) => {
    if (!user) return user;
    let role = USER_ROLE_ORGANIZATION_PARTICIPANT;
    if (user.isOrganizationOwner) role = USER_ROLE_ORGANIZATION_OWNER;
    else if (Object.keys(user.communityPermissions).length > 0)
        role = USER_ROLE_ORGANIZATION_ADMIN;
    return {
        ...user,
        intercomRole: role,
    };
};

/**
 * Get a user's trophies for displaying in User screen
 * @param user
 */
export const transformUserTrophies = (user) => {
    const trophies = {};
    if (user) {
        trophies.actions = extractTrophyInfo(user.pins);
        trophies.badges = extractTrophyInfo(user.badges);
        trophies.medals = extractMedalInfo(user.medals);
    }
    return trophies;
};

/*
 * Get a user's joined community list to be displayed in the user settings screen
 */
export const transformJoinedCommunities = (communities) => {
    return communities
        ?.filter((community) => community.membershipStatus === MANUALLY_JOINED)
        .sort((a, b) => {
            if (a.unjoinEligibility.eligible < b.unjoinEligibility.eligible)
                return -1;
            else if (
                a.unjoinEligibility.eligible > b.unjoinEligibility.eligible
            )
                return 1;
            else return 0;
        });
};
