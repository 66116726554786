import React, { useCallback, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import DatePicker from '../DatePicker';
import styles from './styles';
import moment from 'moment';
import { getTimezoneAbbrev } from '../../utils/dates';

const DatePickerRange = ({ handleDateChange, classes, startDate, endDate }) => {
    const [filterError, setFilterError] = useState();

    const { t, i18n } = useTranslation();

    const handleActions = useCallback(
        (newStartDate, newEndDate) => {
            setFilterError('');
            if (
                newStartDate.toDate().getTime() >= newEndDate.toDate().getTime()
            ) {
                setFilterError(t('datePickers.endAfterStart'));
            } else {
                handleDateChange({
                    startDate: newStartDate,
                    endDate: newEndDate,
                });
            }
        },
        [handleDateChange, t],
    );

    const onStartDateChange = (value) => {
        handleActions(value, endDate);
    };

    const onEndDateChange = (value) => {
        handleActions(startDate, value);
    };

    return (
        <Grid item>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid
                    className={classes.filterField}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <span className={classes.filterLabel}>{t('from')}</span>
                    <DatePicker
                        name="startDate"
                        value={startDate}
                        onChange={onStartDateChange}
                        data-test="startDate"
                    />
                </Grid>
                <Grid
                    className={classes.filterField}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <span className={classes.filterLabel}>{t('to')}</span>
                    <DatePicker
                        name="endDate"
                        value={endDate}
                        onChange={onEndDateChange}
                        data-test="endDate"
                    />
                </Grid>
                <Grid item className={classes.timezoneContainer}>
                    <Typography className={classes.timezone}>
                        {`(${getTimezoneAbbrev(i18n.language)})`}
                    </Typography>
                </Grid>
            </Grid>

            {filterError && (
                <div data-test="dateError" className={classes.errors}>
                    {filterError}
                </div>
            )}
        </Grid>
    );
};

DatePickerRange.propTypes = {
    handleDateChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
};

DatePickerRange.defaultProps = {
    endDate: moment(),
    startDate: moment().subtract(1, 'months'),
};

export default withStyles(styles)(DatePickerRange);
