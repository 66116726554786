const searchFieldRootBasic = (theme) => ({
    width: '100%',
    borderRadius: '8px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: '10px 16px',
    border: '1px solid transparent',
    backgroundColor: theme.custom.inputBackground,
    fontSize: '18px',

    '&:before, &:after': {
        display: 'none',
    },

    '&:hover': {
        backgroundColor: theme.custom.bgLightColor,
    },

    '&:active:not(.Mui-disabled)': {
        backgroundColor: theme.palette.common.white,
    },

    '&.Mui-focused': {
        borderColor: theme.palette.primary,
    },
});

const styles = (theme) => ({
    searchButton: {
        borderRadius: 0,
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        backgroundColor: theme.custom.inputBackground,
    },
    clearButton: {
        borderRadius: 0,
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        backgroundColor: theme.custom.inputBackground,
    },
    searchFieldRootEmpty: {
        ...searchFieldRootBasic(theme),
    },
    searchFieldRootWithText: {
        ...searchFieldRootBasic(theme),
        borderRadius: 0,
    },
    searchFieldInput: {
        height: '24px',
        padding: 0,
    },
});

export default styles;
