export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_BY_MICROSOFT_REQUEST = 'LOGIN_BY_MICROSOFT_REQUEST';
export const LOGIN_BY_OKTA_REQUEST = 'LOGIN_BY_OKTA_REQUEST';
export const LOGIN_REQUEST_SUCCEEDED = 'REQUEST_LOGIN_SUCCEEDED';
export const LOGIN_REQUEST_FAILED = 'REQUEST_LOGIN_FAILED';

export const SIGN_UP_REQUESTED = 'SIGN_UP_REQUESTED';
export const SIGN_UP_REQUEST_SUCCEEDED = 'SIGN_UP_REQUEST_SUCCEEDED';
export const SIGN_UP_REQUEST_FAILED = 'SIGN_UP_REQUEST_FAILED';

export const CONFIRMATION_REQUESTED = 'CONFIRMATION_REQUESTED';
export const CONFIRMATION_REQUEST_SUCCEEDED = 'CONFIRMATION_REQUEST_SUCCEEDED';
export const CONFIRMATION_REQUEST_FAILED = 'CONFIRMATION_REQUEST_FAILED';

export const RESET_PASSWORD_EMAIL_REQUESTED = 'RESET_PASSWORD_EMAIL_REQUESTED';
export const RESET_PASSWORD_SENT = 'RESET_PASSWORD_SENT';
export const RESET_PASSWORD_SEND_FAILED = 'RESET_PASSWORD_SEND_FAILED';

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_REQUEST_SUCCEEDED =
    'RESET_PASSWORD_REQUEST_SUCCEEDED';
export const RESET_PASSWORD_REQUEST_FAILED = 'RESET_PASSWORD_REQUEST_FAILED';

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_REQUEST_SUCCEEDED = 'LOGOUT_REQUEST_SUCCEEDED';
export const LOGOUT_REQUEST_FAILED = 'LOGOUT_REQUEST_FAILED';

export const CLEAR_AUTH_MESSAGES = 'CLEAR_AUTH_MESSAGES';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const CLEAR_EMAIL_VERIFICATION = 'CLEAR_EMAIL_VERIFICATION';

export const EMAIL_VERIFICATION_VERIFY = 'EMAIL_VERIFICATION_VERIFY';
export const EMAIL_VERIFICATION_VERIFY_SUCCEEDED =
    'EMAIL_VERIFICATION_VERIFY_SUCCEEDED';
export const EMAIL_VERIFICATION_VERIFY_FAILED =
    'EMAIL_VERIFICATION_VERIFY_FAILED';

export const CREATE_USER_REQUESTED = 'CREATE_USER_REQUESTED';
export const CREATE_USER_SUCCEEDED = 'CREATE_USER_SUCCEEDED';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const REDIRECTION_AUTOFILL = 'REDIRECTION_AUTOFILL';

export const INITIAL_FORM_POPULATE = 'INITIAL_FORM_POPULATE';

export const INITIAL_FORM_SUBMIT = 'INITIAL_FORM_SUBMIT';
export const INITIAL_FORM_SUBMIT_SUCCEEDED = 'INITIAL_FORM_SUBMIT_SUCCEEDED';
export const INITIAL_FORM_SUBMIT_FAILED = 'INITIAL_FORM_SUBMIT_FAILED';

export const CLEAR_VERIFICATION_MESSAGES = 'CLEAR_VERIFICATION_MESSAGES';

export const SSO_AUTH_REQUESTED = 'SSO_AUTH_REQUESTED';

export const SSO_STORE_CONFIG = 'SSO_STORE_CONFIG';

export const SSO_CODE_EXCHANGE_REQUESTED = 'SSO_CODE_EXCHANGE_REQUESTED';

export const SSO_LOGIN_REQUESTED = 'SSO_LOGIN_REQUESTED';
export const SSO_LOGIN_REQUEST_SUCCEEDED = 'SSO_LOGIN_REQUEST_SUCCEEDED';
export const SSO_LOGIN_REQUEST_FAILED = 'SSO_LOGIN_REQUEST_FAILED';

export function loginRequested(data) {
    return {
        type: LOGIN_REQUESTED,
        payload: {
            data,
        },
    };
}

export function loginByOktaRequested(code) {
    return {
        type: LOGIN_BY_OKTA_REQUEST,
        payload: {
            code,
        },
    };
}

export function loginByMicrosoftRequested() {
    return {
        type: LOGIN_BY_MICROSOFT_REQUEST,
    };
}

export function loginRequestSucceeded(token) {
    return {
        type: LOGIN_REQUEST_SUCCEEDED,
        payload: {
            token,
        },
    };
}

export function loginRequestFailed(errors = []) {
    return {
        type: LOGIN_REQUEST_FAILED,
        payload: {
            errors,
        },
    };
}

export const signUpRequested = (form) => ({
    type: SIGN_UP_REQUESTED,
    payload: {
        form,
    },
});

export const signUpRequestSucceeded = (token, organizationPassphrase) => ({
    type: SIGN_UP_REQUEST_SUCCEEDED,
    payload: {
        token,
        organizationPassphrase,
    },
});

export const signUpRequestFailed = (errors = []) => ({
    type: SIGN_UP_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const confirmationRequested = (orgId) => ({
    type: CONFIRMATION_REQUESTED,
    payload: {
        orgId,
    },
});

export const confirmationRequestSucceeded = () => ({
    type: CONFIRMATION_REQUEST_SUCCEEDED,
});

export const confirmationRequestFailed = (errors = []) => ({
    type: CONFIRMATION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const resetPasswordRequested = (
    password,
    passwordConfirm,
    userId,
    token,
) => ({
    type: RESET_PASSWORD_REQUESTED,
    payload: {
        password,
        passwordConfirm,
        userId,
        token,
    },
});
export const resetPasswordRequestSucceeded = (returnURL) => ({
    type: RESET_PASSWORD_REQUEST_SUCCEEDED,
    payload: {
        returnURL,
    },
});

export const resetPasswordRequestFailed = (errors = []) => ({
    type: RESET_PASSWORD_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const resetPasswordEmailRequested = (email) => ({
    type: RESET_PASSWORD_EMAIL_REQUESTED,
    payload: {
        email,
    },
});
export const resetPasswordEmailSent = () => ({
    type: RESET_PASSWORD_SENT,
});

export const resetPasswordFailedToSend = (errors = []) => ({
    type: RESET_PASSWORD_SEND_FAILED,
    payload: {
        errors,
    },
});

export const logoutRequested = (errors = []) => ({
    type: LOGOUT_REQUESTED,
    payload: {
        errors,
    },
});

export const logoutSucceeded = () => ({
    type: LOGOUT_REQUEST_SUCCEEDED,
});

export const clearAuthMessages = () => ({
    type: CLEAR_AUTH_MESSAGES,
});

export const clearAuthErrors = () => ({
    type: CLEAR_AUTH_ERRORS,
});

export const clearEmailVerification = () => ({
    type: CLEAR_EMAIL_VERIFICATION,
});

export const emailVerificationVerify = ({ email, verificationCode }) => ({
    type: EMAIL_VERIFICATION_VERIFY,
    payload: {
        email,
        verificationCode,
    },
});

export const emailVerificationVerifySucceeded = (email) => ({
    type: EMAIL_VERIFICATION_VERIFY_SUCCEEDED,
    payload: {
        email,
    },
});

export const emailVerificationVerifyFailed = (errors = []) => ({
    type: EMAIL_VERIFICATION_VERIFY_FAILED,
    payload: {
        errors,
    },
});

export const createUserRequested = ({
    username,
    password,
    organizationPassphrase,
    marketingEmailOptIn,
}) => ({
    type: CREATE_USER_REQUESTED,
    payload: {
        username,
        password,
        organizationPassphrase,
        marketingEmailOptIn,
    },
});

export const createUserSucceeded = (token) => ({
    type: CREATE_USER_SUCCEEDED,
    payload: {
        token,
    },
});

export const createUserFailed = (errors = []) => ({
    type: CREATE_USER_FAILED,
    payload: {
        errors,
    },
});

export const redirectionAutofill = (email) => ({
    type: REDIRECTION_AUTOFILL,
    payload: {
        email,
    },
});

export const initialFormSubmit = (values) => ({
    type: INITIAL_FORM_SUBMIT,
    payload: values,
});

export const initialFormSubmitSucceeded = (email, resendVerification) => ({
    type: INITIAL_FORM_SUBMIT_SUCCEEDED,
    payload: {
        email,
        resendVerification,
    },
});

export const initialFormSubmitFailed = (email, errors) => ({
    type: INITIAL_FORM_SUBMIT_FAILED,
    payload: {
        email,
        errors,
    },
});

export const clearEmailVerificationMessages = () => ({
    type: CLEAR_VERIFICATION_MESSAGES,
});

export const initialFormPopulate = (values) => ({
    type: INITIAL_FORM_POPULATE,
    payload: { ...values },
});

export const SSOLoginRequested = (
    accessToken,
    refreshToken,
    authorizationCode = '',
    codeVerifier = '',
) => ({
    type: SSO_LOGIN_REQUESTED,
    payload: {
        accessToken,
        refreshToken,
        authorizationCode,
        codeVerifier,
    },
});

export const SSOLoginRequestFailed = (error) => ({
    type: SSO_LOGIN_REQUEST_FAILED,
    payload: {
        error,
    },
});

export const SSOLoginRequestSucceeded = (email) => ({
    type: SSO_LOGIN_REQUEST_SUCCEEDED,
    payload: {
        email,
    },
});

export const SSOAuthRequested = (config, returnLocation) => ({
    type: SSO_AUTH_REQUESTED,
    payload: {
        config,
        returnLocation,
    },
});

export const SSOStoreConfig = (config, serviceConfig, returnLocation) => ({
    type: SSO_STORE_CONFIG,
    payload: {
        config,
        serviceConfig,
        returnLocation,
    },
});

export const SSOCodeExchangeRequested = () => ({
    type: SSO_CODE_EXCHANGE_REQUESTED,
});
