export const SET_ACTION_DUPLICATION_COMMUNITIES =
    'SET_ACTION_DUPLICATION_COMMUNITIES';

export const SLACK_INTEGRATION_REQUESTED = 'SLACK_INTEGRATION_REQUESTED';
export const SLACK_INTEGRATION_REMOVE_REQUESTED =
    'SLACK_INTEGRATION_REMOVE_REQUESTED';
export const INTEGRATIONS_REQUESTED = 'INTEGRATIONS_REQUESTED';
export const INTEGRATIONS_REQUEST_SUCCEEDED = 'INTEGRATIONS_REQUEST_SUCCEEDED';
export const INTEGRATIONS_REQUEST_FAILED = 'INTEGRATIONS_REQUEST_FAILED';

export const OIDC_INTEGRATION_REQUESTED = 'OIDC_INTEGRATION_REQUESTED';

export const OIDC_INTEGRATION_REMOVE_REQUESTED =
    'OIDC_INTEGRATION_REMOVE_REQUESTED';

export const CLEAR_SETTINGS_RESPONSE = 'CLEAR_SETTINGS_RESPONSE';

export const setActionDuplicationCommunities = (communities) => ({
    type: SET_ACTION_DUPLICATION_COMMUNITIES,
    payload: {
        communities,
    },
});

export const requestSlackIntegration = (code) => ({
    type: SLACK_INTEGRATION_REQUESTED,
    payload: {
        code,
    },
});

export const requestOIDCIntegration = (
    accessToken,
    refreshToken,
    authorizationCode = '',
    codeVerifier = '',
) => ({
    type: OIDC_INTEGRATION_REQUESTED,
    payload: {
        accessToken,
        refreshToken,
        authorizationCode,
        codeVerifier,
    },
});

export const requestOIDCIntegrationRemove = (authType) => ({
    type: OIDC_INTEGRATION_REMOVE_REQUESTED,
    payload: {
        authType,
    },
});

export const requestSlackIntegrationRemove = () => ({
    type: SLACK_INTEGRATION_REMOVE_REQUESTED,
});

export const requestIntegrations = () => ({
    type: INTEGRATIONS_REQUESTED,
});

export const integrationsRequestFailed = (errors) => ({
    type: INTEGRATIONS_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const integrationsRequestSucceeded = (
    integrations,
    responseMessage,
) => ({
    type: INTEGRATIONS_REQUEST_SUCCEEDED,
    payload: {
        integrations,
        responseMessage,
    },
});

export const clearResponse = () => ({
    type: CLEAR_SETTINGS_RESPONSE,
});
