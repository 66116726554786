import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { noop } from '../../../../utils';
import { trackEvent } from '../../../../utils/analytics';
import { EVENT_GOOGLE_SIGN_IN_PRESSED } from '../../../../constants/analyticsEvents';

const GoogleButton = ({ onClick, clientId }) => {
    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current && window.google) {
            window.google.accounts.id.initialize({
                client_id: clientId,
            });
            window.google.accounts.id.renderButton(divRef.current, {
                theme: 'outline',
                size: 'large',
                type: 'standard',
                text: 'signin_with',
                shape: 'rectangular',
                logoAlignment: 'left',
            });
        }
    }, [clientId, divRef]);

    return (
        <Grid
            item
            style={{ cursor: 'pointer' }}
            onClick={(...args) => {
                onClick(...args);
                trackEvent(EVENT_GOOGLE_SIGN_IN_PRESSED);
            }}
        >
            <div
                style={{
                    pointerEvents: 'none',
                }}
            >
                <div
                    id="g_id_onload"
                    data-client_id={clientId}
                    data-context="signin"
                    data-ux_mode="none"
                    data-auto_prompt="false"
                ></div>

                <div
                    ref={divRef}
                    className="g_id_signin"
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme="outline"
                    data-text="signin_with"
                    data-size="large"
                    data-logo_alignment="left"
                ></div>
            </div>
        </Grid>
    );
};

GoogleButton.propTypes = {
    onClick: PropTypes.func,
    clientId: PropTypes.string.isRequired,
};

GoogleButton.defaultProps = {
    onClick: noop,
};

export default GoogleButton;
