const styles = (theme) => ({
    container: {
        width: '100%',
        paddingBottom: '20px',
    },
    addButtonContainer: {
        height: '50px',
        paddingBottom: '15px',
    },
    addButton: {
        borderRadius: '6px',
        paddingBottom: '15px',
        justifyContent: 'center',
        height: '100%',
        border: '2px solid',
    },
    buttonText: {
        justifyContent: 'center',
        marginTop: '5px',
    },
    emailFields: {
        maxHeight: '50vh',
        overflowY: 'auto',
        flexWrap: 'nowrap',
        paddingBottom: '3px',
    },
    participationIcon: {
        marginRight: '10px',
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: '600',
    },
    addMoreLinkText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

export default styles;
