const styles = (theme) => ({
    container: {
        backgroundColor: theme.custom.bgLightAccentColor,
    },
    header: {
        padding: '15px',
        backgroundColor: theme.custom.redDark,
    },
    title: {
        fontSize: '18px',
        fontWeight: 600,
        color: theme.palette.common.white,
    },
    icon: {
        color: theme.palette.common.black,
        opacity: 0.2,

        '&:hover': {
            opacity: 0.5,
            cursor: 'pointer',
        },
    },
    main: {
        padding: '20px',
    },
    topMessage: {
        marginBottom: '10px',
        fontSize: '14px',
    },
    bottomMessage: {
        margin: '10px 0',
        fontSize: '14px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    email: {
        color: theme.palette.primary.dark,
        textDecoration: 'none',

        '&:hover': {
            color: theme.custom.linkPrimaryHover,
            textDecoration: 'underline',
        },
    },
    footer: {
        marginTop: '15px',
        padding: '20px',
    },
});

export default styles;
