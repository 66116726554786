import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

import useStyles from './styles';
import { getChallenge } from './selectors';

import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import CommonPropTypes from '../../common/propTypes';
import { getActiveCommunityId } from '../../user/selectors';
import { challengePhotosRequested } from '../../analytics/photos/actions';
import { viewChallengeRequested } from '../../challenges/actions';
import { getTimezoneAbbrev } from '../../utils/dates';
import {
    getChallengeErrors,
    isChallengeLoading,
} from '../../challenges/selectors';
import {
    arePhotosLoading,
    getErrors as getPhotosErrors,
    getTopPhotos,
} from '../../analytics/photos/selectors';
import TopPhotos from '../../common/TopPhotos';
import { CHALLENGE_TOP_PHOTOS } from '../../common/PhotoCard/constants';
import { useRouteMatch } from 'react-router-dom';
import InfoTooltip from '../../common/InfoTooltip';

const DATE_FORMAT = 'MM/DD/YYYY';
const formatDate = (date) => moment(date).format(DATE_FORMAT);
const safelyFormatDate = (nullableDate) =>
    nullableDate == null ? null : formatDate(nullableDate);

const PHOTOS_BATCH_SIZE = 30;

const ChallengeTopPhotosScreen = ({
    communityId,
    requestChallenge,
    isChallengeLoading,
    challengeErrors,
    challenge,
    requestPhotos,
    arePhotosLoading,
    photosErrors,
    photos,
}) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    const {
        params: { challengeId },
    } = useRouteMatch();

    useEffect(() => {
        requestChallenge({
            communityId,
            challengeId,
        });
    }, [requestChallenge, communityId, challengeId]);

    const challengePresentable = useMemo(
        () =>
            !isChallengeLoading && challengeErrors == null && challenge != null,
        [isChallengeLoading, challengeErrors, challenge],
    );

    const formattedStartDate = useMemo(
        () => safelyFormatDate(challenge?.startDate),
        [challenge],
    );
    const formattedEndDate = useMemo(
        () => safelyFormatDate(challenge?.endDate),
        [challenge],
    );
    const tzCode = getTimezoneAbbrev(i18n.language);

    const bottomRef = useRef(null);
    const skippedRef = useRef(null);
    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (entries.some((entry) => entry.intersectionRatio === 1)) {
                    const skip = photos == null ? 0 : photos.length;
                    if (
                        skippedRef.current == null ||
                        skippedRef.current !== skip
                    ) {
                        requestPhotos({
                            challengeId,
                            communityId,
                            skip,
                            limit: PHOTOS_BATCH_SIZE,
                        });
                        skippedRef.current = skip;
                    }
                }
            },
            { threshold: 1 },
        );
        const targetElement = bottomRef.current;
        intersectionObserver.observe(targetElement);
        return () => intersectionObserver.unobserve(targetElement);
    }, [
        photos,
        skippedRef,
        requestPhotos,
        challengeId,
        communityId,
        bottomRef,
    ]);

    return (
        <Grid container direction="column" className={classes.container}>
            <RequestLoader
                isLoading={isChallengeLoading}
                title={t('Loading Challenge...')}
            />
            <ErrorList errors={challengeErrors} />
            {challengePresentable ? (
                <>
                    <Grid
                        container
                        item
                        direction="column"
                        className={classes.header}
                    >
                        <Grid item>
                            <Typography variant="h1" className={classes.title}>
                                {challenge.name}
                                <InfoTooltip
                                    placement="right"
                                    text={t('viewDocs')}
                                />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h1"
                                className={classes.duration}
                            >
                                {`${formattedStartDate} - ${formattedEndDate} (${tzCode})`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.divider} />
                    <Grid container item direction="column">
                        {photos != null ? (
                            <TopPhotos
                                noHeader
                                challengeId={challengeId}
                                photos={photos}
                                limit={photos.length}
                                screen={CHALLENGE_TOP_PHOTOS}
                            />
                        ) : null}
                        <Grid container item className={classes.loaderWrapper}>
                            <RequestLoader
                                isLoading={arePhotosLoading}
                                title={t('loading.photos')}
                            />
                        </Grid>
                        <ErrorList errors={photosErrors} />
                    </Grid>
                </>
            ) : null}
            <div ref={bottomRef} className={classes.bottom} />
        </Grid>
    );
};

ChallengeTopPhotosScreen.propTypes = {
    communityId: PropTypes.string.isRequired,
    requestChallenge: PropTypes.func.isRequired,
    isChallengeLoading: PropTypes.bool.isRequired,
    challengeErrors: CommonPropTypes.errors,
    challenge: PropTypes.shape({
        name: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
    }),
    requestPhotos: PropTypes.func.isRequired,
    arePhotosLoading: PropTypes.bool.isRequired,
    photosErrors: CommonPropTypes.errors,
    photos: PropTypes.object,
};

ChallengeTopPhotosScreen.defaultProps = {
    challengeErrors: null,
    challenge: null,
    photosErrors: null,
    photos: null,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isChallengeLoading: isChallengeLoading(state),
    challengeErrors: getChallengeErrors(state),
    challenge: getChallenge(state),
    arePhotosLoading: arePhotosLoading(state),
    photosErrors: getPhotosErrors(state),
    photos: getTopPhotos(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestChallenge: (request) => dispatch(viewChallengeRequested(request)),
    requestPhotos: (request) => dispatch(challengePhotosRequested(request)),
});

const ConnectedChallengeTopPhotosScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChallengeTopPhotosScreen);
export default ConnectedChallengeTopPhotosScreen;
