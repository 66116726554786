import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';

import styles from './styles';
import ConfirmModal from '../ConfirmModal';
import Button from '../Button';
import copy from 'copy-to-clipboard';
import { noop } from '../../utils';
import SnackBarAPIResponse from '../SnackbarAlert/SnackBarAPIResponse';
import { trackEvent } from '../../utils/analytics';
import { EVENT_DYNAMIC_LINK_COPIED } from '../../constants/analyticsEvents';

const InviteModal = ({
    classes,
    messageText,
    inviteModalText,
    Toggle,
    Footer,
    isOpen,
    onConfirm,
    shareLink,
    shareImageURL,
    loading,
    inviteLoading,
    imageLoading,
    onDownload,
    errors,
    clearErrors,
    communityId,
    challengeId,
    ...modalProps
}) => {
    const { t } = useTranslation();
    const [snackbarText, setSnackbarText] = useState('');

    return (
        <ConfirmModal
            {...modalProps}
            isOpen={isOpen}
            centeredButtons
            cancelBtnHidden
            confirmBtnHidden
            onConfirm={onConfirm}
        >
            <Typography variant="h4" className={classes.headerText}>
                {inviteModalText.header}
            </Typography>
            <Typography variant="body2" className={classes.modalBodyText}>
                {inviteModalText.body}
            </Typography>
            {Toggle}
            <Grid container item spacing={4}>
                <Grid
                    container
                    item
                    xs={12}
                    sm={shareImageURL ? 6 : 12}
                    data-intercom-target="Invite Modal Message Container"
                >
                    <Typography className={classes.modalPreviewHeader}>
                        {`${inviteModalText.previewHeader}:`}
                    </Typography>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        className={classes.messageTextContainer}
                    >
                        <Typography variant="body2">{messageText}</Typography>
                        <Typography
                            variant="body2"
                            className={classes.linkPreText}
                        >
                            {t('challenge.inviteModal.mobileOnly')}:
                            <Typography
                                variant="body2"
                                className={classes.linkText}
                            >
                                {shareLink}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Button
                        color="accent"
                        fullWidth
                        disabled={!shareLink}
                        loading={inviteLoading}
                        className={classes.bottomButton}
                        onClick={() => {
                            trackEvent(EVENT_DYNAMIC_LINK_COPIED, {
                                community_id: communityId,
                                challenge_id: challengeId,
                            });
                            copy(messageText + ' ' + shareLink);
                            setSnackbarText(t(inviteModalText.copied));
                        }}
                    >
                        {t('copy')}
                    </Button>
                </Grid>
                {shareImageURL && (
                    <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        data-intercom-target="Invite Modal Image Container"
                    >
                        <Typography className={classes.modalPreviewHeader}>
                            {`${inviteModalText.pdfHeader}:`}
                        </Typography>
                        <Grid
                            item
                            container
                            justifyContent="center"
                            className={classes.messageTextContainer}
                        >
                            <img
                                src={shareImageURL}
                                style={{ height: 250 }}
                                alt="share"
                            />
                        </Grid>
                        <Button
                            color="accent"
                            className={classes.bottomButton}
                            fullWidth
                            disabled={imageLoading}
                            onClick={onDownload}
                            loading={imageLoading}
                        >
                            {t('download')}
                        </Button>
                    </Grid>
                )}
                {Footer}
            </Grid>
            <SnackBarAPIResponse
                messages={snackbarText}
                errors={errors}
                clearResponse={() => {
                    setSnackbarText('');
                    clearErrors();
                }}
            />
        </ConfirmModal>
    );
};

InviteModal.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    shareLink: PropTypes.string.isRequired,
    shareImageURL: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    inviteLoading: PropTypes.bool,
    imageLoading: PropTypes.bool,
    onDownload: PropTypes.func,
    messageText: PropTypes.string.isRequired,
    inviteModalText: PropTypes.shape({
        header: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        previewHeader: PropTypes.string.isRequired,
        copied: PropTypes.string.isRequired,
        pdfHeader: PropTypes.string.isRequired,
    }).isRequired,
    onConfirm: PropTypes.func,
    Toggle: PropTypes.element,
    Footer: PropTypes.element,
    errors: PropTypes.array.isRequired,
    clearErrors: PropTypes.func.isRequired,
    communityId: PropTypes.string.isRequired,
    challengeId: PropTypes.string.isRequired,
};

InviteModal.defaultProps = {
    loading: false,
    inviteLoading: false,
    imageLoading: false,
    onDownload: noop,
    onConfirm: noop,
    Toggle: null,
    Footer: null,
};

export default withStyles(styles)(InviteModal);
