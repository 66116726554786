export default (theme) => ({
    listItemRoot: {
        padding: '10px',
        backgroundColor: theme.custom.bgLightColor,
    },
    listItemButton: {
        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },
    },
    img: {
        width: 50,
        height: 50,
        marginRight: 10,
        borderRadius: 6,
        border: '1px solid #fff',
    },
    title: {
        ...theme.typography,
        fontSize: 18,
        align: 'center',
    },
    active: {
        color: theme.palette.primary.main,
    },
});
