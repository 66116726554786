const styles = (theme) => ({
    trophies: {
        padding: '10px',
        maxWidth: '800px',
        width: 'fit-content',
        borderRadius: '5px',
        overflow: 'hidden',
    },
    title: {
        color: theme.custom.textDarkSecondaryMutedColor,
        fontWeight: '700',
        marginBottom: '12px',
    },
    border: {
        border: `5px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        backgroundColor: '#dadada',
    },
    trophyContainer: {
        position: 'relative',
    },
    medalMultiplier: {
        position: 'absolute',
        top: 60,
        right: 0,
        background: theme.palette.primary.main,
        padding: '2px 8px',
        borderRadius: '30%/50%',
    },
    multiplierText: {
        fontSize: '10px',
        fontWeight: '600',
        color: '#fff',
    },
});

export default styles;
