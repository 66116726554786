import moment from 'moment';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { COLORS } from './constants';

export const parseRawData = (rawData, isScoreTracker, type) => {
    const labels = parseLabels(rawData.labels);

    const datasets = rawData.data
        .map((dataset, index) => {
            const step = Math.floor(index / COLORS.length);
            const idx = index - COLORS.length * step;
            const color = COLORS[idx];

            return {
                data: dataset,
                label: rawData.series.length > 0 && rawData.series[index].name,
                borderColor: color,
                backgroundColor: fade(color, 0.5),
                borderWidth: 2,
                pointBorderColor: 'white',
                pointHoverBorderColor: color,
                fill: !isScoreTracker || (isScoreTracker && type === 'bar'),
            };
        })
        .sort((a, b) => {
            const first = a.data[a.data.length - 1];
            const second = b.data[b.data.length - 1];

            return second - first;
        });

    return {
        labels,
        datasets,
    };
};

export const parseLabels = (labels) => {
    return labels.map((label) => moment(label).format('MM/DD'));
};
