import { makeStyles } from '@material-ui/core';
import { CONTENT_TYPE_PIN } from '../../../constants/contentType';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.custom.bgLightColor,
        paddingTop: '10px',
        padding: '10px 20px',
    },
    userImage: ({ userId }) => ({
        maxWidth: '60px',
        maxHeight: '60px',
        marginRight: '8px',
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
        cursor: userId ? 'pointer' : 'auto',
        alignSelf: 'center',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '40px',
            maxHeight: '40px',
            alignSelf: 'flex-start',
        },
    }),
    userName: ({ userId }) => ({
        marginRight: '10px',
        fontSize: 'min(22px, 5vw)',
        lineHeight: 1.1,
        fontWeight: 800,
        textOverflow: 'ellipsis',
        color: theme.palette.common.black,
        cursor: userId ? 'pointer' : 'auto',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    }),
    textBlock: {
        border: '0 !important',
    },
    activityName: {
        fontSize: '18px',
        fontWeight: 500,
        color: theme.custom.bgDarkColor,
    },
    activityCreated: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    activityImage: ({ contentType, contentId }) => ({
        marginLeft: '8px',
        overflow: 'hidden',
        border: '0 !important',
        maxWidth: '80px',
        width: '100%',
        cursor:
            contentId && contentType === CONTENT_TYPE_PIN ? 'pointer' : 'auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '45px',
            maxHeight: '45px',
        },
    }),
}));

export default useStyles;
