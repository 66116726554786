import { Edit, DeleteOutlineOutlined } from '@material-ui/icons';

import { getTimezoneAbbrev } from '../../../utils/dates';
import moment from 'moment';
import { DATETIME_FORMAT_FOR_DATETIME_PICKERS } from '../../../constants/dates';

export const BORDER_ICON = {
    border: '2px solid #fff',
    borderRadius: '6px 6px 50% 50%',
};

export const GROUP_TYPE_QUEUED = 'queued';
export const GROUP_TYPE_DELIVERED = 'delivered';

export const INIT_SORT = {
    [GROUP_TYPE_QUEUED]: [{ id: 'sendDate', desc: false }],
    [GROUP_TYPE_DELIVERED]: [{ id: 'sendDate', desc: true }],
};

// Localization constants
export const LOCALIZATION_NOTIFICATIONS_KEY = 'notifications';
export const LOCALIZATION_NOTIFICATION_GROUPS_KEY = `${LOCALIZATION_NOTIFICATIONS_KEY}.groups`;
export const LOCALIZATION_NOTIFICATION_TABLE_KEY = `${LOCALIZATION_NOTIFICATIONS_KEY}.table`;
export const LOCALIZATION_NOTIFICATION_TABLE_ACTIONS_KEY = `${LOCALIZATION_NOTIFICATION_TABLE_KEY}.actionToolTips`;
export const LOCALIZATION_NOTIFICATION_TABLE_HEADERS_KEY = `${LOCALIZATION_NOTIFICATION_TABLE_KEY}.headers`;

// The groups are the main sections on the page: Queued and Delivered
export const GROUP_NAMES = {
    [GROUP_TYPE_QUEUED]: `${LOCALIZATION_NOTIFICATION_GROUPS_KEY}.${GROUP_TYPE_QUEUED}`,
    [GROUP_TYPE_DELIVERED]: `${LOCALIZATION_NOTIFICATION_GROUPS_KEY}.${GROUP_TYPE_DELIVERED}`,
};

export const GROUPS = [GROUP_TYPE_QUEUED, GROUP_TYPE_DELIVERED];

export const COLUMNS = [
    { accessor: 'icon', sortable: false },
    {
        Header: `${LOCALIZATION_NOTIFICATION_TABLE_HEADERS_KEY}.subject`,
        accessor: 'subject',
    },
    {
        Header: `${LOCALIZATION_NOTIFICATION_TABLE_HEADERS_KEY}.message`,
        accessor: 'message',
    },
    {
        // todo: localize
        Header: `Send Date (${getTimezoneAbbrev()})`,
        accessor: 'sendDate',
        Cell: (props) =>
            moment(props.value).format(DATETIME_FORMAT_FOR_DATETIME_PICKERS),
    },
    {
        Header: `${LOCALIZATION_NOTIFICATION_TABLE_HEADERS_KEY}.audienceType`,
        accessor: 'audienceType',
    },
    {
        Header: `${LOCALIZATION_NOTIFICATION_TABLE_HEADERS_KEY}.audienceMembers`,
        accessor: 'audience',
        sortable: false,
    },
    {
        Header: `${LOCALIZATION_NOTIFICATION_TABLE_HEADERS_KEY}.userCount`,
        accessor: 'userCount',
        sortable: false,
    },
];

export const BTN_EDIT = {
    key: 'editNotification',
    actions: 'manage',
    subject: 'MasterNotifications',
    tooltip: `${LOCALIZATION_NOTIFICATION_TABLE_ACTIONS_KEY}.edit`,
    intercomTarget: 'editNotificationButton',
    icon: Edit,
};

export const BTN_DELETE = {
    key: 'deleteNotification',
    actions: 'manage',
    subject: 'MasterNotifications',
    tooltip: `${LOCALIZATION_NOTIFICATION_TABLE_ACTIONS_KEY}.delete`,
    intercomTarget: 'deleteNotificationButton',
    icon: DeleteOutlineOutlined,
};

export const BUTTONS_CELL = {
    [GROUP_TYPE_QUEUED]: [BTN_EDIT, BTN_DELETE],
};
