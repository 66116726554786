const styles = (theme) => ({
    impactTotalsRow: {
        marginBottom: '10px',
    },
    impactTotalsLabel: {
        color: theme.custom.textDarkSecondaryColor,
        fontSize: '24px',
    },
    tooltip: {
        color: theme.palette.white.main,
    },
    icon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '3px',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    statsCard: {
        flex: 1,
        maxWidth: '100%',
    },
    statsCards: {
        flexBasis: '100%',
    },
});

export default styles;
