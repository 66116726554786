const styles = (theme) => ({
    modalBody: {
        justifyContent: 'center',
    },
    modalHeader: {
        paddingBottom: '10px',
    },
});

export default styles;
