/**
 * Generic function to translate the Header field of table columns
 * @param t i18next t function
 * @param columns
 * @returns {Object[]}
 */
export const translateColumns = (t, columns) =>
    columns.map((col) => {
        const { Header: header, ...colProps } = col;
        return {
            ...(header
                ? {
                      Header: t(header),
                  }
                : {}),
            ...colProps,
        };
    });

/**
 * Generic function to translate the tooltip field of table buttons
 * @param t i18next t function
 * @param buttonCells
 * @returns {Object[]}
 */
export const translateButtonTooltips = (t, buttonCells) =>
    buttonCells.map((cell) => {
        const { tooltip, ...cellProps } = cell;
        return {
            ...(tooltip
                ? {
                      tooltip: t(tooltip),
                  }
                : {}),
            ...cellProps,
        };
    });
