import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Grid,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    withStyles,
    Typography,
} from '@material-ui/core';
import { ChevronRight, Help } from '@material-ui/icons';
import Tooltip from '../Tooltip';
import { getPermissionsBlock } from '../utils';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const PermissionsDropdown = ({
    classes,
    parentInternalName,
    parent,
    onPermissionParentCheck,
    permissions,
    checkedPermissions,
    onPermissionCheck,
}) => {
    const { t } = useTranslation();
    const [perms, parentPermission] = getPermissionsBlock(
        permissions,
        parent,
        parentInternalName,
    );
    const [expanded, setExpanded] = useState(false);

    const getCheckedPermissionsCount = () => {
        const checkedList = [];
        for (const [key, value] of Object.entries(checkedPermissions)) {
            if (value) checkedList.push(key);
        }

        let count = perms.filter((permission) =>
            checkedList.includes(permission.internalName),
        ).length;

        if (checkedList.includes(parentPermission?.internalName)) {
            count++;
        }

        return count;
    };

    const isParentChecked = checkedPermissions[parentPermission?.internalName];

    return (
        <ExpansionPanel
            expanded={expanded}
            onChange={(e, newVal) => setExpanded(newVal)}
            classes={{ root: classes.root }}
        >
            <ExpansionPanelSummary
                classes={{
                    root: classes.summary,
                    content: classes.summaryContent,
                }}
            >
                <Grid container wrap="nowrap">
                    <Grid container item alignItems="center">
                        <ChevronRight
                            className={clsx([
                                classes.arrowIcon,
                                expanded && classes.rotate,
                                !isParentChecked && classes.disabled,
                            ])}
                        />
                        <Typography
                            className={clsx([
                                classes.title,
                                !isParentChecked && classes.disabled,
                            ])}
                        >
                            {parentPermission?.parent}
                        </Typography>
                        <input
                            type="checkbox"
                            name={parentPermission?.internalName}
                            value={parentPermission?.internalName}
                            checked={!!isParentChecked}
                            onChange={(e) => onPermissionParentCheck(e, perms)}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                        />
                        <i
                            className={clsx([
                                classes.small,
                                !isParentChecked && classes.disabled,
                            ])}
                        >
                            {isParentChecked
                                ? t('section.enabled')
                                : t('disabled')}
                        </i>
                    </Grid>
                    <Typography
                        className={clsx([
                            classes.countText,
                            classes.small,
                            !isParentChecked && classes.disabled,
                        ])}
                    >
                        <i>
                            {`(${getCheckedPermissionsCount()}/${perms.length +
                                1})`}
                        </i>
                    </Typography>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.details }}>
                <Grid
                    container
                    className={classes.detailsContent}
                    direction="column"
                >
                    {perms
                        .sort((a, b) => (a.name > b.name) - (a.name < b.name))
                        .map((item) => {
                            const checked =
                                checkedPermissions[item.internalName];
                            return (
                                <Grid
                                    container
                                    item
                                    className={clsx([
                                        classes.detailsItem,
                                        !checked && classes.disabled,
                                    ])}
                                    key={item.internalName}
                                    justify="space-between"
                                    wrap="nowrap"
                                >
                                    <Grid container item>
                                        {item.name}
                                        <Tooltip
                                            title={item.description}
                                            placement="right"
                                        >
                                            <Help
                                                fontSize="small"
                                                className={classes.helpIcon}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <input
                                        type="checkbox"
                                        checked={!!checked}
                                        name={item.internalName}
                                        value={item.internalName}
                                        onChange={(e) =>
                                            onPermissionCheck(
                                                e,
                                                parentPermission,
                                            )
                                        }
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

PermissionsDropdown.propTypes = {
    classes: PropTypes.object.isRequired,
    parentInternalName: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            internalName: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
    ).isRequired,
    checkedPermissions: PropTypes.object.isRequired,
    onPermissionParentCheck: PropTypes.func.isRequired,
    onPermissionCheck: PropTypes.func.isRequired,
};

export default withStyles(styles)(PermissionsDropdown);
