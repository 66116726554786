import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { ONBOARDING_STATUS } from '../constants';
import IMAGES from '../../../constants/images';
import { Link } from 'react-router-dom';

const TopSection = ({ classes, status, email, stripePlanId }) => {
    const { t } = useTranslation();

    const censoredEmailAddress = (value) => {
        const regex = /(.{3})(.*)(?=@)/;

        return value?.replace(regex, (p1, p2, p3) => {
            for (let i = 0; i < p3.length; i++) {
                p2 += '*';
            }
            return p2;
        });
    };

    const getTitle = () => {
        if (stripePlanId) return t('onBoarding.title.premium');
        else return t('onBoarding.title.free');
    };

    if (status === ONBOARDING_STATUS) {
        return (
            <Grid>
                <Typography variant="h3" className={classes.onBoardingTitle}>
                    {getTitle()}
                </Typography>
                <Typography align="center">
                    {t('signUp.existingAccount') + ' '}
                    <Link to="/login" className={classes.linkText}>
                        {t('onBoarding.logIn')}.
                    </Link>
                </Typography>
            </Grid>
        );
    } else {
        return (
            <Grid>
                <Grid className={classes.verifyEmailIconBox}>
                    <img
                        alt={t('alt.veriyEmailIcon')}
                        src={IMAGES.verifyEmailIcon}
                        className={classes.verifyEmailIcon}
                    />
                </Grid>
                <Typography variant="h3" className={classes.verifyTitle}>
                    {t('onBoarding.verify.titleGreen')}
                </Typography>
                <Typography variant="h3" className={classes.onBoardingTitle}>
                    {t('onBoarding.verify.title')}
                </Typography>
                <Typography
                    variant="body2"
                    className={classes.onBoardingSubtitle}
                >
                    {`${t('onBoarding.verify.subtitle', {
                        email: censoredEmailAddress(email),
                    })}`}
                </Typography>
            </Grid>
        );
    }
};

TopSection.propTypes = {
    status: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    stripePlanId: PropTypes.string.isRequired,
};

TopSection.defaultProps = {
    status: ONBOARDING_STATUS,
    email: '',
};

export default withStyles(styles)(TopSection);
