import { useEffect, useRef } from 'react';
import { matchPath } from 'react-router';
import properties from '../properties';
import IMAGES from '../constants/images';
import { keys } from 'lodash';

/**
 * A function that does nothing.
 */
export function noop() {}

/**
 * Returns true if the path given matches any of the
 * paths in the paths array. (Not exact and not strict.)
 * @param path
 * @param paths
 * @returns {boolean}
 */
export const matchesPaths = (path, paths = []) => {
    return paths.reduce((accumulator = false, testPath) => {
        return accumulator || matchPath(testPath, { path }) !== null;
    }, false);
};

/**
 * Safely determine if an object has a key.
 *
 * @see https://eslint.org/docs/rules/no-prototype-builtins
 * @see Object.prototype.hasOwnProperty
 *
 * @param {{}} obj
 * @param {string} key
 */
export function has(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key);
}

/**
 * If value is an object and contains the specified property, that will be returned.
 * Otherwise the default value will be returned. By default the default value is the value
 * itself.
 *
 * @param {*} value
 * @param {string} prop
 * @param {*} def
 * @return {string}
 */
export const extractStringProperty = (value, prop, def = value) => {
    return value && typeof value === 'object' && has(value, prop)
        ? `${value[prop]}`
        : `${def}`;
};

/**
 * If value is an object and contains the specified property, and that property is an array,
 * it will be returned. Otherwise the default value will be returned which is an empty array
 * by default.
 *
 * @param {*} value
 * @param {string} prop
 * @param {*} def
 * @return {*[]}
 */
export const extractArrayProperty = (value, prop, def = []) => {
    return value &&
        typeof value === 'object' &&
        has(value, prop) &&
        Array.isArray(value[prop])
        ? value[prop]
        : def;
};

export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

const getVariant = () => {
    return window.location.host.split('.')?.[0]?.toLowerCase() || 'joulebug';
};

export const getApplicationConfig = () => {
    return JSON.parse(localStorage.getItem('application_config'));
};

export const setApplicationConfig = (config) => {
    localStorage.setItem('application_config', JSON.stringify(config));
};

export const getStaticProperties = () => {
    const variant = getVariant();
    return properties[variant] || properties.joulebug;
};

export const getProperties = () => {
    const cachedConfig = getApplicationConfig();
    const defaultConfig = getStaticProperties();
    if (cachedConfig) {
        return {
            ...defaultConfig,
            ...cachedConfig,
        };
    } else {
        return defaultConfig;
    }
};
export const getUserRole = () => localStorage.getItem('role');

export const getAppName = () => getProperties()?.appName;
export const getAppDescription = () => getProperties()?.appDescription;

export const getSupportedLanguageCodes = () =>
    getProperties()?.supportedLanguageCodes;

export const hasMarketingEmailOptIn = () =>
    getProperties()?.hasMarketingEmailOptIn;

export const getImpactLink = () =>
    getProperties()?.impactLink || getProperties()?.wordpressDomain + '/impact';

export const getTermsLink = () =>
    getProperties()?.termsLink ||
    getProperties()?.wordpressDomain + '/terms-of-use';

export const getPrivacyLink = () =>
    getProperties()?.privacyLink ||
    getProperties()?.wordpressDomain + '/privacy';

export const getSecondPrivacyLink = () => getProperties()?.secondPrivacyLink;

export const getAboutLink = () =>
    getProperties()?.aboutLink || getProperties()?.wordpressDomain + '/';

export const getAppStoreLink = () => getProperties()?.appStoreLink;

export const getPlayStoreLink = () => getProperties()?.playStoreLink;

export const getFontFamily = () => getProperties()?.fontFamily;

export const getColors = () => {
    return {
        ...getStaticProperties().colors,
        ...(getProperties()?.colors || {}),
    };
};

export const getStringsNamespace = () => getProperties()?.stringsNamespace;

export const getAppImages = () => IMAGES[getVariant()] || IMAGES.joulebug;

/**
 * @param unsortedArray []
 * @returns [] sortedArrayWithRank
 */
export const mapSortAddRank = (unsortedArray) => {
    return unsortedArray
        .sort((a, b) => b.value - a.value)
        .map((item, index) => ({ ...item, rank: index + 1 }));
};

/**
 * Default user count for package
 */
export const getDefaultUserCount = () => {
    return 25;
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const changeCommunityURL = (history, communityId, newRoute) => {
    const params = new URLSearchParams(window.location.search);
    params.delete('communityId');
    params.append('communityId', communityId);
    history.push({
        pathname: newRoute,
        search: params.toString(),
    });
};

export const handleCommunityIdQueryParam = (
    permissions,
    communityId,
    changeCommunity,
    setSnackbarError,
    t,
    history,
) => {
    const params = new URLSearchParams(window.location.search);
    const queryCommunityId = params.get('communityId');
    if (queryCommunityId === communityId) return null;
    if (queryCommunityId) {
        if (
            keys(permissions).some(
                (communityId) => communityId === queryCommunityId,
            )
        ) {
            changeCommunity(queryCommunityId);
        } else {
            setSnackbarError(
                t('community.noPermissions', {
                    communityId: queryCommunityId,
                }),
            );
            params.delete('communityId');
            params.append('communityId', communityId);
            history.replace({ search: params.toString() });
        }
    } else {
        params.append('communityId', communityId);
        history.replace({ search: params.toString() });
    }
};

export const downloadShareImage = (
    imageUrl,
    name,
    setDownloadRequested,
    setError,
    errorMessage,
) => {
    fetch(imageUrl).then(
        (response) => {
            response.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${name} share image.jpg`;
                a.click();
                setDownloadRequested(false);
            });
        },
        (_) => {
            setError(errorMessage);
            setDownloadRequested(false);
        },
    );
};
