import { makeStyles } from '@material-ui/styles';
import { HEADER_VARIANT, ACCOUNT_VARIANT } from './constants';

export default makeStyles((theme) => ({
    root: ({ maxUsers, currentUsers }) => ({
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: theme.palette.white.main,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor:
                currentUsers >= maxUsers
                    ? theme.custom.red219
                    : theme.palette.primary.main,
        },
    }),
    container: ({ variant }) => ({
        backgroundColor: theme.custom.grey93,
        padding: variant === HEADER_VARIANT ? '5px 10px 10px 5px' : '10px',
        borderRadius: variant === HEADER_VARIANT ? '0px 0px 5px 5px' : '5px',
        display: 'flex',
    }),
    accountType: {
        fontWeight: 700,
        lineHeight: 1,
    },
    progressBar: {
        height: '10px',
        borderRadius: '3px',
        marginTop: '7px',
    },
    progressBarContainer: ({ variant }) => ({
        paddingTop: variant === ACCOUNT_VARIANT && '0px',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-start',
        },
        [theme.breakpoints.up('md')]: {
            flex: 1,
        },
    }),
    userCount: {
        color: theme.custom.grey60,
        fontSize: '12px',
    },
    iconContainer: ({ variant }) => ({
        display: 'flex',
        alignItems: variant === HEADER_VARIANT && 'center',
        justifyContent: variant === HEADER_VARIANT && 'center',
        height: variant === HEADER_VARIANT && '40px',
    }),
    warningIcon: ({ variant }) => ({
        width: variant === HEADER_VARIANT ? '80%' : '24px',
        height: variant === ACCOUNT_VARIANT && '24px',
        fill: theme.custom.red219,
    }),
    upgradeBtn: {
        padding: 5,
        borderRadius: '10px',
        marginTop: '5px',
        backgroundColor: theme.palette.white.main,
    },
    link: {
        textDecoration: 'none',
    },
}));
