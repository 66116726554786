import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, Grid, Typography, withStyles } from '@material-ui/core';

import styles from './styles';

import ConfirmModal from '../ConfirmModal';
import {
    activeCommunityPropType,
    availableCommunitiesPropType,
} from '../../user/propTypes';

const ActionDuplicationModal = ({
    classes,
    actionDuplicationCommunities,
    activeCommunity,
    isOpen,
    onClose,
    onCancel,
    onConfirm,
    children,
    ...props
}) => {
    const { t } = useTranslation();
    const title = (
        <Trans i18nKey="action.duplicationModal.title">
            Confirm <strong>Save</strong>
        </Trans>
    );

    const [checkedCommunityIds, setCheckedCommunityIds] = useState(
        actionDuplicationCommunities.reduce((checkedMap, community) => {
            checkedMap[community.content.id] = true;
            return checkedMap;
        }, {}),
    );

    const handleCheckChange = useCallback(
        (event) => {
            const checked = {
                ...checkedCommunityIds,
                [event.target.value]: !checkedCommunityIds[event.target.value],
            };
            setCheckedCommunityIds(checked);
        },
        [checkedCommunityIds, setCheckedCommunityIds],
    );

    const renderCommunity = useCallback(
        (name, id, checkOverride = false, handleChange = handleCheckChange) => (
            <Grid
                alignItems="center"
                xs={5}
                className={classes.communityCheckContainer}
            >
                <Typography className={classes.communityName}>
                    {name}
                </Typography>
                <Checkbox
                    className={classes.checkbox}
                    checked={checkOverride || checkedCommunityIds[id]}
                    onChange={handleChange}
                    value={id}
                />
            </Grid>
        ),
        [classes, checkedCommunityIds, handleCheckChange],
    );

    return (
        <ConfirmModal
            title={title}
            isOpen={isOpen}
            confirmBtnText={t('confirm')}
            confirmBtnType="danger"
            cancelBtnText="Cancel"
            cancelBtnType="default"
            onClose={onClose}
            onCancel={onCancel}
            onConfirm={() =>
                onConfirm(
                    Object.keys(checkedCommunityIds).filter(
                        (key) => checkedCommunityIds[key],
                    ),
                )
            }
            classes={
                actionDuplicationCommunities.length > 0 && {
                    footer: classes.footer,
                    container: classes.container,
                }
            }
            {...props}
        >
            <Grid container direction="column">
                {children}
                {actionDuplicationCommunities.length > 0 && (
                    <>
                        <Grid item className={classes.duplicationContainer}>
                            {t('action.duplicationModal.description')}
                        </Grid>
                        {renderCommunity(
                            <strong>{activeCommunity.name}</strong>,
                            activeCommunity.id,
                            true,
                            () => {},
                        )}
                        {actionDuplicationCommunities.map((community) =>
                            renderCommunity(
                                community.content.name,
                                community.content.id,
                            ),
                        )}
                    </>
                )}
            </Grid>
        </ConfirmModal>
    );
};

ActionDuplicationModal.propTypes = {
    classes: PropTypes.object.isRequired,
    activeCommunity: activeCommunityPropType.isRequired,
    actionDuplicationCommunities: availableCommunitiesPropType.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    children: PropTypes.node,
};

ActionDuplicationModal.defaultProps = {
    children: null,
};

export default withStyles(styles)(ActionDuplicationModal);
