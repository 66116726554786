export const ACTIVITY_DATA_REQUESTED = 'ACTIVITY_DATA_REQUESTED';
export const ACTIVITY_DATA_REQUEST_SUCCEEDED =
    'ACTIVITY_DATA_REQUEST_SUCCEEDED';
export const ACTIVITY_DATA_REQUEST_FAILED = 'ACTIVITY_DATA_REQUEST_FAILED';
export const ACTIVITY_DETAIL_REQUESTED = 'ACTIVITY_DETAIL_REQUESTED';
export const ACTIVITY_DETAIL_REQUEST_SUCCEEDED =
    'ACTIVITY_DETAIL_REQUEST_SUCCEEDED';
export const ACTIVITY_DETAIL_REQUEST_FAILED = 'ACTIVITY_DETAIL_REQUEST_FAILED';
export const ACTIVITY_SHARE_URL_REQUESTED = 'ACTIVITY_SHARE_URL_REQUESTED';
export const ACTIVITY_SHARE_URL_REQUEST_SUCCEEDED =
    'ACTIVITY_SHARE_URL_REQUEST_SUCCEEDED';
export const ACTIVITY_SHARE_URL_REQUEST_FAILED =
    'ACTIVITY_SHARE_URL_REQUEST_FAILED';
export const ACTIVITY_LIKE_REQUESTED = 'ACTIVITY_LIKE_REQUESTED';
export const ACTIVITY_LIKE_REQUEST_SUCCEEDED =
    'ACTIVITY_LIKE_REQUEST_SUCCEEDED';
export const ACTIVITY_LIKE_REQUEST_FAILED = 'ACTIVITY_LIKE_REQUEST_FAILED';
export const ACTIVITY_ADD_COMMENT_REQUESTED = 'ACTIVITY_ADD_COMMENT_REQUESTED';
export const ACTIVITY_ADD_COMMENT_REQUEST_SUCCEEDED =
    'ACTIVITY_ADD_COMMENT_REQUEST_SUCCEEDED';
export const ACTIVITY_ADD_COMMENT_REQUEST_FAILED =
    'ACTIVITY_ADD_COMMENT_REQUEST_FAILED';
export const ACTIVITY_CLEAR_ERRORS = 'ACTIVITY_CLEAR_ERRORS';
export const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';

export const activityDataRequested = (activitiesType, options) => ({
    type: ACTIVITY_DATA_REQUESTED,
    payload: {
        activitiesType,
        options,
    },
});
export const activityDataRequestFailed = (activitiesType, errors) => ({
    type: ACTIVITY_DATA_REQUEST_FAILED,
    payload: {
        activitiesType,
        errors,
    },
});
export const activityDataRequestSucceeded = (
    activitiesType,
    newActivityData,
    skip,
) => ({
    type: ACTIVITY_DATA_REQUEST_SUCCEEDED,
    payload: {
        activitiesType,
        newActivityData,
        skip,
    },
});
export const activityDetailRequested = (activityId) => ({
    type: ACTIVITY_DETAIL_REQUESTED,
    payload: {
        activityId,
    },
});
export const activityDetailRequestSucceeded = (activity) => ({
    type: ACTIVITY_DETAIL_REQUEST_SUCCEEDED,
    payload: {
        activity,
    },
});
export const activityDetailRequestFailed = (errors) => ({
    type: ACTIVITY_DETAIL_REQUEST_FAILED,
    payload: {
        errors,
    },
});
export const activityShareURLRequested = (activityId) => ({
    type: ACTIVITY_SHARE_URL_REQUESTED,
    payload: {
        activityId,
    },
});
export const activityShareURLRequestSucceeded = (activityId, url) => ({
    type: ACTIVITY_SHARE_URL_REQUEST_SUCCEEDED,
    payload: {
        activityId,
        url,
    },
});
export const activityShareURLRequestFailed = (errors) => ({
    type: ACTIVITY_SHARE_URL_REQUEST_FAILED,
    payload: {
        errors,
    },
});
export const activityLikeRequested = (activityId, like) => ({
    type: ACTIVITY_LIKE_REQUESTED,
    payload: {
        activityId,
        like,
    },
});
export const activityLikeRequestSucceeded = (activity) => ({
    type: ACTIVITY_LIKE_REQUEST_SUCCEEDED,
    payload: {
        activity,
    },
});
export const activityLikeRequestFailed = (errors) => ({
    type: ACTIVITY_LIKE_REQUEST_FAILED,
    payload: {
        errors,
    },
});
export const addCommentRequested = (activityId, comment) => ({
    type: ACTIVITY_ADD_COMMENT_REQUESTED,
    payload: {
        activityId,
        comment,
    },
});
export const addCommentRequestSucceeded = (activity) => ({
    type: ACTIVITY_ADD_COMMENT_REQUEST_SUCCEEDED,
    payload: {
        activity,
    },
});
export const addCommentRequestFailed = (errors) => ({
    type: ACTIVITY_ADD_COMMENT_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearActivityErrors = () => ({
    type: ACTIVITY_CLEAR_ERRORS,
});

export const clearActivities = (activitiesType) => ({
    type: CLEAR_ACTIVITIES,
    payload: {
        activitiesType,
    },
});
