import React, { memo, useState } from 'react';
import { Grid, Typography, Select, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './styles';
import { noop } from '../../../utils';
import Tooltip from '../../Tooltip';
import InfoTooltip from '../../InfoTooltip';

const TableToolbar = memo(
    ({
        classes,
        title,
        rowsPerPage,
        options,
        setPageSize,
        hideRowsPerPage,
        intercomTargetPrefix,
    }) => {
        const { t } = useTranslation();
        const [openTooltip, setOpenTooltip] = useState(false);

        return (
            <Grid container className={classes.root}>
                <Grid item>
                    <Grid item container alignItems="center">
                        {title && (
                            <>
                                <Typography
                                    variant="h3"
                                    className={classes.textLabel}
                                >
                                    {title}
                                </Typography>
                                <InfoTooltip
                                    intercomTarget={`${intercomTargetPrefix}TableHeaderTooltip`}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                {!!rowsPerPage && !hideRowsPerPage && (
                    <Grid item>
                        <Tooltip
                            open={openTooltip}
                            title={t('Items per page')}
                            placement="left"
                        >
                            <Select
                                onMouseEnter={() => setOpenTooltip(true)}
                                onMouseLeave={() => setOpenTooltip(false)}
                                onOpen={() => setOpenTooltip(false)}
                                classes={{
                                    icon: classes.selectIcon,
                                }}
                                IconComponent={ExpandMore}
                                SelectDisplayProps={{
                                    className: classes.select,
                                }}
                                disableUnderline
                                value={rowsPerPage}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {options.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                        className={classes.menuItem}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
        );
    },
);

TableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    rowsPerPage: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.number).isRequired,
    hideRowsPerPage: PropTypes.bool,
    setPageSize: PropTypes.func,
    intercomTargetPrefix: PropTypes.string,
};

TableToolbar.defaultProps = {
    title: null,
    rowsPerPage: 0,
    hideRowsPerPage: false,
    setPageSize: noop,
    intercomTargetPrefix: '',
};

export default withStyles(styles)(TableToolbar);
