const styles = (theme) => ({
    inputLabel: { color: theme.palette.primary.main, fontWeight: '700' },
    formLabel: { marginBottom: '10px' },
    onBoardingPassword: {
        margin: '100px 0px 0px 0px',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: '0px',
        },
    },
    form: {
        position: 'relative',
        width: '100%',
    },
    title: {
        fontWeight: '700',
        paddingTop: '60px',
        paddingBottom: '60px',
        [theme.breakpoints.down('md')]: {
            paddingTop: '5%',
        },
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },
});

export default styles;
