import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL } from './constants';

const KN_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'KN Sustain',
    shouldShowSignUp: false,
    clientId: '60f09787e0b526054098b204',
    clientSecret:
        '01pkzqcD4Ty3aKV-u_GxpMW8xpVHTS4j8qrhwbrZfdGHOfPnV_JPbaNdOvhxrwGGna',
    loginMethods: [LOGIN_METHOD_EMAIL],
    apiUri: process.env.REACT_APP_JBE_EU_API_URI,
    wordpressDomain: 'https://knsustain.joulebug.com',
    appDescription:
        'With KN Sustain, compete in challenges to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we strive towards common goals.',
};

export default KN_PROPERTIES;
