export const SUPPORTED_LANGUAGES = {
    en: 'English',
    'en-GB': 'English (United Kingdom)',
    'zh-CN': 'Chinese Simplified',
    'zh-TW': 'Chinese Traditional',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    fi: 'Finnish',
    fr: 'French',
    'fr-BE': 'French (Belgium)',
    'fr-CA': 'French (Canada)',
    'fr-CH': 'French (Switzerland)',
    de: 'German',
    'de-CH': 'German (Switzerland)',
    hu: 'Hungarian',
    it: 'Italian',
    'it-CH': 'Italian (Switzerland)',
    ja: 'Japanese',
    ko: 'Korean',
    nb: 'Norwegian Bokmål',
    pl: 'Polish',
    pt: 'Portuguese',
    'pt-BR': 'Portuguese (Brazil)',
    ro: 'Romanian',
    ru: 'Russian',
    sr: 'Serbian',
    sk: 'Slovak',
    si: 'Slovenian',
    es: 'Spanish',
    sv: 'Swedish',
    uk: 'Ukrainian',
};

export const DEFAULT_LANGUAGE = 'en';

export const SUPPORTED_LANGUAGE_CODES = Object.keys(SUPPORTED_LANGUAGES);
