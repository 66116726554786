const styles = (theme) => ({
    root: {
        height: 'auto',
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer + 1,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    toolbar: {
        minHeight: theme.custom.headerHeight,
        height: theme.custom.headerHeight,
        padding: '0 0 0 16px',
    },
    menuIconWrapper: {
        // flex: 3,
    },
    menuIcon: {
        fontSize: '28px',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    title: {
        padding: '15px 8px',
        fontSize: '18px',
        lineHeight: '20px',
        textDecoration: 'none',
        color: theme.palette.common.white,

        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    userDropdown: {
        flex: 1,
        display: 'flex',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export default styles;
