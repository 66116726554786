const styles = (theme) => ({
    title: {
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    header: {
        paddingBottom: '20px',
    },
});

export default styles;
