import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_AZURE_AD } from './constants';

const CLEARTELLIGENCE_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    loginMethods: [LOGIN_METHOD_AZURE_AD],
    appName: 'Joulebug: ClearTelligence',
    clientId: '669dde2c9f61236bd7b85ab5',
    clientSecret:
        '064upTUzPO-kwsEYSHBGOEHkM25kZbUHcIZWdBfxtYmEJMKMjtlXVwAhj7wh3X0smS',
    microsoftClientId: 'f6bccd04-25be-4d78-82e0-016136d641a3',
    microsoftClientAuthority:
        'https://login.microsoftonline.com/b32a5f3f-241e-427d-9acb-a6539011c7ad',
    wordpressDomain: 'https://joulebug.com',
    // Flag for enabling the org creation flow
    shouldShowSignUp: false,
    appDescription:
        'With JouleBug, compete in challenges with fellow co-workers to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we work towards common goals.',
    appStoreLink:
        'https://apps.apple.com/us/app/joulebug-enterprise/id1105090085',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.carebug',
};
export default CLEARTELLIGENCE_PROPERTIES;
