const styles = (theme) => ({
    menuItem: {
        padding: 0,
    },
    link: {
        fontSize: '14px',
        lineHeight: '20px',
        textDecoration: 'none',
        color: theme.custom.textDarkColor,
        width: '100%',
        height: '100%',
        padding: '3px 20px',
    },
    paper: {
        top: '53px !important',
        width: '160px',
        marginRight: '-5px',
        padding: '5px 0',
    },
    button: {
        maxWidth: '80%',
        display: 'flex',
        alignSelf: 'end',
        margin: '10px 15px',
        padding: '5px',
        color: theme.palette.common.white,
        backgroundColor: 'transparent',
        border: 0,
        textTransform: 'capitalize',

        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },

        '& svg:last-child': {
            alignSelf: 'flex-end',
            marginRight: '-5px',
        },
    },
    activeButton: {
        boxShadow: 'inset 0 3px 5px rgba(0,0,0,.125)',
    },
    title: {
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export default styles;
