const styles = (theme) => ({
    container: {
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    divider: {
        width: '100%',
        height: '1px',
        border: 'none',
        backgroundColor: theme.custom.bgDarkHover,
        marginTop: '20px',
        marginBottom: '30px',
    },
    submitButton: {
        marginTop: '10px',
    },
    loaderWrapper: {
        position: 'relative',
    },
    bottom: {
        height: 0,
    },
    emptyText: {
        margin: '20px 0',
        fontSize: '14px',
        lineHeight: 1.1,
        fontWeight: '600',
        textAlign: 'center',
        opacity: 0.5,
    },
});

export default styles;
