const styles = (theme) => ({
    container: {
        maxWidth: '1280px',
        padding: '0 16px',
        margin: 'auto',
    },
    headerButtonLeft: {
        marginRight: 10,
    },
    relative: {
        position: 'relative',
    },
    uploadedFile: {
        marginTop: 5,
        backgroundColor: theme.custom.grey,
        padding: '5px 10px',
        borderRadius: 5,
    },
    closeIcon: {
        cursor: 'pointer',
    },
});

export default styles;
