import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    impactTotalsLabel: {
        fontSize: '24px',
        fontWeight: 600,
        marginTop: '40px',
        marginBottom: '10px',
        lineHeight: 1.1,
        color: theme.custom.textDarkSecondaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& svg': {
            cursor: 'pointer',
            marginLeft: '6px',
            color: 'rgba(51,51,51,.25)',

            '&:hover': {
                color: 'rgba(51,51,51,.5)',
            },
        },
    },
    activityTotals: {
        marginTop: '40px',
    },
}));

export default useStyles;
