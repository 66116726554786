import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    error: {
        fontSize: '14px',
        backgroundColor: '#f2dede',
        color: '#a94442',
    },
}));

export default useStyles;
