import { all, call, put, takeLatest } from 'redux-saga/effects';
import { trackEvent } from '../../utils/analytics';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../../utils/api';
import {
    INVITE_USERS_REQUESTED,
    inviteUsersRequestFailed,
    inviteUsersRequestSucceeded,
} from '../inviteUsers/actions';

export function* handleInviteUsers(action) {
    trackEvent({
        name: 'onboarding_invite_users',
    });

    try {
        const { communityId, invites } = action.payload;
        yield call(fetchWithAuth, '/kiosk/organization/invite', {
            method: 'POST',
            body: {
                invites,
                communityId,
            },
        });
        yield put(inviteUsersRequestSucceeded());
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed send invites to users',
        );
        yield put(inviteUsersRequestFailed(errors));
    }
}

export default function* loginSaga() {
    yield all([takeLatest(INVITE_USERS_REQUESTED, handleInviteUsers)]);
}
