import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Photo from '../Photo';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getImageId,
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../image/selectors';
import {
    clearUploadedImage,
    imageUploadClearErrors,
    imageUploadRequested,
} from '../../image/actions';
import { noop } from '../utils';
import SnackbarAlert from '../SnackbarAlert';
import _uniqueId from 'lodash/uniqueId';

const PhotoUpload = ({
    communityId,
    requestImageUpload,
    uploadRequestPending,
    uploadErrors,
    uploadResponse,
    clearUploadResponse,
    onUpload,
    clearUploadErrors,
    uploadId,
    ...props
}) => {
    const [iconData, setIconData] = useState(null);
    const [id] = useState(_uniqueId('id-'));

    useEffect(() => {
        if (iconData != null) {
            requestImageUpload({ communityId, file: iconData, id });
        }
    }, [requestImageUpload, communityId, iconData, id]);

    useEffect(() => {
        if (
            !uploadRequestPending &&
            uploadErrors == null &&
            id === uploadId &&
            uploadResponse != null
        ) {
            onUpload(uploadResponse);
            clearUploadResponse();
        }
    }, [
        uploadRequestPending,
        uploadErrors,
        uploadResponse,
        onUpload,
        clearUploadResponse,
        id,
        uploadId,
    ]);

    return (
        <>
            <SnackbarAlert
                severity="error"
                messages={uploadErrors}
                clearMessages={clearUploadErrors}
            />
            <Photo setCroppedImageData={setIconData} {...props} />
        </>
    );
};

PhotoUpload.propTypes = {
    communityId: PropTypes.string.isRequired,
    requestImageUpload: PropTypes.func.isRequired,
    uploadRequestPending: PropTypes.bool.isRequired,
    uploadErrors: PropTypes.array,
    uploadResponse: PropTypes.array,
    onUpload: PropTypes.func,
    clearUploadErrors: PropTypes.func,
    clearUploadResponse: PropTypes.func.isRequired,
    uploadId: PropTypes.string.isRequired,
};

PhotoUpload.defaultProps = {
    uploadErrors: null,
    uploadResponse: null,
    onUpload: noop,
    clearUploadErrors: noop,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    uploadRequestPending: isImageUploadRequestPending(state),
    uploadErrors: getImageUploadErrors(state),
    uploadResponse: getImageUploadResponse(state),
    uploadId: getImageId(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestImageUpload: (request) => dispatch(imageUploadRequested(request)),
    clearUploadErrors: () => dispatch(imageUploadClearErrors()),
    clearUploadResponse: () => dispatch(clearUploadedImage()),
});

const ConnectedUpdateIcon = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PhotoUpload);
export default ConnectedUpdateIcon;
