import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    container: {
        maxWidth: '800px',
        border: `1px solid ${theme.custom.bgSeparator}`,
        boxShadow: `0 0 0.2px 0.2px ${theme.custom.bgSeparator}`,
        borderRadius: '5px',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '650px',
        },
    },
    actionsContainer: {
        padding: '7px',
        borderTop: `1px solid ${theme.custom.bgSeparator}`,
        backgroundColor: theme.custom.bgLightColor,
    },
    icon: {
        [theme.breakpoints.up('xs')]: {
            paddingRight: '4px',
            maxWidth: '650px',
        },
        fontSize: '24px',
    },
    commentContainer: {
        padding: '5px 10px',
        backgroundColor: theme.custom.bgLightAccentColor,
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '650px',
        },
        '& fieldset': {
            borderRadius: '20px',
        },
    },
    commentInput: {
        marginRight: '10px',
        backgroundColor: theme.custom.bgLightAccentColor,
        borderRadius: '20px',
        flex: 7,
    },
    commentPopper: {
        minWidth: 40,
    },
    input: {
        padding: '0px',
    },
    commentButton: {
        color: theme.palette.primary.main,
        flex: 1,
    },
    actionContainer: {
        flex: 1,
        cursor: 'pointer',
    },
    actionText: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));
