import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Input, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import useStyles from './styles';

const EmailInput = ({ removable, onRemove, ...props }) => {
    const { t } = useTranslation();
    const classes = useStyles({ removable });

    const inputClasses = useMemo(
        () => ({
            root: classes.root,
            input: classes.input,
        }),
        [classes],
    );

    return (
        <Grid container item direction="row" wrap="nowrap" alignItems="center">
            <Grid container item>
                <Input
                    classes={inputClasses}
                    placeholder={t('signUp.email')}
                    {...props}
                />
            </Grid>
            {removable ? (
                <Button onClick={onRemove} className={classes.removeButton}>
                    <Close />
                </Button>
            ) : null}
        </Grid>
    );
};

export const emailInputProps = {
    removable: PropTypes.bool,
    onRemove: PropTypes.func,
};

EmailInput.propTypes = emailInputProps;

EmailInput.defaultProps = {
    removable: false,
    onRemove: null,
};

export default EmailInput;
