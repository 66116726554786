import React, { useState } from 'react';
import clsx from 'clsx';
import { Tabs, Tab, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const TabbedSection = ({
    tabs,
    withFooter,
    variant,
    onTabChange,
    tabsProps,
    tabProps,
    tabBoxProps,
    footerBoxProps,
    footerPadding,
    selectedIndex,
}) => {
    const classes = useStyles({ footerPadding });

    const [selected, setSelected] = useState(selectedIndex);
    const tabLabels = Object.keys(tabs);
    const numTabs = tabLabels.length;

    const selectTabHandler = (e, newValue) => {
        setSelected(newValue);
        onTabChange && onTabChange(newValue);
    };

    return (
        <>
            <Tabs
                value={selected}
                classes={{
                    root: classes.fullWidth,
                    flexContainer: classes.fullWidth,
                }}
                onChange={selectTabHandler}
                textColor="primary"
                variant={variant}
                {...tabsProps}
            >
                {tabLabels.map((label, id) => {
                    return (
                        <Tab
                            key={label}
                            label={label}
                            value={id}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                            data-intercom-targe={`tab${id}`}
                            {...tabProps}
                        />
                    );
                })}
            </Tabs>
            {tabLabels.map((label, id) => {
                return selected === id ? (
                    <Box
                        key={id}
                        p={numTabs}
                        className={classes.body}
                        {...tabBoxProps}
                    >
                        {tabs[label]}
                    </Box>
                ) : null;
            })}
            {withFooter ? (
                <Box
                    p={numTabs}
                    className={clsx([classes.footer, classes.fullWidth])}
                    {...footerBoxProps}
                >
                    {withFooter}
                </Box>
            ) : null}
        </>
    );
};

TabbedSection.propTypes = {
    // variant to use for MUI tabs (e.g. standard, scrollable, or fullWidth)
    variant: PropTypes.string,

    // optionally provide content to always be present below the tab content
    withFooter: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),

    onTabChange: PropTypes.func,

    // the tabs, where the key is the tab label and the value is the tabbed content
    tabs: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]),
    ).isRequired,

    tabsProps: PropTypes.object,
    tabProps: PropTypes.object,
    tabBoxProps: PropTypes.object,
    footerBoxProps: PropTypes.object,
    footerPadding: PropTypes.string,
    selectedIndex: PropTypes.number,
};

TabbedSection.defaultProps = {
    variant: 'fullWidth',
    withFooter: false,
    onTabChange: null,
    tabsProps: {},
    tabProps: {},
    tabBoxProps: {},
    footerBoxProps: {},
    footerPadding: '24px',
    selectedIndex: 0,
};

export default TabbedSection;
