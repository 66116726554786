export default (theme) => ({
    filterLabel: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        padding: '0 10px',
        fontSize: '13px',
    },

    filterField: {
        width: '200px',
    },
    errors: {
        color: theme.palette.error.main,
        width: '100%',
        lineHeight: '14px',
        fontSize: '14px',
        padding: '15px 0',
        textAlign: 'right',
    },
    timezone: {
        fontSize: '13.5px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    timezoneContainer: {
        alignSelf: 'center',
        marginLeft: 8,
    },
});
