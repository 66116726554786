const styles = (theme) => ({
    titleContainer: {
        paddingBottom: '10px',
    },
    formTitle: {
        fontSize: '24px',
        fontWeight: '700',
    },
    editBtnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    editBtn: {
        height: 'fit-content',
        borderRadius: '5px',
        padding: '0px 10px',
    },
    editBtnIcon: {
        padding: '0 10px 0 0',
    },
    form: {
        width: '100%',
    },
    inputLabel: { color: theme.palette.primary.main },
    textarea: {
        width: '100%',
        padding: '10px 16px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '8px',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '18px',

        '&::placeholder': {
            color: theme.custom.textLightSecondaryColor,
        },

        '&:disabled': {
            color: theme.custom.textLightSecondaryColor,
            '&::placeholder': {
                color: theme.custom.grey88,
            },
        },

        '&:before, &:after': {
            display: 'none',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:active&:not(&:disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&:focus': {
            border: '1px solid',
            borderColor: theme.palette.primary.dark,
        },
        resize: 'none',
    },
    selectBox: { padding: '6px 16px' },
    fieldContainer: {
        paddingBottom: '10px',
    },
    accessContainer: {
        paddingTop: '6px',
    },
    websiteContainer: {
        width: '100%',
        paddingRight: '10px',
        [theme.breakpoints.down('sm')]: { paddingRight: 0 },
    },
    empCountContainer: {
        width: '100%',
    },
    photoLoaderContainer: {
        paddingRight: '25px',
        [theme.breakpoints.down('sm')]: { paddingRight: '16px' },
    },
    error: {
        color: theme.palette.error.main,
    },
    infoIcon: {
        marginLeft: '3px',
        color: theme.custom.tooltipIconColor,
        cursor: 'pointer',

        '&:hover': {
            color: theme.custom.tooltipIconHoverColor,
        },
    },
});

export default styles;
