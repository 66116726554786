import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { changeCommunityURL } from '../../../../utils';
import { useHistory } from 'react-router-dom';

export const CommunityConfirmInfo = ({ newCommunity, title }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Grid container item direction="column">
            <Grid item container alignItems="center" justifyContent="center">
                <Error className={classes.warningBannerIcon} />
                <Typography className={classes.warningBannerTitle}>
                    {title}
                </Typography>
            </Grid>
            <Typography className={classes.warningBannerBody}>
                {t('notifications.organizationWarningBanner.top')}
            </Typography>
            <Typography className={classes.warningBannerBody}>
                <Trans
                    i18nKey="notifications.organizationWarningBanner.bottom"
                    t={t}
                    values={{
                        communityName: newCommunity?.name,
                    }}
                >
                    <Typography
                        className={classes.communityLink}
                        onClick={() => {
                            changeCommunityURL(
                                history,
                                newCommunity?.id,
                                '/notifications',
                            );
                        }}
                    ></Typography>
                </Trans>
            </Typography>
        </Grid>
    );
};

CommunityConfirmInfo.propTypes = {
    newCommunity: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
    }),
    title: PropTypes.string,
};

CommunityConfirmInfo.defaultProps = {
    title: '',
    newCommunity: {},
};
