import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL } from './constants';

const primary = '#033333';
const secondary = '#019486';
const accent = '#E89A30';

const WILD_FOR_ALL_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'Wild For All',
    shouldShowSignUp: false,
    intercomConfig: {
        enabled: false,
    },
    cookieYesConfig: {
        scriptSrc:
            'https://cdn-cookieyes.com/client_data/0f3cb690981f2aba35a81a77/script.js',
    },
    clientId: '623c976b957b2a4a160e8226',
    clientSecret:
        '00yVqKOf0HkcI4f0UWq40odd3L1YHe5aGHUX9tZLeFWMhtY5qTepsiGYDMB7Jls4Fe',
    loginMethods: [LOGIN_METHOD_EMAIL],
    apiUri: process.env.REACT_APP_WFA_API_URI,
    wordpressDomain: 'https://wildforall.org',
    privacyLink: 'https://www.wildforall.org/wcs-privacy-policy',
    secondPrivacyLink: 'https://www.wildforall.org/cui-privacy-policy',
    impactLink:
        'http://wildforalldev.wpengine.com/wp-content/uploads/2022/04/Wild-for-All-impact-report-04-04-2022.pdf',
    allowPassphraseLessLogin: true,
    hasMarketingEmailOptIn: true,
    fontFamily: 'fauna',
    stringsNamespace: 'wildForAll',
    colors: {
        primary: primary,
        primaryDark: primary,
        primaryForeground: primary,
        secondary: secondary,
        secondaryDark: secondary,
        accent: accent,
        accentDark: accent,
        warn: primary,
        warnDark: primary,
        EditingBannerBackground: '#f26549',
        loginFooter: primary,
        impactTileColor: secondary,
    },
    supportedLanguageCodes: ['en', 'es'],
};

export default WILD_FOR_ALL_PROPERTIES;
