import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    useTheme,
    withStyles,
    useMediaQuery,
} from '@material-ui/core';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import {
    getChallengeEndDate,
    getChallengeErrors,
    getChallengeIconUrl,
    getChallengeInfo,
    getChallengeName,
    getChallengeStartDate,
    getChallengeTeamsInfo,
    getChallengeTemplateStatus,
    getHeadlinePhotoAuthor,
    getHeadlinePhotoUrl,
    isChallengeEditable,
    isChallengeLoading,
} from '../../challenges/selectors';
import { viewChallengeRequested } from '../../challenges/actions';
import IMAGES from '../../constants/images';
import ChallengeHeader from '../ViewChallengeScreen/ChallengeHeader';
import { Trans, useTranslation } from 'react-i18next';
import { dateDifferenceInDays } from '../../utils/dates';
import { StyledChallengeInfo as ChallengeInfo } from '../ViewChallengeScreen/ChallengeInfo';
import { Clear, Done, Telegram } from '@material-ui/icons';
import Button from '../../common/Button';
import InfoCard from '../../common/InfoCard';
import { getActiveCommunityId } from '../../user/selectors';
import ChallengeDescription from '../../common/ChallengeDescription';
import ChallengeDuration from '../../common/ChallengeDuration';
import { getAppImages, noop } from '../../utils';
import { clearSaveErrors, saveDraftRequested } from './actions';
import ChallengeHowToWin from '../../common/ChallengeHowToWin';
import ChallengeRules from '../../common/ChallengeRules';
import ChallengeName from '../../common/ChallengeName';
import UpdateCoverPhoto from '../../common/UpdateCoverPhoto';
import UpdateIcon from '../../common/UpdateIcon';
import UpdateTeam from './UpdateTeam';
import ConfirmModal from '../../common/ConfirmModal';
import PhotoPropTypes from '../../common/Photo/propTypes';
import {
    getPublishStatus,
    getSaveErrors,
    isSaveInProgress,
    isSaveSuccessful,
} from './selectors';
import EditingBanner from '../../common/EditingBanner';
import moment from 'moment';
import PrizeModal from '../ViewChallengeScreen/PrizeModal';
import { isImageUploadRequestPending } from '../../image/selectors';
import { transformUpdateChallengeRequest } from '../../challenges/transforms';
import { INFO_CARD_CHALLENGE_UPDATE } from '../../common/InfoCard/constants';
import { showInfoCard } from '../../user/actions';
import CommonPropTypes from '../../common/propTypes';
import { connect } from 'react-redux';
import styles from './styles';
import { CHALLENGE_TEMPLATE_STATUS_DRAFT } from '../../constants/challenges';
import { DRAFT_CHECKLIST_URL, INTERCOM_TARGET_PREFIX } from './constants';
import { initChallengeEditingTour } from '../../config/intercom';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import ChallengeActionsModal from '../ViewChallengeScreen/ChallengeActions/ChallengeActionsModal';
import { actionsRequested } from '../../actions/actions';
import { getActions } from '../../actions/selectors';
import { PIN_ADMIN_VISIBILITY_ALL_INCLUDED } from '../../actions/constants';
import { actionTablePropType } from '../../actions/propTypes';
import {
    BUTTON_ACCENT_COLOR,
    BUTTON_DEFAULT_COLOR,
} from '../../common/Button/constants';
import { themesRequested } from '../../communityThemes/actions';
import { getThemes } from '../../communityThemes/selectors';
import InfoTooltip from '../../common/InfoTooltip';
import ModalTitle from '../../common/ModalTitle';
import { trackEvent } from '../../utils/analytics';
import {
    EVENT_CHALLENGE_ACTION_ADDED,
    EVENT_CHALLENGE_ACTION_REMOVED,
} from '../../constants/analyticsEvents';

const Loader = () => (
    <Grid container justify="center" alignContent="center">
        <img src={IMAGES.loader} alt="loader" />
    </Grid>
);

const Errors = ({ errors }) => (
    <>
        {errors.map((err, i) => (
            <Typography key={i}>{err}</Typography>
        ))}
    </>
);

const UpdateChallengeScreen = ({
    classes,
    communityId,
    headlinePhotoUrl,
    headlinePhotoAuthor,
    iconUrl,
    name: pristineName,
    startDate: pristineStart,
    endDate: pristineEnd,
    info: pristineInfo,
    teamsInfo,
    templateStatus,
    isLoading,
    errors,
    loadFunc,
    saveFunc,
    PhotoProps,
    saveSuccessful,
    isImageUploading,
    showInfoCard,
    published,
    saveErrors,
    clearSnackBarErrors,
    fetchChallengeActionData,
    fetchThemes,
    themes,
    communityActions,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        params: { challengeId },
    } = useRouteMatch();

    const [triedLoading, setTriedLoading] = useState(false);

    const [pristine, setPristine] = useState(true);

    const [editDescriptionShown, setEditDescriptionShown] = useState(false);
    const [editDatesShown, setEditDatesShown] = useState(false);
    const [editHowToWinShown, setEditHowToWinShown] = useState(false);
    const [editRulesShown, setEditRulesShown] = useState(false);
    const [editNameShown, setEditNameShown] = useState(false);
    const [editPhotoShown, setEditPhotoShown] = useState(false);
    const [editIconShown, setEditIconShown] = useState(false);
    const [editTeamShown, setEditTeamShown] = useState(false);

    const [leaveConfirmationShown, setLeaveConfirmationShown] = useState(false);
    const [leaveLocation, setLeaveLocation] = useState(null);
    const [leaveConfirmed, setLeaveConfirmed] = useState(false);
    const [editActionsShown, setEditActionsShown] = useState(false);

    useEffect(() => {
        if (leaveConfirmed) {
            history.push(leaveLocation);
        }
    }, [history, leaveConfirmed, leaveLocation]);

    const [startDate, setStartDate] = useState(pristineStart);
    const [endDate, setEndDate] = useState(pristineEnd);
    const [info, setInfo] = useState(pristineInfo);
    const [name, setName] = useState(pristineName);
    const [image, setImage] = useState(null);
    const [headline, setHeadline] = useState(headlinePhotoUrl);
    const [author, setAuthor] = useState(headlinePhotoAuthor);
    const [iconData, setIconData] = useState(null);
    const [icon, setIcon] = useState(iconUrl);
    const [teams, setTeams] = useState(teamsInfo);
    const [team, setTeam] = useState(null);
    const [isOpenModalReset, setIsOpenModalReset] = useState(false);
    const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
    const [isPrizeModalOpen, setPrizeModalOpen] = useState(false);
    const [selectedPrize, setSelectedPrize] = useState('');
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const [fetchedThemes, setFetchedThemes] = useState(false);

    const teamItems = useMemo(
        () =>
            teams
                ?.sort((t1, t2) => t1.name.localeCompare(t2.name))
                .map((team) => ({
                    id: team.id,
                    label: team.name,
                    value: '',
                    image: team.imageSizes?.[0]?.url || team.image,
                })),
        [teams],
    );

    const handleEditTeam = useCallback(
        (id) => {
            setTeam(teams.find((team) => team.id === id));
            setEditTeamShown(true);
        },
        [setTeam, teams, setEditTeamShown],
    );

    useEffect(() => {
        if (!fetchedThemes) {
            fetchThemes(communityId);
            setFetchedThemes(true);
        }
    }, [fetchThemes, communityId, fetchedThemes]);

    useEffect(() => {
        setStartDate(pristineStart);
        setEndDate(pristineEnd);
        setInfo(pristineInfo);
        setName(pristineName);
        setHeadline(headlinePhotoUrl);
        setAuthor(headlinePhotoAuthor);
        setIcon(iconUrl);
        setTeams(teamsInfo);
    }, [
        pristineStart,
        pristineEnd,
        pristineInfo,
        pristineName,
        headlinePhotoUrl,
        headlinePhotoAuthor,
        iconUrl,
        teamsInfo,
    ]);

    const save0 = (publish) => {
        const body = transformUpdateChallengeRequest(
            image,
            author,
            iconData,
            info,
            startDate,
            endDate,
            name,
            teams,
        );
        if (publish != null) {
            body.publish = publish;
        } else {
            body.status = templateStatus;
        }
        saveFunc({
            challengeId,
            communityId,
            body,
            startDate,
            endDate,
        });
    };

    const saveDraft = () => save0(false);
    const savePublish = () => save0(true);

    const fetchChallengeActionsTableData = useCallback(
        (args) => {
            fetchChallengeActionData(communityId, {
                ...args,
                challengeIds: [challengeId],
            });
        },
        [challengeId, communityId, fetchChallengeActionData],
    );

    const fetchCommunityActionsTableData = useCallback(
        (args) => {
            fetchChallengeActionData(communityId, {
                ...args,
                excludedPinIds: info?.includeActionIds,
                visibility: PIN_ADMIN_VISIBILITY_ALL_INCLUDED,
            });
        },
        [communityId, fetchChallengeActionData, info.includeActionIds],
    );

    useEffect(() => {
        loadFunc({ challengeId, communityId });
        setTriedLoading(true);
    }, [loadFunc, communityId, challengeId, setTriedLoading]);

    useEffect(() => {
        if (saveSuccessful) {
            if (published) setSnackBarMessage(t('challenge.save.publish'));
            else setSnackBarMessage(t('challenge.save.update'));
        }
    }, [saveSuccessful, published, t]);

    const handleResetData = () => {
        setStartDate(pristineStart);
        setEndDate(pristineEnd);
        setInfo(pristineInfo);
        setName(pristineName);
        setImage(null);
        setAuthor(headlinePhotoAuthor);
        setHeadline(headlinePhotoUrl);
        setIconData(null);
        setIcon(iconUrl);
        setPristine(true);
        setIsOpenModalReset(false);
    };

    const isDraft = templateStatus === CHALLENGE_TEMPLATE_STATUS_DRAFT;

    const noActions = info.actions?.data?.length < 1;
    const publishDisabled = endDate < moment() || noActions;

    return (
        <Grid className={classes.container}>
            {isLoading && <Loader />}
            {errors && <Errors errors={errors} />}
            {triedLoading && !isLoading && !errors && name && (
                <>
                    <Prompt
                        when={isDraft || !pristine}
                        message={(nextLocation) => {
                            if (!leaveConfirmed) {
                                setLeaveLocation(nextLocation);
                                setLeaveConfirmationShown(true);
                                return false;
                            }
                            return true;
                        }}
                    />
                    {(!pristine || isDraft) && (
                        <ConfirmModal
                            title={
                                !pristine
                                    ? t('unsavedTitle')
                                    : t('challenge.unpublished')
                            }
                            confirmBtnText={t('leave')}
                            isOpen={leaveConfirmationShown}
                            setOpen={setLeaveConfirmationShown}
                            confirmBtnType={BUTTON_ACCENT_COLOR}
                            cancelBtnType={BUTTON_DEFAULT_COLOR}
                            onConfirm={() => {
                                setLeaveConfirmed(true);
                            }}
                            onClose={() => {
                                setLeaveConfirmationShown(false);
                            }}
                            onCancel={() => {
                                setLeaveConfirmationShown(false);
                            }}
                        >
                            {!pristine ? (
                                t('unsavedWarning')
                            ) : (
                                <div className={classes.textContainer}>
                                    <Typography
                                        className={classes.confirmationText}
                                    >
                                        {t('challenge.draft')}
                                    </Typography>
                                    <Typography className={classes.infoText}>
                                        {t('challenge.draftInfo')}
                                    </Typography>
                                </div>
                            )}
                        </ConfirmModal>
                    )}
                    <ChallengeDescription
                        isOpen={editDescriptionShown}
                        setOpen={setEditDescriptionShown}
                        onClose={noop}
                        onCancel={noop}
                        onConfirm={(newDescription) => {
                            setInfo({
                                ...info,
                                description: newDescription,
                            });
                            setPristine(false);
                            setEditDescriptionShown(false);
                        }}
                        description={info.description}
                    />
                    <ChallengeDuration
                        challengeStatus={templateStatus}
                        isOpen={editDatesShown}
                        setOpen={setEditDatesShown}
                        onClose={noop}
                        onCancel={noop}
                        onConfirm={({
                            startDate: newStartDate,
                            endDate: newEndDate,
                        }) => {
                            setStartDate(newStartDate.toDate());
                            setEndDate(newEndDate.toDate());
                            setPristine(false);
                            setEditDatesShown(false);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <ChallengeHowToWin
                        isOpen={editHowToWinShown}
                        setOpen={setEditHowToWinShown}
                        text={info.howToWin}
                        onConfirm={(newHowToWin) => {
                            setInfo({
                                ...info,
                                howToWin: newHowToWin,
                            });
                            setPristine(false);
                            setEditHowToWinShown(false);
                        }}
                    />
                    <ChallengeRules
                        isOpen={editRulesShown}
                        setOpen={setEditRulesShown}
                        text={info.rules}
                        onConfirm={(newRules) => {
                            setInfo({
                                ...info,
                                rules: newRules,
                            });
                            setPristine(false);
                            setEditRulesShown(false);
                        }}
                    />
                    {editNameShown ? (
                        <ChallengeName
                            isOpen={editNameShown}
                            setOpen={setEditNameShown}
                            name={name}
                            changeNameFunc={(newName) => {
                                setName(newName);
                                setPristine(false);
                                setEditNameShown(false);
                            }}
                        />
                    ) : null}
                    <ChallengeHeader
                        challengeId={challengeId}
                        headlinePhotoUrl={headline}
                        iconUrl={icon}
                        name={name}
                        startDate={startDate}
                        endDate={endDate}
                        action="edit"
                        onChangeName={() => setEditNameShown(true)}
                        onChangePhoto={() => setEditPhotoShown(true)}
                        onChangeIcon={() => setEditIconShown(true)}
                        challengeStatus={templateStatus}
                    />
                    <UpdateCoverPhoto
                        isOpen={editPhotoShown}
                        setOpen={setEditPhotoShown}
                        author={author}
                        photo={headline}
                        onConfirm={({
                            author: newAuthor,
                            image: newImage = [],
                        }) => {
                            setImage(newImage);
                            setAuthor(newAuthor);
                            if (newImage && newImage.length) {
                                setHeadline(newImage[0].file.url);
                            }
                            setPristine(false);
                            setEditPhotoShown(false);
                        }}
                        PhotoProps={PhotoProps}
                    />
                    <UpdateIcon
                        isOpen={editIconShown}
                        setOpen={setEditIconShown}
                        icon={icon}
                        onConfirm={(newIconData) => {
                            setIconData(newIconData);
                            setIcon(newIconData[0].file.url);
                            setPristine(false);
                            setEditIconShown(false);
                        }}
                    />
                    <UpdateTeam
                        isOpen={editTeamShown}
                        setOpen={setEditTeamShown}
                        name={team?.name || ''}
                        headline={team?.headline || ''}
                        photo={team?.image || getAppImages().defaultProfileIcon}
                        isImageUploading={isImageUploading}
                        onConfirm={(newTeam) => {
                            const id = team.id;
                            const teamIndex = teams.findIndex(
                                (team) => team.id === id,
                            );
                            setTeams([
                                ...teams.slice(0, teamIndex),
                                {
                                    ...newTeam,
                                    id: team.id,
                                    imageSizes: newTeam.updateImage?.sizes?.map(
                                        (size) => ({ url: size.file.url }),
                                    ),
                                },
                                ...teams.slice(teamIndex + 1),
                            ]);
                            setPristine(false);
                            setEditTeamShown(false);
                        }}
                    />
                    <Grid
                        container
                        justify="center"
                        direction="column"
                        alignContent="center"
                    >
                        <InfoCard
                            hideCard={
                                templateStatus !==
                                CHALLENGE_TEMPLATE_STATUS_DRAFT
                            }
                            infoCardId={`${INFO_CARD_CHALLENGE_UPDATE}.${challengeId}`}
                            image={getAppImages().welcomeEdit}
                            style={{
                                marginTop: 26,
                                alignSelf: 'center',
                                margin: isSmallScreen ? 16 : 'initial',
                            }}
                            cardActions={
                                isSmallScreen ? (
                                    <Grid
                                        container
                                        direction="column"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Button
                                                style={{
                                                    marginTop: isSmallScreen
                                                        ? 16
                                                        : 'initial',
                                                }}
                                                key="intercomChallengeEditTour"
                                                color="white"
                                                fullWidth
                                                className={classes.button}
                                                textColor={
                                                    theme.palette.primary.main
                                                }
                                                onClick={() => {
                                                    initChallengeEditingTour();
                                                }}
                                            >
                                                {t('take a tour')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                key="checklistDownload"
                                                color="white"
                                                fullWidth
                                                className={classes.button}
                                                textColor={
                                                    theme.palette.primary.main
                                                }
                                                onClick={() => {
                                                    window.open(
                                                        DRAFT_CHECKLIST_URL,
                                                        '_blank',
                                                    );
                                                }}
                                            >
                                                {t('download')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    [
                                        <Button
                                            key="intercomChallengeEditTour"
                                            color="white"
                                            style={{ maxWidth: '20%' }}
                                            className={classes.button}
                                            textColor={
                                                theme.palette.primary.main
                                            }
                                            onClick={() => {
                                                initChallengeEditingTour();
                                            }}
                                        >
                                            {t('take a tour')}
                                        </Button>,
                                        <Button
                                            key="checklistDownload"
                                            color="white"
                                            style={{ maxWidth: '20%' }}
                                            className={classes.button}
                                            textColor={
                                                theme.palette.primary.main
                                            }
                                            onClick={() => {
                                                window.open(
                                                    DRAFT_CHECKLIST_URL,
                                                    '_blank',
                                                );
                                            }}
                                        >
                                            {t('download')}
                                        </Button>,
                                    ]
                                )
                            }
                        >
                            <Typography variant="h5" gutterBottom>
                                <Trans i18nKey="challenge.helpDraft.title">
                                    <b />
                                </Trans>
                            </Typography>
                            <Trans i18nKey="challenge.helpDraft.description">
                                <b />
                            </Trans>
                        </InfoCard>
                        <Grid
                            container
                            direction="column"
                            item
                            className={classes.main}
                        >
                            <EditingBanner />
                            <Grid
                                container
                                item
                                direction={isSmallScreen ? 'column' : 'row'}
                                wrap="nowrap"
                                alignItems="center"
                                className={classes.controls}
                                spacing={2}
                            >
                                <Grid item>
                                    {templateStatus !==
                                    CHALLENGE_TEMPLATE_STATUS_DRAFT ? (
                                        <InfoTooltip
                                            text={t('Help')}
                                            intercomTarget={`${INTERCOM_TARGET_PREFIX}Discard`}
                                            onClick={() =>
                                                showInfoCard(
                                                    INFO_CARD_CHALLENGE_UPDATE,
                                                )
                                            }
                                        />
                                    ) : (
                                        <ModalTitle
                                            text=""
                                            tooltipText={t('viewDocs')}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    container
                                    item
                                    className={classes.buttonContainer}
                                >
                                    <Button
                                        color="danger"
                                        variant="outlined"
                                        className={classes.buttonDiscard}
                                        disabled={pristine}
                                        onClick={() =>
                                            setIsOpenModalReset(true)
                                        }
                                        data-intercom-target={`${INTERCOM_TARGET_PREFIX}DiscardButton`}
                                    >
                                        <Clear
                                            fontSize="large"
                                            className={classes.buttonIcon}
                                        />
                                        {t('Discard Changes')}
                                    </Button>
                                </Grid>
                                {templateStatus ===
                                CHALLENGE_TEMPLATE_STATUS_DRAFT ? (
                                    <>
                                        <Grid
                                            container
                                            item
                                            className={classes.buttonContainer}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className={
                                                    classes.buttonOutlined
                                                }
                                                disabled={
                                                    pristine ||
                                                    endDate < moment()
                                                }
                                                onClick={() => {
                                                    saveDraft();
                                                    setPristine(true);
                                                }}
                                                data-intercom-target={`${INTERCOM_TARGET_PREFIX}SaveDraftButton`}
                                            >
                                                <Done
                                                    fontSize="large"
                                                    className={
                                                        classes.buttonIcon
                                                    }
                                                />
                                                {t('Save Draft')}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            className={classes.buttonContainer}
                                            data-intercom-target={
                                                `${INTERCOM_TARGET_PREFIX}PublishButton` +
                                                (publishDisabled
                                                    ? 'Disabled'
                                                    : '')
                                            }
                                        >
                                            <Button
                                                color="accent"
                                                disabled={publishDisabled}
                                                className={classes.button}
                                                onClick={() => {
                                                    savePublish();
                                                    setPristine(true);
                                                }}
                                            >
                                                <Telegram
                                                    fontSize="large"
                                                    className={
                                                        classes.buttonIcon
                                                    }
                                                />
                                                {t('Save & Publish')}
                                            </Button>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid
                                        container
                                        item
                                        className={classes.buttonContainer}
                                    >
                                        <Button
                                            disabled={pristine || noActions}
                                            color="accent"
                                            className={classes.button}
                                            onClick={() =>
                                                setShowSaveConfirmModal(true)
                                            }
                                        >
                                            <Telegram
                                                fontSize="large"
                                                className={classes.buttonIcon}
                                            />
                                            {t('Save')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                            <ChallengeInfo
                                duration={dateDifferenceInDays(
                                    startDate,
                                    endDate,
                                )}
                                startDate={startDate}
                                endDate={endDate}
                                info={info}
                                teamItems={teamItems}
                                onEditDescription={() =>
                                    setEditDescriptionShown(true)
                                }
                                onEditDates={() => setEditDatesShown(true)}
                                onEditHowToWin={() =>
                                    setEditHowToWinShown(true)
                                }
                                onEditRules={() => setEditRulesShown(true)}
                                onEditTeam={handleEditTeam}
                                onEditPrizes={(id) => {
                                    setPrizeModalOpen(true);
                                    setSelectedPrize(id);
                                }}
                                onDeletePrizes={(id) => {
                                    setInfo({
                                        ...info,
                                        prizes: info.prizes.filter(
                                            (prize) => prize.id !== id,
                                        ),
                                    });
                                    setPristine(false);
                                }}
                                onToggleAutoJoin={(toggled) => {
                                    setInfo({ ...info, autoJoin: toggled });
                                    setPristine(false);
                                }}
                                onToggleChallengeCategory={(toggled) => {
                                    setInfo({
                                        ...info,
                                        makeChallengeCategory: toggled,
                                    });
                                    setPristine(false);
                                }}
                                onEditActions={() => {
                                    setEditActionsShown(true);
                                }}
                                action="edit"
                                t={t}
                                fetchChallengeActionData={
                                    fetchChallengeActionsTableData
                                }
                                onRemoveAction={(action) => {
                                    trackEvent(EVENT_CHALLENGE_ACTION_REMOVED, {
                                        action_id: action?.id,
                                    });
                                    setPristine(false);
                                    setInfo({
                                        ...info,
                                        actions: {
                                            ...info.actions,
                                            data: info.actions.data.filter(
                                                (data) => data.id !== action.id,
                                            ),
                                        },
                                        excludeActionIds: [
                                            ...info.excludeActionIds,
                                            action?.id,
                                        ],
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                    <ConfirmModal
                        isOpen={isOpenModalReset}
                        title={t('confirmReset')}
                        onClose={() => setIsOpenModalReset(false)}
                        onCancel={() => setIsOpenModalReset(false)}
                        onConfirm={handleResetData}
                        cancelBtnType="default"
                        cancelBtnText={t('cancel')}
                        confirmBtnType="primary"
                        confirmBtnText={t('confirm')}
                    >
                        {t('confirmDiscard')}
                    </ConfirmModal>
                    <ConfirmModal
                        isOpen={showSaveConfirmModal}
                        title={t('challenge.save.title')}
                        onClose={() => {
                            setShowSaveConfirmModal(false);
                        }}
                        onCancel={() => {
                            setShowSaveConfirmModal(false);
                        }}
                        onConfirm={() => {
                            setShowSaveConfirmModal(false);
                            save0();
                            setPristine(true);
                        }}
                        cancelBtnType="default"
                        cancelBtnText={t('cancel')}
                        confirmBtnType="primary"
                        confirmBtnText={t('save')}
                    >
                        <Typography>
                            {t('challenge.save.message.beginning')}
                            <b>{t('challenge.save.message.middle')}</b>
                            {t('challenge.save.message.end')}
                        </Typography>
                    </ConfirmModal>
                    <PrizeModal
                        isOpen={isPrizeModalOpen}
                        onCancel={() => setPrizeModalOpen(false)}
                        onClose={() => setPrizeModalOpen(false)}
                        onConfirm={(prizes) => {
                            setPrizeModalOpen(false);
                            setInfo({
                                ...info,
                                prizes,
                            });
                            setPristine(false);
                        }}
                        prizes={info.prizes}
                        id={selectedPrize}
                        isTeamChallenge={info.unit === 'Team'}
                        challengeId={challengeId}
                    />
                    <ChallengeActionsModal
                        isOpen={editActionsShown}
                        onConfirm={() => setEditActionsShown(false)}
                        onClose={() => setEditActionsShown(false)}
                        onCancel={() => setEditActionsShown(false)}
                        fetchActions={fetchCommunityActionsTableData}
                        themes={themes}
                        onAddAction={(action) => {
                            trackEvent(EVENT_CHALLENGE_ACTION_ADDED, {
                                action_id: action?.id,
                            });
                            setPristine(false);
                            setInfo({
                                ...info,
                                actions: {
                                    ...info.actions,
                                    data: [action, ...info.actions.data],
                                },
                                includeActionIds: [
                                    ...info.includeActionIds,
                                    action?.id,
                                ],
                            });
                        }}
                        actions={{
                            ...communityActions,
                            data: communityActions.data?.filter(
                                (action) =>
                                    !info.includeActionIds.includes(action.id),
                            ),
                        }}
                    />
                </>
            )}
            <SnackBarAPIResponse
                messages={snackBarMessage}
                errors={saveErrors}
                clearResponse={() => {
                    setSnackBarMessage(null);
                    clearSnackBarErrors();
                }}
            />
        </Grid>
    );
};

UpdateChallengeScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    fetchChallengeActionData: PropTypes.func.isRequired,
    headlinePhotoUrl: PropTypes.string,
    headlinePhotoAuthor: PropTypes.string,
    iconUrl: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    templateStatus: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    info: PropTypes.object,
    teamsInfo: PropTypes.array,
    loadFunc: PropTypes.func.isRequired,
    saveFunc: PropTypes.func.isRequired,
    PhotoProps: PhotoPropTypes.photo,
    saveSuccessful: PropTypes.bool,
    // Is the team image updating?
    isImageUploading: PropTypes.bool.isRequired,
    showInfoCard: PropTypes.func.isRequired,
    published: PropTypes.bool,
    saveErrors: PropTypes.arrayOf(PropTypes.string),
    clearSnackBarErrors: PropTypes.func.isRequired,
    themes: PropTypes.array,
    fetchThemes: PropTypes.func,
    communityActions: actionTablePropType.isRequired,
};

UpdateChallengeScreen.defaultProps = {
    headlinePhotoUrl: null,
    headlinePhotoAuthor: null,
    iconUrl: null,
    name: null,
    startDate: null,
    endDate: null,
    info: null,
    teamsInfo: null,
    templateStatus: null,
    errors: null,
    PhotoProps: {
        photoTooltipKey: 'coverPhoto.imageHelp',
    },
    saveSuccessful: false,
    published: false,
    fetchThemes: noop,
    themes: [],
    saveErrors: null,
};

const StyledUpdateChallengeScreen = withStyles(styles)(UpdateChallengeScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isEditable: isChallengeEditable(state),
    isLoading: isChallengeLoading(state) || isSaveInProgress(state),
    errors: getChallengeErrors(state),
    headlinePhotoUrl: getHeadlinePhotoUrl(state),
    headlinePhotoAuthor: getHeadlinePhotoAuthor(state),
    iconUrl: getChallengeIconUrl(state),
    name: getChallengeName(state),
    startDate: getChallengeStartDate(state),
    endDate: getChallengeEndDate(state),
    info: getChallengeInfo(state),
    teamsInfo: getChallengeTeamsInfo(state),
    templateStatus: getChallengeTemplateStatus(state),
    saveSuccessful: isSaveSuccessful(state),
    isImageUploading: isImageUploadRequestPending(state),
    published: getPublishStatus(state),
    themes: getThemes(state),
    communityActions: getActions(state, PIN_ADMIN_VISIBILITY_ALL_INCLUDED),
    saveErrors: getSaveErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadFunc: ({ challengeId, communityId }) =>
        dispatch(viewChallengeRequested({ challengeId, communityId })),
    saveFunc: ({ challengeId, communityId, body, startDate, endDate }) =>
        dispatch(
            saveDraftRequested({
                challengeId,
                communityId,
                body,
                startDate,
                endDate,
            }),
        ),
    fetchChallengeActionData: (communityId, filters) =>
        dispatch(actionsRequested(communityId, filters)),
    showInfoCard: (cardId) => dispatch(showInfoCard(cardId)),
    clearSnackBarErrors: () => dispatch(clearSaveErrors()),
    fetchThemes: (communityId) => dispatch(themesRequested(communityId, true)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledUpdateChallengeScreen);
