import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../common/errors';

export const getImageState = (state) => state.image;

export const isImageUploadRequestPending = createSelector(
    getImageState,
    (imageState) => imageState.isImageUploadRequestPending,
);

export const getImageUploadErrors = createSelector(
    getImageState,
    (imageState) => coerceIntoErrors(imageState.imageUploadErrors),
);

export const getImageUploadResponse = createSelector(
    getImageState,
    (imageState) => imageState.imageUploadResponse,
);

export const getImageId = createSelector(
    getImageState,
    (imageState) => imageState.uploadId,
);
