import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import useStyles from './styles';

const LogActionItem = ({ name, description, points, cheatingAction }) => {
    const classes = useStyles({ cheatingAction });
    return (
        <>
            <hr width="100%" className={classes.divider} />
            <Grid
                container
                item
                direction="row"
                wrap="nowrap"
                justify="space-between"
                alignItems="center"
                className={classes.container}
            >
                <Grid
                    container
                    item
                    direction="column"
                    className={classes.info}
                >
                    <Grid item className={classes.name}>
                        <strong>{name}</strong>
                    </Grid>
                    <Grid item className={classes.description}>
                        {cheatingAction ? (
                            <div className={classes.cheatMessage}>
                                {description}
                            </div>
                        ) : (
                            description
                        )}
                    </Grid>
                </Grid>
                {!cheatingAction && (
                    <Grid item className={classes.points}>
                        {points}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

LogActionItem.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    points: PropTypes.number,
    cheatingAction: PropTypes.bool,
};

LogActionItem.defaultProps = {
    points: null,
    cheatingAction: false,
};

export default LogActionItem;
