import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import { Link } from 'react-router-dom';

import styles from './styles';
import {
    getActionPins,
    getActionTabNames,
    getLastVisitedActionCategoryIndex,
    getSearchablePins,
    isActionCategoriesLoading,
} from '../../../actions/selectors';
import {
    actionCategoriesRequested,
    setAction,
    setLastVisitedActionCategoryIndex,
} from '../../../actions/actions';
import ActionTabMenu from '../../../common/ActionTabMenu';
import SearchBar from '../../../common/Search';
import { getAppImages, getAppName, noop } from '../../../utils';
import InfoCardHeader from '../../../common/InfoCardHeader';
import { INFO_CARD_USER_ACTIONS } from '../../../common/InfoCard/constants';

export const UserActionsScreen = ({
    classes,
    isLoading,
    loadActionCategoriesFunction,
    actionTabNames,
    actionPins,
    searchablePins,
    setAction,
    lastVisitedTabIndex,
    setLastVisitedTabIndex,
}) => {
    const { t } = useTranslation();

    const [filteredItems, setSetFilteredItems] = useState([]);

    useEffect(() => {
        loadActionCategoriesFunction({});
    }, [loadActionCategoriesFunction]);

    const searchPins = useCallback(
        (search, data = []) => {
            if (!data) return [];
            const filterList = search
                ? filter(
                      data,
                      ({ name, description }) =>
                          name.toLowerCase().includes(search.toLowerCase()) ||
                          description
                              .toLowerCase()
                              .includes(search.toLowerCase()),
                  )
                : data;
            if (filterList.length > 0) {
                setSetFilteredItems(filterList);
            }
        },
        [setSetFilteredItems],
    );

    const onSearch = (text) => {
        if (!text) {
            setSetFilteredItems([]);
            return;
        }
        searchPins(text, searchablePins);
    };

    const handleSearchItemClick = () => {};

    return (
        <Grid container className={classes.container}>
            <Grid container className={classes.header}>
                <InfoCardHeader
                    infoCardId={INFO_CARD_USER_ACTIONS}
                    image={getAppImages().welcomeActions}
                    pageTitle={t('action.descriptor_plural')}
                >
                    <Typography variant="h2" className={classes.bannerTitle}>
                        {t('actionPage.bannerTitle', {
                            appName: getAppName(),
                        })}
                    </Typography>
                    <Typography className={classes.bannerBody}>
                        {t('actionPage.bannerBody')}
                        <br />
                        {t('actionPage.bannerBody2')}
                    </Typography>
                </InfoCardHeader>
                <Grid item xs={12} className={classes.searchContainer}>
                    <SearchBar
                        placeholder={t('appContent.actions.search')}
                        handleTextChange={onSearch}
                        runSearch={onSearch}
                    />
                    {filteredItems?.length > 0 && (
                        <Paper>
                            <MenuList
                                className={classes.searchList}
                                autoFocus={false}
                            >
                                {filteredItems?.map((item) => (
                                    <MenuItem key={item.id}>
                                        <Link
                                            to={`/actions/${item.id}`}
                                            onClick={handleSearchItemClick}
                                        >
                                            <img src={item.iconUrl} alt="" />
                                            <Typography
                                                className={classes.backText}
                                            >
                                                {item.name}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Paper>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {!isLoading ? (
                    <div>
                        {actionTabNames ? (
                            <ActionTabMenu
                                tabs={actionTabNames}
                                allActionCards={actionPins}
                                onCardClick={setAction}
                                lastVisitedTabIndex={lastVisitedTabIndex}
                                tabAction={setLastVisitedTabIndex}
                            />
                        ) : (
                            <div className={classes.emptyTextContainer}>
                                <Typography className={classes.emptyText}>
                                    {t('data.empty')}
                                </Typography>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={classes.emptyTextContainer}>
                        <Typography className={classes.emptyText}>
                            {t('loading.descriptor')}
                        </Typography>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

UserActionsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    loadActionCategoriesFunction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    actionTabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    actionPins: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchablePins: PropTypes.arrayOf(PropTypes.object).isRequired,
    setAction: PropTypes.func.isRequired,
    lastVisitedTabIndex: PropTypes.number.isRequired,
    setLastVisitedTabIndex: PropTypes.func.isRequired,
};

UserActionsScreen.defaultProps = {
    loadActionCategoriesFunction: noop,
    isLoading: false,
    actionCategories: null,
    actionPins: [],
    searchablePins: [],
    lastVisitedTabIndex: 1,
};

const StyledUserHomeScreen = withStyles(styles)(UserActionsScreen);

const mapStateToProps = (state) => ({
    isLoading: isActionCategoriesLoading(state),
    searchablePins: getSearchablePins(state),
    actionTabNames: getActionTabNames(state),
    actionPins: getActionPins(state),
    lastVisitedTabIndex: getLastVisitedActionCategoryIndex(state),
});

const mapDispatchToProps = (dispatch) => ({
    setAction: (action) => dispatch(setAction(action?.id)),
    setLastVisitedTabIndex: (index) =>
        dispatch(setLastVisitedActionCategoryIndex(index)),
    loadActionCategoriesFunction: (request) =>
        dispatch(actionCategoriesRequested(request)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledUserHomeScreen);
