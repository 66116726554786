const styles = (theme) => {
    const commonDrawer = {
        marginTop: theme.custom.headerHeight,
    };
    return {
        content: {
            backgroundColor: theme.custom.bgColor,
            minHeight: '100vh',
        },
        mainHeader: {
            padding: '10px 34px 20px 34px',
        },
        openDrawer: {
            ...commonDrawer,
            [theme.breakpoints.between('xs', 'sm')]: {
                marginLeft: 0,
            },
            [theme.breakpoints.between('sm', 'md')]: {
                marginLeft: '70px',
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: '240px',
            },
        },
        hideDrawer: {
            ...commonDrawer,
            marginLeft: '70px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
            },
        },
        footer: {
            padding: '100px 0 16px 0',
            backgroundColor: theme.custom.bgColor,
        },
        navOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'black',
            zIndex: '1199',
            opacity: '0.8',
        },
    };
};

export default styles;
