import { createSelector } from 'reselect';

import { getChallenge as getRawChallenge } from '../../challenges/selectors';

export const getChallenge = createSelector(getRawChallenge, (rawChallenge) =>
    rawChallenge != null
        ? {
              name: rawChallenge.name,
              startDate: rawChallenge.startDate,
              endDate: rawChallenge.endDate,
          }
        : null,
);
