import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import BackgroundHeader from '../../../common/BackgroundHeader';
import styles from './styles';
import { noop } from '../../../utils';
import {
    ACTIONS,
    EDIT_ACTION,
} from '../../ActionsScreen/AdminActionsScreen/constants';

const ActionHeader = ({
    classes,
    actionId,
    communityId,
    headlineUrl,
    iconUrl,
    name,
    allowReset,
    onReset,
    onChangePhoto,
}) => {
    return (
        <>
            <BackgroundHeader
                photoUrl={headlineUrl}
                iconUrl={iconUrl}
                name={name}
                action={EDIT_ACTION}
                subject={ACTIONS}
                allowReset={allowReset}
                onReset={onReset}
                onChangePhoto={onChangePhoto}
                intercomTargetPrefix="Action"
            />
        </>
    );
};

ActionHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    actionId: PropTypes.string.isRequired,
    communityId: PropTypes.string.isRequired,
    headlineUrl: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    allowReset: PropTypes.bool,
    onReset: PropTypes.func,
    onChangePhoto: PropTypes.func.isRequired,
};

ActionHeader.defaultProps = {
    allowReset: false,
    onReset: noop,
};

export default withStyles(styles)(ActionHeader);
