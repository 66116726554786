const styles = (theme) => ({
    container: {
        padding: '0px',
    },
    header: {
        marginTop: '40px',
        marginBottom: '20px',
    },
    headerName: {
        fontSize: '36px',
        fontWeight: 600,
    },
    helpIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '5px',
        fontSize: '65%',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    divider: {
        width: '100%',
        height: '1px',
        border: 'none',
        backgroundColor: theme.custom.bgDarkHover,
    },
    challengeIcon: {
        borderColor: '#fff',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '6px 6px 50% 50%',
        position: 'relative',
        zIndex: '0',
    },
    challengeTitle: {
        fontWeight: 700,
    },
    table: {
        marginTop: '40px',
    },
    newDateCell: {
        backgroundColor: 'red',
    },
    centerColumn: {
        width: '100%',
        textAlign: 'center',
    },
    icon: {
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        zIndex: '1',
        opacity: 1,
        background: '#fff',
        height: '18px',
        width: '18px',
        borderColor: '#d3d3d3',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '50%',
    },
    tooltip: {
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        height: '18px',
        width: '18px',
        backgroundColor: 'transparent',
    },
});

export default styles;
