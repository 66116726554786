import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tab, Tabs, withStyles } from '@material-ui/core';
import styles from './styles';
import TabPanel from './TabPanel';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { noop } from '../utils';

const ActionTabMenu = ({
    classes,
    tabs,
    allActionCards,
    onCardClick,
    lastVisitedTabIndex,
    tabAction,
}) => {
    const [value, setValue] = useState(lastVisitedTabIndex);

    const handleChange = (event, newValue) => {
        tabAction(newValue);
        setValue(newValue);
    };
    const { t } = useTranslation();

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    return (
        <>
            <AppBar position="static" elevation={0} className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label={t('actionPage.categories')}
                    variant="scrollable"
                    scrollButtons="auto"
                    data-test="tabs"
                >
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab}
                            {...a11yProps(index)}
                            className={classes.tab}
                        />
                    ))}
                </Tabs>
            </AppBar>
            <SwipeableViews
                index={value}
                onChangeIndex={(value) => handleChange('', value)}
            >
                {allActionCards.map((actionCards, index) => (
                    <TabPanel
                        key={index}
                        value={value}
                        index={index}
                        actionCards={actionCards}
                        data-test="tabPanel"
                        onCardClick={onCardClick}
                    />
                ))}
            </SwipeableViews>
        </>
    );
};

ActionTabMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    allActionCards: PropTypes.arrayOf(PropTypes.object),
    onCardClick: PropTypes.func,
    lastVisitedTabIndex: PropTypes.number,
    tabAction: PropTypes.func,
};

ActionTabMenu.defaultProps = {
    tabs: [],
    allActionCards: [],
    onCardClick: noop,
    lastVisitedTabIndex: 1,
    tabAction: noop,
};

export default withStyles(styles)(ActionTabMenu);
