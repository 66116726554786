import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

import ImageCaptionCard from '../../../common/ImageCaptionCard';
import { Collapse, Grid } from '@material-ui/core';
import Button from '../../../common/Button';
import { LinkPropType } from '../../../actions/propTypes';

const ActionCards = ({ content, onEdit, onRemove, sponsorLogo }) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const [moreContentShown, setMoreContentShown] = useState(false);
    const toggleMoreContentShown = useCallback(
        () => setMoreContentShown(!moreContentShown),
        [setMoreContentShown, moreContentShown],
    );

    if (content == null || content.length === 0) {
        return (
            <Grid container item justify="center" className={classes.noLinks}>
                {t('links.noneAdded')}
            </Grid>
        );
    }

    const [first = {}, ...rest] = content;

    return (
        <>
            <ImageCaptionCard
                {...first}
                withControls={first.isCommunity}
                editPermissionAction="CommunityActionUrls"
                onEdit={() => onEdit(first.key)}
                onRemove={() => onRemove(first.key)}
                topRightImageUrl={first.sponsorId && sponsorLogo}
            />
            {rest.length > 0 ? (
                <>
                    <Collapse in={moreContentShown}>
                        {rest.map((card) => (
                            <ImageCaptionCard
                                {...card}
                                key={card.key}
                                withControls={card.isCommunity}
                                editPermissionAction="CommunityActionUrls"
                                onEdit={() => onEdit(card.key)}
                                onRemove={() => onRemove(card.key)}
                                topRightImageUrl={card.sponsorId && sponsorLogo}
                            />
                        ))}
                    </Collapse>
                    <Grid item className={classes.showMoreBtnItem}>
                        <Button color="light" onClick={toggleMoreContentShown}>
                            {t('Show')}{' '}
                            {moreContentShown ? t('Less') : t('More')}
                        </Button>
                    </Grid>
                </>
            ) : null}
        </>
    );
};

ActionCards.propTypes = {
    content: PropTypes.arrayOf(LinkPropType),
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    sponsorLogo: PropTypes.string,
};

ActionCards.defaultProps = {
    content: [],
    sponsorLogo: '',
    onEdit: () => {},
    onRemove: () => {},
};

export default ActionCards;
