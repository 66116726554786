import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { tooltipPlacementPropType } from '../Tooltip/propTypes';
import Tooltip from '../Tooltip';
import { InfoOutlined } from '@material-ui/icons';

const InfoTooltip = ({ text, placement, intercomTarget, Icon, ...props }) => {
    const classes = useStyles();

    return (
        <Tooltip
            title={text}
            placement={placement}
            data-intercom-target={intercomTarget}
        >
            <Icon fontSize="small" className={classes.icon} {...props} />
        </Tooltip>
    );
};

InfoTooltip.propTypes = {
    text: PropTypes.string,
    placement: tooltipPlacementPropType,
    intercomTarget: PropTypes.string,
    Icon: PropTypes.elementType,
};

InfoTooltip.defaultProps = {
    text: '',
    placement: 'right',
    intercomTarget: '',
    Icon: InfoOutlined,
};

export default InfoTooltip;
