import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Typography,
    withStyles,
    TextareaAutosize,
    /*
     * TextField,
     * MenuItem,
     */
} from '@material-ui/core';
import { Edit, InfoOutlined } from '@material-ui/icons';
import styles from './styles';
import { Can } from '../../casl/ability-context';
import FormInput from '../Form/FormInput';
import Button from '../Button';
import Photo from '../Photo';
import { getAppImages, noop } from '../../utils';
import Tooltip from '../Tooltip';
// import { URL_REGEX } from './constants';

const CommunityInfoForm = ({
    classes,
    initialValues,
    options,
    onSubmit,
    handleCommunityImageUpload,
    resetUploadedImage,
    existingImage,
    errors,
}) => {
    const { t } = useTranslation();

    const [formDisabled, setFormDisabled] = useState(true);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(
            t('account.communityInfoForm.nameRequired'),
        ),
        description: Yup.string(),
        /*
         * website: Yup.string()
         *     .matches(URL_REGEX, t('onBoarding.community.websiteValid'))
         *     .required(t('onBoarding.community.websiteRequired')),
         */
    });

    /*
     * const EmployeeCountSelector = ({ onChange, ...props }) => {
     *     const modifiedProps = {
     *         name: props.name,
     *         value: props.value,
     *         classes: {
     *             root: props.classes.root,
     *         },
     *     };
     *
     *     return (
     *         <TextField
     *             onChange={onChange}
     *             InputProps={{ disableUnderline: true }}
     *             className={classes.selectBox}
     *             fullWidth
     *             select
     *             disabled={props.disabled}
     *             {...modifiedProps}
     *         >
     *             {options.map((option, index) => (
     *                 <MenuItem key={index} value={option}>
     *                     {option}
     *                 </MenuItem>
     *             ))}
     *         </TextField>
     *     );
     * };
     */

    const handleImageUpload = (newImage) => {
        handleCommunityImageUpload(newImage);
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit(values);
                setFormDisabled(true);
            }}
        >
            {(formikProps) => {
                const handleEditClick = () => {
                    setFormDisabled(!formDisabled);

                    if (!formDisabled) {
                        formikProps.resetForm();
                        resetUploadedImage();
                    }
                };

                return (
                    <Grid container direction="row">
                        <Grid
                            className={classes.titleContainer}
                            item
                            container
                            direction="row"
                            xs={12}
                        >
                            <Grid item xs={10}>
                                <Typography
                                    className={classes.formTitle}
                                    variant="h6"
                                >
                                    {t('account.communityInfoForm.title')}
                                </Typography>
                            </Grid>
                            <Can I="edit" a="CommunityProfile">
                                <Grid
                                    className={classes.editBtnContainer}
                                    item
                                    xs={2}
                                >
                                    <Button
                                        className={classes.editBtn}
                                        color="primary"
                                        size="large"
                                        onClick={handleEditClick}
                                    >
                                        <Edit className={classes.editBtnIcon} />
                                        <div className={classes.editBtnText}>
                                            {t('edit')}
                                        </div>
                                    </Button>
                                </Grid>
                            </Can>
                        </Grid>
                        <Grid item container direction="row" xs={12}>
                            <Grid item xs={3}>
                                <Typography className={classes.inputLabel}>
                                    {t('account.communityInfoForm.photoLabel')}
                                </Typography>
                                <div className={classes.photoLoaderContainer}>
                                    <Photo
                                        setCroppedImageData={handleImageUpload}
                                        photo={
                                            existingImage ||
                                            getAppImages().defaultProfileIcon
                                        }
                                        hideTip
                                        border={false}
                                        borderRadius="10px"
                                        disabled={formDisabled}
                                    />
                                </div>
                            </Grid>
                            <Grid item container xs={9}>
                                <Form className={classes.form}>
                                    <Grid
                                        className={classes.fieldContainer}
                                        item
                                        xs={12}
                                    >
                                        <Typography
                                            className={classes.inputLabel}
                                        >
                                            {t(
                                                'account.communityInfoForm.nameLabel',
                                            )}
                                        </Typography>
                                        <FormInput
                                            name="name"
                                            placeholder={t(
                                                'account.communityInfoForm.nameLabel',
                                            )}
                                            noLeftMargin={true}
                                            disabled={formDisabled}
                                        />
                                    </Grid>
                                    <Grid
                                        className={classes.fieldContainer}
                                        item
                                        xs={12}
                                    >
                                        <Typography
                                            className={classes.inputLabel}
                                        >
                                            {t(
                                                'account.communityInfoForm.descriptionLabel',
                                            )}
                                        </Typography>
                                        <FormInput
                                            className={classes.textarea}
                                            name="description"
                                            placeholder={t(
                                                'account.communityInfoForm.descriptionPlaceholder',
                                            )}
                                            noLeftMargin={true}
                                            as={TextareaAutosize}
                                            minRows={3}
                                            disabled={formDisabled}
                                        />
                                    </Grid>
                                    <Grid
                                        className={classes.accessContainer}
                                        item
                                        container
                                        xs={12}
                                    >
                                        <Grid direction="row" container item>
                                            <Grid item xs={6}>
                                                <Typography
                                                    className={
                                                        classes.inputLabel
                                                    }
                                                >
                                                    {t(
                                                        'account.communityInfoForm.accessCodeLabel',
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={6}
                                                justify="flex-end"
                                            >
                                                <Tooltip
                                                    title={t(
                                                        'account.communityInfoForm.accessCodeTooltip',
                                                    )}
                                                    placement="right"
                                                >
                                                    <InfoOutlined
                                                        className={
                                                            classes.infoIcon
                                                        }
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <FormInput
                                            name="accessCode"
                                            noLeftMargin
                                            disabled
                                        />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        container
                                        direction="row"
                                        xs={12}
                                    >
                                        <Grid
                                            className={classes.websiteContainer}
                                            item
                                            sm={12}
                                            md={6}
                                        >
                                            <Typography
                                                className={classes.inputLabel}
                                            >
                                                {t(
                                                    'onBoarding.community.websiteLabel',
                                                )}
                                            </Typography>
                                            <FormInput
                                                name="website"
                                                placeholder={t(
                                                    'onBoarding.community.websitePlaceholder',
                                                )}
                                                noLeftMargin={true}
                                                autoHeight
                                                disabled={formDisabled}
                                            />
                                        </Grid>
                                        <Grid
                                            className={
                                                classes.empCountContainer
                                            }
                                            item
                                            sm={12}
                                            md={6}
                                        >
                                            <Typography
                                                className={classes.inputLabel}
                                            >
                                                {t(
                                                    'onBoarding.community.countLabel',
                                                )}
                                            </Typography>
                                            <FormInput
                                                as={EmployeeCountSelector}
                                                name="count"
                                                noLeftMargin={true}
                                                autoHeight
                                                disabled={formDisabled}
                                            />
                                        </Grid>
                                    </Grid> */}
                                    {!formDisabled && (
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            xs={12}
                                        >
                                            <Button
                                                color="primary"
                                                size="small"
                                                type="submit"
                                                disabled={
                                                    !(
                                                        formikProps.isValid &&
                                                        formikProps.dirty
                                                    )
                                                }
                                            >
                                                {t('submit')}
                                            </Button>
                                        </Grid>
                                    )}
                                </Form>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" xs={12}>
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.error}
                                    variant="body1"
                                >
                                    {errors}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
};

CommunityInfoForm.propTypes = {
    classes: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func,
    handleCommunityImageUpload: PropTypes.func.isRequired,
    resetUploadedImage: PropTypes.func.isRequired,
    existingImage: PropTypes.string.isRequired,
    errors: PropTypes.string,
};

CommunityInfoForm.defaultProps = {
    onSubmit: noop,
    errors: '',
};

export default withStyles(styles)(CommunityInfoForm);
