export const PARAM_SOURCE = 'source';
export const PARAM_MEDIUM = 'medium';
export const PARAM_CAMPAIGN = 'campaign';
export const PARAM_CONTENT_TYPE = 'content_type';
export const PARAM_CONTENT_ID = 'content_id';
export const PARAM_PLATFORM = 'platform';

export const PARAM_PLAN_ID = 'platform';
export const PARAM_SUBSCRIPTION_ID = 'subscription_id';
export const PARAM_ORIGIN = 'origin';

export const CONTENT_TYPES = {
    CHALLENGES: 'challenges',
    ACTIVITIES: 'activities',
    ACTIONS: 'actions',
};
