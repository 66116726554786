import i18n from 'i18next';

export const prettyFormatNumber = (
    value,
    fixed = 1,
    thousandsAbreviate = false,
) => {
    let response;
    if (value >= Math.pow(10, 12)) {
        response = {
            value: (value / Math.pow(10, 12)).toLocaleString([], {
                minimumFractionDigits: fixed,
                maximumFractionDigits: fixed,
            }),
            weight: i18n.t('prefixes.Trillion'),
        };
    } else if (value >= Math.pow(10, 9)) {
        response = {
            value: (value / Math.pow(10, 9)).toFixed(fixed),
            weight: i18n.t('prefixes.Billion'),
        };
    } else if (value >= Math.pow(10, 6)) {
        response = {
            value: (value / Math.pow(10, 6)).toFixed(fixed),
            weight: i18n.t('prefixes.Million'),
        };
    } else if (!thousandsAbreviate && value >= Math.pow(10, 4)) {
        response = {
            value: (value / 1000).toFixed(fixed),
            weight: i18n.t('prefixes.Thousand'),
        };
    } else if (thousandsAbreviate && value >= Math.pow(10, 3)) {
        response = {
            value: (value / 1000).toFixed(fixed),
            weight: i18n.t('prefixes.Thousand'),
        };
    } else if (value < 1) {
        response = {
            value: value.toFixed(fixed),
            weight: '',
        };
    } else {
        fixed = fixed === 1 ? 0 : fixed;
        response = {
            value: value.toLocaleString([], {
                minimumFractionDigits: fixed,
                maximumFractionDigits: fixed,
            }),
            weight: '',
        };
    }
    return response;
};

const fixImpactStat = (stat = {}) => {
    return stat.unit?.symbol === 's'
        ? {
              ...stat,
              value: stat.value / 60 / 60,
              unit: {
                  ...stat.unit,
                  name: 'hour',
                  namePlural: 'hours',
              },
          }
        : stat;
};

export const transformImpactStatsToTotals = (stats) =>
    stats
        ?.map(fixImpactStat)
        ?.map(({ name, themeColor, value, unit: { symbol }, ...rest }) => ({
            title: `${name}`,
            colorTitle: `#${themeColor}`,
            colorContent: `#${themeColor}`,
            color: `#${themeColor}`,
            unit: symbol,
            ...rest,
            ...prettyFormatNumber(value),
        }));

/**
 * Copied from the legacy dashboard. Hack that needs to be updated if any s3 stuff changes
 * Changes imageSizes 'url' property that is received from the API into a 'file' property
 * that can be understood by the api
 */
export const transformImageSizes = (sizes) => {
    return sizes.map((size) => {
        if (!size?.file && size?.url) {
            const splitUrl = size.url.split('static.joulebug.com/');
            return {
                width: size.width,
                height: size.height,
                file: {
                    s3_reference: {
                        bucket_name: 'jb-content',
                        file_name: splitUrl[1],
                    },
                },
            };
        } else {
            return size;
        }
    });
};
