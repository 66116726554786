import IMAGES from '../../constants/images';
const styles = (theme) => ({
    onBoardingContainer: {
        height: 'calc(100vh - 72px)',
        width: '100%',
        position: 'relative',
        zIndex: '2',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '23.5%',
            height: '100%',
            bottom: '0',
            left: '0',
            backgroundImage: `url(${IMAGES.bgOnBoarding1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'left,bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '19.5%',
            height: '100%',
            bottom: '0',
            right: '0',
            backgroundImage: `url(${IMAGES.bgOnBoarding2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'right, bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 160px)',
            background: 'none',
            '@media (orientation: landscape)': {
                height: 'auto',
            },
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            background: 'none',
            '@media (orientation: landscape)': {
                height: 'auto',
            },
        },
    },
    verifyContainer: {
        height: 'calc(100vh - 72px)',
        width: '100%',
        position: 'relative',
        zIndex: '2',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '19.6%',
            height: '100%',
            bottom: '-167px',
            left: '0',
            backgroundImage: `url(${IMAGES.bgEmailVerification1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'left,bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '19.9%',
            height: '100%',
            bottom: '-167px',
            right: '0',
            backgroundImage: `url(${IMAGES.bgEmailVerification2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'right, bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },

        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 160px)',
            background: 'none',
            '@media (orientation: landscape)': {
                height: 'auto',
            },
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            '@media (orientation: landscape)': {
                height: 'auto',
            },
        },
    },
});

export default styles;
