import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    noDataLabelContainer: {
        color: theme.custom.textDarkColor,
    },
    noDataLabel: {
        width: '50%',
        color: theme.custom.textDarkColor,
        textAlign: 'center',
        opacity: '.5',
        alignSelf: 'center',
        paddingTop: 10,
    },
    notFoundImage: {
        alignSelf: 'center',
        maxWidth: 100,
        maxHeight: 100,
    },
}));

export default useStyles;
