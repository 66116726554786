import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        maxWidth: theme.custom.mainContentWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
        boxShadow:
            'rgba(0, 0, 0, 0.03) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 1px 4px 0px',
        color: theme.custom.textDarkColor,
        borderRadius: '5px',
    },
    title: {
        backgroundColor: theme.custom.bgLightColor,
        padding: '10px 15px',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1.1,
        color: theme.custom.textDarkSecondaryMutedColor,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderWidth: 0,
    },
    body: {
        padding: '30px',
        backgroundColor: theme.custom.bgLightAccentColor,
    },
    photo: {
        width: '100%',
    },
    formRight: {
        marginLeft: '15px',
    },
    label: {
        marginBottom: '5px',
        color: theme.custom.textDarkColor,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: 1.4,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& svg': {
            cursor: 'pointer',
            marginLeft: '6px',
            color: 'rgba(51,51,51,.25)',

            '&:hover': {
                color: 'rgba(51,51,51,.5)',
            },
        },
    },
    buttons: {
        backgroundColor: theme.custom.bgLightColor,
        padding: '10px 15px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
    },
}));

export default useStyles;
