import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import IMAGES from '../../../constants/images';

const Loader = ({ classes }) => {
    return (
        <div className={classes.container}>
            <img src={IMAGES.loader} alt="loader" />
        </div>
    );
};

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

Loader.defaultProps = {};

export default withStyles(styles)(Loader);
