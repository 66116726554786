import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from './styles';
import ActionCard from '../../ActionCard';
import { actionPropType } from '../../../actions/propTypes';
import { noop } from '../../utils';

const TabPanel = ({ classes, actionCards, value, index, onCardClick }) => {
    const { t } = useTranslation();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Grid container className={classes.mainContainer}>
                    {actionCards?.length > 0 ? (
                        <Grid
                            item
                            container
                            spacing={2}
                            direction="row"
                            xs={12}
                        >
                            {actionCards.map((action, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={index}
                                    onClick={() => onCardClick(action)}
                                >
                                    <ActionCard
                                        name={action.name}
                                        description={action.description}
                                        iconImageURL={action.iconUrl}
                                        backgroundImageURL={
                                            action.headlinePhotoUrl
                                        }
                                        actionLink={'/actions/' + action.id}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <div className={classes.emptyTextContainer}>
                                <Typography className={classes.emptyText}>
                                    {t('actionPage.emptyCategory')}
                                </Typography>
                            </div>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    actionCards: PropTypes.arrayOf(actionPropType).isRequired,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onCardClick: PropTypes.func,
};

TabPanel.defaultProps = {
    onCardClick: noop,
};

export default withStyles(styles)(TabPanel);
