const styles = (theme) => ({
    drawer: {
        flexShrink: 0,
        width: '240px',
    },
    paper: {
        width: '240px',
        paddingTop: '50px',
        borderRight: 0,
        backgroundColor: '#edeff0',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: '250ms',
        }),
        [theme.breakpoints.down('sm')]: {
            width: '70px',
        },
        [theme.breakpoints.down('xs')]: {
            width: 0,
        },
    },
    openDrawer: {
        width: '240px',
        overflowY: 'auto',
    },
    hideDrawer: {
        width: '70px',
        [theme.breakpoints.down('xs')]: {
            width: 0,
        },
    },
    menuItem: {
        borderTop: `1px solid ${theme.custom.bgAccentColor}`,
        padding: 0,
    },
    dropdown: {
        display: 'flex',
        padding: '0 10px 10px',
        backgroundColor: theme.custom.bgLightColor,
    },
    icon: {
        margin: '0 22px 0 12px',
        fontSize: '26px',
    },
    importedIcon: {
        margin: '0 22px 0 12px',
        width: '26px',
    },
    iconMoon: {
        flexShrink: 0,
        margin: '0 25px 0 15px',
    },
    navLink: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        padding: '15px 10px',
        fontSize: '18px',
        lineHeight: '20px',
        textDecoration: 'none',
        color: theme.custom.textDarkSecondaryMutedColor,
        fill: theme.custom.textDarkSecondaryMutedColor,

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
            color: theme.custom.textDarkSecondaryColor,
            fill: theme.custom.textDarkSecondaryColor,
            cursor: 'pointer',
        },
    },
    navLinkActive: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        backgroundColor: theme.custom.bgLightColor,

        '&:hover': {
            color: theme.palette.primary.main,
            fill: theme.palette.primary.main,
        },
    },
    notificationBadge: {
        paddingLeft: '20px',
    },
    backToOrgButtonContainer: {
        backgroundColor: theme.custom.bgLightColor,
        padding: 10,
    },
    backToOrgButton: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
});

export default styles;
