import { createSelector } from 'reselect';

import { coerceIntoErrors } from '../common/errors';
import { transformImpactStatsToTotals } from '../common/transforms';
import {
    getHighResProfilePicture,
    transformAvailableCommunities,
    transformUsers,
    transformUserTrophies,
    transformUserWithIntercomRole,
    transformJoinedCommunities,
} from './transforms';
import { deriveActiveCommunityFromUser } from '../utils/user';
import { ACTIVE_STATUS_ACTIVE, ACTIVE_STATUS_DEACTIVATED } from './constants';
import { transformActivities } from '../activities/transforms';

export const getUserState = (state) => state.user;
export const getSettingsState = (state) => state.settings;

export const getUser = createSelector(getUserState, (user) => user?.user);

export const getUserId = createSelector(getUser, (user) => user?.id);

export const getUserCommunities = createSelector(getUser, (user) =>
    transformJoinedCommunities(user?.communityMemberships),
);

export const getUserMemberOrganization = createSelector(
    getUser,
    (user) => user?.organization,
);

export const getUserMemberOrganizationId = createSelector(
    getUserMemberOrganization,
    (organization) => organization.id,
);

export const getCurrentUserPermissions = createSelector(
    getUser,
    (user) => user?.communityPermissions || {},
);

export const isUserOrganizationOwner = createSelector(
    getUser,
    (user) => user?.isOrganizationOwner,
);

export const getUserWithIntercomRole = createSelector(getUser, (user) =>
    transformUserWithIntercomRole(user),
);

export const isUserLoading = createSelector(
    getUserState,
    (user) => !!user.loading.user,
);

export const getUserErrors = createSelector(getUserState, (user) =>
    coerceIntoErrors(user.errors),
);

export const getUserName = createSelector(getUser, (user) => user?.name);

export const getUserEmail = createSelector(getUser, (user) => user?.username);

export const isUserViewActive = createSelector(
    getUserState,
    (user) => user?.userViewActive,
);

export const getActiveCommunity = createSelector(
    getUserState,
    getUser,
    isUserViewActive,
    (userState, user, userViewActive) =>
        userState?.activeCommunity?.id
            ? userState.activeCommunity
            : deriveActiveCommunityFromUser(user, userViewActive),
);

export const getActiveCommunityName = createSelector(
    getActiveCommunity,
    (community = {}) => {
        return community.name;
    },
);

export const getActiveCommunityImage = createSelector(
    getActiveCommunity,
    (community = {}) => {
        return (
            community.imageSizes &&
            community.imageSizes.length > 0 &&
            community.imageSizes[0].url
        );
    },
);

export const getActiveCommunityId = createSelector(
    getActiveCommunity,
    (community) => community.id,
);

export const getActiveCommunityOrgId = createSelector(
    getActiveCommunity,
    (community) => community.organizationId,
);

export const isOrganization = createSelector(
    getActiveCommunity,
    (community) => community.organizationId === community.id,
);

export const isActiveCommunityLoading = createSelector(
    getActiveCommunity,
    (activeCommunity = {}) => activeCommunity.loading,
);

export const getActiveCommunityErrors = createSelector(
    getActiveCommunity,
    (activeCommunity = {}) => activeCommunity.errors,
);

export const getAvailableCommunities = createSelector(getUser, (user) =>
    transformAvailableCommunities(user),
);

export const getUserProfile = createSelector(getUser, (user) => ({
    id: user.id,
    image: getHighResProfilePicture(user),
    name: user.name,
    headline: user.headline,
    followers: user.followers.length,
    following: user.following.length,
    impactTotals: transformImpactStatsToTotals(
        user.impactStats?.impactStatTypes,
    ),
    activityTotals: {
        points: user.score,
        logs: user.buzzes,
        actions: user.pinTotal,
        badges: user.badgeTotal,
    },
}));

export const getSelectedUser = createSelector(
    getUserState,
    (userState) => userState.selectedUser,
);

export const isSelectedUserLoading = createSelector(
    getUserState,
    (userState) => userState.selectedUserLoading,
);

export const getSelectedUserErrors = createSelector(getUserState, (userState) =>
    coerceIntoErrors(userState.selectedUserErrors),
);

export const getSelectedUserProfile = createSelector(
    getSelectedUser,
    (selectedUser) =>
        selectedUser && {
            id: selectedUser.id,
            image: getHighResProfilePicture(selectedUser),
            name: selectedUser.name,
            headline: selectedUser.headline,
            followers: selectedUser.followers.length,
            following: selectedUser.following.length,
            impactTotals: transformImpactStatsToTotals(
                selectedUser.impactStats?.impactStatTypes,
            ),
            activityTotals: {
                points: selectedUser.score,
                logs: selectedUser.buzzes,
                actions: selectedUser.pinTotal,
                badges: selectedUser.badgeTotal,
            },
        },
);

export const getSelectedUserActivities = createSelector(
    getUserState,
    (userState) => userState.selectedUserActivities,
);

export const getSelectedUserActivityItems = createSelector(
    getSelectedUserActivities,
    (activities = []) => transformActivities(activities),
);

export const isSelectedUserActivitesLoading = createSelector(
    getUserState,
    (userState) => userState.selectedUserActivitiesLoading,
);

export const getSelectedUserActivitiesErrors = createSelector(
    getUserState,
    (userState) => coerceIntoErrors(userState.selectedUserActivitiesErrors),
);

export const getSelectedUserTrophies = createSelector(
    getSelectedUser,
    (selectedUser) => transformUserTrophies(selectedUser),
);

export const getUserTrophies = createSelector(getUser, (user) =>
    transformUserTrophies(user),
);

export const getUserUpdateState = createSelector(
    getUserState,
    (userState) => userState.update,
);

export const isUserUpdateLoading = createSelector(
    getUserUpdateState,
    (updateState) => updateState.loading,
);

export const getUserUpdateErrors = createSelector(
    getUserUpdateState,
    (updateState) => coerceIntoErrors(updateState.errors),
);

export const getUserUpdateResult = createSelector(
    getUserUpdateState,
    (updateState) => updateState.result,
);

export const getActiveMembers = createSelector(
    getUserState,
    (user) => user[ACTIVE_STATUS_ACTIVE]?.data,
);

export const getDeactivatedMembers = createSelector(
    getUserState,
    (user) => user[ACTIVE_STATUS_DEACTIVATED]?.data,
);

export const getActiveMembersTable = createSelector(
    getActiveMembers,
    getActiveCommunityId,
    (activeMembers, communityId) => transformUsers(activeMembers, communityId),
);
export const getDeactivatedMembersTable = createSelector(
    getDeactivatedMembers,
    getActiveCommunityId,
    (deactivatedMembers, communityId) =>
        transformUsers(deactivatedMembers, communityId),
);

export const getPermissions = createSelector(
    getUserState,
    (user) => user.permissions,
);

export const getRoles = createSelector(getUserState, (user) => user.roles);

export const getActiveMembersLoading = createSelector(
    getUserState,
    (user) => user[ACTIVE_STATUS_ACTIVE]?.loading,
);

export const getDeactivatedMembersLoading = createSelector(
    getUserState,
    (user) => user[ACTIVE_STATUS_DEACTIVATED]?.loading,
);

export const getActiveMembersTotalCount = createSelector(
    getUserState,
    (user) => user[ACTIVE_STATUS_ACTIVE]?.totalCount,
);

export const getDeactivatedMembersTotalCount = createSelector(
    getUserState,
    (user) => user[ACTIVE_STATUS_DEACTIVATED]?.totalCount,
);

export const getErrors = createSelector(getUserState, (user) => user.errors);

export const getDismissedInfoCardIds = createSelector(
    getUserState,
    (user) => user.dismissedInfoCardIds,
);

export const getOtherCommunities = createSelector(
    getUserState,
    (userState) => userState.communities.response,
);

export const isLoadingOtherCommunities = createSelector(
    getUserState,
    (userState) => userState.communities.loading,
);

export const getUserContactEmail = createSelector(
    getUser,
    (user) => user.contact.email,
);
