import { applyMiddleware, createStore as createReduxStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import createRootReducer from './reducer';
import rootSaga from './saga';
import browserHistory from '../history';

/**
 * Create our fully dec'd out redux store.
 *
 * @returns {{store: *, history: {createHref, goBack, length, replace, go, action, location, goForward, block, push, listen}}}
 */
export default function createStore() {
    // Create saga middleware for connecting the store to our root saga
    const sagaMiddleware = createSagaMiddleware();

    // Create the root reducer
    const rootReducer = createRootReducer(browserHistory);

    // Gather a list of our middleware
    const middleware = [
        // Connects our app's store state to the router state
        routerMiddleware(browserHistory),
        // Connect our app's store to the root saga
        sagaMiddleware,
    ];

    // Create our store with our enhanced root reducer, all of our middleware, and dev tools
    const store = createReduxStore(
        rootReducer,
        // eslint-disable-next-line no-undefined
        undefined,
        composeWithDevTools(applyMiddleware(...middleware)),
    );

    const persistor = persistStore(store);

    // Run the root saga
    sagaMiddleware.run(rootSaga);

    // Return our store and history
    return { store, persistor };
}
