const styles = (theme) => ({
    headerSection: {
        color: theme.custom.waste,
        borderBottom: `3px solid ${theme.custom.waste}`,
        paddingBottom: '5px',
        marginBottom: '5px',
    },
    calcSection: {
        marginTop: '30px',
        borderTop: `4px solid ${theme.custom.grey70}`,
    },
    calcRow: {
        padding: '6px 0',
        borderBottom: `2px solid ${theme.custom.textLightSecondaryColor}`,
    },
    valueContainer: {
        paddingTop: '3px',
    },
    finalRow: {
        padding: '6px 0',
        color: theme.custom.waste,
    },
});

export default styles;
