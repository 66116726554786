import { makeStyles } from '@material-ui/core';
import { VARIANT_VERTICAL, VARIANT_HORIZONTAL } from '../FormInputGroup';

const useStyles = makeStyles((theme) => ({
    container: (props) => {
        const horizontalBorderRadius =
            props.variant === VARIANT_HORIZONTAL && '8px';
        const verticalBorderRadius =
            props.variant === VARIANT_VERTICAL && '8px';

        return {
            width: '100%',

            '& .MuiInput-root': {
                margin: 0,
                borderRadius: 0,

                '&:first-of-type': {
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: horizontalBorderRadius,
                    borderTopRightRadius: verticalBorderRadius,
                },

                '&:last-of-type': {
                    borderBottomLeftRadius: verticalBorderRadius,
                    borderBottomRightRadius: '8px',
                    borderTopRightRadius: horizontalBorderRadius,
                    marginBottom: '8px',
                },

                '&:not(:last-child):not(.Mui-focused)': {
                    borderRightColor:
                        props.variant === VARIANT_HORIZONTAL &&
                        theme.custom.inputSeparator,
                },
            },

            '& span': {
                order: '1',
            },
        };
    },
}));

export default useStyles;
