export default (theme) => ({
    root: {
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.custom.textDarkSecondaryColor,
        marginTop: '20px',

        '& .MuiInputBase-root': {
            margin: '2px 0',
            padding: '6px 15px',
            borderRadius: '10px',
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            border: 'solid 2px',
            borderColor: theme.palette.primary.main,
        },

        '& .MuiInputBase-root > div:focus': {
            boxShadow: 'none',
            outline: '0!important',
            borderColor: 'transparent',
        },
    },

    textLabel: {
        fontSize: '32px',
        color: 'inherit',
        fontWeight: 600,
        lineHeight: 1.1,
    },
    selectIcon: {
        fontSize: 18,
        marginRight: 6,
        marginTop: 3,
        color: 'inherit',
    },

    select: {
        minWidth: '30px',
        cursor: 'pointer',
    },
});
