const styles = (theme) => ({
    title: {
        fontSize: '18px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginLeft: '3px',
        color: theme.custom.tooltipIconColor,
        cursor: 'pointer',

        '&:hover': {
            color: theme.custom.tooltipIconHoverColor,
        },
    },
});

export default styles;
