const styles = (theme) => ({
    onBoardingTitle: {
        fontWeight: '700',
        marginBottom: '10px',
        textAlign: 'center',
        margin: '0px 15px 0px 15px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 13px',
            '@media (orientation: landscape)': {
                textAlign: 'left',
            },
        },
    },

    verifyTitle: {
        fontWeight: '700',
        color: theme.palette.primary.main,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            padding: '0px 13px',
        },
    },
    verifyEmailIconBox: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    verifyEmailIcon: {
        width: '6em',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '13px',
            paddingBottom: '13px',
            marginLeft: '0px',
        },
    },
    linkText: {
        color: '#1D8DC7',
        fontWeight: '700',
    },
});

export default styles;
