import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    wrapper: {
        position: 'relative',
    },
}));

export default useStyles;
