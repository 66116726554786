import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { AddBox, IndeterminateCheckBox } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '../../../common/Table';
import {
    activeButtonsCell,
    deactivatedButtonsCell,
    tableColumn,
} from './constants';
import { noop } from '../../../common/utils';
import ActionDuplicationModal from '../../../common/ActionDuplicationModal';
import {
    activeCommunityPropType,
    availableCommunitiesPropType,
} from '../../../user/propTypes';
import Activate from '../../../common/ActivateModal/Activate';
import {
    PIN_ADMIN_VISIBILITIES,
    PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
} from '../../../actions/constants';
import { actionTablePropType } from '../../../actions/propTypes';

const TableManager = ({
    type,
    title,
    data,
    filter,
    onChangeActive,
    columns,
    shouldShowMultiSelect,
    actionDuplicationCommunities,
    activeCommunity,
    fetchDataFunc,
    buttonsCell,
    onButtonsCell,
    clientPagination,
    ...tableProps
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selectedRowIds, setSelectedRowIds] = useState({});
    const [rowsData, setRowsData] = useState({});

    const tableData = useMemo(() => {
        let filteredData = data.data;
        if (clientPagination) {
            filteredData = data.data?.filter(
                ({ name, description }) =>
                    name.toLowerCase().includes(filter.toLowerCase()) ||
                    description.toLowerCase().includes(filter.toLowerCase()),
            );
        }
        return (
            filteredData?.map((item) =>
                [...columns, { accessor: 'id' }].reduce((row, cell) => {
                    return { ...row, [cell.accessor]: item[cell.accessor] };
                }, {}),
            ) || []
        );
    }, [clientPagination, columns, data.data, filter]);

    const changeActivate = useCallback(
        (id) => {
            const currRows = !id
                ? selectedRowIds.reduce((list, itemId) => {
                      const item = tableData.find((item) => item.id === itemId);
                      return item ? [...list, item] : list;
                  }, [])
                : tableData.filter((item) => item.id === id);

            if (!currRows.length) return;

            setRowsData({
                isOpen: true,
                items: currRows.map(({ id, name, icon }) => ({
                    name,
                    iconUrl: icon,
                    identifier: id,
                })),
            });
        },
        [tableData, selectedRowIds],
    );

    const handleActions = useCallback(
        (id, key) => {
            switch (key) {
                case 'viewActionsAdmin':
                    history.push(`/actions/${id}`);
                    break;

                case 'editActions':
                    history.push(`/actions/${id}/edit`);
                    break;

                case 'deactivateActionExclude':
                case 'activateActionExclude':
                    changeActivate(id);
                    break;

                default:
                    alert({ id, key });
            }
        },
        [history, changeActivate],
    );

    const isIncluded = useMemo(
        () => type === PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
        [type],
    );

    const renderHeadButtons = useCallback(() => {
        const disabled = Object.keys(selectedRowIds).length === 0;
        return (
            <IconButton onClick={() => changeActivate()} disabled={disabled}>
                <Tooltip
                    placement="top"
                    title={isIncluded ? t('deactivate') : t('activate')}
                >
                    {isIncluded ? <IndeterminateCheckBox /> : <AddBox />}
                </Tooltip>
            </IconButton>
        );
    }, [t, selectedRowIds, isIncluded, changeActivate]);

    const action = isIncluded ? t('deactivate') : t('activate');
    const descriptionTransKey = isIncluded
        ? 'action.deactivate.description'
        : 'action.activate.description';
    const description = t(descriptionTransKey, {
        count: rowsData.items?.length,
    });

    const handleConfirmActivate = (communityIds) => {
        onChangeActive(
            rowsData.items.map(({ identifier }) => identifier),
            communityIds,
            {
                visibility: type,
            },
        );
        setRowsData({});
    };

    const handleFetchData = useCallback(
        (args) => {
            const body = {
                ...args,
                visibility: type,
            };
            fetchDataFunc(body);
        },
        [fetchDataFunc, type],
    );

    return (
        <Fragment>
            <Table
                title={title}
                columns={columns}
                data={tableData}
                multiActionButton={shouldShowMultiSelect && renderHeadButtons()}
                buttonsCell={
                    buttonsCell ||
                    (isIncluded ? activeButtonsCell : deactivatedButtonsCell)
                }
                onButtonsCell={onButtonsCell || handleActions}
                onRowSelect={setSelectedRowIds}
                fetchDataFunc={handleFetchData}
                searchString={filter}
                totalCount={data.pagination?.totalCount}
                loading={data.loading}
                clientPagination={clientPagination}
                activeCommunityId={activeCommunity.id}
                {...tableProps}
            />
            <ActionDuplicationModal
                isOpen={rowsData.isOpen || false}
                onClose={() => setRowsData({})}
                onCancel={() => setRowsData({})}
                onConfirm={(communityIds) =>
                    handleConfirmActivate(communityIds)
                }
                actionDuplicationCommunities={actionDuplicationCommunities}
                activeCommunity={activeCommunity}
                title={t('confirmUpdate')}
                confirmBtnType={isIncluded ? 'danger' : 'success'}
                confirmBtnText={action}
            >
                <Activate
                    action={!isIncluded ? t('activate') : t('deactivate')}
                    headerKey="action.areYouSure"
                    items={rowsData.items || []}
                    description={description}
                />
            </ActionDuplicationModal>
        </Fragment>
    );
};

TableManager.propTypes = {
    type: PropTypes.oneOf(PIN_ADMIN_VISIBILITIES),
    title: PropTypes.string,
    data: actionTablePropType,
    filter: PropTypes.string,
    onChangeActive: PropTypes.func,
    columns: PropTypes.object,
    shouldShowMultiSelect: PropTypes.bool,
    actionDuplicationCommunities: availableCommunitiesPropType,
    activeCommunity: activeCommunityPropType,
    fetchDataFunc: PropTypes.func,
    buttonsCell: PropTypes.object,
    onButtonsCell: PropTypes.func,
    clientPagination: PropTypes.bool,
};

TableManager.defaultProps = {
    type: PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
    filter: '',
    onChangeActive: noop,
    title: null,
    columns: tableColumn,
    shouldShowMultiSelect: true,
    actionDuplicationCommunities: [],
    activeCommunity: {},
    fetchDataFunc: noop,
    buttonsCell: null,
    onButtonsCell: null,
    clientPagination: false,
    data: {},
};

export default TableManager;
