import { getFontFamily } from '../utils';

export default (theme) => ({
    '#root': {
        minHeight: '100%',
        backgroundColor: theme.custom.bgColor,
    },
    body: {
        fontFamily: `"${getFontFamily() ||
            'effra'}","Helvetica Neue",Helvetica,Arial,sans-serif`,

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    a: {
        color: theme.palette.primary,
        '&:visited': {
            color: theme.palette.disabled,
        },
        '&:hover, &:active': {
            backgroundColor: theme.palette.primary,
            color: theme.palette.primaryForeground,
        },
    },
});
