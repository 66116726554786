import { getProperties } from '../utils';
import { SAMPLE_UNITS } from '../screens/AccountScreen/constants';
import { SUBSCRIPTION_BUCKET_QUANTITY } from './constants';
import {
    getFeatureFlagValue,
    REMOTE_CONFIG_SHOW_SUBSCRIPTION,
} from '../utils/remoteConfig';

export const getCurrencyString = (amount, currency, includeDecimals = true) => {
    let formattedAmount = 0;
    if (amount) {
        formattedAmount = (amount / 100).toLocaleString([], {
            minimumFractionDigits: includeDecimals ? 2 : 0,
            maximumFractionDigits: includeDecimals ? 2 : 0,
        });
    }
    switch (currency) {
        default:
            return '$' + formattedAmount;
    }
};

export const shouldShowSubscription = () => {
    return (
        // If we should allow org creation, we also need to show subscriptions
        getProperties()?.shouldShowSignUp &&
        getFeatureFlagValue(REMOTE_CONFIG_SHOW_SUBSCRIPTION)
    );
};

export const calcBillingRate = (amount, interval) => {
    if (interval === 'month') return amount;
    else {
        return amount / 12;
    }
};

export const getAccountPropsFromSub = (subscription) => {
    const interval = subscription?.interval;
    const maxUsers = subscription?.quotas?.maxUserCount;
    // Fixme: If the subscription has a value for minUsers, it likely means we are charging per bucket (ie usage based)
    const nextAmount =
        maxUsers && subscription?.subscriptionTier?.minUsers
            ? subscription?.amount * (maxUsers / SUBSCRIPTION_BUCKET_QUANTITY)
            : subscription?.amount;

    const rate = getCurrencyString(
        calcBillingRate(nextAmount, interval),
        subscription?.currency,
    );

    return {
        ...subscription,
        accountType: subscription?.subscriptionTier?.name,
        description: subscription?.subscriptionTier?.description,
        rate,
        nextAmount: subscription?.formattedAmount,
        rawAmount: subscription?.amount,
        nextDate: subscription?.nextBillDate,
        interval,
        units: SAMPLE_UNITS,
        estimateUserCount: maxUsers,
        maxUsers,
        currentUsers: subscription?.currentUserCount,
    };
};
