const styles = (theme) => ({
    mainContainer: {
        padding: '10px 0px',
        paddingLeft: 12,
    },
    emptyTextContainer: {
        textAlign: 'center',
    },
    emptyText: {
        fontWeight: 600,
        color: theme.custom.textLightSecondaryColor,
    },
});

export default styles;
