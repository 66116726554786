import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import IMAGES from '../../constants/images';
import styles from './styles';
import { activitiesRequested } from '../../analytics/actions';
import { analyticsActivityRequested } from '../../challenges/actions';
import {
    getTopActions,
    isActivityStatsLoading,
} from '../../analytics/selectors';
import {
    getAnalyticsActivityLoading,
    getTopActions as getChallengeTopActions,
} from '../../challenges/selectors';
import { getActiveCommunityId } from '../../user/selectors';
import Leaderboard from '../../common/Leaderboard';
import RequestLoader from '../../common/RequestLoader';
import DatePicker from '../../common/DatePicker';
import Button from '../../common/Button';
import TopSection from '../../common/ViewAll/TopSection';
import Select, { SIZE_LARGE } from '../../common/Select';
import { DATE_FORMAT } from '../AnalyticsScreen';
import queryString from 'query-string';
import { mapSortAddRank } from '../../utils';

const TopActionsScreen = ({
    classes,
    communityId,
    isLoading,
    isChallengeActionsLoading,
    topActions,
    fetchTopActions,
    challengeActions,
    fetchChallengeActions,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const queryVals = queryString.parse(location.search);
    const sortingOptions = [
        t('sort.methods.mostLogs'),
        t('sort.methods.alphabetical'),
    ];

    const [startDate, setStartDate] = useState(
        queryVals.startDate
            ? moment(queryVals.startDate)
            : moment().subtract(1, 'months'),
    );
    const [endDate, setEndDate] = useState(
        queryVals.endDate ? moment(queryVals.endDate) : moment(),
    );
    const [sortingOption, setSortingOption] = useState(sortingOptions[0]);

    const loadActions = () => {
        const analyticsOptions = {
            startDateIso: startDate.toISOString(),
            endDateIso: endDate.toISOString(),
            scope: 'organization',
        };

        const challengeOptions = {
            communityId,
            challengeId: queryVals.challengeId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            summaryType: 'activity',
        };

        if (queryVals.challengeId) fetchChallengeActions(challengeOptions);
        else fetchTopActions(analyticsOptions, communityId);
    };

    const handleSubmit = () => {
        loadActions();
        setSortingOption(t('sort.methods.mostLogs'));
    };

    const handleSortingOptionChange = useCallback(
        (event) => {
            setSortingOption(event.target.value);
        },
        [setSortingOption],
    );

    useEffect(() => {
        loadActions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortActionsAlphabetically = () => {
        const actions = queryVals.challengeId ? challengeActions : topActions;

        return actions
            .sort((a, b) => {
                if (a.label > b.label) return 1;
                if (a.label < b.label) return -1;
                return 0;
            })
            .map((item, index) => ({ ...item, rank: index + 1 }));
    };

    const sortActionsByLogs = () => {
        const actions = queryVals.challengeId ? challengeActions : topActions;

        return mapSortAddRank(actions);
    };

    const actions = useMemo(() => {
        if (sortingOption === t('sort.methods.alphabetical')) {
            return sortActionsAlphabetically();
        } else {
            return sortActionsByLogs();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topActions, challengeActions, sortingOption, t]);

    return (
        <Grid container direction="column" className={classes.container}>
            <Grid item>
                <TopSection
                    title={t('topActions.title')}
                    handleBack={history.goBack}
                    intercomTargetPrefix="topActions"
                />
            </Grid>
            <Grid item>
                <DatePicker
                    minDate={queryVals.challengeId && startDate}
                    maxDate={endDate}
                    format={DATE_FORMAT}
                    value={startDate}
                    onChange={setStartDate}
                />
            </Grid>
            <Grid item style={{ marginTop: '10px' }}>
                <DatePicker
                    minDate={startDate}
                    format={DATE_FORMAT}
                    value={endDate}
                    onChange={setEndDate}
                />
            </Grid>
            <Grid item>
                <Button
                    className={classes.submitButton}
                    color="accent"
                    onClick={handleSubmit}
                >
                    {t('Submit')}
                </Button>
            </Grid>
            <Grid
                item
                container
                direction="column"
                style={{ marginTop: '10px' }}
            >
                <Grid item>
                    <span className={classes.subtitle}>{t('sort.select')}</span>
                </Grid>
                <Grid item>
                    <Select
                        type={SIZE_LARGE}
                        options={sortingOptions}
                        value={sortingOption}
                        onChange={handleSortingOptionChange}
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>
            <hr className={classes.divider} />
            {isLoading || isChallengeActionsLoading ? (
                <Grid container item className={classes.loaderWrapper}>
                    <RequestLoader
                        isLoading={isLoading}
                        title={t('Loading Actions...')}
                    />
                </Grid>
            ) : actions.length !== 0 ? (
                <Leaderboard
                    defaultImage={IMAGES.defaultPinIcon}
                    items={actions}
                />
            ) : (
                <Typography className={classes.emptyText}>
                    {t('No actions were found.')}
                    <br />
                    {t('Try expanding the time range.')}
                </Typography>
            )}
        </Grid>
    );
};

TopActionsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isChallengeActionsLoading: PropTypes.bool.isRequired,
    topActions: PropTypes.array.isRequired,
    fetchTopActions: PropTypes.func.isRequired,
    challengeActions: PropTypes.array.isRequired,
    fetchChallengeActions: PropTypes.func.isRequired,
};

const StyledTopActionsScreen = withStyles(styles)(TopActionsScreen);

const mapDispatchToProps = (dispatch) => ({
    fetchTopActions: (options, communityId) =>
        dispatch(activitiesRequested(options, communityId)),
    fetchChallengeActions: (args) => dispatch(analyticsActivityRequested(args)),
});

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isLoading: isActivityStatsLoading(state),
    isChallengeActionsLoading: getAnalyticsActivityLoading(state),
    topActions: getTopActions(state),
    challengeActions: getChallengeTopActions(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledTopActionsScreen);
