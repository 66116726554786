import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { copyEmail } from '../../../../common/CopyToEmail/utils';
import { noop } from '../../../../utils';
import InviteModal from '../../../../common/InviteModal';
import styles from './styles';
import { Grid, Switch, Typography, withStyles } from '@material-ui/core';
import Tooltip from '../../../../common/Tooltip';
import { trackEvent } from '../../../../utils/analytics';
import { EVENT_CHALLENGE_INVITE_TOGGLED } from '../../../../constants/analyticsEvents';

const InviteUsersModal = ({
    classes,
    isOpen,
    onConfirm,
    challengeId,
    challengeName,
    challengeDescription,
    challengeShareLink,
    shareImageURL,
    imageLoading,
    inviteLoading,
    orgName,
    themeName,
    onDownload,
    ...modalProps
}) => {
    const { t } = useTranslation();
    const inviteModalText = {
        header: t('challenge.inviteModal.header'),
        body: t('challenge.inviteModal.body'),
        previewHeader: t('challenge.inviteModal.previewHeader'),
        copied: t('challenge.inviteModal.copied'),
        pdfHeader: t('challenge.inviteModal.pdfHeader'),
    };

    const Toggle = (
        <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.switchContainer}
            onClick={() => trackEvent(EVENT_CHALLENGE_INVITE_TOGGLED)}
        >
            <Grid item xs={1}>
                <Tooltip title={t('challenge.inviteModal.tooltip')}>
                    <Switch checked disabled />
                </Tooltip>
            </Grid>
            <Grid item xs={11}>
                <Typography variant="body2" className={classes.switchText}>
                    {t('challenge.inviteModal.toggleSwitch')}
                </Typography>
            </Grid>
        </Grid>
    );

    let messageText = t('challenge.inviteModal.inviteCustom', {
        org_name: orgName,
    });
    if (themeName) {
        messageText = t('challenge.inviteModal.inviteTheme', {
            challenge_name: challengeName,
            org_name: orgName,
            theme_name: themeName.toLowerCase(),
        });
    }

    return (
        <InviteModal
            {...modalProps}
            messageText={messageText}
            inviteModalText={inviteModalText}
            Toggle={Toggle}
            challengeId={challengeId}
            isOpen={isOpen}
            onConfirm={() => {
                copyEmail(challengeShareLink, challengeDescription);
                onConfirm();
            }}
            shareLink={challengeShareLink}
            shareImageURL={shareImageURL}
            imageLoading={imageLoading}
            inviteLoading={inviteLoading}
            onDownload={onDownload}
        />
    );
};

InviteUsersModal.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    challengeId: PropTypes.string.isRequired,
    challengeName: PropTypes.string.isRequired,
    challengeDescription: PropTypes.string.isRequired,
    challengeShareLink: PropTypes.string.isRequired,
    shareImageURL: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    themeName: PropTypes.string.isRequired,
    imageLoading: PropTypes.bool,
    inviteLoading: PropTypes.bool,
    onDownload: PropTypes.func,
};

InviteUsersModal.defaultProps = {
    inviteLoading: false,
    imageLoading: false,
    onDownload: noop,
};

InviteUsersModal.defaultProps = {};

export default withStyles(styles)(InviteUsersModal);
