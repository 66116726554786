import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: { height: '100%', backgroundColor: theme.palette.white.main },
    category: {
        flex: '1 1 0%',
        flexDirection: 'column',
        margin: '0 5px',
        padding: '30px 30px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '5px',
        width: '375px',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    packageType: {
        fontWeight: '600',
        fontSize: '24px',
    },
    packageDescription: {
        fontSize: '16px',
        minHeight: '80px',
        justifyContent: 'center',
    },
    calculatorContainer: {
        marginTop: 20,
    },
    packageFee: ({ isPerUserPricing }) => ({
        color: theme.palette.primary.main,
        fontSize: isPerUserPricing ? 40 : 30,
        fontWeight: '600',
        marginRight: '5px',
        marginBottom: 20,
        lineHeight: 1,
    }),
    period: {
        color: theme.palette.primary.main,
        fontSize: '15px',
        marginLeft: '10px',
        fontWeight: '600',
        lineHeight: 1.3,
        width: '40%',
    },
    features: {
        flexDirection: 'column',
        marginBottom: '15px',
        minHeight: '200px',
    },
    packageUserCount: {
        color: 'gray',
    },
    featureTitle: {
        fontWeight: '600',
        marginBottom: '10px',
    },
    footerComponent: {
        background: 'rgb(245 246 247)',
        padding: '10px',
        borderRadius: '5px',
    },
    footerCardTitle: {
        fontWeight: '600',
    },
    image: {
        height: '25px',
        width: '25px',
    },
    isPopular: {
        border: `2px solid ${theme.palette.primary.main}`,
        margin: '0',
        borderTopRightRadius: '0',
        borderTopLeftRadius: '0',
    },
    greenHeader: ({ opacity }) => ({
        height: '30px',
        background: theme.palette.primary.main,
        margin: '0',
        paddingTop: '5px',
        paddingBottom: '3px',
        color: 'white',
        justifyContent: 'center',
        fontWeight: '600',
        fontSize: '13px',
        width: '375px',
        opacity,
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        padding: '3px 0',
    }),
    popularText: {
        fontSize: '11px',
        fontWeight: '700',
    },
    doneIcon: {
        marginRight: '10px',
    },
    upgradeButton: {
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        margin: '20px 0',
        width: '100%',
        '&:hover': {
            color: `white`,
            backgroundColor: theme.palette.primary.main,
        },
    },
    popularUpgradeButton: {
        background: theme.palette.primary.main,
        color: 'white',
        width: '100%',
        '&:hover': {
            color: `${theme.palette.primary.main}`,
            backgroundColor: 'white',
        },
    },
    blue: {
        color: '#1ba1ad',
        filter:
            'invert(48%) sepia(41%) saturate(1182%) hue-rotate(130deg) brightness(95%) contrast(80%)',
    },
    gray: {
        color: 'gray',
        filter:
            'invert(48%) sepia(41%) saturate(182%) hue-rotate(130deg) brightness(95%) contrast(80%)',
    },
    green: {
        color: theme.palette.primary.main,
    },
    subText: {
        marginTop: '10px',
    },
    featureRow: {
        marginTop: '8px',
        flexWrap: 'initial',
    },
    footerContainer: {
        justifyContent: 'center',
        background: theme.custom.bgLightAccentColor,
        padding: '5px 15px',
        borderRadius: '5px',
    },
    headerGap: {
        opacity: '0',
        height: '20px',
    },
}));

export default useStyles;
