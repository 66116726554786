import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../utils';
import SnackbarAlert from '../index';

const SnackBarAPIResponse = ({ messages, errors, clearResponse, ...props }) => {
    return (
        <>
            <SnackbarAlert
                messages={messages}
                severity="success"
                clearMessages={clearResponse}
            />
            <SnackbarAlert
                messages={errors}
                severity="error"
                clearMessages={clearResponse}
            />
        </>
    );
};

SnackBarAPIResponse.propTypes = {
    clearResponse: PropTypes.func,
    messages: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    errors: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

SnackBarAPIResponse.defaultProps = {
    clearResponse: noop,
    messages: '',
    errors: '',
};

export default SnackBarAPIResponse;
