const styles = (theme) => ({
    title: {
        fontSize: '28px',
        paddingBottom: '8px',
    },
    aboutSection: {
        paddingBottom: '50px',
    },
    link: {
        color: theme.custom.linkColor,
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: 700,
    },
});

export default styles;
