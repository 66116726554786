import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import {
    AddCircleRounded,
    PlusOneRounded,
    StarsRounded,
    SupervisorAccountRounded,
    VibrationRounded,
} from '@material-ui/icons';

import useStyles from './styles';
import StatCard from '../../../common/StatCard';
import { ImpactTotalPropType } from '../../ProfileScreen/propTypes';
import ActivityTotals from '../../../common/ActivityTotals';
import ImpactToolTip from '../../../common/ImpactTooltip';

const TeamDetailsImpact = ({ impactTotals, activityTotals }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const activityItems = useMemo(
        () =>
            activityTotals == null
                ? null
                : [
                      {
                          label: t('Points'),
                          value: activityTotals.points,
                          icon: <PlusOneRounded />,
                      },
                      {
                          label: t('profile.logs'),
                          value: activityTotals.logs,
                          icon: <VibrationRounded />,
                      },
                      {
                          label: t('action.descriptor_plural'),
                          value: activityTotals.actions,
                          icon: <AddCircleRounded />,
                      },
                      {
                          label: t('profile.badges'),
                          value: activityTotals.badges,
                          icon: <StarsRounded />,
                      },
                      {
                          label: t('Members'),
                          value: activityTotals.members,
                          icon: <SupervisorAccountRounded />,
                      },
                  ],
        [t, activityTotals],
    );

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography variant="h3" className={classes.impactTotalsLabel}>
                    {t('impact.totals')}
                    <ImpactToolTip />
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={2}>
                {impactTotals?.map((impact, index) => (
                    <Grid key={index} item xs={6}>
                        <StatCard capitalize {...impact} />
                    </Grid>
                ))}
            </Grid>
            <Grid container item className={classes.activityTotals}>
                <ActivityTotals
                    activityTitle={t('activity.totals')}
                    activityItems={activityItems}
                />
            </Grid>
        </Grid>
    );
};

export const TeamDetailsActivityTotalsPropType = PropTypes.shape({
    points: PropTypes.number.isRequired,
    logs: PropTypes.number.isRequired,
    actions: PropTypes.number.isRequired,
    badges: PropTypes.number.isRequired,
    members: PropTypes.number.isRequired,
});

TeamDetailsImpact.propTypes = {
    impactTotals: PropTypes.arrayOf(ImpactTotalPropType),
    activityTotals: TeamDetailsActivityTotalsPropType,
};

TeamDetailsImpact.defaultProps = {
    impactTotals: null,
    activityTotals: null,
};

export default TeamDetailsImpact;
