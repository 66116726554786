import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { trackEvent } from '../../../../utils/analytics';

import Table from '../../../../common/Table';
import {
    BORDER_ICON,
    BTN_DELETE,
    BTN_EDIT,
    BUTTONS_CELL,
    COLUMNS,
    GROUP_NAMES,
    GROUP_TYPE_QUEUED,
    INIT_SORT,
} from '../constants';
import Delete from '../../../../common/Delete';
import { EVENT_NOTIFICATION_EDIT_BEGAN } from '../../../../constants/analyticsEvents';

const TableManager = ({
    group,
    tableData,
    loading,
    deleteNotification,
    fetchDataFunc,
    totalCount,
    communityImg,
    communityId,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState(null);

    const makeTableColumns = useCallback(
        (group) => {
            const result = COLUMNS.reduce((result, column) => {
                if (column.accessor === 'icon') {
                    return [
                        ...result,
                        {
                            ...column,
                            Cell: ({ row: { original } }) => (
                                <img
                                    alt=""
                                    src={original.icon || communityImg}
                                    style={
                                        original.isChallenge ? BORDER_ICON : {}
                                    }
                                />
                            ),
                        },
                    ];
                } else {
                    // Localize the header for this column
                    column.Header = t(column.Header);
                }

                return [...result, column];
            }, []);

            return group === GROUP_TYPE_QUEUED
                ? [...result, { accessor: 'actions', sortable: false }]
                : result;
        },
        [t, communityImg],
    );

    const makeTableData = (data = []) =>
        data.map((item) =>
            [...COLUMNS, { accessor: 'id' }].reduce((result, cell) => {
                return {
                    ...result,
                    [cell.accessor]: item[cell.accessor],
                };
            }, []),
        );

    const findItemById = (id) =>
        tableData.find((item) => item.id === id) || null;

    const handleCloseModal = () => {
        setActiveItem({ ...activeItem, isOpen: false });
    };

    const handleNotificationDelete = (id) => {
        const { name, icon, isChallenge } = findItemById(id);

        setActiveItem({
            isOpen: true,
            item: {
                name,
                iconUrl: icon,
                identifier: id,
                isChallenge,
            },
        });
    };

    const handleDeleteItem = () => {
        const notification = findItemById(activeItem.item.identifier);
        let challengeId = null;

        if (notification.isChallenge) {
            challengeId = notification.subjectId;
        }

        setActiveItem(null);
        deleteNotification(notification.id, challengeId, {});
    };

    const handleButtonsCell = (id, key) => {
        switch (key) {
            case BTN_EDIT.key:
                trackEvent(EVENT_NOTIFICATION_EDIT_BEGAN);
                history.push(`/notifications/edit/${id}`);
                break;
            case BTN_DELETE.key:
                handleNotificationDelete(id);
                break;
            default:
                break;
        }
    };

    const getButtonsCell = useCallback(() => {
        const buttonsCell = BUTTONS_CELL[group];
        if (buttonsCell && buttonsCell.length) {
            return buttonsCell.map((action) => {
                if (action && action.tooltip) {
                    action.tooltip = t(action.tooltip);
                }
                return action;
            });
        }
        return buttonsCell || [];
    }, [t, group]);

    const groupDisplayName = t(GROUP_NAMES[group]);

    return (
        <Grid
            item
            container
            data-intercom-target={`notificationsTable${group}`}
        >
            <Table
                loading={loading}
                title={groupDisplayName}
                noItemFoundText={t('notifications.table.notFound', {
                    group: groupDisplayName,
                })}
                columns={makeTableColumns(group)}
                data={makeTableData(tableData)}
                buttonsCell={getButtonsCell()}
                onButtonsCell={handleButtonsCell}
                initialSortBy={INIT_SORT[group]}
                fetchDataFunc={fetchDataFunc}
                totalCount={totalCount}
                intercomTargetPrefix={group}
                activeCommunityId={communityId}
            />
            {activeItem && (
                <Delete
                    {...activeItem}
                    deleteWhat={t('Notification')}
                    description={t('notifications.queued.delete')}
                    onClose={handleCloseModal}
                    onCancel={handleCloseModal}
                    onConfirm={handleDeleteItem}
                />
            )}
        </Grid>
    );
};

TableManager.propTypes = {
    group: PropTypes.string.isRequired,
    tableData: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    deleteNotification: PropTypes.func.isRequired,
    fetchDataFunc: PropTypes.func.isRequired,
    communityImg: PropTypes.string,
    communityId: PropTypes.string,
};

TableManager.defaultProps = {
    communityImg: null,
    communityId: '',
};

export default TableManager;
