const styles = (theme) => ({
    root: {
        paddingBottom: '32px',
    },
    container: {
        maxWidth: theme.custom.mainContentWidth,
        paddingLeft: theme.custom.mainContentHorizontalPadding,
        paddingRight: theme.custom.mainContentHorizontalPadding,
    },
    description: {
        marginTop: '26px',
        fontSize: '24px',
        color: theme.custom.textDarkColor,
        whiteSpace: 'pre-line',
    },
    bonusesRow: {},
    rowHeader: {
        marginTop: '26px',
        marginBottom: '10px',
    },
    rowIcon: {
        marginRight: '8px',
    },
    rowLabel: {
        color: theme.custom.textDarkSecondaryColor,
        fontSize: '24px',
        fontWeight: '600',
    },
    linkItem: {
        backgroundColor: theme.custom.bgLightColor,
        borderRadius: '6px',
        marginBottom: '6px',

        '&:hover': {
            backgroundColor: theme.custom.bgLightAccentColor,
        },
    },
    linkImage: {
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        width: 100,
        height: 100,
    },
    linkInfo: {
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '8px',
        flex: 1,
    },
    linkName: {
        marginBottom: '5px',
        fontSize: '14px',
        fontWeight: '600',
    },
    linkDescription: {
        color: theme.custom.textDarkSecondaryColor,
    },
    showMoreBtnItem: {
        marginTop: '6px',
    },
    sameImpactAsContainer: {
        overflowX: 'auto',
        paddingRight: '20px',
    },
    conversionContainer: {
        flexShrink: 0,
        marginRight: '5px',
    },
    helpIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '5px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

export default styles;
