import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { getActiveCommunityId } from '../../user/selectors';
import { subscriptionVerificationRequested } from '../../subscriptions/actions';
import {
    getBillingSessionErrors,
    getBillingSessionLoading,
    getCurrentSubscription,
    getLatestInvoice,
} from '../../subscriptions/selectors';

import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';

import HeaderPage from '../../common/HeaderPage';
import AccountPlan from '../../common/AccountPlan';
import PaymentReceipt from '../../common/PaymentReceipt';
import { getAccountPropsFromSub } from '../../subscriptions/utils';
import moment from 'moment';
import { trackEvent } from '../../utils/analytics';
import { EVENT_SUBSCRIPTION_STRIPE_UPGRADE_COMPLETE } from '../../constants/analyticsEvents';
import { PARAM_PLAN_ID } from '../../constants/analyticsParams';
import Button from '../../common/Button';

const PaymentSuccess = ({
    classes,
    communityId,
    subscription,
    invoice,
    getSubscriptionVerification,
    loading,
    errors,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const planId = query.get('planId');
        getSubscriptionVerification(communityId, planId);
        trackEvent(EVENT_SUBSCRIPTION_STRIPE_UPGRADE_COMPLETE, {
            [PARAM_PLAN_ID]: planId,
        });
    }, [communityId, getSubscriptionVerification]);

    const accountPlanProps = useMemo(() => {
        return getAccountPropsFromSub(subscription);
    }, [subscription]);

    const receiptProps = useMemo(() => {
        return {
            receiptNumber: invoice.receiptNumber,
            customerName: invoice.customerName,
            customerAddress: invoice.customerAddressFull,
            subscriptionName: `JouleBug ${subscription?.subscriptionTier?.name}`,
            interval: subscription?.interval,
            amount: invoice.amount,
            billingProps: {
                amount: invoice.amountDue,
                subTotal: invoice.subTotal,
                paid: invoice.amountPaid,
                salesTax: invoice.salesTax,
                interval: subscription?.interval,
            },
        };
    }, [invoice, subscription]);

    return (
        <Grid className={classes.container} container direction="row">
            <RequestLoader
                isLoading={loading}
                title={t('loading.descriptor')}
            />
            <ErrorList errors={errors} />
            {errors.length === 0 && (
                <Grid item container xs={12}>
                    <Grid
                        className={classes.accountContainer}
                        item
                        container
                        xs={12}
                        sm={6}
                    >
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <HeaderPage
                                    title={t('success')}
                                    underlined={false}
                                />
                                <Typography variant="subtitle1">
                                    {t('account.plan.paymentSuccessMessage')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PaymentReceipt
                                    {...receiptProps}
                                    handleDownload={() =>
                                        window.location.assign(
                                            invoice.hostedInvoice,
                                        )
                                    }
                                />
                            </Grid>
                            <Grid
                                className={classes.leftSectionContainer}
                                item
                                xs={12}
                            >
                                <Typography variant="body1">
                                    {t('account.plan.autoPay')}
                                </Typography>
                                <Typography variant="body1">
                                    {t('account.plan.nextDateFull', {
                                        formattedAmount: invoice.amountDue,
                                        nextBillDate: moment(
                                            subscription.nextBillDate,
                                        ).format('MM/DD/YYYY'),
                                    })}
                                </Typography>
                                <Button
                                    style={{ marginTop: 10 }}
                                    color="accent"
                                    onClick={() => history.push('/account')}
                                >
                                    {t('account.plan.backToAccount')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Todo: abstract the your plan component */}
                    <Grid
                        className={classes.planContainer}
                        item
                        container
                        xs={12}
                        sm={6}
                    >
                        <Grid item xs={12}>
                            <HeaderPage
                                title={t('account.plan.title')}
                                underlined={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AccountPlan accountPlanProps={accountPlanProps} />
                        </Grid>
                        <Grid item xs={12}>
                            <NavLink to="/">
                                <Typography
                                    className={classes.link}
                                    variant="body1"
                                >
                                    {t('account.plan.change')}
                                </Typography>
                            </NavLink>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

PaymentSuccess.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    subscription: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    getSubscriptionVerification: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
};

PaymentSuccess.defaultProps = {
    errors: [],
};

export const StyledAccountScreen = withStyles(styles)(PaymentSuccess);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    errors: getBillingSessionErrors(state),
    loading: getBillingSessionLoading(state),
    subscription: getCurrentSubscription(state),
    invoice: getLatestInvoice(state),
});

const mapDispatchToProps = (dispatch) => ({
    getSubscriptionVerification: (communityId, planId) =>
        dispatch(subscriptionVerificationRequested(communityId, planId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledAccountScreen);
