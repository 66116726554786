const styles = (theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flex: 1,
        display: 'inline-flex',
    },
    navBarItems: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 40,
        display: 'flex',
    },
    navBarContainer: {
        paddingLeft: '10vw',
        paddingRight: '10vw',
        paddingTop: 20,
        paddingBottom: 20,
        background: theme.palette.primary.main,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        display: 'inline-flex',
        width: '100%',
    },
    navBarLogoContainer: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20vw',
        display: 'inline-flex',
    },
    navBarItemContainer: {
        flex: 1,
    },
    navBarItem: {
        flex: 1,
        alignSelf: 'flex-end',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Effra',
        fontWeight: '700',
        textTransform: 'uppercase',
        letterSpacing: 3.2,
        wordWrap: 'break-word',
    },

    header: {
        backgroundColor: theme.palette.secondary.main,
        container: {
            gap: '3.75rem',
            display: 'flex',
        },
    },
    logoContainer: { flex: 1 },

    headerContentContainer: {
        paddingBottom: '32px',
        paddingTop: '1rem',
        paddingLeft: '10vw',
        paddingRight: '3vw',
        padding: '0',
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'inline-flex',
        flex: 1,
    },
    storeDescriptionContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 54,
        flex: 1,
        display: 'inline-flex',
    },
    storeDescriptionContainerInner: {
        maxHeight: '60%',
        textOverflow: 'hidden',
    },
    storeMockupContainer: {
        flex: 2,
    },
    storeMockup: {
        minWidth: '30vw',
        borderRadius: 30,
        flex: 1,
    },
    appMockup: {
        minWidth: '200',
        minHeight: '400',
        flex: 1,
        borderRadius: 30,
        height: '55vw',
        width: '80vw',
    },
    appMockupContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 30,
        display: 'inline-flex',
        paddingBottom: 140,
        paddingTop: '4vw',
    },
    featureContainer: {
        background: '#F4F4F5',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 10,
        display: 'inline-flex',
        flex: 1,
        width: '100%',
    },
    featureContainerBackgroundContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    featureContainerBackground: {
        transform: 'translateX(-50%)',
        left: '50%',
        top: 0,
        position: 'absolute',
        opacity: 0.15,
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: 'clamp(3rem, 6.0vw, 10rem)',
        fontFamily: 'Effra',
        fontWeight: '900',
        wordWrap: 'break-word',
    },
    storeDescriptionTitle: {
        color: theme.custom.bgOnboardingFooter,
        fontSize: 'clamp(2rem, 3.0vw, 10rem)',
        textTransform: 'uppercase',
        wordWrap: 'break-word',
    },
    storeDescriptionAppName: {
        color: theme.palette.primary.main,
        fontWeight: '900',
    },
    storeDescriptionText: {
        color: 'black',
        fontSize: 22,
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    footer: {
        padding: '10px 0 16px 0',
        backgroundColor: theme.custom.bgOnboardingFooter,
    },
    footerText: {
        color: theme.palette.white.main,
        opacity: 0.75,
    },
    footerLogo: {
        width: '4rem',
        // height: 50
    },
    downloadContainer: {
        justifyContent: 'flex-start',
    },
});

export default styles;
