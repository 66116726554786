import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Modal from '../Modal';
import Button from '../Button';
import styles from './styles';

const ErrorModal = ({ classes, error, isOpen, onClose, onCancel }) => {
    const { t } = useTranslation();

    const getErrorTitle = () => {
        const errorText = t('error.descriptor');
        const { status = false } = error;

        if (status) {
            const errorStatusDescription = t(`error.${status}`, '');
            if (errorStatusDescription) {
                return `${status} - ${errorStatusDescription}`;
            } else {
                return `${status} - ${errorText}`;
            }
        }

        return errorText;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Grid container className={classes.container}>
                <Grid
                    container
                    item
                    className={classes.header}
                    justify="space-between"
                >
                    <Typography variant="h4" className={classes.title}>
                        {getErrorTitle()}
                    </Typography>
                    <Close
                        className={classes.icon}
                        fontSize="small"
                        onClick={onClose}
                    />
                </Grid>
                <Grid container item className={classes.main}>
                    <Typography className={classes.topMessage}>
                        {error.message}
                    </Typography>
                    <Typography className={classes.bottomMessage}>
                        {t('serverErrorDefaultMessage')}
                        <a
                            className={classes.email}
                            href="mailto:support@joulebug.com?Subject=Dashboard%20Error"
                        >
                            support@joulebug.com
                        </a>
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    className={classes.footer}
                    justify="flex-end"
                >
                    <Button onClick={onCancel}>{t('cancel')}</Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

ErrorModal.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.shape({
        status: PropTypes.number,
        message: PropTypes.string.isRequired,
    }).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(ErrorModal);
