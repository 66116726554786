const styles = (theme) => ({
    container: {
        paddingBottom: '32px',
        padding: '0',
    },
    tabbedItem: {
        [theme.breakpoints.up('sm')]: {
            width: theme.custom.mainContentWidth,
        },
        marginTop: 2,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    tabBox: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    noDataLabel: {
        marginLeft: '25%',
        width: '50%',
        color: theme.custom.textDarkColor,
        textAlign: 'center',
        opacity: '.5',
    },
    errorStyles: {
        flex: 1,
        alignItems: 'center',
    },
    scoringExplanationText: {
        color: theme.custom.textDarkColor,
        paddingBottom: 5,
        fontSize: 16,
    },
    icon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '3px',

        '&:hover': {
            cursor: 'pointer',
        },
    },
});

export default styles;
