const styles = (theme) => ({
    container: {
        paddingTop: '9px',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    counterContainer: { paddingBottom: '30px' },
    pageNameContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    pageName: {
        lineHeight: 0.5,
        fontSize: '28px',
        fontWeight: 700,
    },
    header: {
        lineHeight: '1.1',
        fontWeight: 600,
    },
    title: {
        margin: '0px 0 10px',
        fontSize: '36px',

        [theme.breakpoints.up('sm')]: {
            fontSize: '64px',
        },
    },
    text: {
        marginBottom: '15px',
        fontSize: '21px',
        fontWeight: 200,
    },
    proTipsSection: {
        marginBottom: '30px',
        marginTop: '30px',
        marginLeft: '5%',
        marginRight: '5%',
    },
    photoSection: {
        marginTop: '20px',
    },
    photoSectionTitle: {
        fontWeight: '600',
        marginBottom: '20px',
    },
    placeholderTextContainer: {
        textAlign: 'center',
    },
    placeholderText: {
        fontWeight: 600,
        color: theme.custom.textLightSecondaryColor,
    },
    analyticsDatePickerContainer: {
        margin: '20px 0px',
    },
    graphContainer: {
        // padding: '30px',
    },
    emptyTextContainer: {
        textAlign: 'center',
    },
    emptyText: {
        fontWeight: 600,
        color: theme.custom.textLightSecondaryColor,
    },
    activeChallengeWrapper: {
        background: `${theme.palette.white.main} 0% 0% no-repeat padding-box`,
        boxShadow: '3px 5px 10px #00000026',
        border: '1px solid #DBDBDB',
        borderRadius: '5px',
        opacity: 1,
        margin: '0 0 20px 0',
        padding: '20px',
        width: '100%',
    },
    contentLoaderContainer: {
        width: '100%',
        height: 388,
    },
    visualItemIcon: {
        width: '20px',
        marginTop: '-2px',
    },
});

export default styles;
