const styles = (theme) => ({
    modal: {
        backgroundColor: theme.custom.bgLightAccentColor,
        padding: '5px',
        minWidth: '650px',
        minHeight: '300px',
    },
    scrollPaper: {
        alignItems: 'flex-start',
    },
    titleBar: {
        padding: '15px',
        fontSize: '18px',
        fontWeight: 100,
    },
    title: {},
    closeButton: {},
    closeIcon: {
        opacity: 0.2,

        '&:hover': {
            opacity: 0.5,
            cursor: 'pointer',
        },
    },
    summary: {},
    summaryImage: {
        margin: '10px',
        width: '64px',
        height: '64px',
    },
    summaryPoints: {
        fontSize: '40px',
        paddingRight: '5px',
    },
    ok: {
        marginTop: '40px',
        marginBottom: '15px',
    },
    okButton: {
        width: '60%',
    },
    icon: {
        paddingRight: '5px',
        fontSize: '24px',
    },
    share: { height: '46px' },
});

export default styles;
