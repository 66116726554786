import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    panel: {
        width: '100%',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 0,
        },
    },
    panelSummary: (props) => ({
        backgroundColor: theme.custom.grey93,
        padding: 0,
        minHeight: 'auto',

        '&.Mui-expanded': {
            minHeight: '60px',
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },
    }),
    content: {
        width: '100%',
        margin: 0,

        '&$expanded': {
            margin: 0,
        },
    },
    panelDetails: {
        padding: 0,
        backgroundColor: theme.custom.bgLightColor,
    },
    panelWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '34px',
        padding: '0 15px 5px 40px',
        borderLeft: `1px solid ${theme.custom.bgAccentColor}`,
    },
    expanded: {
        margin: 0,
    },
    link: {
        marginBottom: '10px',
        textDecoration: 'none',
        color: theme.custom.darkGrey,
        backgroundColor: theme.custom.bgLightColor,
    },
    activeLink: {
        color: theme.palette.primary.main,
    },
    titleLink: (props) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        boxSizing: 'border-box',
        fontSize: '18px',
        textDecoration: 'none',
        color:
            (props.match && props.match.url) === props.titleUrl
                ? theme.palette.primary.main
                : theme.custom.textDarkSecondaryMutedColor,
        fill:
            (props.match && props.match.url) === props.titleUrl
                ? theme.palette.primary.main
                : theme.custom.textDarkSecondaryMutedColor,

        '&:hover': {
            color:
                (props.match && props.match.url) !== props.titleUrl
                    ? theme.custom.textDarkSecondaryColor
                    : theme.palette.primary.main,

            fill:
                (props.match && props.match.url) !== props.titleUrl
                    ? theme.custom.textDarkSecondaryColor
                    : theme.palette.primary.main,
        },
    }),
    titleLeftPartLink: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: '100%',
    },
    titleArrowWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        marginRight: '10px',
    },
    titleArrow: (props) => ({
        transition: 'transform 0.3s linear',
        transform: props.expanded && 'rotate(90deg)',
    }),
    titleIcon: {
        marginLeft: '25px',
        marginRight: '25px',
    },
}));

export default useStyles;
