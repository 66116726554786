/* eslint-disable no-undefined */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { findIndex, propEq, remove } from 'ramda';
import { useTranslation } from 'react-i18next';

import IMAGES from '../../constants/images';
import styles from './styles';
import CommonPropTypes from '../../common/propTypes';
import {
    actionCustomizationRequested,
    actionRequested,
    clearActionCustomizationErrors,
    setUrlMetadata,
} from '../../actions/actions';
import ActionHeader from './ActionHeader';
import BonusPoints from '../../common/BonusPoints';
import {
    getAction,
    getActionErrors,
    getCustomizationErrors,
    getCustomizationMessage,
    isActionLoading,
} from '../../actions/selectors';
import { getActiveCommunity } from '../../user/selectors';
import ActionDescription from '../../common/ActionDescription';
import AddLink from './AddLink';
import MainControls from './MainControls';
import Description from './Description';
import PinSubheader from './PinSubheader';
import ActionCards from './ActionCards';
import UpdateCoverPhoto from '../../common/UpdateCoverPhoto';
import ActionVideo from '../../common/ActionVideo';
import Videos from './Videos';
import PhotoPropTypes from '../../common/Photo/propTypes';
import ConfirmModal from '../../common/ConfirmModal';
import EditingBanner from '../../common/EditingBanner';
import { actionPropType } from '../../actions/propTypes';
import { getActionDuplicationCommunities } from '../SettingsScreen/selectors';
import {
    activeCommunityPropType,
    availableCommunitiesPropType,
} from '../../user/propTypes';
import ActionDuplicationModal from '../../common/ActionDuplicationModal';
import { transformImageSizes } from '../../common/transforms';
import { useRouteMatch } from 'react-router-dom';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';

const Loader = () => (
    <Grid container justify="center" alignContent="center">
        <img src={IMAGES.loader} alt="loader" />
    </Grid>
);

const Errors = ({ errors }) => (
    <>
        {errors.map((err, i) => (
            <Typography key={i}>{err}</Typography>
        ))}
    </>
);

const UpdateActionScreen = ({
    classes,
    activeCommunity,
    action,
    isLoading,
    errors,
    loadFunc,
    requestCustomization,
    setMetadata,
    PhotoProps,
    actionDuplicationCommunities,
    message,
    customizationErrors,
    clearSnackBarErrors,
}) => {
    const { t, i18n } = useTranslation();
    const {
        params: { actionId },
    } = useRouteMatch();
    const [snackBarMessage, setSnackBarMessage] = useState();

    useEffect(() => {
        loadFunc({ actionId, communityId: activeCommunity.id });
    }, [actionId, activeCommunity.id, loadFunc]);

    const [headlineImage, setHeadlineImage] = useState(null);
    const [headline, setHeadline] = useState(action.headlinePhotoUrl);
    const [author, setAuthor] = useState(action.headlinePhotoAuthor);
    const [editVideo, setEditVideo] = useState(null);
    const resetHeadline = useCallback(() => {
        setHeadlineImage(null);
        setHeadline(action.headlinePhotoUrl);
        setAuthor(action.headlinePhotoAuthor);
    }, [
        setHeadline,
        action.headlinePhotoUrl,
        setAuthor,
        action.headlinePhotoAuthor,
        setHeadlineImage,
    ]);
    const headlinePristine = useMemo(
        () => headline === action.headlinePhotoUrl,
        [headline, action.headlinePhotoUrl],
    );
    const [editHeadlineShown, setEditHeadlineShown] = useState(false);

    const [newDescription, setNewDescription] = useState(null);
    const [descriptionData, setDescriptionData] = useState(null);
    const resetDescription = useCallback(() => {
        setNewDescription(action.description);
        setDescriptionData(null);
    }, [setNewDescription, action.description]);

    const revertDescription = useCallback(() => {
        setNewDescription(action.originalDescription);
        setDescriptionData([]);
    }, [setNewDescription, action.originalDescription]);

    const descriptionPristine = useMemo(
        () => action.description === newDescription,
        [action.description, newDescription],
    );

    const [newLinks, setNewLinks] = useState(null);

    const resetLinks = useCallback(() => {
        setNewLinks(action.links);
    }, [setNewLinks, action.links]);

    const linksPristine = useMemo(() => action.links === newLinks, [
        action.links,
        newLinks,
    ]);

    const [newVideos, setNewVideos] = useState(null);
    const resetVideos = useCallback(() => {
        setNewVideos(action.videos);
    }, [setNewVideos, action.videos]);
    const videosPristine = useMemo(() => action.videos === newVideos, [
        action.videos,
        newVideos,
    ]);

    const [isEditDescriptionShown, setEditDescriptionShown] = useState(false);
    const [isAddLinkShown, setAddLinkShown] = useState(false);
    const [isAddVideoShown, setAddVideoShown] = useState(false);
    const [linkEditModeEnabled, setLinkEditModeEnabled] = useState(false);

    const [resetModalOpen, setResetModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const descriptionPreview = useMemo(
        () => ({
            name: action.name,
            description: newDescription,
            imageLink: action.iconUrl,
        }),
        [action.name, newDescription, action.iconUrl],
    );

    const handleDescriptionEdit = useCallback(
        (descriptionEdit) => {
            setEditDescriptionShown(false);
            setNewDescription(descriptionEdit.fullDescription);
            setDescriptionData([
                {
                    description: {
                        [i18n.language]: descriptionEdit.description,
                    },
                    textPlacement: descriptionEdit.textPlacement,
                },
            ]);
        },
        [
            setEditDescriptionShown,
            setNewDescription,
            setDescriptionData,
            i18n.language,
        ],
    );

    const handleLinkAddOrUpdate = useCallback(
        (link) => {
            const {
                url: linkUrl,
                title: name,
                description,
                sizes,
                imageUrl,
                sponsorId,
                custom,
                key = Date.now(),
            } = link;
            const newLink = {
                name,
                description,
                imageUrl,
                linkUrl,
                sponsorId,
                isCommunity: true,
                sizes,
                key,
                custom,
            };
            const indexToReplace = findIndex(propEq('key', key))(newLinks);
            const test =
                indexToReplace > -1
                    ? newLinks.map((existingLink) => {
                          if (newLink.key === existingLink.key) {
                              return newLink;
                          } else {
                              return existingLink;
                          }
                      })
                    : [newLink, ...newLinks];
            setNewLinks(test);
            setAddLinkShown(false);
            setLinkEditModeEnabled(false);
        },
        [setAddLinkShown, setNewLinks, newLinks],
    );

    const allPristine = useMemo(
        () =>
            descriptionPristine &&
            linksPristine &&
            videosPristine &&
            headlinePristine,
        [descriptionPristine, linksPristine, videosPristine, headlinePristine],
    );
    const resetAll = useCallback(() => {
        resetDescription();
        resetLinks();
        resetVideos();
        resetHeadline();
    }, [resetDescription, resetLinks, resetVideos, resetHeadline]);

    useEffect(() => {
        resetAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action.name]);

    useEffect(() => {
        if (message) {
            setSnackBarMessage(message);
        }
    }, [message, t]);

    const handleLinkRemove = useCallback(
        (key) => {
            const indexToRemove = findIndex(propEq('key', key))(newLinks);
            setNewLinks(remove(indexToRemove, 1, newLinks));
        },
        [setNewLinks, newLinks],
    );

    const handleVideoRemove = useCallback(
        (index) => {
            setNewVideos([
                ...newVideos.slice(0, index),
                ...newVideos.slice(index + 1),
            ]);
        },
        [setNewVideos, newVideos],
    );

    const handleVideoEdit = useCallback(
        (index) => {
            const video = newVideos[index];
            setEditVideo({ ...video, index });
            setAddVideoShown(true);
        },
        [newVideos],
    );

    const handleAddOrUpdateVideoShown = useCallback((value) => {
        if (!value) {
            setEditVideo(null);
        }
        setAddVideoShown(value);
    }, []);

    const handleAddOrUpdateVideo = useCallback(
        ({ name, description, video }) => {
            setAddVideoShown(false);
            const newVideo = {
                name,
                description,
                videoId: video,
                isCommunity: true,
            };

            setEditVideo(null);
            setNewVideos(
                newVideos.reduce(
                    (result, item, index) =>
                        index === editVideo?.index
                            ? [...result, newVideo]
                            : [...result, item],
                    editVideo ? [] : [newVideo],
                ),
            );
        },
        [newVideos, editVideo],
    );

    const handleSave = useCallback(
        (actionDuplicationCommunityIds) => {
            if (
                actionDuplicationCommunities.length > 0 &&
                confirmModalOpen === false
            ) {
                return setConfirmModalOpen(true);
            }
            const requestBody = {
                communityId: activeCommunity.id,
                actionId,
                videos: newVideos
                    .filter(({ isCommunity }) => !!isCommunity)
                    .map((video) => ({
                        community: {
                            id: activeCommunity.id,
                        },
                        description: {
                            [i18n.language]: video.description,
                        },
                        internalName: video.name,
                        name: {
                            [i18n.language]: video.name,
                        },
                        videoID: video.videoId,
                    })),
                urls: newLinks
                    .filter(({ isCommunity }) => !!isCommunity)
                    .map((link) => ({
                        autoPreview: true,
                        community: {
                            id: activeCommunity.id,
                        },
                        description: {
                            [i18n.language]: link.description,
                        },
                        internalName: link.name,
                        name: {
                            [i18n.language]: link.name,
                        },
                        updateMetadataAutomatically: !link.custom,
                        sponsorId: link.sponsorId || '',
                        url: link.linkUrl,
                        urlType: null,
                        image: {
                            sizes:
                                transformImageSizes(
                                    link.sizes === null ||
                                        link.sizes === undefined
                                        ? []
                                        : link.sizes,
                                ) || [],
                        },
                    })),
            };
            if (headlineImage != null) {
                requestBody.photos = [
                    {
                        community: {
                            id: activeCommunity.id,
                        },
                        photo: {
                            sizes: headlineImage,
                        },
                        photoSizes: headlineImage,
                        photoAuthorName: author,
                        id: action.headlinePhotoId,
                    },
                ];
            }
            if (descriptionData != null) {
                requestBody.descriptions = descriptionData;
            }
            if (
                actionDuplicationCommunities.length > 0 &&
                actionDuplicationCommunityIds?.length > 0
            ) {
                requestBody.actionDuplicationCommunityIds = actionDuplicationCommunityIds;
            }
            requestCustomization(requestBody);
            setConfirmModalOpen(false);
        },
        [
            newLinks,
            confirmModalOpen,
            activeCommunity.id,
            descriptionData,
            requestCustomization,
            actionId,
            newVideos,
            headlineImage,
            author,
            action.headlinePhotoId,
            actionDuplicationCommunities,
            i18n.language,
        ],
    );

    const handleLinkEdit = useCallback(
        (key) => {
            const link = newLinks.find(
                ({ key: currentKey }) => currentKey === key,
            );
            setMetadata({
                url: link?.linkUrl,
                title: link?.name,
                description: link?.description,
                sponsorId: link?.sponsorId,
                imageUrl: link?.imageUrl,
                updateMetadataAutomatically: link?.updateMetadataAutomatically,
                image: {
                    sizes: link?.sizes,
                },
                key: link?.key,
            });
            setLinkEditModeEnabled(true);
            setAddLinkShown(true);
        },
        [setMetadata, newLinks, setLinkEditModeEnabled, setAddLinkShown],
    );

    return (
        <Grid className={classes.root}>
            {isLoading && <Loader />}
            {errors && <Errors errors={errors} />}
            {!isLoading && !errors && action.name && (
                <>
                    <ActionDescription
                        isOpen={isEditDescriptionShown}
                        setOpen={setEditDescriptionShown}
                        onConfirm={handleDescriptionEdit}
                        preview={descriptionPreview}
                        originalDescription={action.originalDescription}
                    />
                    {isAddLinkShown ? (
                        <AddLink
                            isOpen={isAddLinkShown}
                            onCancel={() => setAddLinkShown(false)}
                            onClose={() => setAddLinkShown(false)}
                            onConfirm={handleLinkAddOrUpdate}
                            editMode={linkEditModeEnabled}
                            sponsor={action.sponsor}
                        />
                    ) : null}
                    {isAddVideoShown ? (
                        <ActionVideo
                            initialVideo={editVideo}
                            isOpen={isAddVideoShown}
                            setOpen={handleAddOrUpdateVideoShown}
                            onConfirm={handleAddOrUpdateVideo}
                        />
                    ) : null}
                    <UpdateCoverPhoto
                        isOpen={editHeadlineShown}
                        setOpen={setEditHeadlineShown}
                        author={author}
                        photo={headline}
                        onConfirm={({ image, author: newAuthor }) => {
                            setHeadlineImage(image);
                            if (image && image.length) {
                                setHeadline(image[0].file.url);
                            }
                            setAuthor(newAuthor);
                            setEditHeadlineShown(false);
                        }}
                        PhotoProps={PhotoProps}
                    />
                    <ActionHeader
                        actionId={actionId}
                        communityId={activeCommunity.id}
                        headlineUrl={headline}
                        iconUrl={action.iconUrl}
                        name={action.name}
                        allowReset={!headlinePristine}
                        onReset={resetHeadline}
                        onChangePhoto={() => setEditHeadlineShown(true)}
                    />
                    <Grid container justify="center">
                        <Grid
                            container
                            item
                            direction="column"
                            className={classes.container}
                        >
                            <Grid item>
                                <EditingBanner
                                    actionDuplicationCommunities={
                                        actionDuplicationCommunities
                                    }
                                />
                            </Grid>
                            <MainControls
                                discardDisabled={allPristine}
                                saveDisabled={allPristine}
                                onDiscard={() => setResetModalOpen(true)}
                                onSave={handleSave}
                            />
                            <Description
                                description={newDescription}
                                onEdit={() => setEditDescriptionShown(true)}
                                onReset={revertDescription}
                            />
                            <PinSubheader
                                title={t('BonusPoints')}
                                imageSrc={IMAGES.plusOneBonusPoints}
                                imageAlt="Bonus Points"
                                editAction="CommunityActionBonuses"
                            />
                            <Grid container item direction="row">
                                {action.contentBonuses.map((bonus, index) => (
                                    <BonusPoints
                                        key={index}
                                        count={bonus.value}
                                        imageUrl={bonus.imageUrl}
                                        title={bonus.name}
                                    />
                                ))}
                            </Grid>
                            {action.sustainableDevelopmentGoals?.length > 0 && (
                                <>
                                    <PinSubheader
                                        title={t(
                                            'action.sustainableDevelopmentGoals',
                                        )}
                                        editAction="CommunitySustainableDevelopmentGoals"
                                    />
                                    <ActionCards
                                        content={
                                            action.sustainableDevelopmentGoals
                                        }
                                    />
                                </>
                            )}
                            <PinSubheader
                                title={t('Videos')}
                                addText={t('Add Video')}
                                imageSrc={IMAGES.videosIcon}
                                imageAlt="Videos"
                                editAction="CommunityActionYoutubes"
                                onAdd={() => setAddVideoShown(true)}
                            />
                            <Videos
                                videos={newVideos}
                                onVideoEdit={handleVideoEdit}
                                onVideoRemove={handleVideoRemove}
                            />
                            <PinSubheader
                                title={t('Links')}
                                addText={t('Add Link')}
                                imageSrc={IMAGES.linksIcon}
                                imageAlt="Links"
                                editAction="CommunityActionUrls"
                                onAdd={() => setAddLinkShown(true)}
                            />
                            <ActionCards
                                sponsorLogo={action.sponsor?.imageUrl}
                                content={newLinks}
                                onEdit={handleLinkEdit}
                                onRemove={handleLinkRemove}
                            />
                        </Grid>
                    </Grid>
                    <ConfirmModal
                        isOpen={resetModalOpen}
                        title={t('confirmReset')}
                        onClose={() => setResetModalOpen(false)}
                        onCancel={() => setResetModalOpen(false)}
                        onConfirm={() => {
                            resetAll();
                            setResetModalOpen(false);
                        }}
                        cancelBtnType="default"
                        cancelBtnText={t('cancel')}
                        confirmBtnType="primary"
                        confirmBtnText={t('confirm')}
                    >
                        {t('confirmDiscard')}
                    </ConfirmModal>
                    {actionDuplicationCommunities.length > 0 && (
                        <ActionDuplicationModal
                            activeCommunity={activeCommunity}
                            actionDuplicationCommunities={
                                actionDuplicationCommunities
                            }
                            onCancel={() => setConfirmModalOpen(false)}
                            onClose={() => setConfirmModalOpen(false)}
                            onConfirm={(communityIds) =>
                                handleSave(communityIds)
                            }
                            isOpen={confirmModalOpen}
                        />
                    )}
                </>
            )}
            <SnackBarAPIResponse
                messages={snackBarMessage}
                errors={customizationErrors}
                clearResponse={() => {
                    setSnackBarMessage(null);
                    clearSnackBarErrors();
                }}
            />
        </Grid>
    );
};

UpdateActionScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    activeCommunity: activeCommunityPropType.isRequired,
    action: actionPropType,
    isLoading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    loadFunc: PropTypes.func.isRequired,
    requestCustomization: PropTypes.func.isRequired,
    setMetadata: PropTypes.func.isRequired,
    actionDuplicationCommunities: availableCommunitiesPropType.isRequired,
    PhotoProps: PhotoPropTypes.photo,
    message: PropTypes.string.isRequired,
    customizationErrors: PropTypes.arrayOf(PropTypes.string),
    clearSnackBarErrors: PropTypes.func.isRequired,
};

UpdateActionScreen.defaultProps = {
    errors: null,
    customizationErrors: null,
    PhotoProps: {
        photoTooltipKey: 'coverPhoto.imageHelp',
    },
    action: {},
};

const StyledUpdateActionScreen = withStyles(styles)(UpdateActionScreen);

const mapStateToProps = (state) => ({
    activeCommunity: getActiveCommunity(state),
    action: getAction(state),
    isLoading: isActionLoading(state),
    errors: getActionErrors(state),
    actionDuplicationCommunities: getActionDuplicationCommunities(state),
    message: getCustomizationMessage(state),
    customizationErrors: getCustomizationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadFunc: ({ actionId, communityId }) =>
        dispatch(actionRequested({ actionId, communityId })),
    requestCustomization: (request) =>
        dispatch(actionCustomizationRequested(request)),
    setMetadata: (linkId) => dispatch(setUrlMetadata(linkId)),
    clearSnackBarErrors: () => dispatch(clearActionCustomizationErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledUpdateActionScreen);
