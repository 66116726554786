import { Grid, Typography, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import Button from '../../../../common/Button';
import Table from '../../../../common/Table';
import { PRIZE_RANK_OPTIONS, prizeButtonsCell } from '../constants';
import styles from '../styles';
const defaultPrizeImageUrl = require('../../../../assets/images/defaultImage.png');

const PrizeTable = ({
    classes,
    onEditPrizes,
    edit,
    prizes,
    t,
    can,
    onDeletePrizes,
}) => {
    const makePrizeColumns = () => [
        {
            accessor: 'imageSizes',
            Cell: ({ value }) => (
                <Grid style={{ position: 'relative' }}>
                    <img
                        src={
                            value[0]?.url ||
                            value[0]?.file?.url ||
                            defaultPrizeImageUrl
                        }
                        width={42}
                        height={42}
                        alt={t('challenge.alt.prizeIcon')}
                        className={classes.prizeIcon}
                    />
                </Grid>
            ),
            sortable: false,
        },
        {
            Header: t('challenge.prizeTable.name'),
            accessor: 'name',
            Cell: ({ value }) => (
                <div className={classes.prizeName}>{value}</div>
            ),
        },
        {
            Header: t('challenge.prizeTable.rank'),
            accessor: 'rank',
            Cell: ({ value }) => (
                <div>
                    {
                        PRIZE_RANK_OPTIONS.find((opt) => opt.value === value)
                            ?.name
                    }
                </div>
            ),
            align: 'left',
        },
        {
            Header: t('challenge.prizeTable.description'),
            accessor: 'description',
            Cell: ({ value }) => <div>{value}</div>,
            align: 'left',
        },
        { accessor: 'actions' },
    ];

    const prizeColumns = makePrizeColumns();

    const handlePrizeActions = useCallback(
        (id, key) => {
            switch (key) {
                case 'editChallengePrizes':
                    onEditPrizes(id);
                    break;

                case 'deleteChallengePrizes':
                    onDeletePrizes(id);
                    break;
                default:
                    alert({ id, key });
            }
        },
        [onEditPrizes, onDeletePrizes],
    );

    return (
        <Grid
            container
            item
            direction="column"
            className={clsx(edit(can) && classes.editable)}
            data-intercom-target="Challenge Info Edit Prizes"
        >
            <Grid item className={classes.rulesLabel}>
                {t('challenge.info.prizes')}
            </Grid>
            <Typography>
                {t('challenge.prizeTable.tableDescription')}
            </Typography>
            <Table
                columns={prizeColumns}
                data={prizes}
                initialSortBy={[
                    {
                        id: 'rank',
                        desc: false,
                    },
                ]}
                buttonsCell={edit(can) ? prizeButtonsCell : []}
                onButtonsCell={edit(can) ? handlePrizeActions : []}
                clientPagination
            />
            {edit(can) && (
                <Grid item xs={2}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.addButton}
                        onClick={() => onEditPrizes('')}
                        data-intercom-target="addPrizeButton"
                        textColor="#fff"
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="row"
                            className={classes.buttonText}
                        >
                            <Add />
                            {t('challenge.prizeTable.addPrize')}
                        </Grid>
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default withStyles(styles)(PrizeTable);
