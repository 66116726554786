import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import useStyles from './styles';
import ModalTitle from '../ModalTitle';
import FieldLabel from '../FieldLabel';

import ConfirmModal from '../ConfirmModal';
import { noop } from '../../utils';
import { BUTTON_ACCENT_COLOR, BUTTON_DEFAULT_COLOR } from '../Button/constants';
import FormInput from '../Form/FormInput';
import Photo from '../Photo';
import PhotoPropTypes from '../Photo/propTypes';
import CommonPropTypes from '../propTypes';
import { connect } from 'react-redux';
import {
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../image/selectors';
import { imageUploadRequested } from '../../image/actions';
import { getActiveCommunityId } from '../../user/selectors';

const UpdateCoverPhoto = ({
    communityId,
    isOpen,
    setOpen,
    author: initialAuthor,
    photo: initialPhoto,
    requestImageUpload,
    uploadRequestPending,
    uploadErrors,
    uploadResponse,
    onClose,
    onCancel,
    onConfirm,
    fileTooLargeErrorMessage,
    DropzoneProps,
    PhotoProps,
    CropProps,
    initCrop,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const [croppedData, setCroppedData] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    useEffect(() => {
        setCroppedData(null);
        setUploadedImage(null);
    }, [isOpen]);

    useEffect(() => {
        if (croppedData != null) {
            requestImageUpload({ communityId, file: croppedData });
        }
    }, [requestImageUpload, communityId, croppedData]);

    useEffect(() => {
        if (
            !uploadRequestPending &&
            uploadErrors == null &&
            uploadResponse != null
        ) {
            setUploadedImage(uploadResponse);
        }
    }, [uploadRequestPending, uploadErrors, uploadResponse, setUploadedImage]);

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
    }, [onClose, setOpen]);

    const handleCancel = useCallback(() => {
        onCancel();
        setOpen(false);
    }, [onCancel, setOpen]);

    const handleConfirm = useCallback(
        (formik) => {
            onConfirm({
                image: uploadedImage,
                author: formik.values.author,
            });
        },
        [onConfirm, uploadedImage],
    );

    const errorAuthorRequired = useMemo(() => t('coverPhoto.authorRequired'), [
        t,
    ]);

    const initialValues = useMemo(
        () => ({
            author: initialAuthor,
        }),
        [initialAuthor],
    );

    const validationSchema = Yup.object().shape({
        author: Yup.string()
            .trim()
            .ensure()
            .required(errorAuthorRequired),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={noop}
        >
            {(formikProps) => (
                <ConfirmModal
                    isOpen={isOpen}
                    title={
                        <ModalTitle
                            text={t('coverPhoto.edit')}
                            tooltipText={t('viewDocs')}
                            tooltipPlacement="right"
                        />
                    }
                    confirmBtnText={t('OK')}
                    confirmBtnType={BUTTON_ACCENT_COLOR}
                    confirmBtnDisabled={!formikProps.isValid}
                    cancelBtnText={t('cancel')}
                    cancelBtnType={BUTTON_DEFAULT_COLOR}
                    onClose={handleClose}
                    onCancel={handleCancel}
                    onConfirm={() => handleConfirm(formikProps)}
                >
                    <Form className={classes.form}>
                        <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            spacing={1}
                        >
                            <Grid container item direction="column">
                                <Photo
                                    title={t('image')}
                                    photo={initialPhoto}
                                    setCroppedImageData={setCroppedData}
                                    DropzoneProps={DropzoneProps}
                                    fileTooLargeErrorMessage={
                                        fileTooLargeErrorMessage
                                    }
                                    CropProps={CropProps}
                                    initCrop={initCrop}
                                    {...PhotoProps}
                                />
                            </Grid>
                            <Grid container item direction="column">
                                <Grid item>
                                    <FieldLabel
                                        text={t('coverPhoto.author')}
                                        tooltipText={t('coverPhoto.authorHelp')}
                                        tooltipPlacement="right"
                                        intercomTarget="coverPhotoAuthorLabel"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormInput noLeftMargin name="author" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </ConfirmModal>
            )}
        </Formik>
    );
};

UpdateCoverPhoto.propTypes = {
    communityId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    author: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    requestImageUpload: PropTypes.func.isRequired,
    uploadRequestPending: PropTypes.bool.isRequired,
    uploadErrors: CommonPropTypes.errors,
    uploadResponse: PropTypes.object,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,

    // See https://react-dropzone.js.org/
    DropzoneProps: PropTypes.object,

    PhotoProps: PhotoPropTypes.photo,
    fileTooLargeErrorMessage: PropTypes.string,

    // See https://www.npmjs.com/package/react-image-crop
    CropProps: PropTypes.object,
    initCrop: PropTypes.object,
};

UpdateCoverPhoto.defaultProps = {
    uploadErrors: null,
    uploadResponse: null,
    onClose: noop,
    onCancel: noop,
    DropzoneProps: null,
    fileTooLargeErrorMessage: null,
    PhotoProps: {},
    CropProps: {
        minWidth: 800,
        minHeight: 500,
        maxWidth: 1080,
        maxHeight: 1920,
    },
    initCrop: {
        aspect: 16 / 9,
        width: 500,
        unit: 'px',
    },
};

export { UpdateCoverPhoto as DumbUpdateCoverPhoto };

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    uploadRequestPending: isImageUploadRequestPending(state),
    uploadErrors: getImageUploadErrors(state),
    uploadResponse: getImageUploadResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestImageUpload: (request) => dispatch(imageUploadRequested(request)),
});

const ConnectedUpdateCoverPhoto = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateCoverPhoto);
export default ConnectedUpdateCoverPhoto;
