import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import Button from '../Button';
import NextAndBackButtons from './NextAndBackButtons';
import { useTranslation } from 'react-i18next';

const ShineForm = ({
    children,
    classes,
    initialValues,
    validator,
    validationSchema,
    onSubmit,
    submitBtnType,
    submitBtnColor,
    submitBtnTextColor,
    submitBtnText,
    enableReinitialize,
    hasNext,
    onBack,
    loading,
    disabled,
    submitBtnClass,
}) => {
    const { t } = useTranslation();
    return (
        <Formik
            initialValues={initialValues}
            validate={validator}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={enableReinitialize}
        >
            {({ isValid, ...formikProps }) => (
                <Form className={classes.form}>
                    <Grid container direction="column">
                        {typeof children === 'function'
                            ? children(formikProps)
                            : children}
                        {onBack ? (
                            <NextAndBackButtons
                                onBack={onBack}
                                isValid={isValid}
                                submitBtnText={submitBtnText}
                            />
                        ) : (
                            <Button
                                color={submitBtnType}
                                data-intercom-target="formSubmitButton"
                                btnColor={submitBtnColor}
                                textColor={submitBtnTextColor}
                                size={hasNext ? 'medium' : 'large'}
                                type="submit"
                                disabled={disabled || !isValid || loading}
                                loading={loading}
                                className={submitBtnClass}
                            >
                                {submitBtnText || t('Submit')}
                            </Button>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

ShineForm.propTypes = {
    children: PropTypes.oneOfType([
        // Specify Formik field(s)
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,

        // Can supply a function as the form children to get access to Formik props
        PropTypes.func,
    ]),
    classes: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    validator: PropTypes.func,
    validationSchema: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    submitBtnType: PropTypes.string,
    submitBtnColor: PropTypes.string,
    submitBtnTextColor: PropTypes.string,
    submitBtnText: PropTypes.string,
    enableReinitialize: PropTypes.bool,
    hasNext: PropTypes.bool,
    onBack: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    submitBtnClass: PropTypes.object,
};

ShineForm.defaultProps = {
    children: null,
    validator: null,
    validationSchema: null,
    submitBtnType: 'accent',
    submitBtnColor: null,
    submitBtnTextColor: null,
    submitBtnText: '',
    enableReinitialize: false,
    hasNext: false,
    onBack: null,
    disabled: false,
    loading: false,
    submitBtnClass: null,
};

export default withStyles(styles)(ShineForm);
