import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textarea: {
        display: 'flex',
        width: '100%',
        padding: '10px 16px',
        border: '1px solid',
        borderRadius: '8px',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.custom.inputBackground,
        fontSize: '14px',
        resize: 'vertical',
        '&:before, &:after': {
            display: 'none',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:active&:not(&:disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&:focus': {
            borderColor: theme.palette.primary.dark,
        },
    },
}));

export default useStyles;
