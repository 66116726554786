import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Typography, withStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { SNACKBAR_DURATION, SUCCESS } from './constants';
import { noop } from '../../utils';
import styles from './styles';

const SnackbarAlert = ({
    messages,
    severity,
    classes,
    clearMessages,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (messages?.length > 0) {
            setIsOpen(true);
        } else setIsOpen(false);
    }, [messages, clearMessages]);

    const onClose = () => {
        setIsOpen(false);
        clearMessages();
    };

    return (
        <Snackbar
            autoHideDuration={SNACKBAR_DURATION}
            open={isOpen}
            onClose={onClose}
            className={classes.snackBar}
            {...props}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                severity={severity}
                className={
                    severity === SUCCESS
                        ? classes.successAlert
                        : classes.errorAlert
                }
            >
                <Typography>{messages}</Typography>
            </MuiAlert>
        </Snackbar>
    );
};

SnackbarAlert.propTypes = {
    clearMessages: PropTypes.func,
    messages: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    severity: PropTypes.string,
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
};

SnackbarAlert.defaultProps = {
    clearMessages: noop,
    messages: '',
    severity: 'success',
    color: null,
};

export default withStyles(styles)(SnackbarAlert);
