import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

import ConfirmModal from '../ConfirmModal';
import { BUTTON_ACCENT_COLOR } from '../Button/constants';
import { noop } from '../../utils';

const ImagePreviewFlaggedContent = ({ isOpen, setOpen, imageUrl, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
    }, [onClose, setOpen]);

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={`${t('flags.descriptor')}: ${t('flags.imagePreview')}`}
            cancelBtnHidden
            confirmBtnText={t('close')}
            confirmBtnType={BUTTON_ACCENT_COLOR}
            centeredButtons
            onClose={handleClose}
            onConfirm={handleClose}
        >
            <div className={classes.previewContainer}>
                <img
                    src={imageUrl}
                    className={classes.previewImage}
                    alt={t('alt.flaggedImage')}
                />
            </div>
        </ConfirmModal>
    );
};

ImagePreviewFlaggedContent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    imageUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func,
};

ImagePreviewFlaggedContent.defaultProps = {
    onClose: noop,
};

export default ImagePreviewFlaggedContent;
