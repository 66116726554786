const styles = (theme) => ({
    modalTitle: {
        fontSize: '18px',
        fontWeight: '600',
    },
    modalPaper: {
        width: '300px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '24px',
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    button: {
        paddingRight: 0,
        marginLeft: 'auto',
    },
    container: {
        width: '100%',
        padding: 0,
        borderRadius: '4px',
        boxShadow:
            '0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 4px 0 rgba(0, 0, 0, 0.06)',
        boxSizing: 'border-box',
        backgroundColor: theme.custom.bgLightAccentColor,
        overflow: 'hidden',
    },
    tooltipIcon: {
        marginLeft: '5px',
        color: theme.custom.iconColor,
        fontSize: '18px',

        '&:hover': {
            cursor: 'pointer',
            color: theme.custom.iconColorHover,
        },
    },
});

export default styles;
