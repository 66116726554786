import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import {
    Collapse,
    Grid,
    Typography,
    useTheme,
    withStyles,
} from '@material-ui/core';
import { trackEvent, useTrackScreenView } from '../../utils/analytics';
import IMAGES from '../../constants/images';
import styles from './styles';
import CommonPropTypes from '../../common/propTypes';
import { actionRequested, logActionRequested } from '../../actions/actions';
import ActionHeader from './ActionHeader';
import BonusPoints from '../../common/BonusPoints';
import {
    didLogAction,
    getAction,
    getActionErrors,
    getLogActionBonuses,
    getLogActionChallengeUpdates,
    getLogActionEncouragements,
    getLogErrors,
    getLoggedActivityId,
    getLogStatus,
    getShareURL,
    isActionLoading,
    isLogActionLoading,
    isShareURLLoading,
} from '../../actions/selectors';
import Button from '../../common/Button';
import LogAction from '../../common/LogAction';
import { getActiveCommunityId } from '../../user/selectors';
import ErrorModal from '../../common/ErrorModal';
import { actionPropType } from '../../actions/propTypes';
import ImageCaptionCard from '../../common/ImageCaptionCard';
import ImpactConversion from '../../common/ImpactConversion';
import IcomoonIcon from '../../common/IcomoonIcon';
import {
    activityShareURLRequested,
    clearActivityErrors,
} from '../../activities/actions';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import { getErrors } from '../../activities/selectors';
import ImpactToolTip from '../../common/ImpactTooltip';
import { useRouteMatch } from 'react-router-dom';
import PinSubheader from '../UpdateActionScreen/PinSubheader';
import ActionCards from '../UpdateActionScreen/ActionCards';
import {
    EVENT_ACTION_LINK_VIEWED,
    EVENT_ACTION_VIDEO_VIEWED,
} from '../../constants/analyticsEvents';

const Loader = () => (
    <Grid container justify="center" alignContent="center">
        <img src={IMAGES.loader} alt="loader" />
    </Grid>
);

const Errors = ({ errors }) => (
    <>
        {errors.map((err, i) => (
            <Typography key={i}>{err}</Typography>
        ))}
    </>
);

const ViewActionScreen = ({
    classes,
    communityId,
    action,
    isLoading,
    isLogActionLoading,
    errors,
    didLog,
    logActionBonuses,
    logActionEncouragements,
    logActionChallengeUpdates,
    loadFunc,
    logActionFunc,
    logActionErrors,
    logActionStatus,
    shareURL,
    shareURLLoading,
    fetchShareURL,
    activityId,
    clearShareURLMessages,
    shareURLErrors,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        params: { actionId },
    } = useRouteMatch();

    useEffect(() => {
        loadFunc({ actionId, communityId });
    }, [actionId, communityId, loadFunc]);

    useTrackScreenView('action_view_detail', {
        action_id: actionId,
    });

    const [isLogShown, setLogShown] = useState(false);
    const [logActionClicked, setLogActionClicked] = useState(false);
    const [isErrorShown, setErrorShown] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [linkRequested, setLinkRequested] = useState(false);

    useEffect(() => {
        if (logActionClicked && didLog) {
            setLogShown(true);
        }
    }, [logActionClicked, didLog, setLogShown]);

    useEffect(() => {
        if (
            logActionClicked &&
            logActionErrors != null &&
            logActionStatus != null
        ) {
            setErrorShown(true);
        }
    }, [logActionClicked, logActionErrors, logActionStatus, setErrorShown]);

    const handleLinkClick = useCallback(() => {
        trackEvent(EVENT_ACTION_LINK_VIEWED, {
            action_id: actionId,
        });
    }, [actionId]);

    const [moreLinksShown, setMoreLinksShown] = useState(false);
    const toggleMoreLinksShown = () => setMoreLinksShown(!moreLinksShown);

    const handleLogActionFunc = useCallback(
        (message, imageFile, imageMD5) => {
            setLogActionClicked(true);
            const options = {};
            if (message?.length > 0) {
                options.message = message;
            }
            if (imageFile && imageMD5) {
                options.photoMD5 = imageMD5;
                options.file = imageFile;
            }
            logActionFunc({
                actionId,
                communityId,
                options,
            });
        },
        [actionId, communityId, logActionFunc],
    );

    useEffect(() => {
        if (shareURL && linkRequested) {
            copy(shareURL);
            setSnackbarMessage(t('sharing.copied'));
            setLinkRequested(false);
        }
    }, [t, shareURL, setSnackbarMessage, linkRequested, setLinkRequested]);

    const handleShareActivity = useCallback(() => {
        if (shareURL) {
            copy(shareURL);
            setSnackbarMessage(t('sharing.copied'));
        } else {
            setLinkRequested(true);
            fetchShareURL(activityId);
        }
    }, [t, shareURL, fetchShareURL, activityId, setLinkRequested]);

    const iframeRef = useRef(null);
    const iframeCallbackRef = useCallback((node) => {
        iframeRef.current = node;
    }, []);

    useEffect(() => {
        const onBlur = () => {
            if (
                document.activeElement &&
                document.activeElement.nodeName.toLowerCase() === 'iframe' &&
                iframeRef.current &&
                iframeRef.current === document.activeElement
            ) {
                trackEvent(EVENT_ACTION_VIDEO_VIEWED, {
                    action_id: actionId,
                });
            }
        };

        window.addEventListener('blur', onBlur);

        return () => {
            window.removeEventListener('blur', onBlur);
        };
    }, [actionId]);
    return (
        <Grid className={classes.root}>
            {!action.name && isLoading && <Loader />}
            {errors && <Errors errors={errors} />}
            {!errors && action.name && (
                <>
                    {isErrorShown ? (
                        <ErrorModal
                            error={{
                                message: logActionErrors.join(';'),
                                status: logActionStatus,
                            }}
                            isOpen={isErrorShown}
                            onClose={() => setErrorShown(false)}
                            onCancel={() => setErrorShown(false)}
                        />
                    ) : null}
                    <LogAction
                        isOpen={isLogShown}
                        summaryImageLink={action.iconUrl}
                        bonuses={logActionBonuses}
                        encouragements={logActionEncouragements}
                        onClose={() => setLogShown(false)}
                        onCancel={() => setLogShown(false)}
                        okayFn={() => setLogShown(false)}
                        onShare={handleShareActivity}
                        logActionFunc={handleLogActionFunc}
                        loading={isLogActionLoading}
                        shareURLLoading={shareURLLoading}
                        challengeUpdates={logActionChallengeUpdates}
                    />
                    <ActionHeader
                        actionId={actionId}
                        communityId={communityId}
                        logActionFunc={() => {
                            setLogShown(true);
                        }}
                        headlineUrl={action.headlinePhotoUrl}
                        iconUrl={action.iconUrl}
                        name={action.name}
                        shareURL={action.shareURL}
                    />
                    <Grid container justify="center">
                        <Grid
                            container
                            item
                            direction="column"
                            className={classes.container}
                        >
                            <Typography className={classes.description}>
                                {action.description &&
                                    parse(action.description)}
                            </Typography>
                            {action.contentBonuses?.length > 0 && (
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    alignItems="center"
                                    className={classes.rowHeader}
                                >
                                    <Grid item className={classes.rowIcon}>
                                        <img
                                            src={IMAGES.plusOneBonusPoints}
                                            alt="plus one"
                                            width="24"
                                            height="24"
                                        />
                                    </Grid>
                                    <Grid item className={classes.rowLabel}>
                                        {t('bonusPoints')}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                container
                                item
                                direction="row"
                                className={classes.bonusesRow}
                            >
                                {action.contentBonuses?.map((bonus, index) => (
                                    <BonusPoints
                                        key={index}
                                        count={bonus.value}
                                        imageUrl={bonus.imageUrl}
                                        title={bonus.name}
                                    />
                                ))}
                            </Grid>
                            {action.sustainableDevelopmentGoals?.length > 0 && (
                                <>
                                    <PinSubheader
                                        title={t(
                                            'action.sustainableDevelopmentGoals',
                                        )}
                                    />
                                    <ActionCards
                                        content={
                                            action.sustainableDevelopmentGoals
                                        }
                                    />
                                </>
                            )}
                            {action.impactConversions?.length > 0 && (
                                <>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems="center"
                                        className={classes.rowHeader}
                                    >
                                        <Grid item className={classes.rowIcon}>
                                            <IcomoonIcon
                                                icon="jb-earth"
                                                color={
                                                    theme.custom
                                                        .textLightSecondaryColor
                                                }
                                                size={18}
                                            />
                                        </Grid>
                                        <Grid item className={classes.rowLabel}>
                                            {t('actionPage.sameYearlyImpactAs')}
                                            <ImpactToolTip />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        wrap="nowrap"
                                        container
                                        className={
                                            classes.sameImpactAsContainer
                                        }
                                    >
                                        {action.impactConversions.map(
                                            (conversion, index) => (
                                                <Grid
                                                    key={`impact-${index}`}
                                                    className={
                                                        classes.conversionContainer
                                                    }
                                                >
                                                    <ImpactConversion
                                                        leadingPhrase={
                                                            conversion.leadingPhrase
                                                        }
                                                        value={conversion.value}
                                                        trailingPhrase={
                                                            conversion.trailingPhrase
                                                        }
                                                        backgroundImage={
                                                            conversion.backgroundImage
                                                        }
                                                    />
                                                </Grid>
                                            ),
                                        )}
                                    </Grid>
                                </>
                            )}
                            {action.videos?.length > 0 && (
                                <>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems="center"
                                        className={classes.rowHeader}
                                    >
                                        <Grid item className={classes.rowIcon}>
                                            <img
                                                src={IMAGES.videosIcon}
                                                alt="videos"
                                                width="24"
                                                height="24"
                                            />
                                        </Grid>
                                        <Grid item className={classes.rowLabel}>
                                            {t('Videos')}
                                        </Grid>
                                    </Grid>
                                    {action.videos.map((video, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            style={{ position: 'relative' }}
                                        >
                                            <iframe
                                                title="video preview"
                                                src={`https://youtube.com/embed/${video.videoId}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                ref={iframeCallbackRef}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )}
                            {action.links?.length > 0 && (
                                <>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems="center"
                                        className={classes.rowHeader}
                                    >
                                        <Grid item className={classes.rowIcon}>
                                            <img
                                                src={IMAGES.linksIcon}
                                                alt="links"
                                                width="24"
                                                height="24"
                                            />
                                        </Grid>
                                        <Grid item className={classes.rowLabel}>
                                            {t('Links')}
                                        </Grid>
                                    </Grid>
                                    <ImageCaptionCard
                                        classes={classes}
                                        {...action.links[0]}
                                        onClick={handleLinkClick}
                                    />
                                    {action.links.length > 1 && (
                                        <>
                                            <Collapse in={moreLinksShown}>
                                                {action.links
                                                    .slice(1)
                                                    .map((link, index) => (
                                                        <ImageCaptionCard
                                                            key={index}
                                                            classes={classes}
                                                            {...link}
                                                            onClick={
                                                                handleLinkClick
                                                            }
                                                        />
                                                    ))}
                                            </Collapse>
                                            <Grid
                                                item
                                                className={
                                                    classes.showMoreBtnItem
                                                }
                                            >
                                                <Button
                                                    color="light"
                                                    onClick={
                                                        toggleMoreLinksShown
                                                    }
                                                >
                                                    Show
                                                    {moreLinksShown
                                                        ? t('Less')
                                                        : t('More')}
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
            <SnackBarAPIResponse
                messages={snackbarMessage}
                errors={shareURLErrors}
                clearResponse={() =>
                    clearShareURLMessages() && setSnackbarMessage('')
                }
            />
        </Grid>
    );
};

ViewActionScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    action: actionPropType,
    isLoading: PropTypes.bool.isRequired,
    isLogActionLoading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    didLog: PropTypes.bool,
    logActionBonuses: PropTypes.array,
    logActionEncouragements: PropTypes.arrayOf(PropTypes.string),
    logActionChallengeUpdates: PropTypes.arrayOf(PropTypes.string),
    loadFunc: PropTypes.func.isRequired,
    logActionFunc: PropTypes.func.isRequired,
    logActionErrors: CommonPropTypes.errors,
    logActionStatus: PropTypes.number,
    shareURL: PropTypes.string,
    shareURLLoading: PropTypes.bool,
    fetchShareURL: PropTypes.func.isRequired,
    activityId: PropTypes.string,
    clearShareURLMessages: PropTypes.func.isRequired,
    shareURLErrors: PropTypes.array,
};

ViewActionScreen.defaultProps = {
    errors: null,
    action: {},
    didLog: false,
    logActionBonuses: null,
    logActionEncouragements: [],
    logActionChallengeUpdates: [],
    logActionErrors: null,
    logActionStatus: null,
    activityId: '',
    shareURLLoading: false,
    shareURL: '',
    shareURLErrors: [],
};

const StyledViewActionScreen = withStyles(styles)(ViewActionScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    action: getAction(state),
    isLoading: isActionLoading(state),
    isLogActionLoading: isLogActionLoading(state),
    errors: getActionErrors(state),
    didLog: didLogAction(state),
    logActionBonuses: getLogActionBonuses(state),
    logActionEncouragements: getLogActionEncouragements(state),
    logActionChallengeUpdates: getLogActionChallengeUpdates(state),
    logActionErrors: getLogErrors(state),
    logActionStatus: getLogStatus(state),
    shareURL: getShareURL(state),
    shareURLLoading: isShareURLLoading(state),
    activityId: getLoggedActivityId(state),
    shareURLErrors: getErrors(state),
    challengeUpdates: getLogActionChallengeUpdates(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchShareURL: (activityId) =>
        dispatch(activityShareURLRequested(activityId)),
    loadFunc: ({ actionId, communityId }) =>
        dispatch(actionRequested({ actionId, communityId })),
    logActionFunc: ({ actionId, communityId, options }) =>
        dispatch(logActionRequested({ actionId, communityId, options })),
    clearShareURLMessages: () => dispatch(clearActivityErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledViewActionScreen);
