import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '10px 0',
    },
    line: (props) => ({
        height: `${props.lineWidth}px`,
        width: '100%',
        backgroundColor: props.lineColor || theme.custom.grey60,
        opacity: 0.8,
    }),
    text: (props) => ({
        margin: props.text && '0 15px',
        fontSize: `${props.fontSize}px`,
        color: props.textColor || theme.custom.grey60,
        opacity: 0.8,
        fontWeight: 500,
        minWidth: 'max-content',
    }),
}));

export default useStyles;
