import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import IcomoonIcon from '../../IcomoonIcon';

const EstimateUnitContainer = ({
    iconName,
    size,
    color,
    value,
    unit,
    subtitle,
}) => {
    const classes = useStyles({ color });

    return (
        <Grid
            className={classes.container}
            container
            alignItems="center"
            justify="center"
            direction="row"
        >
            <Grid
                item
                container
                alignItems="center"
                justify="center"
                wrap="nowrap"
            >
                <Grid style={{ marginRight: '5px' }} item>
                    <IcomoonIcon size={size} icon={iconName} color={color} />
                </Grid>
                <Grid item>
                    <Typography className={classes.measurement} variant="body1">
                        {`${value}${unit}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.bottomRow} item>
                <Typography className={classes.subtitle} variant="body1">
                    {parse(subtitle)}
                </Typography>
            </Grid>
        </Grid>
    );
};

EstimateUnitContainer.propTypes = {
    iconName: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
    value: PropTypes.number,
    unit: PropTypes.string,
    subtitle: PropTypes.string,
};

EstimateUnitContainer.defaultProps = {
    iconName: '',
    size: 32,
    color: '',
    value: 0,
    unit: '',
    subtitle: '',
};

export default EstimateUnitContainer;
