import {
    all,
    call,
    put,
    select,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
    uploadWithAuth,
} from '../utils/api';
import { trackEvent } from '../utils/analytics';
import {
    ACTION_CATEGORIES_REQUESTED,
    ACTION_CUSTOMIZATION_REQUESTED,
    ACTION_REQUESTED,
    ACTION_SHARE_URL_REQUESTED,
    actionCategoriesRequestFailed,
    actionCategoriesRequestSucceeded,
    actionCustomizationRequestFailed,
    actionCustomizationRequestSucceeded,
    actionRequested,
    actionRequestFailed,
    actionRequestSucceeded,
    ACTIONS_REQUESTED,
    actionShareURLRequestFailed,
    actionShareURLRequestSucceeded,
    actionsRequested,
    actionsRequestFailed,
    actionsRequestSucceeded,
    ADD_EXCLUDE_ACTION_REQUESTED,
    ADD_INCLUDE_ACTION_REQUESTED,
    LOG_ACTION_REQUESTED,
    logActionFailed,
    logActionSucceeded,
    SDGS_REQUESTED,
    sdgsRequestFailed,
    sdgsRequestSucceeded,
    URL_METADATA_REQUESTED,
    urlMetadataRequestFailed,
    urlMetadataRequestSucceeded,
} from './actions';
import { getActiveCommunityId } from '../user/selectors';
import { getCheatingAction } from './utils';
import i18n from 'i18next';
import { PIN_ADMIN_VISIBILITY_EXCLUDED } from './constants';
import {
    EVENT_ACTION_EDITED,
    EVENT_ACTION_LOGGED,
} from '../constants/analyticsEvents';

export function* getAction({ payload: { actionId, communityId } }) {
    try {
        const actionData = yield call(fetchWithAuth, `/pins/${actionId}`, {
            query: { communityId },
        });
        const action = actionData.response.pins[0];
        yield put(actionRequestSucceeded({ action }));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Action data',
        );
        yield put(actionRequestFailed(errors));
    }
}

export function* getActions({ payload: { communityId, filters } }) {
    try {
        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/actions`,
            { query: filters },
        );
        const actions = result.response?.pins;
        const count = result.response?.totalCount;
        filters = {
            ...filters,
            pagination: {
                totalCount: count,
            },
        };

        yield put(actionsRequestSucceeded(actions, filters));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get exclude action data',
        );
        yield put(actionsRequestFailed(errors, filters));
    }
}

export function* logAction({
    payload: { actionId, communityId, options = {} },
}) {
    trackEvent(EVENT_ACTION_LOGGED, {
        action_id: actionId,
    });
    try {
        const { file = null, ...body } = options;
        const logActionData = yield call(fetchWithAuth, `/buzz/create`, {
            method: 'POST',
            body: { pinId: actionId, communityId, ...body },
        });
        const logActionResponse = logActionData.response;
        yield put(logActionSucceeded(logActionResponse));
        const activity = logActionResponse?.activities?.[0];
        if (activity && !getCheatingAction(activity.actions)) {
            try {
                const formData = new FormData();
                file && formData.append('file', file);
                formData.append('buzz', activity.id);
                options.message && formData.append('message', options.message);
                formData.append('pinId', actionId);
                yield call(uploadWithAuth, `/buzz/${activity.id}/post`, {
                    method: 'POST',
                    body: formData,
                });
            } catch (error) {
                // fail silently
            }
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to Log Action',
        );
        yield put(logActionFailed(errors, error.response.status));
    }
}

export function* addExcludeAction({
    payload: { actionIds, actionDuplicationCommunityIds, filters },
}) {
    try {
        const state = yield select();
        const communityId = getActiveCommunityId(state);
        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/action/exclude`,
            {
                method: 'POST',
                body: {
                    pinIds: actionIds,
                    actionDuplicationCommunityIds,
                },
            },
        );
        const excludedAction = result.response?.pins;

        yield put(
            actionsRequestSucceeded(excludedAction, {
                visibility: PIN_ADMIN_VISIBILITY_EXCLUDED,
            }),
        );
        yield put(actionsRequested(communityId, filters));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to add exclude Actions',
        );
        yield put(logActionFailed(errors));
    }
}

export function* addIncludeAction({
    payload: { actionIds, actionDuplicationCommunityIds, filters },
}) {
    try {
        const state = yield select();
        const communityId = getActiveCommunityId(state);
        const result = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/action/include`,
            {
                method: 'POST',
                body: {
                    pinIds: actionIds,
                    actionDuplicationCommunityIds,
                },
            },
        );
        const excludedAction = result.response?.pins;

        yield put(
            actionsRequestSucceeded(excludedAction, {
                visibility: PIN_ADMIN_VISIBILITY_EXCLUDED,
            }),
        );
        yield put(actionsRequested(communityId, filters));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to add include Actions',
        );
        yield put(logActionFailed(errors));
    }
}

export function* getUrlMetadata(action) {
    try {
        const {
            payload: { url, saveImage = false, confirm = false },
        } = action;
        const response = yield call(fetchWithAuth, '/urlmetadata', {
            query: {
                url,
                saveImage,
            },
        });
        yield put(urlMetadataRequestSucceeded(response, confirm));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get URL metadata',
        );
        yield put(urlMetadataRequestFailed(errors));
    }
}

export function* customizeAction(action) {
    const {
        payload: {
            request: {
                urls,
                videos,
                photos,
                communityId,
                actionId,
                descriptions,
                actionDuplicationCommunityIds,
            },
        },
    } = action;
    trackEvent(EVENT_ACTION_EDITED, {
        action_id: actionId,
    });
    try {
        const requestBody = {
            pinId: actionId,
            communityURLs: urls,
            communityPhotos: photos,
            communityYouTubes: videos,
            actionDuplicationCommunityIds,
        };
        if (descriptions != null) {
            requestBody.communityDescriptions = descriptions;
        }
        yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/action/${actionId}/customize`,
            {
                method: 'POST',
                query: {
                    communityId,
                },
                body: requestBody,
            },
        );
        yield put(actionCustomizationRequestSucceeded(i18n.t('action.save')));
        yield put(actionRequested({ communityId, actionId }));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to customize action',
        );
        yield put(actionCustomizationRequestFailed(errors));
    }
}

export function* getActionCategories({ payload: { options } }) {
    try {
        const response = yield call(fetchWithAuth, '/categories', {
            query: { ...options },
        });
        yield put(
            actionCategoriesRequestSucceeded(response.response?.categories),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get categories',
        );
        yield put(actionCategoriesRequestFailed(errors));
    }
}

export function* getActionShareURL({ payload: { actionId } }) {
    try {
        const data = yield call(fetchWithAuth, `/pins/${actionId}/share`);
        const url = data.response['dynamic-links'][0].url;
        if (url) {
            yield put(actionShareURLRequestSucceeded(url, actionId));
        } else {
            yield put(
                actionShareURLRequestFailed([
                    i18n.t('activitiesPage.getShareURLError'),
                ]),
            );
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('activitiesPage.getShareURLError'),
        );
        yield put(actionShareURLRequestFailed(errors));
    }
}

export function* getSDGs() {
    try {
        const data = yield call(fetchWithAuth, `/pins/sdgs`);
        const sdgs = data.response['sustainable-development-goals'];
        if (sdgs) {
            yield put(sdgsRequestSucceeded(sdgs));
        } else {
            yield put(sdgsRequestFailed([i18n.t('error.unknown')]));
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(sdgsRequestFailed(errors));
    }
}

export default function* actionSaga() {
    yield all([
        takeLatest(ACTION_REQUESTED, getAction),
        takeEvery(ACTIONS_REQUESTED, getActions),
        takeLatest(LOG_ACTION_REQUESTED, logAction),
        takeLatest(ADD_EXCLUDE_ACTION_REQUESTED, addExcludeAction),
        takeLatest(ADD_INCLUDE_ACTION_REQUESTED, addIncludeAction),
        takeLatest(URL_METADATA_REQUESTED, getUrlMetadata),
        takeLatest(ACTION_CUSTOMIZATION_REQUESTED, customizeAction),
        takeLatest(ACTION_CATEGORIES_REQUESTED, getActionCategories),
        takeLatest(ACTION_SHARE_URL_REQUESTED, getActionShareURL),
        takeLatest(SDGS_REQUESTED, getSDGs),
    ]);
}
