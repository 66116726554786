import { createSelector } from 'reselect';

export const getSettingsState = (state) => state.settings;

export const getActionDuplicationCommunities = createSelector(
    getSettingsState,
    (settings) => settings.actionDuplicationCommunities,
);

export const getResponse = createSelector(
    getSettingsState,
    (settings) => settings.response,
);

export const getErrors = createSelector(
    getSettingsState,
    (settings) => settings.errors,
);

export const getIntegrations = createSelector(
    getSettingsState,
    (settings) => settings.integrations,
);

export const settingsLoading = createSelector(
    getSettingsState,
    (settings) => settings.loading,
);
