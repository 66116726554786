import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    Typography,
    CircularProgress,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import Button from '../../Button';
import LogActionItem from '../LogActionItem';
import styles from './styles';
import { getCheatingAction } from '../../../actions/utils';

const SummaryModal = ({
    classes,
    onClose,
    summaryImageLink,
    bonuses,
    encouragements,
    challengeUpdates,
    okayFn,
    onShare,
    shareURLLoading,
}) => {
    const { t } = useTranslation();

    const cheatingAction = getCheatingAction(bonuses);

    const summaryPoints = cheatingAction
        ? 0
        : bonuses?.reduce((acc, { points }) => acc + points, 0);

    const makeBonusItems = () =>
        cheatingAction ? (
            <LogActionItem {...cheatingAction} cheatingAction points={null} />
        ) : (
            bonuses?.map((bonus, i) => (
                <LogActionItem key={`bonus-${i}`} {...bonus} />
            ))
        );

    const getPositionText = (rank) => {
        if (rank === 1) {
            return t('leaderboardPosition.position.1', { count: rank });
        } else if (rank === 2) {
            return t('leaderboardPosition.position.2', { count: rank });
        } else if (rank === 3) {
            return t('leaderboardPosition.position.3', { count: rank });
        } else {
            return t('leaderboardPosition.position.other', { count: rank });
        }
    };

    const makeChallengeUpdates = () =>
        challengeUpdates?.map((update, index) => (
            <LogActionItem
                key={`challenge-update-${index}`}
                name={update.challenge.name}
                description={getPositionText(update.leaderboardItem.rank)}
                points={update.leaderboardItem.rank}
            />
        ));

    const items = makeBonusItems();
    const challengeItems = makeChallengeUpdates();

    return (
        <Grid container direction="column">
            <Grid
                container
                item
                wrap="nowrap"
                direction="row"
                justify="space-between"
                className={classes.titleBar}
            >
                <Grid container item justify="center" className={classes.title}>
                    <Typography>
                        {cheatingAction
                            ? t('action.log.noPoints')
                            : t('action.log.totalPoints')}
                    </Typography>
                </Grid>
                <Grid item className={classes.closeButton}>
                    <CloseIcon
                        fontSize="small"
                        onClick={onClose}
                        className={classes.closeIcon}
                    />
                </Grid>
            </Grid>
            {encouragements.map((encouragement, i) => (
                <Grid key={`encouragement-${i}`} container justify="center">
                    <Typography>{encouragement}</Typography>
                </Grid>
            ))}
            <Grid
                container
                item
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.summary}
            >
                <Grid item className={classes.summaryItem}>
                    <img
                        src={summaryImageLink}
                        alt="summary"
                        className={classes.summaryImage}
                    />
                </Grid>
                <Grid item className={classes.summaryPoints}>
                    {summaryPoints}
                </Grid>
            </Grid>
            {items}
            {!cheatingAction && challengeItems && challengeItems.length > 0 && (
                <>
                    <Typography
                        className={classes.challengeHeader}
                        align="center"
                    >
                        {t('summaryModal.challengeContributions')}
                    </Typography>
                    <Grid
                        container
                        direction="column"
                        className={classes.challengeContributions}
                        alignItems="center"
                    >
                        {challengeItems}
                    </Grid>
                </>
            )}
            <Grid container item justify="space-around" className={classes.ok}>
                <Button
                    color="accent"
                    className={classes.okButton}
                    size="large"
                    onClick={okayFn}
                >
                    {t('Ok')}
                </Button>
            </Grid>
            {!cheatingAction && (
                <Grid
                    container
                    item
                    alignItems="center"
                    justify="center"
                    className={classes.share}
                >
                    {shareURLLoading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <Button
                            className={classes.okButton}
                            size="large"
                            onClick={onShare}
                        >
                            <ShareIcon className={classes.icon} />
                            <Typography>{t('share')}</Typography>
                        </Button>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

SummaryModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    summaryImageLink: PropTypes.string.isRequired,
    encouragements: PropTypes.arrayOf(PropTypes.string),
    challengeUpdates: PropTypes.arrayOf(
        PropTypes.shape({
            challenge: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            leaderboardItem: PropTypes.shape({
                rank: PropTypes.number.isRequired,
                value: PropTypes.number.isRequired,
                item: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        }),
    ),
    bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            points: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ),
    okayFn: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    shareURLLoading: PropTypes.bool.isRequired,
};

SummaryModal.defaultProps = {
    encouragements: [],
    challengeUpdates: [],
    bonuses: null,
};

export default withStyles(styles)(SummaryModal);
