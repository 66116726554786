import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import Button from '../../Button';
import { noop } from '../../../utils';

const NextAndBackButtons = ({ onBack, isValid, submitBtnText, classes }) => {
    const { t } = useTranslation();

    return (
        <Grid container direction="row" className={classes.buttonWrapper}>
            <Button
                color="primary"
                variant="outlined"
                onClick={onBack}
                className={classes.actionButton}
            >
                {t('back')}
            </Button>
            <Button
                color="primary"
                type="submit"
                disabled={!isValid}
                className={classes.actionButton}
                style={{ marginRight: `0` }}
            >
                {submitBtnText}
            </Button>
        </Grid>
    );
};

NextAndBackButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    submitBtnText: PropTypes.string.isRequired,
};

NextAndBackButtons.defaultProps = {
    onBack: noop,
    isValid: false,
    submitBtnText: '',
};

export default withStyles(styles)(NextAndBackButtons);
