import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, List, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import Button from '../Button';
import styles from './styles';
import ConfirmModal from '../ConfirmModal';
import { useTranslation } from 'react-i18next';
import { noop } from '../../utils';
import InfiniteScroller from '../InfiniteScroller';
import LeaderboardCard from './LeaderboardCard';
import { leaderboardCardPropTypes, leaderboardItemPropType } from './propTypes';
import { LEADERBOARD_TYPE_DEFAULT, LEADERBOARD_TYPES } from './constants';
import LeaderboardCardLoader from './LeaderboardCardLoader';
import InfoTooltip from '../InfoTooltip';

const Leaderboard = ({
    classes,
    items,
    title,
    tooltip,
    viewAllBtn,
    onViewAllClick,
    leaderboardCardProps,
    onConfirm,
    unit,
    errors,
    isLoading,
    loadFunc,
    leaderboardType,
    penalties,
    intercomTargetPrefix,
    ...props
}) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <>
            <ConfirmModal
                title={
                    <span className={classes.modalTitle}>
                        {t('challenge.notJoined')}
                    </span>
                }
                isOpen={isModalOpen}
                confirmBtnHidden={true}
                cancelBtnText={t('close')}
                cancelBtnType="default"
                onClose={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                onConfirm={onConfirm}
                classes={{ modal: classes.modalPaper }}
            >
                {t('challenge.notJoinedDesc')}
            </ConfirmModal>
            <Grid container direction="column" item {...props}>
                {title && (
                    <Typography className={classes.title} variant="h3">
                        {title}
                        {tooltip && (
                            <InfoTooltip
                                text={tooltip}
                                intercomTarget={`${intercomTargetPrefix}Leaderboard`}
                            />
                        )}
                        {viewAllBtn && (
                            <Button
                                className={classes.button}
                                variant="text"
                                color="gray"
                                onClick={onViewAllClick}
                            >
                                {t('viewAll')}
                                <KeyboardArrowRight />
                            </Button>
                        )}
                    </Typography>
                )}
                <List className={classes.container}>
                    <InfiniteScroller
                        isLoading={isLoading}
                        errors={errors}
                        items={items}
                        loadFunc={loadFunc}
                        renderFunc={(item, index) => (
                            <LeaderboardCard
                                key={`${item.label}-${index}`}
                                item={item}
                                rank={item.rank}
                                onInfoClick={() => setModalOpen(true)}
                                leaderboardType={leaderboardType}
                                isPenalty={item.status === 'penalty'}
                                {...leaderboardCardProps}
                            />
                        )}
                        Loader={
                            <Grid item container>
                                <LeaderboardCardLoader />
                                <LeaderboardCardLoader />
                            </Grid>
                        }
                        listEmptyText={t('data.empty')}
                    />
                </List>
            </Grid>
        </>
    );
};

Leaderboard.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(leaderboardItemPropType).isRequired,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    viewAllBtn: PropTypes.bool,
    onViewAllClick: PropTypes.func,
    onConfirm: PropTypes.func,
    unit: PropTypes.string,
    intercomTargetPrefix: PropTypes.string,
    errors: PropTypes.array,
    isLoading: PropTypes.bool,
    loadFunc: PropTypes.func,
    leaderboardType: PropTypes.oneOf(LEADERBOARD_TYPES),
    leaderboardCardProps: PropTypes.shape(leaderboardCardPropTypes),
    penalties: PropTypes.array,
};

Leaderboard.defaultProps = {
    items: [],
    title: null,
    tooltip: null,
    viewAllBtn: false,
    onViewAllClick: noop,
    onConfirm: noop,
    unit: null,
    errors: [],
    isLoading: false,
    loadFunc: noop,
    intercomTargetPrefix: '',
    leaderboardType: LEADERBOARD_TYPE_DEFAULT,
    leaderboardCardProps: {},
    penalties: [],
};

export default withStyles(styles)(Leaderboard);
