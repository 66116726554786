import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import ChallengeHeader from './ChallengeHeader';
import ChallengeLeaderboard from './ChallengeLeaderboard';
import ChallengeImpact from './ChallengeImpact';
import ChallengeInfo from './ChallengeInfo';

import { dateDifferenceInDays } from '../../utils/dates';
import IMAGES from '../../constants/images';
import CommonPropTypes from '../../common/propTypes';
import { viewChallengeRequested } from '../../challenges/actions';
import {
    getChallengeEndDate,
    getChallengeErrors,
    getChallengeIconUrl,
    getChallengeInfo,
    getChallengeName,
    getChallengeParticipationStatus,
    getChallengeStartDate,
    getChallengeTeamsInfo,
    getChallengeTemplateStatus,
    getChallengeUnit,
    getHeadlinePhotoUrl,
    isChallengeLoading,
} from '../../challenges/selectors';
import TabbedSection from '../../common/TabbedSection';
import { getActiveCommunityId } from '../../user/selectors';
import ErrorList from '../../common/errors/ErrorList';
import { useTrackScreenView } from '../../utils/analytics';
import { activityDataRequested } from '../../activities/actions';
import { ACTIVITIES_FEED_TYPE_CHALLENGE } from '../../activities/constants';
import {
    activitiesLoading,
    getActivities,
    getErrors,
} from '../../activities/selectors';
import ActivityCardScroller from '../../common/ActivityCard/ActivityCardScroller';
import { CHALLENGE_TEMPLATE_STATUSES } from '../../constants/challenges';
import { useRouteMatch } from 'react-router-dom';
import { challengeInfoPropType } from './ChallengeInfo/propTypes';
import { actionsRequested } from '../../actions/actions';

const Loader = () => (
    <Grid container justify="center" alignContent="center">
        <img src={IMAGES.loader} alt="loader" />
    </Grid>
);

const ViewChallengeScreen = ({
    classes,
    communityId,
    headlinePhotoUrl,
    iconUrl,
    name,
    info,
    startDate,
    endDate,
    participationStatus,
    unit,
    teams,
    isLoading,
    errors,
    loadFunc,
    loadActivities,
    activitiesLoading,
    activityItems,
    status,
    fetchChallengeActionData,
}) => {
    const {
        params: { challengeId },
    } = useRouteMatch();

    useEffect(() => {
        loadFunc({ challengeId, communityId });
    }, [challengeId, communityId, loadFunc]);

    useEffect(() => {
        loadActivities(challengeId, communityId);
    }, [challengeId, communityId, loadActivities]);

    useTrackScreenView('challenge_view_detail', {
        challenge_id: challengeId,
    });

    const fetchChallengeActionsTableData = useCallback(
        (args) => {
            fetchChallengeActionData(communityId, {
                ...args,
                challengeIds: [challengeId],
            });
        },
        [challengeId, communityId, fetchChallengeActionData],
    );

    const getSelectedIndex = useCallback(() => {
        return participationStatus === 'joined' ? 0 : 3;
    }, [participationStatus]);

    const { t } = useTranslation();
    return (
        <Grid container className={classes.container}>
            {!name && isLoading && <Loader />}
            {errors && (
                <ErrorList className={classes.errorStyles} errors={errors} />
            )}
            {!errors && name && (
                <>
                    <ChallengeHeader
                        challengeId={challengeId}
                        description={info.description}
                        headlinePhotoUrl={headlinePhotoUrl}
                        iconUrl={iconUrl}
                        name={name}
                        participationStatus={participationStatus}
                        unit={unit}
                        teams={teams}
                        startDate={startDate}
                        endDate={endDate}
                        challengeStatus={status}
                    />
                    <Grid
                        container
                        item
                        justify="center"
                        className={classes.body}
                    >
                        <Grid item className={classes.tabbedItem}>
                            <TabbedSection
                                tabs={{
                                    [t('leaderboard.descriptor')]: (
                                        <ChallengeLeaderboard
                                            challengeId={challengeId}
                                            t={t}
                                            challengeName={name}
                                        />
                                    ),
                                    [t('activity.descriptor')]: (
                                        <ActivityCardScroller
                                            loading={activitiesLoading}
                                            loadFunc={(options) =>
                                                loadActivities(
                                                    challengeId,
                                                    communityId,
                                                    options,
                                                )
                                            }
                                            items={activityItems}
                                        />
                                    ),
                                    [t('impact.descriptor')]: (
                                        <ChallengeImpact t={t} />
                                    ),
                                    [t('info')]: (
                                        <ChallengeInfo
                                            info={info}
                                            duration={dateDifferenceInDays(
                                                startDate,
                                                endDate,
                                            )}
                                            startDate={startDate}
                                            endDate={endDate}
                                            fetchChallengeActionData={
                                                fetchChallengeActionsTableData
                                            }
                                            t={t}
                                        />
                                    ),
                                }}
                                selectedIndex={getSelectedIndex()}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

ViewChallengeScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    headlinePhotoUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    participationStatus: PropTypes.oneOf(['joined', 'unjoined']),
    unit: PropTypes.oneOf(['User', 'Team']),
    teams: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    loadFunc: PropTypes.func.isRequired,
    loadActivities: PropTypes.func.isRequired,
    activitiesLoading: PropTypes.bool,
    activityItems: PropTypes.array,
    info: challengeInfoPropType,
    status: PropTypes.oneOf(CHALLENGE_TEMPLATE_STATUSES).isRequired,
    fetchChallengeActionData: PropTypes.func.isRequired,
};

ViewChallengeScreen.defaultProps = {
    headlinePhotoUrl: null,
    iconUrl: null,
    name: null,
    startDate: null,
    endDate: null,
    participationStatus: null,
    unit: null,
    teams: null,
    errors: null,
    activityItems: [],
    activitiesLoading: false,
    info: {},
};

const StyledViewChallengeScreen = withStyles(styles)(ViewChallengeScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    headlinePhotoUrl: getHeadlinePhotoUrl(state),
    iconUrl: getChallengeIconUrl(state),
    name: getChallengeName(state),
    startDate: getChallengeStartDate(state),
    endDate: getChallengeEndDate(state),
    info: getChallengeInfo(state),
    participationStatus: getChallengeParticipationStatus(state),
    unit: getChallengeUnit(state),
    teams: getChallengeTeamsInfo(state),
    isLoading: isChallengeLoading(state),
    status: getChallengeTemplateStatus(state),
    errors: getChallengeErrors(state),
    activitiesLoading: activitiesLoading(state, ACTIVITIES_FEED_TYPE_CHALLENGE),
    activitiesErrors: getErrors(state),
    activityItems: getActivities(state, ACTIVITIES_FEED_TYPE_CHALLENGE),
});

const mapDispatchToProps = (dispatch) => ({
    loadFunc: ({ challengeId, communityId }) =>
        dispatch(viewChallengeRequested({ challengeId, communityId })),
    loadActivities: (challengeId, communityId, options) =>
        dispatch(
            activityDataRequested(ACTIVITIES_FEED_TYPE_CHALLENGE, {
                challengeId,
                communityId,
                ...options,
            }),
        ),
    fetchChallengeActionData: (communityId, filters) =>
        dispatch(actionsRequested(communityId, filters)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledViewChallengeScreen);
