import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dragAndDropContainer: {
        paddingTop: 40,
        paddingBottom: 40,
        backgroundColor: theme.custom.bgLightColor,
    },
    dragAndDropText: {
        fontSize: 14,
        width: '100%',
        textAlign: 'center',
        color: theme.custom.textLightDisabledColor,
    },
    dragAndDropChooseText: {
        fontSize: 14,
        width: '100%',
        textAlign: 'center',
        fontWeight: '700',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

export default useStyles;
