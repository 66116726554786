import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

import VideoComponent from '../VideoComponent';
import { Collapse, Grid } from '@material-ui/core';
import Button from '../../../common/Button';
import { VideoPropType } from '../../../actions/propTypes';

const Videos = ({ videos, onVideoEdit, onVideoRemove }) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const [moreVideosShown, setMoreVideosShown] = useState(false);
    const toggleMoreVideosShown = useCallback(
        () => setMoreVideosShown(!moreVideosShown),
        [setMoreVideosShown, moreVideosShown],
    );

    if (videos == null || videos.length === 0) {
        return (
            <Grid container item justify="center" className={classes.noVideos}>
                {t('videos.noneAdded')}
            </Grid>
        );
    }

    const [first, ...rest] = videos;

    return (
        <>
            <VideoComponent
                video={first}
                onEdit={() => onVideoEdit(0)}
                onRemove={() => onVideoRemove(0)}
            />
            {rest.length > 0 ? (
                <>
                    <Collapse in={moreVideosShown}>
                        {rest.map((video, index) => (
                            <VideoComponent
                                key={index}
                                video={video}
                                onEdit={() => onVideoEdit(index + 1)}
                                onRemove={() => onVideoRemove(index + 1)}
                            />
                        ))}
                    </Collapse>
                    <Grid item className={classes.showMoreBtnItem}>
                        <Button color="light" onClick={toggleMoreVideosShown}>
                            {t('Show')}
                            {moreVideosShown ? t('Less') : t('More')}
                        </Button>
                    </Grid>
                </>
            ) : null}
        </>
    );
};

Videos.propTypes = {
    videos: PropTypes.arrayOf(VideoPropType),
    onVideoEdit: PropTypes.func.isRequired,
    onVideoRemove: PropTypes.func.isRequired,
};

Videos.defaultProps = {
    videos: null,
};

export default Videos;
