import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formLabel: { marginBottom: '10px' },
    selectBox: { padding: '6px 16px' },
    setupFormSection: {
        margin: '10px 0 0 0',
        paddingLeft: '35px',
    },
    errorLabel: {
        color: 'rgb(236, 76, 55)',
        marginBottom: '10px',
    },
    actionButton: {
        marginRight: '10px',
        paddingLeft: '40px',
        paddingRight: '40px',
        [theme.breakpoints.down('xs')]: {
            width: '48%',
        },
    },
    buttonWrapper: {
        padding: '13px 0px',
        paddingBottom: '0px',
        [theme.breakpoints.down('xs')]: {
            padding: '13px 0px',
            paddingBottom: '0px',
        },
    },
    multiEmailInput: {
        backgroundColor: `${theme.custom.inputBackground} !important`,
        borderColor: `${theme.palette.primary.main} !important`,
        padding: '10px 16px',
        fontSize: '18px',
        display: 'flex',
        width: '100%',
        '& input': {
            padding: '0 !important',
            backgroundColor: `${theme.custom.inputBackground} !important`,
            flex: 1,
        },
    },
    enteredEmail: {
        fontSize: '18px',
    },
    dropdown: {
        fontSize: '18px',
        padding: '10px 0px',
        '& .MuiOutlinedInput-root': { padding: 0 },
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] input': {
            padding: '6px 0 6px 12px',
        },
    },
    asteriskRequired: {
        paddingBottom: '10px',
        color: theme.palette.disabled.main,
    },
    inputLabel: {
        color: theme.palette.primary.main,
        lineHeight: 1,
    },
}));

export default useStyles;
