import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    controls: {
        marginTop: '26px',
    },
    infoButtonRoot: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '12px',
        paddingLeft: '12px',
        borderRadius: '8px',
    },
    infoButton: {
        flexGrow: 1,
    },
    infoIcon: {
        color: theme.custom.tooltipIconColor,

        '&:hover': {
            cursor: 'pointer',
        },
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
    },
    button: {
        flexGrow: 1,
        fontSize: '18px',
    },
    buttonIcon: {
        marginRight: '3px',
    },
}));

export default useStyles;
