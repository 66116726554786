export const SUBSCRIPTION_REQUESTED = 'SUBSCRIPTION_REQUESTED';
export const SUBSCRIPTION_REQUEST_SUCCEEDED = 'SUBSCRIPTION_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_REQUEST_FAILED = 'SUBSCRIPTION_REQUEST_FAILED';

export const RETRIEVE_STRIPE_PLAN_ID = 'RETRIEVE_STRIPE_PLAN_ID';

export const SUBSCRIPTION_SESSION_REQUESTED = 'SUBSCRIPTION_SESSION_REQUESTED';
export const SUBSCRIPTION_SESSION_REQUEST_SUCCEEDED =
    'SUBSCRIPTION_SESSION_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_SESSION_REQUEST_FAILED =
    'SUBSCRIPTION_SESSION_REQUEST_FAILED';
export const SUBSCRIPTION_SESSION_CLEAR_ERRORS =
    'SUBSCRIPTION_SESSION_CLEAR_ERRORS';

export const SUBSCRIPTION_RECORDS_REQUESTED = 'SUBSCRIPTION_RECORDS_REQUESTED';
export const SUBSCRIPTION_RECORDS_REQUEST_SUCCEEDED =
    'SUBSCRIPTION_RECORDS_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_RECORDS_REQUEST_FAILED =
    'SUBSCRIPTION_RECORDS_REQUEST_FAILED';

export const SUBSCRIPTION_TIERS_REQUESTED = 'SUBSCRIPTION_TIERS_REQUESTED';
export const SUBSCRIPTION_TIERS_REQUEST_SUCCEEDED =
    'SUBSCRIPTION_TIERS_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_TIERS_REQUEST_FAILED =
    'SUBSCRIPTION_TIERS_REQUEST_FAILED';

export const SUBSCRIPTION_VERIFICATION_REQUESTED =
    'SUBSCRIPTION_VERIFICATION_REQUESTED';
export const SUBSCRIPTION_VERIFICATION_REQUEST_SUCCEEDED =
    'SUBSCRIPTION_VERIFICATION_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_VERIFICATION_REQUEST_FAILED =
    'SUBSCRIPTION_VERIFICATION_REQUEST_FAILED';

export const SUBSCRIPTION_MANAGEMENT_LINK_REQUESTED =
    'SUBSCRIPTION_MANAGEMENT_LINK_REQUESTED';
export const SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_SUCCEEDED =
    'SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_FAILED =
    'SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_FAILED';

export const subscriptionRequested = (request) => ({
    type: SUBSCRIPTION_REQUESTED,
    payload: request,
});

export const subscriptionRequestSucceeded = (currentSubscription) => ({
    type: SUBSCRIPTION_REQUEST_SUCCEEDED,
    payload: { currentSubscription },
});

export const subscriptionRequestFailed = (errors = []) => ({
    type: SUBSCRIPTION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const subscriptionRecordsRequested = ({ groupId }) => ({
    type: SUBSCRIPTION_RECORDS_REQUESTED,
    payload: {
        groupId,
    },
});

export const subscriptionRecordsRequestSucceeded = (records) => ({
    type: SUBSCRIPTION_RECORDS_REQUEST_SUCCEEDED,
    payload: {
        records: records,
    },
});

export const subscriptionRecordsRequestFailed = (errors) => ({
    type: SUBSCRIPTION_RECORDS_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const subscriptionTiersRequested = () => ({
    type: SUBSCRIPTION_TIERS_REQUESTED,
});

export const subscriptionTiersRequestSucceeded = (tiers) => ({
    type: SUBSCRIPTION_TIERS_REQUEST_SUCCEEDED,
    payload: {
        tiers,
    },
});

export const subscriptionTiersRequestFailed = (errors) => ({
    type: SUBSCRIPTION_TIERS_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const retrieveStripePlanId = (planId) => ({
    type: RETRIEVE_STRIPE_PLAN_ID,
    payload: {
        planId,
    },
});

export const subscriptionSessionRequested = (
    groupId,
    successUrl,
    cancelUrl,
    planId,
    quantity,
) => ({
    type: SUBSCRIPTION_SESSION_REQUESTED,
    payload: { groupId, successUrl, cancelUrl, planId, quantity },
});

export const subscriptionSessionRequestSucceeded = (url, planId) => ({
    type: SUBSCRIPTION_SESSION_REQUEST_SUCCEEDED,
    payload: {
        url,
        planId,
    },
});

export const subscriptionSessionRequestFailed = (errors) => ({
    type: SUBSCRIPTION_SESSION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const subscriptionSessionClearErrors = () => ({
    type: SUBSCRIPTION_SESSION_CLEAR_ERRORS,
});

export const subscriptionVerificationRequested = (groupId, planId) => ({
    type: SUBSCRIPTION_VERIFICATION_REQUESTED,
    payload: { groupId, planId },
});

export const subscriptionVerificationRequestSucceeded = (
    invoice,
    subscription,
) => ({
    type: SUBSCRIPTION_VERIFICATION_REQUEST_SUCCEEDED,
    payload: {
        invoice,
        subscription,
    },
});

export const subscriptionVerificationRequestFailed = (errors) => ({
    type: SUBSCRIPTION_VERIFICATION_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const subscriptionManagementLinkRequested = (groupId, returnUrl) => ({
    type: SUBSCRIPTION_MANAGEMENT_LINK_REQUESTED,
    payload: {
        groupId,
        returnUrl,
    },
});

export const subscriptionManagementLinkRequestSucceeded = (url) => ({
    type: SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_SUCCEEDED,
    payload: {
        url,
    },
});

export const subscriptionManagementLinkRequestFailed = (errors) => ({
    type: SUBSCRIPTION_MANAGEMENT_LINK_REQUEST_FAILED,
    payload: {
        errors,
    },
});
