import React from 'react';

import useStyles from './styles';

const CheckboxIcon = () => {
    const classes = useStyles();
    return <div className={classes.checkbox}></div>;
};

export default CheckboxIcon;
