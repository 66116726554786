const styles = (theme) => ({
    container: {
        width: 'fit-content',
    },
    accountContainer: {
        paddingRight: '40px',
        paddingLeft: '32px',
    },
    leftSectionContainer: {
        paddingTop: '30px',
    },
    planContainer: {
        height: 'fit-content',
        padding: '0 40px 120px 40px',
        marginTop: '20px',
        background: theme.custom.bgLightAccentColor,
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 40px 40px 40px',
        },
    },
    link: {
        color: theme.custom.linkColor,
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: 700,
        paddingTop: '20px',
    },
});

export default styles;
