// import { TFunction } from 'i18next';

/**
 *
 * @param {Function} t The i18n t-function for performing localization
 * @param {{str, array}} fields
 * @param {{object, array}} objects
 */
export const localizeFieldsInObject = (t, fields = [], objects = []) => {
    if (typeof fields === 'string') {
        fields = [fields];
    }
    if (typeof objects.length === 'undefined') {
        objects = [objects];
    }
    return objects.map((object) => {
        const localizedObject = object;
        fields.forEach((field) => {
            if (typeof object[field] === 'string') {
                localizedObject[field] = t(object[field]);
            }
        });
        return localizedObject;
    });
};
