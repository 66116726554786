const styles = (theme) => ({
    title: {
        fontSize: '18px',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    helpLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    helpIcon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '3px',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    label: {
        marginBottom: '5px',
        fontWeight: 700,
    },
    textarea: {
        width: '100%',
        marginBottom: '15px',
        padding: '10px 16px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '8px',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '14px',

        '&:before, &:after': {
            display: 'none',
        },

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },

        '&:active&:not(&:disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&:focus': {
            borderColor: theme.palette.primary.dark,
        },
    },
});

export default styles;
