export default (theme) => ({
    inputLabel: {
        color: theme.palette.primary.main,
    },
    errorContainer: {
        marginBottom: '20px',
    },
    errorMessage: {
        color: theme.palette.error.main,
        textAlign: 'center',
    },
});
