const styles = (theme) => ({
    small: {
        fontSize: '12px !important',
    },
    until: {
        backgroundColor: theme.palette.info.main,
        padding: '.2em .6em .3em',
        fontSize: '10.5px !important',
        color: theme.palette.info.contrastText,
        borderRadius: '.25em',
    },
});

export default styles;
