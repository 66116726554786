import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import InfoTooltip from '../InfoTooltip';

const Label = ({ text, tooltipText, tooltipPlacement, intercomTarget }) => {
    const classes = useStyles();

    return (
        <Grid container direction="row" className={classes.label}>
            <Grid item>
                <Typography variant="body1" className={classes.fieldLabel}>
                    {text}
                </Typography>
            </Grid>
            <Grid item>
                {(tooltipText || intercomTarget) && (
                    <InfoTooltip
                        text={tooltipText}
                        placement={tooltipPlacement}
                        intercomTarget={intercomTarget}
                    />
                )}
            </Grid>
        </Grid>
    );
};

Label.propTypes = {
    text: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    intercomTarget: PropTypes.string,
};

Label.defaultProps = {
    tooltipText: null,
    tooltipPlacement: 'right',
    intercomTarget: 'Field Tooltip',
};

export default Label;
