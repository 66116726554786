import IMAGES from '../../constants/images';
const styles = (theme) => ({
    navLink: {
        color: theme.palette.primary.main,
    },
    title: {
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    container: {
        fontFamily: `'effra',sans-serif`,
    },
    header: {
        paddingBottom: '20px',
    },
    activeChallengeCover: {
        height: '351px',
        borderRadius: '5px',
        // opacity: '0.66',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${IMAGES.defaultActiveChallengeBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
    },
    activeChallengeCoverHeader: {
        padding: '10px',
        flexWrap: 'nowrap',
        height: '100%',
        width: '100%',
    },
    editCover: {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        border: '2px',
        fontWeight: 600,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            color: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.white.main,
        },
    },
    buttonIcon: {
        marginRight: '5px',
    },
    activeChallengeCoverBodyRow: {
        height: '100%',
    },
    activeChallengeCoverBodyHeader: {
        height: '50px',
    },
    activeChallengeCoverBodyRowFooter: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        height: '100px',
        alignItems: 'flex-end',
        padding: '10px',
    },
    activeChallengeCoverBodyRowMiddle: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        height: '250px',
        alignItems: 'flex-end',
        padding: '10px',
    },
    challengeMeta: {
        width: '100%',
    },
    challengePeopleMeta: {
        width: '200px',
        padding: '15px',
    },
    challengeTitleWrapper: {
        padding: '0 10px',
    },
    challengeTitle: {
        fontSize: '24px',
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.palette.white.main,
    },
    challengeDates: {
        color: theme.palette.white.main,
        padding: '10px 10px 0px 10px',
    },
    peopleIcon: {
        width: '45px',
        height: '45px',
        '& svg': {
            fill: theme.palette.white.main,
            fontSize: '30px',
        },
    },
    challengeText: {
        color: theme.palette.white.main,
    },
    activityItemsWrapper: {
        backgroundColor: theme.custom.grey93,
        padding: '15px',
    },
    focusVisible: {},
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    activityItem: {
        position: 'relative',
        height: '74px',
        width: '74px',
        border: `1px solid ${theme.palette.white.main}`,
        marginLeft: '5px',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
        },
    },
    activityItemSeeAll: {
        height: '74px',
        width: '113px',
        marginLeft: '5px',
        lineHeight: '74px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    image: {
        position: 'relative',
        height: 200,
    },
});

export default styles;
