import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import {
    DatePicker as DatePickerUI,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Event } from '@material-ui/icons';
import omit from 'lodash/omit';
import Button from '../Button';
import styles from './styles';

const DatePicker = (props) => {
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const openHandler = () => props.disabled || setOpen(true);
    const {
        classes,
        value,
        format,
        onChange,
        rightBtnType,
        as,
        isBottomMargin,
        ...datePickerProps
    } = props;

    const pickerUI = React.createElement(as, {
        classes: null,
        open,
        value,
        format,
        onChange,
        onClose,
        autoOk: true,
        variant: 'inline',
        inputVariant: 'outlined',
        InputProps: {
            classes: omit(classes, ['clearButton', 'bottomMargin']),
            onClick: openHandler,
        },
        style: { width: '100%' },
        ...datePickerProps,
    });

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                wrap="nowrap"
                className={isBottomMargin && classes.bottomMargin}
            >
                {pickerUI}
                <Button
                    className={classes.clearButton}
                    color={rightBtnType}
                    onClick={openHandler}
                >
                    <Event />
                </Button>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

DatePicker.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    format: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    rightBtnType: PropTypes.string,
    disabled: PropTypes.bool,
    as: PropTypes.elementType,
    isBottomMargin: PropTypes.bool,
};

DatePicker.defaultProps = {
    value: null,
    format: 'MM.DD.YYYY',
    rightBtnType: 'accent',
    disabled: false,
    as: DatePickerUI,
    isBottomMargin: false,
};

export default withStyles(styles)(DatePicker);
