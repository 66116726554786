import { useEffect } from 'react';
import {
    getAnalytics,
    logEvent,
    setUserId,
    setUserProperties,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import posthog from 'posthog-js';

import { firebaseConfig } from '../config/analytics';
import {
    hasAdPersonalizationConsent,
    hasAdStorageConsent,
    hasAdUserDataConsent,
    hasAnalyticsConsent,
    hasGDPRConsent,
} from './cookies';
import moment from 'moment';
import { INTERCOM_EVENTS } from '../constants/analyticsEvents';
import {
    PARAM_CAMPAIGN,
    PARAM_CONTENT_TYPE,
    PARAM_MEDIUM,
    PARAM_SOURCE,
} from '../constants/analyticsParams';

export const ANALYTICS_GROUP_COMMUNITY = 'community';
let firebaseAnalytics = null;
let gtmInitialized = false;

// Init firebase w/ out analytics
const firebaseApp = firebaseConfig ? initializeApp(firebaseConfig) : null;

const gtagConsentUpdate = (params) => {
    if (window.gtag) {
        window.gtag('consent', 'update', params);
    }
};

/**
 * Attempts to initialize analytics by checking that the right cookies for GFPR compliance are in place
 */
export const initAnalytics = () => {
    // If we didn't receive consent for analytic tracking, don't initialize any 3rd party tracking tools
    if (!hasGDPRConsent() || !hasAnalyticsConsent()) {
        if (posthog.has_opted_out_capturing()) {
            posthog.opt_out_capturing();
        }
    }
    if (!gtmInitialized) {
        initializeGTM();
        gtmInitialized = true;
    }
    if (posthog.has_opted_out_capturing()) {
        posthog.opt_in_capturing();
    }
    if (!firebaseAnalytics && firebaseApp) {
        firebaseAnalytics = getAnalytics(firebaseApp);
    }
    updateConsentSettings();
};

const initializeGTM = () => {
    const gtmId = process.env.REACT_APP_GOOGLE_ANALYTICS;
    if (gtmId) {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', gtmId);
        };
    }
};

const updateConsentSettings = () => {
    const consentParams = {
        ad_storage: hasAdStorageConsent() ? 'granted' : 'denied',
        analytics_storage: hasAnalyticsConsent() ? 'granted' : 'denied',
        ad_user_data: hasAdUserDataConsent() ? 'granted' : 'denied',
        ad_personalization: hasAdPersonalizationConsent()
            ? 'granted'
            : 'denied',
    };
    gtagConsentUpdate(consentParams);
};

export const trackEvent = (name, parameters = {}) => {
    if (firebaseAnalytics) {
        logEvent(firebaseAnalytics, name, parameters);
    }
    posthog.capture(name, parameters);
    if (INTERCOM_EVENTS.includes(name)) {
        window.Intercom('trackEvent', name, parameters);
    }
};

export const useTrackScreenView = (name, parameters = {}) => {
    useEffect(
        () => trackEvent(name, parameters),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    posthog.capture('$pageview');
};

export const setUserID = (id) => {
    if (firebaseAnalytics) {
        setUserId(firebaseAnalytics, id);
    }
    posthog.identify(id);
};

export const setAnalyticsCommunity = (activeCommunity, properties = {}) => {
    properties.id = activeCommunity?.id;
    if (activeCommunity?.created) {
        properties.created = moment(activeCommunity.created).unix();
    }
    setAnalyticsGroup(
        ANALYTICS_GROUP_COMMUNITY,
        activeCommunity?.id,
        properties,
    );
};

export const setAnalyticsGroup = (groupType, groupKey, groupProperties) => {
    if (groupType && groupKey) {
        posthog.group(groupType, groupKey, groupProperties);
    }
};

export const setTrackingProperties = (properties) => {
    if (firebaseAnalytics) {
        setUserProperties(firebaseAnalytics, properties);
    }
    posthog.has_opted_in_capturing() &&
        posthog.people &&
        posthog.people.set_once(properties);
};

export const getUTMParams = () => {
    const query = new URLSearchParams(window.location.search);
    return {
        [PARAM_CAMPAIGN]: query.get('utm_campaign'),
        [PARAM_SOURCE]: query.get('utm_source'),
        [PARAM_MEDIUM]: query.get('utm_medium'),
        [PARAM_CONTENT_TYPE]: query.get('utm_content'),
    };
};
