import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { Can } from '../../casl/ability-context';
import AdminChallengesScreen from './AdminChallengesScreen';
import UserChallengesScreen from './UserChallengesScreen';
import styles from './styles';

const ChallengesScreen = ({ classes }) => {
    return (
        <Grid container className={classes.container}>
            <Can I="view" a="AdminScreens">
                <AdminChallengesScreen />
            </Can>
            <Can not I="view" a="AdminScreens">
                <UserChallengesScreen />
            </Can>
        </Grid>
    );
};

ChallengesScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChallengesScreen);
