const styles = (theme) => ({
    title: {
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '24px',
        lineHeight: 1.1,
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 0,
        margin: '0 8px',
        padding: '8px',

        '& svg': {
            fontSize: '20px',
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        padding: '8px',
        fontSize: '18px',
        fontWeight: 600,
        verticalAlign: 'middle',
    },
    value: {
        padding: '8px',
        paddingRight: '16px',
        fontSize: '34px',
    },
    listItem: {
        border: '1px solid transparent',
        borderBottomColor: theme.custom.bgSeparator,
        color: theme.custom.textDarkColor,

        '&:last-child': {
            borderBottomColor: 'transparent',
        },
    },
    container: {
        padding: 0,
        borderRadius: '4px',
        boxShadow:
            '0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 4px 0 rgba(0, 0, 0, 0.06)',
        boxSizing: 'border-box',
        backgroundColor: theme.custom.bgLightAccentColor,
    },
});

export default styles;
