export const INVITE_USERS_REQUESTED = 'INVITE_USERS_REQUESTED';
export const INVITE_USERS_REQUEST_SUCCEEDED = 'INVITE_USERS_REQUEST_SUCCEEDED';
export const INVITE_USERS_REQUEST_FAILED = 'INVITE_USERS_REQUEST_FAILED';
export const INVITE_USERS_RESET = 'INVITE_USERS_RESET';

export const inviteUsersRequested = (communityId, invites) => ({
    type: INVITE_USERS_REQUESTED,
    payload: {
        invites,
        communityId,
    },
});

export const inviteUsersRequestSucceeded = () => ({
    type: INVITE_USERS_REQUEST_SUCCEEDED,
});

export const inviteUsersRequestFailed = (errors = []) => ({
    type: INVITE_USERS_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const inviteUsersReset = () => ({
    type: INVITE_USERS_RESET,
});
