import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import {
    clearUserErrors,
    deleteUserAccountRequested,
    otherCommunitiesRequested,
    userEmailUpdateRequested,
} from '../../../user/actions';
import {
    getActiveCommunityId,
    getOtherCommunities,
    getUserCommunities,
    getUserEmail,
    getUserId,
    getUserUpdateErrors,
    isLoadingOtherCommunities,
    isUserUpdateLoading,
} from '../../../user/selectors';
import { logoutRequested } from '../../../auth/actions';
import {
    clearCommunityErrors,
    communitySearchRequested,
    joinCommunityRequested,
    unjoinCommunityRequested,
} from '../../../community/actions';
import {
    getJoinRequestErrors,
    getSearchedCommunities,
    getUnjoinRequestErrors,
    isCommunitySearchLoading,
} from '../../../community/selectors';
import styles from './styles';
import Button from '../../../common/Button';
import ConfirmModal from '../../../common/ConfirmModal';
import HeaderPage from '../../../common/HeaderPage';
import EditEmailForm from '../../../common/UserSettings/EditEmailForm';
import CommunityList from '../../../common/UserSettings/CommunityList';
import AboutAndSupport from '../../../common/UserSettings/AboutAndSupport';
import Footer from '../../../common/Footer';
import RequestLoader from '../../../common/RequestLoader';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';
import theme from '../../../theme';
import SearchBar from '../../../common/Search';
import { MANUALLY_JOINED } from '../../../user/constants';
import { Search } from '@material-ui/icons';

const JOINED_COMMUNITIES = 'joined';
const OTHER_COMMUNITIES = 'other';

const UserSettingsScreen = ({
    classes,
    communityId,
    userId,
    userEmail,
    updateLoading,
    updateErrors,
    clearUpdateErrors,
    requestUserEmailUpdate,
    deleteAccount,
    onLogout,
    joinedCommunities,
    fetchOtherCommunities,
    otherCommunities,
    loadingOtherCommunities,
    requestJoinCommunity,
    joinRequestErrors,
    clearJoinLeaveRequestErrors,
    requestLeaveCommunity,
    leaveRequestErrors,
    searchCommunities,
    searchedCommunities,
    searchLoading,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selectedCommunityId, setSelectedCommunityId] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openLeaveCommunityModal, setOpenLeaveCommunityModal] = useState(
        false,
    );
    const [openJoinCommunityModal, setOpenJoinCommunityModal] = useState(false);
    const [communities, setCommunities] = useState(() => ({
        [JOINED_COMMUNITIES]: [],
        [OTHER_COMMUNITIES]: [],
    }));
    const [searchText, setText] = useState('');

    const editEmailFormInitValues = {
        email: userEmail,
    };

    const getErrors = () => {
        const errors = [];
        if (joinRequestErrors) errors.push(...joinRequestErrors);
        if (leaveRequestErrors) errors.push(...leaveRequestErrors);
        return errors;
    };

    const errors = getErrors();

    const handleEditEmailFormSubmit = useCallback(
        (values) => {
            const request = {
                userId,
                email: values.email,
            };
            requestUserEmailUpdate(request);
        },
        [requestUserEmailUpdate, userId],
    );

    const handleViewCommunity = (id) => {
        history.push(`/communities/${id}`);
    };

    const initFilteredItems = useCallback(() => {
        setCommunities((prevState) => {
            return {
                ...prevState,
                [JOINED_COMMUNITIES]: joinedCommunities,
            };
        });
        setCommunities((prevState) => {
            return {
                ...prevState,
                [OTHER_COMMUNITIES]: otherCommunities,
            };
        });
    }, [joinedCommunities, otherCommunities]);

    useEffect(() => {
        if (searchedCommunities) {
            const joined = [];
            const other = [];
            searchedCommunities.forEach((community) => {
                if (community.membershipStatus === MANUALLY_JOINED)
                    joined.push(community);
                else other.push(community);
            });
            setCommunities((prevState) => {
                return {
                    ...prevState,
                    [JOINED_COMMUNITIES]: joined,
                };
            });
            setCommunities((prevState) => {
                return {
                    ...prevState,
                    [OTHER_COMMUNITIES]: other,
                };
            });
        }
    }, [searchedCommunities]);

    const onSearch = () => {
        if (searchText) {
            searchCommunities(searchText);
        } else {
            initFilteredItems();
        }
    };

    const onKeyChange = (text) => {
        text ? setText(text) : initFilteredItems();
    };

    useEffect(() => {
        initFilteredItems();
    }, [initFilteredItems]);

    // Leave community functions
    const handleLeaveCommunity = (id) => {
        setOpenLeaveCommunityModal(true);
        setSelectedCommunityId(id);
    };

    const handleCancelLeaveCommunity = () => {
        setOpenLeaveCommunityModal(false);
        setSelectedCommunityId('');
    };

    const handleConfirmLeaveCommunity = () => {
        requestLeaveCommunity(selectedCommunityId);
        setOpenLeaveCommunityModal(false);
    };

    // Join community functions
    const handleJoinCommunity = (id) => {
        setOpenJoinCommunityModal(true);
        setSelectedCommunityId(id);
    };

    const handleCancelJoinCommunity = () => {
        setOpenJoinCommunityModal(false);
        setSelectedCommunityId('');
    };

    const handleConfirmJoinCommunity = () => {
        requestJoinCommunity(selectedCommunityId);
        setOpenJoinCommunityModal(false);
    };

    // Account functions
    const handleDeleteAccountRequest = () => {
        setOpenDeleteModal(true);
    };

    const handleDeleteAccountConfirm = () => {
        setOpenDeleteModal(false);
        deleteAccount(userId);
        onLogout();
    };

    const handleDeleteAccountCancel = () => {
        setOpenDeleteModal(false);
    };

    useEffect(() => {
        fetchOtherCommunities();
    }, [fetchOtherCommunities]);

    return (
        <Grid className={classes.container} container direction="row">
            <RequestLoader
                isLoading={updateLoading}
                title={`${t('loading.descriptor')}...`}
            />
            <Grid
                className={classes.leftPane}
                item
                container
                direction="column"
                xs={12}
                sm={7}
            >
                <Grid item>
                    <HeaderPage title={t('Settings')} underlined={false} />
                </Grid>
                <Grid container item sm={10} xs={12}>
                    <Grid item container>
                        <Typography
                            className={classes.leftPaneTitle}
                            variant="h4"
                        >
                            {t('notifications.subjectGeneralTitle')}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <EditEmailForm
                            initialFormValues={editEmailFormInitValues}
                            onSubmit={handleEditEmailFormSubmit}
                            errors={updateErrors}
                            clearErrors={clearUpdateErrors}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={1}
                        className={classes.searchContainer}
                    >
                        <Grid item xs={11}>
                            <SearchBar
                                placeholder={t(
                                    'settings.user.communities.search',
                                )}
                                handleTextChange={onKeyChange}
                                runSearch={onSearch}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                className={classes.searchBtn}
                                color="primary"
                                onClick={onSearch}
                            >
                                <Search />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <CommunityList
                            joinedCommunities={communities[JOINED_COMMUNITIES]}
                            otherCommunities={communities[OTHER_COMMUNITIES]}
                            onView={handleViewCommunity}
                            onJoin={handleJoinCommunity}
                            onLeave={handleLeaveCommunity}
                            isSearch={searchText}
                        />
                        <RequestLoader
                            isLoading={loadingOtherCommunities || searchLoading}
                            title={t('settings.user.communities.loading')}
                        />
                    </Grid>
                    <Grid className={classes.logoutBtnContainer} item xs={12}>
                        <Button
                            className={classes.logoutBtn}
                            color="primary"
                            onClick={onLogout}
                        >
                            {t('Logout')}
                        </Button>
                    </Grid>
                    <Grid className={classes.linkContainer} item xs={12}>
                        <Typography
                            className={classes.link}
                            variant="body1"
                            onClick={handleDeleteAccountRequest}
                        >
                            {t('settings.user.deleteAccount.linkText')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.rightPane} item container xs={12} sm={5}>
                <div className={classes.aboutContainer}>
                    <AboutAndSupport />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Footer className={classes.footer} />
            </Grid>
            <ConfirmModal
                isOpen={openDeleteModal}
                title={t('settings.user.deleteAccount.linkText')}
                onClose={handleDeleteAccountCancel}
                onCancel={handleDeleteAccountCancel}
                onConfirm={handleDeleteAccountConfirm}
                cancelBtnType="default"
                cancelBtnText={t('cancel')}
                confirmBtnType="success"
                confirmBtnColor={theme.palette.error.main}
                confirmBtnText={t('settings.user.deleteAccount.confirm')}
            >
                {t('settings.user.deleteAccount.modalText')}
            </ConfirmModal>
            <ConfirmModal
                isOpen={openLeaveCommunityModal}
                title={t('settings.user.communities.leaveModal.title')}
                onClose={handleCancelLeaveCommunity}
                onCancel={handleCancelLeaveCommunity}
                onConfirm={handleConfirmLeaveCommunity}
                cancelBtnType="default"
                cancelBtnText={t('cancel')}
                confirmBtnType="success"
                confirmBtnColor={theme.palette.error.main}
                confirmBtnText={t('confirm')}
            >
                {t('settings.user.communities.leaveModal.content')}
            </ConfirmModal>
            <ConfirmModal
                isOpen={openJoinCommunityModal}
                title={t('settings.user.communities.joinModal.title')}
                onClose={handleCancelJoinCommunity}
                onCancel={handleCancelJoinCommunity}
                onConfirm={handleConfirmJoinCommunity}
                cancelBtnType="default"
                cancelBtnText={t('cancel')}
                confirmBtnType="success"
                confirmBtnColor={theme.palette.error.main}
                confirmBtnText={t('confirm')}
            >
                {t('settings.user.communities.joinModal.content')}
            </ConfirmModal>
            <SnackBarAPIResponse
                errors={errors}
                clearResponse={clearJoinLeaveRequestErrors}
            />
        </Grid>
    );
};

UserSettingsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    updateLoading: PropTypes.bool.isRequired,
    updateErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearUpdateErrors: PropTypes.func.isRequired,
    requestUserEmailUpdate: PropTypes.func.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    joinedCommunities: PropTypes.array,
    fetchOtherCommunities: PropTypes.func.isRequired,
    otherCommunities: PropTypes.array,
    loadingOtherCommunities: PropTypes.bool.isRequired,
    requestJoinCommunity: PropTypes.func.isRequired,
    joinRequestErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearJoinLeaveRequestErrors: PropTypes.func.isRequired,
    requestLeaveCommunity: PropTypes.func.isRequired,
    leaveRequestErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    searchCommunities: PropTypes.func.isRequired,
    searchedCommunities: PropTypes.array,
    searchLoading: PropTypes.bool.isRequired,
};

UserSettingsScreen.defaultProps = {
    joinedCommunities: [],
    otherCommunities: [],
    searchedCommunities: [],
};

export const StyledUserSettingsScreen = withStyles(styles)(UserSettingsScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    userId: getUserId(state),
    userEmail: getUserEmail(state),
    updateLoading: isUserUpdateLoading(state),
    updateErrors: getUserUpdateErrors(state),
    joinedCommunities: getUserCommunities(state),
    otherCommunities: getOtherCommunities(state),
    loadingOtherCommunities: isLoadingOtherCommunities(state),
    joinRequestErrors: getJoinRequestErrors(state),
    leaveRequestErrors: getUnjoinRequestErrors(state),
    searchedCommunities: getSearchedCommunities(state),
    searchLoading: isCommunitySearchLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestUserEmailUpdate: (request) =>
        dispatch(userEmailUpdateRequested(request)),
    clearUpdateErrors: () => dispatch(clearUserErrors()),
    deleteAccount: (userId) => dispatch(deleteUserAccountRequested(userId)),
    onLogout: () => dispatch(logoutRequested()),
    fetchOtherCommunities: () => dispatch(otherCommunitiesRequested()),
    requestJoinCommunity: (communityId) =>
        dispatch(joinCommunityRequested(communityId)),
    clearJoinLeaveRequestErrors: () => dispatch(clearCommunityErrors()),
    requestLeaveCommunity: (communityId) =>
        dispatch(unjoinCommunityRequested(communityId)),
    searchCommunities: (searchString) =>
        dispatch(communitySearchRequested(searchString)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledUserSettingsScreen);
