import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    MenuItem,
    Switch,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import ConfirmModal from '../../../common/ConfirmModal';
import { BUTTON_DEFAULT_COLOR } from '../../../common/Button/constants';
import ImageCaptionForm from '../../../common/ImageCaptionForm';
import styles from './styles';
import FieldLabel from '../../../common/FieldLabel';
import Input from '../../../common/ImageCaptionForm/Input';
import Textarea from '../../../common/Textarea';
import theme from '../../../theme';
import { PRIZE_RANK_OPTIONS } from '../ChallengeInfo/constants';
import {
    CHALLENGE_UNIT_TEAM,
    CHALLENGE_UNIT_USER,
} from '../../../challenges/constants';
import FormInput from '../../../common/Form/FormInput';
import { trackEvent } from '../../../utils/analytics';
import { EVENT_CHALLENGE_PRIZE_ADDED } from '../../../constants/analyticsEvents';

const PrizeModal = ({
    classes,
    isOpen,
    onClose,
    onCancel,
    onConfirm,
    prizes,
    id,
    isTeamChallenge,
    challengeId,
}) => {
    const { t } = useTranslation();
    const [isChecked, setChecked] = useState(false);

    const newPrize = () => {
        return {
            name: '',
            description: '',
            imageSizes: [],
            rank: 'Other',
            numberOfWinners: 1,
            prizeUnit: '',
        };
    };

    const [prize, setPrize] = useState(newPrize());

    const prizeUnitOptions = [CHALLENGE_UNIT_USER, CHALLENGE_UNIT_TEAM];

    useEffect(() => {
        if (isOpen) {
            setPrize(id ? prizes.find((prize) => prize.id === id) : newPrize());
        }
    }, [isOpen, prizes, id]);

    const setValue = useCallback(
        (value, field) => {
            setPrize({
                ...prize,
                [field]: value,
            });
        },
        [prize],
    );

    const showWinnerToggle = false;

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={
                id
                    ? t('challenge.prizeModal.editHeader')
                    : t('challenge.prizeModal.addHeader')
            }
            titleStyle={{
                textAlign: 'center',
                fontSize: 26,
                fontWeight: 'bold',
            }}
            cancelBtnType={BUTTON_DEFAULT_COLOR}
            cancelBtnText={t('cancel')}
            onClose={onClose}
            onCancel={onCancel}
            onConfirm={() => {
                if (!prize?.id) {
                    trackEvent(EVENT_CHALLENGE_PRIZE_ADDED, {
                        challenge_id: challengeId,
                    });
                }
                onConfirm(
                    prize?.id
                        ? prizes.map((currentPrize) => {
                              return currentPrize.id === id
                                  ? prize
                                  : currentPrize;
                          })
                        : [...prizes, prize],
                );
            }}
            confirmBtnType="success"
            centeredButtons
            cancelBtnHidden
            confirmBtnColor={theme.palette.primary.main}
            confirmBtnDisabled={!prize.name}
            fullWidth
            maxWidth="md"
        >
            {prize && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    className={classes.prizeForm}
                >
                    <Grid item xs={12}>
                        <ImageCaptionForm
                            imageSizes={prize.imageSizes}
                            handleImageChange={(sizes) =>
                                setValue(sizes, 'imageSizes')
                            }
                            imageLabel={t('challenge.prizeModal.image')}
                            imageTooltipText={t(
                                'challenge.prizeModal.imageTooltip',
                            )}
                            title={prize.name}
                            handleTitleChange={(e) => {
                                setValue(e.target.value, 'name');
                            }}
                            titleLabel={t('challenge.prizeModal.title') + '*'}
                            titlePlaceholder={t('name')}
                            description={prize.description}
                            handleDescriptionChange={(e) =>
                                setValue(e.target.value, 'description')
                            }
                            descriptionLabel={t(
                                'challenge.prizeModal.description',
                            )}
                            descriptionPlaceholder={t('description')}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        spacing={2}
                        xs={12}
                        direction="row"
                        className={classes.inputRow}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            direction="column"
                            className={classes.input}
                        >
                            <Grid item>
                                <FieldLabel
                                    text={t('challenge.prizeModal.rank')}
                                    intercomTarget="prizeModalRank"
                                />
                            </Grid>
                            <Grid item className={classes.dropDownContainer}>
                                <Input disabled />
                                <TextField
                                    onChange={(e) =>
                                        setValue(e.target.value, 'rank')
                                    }
                                    InputProps={{ disableUnderline: true }}
                                    fullWidth
                                    value={prize.rank}
                                    select
                                    placeholder={t(
                                        'challenge.prizeModal.dropdownPlaceholder',
                                    )}
                                    className={classes.dropDown}
                                >
                                    {PRIZE_RANK_OPTIONS.map((option, index) => (
                                        <MenuItem
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            direction="column"
                        >
                            <Grid item>
                                <FormInput
                                    name="numberOfWinners"
                                    label={t('challenge.prizeModal.numWinners')}
                                    tooltipIntercomTarget="prizeModalNumberOfWinners"
                                    type="number"
                                    useFormik={false}
                                    noErrorMessage
                                    value={prize.numberOfWinners}
                                    onChange={(e) =>
                                        setValue(
                                            parseInt(e.target.value),
                                            'numberOfWinners',
                                        )
                                    }
                                    placeholder={t(
                                        'challenge.prizeModal.dropdownPlaceholder',
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            direction="column"
                        >
                            {isTeamChallenge && (
                                <>
                                    <Grid item>
                                        <FieldLabel
                                            text={t(
                                                'challenge.prizeModal.winnerType',
                                            )}
                                            tooltipText=""
                                            tooltipPlacement="right"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.dropDownContainer}
                                    >
                                        <Input disabled />
                                        <TextField
                                            onChange={(e) =>
                                                setValue(
                                                    e.target.value,
                                                    'prizeUnit',
                                                )
                                            }
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                            value={prize.prizeUnit}
                                            select
                                            placeholder={t(
                                                'challenge.prizeModal.dropdownPlaceholder',
                                            )}
                                            className={classes.dropDown}
                                        >
                                            {prizeUnitOptions.map(
                                                (option, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </TextField>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {showWinnerToggle && (
                            <>
                                <Grid item xs={12} className={classes.toggle}>
                                    <Typography className={classes.toggleText}>
                                        {t(
                                            'challenge.prizeModal.toggleDescription',
                                        )}
                                    </Typography>
                                    <Switch
                                        checked={isChecked}
                                        onChange={() => setChecked(!isChecked)}
                                    />
                                </Grid>
                                <Grid container item direction="column">
                                    <Grid item>
                                        <FieldLabel
                                            text={t(
                                                'challenge.prizeModal.winnerMessageTitle',
                                            )}
                                            Icon={null}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Input
                                            value={prize.winnerMessageTitle}
                                            onChange={(e) =>
                                                setValue(
                                                    e.target.value,
                                                    'winnerMessageTitle',
                                                )
                                            }
                                            placeholder={t(
                                                'challenge.prizeModal.winnerTitlePlaceholder',
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel
                                            text={t(
                                                'challenge.prizeModal.winnerMessageBody',
                                            )}
                                            Icon={null}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Textarea
                                            rowsMin={3}
                                            value={prize.winnerMessageBody}
                                            onChange={(e) =>
                                                setValue(
                                                    e.target.value,
                                                    'winnerMessageBody',
                                                )
                                            }
                                            placeholder={t('description')}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Typography
                                variant="body2"
                                className={classes.asteriskRequired}
                            >
                                {t('onBoarding.asteriskRequired')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </ConfirmModal>
    );
};

PrizeModal.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    prizes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            imageUrl: PropTypes.string,
        }),
    ),
    id: PropTypes.string,
    isTeamChallenge: PropTypes.bool,
    challengeId: PropTypes.string,
};

PrizeModal.defaultProps = {
    prizes: [],
    id: '',
    isTeamChallenge: false,
    challengeId: '',
};

export default withStyles(styles)(PrizeModal);
