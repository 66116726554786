const styles = (theme) => ({
    inputLabel: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: '14px',
    },
    formLabel: { marginBottom: '10px' },
    selectBox: { padding: '6px 16px' },
    setupFormSection: {
        margin: '10px 0 0 0',
        paddingLeft: '35px',
    },
    textarea: theme.shineAdmin.textarea,
    input: {
        backgroundColor: theme.palette.inputBackground,
        width: '100%',
        border: '1px solid',
        borderRadius: '8px',
        padding: '6px 10px',
        fontSize: '14px',
        borderColor: theme.palette.primary.main,

        '&:focus': {
            borderColor: theme.palette.primary.dark,
        },
        '&:active': {
            borderColor: theme.palette.primary.dark,
        },
    },
    actionButton: {
        marginRight: '10px',
        paddingLeft: '40px',
        paddingRight: '40px',
        [theme.breakpoints.down('xs')]: {
            width: '48%',
        },
    },
    form: {
        '& .MuiInput-multiline': {
            fontSize: '15px',
        },
        '& .MuiInput-underline': {
            fontSize: '15px',
            marginBottom: 5,
        },
    },
    formItemDescription: {
        marginBottom: 60,
    },
    formToolTip: {
        position: 'relative',
    },
    formButtonRow: {
        position: 'relative',
        paddingBottom: '10px',
    },
    formButtonRowInfoIcon: {
        color: theme.palette.primary.main,
        opacity: 0.8,
    },
    formChallengeType: {
        width: '49%',
        '& .ChallengeForm-formButtonRowInfoIcon-257': {
            top: 0,
        },
    },
    formChallengeTypeButton: {
        fontSize: '14px',
        '& svg': {
            fill: theme.palette.primary.main,
            marginRight: '10px',
            width: '15px',
            height: '30px',
        },
    },
    dateError: {
        marginTop: '-30px',
        fontSize: '16px',
        color: theme.palette.error.main,
    },
    buttonWrapper: {
        paddingTop: '30px',
        paddingBottom: '60px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '15px',
        },
    },
});

export default styles;
