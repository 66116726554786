export const EVENT_DYNAMIC_LINK_OPEN = 'dynamic_link_open';

export const EVENT_SHARE_BEGIN = 'share_begin';

export const EVENT_SUBSCRIPTION_STRIPE_UPGRADE_BEGIN =
    'subscription_stripe_upgrade_started';
export const EVENT_SUBSCRIPTION_STRIPE_UPGRADE_COMPLETE =
    'subscription_stripe_upgrade_finished';
export const EVENT_SUBSCRIPTION_PRESS_UPGRADE = 'subscription_upgrade_pressed';
export const EVENT_SUBSCRIPTION_PRESS_CHANGE_PLAN =
    'subscription_change_plan_pressed';
export const EVENT_SUBSCRIPTION_ONBOARDING_BEGIN =
    'subscription_onboarding_begin';
export const EVENT_SUBSCRIPTION_PRESS_CONTACT = 'subscription_contact_pressed';

export const EVENT_ONBOARDING_GOOGLE_ORGANIZATION_CREATED =
    'onboarding_google_organization_created';

export const EVENT_ORGANIZATION_DOMAIN_RESTRICTED =
    'organization_domain_restricted';

export const EVENT_ACCOUNT_SSO_LOGGED_IN = 'account_sso_logged_in';

export const EVENT_ACCOUNT_LOGIN = 'account_login';

export const EVENT_GOOGLE_SIGN_IN_PRESSED = 'google_sign_in_pressed';

export const EVENT_COMMUNITY_CREATE_SUBMITTED = 'community_create_submitted';
export const EVENT_COMMUNITY_CREATE_PRESSED = 'community_create_pressed';
export const EVENT_COMMUNITY_EDIT_SUBMITTED = 'community_edit_submitted';
export const EVENT_COMMUNITY_EDIT_PRESSED = 'community_edit_pressed';

export const EVENT_CHALLENGE_INVITE_TOGGLED = 'challenge_invite_toggled';

export const EVENT_ONBOARDING_ORGANIZATION_DETAILS_SUBMITTED =
    'onboarding_organization_details_submitted';

export const EVENT_ONBOARDING_ORGANIZATION_CREATE_BEGAN =
    'onboarding_organization_create_began';

export const EVENT_ONBOARDING_EMAIL_VERIFIED = 'onboarding_email_verified';

export const EVENT_ONBOARDING_PASSWORD_SUBMITTED =
    'onboarding_password_submitted';

export const EVENT_ONBOARDING_SKIPPED = 'onboarding_skipped';

export const EVENT_ONBOARDING_CHALLENGE_TYPE_CHOSEN =
    'onboarding_challenge_type_chosen';

export const EVENT_ONBOARDING_CHALLENGE_DETAILS_SUBMITTED =
    'onboarding_challenge_details_submitted';

export const EVENT_CHALLENGE_CREATED = 'challenge_created';
export const EVENT_CHALLENGE_PUBLISHED = 'challenge_published';

export const EVENT_CHALLENGE_JOINED = 'challenge_joined';

export const EVENT_CHALLENGE_EDITED = 'challenge_edited';

export const INTERCOM_EVENTS = [EVENT_CHALLENGE_PUBLISHED];

export const EVENT_CHALLENGE_ACTION_REMOVED = 'challenge_action_removed';
export const EVENT_CHALLENGE_ACTION_ADDED = 'challenge_action_added';

export const EVENT_CHALLENGE_PRIZE_ADDED = 'challenge_prize_added';

export const EVENT_COMMUNITY_SHARE_IMAGE_DOWNLOADED =
    'community_share_image_downloaded';

export const EVENT_COMMUNITY_USERS_MANUALLY_INVITED =
    'community_users_manually_invited';

export const EVENT_DYNAMIC_LINK_COPIED = 'dynamic_link_copied';

export const EVENT_ACTION_LOGGED = 'action_logged';

export const EVENT_ACTION_EDITED = 'action_edited';

// Turn the below into events matching the format above
export const EVENT_NOTIFICATION_EDIT_BEGAN = 'notification_edit_began';
export const EVENT_NOTIFICATION_EDIT_FINISHED = 'notification_edit_finished';
export const EVENT_NOTIFICATION_CREATE_BEGAN = 'notification_create_began';
export const EVENT_NOTIFICATION_CREATE_FINISHED =
    'notification_create_finished';
export const EVENT_NOTIFICATION_DELETED = 'notification_deleted';
export const EVENT_SLACK_INTEGRATED = 'slack_integrated';

export const EVENT_PROFILE_DEACTIVATED = 'profile_deactivated';
export const EVENT_PROFILE_ACTIVATED = 'profile_activated';

export const EVENT_ACTION_VIDEO_VIEWED = 'action_video_viewed';

export const EVENT_ACTION_LINK_VIEWED = 'action_link_viewed';

export const ANALYTICS_FILTER_CHANGED = 'analytics_filter_changed';
export const EVENT_PROFILE_TROPHY_CASE_VIEWED = 'profile_trophy_case_viewed';
export const EVENT_PROFILE_ACTIVITY_VIEWED = 'profile_activity_viewed';
