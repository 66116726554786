const styles = (theme) => ({
    logActionIcon: {
        marginRight: 4,
    },
    logActionButton: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            padding: '8px 8px',
        },
    },
    buttonsContainer: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    shareButton: {
        fontSize: 'min(18px, 5vw)',
        marginLeft: 20,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginTop: '5px',
            padding: '8px 8px',
        },
    },
});

export default styles;
