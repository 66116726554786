import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../common/errors';
import { transformTheme } from './transforms';
export const getThemesState = (state) => state.themes;

export const areThemesLoading = createSelector(
    getThemesState,
    (themesState) => themesState.themes.loading,
);

export const isThemeUpdateLoading = createSelector(
    getThemesState,
    (themesState) => themesState.update.loading,
);

export const getThemesErrors = createSelector(
    getThemesState,
    (themesState) => coerceIntoErrors(themesState.themes.errors) || [],
);

export const getUpdateThemesErrors = createSelector(
    getThemesState,
    (themesState) => coerceIntoErrors(themesState.update.errors),
);

export const getThemes = createSelector(getThemesState, (themesState) =>
    themesState.themes?.themes?.map((theme) => transformTheme(theme)),
);
