import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearAuthErrors, signUpRequested } from '../../auth/actions';
import {
    getOnBoardingAccountDetails,
    getOrganizationCreationErrors,
    hasOrgSignupInfo,
    isAuthLoading,
} from '../../auth/selectors';
import { Grid, Hidden, withStyles } from '@material-ui/core';
import styles from './styles';
import OnboardingHeader from '../../common/OnboardingHeader';
import OnboardingFooter from '../../common/OnboardingFooter';
import WelcomeForm from '../../common/WelcomeForm';
import Loader from '../../common/OnBoarding/Loader';
import { noop } from '../../common/utils';
import { trackEvent } from '../../utils/analytics';
import { subscriptionSessionRequested } from '../../subscriptions/actions';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getBillingSessionLoading,
    getBillingSessionUrl,
    getStripePlanId,
} from '../../subscriptions/selectors';
import {
    ONBOARDING_CREATE_CANCEL_URL,
    CREATE_SUCCESS_URL,
} from '../../subscriptions/constants';
import { EVENT_ONBOARDING_PASSWORD_SUBMITTED } from '../../constants/analyticsEvents';

const OnboardingPasswordScreen = ({
    classes,
    isLoading,
    isSessionLoading,
    clearErrors,
    hasOrgSignupInfo,
    onSignUp,
    fetchSubscriptionSession,
    sessionUrl,
    communityId,
    planId,
    accountDetails,
    errors,
}) => {
    const history = useHistory();
    const initialFormValues = { password: '' };

    const handleSubmit = (values) => {
        trackEvent(EVENT_ONBOARDING_PASSWORD_SUBMITTED);
        const formValues = {
            username: accountDetails.username,
            password: values.password,
            organization: accountDetails.organization,
            website: accountDetails.website,
            email: accountDetails.email,
            employeeCount: accountDetails.employeeCount,
        };
        onSignUp(formValues);
    };

    useEffect(() => {
        clearErrors();
    }, [clearErrors]);

    useEffect(() => {
        if (!hasOrgSignupInfo) {
            history.push('/get-started');
        }
    }, [hasOrgSignupInfo, history]);

    useEffect(() => {
        if (sessionUrl) {
            window.location.assign(sessionUrl);
        }
    });

    useEffect(() => {
        if (communityId) {
            if (planId)
                fetchSubscriptionSession({
                    communityId,
                    successUrl: CREATE_SUCCESS_URL(planId),
                    cancelUrl: ONBOARDING_CREATE_CANCEL_URL,
                    planId,
                });
            else {
                history.push('/get-started/payment');
            }
        }
    }, [communityId, planId, fetchSubscriptionSession, history]);

    return (
        <Grid container direction="row">
            {(isLoading || isSessionLoading) && <Loader />}
            <Grid container item sm={12} md={5} className={classes.container}>
                <Grid item xs={12}>
                    <OnboardingHeader />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    grid-item--height2
                    className={classes.mainContent}
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <WelcomeForm
                            initialFormValues={initialFormValues}
                            onSubmit={handleSubmit}
                            errors={errors}
                            clearSnackBar={clearErrors}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <Grid container item xs={7}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.pictureColumn}
                        alignItems="flex-end"
                    ></Grid>
                </Grid>
            </Hidden>
            <Grid container item xs={12}>
                <OnboardingFooter />
            </Grid>
        </Grid>
    );
};

OnboardingPasswordScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    isSessionLoading: PropTypes.bool,
    sessionUrl: PropTypes.string,
    clearErrors: PropTypes.func.isRequired,
    onSignUp: PropTypes.func.isRequired,
    fetchSubscriptionSession: PropTypes.func.isRequired,
    communityId: PropTypes.string,
    planId: PropTypes.string,
    accountDetails: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasOrgSignupInfo: PropTypes.bool,
};

OnboardingPasswordScreen.defaultProps = {
    clearErrors: noop,
    communityId: null,
    planId: null,
    hasOrgSignupInfo: false,
    isSessionLoading: false,
    isLoading: false,
    sessionUrl: '',
};

const StyledOnboardingPasswordScreen = withStyles(styles)(
    OnboardingPasswordScreen,
);

const mapStateToProps = (state) => ({
    isLoading: isAuthLoading(state),
    isSessionLoading: getBillingSessionLoading(state),
    sessionUrl: getBillingSessionUrl(state),
    communityId: getActiveCommunityId(state),
    planId: getStripePlanId(state),
    accountDetails: getOnBoardingAccountDetails(state),
    errors: getOrganizationCreationErrors(state),
    hasOrgSignupInfo: hasOrgSignupInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearErrors: () => dispatch(clearAuthErrors()),
    onSignUp: (form) => dispatch(signUpRequested(form)),
    fetchSubscriptionSession: ({
        communityId,
        successUrl,
        cancelUrl,
        planId,
    }) =>
        dispatch(
            subscriptionSessionRequested(
                communityId,
                successUrl,
                cancelUrl,
                planId,
            ),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledOnboardingPasswordScreen);
