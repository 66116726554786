import { Grid, Link, List, withStyles } from '@material-ui/core';
import { getAppStoreLink, getPlayStoreLink } from '../../utils';
import IMAGES from '../../constants/images';
import React from 'react';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const AppDownloadLinks = ({ classes }) => {
    const { t } = useTranslation();
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={6}
            container
            alignItems="center"
            className={classes.container}
        >
            <List className={classes.inlineListSection}>
                {getAppStoreLink() && (
                    <li className={classes.listItem}>
                        <Link
                            href={getAppStoreLink()}
                            target="_blank"
                            aria-label={t('alt.appleDownload')}
                        >
                            <img
                                src={IMAGES.appleStoreBadge}
                                className={classes.storeBadge}
                                align="right"
                                alt={t('alt.appleDownload')}
                                aria-label={t('alt.appleDownload')}
                                aria-hidden="true"
                            />
                        </Link>
                    </li>
                )}
                {getPlayStoreLink() && (
                    <li className={classes.listItem}>
                        <Link
                            href={getPlayStoreLink()}
                            target="_blank"
                            aria-label={t('alt.androidDownload')}
                        >
                            <img
                                src={IMAGES.googleStoreBadge}
                                className={classes.storeBadge}
                                align="center"
                                alt={t('alt.androidDownload')}
                            />
                        </Link>
                    </li>
                )}
            </List>
        </Grid>
    );
};

export default withStyles(styles)(AppDownloadLinks);
