import React from 'react';

import { Can } from '../../casl/ability-context';
import UserActionsScreen from '../ActionsScreen/UserActionsScreen';
import AdminActionsScreen from './AdminActionsScreen';

export const ActionsScreen = (props) => {
    return (
        <>
            <Can I="view" a="AdminScreens">
                <AdminActionsScreen {...props} />
            </Can>
            <Can not I="view" a="AdminScreens">
                <UserActionsScreen {...props} />
            </Can>
        </>
    );
};

export default ActionsScreen;
