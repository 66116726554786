import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        maxWidth: theme.custom.mainContentWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '40px',
        paddingLeft: theme.custom.mainContentHorizontalPadding,
        paddingRight: theme.custom.mainContentHorizontalPadding,
    },
    infoIcon: {
        marginLeft: '6px',
        fontSize: '28px',
        color: 'rgba(51,51,51,.25)',
        cursor: 'pointer',

        '&:hover': {
            color: 'rgba(51,51,51,.5)',
        },
    },
    editButton: {
        borderRadius: '6px',

        '& svg': {
            marginRight: '3px',
        },
    },
    profileImageContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    profileImage: {
        display: 'block',
        width: '188px',
        height: '188px',
        borderRadius: '6px',
        border: '1px solid rgba(255,255,255)',
    },
    profileName: {
        marginTop: '20px',
        marginBottom: '10px',
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: 1.1,
        color: theme.custom.textDarkColor,
    },
    orgId: {
        marginTop: '20px',
        fontSize: '16px',
        color: theme.custom.textDarkColor,
    },
    profileDescription: {
        fontSize: '14px',
        marginTop: '10px',
        marginBottom: '10px',
        fontWeight: 600,
        lineHeight: 1.1,
        textAlign: 'center',
        color: theme.custom.textDarkColor,
    },
    followContainer: {
        marginTop: '10px',
    },
    impactTotalsLabel: {
        fontSize: '24px',
        fontWeight: 600,
        marginTop: '40px',
        marginBottom: '10px',
        lineHeight: 1.1,
        color: theme.custom.textDarkSecondaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& svg': {
            cursor: 'pointer',
            marginLeft: '6px',
            color: 'rgba(51,51,51,.25)',

            '&:hover': {
                color: 'rgba(51,51,51,.5)',
            },
        },
    },
    activityTotals: {
        marginTop: '40px',
    },
}));

export default useStyles;
