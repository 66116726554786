import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

import styles from './styles';
import FieldLabel from '../FieldLabel';
import { noop } from '../utils';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../image/selectors';
import { imageUploadRequested } from '../../image/actions';
import { connect } from 'react-redux';
import CommonPropTypes from '../propTypes';
import PhotoUpload from '../../common/PhotoUpload';
import PhotoAlternatePlaceholder from '../Photo/PhotoAlternatePlaceholder';
import FormInput from '../Form/FormInput';

const ImageCaptionForm = ({
    classes,
    imageLabel,
    imageSizes,
    imageTooltipText,
    titleLabel,
    title,
    titleTooltipText,
    titlePlaceholder,
    descriptionLabel,
    description,
    descriptionTooltipText,
    descriptionPlaceholder,
    handleTitleChange,
    handleDescriptionChange,
    handleImageChange,
    requestImageUpload,
    isImageUploading,
    imageUploadErrors,
    imageUploadResponse,
    communityId,
    titleError,
    ...props
}) => {
    return (
        <Grid container direction="row" wrap="nowrap" spacing={2} {...props}>
            <Grid container item xs={3} direction="column">
                <Grid item>
                    <FieldLabel
                        text={imageLabel}
                        tooltipText={imageTooltipText}
                    />
                </Grid>
                <Grid item>
                    <PhotoUpload
                        borderRadius={8}
                        hideTip
                        onUpload={handleImageChange}
                        maxHeight={135}
                        placeholder={
                            <PhotoAlternatePlaceholder
                                containerStyle={{ padding: '50px 0px' }}
                            />
                        }
                        photo={imageSizes?.[0]?.url}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={9} direction="column">
                <Grid item>
                    <FormInput
                        name={titleLabel}
                        useFormik={false}
                        noErrorMessage
                        label={titleLabel}
                        tooltipText={titleTooltipText}
                        tooltipIntercomTarget="imageCaptionFormTitle"
                        value={title}
                        onChange={(e) => handleTitleChange(e)}
                        placeholder={titlePlaceholder}
                        error={titleError}
                    />
                </Grid>
                <Grid item>
                    <FormInput
                        name={descriptionLabel}
                        useFormik={false}
                        noErrorMessage
                        label={descriptionLabel}
                        tooltipText={descriptionTooltipText}
                        tooltipIntercomTarget="imageCaptionFormDescription"
                        rowsMin={3}
                        value={description}
                        onChange={(e) => handleDescriptionChange(e)}
                        placeholder={descriptionPlaceholder}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

ImageCaptionForm.propTypes = {
    classes: PropTypes.object.isRequired,

    // the label for the image input
    imageLabel: PropTypes.string,

    // The current image sizes object
    imageSizes: PropTypes.oneOf([
        PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
            }),
        ),
        PropTypes.arrayOf(
            PropTypes.shape({
                file: PropTypes.shape({ url: PropTypes.string }),
            }),
        ),
    ]),

    // The image input label tooltip text
    imageTooltipText: PropTypes.string,

    // The label for the title input
    titleLabel: PropTypes.string,

    // The current title input value
    title: PropTypes.string,

    // The image input label tooltip text
    titleTooltipText: PropTypes.string,

    // The label for the description input
    descriptionLabel: PropTypes.string,

    // The current description input value
    description: PropTypes.string,

    // The description input label tooltip text
    descriptionTooltipText: PropTypes.string,

    // Function called when the title input changes
    handleTitleChange: PropTypes.func,

    // Function called when the description input changes
    handleDescriptionChange: PropTypes.func,

    // Function called when the image URL changes
    handleImageChange: PropTypes.func,

    // Connected props
    communityId: PropTypes.string.isRequired,
    requestImageUpload: PropTypes.func.isRequired,
    isImageUploading: PropTypes.bool.isRequired,
    imageUploadErrors: CommonPropTypes.errors.isRequired,
    imageUploadResponse: PropTypes.object.isRequired,
    titlePlaceholder: PropTypes.string,
    descriptionPlaceholder: PropTypes.string,
    titleError: PropTypes.bool,
};

ImageCaptionForm.defaultProps = {
    imageLabel: '',
    imageSizes: [],
    imageTooltipText: '',
    titleLabel: '',
    title: '',
    titleTooltipText: '',
    descriptionLabel: '',
    description: '',
    descriptionTooltipText: '',
    handleTitleChange: noop,
    handleDescriptionChange: noop,
    handleImageChange: noop,
    titlePlaceholder: '',
    descriptionPlaceholder: '',
    titleError: false,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isImageUploading: isImageUploadRequestPending(state),
    imageUploadErrors: getImageUploadErrors(state),
    imageUploadResponse: getImageUploadResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestImageUpload: (request) => dispatch(imageUploadRequested(request)),
});

export const StyledImageCaptionForm = withStyles(styles)(ImageCaptionForm);

const ConnectedImageCaptionForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledImageCaptionForm);

export default ConnectedImageCaptionForm;
