import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL, LOGIN_METHOD_MICROSOFT } from './constants';

const betterLivingPrimary = '#0058A3';
const betterLivingPrimaryDark = '#004682';

const BETTERLIVING_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'IKEA Better Living',
    clientId: '5720f445a08aad025c3c6f00',
    clientSecret:
        '02aBkGZzrcsbSUvyI_wJKknWxM1DeCBhO0O3GBwMemi12TrAcK6t-yFvLbxB_aeCMt',
    microsoftClientId: '38ee14d9-47a5-4f82-bcdd-36040975cb7f',
    microsoftClientAuthority:
        'https://login.microsoftonline.com/7e1a2e8a-1c06-4fdd-90b7-ccd696f0c7ee',
    apiUri: process.env.REACT_APP_IKEA_API_URI,
    loginMethods: [LOGIN_METHOD_EMAIL, LOGIN_METHOD_MICROSOFT],
    colors: {
        primary: betterLivingPrimary,
        primaryDark: betterLivingPrimaryDark,
        primaryForeground: betterLivingPrimary,
        secondary: betterLivingPrimary,
        secondaryDark: betterLivingPrimaryDark,
        accent: '#1e3c7b',
        accentDark: '#1e3c7b',
        warn: betterLivingPrimary,
        warnDark: betterLivingPrimaryDark,
        EditingBannerBackground: '#ffa524',
    },
};

export default BETTERLIVING_PROPERTIES;
