import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import useStyles from './styles';

import ConfirmModal from '../../../common/ConfirmModal';
import Button from '../../../common/Button';
import {
    BUTTON_ACCENT_COLOR,
    BUTTON_DEFAULT_COLOR,
} from '../../../common/Button/constants';
import { noop } from '../../../utils';

const JoinTeam = ({
    isOpen,
    setOpen,
    teams,
    onClose,
    onCancel,
    onJoinTeam,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
    }, [onClose, setOpen]);

    const handleCancel = useCallback(() => {
        onCancel();
        setOpen(false);
    }, [onCancel, setOpen]);

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={t('challenge.joinTeamCTA')}
            confirmBtnHidden
            cancelBtnType={BUTTON_DEFAULT_COLOR}
            cancelBtnText={t('cancel')}
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={noop}
        >
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography>{t('challenge.teamJoin')}</Typography>
                </Grid>
                <Grid container item>
                    {teams?.map(({ id, image, name }, index) => (
                        <Grid
                            key={index}
                            container
                            item
                            direction="row"
                            wrap="nowrap"
                            justify="space-between"
                            alignItems="center"
                            className={classes.container}
                        >
                            <Grid
                                container
                                item
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid item>
                                    <img
                                        alt={t('alt.teamPhoto')}
                                        src={image}
                                        width={40}
                                        height={40}
                                        className={classes.image}
                                    />
                                </Grid>
                                <Grid item>{name}</Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    color={BUTTON_ACCENT_COLOR}
                                    size="small"
                                    onClick={() => onJoinTeam(id)}
                                >
                                    <Add /> {t('challenge.joinChallengeCTA')}
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid item>
                    <Typography className={classes.description}>
                        {t('challenge.teamSelect')}
                    </Typography>
                </Grid>
            </Grid>
        </ConfirmModal>
    );
};

JoinTeam.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            image: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onJoinTeam: PropTypes.func.isRequired,
};

JoinTeam.defaultProps = {
    teams: null,
    onClose: noop,
    onCancel: noop,
};

export default JoinTeam;
