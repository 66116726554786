export const PERMISSIONS_LIST = [
    {
        parent: 'actions',
        parentInternalName: 'viewActionsAdmin',
    },
    {
        parent: 'assets',
        parentInternalName: 'viewAssets',
    },
    {
        parent: 'community',
        parentInternalName: 'viewCommunityProfile',
    },
    {
        parent: 'challenges',
        parentInternalName: 'viewChallengesAdmin',
    },
    {
        parent: 'flaggedContent',
        parentInternalName: 'viewFlaggedContent',
    },
    {
        parent: 'notifications',
        parentInternalName: 'viewMasterNotifications',
    },
    {
        parent: 'support',
        parentInternalName: 'viewSupport',
    },
    {
        parent: 'users',
        parentInternalName: 'viewCommunityMembers',
    },
    {
        parent: 'themes',
        parentInternalName: 'viewThemes',
    },
    {
        parent: 'billing',
        parentInternalName: 'viewBillingInfo',
    },
];
