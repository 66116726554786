import moment from 'moment';

export const getResolution = (startDate, endDate) => {
    const diffDays = moment(endDate).diff(moment(startDate), 'days');
    const diffMonths = moment(endDate).diff(moment(startDate), 'months');
    const diffYears = moment(endDate).diff(moment(startDate), 'years');

    if (diffDays <= 31) {
        return 'day';
    } else if (diffDays > 31 && diffMonths < 12) {
        return 'week';
    } else if (diffYears >= 1 && diffYears < 3) {
        return 'month';
    } else return 'year';
};
