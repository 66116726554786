import React from 'react';
import PropTypes from 'prop-types';

export const ARef = (props) => <button {...props} />;

const ConditionalWrapper = ({ wrap, Parent, ParentProps, children }) => {
    return wrap ? <Parent {...ParentProps}>{children}</Parent> : children;
};

ConditionalWrapper.propTypes = {
    wrap: PropTypes.bool.isRequired,
    Parent: PropTypes.elementType.isRequired,
    ParentProps: PropTypes.object,
    children: PropTypes.node.isRequired,
};

ConditionalWrapper.defaultProps = {
    ParentProps: {},
};

export default ConditionalWrapper;
