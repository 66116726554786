import { InfoRounded, NotInterested } from '@material-ui/icons';

export const DATE_FORMAT = 'MMM D h:mm A';

export const COLUMNS = [
    { Header: 'User', accessor: 'user', sortable: false },
    { Header: 'Points', accessor: 'points' },
    { Header: 'Buzzes', accessor: 'buzzes' },
    { Header: 'Throttles', accessor: 'throttles' },
    { Header: 'Penalty 1', accessor: 'penalty1' },
    { Header: 'Penalty 2', accessor: 'penalty2' },
    { Header: 'Penalty 3 ', accessor: 'penalty3' },
    { Header: 'Penalty 4', accessor: 'penalty4' },
    { Header: 'Penalty 5', accessor: 'penalty5' },
    { Header: 'warning', accessor: 'warnings' },
    { accessor: 'actions', sortable: false },
];

export const BTN_WARN_USER = {
    key: 'warnChallengeUser',
    actions: 'warn',
    subject: 'ChallengeUser',
    tooltip: 'challenge.penalties.warn.send',
    icon: InfoRounded,
};

export const BTN_REMOVE_USER = {
    key: 'removeChallengeUser',
    actions: 'remove',
    subject: 'ChallengeUser',
    tooltip: 'remove',
    icon: NotInterested,
};

export const BUTTONS_CELL = [BTN_WARN_USER, BTN_REMOVE_USER];
