import get from 'lodash/get';
import { prettyFormatNumber } from '../common/transforms';
import { mapSortAddRank } from '../utils';

export const transformTopActions = (data = []) =>
    mapSortAddRank(
        data.map((activity = {}) => ({
            value: activity.total,
            label: activity.contentName,
            image: get(activity, 'images[0].url'),
        })),
    );

export const transformActivityStats = (data = []) => {
    const activityStats = data.reduce(
        (
            total = [0, 0, 0, 0],
            activity = { total: 0, points: 0, actionEarned: 0 },
        ) => {
            return [
                total[0] + activity.total || 0,
                total[1] + activity.points || 0,
                total[2] + activity.actionEarned || 0,
                total[3],
            ];
        },
        [0, 0, 0, data.length],
    );
    return activityStats.map((stat) => {
        return prettyFormatNumber(stat);
    });
};

export const transformTopUsers = (data = []) =>
    mapSortAddRank(
        data.map((activity = {}) => ({
            id: activity.id,
            value: activity.points,
            label: activity.contentName,
            image: get(activity, 'images[0].url'),
        })),
    );

export const transformUserStats = (data = {}) => {
    const {
        activeUsers = 0,
        newUsers = 0,
        avgActions = 0,
        avgActionsPerDay = 0,
    } = data;

    return [
        prettyFormatNumber(activeUsers, 0),
        prettyFormatNumber(newUsers, 0),
        prettyFormatNumber(avgActions, 2),
        prettyFormatNumber(avgActionsPerDay, 2),
    ];
};
