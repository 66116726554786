import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    banner: {
        marginTop: '60px',
        marginBottom: '30px',
        borderRadius: 5,
        backgroundColor: theme.palette.primary.contrastText,
        border: '3px',
        borderColor: theme.custom.EditingBannerBackground,
        borderStyle: 'solid',
        color: theme.custom.bgDarkAccentColor,
    },
    link: {
        color: theme.palette.primary.main,
        fontWeight: '700',
    },
    messageGridItem: {
        flex: 1,
        marginLeft: 25,
        marginRight: 25,
    },
    messageText: {
        color: theme.palette.primaryForeground,
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0,
        textAlign: 'left',
    },
}));
