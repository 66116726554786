import DEFAULT_PROPERTIES from './default';

const JOULEBUG_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'JouleBug',
    clientId: '52c6e3a6f3b26c1b74a7cce0',
    clientSecret:
        '02EcKXRmMfwmVklnXHmli_vO-xnaWNyUOkAfj5NZISFShsrcYJDv0dEYKqLWXM1J1s',
    microsoftClientId: '71b90519-317a-4ab6-8f00-84dfac646535',
    microsoftClientAuthority:
        'https://login.microsoftonline.com/cf1a8e4b-971c-4a33-83a7-db26afe7a6dc',
    integrations: {
        slack: {
            redirectUri: 'https://staging.web.joulebug.com/settings',
        },
    },
    wordpressDomain: 'https://joulebug.com',
    // Flag for enabling the org creation flow
    shouldShowSignUp: true,
    appDescription:
        'With JouleBug, compete in challenges with fellow co-workers to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we work towards common goals.',
    appStoreLink:
        'https://apps.apple.com/us/app/joulebug-enterprise/id1105090085',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.carebug',
};
export default JOULEBUG_PROPERTIES;
