import { Ability } from '@casl/ability';
import { PERMISSIONS } from './constants';

Ability.addAlias('activate');
Ability.addAlias('deactivate');
Ability.addAlias('edit');
Ability.addAlias('optOut');
Ability.addAlias('reactivate');
Ability.addAlias('send');
Ability.addAlias('set');
Ability.addAlias('suspend');
Ability.addAlias('move');
Ability.addAlias('view');

export const getUserPermissions = (user = {}, activeCommunityId) => {
    const { communityPermissions = {} } = user;
    const id = activeCommunityId;
    const userPermissions =
        id && communityPermissions[id] ? communityPermissions[id].actions : [];
    const permissions = PERMISSIONS.filter(
        (permission) => userPermissions.indexOf(permission.identifier) !== -1,
    );

    if (Object.keys(communityPermissions).length > 0) {
        permissions.push({ action: 'view', subject: 'AdminScreens' });
        permissions.push({ action: 'view', subject: 'Account' });
    }
    return permissions;
};

let rules = [];
try {
    rules = JSON.parse(localStorage.getItem('rules')) || [];
} catch (error) {
    rules = [];
}

export default new Ability(rules);
