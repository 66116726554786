import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_AZURE_AD } from './constants';

const NNPCGROUP_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    loginMethods: [LOGIN_METHOD_AZURE_AD],
    appName: 'Joulebug: NNPC',
    clientId: '671fab094b4cb6293eeee942',
    clientSecret:
        '02nZLP6oaXgyDfGG21oVKIx8O6amApE3XKZp4G0bu08N-0Vts7ehm92beOHjO7ZQlq',
    microsoftClientId: '83d8edee-0da4-45a4-808b-894f5c2ccd52',
    microsoftClientAuthority:
        'https://login.microsoftonline.com/c9bfec4a-5ccc-4996-bf58-9401877a9892',
    wordpressDomain: 'https://joulebug.com',
    // Flag for enabling the org creation flow
    shouldShowSignUp: false,
    appDescription:
        'With JouleBug, compete in challenges with fellow co-workers to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we work towards common goals.',
    appStoreLink:
        'https://apps.apple.com/us/app/joulebug-enterprise/id1105090085',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.carebug',
};
export default NNPCGROUP_PROPERTIES;
