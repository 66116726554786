import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import EstimateUnitContainer from './EstimateUnitContainer';
import theme from '../../theme';

const PaymentPlanFooter = ({ units, userCount }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getIconData = (index) => {
        switch (index) {
            case 1:
                return ['jb-waste', 26, theme.custom.waste];
            case 2:
                return ['jb-water', 26, theme.custom.aqua];
            default:
                return ['jb-carbon', 26, theme.custom.grey83];
        }
    };

    return (
        <Grid className={classes.container} container direction="row">
            <Grid item container>
                <Typography className={classes.title} variant="h1">
                    {t('account.plan.footerTitle')}
                </Typography>
            </Grid>
            <Grid item justify="space-between" container wrap="nowrap">
                {units.map((unit, index) => (
                    <Grid key={index} style={{ padding: '2px', flex: 1 }} item>
                        <EstimateUnitContainer
                            iconName={getIconData(index)[0]}
                            size={getIconData(index)[1]}
                            color={getIconData(index)[2]}
                            value={unit.value}
                            unit={unit.unit}
                            subtitle={unit.subtitle}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid item container>
                <Typography className={classes.endText} variant="body1">
                    {t('account.plan.footerEndText', { userCount })}
                </Typography>
            </Grid>
        </Grid>
    );
};

PaymentPlanFooter.propTypes = {
    units: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
            subtitle: PropTypes.string,
        }),
    ),
    userCount: PropTypes.number,
};

PaymentPlanFooter.defaultProps = {
    units: [
        {
            value: 0,
            unit: '',
            subtitle: '',
        },
    ],
    userCount: 0,
};

export default PaymentPlanFooter;
