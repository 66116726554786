import PropTypes from 'prop-types';

export const IconComponentPropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
]);

export const ButtonsCellPropType = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string.isRequired,
        actions: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
        tooltip: PropTypes.string.isRequired,
        icon: IconComponentPropType.component,
    }),
);
