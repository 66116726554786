import IMAGES from '../../../constants/images';

const styles = (theme) => ({
    scrollOption: {
        marginTop: '65px',
        paddingBottom: '30px',
        paddingTop: '60px',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            paddingBottom: 'auto',
            paddingTop: '20px',
            marginTop: '10px',
        },
    },
    challengeTypeSection: {
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            paddingTop: '8%',
            paddingBottom: '2%',
        },
    },
    challengeTypeTitle: {
        fontWeight: '700',
    },
    imageSection: {
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${IMAGES.bgWelcome})`,
        backgroundSize: 'cover',
    },
    typesSection: {
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
    buttonWrapper: {
        paddingTop: '30px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '15px',
        },
    },
    actionButton: {
        marginRight: '10px',
        paddingLeft: '40px',
        paddingRight: '40px',
        [theme.breakpoints.down('xs')]: {
            width: '48.3%',
        },
    },
    toggleButton: {
        padding: '35px',
    },
    toggleButtonWrapper: {
        padding: '0px 8px 8px 0',
        [theme.breakpoints.down('xs')]: {
            minWidth: '159px',
        },
    },
    actionIcon: {
        height: 30,
        marginRight: 5,
    },
    actionsText: {
        fontSize: 16,
        fontStyle: 'italic',
        paddingRight: 5,
    },
    actionContainer: {
        paddingRight: 10,
    },
    actionName: {
        fontSize: 16,
        fontStyle: 'italic',
    },
    themeDescriptionText: {
        fontSize: 16,
        marginTop: 5,
        marginBottom: 10,
    },
});

export default styles;
