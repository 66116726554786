import { Delete, Edit } from '@material-ui/icons';

export const ECO_ACTIONS = 'Eco Actions';
export const FITNESS_ACTIONS = 'Fitness Actions';
export const NUTRITION_ACTIONS = 'Nutrition Actions';
export const DIVERSITY_ACTIONS = 'D&I Actions';
export const REMOTE_ACTIONS = 'RE Actions';
export const CIVIC_ACTIONS = 'Civic Actions';

export const DATE_FORMAT = 'MMM DD, YYYY';
export const TIME_FORMAT = 'h:mm A';

export const DEFAULT_CONTENT_FILTER_TEXT_KEY = 'challenge.info.allActions';

export const prizeButtonsCell = [
    {
        key: 'editChallengePrizes',
        actions: 'edit',
        subject: 'ChallengePrizes',
        tooltip: 'edit',
        icon: Edit,
    },
    {
        key: 'deleteChallengePrizes',
        actions: 'edit',
        subject: 'ChallengePrizes',
        tooltip: 'delete',
        icon: Delete,
    },
];

export const PRIZE_RANK_OPTIONS = [
    { name: '1st', value: 1 },
    { name: '2nd', value: 2 },
    { name: '3rd', value: 3 },
    { name: 'Other', value: 4 },
];
