const styles = (theme) => ({
    logoContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingTop: '10px',
    },
    customerInfoContainer: { paddingRight: '40px' },
    logo: { width: '40px' },
});

export default styles;
