const styles = (theme) => ({
    container: {
        maxWidth: '1280px',
        position: 'relative',
        marginRight: '20px',
        marginLeft: '20px',
        justifyContent: 'center',
    },
});

export default styles;
