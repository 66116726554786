import React from 'react';

import TrophySection from '../../../common/TrophySection';
import { ProfileTrophyPropType } from '../propTypes';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const ProfileTrophies = ({ userTrophies }) => {
    const { t } = useTranslation();
    return (
        <Grid container direction="column" spacing={4}>
            <TrophySection
                title={t('profile.actions')}
                trophies={userTrophies.actions}
                description={t('profile.noActions')}
                hasBorder
            />
            <TrophySection
                title={t('profile.badges')}
                trophies={userTrophies.badges}
                description={t('profile.noBadges')}
            />
            <TrophySection
                title={t('profile.medals')}
                trophies={userTrophies.medals}
                description={t('profile.noMedals')}
                hasMultiplier
            />
        </Grid>
    );
};

ProfileTrophies.propTypes = {
    userTrophies: ProfileTrophyPropType.isRequired,
};

export default ProfileTrophies;
