import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 'min(14px, 4vw)',
        backgroundColor: theme.custom.bgLightColor,

        '&:hover': {
            backgroundColor: theme.custom.bgLightAccentColor,
        },

        '&$selected': {
            backgroundColor: theme.custom.bgLightColor,
        },
    },
    selected: {
        backgroundColor: theme.custom.bgLightColor,
    },
    container: {
        backgroundColor: theme.custom.bgLightAccentColor,
    },
    fullWidth: {
        width: '100%',
    },
    footer: (props) => ({
        backgroundColor: theme.custom.bgLightColor,
        padding: props.footerPadding,
    }),
    body: {
        padding: `16px ${theme.custom.mainContentHorizontalPadding}`,
        width: '100%',
    },
}));

export default useStyles;
