const styles = (theme) => ({
    modal: {
        alignItems: 'flex-start',
    },
    paper: {
        maxWidth: '100%',
        maxHeight: 'calc(100% - 20px)',
        width: '100%',
        margin: '10px',
        borderRadius: 0,
        backgroundColor: theme.custom.bgLightAccentColor,

        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px',
            width: '600px',
            margin: '30px auto',
        },

        [theme.breakpoints.up('md')]: {
            maxWidth: '900px',
            width: '900px',
        },

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    leftContainer: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        '&:hover': {
            backgroundColor: theme.custom.bgBlackTransparent,

            '& $button': {
                opacity: 1,
            },
        },
    },
    photo: {
        width: '100%',
        height: '100%',
    },
    button: {
        position: 'absolute',
        margin: '8px',
        opacity: 0.25,
    },
    popper: {
        zIndex: theme.zIndex.modal + 1,
        margin: '2px 0',
    },
    imageList: {
        minWidth: '160px',
        padding: '5px 0',
        margin: 0,
        listStyle: 'none',
    },
    imageListItem: {
        display: 'block',
        width: '100%',
        padding: '3px 20px',
        textDecoration: 'none',
        color: theme.custom.textDarkColor,

        '&:hover': {
            backgroundColor: theme.custom.bgLightColor,
        },
    },
    header: {
        backgroundColor: theme.custom.bgLightColor,
    },
    headerTitle: {
        overflow: 'hidden',
    },
    userImageWrapper: {
        width: '17%',
        margin: '8px 8px 8px 16px',
    },
    actionImageWrapper: {
        width: '24%',
        margin: '8px 8px 8px 16px',
    },
    rightContainer: {
        color: theme.custom.bgDarkColor,
        overflowY: 'scroll',
        paddingBottom: '46px',

        [theme.breakpoints.up('sm')]: {
            maxHeight: '300px',
        },

        [theme.breakpoints.up('md')]: {
            maxHeight: '450px',
        },

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    image: {
        width: '100%',
    },
    userName: {
        width: '100%',
        margin: '8px 8px 4px 0',
        fontSize: '22px',
        fontWeight: 800,
        color: theme.palette.common.black,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    defaultActionText: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    leftColumn: {
        width: '12.5%',
        margin: '8px 8px 8px 16px',
    },
    likes: {
        width: '100%',
        padding: '18px 0 16px',
        fontSize: '18px',
        lineHeight: '24px',
    },
    comment: {
        borderBottom: `1px solid ${theme.custom.bgSeparator}`,
    },
    commentOwner: {
        paddingTop: '16px',
        fontSize: '18px',
    },
    commentIcon: {
        marginTop: '20px',
    },
    commentMessage: {
        marginBottom: '15px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    emptyComments: {
        width: '100%',
        padding: '20px 0',
        borderBottom: `1px solid ${theme.custom.bgSeparator}`,
        fontSize: '14px',
        textAlign: 'center',
    },
    footer: {
        backgroundColor: theme.custom.bgLightColor,
        position: 'absolute',
        bottom: '0px',
        right: 0,
        maxWidth: '50%',
    },
    closeButton: {
        margin: '6px 12px',
        backgroundColor: theme.palette.accent.main,

        '&:hover': {
            backgroundColor: theme.palette.accent.dark,
        },
    },
});

export default styles;
