import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {},
    divider: {
        height: '1px',
        border: 'none',
        background: 'black',
    },
    info: {
        paddingTop: '5px',
        paddingLeft: '10px',
    },
    name: ({ cheatingAction }) => ({
        paddingBottom: '10px',
        textAlign: cheatingAction && 'center',
    }),
    description: ({ cheatingAction }) => ({
        maxWidth: !cheatingAction && '50%',
        display: cheatingAction && 'flex',
        justifyContent: cheatingAction && 'center',
    }),
    cheatMessage: {
        width: '50%',
        textAlign: 'center',
    },
    points: {
        fontSize: '24px',
        paddingRight: '5px',
    },
}));

export default useStyles;
