import keys from 'lodash/keys';
import { transformCommunityPermission } from '../user/transforms';

export const deriveActiveCommunityFromUser = (
    user = {},
    userViewActive = false,
) => {
    const whitelist = {
        superAdmin: 1,
        orgAdmin: 2,
        communityAdmin: 3,
        jbWhiteLabelAdmin: 3,
    };
    let highestPermission;
    let activeCommunity = {};
    if (user) {
        const { communityPermissions, organization } = user;
        const userCommunities = keys(communityPermissions);
        if (userCommunities.length === 0 || userViewActive) {
            return {
                id: organization.id,
                name: organization.name,
                description: organization.description,
                contentType: organization.contentType,
                imageSizes: organization.imageSizes,
                contentURL: organization.contentURL,
                created: organization.created,
            };
        }
        userCommunities.forEach((communityId) => {
            const role = communityPermissions[communityId].role;

            if (role === 'superAdmin') {
                highestPermission = whitelist[role];
                activeCommunity = transformCommunityPermission(
                    communityPermissions[communityId],
                );
            } else if (role === 'orgAdmin' && highestPermission !== 1) {
                highestPermission = whitelist[role];
                activeCommunity = transformCommunityPermission(
                    communityPermissions[communityId],
                );
            } else {
                highestPermission = whitelist[role];
                activeCommunity = transformCommunityPermission(
                    communityPermissions[communityId],
                );
            }
        });
    }
    return activeCommunity;
};
