import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import {
    getActiveCommunityId,
    getUserContactEmail,
} from '../../user/selectors';
import { CommunityProfilePropType } from '../CommunityProfileScreen';
import {
    communityRequested,
    communityUpdateRequested,
} from '../../community/actions';
import {
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../image/selectors';
import { imageUploadRequested } from '../../image/actions';
import {
    getCommunityAccessCode,
    getCommunityErrors,
    getCommunityProfile,
    getCommunityUpdateErrors,
    isCommunityLoading,
    isCommunityUpdateLoading,
} from '../../community/selectors';
import { subscriptionRequested } from '../../subscriptions/actions';
import {
    getCurrentSubscription,
    getPaymentMethod,
    isSubscriptionCanceled,
} from '../../subscriptions/selectors';
import CommonPropTypes from '../../common/propTypes';
import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import HeaderPage from '../../common/HeaderPage';
import CommunityInformationForm from '../../common/CommunityInformationForm';
import PaymentMethod from '../../common/PaymentMethod';
import AccountPlan from '../../common/AccountPlan';
import { EMPLOYEE_COUNT_DROPDOWN_VALUES } from './constants';
import { getAccountPropsFromSub } from '../../subscriptions/utils';
import BillingHistory from '../../common/BillingHistory';
import { trackEvent } from '../../utils/analytics';
import { EVENT_SUBSCRIPTION_PRESS_CHANGE_PLAN } from '../../constants/analyticsEvents';
import { PARAM_SUBSCRIPTION_ID } from '../../constants/analyticsParams';

const AccountScreen = ({
    classes,
    communityId,
    profileLoading,
    profileErrors,
    profile,
    requestCommunity,
    imageUploading,
    imageUploadErrors,
    imageUploadResponse,
    requestImageUpload,
    updateLoading,
    updateErrors,
    requestUpdate,
    paymentMethod,
    subscription,
    fetchCurrentSubscription,
    canceled,
    accessCode,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const params = new URLSearchParams(window.location.search);
    if (params.get('stripeCancellation') && canceled)
        history.push('/account?cancel=true');

    const isPresentable = useMemo(
        () => !profileLoading && profileErrors == null && profile != null,
        [profileLoading, profileErrors, profile],
    );

    useEffect(() => {
        requestCommunity(communityId);
    }, [requestCommunity, communityId]);

    const [existingCommunityImage, setExistingCommunityImage] = useState(null);
    const [newCommunityImage, setNewCommunityImage] = useState(null);
    const [imageSizes, setImageSizes] = useState(null);

    useEffect(() => {
        if (newCommunityImage !== null) {
            requestImageUpload({
                communityId,
                file: newCommunityImage,
            });
        }
    }, [newCommunityImage, requestImageUpload, communityId]);

    useEffect(() => {
        if (
            !imageUploading &&
            imageUploadErrors === null &&
            imageUploadResponse !== null
        ) {
            setImageSizes(imageUploadResponse);
        }
    }, [imageUploading, imageUploadErrors, imageUploadResponse, setImageSizes]);

    const communityFormInitialValues = useMemo(
        () => ({
            name: profile?.name,
            description: profile?.description || '',
            accessCode,
            /*
             * website: '',
             * count: '1-100',
             */
        }),
        [profile, accessCode],
    );

    const handleCommunityInfoSubmit = useCallback(
        ({ name, description }) => {
            requestUpdate({
                communityId,
                name,
                description,
                imageSizes,
            });
        },
        [requestUpdate, communityId, imageSizes],
    );

    const handleCommunityImageUpload = (image) => {
        setExistingCommunityImage(URL.createObjectURL(image));
        setNewCommunityImage(image);
        return newCommunityImage;
    };

    const resetUploadedCommunityImage = () => {
        if (profile.image) setExistingCommunityImage(profile.image);
        else setExistingCommunityImage(null);
    };

    useEffect(() => {
        if (profile?.image) {
            setExistingCommunityImage(profile.image);
        } else {
            setExistingCommunityImage(null);
        }
    }, [profile]);

    useEffect(() => {
        fetchCurrentSubscription({ groupId: communityId });
    }, [communityId, fetchCurrentSubscription]);

    const accountPlanProps = useMemo(() => {
        return getAccountPropsFromSub(subscription);
    }, [subscription]);

    return (
        <Grid className={classes.container} container direction="row">
            <RequestLoader
                isLoading={profileLoading}
                title={`${t('Loading Community')}...`}
            />
            <RequestLoader
                isLoading={updateLoading}
                title={`${t('Saving Changes')}...`}
            />
            <ErrorList errors={profileErrors} />
            <ErrorList errors={updateErrors} />
            {isPresentable && (
                <Grid item container>
                    <Grid item container>
                        <Grid
                            className={classes.accountContainer}
                            item
                            container
                        >
                            <Grid item container direction="column">
                                <Grid item container>
                                    <HeaderPage
                                        title={t('account.pageTitle')}
                                        underlined={false}
                                    />
                                </Grid>
                                <Grid item container>
                                    <CommunityInformationForm
                                        initialValues={
                                            communityFormInitialValues
                                        }
                                        options={EMPLOYEE_COUNT_DROPDOWN_VALUES}
                                        handleCommunityImageUpload={
                                            handleCommunityImageUpload
                                        }
                                        resetUploadedImage={
                                            resetUploadedCommunityImage
                                        }
                                        existingImage={existingCommunityImage}
                                        onSubmit={handleCommunityInfoSubmit}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.leftSectionContainer}
                                    item
                                    xs={12}
                                >
                                    {paymentMethod && (
                                        <PaymentMethod
                                            existingPayMethod={paymentMethod}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={classes.planContainer} item container>
                            <Grid item xs={12}>
                                <HeaderPage
                                    title={t('account.plan.title')}
                                    underlined={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AccountPlan
                                    accountPlanProps={accountPlanProps}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NavLink
                                    onClick={() => {
                                        trackEvent(
                                            EVENT_SUBSCRIPTION_PRESS_CHANGE_PLAN,
                                            {
                                                [PARAM_SUBSCRIPTION_ID]:
                                                    subscription?.id,
                                            },
                                        );
                                    }}
                                    to="/payment"
                                >
                                    <Typography
                                        className={classes.link}
                                        variant="body1"
                                    >
                                        {t('account.plan.change')}
                                    </Typography>
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        className={classes.billingHistoryContainer}
                    >
                        <BillingHistory />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

AccountScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    profileLoading: PropTypes.bool.isRequired,
    profileErrors: CommonPropTypes.errors,
    profile: CommunityProfilePropType,
    requestCommunity: PropTypes.func.isRequired,
    imageUploading: PropTypes.bool.isRequired,
    imageUploadErrors: CommonPropTypes.errors,
    imageUploadResponse: PropTypes.object,
    requestImageUpload: PropTypes.func.isRequired,
    updateLoading: PropTypes.bool.isRequired,
    updateErrors: CommonPropTypes.errors,
    requestUpdate: PropTypes.func.isRequired,
    paymentMethod: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    fetchCurrentSubscription: PropTypes.func.isRequired,
    canceled: PropTypes.bool.isRequired,
    accessCode: PropTypes.string,
};

AccountScreen.defaultProps = {
    profileErrors: null,
    profile: null,
    accessCode: '',
    imageUploadErrors: null,
    imageUploadResponse: null,
    updateErrors: null,
};

export const StyledAccountScreen = withStyles(styles)(AccountScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    profileLoading: isCommunityLoading(state),
    profileErrors: getCommunityErrors(state),
    profile: getCommunityProfile(state),
    accessCode: getCommunityAccessCode(state),
    imageUploading: isImageUploadRequestPending(state),
    imageUploadErrors: getImageUploadErrors(state),
    imageUploadResponse: getImageUploadResponse(state),
    updateLoading: isCommunityUpdateLoading(state),
    updateErrors: getCommunityUpdateErrors(state),
    contactEmail: getUserContactEmail(state),
    paymentMethod: getPaymentMethod(state),
    subscription: getCurrentSubscription(state),
    canceled: isSubscriptionCanceled(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestCommunity: (request) => dispatch(communityRequested(request)),
    requestImageUpload: (request) => dispatch(imageUploadRequested(request)),
    requestUpdate: (request) => dispatch(communityUpdateRequested(request)),
    fetchCurrentSubscription: (request) =>
        dispatch(subscriptionRequested(request)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledAccountScreen);
