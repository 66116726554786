import {
    COMMUNITY_REQUEST_FAILED,
    COMMUNITY_REQUEST_SUCCEEDED,
    COMMUNITY_REQUESTED,
    COMMUNITY_UPDATE_REQUEST_FAILED,
    COMMUNITY_UPDATE_REQUEST_SUCCEEDED,
    COMMUNITY_UPDATE_REQUESTED,
    JOIN_COMMUNITY_REQUESTED,
    JOIN_COMMUNITY_REQUEST_SUCCEEDED,
    JOIN_COMMUNITY_REQUEST_FAILED,
    CLEAR_COMMUNITY_ERRORS,
    UNJOIN_COMMUNITY_REQUESTED,
    UNJOIN_COMMUNITY_REQUEST_SUCCEEDED,
    UNJOIN_COMMUNITY_REQUEST_FAILED,
    COMMUNITY_INVITE_LINK_REQUESTED,
    COMMUNITY_INVITE_LINK_REQUEST_SUCCEEDED,
    COMMUNITY_INVITE_LINK_REQUEST_FAILED,
    CREATE_COMMUNITY_REQUESTED,
    CREATE_COMMUNITY_REQUEST_SUCCEEDED,
    CREATE_COMMUNITY_REQUEST_FAILED,
    COMMUNITIES_REQUEST_FAILED,
    COMMUNITIES_REQUEST_SUCCEEDED,
    COMMUNITIES_REQUESTED,
    COMMUNITY_SEARCH_REQUESTED,
    COMMUNITY_SEARCH_REQUEST_SUCCEEDED,
    COMMUNITY_SEARCH_REQUEST_FAILED,
    COMMUNITY_SHARE_IMAGE_REQUESTED,
    COMMUNITY_SHARE_IMAGE_REQUEST_SUCCEEDED,
    COMMUNITY_SHARE_IMAGE_REQUEST_FAILED,
    UPLOAD_COMMUNITY_FILE_REQUESTED,
    UPLOAD_COMMUNITY_FILE_REQUEST_SUCCEEDED,
    UPLOAD_COMMUNITY_FILE_REQUEST_FAILED,
} from './actions';

const initialState = {
    loading: false,
    errors: [],
    community: null,
    update: {
        loading: false,
        errors: [],
        result: null,
    },
    join: {
        loading: false,
        errors: [],
        response: null,
    },
    unjoin: {
        loading: false,
        errors: [],
        response: null,
    },
    create: {
        loading: false,
        errors: [],
        response: null,
    },
    communities: {
        loading: false,
        errors: [],
        communities: [],
    },
    search: {
        loading: false,
        errors: [],
        response: null,
    },
    inviteLink: {
        loading: false,
        errors: [],
    },
    shareImage: {
        loading: false,
        errors: [],
        url: '',
    },
    bulkJoin: {
        loading: false,
        errors: [],
        response: null,
    },
    url: '',
};

const communityReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case COMMUNITY_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case COMMUNITY_REQUEST_SUCCEEDED:
            return {
                ...state,
                loading: false,
                errors: [],
                community: payload.community,
            };
        case COMMUNITY_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                errors: payload.errors,
            };
        case COMMUNITY_UPDATE_REQUESTED:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: true,
                },
            };
        case COMMUNITY_UPDATE_REQUEST_SUCCEEDED:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    errors: [],
                    result: payload.response,
                },
            };
        case COMMUNITY_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case JOIN_COMMUNITY_REQUESTED: {
            return {
                ...state,
                join: { ...state.join, loading: true, errors: [] },
            };
        }
        case JOIN_COMMUNITY_REQUEST_SUCCEEDED: {
            return {
                ...state,
                join: {
                    ...state.join,
                    loading: false,
                    response: payload.response,
                },
            };
        }
        case JOIN_COMMUNITY_REQUEST_FAILED: {
            return {
                ...state,
                join: { ...state.join, loading: false, errors: payload.errors },
            };
        }
        case CLEAR_COMMUNITY_ERRORS: {
            return {
                ...state,
                errors: [],
                join: { ...state.join, errors: [] },
                unjoin: { ...state.unjoin, errors: [] },
                create: { ...state.create, errors: [] },
                communities: { ...state.communities, errors: [] },
                shareImage: { ...state.shareImage, errors: [] },
                inviteLink: { ...state.shareImage, errors: [] },
                bulkJoin: { ...state.bulkJoin, errors: [], response: null },
            };
        }
        case UNJOIN_COMMUNITY_REQUESTED: {
            return {
                ...state,
                unjoin: { ...state.unjoin, loading: true, errors: [] },
            };
        }
        case UNJOIN_COMMUNITY_REQUEST_SUCCEEDED: {
            return {
                ...state,
                unjoin: {
                    ...state.unjoin,
                    loading: false,
                    response: payload.response,
                },
            };
        }
        case UNJOIN_COMMUNITY_REQUEST_FAILED: {
            return {
                ...state,
                unjoin: {
                    ...state.unjoin,
                    loading: false,
                    errors: payload.errors,
                },
            };
        }
        case COMMUNITY_INVITE_LINK_REQUESTED:
            return {
                ...state,
                inviteLink: {
                    loading: true,
                },
            };
        case COMMUNITY_INVITE_LINK_REQUEST_SUCCEEDED:
            return {
                ...state,
                inviteLink: {
                    ...state.inviteLink,
                    loading: false,
                },
                url: payload.url,
            };
        case COMMUNITY_INVITE_LINK_REQUEST_FAILED:
            return {
                ...state,
                inviteLink: {
                    ...state.inviteLink,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case CREATE_COMMUNITY_REQUESTED:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                },
            };
        case CREATE_COMMUNITY_REQUEST_SUCCEEDED:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    response: payload.response,
                },
            };
        case CREATE_COMMUNITY_REQUEST_FAILED:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case COMMUNITIES_REQUESTED:
            return {
                ...state,
                communities: {
                    ...state.communities,
                    loading: true,
                },
            };
        case COMMUNITIES_REQUEST_SUCCEEDED:
            return {
                ...state,
                communities: {
                    ...state.communities,
                    loading: false,
                    communities: payload.communities,
                },
            };
        case COMMUNITIES_REQUEST_FAILED:
            return {
                ...state,
                communities: {
                    ...state.communities,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case COMMUNITY_SEARCH_REQUESTED:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: true,
                },
            };
        case COMMUNITY_SEARCH_REQUEST_SUCCEEDED:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                    response: payload.communities,
                },
            };
        case COMMUNITY_SEARCH_REQUEST_FAILED:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                    errors: payload.errors,
                },
            };
        case COMMUNITY_SHARE_IMAGE_REQUESTED:
            return {
                ...state,
                shareImage: {
                    ...state.shareImage,
                    loading: true,
                },
            };
        case COMMUNITY_SHARE_IMAGE_REQUEST_SUCCEEDED:
            return {
                ...state,
                shareImage: {
                    ...state.shareImage,
                    loading: false,
                    url: payload.url,
                },
            };
        case COMMUNITY_SHARE_IMAGE_REQUEST_FAILED:
            return {
                ...state,
                shareImage: {
                    ...state.shareImage,
                    loading: false,
                    errors: payload.errors,
                },
            };
        case UPLOAD_COMMUNITY_FILE_REQUESTED:
            return {
                ...state,
                bulkJoin: {
                    ...state.bulkJoin,
                    loading: true,
                },
            };
        case UPLOAD_COMMUNITY_FILE_REQUEST_SUCCEEDED:
            return {
                ...state,
                bulkJoin: {
                    ...state.bulkJoin,
                    loading: false,
                    response: payload.response,
                },
            };
        case UPLOAD_COMMUNITY_FILE_REQUEST_FAILED:
            return {
                ...state,
                bulkJoin: {
                    ...state.bulkJoin,
                    loading: false,
                    errors: payload.errors,
                },
            };
        default:
            return state;
    }
};

export default communityReducer;
