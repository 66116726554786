import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import {
    AddCircleRounded,
    PlusOneRounded,
    StarsRounded,
    SupervisorAccountRounded,
    VibrationRounded,
} from '@material-ui/icons';

import styles from './styles';

import {
    getChallengeActivityTotals,
    getChallengeImpactItems,
} from '../../../challenges/selectors';
import StatCard from '../../../common/StatCard';
import ActivityTotals from '../../../common/ActivityTotals';
import ImpactToolTip from '../../../common/ImpactTooltip';

const ChallengeImpact = ({ classes, impactItems, activityTotals, t }) => (
    <Grid container direction="column">
        {impactItems?.length > 0 && (
            <Grid>
                <Grid
                    container
                    item
                    direction="row"
                    className={classes.impactTotalsRow}
                >
                    <Grid item className={classes.impactTotalsLabel}>
                        <strong>{t('impact.totals')}</strong>
                    </Grid>
                    <ImpactToolTip />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    xs={12}
                    md="auto"
                    className={classes.statsCards}
                    justify="space-evenly"
                >
                    {impactItems.map(({ id, ...props }) => (
                        <Grid
                            key={id}
                            item
                            className={classes.statsCard}
                            xs={12}
                            md
                        >
                            <StatCard {...props} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        )}
        <Grid item>
            <ActivityTotals
                activityItems={[
                    {
                        label: 'Points',
                        value: activityTotals.points,
                        icon: <PlusOneRounded fontSize="large" />,
                    },
                    {
                        label: 'Logs',
                        value: activityTotals.logs,
                        icon: <VibrationRounded fontSize="large" />,
                    },
                    {
                        label: 'Actions',
                        value: activityTotals.actions,
                        icon: <AddCircleRounded fontSize="large" />,
                    },
                    {
                        label: 'Badges',
                        value: activityTotals.badges,
                        icon: <StarsRounded fontSize="large" />,
                    },
                    {
                        label: 'Members',
                        value: activityTotals.members,
                        icon: <SupervisorAccountRounded fontSize="large" />,
                    },
                ]}
            />
        </Grid>
    </Grid>
);

ChallengeImpact.propTypes = {
    classes: PropTypes.object.isRequired,
    impactItems: PropTypes.array.isRequired,
    activityTotals: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const StyledChallengeImpact = withStyles(styles)(ChallengeImpact);

const mapStateToProps = (state) => ({
    impactItems: getChallengeImpactItems(state),
    activityTotals: getChallengeActivityTotals(state),
});

export default connect(mapStateToProps)(StyledChallengeImpact);
