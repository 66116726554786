export default (theme) => ({
    container: {
        padding: `29px ${theme.custom.mainContentHorizontalPadding}`,
        maxWidth: theme.custom.mainContentWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    bannerTitle: {
        marginBottom: 10,
        marginTop: 15,
        fontSize: 30,
        fontWeight: 'bold',
    },
    bannerBody: {
        margin: '0 0 10px',
        fontSize: 24,
    },
    infoIcon: {
        marginLeft: '6px',
        fontSize: '28px',
        color: 'rgba(51,51,51,.25)',
        cursor: 'pointer',

        '&:hover': {
            color: 'rgba(51,51,51,.5)',
        },
    },
    typeSelector: {
        paddingLeft: '10px',
        color: theme.custom.grey60,
    },
    menuItem: {
        color: theme.custom.grey60,
    },
    cardContainer: {
        paddingTop: '20px',
    },
    emptyTextContainer: {
        textAlign: 'center',
    },
    emptyText: {
        fontWeight: 600,
        color: theme.custom.textLightSecondaryColor,
    },
});
