import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from './styles';
import IMAGES from '../../../constants/images';

const DATE_FORMAT = 'MM/DD/YYYY';

const InformationSection = ({
    classes,
    customerName,
    customerAddress,
    card,
    nextBillingDate,
}) => {
    const { t } = useTranslation();

    return (
        <Grid container direction="row">
            <Grid
                className={classes.customerInfoContainer}
                item
                container
                direction="column"
                xs={5}
            >
                <Grid item>
                    <Typography variant="body1">
                        {t('account.receipt.billedTo')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography data-test="customer-name" variant="body2">
                        {customerName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography data-test="customer-address" variant="body2">
                        {customerAddress}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Grid item>
                    <Typography variant="body1">
                        {t('account.receipt.paymentInfo')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography data-test="card" variant="body2">
                        {card}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        {t('account.receipt.nextBillingDate', {
                            date: moment(nextBillingDate).format(DATE_FORMAT),
                        })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.logoContainer} item xs={2}>
                <img
                    className={classes.logo}
                    src={IMAGES.greenLogo}
                    alt={t('Joulebug')}
                />
            </Grid>
        </Grid>
    );
};

InformationSection.propTypes = {
    classes: PropTypes.object.isRequired,
    customerName: PropTypes.string.isRequired,
    customerAddress: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    nextBillingDate: PropTypes.string.isRequired,
};

export default withStyles(styles)(InformationSection);
