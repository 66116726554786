import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Menu, MenuItem, withStyles } from '@material-ui/core';
import { Person, ArrowDropDown } from '@material-ui/icons';
import styles from './styles';

const UserDropdown = ({ menuItems, title, classes }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <button
                className={clsx([
                    classes.button,
                    Boolean(anchorEl) && classes.activeButton,
                ])}
                onClick={handleClick}
            >
                <Person /> <span className={classes.title}>{title}</span>
                <ArrowDropDown fontSize="small" />
            </button>
            <Menu
                id="menu-dropdown"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{
                    paper: classes.paper,
                }}
            >
                {menuItems.map((menuItem) => (
                    <MenuItem key={menuItem.label} className={classes.menuItem}>
                        <Link
                            className={classes.link}
                            onClick={() => {
                                menuItem.onClick && menuItem.onClick();
                                setAnchorEl(null);
                            }}
                            to={menuItem.url}
                        >
                            {menuItem.label}
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
};

UserDropdown.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
    title: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};

UserDropdown.defaultProps = {
    menuItems: [],
};

export default withStyles(styles)(UserDropdown);
