import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { getRedirect, isAuthenticated } from './auth/selectors';
import ForgotPasswordScreen from './screens/SignInScreens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/SignInScreens/ResetPasswordScreen';
import MainScreen from './screens/MainScreen';
import HomeScreen from './screens/HomeScreen';

import ViewActionScreen from './screens/ViewActionScreen';
import UpdateActionScreen from './screens/UpdateActionScreen';
import ActionsScreen from './screens/ActionsScreen';
import ChallengePenaltiesScreen from './screens/ChallengePenaltiesScreen';
import ChallengeTopPhotosScreen from './screens/ChallengeTopPhotosScreen';
import ViewChallengeScreen from './screens/ViewChallengeScreen';
import UpdateChallengeScreen from './screens/UpdateChallengeScreen';
import ChallengeAnalyticsScreen from './screens/ChallengeAnalyticsScreen';
import CreateChallengeScreen from './screens/CreateChallengeScreen';
import ChallengesScreen from './screens/ChallengesScreen';

import AnalyticsPhotosScreen from './screens/AnalyticsPhotosScreen';
import ProfileCreateScreen from './screens/SignInScreens/ProfileCreateScreen';
import UserCreateScreen from './screens/SignInScreens/UserCreateScreen';
import AnalyticsScreen from './screens/AnalyticsScreen';
import NotificationsScreen from './screens/NotificationsScreen';
import UpdateNotificationScreen from './screens/UpdateNotificationScreen';
import UpdateProfileScreen from './screens/UpdateProfileScreen';
import ProfileScreen from './screens/ProfileScreen';
import UpdateCommunityProfileScreen from './screens/UpdateCommunityProfileScreen';
import CommunityProfileScreen from './screens/CommunityProfileScreen';
import FlaggedContentScreen from './screens/FlaggedContentScreen';
import SettingsScreen from './screens/SettingsScreen';
import TeamDetailsScreen from './screens/TeamDetailsScreen';
import UsersScreen from './screens/UsersScreen';
import EditThemesScreen from './screens/EditThemesScreen';
import OnBoardingScreen from './screens/OnBoardingScreen';
import OnboardingPasswordScreen from './screens/OnboardingPasswordScreen';
import OnBoardingSubscriptionScreen from './screens/OnBoardingSubscriptionScreen';
import OnboardingChallengeScreen from './screens/OnboardingChallengeScreen';
import TopActionsScreen from './screens/TopActionsScreen';
import { getAppImages, getAppName, getProperties } from './utils';
import PaymentScreen from './screens/PaymentPlanScreen';
import PaymentSuccessScreen from './screens/PaymentSuccessScreen';

import AccountScreen from './screens/AccountScreen';
import { useTranslation } from 'react-i18next';
import ActivitiesScreen from './screens/ActivitiesScreen';
import ViewActivityScreen from './screens/ViewActivityScreen';
import { queueDeepLink } from './deepLinks/actions';
import { getUTMParams, trackEvent } from './utils/analytics';
import { PARAM_CONTENT_ID, PARAM_PLATFORM } from './constants/analyticsParams';
import { EVENT_DYNAMIC_LINK_OPEN } from './constants/analyticsEvents';
import { getActiveCommunity, getUserWithIntercomRole } from './user/selectors';
import {
    setupIntercomForLeads,
    setupIntercomForUsers,
} from './config/intercom';
import { Helmet } from 'react-helmet';
import { UTM_CAMPAIGN_DYNAMIC_LINKS } from './constants/UTMParams';
import SignInScreen from './screens/SignInScreens/SignInScreen';
import { shouldShowSubscription } from './subscriptions/utils';
import CommunitiesScreen from './screens/CommunitiesScreen';
import WordpressScreen from './screens/WordpressScreen';
import OAuthScreen from './screens/OAuthScreen';
import { LOGIN_METHOD_GOOGLE } from './properties/constants';
import OAuthOrganizationCreateScreen from './screens/SignInScreens/OAuthOrganizationCreateScreen';

function Router({
    history,
    isAuth,
    user,
    activeCommunity,
    queueDeepLink,
    unauthedRedirect,
}) {
    const { t } = useTranslation();
    const appName = getAppName();
    const metaDescription = t('meta.content', {
        appName,
    });
    // Override the meta/title properties for the site as this is dynamic based on client
    document.title = appName;
    document.getElementById('favicon').href = getAppImages().favicon;
    const darkModeFavicon = getAppImages().faviconDarkMode;
    if (
        window.matchMedia('(prefers-color-scheme: dark)').matches &&
        darkModeFavicon
    ) {
        document.getElementById('favicon').href = darkModeFavicon;
    }
    document.getElementById('metaDescription').content = metaDescription;

    const appImage = getAppImages()?.metaImage;
    const appImageHorizontal = getAppImages()?.metaImageHorizontal;
    let gSignIn = [];
    if (
        getProperties()?.authCredentials?.some(
            (config) => config.authType === LOGIN_METHOD_GOOGLE,
        )
    ) {
        gSignIn = [
            <script
                key="gSignin"
                src="https://accounts.google.com/gsi/client"
                async
                defer
            ></script>,
        ];
    }

    const OgMeta = (
        <Helmet>
            <meta itemProp="name" content={appName} />
            <meta itemProp="description" content={metaDescription} />
            <meta itemProp="image" content={appImage} />
            {gSignIn}

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={appName} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={appImageHorizontal} />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={appName} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={appImageHorizontal} />
        </Helmet>
    );

    useEffect(() => {
        if (isAuth) {
            setupIntercomForUsers(user, activeCommunity);
        } else {
            setupIntercomForLeads();
        }
    }, [isAuth, user, activeCommunity]);

    const path = window.location.pathname;
    const utmParams = getUTMParams();
    if (utmParams.campaign) {
        if (utmParams.campaign === UTM_CAMPAIGN_DYNAMIC_LINKS) {
            trackEvent(EVENT_DYNAMIC_LINK_OPEN, {
                ...utmParams,
                [PARAM_CONTENT_ID]: path.split('/')?.[1],
                [PARAM_PLATFORM]: 'web',
            });
        }
    }

    const unauthedRoutes = [
        <Route
            exact
            key="/forgot-password"
            path="/forgot-password"
            component={ForgotPasswordScreen}
        />,
        <Route
            exact
            key="/forgot-password/reset"
            path="/forgot-password/reset"
            component={ResetPasswordScreen}
        />,
        <Route
            key="/signup/create-account"
            path="/signup/create-account"
            exact
            component={UserCreateScreen}
        />,
        <Route
            key="/signup/create-profile"
            path="/signup/create-profile"
            exact
            component={ProfileCreateScreen}
        />,
        <Route
            key="/oauth"
            path="/login/callback"
            exact
            component={OAuthScreen}
        />,
    ];

    useEffect(() => {
        // We redirect to a download page if a user is trying to signup/register with a link containing an access code (oac) as this functionality is currently not available to end users on the web. It should be removed once the user view is ready for full roll out and web/native onboarding are in parity.
        const deepLinkParams = ['oac'];
        const params = new URLSearchParams(window.location.search);
        if (!isAuth && deepLinkParams.some((param) => params.has(param))) {
            history.push('/download');
        }
    }, [isAuth, history]);

    const getRedirect = useCallback(() => {
        let redirect =
            unauthedRedirect || getProperties().shouldShowSignUp
                ? '/get-started'
                : '/login';
        const path = window.location.pathname;
        if (!isAuth) {
            const deepLinkPaths = ['challenges/', 'activities/', 'actions/'];
            if (
                deepLinkPaths.some((partialPath) => path.includes(partialPath))
            ) {
                redirect = '/download';
            }
        }
        return redirect;
    }, [isAuth, unauthedRedirect]);

    const PrivateRoute = useMemo(
        () => ({ children, ...rest }) => {
            return (
                <Route
                    {...rest}
                    render={() =>
                        isAuth ? (
                            children
                        ) : (
                            <Redirect
                                to={{
                                    pathname: getRedirect(),
                                    search: window.location.search,
                                }}
                            />
                        )
                    }
                />
            );
        },
        [isAuth, getRedirect],
    );

    const PrivateMainRoute = useMemo(
        () => ({ children, ...rest }) => {
            return (
                <PrivateRoute {...rest}>
                    <MainScreen>{children}</MainScreen>
                </PrivateRoute>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [PrivateRoute],
    );

    return (
        <ConnectedRouter history={history}>
            {OgMeta}
            <Switch>
                <Route
                    key="/wp"
                    path={[
                        '/legal',
                        '/privacy',
                        '/terms-of-use',
                        '/terms-of-service',
                        '/download',
                        '/about',
                        '/impact',
                        '/about',
                        '/wp/:wpPath',
                    ]}
                    component={WordpressScreen}
                />
                ,
                <Route path="/login" exact component={SignInScreen} />
                {getProperties().shouldShowSignUp
                    ? [
                          ...unauthedRoutes,
                          <Route
                              key="/get-started"
                              path="/get-started"
                              exact
                              component={OnBoardingScreen}
                          />,
                          <Route
                              key="/get-started/profile"
                              path="/get-started/profile"
                              exact
                              component={OnboardingPasswordScreen}
                          />,
                          <Route
                              key="/get-started/oauth"
                              path={['/get-started/oauth']}
                              exact
                              component={OAuthOrganizationCreateScreen}
                          />,
                          <PrivateRoute
                              key="/get-started/payment"
                              path="/get-started/payment"
                              exact
                          >
                              <OnBoardingSubscriptionScreen />
                          </PrivateRoute>,
                          <PrivateRoute
                              key="/get-started/challenge"
                              path="/get-started/challenge"
                              exact
                          >
                              <OnboardingChallengeScreen />
                          </PrivateRoute>,
                      ]
                    : unauthedRoutes}
                <PrivateMainRoute path="/flags">
                    <FlaggedContentScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/settings">
                    <SettingsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/communities/:communityId/edit">
                    <UpdateCommunityProfileScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/communities/:communityId">
                    <CommunityProfileScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/communities">
                    <CommunitiesScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/profile/edit">
                    <UpdateProfileScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/profile/:userId">
                    <ProfileScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/analytics/photos">
                    <AnalyticsPhotosScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/analytics/top-actions">
                    <TopActionsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/analytics">
                    <AnalyticsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/actions/:actionId/edit">
                    <UpdateActionScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/actions/active">
                    <ActionsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/actions/deactivated">
                    <ActionsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/actions/:actionId">
                    <ViewActionScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/actions">
                    <ActionsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges/:challengeId/team/:teamId">
                    <TeamDetailsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges/:challengeId/analytics/photos">
                    <ChallengeTopPhotosScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges/:challengeId/analytics">
                    <ChallengeAnalyticsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges/:challengeId/penalties">
                    <ChallengePenaltiesScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges/:challengeId/edit">
                    <UpdateChallengeScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges/create">
                    <CreateChallengeScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/themes/edit">
                    <EditThemesScreen />
                </PrivateMainRoute>
                <PrivateMainRoute
                    path={[
                        '/challenges/active',
                        '/challenges/drafts',
                        '/challenges/upcoming',
                        '/challenges/past',
                        '/challenges/deactivated',
                    ]}
                >
                    <ChallengesScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges/:challengeId">
                    <ViewChallengeScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/challenges">
                    <ChallengesScreen />
                </PrivateMainRoute>
                <PrivateMainRoute
                    path={[
                        '/notifications/create',
                        '/notifications/edit/:notificationId',
                    ]}
                >
                    <UpdateNotificationScreen />
                </PrivateMainRoute>
                <PrivateMainRoute
                    path={[
                        '/notifications/queued',
                        '/notifications/sent',
                        '/notifications',
                    ]}
                >
                    <NotificationsScreen />
                </PrivateMainRoute>
                <PrivateMainRoute
                    path={['/members/active', '/members/deactivated']}
                >
                    <UsersScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path={['/members']}>
                    <UsersScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/activities/:activityId">
                    <ViewActivityScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/activities">
                    <ActivitiesScreen />
                </PrivateMainRoute>
                <PrivateMainRoute path="/members">
                    <UsersScreen />
                </PrivateMainRoute>
                {shouldShowSubscription() && [
                    <PrivateMainRoute key="/account" path="/account">
                        <AccountScreen />
                    </PrivateMainRoute>,
                    <PrivateMainRoute
                        key="/payment/success"
                        path="/payment/success"
                    >
                        <PaymentSuccessScreen />
                    </PrivateMainRoute>,
                    <PrivateMainRoute key="/payment" path="/payment">
                        <PaymentScreen />
                    </PrivateMainRoute>,
                ]}
                <PrivateMainRoute path="/">
                    <HomeScreen />
                </PrivateMainRoute>
            </Switch>
        </ConnectedRouter>
    );
}

Router.propTypes = {
    history: PropTypes.object.isRequired,
    isAuth: PropTypes.bool.isRequired,
    queueDeepLink: PropTypes.func.isRequired,
    unauthedRedirect: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    activeCommunity: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    isAuth: isAuthenticated(state),
    user: getUserWithIntercomRole(state),
    activeCommunity: getActiveCommunity(state),
    unauthedRedirect: getRedirect(state),
});

const mapDispatchToProps = (dispatch) => ({
    queueDeepLink: (deepLinkPath) => dispatch(queueDeepLink(deepLinkPath)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
