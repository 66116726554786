import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import HeaderPage from '../../common/HeaderPage';
import { getActiveCommunityId } from '../../user/selectors';
import ContentToggleButton from '../../common/ContentToggleButton';
import {
    areThemesLoading,
    getThemes,
    getUpdateThemesErrors,
    isThemeUpdateLoading,
} from '../../communityThemes/selectors';
import Button from '../../common/Button';
import RequestLoader from '../../common/RequestLoader';
import {
    themesRequested,
    themesUpdateRequested,
} from '../../communityThemes/actions';
import TableManager from '../ActionsScreen/AdminActionsScreen/TableManager';
import { getActions } from '../../actions/selectors';
import { actionsRequested } from '../../actions/actions';
import ErrorModal from '../../common/ErrorModal';
import CommonPropTypes from '../../common/propTypes';
import { clearAllErrors } from '../../notifications/actions';
import { baseColumns } from '../ActionsScreen/AdminActionsScreen/constants';
import {
    CLIENT_PAGINATION_FETCH_LIMIT,
    PIN_ADMIN_VISIBILITY_ALL_INCLUDED,
    PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
} from '../../actions/constants';
import { actionTablePropType } from '../../actions/propTypes';

export const EditThemesScreen = ({
    classes,
    communityId,
    themes,
    themesUpdateRequested,
    includedActions,
    excludedActions,
    getThemes,
    areThemesLoading,
    isThemeUpdateLoading,
    getActionsForThemes,
    updateErrors,
    clearErrors,
}) => {
    const [isErrorShown, setErrorShown] = useState(false);

    useEffect(() => {
        getThemes(communityId);
    }, [getThemes, communityId]);

    useEffect(() => {
        if (updateErrors != null) {
            setErrorShown(true);
        }
    }, [updateErrors]);

    const handleModal = useCallback(() => {
        clearErrors();
        setErrorShown(false);
    }, [clearErrors]);

    const { t } = useTranslation();
    const [selectedTheme, setSelectedTheme] = useState({});

    useEffect(() => {
        if (selectedTheme) {
            getActionsForThemes(communityId, [selectedTheme?.id]);
        }
    }, [communityId, getActionsForThemes, selectedTheme]);

    return (
        <Grid container justify="center" className={classes.container}>
            <HeaderPage title={t('appContent.themes.choose')} />
            <Grid
                container
                direction="row"
                spacing={6}
                className={classes.selectedThemes}
            >
                {themes.map((theme, index) => (
                    <Grid item key={index} lg={3} xs={6}>
                        <ContentToggleButton
                            selected={theme.id === selectedTheme?.id}
                            name={theme.name}
                            iconUrl={theme.whiteIcon}
                            description={theme.explainerText}
                            selectable={false}
                            onClick={() => {
                                setSelectedTheme(theme);
                            }}
                        />
                    </Grid>
                ))}
                <RequestLoader
                    isLoading={isThemeUpdateLoading}
                    title={t('appContent.themes.saving')}
                    classes={{}}
                />
                <RequestLoader
                    isLoading={areThemesLoading}
                    title={t('appContent.themes.loading')}
                    classes={{}}
                />
            </Grid>
            <Grid
                container
                direction="column"
                className={classes.descriptionsContainer}
            >
                <Typography align="center">
                    {selectedTheme?.description}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Button
                    color="accent"
                    fullWidth
                    size="large"
                    disabled={!selectedTheme?.id}
                    onClick={() =>
                        themesUpdateRequested([selectedTheme?.id], communityId)
                    }
                    loading={areThemesLoading}
                >
                    {t('appContent.themes.save')}
                </Button>
            </Grid>
            <Grid container justify="center">
                <Box position="relative" className={classes.actionsTable}>
                    {includedActions && selectedTheme?.id && (
                        <TableManager
                            title={t('active')}
                            type={PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME}
                            data={includedActions}
                            initialSortBy={[{ id: 'name', desc: false }]}
                            columns={baseColumns}
                            shouldShowMultiSelect={false}
                            clientPagination
                        />
                    )}
                </Box>
                {isErrorShown && (
                    <ErrorModal
                        error={{
                            message: updateErrors.join(';'),
                        }}
                        isOpen={isErrorShown}
                        onClose={handleModal}
                        onCancel={handleModal}
                    />
                )}
            </Grid>
        </Grid>
    );
};

EditThemesScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    includedActions: actionTablePropType.isRequired,
    excludedActions: actionTablePropType.isRequired,
    themesUpdateRequested: PropTypes.func.isRequired,
    getActionsForThemes: PropTypes.func.isRequired,
    getThemes: PropTypes.func.isRequired,
    communityId: PropTypes.string.isRequired,
    themes: PropTypes.array,
    areThemesLoading: PropTypes.bool,
    isThemeUpdateLoading: PropTypes.bool,
    updateErrors: CommonPropTypes.errors,
    clearErrors: PropTypes.func.isRequired,
};

EditThemesScreen.defaultProps = {
    themes: [],
    areThemesLoading: false,
    isThemeUpdateLoading: false,
    isIncludedActionLoading: false,
    updateErrors: null,
};

export const StyledEditThemesScreen = withStyles(styles)(EditThemesScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    themes: getThemes(state),
    areThemesLoading: areThemesLoading(state),
    isThemeUpdateLoading: isThemeUpdateLoading(state),
    includedActions: getActions(state, PIN_ADMIN_VISIBILITY_ALL_INCLUDED),
    updateErrors: getUpdateThemesErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    getActionsForThemes: (communityId, themeIds) => {
        dispatch(
            actionsRequested(communityId, {
                themeIds,
                limit: CLIENT_PAGINATION_FETCH_LIMIT,
                visibility: PIN_ADMIN_VISIBILITY_ALL_INCLUDED,
            }),
        );
    },
    themesUpdateRequested: (themeIds, communityId, history) =>
        dispatch(themesUpdateRequested(themeIds, communityId, '/actions')),
    getThemes: (communityId) => dispatch(themesRequested(communityId)),
    clearErrors: () => dispatch(clearAllErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledEditThemesScreen);
