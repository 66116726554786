import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, Switch, Typography } from '@material-ui/core';
import useStyles from './styles';
import HeaderPage from '../../../common/HeaderPage';
import Footer from '../../../common/Footer';
import { SUPPORTED_LANGUAGES } from '../../../localization/constants';
import {
    getActiveCommunity,
    getAvailableCommunities,
} from '../../../user/selectors';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    clearResponse,
    requestIntegrations,
    requestOIDCIntegrationRemove,
    requestSlackIntegration,
    requestSlackIntegrationRemove,
    setActionDuplicationCommunities,
} from '../actions';
import {
    getActionDuplicationCommunities,
    getErrors,
    getIntegrations,
    getResponse,
    settingsLoading,
} from '../selectors';
import Select, { SIZE_LARGE } from '../../../common/Select';
import {
    activeCommunityPropType,
    availableCommunitiesPropType,
} from '../../../user/propTypes';
import { Can } from '../../../casl/ability-context';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';
import { getSlackIntegrationLink } from '../../../utils/integrations';
import { getApplicationConfig } from '../../../utils';
import { LOGIN_METHOD_GOOGLE } from '../../../properties/constants';
import { SSOAuthRequested } from '../../../auth/actions';
import { RETURN_LOCATION_SETTINGS } from '../../../auth/constants';
import { GoogleIntegration } from './GoogleIntegration';

const AdminSettingsScreen = ({
    communities,
    activeCommunity,
    actionDuplicationCommunities,
    setActionDuplicationCommunities,
    requestSlackIntegration,
    requestSlackIntegrationRemove,
    errors,
    response,
    clearResponse,
    requestIntegrations,
    integrations,
    requestOIDCIntegration,
    requestOIDCIntegrationRemove,
    loading,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [slackIntegrated, setSlackIntegrated] = useState(
        !!integrations.slack?.postChannel,
    );

    useEffect(() => {
        setSlackIntegrated(!!integrations?.slack?.postChannel);
    }, [integrations.slack]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        if (code) {
            // Assume that the oauth flow will work, maybe add a loading icon instead later
            setSlackIntegrated(true);
            requestSlackIntegration(code);
        }
    }, [requestSlackIntegration]);

    useEffect(() => {
        requestIntegrations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const languageOptions = useMemo(
        () =>
            i18n.languages.map((langCode) => ({
                value: langCode,
                label: SUPPORTED_LANGUAGES[langCode],
            })),
        [i18n],
    );

    const handleLanguageChange = useCallback(
        (event) => {
            i18n.changeLanguage(event.target.value);
        },
        [i18n],
    );

    const communityOptions = useMemo(
        () =>
            communities
                .filter((community) =>
                    community.actions.includes('editActions'),
                )
                .map((community) => ({
                    value: community.content.id,
                    label: community.content.name,
                })),
        [communities],
    );

    const selectedCommunityIds = useMemo(
        () =>
            actionDuplicationCommunities.map(
                (community) => community.content.id,
            ),
        [actionDuplicationCommunities],
    );

    const handleSelectedCommunitiesChange = (event) => {
        const selected = event.target.value.reduce(
            (selectedCommunities, communityId) => {
                if (communityId !== activeCommunity.id) {
                    const selectedCommunity = communities.find(
                        (community) => community.content.id === communityId,
                    );
                    selectedCommunities.push(selectedCommunity);
                }
                return selectedCommunities;
            },
            [],
        );
        setActionDuplicationCommunities(selected);
    };

    const renderMenuItemDecorators = useCallback(
        (option) => (
            <Checkbox
                className={classes.checkbox}
                checked={
                    option.value === activeCommunity.id ||
                    selectedCommunityIds.includes(option.value)
                }
            />
        ),
        [selectedCommunityIds, activeCommunity.id, classes.checkbox],
    );

    const slackAuthorizationURL = getSlackIntegrationLink();

    const googleAuth = getApplicationConfig()?.authCredentials?.find(
        (creds) => creds.authType === LOGIN_METHOD_GOOGLE,
    );

    return (
        <Grid container direction="column" className={classes.container}>
            <HeaderPage title={t('Settings')} />
            <Typography variant="h2" className={classes.translations}>
                {t('settings.translations.descriptor')}
            </Typography>
            <span className={classes.subtitle}>
                {t('settings.translations.selectLanguage')}
            </span>
            <Grid item>
                <Select
                    type={SIZE_LARGE}
                    options={languageOptions}
                    value={i18n.language}
                    onChange={handleLanguageChange}
                />
            </Grid>
            <Can I="edit" a="Actions">
                {communities.length > 1 && (
                    <>
                        <Typography
                            variant="h2"
                            className={classes.duplication}
                        >
                            {t('settings.duplication.descriptor')}
                        </Typography>
                        <span className={classes.subtitle}>
                            {t('settings.duplication.selectOrgs')}
                        </span>
                        <Grid item>
                            <Select
                                multiple
                                options={communityOptions}
                                value={[
                                    activeCommunity.id,
                                    ...selectedCommunityIds,
                                ]}
                                onChange={handleSelectedCommunitiesChange}
                                renderMenuItemDecorators={
                                    renderMenuItemDecorators
                                }
                                renderValue={(selected) =>
                                    communityOptions
                                        .filter((option) =>
                                            selected.includes(option.value),
                                        )
                                        ?.map((option) => option.label)
                                        ?.join(', ')
                                }
                            />
                        </Grid>
                    </>
                )}
            </Can>
            {googleAuth && (
                <GoogleIntegration
                    integrations={integrations}
                    requestOIDCIntegration={requestOIDCIntegration}
                    requestOIDCIntegrationRemove={requestOIDCIntegrationRemove}
                    googleAuth={googleAuth}
                    loading={loading}
                />
            )}
            {slackAuthorizationURL && (
                <Can I="add" a="SlackWebhook">
                    <Typography variant="h2" className={classes.duplication}>
                        {t('settings.integrations.descriptor')}
                    </Typography>
                    <Grid container alignItems="center">
                        <Grid container item xs={6}>
                            <Grid item>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={classes.slackIcon}
                                    viewBox="0 0 122.8 122.8"
                                >
                                    <path
                                        d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                                        fill="#e01e5a"
                                    />
                                    <path
                                        d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                                        fill="#36c5f0"
                                    />
                                    <path
                                        d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                                        fill="#2eb67d"
                                    />
                                    <path
                                        d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                                        fill="#ecb22e"
                                    />
                                </svg>
                            </Grid>
                            <Grid item>
                                <Typography style={{ fontWeight: '700' }}>
                                    {t(
                                        'settings.integrations.slack.descriptor',
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={slackIntegrated}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        window.open(
                                            getSlackIntegrationLink(),
                                            '_self',
                                        );
                                    } else {
                                        requestSlackIntegrationRemove();
                                        // assume that the removal will succeed, don't wait for a response
                                        setSlackIntegrated(false);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Can>
            )}
            <SnackBarAPIResponse
                errors={errors}
                messages={response}
                clearResponse={clearResponse}
            />
            <Grid item>
                <Footer className={classes.footer} />
            </Grid>
        </Grid>
    );
};

AdminSettingsScreen.propTypes = {
    communities: availableCommunitiesPropType.isRequired,
    activeCommunity: activeCommunityPropType.isRequired,
    actionDuplicationCommunities: PropTypes.array.isRequired,
    setActionDuplicationCommunities: PropTypes.func.isRequired,
    requestSlackIntegration: PropTypes.func.isRequired,
    requestSlackIntegrationRemove: PropTypes.func.isRequired,
    requestIntegrations: PropTypes.func.isRequired,
    clearResponse: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    response: PropTypes.arrayOf(PropTypes.string).isRequired,
    integrations: PropTypes.shape({
        slack: PropTypes.shape({
            postChannel: PropTypes.string,
            teamName: PropTypes.string,
        }),
        oidc: PropTypes.arrayOf(PropTypes.object).isRequired,
        domains: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    requestOIDCIntegration: PropTypes.func.isRequired,
    requestOIDCIntegrationRemove: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

AdminSettingsScreen.defaultProps = {
    loading: false,
};

const mapStateToProps = (state) => ({
    communities: getAvailableCommunities(state),
    activeCommunity: getActiveCommunity(state),
    actionDuplicationCommunities: getActionDuplicationCommunities(state),
    response: getResponse(state),
    errors: getErrors(state),
    integrations: getIntegrations(state),
    loading: settingsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    setActionDuplicationCommunities: (communities) =>
        dispatch(setActionDuplicationCommunities(communities)),
    requestSlackIntegration: (code) => dispatch(requestSlackIntegration(code)),
    requestSlackIntegrationRemove: () =>
        dispatch(requestSlackIntegrationRemove()),
    requestIntegrations: () => dispatch(requestIntegrations()),
    clearResponse: () => dispatch(clearResponse()),
    requestOIDCIntegration: (config) =>
        dispatch(SSOAuthRequested(config, RETURN_LOCATION_SETTINGS)),
    requestOIDCIntegrationRemove: (authType) =>
        dispatch(requestOIDCIntegrationRemove(authType)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminSettingsScreen);
