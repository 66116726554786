import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: ({ isFullWidth }) => ({
        maxWidth: isFullWidth ? 'none' : 800,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 8,
    }),
    content: {
        display: 'flex',
        flexDirection: 'row',
        margin: 30,
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    cardAction: {
        justifyContent: 'flex-end',
        padding: 0,
    },
    cardActionContainer: {
        width: '100%',
    },
    button: {
        padding: '6px 12px',
        border: '1px solid #fff',
        borderRadius: 6,
        textTransform: 'capitalize',
        color: theme.custom.white,
        whiteSpace: 'nowrap',
    },
    helpBtn: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        borderRadius: 6,
        '&:link': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
        color: '#fff',
    },
    helpIcon: {
        marginRight: 2,
    },
}));
