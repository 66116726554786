import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        height: '100%',
        width: '100%',
        minHeight: 144,
        backgroundColor: theme.custom.grey93,
        alignItems: 'center',
    },
    headerRoot: {
        padding: '10px 15px',
        alignItems: 'center',
        backgroundColor: theme.custom.grey95,
    },
    headerTitle: ({ colorTitle, capitalize }) => ({
        fontSize: 16,
        fontWeight: 600,
        color: colorTitle,
        marginRight: 4,
        textTransform: capitalize ? 'capitalize' : 'none',
    }),
    headerContent: {
        flex: 0,
        whiteSpace: 'nowrap',
    },
    headerAction: {
        margin: 0,
    },
    iconButton: {
        width: 14,
        height: 14,
        color: theme.custom.grey60,
    },
    contentRoot: ({ colorContent }) => ({
        color: colorContent,
        padding: 15,
        '&:first-child': {
            padding: 15,
        },
    }),
    value: {
        fontSize: 80,
        lineHeight: '64px',
        fontWeight: 600,
        whiteSpace: 'nowrap',
    },
    weight: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '40px',
    },
}));

export default useStyles;
