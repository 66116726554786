import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import ShareChallenge from '../ShareChallenge';
import IMAGES from '../../../constants/images';
import ChallengeIcon from '../../ChallengeIcon';
import QuickActions from '../QuickActions';
import { noop } from '../../../utils';
import { ButtonsCellPropType } from '../propTypes';
import PersonIcon from '@material-ui/icons/Person';
import ChallengeStats from '../ChallengeStats';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { createChallengeEndString, createChallengeStartString } from './utils';

const circleIconStyle = {
    border: `2px solid ${theme.palette.white.main}`,
    borderRadius: '50%',
    backgroundColor: theme.palette.white.main,
    width: '96px',
    height: '96px',
    minWidth: '96px',
};

const ActiveCard = ({
    sharingOptions,
    name,
    startDate,
    endDate,
    id,
    classes,
    onButtonsCell,
    buttonsCell,
    memberCount,
    imageUrl,
    iconUrl,
    onSocialLinkClicked,
    isLoading,
}) => {
    const { t } = useTranslation();

    const now = new Date();

    return (
        <Grid>
            <div
                className={classes.activeChallengeCover}
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imageUrl ||
                        IMAGES.defaultActiveChallengeBg})`,
                }}
            >
                {isLoading && (
                    <div className={classes.loadingIndicator}>
                        {t('activeChallenges.loading')}
                    </div>
                )}
                {!isLoading && (
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        className={classes.activeChallengeCoverHeader}
                    >
                        <div className={classes.activeChallengeCoverBodyHeader}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={classes.activeChallengeCoverHeader}
                            >
                                {sharingOptions?.length > 0 && (
                                    <div>
                                        <ShareChallenge
                                            onSocialLinkClicked={(option) => {
                                                onSocialLinkClicked(option);
                                            }}
                                            sharingOptions={sharingOptions}
                                            url={`http://joulebug.com/share/links/${id}`}
                                        />
                                    </div>
                                )}
                            </Grid>
                        </div>
                        <div className={classes.activeChallengeCoverBodyRow}>
                            <div>
                                <div
                                    className={
                                        classes.activeChallengeCoverBodyRowMiddle
                                    }
                                    data-test="challengeIcon"
                                >
                                    <ChallengeIcon
                                        canEdit={false}
                                        iconStyle={circleIconStyle}
                                        iconUrl={
                                            iconUrl || IMAGES.impactEcoIcon
                                        }
                                        onChangeIcon={() =>
                                            alert(
                                                'This should not have happened',
                                            )
                                        }
                                    />
                                    <div className={classes.challengeMeta}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="flex-start"
                                            alignItems="stretch"
                                        >
                                            <div
                                                className={
                                                    classes.challengeTitleWrapper
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.challengeTitle
                                                    }
                                                    variant="h3"
                                                    data-test="challengeText"
                                                >
                                                    {name}
                                                </Typography>
                                            </div>
                                            {startDate && endDate && (
                                                <div
                                                    className={
                                                        classes.challengeDates
                                                    }
                                                    data-test="challengeDate"
                                                >
                                                    {now.getTime() >
                                                    startDate.getTime()
                                                        ? createChallengeEndString(
                                                              endDate,
                                                              t,
                                                          )
                                                        : createChallengeStartString(
                                                              startDate,
                                                              t,
                                                          )}
                                                </div>
                                            )}
                                        </Grid>
                                    </div>
                                    <div
                                        className={classes.challengePeopleMeta}
                                    >
                                        <ChallengeStats
                                            color={theme.palette.white.main}
                                            icon={PersonIcon}
                                            placeholder={t(
                                                'activeChallenges.members',
                                                { count: memberCount },
                                            )}
                                            stat={memberCount}
                                            flexJustify="flex-end"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                classes.activeChallengeCoverBodyRowFooter
                            }
                        >
                            <QuickActions
                                id={id}
                                buttonsCell={buttonsCell}
                                onButtonsCell={onButtonsCell}
                            />
                        </div>
                    </Grid>
                )}
            </div>
        </Grid>
    );
};

export const SharingOptions = PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

ActiveCard.propTypes = {
    sharingOptions: PropTypes.arrayOf(SharingOptions).isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    memberCount: PropTypes.number,
    classes: PropTypes.object.isRequired,
    onButtonsCell: PropTypes.func,
    buttonsCell: ButtonsCellPropType,
    onSocialLinkClicked: PropTypes.func,
    isLoading: PropTypes.bool,
    iconUrl: PropTypes.string.isRequired,
};

ActiveCard.defaultProps = {
    onButtonsCell: noop,
    onSocialLinkClicked: noop,
    buttonsCell: null,
    memberCount: 0,
    isLoading: false,
};

export default withStyles(styles)(ActiveCard);
