import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    label: {
        marginBottom: '5px',
        fontWeight: 700,
        display: 'flex',
    },
    fieldLabel: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 14,
    },
}));

export default useStyles;
