import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    inputRoot: (props) => ({
        color: '#999',
        padding: '0 !important',
        backgroundColor: props.bgColor,

        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primary.dark}`,
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primary.dark}`,
        },

        '& input:focus': {
            backgroundColor: 'white',
        },
    }),
    input: {
        padding: '6px 35px 6px 12px !important',
        height: '24px',
    },
    option: {
        padding: '3px 10px',
    },
    popper: {
        minWidth: '200px',
    },
    communityIndent: {
        borderLeft: `1px solid ${theme.custom.grey88}`,
        borderBottom: `1px solid ${theme.custom.grey88}`,
        marginLeft: 9.5,
        marginRight: 5,
        width: 10,
        height: 12,
        alignSelf: 'flex-start',
    },
    groupCard: {
        cursor: 'pointer',
        paddingLeft: 10,
        '&:hover': {
            backgroundColor: '#f5f6f7',
        },
    },
}));

export default useStyles;
