import React from 'react';
import useStyles from './styles';
import Tooltip from '../Tooltip';
import { getImpactLink } from '../../utils';
import { Help } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const ImpactToolTip = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Tooltip
            placement="right"
            title={
                <span className={classes.tooltip}>{t('impact.tooltip')}</span>
            }
        >
            <a target="_blank" rel="noreferrer noopener" href={getImpactLink()}>
                <Help
                    data-intercom-target="impactTooltip"
                    className={classes.icon}
                />
            </a>
        </Tooltip>
    );
};
export default ImpactToolTip;
