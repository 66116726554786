import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../assets/icomoon/selection.json';

const IcomoonIcon = ({ color, size, icon, className }) => {
    return (
        <IcomoonReact
            className={className}
            iconSet={iconSet}
            color={color}
            size={size}
            icon={icon}
        />
    );
};

IcomoonIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IcomoonIcon.defaultProps = {
    className: '',
    color: '',
    size: '100%',
};

export default IcomoonIcon;
