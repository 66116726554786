import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Switch, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import pluralize from 'pluralize';
import moment from 'moment';
import clsx from 'clsx';
import parse from 'html-react-parser';
import i18n from 'i18next';

import styles from './styles';

import { getTeamItems } from '../../../challenges/selectors';
import { Can } from '../../../casl/ability-context';
import { getAppImages, noop } from '../../../utils';
import Leaderboard from '../../../common/Leaderboard';
import IcomoonIcon from '../../../common/IcomoonIcon';
import { CHALLENGE_UNIT_USER } from '../../../challenges/constants';
import {
    EcoActionsIcon,
    NutritionIcon,
    RemoteIcon2,
} from '../../../assets/images/svg';
import {
    CIVIC_ACTIONS,
    DATE_FORMAT,
    DEFAULT_CONTENT_FILTER_TEXT_KEY,
    DIVERSITY_ACTIONS,
    ECO_ACTIONS,
    FITNESS_ACTIONS,
    NUTRITION_ACTIONS,
    REMOTE_ACTIONS,
    TIME_FORMAT,
} from './constants';
import ChallengeActions from '../ChallengeActions';
import PrizeTable from './PrizeTable';
import Tooltip from '../../../common/Tooltip';
import InfoTooltip from '../../../common/InfoTooltip';
import { challengeInfoPropType } from './propTypes';
import { INTERCOM_TARGET_PREFIX } from '../../UpdateChallengeScreen/constants';

const ChallengeInfo = ({
    classes,
    info: {
        description,
        rulesType,
        unit,
        contentFilterText,
        howToWin,
        rules,
        prizes,
        actions,
        autoJoin,
        makeChallengeCategory,
    },
    teamItems,
    duration,
    startDate,
    endDate,
    action,
    t,
    onEditDescription,
    onEditDates,
    onEditHowToWin,
    onEditRules,
    onEditPrizes,
    onDeletePrizes,
    onEditTeam,
    onToggleAutoJoin,
    onToggleChallengeCategory,
    onEditActions,
    onRemoveAction,
    fetchChallengeActionData,
}) => {
    const edit = (can) => !!can && action === 'edit';
    const handleTeamEditIconClick = useCallback(
        (event, id) => {
            onEditTeam(id);
        },
        [onEditTeam],
    );

    const ChallengeTypeIcon = () => {
        switch (contentFilterText) {
            case ECO_ACTIONS:
                return (
                    <EcoActionsIcon
                        className={classes.icon}
                        alt={t('challenge.info.contentFilter')}
                    />
                );
            case FITNESS_ACTIONS:
                return (
                    <IcomoonIcon
                        icon="jb-fitness"
                        className={classes.icon}
                        alt={t('challenge.info.contentFilter')}
                    />
                );
            case NUTRITION_ACTIONS:
                return (
                    <NutritionIcon
                        className={classes.icon}
                        alt={t('challenge.info.contentFilter')}
                    />
                );
            case DIVERSITY_ACTIONS:
                return (
                    <IcomoonIcon
                        icon="jb-diversity"
                        className={classes.icon}
                        alt={t('challenge.info.contentFilter')}
                    />
                );
            case REMOTE_ACTIONS:
                return (
                    <RemoteIcon2
                        className={classes.icon}
                        alt={t('challenge.info.contentFilter')}
                    />
                );
            case CIVIC_ACTIONS:
                return (
                    <IcomoonIcon
                        icon="jb-civic"
                        className={classes.icon}
                        alt={t('challenge.info.contentFilter')}
                    />
                );
            default:
                return (
                    <IcomoonIcon
                        icon="jb-wellness"
                        className={classes.icon}
                        alt={t('challenge.info.contentFilter')}
                    />
                );
        }
    };
    return (
        <Grid container direction="column">
            <Can passThrough I={action} a="ChallengeDescription">
                {(can) => (
                    <Grid
                        item
                        className={clsx([
                            classes.description,
                            edit(can) && classes.editable,
                        ])}
                        onClick={
                            edit(can)
                                ? () => onEditDescription(description)
                                : noop
                        }
                        data-intercom-target="Challenge Info Edit Description"
                    >
                        {description && (
                            <Typography
                                style={{ fontSize: '24px', whiteSpace: 'pre-line' }}
                            >
                                {parse(description)}
                            </Typography>
                        )}
                        {edit(can) && (
                            <Edit
                                fontSize="large"
                                className={classes.editIcon}
                            />
                        )}
                    </Grid>
                )}
            </Can>
            <Grid container item direction="row" wrap="nowrap" justify="center">
                <Grid container item alignItems="center" direction="column">
                    <Grid item>
                        {rulesType === 'Competitive' ? (
                            <IcomoonIcon
                                icon="jb-trophy"
                                className={classes.icon}
                                alt={t('challenge.info.rulesType')}
                            />
                        ) : (
                            <IcomoonIcon
                                icon="jb-goals"
                                className={classes.icon}
                                alt={t('challenge.info.rulesType')}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.iconLabel}>
                        {rulesType}
                    </Grid>
                </Grid>
                <Grid container item alignItems="center" direction="column">
                    <Grid item>
                        {unit === CHALLENGE_UNIT_USER ? (
                            <IcomoonIcon
                                icon="jb-profile"
                                className={classes.icon}
                                alt={t('challenge.info.unit')}
                            />
                        ) : (
                            <IcomoonIcon
                                icon="jb-community"
                                className={classes.icon}
                                alt={t('challenge.info.unit')}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.iconLabel}>
                        {unit === 'User' ? 'Individuals' : unit}
                    </Grid>
                </Grid>
                <Grid container item alignItems="center" direction="column">
                    <Grid item>
                        <IcomoonIcon
                            icon="jb-calendar"
                            className={classes.icon}
                            alt={t('challenge.info.duration')}
                        />
                    </Grid>
                    <Grid item className={classes.iconLabel}>
                        {pluralize(t('day'), duration, true)}
                    </Grid>
                </Grid>

                <Grid container item alignItems="center" direction="column">
                    <Grid item>
                        <ChallengeTypeIcon />
                    </Grid>
                    <Grid item className={classes.iconLabel}>
                        {contentFilterText ||
                            t(DEFAULT_CONTENT_FILTER_TEXT_KEY)}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="row"
                wrap="nowrap"
                justify="space-around"
                className={classes.datesRow}
            >
                <Can passThrough I={action} a="ChallengeDuration">
                    {(can) => (
                        <Grid
                            container
                            item
                            direction="column"
                            alignItems="center"
                            className={clsx(
                                edit(can) &&
                                    classes.editable &&
                                    classes.dateContainer,
                            )}
                            onClick={
                                edit(can)
                                    ? () => onEditDates(startDate, endDate)
                                    : noop
                            }
                            data-intercom-target="Challenge Info Edit Start Date"
                        >
                            <Grid item className={classes.startEndLabel}>
                                {t('Start')}
                                {edit(can) && (
                                    <Edit className={classes.editIcon} />
                                )}
                            </Grid>
                            <Grid item className={classes.date}>
                                <strong>
                                    {moment(startDate).format(DATE_FORMAT)}
                                </strong>
                            </Grid>
                            <Grid item className={classes.time}>
                                {moment(startDate).format(TIME_FORMAT)}
                            </Grid>
                        </Grid>
                    )}
                </Can>
                <Can passThrough I={action} a="ChallengeDuration">
                    {(can) => (
                        <Grid
                            container
                            item
                            direction="column"
                            alignItems="center"
                            className={clsx(
                                edit(can) &&
                                    classes.editable &&
                                    classes.dateContainer,
                            )}
                            onClick={
                                edit(can)
                                    ? () => onEditDates(startDate, endDate)
                                    : noop
                            }
                            data-intercom-target="Challenge Info Edit End Date"
                        >
                            <Grid item className={classes.startEndLabel}>
                                {t('End')}
                                {edit(can) && (
                                    <Edit className={classes.editIcon} />
                                )}
                            </Grid>
                            <Grid item className={classes.date}>
                                <strong>
                                    {moment(endDate).format(DATE_FORMAT)}
                                </strong>
                            </Grid>
                            <Grid item className={classes.time}>
                                {moment(endDate).format(TIME_FORMAT)}
                            </Grid>
                        </Grid>
                    )}
                </Can>
            </Grid>
            {unit === 'Team' ? (
                <Grid container item>
                    <Grid item className={classes.teamsLabel}>
                        {t('challenge.teams')}
                    </Grid>
                    <Grid container item>
                        <Can passThrough I={action} a="ChallengeTeams">
                            {(can) => (
                                <Leaderboard
                                    leaderboardCardProps={{
                                        hideRanks: true,
                                        imageIsRounded: true,
                                        Icon: edit(can) ? Edit : null,
                                        onIconClick: handleTeamEditIconClick,
                                        defaultImageSrc: getAppImages()
                                            .defaultProfileIcon,
                                    }}
                                    items={teamItems?.toSorted((a, b) => a.label.localeCompare(b.label, i18n.language, { numeric: true }) || [])}
                                />
                            )}
                        </Can>
                    </Grid>
                </Grid>
            ) : null}
            {action === 'edit' && (
                <>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.toggleContainer}
                        data-intercom-target="autoJoinToggle"
                    >
                        <Grid item xs={12} className={classes.rulesLabel}>
                            {t('challenge.info.autoJoin.header')}
                            <InfoTooltip
                                text={t('challenge.info.autoJoin.tooltip')}
                                intercomTarget={`${INTERCOM_TARGET_PREFIX}AutoJoinLabel`}
                            />
                        </Grid>
                        <Tooltip
                            title={
                                unit === 'Team'
                                    ? t(
                                          'challenge.info.autoJoin.tooltipDisabled',
                                      )
                                    : ''
                            }
                            placement="left"
                        >
                            <Switch
                                onChange={(e) =>
                                    onToggleAutoJoin(e.target.checked)
                                }
                                disabled={unit === 'Team'}
                                checked={autoJoin}
                                size="medium"
                            />
                        </Tooltip>
                        <Typography className={classes.toggleText}>
                            {t('challenge.info.autoJoin.description')}
                        </Typography>
                        <Grid
                            item
                            xs={12}
                            className={classes.rulesLabel}
                            data-intercom-target="makeChallengeCategoryToggle"
                        >
                            {t('challenge.info.makeChallengeCategory.header')}
                            <InfoTooltip
                                text={t(
                                    'challenge.info.makeChallengeCategory.description',
                                )}
                                intercomTarget={`${INTERCOM_TARGET_PREFIX}MakeChallengeCategoryLabel`}
                            />
                        </Grid>
                        <Switch
                            onChange={(e) =>
                                onToggleChallengeCategory(e.target.checked)
                            }
                            checked={makeChallengeCategory}
                            size="medium"
                        />
                        <Typography className={classes.toggleText}>
                            {t(
                                'challenge.info.makeChallengeCategory.description',
                            )}
                        </Typography>
                    </Grid>
                </>
            )}
            <Can passThrough I={action} a="ChallengePrizes">
                {(can) => (
                    <PrizeTable
                        classes={classes}
                        onEditPrizes={onEditPrizes}
                        edit={edit}
                        prizes={prizes}
                        t={t}
                        can={can}
                        onDeletePrizes={onDeletePrizes}
                    />
                )}
            </Can>
            <ChallengeActions
                isEdit={action === 'edit'}
                fetchTableData={fetchChallengeActionData}
                data={actions}
                onEditActions={onEditActions}
                onRemoveAction={onRemoveAction}
            />
            <Can passThrough I={action} a="ChallengeHowToWin">
                {(can) => (
                    <Grid
                        container
                        item
                        direction="column"
                        className={clsx(edit(can) && classes.editable)}
                        onClick={
                            edit(can) ? () => onEditHowToWin(howToWin) : noop
                        }
                        data-intercom-target="Challenge Info Edit How To Win"
                    >
                        <Grid item className={classes.howToWinLabel}>
                            {t('challenge.info.howToWin')}
                            {edit(can) && (
                                <Edit
                                    fontSize="large"
                                    className={classes.editIcon}
                                />
                            )}
                        </Grid>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                            {parse(howToWin || '')}
                        </Typography>
                    </Grid>
                )}
            </Can>
            {rules && rules.length ? (
                <Can passThrough I={action} a="ChallengeRules">
                    {(can) => (
                        <Grid
                            container
                            item
                            direction="column"
                            className={clsx(edit(can) && classes.editable)}
                            onClick={
                                edit(can) ? () => onEditRules(rules) : noop
                            }
                            data-intercom-target="Challenge Info Edit Rules"
                        >
                            <Grid item className={classes.rulesLabel}>
                                {t('challenge.info.rules')}
                                {edit(can) && (
                                    <Edit
                                        fontSize="large"
                                        className={classes.editIcon}
                                    />
                                )}
                            </Grid>
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                                {parse(rules)}
                            </Typography>
                        </Grid>
                    )}
                </Can>
            ) : null}
        </Grid>
    );
};

ChallengeInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    info: challengeInfoPropType.isRequired,
    teamItems: PropTypes.array,
    duration: PropTypes.number.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    onEditActions: PropTypes.func,
    onRemoveAction: PropTypes.func,
    onEditDescription: PropTypes.func,
    onEditDates: PropTypes.func,
    onEditHowToWin: PropTypes.func,
    onEditRules: PropTypes.func,
    onEditTeam: PropTypes.func,
    onEditPrizes: PropTypes.func,
    onDeletePrizes: PropTypes.func,
    onToggleAutoJoin: PropTypes.func,
    onToggleChallengeCategory: PropTypes.func,
    fetchChallengeActionData: PropTypes.func,
    action: PropTypes.string,
    t: PropTypes.func.isRequired,
};

ChallengeInfo.defaultProps = {
    teamItems: null,
    action: 'view',
    onEditActions: noop,
    onRemoveAction: noop,
    onEditDescription: noop,
    onEditDates: noop,
    onEditHowToWin: noop,
    onEditRules: noop,
    onEditTeam: noop,
    onEditPrizes: noop,
    onDeletePrizes: noop,
    onToggleAutoJoin: noop,
    onToggleChallengeCategory: noop,
    fetchChallengeActionData: noop,
};

export const StyledChallengeInfo = withStyles(styles)(ChallengeInfo);

const mapStateToProps = (state) => ({
    teamItems: getTeamItems(state),
});

export default connect(mapStateToProps)(StyledChallengeInfo);
