import {
    ACTIVATE_ACTION,
    BTN_ACTIVATE_CHALLENGE,
    BTN_DEACTIVATE_CHALLENGE,
    BTN_DELETE_CHALLENGE,
    BTN_DOWNLOAD_CHALLENGE_REPORT,
    BTN_DUPLICATE_CHALLENGE,
    BTN_EDIT_CHALLENGE,
    BTN_PUBLISH_CHALLENGE,
    BTN_VIEW_ANALYTICS,
    BTN_VIEW_CHALLENGE,
    BTN_VIEW_PENALTIES,
    DEACTIVATE_ACTION,
    DELETE_CHALLENGE_ACTION,
    GENERATE_REPORT_ACTION,
} from '../screens/ChallengesScreen/constants';

export const handleReport = (
    id,
    challengeName,
    challengeIcon,
    setModalDetails,
    t,
) => {
    setModalDetails({
        isOpen: true,
        title: t('report.generateTitle'),
        confirmBtnText: t('report.generateTitle'),
        isActivate: true,
        action: GENERATE_REPORT_ACTION,
        items: [
            {
                name: challengeName,
                iconUrl: challengeIcon,
                identifier: id,
            },
        ],
        headerKey: 'report.areYouSureChallenge',
        description: t('report.description'),
    });
};

export const showActivationModal = (
    id,
    isActivate,
    challengeName,
    challengeIcon,
    setModalDetails,
    t,
) => {
    setModalDetails({
        isOpen: true,
        isActivate,
        action: isActivate ? ACTIVATE_ACTION : DEACTIVATE_ACTION,
        items: [
            {
                name: challengeName,
                iconUrl: challengeIcon,
                identifier: id,
            },
        ],
        description: isActivate
            ? t('challenge.activate.description')
            : t('challenge.deactivate.description'),
    });
};

const handlePublish = (
    id,
    challengeName,
    challengeIcon,
    setModalDetails,
    t,
) => {
    setModalDetails({
        isOpen: true,
        isActivate: true,
        action: ACTIVATE_ACTION,
        items: [
            {
                name: challengeName,
                iconUrl: challengeIcon,
                identifier: id,
            },
        ],
        description: t('challenge.publish.description'),
    });
};

export const handleButtonsCell = (
    id,
    key,
    challengeName,
    challengeIcon,
    history,
    setModalDetails,
    t,
) => {
    switch (key) {
        case BTN_VIEW_CHALLENGE.key:
            history.push(`/challenges/${id}`);
            break;
        case BTN_EDIT_CHALLENGE.key:
            history.push(`/challenges/${id}/edit`);
            break;
        case BTN_DEACTIVATE_CHALLENGE.key:
            showActivationModal(
                id,
                false,
                challengeName,
                challengeIcon,
                setModalDetails,
                t,
            );
            break;
        case BTN_ACTIVATE_CHALLENGE.key:
            showActivationModal(
                id,
                true,
                challengeName,
                challengeIcon,
                setModalDetails,
                t,
            );
            break;
        case BTN_VIEW_ANALYTICS.key:
            history.push(`/challenges/${id}/analytics`);
            break;
        case BTN_VIEW_PENALTIES.key:
            history.push(`/challenges/${id}/penalties`);
            break;
        case BTN_PUBLISH_CHALLENGE.key:
            handlePublish(id, challengeName, challengeIcon, setModalDetails, t);
            break;
        case BTN_DELETE_CHALLENGE.key:
            setModalDetails({
                isOpen: true,
                title: t('delete'),
                confirmBtnText: t('delete'),
                isActivate: false,
                action: DELETE_CHALLENGE_ACTION,
                items: [
                    {
                        name: challengeName,
                        iconUrl: challengeIcon,
                        identifier: id,
                    },
                ],
                headerKey: 'challenge.delete.description',
                description: t('cannotBeUndone'),
            });
            break;
        case BTN_DOWNLOAD_CHALLENGE_REPORT.key:
            handleReport(id, challengeName, challengeIcon, setModalDetails, t);
            break;
        case BTN_DUPLICATE_CHALLENGE.key:
            history.push({
                pathname: '/challenges/create',
                search: `?challengeId=${id}`,
            });
            break;
        default:
            break;
    }
};
