const styles = (theme) => ({
    container: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '3px',
    },
    content: {
        padding: '25px 30px',
        background: theme.palette.white.main,
    },
    accountType: {
        fontSize: '36px',
        paddingRight: '15px',
    },
    trialTextContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    trialText: {
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    description: {
        fontSize: '18px',
        padding: '15px 0',
    },
    rate: {
        fontSize: '28px',
        color: theme.palette.primary.main,
    },
    subBillTextContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    subBillText: {
        fontSize: '12px',
        color: theme.palette.primary.main,
        paddingBottom: '3.5px',
        paddingLeft: '10px',
    },
    countText: {
        fontSize: '12px',
    },
    counterContainer: {
        padding: '25px 0',
    },
    nextPaymentContainer: {
        paddingTop: '10px',
        borderTop: `2px solid ${theme.custom.bgAccentColor}`,
    },
    nextPayText: {
        fontSize: '12px',
    },
    footerContainer: {
        padding: '0px 30px',
        background: theme.custom.bgLightAccentColor,
    },
});

export default styles;
