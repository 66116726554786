import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';

import {
    challengeClearErrors,
    challengeCreationRequested,
    viewChallengeRequested,
} from '../../challenges/actions';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getChallenge,
    getChallengeCreationErrors,
    getChallengeErrors,
    getCreatedChallenge,
    isChallengeCreationRequested,
    isChallengeLoading,
} from '../../challenges/selectors';
import CommonPropTypes from '../../common/propTypes';
import RequestLoader from '../../common/RequestLoader';
import CustomizeChallenge from './CustomizeChallenge';
import SelectChallengeTheme from './SelectChallengeTheme';
import { getActions } from '../../actions/selectors';
import { actionsRequested } from '../../actions/actions';
import styles from './styles';
import { noop } from '../../utils';
import {
    clearThemesErrors,
    themesRequested,
} from '../../communityThemes/actions';
import {
    areThemesLoading,
    getThemes,
    getThemesErrors,
} from '../../communityThemes/selectors';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import {
    CLIENT_PAGINATION_FETCH_LIMIT,
    PIN_ADMIN_VISIBILITY_ALL_INCLUDED,
} from '../../actions/constants';
import { actionTablePropType } from '../../actions/propTypes';

const CreateChallengeScreen = ({
    classes,
    communityId,
    requestChallengeCreation,
    isChallengeCreationRequested,
    challengeCreationErrors,
    createdChallenge,
    fetchThemes,
    areThemesLoading,
    themesErrors,
    themes,
    requestActions,
    actions,
    clearErrors,
    challengeToDuplicate,
    challengeToDuplicateLoading,
    challengeToDuplicateErrors,
    fetchChallenge,
}) => {
    const history = useHistory();

    const { i18n, t } = useTranslation();

    const language = useMemo(() => i18n.language, [i18n.language]);

    const challengeId = useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        return params.get('challengeId');
    }, []);

    const isDuplicating = challengeId !== null;

    const [requestSent, setRequestSent] = useState(false);
    const [creationStep, setCreationStep] = useState(isDuplicating ? 1 : 0);

    const [selectedTheme, setSelectedTheme] = useState();

    const handleChallengeCreation = useCallback(
        (creationData) => {
            requestChallengeCreation({
                communityId,
                language,
                ...creationData,
            });
            setRequestSent(true);
        },
        [communityId, language, requestChallengeCreation],
    );

    const handleThemeSelected = useCallback(
        (theme) => {
            if (theme?.id) {
                requestActions(communityId, theme?.id);
            }
            setSelectedTheme(theme);
        },
        [communityId, requestActions, setSelectedTheme],
    );

    useEffect(() => {
        if (challengeToDuplicate && themes) {
            setSelectedTheme(
                themes.find(
                    (theme) =>
                        theme.id ===
                        challengeToDuplicate.contentFilterThemes?.[0],
                ),
            );
        }
    }, [challengeToDuplicate, themes]);

    useEffect(() => {
        if (challengeId) {
            fetchChallenge(challengeId, communityId);
        }
    }, [fetchChallenge, challengeId, communityId]);

    useEffect(() => {
        fetchThemes(communityId);
    }, [fetchThemes, communityId]);

    useEffect(() => {
        if (
            requestSent &&
            !isChallengeCreationRequested &&
            challengeCreationErrors?.length < 1 &&
            createdChallenge != null
        ) {
            const { id } = createdChallenge;
            history.push(`/challenges/${id}/edit`);
        }
    }, [
        isChallengeCreationRequested,
        challengeCreationErrors,
        createdChallenge,
        history,
        requestSent,
    ]);

    let viewToDisplay;
    if (creationStep === 0) {
        viewToDisplay = (
            <SelectChallengeTheme
                selectedTemplate={selectedTheme}
                themes={themes}
                actions={actions}
                setSelectedTemplate={handleThemeSelected}
                onCustomizeClick={() => setCreationStep(1)}
                clearTemplateErrors={clearErrors}
            />
        );
    } else if (!challengeToDuplicateLoading) {
        viewToDisplay = (
            <CustomizeChallenge
                onConfirm={handleChallengeCreation}
                onCancel={() => {
                    if (challengeId) {
                        history.goBack();
                    } else {
                        setCreationStep(0);
                    }
                }}
                challengeToDuplicate={isDuplicating && challengeToDuplicate}
                selectedTheme={selectedTheme}
            />
        );
    }

    return (
        <Grid container justifyContent="center">
            <Grid container item className={classes.container}>
                {viewToDisplay}
            </Grid>
            <RequestLoader
                isLoading={areThemesLoading}
                title={t('challenge.create.loadingTemplates')}
            />
            <RequestLoader
                isLoading={isChallengeCreationRequested}
                title={t('challenge.create.creatingChallenge')}
            />
            <RequestLoader isLoading={challengeToDuplicateLoading} />
            <SnackBarAPIResponse
                errors={[
                    ...(themesErrors || []),
                    ...(challengeCreationErrors || []),
                    ...(challengeToDuplicateErrors || []),
                ]}
                clearResponse={clearErrors}
            />
        </Grid>
    );
};

CreateChallengeScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    requestChallengeCreation: PropTypes.func.isRequired,
    isChallengeCreationRequested: PropTypes.bool.isRequired,
    requestActions: PropTypes.func.isRequired,
    fetchThemes: PropTypes.func.isRequired,
    challengeCreationErrors: CommonPropTypes.errors,
    createdChallenge: PropTypes.object,
    areThemesLoading: PropTypes.bool,
    themesErrors: PropTypes.array,
    themes: PropTypes.array,
    actions: actionTablePropType,
    clearErrors: PropTypes.func,
    challengeToDuplicate: PropTypes.object,
    challengeToDuplicateLoading: PropTypes.bool,
    challengeToDuplicateErrors: CommonPropTypes.errors,
    fetchChallenge: PropTypes.func,
};

CreateChallengeScreen.defaultProps = {
    challengeCreationErrors: null,
    createdChallenge: null,
    areThemesLoading: false,
    themesErrors: null,
    themes: null,
    loadingActions: false,
    clearErrors: noop,
    actions: {},
    challengeToDuplicate: null,
    challengeToDuplicateLoading: false,
    challengeToDuplicateErrors: null,
    fetchChallenge: noop,
};

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isChallengeCreationRequested: isChallengeCreationRequested(state),
    challengeCreationErrors: getChallengeCreationErrors(state),
    createdChallenge: getCreatedChallenge(state),
    themes: getThemes(state),
    themesErrors: getThemesErrors(state),
    areThemesLoading: areThemesLoading(state),
    actions: getActions(state, PIN_ADMIN_VISIBILITY_ALL_INCLUDED),
    challengeToDuplicate: getChallenge(state),
    challengeToDuplicateLoading: isChallengeLoading(state),
    challengeToDuplicateErrors: getChallengeErrors(state),
});

export const StyledChallengeScreen = withStyles(styles)(CreateChallengeScreen);

const mapDispatchToProps = (dispatch) => ({
    requestChallengeCreation: (request) =>
        dispatch(challengeCreationRequested(request)),
    fetchThemes: (communityId) => dispatch(themesRequested(communityId)),
    requestActions: (communityId, themeId) =>
        dispatch(
            actionsRequested(communityId, {
                limit: CLIENT_PAGINATION_FETCH_LIMIT,
                themeIds: [themeId],
                visibility: PIN_ADMIN_VISIBILITY_ALL_INCLUDED,
            }),
        ),
    clearErrors: () => {
        dispatch(challengeClearErrors());
        dispatch(clearThemesErrors());
    },
    fetchChallenge: (challengeId, communityId) =>
        dispatch(viewChallengeRequested({ challengeId, communityId })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledChallengeScreen);
