const styles = (theme) => ({
    appBar: {
        backgroundColor: 'transparent',
        color: theme.custom.grey60,
        fontSize: '14px',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '3px',
            bottom: '0',
            left: '0',
            backgroundColor: '#e5e5e5',
            zIndex: '-1',
        },
        '& .MuiTabs-scrollButtonsDesktop': {
            display: 'flex',
        },
    },
    tab: {
        minWidth: '0px',
        padding: '10px 16px',
        '&:hover': {
            color: '#757575',
            borderBottomColor: '#757575',
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            borderBottomColor: theme.palette.primary.main,
        },
    },
});
export default styles;
