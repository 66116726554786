import React from 'react';
import moment from 'moment';
import {
    Person,
    SwapHoriz,
    IndeterminateCheckBox,
    AddBox,
    Delete,
    Visibility,
    Clear,
} from '@material-ui/icons';
import { getAppImages } from '../../utils';

const dateCell = ({ value }) => moment(value).format('M/D/YY h:mm A');

export const tableColumns = [
    {
        accessor: 'icon',
        Cell: ({ value }) => (
            <img
                src={value || getAppImages().defaultProfileIcon}
                width={42}
                height={42}
                style={{ borderRadius: '6px' }}
                alt="alt.profileIcon"
            />
        ),
    },
    {
        Header: 'usersPage.name',
        accessor: 'name',
        Cell: ({ value }) => <span>{value}</span>,
    },
    {
        Header: 'usersPage.userId',
        accessor: 'id',
        sortable: false,
    },
    {
        Header: 'email',
        accessor: 'email',
    },
    {
        Header: 'role.descriptor',
        accessor: 'role',
    },
    {
        Header: 'date.joined',
        accessor: 'dateJoined',
        Cell: dateCell,
    },
    {
        Header: 'lastSeen',
        accessor: 'lastSeen',
        Cell: dateCell,
    },
    { accessor: 'actions' },
];

export const activeButtonsCell = [
    {
        key: 'setUserRole',
        actions: 'set',
        subject: 'UserRole',
        tooltip: 'role.edit',
        icon: Person,
    },
    {
        key: 'viewUserProfile',
        actions: 'view',
        subject: 'UserProfile',
        tooltip: 'view',
        icon: Visibility,
    },
    {
        key: 'moveUserOrg',
        actions: 'move',
        subject: 'UserOrg',
        tooltip: 'organization.switch',
        icon: SwapHoriz,
    },
    {
        key: 'deactivateUser',
        actions: 'deactivate',
        subject: 'User',
        tooltip: 'deactivate',
        icon: IndeterminateCheckBox,
    },
    {
        key: 'deleteUser',
        actions: 'delete',
        subject: 'User',
        tooltip: 'delete',
        icon: Delete,
    },
];

export const deactivatedButtonsCell = [
    {
        key: 'reactivateUser',
        actions: 'reactivate',
        subject: 'User',
        tooltip: 'activate',
        icon: AddBox,
    },
];

export const communityButtonsCell = [
    {
        key: 'removeUserFromCommunity',
        actions: 'remove',
        subject: 'UserFromCommunity',
        tooltip: 'remove',
        icon: Clear,
    },
];
