const styles = (theme) => ({
    container: {
        background: `${theme.palette.primary.main} linear-gradient(rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.7)) 50%`,
        borderRadius: '5px',
    },
    header: {
        paddingTop: '100px',
        paddingBottom: '50px',
    },
    logoSection: {
        padding: '0 30px',
    },
    logoContainer: {
        background: '#fff',
        borderRadius: '50%',
        padding: '15px',
    },
    logo: {
        width: 'auto',
        height: '45px',
    },
    title: {
        fontWeight: 600,
        color: '#fff',
        padding: '5px 0',
    },
    body: {
        color: '#fff',
        paddingBottom: '35px',
    },
    createBtn: {
        padding: '10px 30px',
        textTransform: 'none',
    },
    textSection: {
        maxWidth: '350px',
    },
});

export default styles;
