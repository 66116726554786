const styles = (theme) => ({
    container: {
        height: '100%',
    },
    leftPane: {
        paddingLeft: theme.custom.mainContentHorizontalPadding,
        paddingRight: theme.custom.mainContentHorizontalPadding,
        height: '100%',
    },
    leftPaneTitle: {
        fontSize: '28px',
    },
    logoutBtnContainer: {
        paddingTop: '100px',
        paddingBottom: '20px',
    },
    logoutBtn: {
        width: '120px',
        height: '40px',
        borderRadius: '5px',
    },
    searchBtn: {
        borderRadius: '5px',
        height: '100%',
        width: '100%',
    },
    rightPane: {
        padding: '122px 16px 50px 80px',
        background: theme.custom.bgLightAccentColor,
    },
    aboutContainer: { height: 'min-content' },
    linkContainer: {
        paddingBottom: '100px',
    },
    searchContainer: {
        position: 'relative',
        paddingBottom: '20px',
    },
    link: {
        color: theme.custom.linkColor,
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: 700,
        cursor: 'pointer',
    },
    footer: {
        padding: '100px 0 16px 0',
        backgroundColor: theme.custom.bgColor,
    },
});

export default styles;
