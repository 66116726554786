import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';

import styles from './styles';
import ChallengeForm from './ChallengeForm';
import { noop } from '../../../common/utils';

const CustomizeChallenge = ({
    classes,
    onBack,
    onSubmit,
    formData,
    onFormDataChanged,
}) => {
    const { t } = useTranslation();
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            alignContent="center"
            className={classes.scrollOption}
        >
            <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                alignContent="flex-start"
            >
                <Grid item xs={10}>
                    <Typography
                        variant="h3"
                        className={classes.challengeTypeTitle}
                    >
                        {t(
                            'onBoarding.createChallenge.customizeChallengeHeader',
                        )}
                    </Typography>
                </Grid>
                <ChallengeForm
                    onSubmit={onSubmit}
                    onBack={onBack}
                    formData={formData}
                    onFormDataChanged={onFormDataChanged}
                />
            </Grid>
        </Grid>
    );
};

CustomizeChallenge.propTypes = {
    classes: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    onFormDataChanged: PropTypes.func.isRequired,
};

CustomizeChallenge.defaultProps = {
    onBack: noop,
    onSubmit: noop,
};

export default withStyles(styles)(CustomizeChallenge);
