import React, { useEffect } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HeaderPage from '../../../common/HeaderPage';
import {
    areUserNotificationsLoading,
    getUserNotifications,
} from '../../../notifications/selectors';
import {
    markUserNotificationsRequested,
    userNotificationsRequested,
} from '../../../notifications/actions';
import styles from './styles';
import InfiniteScroller from '../../../common/InfiniteScroller';
import { getRelativeTime } from '../../../common/utils';
import { getNotificationIcon } from './utils';
import { getAppImages } from '../../../utils';
import ContentLoader from 'react-content-loader';

const NOTIFICATION_FETCH_LIMIT = 25;

const UserNotificationsScreen = ({
    classes,
    loading,
    notifications,
    getNotifications,
    markNotifications,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        getNotifications({ limit: NOTIFICATION_FETCH_LIMIT, skip: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (notifications.length > 0) {
            markNotifications();
        }
    }, [notifications.length, markNotifications]);

    const renderNotification = (item) => (
        <Grid
            container
            direction="row"
            className={classes.notificationContainer}
            justify="space-between"
            alignItems="center"
        >
            <Grid xs={10} wrap="nowrap" container>
                <Grid
                    alignItems="center"
                    justify="center"
                    item
                    container
                    direction="row"
                    style={{ width: 'auto' }}
                >
                    <img
                        width={48}
                        height={48}
                        src={item.leftIcon || getAppImages().defaultAppIcon}
                        className={classes.leftIcon}
                        alt=""
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.subject}>
                        {item.title}
                    </Typography>
                    <Typography variant="body2">{item.message}</Typography>
                </Grid>
            </Grid>
            <Grid xs={2} container item direction="column" alignItems="center">
                {getNotificationIcon(item.rightIconName, {
                    size: 32,
                    className: classes.rightIcon,
                })}
                <Typography variant="body2" align="center">
                    {getRelativeTime(item.created)}
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <Grid container className={classes.container} justify="center">
            <HeaderPage title={t('notifications.descriptor')} />
            <InfiniteScroller
                fetchLimit={NOTIFICATION_FETCH_LIMIT}
                items={notifications}
                loadFunc={getNotifications}
                renderFunc={renderNotification}
                isLoading={loading}
                listEmptyText={t('notifications.table.empty')}
                Loader={
                    <ContentLoader className={classes.contentLoaderContainer}>
                        <rect
                            x="88"
                            y="20"
                            rx="3"
                            ry="3"
                            width="30%"
                            height="18"
                        />
                        <rect
                            x="88"
                            y="48"
                            rx="3"
                            ry="3"
                            width="50%"
                            height="10"
                        />
                        <circle cx="40" cy="42" r="25" />
                    </ContentLoader>
                }
            />
        </Grid>
    );
};

UserNotificationsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    getNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    markNotifications: PropTypes.func.isRequired,
};

const StyledUserNotificationsScreen = withStyles(styles)(
    UserNotificationsScreen,
);

const mapStateToProps = (state) => ({
    notifications: getUserNotifications(state),
    loading: areUserNotificationsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    getNotifications: (options) =>
        dispatch(userNotificationsRequested(options)),
    markNotifications: () => dispatch(markUserNotificationsRequested()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledUserNotificationsScreen);
