import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

import { Grid, Typography } from '@material-ui/core';

const ImpactConversion = ({
    leadingPhrase,
    value,
    trailingPhrase,
    backgroundImage,
}) => {
    const classes = useStyles({ backgroundImage });
    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.container}
            direction="column"
        >
            <Typography className={classes.phraseText}>
                {leadingPhrase}
            </Typography>
            <Typography className={classes.valueText}>{value}</Typography>
            <Typography className={classes.phraseText}>
                {trailingPhrase}
            </Typography>
        </Grid>
    );
};
ImpactConversion.propTypes = {
    leadingPhrase: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    trailingPhrase: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string.isRequired,
};

ImpactConversion.defaultProps = {};

export default ImpactConversion;
