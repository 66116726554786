import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    clearAuthErrors,
    clearEmailVerificationMessages,
    emailVerificationVerify,
    initialFormPopulate,
    initialFormSubmit,
    SSOAuthRequested,
} from '../../auth/actions';
import {
    getEmailVerificationCode,
    getEmailVerificationErrors,
    getEmailVerificationMessages,
    getEmailVerifiedStatus,
    getOnBoardingAccountDetails,
    getOnboardingEmail,
    getToken,
    isAuthLoading,
    isOrgValid,
} from '../../auth/selectors';
import { getUserId } from '../../user/selectors';
import { retrieveStripePlanId } from '../../subscriptions/actions';
import { getStripePlanId } from '../../subscriptions/selectors';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import OnboardingHeader from '../../common/OnboardingHeader';
import OnboardingFooter from '../../common/OnboardingFooter';
import OnBoarding from '../../common/OnBoarding';
import Loader from '../../common/OnBoarding/Loader';
import { noop } from '../../utils';
import { getUTMParams, trackEvent } from '../../utils/analytics';
import { EVENT_SUBSCRIPTION_ONBOARDING_BEGIN } from '../../constants/analyticsEvents';
import { PARAM_PLAN_ID } from '../../constants/analyticsParams';
import { updateIntercomUserData } from '../../config/intercom';
import { RETURN_LOCATION_ADMIN_SIGNUP } from '../../auth/constants';

const VERIFY_STATE = 'verify';
const ONBOARDING_STATE = 'onBoarding';

const OnboardingScreen = ({
    classes,
    isLoading,
    onEmailLogin,
    onMicrosoftLogin,
    onGoogleLogin,
    sendEmailVerificationCode,
    verificationErrors,
    clearErrors,
    pendingApproveEmail,
    accountDetails,
    userId,
    sendStripePlanId,
    stripePlanId,
    submitForm,
    populateForm,
    valid,
    verificationMessages,
    clearVerificationMessages,
    attemptedCode,
    onSSOClick,
}) => {
    const history = useHistory();

    const [verify, setVerify] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [unsubscribe, setUnsubscribe] = useState(true);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const planId = params.get('planId');

        if (planId) {
            const utmParams = getUTMParams();
            updateIntercomUserData(utmParams);
            trackEvent(EVENT_SUBSCRIPTION_ONBOARDING_BEGIN, {
                ...utmParams,
                [PARAM_PLAN_ID]: planId,
            });
            sendStripePlanId(planId);
        } else {
            sendStripePlanId('');
        }
    }, [sendStripePlanId]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const email = params.get('email')?.replace(' ', '+');
        const organization = params.get('organization');
        const website = params.get('website');
        const employeeCount = params.get('employeeCount');
        const username = params.get('username');

        if (
            code &&
            code !== attemptedCode &&
            email &&
            verificationErrors.length < 1 &&
            valid
        ) {
            setVerify(true);
            populateForm({
                email,
                username,
                organization,
                website,
                employeeCount,
            });
            sendEmailVerificationCode({
                email,
                verificationCode: code,
            });
        } else if (!valid) {
            setVerify(false);
        }
    }, [
        setVerify,
        sendEmailVerificationCode,
        populateForm,
        verificationErrors,
        valid,
        attemptedCode,
    ]);

    const handleFormSubmit = (values) => {
        submitForm({
            username: values.firstName.concat(' ', values.lastName),
            email: values.email,
            employeeCount: values.employeeCount,
            organization: values.orgName,
            website: values.website,
        });
    };

    const handleVerificationSubmit = (values) => {
        const data = {
            email: pendingApproveEmail,
            verificationCode: values.code,
        };
        sendEmailVerificationCode(data);
        updateIntercomUserData({ unsubscribe_from_emails: unsubscribe });
    };

    const handleVerificationResend = () => {
        submitForm({
            username: accountDetails.username,
            email: accountDetails.email,
            employeeCount: accountDetails.employeeCount,
            organization: accountDetails.organization,
            website: accountDetails.website,
            resendVerification: true,
        });
    };

    const toggleChecked = () => {
        setCheckbox(!checkbox);
    };

    const toggleSubscribed = () => {
        setUnsubscribe(!unsubscribe);
    };

    useEffect(() => {
        if (pendingApproveEmail && valid) {
            setVerify(true);
        } else if (!valid) {
            setVerify(false);
        }
    }, [pendingApproveEmail, verificationErrors, valid]);

    useEffect(() => {
        clearErrors();
    }, [clearErrors]);

    useEffect(() => {
        if (userId) {
            history.push('/get-started/challenge');
        }
    }, [userId, history]);

    useEffect(() => {
        if (accountDetails?.name) {
            history.push('/get-started/community');
        }
    }, [accountDetails, history]);

    const initialEmailFormValues = {
        email: '',
    };

    const initialVerificationFormValues = {
        code: '',
    };

    return (
        <Grid container direction="column" className={classes.container}>
            {isLoading && <Loader />}
            <Grid container item sm={12}>
                <OnboardingHeader />
            </Grid>
            <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                sm={12}
                className={
                    verify
                        ? classes.verifyContainer
                        : classes.onBoardingContainer
                }
            >
                <Grid item sm={12}>
                    <OnBoarding
                        initialEmailFormValues={initialEmailFormValues}
                        initialVerificationFormValues={
                            initialVerificationFormValues
                        }
                        status={verify ? VERIFY_STATE : ONBOARDING_STATE}
                        errors={verificationErrors}
                        messages={verificationMessages}
                        clearMessages={clearVerificationMessages}
                        clearSnackBar={() => {
                            clearErrors();
                            clearVerificationMessages();
                        }}
                        emailAddress={pendingApproveEmail}
                        onFormSubmit={handleFormSubmit}
                        onVerificationSubmit={handleVerificationSubmit}
                        onVerificationResend={handleVerificationResend}
                        onEmailLogin={onEmailLogin}
                        onMicrosoftLogin={onMicrosoftLogin}
                        onGoogleLogin={onGoogleLogin}
                        stripePlanId={stripePlanId}
                        toggleChecked={toggleChecked}
                        checked={checkbox}
                        toggleSubscribed={toggleSubscribed}
                        onSSOClick={onSSOClick}
                    />
                </Grid>
            </Grid>
            <Grid container item sm={12}>
                <OnboardingFooter positionFixed />
            </Grid>
        </Grid>
    );
};

OnboardingScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onEmailLogin: PropTypes.func.isRequired,
    onMicrosoftLogin: PropTypes.func.isRequired,
    onGoogleLogin: PropTypes.func.isRequired,
    sendEmailVerificationCode: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    verificationErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    pendingApproveEmail: PropTypes.string.isRequired,
    accountDetails: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    sendStripePlanId: PropTypes.func.isRequired,
    stripePlanId: PropTypes.string.isRequired,
    submitForm: PropTypes.func.isRequired,
    populateForm: PropTypes.func,
    valid: PropTypes.bool.isRequired,
    verificationMessages: PropTypes.string.isRequired,
    clearVerificationMessages: PropTypes.func.isRequired,
    attemptedCode: PropTypes.string.isRequired,
    onSSOClick: PropTypes.func,
};

OnboardingScreen.defaultProps = {
    onEmailLogin: noop,
    onMicrosoftLogin: noop,
    onGoogleLogin: noop,
    sendEmailForVerification: noop,
    sendEmailVerificationCode: noop,
    clearErrors: noop,
    resetVerificationFun: noop,
    populateForm: noop,
    onSSOClick: noop,
};

export const StyledOnboardingScreen = withStyles(styles)(OnboardingScreen);

const mapStateToProps = (state) => ({
    verificationErrors: getEmailVerificationErrors(state),
    isLoading: isAuthLoading(state),
    pendingApproveEmail: getOnboardingEmail(state),
    accountDetails: getOnBoardingAccountDetails(state),
    isEmailVerified: getEmailVerifiedStatus(state),
    isAuthenticated: getToken(state),
    userId: getUserId(state),
    stripePlanId: getStripePlanId(state),
    valid: isOrgValid(state),
    verificationMessages: getEmailVerificationMessages(state),
    attemptedCode: getEmailVerificationCode(state),
});

const mapDispatchToProps = (dispatch) => ({
    sendEmailVerificationCode: (data) =>
        dispatch(emailVerificationVerify(data)),
    clearErrors: () => dispatch(clearAuthErrors()),
    sendStripePlanId: (planId) => dispatch(retrieveStripePlanId(planId)),
    submitForm: (values) => dispatch(initialFormSubmit(values)),
    populateForm: (values) => dispatch(initialFormPopulate(values)),
    clearVerificationMessages: () => dispatch(clearEmailVerificationMessages()),
    onSSOClick: (config) =>
        dispatch(SSOAuthRequested(config, RETURN_LOCATION_ADMIN_SIGNUP)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledOnboardingScreen);
