import React, { Fragment } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMAT } from '../constants';
import Tooltip from '../../../common/Tooltip';
import styles from './styles';

const PenaltyCell = ({
    classes,
    totalTooltip,
    lastTooltip,
    count,
    created,
}) => {
    return (
        <Fragment>
            <Tooltip title={totalTooltip}>
                <Typography component="span" className={classes.bold}>
                    {count}
                </Typography>
            </Tooltip>
            <br />
            {!!created && !!count && (
                <Fragment>
                    <Tooltip title={lastTooltip}>
                        <Typography component="span" className={classes.small}>
                            {moment(created).format(DATE_FORMAT)}
                        </Typography>
                    </Tooltip>
                    <br />
                </Fragment>
            )}
        </Fragment>
    );
};

PenaltyCell.propTypes = {
    classes: PropTypes.object.isRequired,
    totalTooltip: PropTypes.string,
    lastTooltip: PropTypes.string,
    created: PropTypes.string,
    count: PropTypes.number,
};

PenaltyCell.defaultProps = {
    totalTooltip: '',
    lastTooltip: '',
    created: '',
    count: 0,
};

export default withStyles(styles)(PenaltyCell);
