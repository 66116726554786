import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        width: '500px',
        height: '500px',
        overflow: 'hidden',
        margin: '0 auto',
    },
    previewImage: {
        width: '500px',
        height: '500px',
    },
}));

export default useStyles;
