import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
    navContainer: {
        textDecoration: 'none',
    },
    cardContainer: ({ backgroundImageURL }) => ({
        borderRadius: '3px',
        position: 'relative',
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        padding: '100px 10px 10px 10px',
        height: '100%',
        flexWrap: 'nowrap',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            height: '100%',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            backgroundColor: '#00000080',
            transition: '0.1s',
        },
        '&:hover': {
            '&::after': {
                backgroundColor: '#00000090',
                transition: '0.1s',
            },
        },
    }),
    iconBox: {
        zIndex: 1,
        paddingRight: '10px',
    },
    icon: {
        width: '123px',
        [theme.breakpoints.down('xs')]: {
            width: '90px',
        },
    },
    infoContainer: {
        width: '100%',
        color: theme.palette.common.white,
        zIndex: 1,
    },
    name: {
        fontSize: '20px',
        marginBottom: '4px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '1.1',
        fontWeight: '500',
    },
    description: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '1.4',
    },
    iconChallenge: {
        border: `2px solid ${theme.palette.common.white}`,
        borderRadius: '6px 6px 50% 50%',
        [theme.breakpoints.down('xs')]: {
            width: '90px',
        },
    },
}));
