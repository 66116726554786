import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import Button from '../Button';
import PhotoCard from '../PhotoCard';
import SocialPhoto from '../SocialPhoto';
import styles from './styles';
import { CARD_SCREENS } from '../PhotoCard/constants';
import InfoTooltip from '../InfoTooltip';

const TopPhotos = ({
    classes,
    photos,
    challengeId,
    onViewAllClick,
    limit,
    noHeader,
    screen,
    intercomTargetPrefix,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [photo, setPhoto] = useState(photos && photos[0]);
    const [open, setOpen] = useState(false);

    const goToViewAll = () => {
        history.push(`/challenges/${challengeId}/analytics/photos/`);
    };

    const closeHandler = () => {
        setOpen(false);
    };

    const getFilteredPhotos = () => {
        const rawPhotos = [...photos];

        if (photos.length === 0) return [];

        if (rawPhotos.length > limit) {
            rawPhotos.length = limit;
        }

        return rawPhotos;
    };

    const onPhotoCardClick = (e, index) => {
        setPhoto(photos[index]);
        setOpen(true);
    };

    return (
        <Grid container item>
            {noHeader || (
                <Grid
                    container
                    item
                    className={classes.header}
                    justify="space-between"
                >
                    <Typography className={classes.title} variant="h3">
                        {t('topPhotos')}
                        <InfoTooltip
                            intercomTarget={`${intercomTargetPrefix}TopPhotos`}
                            text={t('topPhotosDesc')}
                        />
                    </Typography>
                    <Button
                        className={classes.button}
                        variant="text"
                        color="gray"
                        onClick={onViewAllClick || goToViewAll}
                    >
                        {t('viewAll')}
                        <KeyboardArrowRight />
                    </Button>
                </Grid>
            )}
            <Grid container item justify="center">
                {photos.length === 0 && (
                    <Typography className={classes.emptyText}>
                        {t('topPhotosNone')}
                    </Typography>
                )}
                {getFilteredPhotos().map((photo, index) => (
                    <PhotoCard
                        key={index}
                        photo={photo}
                        index={index}
                        onClick={onPhotoCardClick}
                        screen={screen}
                    />
                ))}
            </Grid>
            {photo && (
                <SocialPhoto
                    photo={photo}
                    isOpen={open}
                    onClose={closeHandler}
                />
            )}
        </Grid>
    );
};

TopPhotos.propTypes = {
    classes: PropTypes.object.isRequired,
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            actionUrl: PropTypes.string,
            likes: PropTypes.array,
            comments: PropTypes.array,
        }),
    ),
    challengeId: PropTypes.string,
    onViewAllClick: PropTypes.func,
    limit: PropTypes.number,
    noHeader: PropTypes.bool,
    screen: PropTypes.oneOf(CARD_SCREENS).isRequired,
    intercomTargetPrefix: PropTypes.string,
};

TopPhotos.defaultProps = {
    photos: [],
    challengeId: null,
    onViewAllClick: null,
    limit: 12,
    noHeader: false,
    intercomTargetPrefix: '',
};

export default withStyles(styles)(TopPhotos);
