import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import ConfirmModal from '../../../common/ConfirmModal';
import { BUTTON_DEFAULT_COLOR } from '../../../common/Button/constants';
import styles from '../ChallengeInfo/styles';
import theme from '../../../theme';
import ChallengeActionsTable from './ChallengeActionsTable';
import { actionTablePropType } from '../../../actions/propTypes';
import { challengeAddButtonsCell } from '../../ActionsScreen/AdminActionsScreen/constants';
import { PIN_ADMIN_VISIBILITY_ALL_INCLUDED } from '../../../actions/constants';
import useStyles from './styles';

const ChallengeActionsModal = ({
    isOpen,
    onClose,
    onCancel,
    onConfirm,
    fetchActions,
    actions,
    themes,
    onAddAction,
}) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const onButtonsCell = useCallback(
        (id, key) => {
            switch (key) {
                case 'editChallenge':
                    onAddAction(
                        actions?.data?.find((action) => action.id === id),
                    );
                    break;
                default:
                    break;
            }
        },
        [actions, onAddAction],
    );

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={t('challenge.actions.add')}
            titleStyle={classes.title}
            modalStyle={classes.modal}
            cancelBtnType={BUTTON_DEFAULT_COLOR}
            cancelBtnText={t('cancel')}
            onClose={onClose}
            onCancel={onCancel}
            onConfirm={onConfirm}
            confirmBtnType="success"
            centeredButtons
            cancelBtnHidden
            confirmBtnColor={theme.palette.primary.main}
            fullWidth
            maxWidth="md"
        >
            {isOpen && (
                <ChallengeActionsTable
                    themes={themes}
                    showThemesFilter
                    fetchDataFunc={fetchActions}
                    data={actions}
                    buttonsCell={challengeAddButtonsCell}
                    onButtonsCell={onButtonsCell}
                    type={PIN_ADMIN_VISIBILITY_ALL_INCLUDED}
                />
            )}
        </ConfirmModal>
    );
};

ChallengeActionsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    fetchActions: PropTypes.func.isRequired,
    actions: actionTablePropType.isRequired,
    themes: PropTypes.array,
    onAddAction: PropTypes.func.isRequired,
};

ChallengeActionsModal.defaultProps = {
    themes: [],
};

export default withStyles(styles)(ChallengeActionsModal);
