import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../utils/api';
import {
    ACTIVITY_ADD_COMMENT_REQUESTED,
    ACTIVITY_DATA_REQUESTED,
    ACTIVITY_DETAIL_REQUESTED,
    ACTIVITY_LIKE_REQUESTED,
    ACTIVITY_SHARE_URL_REQUESTED,
    activityDataRequestFailed,
    activityDataRequestSucceeded,
    activityDetailRequestFailed,
    activityDetailRequestSucceeded,
    activityLikeRequestFailed,
    activityLikeRequestSucceeded,
    activityShareURLRequestFailed,
    activityShareURLRequestSucceeded,
    addCommentRequestFailed,
    addCommentRequestSucceeded,
} from './actions';
import {
    ACTIVITIES_FEED_TYPE_CHALLENGE,
    ACTIVITIES_FEED_TYPE_FOLLOWING,
    ACTIVITIES_FEED_TYPE_TEAM,
    ACTIVITIES_FEED_TYPE_TRENDING,
    ACTIVITIES_FEED_TYPE_USER,
} from './constants';
import { SCROLLER_BATCH_SIZE } from '../common/InfiniteScroller/constants';
import i18n from 'i18next';

export function* getActivityData({ payload: { activitiesType, options } }) {
    const getURL = () => {
        switch (activitiesType) {
            case ACTIVITIES_FEED_TYPE_TRENDING:
                return `/community/${options.communityId}/buzz`;
            case ACTIVITIES_FEED_TYPE_CHALLENGE:
                return `/challenges/${options.challengeId}/buzz`;
            case ACTIVITIES_FEED_TYPE_TEAM:
                return `/teams/${options.teamId}/buzz`;
            case ACTIVITIES_FEED_TYPE_USER:
                return `user/${options.userId}/buzz`;
            case ACTIVITIES_FEED_TYPE_FOLLOWING:
            default:
                return '/buzz/recent';
        }
    };
    try {
        const activityData = yield call(fetchWithAuth, getURL(), {
            query: {
                limit: SCROLLER_BATCH_SIZE,
                ...options,
            },
        });
        yield put(
            activityDataRequestSucceeded(
                activitiesType,
                activityData.response?.activities || [],
                options.skip,
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('activitiesPage.fetchError'),
        );
        yield put(activityDataRequestFailed(activitiesType, errors));
    }
}

export function* getActivityDetail({ payload: { activityId } }) {
    try {
        const activityData = yield call(fetchWithAuth, `/buzz/${activityId}`);
        yield put(
            activityDetailRequestSucceeded(activityData.response.activities[0]),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('activitiesPage.fetchError'),
        );
        yield put(activityDetailRequestFailed(errors));
    }
}
export function* likeActivity({ payload: { activityId, like } }) {
    try {
        const activityData = yield call(
            fetchWithAuth,
            `/buzz/${activityId}/like`,
            {
                method: 'POST',
                body: {
                    like,
                },
            },
        );
        yield put(
            activityLikeRequestSucceeded(activityData.response.activities[0]),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('activitiesPage.likeError'),
        );
        yield put(activityLikeRequestFailed(errors));
    }
}
export function* addComment({ payload: { activityId, comment } }) {
    try {
        const activityData = yield call(
            fetchWithAuth,
            `/buzz/${activityId}/addcomment`,
            {
                method: 'POST',
                body: {
                    comment,
                },
            },
        );
        yield put(
            addCommentRequestSucceeded(activityData.response.activities[0]),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('activitiesPage.addCommentError'),
        );
        yield put(addCommentRequestFailed(errors));
    }
}
export function* getShareURL({ payload: { activityId } }) {
    try {
        const activityData = yield call(
            fetchWithAuth,
            `/buzz/${activityId}/social`,
        );

        yield put(
            activityShareURLRequestSucceeded(
                activityId,
                activityData.response['dynamic-links'][0].url,
            ),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('activitiesPage.getShareURLError'),
        );
        yield put(activityShareURLRequestFailed(errors));
    }
}

export default function* activitiesSaga() {
    yield all([
        takeLatest(ACTIVITY_DATA_REQUESTED, getActivityData),
        takeLatest(ACTIVITY_DETAIL_REQUESTED, getActivityDetail),
        takeLatest(ACTIVITY_LIKE_REQUESTED, likeActivity),
        takeLatest(ACTIVITY_SHARE_URL_REQUESTED, getShareURL),
        takeLatest(ACTIVITY_ADD_COMMENT_REQUESTED, addComment),
    ]);
}
