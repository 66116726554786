import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Grid, Link, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import styles from './styles';
import Button from '../../../common/Button';
import Table from '../../../common/Table';
import { getTimezoneAbbrev } from '../../../utils/dates';
import {
    getChallengesScreenActionErrors,
    getChallengesScreenActionResponse,
    getGroupLoadingStates,
    getGroupTableData,
    isChallengesScreenActionLoading,
} from '../selectors';
import {
    challengeActivationRequested,
    challengeDeactivationRequested,
    challengeDeletionRequested,
    challengeReportRequested,
    challengesRequested,
    clearChallengesScreenActionErrors,
} from '../../../challenges/actions';
import { getActiveCommunityId } from '../../../user/selectors';
import {
    ACTIVATE_ACTION,
    BUTTONS_CELL,
    DEACTIVATE_ACTION,
    DELETE_CHALLENGE_ACTION,
    GENERATE_REPORT_ACTION,
    GROUP_ACTIVE,
    GROUP_DEACTIVATED,
    GROUP_DRAFT,
    GROUP_NAMES,
    GROUP_PAST,
    GROUP_UPCOMING,
    GROUPS,
    INTERCOM_TARGET_PREFIX,
} from '../constants';
import { Can } from '../../../casl/ability-context';
import CommonPropTypes from '../../../common/propTypes';
import { sortPropType } from '../../../common/Table/propTypes';
import ActivateModal from '../../../common/ActivateModal';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';
import { handleButtonsCell } from '../../../utils/challengeButtons';
import { getAppImages } from '../../../utils';
import { useTrackScreenView } from '../../../utils/analytics';
import HeaderPage from '../../../common/HeaderPage';
import { CommunitiesIcon } from '../../../assets/images/svg/index';
import Tooltip from '../../../common/Tooltip';

const groupsForPath = (path) => {
    switch (path) {
        case '/challenges/active':
            return [GROUP_ACTIVE];
        case '/challenges/drafts':
            return [GROUP_DRAFT];
        case '/challenges/upcoming':
            return [GROUP_UPCOMING];
        case '/challenges/past':
            return [GROUP_PAST];
        case '/challenges/deactivated':
            return [GROUP_DEACTIVATED];
        default:
            return GROUPS;
    }
};

const ChallengesScreen = ({
    classes,
    tableData,
    loadFunc,
    communityId,
    groupLoadingStates,
    initialSort,
    isActionLoading,
    actionErrors,
    actionResponse,
    requestActivation,
    requestDeactivation,
    requestChallengeReport,
    clearErrors,
    requestDeletion,
}) => {
    const { path } = useRouteMatch();
    const { t, i18n } = useTranslation();
    const tzCode = getTimezoneAbbrev(i18n.language);
    const history = useHistory();

    useTrackScreenView('challenge_view_overview_admin');

    const usedGroups = useMemo(() => groupsForPath(path), [path]);

    const [modalDetails, setModalDetails] = useState(null);

    const loadAllGroups = useCallback(
        (communityId) => {
            usedGroups.forEach((group) => {
                loadFunc({
                    communityId,
                    group,
                });
            });
        },
        [usedGroups, loadFunc],
    );

    useEffect(() => {
        loadAllGroups(communityId);
    }, [loadAllGroups, communityId]);

    const makeColumns = () => [
        {
            accessor: 'icon',
            Cell: ({ value }) => (
                <Grid style={{ position: 'relative' }}>
                    <img
                        src={value.url || getAppImages().defaultChallengeIcon}
                        width={42}
                        height={42}
                        alt={t('challenge.alt.icon')}
                        className={classes.challengeIcon}
                    />
                    {value.communityId && value.communityId !== communityId && (
                        <Tooltip
                            title={t('challenge.communitySwitch', {
                                communityName: value.communityName,
                            })}
                            placement="left"
                            className={classes.tooltip}
                        >
                            <Link
                                href={`/challenges?communityId=${value.communityId}`}
                                onClick={() => loadAllGroups(communityId)}
                            >
                                <CommunitiesIcon className={classes.icon} />
                            </Link>
                        </Tooltip>
                    )}
                </Grid>
            ),
        },
        {
            Header: t('Title'),
            accessor: 'title',
            Cell: ({ value }) => (
                <div className={classes.challengeTitle}>{value}</div>
            ),
        },
        {
            Header: t('Teams'),
            accessor: 'teams',
            Cell: ({ value }) => (
                <div className={classes.centerColumn}>{value}</div>
            ),
            align: 'center',
        },
        {
            Header: t('Members'),
            accessor: 'members',
            Cell: ({ value }) => (
                <div className={classes.centerColumn}>{value}</div>
            ),
            align: 'center',
        },
        {
            Header: `${t('challenge.startDate')} (${tzCode})`,
            accessor: 'startDate',
            Cell: ({ value }) => (
                <div className={classes.centerColumn}>
                    {moment(value).format('M/D/YY h:mm A')}
                </div>
            ),
            align: 'center',
        },
        {
            Header: `${t('challenge.endDate')} (${tzCode})`,
            accessor: 'endDate',
            Cell: ({ value }) => (
                <div className={classes.centerColumn}>
                    {moment(value).format('M/D/YY h:mm A')}
                </div>
            ),
            align: 'center',
        },
        { accessor: 'actions' },
    ];

    const columns = makeColumns();

    const handleCreateChallenge = () => {
        history.push('/challenges/create');
    };

    const findChallengeById = (id) => {
        let challengeData = null;
        for (const group of usedGroups) {
            challengeData = tableData[group].find((item) => item.id === id);
            if (challengeData != null) {
                break;
            }
        }
        return challengeData;
    };

    const [isActionSent, setActionSent] = useState(false);

    useEffect(() => {
        if (
            isActionSent &&
            !isActionLoading &&
            actionErrors == null &&
            actionResponse != null
        ) {
            loadAllGroups(communityId);
            setActionSent(false);
        }
    }, [
        isActionSent,
        isActionLoading,
        actionErrors,
        actionResponse,
        loadAllGroups,
        communityId,
    ]);

    const handleCloseModal = () => {
        modalDetails && setModalDetails({ ...modalDetails, isOpen: false });
    };

    const handleConfirm = () => {
        handleCloseModal();
        const challengeId = modalDetails.items[0].identifier;
        const requestBody = {
            communityId,
            challengeId,
        };
        switch (modalDetails.action) {
            case ACTIVATE_ACTION:
                requestActivation(requestBody);
                setActionSent(true);
                break;
            case DEACTIVATE_ACTION:
                requestDeactivation(requestBody);
                setActionSent(true);
                break;
            case GENERATE_REPORT_ACTION:
                requestChallengeReport(challengeId);
                break;
            case DELETE_CHALLENGE_ACTION:
                requestDeletion(requestBody);
                setActionSent(true);
                break;
            default:
                break;
        }
    };

    const onButtonsCell = (id, key) => {
        const challenge = findChallengeById(id);
        handleButtonsCell(
            id,
            key,
            challenge?.title,
            challenge?.icon?.url,
            history,
            setModalDetails,
            t,
        );
    };
    return (
        <Grid container direction="column" className={classes.container}>
            {modalDetails && (
                <ActivateModal
                    {...modalDetails}
                    onClose={handleCloseModal}
                    onCancel={handleCloseModal}
                    onConfirm={handleConfirm}
                />
            )}
            <Grid
                container
                item
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.header}
            >
                <HeaderPage
                    title={t('challenge_plural')}
                    rightContent={
                        <Can I="create" a="Challenge">
                            <Button
                                data-intercom-target={`${INTERCOM_TARGET_PREFIX}CreateButton`}
                                color="accent"
                                onClick={handleCreateChallenge}
                            >
                                <Add />
                                {t('challenge.create.submit')}
                            </Button>
                        </Can>
                    }
                />
            </Grid>
            {usedGroups.map((group, index) => {
                return (
                    <Grid key={index} container item className={classes.table}>
                        <Table
                            title={t(GROUP_NAMES[group])}
                            intercomTargetPrefix={`${INTERCOM_TARGET_PREFIX}${group}`}
                            columns={columns}
                            data={tableData[group]}
                            buttonsCell={BUTTONS_CELL[group]}
                            onButtonsCell={onButtonsCell}
                            initialSortBy={initialSort}
                            loaderRows={2}
                            loading={groupLoadingStates[group]}
                            clientPagination={true}
                        />
                    </Grid>
                );
            })}
            <SnackBarAPIResponse
                messages={actionResponse}
                errors={actionErrors}
                clearResponse={clearErrors}
            />
        </Grid>
    );
};

ChallengesScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    tableData: PropTypes.object.isRequired,
    loadFunc: PropTypes.func.isRequired,
    communityId: PropTypes.string.isRequired,
    groupLoadingStates: PropTypes.object.isRequired,

    // Define initial sorting behavior for all tables rendered by this screen.
    initialSort: sortPropType,

    // selectors and actions for challenge [de]activation
    isActionLoading: PropTypes.bool.isRequired,
    actionErrors: CommonPropTypes.errors,
    actionResponse: PropTypes.object,
    requestActivation: PropTypes.func.isRequired,
    requestDeactivation: PropTypes.func.isRequired,
    requestChallengeReport: PropTypes.func.isRequired,
    requestDeletion: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

ChallengesScreen.defaultProps = {
    initialSort: [{ id: 'startDate', desc: true }],
    actionErrors: null,
    actionResponse: null,
};

const StyledChallengesScreen = withStyles(styles)(ChallengesScreen);

const mapStateToProps = (state) => ({
    tableData: GROUPS.reduce(
        (acc, group) => ({
            ...acc,
            [group]: getGroupTableData(state, group),
        }),
        {},
    ),
    communityId: getActiveCommunityId(state),
    groupLoadingStates: getGroupLoadingStates(state),
    isActionLoading: isChallengesScreenActionLoading(state),
    actionErrors: getChallengesScreenActionErrors(state),
    actionResponse: getChallengesScreenActionResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadFunc: (request) => dispatch(challengesRequested(request)),
    requestActivation: (request) =>
        dispatch(challengeActivationRequested(request)),
    requestDeactivation: (request) =>
        dispatch(challengeDeactivationRequested(request)),
    requestChallengeReport: (challengeId) =>
        dispatch(challengeReportRequested(challengeId)),
    requestDeletion: (request) => dispatch(challengeDeletionRequested(request)),
    clearErrors: () => dispatch(clearChallengesScreenActionErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledChallengesScreen);
