const styles = (theme) => ({
    tabs: {
        borderRadius: '4px',
        overflow: 'hidden',
        boxShadow: '0 0 3px 1px lightgray',
    },
    title: {
        margin: '20px 0 10px',
        fontSize: '24px',
        fontWeight: 600,
        color: theme.custom.textDarkSecondaryColor,
    },
    selectWrapper: {
        height: '32px',
        marginLeft: '5px',
    },
    select: {
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
    option: {
        paddingLeft: '20px',

        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            cursor: 'pointer',
        },
    },
});

export default styles;
