import {
    AUDIENCE_VALUE_CHALLENGE_MEMBERS,
    AUDIENCE_VALUE_CHALLENGE_TEAMS,
    AUDIENCE_VALUE_COMMUNITIES,
    AUDIENCE_VALUE_COMMUNITY_MEMBERS,
    AUDIENCE_VALUE_COMMUNITY_MEMBERS_NOT_JOINED,
    AUDIENCE_VALUE_USERS,
} from '../../../notifications/constants';

export const FORM_FIELD_AUDIENCES = 'audiences';
export const FORM_FIELD_AUDIENCE_TYPE = 'audienceType';
export const FORM_FIELD_SUBJECT = 'subject';
export const FORM_FIELD_SUBJECT_CONTENT_ID = 'subjectContentID';
export const FORM_FIELD_SUBJECT_CONTENT_TYPE = 'subjectContentType';
export const FORM_FIELD_MESSAGE = 'message';
export const FORM_FIELD_SEND_DATE = 'sendDate';

export const AUDIENCE_TYPE_CHALLENGE_TEAMS = {
    id: 'audience-5',
    title: 'Team',
    value: AUDIENCE_VALUE_CHALLENGE_TEAMS,
    iconName: 'jb-community',
};

export const ALL_AUDIENCE_TYPES = [
    {
        id: 'audience-0',
        title: 'Everyone',
        value: AUDIENCE_VALUE_COMMUNITY_MEMBERS,
        iconName: 'jb-diversity',
    },
    {
        id: 'audience-1',
        title: 'User',
        value: AUDIENCE_VALUE_USERS,
        iconName: 'jb-profile',
    },
    {
        id: 'audience-2',
        title: 'Joined',
        value: AUDIENCE_VALUE_CHALLENGE_MEMBERS,
        iconName: 'jb-challenge-member',
    },
    {
        id: 'audience-3',
        title: 'Not Joined',
        value: AUDIENCE_VALUE_COMMUNITY_MEMBERS_NOT_JOINED,
        iconName: 'jb-challenge-join',
    },
    {
        id: 'audience-4',
        title: 'Community',
        value: AUDIENCE_VALUE_COMMUNITIES,
        iconName: 'jb-community',
    },
    AUDIENCE_TYPE_CHALLENGE_TEAMS,
];

export const DEFAULT_AUDIENCE_TYPES = [
    AUDIENCE_VALUE_USERS,
    AUDIENCE_VALUE_COMMUNITIES,
    AUDIENCE_VALUE_COMMUNITY_MEMBERS,
].map((type) => ALL_AUDIENCE_TYPES.find((item) => item.value === type));

export const AUDIENCE_TYPES_WITH_AUDIENCE = [
    AUDIENCE_VALUE_COMMUNITIES,
    AUDIENCE_VALUE_CHALLENGE_TEAMS,
    AUDIENCE_VALUE_USERS,
];

export const MAX_MSG_LENGTH = 140;
