import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

const Modal = ({ isOpen, onClose, children, ...props }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} {...props}>
            {children}
        </Dialog>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default Modal;
