import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../../utils';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import SignUpForm from '../../../common/OnBoarding/SignUpForm';
import { signUpRequested } from '../../../auth/actions';
import useStyles from './styles';
import SignInScreenTemplate from '../index';
import IMAGES from '../../../constants/images';
import { getOnboardingEmail } from '../../../auth/selectors';
import { updateIntercomUserData } from '../../../config/intercom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getActiveCommunityId } from '../../../user/selectors';
import { VARIANT_SSO } from '../../../common/OnBoarding/SignUpForm/constants';
import { trackEvent } from '../../../utils/analytics';
import { EVENT_ORGANIZATION_DOMAIN_RESTRICTED } from '../../../constants/analyticsEvents';

export const OAuthOrganizationCreateScreen = ({
    onSignUp,
    email,
    communityId,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [checked, setChecked] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [ssoEnabled, setSSOEnabled] = useState(false);

    const handleSubmit = (values) => {
        const formValues = {
            email,
            organization: values.orgName,
            website: values.website,
            employeeCount: values.employeeCount,
            enableSSO: values.ssoEnabled,
        };
        updateIntercomUserData({ unsubscribe_from_emails: subscribed });
        if (values.ssoEnabled) {
            trackEvent(EVENT_ORGANIZATION_DOMAIN_RESTRICTED, {
                type: 'google',
            });
        }
        onSignUp(formValues);
    };
    useEffect(() => {
        if (communityId) {
            history.push('/get-started/payment');
        }
    }, [communityId, history]);

    return (
        <SignInScreenTemplate
            leftBackgroundImage={IMAGES.bgEmailVerification2}
            rightBackgroundImage={IMAGES.bgEmailVerification1}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    justifyContent="center"
                    style={{ marginBottom: 30 }}
                >
                    <Typography variant="h3" className={classes.verifyTitle}>
                        {t('onBoarding.verify.titleGreen')}
                    </Typography>
                    <Typography
                        variant="h3"
                        className={classes.onBoardingTitle}
                    >
                        {t('onBoarding.community.title')}
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid container>
                        <SignUpForm
                            initialFormValues={{ email }}
                            onSubmit={handleSubmit}
                            checked={checked}
                            toggleChecked={() => setChecked(!checked)}
                            toggleSubscribed={() => setSubscribed(!subscribed)}
                            toggleSSOEnabled={() => setSSOEnabled(!ssoEnabled)}
                            variant={VARIANT_SSO}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </SignInScreenTemplate>
    );
};

OAuthOrganizationCreateScreen.propTypes = {
    email: PropTypes.string,
    communityId: PropTypes.string,
    onSignUp: PropTypes.func,
};

OAuthOrganizationCreateScreen.defaultProps = {
    email: '',
    communityId: '',
    onSignUp: noop,
};

const mapStateToProps = (state) => ({
    email: getOnboardingEmail(state),
    communityId: getActiveCommunityId(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSignUp: (form) => dispatch(signUpRequested(form)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OAuthOrganizationCreateScreen);
