import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Link, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { getAboutLink, getPrivacyLink, getTermsLink } from '../../../utils';
import { showIntercomChat } from '../../../config/intercom';

const AboutAndSupport = ({ classes }) => {
    const { t } = useTranslation();

    return (
        <Grid container direction="row">
            <Grid className={classes.aboutSection} item container xs={12}>
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="h4">
                        {t('settings.user.about')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Link
                        href={getTermsLink()}
                        target="_blank"
                        className={classes.link}
                    >
                        {t('termsOfService')}
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link
                        href={getPrivacyLink()}
                        target="_blank"
                        className={classes.link}
                    >
                        {t('privacyPolicy')}
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link
                        href={getAboutLink()}
                        target="_blank"
                        className={classes.link}
                    >
                        {t('settings.user.about')}
                    </Link>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="h4">
                        {t('Support')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        onClick={() => showIntercomChat()}
                        className={classes.link}
                    >
                        {t('settings.user.helpCenter')}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

AboutAndSupport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutAndSupport);
