import React, { useCallback, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import ChallengeActionsTable from './ChallengeActionsTable';
import { noop } from '../../../utils';
import { actionTablePropType } from '../../../actions/propTypes';
import {
    CLIENT_PAGINATION_FETCH_LIMIT,
    PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
} from '../../../actions/constants';
import useStyles from './styles';
import {
    challengeEditButtonsCell,
    challengeViewButtonsCell,
} from '../../ActionsScreen/AdminActionsScreen/constants';
import { useHistory } from 'react-router-dom';

const ChallengeActions = ({
    isEdit,
    onEditActions,
    onRemoveAction,
    data,
    fetchTableData,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        fetchTableData({ limit: CLIENT_PAGINATION_FETCH_LIMIT });
    }, [fetchTableData]);

    const classes = useStyles();

    const handleActions = useCallback(
        (id, key) => {
            switch (key) {
                case 'viewActionsAdmin':
                    window.open(`/actions/${id}`);
                    break;
                case 'editActions':
                    history.push(`/actions/${id}/edit`);
                    break;

                case 'editChallenge':
                    onRemoveAction(
                        data?.data?.find((action) => action.id === id),
                    );
                    break;

                default:
                    alert({ id, key });
            }
        },
        [history, onRemoveAction, data],
    );

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.label}>
                <Typography variant="h5"> {t('actions.descriptor')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.description}>
                    {t('challenge.actions.header')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ChallengeActionsTable
                    data={data}
                    type={PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME}
                    clientPagination
                    buttonsCell={
                        isEdit
                            ? challengeEditButtonsCell
                            : challengeViewButtonsCell
                    }
                    onButtonsCell={handleActions}
                />
            </Grid>
            {isEdit && (
                <Grid item xs={2}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.addButton}
                        onClick={onEditActions}
                        textColor="#fff"
                        data-intercom-target="challengeActionsAddButton"
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="row"
                            className={classes.buttonText}
                        >
                            <Add />
                            {t('challenge.actions.add')}
                        </Grid>
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

ChallengeActions.propTypes = {
    isEdit: PropTypes.bool,
    onEditActions: PropTypes.func,
    onRemoveAction: PropTypes.func,
    fetchTableData: PropTypes.func.isRequired,
    data: actionTablePropType.isRequired,
};
ChallengeActions.defaultProps = {
    isEdit: false,
    onEditActions: noop,
    onRemoveAction: noop,
    fetchTableData: noop,
};

export default ChallengeActions;
