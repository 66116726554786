const styles = (theme) => ({
    snackBar: {
        bottom: '86px',
        [theme.breakpoints.down('sm')]: {
            bottom: '134px',
        },
        [theme.breakpoints.down('xs')]: {
            bottom: '8px',
        },
    },
    successAlert: {
        color: theme.palette.alert.contrastText,
        backgroundColor: theme.palette.alert.main,
    },
    errorAlert: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
    },
});
export default styles;
