import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Can } from '../../casl/ability-context';
import useStyles from './styles';

const MenuDropdown = ({
    menuItems,
    title,
    id,
    titleUrl,
    TitleIcon,
    iconName,
    isExpandBlocked,
    isExpanded,
    handleSelfExpand,
}) => {
    const [expanded, setExpanded] = useState(false);
    const match = useRouteMatch(titleUrl);
    const history = useHistory();
    const classes = useStyles({ expanded, match, titleUrl });

    const handleNavigate = () => {
        if (titleUrl) {
            history.push(titleUrl);
        }
        handleSelfExpand(id);
        !isExpandBlocked && setExpanded(!expanded);
    };

    useEffect(() => {
        if (!isExpanded) setExpanded(false);
    }, [isExpanded]);

    useEffect(() => {
        if (isExpandBlocked) {
            setExpanded(false);
        }
    }, [isExpandBlocked]);

    const withCan = (children, actions, subject, id) => {
        if (!actions) return children;

        return (
            <Can I={actions} a={subject} id={id}>
                {children}
            </Can>
        );
    };

    return (
        <ExpansionPanel
            expanded={expanded}
            classes={{ root: classes.panel, expanded: classes.expanded }}
        >
            <ExpansionPanelSummary
                classes={{
                    root: classes.panelSummary,
                    content: classes.content,
                    expanded: classes.expanded,
                }}
            >
                <div className={classes.titleLink}>
                    <div
                        className={classes.titleLeftPartLink}
                        onClick={handleNavigate}
                    >
                        <div className={classes.titleIcon}>
                            <TitleIcon size={20} icon={iconName} />
                        </div>
                        {title}
                    </div>
                    <div
                        className={classes.titleArrowWrapper}
                        onClick={() => {
                            handleSelfExpand(title);
                            !isExpandBlocked && setExpanded(!expanded);
                        }}
                    >
                        <ChevronRight className={classes.titleArrow} />
                    </div>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                <div className={classes.panelWrapper}>
                    {menuItems.map((menuItem) =>
                        withCan(
                            <NavLink
                                className={classes.link}
                                activeClassName={classes.activeLink}
                                to={menuItem.url}
                            >
                                {menuItem.label}
                            </NavLink>,
                            menuItem.actions,
                            menuItem.subject,
                            menuItem.label,
                        ),
                    )}
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

MenuDropdown.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
    title: PropTypes.string.isRequired,
    titleUrl: PropTypes.string,
    TitleIcon: PropTypes.func.isRequired,
    iconName: PropTypes.string.isRequired,
    isExpandBlocked: PropTypes.bool,
    isExpanded: PropTypes.bool,
    handleSelfExpand: PropTypes.func.isRequired,
    // A unique string id that identifies this dropdown
    id: PropTypes.string.isRequired,
};

MenuDropdown.defaultProps = {
    menuItems: [],
    titleUrl: null,
    isExpandBlocked: false,
    isExpanded: false,
};

export default MenuDropdown;
