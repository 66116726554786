import PropTypes from 'prop-types';

export const upload = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,

    // If no photoTooltip is provided, we'll use photoTooltipKey which defaults to an existing localization key.
    photoTooltip: PropTypes.string,
    photoTooltipKey: PropTypes.string,

    // See https://react-dropzone.js.org/
    DropzoneProps: PropTypes.object,

    // If provided, this will be used when the error message for a rejected file has a code, 'file-too-large'
    fileTooLargeErrorMessage: PropTypes.string,

    // See https://www.npmjs.com/package/react-image-crop
    hideTip: PropTypes.bool,
    border: PropTypes.bool,
    borderRadius: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.node,
    maxHeight: PropTypes.string,
};

export default {
    upload,
};
