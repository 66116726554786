import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const ActivityCardBadge = ({ activities }) => {
    const classes = useStyles();

    return (
        <Grid
            className={classes.container}
            container
            item
            direction="column"
            spacing={2}
            wrap="nowrap"
            justify="space-between"
        >
            {activities.map((activity, index) => (
                <Grid
                    container
                    item
                    key={index}
                    direction="row"
                    wrap="nowrap"
                    justify="space-between"
                >
                    <Grid
                        container
                        item
                        direction="row"
                        wrap="nowrap"
                        justify="space-between"
                    >
                        <Grid item>
                            <Typography className={classes.andBlock}>
                                &amp;
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            justify="center"
                            direction="column"
                            item
                            alignItems="baseline"
                        >
                            <Typography className={classes.badgeName}>
                                {activity.activityName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        className={classes.badgeImage}
                        container
                        item
                        direction="column"
                        justify="center"
                    >
                        <img
                            src={activity.activityImage}
                            alt="badge"
                            width="36"
                            height="36"
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

ActivityCardBadge.propTypes = {
    activities: PropTypes.array.isRequired,
};

export default ActivityCardBadge;
