import { getProperties } from '../utils';

const config = getProperties()?.intercomConfig;
export const setupIntercomForUsers = (user, community) => {
    const {
        name,
        username: email,
        dateJoined,
        intercomHash,
        id,
        intercomRole,
    } = user;
    const settings = {
        user_hash: intercomHash,
        user_id: id,
        name: name, // Full name
        email: email, // Email address
        created_at: dateJoined, // Signup date as a Unix timestamp
        role: intercomRole,
        company: {
            /*
             * Todo: pull in additional params related to subscription
             * monthly_spend, user_count, size, website, plan
             */
            name: community.name,
            id: community.id,
            created_at: community.created || '',
        },
    };
    initIntercom(settings);
};

export const setupIntercomForLeads = () => {
    initIntercom();
};

export const showIntercomChat = () => {
    window.Intercom('showNewMessage', '');
};

export const initTour = (tourId) => {
    window.Intercom('startTour', tourId);
};

export const initChallengeEditingTour = () => {
    initTour(config.productTours.challengeEdit);
};

export const updateIntercomUserData = (userData = {}) => {
    window.Intercom('update', {
        ...window.intercomSettings,
        ...userData,
    });
};

export const updateIntercomCompanyData = (companyData = {}) => {
    window.Intercom('update', {
        ...window.intercomSettings,
        company: {
            ...window.intercomSettings.company,
            ...companyData,
        },
    });
};

export const shutDownIntercom = () => {
    try {
        window.Intercom('shutdown');
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error shutting down intercom');
    }
};

const initIntercom = (settings) => {
    window.intercomSettings = settings;
    if (!config.enabled) {
        return;
    }
    const baseSettings = {
        api_base: config.apiBase,
        app_id: config.appId,
    };
    window.Intercom('boot', { ...baseSettings, ...settings });
};
