export default (theme) => ({
    root: {
        backgroundColor: theme.palette.white.main,
        fontSize: 14,
        whiteSpace: 'nowrap',
        '& td, th': {
            padding: '8px',
            width: 'inherit',
            height: 'inherit',
            color: theme.custom.darkGrey,
            fontWeight: 'bold',
        },
    },
    sortLabel: {
        color: `${theme.palette.primary.main} !important`,
        '& svg': {
            color: 'inherit !important',
        },
    },
    defaultCell: {
        padding: 8,
    },
    checkboxCell: {
        padding: '0 8px !important',
    },
    centerAlignHeader: {
        textAlign: 'center',
    },
    leftAlignHeader: {
        textAlign: 'left',
    },
    rightAlignHeader: {
        textAlign: 'right',
    },
});
