import { createSelector } from 'reselect';
import { getUserState } from '../user/selectors';
import { coerceIntoErrors } from '../common/errors';

export const getAuthState = (state) => {
    return state.auth;
};

export const getToken = createSelector(getAuthState, (auth) => auth.token);

export const isAuthLoading = createSelector(
    getAuthState,
    (auth) => !!auth.loading,
);

export const isAuthenticated = createSelector(
    getAuthState,
    getUserState,
    (auth, user) => !!auth.token && !!user.user?.id,
);

export const getAuthMessages = createSelector(
    getAuthState,
    (auth) => auth.messages,
);

export const getLoginErrors = createSelector(
    getAuthState,
    (auth) => coerceIntoErrors(auth.errors.login) || [],
);

export const getRedirect = createSelector(
    getAuthState,
    (auth) => auth.redirect,
);

export const getAuthErrors = createSelector(getAuthState, (auth) => auth);

export const getPwResetErrors = createSelector(
    getAuthState,
    (auth) => auth.errors.pwReset || [],
);

export const getEmailVerificationErrors = createSelector(
    getAuthState,
    (auth) => auth.errors.emailVerificationErrors || [],
);

export const getEmailVerificationMessages = createSelector(
    getAuthState,
    (auth) => auth.onBoarding.verificationMessages,
);

export const getEmailVerificationCode = createSelector(
    getAuthState,
    (auth) => auth.onBoarding.verificationCode,
);

export const getEmailVerifiedStatus = createSelector(
    getAuthState,
    (auth) => auth.onBoarding?.verified,
);

export const getOnboardingEmail = createSelector(
    getAuthState,
    (auth) => auth.onBoarding?.accountDetails?.email,
);

export const getOnBoardingAccountDetails = createSelector(
    getAuthState,
    (auth) => auth.onBoarding?.accountDetails,
);

export const isOrgValid = createSelector(
    getAuthState,
    (auth) => auth.onBoarding?.orgValid,
);

export const getOrganizationCreationErrors = createSelector(
    getAuthState,
    (auth) => coerceIntoErrors(auth.errors.signup) || '',
);

export const getUserCreateErrors = createSelector(
    getAuthState,
    (auth) => coerceIntoErrors(auth.errors.userCreateErrors) || [],
);

export const getPasswordResetReturnURL = createSelector(
    getAuthState,
    (auth) => auth.passwordResetReturnURL,
);

export const getAutofillEmail = createSelector(
    getAuthState,
    (auth) => auth.onBoarding?.accountDetails?.email,
);

export const getSSOConfig = createSelector(getAuthState, (auth) => auth.sso);

export const getSSOReturnLocation = createSelector(
    getAuthState,
    (auth) => auth.sso?.returnLocation,
);

export const hasOrgSignupInfo = createSelector(
    getAuthState,
    (auth) =>
        auth.onBoarding?.accountDetails?.email &&
        auth.onBoarding?.accountDetails?.organization &&
        auth.onBoarding?.accountDetails?.username,
);
