const styles = (theme) => ({
    root: {
        padding: '0 96px',
    },
    subtitle: {
        fontSize: 23,
        lineHeight: 1,
        fontWeight: 400,
        color: theme.custom.grey60,
    },
    relative: {
        position: 'relative',
    },
});

export default styles;
