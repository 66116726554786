export default (theme) => ({
    root: {},
    input: {},
    charsLeft: {
        marginTop: '5px',
        marginBottom: '10px',
        fontSize: '85%',
        color: '#737373',
    },
});
