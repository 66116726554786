import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    container: ({ backgroundImage }) => ({
        width: '250px',
        height: '200px',
        margin: '5px 0px',
        color: theme.palette.common.white,
        backgroundSize: 'cover',
        borderRadius: '3px',
        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundImage})`,
    }),
    phraseText: {
        fontWeight: '400',
        fontSize: '16px',
        textAlign: 'center',
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    valueText: {
        fontWeight: '700',
        fontSize: '36px',
        textAlign: 'center',
    },
}));
