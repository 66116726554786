const styles = (theme) => ({
    textLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        margin: '20px 0 10px',
        fontSize: '30px',
        fontWeight: 600,
        color: theme.custom.textDarkColor,
    },
    textValue: {
        marginLeft: '10px',
        fontSize: '65%',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
});

export default styles;
