import { createSelector } from 'reselect';
import sumBy from 'lodash/sumBy';
import { coerceIntoErrors } from '../common/errors';
import {
    transformActivity,
    transformChallenge,
    transformLeaderboardItems,
    transformUsers,
} from './transforms';
import {
    prettyFormatNumber,
    transformImpactStatsToTotals,
} from '../common/transforms';
import { transformActivities } from '../activities/transforms';
import moment from 'moment';
import {
    CHALLENGE_PARTICIPATION_JOINED_STATUS,
    CHALLENGE_PARTICIPATION_UN_JOINED_STATUS,
} from './constants';

export const getViewChallengeState = (state) => state.viewChallenge;

export const isChallengeLoading = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.isChallengeLoading,
);

export const getChallengeErrors = createSelector(
    getViewChallengeState,
    (viewChallenge) => coerceIntoErrors(viewChallenge.challengeErrors),
);

export const isLeaderboardLoading = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.isLeaderboardLoading,
);

export const getLeaderboardErrors = createSelector(
    getViewChallengeState,
    (viewChallenge) => coerceIntoErrors(viewChallenge.leaderboardErrors),
);

export const getSearchedChallenges = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.challenges?.searchResult,
);

export const isChallengesLoading = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.challenges?.isLoading,
);

export const getChallenge = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.challenge,
);

export const getChallengePaginatedActions = createSelector(
    getChallenge,
    (challenge) => challenge && challenge?.actions,
);

export const getChallengeName = createSelector(
    getChallenge,
    (challenge) => challenge && challenge.name,
);

export const getChallengeStartDate = createSelector(
    getChallenge,
    (challenge) => challenge && new Date(Date.parse(challenge.startDate)),
);

export const getChallengeEndDate = createSelector(
    getChallenge,
    (challenge) => challenge && new Date(Date.parse(challenge.endDate)),
);

export const getChallengeId = createSelector(
    getChallenge,
    (challenge) => challenge?.id,
);

export const isChallengeEditable = createSelector(
    getChallenge,
    (challenge) => challenge && challenge.isEditable,
);

export const getChallengeParticipationStatus = createSelector(
    getChallenge,
    (challenge) => challenge && challenge.participationStatus,
);

export const getChallengeTemplateStatus = createSelector(
    getChallenge,
    (challenge) => challenge?.challengeTemplateStatus,
);

export const getHeadlinePhotoUrl = createSelector(
    getChallenge,
    (challenge) =>
        challenge &&
        challenge.headlinePhoto &&
        challenge.headlinePhoto.photoSizes &&
        challenge.headlinePhoto.photoSizes.length > 0 &&
        challenge.headlinePhoto.photoSizes[
            challenge.headlinePhoto.photoSizes.length - 1
        ].url,
);

export const getHeadlinePhotoAuthor = createSelector(
    getChallenge,
    (challenge) => challenge?.headlinePhoto?.photoAuthorName,
);

export const getChallengeIconUrl = createSelector(
    getChallenge,
    (challenge) =>
        challenge &&
        challenge.imageSizes &&
        challenge.imageSizes.length > 0 &&
        challenge.imageSizes[challenge.imageSizes.length - 1].url,
);

export const getChallengeUnit = createSelector(
    getChallenge,
    (challenge) => challenge && challenge.challengeUnit,
);

export const getChallengeScoringType = createSelector(
    getChallenge,
    (challenge) => challenge && challenge.scoringType,
);

export const getLeaderboard = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.leaderboard,
);

export const getLeaderboardItems = createSelector(
    getLeaderboard,
    (leaderboard) =>
        leaderboard && transformLeaderboardItems(leaderboard, true),
);

export const getSecondaryLeaderboard = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.UserLeaderboard || [],
);

export const getSecondaryLeaderboardItems = createSelector(
    getSecondaryLeaderboard,
    (leaderboard) =>
        leaderboard && transformLeaderboardItems(leaderboard, true),
);

export const getTeamItems = createSelector(
    getLeaderboard,
    (leaderboard) => leaderboard && transformLeaderboardItems(leaderboard),
);

export const getChallengeTeams = createSelector(
    getChallenge,
    (challenge) => challenge?.teams,
);

export const getChallengeTeamsInfo = createSelector(
    getChallengeTeams,
    (teams) =>
        teams?.map((team) => ({
            id: team.id,
            name: team.name,
            headline: team.description || '',
            imageSizes: team.imageSizes,
            image: team.imageSizes?.[0]?.url,
            joined: team.participationStatus === 'joined',
        })),
);

export const getChallengeImpactItems = createSelector(
    getChallenge,
    (challenge) =>
        transformImpactStatsToTotals(challenge?.impactStats?.impactStatTypes),
);

export const getChallengeActivityTotals = createSelector(
    getChallenge,
    (challenge) =>
        challenge && {
            points: challenge.score || 0,
            logs: challenge.buzzes || 0,
            actions: challenge.pinTotal || 0,
            badges: challenge.badgeTotal || 0,
            members: challenge.membersCount || 0,
        },
);

export const getChallengeInfo = createSelector(
    getChallenge,
    getChallengePaginatedActions,
    (challenge, actions) => transformChallenge(challenge, actions) || {},
);

export const getGraphData = createSelector(
    getViewChallengeState,
    (challenge) => challenge.graphData,
);

export const getGraphDataLoading = createSelector(
    getViewChallengeState,
    (challenge) => challenge.isGraphDataLoading,
);

export const getGraphDataErrors = createSelector(
    getViewChallengeState,
    (challenge) => coerceIntoErrors(challenge.graphDataErrors),
);

export const getActivityTab = createSelector(
    getViewChallengeState,
    ({ analyticsActivity }) => {
        if (!analyticsActivity) return [];

        const sumByTotal = prettyFormatNumber(
            sumBy(analyticsActivity, 'total'),
        );
        const sumByPoints = prettyFormatNumber(
            sumBy(analyticsActivity, 'points'),
        );
        const sumByActionEarned = prettyFormatNumber(
            sumBy(analyticsActivity, 'actionEarned'),
        );

        return [
            {
                id: 'activity-tab-0',
                title: 'Total Logs',
                info:
                    "Total Logs shows the total number of times any Action was performed by all of your community's users within the selected time period.",
                ...sumByTotal,
            },
            {
                id: 'activity-tab-1',
                title: 'Points',
                info:
                    "Points shows the total number of points earned by all of your community's users within the selected time period.",
                ...sumByPoints,
            },
            {
                id: 'activity-tab-2',
                title: 'Actions Mastered',
                info:
                    "Actions Mastered shows the total number of Actions that were mastered by all of your community's users within the selected time period.",
                ...sumByActionEarned,
            },
            {
                id: 'activity-tab-3',
                title: 'Unique Actions',
                info:
                    "Unique actions show how many unique types of Actions were performed by all of your community's users within the selected time period.",
                value: analyticsActivity.length,
            },
        ];
    },
);

export const getUsersTab = createSelector(
    getViewChallengeState,
    ({ analyticsUsers }) =>
        analyticsUsers
            ? [
                  {
                      id: 'users-tab-0',
                      title: 'Active Users',
                      info:
                          'Active Users shows the total number of unique users who were seen within the selected time period.',
                      value: analyticsUsers.activeUsers,
                  },
                  {
                      id: 'users-tab-1',
                      title: 'Logs per User',
                      info:
                          'Logs per User shows the average number of Actions performed by a single user within the selected time period. It is derived by dividing the total number of Logs by the total number of Active Users.',
                      value: analyticsUsers.avgActions.toFixed(2),
                  },
                  {
                      id: 'users-tab-2',
                      title: 'Logs per User per Day',
                      info:
                          'Logs per User per Day shows the average number of Actions performed by a single user on an average day within the selected time period. It is derived by dividing the total number of Logs per Active Users divided by the number of days in the selected time period.',
                      value: analyticsUsers.avgActionsPerDay.toFixed(2),
                  },
              ]
            : [],
);

export const getTopUsers = createSelector(
    getViewChallengeState,
    ({ analyticsUsers }) => transformUsers(analyticsUsers),
);

export const getAnalyticsUsersErrors = createSelector(
    getViewChallengeState,
    (challenge) => challenge.analyticsUsersErrors,
);

export const getAnalyticsUsersLoading = createSelector(
    getViewChallengeState,
    (challenge) => challenge.isAnalyticsUsersLoading,
);

export const getTopActions = createSelector(
    getViewChallengeState,
    ({ analyticsActivity }) => transformActivity(analyticsActivity),
);

export const getAnalyticsActivityErrors = createSelector(
    getViewChallengeState,
    (challenge) => challenge.analyticsActivityErrors,
);

export const getAnalyticsActivityLoading = createSelector(
    getViewChallengeState,
    (challenge) => challenge.isAnalyticsActivityLoading,
);

export const getChallengeImpactStats = createSelector(
    getViewChallengeState,
    (challenge) =>
        transformImpactStatsToTotals(challenge.analyticsImpact) || [],
);

export const getAnalyticsImpactErrors = createSelector(
    getViewChallengeState,
    (challenge) => challenge.analyticsImpactErrors,
);

export const getAnalyticsImpactLoading = createSelector(
    getViewChallengeState,
    (challenge) => challenge.isAnalyticsImpactLoading,
);

export const getTopPhotos = createSelector(
    getViewChallengeState,
    (challenge) => challenge.analyticsPhotos,
);

export const getTopPhotosErrors = createSelector(
    getViewChallengeState,
    (challenge) => challenge.analyticsPhotosErrors,
);

export const getTopsPhotosLoading = createSelector(
    getViewChallengeState,
    (challenge) => challenge.isAnalyticsPhotosLoading,
);

export const getPenalties = createSelector(
    getViewChallengeState,
    (challenge = {}) => challenge.penalties,
);

export const isPenaltiesLoading = createSelector(
    getViewChallengeState,
    (challenge = {}) => challenge.penaltiesLoading,
);

export const isPenaltiesActionLoading = createSelector(
    getViewChallengeState,
    (challenge = {}) => challenge.penaltiesActions.loading,
);

export const getPenaltiesActionsResponse = createSelector(
    getViewChallengeState,
    (challenge = {}) => challenge.penaltiesActions.response,
);

export const getPenaltiesActionsErrors = createSelector(
    getViewChallengeState,
    (challenge = {}) => challenge.penaltiesActions.errors,
);

export const getChallengeCreationState = createSelector(
    getViewChallengeState,
    (viewChallengeState = {}) => viewChallengeState.creation,
);

export const isChallengeCreationRequested = createSelector(
    getChallengeCreationState,
    (creationState = {}) => creationState.isChallengeCreationRequested,
);

export const getChallengeCreationErrors = createSelector(
    getChallengeCreationState,
    (creationState = {}) =>
        coerceIntoErrors(creationState.challengeCreationErrors) || [],
);

export const getCreatedChallenge = createSelector(
    getChallengeCreationState,
    (creationState = {}) => creationState.createdChallenge,
);

export const isChallengeCreationLoading = createSelector(
    getChallengeCreationState,
    (creationState = {}) => creationState.loading,
);

export const getTeamDetailsState = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.teamDetails,
);

export const areTeamDetailsLoading = createSelector(
    getTeamDetailsState,
    (teamDetails) => teamDetails.loading,
);

export const getTeamDetailsErrors = createSelector(
    getTeamDetailsState,
    (teamDetails) => coerceIntoErrors(teamDetails.errors),
);

export const getTeam = createSelector(
    getTeamDetailsState,
    (teamDetails) => teamDetails.team,
);

export const getTeamDetails = createSelector(getTeam, (team) =>
    team
        ? {
              id: team.id,
              name: team.name,
              headline: team.description,
              image: team.imageSizes?.[0]?.url,
              impactTotals: transformImpactStatsToTotals(
                  team.impactStats?.impactStatTypes,
              ),
              activityTotals: {
                  points: team.score || 0,
                  logs: team.buzzes || 0,
                  actions: team.pinTotal || 0,
                  badges: team.badgeTotal || 0,
                  members: team.membersCount || 0,
              },
          }
        : null,
);

export const getParticipationStatus = createSelector(
    getTeam,
    (team) => team.participationStatus,
);

export const getTeamLeaderboardState = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.teamLeaderboard,
);

export const isTeamLeaderboardLoading = createSelector(
    getTeamLeaderboardState,
    (teamLeaderboard) => teamLeaderboard.loading,
);

export const getTeamLeaderboardErrors = createSelector(
    getTeamLeaderboardState,
    (teamLeaderboard) => coerceIntoErrors(teamLeaderboard.errors),
);

export const getTeamLeaderboard = createSelector(
    getTeamLeaderboardState,
    (teamLeaderboard) => teamLeaderboard.leaderboard,
);

export const getTeamLeaderboardItems = createSelector(
    getTeamLeaderboard,
    (leaderboard) =>
        leaderboard?.map(({ item, statusInfoTitle = null }) => {
            return {
                ...item,
                disabled: statusInfoTitle && statusInfoTitle === 'Not Joined',
            };
        }),
);

export const getPresentableTeamLeaderboardItems = createSelector(
    getTeamLeaderboardItems,
    (items) =>
        items?.map(({ id, name, score = 0, imageSizes, ...rest }) => ({
            id,
            label: name,
            value: score,
            image: imageSizes?.[0]?.url,
            ...rest,
        })),
);

export const getTeamActivitiesState = createSelector(
    getViewChallengeState,
    (viewChallenge) => viewChallenge.teamActivities,
);

export const areTeamActivitiesLoading = createSelector(
    getTeamActivitiesState,
    (teamActivities) => teamActivities.loading,
);

export const getTeamActivitiesErrors = createSelector(
    getTeamActivitiesState,
    (teamActivities) => coerceIntoErrors(teamActivities.errors),
);

export const getTeamActivities = createSelector(
    getTeamActivitiesState,
    (teamActivities) => teamActivities.activities,
);

export const getTeamActivityItems = createSelector(
    getTeamActivities,
    (activities) => transformActivities(activities),
);

export const getAllChallenges = createSelector(
    getViewChallengeState,
    (challenge) => {
        return challenge?.challenges?.challenges?.map((challenge) => ({
            id: challenge?.id,
            backgroundImageUrl: challenge?.photos?.[0]?.photoSizes?.[0]?.url,
            iconUrl: challenge?.imageSizes?.[0]?.url,
            name: challenge?.name,
            teams: challenge?.teams,
            members: challenge?.members,
            startDate: challenge?.startDate,
            endDate: challenge?.endDate,
            isOrgChallenge: challenge?.isOrgChallenge,
            participationStatus: challenge?.participationStatus,
        }));
    },
);
export const getAvailableChallenges = createSelector(
    getAllChallenges,
    (allChallenges) => {
        const currentDate = moment();
        return allChallenges?.filter(
            (challenge) =>
                moment(challenge.endDate).unix() > currentDate.unix() &&
                challenge.participationStatus ===
                    CHALLENGE_PARTICIPATION_UN_JOINED_STATUS,
        );
    },
);
export const getUserSubscribedChallenges = createSelector(
    getAllChallenges,
    (allChallenges) => {
        const currentDate = moment();
        return allChallenges?.filter(
            (challenge) =>
                moment(challenge.endDate).unix() > currentDate.unix() &&
                challenge.participationStatus ===
                    CHALLENGE_PARTICIPATION_JOINED_STATUS,
        );
    },
);
export const getUserPreviousChallenges = createSelector(
    getAllChallenges,
    (allChallenges) => {
        const currentDate = moment();
        return allChallenges?.filter((challenge) => {
            return currentDate.unix() > moment(challenge.endDate).unix();
        });
    },
);

const getChallengeShareURLState = createSelector(
    getViewChallengeState,
    (viewChallenge) => {
        return viewChallenge.challenge?.id ===
            viewChallenge.shareURL.challengeId
            ? viewChallenge.shareURL
            : {};
    },
);

export const getChallengeShareURL = createSelector(
    getChallengeShareURLState,
    (shareURL) => shareURL.url,
);

export const isChallengeShareURLLoading = createSelector(
    getChallengeShareURLState,
    (shareURL) => shareURL.loading,
);

export const getChallengeShareURLErrors = createSelector(
    getChallengeShareURLState,
    (shareURL) => shareURL.errors,
);

const getChallengeAdminShareImageURLState = createSelector(
    getViewChallengeState,
    (viewChallenge) => {
        return viewChallenge.challenge?.id ===
            viewChallenge.adminShareImageURL.challengeId
            ? viewChallenge.adminShareImageURL
            : {};
    },
);

export const getChallengeAdminShareImageURL = createSelector(
    getChallengeAdminShareImageURLState,
    (shareURL) => shareURL.url,
);

export const isChallengeAdminShareImageURLLoading = createSelector(
    getChallengeAdminShareImageURLState,
    (shareURL) => shareURL.loading,
);

export const getChallengeAdminShareImageURLErrors = createSelector(
    getChallengeAdminShareImageURLState,
    (shareURL) => shareURL.errors,
);

export const getChallengeThemeName = createSelector(
    getChallenge,
    (challenge) => challenge.themeName,
);
