import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { Notifications, Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import styles from '../styles';

import Leaderboard from '../../../common/Leaderboard';

import {
    challengeLeaderboardRequested,
    removeUserRequested,
} from '../../../challenges/actions';
import {
    getChallengeScoringType,
    getChallengeUnit,
    getLeaderboardErrors,
    getLeaderboardItems,
    getSecondaryLeaderboardItems,
    isLeaderboardLoading,
    isPenaltiesActionLoading,
} from '../../../challenges/selectors';
import CommonPropTypes from '../../../common/propTypes';
import { getActiveCommunityId, getUser } from '../../../user/selectors';
import { getAppImages, noop } from '../../../utils';
import { openCreateNotification } from '../../../notifications/actions';
import {
    AUDIENCE_VALUE_CHALLENGE_TEAMS,
    AUDIENCE_VALUE_USERS,
} from '../../../notifications/constants';
import TabbedSection from '../../../common/TabbedSection';
import { AbilityContext } from '../../../casl/ability-context';
import { getApplicationStrings } from '../../../init/selectors';
import ConfirmModal from '../../../common/ConfirmModal';
import InfoTooltip from '../../../common/InfoTooltip';
import Delete from '../../../common/Delete';

const TEAM = 'Team';
const USER = 'User';

const Errors = ({ errors }) => (
    <>
        {errors.map((err, i) => (
            <Typography key={i}>{err}</Typography>
        ))}
    </>
);

const ChallengeLeaderboard = ({
    classes,
    challengeId,
    challengeName,
    communityId,
    loadFunc,
    isLoading,
    errors,
    unit,
    mainTabItems,
    t,
    secondaryTabItems,
    onNotificationClick,
    user,
    scoringType,
    appStrings,
    onRemove,
    removeLoading,
}) => {
    const history = useHistory();
    const ability = useContext(AbilityContext);

    const TEAMS_LABEL = t('leaderboard.teams');
    const USERS_LABEL = t('user_plural');
    const SEND_NOTIFICATION_LABEL = t('leaderboard.sendNotification');
    const VIEW_USER_LABEL = t('leaderboard.viewUser');

    const [scoreExplanationTitle, setScoreExplanationTitle] = useState('');
    const [scoreExplanation, setScoreExplanation] = useState('');
    const [scoreModalOpen, setScoreModalOpen] = useState(false);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [removeRequested, setRemoveRequested] = useState(false);

    const canManageMasterNotifications = ability.can(
        'manage',
        'MasterNotifications',
    );

    const canRemoveChallengeUser = ability.can('remove', 'ChallengeUser');

    const getTeamScoreExplanation = useCallback(() => {
        let explanation;
        scoringType === 'movingAverage'
            ? (explanation =
                  appStrings?.challenge?.scoreModals?.team?.movingAverage
                      ?.leaderboard)
            : (explanation =
                  appStrings?.challenge?.scoreModals?.team?.basicAggregate
                      ?.leaderboard);
        return explanation;
    }, [appStrings, scoringType]);

    const loadTeams = useCallback(
        (request = {}) => {
            loadFunc({ challengeId, communityId, ...request });
        },
        [challengeId, communityId, loadFunc],
    );

    // If we have requested a remove, set the removeRequested flag to true
    useEffect(() => {
        if (!removeRequested) {
            setRemoveRequested(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeLoading]);

    // Reload the leaderboard once we know the remove action has completed since we don't get anything in the response
    useEffect(() => {
        if (!removeLoading && removeRequested) {
            loadTeams();
        }
    }, [loadTeams, removeLoading, removeRequested]);

    useEffect(() => {
        loadTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUserScoreTooltipClick = useCallback(() => {
        setScoreExplanationTitle(
            appStrings?.challenge?.scoreModals?.user?.scoreTitle,
        );
        setScoreExplanation(
            appStrings?.challenge?.scoreModals?.user?.leaderboard,
        );
        setScoreModalOpen(true);
    }, [appStrings]);

    const handleTeamScoreTooltipClick = useCallback(() => {
        setScoreExplanationTitle(
            appStrings?.challenge?.scoreModals?.team?.scoreTitle,
        );
        setScoreExplanation(getTeamScoreExplanation());
        setScoreModalOpen(true);
    }, [appStrings, getTeamScoreExplanation]);

    const handleTeamItemClick = useCallback(
        (id) => {
            history.push(`/challenges/${challengeId}/team/${id}`);
        },
        [history, challengeId],
    );

    const handleNotificationIconClick = useCallback(
        (event, itemId, items, notifyUnit) => {
            const item = items.find(({ id }) => id === itemId);
            onNotificationClick({
                subjectContentID: challengeId,
                subjectContentType: 'challenge',
                audienceType:
                    notifyUnit === 'Team'
                        ? AUDIENCE_VALUE_CHALLENGE_TEAMS
                        : AUDIENCE_VALUE_USERS,
                audiences: [item],
                subject: challengeName,
            });
        },
        [challengeId, challengeName, onNotificationClick],
    );

    const handleViewIconClick = useCallback(
        (id) => {
            user.id === id
                ? history.push('/profile/self')
                : history.push(`/profile/${id}`);
        },
        [history, user],
    );

    const tab1Title = unit === TEAM ? TEAMS_LABEL : USERS_LABEL;

    const handleRemove =
        canRemoveChallengeUser &&
        ((userId) => {
            setDeleteModalOpen(true);
            setDeleteId(userId);
        });

    const leaderboardCardProps = {
        defaultImageSrc: getAppImages().defaultProfileIcon,
        imageIsRounded: true,
        hideRanks: false,
        ViewIcon: unit === USER ? Visibility : null,
        onItemClick:
            unit === TEAM
                ? handleTeamItemClick
                : (itemId) => handleViewIconClick(itemId),
        viewIconTooltip: VIEW_USER_LABEL,
        iconTooltipPlacement: 'top',
        showId: false,
        onRemove: unit === USER && handleRemove,
    };
    if (canManageMasterNotifications) {
        leaderboardCardProps.showId = unit === USER;
        leaderboardCardProps.Icon = Notifications;
        leaderboardCardProps.iconTooltip = SEND_NOTIFICATION_LABEL;
        leaderboardCardProps.onIconClick = (event, itemId) =>
            handleNotificationIconClick(event, itemId, mainTabItems, unit);
    }

    const MainTab = (
        <>
            {unit === TEAM ? (
                <Typography className={classes.scoringExplanationText}>
                    {appStrings?.challenge?.scoreModals?.team?.scoreTitle}
                    <InfoTooltip
                        onClick={handleTeamScoreTooltipClick}
                        text={t('learnMore')}
                        intercomTarget="teamScoreExplanation"
                    />
                </Typography>
            ) : (
                <Typography className={classes.scoringExplanationText}>
                    {appStrings?.challenge?.scoreModals?.user?.scoreTitle}
                    <InfoTooltip
                        onClick={handleUserScoreTooltipClick}
                        text={t('learnMore')}
                        intercomTarget="userScoreExplanation"
                    />
                </Typography>
            )}
            <Leaderboard
                loadFunc={(request) => loadTeams(request)}
                isLoading={isLoading}
                items={mainTabItems}
                leaderboardCardProps={leaderboardCardProps}
                unit={unit}
            />
        </>
    );
    const tabs = {
        [tab1Title]: MainTab,
    };
    if (unit === TEAM) {
        const teamLeaderboardCardProps = {
            showId: true,
            ViewIcon: Visibility,
            onItemClick: noop,
            ...leaderboardCardProps,
            onRemove: handleRemove,
        };

        if (canManageMasterNotifications) {
            teamLeaderboardCardProps.onIconClick = (event, itemId) =>
                handleNotificationIconClick(
                    event,
                    itemId,
                    secondaryTabItems,
                    USER,
                );
        }

        tabs[USERS_LABEL] = (
            <>
                <Typography className={classes.scoringExplanationText}>
                    {appStrings?.challenge?.scoreModals?.user?.scoreTitle}
                    <InfoTooltip
                        onClick={handleUserScoreTooltipClick}
                        text={t('learnMore')}
                        intercomTarget="userScoreExplanation"
                    />
                </Typography>
                <Leaderboard
                    loadFunc={(request) =>
                        loadFunc({
                            challengeId,
                            communityId,
                            challengeUnit: USER,
                            ...request,
                        })
                    }
                    isLoading={isLoading}
                    items={secondaryTabItems || []}
                    leaderboardCardProps={teamLeaderboardCardProps}
                    unit={unit}
                />
            </>
        );
    }

    const renderLeaderboard = () => {
        return unit === TEAM ? (
            <TabbedSection
                onTabChange={(tab) => {
                    if (tab === 1) {
                        loadFunc({
                            challengeId,
                            communityId,
                            challengeUnit: USER,
                        });
                    }
                }}
                tabs={tabs}
            />
        ) : (
            MainTab
        );
    };

    const getItem = () => {
        let item = mainTabItems?.find((i) => i.id === deleteId);
        if (unit === TEAM) {
            item = secondaryTabItems?.find((i) => i.id === deleteId);
        }
        return {
            id: item?.id,
            name: item?.label,
            iconUrl: item?.image,
        };
    };

    return (
        <>
            {errors && <Errors errors={errors} />}
            {!errors && renderLeaderboard()}
            <ConfirmModal
                cancelBtnHidden
                confirmBtnText={t('Ok')}
                title={scoreExplanationTitle}
                isOpen={scoreModalOpen}
                onConfirm={() => setScoreModalOpen(false)}
                onClose={() => setScoreModalOpen(false)}
            >
                <Typography class={classes.scoringExplanationText}>
                    {scoreExplanation}
                </Typography>
            </ConfirmModal>
            <Delete
                title={t('removeChallengeUserModal.title')}
                isOpen={deleteModalOpen}
                confirmMessage={t('removeChallengeUserModal.header')}
                description={t('removeChallengeUserModal.description')}
                onClose={() => {
                    setDeleteModalOpen(false);
                    setDeleteId(null);
                }}
                onCancel={() => {
                    setDeleteModalOpen(false);
                    setDeleteId(null);
                }}
                onConfirm={() => {
                    onRemove(challengeId, deleteId, communityId);
                    setDeleteModalOpen(false);
                    setDeleteId(null);
                }}
                item={getItem()}
                confirmBtnText={t('remove')}
            />
        </>
    );
};

ChallengeLeaderboard.propTypes = {
    classes: PropTypes.object.isRequired,
    challengeId: PropTypes.string.isRequired,
    challengeName: PropTypes.string.isRequired,
    communityId: PropTypes.string.isRequired,
    loadFunc: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    unit: PropTypes.string,
    mainTabItems: PropTypes.array,
    secondaryTabItems: PropTypes.array,
    t: PropTypes.func.isRequired,
    onNotificationClick: PropTypes.func,
    user: PropTypes.object.isRequired,
    appStrings: PropTypes.object,
    scoringType: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    removeLoading: PropTypes.bool,
};

ChallengeLeaderboard.defaultProps = {
    errors: null,
    unit: null,
    mainTabItems: null,
    secondaryTabItems: null,
    onNotificationClick: noop,
    appStrings: {},
    onRemove: null,
    removeLoading: false,
};

const StyledChallengeLeaderboard = withStyles(styles)(ChallengeLeaderboard);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    isLoading: isLeaderboardLoading(state),
    removeLoading: isPenaltiesActionLoading(state),
    errors: getLeaderboardErrors(state),
    unit: getChallengeUnit(state),
    mainTabItems: getLeaderboardItems(state),
    secondaryTabItems: getSecondaryLeaderboardItems(state),
    user: getUser(state),
    appStrings: getApplicationStrings(state),
    scoringType: getChallengeScoringType(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadFunc: (request) => dispatch(challengeLeaderboardRequested(request)),
    onNotificationClick: (notification) =>
        dispatch(openCreateNotification(notification)),
    onRemove: (challengeId, userId, communityId) =>
        dispatch(removeUserRequested({ challengeId, userId, communityId })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledChallengeLeaderboard);
