import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from '../../InfiniteScroller';
import ActivityCard from '../index';
import ActivityCardLoader from '../ActivityCardLoader';
import useStyles from './styles';
import SnackBarAPIResponse from '../../SnackbarAlert/SnackBarAPIResponse';
import { noop } from '../../../utils';
import { connect } from 'react-redux';
import { getErrors, getMessages } from '../../../activities/selectors';
import {
    getErrors as getFlagErrors,
    getMessages as getFlagMessages,
} from '../../../flags/selectors';
import { clearActivityErrors } from '../../../activities/actions';
import { clearFlagMessages } from '../../../flags/actions';
import { Grid } from '@material-ui/core';

export const ActivityCardScroller = ({
    loading,
    errors,
    messages,
    loadFunc,
    items,
    clearErrors,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container item style={{ maxWidth: '100%' }}>
            <InfiniteScroller
                isLoading={loading}
                loadFunc={loadFunc}
                renderFunc={(item, index) => (
                    <ActivityCard
                        key={`${item.label}-${index}`}
                        classes={{ container: classes.cardContainer }}
                        activity={item}
                    />
                )}
                items={items}
                listEmptyText={t('activity.empty')}
                Loader={
                    <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ActivityCardLoader />
                        <ActivityCardLoader />
                    </Grid>
                }
            />
            <SnackBarAPIResponse
                messages={messages}
                errors={errors}
                clearResponse={clearErrors}
            />
        </Grid>
    );
};

ActivityCardScroller.propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    messages: PropTypes.array.isRequired,
    loadFunc: PropTypes.func.isRequired,
    items: PropTypes.object.isRequired,
    clearErrors: PropTypes.func,
};

ActivityCardScroller.defaultProps = {
    errors: [],
    messages: [],
    flagErrors: [],
    flagMessages: [],
    clearErrors: noop,
};

const mapStateToProps = (state) => ({
    errors: [...(getErrors(state) || []), ...(getFlagErrors(state) || [])],
    messages: [...getMessages(state), ...getFlagMessages(state)],
});

const mapDispatchToProps = (dispatch) => ({
    clearErrors: () => {
        dispatch(clearActivityErrors());
        dispatch(clearFlagMessages());
    },
});

const ConnectedActivityCardScroller = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActivityCardScroller);

export default ConnectedActivityCardScroller;
