import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import styles from './styles';
import { getTimezoneAbbrev } from '../../../utils/dates';
import { noop } from '../../../utils';
import { useTranslation } from 'react-i18next';
import HeaderPage from '../../HeaderPage';

const TopSection = ({
    classes,
    title,
    handleBack,
    redirectLink,
    intercomTargetPrefix,
}) => {
    const { t } = useTranslation();

    return (
        <Grid container direction="column">
            <Grid item>
                <IconButton
                    onClick={handleBack}
                    classes={{ root: classes.backIcon }}
                >
                    <ArrowBack aria-label={t('alt.backButton')} />
                </IconButton>
            </Grid>
            <HeaderPage title={title} helpLink={redirectLink} />
            <Grid item>
                <Typography variant="h4" className={classes.timezone}>
                    {`(${getTimezoneAbbrev()})`}
                </Typography>
            </Grid>
        </Grid>
    );
};

TopSection.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    handleBack: PropTypes.func,
    redirectLink: PropTypes.string,
    intercomTargetPrefix: PropTypes.string,
};

TopSection.defaultProps = {
    title: '',
    handleBack: noop,
    redirectLink: '',
    intercomTargetPrefix: '',
};

export default withStyles(styles)(TopSection);
