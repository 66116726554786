import PropTypes from 'prop-types';
import UploadPropTypes from '../Upload/propTypes';

export const photo = {
    ...UploadPropTypes.upload,
    classes: PropTypes.object.isRequired,
    photo: PropTypes.string,
    setCroppedImageData: PropTypes.func.isRequired,

    // See https://www.npmjs.com/package/react-image-crop
    CropProps: PropTypes.object,
    initCrop: PropTypes.object,
    intercomTarget: PropTypes.string,
};

export default {
    photo,
};
