import {
    CHALLENGES_REQUEST_FAILED,
    CHALLENGES_REQUEST_SUCCEEDED,
    CHALLENGES_REQUESTED,
} from '../../challenges/actions';
import { GROUPS } from './constants';

const initialState = {
    ...GROUPS.reduce(
        (acc, group) => ({
            ...acc,
            [group]: {
                isLoading: false,
                errors: null,
                challenges: null,
            },
        }),
        {},
    ),
};

export default function challengesReducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case CHALLENGES_REQUESTED:
            return {
                ...state,
                [payload.group]: {
                    isLoading: true,
                },
            };
        case CHALLENGES_REQUEST_SUCCEEDED:
            return {
                ...state,
                [payload.group]: {
                    isLoading: false,
                    errors: [],
                    challenges: payload.challenges,
                },
            };
        case CHALLENGES_REQUEST_FAILED:
            return {
                ...state,
                [payload.group]: {
                    isLoading: false,
                    errors: payload.errors,
                },
            };
        default:
            return state;
    }
}
