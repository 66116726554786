import React from 'react';
import useStyles from './styles';
import { Grid, ListSubheader, MenuItem, Typography } from '@material-ui/core';
import FormDropdown from '../FormDropdown';
import PropTypes from 'prop-types';
import { noop } from '../utils';

const CategoryDropdown = ({
    value,
    onChange,
    placeholder,
    themes,
    ...props
}) => {
    const classes = useStyles();
    return (
        <FormDropdown
            onChange={onChange}
            value={value}
            fullWidth
            select
            placeholder={placeholder}
            items={themes?.flatMap((theme) =>
                theme.categories.map((category) => ({
                    label: category.name,
                    value: category.id,
                })),
            )}
            renderItems={() =>
                themes?.map((theme) => [
                    <ListSubheader disableSticky key={theme.name}>
                        <Grid item container>
                            <img
                                src={theme.iconUrl}
                                alt="theme icon"
                                className={classes.categoryIcon}
                            />
                            <Typography>{theme.name}</Typography>
                        </Grid>
                    </ListSubheader>,
                    theme.categories?.map((c) => (
                        <MenuItem key={c.name} value={c.id}>
                            <Grid item container className={classes.selectItem}>
                                <img
                                    src={c.iconUrl}
                                    alt="category icon"
                                    className={classes.categoryIcon}
                                />
                                <Typography>{c.name}</Typography>
                            </Grid>
                        </MenuItem>
                    )),
                ])
            }
        />
    );
};

CategoryDropdown.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    themes: PropTypes.array,
};

CategoryDropdown.defaultProps = {
    value: '',
    onChange: noop,
    placeholder: '',
    themes: [],
};
export default CategoryDropdown;
