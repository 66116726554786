import DEFAULT_PROPERTIES from './default';

const CAPGROUP_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'CG Green',
    shouldShowSignUp: false,
    clientId: '60f05ed1e0b5260ecded577d',
    clientSecret:
        '035L8ZaOHhk3UCgdk0H-QQAXCEoTVXO2Kb8PDxK2_72uemIRAHDR02rJ0YxNHGvf5m',
    wordpressDomain: 'https://cggreen.joulebug.com',
    appDescription:
        'With CG Green, compete in challenges to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we strive towards common goals. ',
};

export default CAPGROUP_PROPERTIES;
