import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    ACTIVITIES_REQUESTED,
    activitiesRequestFailed,
    activitiesRequestSucceeded,
    ANALYTICS_GRAPH_DATA_REQUESTED,
    analyticsGraphDataRequestFailed,
    analyticsGraphDataRequestSucceeded,
    GRAPH_DATA_REQUESTED,
    graphDataRequestFailed,
    graphDataRequestSucceeded,
    IMPACT_STATS_REQUESTED,
    impactStatsRequestFailed,
    impactStatsRequestSucceeded,
    USERS_ANALYTIC_REQUESTED,
    usersAnalyticRequestFailed,
    usersAnalyticRequestSucceeded,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../utils/api';

export function* getActivitiesStats({ payload: { options, communityId } }) {
    try {
        const activityData = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/analytics/timeseries/summary`,
            {
                query: { ...options, summaryType: 'activity' },
            },
        );
        const activities = activityData.response.analytics[0].activities;
        yield put(activitiesRequestSucceeded(activities));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Activity Stats',
        );
        yield put(activitiesRequestFailed(errors));
    }
}

export function* getUsersAnalytic({ payload: { options, communityId } }) {
    try {
        const userData = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/analytics/timeseries/summary`,
            {
                query: { ...options, summaryType: 'users' },
            },
        );

        const usersAnalytic = userData.response.analytics[0];
        yield put(usersAnalyticRequestSucceeded(usersAnalytic));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get User Stats',
        );
        yield put(usersAnalyticRequestFailed(errors));
    }
}

export function* getImpactStats({ payload: { options, communityId } }) {
    try {
        const userData = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/analytics/impact/summary`,
            {
                query: { ...options, metric: 'buzzBaseAction' },
            },
        );
        const impacts = userData.response['impact-stats'] || [];
        yield put(impactStatsRequestSucceeded(impacts));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Impact Stats',
        );
        yield put(impactStatsRequestFailed(errors));
    }
}

export function* getGraphData({ payload: { options, communityId } }) {
    try {
        const rawGraphData = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/analytics/timeseries`,
            {
                query: options,
            },
        );
        const graphData = rawGraphData.response.analytics[0];
        yield put(graphDataRequestSucceeded(graphData, options?.metric));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Graph Data',
        );
        yield put(graphDataRequestFailed(errors));
    }
}

function* handleSingleAnalyticsRequest(payload) {
    const { options, communityId, metrics } = payload;
    const requestOptions = { ...options, metric: metrics.join(',') };
    const allMetricData = yield call(
        fetchWithAuth,
        `/kiosk/group/${communityId}/analytics/timeseries`,
        {
            query: requestOptions,
        },
    );
    const graphDataArray = allMetricData.response.analytics;
    yield put(
        analyticsGraphDataRequestSucceeded({
            newGraphData: graphDataArray,
            metrics: metrics,
        }),
    );
}

function* asyncFetchMultipleAnalyticsGraphs(payload) {
    const { options, communityId, metrics } = payload;
    const allMetricData = yield all(
        metrics.map((metric) => {
            const requestOptions = { ...options, metric };
            return call(
                fetchWithAuth,
                `/kiosk/group/${communityId}/analytics/timeseries`,
                {
                    query: requestOptions,
                },
            );
        }),
    );
    const graphDataArray = allMetricData.map((data) => {
        return { ...data.response.analytics[0] };
    });
    yield put(
        analyticsGraphDataRequestSucceeded({
            newGraphData: graphDataArray,
            metrics: metrics,
        }),
    );
}

export function* getAnalyticsGraphData({ payload }) {
    const { options, metrics } = payload;
    try {
        const { singleRequest = false } = options;
        if (singleRequest) {
            yield call(handleSingleAnalyticsRequest, payload);
        } else {
            yield call(asyncFetchMultipleAnalyticsGraphs, payload);
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get Graph Data',
        );
        yield put(analyticsGraphDataRequestFailed(errors, metrics));
    }
}

export default function* analyticsSaga() {
    yield all([
        takeLatest(ACTIVITIES_REQUESTED, getActivitiesStats),
        takeLatest(USERS_ANALYTIC_REQUESTED, getUsersAnalytic),
        takeLatest(IMPACT_STATS_REQUESTED, getImpactStats),
        takeLatest(GRAPH_DATA_REQUESTED, getGraphData),
        takeEvery(ANALYTICS_GRAPH_DATA_REQUESTED, getAnalyticsGraphData),
    ]);
}
