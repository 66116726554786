import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        width: '100%',
        borderRadius: '23px',
        border: '1px solid transparent',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '18px',
        '&:before, &:after': {
            display: 'none',
        },
        height: 46,
        justifyContent: 'center',
        paddingLeft: 10,
    },
    select: {
        '&:focus': {
            backgroundColor: theme.custom.inputBackground,
        },
    },
    selectItem: {
        paddingLeft: 10,
    },
    clear: {
        marginRight: 20,
        cursor: 'pointer',
    },
}));

export default useStyles;
