import { getProperties } from './index';

export const getSlackIntegrationLink = () => {
    const { redirectURI = null, clientId = null } =
        getProperties()?.integrationCredentials?.slack || {};
    if (redirectURI && clientId) {
        return (
            'https://slack.com/oauth/v2/authorize' +
            '?scope=incoming-webhook,commands' +
            '&user_scope=' +
            '&redirect_uri=' +
            redirectURI +
            '&client_id=' +
            clientId
        );
    }
    return null;
};
