import theme from '../../../theme';

export const COLORS = [
    theme.palette.primary.main,
    '#f1be55',
    '#5ab5c6',
    '#00b89f',
    '#876c9c',
    '#3c5991',
    '#95bbb9',
    '#afdd57',
    '#c46d41',
    '#8b3f51',
];

export const CHART_TYPE_BAR = 'bar';

export const CHART_TYPE_LINE = 'line';

export const CHART_TYPES = [CHART_TYPE_LINE, CHART_TYPE_BAR];

export const CHART_INTERVAL_DAY = 'day';
export const CHART_INTERVAL_HOUR = 'hour';
export const CHART_INTERVAL_WEEK = 'week';
export const CHART_INTERVAL_MONTH = 'month';
export const CHART_INTERVAL_YEAR = 'year';

export const CHART_INTERVALS = [
    CHART_INTERVAL_DAY,
    CHART_INTERVAL_HOUR,
    CHART_INTERVAL_WEEK,
    CHART_INTERVAL_MONTH,
    CHART_INTERVAL_YEAR,
];

export const GRAPH_METRIC_TOTAL_LOGS = 'buzzBaseAction';
export const GRAPH_METRIC_ACTIVE_USERS = 'activeUser';
export const GRAPH_METRIC_NEW_USERS = 'communityManualJoin';
export const GRAPH_METRIC_SCORE_TRACKER = 'scoreTracker';
export const GRAPH_METRIC_PHOTO_ADDED = 'addPhoto';

export const GRAPH_METRIC_IMPACT_WASTE = 'waste';
export const GRAPH_METRIC_IMPACT_WATER = 'water';
export const GRAPH_METRIC_IMPACT_CARBON = 'co2';
export const GRAPH_METRIC_IMPACT_HABITAT_SAVED = 'habitatSaved';

export const GRAPH_TYPE_SCORE_TRACKER = 'scoretracker';
export const GRAPH_TYPE_TIME_SERIES = 'timeseries';
