import DEFAULT_PROPERTIES from './default';

const JB_PUBLIC_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'JouleBug',
    clientId: '595409730b8c5839fa7059c7',
    clientSecret:
        '02EcKXRmMfwmVklnXHmli_vO-xnaWNyUOkAfj5NZISFShsrcYJDv0dEYKqLWXM1J1s',
    apiUri: process.env.REACT_APP_JB_API_URI,
    allowPassphraseLessLogin: true,
    wordpressDomain: 'https://joulebug.com',
};

export default JB_PUBLIC_PROPERTIES;
