import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    COMMUNITIES_REQUESTED,
    communitiesRequestFailed,
    communitiesRequestSucceeded,
    COMMUNITY_INVITE_LINK_REQUESTED,
    COMMUNITY_REQUESTED,
    COMMUNITY_SEARCH_REQUESTED,
    COMMUNITY_SHARE_IMAGE_REQUESTED,
    COMMUNITY_UPDATE_REQUESTED,
    communityInviteLinkRequestFailed,
    communityInviteLinkRequestSucceeded,
    communityRequestFailed,
    communityRequestSucceeded,
    communitySearchRequestFailed,
    communitySearchRequestSucceeded,
    communityShareImageRequestFailed,
    communityShareImageRequestSucceeded,
    communityUpdateRequestFailed,
    communityUpdateRequestSucceeded,
    CREATE_COMMUNITY_REQUESTED,
    createCommunityRequestFailed,
    createCommunityRequestSucceeded,
    JOIN_COMMUNITY_REQUESTED,
    joinCommunityRequestFailed,
    joinCommunityRequestSucceeded,
    UNJOIN_COMMUNITY_REQUESTED,
    unjoinCommunityRequestFailed,
    unjoinCommunityRequestSucceeded,
    UPLOAD_COMMUNITY_FILE_REQUESTED,
    uploadCommunityFileRequestFailed,
    uploadCommunityFileRequestSucceeded,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
    uploadWithAuth,
} from '../utils/api';
import i18next from 'i18next';
import {
    otherCommunitiesRequested,
    updateUserCommunities,
} from '../user/actions';
import i18n from '../localization/i18n';
import { push } from 'connected-react-router';
import { getCurrentUser } from '../user/saga';
import { getActiveCommunityId } from '../user/selectors';
import { transformCommunityEditRequest } from './transforms';

export function* getCommunity(action) {
    try {
        const {
            payload: { communityId },
        } = action;
        const data = yield call(fetchWithAuth, `/community/${communityId}`, {
            query: {
                communityId,
            },
        });
        const community = data?.response?.communities[0];
        yield put(communityRequestSucceeded(community));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to get community data',
        );
        yield put(communityRequestFailed(errors));
    }
}

export function* updateCommunity(action) {
    try {
        const {
            payload: { request },
        } = action;
        if (!request.communityId) {
            throw new Error('Community ID is required');
        }
        const updateData = yield call(
            fetchWithAuth,
            `/kiosk/group/${request.communityId}/edit`,
            {
                method: 'POST',
                body: transformCommunityEditRequest(request),
            },
        );
        const response = updateData.response.communities[0];
        yield put(communityUpdateRequestSucceeded(response));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update community',
        );
        yield put(communityUpdateRequestFailed(errors));
    }
}

export function* joinCommunity(action) {
    try {
        const { communityId } = action.payload;

        const data = yield call(
            fetchWithAuth,
            `/community/${communityId}/join`,
            {
                method: 'POST',
            },
        );

        const response = data.response?.communities;
        yield put(updateUserCommunities(response));
        yield put(otherCommunitiesRequested());
        yield put(joinCommunityRequestSucceeded(response));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18next.t('settings.user.communities.errors.join'),
        );

        yield put(joinCommunityRequestFailed(errors));
    }
}

export function* unjoinCommunity(action) {
    try {
        const { communityId } = action.payload;

        const data = yield call(
            fetchWithAuth,
            `/community/${communityId}/unjoin`,
            {
                method: 'POST',
            },
        );

        const response = data.response?.communities;
        yield put(updateUserCommunities(response));
        yield put(otherCommunitiesRequested());
        yield put(unjoinCommunityRequestSucceeded(response));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18next.t('settings.user.communities.errors.leave'),
        );
        yield put(unjoinCommunityRequestFailed(errors));
    }
}

export function* getInviteLink(action) {
    try {
        const {
            payload: { communityId },
        } = action;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/invite`,
            {
                query: {
                    communityId,
                },
            },
        );
        const url = data.response['dynamic-links'][0].url;
        yield put(communityInviteLinkRequestSucceeded(url));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('community.saga.errors.shareLink'),
        );
        yield put(communityInviteLinkRequestFailed(errors));
    }
}

export function* createCommunity(action) {
    try {
        const {
            payload: { requestBody, communityId },
        } = action;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/communities/create`,
            {
                method: 'POST',
                body: transformCommunityEditRequest(requestBody),
            },
        );
        const community = data.response?.communities[0];
        // Get the current user so we get the updated permissions in the background
        yield call(getCurrentUser, {
            payload: { isAuthenticating: false },
        });
        yield put(createCommunityRequestSucceeded(community));
        // Add a query parameter to the URL so we can show a product tour for switching communities
        yield put(push(`/communities/${community.id}?created=1`));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(createCommunityRequestFailed(errors));
    }
}

export function* getCommunities(action) {
    try {
        const communityId = yield select(getActiveCommunityId);
        const data = yield call(
            fetchWithAuth,
            `/kiosk/organization/${communityId}/communities`,
            {
                query: action.payload.options,
            },
        );
        yield put(
            communitiesRequestSucceeded(data.response?.communities || []),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(communitiesRequestFailed(errors));
    }
}

export function* searchCommunities({ payload: { searchString } }) {
    try {
        const data = yield call(fetchWithAuth, `/community/search`, {
            query: {
                searchString,
            },
        });
        const communities = data.response ? data.response.communities : [];
        yield put(communitySearchRequestSucceeded(communities));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18next.t('settings.user.communities.errors.search'),
        );
        yield put(communitySearchRequestFailed(errors));
    }
}

export function* getCommunityShareImageUrl({ payload: { communityId } }) {
    try {
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/shareImage`,
        );
        const url = data.response.urls[0].url;
        yield put(communityShareImageRequestSucceeded(url));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('community.saga.errors.shareLink'),
        );
        yield put(communityShareImageRequestFailed(errors));
    }
}

export function* uploadCommunityFile({ payload: { communityId, file } }) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const response = yield call(
            uploadWithAuth,
            `/kiosk/group/${communityId}/bulk-join`,
            {
                method: 'POST',
                body: formData,
            },
        );
        yield put(uploadCommunityFileRequestSucceeded(response.meta.message));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('error.unknown'),
        );
        yield put(uploadCommunityFileRequestFailed(errors));
    }
}

export default function* communitySaga() {
    yield all([
        takeLatest(COMMUNITY_REQUESTED, getCommunity),
        takeLatest(COMMUNITY_UPDATE_REQUESTED, updateCommunity),
        takeLatest(JOIN_COMMUNITY_REQUESTED, joinCommunity),
        takeLatest(UNJOIN_COMMUNITY_REQUESTED, unjoinCommunity),
        takeLatest(COMMUNITY_INVITE_LINK_REQUESTED, getInviteLink),
        takeLatest(COMMUNITY_SEARCH_REQUESTED, searchCommunities),
        takeLatest(CREATE_COMMUNITY_REQUESTED, createCommunity),
        takeLatest(COMMUNITIES_REQUESTED, getCommunities),
        takeLatest(COMMUNITY_SHARE_IMAGE_REQUESTED, getCommunityShareImageUrl),
        takeLatest(UPLOAD_COMMUNITY_FILE_REQUESTED, uploadCommunityFile),
    ]);
}
