import { all, call } from 'redux-saga/effects';
import initSaga from '../init/saga';
import authSaga from '../auth/saga';
import userSaga from '../user/saga';
import viewChallengeSaga from '../challenges/saga';
import updateChallengeSaga from '../screens/UpdateChallengeScreen/saga';
import viewActionSaga from '../actions/saga';
import analyticsPhotoSaga from '../analytics/photos/saga';
import analyticsSaga from '../analytics/saga';
import communitySaga from '../community/saga';
import imageSaga from '../image/saga';
import notificationsSaga from '../notifications/saga';
import flaggedContentSaga from '../flags/saga';
import themesSaga from '../communityThemes/saga';
import deepLinksSaga from '../deepLinks/saga';
import inviteUsersSaga from '../store/inviteUsers/saga';
import settingsSaga from '../screens/SettingsScreen/saga';
import activitiesSaga from '../activities/saga';
import subscriptionSaga from '../subscriptions/saga';

export default function* rootSaga() {
    yield all([
        call(initSaga),
        call(authSaga),
        call(deepLinksSaga),
        call(userSaga),
        call(viewChallengeSaga),
        call(updateChallengeSaga),
        call(viewActionSaga),
        call(analyticsPhotoSaga),
        call(analyticsSaga),
        call(activitiesSaga),
        call(flaggedContentSaga),
        call(communitySaga),
        call(imageSaga),
        call(notificationsSaga),
        call(themesSaga),
        call(imageSaga),
        call(inviteUsersSaga),
        call(settingsSaga),
        call(subscriptionSaga),
    ]);
}
