import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    Typography,
} from '@material-ui/core';

import useStyles from './styles';
import { trackEvent, useTrackScreenView } from '../../utils/analytics';
import { connect } from 'react-redux';
import {
    clearCommunityErrors,
    communitiesRequested,
    communityUpdateRequested,
    createCommunityRequested,
} from '../../community/actions';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import HeaderPage from '../../common/HeaderPage';
import { Can } from '../../casl/ability-context';
import Button from '../../common/Button';
import { Add, PlayCircleFilled } from '@material-ui/icons';
import {
    communitiesLoading,
    createCommunityErrors,
    createCommunityLoading,
    getCommunitiesAdmin,
    getCommunitiesErrors,
    getCommunityUpdateErrors,
    getCommunityUpdateResult,
    isCommunityUpdateLoading,
} from '../../community/selectors';
import Table from '../../common/Table';
import { buttonsCell, tableColumns } from './constants';
import { useHistory } from 'react-router-dom';
import ConfirmModal from '../../common/ConfirmModal';
import CreateCommunityForm from './CreateCommunityForm';
import PropTypes from 'prop-types';
import { noop } from '../../utils';
import { getActiveCommunityId, isOrganization } from '../../user/selectors';
import RequestLoader from '../../common/RequestLoader';
import {
    EVENT_COMMUNITY_CREATE_PRESSED,
    EVENT_COMMUNITY_CREATE_SUBMITTED,
    EVENT_COMMUNITY_EDIT_PRESSED,
    EVENT_COMMUNITY_EDIT_SUBMITTED,
} from '../../constants/analyticsEvents';

const CommunitiesScreen = ({
    fetchCommunities,
    createCommunity,
    editCommunity,
    communities,
    createErrors,
    createLoading,
    fetchErrors,
    editErrors,
    editLoading,
    editResponse,
    fetchLoading,
    clearErrors,
    isOrganization,
    communityId,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [selectedCommunity, setSelectedCommunity] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useTrackScreenView('communities_screen_view');

    const hasCommunities = communities.length > 0;

    const [creationModalOpen, setCreationModalOpen] = useState(false);

    const handleCommunityModalOpen = (community) => {
        setSelectedCommunity(community);
        setCreationModalOpen(true);
        if (community) {
            trackEvent(EVENT_COMMUNITY_EDIT_PRESSED, { id: community.id });
        } else {
            trackEvent(EVENT_COMMUNITY_CREATE_PRESSED);
        }
    };

    const onSubmit = (submitFunc, values) => {
        if (submitFunc === createCommunity) {
            trackEvent(EVENT_COMMUNITY_CREATE_SUBMITTED);
        } else {
            trackEvent(EVENT_COMMUNITY_EDIT_SUBMITTED, {
                id: selectedCommunity?.id,
            });
        }
        submitFunc({
            ...values,
            imageSizes: values.image,
            hasPassword: values.passwordChecked,
        });
    };

    useEffect(() => {
        if (editResponse) {
            fetchCommunities();
            setSnackbarMessage(t('community.editSuccess'));
            setCreationModalOpen(false);
        }
    }, [editResponse, fetchCommunities, t]);

    useEffect(() => {
        if (isOrganization) {
            fetchCommunities();
        } else {
            history.push(`/communities/${communityId}`);
        }
    }, [communityId, fetchCommunities, history, isOrganization]);

    const handleActions = useCallback(
        (id, key) => {
            switch (key) {
                case 'viewCommunityProfile':
                    history.push(`/communities/${id}`);
                    break;
                case 'editCommunityProfile':
                    handleCommunityModalOpen(
                        communities.find((community) => community.id === id),
                    );
                    break;
                default:
                    break;
            }
        },
        [history, communities],
    );

    const CreateButton = useMemo(() => {
        return (
            <Can I="create" a="Community">
                <Button
                    color="accent"
                    onClick={() => handleCommunityModalOpen(null)}
                >
                    <Add />
                    {t('community.create')}
                </Button>
            </Can>
        );
    }, [t]);

    const CommunitiesInfoExplainer = useMemo(() => {
        return (
            <>
                <Typography variant="h5" className={classes.introText}>
                    <Trans
                        t={t}
                        i18nKey="community.infoBlurb.introducingCommunities.title"
                    >
                        <b />
                    </Trans>
                </Typography>
                <Typography variant="body1" className={classes.introText}>
                    {t(
                        'community.infoBlurb.introducingCommunities.description',
                    )}
                </Typography>
                <Typography
                    variant="h6"
                    style={{ fontWeight: 'bold' }}
                    className={classes.introText}
                >
                    {t('community.infoBlurb.withCommunities.title')}
                </Typography>
                <Typography variant="body1" className={classes.introText}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PlayCircleFilled />
                            </ListItemIcon>
                            {t(
                                'community.infoBlurb.withCommunities.description.bulletOne',
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PlayCircleFilled />
                            </ListItemIcon>
                            {t(
                                'community.infoBlurb.withCommunities.description.bulletTwo',
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PlayCircleFilled />
                            </ListItemIcon>
                            {t(
                                'community.infoBlurb.withCommunities.description.bulletThree',
                            )}
                        </ListItem>
                    </List>
                </Typography>
                <Grid item xs={3}>
                    {CreateButton}
                </Grid>
            </>
        );
    }, [CreateButton, classes.introText, t]);

    return (
        <Grid container direction="column" className={classes.container}>
            <HeaderPage
                title={t('community.community_plural')}
                rightContent={hasCommunities && CreateButton}
            />
            <RequestLoader isLoading={fetchLoading} />
            {hasCommunities ? (
                <Table
                    columns={tableColumns}
                    data={communities}
                    noItemFoundText={t('community.notFound')}
                    buttonsCell={buttonsCell}
                    onButtonsCell={handleActions}
                    clientPagination
                    loaderRows={2}
                    initialSortBy={[{ id: 'created', desc: true }]}
                    loading={fetchLoading}
                />
            ) : (
                !fetchLoading && CommunitiesInfoExplainer
            )}
            <ConfirmModal
                isOpen={creationModalOpen}
                onClose={() => setCreationModalOpen(false)}
                cancelBtnHidden
                confirmBtnHidden
                title={
                    <Grid item container justifyContent="center">
                        <Typography className={classes.communityCreateButton}>
                            {!selectedCommunity
                                ? t('community.create')
                                : t('community.edit')}
                        </Typography>
                    </Grid>
                }
            >
                {!selectedCommunity ? (
                    <CreateCommunityForm
                        onSubmit={(values) => {
                            onSubmit(createCommunity, values);
                        }}
                        submitBtnText={t('community.create')}
                        loading={createLoading}
                    />
                ) : (
                    <CreateCommunityForm
                        onSubmit={(values) => onSubmit(editCommunity, values)}
                        initialFormValues={{
                            image: selectedCommunity.image,
                            name: selectedCommunity.name,
                            passwordChecked: selectedCommunity.hasPassword,
                            description: selectedCommunity.description,
                            visibility: selectedCommunity.visibility,
                            additionalAdmins: [],
                        }}
                        communityId={selectedCommunity.id}
                        submitBtnText={t('community.edit')}
                        loading={editLoading}
                    />
                )}
            </ConfirmModal>
            <SnackBarAPIResponse
                messages={snackbarMessage}
                errors={[
                    ...(createErrors || []),
                    ...(fetchErrors || []),
                    ...(editErrors || []),
                ]}
                clearResponse={() => {
                    clearErrors();
                    setSnackbarMessage('');
                }}
            />
        </Grid>
    );
};

CommunitiesScreen.propTypes = {
    fetchCommunities: PropTypes.func,
    createCommunity: PropTypes.func,
    editCommunity: PropTypes.func,
    communities: PropTypes.array,
    fetchLoading: PropTypes.bool,
    createErrors: PropTypes.array,
    createLoading: PropTypes.bool,
    fetchErrors: PropTypes.array,
    clearErrors: PropTypes.func,
    editErrors: PropTypes.array,
    editLoading: PropTypes.bool,
    editResponse: PropTypes.string,
    isOrganization: PropTypes.bool,
    communityId: PropTypes.string,
};

CommunitiesScreen.defaultProps = {
    fetchCommunities: noop,
    createCommunity: noop,
    editCommunity: noop,
    communities: [],
    fetchLoading: false,
    clearErrors: noop,
    createErrors: [],
    createLoading: false,
    fetchErrors: [],
    editErrors: [],
    editLoading: false,
    editResponse: '',
    isOrganization: false,
    communityId: '',
};

const mapStateToProps = (state) => ({
    fetchErrors: getCommunitiesErrors(state),
    fetchLoading: communitiesLoading(state),
    communities: getCommunitiesAdmin(state),
    createErrors: createCommunityErrors(state),
    createLoading: createCommunityLoading(state),
    editErrors: getCommunityUpdateErrors(state),
    editLoading: isCommunityUpdateLoading(state),
    editResponse: getCommunityUpdateResult(state),
    isOrganization: isOrganization(state),
    communityId: getActiveCommunityId(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCommunities: (request) => dispatch(communitiesRequested(request)),
    clearErrors: () => dispatch(clearCommunityErrors()),
    createCommunity: (values) =>
        dispatch(createCommunityRequested(values.communityId, values)),
    editCommunity: (values) => dispatch(communityUpdateRequested(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunitiesScreen);
