export default (theme) => ({
    container: {
        flexDirection: 'column',
        maxWidth: theme.custom.mainContentWidth,
        paddingLeft: theme.custom.mainContentHorizontalPadding,
        paddingRight: theme.custom.mainContentHorizontalPadding,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    notificationContainer: {
        backgroundColor: theme.custom.bgLightAccentColor,
        borderBottom: 'solid 1px',
        borderTop: 'solid 1px',
        borderBottomColor: theme.custom.grey88,
        borderTopColor: theme.custom.grey88,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
        },
        padding: '5px 5px 5px 20px',
    },
    relative: {
        position: 'relative',
    },
    leftIcon: {
        borderRadius: '50%',
        borderStyle: 'none',
        boxShadow: '0px 3px 10px #00000026',
        minWidth: '48px',
        [theme.breakpoints.down('xs')]: {
            marginRight: '10px',
        },
        marginRight: '20px',
    },
    rightIcon: {
        fontSize: '32px',
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        height: '32px',
    },
    contentLoaderContainer: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        height: 70,
    },
    subject: {
        lineHeight: 1.1,
    },
    timeText: {},
});
