const styles = (theme) => ({
    container: {},
    icon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '3px',
        marginTop: '3px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    tooltip: {
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    descriptionLabel: {},
    placementHint: {
        marginBottom: '10px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    textarea: theme.shineAdmin.textarea,
    placementLabel: {},
    select: {
        minWidth: '50%',
        backgroundColor: theme.custom.inputBackground,
        fontSize: '14px',
        borderRadius: '4px',
        border: 'solid 1px',
        borderColor: theme.palette.primary.main,
    },
    check: {
        minWidth: '20px',
        paddingTop: '3px',
    },
    menuItem: {
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '1px',
        fontSize: '14px',
    },
    inputRoot: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '5px',
    },
    selectPaper: {
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        borderRadius: '4px',
    },
    translation: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    translationLabel: {
        marginRight: '5px',
    },
    previewLabel: {
        marginBottom: '10px',
    },
    preview: {},
    previewIcon: {
        width: '64px',
        height: '64px',
    },
    previewInfo: {
        marginLeft: '10px',
    },
    previewName: {},
    previewDescription: {},
});

export default styles;
