import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getChallengeEndDate,
    getChallengeErrors,
    getChallengeId,
    getChallengeName,
    getChallengeStartDate,
    getGraphData,
    getGraphDataErrors,
    getGraphDataLoading,
    isChallengeLoading,
} from '../../challenges/selectors';
import {
    getGraphDataRequested,
    viewChallengeRequested,
} from '../../challenges/actions';
import CommonPropTypes from '../../common/propTypes';
import styles from './styles';
import HeaderPage from '../../common/HeaderPage';
import Graph from '../../common/Graph';
import RequestLoader from '../../common/RequestLoader';
import ChallengeStats from './ChallengeStats';
import { CHARTS, DATE_FORMAT } from './constants';
import { getTimezoneAbbrev } from '../../utils/dates';
import {
    CHART_INTERVAL_DAY,
    CHART_INTERVALS,
    GRAPH_METRIC_ACTIVE_USERS,
    GRAPH_METRIC_SCORE_TRACKER,
    GRAPH_METRIC_TOTAL_LOGS,
    GRAPH_TYPE_SCORE_TRACKER,
    GRAPH_TYPE_TIME_SERIES,
} from '../../common/Graph/ChartItem/constants';
import { useRouteMatch } from 'react-router-dom';

const ChallengeAnalyticsScreen = ({
    classes,
    name,
    startDate,
    endDate,
    communityId,
    graphData,
    graphErrors,
    isGraphLoading,
    isChallengeLoading,
    challengeErrors,
    challengeId,
    loadFunc,
    getGraphData,
}) => {
    const { t } = useTranslation();
    const {
        params: { challengeId: challengeIdParam },
    } = useRouteMatch();

    const [intervals, setIntervals] = useState(CHART_INTERVALS);
    const [params, setParams] = useState({
        resolution: CHART_INTERVAL_DAY,
    });

    useEffect(() => {
        loadFunc({ communityId, challengeId: challengeIdParam });
    }, [communityId, challengeIdParam, loadFunc]);

    const options = useMemo(
        () => ({
            communityId,
            challengeId: challengeIdParam,
            scope: 'challenge',
            scopeId: challengeIdParam,
            startDateIso: startDate && startDate.toISOString(),
            endDateIso: endDate && endDate.toISOString(),
        }),
        [communityId, challengeIdParam, startDate, endDate],
    );

    const checkIncludeInterval = (intervals) => {
        if (!intervals.includes(params.interval)) {
            setParams({ ...params, resolution: intervals[0] });
            return intervals[0];
        }
    };

    const getIntervalByDate = () => {
        const diffWeeks = moment(endDate).diff(moment(startDate), 'weeks');
        const diffMonth = moment(endDate).diff(moment(startDate), 'months');

        if (diffMonth > 12) {
            return ['week', 'month', 'year'];
        } else if (diffWeeks > 9) {
            return ['week', 'month'];
        } else {
            return ['day', 'week', 'month'];
        }
    };

    const fetchData = () => {
        if (!name) return;

        const intervals = getIntervalByDate();
        setIntervals(intervals);

        const interval = checkIncludeInterval(intervals);
        const params = {
            ...options,
            type: GRAPH_TYPE_SCORE_TRACKER,
            metric: GRAPH_METRIC_SCORE_TRACKER,
            resolution: interval,
        };

        setParams(params);
        getGraphData(params);
    };

    const changeTabHandler = (tabIndex) => {
        let newParams = { resolution: params.resolution };

        if (tabIndex === 0) {
            newParams = {
                ...newParams,
                type: GRAPH_TYPE_SCORE_TRACKER,
                metric: GRAPH_METRIC_SCORE_TRACKER,
            };
        } else if (tabIndex === 1) {
            newParams = {
                ...newParams,
                type: GRAPH_TYPE_TIME_SERIES,
                metric: GRAPH_METRIC_TOTAL_LOGS,
            };
        } else if (tabIndex === 2) {
            newParams = {
                ...newParams,
                type: GRAPH_TYPE_TIME_SERIES,
                metric: GRAPH_METRIC_ACTIVE_USERS,
            };
        }

        setParams(newParams);
        getGraphData({ ...options, ...newParams });
    };

    const intervalChangeHandler = (interval) => {
        const newParams = { ...params, resolution: interval };

        setParams(newParams);
        getGraphData({ ...options, ...newParams });
    };

    useEffect(() => {
        if (challengeIdParam === challengeId) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeId, challengeIdParam]);

    return (
        <Fragment>
            {graphErrors || challengeErrors ? (
                [...(graphErrors || []), challengeErrors].map((err, i) => (
                    <Typography key={i}>{err}</Typography>
                ))
            ) : (
                <Grid
                    container
                    direction="column"
                    justify="center"
                    className={classes.root}
                >
                    <HeaderPage
                        title={name || ''}
                        subtitle={
                            <Typography className={classes.subtitle}>
                                {`${moment(startDate).format(DATE_FORMAT)} 
                                - ${moment(endDate).format(DATE_FORMAT)} 
                                 (${getTimezoneAbbrev()})`}
                            </Typography>
                        }
                    />
                    <Grid container item className={classes.relative}>
                        {graphData && !isGraphLoading && (
                            <Graph
                                charts={CHARTS}
                                data={graphData}
                                intervals={intervals}
                                interval={params.resolution}
                                onIntervalChange={intervalChangeHandler}
                                onTabChange={changeTabHandler}
                                footerPadding="10px"
                            />
                        )}
                        <RequestLoader
                            title={t('loading.graph')}
                            isLoading={isGraphLoading || isChallengeLoading}
                        />
                    </Grid>
                    <ChallengeStats
                        challengeId={challengeIdParam}
                        communityId={communityId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
            )}
        </Fragment>
    );
};

ChallengeAnalyticsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    name: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    graphData: PropTypes.shape({
        data: PropTypes.array,
        labels: PropTypes.arrayOf(PropTypes.string),
        series: PropTypes.array,
    }),
    graphErrors: CommonPropTypes.errors,
    isGraphLoading: PropTypes.bool.isRequired,
    challengeId: PropTypes.string.isRequired,
    isChallengeLoading: PropTypes.bool.isRequired,
    challengeErrors: CommonPropTypes.errors,
    loadFunc: PropTypes.func.isRequired,
    getGraphData: PropTypes.func.isRequired,
};

ChallengeAnalyticsScreen.defaultProps = {
    name: null,
    startDate: null,
    endDate: null,
    graphData: null,
    graphErrors: [],
    challengeErrors: [],
};

const StyledLeftColumn = withStyles(styles)(ChallengeAnalyticsScreen);

const mapStateToProps = (state) => ({
    name: getChallengeName(state),
    startDate: getChallengeStartDate(state),
    endDate: getChallengeEndDate(state),
    challengeId: getChallengeId(state),
    communityId: getActiveCommunityId(state),
    graphData: getGraphData(state),
    isGraphLoading: getGraphDataLoading(state),
    graphErrors: getGraphDataErrors(state),
    isChallengeLoading: isChallengeLoading(state),
    challengeErrors: getChallengeErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadFunc: ({ challengeId, communityId }) =>
        dispatch(viewChallengeRequested({ challengeId, communityId })),
    getGraphData: (args) => dispatch(getGraphDataRequested(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledLeftColumn);
