import i18n from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    SUBSCRIPTION_MANAGEMENT_LINK_REQUESTED,
    SUBSCRIPTION_RECORDS_REQUESTED,
    SUBSCRIPTION_REQUESTED,
    SUBSCRIPTION_SESSION_REQUESTED,
    SUBSCRIPTION_TIERS_REQUESTED,
    SUBSCRIPTION_VERIFICATION_REQUESTED,
    subscriptionManagementLinkRequestFailed,
    subscriptionManagementLinkRequestSucceeded,
    subscriptionRecordsRequestFailed,
    subscriptionRecordsRequestSucceeded,
    subscriptionRequestFailed,
    subscriptionRequestSucceeded,
    subscriptionSessionRequestFailed,
    subscriptionSessionRequestSucceeded,
    subscriptionTiersRequestFailed,
    subscriptionTiersRequestSucceeded,
    subscriptionVerificationRequestFailed,
    subscriptionVerificationRequestSucceeded,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../utils/api';
import { getProperties } from '../utils';
import { setAnalyticsCommunity } from '../utils/analytics';
import { getActiveCommunity } from '../user/selectors';
import { updateIntercomCompanyData } from '../config/intercom';

export function* getSubscription(action) {
    try {
        const {
            payload: { groupId },
        } = action;

        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${groupId}/subscriptions`,
        );

        const currentSubscription =
            data.response.communityBillingSubscription[0];
        yield put(subscriptionRequestSucceeded(currentSubscription));
        setAnalyticsCommunity(yield select(getActiveCommunity), {
            subscription: currentSubscription?.id,
        });
        let amount = currentSubscription?.amount / 100;
        if (currentSubscription?.interval === 'year') {
            amount = amount / 12;
        }
        updateIntercomCompanyData({
            monthly_spend: amount,
            plan: currentSubscription?.subscriptionTier?.internalName,
            user_count: currentSubscription?.currentUserCount,
            trial_end: currentSubscription?.trialEnd,
        });
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('account.saga.errors.subscription'),
        );

        yield put(subscriptionRequestFailed(errors));
    }
}

export function* getSubscriptionSession(action) {
    try {
        const {
            groupId,
            successUrl,
            cancelUrl,
            planId,
            quantity,
        } = action.payload;
        const query = {
            successUrl,
            cancelUrl,
            planId,
        };
        if (quantity) {
            query.quantity = quantity;
        }
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${groupId}/subscriptions/session`,
            {
                query,
            },
        );

        const url = data.response.billingSession[0].url;

        yield put(subscriptionSessionRequestSucceeded(url));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('account.saga.errors.subscriptionSession'),
        );

        yield put(subscriptionSessionRequestFailed(errors));
    }
}

export function* getSubscriptionVerification(action) {
    try {
        const { groupId, planId } = action.payload;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${groupId}/subscriptions/verification`,
            {
                query: {
                    planId,
                },
            },
        );

        const subscription = data.response.communityBillingSubscription[0];
        const invoice = subscription.invoice;

        yield put(
            subscriptionVerificationRequestSucceeded(invoice, subscription),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('account.saga.errors.subscriptionVerification'),
        );

        yield put(subscriptionVerificationRequestFailed(errors));
    }
}

export function* getSubscriptionsRecords(action) {
    try {
        const {
            payload: { groupId },
        } = action;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${groupId}/subscriptions/records`,
            {
                query: {
                    limit: 100,
                },
            },
        );

        const billingHistory = data.response?.billingSubscriptionRecords;
        yield put(subscriptionRecordsRequestSucceeded(billingHistory));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('account.saga.errors.subscriptionRecords'),
        );
        yield put(subscriptionRecordsRequestFailed(errors));
    }
}

export function* getSubscriptionsTiers() {
    try {
        const data = yield call(fetchWithAuth, '/kiosk/subscriptions/tiers', {
            query: {
                client_secret: getProperties()?.clientSecret,
                client_id: getProperties()?.clientId,
            },
        });
        const billingTiers = data.response?.billingSubscriptionTiers;
        yield put(subscriptionTiersRequestSucceeded(billingTiers));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('account.saga.errors.subscriptionTiers'),
        );
        yield put(subscriptionTiersRequestFailed(errors));
        yield put(subscriptionRecordsRequestFailed(errors));
    }
}

export function* getSubscriptionManagementLink(action) {
    try {
        const { groupId, returnUrl } = action.payload;
        const data = yield call(
            fetchWithAuth,
            `/kiosk/group/${groupId}/subscriptions/session/update`,
            {
                query: {
                    returnUrl,
                },
            },
        );
        const response = data.response;

        const url = response.billingSession[0].url;

        yield put(subscriptionManagementLinkRequestSucceeded(url));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            i18n.t('account.saga.errors.subscriptionManagement'),
        );
        yield put(subscriptionManagementLinkRequestFailed(errors));
    }
}

export default function* subscriptionSaga() {
    yield all([
        takeLatest(SUBSCRIPTION_REQUESTED, getSubscription),
        takeLatest(SUBSCRIPTION_SESSION_REQUESTED, getSubscriptionSession),
        takeLatest(
            SUBSCRIPTION_VERIFICATION_REQUESTED,
            getSubscriptionVerification,
        ),
        takeLatest(SUBSCRIPTION_RECORDS_REQUESTED, getSubscriptionsRecords),
        takeLatest(SUBSCRIPTION_TIERS_REQUESTED, getSubscriptionsTiers),
        takeLatest(
            SUBSCRIPTION_MANAGEMENT_LINK_REQUESTED,
            getSubscriptionManagementLink,
        ),
    ]);
}
