import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL } from './constants';

const EARTHSHARE_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'ES Engage',
    shouldShowSignUp: false,
    clientId: '5cca824a4b288b37890fe723',
    clientSecret:
        '03woAbS5r8XB118vHyxju3OYRds1pS3iiJPACy4CrkCKH6DiOB07Zg95WHSIgiie1J',
    loginMethods: [LOGIN_METHOD_EMAIL],
    wordpressDomain: 'https://esengage.joulebug.com',
    wordpressOverrides: {
        '/about': 'https://esengage.joulebug.com',
    },
    appStoreLink:
        'https://apps.apple.com/us/app/earthshare-engage/id1462718042',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.earthsharegl',
    appDescription:
        'With EarthShare Engage, compete in challenges to improve your body, your community, and your planet. Earn points and build camaraderie by completing simple real-life actions as we strive towards common goals. Download today!',
};

export default EARTHSHARE_PROPERTIES;
