const styles = (theme) => ({
    tabs: {
        borderRadius: 4,
        overflow: 'hidden',
        boxShadow: '0 0 0 1px rgba(0,0,0,.03), 0 1px 4px 0 rgba(0,0,0,.06)',
    },
    footer: {
        fontSize: '10px',
        textAlign: 'center',
    },
});

export default styles;
