import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import IMAGES from '../../constants/images';
import styles from './styles';
import { useTranslation } from 'react-i18next';

export const RequestLoader = ({ classes, isLoading, title }) => {
    const { t } = useTranslation();

    if (!isLoading) return null;

    return (
        <Grid
            container
            item
            className={classes.container}
            justify="center"
            alignItems="center"
        >
            <Grid
                container
                item
                className={classes.wrapper}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <img
                    className={classes.loader}
                    src={IMAGES.loader}
                    alt={t('alt.loader')}
                />
                <Typography className={classes.title}>
                    {title || t('loading.descriptor')}
                </Typography>
            </Grid>
            <div className={classes.overlay} />
        </Grid>
    );
};

RequestLoader.propTypes = {
    classes: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    title: PropTypes.string,
};

RequestLoader.defaultProps = {
    classes: {},
    title: '',
};

export const StyledRequestLoader = withStyles(styles)(RequestLoader);
export default StyledRequestLoader;
