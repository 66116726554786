export const LOGIN_METHOD_EMAIL = 'email';
export const LOGIN_METHOD_OKTA = 'okta';
export const LOGIN_METHOD_MICROSOFT = 'microsoft';
export const LOGIN_METHOD_AZURE_AD = 'azureAD';
export const LOGIN_METHOD_ADFS = 'adfs';

export const LOGIN_METHOD_GOOGLE = 'google';

export const SERVER_SIDE_PKCE = 'SERVER_SIDE_PKCE';

export const CLIENT_SIDE_PKCE = 'CLIENT_SIDE_PKCE';

export const SERVER_SIDE_NON_PKCE = 'SERVER_SIDE_NON_PKCE';
