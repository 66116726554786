import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Grid, withStyles, Typography } from '@material-ui/core';
import styles from './styles';
import { Favorite } from '@material-ui/icons';

const ActivityCardLikes = ({ classes, likes }) => {
    if (!likes) {
        return null;
    }

    return (
        <Grid container item className={classes.container}>
            <Favorite className={classes.icon} />
            <Typography className={classes.text}>
                {pluralize('Like', likes, true)}
            </Typography>
        </Grid>
    );
};

ActivityCardLikes.propTypes = {
    classes: PropTypes.object.isRequired,
    likes: PropTypes.number,
};

ActivityCardLikes.defaultProps = {
    likes: null,
};

export default withStyles(styles)(ActivityCardLikes);
