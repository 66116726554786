const styles = (theme) => ({
    container: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '5px',
        padding: '10px',
    },
    image: {
        maxWidth: '50px',
        maxHeight: '50px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '40px',
            maxHeight: '40px',
        },
        borderRadius: '5px',
    },
    detailsContainer: {
        paddingLeft: '15px',
    },
    subTextContainer: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    subText: {
        color: theme.custom.textLightSecondaryColor,
        paddingRight: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    viewButton: {
        height: 'min-content',
        padding: '5px 20px',
    },
    leaveButton: {
        height: 'min-content',
        padding: '5px 10px',
        marginLeft: '10px',
    },
});

export default styles;
