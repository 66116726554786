import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { buttonsCellPropType } from '../propTypes';
import { Can } from '../../../casl/ability-context';
import Tooltip from '../../Tooltip';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const TableCellActions = ({
    classes,
    id,
    buttonsCell,
    onButtonsCell,
    rowIdx,
}) => {
    const { t } = useTranslation();
    const getButton = (tooltip, key, Icon, intercomTarget) => (
        <Tooltip
            title={t(tooltip)}
            placement="top"
            data-intercom-target={intercomTarget}
        >
            <IconButton
                data-intercom-target={`Table Row Action Button ${key} ${rowIdx}`}
                className={classes.iconLink}
                onClick={() => onButtonsCell(id, key)}
            >
                <Icon />
            </IconButton>
        </Tooltip>
    );
    return (
        <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
            {buttonsCell.map(
                ({
                    key,
                    actions,
                    subject,
                    tooltip,
                    icon: Icon,
                    intercomTarget,
                }) =>
                    subject && actions ? (
                        <Can key={`${key}-${id}`} I={actions} a={subject}>
                            {getButton(tooltip, key, Icon, intercomTarget)}
                        </Can>
                    ) : (
                        getButton(tooltip, key, Icon)
                    ),
            )}
        </Grid>
    );
};

TableCellActions.propTypes = {
    id: PropTypes.string.isRequired,
    buttonsCell: buttonsCellPropType.isRequired,
    onButtonsCell: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    // The row index -- only used for intercom targets
    rowIdx: PropTypes.string.isRequired,
};

export default withStyles(styles)(TableCellActions);
