import { transformImpactStatsToTotals } from '../common/transforms';
import i18next from 'i18next';

export const transformCommunity = (community) =>
    community && {
        id: community.id,
        image: community.imageSizes?.[0]?.url,
        name: community.name,
        description: community.description,
        impactTotals: transformImpactStatsToTotals(
            community.impactStats?.impactStatTypes,
        ),
        activityTotals: {
            points: community.score,
            logs: community.buzzes,
            actions: community.pinTotal,
            badges: community.badgeTotal,
            members: community.membersCount,
        },
        created: community.created,
    };

export const transformAdminCommunity = (community) =>
    community && {
        id: community.id,
        image: community.imageSizes?.[0]?.url,
        name: community.name,
        description: community.description,
        members: community.membersCount,
        visibility: community.visibility,
        hasPassword: community.hasPassword,
        created: community.created,
    };

export const transformCommunityEditRequest = (data) => {
    const lang = i18next.language;
    return {
        ...data,
        internalName: data.name,
        name: { [lang]: data.name },
        description: { [lang]: data.description },
        additionalAdmins: data.additionalAdmins?.map((admin) => admin.title),
        image: data.imageSizes && {
            sizes: data.imageSizes,
        },
    };
};
