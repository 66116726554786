import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
    container: {
        flex: 1,
        borderRadius: 4,
        boxShadow: '0 0 0 1px rgba(0,0,0,.03), 0 1px 4px 0 rgba(0,0,0,.06)',
        backgroundColor: theme.custom.bgLightColor,

        '& form>.MuiGrid-container>button': { margin: '10px 15px 10px' },
    },
    top: {
        padding: '12px 16px',
        alignItems: 'center',
        color: theme.custom.darkGrey,
    },
    title: {
        fontSize: 16,
        margin: 0,
        fontWeight: 700,
    },
    warningBanner: {
        borderRadius: 10,
        borderWidth: 2,
        borderColor: theme.palette.error.main,
        borderStyle: 'solid',
        backgroundColor: '#fff',
        padding: '15px 20px',
    },
    modalTitle: {
        paddingBottom: 0,
    },
    body: {
        flexDirection: 'column',
        padding: 15,
        backgroundColor: fade(theme.palette.common.black, 0.05),
        color: theme.custom.textDarkColor,
    },
    inputField: {
        padding: '6px 12px',
        fontSize: 14,
        resize: 'none',

        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },

        '&:active&:not(&:disabled)': {
            backgroundColor: theme.palette.common.white,
        },

        '&:focus': {
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.primary.dark,
            outline: 'none',
        },
    },
    bottomFields: {
        width: '220px',
    },
    textarea: theme.shineAdmin.textarea,
    dropdown: {
        padding: 0,

        '& .MuiOutlinedInput-root': { padding: 0 },
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] input': {
            padding: '6px 0 6px 12px',
        },
    },
});
