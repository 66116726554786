import React, {
    Fragment,
    memo,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    MenuItem,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import HeaderPage from '../../../common/HeaderPage';
import SearchBar from '../../../common/Search';
import RequestLoader from '../../../common/RequestLoader';
import {
    actionsRequested,
    addExcludeActionRequest,
    addIncludeActionRequest,
    clearActionErrors,
    sdgsRequested,
} from '../../../actions/actions';
import { getActions, getSDGs } from '../../../actions/selectors';
import TableManager from './TableManager';
import { getActiveCommunity } from '../../../user/selectors';
import { sortPropType } from '../../../common/Table/propTypes';
import ContentToggleButton from '../../../common/ContentToggleButton';
import {
    areThemesLoading,
    getThemes,
} from '../../../communityThemes/selectors';
import { themesRequested } from '../../../communityThemes/actions';
import { Add } from '@material-ui/icons';
import Button from '../../../common/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AbilityContext, Can } from '../../../casl/ability-context';
import { SELECTED, UNSELECTED } from '../../../communityThemes/constants';
import { getActionDuplicationCommunities } from '../../SettingsScreen/selectors';
import {
    activeCommunityPropType,
    availableCommunitiesPropType,
} from '../../../user/propTypes';
import { useTrackScreenView } from '../../../utils/analytics';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';
import { noop } from '../../../utils';
import {
    PIN_ADMIN_VISIBILITY_EXCLUDED,
    PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
} from '../../../actions/constants';
import { actionTablePropType } from '../../../actions/propTypes';
import InfoTooltip from '../../../common/InfoTooltip';
import { INTERCOM_TARGET_PREFIX } from './constants';
import FormDropdown from '../../../common/FormDropdown';
import CategoryDropdown from '../../../common/CategoryDropdown';
import FormInput from '../../../common/Form/FormInput';

export const AdminActionsScreen = memo(
    ({
        classes,
        activeCommunity,
        actionDuplicationCommunities,
        includedTableData,
        excludedTableData,
        fetchActions,
        addInclude,
        addExclude,
        initialSortActive,
        initialSortDeactivated,
        themes,
        getThemes,
        clearErrors,
        themesLoading,
        getSDGs,
        sdgs,
    }) => {
        const { t } = useTranslation();
        const { path } = useRouteMatch();

        const [textFilter, setTextFilter] = useState('');
        const [sdgFilter, setSDGFilter] = useState(null);
        const [minPointsFilter, setMinPointsFilter] = useState(null);
        const [maxPointsFilter, setMaxPointsFilter] = useState(null);
        const [categoryFilter, setCategoryFilter] = useState(null);

        const [pointsFilterOpen, setPointsFilterOpen] = useState(false);

        const [refetchData, setRefetchData] = useState(0);

        const history = useHistory();

        useTrackScreenView('action_view_overview_admin');

        const ability = useContext(AbilityContext);
        const canDeactivateActions = ability.can(
            'edit',
            'CommunityActionExclude',
        );

        useEffect(() => {
            getSDGs();
        }, [getSDGs]);

        useEffect(() => {
            getThemes(activeCommunity.id);
        }, [activeCommunity.id, getThemes]);

        const fetchActionData = useCallback(
            (args) => {
                const body = {
                    ...args,
                };
                if (sdgFilter) {
                    body.sdgId = sdgFilter;
                }
                if (minPointsFilter) {
                    body.minPoints = minPointsFilter;
                }
                if (maxPointsFilter) {
                    body.maxPoints = maxPointsFilter;
                }
                if (categoryFilter) {
                    body.categoryIds = [categoryFilter];
                }
                fetchActions(activeCommunity.id, body);
            },
            [
                activeCommunity.id,
                categoryFilter,
                fetchActions,
                maxPointsFilter,
                minPointsFilter,
                sdgFilter,
            ],
        );

        const handleKeyDown = (e) => {
            if (e.which === 13) {
                // Enter key pressed
                if (minPointsFilter || maxPointsFilter) {
                    setRefetchData(refetchData + 1);
                }
                setPointsFilterOpen(false);
            } else if (e.which === 9) {
                // Tab key pressed
                e.stopPropagation();
            }
        };

        return (
            <Grid container justify="center" className={classes.container}>
                <HeaderPage title={t('appContent.title')} />
                <Can I="view" a="Themes">
                    <Grid
                        container
                        justify="space-between"
                        direction="row"
                        wrap="nowrap"
                        className={classes.themesHeader}
                    >
                        <Grid container direction="column">
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                            >
                                <Grid
                                    xs={6}
                                    container
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="h3"
                                        className={classes.themesTitle}
                                    >
                                        {t('appContent.themes.title')}
                                    </Typography>
                                    <InfoTooltip
                                        text={t('appContent.themes.info')}
                                        intercomTarget={`${INTERCOM_TARGET_PREFIX}Theme`}
                                    />
                                </Grid>
                                <Can I="edit" a="Themes">
                                    <Button
                                        color="accent"
                                        onClick={() =>
                                            history.push('/themes/edit')
                                        }
                                        className={classes.editThemesButton}
                                        data-intercom-target="Action Header Edit Button"
                                    >
                                        <Add
                                            className={classes.editThemesPlus}
                                        />
                                        <Typography noWrap>
                                            {t('appContent.themes.edit')}
                                        </Typography>
                                    </Button>
                                </Can>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        spacing={6}
                        className={classes.selectedThemes}
                    >
                        {themes
                            .filter(
                                (theme) =>
                                    theme.status && theme.status !== UNSELECTED,
                            )
                            .map((theme, index) => {
                                const manuallySelected =
                                    theme.status === SELECTED;
                                let button = (
                                    <ContentToggleButton
                                        selected={manuallySelected}
                                        name={theme.name}
                                        iconUrl={theme.whiteIcon}
                                        selectable={false}
                                        disabled
                                        data-intercom-target={`Action Header Theme Selection ${index}`}
                                    />
                                );
                                if (!manuallySelected) {
                                    button = (
                                        <Tooltip
                                            placement="top"
                                            title={t(
                                                'appContent.themes.locked',
                                            )}
                                        >
                                            <Grid item>{button}</Grid>
                                        </Tooltip>
                                    );
                                }
                                return (
                                    <Grid item key={index} lg={3} xs={6}>
                                        {button}
                                    </Grid>
                                );
                            })}
                        <RequestLoader
                            isLoading={themesLoading}
                            title={t('appContent.themes.loading')}
                        />
                    </Grid>
                </Can>
                <Fragment>
                    <Grid container item direction="row">
                        <Grid item xs={12} sm={6}>
                            <SearchBar
                                placeholder={t('actions.search')}
                                handleTextChange={setTextFilter}
                                runSearch={setTextFilter}
                                data-intercom-target="Action Header Search"
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} className={classes.filter}>
                            <FormDropdown
                                onChange={(e) => {
                                    setSDGFilter(e.target.value);
                                    setRefetchData(refetchData + 1);
                                }}
                                value={sdgFilter}
                                items={sdgs?.map((sdg) => ({
                                    value: sdg.id,
                                    label: sdg.name,
                                }))}
                                placeholder={t('action.sdgs')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} className={classes.filter}>
                            <CategoryDropdown
                                onChange={(e) => {
                                    setCategoryFilter(e.target.value);
                                    setRefetchData(refetchData + 1);
                                }}
                                themes={themes.filter(
                                    (theme) => theme.status === SELECTED,
                                )}
                                value={categoryFilter}
                                placeholder={t('category')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} className={classes.filter}>
                            <FormDropdown
                                SelectProps={{
                                    open: pointsFilterOpen,
                                    onOpen: () => setPointsFilterOpen(true),
                                    onClose: () => {
                                        setPointsFilterOpen(false);
                                        if (
                                            minPointsFilter ||
                                            maxPointsFilter
                                        ) {
                                            setRefetchData(refetchData + 1);
                                        }
                                    },
                                    renderValue: () => {
                                        if (
                                            minPointsFilter &&
                                            maxPointsFilter
                                        ) {
                                            return `${minPointsFilter} - ${maxPointsFilter}`;
                                        } else if (minPointsFilter) {
                                            return `${minPointsFilter} -`;
                                        } else if (maxPointsFilter) {
                                            return `- ${maxPointsFilter}`;
                                        } else {
                                            return t('Points');
                                        }
                                    },
                                }}
                                value={minPointsFilter || maxPointsFilter}
                                onChange={(e) => {
                                    if (Object.keys(e).length === 1) {
                                        setRefetchData(refetchData + 1);
                                        setMinPointsFilter(null);
                                        setMaxPointsFilter(null);
                                    }
                                }}
                                renderItems={() => [
                                    <MenuItem
                                        key={1}
                                        onClick={() =>
                                            setPointsFilterOpen(true)
                                        }
                                    >
                                        <Grid
                                            item
                                            className={classes.pointsInput}
                                        >
                                            <FormInput
                                                name="min"
                                                useFormik={false}
                                                noErrorMessage
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                inputProps={{
                                                    min: 0,
                                                }}
                                                onChange={(e) => {
                                                    const val = e.target.value;
                                                    if (
                                                        val >= 0 &&
                                                        val < 1000
                                                    ) {
                                                        setMinPointsFilter(val);
                                                    }
                                                }}
                                                value={minPointsFilter}
                                            />
                                        </Grid>
                                        -
                                        <Grid
                                            item
                                            className={classes.pointsInput}
                                        >
                                            <FormInput
                                                name="max"
                                                useFormik={false}
                                                noErrorMessage
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                inputProps={{
                                                    min: 0,
                                                }}
                                                onChange={(e) => {
                                                    const val = e.target.value;
                                                    if (
                                                        val >= 0 &&
                                                        val < 1000
                                                    ) {
                                                        setMaxPointsFilter(val);
                                                    }
                                                }}
                                                value={maxPointsFilter}
                                            />
                                        </Grid>
                                    </MenuItem>,
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        style={{ position: 'relative', marginTop: 6 }}
                    >
                        {path !== '/actions/deactivated' && (
                            <TableManager
                                type={PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME}
                                title={t('active')}
                                filter={textFilter}
                                data={includedTableData}
                                onChangeActive={addExclude}
                                initialSortBy={initialSortActive}
                                shouldShowMultiSelect={canDeactivateActions}
                                actionDuplicationCommunities={
                                    actionDuplicationCommunities
                                }
                                fetchDataFunc={fetchActionData}
                                activeCommunity={activeCommunity}
                                refetchData={refetchData}
                                intercomTargetPrefix={`${INTERCOM_TARGET_PREFIX}Active`}
                            />
                        )}
                        {path !== '/actions/active' && canDeactivateActions && (
                            <TableManager
                                type={PIN_ADMIN_VISIBILITY_EXCLUDED}
                                title={t('deactivated')}
                                data={excludedTableData}
                                filter={textFilter}
                                onChangeActive={addInclude}
                                initialSortBy={initialSortDeactivated}
                                actionDuplicationCommunities={
                                    actionDuplicationCommunities
                                }
                                fetchDataFunc={fetchActionData}
                                activeCommunity={activeCommunity}
                                refetchData={refetchData}
                                intercomTargetPrefix={`${INTERCOM_TARGET_PREFIX}Deactivated`}
                            />
                        )}
                    </Grid>
                </Fragment>
                <SnackBarAPIResponse
                    errors={[
                        ...(includedTableData?.errors || []),
                        ...(excludedTableData?.errors || []),
                    ]}
                    clearResponse={clearErrors}
                />
            </Grid>
        );
    },
);

AdminActionsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    includedTableData: actionTablePropType.isRequired,
    excludedTableData: actionTablePropType.isRequired,
    fetchActions: PropTypes.func.isRequired,
    activeCommunity: activeCommunityPropType.isRequired,
    actionDuplicationCommunities: availableCommunitiesPropType.isRequired,
    addInclude: PropTypes.func.isRequired,
    addExclude: PropTypes.func.isRequired,
    getThemes: PropTypes.func.isRequired,
    initialSortActive: sortPropType,
    initialSortDeactivated: sortPropType,
    themes: PropTypes.array,
    themesLoading: PropTypes.bool,
    clearErrors: PropTypes.func,
    sdgs: PropTypes.object,
    getSDGs: PropTypes.func,
};

AdminActionsScreen.defaultProps = {
    initialSortActive: [{ id: 'name', desc: false }],
    initialSortDeactivated: [{ id: 'name', desc: false }],
    themes: [],
    themesLoading: false,
    clearErrors: noop,
    sdgs: [],
    getSDGs: noop,
};

export const StyledAdminActionsScreen = withStyles(styles)(AdminActionsScreen);

const mapStateToProps = (state) => ({
    activeCommunity: getActiveCommunity(state),
    actionDuplicationCommunities: getActionDuplicationCommunities(state),
    includedTableData: getActions(
        state,
        PIN_ADMIN_VISIBILITY_INCLUDED_FOR_THEME,
    ),
    excludedTableData: getActions(state, PIN_ADMIN_VISIBILITY_EXCLUDED),
    themes: getThemes(state),
    themesLoading: areThemesLoading(state),
    sdgs: getSDGs(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchActions: (communityId, filters) =>
        dispatch(actionsRequested(communityId, filters)),
    addExclude: (actionIds, communityIds, filters) =>
        dispatch(addExcludeActionRequest(actionIds, communityIds, filters)),
    addInclude: (actionIds, communityIds, filters) =>
        dispatch(addIncludeActionRequest(actionIds, communityIds, filters)),
    getThemes: (communityId) => dispatch(themesRequested(communityId, true)),
    clearErrors: () => dispatch(clearActionErrors()),
    getSDGs: () => dispatch(sdgsRequested()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledAdminActionsScreen);
