export const FLAGGED_CONTENT_REQUESTED = 'FLAGGED_CONTENT_REQUESTED';
export const FLAGGED_CONTENT_REQUEST_SUCCEEDED =
    'FLAGGED_CONTENT_REQUEST_SUCCEEDED';
export const FLAGGED_CONTENT_REQUEST_FAILED = 'FLAGGED_CONTENT_REQUEST_FAILED';

export const FLAG_EXCLUDE_REQUESTED = 'FLAG_EXCLUDE_REQUESTED';
export const FLAG_EXCLUDE_REQUEST_SUCCEEDED = 'FLAG_EXCLUDE_REQUEST_SUCCEEDED';
export const FLAG_EXCLUDE_REQUEST_FAILED = 'FLAG_EXCLUDE_REQUEST_FAILED';

export const FLAG_IGNORE_REQUESTED = 'FLAG_IGNORE_REQUESTED';
export const FLAG_IGNORE_REQUEST_SUCCEEDED = 'FLAG_IGNORE_REQUEST_SUCCEEDED';
export const FLAG_IGNORE_REQUEST_FAILED = 'FLAG_IGNORE_REQUEST_FAILED';

export const FLAG_REPORT_REQUESTED = 'FLAG_REPORT_REQUESTED';
export const FLAG_REPORT_REQUEST_SUCCEEDED = 'FLAG_REPORT_REQUEST_SUCCEEDED';
export const FLAG_REPORT_REQUEST_FAILED = 'FLAG_REPORT_REQUEST_FAILED';
export const FLAG_REPORT_CLEAR_MESSAGES = 'FLAG_REPORT_CLEAR_MESSAGES';

export const flaggedContentRequested = (request) => ({
    type: FLAGGED_CONTENT_REQUESTED,
    payload: {
        request,
    },
});

export const flaggedContentRequestSucceeded = (response) => ({
    type: FLAGGED_CONTENT_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const flaggedContentRequestFailed = (errors) => ({
    type: FLAGGED_CONTENT_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const flagExcludeRequested = (request) => ({
    type: FLAG_EXCLUDE_REQUESTED,
    payload: {
        request,
    },
});

export const flagExcludeRequestSucceeded = (response) => ({
    type: FLAG_EXCLUDE_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const flagExcludeRequestFailed = (errors) => ({
    type: FLAG_EXCLUDE_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const flagIgnoreRequested = (request) => ({
    type: FLAG_IGNORE_REQUESTED,
    payload: {
        request,
    },
});

export const flagIgnoreRequestSucceeded = (response) => ({
    type: FLAG_IGNORE_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const flagIgnoreRequestFailed = (errors) => ({
    type: FLAG_IGNORE_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const activityReportRequested = (contentId, commentId) => ({
    type: FLAG_REPORT_REQUESTED,
    payload: {
        contentId,
        commentId,
    },
});

export const profileReportRequested = (contentId, commentId) => ({
    type: FLAG_REPORT_REQUESTED,
    payload: {
        contentId,
        commentId,
        type: 'user',
    },
});

export const activityReportRequestSucceeded = (contentId) => ({
    type: FLAG_REPORT_REQUEST_SUCCEEDED,
    payload: {
        contentId,
    },
});
export const activityReportRequestFailed = (errors) => ({
    type: FLAG_REPORT_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearFlagMessages = () => ({
    type: FLAG_REPORT_CLEAR_MESSAGES,
});
