import React from 'react';
import Grow from '@material-ui/core/Grow';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Link from '@material-ui/core/Link';
import HelpIcon from '@material-ui/icons/Help';
import DoneIcon from '@material-ui/icons/Done';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../Button';
import useStyles from './styles';
import { noop } from '../../utils';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core';
import { dismissInfoCard } from '../../user/actions';
import { getDismissedInfoCardIds } from '../../user/selectors';
import { useTranslation } from 'react-i18next';

export const InfoCard = ({
    infoCardId,
    hideCard,
    image,
    helpLink,
    onClose,
    dismissedInfoCardIds,
    isFullWidth,
    children,
    cardActions,
    ...props
}) => {
    const classes = useStyles({ isFullWidth });
    const theme = useTheme();
    const { t } = useTranslation();
    const isDismissed = hideCard || dismissedInfoCardIds.includes(infoCardId);

    if (!cardActions) {
        cardActions = [
            <Button
                key="doneButton"
                color="white"
                className={classes.button}
                textColor={theme.palette.primary.main}
                onClick={() => onClose(infoCardId)}
                data-test="doneButton"
            >
                <DoneIcon />
                {t('acknowledge')}
            </Button>,
        ];

        if (helpLink) {
            cardActions.push(
                <Link
                    className={clsx(classes.button, classes.helpBtn)}
                    target="_blank"
                    rel="noopener"
                    href={helpLink}
                    data-test="helpLink"
                >
                    <HelpIcon className={classes.helpIcon} />
                    {t('moreHelp')}
                </Link>,
            );
        }
    }

    return (
        <Grow in={!isDismissed} appear={false} unmountOnExit={true}>
            <Card
                className={classes.root}
                variant="outlined"
                {...props}
                data-test="infoCard"
            >
                <CardContent className={classes.content}>
                    <Grid
                        container
                        spacing={image ? 2 : 0}
                        justifyContent="space-evenly"
                    >
                        {image && (
                            <Grid item xs={4} sm={3} md={2}>
                                <CardMedia
                                    component="img"
                                    className={classes.media}
                                    image={image}
                                    data-test="bannerImage"
                                    role="presentation"
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            container
                            xs={12}
                            sm={image ? 9 : 12}
                            md={image ? 10 : 12}
                        >
                            <Grid item>{children}</Grid>
                            <Grid item className={classes.cardActionContainer}>
                                <CardActions className={classes.cardAction}>
                                    {cardActions}
                                </CardActions>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grow>
    );
};

InfoCard.propTypes = {
    infoCardId: PropTypes.oneOf([PropTypes.string, PropTypes.number])
        .isRequired,
    image: PropTypes.string,
    helpLink: PropTypes.string,
    hideCard: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    dismissedInfoCardIds: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    cardActions: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

InfoCard.defaultProps = {
    image: null,
    helpLink: '',
    afterClose: noop,
    isFullWidth: false,
    hideCard: false,
    dismissedInfoCardIds: [],
    cardActions: [],
};

const mapStateToProps = (state) => ({
    dismissedInfoCardIds: getDismissedInfoCardIds(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: (infoCardId) => dispatch(dismissInfoCard(infoCardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoCard);
