import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '1280px',
        position: 'relative',
    },
    templates: {
        marginTop: '30px',
        position: 'relative',
        minHeight: '80px',
    },
    confirmButton: {
        paddingTop: '40px',
        paddingBottom: '40px',
    },
    descriptionContainer: {
        marginTop: '30px',
    },
}));

export default useStyles;
