import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AddBox, Clear, IndeterminateCheckBox } from '@material-ui/icons';
import { Grid, IconButton, Tooltip, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
    activeButtonsCell as rawActiveButtonsCell,
    communityButtonsCell,
    deactivatedButtonsCell as rawDeactivatedButtonsCell,
    tableColumns as rawTableColumns,
} from './constants';
import { Can } from '../../casl/ability-context';
import Table from '../../common/Table';
import { noop } from '../../common/utils';
import styles from './styles';
import {
    translateButtonTooltips,
    translateColumns,
} from '../../common/Table/transforms';
import Delete from '../../common/Delete';
import PermissionsEditor from '../../common/PermissionsEditor';
import SwitchOrganization from '../../common/SwitchOrganization';
import ConfirmModal from '../../common/ConfirmModal';
import { activeCommunityPropType } from '../../user/propTypes';
import ActivateModal from '../../common/ActivateModal';
import { ACTIVE_STATUS_ACTIVE } from '../../user/constants';

const TableManager = ({
    classes,
    type,
    title,
    data,
    isLoading,
    onChangeActive,
    onDelete,
    activeCommunity,
    permissions,
    roles,
    pageIndex,
    updateUserPermissions,
    currentUserPermissions,
    moveUserOrganization,
    fetchDataFunc,
    searchFunc,
    searchString,
    searchPlaceholder,
    totalCount,
    intercomTargetPrefix,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const tableColumns = translateColumns(t, rawTableColumns);
    const activeButtonsCell = translateButtonTooltips(t, rawActiveButtonsCell);
    const deactivatedButtonsCell = translateButtonTooltips(
        t,
        rawDeactivatedButtonsCell,
    );

    const [selectedRowIds, setSelectedRowIds] = useState({});
    const [rowsData, setRowsData] = useState({});

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [permissionsEditorOpen, setPermissionsEditorOpen] = useState(false);
    const [permissionsEditorMember, setPermissionsEditorMember] = useState(
        null,
    );

    const [switchOrganizationOpen, setSwitchOrganizationOpen] = useState(false);
    const [switchOrganizationUser, setSwitchOrganizationUser] = useState(null);
    const [
        switchOrganizationSelectedOrg,
        setSwitchOrganizationSelectedOrg,
    ] = useState(null);
    const [
        switchOrganizationConfirmationOpen,
        setSwitchOrganizationConfirmationOpen,
    ] = useState(false);

    const isOrganization =
        activeCommunity &&
        activeCommunity?.organizationId === activeCommunity?.id;

    if (!isOrganization) {
        const communityButtons = translateButtonTooltips(
            t,
            communityButtonsCell,
        );
        activeButtonsCell.push(...communityButtons);
        deactivatedButtonsCell.push(...communityButtons);
    }

    const getDeleteItem = (deleteId) => {
        const foundItem =
            data.find(({ id }) => {
                return id === deleteId;
            }) || {};
        const { id: identifier, icon: iconUrl, ...restItem } = foundItem;
        return {
            identifier,
            iconUrl,
            ...restItem,
        };
    };

    const changeActivate = useCallback(
        (id, type) => {
            const currRows = !id
                ? selectedRowIds.reduce((list, itemId) => {
                      const item = data.find((item) => item.id === itemId);
                      return item ? [...list, item] : list;
                  }, [])
                : data.filter((item) => item.id === id);

            if (!currRows.length) return;

            setRowsData({
                isOpen: true,
                type,
                items: currRows.map(({ id, name, icon }) => ({
                    name,
                    iconUrl: icon,
                    identifier: id,
                })),
            });
        },
        [selectedRowIds, data],
    );

    const handleActions = useCallback(
        (id, key) => {
            const member =
                data.find(({ id: currentId }) => currentId === id) || {};
            switch (key) {
                case 'setUserRole': {
                    setPermissionsEditorMember(member);
                    setPermissionsEditorOpen(true);
                    break;
                }

                case 'moveUserOrg':
                    setSwitchOrganizationUser(member);
                    setSwitchOrganizationOpen(true);
                    break;

                case 'viewUserProfile':
                    history.push(`/profile/${id}`);
                    break;

                case 'deleteUser':
                    setDeleteModalOpen(true);
                    setDeleteId(id);
                    break;
                case 'removeUserFromCommunity':
                    changeActivate(id, 'abc');
                    break;
                case 'deactivateUser':
                case 'reactivateUser':
                    changeActivate(id);
                    break;

                default:
                    alert({ id, key });
            }
        },
        [changeActivate, data, history],
    );

    const handleConfirmActivate = (type) => {
        const ids = rowsData.items.map(({ identifier }) => identifier);
        if (type === 'abc') {
            onDelete(activeCommunity.id, ids);
        } else {
            onChangeActive(ids);
        }
        setRowsData({});
    };

    const handleClosePermissionsEditor = () => {
        setPermissionsEditorMember(null);
        setPermissionsEditorOpen(false);
    };

    const renderHeadButtons = useCallback(() => {
        const disabled = Object.keys(selectedRowIds).length === 0;
        if (isOrganization) {
            return (
                <Can
                    a="User"
                    I={
                        type === ACTIVE_STATUS_ACTIVE
                            ? 'reactivate'
                            : 'deactivate'
                    }
                >
                    {() => (
                        <IconButton
                            onClick={() => changeActivate()}
                            disabled={disabled}
                        >
                            <Tooltip
                                placement="top"
                                title={
                                    type === ACTIVE_STATUS_ACTIVE
                                        ? t('deactivate')
                                        : t('activate')
                                }
                            >
                                {type === ACTIVE_STATUS_ACTIVE ? (
                                    <IndeterminateCheckBox />
                                ) : (
                                    <AddBox />
                                )}
                            </Tooltip>
                        </IconButton>
                    )}
                </Can>
            );
        } else {
            return (
                <Can I="remove" a="UserFromCommunity">
                    {() => (
                        <IconButton
                            onClick={() => changeActivate(null, 'abc')}
                            disabled={disabled}
                        >
                            <Tooltip placement="top" title={t('remove')}>
                                <Clear />
                            </Tooltip>
                        </IconButton>
                    )}
                </Can>
            );
        }
    }, [isOrganization, t, selectedRowIds, type, changeActivate]);

    const getActivateProps = () => {
        if (rowsData.type === 'abc') {
            return {
                headerKey: 'usersModal.header.removeFromCommunity',
                description: t('usersModal.description.removeFromCommunity'),
                confirmBtnText: t('remove'),
            };
        } else {
            return {
                isActivate: type !== ACTIVE_STATUS_ACTIVE,
                headerKey: 'usersModal.areYouSure',
                description:
                    type !== ACTIVE_STATUS_ACTIVE
                        ? t('usersModal.description.activate')
                        : t('usersModal.description.deactivate'),
            };
        }
    };

    return (
        <Grid
            container
            className={classes.relative}
            data-intercom-target={`${intercomTargetPrefix} Container`}
        >
            <Table
                title={title}
                columns={tableColumns}
                data={data}
                noItemFoundText={t('users.notFound')}
                multiActionButton={renderHeadButtons()}
                buttonsCell={
                    type === ACTIVE_STATUS_ACTIVE
                        ? activeButtonsCell
                        : deactivatedButtonsCell
                }
                onButtonsCell={handleActions}
                onRowSelect={setSelectedRowIds}
                pageIndex={pageIndex}
                fetchDataFunc={fetchDataFunc}
                totalCount={totalCount}
                loading={isLoading}
                searchFunc={searchFunc}
                searchString={searchString}
                searchPlaceholder={searchPlaceholder}
                intercomTargetPrefix={intercomTargetPrefix}
                activeCommunityId={activeCommunity.id}
            />
            <ActivateModal
                isOpen={rowsData.isOpen || false}
                items={rowsData.items || []}
                onClose={() => setRowsData({})}
                onCancel={() => setRowsData({})}
                onConfirm={() => handleConfirmActivate(rowsData.type)}
                {...getActivateProps()}
            />
            <Delete
                deleteWhat="User"
                isOpen={deleteModalOpen}
                description={t('usersModal.description.delete')}
                onClose={() => {
                    setDeleteModalOpen(false);
                    setDeleteId(null);
                }}
                onCancel={() => {
                    setDeleteModalOpen(false);
                    setDeleteId(null);
                }}
                onConfirm={() => {
                    onDelete(activeCommunity.id, [deleteId]);
                    setDeleteModalOpen(false);
                    setDeleteId(null);
                }}
                item={getDeleteItem(deleteId)}
            />
            {permissionsEditorOpen && (
                <PermissionsEditor
                    isOpen={permissionsEditorOpen}
                    member={permissionsEditorMember}
                    onSave={(communityId, body) => {
                        handleClosePermissionsEditor();
                        updateUserPermissions(
                            communityId,
                            body?.userIds,
                            body?.permissions,
                            body?.role,
                        );
                    }}
                    onCancel={() => {
                        handleClosePermissionsEditor();
                    }}
                    onClose={() => {
                        handleClosePermissionsEditor();
                    }}
                    activeCommunity={activeCommunity}
                    userPermissions={currentUserPermissions}
                    roles={roles}
                    permissions={permissions}
                />
            )}
            {switchOrganizationOpen && (
                <>
                    <SwitchOrganization
                        isOpen={switchOrganizationOpen}
                        onClose={() => {
                            setSwitchOrganizationOpen(false);
                            setSwitchOrganizationUser(null);
                            setSwitchOrganizationSelectedOrg(null);
                        }}
                        onConfirm={() => {
                            setSwitchOrganizationConfirmationOpen(true);
                        }}
                        availableOrganizations={
                            currentUserPermissions
                                ? Object.entries(currentUserPermissions).reduce(
                                      (accumulatedOrgs, [, userPermission]) => {
                                          const actions =
                                              userPermission?.actions || [];
                                          if (
                                              actions.includes('moveUserOrg') &&
                                              actions.includes('sendOrgInvite')
                                          ) {
                                              accumulatedOrgs.push({
                                                  ...userPermission?.content,
                                                  title:
                                                      userPermission?.content
                                                          ?.name,
                                                  url:
                                                      userPermission?.content
                                                          ?.imageSizes[0]?.url,
                                              });
                                          }
                                          return accumulatedOrgs;
                                      },
                                      [],
                                  )
                                : []
                        }
                        selectedOrganization={switchOrganizationSelectedOrg}
                        onChange={(e, value) => {
                            setSwitchOrganizationSelectedOrg(value);
                        }}
                        organizationName={activeCommunity?.name}
                        userName={switchOrganizationUser?.name}
                    />
                    <ConfirmModal
                        title={t('organization.confirm')}
                        open={switchOrganizationConfirmationOpen}
                        onConfirm={() => {
                            moveUserOrganization(
                                activeCommunity?.id,
                                switchOrganizationUser?.id,
                                switchOrganizationSelectedOrg?.id,
                            );
                            setSwitchOrganizationOpen(false);
                            setSwitchOrganizationUser(null);
                            setSwitchOrganizationSelectedOrg(null);
                            setSwitchOrganizationConfirmationOpen(false);
                        }}
                        onCancel={() =>
                            setSwitchOrganizationConfirmationOpen(false)
                        }
                        onClose={() =>
                            setSwitchOrganizationConfirmationOpen(false)
                        }
                    >
                        {t('organization.confirmMessage')}
                    </ConfirmModal>
                </>
            )}
        </Grid>
    );
};

TableManager.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pageIndex: PropTypes.number.isRequired,
    onChangeActive: PropTypes.func,
    onDelete: PropTypes.func,
    activeCommunity: activeCommunityPropType.isRequired,
    permissions: PropTypes.array,
    roles: PropTypes.array,
    updateUserPermissions: PropTypes.func,
    currentUserPermissions: PropTypes.object,
    moveUserOrganization: PropTypes.func,
    fetchDataFunc: PropTypes.func.isRequired,
    searchFunc: PropTypes.func,
    searchString: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    totalCount: PropTypes.number.isRequired,
    intercomTargetPrefix: PropTypes.string,
};

TableManager.defaultProps = {
    onChangeActive: noop,
    onDelete: noop,
    permissions: [],
    roles: [],
    updateUserPermissions: noop,
    currentUserPermissions: {},
    moveUserOrganization: noop,
    fetchDataFunc: noop,
    searchFunc: noop,
    searchString: '',
    searchPlaceholder: '',
    intercomTargetPrefix: 'Member',
};

export default withStyles(styles)(TableManager);
