import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes, { shape } from 'prop-types';

import {
    Checkbox,
    Grid,
    Link,
    Typography,
    withStyles,
} from '@material-ui/core';
import styles from './styles';
import Form from '../../Form';
import SnackBarAPIResponse from '../../SnackbarAlert/SnackBarAPIResponse';
import * as Yup from 'yup';
import { noop } from '../../utils';
import { getPrivacyLink, getTermsLink } from '../../../utils';
import { VARIANT_DEFAULT, VARIANT_SSO } from './constants';
import { PROHIBITED_DOMAINS } from '../../../screens/SignInScreens/OAuthButtons/GoogleButton/constants';
import SignUpFormFields from './SignUpFormFields';

const SignUpForm = ({
    classes,
    initialFormValues,
    onSubmit,
    errors,
    clearSnackBar,
    checked,
    toggleChecked,
    toggleSubscribed,
    variant,
}) => {
    const { t } = useTranslation();

    const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/*)$/gm;

    const domain = initialFormValues?.email?.split('@')?.[1];

    let validationSchema = {
        orgName: Yup.string().required(t('onBoarding.community.nameRequired')),
        website: Yup.string().matches(
            urlRegex,
            t('onBoarding.community.websiteValid'),
        ),
    };

    const isSSO = variant === VARIANT_SSO;

    if (!isSSO) {
        validationSchema = {
            ...validationSchema,
            firstName: Yup.string().required(
                t('onBoarding.welcome.nameRequired.first'),
            ),
            lastName: Yup.string().required(
                t('onBoarding.welcome.nameRequired.last'),
            ),
            email: Yup.string()
                .email(t('validation.emailInvalid'))
                .required(t('validation.emailRequired')),
        };
    }

    const LinkText = ({ value, link }) => {
        return (
            <Link href={link} target="_blank" className={classes.linkText}>
                <u>{t(value)}</u>
            </Link>
        );
    };

    return (
        <Form
            initialValues={initialFormValues}
            validationSchema={Yup.object().shape(validationSchema)}
            submitBtnText={t(isSSO ? 'next' : 'onBoarding.movement')}
            onSubmit={onSubmit}
            data-test="emailForm"
            disabled={!checked}
            submitBtnClass={classes.onboardingSubmit}
        >
            <SignUpFormFields
                showEmailField={!isSSO}
                showNameFields={!isSSO}
                showSSOField={
                    isSSO && domain && !PROHIBITED_DOMAINS.includes(domain)
                }
            />
            <Typography variant="body2" className={classes.asteriskRequired}>
                {t('onBoarding.asteriskRequired')}
            </Typography>
            <Grid container justifyContent="center">
                <Grid
                    container
                    item
                    xs={12}
                    sm={10}
                    justifyContent="flex-start"
                >
                    <Grid item>
                        <Typography variant="body2">
                            <Checkbox
                                onChange={toggleSubscribed}
                                color="primary"
                                className={classes.checkbox}
                            />
                            {t('onBoarding.newsletter')}.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            <Checkbox
                                onChange={toggleChecked}
                                color="primary"
                                className={classes.checkbox}
                            />
                            <Trans t={t} i18nKey="onBoarding.agreement">
                                <LinkText
                                    value="privacyPolicy"
                                    link={getPrivacyLink()}
                                />{' '}
                                <LinkText
                                    value="termsOfService"
                                    link={getTermsLink()}
                                />
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <SnackBarAPIResponse
                errors={errors}
                clearResponse={clearSnackBar}
            />
        </Form>
    );
};

SignUpForm.propTypes = {
    initialFormValues: shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        employeeCount: PropTypes.object,
        orgName: PropTypes.string,
        website: PropTypes.string,
        ssoEnabled: PropTypes.bool,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearSnackBar: PropTypes.func,
    classes: PropTypes.object.isRequired,
    checked: PropTypes.bool.isRequired,
    toggleChecked: PropTypes.func.isRequired,
    toggleSubscribed: PropTypes.func.isRequired,
    variant: PropTypes.oneOf([VARIANT_DEFAULT, VARIANT_SSO]),
};

SignUpForm.defaultProps = {
    initialFormValues: { email: '' },
    onSubmit: noop,
    clearSnackBar: noop,
    variant: VARIANT_DEFAULT,
};

export default withStyles(styles)(SignUpForm);
