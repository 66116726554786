import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    tooltip: {
        color: theme.palette.white.main,
    },
    icon: {
        color: theme.custom.tooltipIconColor,
        marginLeft: '3px',

        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
