import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    checkboxRoot: {
        cursor: 'auto',
        '&:hover': {
            backgroundColor: theme.custom.bgLightAccentColor,
        },
        '&.Mui-checked:hover': {
            backgroundColor: theme.custom.bgLightAccentColor,
        },
    },
    checkboxSecondaryColor: {
        '&.Mui-checked': {
            color: '#4991e7',
        },
    },
    form: {
        width: '100%',
    },
    customize: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    customizeLabel: {
        fontWeight: 700,
        fontSize: '18px',
    },
    sponsorLabel: {
        fontWeight: 600,
        fontSize: '14px',
    },
    dropzoneRoot: {
        width: '120px',
        height: '120px',
        border: `2px dashed ${theme.custom.textLightSecondaryColor}`,
        cursor: 'pointer',

        '&:hover': {
            borderColor: '#fff',
            backgroundColor: theme.custom.bgAccentColor,
        },
    },
    dndLabel: {
        fontSize: '85%',
    },
    previewLabel: {
        fontSize: '14px',
        fontWeight: 600,
        marginTop: '10px',
        marginBottom: '10px',
    },
    preview: {
        position: 'relative',
    },
    previewImageWrapper: {
        position: 'relative',
        maxWidth: '100px',
    },
    previewImage: {
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
    },
    sponsorLogo: {
        position: 'absolute',
        top: 5,
        right: 5,
    },
    previewSummary: {
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '10px',
        minHeight: '112px',

        '&:not(:hover)': {
            backgroundColor: theme.custom.bgLightColor,
        },
    },
    previewSummaryTitle: {
        marginBottom: '5px',
        fontSize: '14px',
        fontWeight: 600,
    },
    previewSummaryDescription: {
        marginBottom: '10px',
        fontSize: '14px',
        color: theme.custom.textDarkSecondaryColor,
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export default useStyles;
