import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Notifications as IconNotifications,
    Visibility as IconVisibility,
} from '@material-ui/icons';

import useStyles from './styles';

import RequestLoader from '../../../common/RequestLoader';
import ErrorList from '../../../common/errors/ErrorList';
import Leaderboard from '../../../common/Leaderboard';
import CommonPropTypes from '../../../common/propTypes';
import { teamLeaderboardRequested } from '../../../challenges/actions';
import {
    getChallengeName,
    getChallengeScoringType,
    getParticipationStatus,
    getPresentableTeamLeaderboardItems,
    getTeamLeaderboardErrors,
    isTeamLeaderboardLoading,
} from '../../../challenges/selectors';
import { useHistory } from 'react-router-dom';
import { openCreateNotification } from '../../../notifications/actions';
import { getAppImages, noop } from '../../../utils';
import { AbilityContext } from '../../../casl/ability-context';
import { Typography } from '@material-ui/core';
import { getApplicationStrings } from '../../../init/selectors';
import ConfirmModal from '../../../common/ConfirmModal';
import InfoTooltip from '../../../common/InfoTooltip';

const TeamDetailsLeaderboard = ({
    communityId,
    teamId,
    requestLeaderboard,
    loading,
    errors,
    leaderboardItems,
    onNotificationClick,
    challengeId,
    challengeName,
    appStrings,
    scoringType,
    participationStatus,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const ability = useContext(AbilityContext);

    const [scoreExplanationTitle, setScoreExplanationTitle] = useState('');
    const [scoreExplanation, setScoreExplanation] = useState('');
    const [scoreModalOpen, setScoreModalOpen] = useState(false);

    useEffect(() => {
        requestLeaderboard({
            communityId,
            teamId,
        });
    }, [requestLeaderboard, communityId, teamId]);

    const getTeamScoreExplanation = useCallback(() => {
        let explanation;
        scoringType === 'movingAverage'
            ? (explanation =
                  appStrings?.challenge?.scoreModals?.team?.movingAverage
                      ?.detail)
            : (explanation =
                  appStrings?.challenge?.scoreModals?.team?.basicAggregate
                      ?.detail);
        return explanation;
    }, [appStrings, scoringType]);

    const handleTeamScoreTooltipClick = useCallback(() => {
        setScoreExplanationTitle(
            appStrings?.challenge?.scoreModals?.team?.scoreTitle,
        );
        setScoreExplanation(getTeamScoreExplanation());
        setScoreModalOpen(true);
    }, [appStrings, getTeamScoreExplanation]);

    const isPresentable = useMemo(
        () => !loading && errors == null && leaderboardItems != null,
        [loading, errors, leaderboardItems],
    );
    const canViewUserDetails = ability.can('view', 'UserProfile');
    return (
        <div className={classes.container}>
            <RequestLoader
                isLoading={loading}
                title={`${t('loading.leaderboard')}...`}
            />
            <ErrorList errors={errors} />
            {isPresentable ? (
                <>
                    <ConfirmModal
                        cancelBtnHidden
                        confirmBtnText={t('Ok')}
                        title={scoreExplanationTitle}
                        isOpen={scoreModalOpen}
                        onConfirm={() => setScoreModalOpen(false)}
                        onClose={() => setScoreModalOpen(false)}
                    >
                        <Typography class={classes.scoringExplanationText}>
                            {scoreExplanation}
                        </Typography>
                    </ConfirmModal>
                    {participationStatus === 'joined' && (
                        <Typography className={classes.scoringExplanationText}>
                            {
                                appStrings?.challenge?.scoreModals?.team
                                    ?.scoreTitle
                            }
                            <InfoTooltip
                                onClick={handleTeamScoreTooltipClick}
                                text={t('learnMore')}
                                intercomTarget="teamScoreExplanation"
                            />
                        </Typography>
                    )}
                    <Leaderboard
                        leaderboardCardProps={{
                            imageIsRounded: true,
                            Icon: IconNotifications,
                            ViewIcon: canViewUserDetails && IconVisibility,
                            defaultImageSrc: getAppImages().defaultProfileIcon,
                            iconTooltip: t('notifications.send'),
                            iconTooltipPlacement: 'top',
                            onItemClick: (userId) =>
                                history.push(`/profile/${userId}`),
                            onIconClick: (event, itemId) => {
                                const item = leaderboardItems.find(
                                    ({ id }) => id === itemId,
                                );
                                onNotificationClick({
                                    subjectContentID: challengeId,
                                    subjectContentType: 'challenge',
                                    audienceType: 'users',
                                    audiences: [item], // TODO Can't send the whole team as the audience, the API call fails, hmm.
                                    subject: challengeName,
                                });
                            },
                        }}
                        items={leaderboardItems}
                    />
                </>
            ) : null}
        </div>
    );
};

TeamDetailsLeaderboard.propTypes = {
    communityId: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    requestLeaderboard: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    leaderboardItems: PropTypes.array,
    onNotificationClick: PropTypes.func,
    challengeId: PropTypes.string.isRequired,
    challengeName: PropTypes.string.isRequired,
    appStrings: PropTypes.object,
    scoringType: PropTypes.string.isRequired,
    participationStatus: PropTypes.string.isRequired,
};

TeamDetailsLeaderboard.defaultProps = {
    errors: null,
    leaderboardItems: null,
    onNotificationClick: noop,
    appStrings: {},
};

const mapStateToProps = (state) => ({
    loading: isTeamLeaderboardLoading(state),
    errors: getTeamLeaderboardErrors(state),
    leaderboardItems: getPresentableTeamLeaderboardItems(state),
    challengeName: getChallengeName(state),
    appStrings: getApplicationStrings(state),
    participationStatus: getParticipationStatus(state),
    scoringType: getChallengeScoringType(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestLeaderboard: (request) =>
        dispatch(teamLeaderboardRequested(request)),
    onNotificationClick: (notification) =>
        dispatch(openCreateNotification(notification)),
});

const ConnectedTeamDetailsLeaderboard = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TeamDetailsLeaderboard);
export default ConnectedTeamDetailsLeaderboard;
