import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';

import useStyles from './styles';
import Button from '../../../common/Button';
import InfoTooltip from '../../../common/InfoTooltip';
import { INTERCOM_TARGET_PREFIX } from '../constants';

const MainControls = ({ discardDisabled, saveDisabled, onDiscard, onSave }) => {
    const { t } = useTranslation();

    const classes = useStyles();

    return (
        <Grid
            container
            item
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.controls}
            spacing={2}
        >
            <InfoTooltip
                text={t('Help')}
                intercomTarget={`${INTERCOM_TARGET_PREFIX}Discard`}
            />
            <Grid container item className={classes.buttonContainer}>
                <Button
                    color="danger"
                    variant="outlined"
                    className={classes.button}
                    disabled={discardDisabled}
                    onClick={onDiscard}
                >
                    <Clear fontSize="large" className={classes.buttonIcon} />
                    {t('Discard Changes')}
                </Button>
            </Grid>
            <Grid container item className={classes.buttonContainer}>
                <Button
                    color="accent"
                    className={classes.button}
                    disabled={saveDisabled}
                    onClick={onSave}
                >
                    <Done fontSize="large" className={classes.buttonIcon} />
                    {t('Save Changes')}
                </Button>
            </Grid>
        </Grid>
    );
};

MainControls.propTypes = {
    discardDisabled: PropTypes.bool.isRequired,
    saveDisabled: PropTypes.bool.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default MainControls;
