import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    withStyles,
    Typography,
    Grid,
    TextareaAutosize,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';

import styles from './styles';

import ConfirmModal from '../ConfirmModal';
import Tooltip from '../Tooltip';

const ChallengeDescription = ({
    classes,
    isOpen,
    setOpen,
    onClose,
    onCancel,
    onConfirm,
    description,
}) => {
    const { t } = useTranslation();

    const Title = () => (
        <Typography variant="h4" className={classes.title}>
            {t('challenge.descriptionFieldTitle')}
            <Tooltip title={t('viewDocs')} placement="right">
                <Help
                    fontSize="small"
                    className={classes.helpIcon}
                    data-intercom-target="modalTitle"
                />
            </Tooltip>
        </Typography>
    );

    const [newDescription, setNewDescription] = useState('');
    useEffect(() => {
        setNewDescription(description);
    }, [description, setNewDescription]);

    const handleClose = () => {
        onClose();
        setOpen(false);
    };
    const handleCancel = () => {
        onCancel();
        setOpen(false);
    };
    const handleConfirm = () => onConfirm(newDescription);

    const onTextChange = (event) => setNewDescription(event.target.value);

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={<Title />}
            confirmBtnText={t('OK')}
            confirmBtnType="accent"
            cancelBtnText={t('cancel')}
            cancelBtnType="default"
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        >
            <Grid container direction="column">
                <Grid item className={classes.label}>
                    {t('challenge.descriptionTitle')}
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        rowsMin={5}
                        value={newDescription}
                        onChange={onTextChange}
                        className={classes.textarea}
                    />
                </Grid>
            </Grid>
        </ConfirmModal>
    );
};

ChallengeDescription.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChallengeDescription);
