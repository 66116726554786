import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0 8px',
        width: '176px',
        [theme.breakpoints.down('xs')]: {
            width: '110px',
        },
    },
    count: (props) => ({
        width: '160px',
        height: '160px',
        margin: '24px auto',
        padding: '10px',
        borderRadius: '50%',
        fontSize: '100px',
        textAlign: 'center',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${props.imageUrl})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            height: '100px',
            width: '100px',
            fontSize: '55px',
        },
    }),
    title: {
        marginBottom: '10px',
        textAlign: 'center',
    },
}));

export default useStyles;
