import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    videoControls: {
        marginTop: '6px',
        marginBottom: '20px',
    },
    buttonIcon: {
        marginRight: '3px',
    },
}));

export default useStyles;
