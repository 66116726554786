import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, withStyles } from '@material-ui/core';
import moment from 'moment';
import styles from './styles';
import DatePicker from '../../common/DatePicker';
import Button from '../../common/Button';
import TopSection from '../../common/ViewAll/TopSection';
import {
    clearCommunityPhotos,
    communityPhotosRequested,
} from '../../analytics/photos/actions';
import TopPhotos from '../../common/TopPhotos';
import {
    arePhotosLoading,
    getErrors as getPhotosErrors,
    getTopPhotos,
} from '../../analytics/photos/selectors';
import { getActiveCommunityId } from '../../user/selectors';
import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import { DATE_FORMAT } from '../AnalyticsScreen';
import { PHOTOS_BATCH_SIZE } from '../../common/TopPhotos/constants';
import { ANALYTICS_PHOTOS_SCREEN } from '../../common/PhotoCard/constants';
import queryString from 'query-string';

const AnalyticsPhotosScreen = ({
    classes,
    communityId,
    photos,
    photosErrors,
    arePhotosLoading,
    loadMorePhotosFunc,
    clearPhotos,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const queryVals = queryString.parse(location.search);
    const [startDate, setStartDate] = useState(
        queryVals.startDate
            ? moment(queryVals.startDate)
            : moment().subtract(1, 'months'),
    );
    const [endDate, setEndDate] = useState(
        queryVals.endDate ? moment(queryVals.endDate) : moment(),
    );

    const loadPhotos = () => {
        const skip = photos == null ? 0 : photos.length;

        loadMorePhotosFunc({
            communityId,
            skip,
            limit: PHOTOS_BATCH_SIZE,
            afterTimestamp: startDate.unix(),
            beforeTimestamp: endDate.unix(),
        });
    };

    const handleSubmit = () => {
        clearPhotos();
        loadPhotos();
    };

    const bottomRef = useRef(null);
    const skippedRef = useRef(null);
    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (entries.some((entry) => entry.intersectionRatio === 1)) {
                    const skip = photos == null ? 0 : photos.length;
                    if (
                        skippedRef.current == null ||
                        skippedRef.current !== skip
                    ) {
                        loadMorePhotosFunc({
                            communityId,
                            skip,
                            limit: PHOTOS_BATCH_SIZE,
                            afterTimestamp: startDate.unix(),
                            beforeTimestamp: endDate.unix(),
                        });
                        skippedRef.current = skip;
                    }
                }
            },
            { threshold: 1 },
        );
        const targetElement = bottomRef.current;
        intersectionObserver.observe(targetElement);
        return () => intersectionObserver.unobserve(targetElement);
    }, [
        startDate,
        endDate,
        photos,
        skippedRef,
        communityId,
        bottomRef,
        loadMorePhotosFunc,
    ]);

    return (
        <Grid container direction="column" className={classes.container}>
            <Grid item>
                <TopSection
                    title={t('Analytics')}
                    description={t('viewDocs')}
                    handleBack={history.goBack}
                    intercomTargetPrefix="topPhotos"
                />
            </Grid>
            <Grid item>
                <DatePicker
                    maxDate={endDate}
                    format={DATE_FORMAT}
                    value={startDate}
                    onChange={setStartDate}
                />
            </Grid>
            <Grid item>
                <DatePicker
                    minDate={startDate}
                    format={DATE_FORMAT}
                    value={endDate}
                    onChange={setEndDate}
                />
            </Grid>
            <Grid item>
                <Button color="accent" onClick={handleSubmit}>
                    {t('Submit')}
                </Button>
            </Grid>
            <hr className={classes.divider} />
            <Grid container item direction="column">
                {photos != null ? (
                    <TopPhotos
                        noHeader
                        photos={photos}
                        challengeId={null}
                        limit={photos.length}
                        screen={ANALYTICS_PHOTOS_SCREEN}
                    />
                ) : null}
                <Grid container item className={classes.loaderWrapper}>
                    <RequestLoader
                        isLoading={arePhotosLoading}
                        title={t('Loading Photos...')}
                    />
                </Grid>
                <ErrorList errors={photosErrors} />
            </Grid>
            <div ref={bottomRef} className={classes.bottom} />
        </Grid>
    );
};

AnalyticsPhotosScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    communityId: PropTypes.string.isRequired,
    photos: PropTypes.array,
    photosErrors: PropTypes.array,
    arePhotosLoading: PropTypes.bool.isRequired,
    loadMorePhotosFunc: PropTypes.func.isRequired,
    clearPhotos: PropTypes.func.isRequired,
};

AnalyticsPhotosScreen.defaultProps = {
    photos: null,
    photosErrors: [],
};

const StyledAnalyticsPhotosScreen = withStyles(styles)(AnalyticsPhotosScreen);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    photos: getTopPhotos(state),
    photosErrors: getPhotosErrors(state),
    arePhotosLoading: arePhotosLoading(state),
});
const mapDispatchToProps = (dispatch) => ({
    loadMorePhotosFunc: (request) =>
        dispatch(communityPhotosRequested(request)),
    clearPhotos: () => dispatch(clearCommunityPhotos()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledAnalyticsPhotosScreen);
