export const COMMUNITY_REQUESTED = 'COMMUNITY_REQUESTED';
export const COMMUNITY_REQUEST_SUCCEEDED = 'COMMUNITY_REQUEST_SUCCEEDED';
export const COMMUNITY_REQUEST_FAILED = 'COMMUNITY_REQUEST_FAILED';

export const UPLOAD_COMMUNITY_FILE_REQUESTED =
    'UPLOAD_COMMUNITY_FILE_REQUESTED';
export const UPLOAD_COMMUNITY_FILE_REQUEST_SUCCEEDED =
    'UPLOAD_COMMUNITY_FILE_REQUEST_SUCCEEDED';
export const UPLOAD_COMMUNITY_FILE_REQUEST_FAILED =
    'UPLOAD_COMMUNITY_FILE_REQUEST_FAILED';

export const COMMUNITY_UPDATE_REQUESTED = 'COMMUNITY_UPDATE_REQUESTED';
export const COMMUNITY_UPDATE_REQUEST_SUCCEEDED =
    'COMMUNITY_UPDATE_REQUEST_SUCCEEDED';
export const COMMUNITY_UPDATE_REQUEST_FAILED =
    'COMMUNITY_UPDATE_REQUEST_FAILED';

export const ACCOUNT_PLAN_REQUESTED = 'ACCOUNT_PLAN_REQUESTED';
export const ACCOUNT_PLAN_REQUEST_SUCCEEDED = 'ACCOUNT_PLAN_REQUEST_SUCCEEDED';
export const ACCOUNT_PLAN_REQUEST_FAILED = 'ACCOUNT_PLAN_REQUEST_FAILED';

export const JOIN_COMMUNITY_REQUESTED = 'JOIN_COMMUNITY_REQUESTED';
export const JOIN_COMMUNITY_REQUEST_SUCCEEDED =
    'JOIN_COMMUNITY_REQUEST_SUCCEEDED';
export const JOIN_COMMUNITY_REQUEST_FAILED = 'JOIN_COMMUNITY_REQUEST_FAILED';
export const CLEAR_COMMUNITY_ERRORS = 'CLEAR_JOIN_UNJOIN_ERRORS';

export const COMMUNITY_SEARCH_REQUESTED = 'COMMUNITY_SEARCH_REQUESTED';
export const COMMUNITY_SEARCH_REQUEST_SUCCEEDED =
    'COMMUNITY_SEARCH_REQUEST_SUCCEEDED';
export const COMMUNITY_SEARCH_REQUEST_FAILED =
    'COMMUNITY_SEARCH_REQUEST_FAILED';

export const UNJOIN_COMMUNITY_REQUESTED = 'UNJOIN_COMMUNITY_REQUESTED';
export const UNJOIN_COMMUNITY_REQUEST_SUCCEEDED =
    'UNJOIN_COMMUNITY_REQUEST_SUCCEEDED';
export const UNJOIN_COMMUNITY_REQUEST_FAILED =
    'UNJOIN_COMMUNITY_REQUEST_FAILED';
export const COMMUNITY_INVITE_LINK_REQUESTED =
    'COMMUNITY_INVITE_LINK_REQUESTED';
export const COMMUNITY_INVITE_LINK_REQUEST_SUCCEEDED =
    'COMMUNITY_INVITE_LINK_REQUEST_SUCCEEDED';
export const COMMUNITY_INVITE_LINK_REQUEST_FAILED =
    'COMMUNITY_INVITE_LINK_REQUEST_FAILED';

export const CREATE_COMMUNITY_REQUESTED = 'CREATE_COMMUNITY_REQUESTED';
export const CREATE_COMMUNITY_REQUEST_SUCCEEDED =
    'CREATE_COMMUNITY_REQUEST_SUCCEEDED';
export const CREATE_COMMUNITY_REQUEST_FAILED =
    'CREATE_COMMUNITY_REQUEST_FAILED';

export const COMMUNITIES_REQUESTED = 'COMMUNITIES_REQUESTED';
export const COMMUNITIES_REQUEST_SUCCEEDED = 'COMMUNITIES_REQUEST_SUCCEEDED';
export const COMMUNITIES_REQUEST_FAILED = 'COMMUNITIES_REQUEST_FAILED';

export const COMMUNITY_SHARE_IMAGE_REQUESTED =
    'COMMUNITY_SHARE_IMAGE_REQUESTED';
export const COMMUNITY_SHARE_IMAGE_REQUEST_SUCCEEDED =
    'COMMUNITY_SHARE_IMAGE_REQUEST_SUCCEEDED';
export const COMMUNITY_SHARE_IMAGE_REQUEST_FAILED =
    'COMMUNITY_SHARE_IMAGE_REQUEST_FAILED';

export const communityRequested = (communityId) => ({
    type: COMMUNITY_REQUESTED,
    payload: {
        communityId,
    },
});

export const communityRequestSucceeded = (community) => ({
    type: COMMUNITY_REQUEST_SUCCEEDED,
    payload: {
        community,
    },
});

export const communityRequestFailed = (errors) => ({
    type: COMMUNITY_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const communityUpdateRequested = (request) => ({
    type: COMMUNITY_UPDATE_REQUESTED,
    payload: {
        request,
    },
});

export const communityUpdateRequestSucceeded = (response) => ({
    type: COMMUNITY_UPDATE_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const communityUpdateRequestFailed = (errors) => ({
    type: COMMUNITY_UPDATE_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const accountPlanDataRequested = () => ({
    type: ACCOUNT_PLAN_REQUESTED,
});

export const accountPlanDataRequestSucceeded = (
    accountType,
    currentUsers,
    maxUsers,
) => ({
    type: ACCOUNT_PLAN_REQUEST_SUCCEEDED,
    payload: {
        accountType,
        currentUsers,
        maxUsers,
    },
});

export const accountPlanDataRequestFailed = (errors = []) => ({
    type: ACCOUNT_PLAN_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const communityInviteLinkRequested = (communityId) => ({
    type: COMMUNITY_INVITE_LINK_REQUESTED,
    payload: {
        communityId,
    },
});

export const joinCommunityRequested = (communityId) => ({
    type: JOIN_COMMUNITY_REQUESTED,
    payload: {
        communityId,
    },
});

export const joinCommunityRequestSucceeded = (response) => ({
    type: JOIN_COMMUNITY_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const joinCommunityRequestFailed = (errors) => ({
    type: JOIN_COMMUNITY_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearCommunityErrors = () => ({
    type: CLEAR_COMMUNITY_ERRORS,
});

export const unjoinCommunityRequested = (communityId) => ({
    type: UNJOIN_COMMUNITY_REQUESTED,
    payload: {
        communityId,
    },
});

export const unjoinCommunityRequestSucceeded = (response) => ({
    type: UNJOIN_COMMUNITY_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const unjoinCommunityRequestFailed = (errors) => ({
    type: UNJOIN_COMMUNITY_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const communityInviteLinkRequestSucceeded = (url) => ({
    type: COMMUNITY_INVITE_LINK_REQUEST_SUCCEEDED,
    payload: {
        url,
    },
});

export const communityInviteLinkRequestFailed = (errors = []) => ({
    type: COMMUNITY_INVITE_LINK_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const createCommunityRequested = (communityId, requestBody) => ({
    type: CREATE_COMMUNITY_REQUESTED,
    payload: {
        communityId,
        requestBody,
    },
});

export const createCommunityRequestSucceeded = (response) => ({
    type: CREATE_COMMUNITY_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const createCommunityRequestFailed = (errors) => ({
    type: CREATE_COMMUNITY_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const communitiesRequested = (options) => ({
    type: COMMUNITIES_REQUESTED,
    payload: {
        options,
    },
});

export const communitiesRequestSucceeded = (communities) => ({
    type: COMMUNITIES_REQUEST_SUCCEEDED,
    payload: {
        communities,
    },
});

export const communitiesRequestFailed = (errors) => ({
    type: COMMUNITIES_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const communitySearchRequested = (searchString) => ({
    type: COMMUNITY_SEARCH_REQUESTED,
    payload: { searchString },
});

export const communitySearchRequestSucceeded = (communities) => ({
    type: COMMUNITY_SEARCH_REQUEST_SUCCEEDED,
    payload: { communities },
});

export const communitySearchRequestFailed = (errors) => ({
    type: COMMUNITY_SEARCH_REQUEST_FAILED,
    payload: { errors },
});

export const communityShareImageRequested = (communityId) => ({
    type: COMMUNITY_SHARE_IMAGE_REQUESTED,
    payload: { communityId },
});

export const communityShareImageRequestSucceeded = (url) => ({
    type: COMMUNITY_SHARE_IMAGE_REQUEST_SUCCEEDED,
    payload: { url },
});

export const communityShareImageRequestFailed = (errors) => ({
    type: COMMUNITY_SHARE_IMAGE_REQUEST_FAILED,
    payload: { errors },
});

export const uploadCommunityFileRequested = (communityId, file) => ({
    type: UPLOAD_COMMUNITY_FILE_REQUESTED,
    payload: {
        communityId,
        file,
    },
});

export const uploadCommunityFileRequestSucceeded = (response) => ({
    type: UPLOAD_COMMUNITY_FILE_REQUEST_SUCCEEDED,
    payload: {
        response,
    },
});

export const uploadCommunityFileRequestFailed = (errors) => ({
    type: UPLOAD_COMMUNITY_FILE_REQUEST_FAILED,
    payload: {
        errors,
    },
});
