import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import ConfirmModal from '../ConfirmModal';
import ModalTitle from '../ModalTitle';
import { BUTTON_ACCENT_COLOR, BUTTON_DEFAULT_COLOR } from '../Button/constants';
import Photo from '../Photo';
import CommonPropTypes from '../propTypes';
import { noop } from '../../utils';
import { getActiveCommunityId } from '../../user/selectors';
import {
    getImageUploadErrors,
    getImageUploadResponse,
    isImageUploadRequestPending,
} from '../../image/selectors';
import { imageUploadRequested } from '../../image/actions';

const UpdateIcon = ({
    communityId,
    isOpen,
    setOpen,
    icon,
    requestImageUpload,
    uploadRequestPending,
    uploadErrors,
    uploadResponse,
    onClose,
    onCancel,
    onConfirm,
}) => {
    const { t } = useTranslation();

    const [iconData, setIconData] = useState(null);
    const [uploadedIcon, setUploadedIcon] = useState(null);

    useEffect(() => {
        if (iconData != null) {
            requestImageUpload({ communityId, file: iconData });
        }
    }, [requestImageUpload, communityId, iconData]);

    useEffect(() => {
        if (
            !uploadRequestPending &&
            uploadErrors == null &&
            uploadResponse != null
        ) {
            setUploadedIcon(uploadResponse);
        }
    }, [uploadRequestPending, uploadErrors, uploadResponse, setUploadedIcon]);

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
    }, [onClose, setOpen]);

    const handleCancel = useCallback(() => {
        onCancel();
        setOpen(false);
    }, [onCancel, setOpen]);

    const handleConfirm = useCallback(() => {
        onConfirm(uploadedIcon);
    }, [onConfirm, uploadedIcon]);

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={
                <ModalTitle
                    tooltipPlacement="right"
                    text={t('editIcon')}
                    tooltipText={t('viewDocs')}
                />
            }
            confirmBtnText={t('OK')}
            confirmBtnType={BUTTON_ACCENT_COLOR}
            cancelBtnText={t('cancel')}
            cancelBtnType={BUTTON_DEFAULT_COLOR}
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        >
            <Grid container direction="column" alignItems="center">
                <Photo
                    title={t('challengeIcon')}
                    photo={icon}
                    setCroppedImageData={setIconData}
                />
            </Grid>
        </ConfirmModal>
    );
};

UpdateIcon.propTypes = {
    communityId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    requestImageUpload: PropTypes.func.isRequired,
    uploadRequestPending: PropTypes.bool.isRequired,
    uploadErrors: CommonPropTypes.errors,
    uploadResponse: PropTypes.object,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
};

UpdateIcon.defaultProps = {
    uploadErrors: null,
    uploadResponse: null,
    onClose: noop,
    onCancel: noop,
};

export { UpdateIcon as DumbUpdateIcon };

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    uploadRequestPending: isImageUploadRequestPending(state),
    uploadErrors: getImageUploadErrors(state),
    uploadResponse: getImageUploadResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestImageUpload: (request) => dispatch(imageUploadRequested(request)),
});

const ConnectedUpdateIcon = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateIcon);
export default ConnectedUpdateIcon;
