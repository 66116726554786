import ContentLoader from 'react-content-loader';
import React from 'react';
import useStyles from './styles';

const LeaderboardCardLoader = () => {
    const classes = useStyles();
    return (
        <ContentLoader height={70} className={classes.cardLoaderContainer}>
            <rect x="60" y="16" rx="4" ry="4" width="70%" height="18" />
            <rect x="0" y="0" height="50" width="50" rx="10" ry="10" />
        </ContentLoader>
    );
};

export default LeaderboardCardLoader;
