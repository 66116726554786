import WILD_FOR_ALL_PROPERTIES from './wildForAll';
import EARTHSHARE_PROPERTIES from './earthshare';
import BLACKSTONE_PROPERTIES from './blackstone';
import BETTERLIVING_PROPERTIES from './betterliving';
import JB_PUBLIC_PROPERTIES from './public';
import JOULEBUG_PROPERTIES from './joulebug';
import CEMEX_PROPERTIES from './cemex';
import REXEL_PROPERTIES from './rexel';
import CAPGROUP_PROPERTIES from './capgroup';
import KN_PROPERTIES from './kn';
import BOSTON_UNIVERSITY_PROPERTIES from './bostonUniversity';
import PUREINDUSTRIAL_PROPERTIES from './pureindustrial';
import CLEARTELLIGENCE_PROPERTIES from './cleartelligence';
import NNPCGROUP_PROPERTIES from './nnpcgroup';

/*
 * The key values for this object must match the subdomain that we set up for the private label
 * ex: blackstoneenergy.joulebug.com
 */
const properties = {
    betterliving: BETTERLIVING_PROPERTIES,
    public: JB_PUBLIC_PROPERTIES,
    joulebug: JOULEBUG_PROPERTIES,
    // TODO: Do something better for this once another client wants app.* to be their domain
    app: WILD_FOR_ALL_PROPERTIES,
    cemex: CEMEX_PROPERTIES,
    rexel: REXEL_PROPERTIES,
    capgroup: CAPGROUP_PROPERTIES,
    'kuehne-nagel': KN_PROPERTIES,
    kn: KN_PROPERTIES,
    pureindustrial: PUREINDUSTRIAL_PROPERTIES,
    cleartelligence: CLEARTELLIGENCE_PROPERTIES,
    earthshare: EARTHSHARE_PROPERTIES,
    blackstoneenergy: BLACKSTONE_PROPERTIES,
    bu: BOSTON_UNIVERSITY_PROPERTIES,
    nnpcgroup: NNPCGROUP_PROPERTIES,
};

export default properties;
