import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import useStyles from './styles';
import SubscriptionTierCard from '../SubscriptionTierCard';
import SnackBarAPIResponse from '../SnackbarAlert/SnackBarAPIResponse';

const PaymentPlans = ({
    subscriptionTiers,
    upgradeButtonProps,
    tierInfo,
    errors,
    clearSnackBar,
}) => {
    const classes = useStyles();

    return (
        <>
            {subscriptionTiers.map((tier, index) => {
                return (
                    <>
                        <Grid
                            className={classes.tierCardContainer}
                            item
                            key={`grid_item_${tier.id}`}
                        >
                            <SubscriptionTierCard
                                tier={tier}
                                upgradeButtonProps={upgradeButtonProps[index]}
                                tierInfo={tierInfo[index]}
                                errors={errors}
                            />
                        </Grid>
                        <SnackBarAPIResponse
                            errors={errors}
                            clearResponse={clearSnackBar}
                        />
                    </>
                );
            })}
        </>
    );
};

PaymentPlans.propTypes = {
    subscriptionTiers: PropTypes.array,
    upgradeButtonProps: PropTypes.array,
    tierInfo: PropTypes.array,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearSnackBar: PropTypes.func.isRequired,
};

PaymentPlans.defaultProps = {
    subscriptionTiers: [],
    upgradeButtonProps: [],
    tierInfo: [],
};

export default PaymentPlans;
