import {
    INIT_STATUS_FAILED,
    INIT_STATUS_INITIALIZED,
    INIT_STATUS_INITIALIZING,
} from './constants';
import {
    INITIALIZATION_FAILED,
    INITIALIZATION_SUCCEEDED,
    APP_STRINGS_REQUEST_FAILED,
    APP_STRINGS_REQUEST_SUCCEEDED,
} from './actions';

export const initInitialState = {
    status: INIT_STATUS_INITIALIZING,
    errors: null,
    appStrings: null,
    isAppStringsLoading: false,
    appStringsErrors: [],
};

export default function initReducer(state = initInitialState, action) {
    const { type, payload } = action;

    if (type === INITIALIZATION_SUCCEEDED) {
        return {
            ...state,
            status: INIT_STATUS_INITIALIZED,
        };
    } else if (type === INITIALIZATION_FAILED) {
        return {
            ...state,
            status: INIT_STATUS_FAILED,
            errors: action.payload.errors,
        };
    } else if (type === APP_STRINGS_REQUEST_FAILED) {
        return {
            ...state,
            appStringsErrors: payload.errors,
            isAppStringsLoading: false,
        };
    } else if (type === APP_STRINGS_REQUEST_SUCCEEDED) {
        return {
            ...state,
            appStrings: payload,
            isAppStringsLoading: false,
        };
    }

    return state;
}
