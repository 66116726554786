import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from './styles';

const BILLING_INTERVAL_MONTH = 'month';

const BillingSection = ({
    classes,
    subscriptionName,
    interval,
    amount,
    subTotal,
    salesTax,
    paid,
}) => {
    const { t } = useTranslation();

    const billingType = () => {
        switch (interval) {
            case BILLING_INTERVAL_MONTH:
                return t('account.receipt.interval.month');
            default:
                return t('account.receipt.interval.year');
        }
    };

    return (
        <Grid container direction="row">
            <Grid
                className={classes.headerSection}
                item
                container
                direction="row"
                xs={12}
            >
                <Grid item xs={9}>
                    <Typography variant="body1">
                        {t('account.subscribe')}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" align="right">
                        {t('account.cost')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="row" xs={12}>
                <Grid item xs={9}>
                    <Typography data-test="subscription-name" variant="body1">
                        {subscriptionName} {billingType()}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        data-test="subscription-amount"
                        variant="body2"
                        align="right"
                    >
                        {amount}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                className={classes.calcSection}
                item
                container
                direction="row"
                xs={12}
            >
                <Grid className={classes.calcRow} item container xs={12}>
                    <Grid item xs={9}>
                        <Typography variant="body1" align="right">
                            {t('account.receipt.subtotal')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.valueContainer} item xs={3}>
                        <Typography
                            data-test="subtotal"
                            variant="body2"
                            align="right"
                        >
                            {subTotal}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.calcRow} item container xs={12}>
                    <Grid item xs={9}>
                        <Typography variant="body1" align="right">
                            {t('account.receipt.salesTax')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.valueContainer} item xs={3}>
                        <Typography
                            data-test="sales-tax"
                            variant="body2"
                            align="right"
                        >
                            {salesTax}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.calcRow} item container xs={12}>
                    <Grid item xs={9}>
                        <Typography variant="body1" align="right">
                            {t('account.receipt.total')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.valueContainer} item xs={3}>
                        <Typography
                            data-test="total"
                            variant="body2"
                            align="right"
                        >
                            {amount}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.finalRow} item container xs={12}>
                    <Grid item xs={9}>
                        <Typography variant="body1" align="right">
                            {t('account.receipt.amountPaid')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.valueContainer} item xs={3}>
                        <Typography
                            data-test="paid-amount"
                            variant="body2"
                            align="right"
                        >
                            {paid}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

BillingSection.propTypes = {
    classes: PropTypes.object.isRequired,
    subscriptionName: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    subTotal: PropTypes.string.isRequired,
    salesTax: PropTypes.string.isRequired,
    paid: PropTypes.string.isRequired,
};

export default withStyles(styles)(BillingSection);
