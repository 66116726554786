import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    header: {
        color: theme.custom.textDarkSecondaryMutedColor,
        fontSize: '18px',
        paddingTop: '10px',
    },
    divider: {
        width: '100%',
        height: '1px',
        border: 'none',
        background: theme.custom.bgAccentColor,
    },
    image: {
        border: '1px solid #fff',
        borderRadius: '6px',
    },
}));

export default useStyles;
