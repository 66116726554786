import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { NavLink } from 'react-router-dom';
import { noop } from '../../utils';
import { ButtonsCellPropType } from './propTypes';
import ActiveCard from './ActiveCard';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

const ActiveChallenges = ({
    activityItem,
    activityTitle,
    classes,
    onButtonsCell,
    buttonsCell,
    sharingOptions,
    isLoading,
    defaultCoverImageUrl,
}) => {
    const { t } = useTranslation();
    const onSocialLinkClicked = (data) => {
        // trigger when click on social link
        alert('onSocialLinkClicked: ' + JSON.stringify(data));
    };

    return (
        <div className={classes.container}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.header}
            >
                <Typography
                    className={classes.title}
                    variant="h6"
                    data-test="activeChallengeHeader"
                >
                    {activityTitle}
                </Typography>
                <NavLink
                    to="/challenges"
                    exact
                    className={classes.navLink}
                    data-test="sellAllChallengeLink"
                >
                    {t('activeChallenges.seeAllChallenges')}
                </NavLink>
            </Grid>
            <Grid>
                {activityItem && activityItem.name && (
                    <ActiveCard
                        sharingOptions={sharingOptions || null}
                        isLoading={isLoading}
                        buttonsCell={buttonsCell}
                        onButtonsCell={onButtonsCell}
                        name={activityItem.name}
                        id={activityItem.id}
                        memberCount={activityItem.members}
                        onSocialLinkClicked={onSocialLinkClicked}
                        startDate={moment(activityItem.startDate).toDate()}
                        endDate={moment(activityItem.endDate).toDate()}
                        iconUrl={
                            activityItem.icon && activityItem.icon?.[0]?.url
                        }
                        imageUrl={defaultCoverImageUrl}
                    />
                )}
            </Grid>
            {activityItem && !isLoading && !activityItem.name && (
                <Alert severity="error">
                    {t('activeChallenges.activeChallengesNotAvailable')}
                </Alert>
            )}
        </div>
    );
};

export const SharingOptions = PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

export const ActivityItem = PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    id: PropTypes.string.isRequired,
    members: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
    icon: PropTypes.array.isRequired,
    defaultCoverImageUrl: PropTypes.string,
});

ActiveChallenges.propTypes = {
    activityItem: ActivityItem,
    activityTitle: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onButtonsCell: PropTypes.func,
    buttonsCell: ButtonsCellPropType,
    sharingOptions: PropTypes.arrayOf(SharingOptions),
    isLoading: PropTypes.bool,
    defaultCoverImageUrl: PropTypes.string,
};

ActiveChallenges.defaultProps = {
    activityItem: {},
    activityTitle: '',
    onButtonsCell: noop,
    onSocialLinkClicked: noop,
    buttonsCell: null,
    sharingOptions: [],
    isLoading: false,
    defaultCoverImageUrl: null,
};

export default withStyles(styles)(ActiveChallenges);
