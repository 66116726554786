import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Grid, Link, List, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { getPrivacyLink, getTermsLink } from '../../utils';
import AppDownloadLinks from '../AppDownloadLinks';

const OnboardingFooter = ({ classes, isLightMode, positionFixed }) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classNames([
                isLightMode && classes.lightModeContainer,
                positionFixed
                    ? classes.onBoardingFooterContainerFixed
                    : classes.onBoardingFooterContainer,
            ])}
        >
            <Grid
                item
                xs={12}
                sm={6}
                md={6}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <List className={classes.inlineListSection}>
                    <li className={classes.listItem}>
                        <Typography variant="body1" align="center">
                            <Link
                                href={getTermsLink()}
                                target="_blank"
                                className={classes.onBoardingFooterText}
                            >
                                {t('termsOfService')}
                            </Link>
                        </Typography>
                    </li>
                    <li
                        className={classes.listItem}
                        style={{ marginLeft: `9px` }}
                    >
                        <Typography variant="body1" align="center">
                            <Link
                                href={getPrivacyLink()}
                                target="_blank"
                                className={classes.onBoardingFooterText}
                            >
                                {t('privacyPolicy')}
                            </Link>
                        </Typography>
                    </li>
                </List>
            </Grid>
            <AppDownloadLinks classes={{ container: classes.downloadLinks }} />
        </Grid>
    );
};

OnboardingFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    isLightMode: PropTypes.bool,
    positionFixed: PropTypes.bool,
};
OnboardingFooter.defaultProps = {
    isLightMode: false,
    positionFixed: true,
};

export default withStyles(styles)(OnboardingFooter);
