import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import {
    Grid,
    MenuItem,
    Select,
    TextareaAutosize,
    withStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

import styles from './styles';

import ConfirmModal from '../ConfirmModal';
import { noop } from '../../utils';
import ModalTitle from '../ModalTitle';
import InfoTooltip from '../InfoTooltip';
import { INTERCOM_TARGET_PREFIX } from '../../screens/UpdateActionScreen/constants';

const ActionDescription = ({
    classes,
    isOpen,
    setOpen,
    onClose,
    onCancel,
    onConfirm,
    originalDescription,
    preview: { name, description, imageLink },
}) => {
    const { t, i18n } = useTranslation();
    const PLACEMENTS = {
        APPEND_BEFORE: t('action.edit.appendBefore'),
        APPEND_AFTER: t('action.edit.appendAfter'),
        OVERWRITE: t('action.edit.overwrite'),
    };

    const PLACEMENT_VALUES = {
        [PLACEMENTS.APPEND_BEFORE]: 'appendBefore',
        [PLACEMENTS.APPEND_AFTER]: 'appendAfter',
        [PLACEMENTS.OVERWRITE]: 'overwrite',
    };

    const PLACEMENT_HINTS = {
        [PLACEMENTS.APPEND_BEFORE]: t('action.edit.appendBeforeDescription'),
        [PLACEMENTS.APPEND_AFTER]: t('action.edit.appendAfterDescription'),
        [PLACEMENTS.OVERWRITE]: t('action.edit.overwriteDescription'),
    };

    const PLACEMENT_TRANSFORMS = {
        [PLACEMENTS.APPEND_BEFORE]: (text, moreText) => `${moreText}${text}`,
        [PLACEMENTS.APPEND_AFTER]: (text, moreText) => `${text}${moreText}`,
        [PLACEMENTS.OVERWRITE]: (text, moreText) => moreText,
    };

    const [placement, setPlacement] = useState(PLACEMENTS.APPEND_BEFORE);
    const [text, setText] = useState('');
    useEffect(() => {
        const splits = description?.split(originalDescription);
        if (splits?.length === 1) {
            setPlacement(PLACEMENTS.OVERWRITE);
            setText(description);
        } else if (splits?.[0] === '') {
            setPlacement(PLACEMENTS.APPEND_AFTER);
            setText(splits?.[1]);
        } else {
            setPlacement(PLACEMENTS.APPEND_BEFORE);
            setText(splits?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [description, originalDescription, isOpen]);

    const onTextChange = (event) => setText(event.target.value);
    const onPlacementChange = (event) => setPlacement(event.target.value);

    const fullDescription = PLACEMENT_TRANSFORMS[placement](
        originalDescription,
        text,
    );

    const makeMenuItem = (placementKey) => {
        const placementItem = PLACEMENTS[placementKey];
        return (
            <MenuItem
                key={placementKey}
                value={placementItem}
                classes={{ root: classes.menuItem }}
            >
                <Grid container direction="row">
                    <Grid item className={classes.check}>
                        {placement === placementItem ? (
                            <CheckIcon fontSize="small" />
                        ) : null}
                    </Grid>
                    <Grid item>{placementItem}</Grid>
                </Grid>
            </MenuItem>
        );
    };

    const menuItems = Object.keys(PLACEMENTS).map((placementKey) =>
        makeMenuItem(placementKey),
    );

    const [key, setKey] = useState(0);
    const clearState = useCallback(() => {
        setText('');
        setPlacement(PLACEMENTS.APPEND_BEFORE);
        setKey(key + 1);
    }, [key, PLACEMENTS.APPEND_BEFORE]);

    const handleClose = () => {
        onClose();
        setOpen(false);
        clearState();
    };

    const handleCancel = () => {
        onCancel();
        setOpen(false);
        clearState();
    };

    const handleConfirm = () => {
        onConfirm({
            fullDescription,
            description: text,
            textPlacement: PLACEMENT_VALUES[placement],
        });
        clearState();
    };

    return (
        <ConfirmModal
            key={key}
            title={
                <ModalTitle
                    text={t('actions.editDescription')}
                    tooltipText={t('viewDocs')}
                />
            }
            isOpen={isOpen}
            confirmBtnText={t('OK')}
            confirmBtnType="accent"
            cancelBtnText={t('cancel')}
            cancelBtnType="default"
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        >
            <Grid container direction="column" className={classes.container}>
                <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.descriptionLabel}
                >
                    <Grid item>
                        <strong>{t('description')}</strong>
                    </Grid>
                    <Grid item>
                        <InfoTooltip
                            text={t('actions.descriptionToolTip')}
                            intercomTarget={`${INTERCOM_TARGET_PREFIX}ModalDescription`}
                        />
                    </Grid>
                </Grid>
                <Grid item className={classes.placementHint}>
                    {PLACEMENT_HINTS[placement]}
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        rowsMin={10}
                        className={classes.textarea}
                        value={text}
                        onChange={onTextChange}
                    />
                </Grid>
                <Grid item className={classes.placementLabel}>
                    <strong>{t('actions.textPlacement')}</strong>
                </Grid>
                <Grid item>
                    <Select
                        value={placement}
                        disableUnderline
                        onChange={onPlacementChange}
                        className={classes.select}
                        classes={{ root: classes.inputRoot }}
                        IconComponent={() => <UnfoldMoreIcon />}
                        MenuProps={{
                            classes: {
                                paper: classes.selectPaper,
                            },
                        }}
                        renderValue={(value) => value}
                    >
                        {menuItems}
                    </Select>
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    className={classes.translation}
                >
                    <Grid item className={classes.translationLabel}>
                        <strong>
                            {t('settings.translations.translatingIn')}:
                        </strong>
                    </Grid>
                    <Grid>{i18n.language}</Grid>
                </Grid>
                <Grid item className={classes.previewLabel}>
                    <strong>{t('preview')}</strong>
                </Grid>
                <Grid
                    container
                    item
                    wrap="nowrap"
                    direction="row"
                    className={classes.preview}
                >
                    <Grid item>
                        <img
                            src={imageLink}
                            alt="preview"
                            className={classes.previewIcon}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        className={classes.previewInfo}
                    >
                        <Grid item className={classes.previewName}>
                            <strong>{name}</strong>
                        </Grid>
                        <Grid item className={classes.previewDescription}>
                            {parse(fullDescription)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ConfirmModal>
    );
};

ActionDescription.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    preview: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        imageLink: PropTypes.string.isRequired,
    }).isRequired,
    originalDescription: PropTypes.string.isRequired,
};

ActionDescription.defaultProps = {
    onClose: noop,
    onCancel: noop,
};

export default withStyles(styles)(ActionDescription);
