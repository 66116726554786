const styles = (theme) => ({
    form: {
        position: 'relative',
        width: '100%',
    },
    setupFormButton: {
        width: '30%',
        marginTop: '13px',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
            marginTop: '13px',
        },
    },
    buttonWrapper: {
        paddingTop: '20px',
        paddingBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '13px',
            paddingBottom: '13px',
        },
    },
});

export default styles;
