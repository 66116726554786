import {
    CHALLENGE_PHOTOS_REQUEST_FAILED,
    CHALLENGE_PHOTOS_REQUEST_SUCCEEDED,
    CHALLENGE_PHOTOS_REQUESTED,
    CLEAR_COMMUNITY_PHOTOS,
    COMMUNITY_PHOTOS_REQUEST_FAILED,
    COMMUNITY_PHOTOS_REQUEST_SUCCEEDED,
    COMMUNITY_PHOTOS_REQUESTED,
} from './actions';
import { mergePaginatedResponse } from '../../common/utils';

const initialPhotoState = {
    photos: [],
    isLoading: false,
    errors: [],
};

export default function analyticsPhotoReducer(
    state = initialPhotoState,
    action,
) {
    const { type, payload = {} } = action;
    switch (type) {
        case COMMUNITY_PHOTOS_REQUESTED:
        case CHALLENGE_PHOTOS_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case COMMUNITY_PHOTOS_REQUEST_SUCCEEDED:
        case CHALLENGE_PHOTOS_REQUEST_SUCCEEDED:
            return {
                ...state,
                photos: mergePaginatedResponse(
                    state.photos,
                    payload.photos,
                    payload.skip,
                ),
                isLoading: false,
                errors: [],
            };
        case COMMUNITY_PHOTOS_REQUEST_FAILED:
        case CHALLENGE_PHOTOS_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload.errors,
            };
        case CLEAR_COMMUNITY_PHOTOS: {
            return {
                ...state,
                photos: [],
            };
        }
        default:
            return state;
    }
}
