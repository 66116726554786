const styles = (theme) => ({
    confirmMessage: {
        paddingBottom: '10px',
    },
    identifier: {
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    description: {
        marginTop: '10px',
        color: theme.custom.textDarkSecondaryMutedColor,
    },
    borderIcon: {
        border: '2px solid #fff',
        borderRadius: '6px 6px 50% 50%',
    },
});

export default styles;
