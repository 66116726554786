import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Grid, Popper, Paper, withStyles, Typography } from '@material-ui/core';
import { Favorite, FormatQuoteRounded } from '@material-ui/icons';
import Modal from '../Modal';
import Button from '../Button';
import styles from './styles';
import { getAppImages } from '../../utils';

const SocialPhoto = ({ classes, isOpen, onClose, photo }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopper, setOpenPopper] = useState(false);

    const handlePopperOpen = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setOpenPopper((open) => !open);
    };

    const hidePopper = () => {
        setOpenPopper(false);
    };

    return (
        <Modal
            PaperProps={{
                classes: {
                    root: classes.paper,
                },
            }}
            classes={{ container: classes.modal }}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Grid container onClick={hidePopper}>
                <Grid
                    container
                    item
                    className={classes.leftContainer}
                    xs={12}
                    sm={6}
                >
                    <div className={classes.overlay}>
                        <Button
                            variant="outlined"
                            color="white"
                            className={classes.button}
                            onClick={handlePopperOpen}
                        >
                            {t('activity.downloadImage')}
                        </Button>
                        <Popper
                            className={classes.popper}
                            open={openPopper}
                            anchorEl={anchorEl}
                            placement="bottom-start"
                        >
                            <Paper>
                                <ul className={classes.imageList}>
                                    {photo.imageSizes.map((image) => (
                                        <li key={image.url}>
                                            <a
                                                href={image.url}
                                                className={
                                                    classes.imageListItem
                                                }
                                            >
                                                {`${image.width} x ${image.height}`}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </Paper>
                        </Popper>
                    </div>
                    <img
                        src={photo.photoUrl}
                        className={classes.photo}
                        alt={t('alt.activityPhoto')}
                    />
                </Grid>
                <Grid
                    container
                    item
                    className={classes.rightContainer}
                    direction="column"
                    justify="space-between"
                    wrap="nowrap"
                    xs={12}
                    sm={6}
                >
                    <Grid container item>
                        <Grid
                            container
                            item
                            className={classes.header}
                            direction="row"
                            wrap="nowrap"
                        >
                            <Grid item className={classes.userImageWrapper}>
                                <img
                                    alt={t('alt.profileImage')}
                                    src={
                                        photo.userUrl ||
                                        getAppImages().defaultProfileIcon
                                    }
                                    className={classes.image}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                className={classes.headerTitle}
                                direction="column"
                            >
                                <Typography className={classes.userName}>
                                    {photo.userName}
                                </Typography>
                                <Typography
                                    className={classes.defaultActionText}
                                >
                                    {photo.defaultActionText}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.actionImageWrapper}>
                                <img
                                    alt={t('alt.actionPhoto')}
                                    src={photo.actionUrl}
                                    className={classes.image}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" wrap="nowrap">
                            <Grid
                                container
                                item
                                className={classes.leftColumn}
                                justify="center"
                                alignItems="center"
                            >
                                <Favorite />
                            </Grid>
                            <Grid container item>
                                <Typography className={classes.likes}>
                                    {pluralize(
                                        t('activity.like'),
                                        photo.likes.length || 0,
                                        true,
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row">
                            {photo.comments.map((comment, index) => (
                                <Grid
                                    container
                                    item
                                    className={classes.comment}
                                    key={`comment-${index}`}
                                    wrap="nowrap"
                                >
                                    <Grid
                                        container
                                        item
                                        className={clsx([
                                            classes.leftColumn,
                                            classes.commentIcon,
                                        ])}
                                        justify="center"
                                    >
                                        <FormatQuoteRounded />
                                    </Grid>
                                    <Grid container item direction="column">
                                        <Typography
                                            className={classes.commentOwner}
                                        >
                                            {comment.userName}
                                        </Typography>
                                        <Typography
                                            className={classes.commentMessage}
                                        >
                                            {comment.message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            {photo.comments.length === 0 && (
                                <Grid container item>
                                    <Typography
                                        className={classes.emptyComments}
                                    >
                                        {t('activity.noComment')}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        className={classes.footer}
                        justify="flex-end"
                    >
                        <Button
                            className={classes.closeButton}
                            color="secondary"
                            onClick={onClose}
                        >
                            {t('close')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

SocialPhoto.propTypes = {
    classes: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    photo: PropTypes.shape({
        photoUrl: PropTypes.string.isRequired,
        userUrl: PropTypes.string,
        userName: PropTypes.string.isRequired,
        defaultActionText: PropTypes.string,
        actionUrl: PropTypes.string.isRequired,
        imageSizes: PropTypes.array,
        likes: PropTypes.array,
        comments: PropTypes.arrayOf(
            PropTypes.shape({
                userName: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
            }),
        ),
    }).isRequired,
};

export default withStyles(styles)(SocialPhoto);
