import DEFAULT_PROPERTIES from './default';
import { LOGIN_METHOD_EMAIL } from './constants';

const BOSTON_UNIVERSITY_PROPERTIES = {
    ...DEFAULT_PROPERTIES,
    appName: 'SustainableBU',
    shouldShowSignUp: false,
    clientId: '595409730b8c5839fa7059cc',
    clientSecret:
        '04NiBCO19pdrOl9_1EFYYXHJnr_ffbcPkiHn9Z3QLQ6JqceXlCR0zApIDnXs6oASs2',
    loginMethods: [LOGIN_METHOD_EMAIL],
    apiUri: process.env.REACT_APP_JBE_API_URI,
    wordpressDomain: 'https://joulebug.com',
    wordpressOverrides: {
        '/about': 'https://www.bu.edu/sustainability/',
    },
    appStoreLink:
        'https://apps.apple.com/us/app/sustainability-bu/id1136712023',
    playStoreLink:
        'https://play.google.com/store/apps/details?id=com.cleanbit.joulebug.pbj.bostonu',
    colors: {
        primary: '#50B43C',
        primaryDark: '#388129',
        primaryForeground: '#14253D',
        secondary: '#0081C3',
        secondaryDark: '#0081C3',
        accent: '#0081C3',
        accentDark: '#016A9F',
        warn: '#0081C3',
        warnDark: '#0081C3',
        EditingBannerBackground: '#f26549',
        loginFooter: '#0081C3',
        impactTileColor: '#F7772C',
    },
    appDescription:
        "sustainableBU is the easy way to make your everyday habits more sustainable—whether on Boston University’s campuses or at home. The app organizes sustainability tips into Actions that you log in the app when you perform them in real-life. Learn more about each Action with easy-to-understand Impact stats, bonuses, how-to videos, and helpful links. Compete in Challenges with other students, faculty, and staff to see who’s the most sustainable. Encourage your friends by sharing how you're making a difference and following what they're up to in the feed. Track your impact—quantified through carbon dioxide saved, waste diverted, and water conserved—with your career stats and trophy collection.",
};
export default BOSTON_UNIVERSITY_PROPERTIES;
