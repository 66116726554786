import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Form from '../../../common/Form';
import FormInput from '../../../common/Form/FormInput';
import FormInputGroup from '../../../common/Form/FormInputGroup';

import styles from './styles';
import SignInScreenTemplate from '../index';
import Photo from '../../../common/Photo';
import { getAppImages } from '../../../utils';
import { clearUserErrors, userUpdateRequested } from '../../../user/actions';
import {
    getActiveCommunityId,
    isUserUpdateLoading,
} from '../../../user/selectors';
import RequestLoader from '../../../common/RequestLoader';
import SnackBarAPIResponse from '../../../common/SnackbarAlert/SnackBarAPIResponse';
import { getUpdateErrors } from '../../../notifications/selectors';
import { useHistory } from 'react-router-dom';
import { getToken } from '../../../auth/selectors';

const ProfileCreateScreen = ({
    classes,
    requestUpdate,
    communityId,
    updateLoading,
    updateErrors,
    clearErrors,
    accessToken,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        if (!accessToken || accessToken === 'null') {
            history.push('/signup/create-account');
        }
    });

    const userForm = {
        displayName: '',
        headline: '',
    };

    const ValidationSchema = Yup.object().shape({
        displayName: Yup.string().required(t('userCreate.inputRequired')),
        headline: Yup.string(),
    });

    const [profileImage, setProfileImage] = useState(null);

    const submitHandler = (values) => {
        requestUpdate({
            communityId,
            userId: 'self',
            name: values.displayName,
            headline: values.headline,
            file: profileImage,
            isCreating: true,
        });
    };

    return (
        <SignInScreenTemplate>
            <RequestLoader isLoading={updateLoading} />
            <Typography variant="h3" className={classes.title}>
                {t('profileCreate.title')}
            </Typography>
            <Grid className={classes.photoContainer}>
                <Photo
                    photo={getAppImages().defaultProfileIcon}
                    setCroppedImageData={setProfileImage}
                    title={t('profileCreate.addPicture')}
                />
            </Grid>
            <Form
                validationSchema={ValidationSchema}
                initialValues={userForm}
                onSubmit={submitHandler}
                submitBtnText={t('signUp.continue')}
            >
                <FormInputGroup>
                    <Typography className={classes.inputLabel}>
                        {t('profileCreate.yourName')}
                    </Typography>
                    <FormInput
                        name="displayName"
                        placeholder={t('profileCreate.displayName')}
                    />
                    <Typography className={classes.inputLabel}>
                        {t('profileCreate.headline')}
                    </Typography>
                    <FormInput name="headline" />
                </FormInputGroup>
            </Form>
            <SnackBarAPIResponse
                errors={updateErrors}
                clearResponse={clearErrors}
            />
        </SignInScreenTemplate>
    );
};
ProfileCreateScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    requestUpdate: PropTypes.func.isRequired,
    communityId: PropTypes.string.isRequired,
    updateLoading: PropTypes.string.isRequired,
    updateErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearErrors: PropTypes.func.isRequired,
    accessToken: PropTypes.string.isRequired,
};

export const StyledProfileCreateScreen = withStyles(styles)(
    ProfileCreateScreen,
);

const mapStateToProps = (state) => ({
    communityId: getActiveCommunityId(state),
    updateLoading: isUserUpdateLoading(state),
    updateErrors: getUpdateErrors(state),
    accessToken: getToken(state),
});
const mapDispatchToProps = (dispatch) => ({
    requestUpdate: (request) => dispatch(userUpdateRequested(request, true)),
    clearErrors: () => dispatch(clearUserErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledProfileCreateScreen);
