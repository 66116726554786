export const THEMES_REQUESTED = 'THEMES_REQUESTED';
export const THEMES_REQUEST_SUCCEEDED = 'THEMES_REQUEST_SUCCEEDED';
export const THEMES_REQUEST_FAILED = 'THEMES_REQUEST_FAILED';

export const THEMES_UPDATE_REQUESTED = 'THEMES_UPDATE_REQUESTED';
export const THEMES_UPDATE_REQUEST_SUCCEEDED =
    'THEMES_UPDATE_REQUEST_SUCCEEDED';
export const THEMES_UPDATE_REQUEST_FAILED = 'THEMES_UPDATE_REQUEST_FAILED';

export const CLEAR_THEMES_ERRORS = 'CLEAR_THEMES_ERRORS';

export const themesRequested = (communityId, includeCategories = false) => ({
    type: THEMES_REQUESTED,
    payload: {
        communityId,
        includeCategories,
    },
});

export const themesRequestSucceeded = (themes) => ({
    type: THEMES_REQUEST_SUCCEEDED,
    payload: {
        themes,
    },
});

export const themesRequestFailed = (errors) => ({
    type: THEMES_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const themesUpdateRequested = (
    themeIds,
    orgId,
    nextLocation = null,
) => ({
    type: THEMES_UPDATE_REQUESTED,
    payload: {
        themeIds,
        orgId,
        nextLocation,
    },
});

export const themesUpdateRequestSucceeded = (themes) => ({
    type: THEMES_UPDATE_REQUEST_SUCCEEDED,
    payload: {
        themes,
    },
});

export const themesUpdateRequestFailed = (errors) => ({
    type: THEMES_UPDATE_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const clearThemesErrors = () => ({
    type: CLEAR_THEMES_ERRORS,
});
