import { call, put, takeLatest } from 'redux-saga/effects';
import {
    SAVE_DRAFT_REQUESTED,
    saveDraftFailed,
    saveDraftSucceeded,
} from './actions';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../../utils/api';
import { viewChallengeRequested } from '../../challenges/actions';
import { trackEvent } from '../../utils/analytics';
import { updateIntercomCompanyData } from '../../config/intercom';
import {
    EVENT_CHALLENGE_EDITED,
    EVENT_CHALLENGE_PUBLISHED,
} from '../../constants/analyticsEvents';
import { CHALLENGE_TEMPLATE_STATUS_DRAFT } from '../../constants/challenges';
import i18n from '../../localization/i18n';

function* saveDraft(action) {
    const {
        payload: { challengeId, communityId, body, startDate, endDate },
    } = action;

    trackEvent(EVENT_CHALLENGE_EDITED, {
        challenge_id: challengeId,
    });
    try {
        yield call(
            fetchWithAuth,
            `/kiosk/group/${communityId}/challenges/${challengeId}/edit`,
            {
                method: 'POST',
                body,
                query: {
                    communityId,
                    /*
                     * This particular request, for some reason, must contain this param,
                     * otherwise 500 Interval Server Error happens.
                     */
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            },
        );
        yield put(saveDraftSucceeded(body?.publish));
        if (body?.publish) {
            trackEvent(EVENT_CHALLENGE_PUBLISHED, {
                startDate: startDate,
                endDate: endDate,
            });
        }

        // If the challenge is no longer a draft, it has likely been published prior and we can update intercom data.
        if (body?.status !== CHALLENGE_TEMPLATE_STATUS_DRAFT) {
            updateIntercomCompanyData({
                last_challenge_start_at: startDate,
                last_challenge_end_at: endDate,
            });
        }

        yield put(viewChallengeRequested({ challengeId, communityId }));
    } catch (error) {
        const message =
            body?.status === CHALLENGE_TEMPLATE_STATUS_DRAFT
                ? i18n.t('challenge.save.failed.draft')
                : i18n.t('challenge.save.failed.published');
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            message,
        );
        yield put(saveDraftFailed(errors));
    }
}

export default function* updateChallengeSaga() {
    yield takeLatest(SAVE_DRAFT_REQUESTED, saveDraft);
}
