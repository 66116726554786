import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import Button from '../Button';
import { WarningIcon } from '../../assets/images/svg';
import useStyles from './styles';
import { ACCOUNT_VARIANT, HEADER_VARIANT } from './constants';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../utils/analytics';
import { EVENT_SUBSCRIPTION_PRESS_UPGRADE } from '../../constants/analyticsEvents';
import { PARAM_ORIGIN } from '../../constants/analyticsParams';

const UserCounter = ({ accountType, maxUsers, currentUsers, variant }) => {
    const { t } = useTranslation();

    const classes = useStyles({ maxUsers, currentUsers, variant });
    const history = useHistory();

    const UserCountBar = () => {
        return (
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <div className={classes.root}>
                        <LinearProgress
                            className={classes.progressBar}
                            value={calcUserPercentage()}
                            variant="determinate"
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        className={classes.userCount}
                        variant="body1"
                        align="right"
                    >
                        {`${currentUsers}/${maxUsers} Users`}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const calcUserPercentage = () => {
        if (currentUsers >= maxUsers) return 100;
        return Math.round((currentUsers / maxUsers) * 100);
    };

    const calcRemainingUserCount = () => {
        return maxUsers - currentUsers;
    };

    const handleUpgradeClick = () => {
        trackEvent(EVENT_SUBSCRIPTION_PRESS_UPGRADE, {
            [PARAM_ORIGIN]: variant,
        });
        history.push('/payment');
    };

    return (
        <Grid
            className={classes.container}
            container
            direction="row"
            spacing={variant === HEADER_VARIANT ? 2 : 1}
        >
            {variant === HEADER_VARIANT && (
                <Grid
                    item
                    container
                    direction="row"
                    style={{ display: 'flex', flex: 1 }}
                >
                    {currentUsers >= maxUsers && (
                        <Grid item container style={{ flex: 1 }}>
                            <div className={classes.iconContainer}>
                                <WarningIcon
                                    className={classes.warningIcon}
                                    data-test="header-warning-icon"
                                />
                            </div>
                        </Grid>
                    )}
                    <Grid item container direction="row" style={{ flex: 3 }}>
                        <Grid item xs={12}>
                            <Typography style={{ lineHeight: 1 }} align="right">
                                {t('account.accountTypeText')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.accountType}
                                align="right"
                                data-test="account-type"
                            >
                                {accountType}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {variant === ACCOUNT_VARIANT && (
                <Grid item container direction="row" xs={12}>
                    {currentUsers >= maxUsers ? (
                        <Grid
                            item
                            container
                            xs={12}
                            data-test="account-warning-section"
                        >
                            <Grid item xs={1}>
                                <div className={classes.iconContainer}>
                                    <WarningIcon
                                        className={classes.warningIcon}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant="body1">
                                    {t('account.maxedOutText')}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item container xs={12}>
                            <Typography
                                variant="body1"
                                data-test="account-remaining-text"
                            >
                                {t('account.remainingUserText', {
                                    remainingUserCount: calcRemainingUserCount(),
                                })}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
            <Grid
                className={classes.progressBarContainer}
                item
                container
                direction="row"
            >
                <UserCountBar data-test="user-counter-bar" />
            </Grid>
            <Grid item style={{ flex: 1 }}>
                <Button
                    className={classes.upgradeBtn}
                    variant="outlined"
                    color="primary"
                    onClick={handleUpgradeClick}
                    fullWidth
                    data-test="upgrade-button"
                >
                    {t('account.upgradeButtonText')}
                </Button>
            </Grid>
        </Grid>
    );
};

UserCounter.propTypes = {
    accountType: PropTypes.string.isRequired,
    maxUsers: PropTypes.number.isRequired,
    currentUsers: PropTypes.number.isRequired,
    variant: PropTypes.oneOf([HEADER_VARIANT, ACCOUNT_VARIANT]),
};

UserCounter.defaultProps = {
    variant: HEADER_VARIANT,
};

export default UserCounter;
