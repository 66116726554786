import { createSelector } from 'reselect';

export const getUpdateChallengeState = (state) => state.updateChallenge || {};

export const isSaveInProgress = createSelector(
    getUpdateChallengeState,
    (updateChallenge) => updateChallenge.saveInProgress,
);

export const isSaveSuccessful = createSelector(
    getUpdateChallengeState,
    (updateChallenge) => updateChallenge.saveSuccessful,
);

export const getSaveErrors = createSelector(
    getUpdateChallengeState,
    (updateChallenge) => updateChallenge.errors || [],
);

export const getPublishStatus = createSelector(
    getUpdateChallengeState,
    (updateChallenge) => updateChallenge.publish,
);
