import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import styles from './styles';
import Button from '../Button';
import { noop } from '../../utils';

const PaymentMethod = ({
    classes,
    handleAddPayMethod,
    existingPayMethod,
    handleEditClick,
}) => {
    const { t } = useTranslation();

    return (
        <Grid container direction="row">
            <Grid
                className={classes.titleContainer}
                item
                container
                direction="row"
                xs={12}
            >
                <Grid item xs={12}>
                    <Typography className={classes.formTitle} variant="h6">
                        {t('account.paymentMethod.title')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="row" xs={12}>
                {existingPayMethod ? (
                    <Grid
                        className={classes.methodContainer}
                        item
                        container
                        direction="row"
                        xs={12}
                    >
                        <Grid item xs={2}>
                            <Typography variant="body1">
                                {existingPayMethod.issuer}
                            </Typography>
                        </Grid>
                        {/* CJB-175 removed till changes are approved 
                        <Grid className={classes.logoContainer} item xs={2}>
                            <img
                                src={existingPayMethod.logo}
                                alt={t('Bank Logo')}
                            />
                        </Grid>
                        */}
                        <Grid item xs={3}>
                            <Typography variant="body1">
                                {t('account.paymentMethod.ending', {
                                    digits: existingPayMethod.digits,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1">
                                {t('account.paymentMethod.expire', {
                                    date: existingPayMethod.expire,
                                })}
                            </Typography>
                        </Grid>
                        <Grid className={classes.editBtnContainer} item xs={4}>
                            <Button
                                className={classes.editBtn}
                                color="primary"
                                size="small"
                                onClick={handleEditClick}
                            >
                                <Edit className={classes.editBtnIcon} />
                                <div className={classes.editBtnText}>
                                    {t('edit')}
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Button
                            className={classes.addButton}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            {t('account.paymentMethod.btnText')}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

PaymentMethod.propTypes = {
    classes: PropTypes.object.isRequired,
    handleAddPayMethod: PropTypes.func,
    existingPayMethod: PropTypes.shape({
        issuer: PropTypes.string,
        logo: PropTypes.string,
        digits: PropTypes.number,
        expire: PropTypes.string,
    }).isRequired,
    handleEditClick: PropTypes.func,
};

PaymentMethod.defaultProps = {
    handleAddPayMethod: noop,
    handleEditClick: noop,
};

export default withStyles(styles)(PaymentMethod);
