import IMAGES from '../../constants/images';
const styles = (theme) => ({
    container: {
        width: '100%',
        position: 'relative',
        zIndex: '0',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '19.6%',
            height: '100%',
            bottom: '0px',
            left: '0',
            backgroundImage: `url(${IMAGES.bgEmailVerification1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'left,bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '19.9%',
            height: '100%',
            bottom: '0px',
            right: '0',
            backgroundImage: `url(${IMAGES.bgEmailVerification2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: '-1',
            backgroundPosition: 'right, bottom',
            '@media only screen and (min-width: 1024px) and (orientation: portrait)': {
                display: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },

        [theme.breakpoints.down('sm')]: {
            background: 'none',
            '@media (orientation: landscape)': {
                height: 'auto',
            },
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            '@media (orientation: landscape)': {
                height: 'auto',
            },
        },
    },
    title: {
        fontWeight: '700',
    },
    mainContent: {
        padding: '80px 0px 80px 32px',
        zIndex: '1',
    },
    page: {
        overflow: 'auto',
    },
});

export default styles;
