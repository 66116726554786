import { createSelector } from 'reselect';

export const getInviteUsersState = (state) => {
    return state.inviteUsers;
};

export const getInviteUsersSuccess = createSelector(
    getInviteUsersState,
    (state) => state.success || false,
);

export const getInviteUsersMessages = createSelector(
    getInviteUsersState,
    (state) => state.messages || [],
);

export const getInviteUsersErrors = createSelector(
    getInviteUsersState,
    (state) => state.errors || [],
);

export const getInviteUsersLoading = createSelector(
    getInviteUsersState,
    (state) => state.loading || false,
);
