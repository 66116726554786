import qs from 'qs';
import APIErrorResponse from './APIErrorResponse';
import { call, put } from 'redux-saga/effects';
import i18next from 'i18next';
import { getProperties } from '../index';
import { logoutRequested } from '../../auth/actions';
import { setNotificationCounts } from '../../notifications/actions';
import {
    ERROR_CODE_DUPLICATE_DOMAIN,
    ERROR_CODE_DUPLICATE_ORG,
    ERROR_CODE_TOKEN_EXPIRED,
    ERROR_CODE_TOKEN_INVALID,
} from '../../auth/constants';
import { replace } from 'connected-react-router';

export const getFullURI = (path, query = null) => {
    // Get the API URI from the build-time environment variables, removing any trailing slash
    const apiUri = `${getProperties()?.apiUri}`.replace(/\/+$/, '');
    // Append the path to the apiUri with a slash, removing any leading slashes on the path
    let fullURI = `${apiUri}/${path.replace(/^\/+/, '')}`;
    const languageCode = i18next.language;
    if (query !== null) {
        query.language = languageCode;
        fullURI += '?' + qs.stringify(query, { indices: false });
    } else {
        fullURI += '?language=' + languageCode;
    }
    fullURI += '&platform=Web';
    return fullURI;
};

/**
 * Make a request to a URI using the fetch API.
 *
 * @param {string} path
 * @param {string=} method
 * @param {(string|null)=} token
 * @param {({}|null)=} query
 * @param {(*|null)=} body
 * @param {boolean=} json Whether or not the response should be attempted to parse to JSON (default: `false`)
 * @param headers
 * @return {Promise<Response>}
 */
export function* request(
    path,
    method = 'GET',
    token = null,
    query = null,
    body = null,
    json = false,
    headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
) {
    if (token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }

    const options = { method, headers };
    if (body) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(body);
    }
    const fullURI = getFullURI(path, query);
    const response = yield call(fetch, fullURI, options);

    if (response.ok) {
        return yield call(safelyReadResponse, response, json);
    } else {
        const errorResponse = yield call(createAPIErrorResponse, response);
        yield call(
            logAPIErrorResponse,
            {
                uri: fullURI,
                options,
            },
            errorResponse,
        );
        throw errorResponse;
    }
}

/**
 * attempts to read the response body safely. If it has already been read or json is not expected, the raw response is returned/
 * Otherwise, a JSON parsed response is returned
 * @param response
 * @param {boolean=} json
 * @returns {IterableIterator<*>}
 */
function* safelyReadResponse(response, json = false) {
    return json && response && !response.bodyUsed
        ? yield call([response, response.json])
        : response;
}

/**
 * Create an APIErrorResponse object from a fetch Response.
 *
 * @param {Response} response
 * @return {Promise<APIErrorResponse>}
 */
export async function createAPIErrorResponse(response) {
    const errorResponse = new APIErrorResponse();
    await errorResponse.setJsonFromResponse(response);
    return errorResponse;
}

/**
 * Given a caught error, if it's an `APIErrorResponse`, attempt to extract its validation errors or other errors as an array of
 * error messages that occurred, falling back to an array containing only `genericErrorMessage` in all of the following
 * scenarios:
 *
 * -   `error` is not an `APIErrorResponse`
 * -   The `APIErrorResponse` was not JSON parse-able
 * -   The `APIErrorResponse`'s JSON body did not contain a top-level `message` and did not contain any `validationErrors` or
 *     it was empty.
 *
 * @see APIErrorResponse.getErrorMessages
 * @param {APIErrorResponse|*} error
 * @param {string|null} genericErrorMessage
 * @return {string[]}
 */
export function* getErrorsFromPossibleAPIErrorResponse(
    error,
    genericErrorMessage = null,
) {
    const fallback = genericErrorMessage ? [genericErrorMessage] : [];
    if (error instanceof APIErrorResponse) {
        const errorMessages = error.getErrorMessages(genericErrorMessage);
        const params = new URLSearchParams();
        switch (error?.getError()?.errorCode) {
            case ERROR_CODE_DUPLICATE_ORG:
                params.append('bot', 'duplicate-organization');
                break;
            case ERROR_CODE_DUPLICATE_DOMAIN:
                params.append('bot', 'duplicate-domain');
                break;
            case ERROR_CODE_TOKEN_EXPIRED:
            case ERROR_CODE_TOKEN_INVALID:
                yield put(logoutRequested(errorMessages));
                break;
            default:
                break;
        }
        if (params.keys().next()) {
            yield put(replace({ search: params.toString() }));
        }
        return errorMessages;
    }
    return fallback;
}

/**
 * We send API errors to the console since the devtools Network tab still doesn't like to show error response bodies.
 * @param request An object representing the API request
 * @param errorResponse
 */
export function logAPIErrorResponse(request, errorResponse) {
    // eslint-disable-next-line no-console
    console.error(
        'An API error occurred: ',
        errorResponse && errorResponse.json
            ? errorResponse.json
            : errorResponse,
    );
}

export function* postWithAuth(
    path,
    { json = true, query = null, body = null } = {},
) {
    return yield call(fetchWithAuth, path, {
        method: 'POST',
        json,
        query,
        body,
    });
}

export function* fetchWithAuth(
    path,
    { method = 'GET', json = true, query = null, body = null } = {},
) {
    const token = getToken();
    const headers = {
        'Cache-Control': 'max-age=0',
    };
    const response = yield call(
        request,
        path,
        method,
        token,
        query,
        body,
        json,
        headers,
    );
    const counts = response?.notificationCounts;
    if (counts) {
        yield put(setNotificationCounts(counts));
    }
    return response;
}

export function* fetchWithoutAuth(
    path,
    { method = 'GET', json = true, query = null, body = null } = {},
) {
    return yield call(request, path, method, null, query, body, json);
}

export function* uploadWithAuth(
    uri,
    { method = 'PUT', body = null, query = null } = {},
) {
    const fullURI = getFullURI(uri, query);
    const headers = {
        Accept: 'application/json',
    };

    const token = getToken();
    headers.Authorization = 'Bearer ' + token;

    const options = { method, headers };
    if (body) {
        options.body = body;
    }
    const response = yield call(fetch, fullURI, options);
    if (response.ok) {
        return yield call(safelyReadResponse, response, true);
    } else {
        const errorResponse = yield call(createAPIErrorResponse, response);
        yield call(
            logAPIErrorResponse,
            {
                uri: fullURI,
                options,
            },
            errorResponse,
        );
        throw errorResponse;
    }
}

export const getToken = () => {
    return localStorage.getItem('token');
};

export const setToken = (token) => {
    localStorage.setItem('token', token);
};
