const styles = (theme) => ({
    container: {
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    divider: {
        width: '100%',
        height: '1px',
        border: 'none',
        backgroundColor: theme.custom.bgDarkHover,
        marginTop: '20px',
        marginBottom: '30px',
    },
    loaderWrapper: {
        position: 'relative',
    },
    bottom: {
        height: 0,
    },
});

export default styles;
