import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useStyles from './styles';
import Button from '../../common/Button';
import { BUTTON_ACCENT_COLOR } from '../../common/Button/constants';
import {
    getSelectedUserErrors,
    getSelectedUserProfile,
    getSelectedUserTrophies,
    getUserErrors,
    getUserId,
    getUserProfile,
    getUserTrophies,
    isSelectedUserLoading,
    isUserLoading,
} from '../../user/selectors';
import { userDetailsRequested, userRequested } from '../../user/actions';
import CommonPropTypes from '../../common/propTypes';
import RequestLoader from '../../common/RequestLoader';
import ErrorList from '../../common/errors/ErrorList';
import TabbedSection from '../../common/TabbedSection';
import ProfileStats from './ProfileStats';
import { ProfilePropType, ProfileTrophyPropType } from './propTypes';
import ProfileTrophies from './ProfileTrophies';
import { USER_SELF } from '../../user/constants';
import { getAppImages, noop } from '../../utils';
import { trackEvent, useTrackScreenView } from '../../utils/analytics';
import InfoCardHeader from '../../common/InfoCardHeader';
import { INFO_CARD_PROFILE } from '../../common/InfoCard/constants';
import {
    activityDataRequested,
    clearActivities,
} from '../../activities/actions';
import { clearFlagMessages, profileReportRequested } from '../../flags/actions';
import { ACTIVITIES_FEED_TYPE_USER } from '../../activities/constants';
import { activitiesLoading, getActivities } from '../../activities/selectors';
import {
    getReportPostContentId,
    getMessages as getFlagMessages,
    getErrors as getFlagErrors,
} from '../../flags/selectors';
import ActivityCardScroller from '../../common/ActivityCard/ActivityCardScroller';
import ActivityOptionsPopper from '../../common/ActivityCard/ActivityOptionsPopper';
import SnackBarAPIResponse from '../../common/SnackbarAlert/SnackBarAPIResponse';
import { REPORT_TYPE_PROFILE } from '../../common/ActivityCard/ActivityOptionsPopper/constants';
import {
    EVENT_PROFILE_ACTIVITY_VIEWED,
    EVENT_PROFILE_TROPHY_CASE_VIEWED,
} from '../../constants/analyticsEvents';

const ProfileScreen = ({
    loading,
    errors,
    profile,
    requestUser,
    fetchSelectedUser,
    selectedUserLoading,
    selectedUserErrors,
    selectedUserProfile,
    userTrophies,
    selectedUserTrophies,
    fetchSelectedUserActivities,
    clearUserActivities,
    userActivitiesLoading,
    userActivityItems,
    currentUserId,
    handleSubmitReport,
    flagReportLoadingId,
    clearErrors,
    messages,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const {
        params: { userId },
    } = useRouteMatch();

    const flagReportLoading = useMemo(
        () => flagReportLoadingId === selectedUserProfile?.id,
        [flagReportLoadingId, selectedUserProfile],
    );

    const [haveFetchedActivities, setHaveFetchedActivities] = useState(false);

    const isSelf = useMemo(
        () => userId === currentUserId || userId === USER_SELF,
        [userId, currentUserId],
    );

    useTrackScreenView(isSelf ? 'profile_view_self' : 'profile_view_other');

    // Determines weather we should show the user info
    const isPresentable = useMemo(
        () =>
            profile != null && isSelf
                ? !loading && errors == null
                : !selectedUserLoading &&
                  selectedUserErrors == null &&
                  selectedUserProfile != null,
        [
            isSelf,
            loading,
            errors,
            profile,
            selectedUserLoading,
            selectedUserErrors,
            selectedUserProfile,
        ],
    );

    const handleEditClick = useCallback(() => {
        history.push('/profile/edit');
    }, [history]);

    const getProfile = () => (isSelf ? profile : selectedUserProfile);

    const getTrophies = () => (isSelf ? userTrophies : selectedUserTrophies);

    useEffect(() => {
        if (isSelf) {
            requestUser();
        } else {
            fetchSelectedUser(userId);
        }
        clearUserActivities();
        setHaveFetchedActivities(false);
    }, [requestUser, userId, fetchSelectedUser, isSelf, clearUserActivities]);

    const handleTabChange = (index) => {
        switch (index) {
            case 1:
                trackEvent(EVENT_PROFILE_TROPHY_CASE_VIEWED);
                break;
            case 2:
                trackEvent(EVENT_PROFILE_ACTIVITY_VIEWED);
                if (!haveFetchedActivities) {
                    fetchSelectedUserActivities(userId);
                }
                break;
            default:
                break;
        }
    };

    const tabs = {
        [t('stats')]: <ProfileStats profile={getProfile()} />,
        [t('profile.trophyCase')]: (
            <ProfileTrophies userTrophies={getTrophies()} />
        ),
        [t('activity.descriptor')]: (
            <ActivityCardScroller
                loading={userActivitiesLoading}
                loadFunc={(options) =>
                    fetchSelectedUserActivities(userId, options)
                }
                items={userActivityItems}
            />
        ),
    };

    return (
        <Grid container direction="column" className={classes.container}>
            <RequestLoader isLoading={loading} title={t('profile.loading')} />
            <ErrorList errors={isSelf ? errors : selectedUserErrors} />
            {isPresentable && (
                <>
                    <Grid
                        container
                        direction="column"
                        className={classes.header}
                        justify="center"
                    >
                        <InfoCardHeader
                            infoCardId={INFO_CARD_PROFILE}
                            hideCard={!isSelf}
                            hasHelpLink={isSelf}
                            image={getAppImages().welcomeProfile}
                            pageTitle={t('profile.title')}
                            rightContent={
                                isSelf ? (
                                    <Button
                                        color={BUTTON_ACCENT_COLOR}
                                        className={classes.editButton}
                                        onClick={handleEditClick}
                                    >
                                        <Edit />
                                        {t('profile.edit')}
                                    </Button>
                                ) : (
                                    <ActivityOptionsPopper
                                        options={[
                                            {
                                                id: REPORT_TYPE_PROFILE,
                                                label: t(
                                                    `flag.${REPORT_TYPE_PROFILE}`,
                                                ),
                                            },
                                        ]}
                                        onSelect={() =>
                                            handleSubmitReport(
                                                selectedUserProfile.id,
                                            )
                                        }
                                        isLoading={flagReportLoading}
                                        content={selectedUserProfile}
                                    />
                                )
                            }
                        >
                            <Typography
                                variant="h2"
                                className={classes.infoTitle}
                            >
                                {t('profile.expressYourself')}
                            </Typography>
                            <Typography className={classes.infoDescription}>
                                {t('profile.welcomeToYourProfile')}
                            </Typography>
                        </InfoCardHeader>
                        <Grid item className={classes.profileImageContainer}>
                            <img
                                src={
                                    getProfile().image ||
                                    getAppImages().defaultProfileIcon
                                }
                                className={classes.profileImage}
                                alt={t('alt.profileImage')}
                            />
                        </Grid>
                        <Typography
                            variant="h2"
                            className={classes.profileName}
                        >
                            {getProfile().name}
                        </Typography>
                        <Typography
                            variant="h5"
                            className={classes.profileHeadline}
                        >
                            {getProfile().headline}
                        </Typography>
                    </Grid>
                    <TabbedSection onTabChange={handleTabChange} tabs={tabs} />
                </>
            )}
            <SnackBarAPIResponse
                messages={messages}
                errors={errors}
                clearResponse={clearErrors}
            />
        </Grid>
    );
};

ProfileScreen.propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: CommonPropTypes.errors,
    profile: ProfilePropType,
    requestUser: PropTypes.func.isRequired,
    fetchSelectedUser: PropTypes.func.isRequired,
    selectedUserLoading: PropTypes.bool.isRequired,
    selectedUserErrors: CommonPropTypes.errors,
    selectedUserProfile: ProfilePropType,
    userTrophies: ProfileTrophyPropType,
    selectedUserTrophies: ProfileTrophyPropType,
    fetchSelectedUserActivities: PropTypes.func.isRequired,
    clearUserActivities: PropTypes.func.isRequired,
    userActivitiesLoading: PropTypes.bool,
    userActivityItems: PropTypes.array,
    currentUserId: PropTypes.string.isRequired,
    handleSubmitReport: PropTypes.func,
    clearErrors: PropTypes.func,
    flagReportLoadingId: PropTypes.string,
    messages: PropTypes.array,
};

ProfileScreen.defaultProps = {
    errors: null,
    profile: null,
    userTrophies: [],
    selectedUserTrophies: [],
    selectedUserErrors: null,
    selectedUserProfile: null,
    userActivitiesLoading: false,
    userActivityItems: [],
    handleSubmitReport: noop,
    flagReportLoadingId: null,
    messages: [],
    clearErrors: noop,
};

const mapStateToProps = (state) => {
    const errors = [
        ...(getUserErrors(state) || []),
        ...(getFlagErrors(state) || []),
    ];
    return {
        // Admin
        loading: isUserLoading(state),
        errors: errors?.length === 0 ? null : errors,
        messages: getFlagMessages(state),
        profile: getUserProfile(state),
        userTrophies: getUserTrophies(state),
        currentUserId: getUserId(state),

        // Selected User
        selectedUserLoading: isSelectedUserLoading(state),
        selectedUserErrors: getSelectedUserErrors(state),
        selectedUserProfile: getSelectedUserProfile(state),
        selectedUserTrophies: getSelectedUserTrophies(state),

        userActivitiesLoading: activitiesLoading(
            state,
            ACTIVITIES_FEED_TYPE_USER,
        ),
        userActivityItems: getActivities(state, ACTIVITIES_FEED_TYPE_USER),
        flagReportLoadingId: getReportPostContentId(state),
        clearErrors: PropTypes.func,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleSubmitReport: (profileId) =>
        dispatch(profileReportRequested(profileId)),
    fetchSelectedUser: (userId) => dispatch(userDetailsRequested(userId)),
    requestUser: () => dispatch(userRequested()),
    fetchSelectedUserActivities: (userId, options) =>
        dispatch(
            activityDataRequested(ACTIVITIES_FEED_TYPE_USER, {
                userId,
                ...options,
            }),
        ),
    clearUserActivities: () =>
        dispatch(clearActivities(ACTIVITIES_FEED_TYPE_USER)),
    clearErrors: () => dispatch(clearFlagMessages()),
});

const ConnectedProfileScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileScreen);
export default ConnectedProfileScreen;
