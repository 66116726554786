export const CURRENT_USER_REQUESTED = 'CURRENT_USER_REQUESTED';
export const USER_REQUEST_SUCCEEDED = 'USER_REQUEST_SUCCEEDED';
export const USER_REQUEST_FAILED = 'USER_REQUEST_FAILED';

export const SET_ACTIVE_COMMUNITY = 'SET_ACTIVE_COMMUNITY';
export const SET_ACTIVE_COMMUNITY_FAILED = 'SET_ACTIVE_COMMUNITY_FAILED';
export const SET_ACTIVE_COMMUNITY_SUCCEEDED = 'SET_ACTIVE_COMMUNITY_SUCCEEDED';

export const USER_DETAILS_REQUESTED = 'USER_DETAILS_REQUESTED';
export const USER_DETAILS_REQUEST_SUCCEEDED = 'USER_DETAILS_REQUEST_SUCCEEDED';
export const USER_DETAILS_REQUESTED_FAILED = 'USER_DETAILS_REQUESTED_FAILED';

export const MEMBERS_SEARCH_REQUESTED = 'MEMBERS_SEARCH_REQUESTED';
export const MEMBERS_SEARCH_REQUEST_FAILED = 'MEMBERS_SEARCH_REQUEST_FAILED';
export const MEMBERS_SEARCH_REQUEST_SUCCEEDED =
    'MEMBERS_SEARCH_REQUEST_SUCCEEDED';

export const MEMBERS_REQUESTED = 'MEMBERS_REQUESTED';
export const MEMBERS_REQUEST_SUCCEEDED = 'MEMBERS_REQUEST_SUCCEEDED';
export const MEMBERS_REQUEST_FAILED = 'MEMBERS_REQUEST_FAILED';

export const PERMISSIONS_AND_ROLES_REQUESTED =
    'PERMISSIONS_AND_ROLES_REQUESTED';
export const PERMISSIONS_AND_ROLES_REQUEST_SUCCEEDED =
    'PERMISSIONS_AND_ROLES_REQUEST_SUCCEEDED';
export const PERMISSIONS_AND_ROLES_REQUEST_FAILED =
    'PERMISSIONS_AND_ROLES_REQUEST_FAILED';

export const ACTIVATE_USERS_REQUESTED = 'ACTIVATE_USERS_REQUESTED';
export const ACTIVATE_USERS_REQUEST_SUCCEEDED =
    'ACTIVATE_USERS_REQUEST_SUCCEEDED';
export const ACTIVATE_USERS_REQUEST_FAILED = 'ACTIVATE_USERS_REQUEST_FAILED';

export const DEACTIVATE_USERS_REQUESTED = 'DEACTIVATE_USERS_REQUESTED';
export const DEACTIVATE_USERS_REQUEST_SUCCEEDED =
    'DEACTIVATE_USERS_REQUEST_SUCCEEDED';
export const DEACTIVATE_USERS_REQUEST_FAILED =
    'DEACTIVATE_USERS_REQUEST_FAILED';

export const USER_UPDATE_REQUESTED = 'USER_UPDATE_REQUESTED';
export const USER_UPDATE_SUCCEEDED = 'USER_UPDATE_SUCCEEDED';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';

export const USER_EMAIL_UPDATE_REQUESTED = 'USER_EMAIL_UPDATE_REQUESTED';
export const USER_EMAIL_UPDATE_SUCCEEDED = 'USER_EMAIL_UPDATE_SUCCEEDED';
export const USER_EMAIL_UPDATE_FAILED = 'USER_EMAIL_UPDATE_FAILED';
export const REMOVE_USER_REQUESTED = 'REMOVE_USER_REQUESTED';

export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_REQUEST_FAILED = 'DELETE_USER_REQUEST_FAILED';
export const DELETE_USER_REQUEST_SUCCEEDED = 'DELETE_USER_REQUEST_SUCCEEDED';

export const DELETE_USER_ACCOUNT_REQUESTED = 'DELETE_USER_ACCOUNT_REQUESTED';
export const DELETE_USER_ACCOUNT_REQUEST_FAILED =
    'DELETE_USER_ACCOUNT_REQUEST_FAILED';
export const DELETE_USER_ACCOUNT_REQUEST_SUCCEEDED =
    'DELETE_USER_ACCOUNT_REQUEST_SUCCEEDED';

export const UPDATE_USER_PERMISSIONS_REQUESTED =
    'UPDATE_USER_PERMISSIONS_REQUESTED';
export const UPDATE_USER_PERMISSIONS_SUCCEEDED =
    'UPDATE_USER_PERMISSIONS_SUCCEEDED';
export const UPDATE_USER_PERMISSIONS_FAILED = 'UPDATE_USER_PERMISSIONS_FAILED';

export const MOVE_USER_ORGANIZATION_REQUESTED =
    'MOVE_USER_ORGANIZATION_REQUESTED';
export const MOVE_USER_ORGANIZATION_SUCCEEDED =
    'MOVE_USER_ORGANIZATION_SUCCEEDED';
export const MOVE_USER_ORGANIZATION_FAILED = 'MOVE_USER_ORGANIZATION_FAILED';

export const OTHER_COMMUNITIES_REQUESTED = 'OTHER_COMMUNITIES_REQUESTED';
export const OTHER_COMMUNITIES_REQUEST_SUCCEEDED =
    'OTHER_COMMUNITIES_REQUEST_SUCCEEDED';
export const OTHER_COMMUNITIES_REQUEST_FAILED =
    'OTHER_COMMUNITIES_REQUEST_FAILED';

export const UPDATE_USER_COMMUNITIES = 'UPDATE_USER_COMMUNITIES';

export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';

export const DISMISS_INFO_CARD = 'DISMISS_INFO_CARD';
export const SHOW_INFO_CARD = 'SHOW_INFO_CARD';

export const TOGGLE_USER_VIEW = 'TOGGLE_USER_VIEW';
export const TOGGLE_USER_VIEW_SUCCEEDED = 'TOGGLE_USER_VIEW_SUCCEEDED';

export const userRequested = (isAuthenticating = false) => ({
    type: CURRENT_USER_REQUESTED,
    payload: {
        isAuthenticating,
    },
});
export const userRequestSucceeded = (user, isAuthenticating = false) => ({
    type: USER_REQUEST_SUCCEEDED,
    payload: {
        user,
        isAuthenticating,
    },
});
export const userRequestFailed = (errors) => ({
    type: USER_REQUEST_FAILED,
    payload: {
        errors,
    },
});

export const userDetailsRequested = (userId) => ({
    type: USER_DETAILS_REQUESTED,
    payload: {
        userId,
    },
});

export const userDetailsRequestSucceeded = (user) => ({
    type: USER_DETAILS_REQUEST_SUCCEEDED,
    payload: {
        user,
    },
});

export const userDetailsRequestFailed = (errors) => ({
    type: USER_DETAILS_REQUESTED_FAILED,
    payload: {
        errors,
    },
});

export const setActiveCommunity = (communityId, toggleToUserView) => ({
    type: SET_ACTIVE_COMMUNITY,
    payload: {
        communityId,
        toggleToUserView,
    },
});

export const setActiveCommunityFailed = (errors) => ({
    type: SET_ACTIVE_COMMUNITY_FAILED,
    payload: {
        errors,
    },
});

export const setActiveCommunitySucceeded = (communityId) => ({
    type: SET_ACTIVE_COMMUNITY_SUCCEEDED,
    payload: {
        communityId,
    },
});

export const userUpdateRequested = (request, isCreating = false) => ({
    type: USER_UPDATE_REQUESTED,
    payload: {
        request,
        isCreating,
    },
});

export const userUpdateSucceeded = (response) => ({
    type: USER_UPDATE_SUCCEEDED,
    payload: {
        response,
    },
});

export const userUpdateFailed = (errors) => ({
    type: USER_UPDATE_FAILED,
    payload: {
        errors,
    },
});

export const userEmailUpdateRequested = (request) => ({
    type: USER_EMAIL_UPDATE_REQUESTED,
    payload: {
        request,
    },
});

export const userEmailUpdateSucceeded = (response) => ({
    type: USER_EMAIL_UPDATE_SUCCEEDED,
    payload: {
        response,
    },
});

export const userEmailUpdateFailed = (errors) => ({
    type: USER_EMAIL_UPDATE_FAILED,
    payload: {
        errors,
    },
});

export const membersRequested = (communityId, activeStatus, options) => {
    return {
        type: MEMBERS_REQUESTED,
        payload: {
            communityId,
            activeStatus,
            options,
        },
    };
};

export const membersRequestSucceeded = (response) => ({
    type: MEMBERS_REQUEST_SUCCEEDED,
    payload: response,
});

export const membersRequestFailed = (activeStatus, errors) => ({
    type: MEMBERS_REQUEST_FAILED,
    payload: {
        errors,
        activeStatus,
    },
});

export const membersSearchRequested = (
    communityId,
    searchString,
    activeStatus,
    options,
) => ({
    type: MEMBERS_SEARCH_REQUESTED,
    payload: {
        communityId,
        searchString,
        activeStatus,
        options,
    },
});

export const membersSearchRequestSucceeded = (response) => ({
    type: MEMBERS_SEARCH_REQUEST_SUCCEEDED,
    payload: response,
});

export const membersSearchRequestFailed = (activeStatus, errors) => ({
    type: MEMBERS_SEARCH_REQUEST_FAILED,
    payload: {
        activeStatus,
        errors,
    },
});

export const permissionsAndRolesRequested = (communityId) => ({
    type: PERMISSIONS_AND_ROLES_REQUESTED,
    payload: {
        communityId,
    },
});

export const permissionsAndRolesRequestSucceeded = (permissions, roles) => ({
    type: PERMISSIONS_AND_ROLES_REQUEST_SUCCEEDED,
    payload: {
        permissions,
        roles,
    },
});

export const permissionsAndRolesRequestFailed = () => ({
    type: PERMISSIONS_AND_ROLES_REQUEST_FAILED,
});

export const activateUsersRequested = (communityId, userIds) => ({
    type: ACTIVATE_USERS_REQUESTED,
    payload: {
        communityId,
        userIds,
    },
});

export const activateUsersRequestSucceeded = () => ({
    type: ACTIVATE_USERS_REQUEST_SUCCEEDED,
});

export const activateUsersRequestFailed = (errors = []) => ({
    type: ACTIVATE_USERS_REQUEST_FAILED,
    payload: { errors },
});

export const deactivateUsersRequested = (communityId, userIds) => ({
    type: DEACTIVATE_USERS_REQUESTED,
    payload: {
        communityId,
        userIds,
    },
});

export const deactivateUsersRequestSucceeded = () => ({
    type: DEACTIVATE_USERS_REQUEST_SUCCEEDED,
});

export const deactivateUsersRequestFailed = (errors = []) => ({
    type: DEACTIVATE_USERS_REQUEST_FAILED,
    payload: { errors },
});

export const deleteUserRequested = (communityId, userIds) => ({
    type: DELETE_USER_REQUESTED,
    payload: {
        communityId,
        userIds,
    },
});

export const deleteUserRequestFailed = (errors = []) => ({
    type: DELETE_USER_REQUEST_FAILED,
    payload: { errors },
});

export const deleteUserRequestSucceeded = () => ({
    type: DELETE_USER_REQUEST_SUCCEEDED,
});

export const deleteUserAccountRequested = (userId) => ({
    type: DELETE_USER_ACCOUNT_REQUESTED,
    payload: {
        userId,
    },
});

export const deleteUserAccountRequestFailed = (errors = []) => ({
    type: DELETE_USER_ACCOUNT_REQUEST_FAILED,
    payload: { errors },
});

export const deleteUserAccountRequestSucceeded = () => ({
    type: DELETE_USER_ACCOUNT_REQUEST_SUCCEEDED,
});

export const updateUserPermissionsRequested = (
    communityId,
    userIds,
    permissions,
    role,
) => ({
    type: UPDATE_USER_PERMISSIONS_REQUESTED,
    payload: {
        communityId,
        userIds,
        permissions,
        role,
    },
});

export const updateUserPermissionsSucceeded = () => ({
    type: UPDATE_USER_PERMISSIONS_SUCCEEDED,
});

export const updateUserPermissionsFailed = (errors = []) => ({
    type: UPDATE_USER_PERMISSIONS_FAILED,
    payload: { errors },
});

export const moveUserOrganizationRequested = (
    communityId,
    userId,
    newOrganizationId,
) => ({
    type: MOVE_USER_ORGANIZATION_REQUESTED,
    payload: {
        communityId,
        userId,
        newOrganizationId,
    },
});

export const moveUserOrganizationSucceeded = () => ({
    type: MOVE_USER_ORGANIZATION_SUCCEEDED,
});

export const moveUserOrganizationFailed = (errors = []) => ({
    type: MOVE_USER_ORGANIZATION_FAILED,
    payload: { errors },
});

export const otherCommunitiesRequested = () => ({
    type: OTHER_COMMUNITIES_REQUESTED,
});

export const otherCommunitiesRequestSucceeded = (response) => ({
    type: OTHER_COMMUNITIES_REQUEST_SUCCEEDED,
    payload: { response },
});

export const otherCommunitiesRequestFailed = (errors) => ({
    type: OTHER_COMMUNITIES_REQUEST_FAILED,
    payload: { errors },
});

export const updateUserCommunities = (communities) => ({
    type: UPDATE_USER_COMMUNITIES,
    payload: { communities },
});

export const clearUserErrors = () => ({
    type: CLEAR_USER_ERRORS,
});

export const dismissInfoCard = (infoCardId) => ({
    type: DISMISS_INFO_CARD,
    payload: { infoCardId },
});

export const showInfoCard = (infoCardId) => ({
    type: SHOW_INFO_CARD,
    payload: { infoCardId },
});

export const toggleUserView = () => ({
    type: TOGGLE_USER_VIEW,
});

export const toggleUserViewSucceeded = () => ({
    type: TOGGLE_USER_VIEW_SUCCEEDED,
});
