const styles = (theme) => ({
    root: {
        color: theme.palette.secondary.main,
        borderRadius: 3,
    },
    largeRoot: {
        height: theme.shineAdmin.select.largeHeight,
        minWidth: theme.shineAdmin.select.largeMinWidth,
    },
    compactRoot: {
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.primary.contrastText,
        height: theme.shineAdmin.select.compactHeight,
        minWidth: theme.shineAdmin.select.compactMinWidth,
    },
    icon: {
        color: theme.palette.secondary.main,
        marginRight: 7,
    },
    compactIcon: {
        fontSize: 16,
        marginRight: 2,
        marginTop: 4,
    },
    compactMenuItem: {
        paddingBottom: 0,
    },
    largeDisplay: {
        padding: 10,
        paddingRight: 30,
        height: theme.shineAdmin.select.largeHeight,
        minWidth: theme.shineAdmin.select.largeMinWidth,
    },
    compactDisplay: {
        padding: 2,
        color: theme.palette.secondary.main,
        fontSize: theme.custom.paginationFontSize,
        height: theme.shineAdmin.select.compactHeight - 2,
        minWidth: theme.shineAdmin.select.compactMinWidth,
        marginLeft: 2,
    },
    error: {
        border: `2px solid ${theme.custom.red}`,
    },
    errorText: {
        fontWeight: 'bold',
        marginLeft: 8,
        fontSize: 12,
    },
    disabledIcon: {
        display: 'none',
    },
    formControl: {
        maxWidth: 300,
    },
    select: {
        backgroundColor: 'white',
        border: 'solid 1px',
        borderColor: theme.palette.primary.main,
        borderRadius: '5px',
    },
});

export default styles;
