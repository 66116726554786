import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    translations: {
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: 1.1,
        marginBottom: '10px',
        marginTop: '20px',
    },
    duplication: {
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: 1.1,
        marginBottom: '10px',
        marginTop: '50px',
    },
    subtitle: {
        marginBottom: '5px',
        fontWeight: 700,
    },
    checkbox: {
        marginLeft: 'auto',
    },
    slackButton: {
        'align-items': 'center',
        color: '#fff',
        'background-color': '#4A154B',
        border: 0,
        'border-radius': '4px',
        display: 'inline-flex',
        'font-family': 'Lato, sans-serif',
        'font-size': '18px',
        'font-weight': 600,
        height: '56px',
        'justify-content': 'center',
        'text-decoration': 'none',
        width: '276px',
    },
    slackIcon: {
        height: '24px',
        width: '24px',
        'margin-right': '12px',
    },
    footer: {
        padding: '100px 0 16px 0',
        backgroundColor: theme.custom.bgColor,
    },
}));

export default useStyles;
