const styles = (theme) => ({
    root: {
        padding: '0 96px',
        '& td, td span, th': { fontSize: 14 },
    },
    bold: {
        fontWeight: 'bold',
    },
});

export default styles;
