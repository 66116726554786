export const actionItems = [
    {
        label: 'active',
        url: '/actions/active',
        actions: 'view',
        subject: 'ActionsAdmin',
    },
    {
        label: 'deactivated',
        url: '/actions/deactivated',
        actions: 'edit',
        subject: 'CommunityActionExclude',
    },
];

export const challengeItems = [
    {
        label: 'create',
        url: '/challenges/create',
        actions: 'create',
        subject: 'Challenge',
    },
    {
        label: 'active',
        url: '/challenges/active',
        actions: 'view',
        subject: 'ChallengesAdmin',
    },
    {
        label: 'challenge.status.draft',
        url: '/challenges/drafts',
        actions: 'view',
        subject: 'ChallengesAdmin',
    },
    {
        label: 'challenge.status.upcoming',
        url: '/challenges/upcoming',
        actions: 'view',
        subject: 'ActionsAdmin',
    },
    {
        label: 'challenge.status.past',
        url: '/challenges/past',
        actions: 'view',
        subject: 'ActionsAdmin',
    },
    {
        label: 'deactivated',
        url: '/challenges/deactivated',
        actions: 'view',
        subject: 'ActionsAdmin',
    },
];

export const notificationsItems = [
    {
        label: 'create',
        url: '/notifications/create',
        actions: 'manage',
        subject: 'MasterNotifications',
    },
    {
        label: 'notifications.groups.queued',
        url: '/notifications/queued',
        actions: 'view',
        subject: 'MasterNotifications',
    },
    {
        label: 'notifications.groups.delivered',
        url: '/notifications/sent',
        actions: 'view',
        subject: 'MasterNotifications',
    },
];

export const usersItems = [
    {
        label: 'active',
        url: '/members/active',
        actions: 'view',
        subject: 'CommunityMembers',
    },
    {
        label: 'deactivated',
        url: '/members/deactivated',
        actions: 'view',
        subject: 'CommunityMembers',
    },
];

export const TAB_CHALLENGES = 'challenge';
export const TAB_ACTIONS = 'actions';
export const TAB_NOTIFICATIONS = 'notifications';
export const TAB_USERS = 'users';
