import PropTypes from 'prop-types';

const FlaggedContentPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    flags: PropTypes.number.isRequired,
    createdDate: PropTypes.string.isRequired,
    flaggedDate: PropTypes.string.isRequired,
});

export default FlaggedContentPropType;
