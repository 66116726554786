import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
    getAppDescription,
    getAppImages,
    getAppName,
    getProperties,
} from '../../utils';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import Footer from '../../common/Footer';
import AppDownloadLinks from '../../common/AppDownloadLinks';
import Logo from '../../common/Logo';

const WordpressScreen = ({ classes }) => {
    const {
        params: { wpPath },
    } = useRouteMatch();
    const { pathname } = useLocation();
    const menuItems = [
        { label: 'Terms of Service', route: '/terms-of-service' },
        { label: 'Privacy', route: '/privacy' },
    ];

    if (!['/download', '/about'].includes(pathname)) {
        let url = `${getProperties()?.wordpressDomain}${pathname}`;

        if (getProperties()?.wordpressOverrides?.[pathname]) {
            url = getProperties()?.wordpressOverrides?.[pathname];
        } else if (wpPath) {
            url = `${getProperties()?.wordpressDomain}/${wpPath}`;
        }
        window.location.assign(url);
    }

    const NavBar = () => {
        return (
            <Grid container className={classes.navBarContainer}>
                <Grid container item className={classes.navBarLogoContainer}>
                    <Grid item className={classes.logoContainer}>
                        <Logo isLogoWhite />
                    </Grid>
                    <Grid
                        container
                        item
                        className={classes.navBarItemContainer}
                    >
                        {menuItems.map((item, i) => (
                            <Grid
                                item
                                href={item.route}
                                key={i}
                                className={classes.navBarItemContainer}
                            >
                                <a
                                    className={classes.navBarItem}
                                    href={item.route}
                                >
                                    +{item.label}
                                </a>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const appName = getAppName();
    const appDescription = getAppDescription();
    const HeaderContent = () => (
        <Grid
            container
            className={classes.headerContentContainer}
            key="HeaderContent"
        >
            {/* App store description */}
            <Grid item className={classes.storeDescriptionContainer}>
                <Grid item className={classes.storeDescriptionContainerInner}>
                    <Grid item>
                        {/*    Title */}
                        <Typography className={classes.storeDescriptionTitle}>
                            Join your organization on{' '}
                            <span className={classes.storeDescriptionAppName}>
                                {appName}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/*    Description */}
                        <Typography className={classes.storeDescriptionText}>
                            {appDescription}
                        </Typography>
                    </Grid>
                    {/* App Store Links    */}
                    <Grid item style={{ flex: 1, paddingTop: 30 }}>
                        <AppDownloadLinks
                            classes={{ container: classes.downloadContainer }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* Mockup */}
            <Grid item className={classes.storeMockupContainer}>
                <img
                    alt=""
                    src={getAppImages().landingMockup}
                    className={classes.storeMockup}
                />
            </Grid>
        </Grid>
    );

    return (
        <Grid container className={classes.container}>
            <NavBar />
            <HeaderContent />
            <Grid item className={classes.featureContainer}>
                <Grid
                    item
                    className={classes.featureContainerBackgroundContainer}
                >
                    <div className={classes.featureContainerBackground}>
                        Inside the App
                    </div>
                </Grid>
                <Grid item className={classes.appMockupContainer}>
                    <img
                        alt=""
                        src={getAppImages().appMockup}
                        className={classes.appMockup}
                    />
                </Grid>
            </Grid>
            <Footer
                className={classes.footer}
                classes={{
                    footerText: classes.footerText,
                    logo: classes.footerLogo,
                }}
            />
        </Grid>
    );
};

export default withStyles(styles)(WordpressScreen);
