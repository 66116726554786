import { createSelector } from 'reselect';
import { coerceIntoErrors } from '../../common/errors';
import { getAppImages } from '../../utils';

export const getAnalyticsPhotoState = (state) => state.analyticsPhoto;

export const getPhotos = createSelector(
    getAnalyticsPhotoState,
    (analyticsPhoto) => analyticsPhoto.photos,
);

export const arePhotosLoading = createSelector(
    getAnalyticsPhotoState,
    (analyticsPhoto) => analyticsPhoto.isLoading,
);

export const getErrors = createSelector(
    getAnalyticsPhotoState,
    (analyticsPhoto) => coerceIntoErrors(analyticsPhoto.errors),
);

export const getTopPhotos = createSelector(getPhotos, (photos) => {
    if (!photos) {
        return [];
    }
    return photos.map((photo) => {
        const userName = photo.user.name;
        const defaultActionText = photo.actions[0].actionDefaultText;
        const imageSizes = photo.photoSizes || [];
        const likes = photo.liked || [];
        const comments = (photo.comments || []).map((comment) => ({
            userName: comment.user.name,
            message: comment.message,
        }));
        const actionUrl = photo.actions[0].content.imageSizes[0]?.url;
        const userUrl =
            (photo.user.imageSizes &&
                photo.user.imageSizes.length > 0 &&
                photo.user.imageSizes[0].url) ||
            getAppImages().defaultProfileIcon;
        const photoUrl =
            (photo.photoSizes &&
                photo.photoSizes.length > 0 &&
                photo.photoSizes[photo.photoSizes.length - 1].url) ||
            null;
        return {
            userName,
            defaultActionText,
            imageSizes,
            likes,
            comments,
            actionUrl,
            userUrl,
            photoUrl,
        };
    });
});
