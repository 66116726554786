import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchWithAuth,
    getErrorsFromPossibleAPIErrorResponse,
} from '../../utils/api';
import {
    CHALLENGE_PHOTOS_REQUESTED,
    challengePhotosRequestFailed,
    challengePhotosRequestSucceeded,
    COMMUNITY_PHOTOS_REQUESTED,
    communityPhotosRequestFailed,
    communityPhotosRequestSucceeded,
} from './actions';

export function* getMorePhotos({ payload }) {
    try {
        const data = yield call(
            fetchWithAuth,
            `/community/${payload.communityId}/buzz`,
            {
                query: {
                    communityId: payload.communityId,
                    afterTimestamp: payload.afterTimestamp,
                    beforeTimestamp: payload.beforeTimestamp,
                    skip: payload.skip,
                    limit: payload.limit,
                    photosOnly: true,
                    sort: 'likesfirst',
                },
            },
        );
        const activities = (data.response && data.response.activities) || [];
        yield put(
            communityPhotosRequestSucceeded({
                photos: activities,
                skip: payload.skip,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to load photos',
        );
        yield put(communityPhotosRequestFailed(errors));
    }
}

export function* getMoreChallengePhotos({ payload }) {
    try {
        const data = yield call(
            fetchWithAuth,
            `/challenges/${payload.challengeId}/buzz`,
            {
                query: {
                    communityId: payload.communityId,
                    skip: payload.skip,
                    limit: payload.limit,
                    photosOnly: true,
                    sort: 'likesfirst',
                },
            },
        );
        const activities = (data.response && data.response.activities) || [];
        yield put(
            challengePhotosRequestSucceeded({
                photos: activities,
                skip: payload.skip,
            }),
        );
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to load photos',
        );
        yield put(challengePhotosRequestFailed(errors));
    }
}

export default function* analyticsPhotoSaga() {
    yield all([
        takeLatest(COMMUNITY_PHOTOS_REQUESTED, getMorePhotos),
        takeLatest(CHALLENGE_PHOTOS_REQUESTED, getMoreChallengePhotos),
    ]);
}
