import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import ConfirmModal from '../ConfirmModal';
import ModalTitle from '../ModalTitle';
import { BUTTON_ACCENT_COLOR, BUTTON_DEFAULT_COLOR } from '../Button/constants';
import FieldLabel from '../FieldLabel';
import Textarea from '../Textarea';
import { noop } from '../../utils';

const UpdateTextModal = ({
    isOpen,
    setOpen,
    title,
    label,
    text: initialText,
    textareaProps,
    onClose,
    onCancel,
    onConfirm,
}) => {
    const { t } = useTranslation();

    const [text, setText] = useState(initialText);
    const [key, setKey] = useState(0);
    const clearState = useCallback(() => {
        setText(initialText);
        setKey(key + 1);
    }, [key, initialText]);

    const handleClose = useCallback(() => {
        onClose();
        setOpen(false);
        clearState();
    }, [onClose, setOpen, clearState]);

    const handleCancel = useCallback(() => {
        onCancel();
        setOpen(false);
        clearState();
    }, [onCancel, setOpen, clearState]);

    const handleConfirm = useCallback(() => {
        onConfirm(text);
        clearState();
    }, [onConfirm, text, clearState]);

    const handleTextChange = useCallback(
        (event) => {
            setText(event.target.value);
        },
        [setText],
    );

    return (
        <ConfirmModal
            isOpen={isOpen}
            title={
                <ModalTitle
                    tooltipPlacement="right"
                    text={title}
                    tooltipText={t('viewDocs')}
                />
            }
            confirmBtnText={t('OK')}
            confirmBtnType={BUTTON_ACCENT_COLOR}
            cancelBtnText={t('cancel')}
            cancelBtnType={BUTTON_DEFAULT_COLOR}
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        >
            <Grid container direction="column" key={key}>
                <Grid item>
                    <FieldLabel Icon={null} text={label} />
                </Grid>
                <Grid item>
                    <Textarea
                        value={text}
                        onChange={handleTextChange}
                        {...textareaProps}
                    />
                </Grid>
            </Grid>
        </ConfirmModal>
    );
};

UpdateTextModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textareaProps: PropTypes.object,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
};

const DEFAULT_TEXTAREA_PROPS = {
    rowsMin: 5,
};

UpdateTextModal.defaultProps = {
    onClose: noop,
    onCancel: noop,
    textareaProps: DEFAULT_TEXTAREA_PROPS,
};

export default UpdateTextModal;
