export const BUTTON_PRIMARY_COLOR = 'primary';
export const BUTTON_SECONDARY_COLOR = 'secondary';
export const BUTTON_DEFAULT_COLOR = 'default';
export const BUTTON_ACCENT_COLOR = 'accent';
export const BUTTON_DANGER_COLOR = 'danger';
export const BUTTON_WARNING_COLOR = 'warning';
export const BUTTON_SUCCESS_COLOR = 'success';
export const BUTTON_INFO_COLOR = 'info';
export const BUTTON_WHITE_COLOR = 'white';
export const BUTTON_GRAY_COLOR = 'gray';
export const BUTTON_LIGHT_COLOR = 'light';

const BUTTON_COLORS = [
    BUTTON_PRIMARY_COLOR,
    BUTTON_SECONDARY_COLOR,
    BUTTON_DEFAULT_COLOR,
    BUTTON_ACCENT_COLOR,
    BUTTON_DANGER_COLOR,
    BUTTON_WARNING_COLOR,
    BUTTON_SUCCESS_COLOR,
    BUTTON_INFO_COLOR,
    BUTTON_WHITE_COLOR,
    BUTTON_GRAY_COLOR,
    BUTTON_LIGHT_COLOR,
];

export default BUTTON_COLORS;
