import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { resetPasswordEmailRequested } from '../../../auth/actions';
import { useTranslation } from 'react-i18next';
import Form from '../../../common/Form';
import FormInput from '../../../common/Form/FormInput';
import styles from './styles';
import SignInScreenTemplate from '../index';
import AdminButton from '../../../common/Button';
import { getAuthMessages } from '../../../auth/selectors';

const ForgotPasswordScreen = ({ classes, onResetPassword, successMessage }) => {
    const { t } = useTranslation();

    const ValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('userCreate.emailInvalid'))
            .required(t('userCreate.inputRequired')),
    });

    useEffect(() => {
        if (successMessage?.length > 0) {
            setShowForm(false);
        }
    }, [successMessage]);

    const submitHandler = (value) => {
        onResetPassword(value.email);
    };

    const [showForm, setShowForm] = useState(true);

    return (
        <SignInScreenTemplate>
            <Grid container item justify="center">
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="h2">
                        {t('passwordReset.title')}
                    </Typography>
                </Grid>
                {showForm ? (
                    <>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                {t('passwordReset.resetSendDescription')}
                            </Typography>
                        </Grid>
                        <Form
                            validationSchema={ValidationSchema}
                            initialValues={{ email: '' }}
                            onSubmit={submitHandler}
                        >
                            <FormInput name="email" label={t('email')} />
                        </Form>
                    </>
                ) : (
                    <AdminButton
                        color="accent"
                        size="large"
                        style={{ width: '100%' }}
                        onClick={() => setShowForm(true)}
                    >
                        {t('passwordReset.resendEmail')}
                    </AdminButton>
                )}
                <Link className={classes.link} to="/login">
                    {t('passwordReset.returnToLogin')}
                </Link>
            </Grid>
        </SignInScreenTemplate>
    );
};

ForgotPasswordScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    onResetPassword: PropTypes.func.isRequired,
    successMessage: PropTypes.arrayOf(PropTypes.string),
};

ForgotPasswordScreen.defaultProps = {
    successMessage: [],
};

export const StyledResetPasswordScreen = withStyles(styles)(
    ForgotPasswordScreen,
);

const mapStateToProps = (state) => ({
    successMessage: getAuthMessages(state),
});

const mapDispatchToProps = (dispatch) => ({
    onResetPassword: (email) => dispatch(resetPasswordEmailRequested(email)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledResetPasswordScreen);
